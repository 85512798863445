import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import Header from './layout/Header';
import Main from './layout/Main';
import { fetchSelf } from '../actions';
import './App.css';
import TwoStepAuthorizationModal from './modules/booking/payment/TwoStepAuthorizationModal';

export class App extends Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    fetchSelf: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    // fetch current user when loading the app to confirm user is logged in
    this.props.fetchSelf();
  }

  render() {
    return (
      <div className="App">
        <Header authenticated={this.props.authenticated} />
        <Main />
        <div>
          <ReduxToastr transitionIn="fadeIn" transitionOut="fadeOut" />
        </div>
        <div>
          <TwoStepAuthorizationModal />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => ({
  authenticated: authentication.get('authenticated'),
});

const mapDispatchToProps = {
  fetchSelf,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
