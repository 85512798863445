import React from 'react';
import PropTypes from 'prop-types';
import ReactCreatableSelect from 'react-select/creatable';
import { FormText } from 'reactstrap';
import { debounce } from '../../../../utils/promise';

const CreatableSelect = ({
  input: { onChange, value },
  name,
  options,
  isLoading,
  isClearable,
  onInputChange,
  placeholder,
  disabled,
  meta: { touched, error, warning },
  autoFocus,
  noOptionsMessage,
  formatCreateLabel,
  onCreateOption,
  isValidNewOption,
}) => (
  <div>
    <ReactCreatableSelect
      disabled={disabled}
      onChange={onChange}
      onInputChange={debounce(onInputChange)}
      value={value}
      name={name}
      options={options}
      isClearable={isClearable}
      isLoading={isLoading}
      placeholder={placeholder}
      autoFocus={autoFocus}
      noOptionsMessage={noOptionsMessage}
      onCreateOption={onCreateOption}
      isValidNewOption={isValidNewOption}
      formatCreateLabel={formatCreateLabel}
      loadingMessage={() => 'Cargando...'}
    />
    <FormText color="danger">
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </FormText>
  </div>
);

CreatableSelect.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }),
  autoFocus: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  onCreateOption: PropTypes.func.isRequired,
  isValidNewOption: PropTypes.func.isRequired,
};

CreatableSelect.defaultProps = {
  name: '',
  options: [],
  input: {
    input: () => {},
    value: null,
  },
  meta: {
    touched: false,
  },
  onInputChange: () => {},
  isLoading: false,
  isClearable: false,
  disabled: false,
  placeholder: 'Selecciona ...',
  autoFocus: false,
  noOptionsMessage: () => 'No hay resultados.',
  formatCreateLabel: (inputValue) => `Registrar ${inputValue}`,
};

export default CreatableSelect;
