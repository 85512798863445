import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NewOccupancyReport from './occupancy-report/NewOccupancyReport';
import ReportHome from './ReportHome';
import OccupancyReport from './occupancy-report/OccupancyReport';
import OccupancyReports from './occupancy-report/OccupancyReports';
import {
  OCCUPANCY_REPORTS_PATH,
  NEW_OCCUPANCY_REPORT_PATH,
  TICKET_REPORTS_PATH,
  NEW_TICKET_REPORT_PATH,
  ITINERARY_REPORTS_PATH,
  NEW_ITINERARY_REPORT_PATH,
  NOT_BOARDED_TICKET_REPORTS_PATH,
  NEW_NOT_BOARDED_TICKET_REPORT_PATH,
  ACCOUNTING_REPORTS_PATH,
  NEW_ACCOUNTING_REPORT_PATH,
  DISCOUNT_CODE_REPORTS_PATH,
  NEW_DISCOUNT_CODE_REPORT_PATH,
  CALL_CENTER_REPORTS_PATH,
  NEW_CALL_CENTER_REPORT_PATH,
  EXPENSE_REPORTS_PATH,
  NEW_EXPENSE_REPORT_PATH,
  PRICING_PROFILE_REPORTS_PATH,
  NEW_PRICING_PROFILE_REPORT_PATH,
} from '../../../config/paths';
import TicketReport from './ticket-report/TicketReport';
import TicketReports from './ticket-report/TicketReports';
import NewTicketReport from './ticket-report/NewTicketReport';
import NotBoardedTicketReport from './not-boarded-ticket-report/NotBoardedTicketReport';
import NotBoardedTicketReports from './not-boarded-ticket-report/NotBoardedTicketReports';
import NewNotBoardedTicketReport from './not-boarded-ticket-report/NewNotBoardedTicketReport';
import ItineraryReport from './itinerary-report/ItineraryReport';
import ItineraryReports from './itinerary-report/ItineraryReports';
import NewItineraryReport from './itinerary-report/NewItineraryReport';
// import AccountingReport from './accounting-report/AccountingReport';
import AccountingReports from './accounting-report/AccountingReports';
import NewAccountingReport from './accounting-report/NewAccountingReport';
import AccountingReport from './accounting-report/AccountingReport';
import DiscountCodeReports from './discount-code-report/DiscountCodeReports';
import NewDiscountCodeReport from './discount-code-report/NewDiscountCodeReport';
import DiscountCodeReport from './discount-code-report/DiscountCodeReport';
import CallCenterReports from './call-center-report/CallCenterReports';
import NewCallCenterReport from './call-center-report/NewCallCenterReport';
import CallCenterReport from './call-center-report/CallCenterReport';
import ExpenseReports from './expense-report/ExpenseReports';
import NewExpenseReport from './expense-report/NewExpenseReport';
import ExpenseReport from './expense-report/ExpenseReport';
import PricingProfileReports from './pricing-profile-report/PricingProfileReports';
import NewPricingProfileReport from './pricing-profile-report/NewPricingProfileReport';
import PricingProfileReport from './pricing-profile-report/PricingProfileReport';

// new paths need to come before paths with path variables such as `:id`
const ReportModule = () => (
  <Switch>
    <Route exact path="/reports" component={ReportHome} />

    <Route exact path={OCCUPANCY_REPORTS_PATH} component={OccupancyReports} />
    <Route
      exact
      path={NEW_OCCUPANCY_REPORT_PATH}
      component={NewOccupancyReport}
    />
    <Route
      exact
      path={`${OCCUPANCY_REPORTS_PATH}/:id`}
      component={OccupancyReport}
    />

    <Route exact path={TICKET_REPORTS_PATH} component={TicketReports} />
    <Route exact path={NEW_TICKET_REPORT_PATH} component={NewTicketReport} />
    <Route exact path={`${TICKET_REPORTS_PATH}/:id`} component={TicketReport} />

    <Route exact path={ITINERARY_REPORTS_PATH} component={ItineraryReports} />
    <Route
      exact
      path={NEW_ITINERARY_REPORT_PATH}
      component={NewItineraryReport}
    />
    <Route
      exact
      path={`${ITINERARY_REPORTS_PATH}/:id`}
      component={ItineraryReport}
    />

    <Route
      exact
      path={NOT_BOARDED_TICKET_REPORTS_PATH}
      component={NotBoardedTicketReports}
    />
    <Route
      exact
      path={NEW_NOT_BOARDED_TICKET_REPORT_PATH}
      component={NewNotBoardedTicketReport}
    />
    <Route
      exact
      path={`${NOT_BOARDED_TICKET_REPORTS_PATH}/:id`}
      component={NotBoardedTicketReport}
    />

    <Route exact path={ACCOUNTING_REPORTS_PATH} component={AccountingReports} />
    <Route
      exact
      path={NEW_ACCOUNTING_REPORT_PATH}
      component={NewAccountingReport}
    />
    <Route
      exact
      path={`${ACCOUNTING_REPORTS_PATH}/:id`}
      component={AccountingReport}
    />

    <Route
      exact
      path={DISCOUNT_CODE_REPORTS_PATH}
      component={DiscountCodeReports}
    />
    <Route
      exact
      path={NEW_DISCOUNT_CODE_REPORT_PATH}
      component={NewDiscountCodeReport}
    />
    <Route
      exact
      path={`${DISCOUNT_CODE_REPORTS_PATH}/:id`}
      component={DiscountCodeReport}
    />

    <Route
      exact
      path={CALL_CENTER_REPORTS_PATH}
      component={CallCenterReports}
    />
    <Route
      exact
      path={NEW_CALL_CENTER_REPORT_PATH}
      component={NewCallCenterReport}
    />
    <Route
      exact
      path={`${CALL_CENTER_REPORTS_PATH}/:id`}
      component={CallCenterReport}
    />

    <Route exact path={EXPENSE_REPORTS_PATH} component={ExpenseReports} />
    <Route exact path={NEW_EXPENSE_REPORT_PATH} component={NewExpenseReport} />
    <Route
      exact
      path={`${EXPENSE_REPORTS_PATH}/:id`}
      component={ExpenseReport}
    />

    <Route
      exact
      path={PRICING_PROFILE_REPORTS_PATH}
      component={PricingProfileReports}
    />
    <Route
      exact
      path={NEW_PRICING_PROFILE_REPORT_PATH}
      component={NewPricingProfileReport}
    />
    <Route
      exact
      path={`${PRICING_PROFILE_REPORTS_PATH}/:id`}
      component={PricingProfileReport}
    />
  </Switch>
);

export default ReportModule;
