import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDriverTypes, clearDriverTypes } from '../../../../actions';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';

class DriverTypeSelect extends Component {
  componentDidMount() {
    const { dispatchGetDriverTypes } = this.props;
    dispatchGetDriverTypes(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearDriverTypes, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearDriverTypes();
    }
  }

  render() {
    const { options, isLoading, isMulti, input, meta } = this.props;
    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
      />
    );
  }
}

DriverTypeSelect.propTypes = {
  dispatchGetDriverTypes: PropTypes.func.isRequired,
  dispatchClearDriverTypes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  cleanWhenUnmount: PropTypes.bool,
};

DriverTypeSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  options: [],
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  options: TrafficUnit.DriverType.getIn(['all', 'content', 'content']).map(
    (type) => ({
      value: type.id,
      label: type.name,
    }),
  ),
  loading: TrafficUnit.DriverType.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetDriverTypes: getDriverTypes,
  dispatchClearDriverTypes: clearDriverTypes,
};

export default connect(mapStateToProps, mapDispatchToprops)(DriverTypeSelect);
