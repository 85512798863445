import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ACTIVITY_TASK_PATH } from '../../../../config/paths';
import { postActivityTask } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ActivityTaskForm from './ActivityTaskForm';

const NewActivityTask = ({ breadcrumbs, dispatchPostActivityTask }) => {
  const onSubmit = (formValues) =>
    dispatchPostActivityTask({
      ...formValues,
      companyAreaId: formValues.companyAreaId.value,
      activityId: formValues.activityId.value,
      order: formValues.orderDependent ? formValues.order : null,
    });

  const content = <ActivityTaskForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Tarea de Actividad"
      subtitle="Crear nueva tarea de actividad"
      content={content}
    />
  );
};

NewActivityTask.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostActivityTask: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostActivityTask: postActivityTask,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Tareas de Actividad',
      href: ACTIVITY_TASK_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewActivityTask);
