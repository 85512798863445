import { arrayOf, shape, string, number, bool } from 'prop-types';

const transactionList = arrayOf(
  shape({
    voucherType: shape({
      name: string,
    }),
  }),
);

const expenseBasicInformationPropTypes = shape({
  authorizerUser: shape({
    customer: shape({
      fullName: string,
    }),
  }),
  costCenter: shape({
    costCenterCode: string,
    description: string,
  }),
  documentSeries: string,
  documentCode: number,
  expenseStatus: string,
  isSettled: bool,
  payeeCustomer: shape({
    fullName: string,
  }),
  transactionList,
  voucherTime: number,
});

const expenseItemInformationPropTypes = shape({
  description: string,
  transactionList,
});

const expenseInternalInformationPropTypes = shape({
  id: number,
  createDate: number,
  lastUpdate: number,
});

export {
  expenseBasicInformationPropTypes,
  expenseItemInformationPropTypes,
  expenseInternalInformationPropTypes,
};
