import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WORKSTATION_PATH } from '../../../../config/paths';
import { postWorkstation } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import WorkstationForm from './WorkstationForm';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';

export class NewWorkstation extends Component {
  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    if (formValues.printStationList) {
      newFormValues.printStationList = formValues.printStationList.map(
        (printStation) => printStation,
      );
    }

    const { dispatchPostWorkstation } = this.props;

    dispatchPostWorkstation(newFormValues);
  };

  render() {
    const { loading } = this.props;

    const content = loading ? (
      <Loader />
    ) : (
      <WorkstationForm onSubmit={this.onSubmit} />
    );

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Caja"
        subtitle="Crear una caja"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Caja',
      href: WORKSTATION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  loading: !HumanResourcesUnit.Workstation.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPostWorkstation: postWorkstation,
};

NewWorkstation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostWorkstation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewWorkstation.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewWorkstation);
