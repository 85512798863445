import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { numberFormatter } from '../../../../../utils/number';
import detractionBasicInformationPropTypes from '../proptypes/DetractionPropTypes';

const DetractionBasicInformationResource = ({
  detractionRate,
  minDetractionAmount,
  country: { name: countryName },
  detractionCode,
  bankAccountNumber,
}) => (
  <>
    <ResourceProperty label="Porcentaje de Detracción:">
      {numberFormatter({
        value: detractionRate,
        style: 'percent',
      })}
    </ResourceProperty>
    <ResourceProperty label="Monto mínimo para la Detracción:">
      {minDetractionAmount}
    </ResourceProperty>
    <ResourceProperty label="Código de Detracción:">
      {numberFormatter({
        value: detractionCode,
        digits: 3,
      })}
    </ResourceProperty>
    <ResourceProperty label="País:">{countryName}</ResourceProperty>
    <ResourceProperty label="Número de cuenta:">
      {bankAccountNumber}
    </ResourceProperty>
  </>
);

DetractionBasicInformationResource.propTypes =
  detractionBasicInformationPropTypes;

export default DetractionBasicInformationResource;
