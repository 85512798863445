import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Alert, ListGroup, Button } from 'reactstrap';
import PrintedTicket from './PrintedTicket';
import {
  changeStep,
  clearItinerariesForBooking,
  clearSeatsForBooking,
} from '../../../../actions/index';
import { printPrintedTickets } from '../../../../utils/printers/Ticket';
import { STEPS } from '../../../../config/constants';
import { printPrintedReservations } from '../../../../utils/printers/ReservationVoucher';

const ITINERARY_SEARCH_INDEX = 0;

export class PrintedTickets extends Component {
  state = {
    printed: false,
  };

  handleRestartBookingProcess = () => {
    const {
      dispatchChangeStep,
      dispatchClearItinerariesForBooking,
      dispatchClearSeatsForBooking,
    } = this.props;

    dispatchClearItinerariesForBooking();
    dispatchClearSeatsForBooking();
    dispatchChangeStep(STEPS[ITINERARY_SEARCH_INDEX]);
  };

  handlePrintTickets = () => {
    const {
      user: { salesSessionUserId },
    } = this.props;

    if (salesSessionUserId) {
      printPrintedReservations(this.props.printedTickets.toJS());
    } else {
      printPrintedTickets(this.props.printedTickets.toJS());
    }
    this.setState({ printed: true });
  };

  render() {
    const printedTickets = this.props.printedTickets.toJS();
    const index = 0;
    let printedTicketComponents;
    const hasTickets = printedTickets.length;
    if (hasTickets) {
      printedTicketComponents = printedTickets.map((printedTicket) => (
        <PrintedTicket
          key={printedTicket.passenger.idDocumentNumber}
          printedTicket={printedTicket}
          index={index}
        />
      ));
    } else {
      printedTicketComponents = (
        <Alert color="danger">
          No hay boletos electrónicos listos para imprimir.
        </Alert>
      );
    }
    // show search button if there is no tickets or if there is and
    // they are printed
    const showNewSearchButton =
      !hasTickets || (hasTickets && this.state.printed);
    return (
      <Fragment>
        <ListGroup className="mb-3">{printedTicketComponents}</ListGroup>
        <div>
          <Button
            color="primary"
            className="float-left"
            disabled={!showNewSearchButton}
            onClick={this.handleRestartBookingProcess}
            size="lg"
          >
            <i className="fa fa-search" /> Nueva Búsqueda
          </Button>
        </div>
        <div>
          <Button
            color="primary"
            className="float-right"
            disabled={!hasTickets}
            onClick={this.handlePrintTickets}
            size="lg"
          >
            <i className="fa fa-print" /> Imprimir
          </Button>
        </div>
      </Fragment>
    );
  }
}

PrintedTickets.propTypes = {
  printedTickets: PropTypes.instanceOf(Immutable.Set).isRequired,
  dispatchChangeStep: PropTypes.func.isRequired,
  dispatchClearItinerariesForBooking: PropTypes.func.isRequired,
  dispatchClearSeatsForBooking: PropTypes.func.isRequired,
  user: PropTypes.shape({
    salesSessionUserId: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.get('user'),
});

const mapDispatchToProps = {
  dispatchChangeStep: changeStep,
  dispatchClearItinerariesForBooking: clearItinerariesForBooking,
  dispatchClearSeatsForBooking: clearSeatsForBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintedTickets);
