import {
  BUSINESS_INVOICE_ID,
  CONTRACT_TYPE,
  MAINTENANCE_FACTOR_TYPE,
} from './constants';

export const DEFAULT_ID_COUNTRY_OF_ORIGIN_OPTION = {
  label: 'Perú',
  value: 'PE',
};

export const DEFAULT_IDENTIFICATION_TYPE_ID_OPTION = {
  label: 'DNI',
  value: 1,
  identificationInCountry: 'DNI_LIBRETA_ELECTORAL',
};

export const DEFAULT_FOOD_OPTION = {
  label: 'Normal',
  value: 3,
};

export const DEFAULT_PAYMENT_METHOD_OPTION = {
  label: 'Efectivo',
  value: 1,
};

// Payment method for self service business
// TODO define payment method for production
export const SELF_SERVICE_PAYMENT_METHOD_OPTION = {
  label: 'Contrato',
  value: 9,
};

export const DEFAULT_VOUCHER_TYPE_OPTION = {
  label: 'Boleta',
  value: 1,
};

export const DEFAULT_CORPORATE_VOUCHER_TYPE_OPTION = {
  label: 'Factura',
  value: BUSINESS_INVOICE_ID,
};

export const DEFAULT_SELF_SERVICE_VOUCHER_TYPE_OPTION = {
  label: 'Constancia de Reserva',
  value: 1,
};

export const DEFAULT_CONTRACT_TYPE_OPTION = {
  value: CONTRACT_TYPE.DEFAULT.value,
  label: CONTRACT_TYPE.DEFAULT.label,
};

export const DEFAULT_COMPANY_OPTION = {
  value: 1,
  label: 'TURISMO CIVA SAC',
};

export const DEFAULT_ACTIVITY_FACTOR_TYPE_OPTION =
  MAINTENANCE_FACTOR_TYPE.KILOMETER;
