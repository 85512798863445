import React from 'react';
import { ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import PropTypes from 'prop-types';
import { PRINTED_TICKET_SHAPE } from '../../../../config/shapes';

const PrintedTicket = ({ printedTicket }) => {
  // todo: get the correct properties from the printedTicket object
  const { passenger } = printedTicket;
  return (
    <ListGroupItem className="PrintedTicket">
      <ListGroupItemHeading
        className="d-flex justify-content-between align-items-center"
        tag="div"
      >
        <h5>{passenger.fullName}</h5>
      </ListGroupItemHeading>
    </ListGroupItem>
  );
};

PrintedTicket.propTypes = {
  printedTicket: PropTypes.shape(PRINTED_TICKET_SHAPE).isRequired,
};

export default PrintedTicket;
