import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  FACTURADOR_PDF_ENDPOINT,
  MANUAL_GRR_ENDPOINT,
  SENDER_REMISSION_GUIDE_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  PDF_GET_CONFIG,
} from '../../config/rest';
import {
  FLAG_MANUAL_SENDER_REMISSION_GUIDE_ACTIVITY,
  CLEAR_MANUAL_SENDER_REMISSION_GUIDE,
  CLEAR_MANUAL_SENDER_REMISSION_GUIDES,
  FLAG_GETTING_MANUAL_SENDER_REMISSION_GUIDE,
  GET_MANUAL_SENDER_REMISSION_GUIDE,
  GET_MANUAL_SENDER_REMISSION_GUIDES,
} from '../types/cargo/ManualSenderRemissionGuide';
import { MANUAL_SENDER_REMISSION_GUIDE_PATH } from '../../config/paths';

const flagManualSenderRemissionGuideActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MANUAL_SENDER_REMISSION_GUIDE_ACTIVITY,
    payload: flag,
  });

const flagGettingManualSenderRemissionGuides = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MANUAL_SENDER_REMISSION_GUIDE,
    payload: flag,
  });

const clearManualSenderRemissionGuides = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANUAL_SENDER_REMISSION_GUIDES,
  });

const getManualSenderRemissionGuides =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingManualSenderRemissionGuides(true));
      const query = tableFilters;
      const url = `${SENDER_REMISSION_GUIDE_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const parcels = await response.json();
      dispatch({
        type: GET_MANUAL_SENDER_REMISSION_GUIDES,
        payload: parcels,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingManualSenderRemissionGuides(false));
    }
  };

const getManualSenderRemissionGuide =
  async ({ SenderRemissionGuideId }) =>
  async (dispatch) => {
    try {
      dispatch(flagManualSenderRemissionGuideActivity(true));
      const url = `${SENDER_REMISSION_GUIDE_ENDPOINT}/${SenderRemissionGuideId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcel = await response.json();
      dispatch({
        type: GET_MANUAL_SENDER_REMISSION_GUIDE,
        payload: parcel,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagManualSenderRemissionGuideActivity(false));
    }
  };

const postManualSenderRemissionGuide = async (payload) => async (dispatch) => {
  try {
    dispatch(flagManualSenderRemissionGuideActivity(true));
    const url = `${MANUAL_GRR_ENDPOINT}/manual`;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);

    const guide = await response.json();

    dispatch(push(`${MANUAL_SENDER_REMISSION_GUIDE_PATH}/${guide.id}`));
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagManualSenderRemissionGuideActivity(false));
  }
};

const clearManualSenderRemissionGuide = () => (dispatch) =>
  dispatch({
    type: CLEAR_MANUAL_SENDER_REMISSION_GUIDE,
  });

const getPdfManualSenderRemissionGuide = async (ticket) => async (dispatch) => {
  try {
    dispatch(flagManualSenderRemissionGuideActivity(true));
    const url = `${FACTURADOR_PDF_ENDPOINT}${ticket}`;
    const response = await fetch(url, {
      ...PDF_GET_CONFIG,
    });
    await isErrorResponse(response);

    const blob = await response.blob();
    const urlPdf = window.URL.createObjectURL(blob);
    window.open(urlPdf, '_blank');
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagManualSenderRemissionGuideActivity(false));
  }
};

export {
  flagManualSenderRemissionGuideActivity,
  flagGettingManualSenderRemissionGuides,
  clearManualSenderRemissionGuides,
  getManualSenderRemissionGuides,
  getManualSenderRemissionGuide,
  postManualSenderRemissionGuide,
  clearManualSenderRemissionGuide,
  getPdfManualSenderRemissionGuide,
};
