import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GEOGRAPHIC_ZONE_PATH } from '../../../../config/paths';

const EditGeographicZoneButton = ({ geographicZoneId }) => (
  <Link
    className="btn btn-primary"
    to={`${GEOGRAPHIC_ZONE_PATH}/${geographicZoneId}/edit`}
  >
    Editar
  </Link>
);

EditGeographicZoneButton.propTypes = {
  geographicZoneId: PropTypes.number.isRequired,
};

export default EditGeographicZoneButton;
