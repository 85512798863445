import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { Field, change } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateEmail } from '../../../../utils/validators';
import { postGenerateCip } from '../../../../actions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import { CIP_DOCUMENT_TYPE, CURRENCY_CODE } from '../../../../config/constants';
import Loader from '../../../common/Loader';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import { PAGOEFECTIVO_CIP_ENDPOINT } from '../../../../config/endpoints';

const PagoEfectivoFields = ({
  show,
  cipValues: {
    amount,
    currency,
    userName,
    userLastName,
    userEmail,
    userDocumentNumber,
    userDocumentType,
    userCodeCountry,
    userPhone,
    transactionCode,
  },
  dispatchPostGenerateCip,
  dispatchChange,
  form,
}) => {
  const [loadingCip, setLoadingCip] = useState(false);
  const [cipResponse, setCipResponse] = useState(false);

  const onClickGenerateCip = async () => {
    if (!userName) toastr.error('Error', 'Nombre es obligatorio.');
    if (!userLastName) toastr.error('Error', 'Apellido es obligatorio.');
    if (!userEmail) toastr.error('Error', 'Correo es obligatorio.');
    if (!userDocumentNumber)
      toastr.error('Error', 'Nro. de Documento es obligatorio.');
    if (!userCodeCountry)
      toastr.error('Error', 'Código de País es obligatorio.');
    if (!userPhone) toastr.error('Error', 'Celular es obligatorio.');

    if (
      userName &&
      userLastName &&
      userEmail &&
      userDocumentNumber &&
      userCodeCountry &&
      userPhone
    ) {
      setLoadingCip(true);

      const newCipResponse = await dispatchPostGenerateCip({
        amount,
        currency: currency.value,
        userName,
        userLastName,
        userEmail,
        userDocumentNumber,
        userDocumentType: userDocumentType.value,
        userCodeCountry,
        userPhone,
        transactionCode,
      });

      if (newCipResponse)
        dispatchChange(form, 'voucherCode', newCipResponse.cip);

      setLoadingCip(false);
      setCipResponse(newCipResponse);
    }
  };

  const onClickOpenCipWindow = () =>
    window.open(
      cipResponse.cipUrl,
      '_blank',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=600,height=600,left=100,top=100`,
    );

  if (!show) return null;

  if (loadingCip) return <Loader />;

  return (
    <>
      <h2>PagoEfectivo</h2>
      <hr />
      <p className="text-muted">
        Los siguientes datos son necesarios para la correcta generación del
        Código CIP
      </p>
      <FormGroup row>
        <FormItem label="Monto" required>
          <Row>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="amount"
                id="amount"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Monto"
                disabled
                validate={[isRequired]}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="currency"
                id="currency"
                component={Select}
                showToolTip
                options={enumToSelectOptions(CURRENCY_CODE)}
                placeholder="Moneda"
                isDisabled
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Pasajero" required>
          <Row>
            <Col md={6} sm={12} xs={12}>
              <Field
                name="userName"
                id="userName"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Nombre"
                validate={[isRequired]}
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <Field
                name="userLastName"
                id="userLastName"
                component={TextInput}
                showToolTip
                type="text"
                validate={[isRequired]}
                placeholder="Apellido"
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Correo" required>
          <Field
            name="userEmail"
            component={TextInput}
            type="text"
            validate={[isRequired, validateEmail]}
            placeholder="Correo"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Doc. de Identidad" required>
          <Row>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="userDocumentNumber"
                id="userDocumentNumber"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Nro. de Documento"
                validate={[isRequired]}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="userDocumentType"
                id="userDocumentType"
                component={Select}
                isClearable={false}
                showToolTip
                options={enumToSelectOptions(CIP_DOCUMENT_TYPE)}
                placeholder="Tipo de Documento"
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Celular" required>
          <Row>
            <Col lg={2} md={3} sm={4} xs={6}>
              <Field
                name="userCodeCountry"
                id="userCodeCountry"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Código de País"
                validate={[isRequired]}
              />
            </Col>
            <Col lg={4} md={5} sm={8} xs={6}>
              <Field
                name="userPhone"
                id="userPhone"
                component={TextInput}
                type="text"
                showToolTip
                placeholder="Celular"
                validate={[isRequired]}
              />
              <Field
                name="transactionCode"
                component={TextInput}
                type="hidden"
                placeholder=""
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem>
          <Button
            type="button"
            color="secondary"
            onClick={onClickGenerateCip}
            disabled={!!cipResponse}
          >
            <i className="fa fa-file-text-o" /> Generar CIP
          </Button>{' '}
          <Button
            type="button"
            color="secondary"
            onClick={onClickOpenCipWindow}
            disabled={!cipResponse}
          >
            <i className="fa fa-window-maximize" /> Mostrar ventana PagoEfectivo
          </Button>
        </FormItem>
      </FormGroup>
    </>
  );
};

PagoEfectivoFields.propTypes = {
  show: PropTypes.bool,
  dispatchPostGenerateCip: PropTypes.func.isRequired,
  cipValues: PropTypes.shape({
    amount: PropTypes.number,
    currency: optionPropTypes,
    userName: PropTypes.string,
    userLastName: PropTypes.string,
    userEmail: PropTypes.string,
    userDocumentNumber: PropTypes.string,
    userDocumentType: optionPropTypes,
    userCodeCountry: PropTypes.string,
    userPhone: PropTypes.string,
    transactionCode: PropTypes.number,
  }).isRequired,
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
};

PagoEfectivoFields.defaultProps = {
  show: false,
};

const mapStateToProps = (state, { selector }) => ({
  cipValues: {
    amount: selector(state, 'amount'),
    currency: selector(state, 'currency'),
    userName: selector(state, 'userName'),
    userLastName: selector(state, 'userLastName'),
    userEmail: selector(state, 'userEmail'),
    userDocumentNumber: selector(state, 'userDocumentNumber'),
    userDocumentType: selector(state, 'userDocumentType'),
    userCodeCountry: selector(state, 'userCodeCountry'),
    userPhone: selector(state, 'userPhone'),
    transactionCode: selector(state, 'transactionCode'),
  },
});

const mapDispatchToprops = {
  dispatchPostGenerateCip: postGenerateCip,
  dispatchChange: change,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToprops,
)(PagoEfectivoFields);

export default withEndpointAuthorization({
  url: PAGOEFECTIVO_CIP_ENDPOINT,
  permissionType: POST,
})(connectedComponent);
