import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteDeposit } from '../../../../actions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generateGetDepositEndpoint } from '../../../../config/endpoints';
import { DELETE } from '../../../../config/permissions';

class DepositDeleteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  onClickDelete = () => {
    const { dispatchDeleteDeposit, depositId } = this.props;
    dispatchDeleteDeposit({ depositId });
  };

  onClickShowModal = () => this.setState({ showModal: true });

  onClickCancel = () => this.setState({ showModal: false });

  render() {
    const { showModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showModal}
        onClickConfirm={this.onClickDelete}
        onClickCancel={this.onClickCancel}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.onClickShowModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DepositDeleteButton.propTypes = {
  depositId: PropTypes.number.isRequired,
  dispatchDeleteDeposit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteDeposit: deleteDeposit,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(DepositDeleteButton);

export default withEndpointAuthorization({
  url: generateGetDepositEndpoint(),
  mapUrlParamsToProps: { depositId: 'depositId' },
  permissionType: DELETE,
})(connectedComponent);
