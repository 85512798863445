import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  getTicketActionModal,
  postTicketRefund,
} from '../../../../../actions/index';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import {
  generateTicketRefund,
  TICKET_REFUND_MODAL_ENDPOINT,
} from '../../../../../config/endpoints';
import { POST } from '../../../../../config/permissions';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import { MODAL_ACTION_CONFIRM_MESSAGE } from '../../../../../config/messages';

const RefundButton = ({
  dispatchPostTicketRefund,
  ticketId,
  forThirdParty,
  dispatchGetTicketActionModal,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(MODAL_ACTION_CONFIRM_MESSAGE);
  const [url, setUrl] = useState(null);

  const handleShowModal = async () => {
    setLoading(true);

    const response = await dispatchGetTicketActionModal({
      ticketId,
      url: TICKET_REFUND_MODAL_ENDPOINT,
    });

    if (response && response.message && response.url) {
      setMessage(response.message);
      setUrl(response.url.replace('/ticket/{ticketId}', ''));
      setShowConfirmationModal(true);
    }

    setLoading(false);
  };

  const handleCloseModal = () => setShowConfirmationModal(false);

  const handleTicketRefund = () =>
    dispatchPostTicketRefund(url, ticketId, forThirdParty);

  return (
    <Fragment>
      <Button color="warning" onClick={handleShowModal}>
        <i className={`fa fa-${loading ? 'spinner fa-spin' : 'usd'}`} />{' '}
        Devolución
      </Button>
      <ConfirmationModal
        show={showConfirmationModal}
        title="Confirmación de Devolución"
        body={message}
        onClickConfirm={handleTicketRefund}
        onClickCancel={handleCloseModal}
        cancelButtonColor="warning"
      />
    </Fragment>
  );
};

RefundButton.propTypes = {
  dispatchGetTicketActionModal: PropTypes.func.isRequired,
  dispatchPostTicketRefund: PropTypes.func.isRequired,
  ticketId: PropTypes.number.isRequired,
  forThirdParty: PropTypes.bool,
};
RefundButton.defaultProps = {
  forThirdParty: false,
};

const mapDispatchToProps = {
  dispatchGetTicketActionModal: getTicketActionModal,
  dispatchPostTicketRefund: postTicketRefund,
};

const connectedComponent = connect(null, mapDispatchToProps)(RefundButton);

export default withEndpointAuthorization({
  url: generateTicketRefund(0),
  permissionType: POST,
})(connectedComponent);
