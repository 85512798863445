import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { numberFormatter } from '../../../../utils/number';

const CashBalance = ({ cashOnHand }) => (
  <>
    <h2>Balance en Efectivo</h2>
    <p>Éste es el balance en efectivo que la caja debería tener</p>
    <ResourceProperty label="Ingresos en Efectivo: ">
      {numberFormatter({ value: cashOnHand })}
    </ResourceProperty>
  </>
);

CashBalance.propTypes = {
  cashOnHand: PropTypes.number.isRequired,
};

export default CashBalance;
