import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getCountry, clearCountry } from '../../../../actions/location/Country';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import CountryToolbar from './CountryToolbar';
import { COUNTRY_PATH } from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import InternalResource from '../../../common/resource/InternalResource';

export class Country extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetCountry,
    } = this.props;
    dispatchGetCountry(id);
  }

  render() {
    const {
      country,
      loading,
      breadcrumbs,
      match: {
        params: { id },
      },
    } = this.props;

    let content;
    let toolbar;

    if (loading || country.isEmpty()) {
      content = <Loader />;
    } else if (country.isEmpty()) {
      content = <NoDataResource returnPage={COUNTRY_PATH} />;
    } else {
      const countryData = (
        <Fragment>
          <ResourceProperty label="País:">
            {country.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Código del país:">
            {country.get('countryCode')}
          </ResourceProperty>
        </Fragment>
      );

      const internalData = (
        <InternalResource
          id={country.get('id')}
          createDate={country.get('createDate')}
          lastUpdate={country.get('lastUpdate')}
        />
      );

      content = (
        <Fragment>
          {countryData}
          {internalData}
        </Fragment>
      );

      toolbar = <CountryToolbar countryId={+id} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="País"
        content={content}
      />
    );
  }
}

Country.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetCountry: PropTypes.func.isRequired,
  country: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
};

Country.defaultProps = {
  breadcrumbs: [],
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Países',
      href: COUNTRY_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  country: LocationUnit.Country.getIn(['current', 'content']),
  loading: LocationUnit.Country.getIn(['current', 'activity']),
});

const mapDispatchToProps = {
  dispatchGetCountry: getCountry,
  dispatchClearCountry: clearCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(Country);
