import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ITINERARY_SCHEDULE_PATH } from '../../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../../common/resource/proptypes/CommonPropTypes';
import ItineraryForm from './ItineraryScheduleForm';
import { INT_DATE_FORMAT, TIME_FORMAT } from '../../../../../config/locale';
import { ITINERARY_REPEAT_TYPE } from '../../../../../config/constants';
import { postItinerarySchedule } from '../../../../../actions';
import Content from '../../../../layout/Content';
import { tzNormalizeDate } from '../../../../../utils/date';

export class NewItinerarySchedule extends Component {
  onSubmit = (formValues) => {
    const startDateformatted = formValues.startDate
      ? tzNormalizeDate({ date: formValues.startDate, format: INT_DATE_FORMAT })
      : tzNormalizeDate({ format: INT_DATE_FORMAT });

    const departureTime = formValues.departureTime
      ? tzNormalizeDate({ date: formValues.departureTime, format: TIME_FORMAT })
      : tzNormalizeDate({ format: TIME_FORMAT });

    const startDate = tzNormalizeDate({
      date: `${startDateformatted} ${departureTime}`,
    });

    const endDateformatted = formValues.endDate
      ? tzNormalizeDate({ date: formValues.endDate, format: INT_DATE_FORMAT })
      : tzNormalizeDate({ format: INT_DATE_FORMAT });

    const endDate = tzNormalizeDate({
      date: `${endDateformatted} ${departureTime}`,
      addTime: { amount: 30, unit: 'seconds' },
    });

    let itinerarySchedulePricingProfiles = [];

    if (formValues.pricingProfileId) {
      itinerarySchedulePricingProfiles = formValues.pricingProfileId.map(
        (pricingProfile) => ({
          pricingProfileId: pricingProfile.value,
        }),
      );
    }

    const driverCommissionId = formValues.driverCommissionId
      ? formValues.driverCommissionId.value
      : null;

    const circuitId = formValues.circuitId ? formValues.circuitId.value : null;

    const serviceTypeId = formValues.serviceTypeId
      ? formValues.serviceTypeId.value
      : null;

    const cabinCrewCommissionId = formValues.cabinCrewCommissionId
      ? formValues.cabinCrewCommissionId.value
      : null;

    const createdFromApi = false;

    const { itineraryCode, name, description, isMaciva, isActive } = formValues;

    const newFormValues = {
      itineraryScheduleVersion: 1,
      name,
      description,
      itinerary: {
        routeId: formValues.routeId ? formValues.routeId.value : null,
        seatMapId: formValues.seatMapId ? formValues.seatMapId.value : null,
        type: formValues.type ? formValues.type.value : null,
        itineraryPricingProfiles: itinerarySchedulePricingProfiles,
        isMaciva,
        driverCommissionId,
        circuitId,
        serviceTypeId,
        createdFromApi,
        isActive,
        cabinCrewCommissionId,
        departureTime: startDate,
        departureDate: startDate,
      },
      repeatEvery: 1,
      repeatType: ITINERARY_REPEAT_TYPE.NO_REPEAT.value,
      itineraryCode,
      driverCommissionId,
      circuitId,
      serviceTypeId,
      createdFromApi,
      isMaciva,
      itinerarySchedulePricingProfiles,
      cabinCrewCommissionId,
      startDate,
      departureTime,
      endDate,
      companyId: formValues.companyId ? formValues.companyId.value : null,
    };

    if (!isMaciva) {
      newFormValues.repeatEvery = formValues.repeatEvery || 1;
      newFormValues.repeatType = formValues.repeatType.value;
      newFormValues.dailyRepeat = null;
      newFormValues.weeklyRepeat = null;
      newFormValues.monthlyRepeat = null;
      newFormValues.yearlyRepeat = null;
      newFormValues.noRepeat = null;
      newFormValues.itineraryScheduleSegmentList =
        formValues.itineraryScheduleSegmentList;

      if (formValues.repeatType.value === ITINERARY_REPEAT_TYPE.NO_REPEAT.value)
        newFormValues.endDate = startDate;

      if (
        formValues.repeatType.value === ITINERARY_REPEAT_TYPE.DAILY_REPEAT.value
      )
        newFormValues.dailyRepeat = {};

      if (
        formValues.repeatType.value ===
        ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.value
      )
        newFormValues.weeklyRepeat = {
          monday: formValues.monday,
          tuesday: formValues.tuesday,
          wednesday: formValues.wednesday,
          thursday: formValues.thursday,
          friday: formValues.friday,
          saturday: formValues.saturday,
          sunday: formValues.sunday,
        };

      if (
        formValues.repeatType.value ===
        ITINERARY_REPEAT_TYPE.MONTHLY_REPEAT.value
      )
        newFormValues.monthlyRepeat = {
          repeatByDayOfMonth: !!formValues.repeatByDayOfMonth,
        };
    }

    this.props.dispatchPostItinerarySchedule(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Plantilla de Itinerario"
        subtitle="Crear una nueva plantilla de itinerario"
        content={<ItineraryForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Plantillas de Itinerario',
      href: ITINERARY_SCHEDULE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostItinerarySchedule: postItinerarySchedule,
};

NewItinerarySchedule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostItinerarySchedule: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewItinerarySchedule);
