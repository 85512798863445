import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { generateGetItineraryQualityEndpoint } from '../../../../../config/endpoints';
import { PUT } from '../../../../../config/permissions';
import Checkbox from '../../../../common/forms/Checkbox';

const QualityControlButton = ({ itineraryId }) => (
  <Link
    className="btn btn-light border"
    to={`${ITINERARY_FOR_COUNTER_PATH}/${itineraryId}/quality-control`}
  >
    <Checkbox checked /> Control Calidad
  </Link>
);

QualityControlButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default withEndpointAuthorization({
  url: generateGetItineraryQualityEndpoint(),
  mapUrlParamsToProps: { itineraryId: 'itineraryId' },
  permissionType: PUT,
})(QualityControlButton);
