// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import {
  CLEAR_ERROR_POSTING_OCCUPANCY_REPORT,
  DOWNLOAD_OCCUPANCY_REPORT,
  FLAG_GETTING_OCCUPANCY_REPORT,
  FLAG_GETTING_OCCUPANCY_REPORTS,
  FLAG_POSTING_OCCUPANCY_REPORT,
  GET_OCCUPANCY_REPORT,
  GET_OCCUPANCY_REPORTS,
  POST_OCCUPANCY_REPORT,
  SHOW_ERROR_POSTING_OCCUPANCY_REPORT,
  CLEAR_ERROR_GETTING_OCCUPANCY_REPORT,
  SHOW_ERROR_GETTING_OCCUPANCY_REPORT,
  CLEAR_ERROR_GETTING_OCCUPANCY_REPORTS,
  SHOW_ERROR_GETTING_OCCUPANCY_REPORTS,
  CLEAR_OCCUPANCY_REPORTS,
  CLEAR_OCCUPANCY_REPORT,
} from '../types/index';
import { getReportsServerEndpoint } from './Report';
import { handleResponseError } from '../../utils/error-handlers';
import { OCCUPANCY_REPORTS_PATH } from '../../config/paths';
import { OCCUPANCY_REPORT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { validateReportDateRange } from '../../utils/validators';

const flagGettingOccupancyReports = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_OCCUPANCY_REPORTS,
});

const clearErrorGettingOccupancyReports = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_OCCUPANCY_REPORTS,
});

const showErrorGettingOccupancyReports = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_OCCUPANCY_REPORTS,
  payload: error,
});

const clearOccupancyReports = () => dispatch => dispatch({
  type: CLEAR_OCCUPANCY_REPORTS,
});

const getOccupancyReports = async ({
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
} = {}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingOccupancyReports());
  dispatch(flagGettingOccupancyReports(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    // make request
    const url = reportsServerEndpoint + OCCUPANCY_REPORT_ENDPOINT;
    const query = {
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingOccupancyReports(false));
      return dispatch(showErrorGettingOccupancyReports(serverError));
    }
    const reports = await response.json();
    dispatch({
      type: GET_OCCUPANCY_REPORTS,
      payload: reports,
    });
    return dispatch(flagGettingOccupancyReports(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingOccupancyReports(false));
    const errMsg = 'Error obteniendo los reportes de ocupabilidad.';
    return dispatch(showErrorGettingOccupancyReports(errMsg));
  }
};

const flagGettingOccupancyReport = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_OCCUPANCY_REPORT,
});

const clearErrorGettingOccupancyReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_OCCUPANCY_REPORT,
});

const showErrorGettingOccupancyReport = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_OCCUPANCY_REPORT,
  payload: error,
});

const getOccupancyReport = async ({
  id,
  reportFormat = 'json',
  download = false,
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingOccupancyReport());
  dispatch(flagGettingOccupancyReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    // make request
    const url = `${reportsServerEndpoint + OCCUPANCY_REPORT_ENDPOINT}/${id}`;
    const query = {
      reportFormat,
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingOccupancyReport(false));
      return dispatch(showErrorGettingOccupancyReport(serverError));
    }
    // parse json or text depending on format
    const text = reportFormat === 'csv';
    const report = text ? await response.text() : await response.json();
    if (download) {
      dispatch({
        type: DOWNLOAD_OCCUPANCY_REPORT,
        payload: report,
      });
    } else {
      dispatch({
        type: GET_OCCUPANCY_REPORT,
        payload: report,
      });
    }
    return dispatch(flagGettingOccupancyReport(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingOccupancyReport(false));
    const errMsg = `Error obteniendo el reporte de ocupabilidad #${id}.`;
    return dispatch(showErrorGettingOccupancyReport(errMsg));
  }
};

const clearErrorPostingOccupancyReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_POSTING_OCCUPANCY_REPORT,
});

const showErrorPostingOccupancyReport = error => dispatch => dispatch({
  type: SHOW_ERROR_POSTING_OCCUPANCY_REPORT,
  payload: error,
});

const flagPostingOccupancyReport = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_POSTING_OCCUPANCY_REPORT,
});

const postOccupancyReport = async ({
  fromDate,
  toDate,
  force,
}) => async (dispatch, getState) => {
  dispatch(clearErrorPostingOccupancyReport());

  // validate before raising the flag and outside the
  // try/catch so that the error is caught by redux form
  validateReportDateRange(fromDate, toDate);

  // raise flag
  dispatch(flagPostingOccupancyReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    const query = {
      force,
    };
    // make request
    const url = reportsServerEndpoint + OCCUPANCY_REPORT_ENDPOINT;
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ fromDate, toDate }),
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      return dispatch(showErrorPostingOccupancyReport(serverError));
    }
    const report = await response.json();
    dispatch({
      type: POST_OCCUPANCY_REPORT,
      payload: report,
    });
    dispatch(flagPostingOccupancyReport(false));
    // redirect to report
    return dispatch(push(`${OCCUPANCY_REPORTS_PATH}/${report.id}`));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagPostingOccupancyReport(false));
    const errMsg = 'Error en la creación del reporte de ocupabilidad.';
    return dispatch(showErrorPostingOccupancyReport(errMsg));
  }
};

const clearOccupancyReport = () => dispatch => dispatch({
  type: CLEAR_OCCUPANCY_REPORT,
});

export {
  getOccupancyReport,
  getOccupancyReports,
  postOccupancyReport,
  clearOccupancyReports,
  clearOccupancyReport,
  clearErrorPostingOccupancyReport,
  clearErrorGettingOccupancyReports,
};
