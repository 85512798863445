import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { change, reduxForm } from 'redux-form';
import Loader from '../../../../common/Loader';
import {
  optionPropTypes,
  optionsPropTypes,
} from '../../../../common/forms/select/SelectPropTypes';
import FormFooter from '../../../../common/forms/FormFooter';
import { generateEditMaintenanceServiceOrderStep1 } from '../../../../../config/paths';
import DynamicForm from '../../../../common/forms/DynamicForm';
import { generateMaintenanceServiceOrderListFormColumns } from '../../../../../config/dynamicFormFields';
import RegisterBusActivityForm from '../RegisterBusActivityForm';
import Modal from '../../../../common/modal/Modal';
import { postRegisterActivityAndBusActivity } from '../../../../../actions';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_FACTOR_TYPE,
} from '../../../../../config/constants';

export const AssignActivitiesToMaintenanceServiceOrderForm = ({
  handleSubmit,
  loadingActivityBus,
  maintenanceServiceOrderId,
  registeredBus,
  dispatchChange,
  dispatchPostRegisterActivityAndBusActivity,
  loading,
}) => {
  if (loading) return <Loader />;

  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const onClickNoOptionMessage = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
  };

  const hideModal = () => setShowModal(false);

  const onSubmitRegisterBusActivity = async (formValues) => {
    const activityBus = await dispatchPostRegisterActivityAndBusActivity({
      activityId: formValues.activityId && formValues.activityId.value,
      activityLabel: formValues.activityId && formValues.activityId.label,
      registeredBusId: registeredBus.value,
      activity: !formValues.activityId && {
        name: formValues.name,
        description: formValues.description,
        factor: formValues.factor,
        factorType: formValues.factorType && formValues.factorType.value,
        activityType: formValues.activityType && formValues.activityType.value,
        companyAreaId:
          formValues.companyAreaId && formValues.companyAreaId.values,
        companyActivityId: formValues.companyActivityId,
        materialList:
          formValues.materialList &&
          formValues.materialList.map(({ materialId, quantity }) => ({
            materialId: materialId.value,
            quantity: quantity || 1,
          })),
        activityTaskList:
          formValues.activityTaskList &&
          formValues.activityTaskList.map(
            ({ description, workingHours, companyAreaId, taskOrder }) => ({
              description,
              workingHours: +workingHours,
              companyAreaId: companyAreaId.value,
              taskOrder: +taskOrder,
              orderDependent: !!taskOrder,
            }),
          ),
      },
    });
    if (activityBus) {
      setShowModal(false);
      dispatchChange(
        'AssignActivitiesToMaintenanceServiceOrderForm',
        `activityBusExecutionList[${selectedIndex}].activityBusId`,
        activityBus,
      );
      setSelectedIndex(null);
    }
  };

  const noOptionsMessage = (index, value) => (
    <div>
      <p>
        La actividad ingresada no esta asociada al bus {registeredBus.label}.
      </p>
      <Button
        color="primary"
        type="button"
        onClick={() => onClickNoOptionMessage(index, value)}
      >
        <i className="fa fa-plus-circle" /> Asociar actividad
      </Button>
    </div>
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h4>Lista de Actividades</h4>
        <DynamicForm
          name="activityBusExecutionList"
          columns={generateMaintenanceServiceOrderListFormColumns(
            noOptionsMessage,
            registeredBus.value,
          )}
        />
        <FormFooter
          saveButtonIcon="fa fa-arrow-circle-right"
          saveButtonText="Continuar"
        >
          <Link
            className="btn btn-secondary"
            type="button"
            to={generateEditMaintenanceServiceOrderStep1(
              maintenanceServiceOrderId,
            )}
          >
            <i className="fa fa-arrow-circle-left" /> Anterior
          </Link>
        </FormFooter>
      </Form>
      <Modal
        show={showModal}
        onClickClose={hideModal}
        title={`Registrar actividad al bus ${registeredBus.label}`}
        body={
          loadingActivityBus ? (
            <Loader />
          ) : (
            <RegisterBusActivityForm
              onSubmit={onSubmitRegisterBusActivity}
              initialValues={{
                factorType: MAINTENANCE_FACTOR_TYPE.KILOMETER,
                activityType: MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE,
              }}
            />
          )
        }
        size="xl"
      />
    </>
  );
};

AssignActivitiesToMaintenanceServiceOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadingActivityBus: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    registeredBusId: optionsPropTypes,
  }),
  maintenanceServiceOrderId: PropTypes.number.isRequired,
  registeredBus: optionPropTypes.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  dispatchPostRegisterActivityAndBusActivity: PropTypes.func.isRequired,
};

AssignActivitiesToMaintenanceServiceOrderForm.defaultProps = {
  initialValues: null,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loadingActivityBus: !MechanicalMaintenanceUnit.BusActivityAssignment.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchPostRegisterActivityAndBusActivity:
    postRegisterActivityAndBusActivity,
};

const formComponent = reduxForm({
  form: 'AssignActivitiesToMaintenanceServiceOrderForm',
})(AssignActivitiesToMaintenanceServiceOrderForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
