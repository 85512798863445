import Immutable from 'immutable';
import {
  HOME_PATH,
  PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
  PROFILE_AUTHORIZED_BY_PROFILE_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'security-profiles',
      title: 'Perfiles de Seguridad',
      path: '/security/security-profiles',
      icon: 'user-secret',
      disabled: true,
    },
    {
      id: 'views',
      title: 'Vistas',
      path: '/security/views',
      icon: 'eye',
      disabled: true,
    },
    {
      id: 'endpoints',
      title: 'Puntos de Acceso',
      path: '/security/endpoints',
      icon: 'share-alt',
      disabled: true,
    },
    {
      id: 'api-clients',
      title: 'Clientes API',
      path: '/security/api-clients',
      icon: 'key',
      disabled: true,
    },
    {
      id: 'profiles-authorized-by-profile',
      title: 'Perfiles Autorizados por Perfil de Seguridad',
      path: PROFILE_AUTHORIZED_BY_PROFILE_PATH,
      icon: 'list-alt',
    },
    {
      id: 'payment-method-for-security-profile',
      title: 'Metodos de Pago por Perfil de Seguridad',
      path: PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
      icon: 'money',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Seguridad',
      tag: 'span',
      href: '/security',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
