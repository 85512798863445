import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { ITEM_CATEGORY_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  FLAG_GETTING_ITEM_CATEGORIES,
  GET_ITEM_CATEGORIES,
  CLEAR_ITEM_CATEGORIES,
} from '../types/baggage/ItemCategory';

const flagGettingItemCategories = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_ITEM_CATEGORIES,
    payload: flag,
  });

const getItemCategories = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingItemCategories(true));
    const query = tableFilters;

    const url = `${ITEM_CATEGORY_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const categories = await response.json();
    dispatch({
      type: GET_ITEM_CATEGORIES,
      payload: categories,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingItemCategories(false));
  }
};

const clearItemCategories = () => dispatch =>
  dispatch({
    type: CLEAR_ITEM_CATEGORIES,
  });

export { flagGettingItemCategories, getItemCategories, clearItemCategories };
