import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_CREW_STATUSES,
  GET_CREW_STATUSES,
  CLEAR_CREW_STATUSES,
  FLAG_CREW_STATUS_ACTIVITY,
  GET_CREW_STATUS,
  CLEAR_CREW_STATUS,
} from '../types/traffic';
import { CREW_STATUS_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingCrewStatuses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CREW_STATUSES,
    payload: flag,
  });

const getCrewStatuses = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCrewStatuses(true));
    const query = tableFilters;
    const url = `${CREW_STATUS_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const statuses = await response.json();
    dispatch({
      type: GET_CREW_STATUSES,
      payload: statuses,
    });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingCrewStatuses(false));
  }
};

const clearCrewStatuses = () => (dispatch) =>
  dispatch({
    type: CLEAR_CREW_STATUSES,
  });

const flagCrewStatusActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CREW_STATUS_ACTIVITY,
    payload: flag,
  });

const getCrewStatus =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagCrewStatusActivity(true));
      const url = `${CREW_STATUS_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response);
      const status = await response.json();
      dispatch({
        type: GET_CREW_STATUS,
        payload: status,
      });
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagCrewStatusActivity(false));
    }
  };

const clearCrewStatus = () => (dispatch) =>
  dispatch({
    type: CLEAR_CREW_STATUS,
  });

export {
  flagGettingCrewStatuses,
  getCrewStatuses,
  clearCrewStatuses,
  getCrewStatus,
  clearCrewStatus,
};
