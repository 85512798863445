import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import {
  getPostpaidBookings,
  clearPostpaidBookings,
} from '../../../actions/index';
import ModuleList from '../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../config/constants';
import { POSTPAID_BOOKINGS_COLUMNS } from '../../../config/columns';
import { POSTPAID_BOOKING_NEW_PATH } from '../../../config/paths';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

export class PostpaidBookings extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    postpaidBookings: PropTypes.instanceOf(Immutable.Map).isRequired,
    loading: PropTypes.bool.isRequired,
    dispatchGetPostpaidBookings: PropTypes.func.isRequired,
    dispatchClearPostpaidBookings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = POSTPAID_BOOKINGS_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearPostpaidBookings();
  }

  render() {
    const { postpaidBookings, loading, dispatchGetPostpaidBookings } =
      this.props;

    const data = postpaidBookings.get('content') || [];

    const pages = postpaidBookings.get('totalPages') || null;

    const defaultPageSize = postpaidBookings.get('size') || DEFAULT_PAGE_SIZE;

    const rowComponent = ({
      original: {
        customerOrder: { customerOrderReservationList },
      },
    }) => {
      let ticketListSelected = [];

      customerOrderReservationList.forEach(({ reservation }) => {
        if (reservation.ticketList.length > ticketListSelected.length)
          ticketListSelected = reservation.ticketList;
      });

      const content = ticketListSelected.map((ticket, index) => {
        const hasTicketCode = ticket.ticketCode && ticket.ticketCode.length;

        const seatColumn = ticket.seatReservation ? (
          <Col>
            Asiento
            <b> {ticket.seatReservation.seat.seatNumber} </b>
          </Col>
        ) : null;

        return (
          <Row key={+index.toString()}>
            <Col>{ticket.customer.fullName}</Col>
            <Col>
              {`${
                ticket.customer.identificationType
                  ? ticket.customer.identificationType.name
                  : null
              }
                  - ${ticket.customer.idDocumentNumber || null}`}{' '}
              ({ticket.customer.idCountryOfOrigin})
            </Col>
            {hasTicketCode && <Col>{ticket.ticketCode}</Col>}
            {seatColumn}
          </Row>
        );
      });

      return (
        <Container>
          <br />
          {content}
          <br />
        </Container>
      );
    };

    return (
      <ModuleList
        title="Reservas Pospago"
        breadcrumbs={this.props.breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetPostpaidBookings,
          loading,
          rowComponent,
        }}
        buttonPath={POSTPAID_BOOKING_NEW_PATH}
        buttonText="Nueva Reserva"
      />
    );
  }
}

const mapStateToProps = ({ PostpaidBookingUnit }) => ({
  postpaidBookings: PostpaidBookingUnit.PostpaidBooking.getIn([
    'all',
    'content',
  ]),
  loading: PostpaidBookingUnit.PostpaidBooking.getIn(['all', 'activity']),
  breadcrumbs: PostpaidBookingUnit.UnitHome.get('breadcrumbs'),
});

const mapDispatchToProps = {
  dispatchGetPostpaidBookings: getPostpaidBookings,
  dispatchClearPostpaidBookings: clearPostpaidBookings,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostpaidBookings);
