import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
  putMaintenanceServiceOrder,
} from '../../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../../layout/Content';
import MaintenanceServiceOrderForm from '../MaintenanceServiceOrderForm';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../../config/paths';
import {
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
} from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import Loader from '../../../../common/Loader';
import NoDataResource from '../../../../common/resource/NoDataResource';
import {
  maintenanceWarningGenerator,
  registeredBusGenerator,
} from '../../../../../utils/app/json-generator-from-reducer';
import Step from '../../../../common/Step';

const EditMaintenanceServiceOrder = ({
  dispatchPutMaintenanceServiceOrder,
  dispacthGetMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder,
  maintenanceServiceOrder,
  loading,
  match: {
    params: { id: maintenanceServiceOrderId },
  },
  breadcrumbs,
}) => {
  useLayoutEffect(() => {
    dispacthGetMaintenanceServiceOrder({ maintenanceServiceOrderId });

    return () => dispacthClearMaintenanceServiceOrder();
  }, []);

  const onSubmitToStepAssignActivity = (formValues) =>
    dispatchPutMaintenanceServiceOrder(
      maintenanceServiceOrderId,
      {
        maintenanceWarningList: formValues.maintenanceWarningList.map(
          (warning) => ({
            id: warning.maintenanceWarningId.value,
          }),
        ),
        registeredBusId: formValues.registeredBusId.value,
        deadline: tzNormalizeDate({ date: formValues.deadline }),
        priority: formValues.priority.value,
        description: formValues.description,
        status: formValues.status.value,
      },
      true,
    );

  const generateInitialValues = (maintenanceServiceOrderJson) => {
    const {
      priority,
      deadline,
      description,
      maintenanceWarningList,
      registeredBus,
      status,
    } = maintenanceServiceOrderJson;

    return {
      maintenanceWarningList: maintenanceWarningList.map((warning) => ({
        maintenanceWarningId: maintenanceWarningGenerator(warning),
      })),
      registeredBusId: registeredBus
        ? registeredBusGenerator(registeredBus)
        : null,
      deadline: deadline && tzNormalizeDate({ date: deadline }).slice(0, 16),
      description,
      priority: MAINTENANCE_SERVICE_ORDER_PRIORITY[priority],
      status: MAINTENANCE_SERVICE_ORDER_STATUS[status],
    };
  };

  let content;

  if (loading) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty())
    content = (
      <NoDataResource
        returnPage={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}`}
      />
    );
  else {
    content = (
      <>
        <Step
          stepElements={[
            { text: 'Actualizar orden de servicio', active: true },
            { text: 'Asociar actividad' },
            { text: 'Asociar empleados' },
          ]}
        />
        <MaintenanceServiceOrderForm
          onSubmit={onSubmitToStepAssignActivity}
          initialValues={generateInitialValues(maintenanceServiceOrder.toJS())}
          maintenanceServiceOrderId={+maintenanceServiceOrderId}
          editionMode
          stepMode
        />
      </>
    );
  }

  return <Content breadcrumbs={breadcrumbs} content={content} />;
};

EditMaintenanceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes,
  dispatchPutMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
};

EditMaintenanceServiceOrder.defaultProps = {
  match: null,
};

const mapDispatchToProps = {
  dispatchPutMaintenanceServiceOrder: putMaintenanceServiceOrder,
  dispacthGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceServiceOrder);
