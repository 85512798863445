import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import Select from '../../../common/forms/select/Select';
import { getLocationsForItinerarySearchOmission } from '../../../../actions/itinerary/LocationForItinerarySearchOmission';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

class ItinerarySearchOmissionForm extends Component {
  componentDidMount() {
    this.props.dispatchGetLocationsForItinerarySearchOmission();
  }

  render() {
    const { loading, locationForItinerarySearchOmissionOptions } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <FormGroup row>
          <FormItem label="Ubicación Origen: " required>
            <Field
              name="sourceLocationId"
              component={Select}
              type="text"
              placeholder="Ubicación Origen"
              options={locationForItinerarySearchOmissionOptions}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Ubicación Destino: " required>
            <Field
              name="destinationLocationId"
              component={Select}
              type="text"
              placeholder="Ubicación Destino"
              options={locationForItinerarySearchOmissionOptions}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

ItinerarySearchOmissionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetLocationsForItinerarySearchOmission: PropTypes.func.isRequired,
  locationForItinerarySearchOmissionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool,
};

ItinerarySearchOmissionForm.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ItineraryUnit }) => {
  const locationForItinerarySearchOmissionOptions =
    ItineraryUnit.LocationForItinerarySearchOmission.getIn(['all', 'content']);

  const options = [];
  locationForItinerarySearchOmissionOptions.forEach((location) => {
    options.push({
      value: location.id,
      label: `${location.id} - ${location.name}`,
    });
  });

  return {
    locationForItinerarySearchOmissionOptions: options,
    loading: !ItineraryUnit.ItinerarySearchOmission.getIn([
      'current',
      'activity',
    ]).isEmpty(),
  };
};

const mapDispatchToProps = {
  dispatchGetLocationsForItinerarySearchOmission:
    getLocationsForItinerarySearchOmission,
};

const formComponent = reduxForm({ form: 'ItinerarySearchOmissionForm' })(
  ItinerarySearchOmissionForm,
);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
