// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { handleResponseError } from '../../utils/error-handlers';
import {
  ITINERARY_REPORT_ENDPOINT,
} from '../../config/endpoints';
import {
  GET_ITINERARY_REPORTS,
  FLAG_GETTING_ITINERARY_REPORTS,
  CLEAR_ERROR_GETTING_ITINERARY_REPORTS,
  SHOW_ERROR_GETTING_ITINERARY_REPORTS,
  CLEAR_ITINERARY_REPORTS,
  CLEAR_ERROR_POSTING_ITINERARY_REPORT,
  POST_ITINERARY_REPORT,
  SHOW_ERROR_POSTING_ITINERARY_REPORT,
  FLAG_POSTING_ITINERARY_REPORT,
  DOWNLOAD_ITINERARY_REPORT,
  GET_ITINERARY_REPORT,
  SHOW_ERROR_GETTING_ITINERARY_REPORT,
  CLEAR_ERROR_GETTING_ITINERARY_REPORT,
  FLAG_GETTING_ITINERARY_REPORT,
  CLEAR_ITINERARY_REPORT,
} from '../types/index';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { getReportsServerEndpoint } from './Report';
import { ITINERARY_REPORTS_PATH } from '../../config/paths';
import { validateReportDateRange } from '../../utils/validators';

const flagGettingItineraryReports = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_ITINERARY_REPORTS,
});

const showErrorGettingItineraryReports = error => dispatch => dispatch({
  payload: error,
  type: SHOW_ERROR_GETTING_ITINERARY_REPORTS,
});

const clearErrorGettingItineraryReports = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_ITINERARY_REPORTS,
});

const getItineraryReports = async ({
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
} = {}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingItineraryReports());
  dispatch(flagGettingItineraryReports(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    // make request
    const url = reportsServerEndpoint + ITINERARY_REPORT_ENDPOINT;
    const query = {
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingItineraryReports(false));
      return dispatch(showErrorGettingItineraryReports(serverError));
    }
    const reports = await response.json();
    dispatch({
      type: GET_ITINERARY_REPORTS,
      payload: reports,
    });
    return dispatch(flagGettingItineraryReports(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingItineraryReports(false));
    const errMsg = 'Error obteniendo los reportes de itinerarios.';
    return dispatch(showErrorGettingItineraryReports(errMsg));
  }
};

const clearItineraryReports = () => dispatch => dispatch({
  type: CLEAR_ITINERARY_REPORTS,
});

const showErrorPostingItineraryReport = error => dispatch => dispatch({
  type: SHOW_ERROR_POSTING_ITINERARY_REPORT,
  payload: error,
});

const clearErrorPostingItineraryReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_POSTING_ITINERARY_REPORT,
});

const flagPostingItineraryReport = flag => dispatch => dispatch({
  type: FLAG_POSTING_ITINERARY_REPORT,
  payload: flag,
});

const postItineraryReport = async ({
  fromDate,
  toDate,
  bus,
  serviceType,
  driver,
  route,
  force,
}) => async (dispatch, getState) => {
  dispatch(clearErrorPostingItineraryReport());

  // validate before raising the flag and outside the
  // try/catch so that the error is caught by redux form
  validateReportDateRange(fromDate, toDate);

  // raise flag
  dispatch(flagPostingItineraryReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    const query = {
      force,
    };
    // make request
    const url = reportsServerEndpoint + ITINERARY_REPORT_ENDPOINT;
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          fromDate,
          toDate,
          bus,
          serviceType,
          driver,
          route,
        }),
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      return dispatch(showErrorPostingItineraryReport(serverError));
    }
    const report = await response.json();
    dispatch({
      type: POST_ITINERARY_REPORT,
      payload: report,
    });
    dispatch(flagPostingItineraryReport(false));
    // redirect to report
    return dispatch(push(`${ITINERARY_REPORTS_PATH}/${report.id}`));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagPostingItineraryReport(false));
    const errMsg = 'Error en la creación del reporte de itinerarios.';
    return dispatch(showErrorPostingItineraryReport(errMsg));
  }
};

const flagGettingItineraryReport = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_ITINERARY_REPORT,
});

const clearErrorGettingItineraryReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_ITINERARY_REPORT,
});

const showErrorGettingItineraryReport = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_ITINERARY_REPORT,
  payload: error,
});

const getItineraryReport = async ({
  id,
  reportFormat = 'json',
  download = false,
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingItineraryReport());
  dispatch(flagGettingItineraryReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    // make request
    const url = `${reportsServerEndpoint + ITINERARY_REPORT_ENDPOINT}/${id}`;
    const query = {
      reportFormat,
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingItineraryReport(false));
      return dispatch(showErrorGettingItineraryReport(serverError));
    }
    // parse json or text depending on format
    const text = reportFormat === 'csv';
    const report = text ? await response.text() : await response.json();
    if (download) {
      dispatch({
        type: DOWNLOAD_ITINERARY_REPORT,
        payload: report,
      });
    } else {
      dispatch({
        type: GET_ITINERARY_REPORT,
        payload: report,
      });
    }
    return dispatch(flagGettingItineraryReport(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingItineraryReport(false));
    const errMsg = `Error obteniendo el reporte de itinerarios #${id}.`;
    return dispatch(showErrorGettingItineraryReport(errMsg));
  }
};

const clearItineraryReport = () => dispatch => dispatch({
  type: CLEAR_ITINERARY_REPORT,
});

export {
  getItineraryReports,
  clearErrorGettingItineraryReports,
  clearItineraryReports,
  clearErrorPostingItineraryReport,
  postItineraryReport,
  getItineraryReport,
  clearItineraryReport,
  clearErrorGettingItineraryReport,
};
