import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SalesHome from './SalesHome';
import {
  SALES_SESSION_PATH,
  DEPOSIT_PATH,
  NEW_SALES_SESSION_PATH,
  EXPENSE_PATH,
  NEW_EXPENSE_PATH,
  PRICING_PROFILE_PATH,
  SALES_UNIT_PATH,
  NEW_PRICING_PROFILE_PATH,
  LIQUIDATION_SESSION_PATH,
  NEW_LIQUIDATION_SESSION_PATH,
  NEW_DEPOSIT_PATH,
  GENERAL_INCOME_PATH,
  NEW_GENERAL_INCOME_PATH,
  DISCOUNT_TO_BENEFICIARIES_PATH,
  EXPENSE_STATUS_PATH,
  generateExpenseStatusAddRemarkPath,
  CREDIT_NOTE_PATH,
  NEW_CREDIT_NOTE_PATH,
} from '../../../config/paths';
import SalesSessions from './salesSession/SalesSessions';
import SalesSession from './salesSession/SalesSession';
import NewSalesSession from './salesSession/NewSalesSession';
import Expenses from './expense/Expenses';
import Expense from './expense/Expense';
import NewExpense from './expense/NewExpense';
import EditExpense from './expense/EditExpense';
import PricingProfiles from './princing-profile/PricingProfiles';
import NewPricingProfile from './princing-profile/NewPricingProfile';
import PricingProfile from './princing-profile/PricingProfile';
import EditPricingProfile from './princing-profile/EditPricingProfile';
import LiquidationSessions from './liquidation-session/LiquidationSessions';
import LiquidationSession from './liquidation-session/LiquidationSession';
import NewLiquidationSession from './liquidation-session/NewLiquidationSession';
import EditLiquidationSession from './liquidation-session/EditLiquidationSession';
import Deposits from './deposit/Deposits';
import NewDeposit from './deposit/NewDeposit';
import Deposit from './deposit/Deposit';
import EditDeposit from './deposit/EditDeposit';
import GeneralIncomes from './general-income/GeneralIncomes';
import NewGeneralIncome from './general-income/NewGeneralIncome';
import GeneralIncome from './general-income/GeneralIncome';
import EditGeneralIncome from './general-income/EditGeneralIncome';
import DiscountToBeneficiary from './discount-to-beneficiary/DiscountToBeneficiary';
import ExpensesPerUser from './expense-status/ExpensesPerUser';
import ExpensePerUser from './expense-status/ExpensePerUser';
import ExpenseStatusAddRemarkForm from './expense-status/ExpenseStatusAddRemarkForm';
import CreditNotes from './credit-note/CreditNotes';
import NewCreditNote from './credit-note/NewCreditNote';
import CreditNote from './credit-note/CreditNote';
import EditCreditNote from './credit-note/EditCreditNote';

const SalesUnit = () => (
  <Switch>
    <Route exact path={SALES_UNIT_PATH} component={SalesHome} />
    <Route exact path={PRICING_PROFILE_PATH} component={PricingProfiles} />
    <Route
      exact
      path={NEW_PRICING_PROFILE_PATH}
      component={NewPricingProfile}
    />
    <Route
      exact
      path={`${PRICING_PROFILE_PATH}/:id`}
      component={PricingProfile}
    />
    <Route
      exact
      path={`${PRICING_PROFILE_PATH}/:id/edit`}
      component={EditPricingProfile}
    />
    <Route exact path={SALES_SESSION_PATH} component={SalesSessions} />
    <Route
      exact
      path={`${NEW_SALES_SESSION_PATH}`}
      component={NewSalesSession}
    />
    <Route exact path={`${SALES_SESSION_PATH}/:id`} component={SalesSession} />
    <Route exact path={EXPENSE_PATH} component={Expenses} />
    <Route exact path={`${NEW_EXPENSE_PATH}`} component={NewExpense} />
    <Route exact path={`${EXPENSE_PATH}/:id`} component={Expense} />
    <Route exact path={`${EXPENSE_PATH}/:id/edit`} component={EditExpense} />
    <Route
      exact
      path={LIQUIDATION_SESSION_PATH}
      component={LiquidationSessions}
    />
    <Route
      exact
      path={NEW_LIQUIDATION_SESSION_PATH}
      component={NewLiquidationSession}
    />
    <Route
      exact
      path={`${LIQUIDATION_SESSION_PATH}/:id`}
      component={LiquidationSession}
    />
    <Route
      exact
      path={`${LIQUIDATION_SESSION_PATH}/:id/edit`}
      component={EditLiquidationSession}
    />
    <Route exact path={DEPOSIT_PATH} component={Deposits} />
    <Route exact path={NEW_DEPOSIT_PATH} component={NewDeposit} />
    <Route exact path={`${DEPOSIT_PATH}/:id`} component={Deposit} />
    <Route exact path={`${DEPOSIT_PATH}/:id/edit`} component={EditDeposit} />
    <Route exact path={GENERAL_INCOME_PATH} component={GeneralIncomes} />
    <Route exact path={NEW_GENERAL_INCOME_PATH} component={NewGeneralIncome} />
    <Route
      exact
      path={`${GENERAL_INCOME_PATH}/:id`}
      component={GeneralIncome}
    />
    <Route
      exact
      path={`${GENERAL_INCOME_PATH}/:id/edit`}
      component={EditGeneralIncome}
    />
    <Route
      exact
      path={DISCOUNT_TO_BENEFICIARIES_PATH}
      component={DiscountToBeneficiary}
    />
    <Route exact path={EXPENSE_STATUS_PATH} component={ExpensesPerUser} />
    <Route
      exact
      path={`${EXPENSE_STATUS_PATH}/:id`}
      component={ExpensePerUser}
    />
    <Route
      exact
      path={generateExpenseStatusAddRemarkPath()}
      component={ExpenseStatusAddRemarkForm}
    />
    <Route exact path={CREDIT_NOTE_PATH} component={CreditNotes} />
    <Route exact path={NEW_CREDIT_NOTE_PATH} component={NewCreditNote} />
    <Route exact path={`${CREDIT_NOTE_PATH}/:id`} component={CreditNote} />
    <Route
      exact
      path={`${CREDIT_NOTE_PATH}/:id/edit`}
      component={EditCreditNote}
    />
  </Switch>
);

export default SalesUnit;
