import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ItinerarySearchForm from '../itinerary/ItinerarySearchForm';
import {
  clearItinerarySalesControl,
  getItinerarySalesControl,
} from '../../../../actions';
import { INT_DATE_FORMAT } from '../../../../config/locale';
import { DEFAULT_QUERY } from '../../../../config/queries';
import ItinerarySalesControlSearchResult from './ItinerarySalesControlSearchResult';
import { isLocalEnvironment } from '../../../../config/environments';
import { padStart } from '../../../../utils/string';
import { tzNormalizeDate } from '../../../../utils/date';

export class ItinerarySalesControlSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFormValues: {
        sourceLocationId: 1,
        destinationLocationId: 1,
        toDayString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
        fromDayString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
        ...DEFAULT_QUERY,
        size: 100,
      },
    };
  }

  onSubmit = (formValues) => {
    const newFormValues = {
      toDayString: tzNormalizeDate({
        date: formValues.toDateString,
        format: INT_DATE_FORMAT,
      }),
      fromDayString: tzNormalizeDate({
        date: formValues.fromDateString,
        format: INT_DATE_FORMAT,
      }),
      ...DEFAULT_QUERY,
      size: 100,
    };

    if (formValues.sourceLocationId)
      newFormValues.sourceLocationId = formValues.sourceLocationId.value;

    if (formValues.destinationLocationId)
      newFormValues.destinationLocationId =
        formValues.destinationLocationId.value;

    if (formValues.departureTimeString) {
      let utcTime = formValues.departureTimeString;

      if (!isLocalEnvironment()) {
        // Convert local time to utc time adding 5 hours
        const [localHour, minute] = utcTime.split(':');
        let utcHour = +localHour + 5;
        if (+localHour > 18) utcHour -= 24;
        utcHour = padStart(utcHour, 2);
        utcTime = `${utcHour}:${minute}`;
      }

      newFormValues.departureTimeHourMinuteStringUtc = utcTime;
    }

    this.setState({ searchFormValues: newFormValues });

    const { dispatchClearItinerarySalesControl } = this.props;

    dispatchClearItinerarySalesControl();
    this.fetchData(newFormValues);
  };

  fetchData = (query) => {
    this.setState({ searchFormValues: query });
    this.props.dispatchGetItinerarySalesControl(query);
  };

  render() {
    const date = tzNormalizeDate();

    const { searchFormValues } = this.state;

    return (
      <div>
        <ItinerarySearchForm
          onSubmit={this.onSubmit}
          initialValues={{
            departureDayString: date,
            fromDateString: tzNormalizeDate(),
            toDateString: tzNormalizeDate(),
          }}
          searchByDateRangeAndHour
          sourceLocationIsRequired={false}
          destinationLocationIsRequired={false}
        />
        <ItinerarySalesControlSearchResult
          searchFormValues={searchFormValues}
          fetchData={this.fetchData}
        />
      </div>
    );
  }
}

ItinerarySalesControlSearch.propTypes = {
  dispatchGetItinerarySalesControl: PropTypes.func.isRequired,
  dispatchClearItinerarySalesControl: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetItinerarySalesControl: getItinerarySalesControl,
  dispatchClearItinerarySalesControl: clearItinerarySalesControl,
};

export default connect(null, mapDispatchToProps)(ItinerarySalesControlSearch);
