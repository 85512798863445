import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_CUSTOMERS,
  GET_CUSTOMERS,
  CLEAR_CUSTOMERS,
  FLAG_GETTING_CUSTOMER_EMAILS,
  GET_CUSTOMER_EMAILS,
  CLEAR_CUSTOMER_EMAILS,
  FLAG_GETTING_CUSTOMER_DELIVERY_ADDRESSES,
  GET_CUSTOMER_DELIVERY_ADDRESSES,
  CLEAR_CUSTOMER_DELIVERY_ADDRESSES,
  FLAG_CUSTOMER_ACTIVITY,
  GET_CUSTOMER,
  CLEAR_CUSTOMER,
} from '../types';
import { DEFAULT_QUERY_GET_ALL } from '../../config/queries';
import {
  CUSTOMER_ENDPOINT,
  CUSTOMER_EMAILS_ENDPOINT,
  CUSTOMER_DELIVERY_ADDRESSES_ENDPOINT,
  MAINTENANCE_WARNING_REPORTER_ENDPOINT,
  CUSTOMER_ENDPOINT_V2,
} from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  handleResponseError,
  isErrorResponse,
} from '../../utils/error-handlers';
import { CUSTOMER_PATH } from '../../config/paths';

const flagGettingCustomers = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CUSTOMERS,
    payload: flag,
  });

const hasIso2Format = (countryCode) => {
  if (!parseInt(countryCode, 10) && countryCode.length === 2) {
    return true;
  }
  return false;
};

const flagCustomerActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CUSTOMER_ACTIVITY,
    payload: flag,
  });

const getCustomers =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCustomers(true));
      const query = tableFilters;
      const url = `${CUSTOMER_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const customers = await response.json();
      dispatch({ type: GET_CUSTOMERS, payload: customers });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingCustomers(false));
    }
  };

// TODO this function was used for get customers
const getCustomersDeprecated =
  async (tableFilters = null) =>
  async (dispatch) => {
    dispatch(flagGettingCustomers(true));
    try {
      const query = tableFilters;
      const url = `${CUSTOMER_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      const serverError = await handleResponseError(response);
      if (serverError) {
        toastr.error('Error', serverError);
        dispatch(flagGettingCustomers(false));
      }
      const customers = await response.json();
      // TODO: delete this duplicate filter once
      // customer data gets cleanned up
      const duplicateFreeCustomers = customers.content.filter(
        (currentCustomer, currentIndex, originalArray) => {
          let hasDuplicate = false;
          let duplicateHasIso2 = true;

          const duplicates = [];
          originalArray.forEach((originalArrayCustomer) => {
            if (
              currentCustomer.id !== originalArrayCustomer.id &&
              currentCustomer.idDocumentNumber ===
                originalArrayCustomer.idDocumentNumber &&
              currentCustomer.identificationType &&
              currentCustomer.identificationType.id ===
                originalArrayCustomer.identificationType &&
              originalArrayCustomer.identificationType.id
            ) {
              if (!hasDuplicate) {
                duplicates.push(currentCustomer);
              }
              duplicates.push(originalArrayCustomer);
              hasDuplicate = true;
              if (hasIso2Format(originalArrayCustomer.idCountryOfOrigin)) {
                duplicateHasIso2 = true;
              }
            }
          });
          if (hasDuplicate) {
            // if there is no duplicate with iso2
            // only keep the first one
            if (!duplicateHasIso2) {
              if (duplicates[0].id === currentCustomer.id) {
                return true;
              }
              return false;
            }
            // if there is return those that do have iso 2
            if (hasIso2Format(currentCustomer.idCountryOfOrigin)) {
              return true;
            }
            return false;
          }
          return true;
        },
      );
      customers.content = duplicateFreeCustomers;
      dispatch({
        type: GET_CUSTOMERS,
        payload: customers,
      });
    } catch ({ message }) {
      toastr.error('Error', `Error obteniendo clientes: ${message}`);
    } finally {
      dispatch(flagGettingCustomers(false));
    }
  };

const clearCustomers = () => (dispatch) =>
  dispatch({
    type: CLEAR_CUSTOMERS,
  });

const flagGettingCustomerEmails = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CUSTOMER_EMAILS,
    payload: flag,
  });

const getCustomerEmails =
  async ({ customerId = null }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCustomerEmails(true));
      const parameters = {
        ...DEFAULT_QUERY_GET_ALL,
        customerId,
      };
      const url = `${CUSTOMER_EMAILS_ENDPOINT}?${QueryString.stringify(
        parameters,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      // check if it is an error response
      await isErrorResponse(response);
      const customerEmails = await response.json();
      dispatch({
        type: GET_CUSTOMER_EMAILS,
        payload: customerEmails,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingCustomerEmails(false));
    }
  };

const clearCustomerEmails = () => (dispatch) =>
  dispatch({
    type: CLEAR_CUSTOMER_EMAILS,
  });

const flagGettingCustomerDeliveryAddresses = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CUSTOMER_DELIVERY_ADDRESSES,
    payload: flag,
  });

const getCustomer =
  async ({ customerId }, isDispatch = true) =>
  async (dispatch) => {
    try {
      dispatch(flagCustomerActivity(true));

      const url = `${CUSTOMER_ENDPOINT}/${customerId}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response);

      const customer = await response.json();

      if (isDispatch)
        dispatch({
          type: GET_CUSTOMER,
          payload: customer,
        });

      return customer;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagCustomerActivity(false));
    }
  };

const getCustomerDeliveryAddresses =
  async ({ customerId = null }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCustomerDeliveryAddresses(true));
      const parameters = {
        ...DEFAULT_QUERY_GET_ALL,
        customerId,
      };
      const url = `${CUSTOMER_DELIVERY_ADDRESSES_ENDPOINT}?${QueryString.stringify(
        parameters,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      // check if it is an error response
      await isErrorResponse(response);
      const customerDeliveryAddresses = await response.json();
      dispatch({
        type: GET_CUSTOMER_DELIVERY_ADDRESSES,
        payload: customerDeliveryAddresses,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingCustomerDeliveryAddresses(false));
    }
  };

const clearCustomerDeliveryAddresses = () => (dispatch) =>
  dispatch({
    type: CLEAR_CUSTOMER_DELIVERY_ADDRESSES,
  });

const postCustomerEmail = async ({ customerId, email }) => {
  try {
    const payload = {
      customerId,
      email,
    };
    const url = CUSTOMER_EMAILS_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    // check if it is a error response
    await isErrorResponse(response);
    return response.json();
  } catch ({ message }) {
    toastr.error('Error', message);
    return null;
  }
};

const postCustomerDeliveryAddress = async ({ customerId, deliveryAddress }) => {
  try {
    const payload = {
      customerId,
      deliveryAddress,
    };
    const url = CUSTOMER_DELIVERY_ADDRESSES_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    // check if it is a error response
    await isErrorResponse(response);
    return response.json();
  } catch ({ message }) {
    toastr.error('Error', message);
    return null;
  }
};

const clearCustomer = () => (dispatch) =>
  dispatch({
    type: CLEAR_CUSTOMER,
  });

const createNewCustomer = async ({
  firstName,
  lastName,
  gender,
  addressId,
  idDocumentNumber,
  idCountryOfOrigin,
  identificationTypeId,
  mobilePhone,
  homePhone,
  workPhone,
  fax,
  dob,
  email,
  address,
}) => {
  const payload = {
    firstName,
    lastName,
    gender,
    addressId,
    idDocumentNumber,
    idCountryOfOrigin,
    identificationTypeId,
    mobilePhone,
    homePhone,
    workPhone,
    fax,
    dob,
    email,
    address,
  };
  const url = CUSTOMER_ENDPOINT;
  const response = await fetch(url, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(payload),
  });
  await isErrorResponse(response);
  const customer = await response.json();
  return customer;
};

const postCustomer =
  async (values, isDispatch = true) =>
  async (dispatch) => {
    try {
      dispatch(flagCustomerActivity(true));

      const customer = await createNewCustomer(values);

      if (isDispatch) dispatch(push(CUSTOMER_PATH));

      return customer;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagCustomerActivity(false));
    }
  };

const putCustomer =
  async (
    customerId,
    {
      firstName,
      lastName,
      gender,
      addressId,
      idDocumentNumber,
      idCountryOfOrigin,
      identificationTypeId,
      mobilePhone,
      homePhone,
      workPhone,
      fax,
      dob,
      email,
      address,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCustomerActivity(true));

      const payload = {
        firstName,
        lastName,
        gender,
        addressId,
        idDocumentNumber,
        idCountryOfOrigin,
        identificationTypeId,
        mobilePhone,
        homePhone,
        workPhone,
        fax,
        dob,
        email,
        id: customerId,
        address,
      };

      const url = `${CUSTOMER_ENDPOINT}/${customerId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);
      await response.json();

      dispatch(push(CUSTOMER_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCustomerActivity(false));
    }
  };

const patchCustomer =
  async (
    customerId,
    { gender, mobilePhone, homePhone, workPhone, fax, dob, email, address },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCustomerActivity(true));

      const payload = {
        gender,
        mobilePhone,
        homePhone,
        workPhone,
        fax,
        dob,
        email,
        address,
        id: customerId,
      };

      const url = `${CUSTOMER_ENDPOINT_V2}/${customerId}`;
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      const customer = await response.json();

      return customer;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagCustomerActivity(false));
    }
  };

const deleteCustomer =
  async ({ customerId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCustomerActivity(true));

      const url = `${CUSTOMER_ENDPOINT}/${customerId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response);

      await response.json();

      dispatch(push(CUSTOMER_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCustomerActivity(false));
    }
  };

const getMaintenanceWarningReporters =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingCustomers(true));
      const query = tableFilters;
      const url = `${MAINTENANCE_WARNING_REPORTER_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({ type: GET_CUSTOMERS, payload: response });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingCustomers(false));
    }
  };

export {
  getCustomers,
  clearCustomers,
  getCustomerEmails,
  clearCustomerEmails,
  getCustomerDeliveryAddresses,
  clearCustomerDeliveryAddresses,
  postCustomerEmail,
  postCustomerDeliveryAddress,
  postCustomer,
  clearCustomer,
  putCustomer,
  getCustomer,
  deleteCustomer,
  getCustomersDeprecated,
  getMaintenanceWarningReporters,
  patchCustomer,
  createNewCustomer,
};
