import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSalesSessionTickets, clearTickets } from '../../../../actions';
import Table from '../../../common/Table';
import { SALES_SESSION_TICKETS_COLUMNS } from '../../../../config/columns';
import { TICKET_PATH } from '../../../../config/paths';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

class TicketsTable extends Component {
  static propTypes = {
    salesSessionId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    dispatchGetSalesSessionTickets: PropTypes.func.isRequired,
    dispatchClearTickets: PropTypes.func.isRequired,
    salesSessionTickets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        documentSeries: PropTypes.string.isRequired,
        documentCode: PropTypes.number.isRequired,
        salePrice: PropTypes.number.isRequired,
      }),
    ),
  };

  static defaultProps = {
    salesSessionTickets: undefined,
  };

  constructor(props) {
    super(props);
    const { salesSessionId, dispatchGetSalesSessionTickets } = this.props;
    dispatchGetSalesSessionTickets({ salesSessionId });
  }

  componentWillUnmount() {
    const { dispatchClearTickets } = this.props;
    dispatchClearTickets();
  }

  render() {
    const { loading, salesSessionTickets } = this.props;

    return (
      <>
        <Table
          manual={false}
          columns={SALES_SESSION_TICKETS_COLUMNS}
          data={salesSessionTickets}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
          loading={loading}
          navigateToModelOnRowClick
          modelPath={TICKET_PATH}
          openPathInNewTab
        />
        <br />
      </>
    );
  }
}

const mapStateToProps = ({ ReservationUnit }) => {
  const salesSessionTickets = ReservationUnit.Ticket.getIn([
    'all',
    'content',
    'content',
  ]);
  const loading = ReservationUnit.Ticket.getIn(['all', 'loading']);
  return {
    loading,
    salesSessionTickets,
  };
};

const mapDispatchToProps = {
  dispatchGetSalesSessionTickets: getSalesSessionTickets,
  dispatchClearTickets: clearTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsTable);
