import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCircuits, clearCircuits } from '../../../actions/index';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../config/queries';

class CircuitFilter extends Component {
  static propTypes = {
    dispatchGetCircuits: PropTypes.func.isRequired,
    dispatchClearCircuits: PropTypes.func.isRequired,
    circuits: optionsPropTypes,
    onChangeFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    circuits: null,
  };

  componentDidMount() {
    this.props.dispatchGetCircuits({ ...DEFAULT_QUERY_GET_ALL });
  }

  componentWillUnmount() {
    this.props.dispatchClearCircuits();
  }

  render() {
    const { circuits, onChangeFilter } = this.props;
    return (
      <SelectFilter
        isMulti
        onChangeFilter={onChangeFilter}
        options={circuits}
      />
    );
  }
}

const mapStateToProps = ({ RouteUnit }) => {
  const circuits = RouteUnit.Circuit.getIn(['all', 'content', 'content']).map(
    (circuit) => ({
      value: circuit.id,
      label: circuit.name,
    }),
  );
  return {
    circuits,
  };
};

const mapDispatchToprops = {
  dispatchGetCircuits: getCircuits,
  dispatchClearCircuits: clearCircuits,
};

export default connect(mapStateToProps, mapDispatchToprops)(CircuitFilter);
