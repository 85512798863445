import React from 'react';
import Table from '../../../../common/Table';
import { numberFormatter } from '../../../../../utils/number';
import { parcelOtherIncomesSummaryPropTypes } from '../../salesSession/SalesSessionPropTypes';

const LiquidationParcelOtherIncomesResource = ({
  parcelOtherIncomesSummaryList,
}) => {
  if (!parcelOtherIncomesSummaryList.length) {
    return null;
  }
  return (
    <>
      <hr />
      <h2>Otros Ingresos de Cargo</h2>
      <Table
        columns={[
          {
            Header: 'Método de Pago',
            accessor: 'moneyFlowConcept',
            className: 'text-center',
          },
          {
            Header: 'Total',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={parcelOtherIncomesSummaryList}
        defaultPageSize={parcelOtherIncomesSummaryList.length}
        showPagination={false}
      />
    </>
  );
};

LiquidationParcelOtherIncomesResource.propTypes = {
  parcelOtherIncomesSummaryList: parcelOtherIncomesSummaryPropTypes.isRequired,
};

export default LiquidationParcelOtherIncomesResource;
