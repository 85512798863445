import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Input } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateIntegerNumber,
} from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import FileDetailResource from '../../../common/resource/FileDetailResource';
import {
  EXCEL_FILE_PATTERN,
  MAX_SIZE_ENABLED_TO_UPLOAD,
} from '../../../../config/constants';
import FormFooter from '../../../common/forms/FormFooter';

export class DiscountToBeneficiaryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      fileError: null,
    };
  }

  onChangeFile = (event) =>
    this.setState({ selectedFile: event.target.files[0] });

  handleSubmitDiscountToBeneficiary = (formValues) => {
    const newFormValues = formValues;

    const { selectedFile } = this.state;

    if (this.validateExcelFile()) {
      newFormValues.file = selectedFile;
      this.props.onSubmit(newFormValues);
    }
  };

  validateExcelFile = () => {
    const { selectedFile } = this.state;

    let valid = true;

    this.setState({ fileError: null });

    if (!selectedFile) {
      this.setState({
        fileError: 'Campo requerido',
      });
      valid = false;
    } else if (!selectedFile.name.match(EXCEL_FILE_PATTERN)) {
      this.setState({
        fileError: 'Sólo se permiten archivo Excel (.xls, .xlsx)',
      });
      valid = false;
    } else if (selectedFile.size > MAX_SIZE_ENABLED_TO_UPLOAD) {
      this.setState({
        fileError: 'Sólo se permiten archivos Excel de 2MB o menos',
      });
      valid = false;
    }

    return valid;
  };

  render() {
    const { loading, handleSubmit } = this.props;

    const { fileError, selectedFile } = this.state;

    if (loading) {
      return <Loader />;
    }

    const fileDetailResource = <FileDetailResource file={selectedFile} />;

    let formTextError = null;
    if (fileError) {
      formTextError = <small className="text-danger">{fileError}</small>;
    }

    return (
      <Form onSubmit={handleSubmit(this.handleSubmitDiscountToBeneficiary)}>
        <FormGroup row>
          <FormItem label="Lista de Beneficiarios" required>
            <Input
              name="excel"
              type="file"
              validate={[isRequired]}
              onChange={(e) => this.onChangeFile(e)}
            />
            {formTextError}
            {fileDetailResource}
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="ID Vale de Descuento" required>
            <Field
              name="discountCodeId"
              component={TextInput}
              type="text"
              placeholder="ID Vale de Descuento"
              validate={[isRequired, validateIntegerNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Empresa" required>
            <Field
              name="companyId"
              component={CompanySelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

DiscountToBeneficiaryForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ SalesUnit }) => ({
  loading: !SalesUnit.DiscountToBeneficiary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DiscountToChinalcoBeneficiaryForm',
})(DiscountToBeneficiaryForm);

export default connect(mapStateToProps)(formComponent);
