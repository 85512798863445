import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import CheckBillingsStateButton from './CheckBillingsStateButton';
import PrintCarrierRemissionGuideButton from './PrintCarrierRemissionGuideButton';

const BillingItineraryToolBar = ({ itineraryId, handleChecking }) => {
  let toolbar = null;

  toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        <CheckBillingsStateButton handleChecking={handleChecking} />{' '}
        <PrintCarrierRemissionGuideButton itineraryId={itineraryId} />
      </div>
    </ButtonToolbar>
  );

  return (
    <ButtonToolbar className="pull-right">
      <div>{toolbar}</div>
    </ButtonToolbar>
  );
};

BillingItineraryToolBar.propTypes = {
  itineraryId: PropTypes.string.isRequired,
  handleChecking: PropTypes.func.isRequired,
};

export default BillingItineraryToolBar;
