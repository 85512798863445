import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ORGANIZATION_CHART_PATH } from '../../../../config/paths';
import { postOrganizationChart } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import OrganizationChartForm from './OrganizationChartForm';
import { DEFAULT_COMPANY_OPTION } from '../../../../config/defaults';

const NewOrganizationChart = ({
  breadcrumbs,
  dispatchPostOrganizationChart,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.companyAreaTypeId = formValues.companyAreaTypeId.value;
    newFormValues.companyId = formValues.companyId.value;
    newFormValues.parentCompanyAreaId =
      formValues.parentCompanyAreaId && formValues.parentCompanyAreaId.value;
    dispatchPostOrganizationChart(newFormValues);
  };

  const content = (
    <OrganizationChartForm
      onSubmit={onSubmit}
      initialValues={{ companyId: DEFAULT_COMPANY_OPTION }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo registro de Organigrama"
      subtitle="Crear nuevo registro de organigrama"
      content={content}
    />
  );
};

NewOrganizationChart.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostOrganizationChart: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostOrganizationChart: postOrganizationChart,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Organigrama',
      href: ORGANIZATION_CHART_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewOrganizationChart);
