import PropTypes from 'prop-types';

export const COLLECTION_SHAPE = {
  content: [],
  totalPages: 0,
  size: 10,
  number: 0,
  first: true,
  last: true,
  numberOfElements: 0,
  totalElements: 0,
  sort: null,
};

export const FUEL_REPORT_PREVIEW_SHAPE = {
  header: {},
  itineraries: [],
};

// todo: update with real shape
export const PRINTED_TICKET_SHAPE = {
  passenger: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }).isRequired,
};

export const VIEW_SHAPE = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  route: PropTypes.string.isRequired,
  createDate: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

export const VIEW_AUTHORIZATION_SHAPE = {
  id: PropTypes.number.isRequired,
  securityProfileId: PropTypes.number.isRequired,
  viewId: PropTypes.number.isRequired,
  view: PropTypes.shape(VIEW_SHAPE),
};

export const COUNTRY_SHAPE = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  countryCode: PropTypes.string,
  lastUpdate: PropTypes.number.isRequired,
};

export const REGION_SHAPE = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  country: PropTypes.shape(COUNTRY_SHAPE),
  countryId: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

export const CITY_SHAPE = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  region: PropTypes.shape(REGION_SHAPE),
  regionId: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

export const ADDRESS_SHAPE = {
  id: PropTypes.number.isRequired,
  address: PropTypes.string,
  address2: PropTypes.string,
  district: PropTypes.string,
  city: PropTypes.shape(CITY_SHAPE),
  cityId: PropTypes.number.isRequired,
  postalCode: PropTypes.string,
  phone: PropTypes.string,
};

export const ENDPOINT_SHAPE = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  route: PropTypes.string.isRequired,
  permissionType: PropTypes.string.isRequired,
  lastUpdate: PropTypes.number.isRequired,
  createDate: PropTypes.number.isRequired,
};

export const AUTHORIZED_ENDPOINT_SHAPE = {
  id: PropTypes.number.isRequired,
  securityProfileId: PropTypes.number.isRequired,
  endpointId: PropTypes.number.isRequired,
  endpoint: PropTypes.shape(ENDPOINT_SHAPE),
};

export const SECURITY_PROFILE_SHAPE = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  securityVisibility: PropTypes.string,
  createDate: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
  viewAuthorizationList: PropTypes.arrayOf(
    PropTypes.shape(VIEW_AUTHORIZATION_SHAPE),
  ),
  authorizedEndpointList: PropTypes.arrayOf(
    PropTypes.shape(AUTHORIZED_ENDPOINT_SHAPE),
  ),
};

export const IDENTIFICATION_TYPE_SHAPE = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
};

export const CUSTOMER_SHAPE = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  addressId: PropTypes.number,
  address: PropTypes.shape(ADDRESS_SHAPE),
  idDocumentNumber: PropTypes.string,
  idCountryOfOrigin: PropTypes.string,
  gender: PropTypes.string,
  dob: PropTypes.string,
  identificationTypeId: PropTypes.number.isRequired,
  identificationType: PropTypes.shape(IDENTIFICATION_TYPE_SHAPE),
  mobilePhone: PropTypes.string,
  homePhone: PropTypes.string,
  workPhone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string.isRequired,
};

export const USER = {
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  newPassword: PropTypes.string,
  profilePictureId: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  verified: PropTypes.bool.isRequired,
  blocked: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  customerId: PropTypes.number.isRequired,
  isSelf: PropTypes.bool,
  createDate: PropTypes.number,
  lastUpdate: PropTypes.number,
  lastLogin: PropTypes.number,
  authorizingPassword: PropTypes.string,
  confirmNewPassword: PropTypes.string,
  customer: PropTypes.shape(CUSTOMER_SHAPE),
  securityProfileSet: PropTypes.arrayOf(
    PropTypes.shape(SECURITY_PROFILE_SHAPE),
  ),
  // todo: see if redundancy with security profile set is still needed
  // only added when fetching self
  viewAuthorizations: PropTypes.arrayOf(
    PropTypes.shape(VIEW_AUTHORIZATION_SHAPE),
  ),
};
