import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { LOCATION_PATH } from '../../../../config/paths';
import { getLocation, clearLocation } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import LocationToolbar from './LocationToolbar';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import AddressResource from '../../../common/resource/AddressResource';
import LocationBasicInformationResource from './resource/LocationBasicInformationResource';
import LocationAttributeInformationResource from './resource/LocationAttributeInformationResource';

class Location extends Component {
  componentDidMount() {
    const {
      dispatchGetLocation,
      match: {
        params: { id: locationId },
      },
    } = this.props;
    dispatchGetLocation({ locationId });
  }

  componentWillUnmount() {
    this.props.dispatchClearLocation();
  }

  render() {
    const { breadcrumbs, location, loading } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (location.isEmpty()) {
      content = <NoDataResource returnPage={LOCATION_PATH} />;
    } else {
      const locationJson = location.toJS();

      content = (
        <Fragment>
          <Row>
            <Col md={6}>
              <LocationBasicInformationResource {...locationJson} />
              <LocationAttributeInformationResource {...locationJson} />
            </Col>
            <Col md={6}>
              <h3>Dirección</h3>
              <AddressResource address={locationJson.address} />
            </Col>
          </Row>
        </Fragment>
      );

      toolbar = <LocationToolbar locationId={locationJson.id} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Ubicación"
        content={content}
      />
    );
  }
}

Location.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetLocation: PropTypes.func.isRequired,
  dispatchClearLocation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  location: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Location.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ubicaciones',
      href: LOCATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  location: LocationUnit.Location.getIn(['current', 'content']),
  loading: !LocationUnit.Location.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetLocation: getLocation,
  dispatchClearLocation: clearLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
