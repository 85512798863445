import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Loader from '../../../../common/Loader';
import NoteListResource from '../../../../common/resource/NoteListResource';
import {
  clearExpenseStatusRemark,
  getExpenseStatusRemark,
} from '../../../../../actions/sales/ExpenseStatus';

class ExpenseStatusRemarkResource extends Component {
  componentDidMount() {
    const { expenseId, dispatchGetExpenseStatusRemark } = this.props;
    dispatchGetExpenseStatusRemark(expenseId);
  }

  componentWillUnmount() {
    this.props.dispatchClearExpenseStatusRemark();
  }

  render() {
    const { loading, remarks } = this.props;

    if (loading) {
      return <Loader />;
    }

    const notes = remarks.toJSON().map((remark) => ({
      note: remark.remarks,
    }));

    return (
      <Fragment>
        <h3>Notas</h3>
        <NoteListResource values={notes} />
      </Fragment>
    );
  }
}

ExpenseStatusRemarkResource.propTypes = {
  expenseId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetExpenseStatusRemark: PropTypes.func.isRequired,
  dispatchClearExpenseStatusRemark: PropTypes.func.isRequired,
  remarks: PropTypes.instanceOf(Immutable.Set).isRequired,
};

const mapStateToProps = ({ SalesUnit }) => ({
  loading: !SalesUnit.ExpenseStatus.getIn(['remark', 'activity']).isEmpty(),
  remarks: SalesUnit.ExpenseStatus.getIn(['remark', 'content']),
});

const mapDispatchToProps = {
  dispatchGetExpenseStatusRemark: getExpenseStatusRemark,
  dispatchClearExpenseStatusRemark: clearExpenseStatusRemark,
};

const formComponent = reduxForm({
  form: 'ExpenseStatusRemarkResource',
})(ExpenseStatusRemarkResource);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
