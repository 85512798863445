import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ItinerarySearchForm from './ItinerarySearchForm';
import { searchForItineraries } from '../../../../actions';
import { clearBookingState } from '../../../../actions/booking';
import { BOOKING_SEARCH_STEP_INDEX } from '../../../../config/constants';

class ItinerarySearch extends Component {
  constructor(props) {
    super(props);
    this.props.clearBookingState(BOOKING_SEARCH_STEP_INDEX);
  }

  render() {
    return (
      <div>
        <ItinerarySearchForm onSubmit={this.props.searchForItineraries} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  searchForItineraries,
  clearBookingState,
};

ItinerarySearch.propTypes = {
  searchForItineraries: PropTypes.func.isRequired,
  clearBookingState: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ItinerarySearch);
