import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  NEW_FUEL_STATION_PATH,
  FUEL_STATION_PATH,
} from '../../../../config/paths';
import FuelStationForm from './FuelStationForm';
import { postFuelStation } from '../../../../actions/route/FuelStation';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';

class NewFuelStaion extends Component {
  onSubmit = (formValues) => {
    const values = {
      locationId: formValues.locationId.value,
      businessId: formValues.businessId.value,
    };
    this.props.dispatchPostFuelStation(values);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Grifo"
        subtitle="Crear un nuevo grifo"
        content={<FuelStationForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grifos',
      href: FUEL_STATION_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_FUEL_STATION_PATH,
    },
  ],
});

const mapDispatchToProps = { dispatchPostFuelStation: postFuelStation };

NewFuelStaion.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostFuelStation: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFuelStaion);
