import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  PROFILE_AUTHORIZED_BY_PROFILE_PATH,
  NEW_PROFILE_AUTHORIZED_BY_PROFILE_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { AUTHORIZED_PROFILES_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearAuthorizedProfiles,
  getAuthorizedProfiles,
} from '../../../../actions/security/AuthorizedProfilesByProfile';

export class AuthorizedProfilesByProfile extends Component {
  componentWillUnmount() {
    this.props.dispatchClearAuthorizedProfiles();
  }

  render() {
    const { users, loading, dispatchGetAuthorizedProfiles, breadcrumbs } =
      this.props;

    const data = users.get('content') || [];
    const pages = users.get('totalPages') || null;
    const defaultPageSize = users.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Perfiles Autorizados por Perfil de Seguridad"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_PROFILE_AUTHORIZED_BY_PROFILE_PATH}
        tableStructure={{
          columns: AUTHORIZED_PROFILES_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetAuthorizedProfiles,
          modelPath: PROFILE_AUTHORIZED_BY_PROFILE_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetAuthorizedProfiles: getAuthorizedProfiles,
  dispatchClearAuthorizedProfiles: clearAuthorizedProfiles,
};

const mapStateToProps = ({ SecurityUnit }) => ({
  breadcrumbs: [
    ...SecurityUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles Autorizados por Perfil de Seguridad',
      href: PROFILE_AUTHORIZED_BY_PROFILE_PATH,
    },
  ],
  users: SecurityUnit.AuthorizedProfile.getIn(['all', 'content']),
  loading: SecurityUnit.AuthorizedProfile.getIn(['all', 'loading']),
});

AuthorizedProfilesByProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  users: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetAuthorizedProfiles: PropTypes.func.isRequired,
  dispatchClearAuthorizedProfiles: PropTypes.func.isRequired,
};

AuthorizedProfilesByProfile.defaultProps = {
  users: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorizedProfilesByProfile);
