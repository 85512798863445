import { toastr } from 'react-redux-toastr';
import { DELAY_REASON_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  FLAG_GETTING_DELAY_REASONS,
  GET_DELAY_REASONS,
  CLEAR_DELAY_REASONS,
} from '../types/itinerary/DelayReason';

const flagGettingDelayReasons = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_DELAY_REASONS,
    payload: flag,
  });

const getDelayReasons = async () => async dispatch => {
  try {
    dispatch(flagGettingDelayReasons(true));
    const url = DELAY_REASON_ENDPOINT;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const delayReason = await response.json();
    dispatch({
      type: GET_DELAY_REASONS,
      payload: delayReason,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingDelayReasons(false));
  }
};

const clearDelayReasons = () => dispatch =>
  dispatch({
    type: CLEAR_DELAY_REASONS,
  });

export { flagGettingDelayReasons, getDelayReasons, clearDelayReasons };
