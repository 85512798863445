import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { USER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import UserForm from './UserForm';
import { postUser } from '../../../../actions';
import Content from '../../../layout/Content';

export class NewUser extends Component {
  onSubmit = (formValues) => {
    const userFormValues = { ...formValues };

    userFormValues.securityProfileSet = formValues.securityProfileSet.map(
      (profile) => ({
        id: profile.value,
      }),
    );
    userFormValues.customerId = formValues.customerId.value;
    userFormValues.locale = formValues.locale.value;

    const { dispatchPostUser } = this.props;
    dispatchPostUser(userFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Usuario"
        subtitle="Crear un nuevo usuario"
        content={
          <UserForm
            onSubmit={this.onSubmit}
            initialValues={{ enabled: true, verified: true, blocked: false }}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios',
      href: USER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostUser: postUser,
};

NewUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
