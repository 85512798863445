import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clearBaggageExcesses,
  getSalesSessionBaggageExcesses,
} from '../../../../actions';
import { SALES_SESSION_BAGGAGE_EXCESSES_COLUMNS } from '../../../../config/columns';
import Table from '../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

class ExcessBaggageTable extends Component {
  static propTypes = {
    salesSessionId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    dispatchGetSalesSessionBaggageExcesses: PropTypes.func.isRequired,
    dispatchClearBaggageExcesses: PropTypes.func.isRequired,
    salesSessionBaggageExcesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        documentSeries: PropTypes.string.isRequired,
        documentCode: PropTypes.number.isRequired,
        weight: PropTypes.number.isRequired,
        priceBeforeDiscount: PropTypes.number.isRequired,
        discountAmount: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        paymentMethod: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    salesSessionBaggageExcesses: [],
  };

  constructor(props) {
    super(props);
    const { salesSessionId, dispatchGetSalesSessionBaggageExcesses } =
      this.props;
    dispatchGetSalesSessionBaggageExcesses({ salesSessionId });
  }

  componentWillUnmount() {
    const { dispatchClearBaggageExcesses } = this.props;
    dispatchClearBaggageExcesses();
  }

  render() {
    const { salesSessionBaggageExcesses, loading } = this.props;

    return (
      <>
        <Table
          manual={false}
          columns={SALES_SESSION_BAGGAGE_EXCESSES_COLUMNS}
          data={salesSessionBaggageExcesses}
          loading={loading}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
          navigateToModelOnRowClick
          openPathInNewTab
        />
        <br />
      </>
    );
  }
}

const mapStateToProps = ({ BaggageUnit }) => ({
  loading: BaggageUnit.Baggage.getIn(['excess', 'loading']),
  salesSessionBaggageExcesses: BaggageUnit.Baggage.getIn([
    'excess',
    'content',
  ]).toJS(),
});

const mapDispatchToProps = {
  dispatchGetSalesSessionBaggageExcesses: getSalesSessionBaggageExcesses,
  dispatchClearBaggageExcesses: clearBaggageExcesses,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcessBaggageTable);
