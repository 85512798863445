import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  INTERNAL_TRANSFER_PATH,
  NEW_INTERNAL_TRANSFER_PATH,
} from '../../../../config/paths';
import {
  getInternalParcels,
  clearInternalParcels,
} from '../../../../actions/cargo/InternalParcel';
import { INTERNAL_PARCELS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class InternalParcels extends Component {
  constructor(props) {
    super(props);
    this.columns = INTERNAL_PARCELS_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearParcels();
  }

  render() {
    const { parcels, loading, breadcrumbs, dispatchGetParcels } = this.props;
    const data = parcels.get('content') || [];
    const pages = parcels.get('totalPages') || null;
    const defaultPageSize = parcels.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Translados Internos"
        buttonPath={NEW_INTERNAL_TRANSFER_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetParcels,
          loading,
          modelPath: INTERNAL_TRANSFER_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetParcels: getInternalParcels,
  dispatchClearParcels: clearInternalParcels,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Translados Internos',
      href: INTERNAL_TRANSFER_PATH,
    },
  ],
  parcels: CargoUnit.InternalTransfer.getIn(['all', 'content']),
  loading: CargoUnit.InternalTransfer.getIn(['all', 'loading']),
});

InternalParcels.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  parcels: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetParcels: PropTypes.func.isRequired,
  dispatchClearParcels: PropTypes.func.isRequired,
};

InternalParcels.defaultProps = {
  parcels: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalParcels);
