import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Container } from 'reactstrap';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import { generateInternalTransferItemColumns } from '../../../../config/dynamicFormFields';
import { getActiveSalesSession } from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import { INTERNAL_TRANSFER_PATH } from '../../../../config/paths';
import CargoLocationSelect from '../../../common/forms/select/CargoLocationSelect';
import FormFooter from '../../../common/forms/FormFooter';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';

export class InternalParcelForm extends Component {
  static validateFields(values) {
    const errors = {};

    if (!values.internalCargoItems || !values.internalCargoItems.length) {
      errors.internalCargoItemsError = 'Debe ingresar un item';
    }

    return errors;
  }

  constructor(props) {
    super(props);
    this.state = {
      sourceCityId: 0,
      destinationCityId: 0,
    };
  }

  componentDidMount() {
    const { dispatchGetActiveSalesSession } = this.props;
    dispatchGetActiveSalesSession({ source: INTERNAL_TRANSFER_PATH });
  }

  onClickRemove = (index, fields) => {
    fields.remove(index);
  };

  handleLocationChange = (location, name) => {
    let setState = null;
    if (name === 'sourceCityId') {
      setState = { sourceCityId: location.city ? location.city.id : 0 };
    }
    if (name === 'destinationCityId') {
      setState = { destinationCityId: location.city ? location.city.id : 0 };
    }
    this.setState(setState);
  };

  renderDynamicForm = () => {
    const { sourceCityId, destinationCityId } = this.state;
    if (sourceCityId === 0 || destinationCityId === 0) {
      return <Alert message="Seleccione Origen y Destino" />;
    }
    return (
      <DynamicForm
        name="internalCargoItems"
        columns={generateInternalTransferItemColumns(
          this.onQuantityInputChange,
          this.onCommonProductChange,
          this.onWeightChange,
        )}
        onClickRemove={this.onClickRemove}
      />
    );
  };

  render() {
    const {
      handleSubmit,
      loading,
      gettingActiveSalesSession,
      activeSalesSession,
    } = this.props;
    if (gettingActiveSalesSession) {
      return <Loader />;
    }
    if (loading) {
      return <Loader />;
    }
    if (activeSalesSession.isEmpty()) {
      return (
        <Container>
          <Alert message="No hay ninguna sesión de ventas activa." />
        </Container>
      );
    }

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <FormGroup row>
            <FormItem label="Origen" required>
              <Field
                name="sourceLocation"
                component={CargoLocationSelect}
                onChange={(option) =>
                  this.handleLocationChange(option, 'sourceCityId')
                }
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Destino" required>
              <Field
                name="destinationLocation"
                component={CargoLocationSelect}
                onChange={(option) =>
                  this.handleLocationChange(option, 'destinationCityId')
                }
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <CustomerInputGroup
            label="Remitente"
            labelRequired
            name="senderCustomer"
            form="InternalParcelForm"
            validate={[isRequired]}
            showDetails
          />
          <CustomerInputGroup
            label="Consignado"
            labelRequired
            name="consigneeCustomer"
            form="InternalParcelForm"
            validate={[isRequired]}
            showDetails
          />
          <FormGroup row>
            <FormItem label="Nota">
              <Field
                name="note"
                component={TextInput}
                type="textarea"
                placeholder="Nota"
              />
            </FormItem>
          </FormGroup>
          <h5>Detalle de Carga</h5>
          <Field
            name="internalCargoItemsError"
            component={TextInput}
            type="hidden"
          />
          {this.renderDynamicForm()}
          <FormFooter />
        </Form>
      </>
    );
  }
}

InternalParcelForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
};

InternalParcelForm.defaultProps = {
  loading: false,
};

const mapStateToProps = (state) => ({
  loading: !state.CargoUnit.Cargo.getIn(['current', 'activity']).isEmpty(),
  gettingActiveSalesSession: state.SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: state.SalesUnit.SalesSession.getIn(['active', 'content']),
});

const mapDispatchToProps = {
  dispatchGetActiveSalesSession: getActiveSalesSession,
};

const formComponent = reduxForm({
  form: 'InternalParcelForm',
  validate: InternalParcelForm.validateFields,
})(InternalParcelForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
