import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Table } from '../../../../common/Table';
import CollapsibleHeader from '../../../../layout/CollapsibleHeader';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { numberFormatter } from '../../../../../utils/number';
import { padStart } from '../../../../../utils/string';

const LiquidationCreditNoteResource = ({
  generatedCreditNoteSet,
  creditNoteSet,
}) => {
  if (!generatedCreditNoteSet.length && !creditNoteSet.length) return null;

  let creditNoteList = null;

  if (generatedCreditNoteSet.length)
    creditNoteList = (
      <Col>
        <h5>Generadas</h5>
        <Table
          columns={[
            {
              Header: 'Serie',
              accessor: 'documentSeries',
              className: 'text-center',
            },
            {
              Header: 'Correlativo',
              accessor: 'documentCode',
              Cell: ({ value }) => padStart(value, 7),
              className: 'text-center',
            },
            {
              Header: 'PIN',
              accessor: 'reference',
              className: 'text-center',
            },
            {
              Header: 'Monto',
              accessor: 'amount',
              Cell: ({ value }) => numberFormatter({ value }),
              className: 'text-center',
            },
          ]}
          manual={false}
          data={generatedCreditNoteSet}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
        />
        <br />
      </Col>
    );

  let usedCreditNoteList = null;

  if (creditNoteSet.length)
    usedCreditNoteList = (
      <Col>
        <h5>Usadas</h5>
        <Table
          columns={[
            {
              Header: 'Serie',
              accessor: 'documentSeries',
              className: 'text-center',
            },
            {
              Header: 'Correlativo',
              accessor: 'documentCode',
              Cell: ({ value }) => padStart(value, 7),
              className: 'text-center',
            },
            {
              Header: 'PIN',
              accessor: 'reference',
              className: 'text-center',
            },
            {
              Header: 'Monto',
              accessor: 'amount',
              Cell: ({ value }) => numberFormatter({ value }),
              className: 'text-center',
            },
          ]}
          manual={false}
          data={creditNoteSet}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
        />
        <br />
      </Col>
    );

  const content = () => (
    <Row>
      {creditNoteList}
      {usedCreditNoteList}
    </Row>
  );

  return (
    <>
      <hr />
      <CollapsibleHeader headerText="Notas de Crédito" component={content} />
    </>
  );
};

LiquidationCreditNoteResource.propTypes = {
  creditNoteSet: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      amount: PropTypes.number,
    }),
  ),
  generatedCreditNoteSet: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      amount: PropTypes.number,
    }),
  ),
};

LiquidationCreditNoteResource.defaultProps = {
  creditNoteSet: [],
  generatedCreditNoteSet: [],
};

export default LiquidationCreditNoteResource;
