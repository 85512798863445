import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PayParcelForm from './PayParcelForm';
import Modal from '../../../common/modal/Modal';
import {
  getActiveSalesSession,
  getParcel,
  putParcelToPay,
} from '../../../../actions';
import { CARGO_PATH } from '../../../../config/paths';

const PayParcelButton = ({
  parcel,
  dispatchPutParcelPay,
  dispatchGetParcel,
  dispatchGetActiveSalesSession,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState('');

  const onModalSubmit = async ({ paymentMethod, voucherCode }) => {
    const isParcelPay = await dispatchPutParcelPay({
      parcelId: parcel.id,
      paymentMethodId: paymentMethod.value,
      voucherCode,
    });

    if (isParcelPay) {
      dispatchGetParcel({ parcelId: parcel.id });
      setModalBody(null);
    }
  };

  const openModal = async () => {
    await dispatchGetActiveSalesSession({
      source: `${CARGO_PATH}/${parcel.id}`,
    });
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const buildModalBody = () => {
    const newModalBody = (
      <PayParcelForm
        parcel={parcel}
        onCancel={closeModal}
        handleProcess={onModalSubmit}
      />
    );
    setModalBody(newModalBody);
  };

  useEffect(() => {
    if (showModal !== false) {
      buildModalBody();
    }
  }, [showModal]);

  return (
    <>
      <Modal
        show={showModal}
        title="Pagar Encomienda"
        body={modalBody}
        size="lg"
      />
      <Button color="info" onClick={openModal}>
        <i className="fa  fa-money" /> Pagar
      </Button>
    </>
  );
};

PayParcelButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  parcel: PropTypes.object.isRequired,
  dispatchPutParcelPay: PropTypes.func.isRequired,
  dispatchGetParcel: PropTypes.func.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPutParcelPay: putParcelToPay,
  dispatchGetParcel: getParcel,
  dispatchGetActiveSalesSession: getActiveSalesSession,
};

export default connect(null, mapDispatchToProps)(PayParcelButton);
