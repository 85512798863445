import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { getFuelStation } from '../../../../actions/route/FuelStation';
import { FUEL_STATION_PATH } from '../../../../config/paths';
import FuelStationToolbar from './FuelStationToolbar';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import AddressResource from '../../../common/resource/AddressResource';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

export class FuelStation extends Component {
  componentDidMount() {
    const {
      dispatchGetFuelStation,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetFuelStation({ id });
  }

  render() {
    const {
      fuelStation,
      activity,
      breadcrumbs,
      match: {
        params: { id: fuelStationId },
      },
    } = this.props;

    let content;
    let toolbar;

    if (activity) {
      content = <Loader />;
    } else if (fuelStation.isEmpty()) {
      content = <NoDataResource returnPage={FUEL_STATION_PATH} />;
    } else {
      const fuelStationData = (
        <Fragment>
          <ResourceProperty label="Nombre:">
            {fuelStation.get('location').name}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {fuelStation.get('location').description}
          </ResourceProperty>
        </Fragment>
      );

      let contactInformationData = null;

      if (fuelStation.get('location').address) {
        contactInformationData = (
          <ResourceProperty label="Direccón:">
            <AddressResource address={fuelStation.get('location').address} />
          </ResourceProperty>
        );
      }

      let business = null;

      business = (
        <Fragment>
          <ResourceProperty label="Empresa:">
            {fuelStation.get('business').name}
          </ResourceProperty>
        </Fragment>
      );

      const internalData = (
        <InternalResource
          id={fuelStation.get('id')}
          createDate={fuelStation.get('createDate')}
          lastUpdate={fuelStation.get('lastUpdate')}
        />
      );

      content = (
        <Fragment>
          {fuelStationData}
          {contactInformationData}
          {business}
          {internalData}
        </Fragment>
      );

      toolbar = <FuelStationToolbar fuelStationId={+fuelStationId} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Grifo"
        content={content}
      />
    );
  }
}

FuelStation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetFuelStation: PropTypes.func.isRequired,
  fuelStation: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  activity: PropTypes.bool.isRequired,
};

FuelStation.defaultProps = {
  breadcrumbs: [],
};

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grifos',
      href: FUEL_STATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  fuelStation: RouteUnit.FuelStation.getIn(['current', 'content']),
  activity: !RouteUnit.FuelStation.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetFuelStation: getFuelStation,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelStation);
