import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Form, FormGroup, Container } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import {
  clearExpenses,
  getActiveSalesSession,
  getAllExpenses,
} from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import Loader from '../../../common/Loader';
import { NEW_GENERAL_INCOME_PATH } from '../../../../config/paths';
import CustomerField from '../../../common/forms/fields/CustomerField';
import UserSelect from '../../../common/forms/select/UserSelect';
import GeneralIncomeTypeSelect from '../../../common/forms/select/GeneralIncomeTypeSelect';
import ExpenseSearchButton from '../../../common/button/ExpenseSearchButton';
import FormFooter from '../../../common/forms/FormFooter';

export class GeneralIncomeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableControls: false,
    };
  }

  componentDidMount() {
    this.props.dispatchGetActiveSalesSession({
      source: NEW_GENERAL_INCOME_PATH,
    });
  }

  componentDidUpdate() {
    this.onUpdate();
  }

  onUpdate = () => {
    const { initialValues, activeSalesSession } = this.props;

    const { disableControls } = this.state;

    if (
      initialValues &&
      !disableControls &&
      +initialValues.salesSessionId !== +activeSalesSession.get('id')
    )
      this.setState({ disableControls: true });
  };

  onSelectRow = (expense) => {
    const descriptionText = expense.description;
    const expenseTypeText = `de ${
      expense.transaction ? expense.transaction.expenseType.description : null
    }`;
    const documentText = `${expense.documentSeries}-${expense.documentCode}`;

    const description = `${descriptionText} ${expenseTypeText} ${documentText}`;

    const amount = expense.total;

    const { dispatchChange } = this.props;

    dispatchChange('GeneralIncomeForm', 'amount', amount);
    dispatchChange('GeneralIncomeForm', 'description', description);
  };

  render() {
    const {
      gettingActiveSalesSession,
      loading,
      activeSalesSession,
      handleSubmit,
    } = this.props;

    const { disableControls } = this.state;

    if (gettingActiveSalesSession) return <Loader />;

    if (loading) return <Loader />;

    if (activeSalesSession.isEmpty())
      return (
        <Container>
          <Alert message="No hay ninguna sesión de ventas activa." />
        </Container>
      );

    const documentFields = (
      <>
        <h3>Documento</h3>
        <FormGroup row>
          <FormItem label="Serie" required>
            <Field
              name="documentSeries"
              component={TextInput}
              type="text"
              placeholder="Serie"
              validate={[isRequired]}
              disabled={disableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Correlativo" required>
            <Field
              name="documentCode"
              component={TextInput}
              type="text"
              placeholder="Correlativo"
              validate={[isRequired, validateNumber]}
              disabled={disableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Pagador" required>
            <CustomerField
              fieldName="payerCustomerId"
              validate={[isRequired]}
              isDisabled={disableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Autorizador" required>
            <Field
              name="authorizerUserId"
              component={UserSelect}
              validate={[isRequired]}
              isDisabled={disableControls}
            />
          </FormItem>
        </FormGroup>
      </>
    );

    const itemFields = (
      <>
        <h3>Item</h3>
        <FormGroup row>
          <FormItem label="Tipo de Ingreso" required>
            <Field
              name="generalIncomeTypeId"
              component={GeneralIncomeTypeSelect}
              validate={[isRequired]}
              isDisabled={disableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
              disabled={disableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Total" required>
            <Field
              name="amount"
              component={TextInput}
              type="text"
              placeholder="0.00"
              validate={[isRequired, validateNumber]}
              disabled={disableControls}
            />
          </FormItem>
        </FormGroup>
      </>
    );

    return (
      <>
        <div className="text-center border-bottom pb-2 mb-3">
          <ExpenseSearchButton
            title="Completar con datos de Egreso"
            getRowData={this.onSelectRow}
            size="sm"
          />
        </div>
        <Form onSubmit={handleSubmit}>
          {documentFields}
          <hr />
          {itemFields}
          <FormFooter />
        </Form>
      </>
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  gettingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  loading: !SalesUnit.GeneralIncome.getIn(['current', 'activity']).isEmpty(),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  expenses: SalesUnit.Expense.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchGetAllExpenses: getAllExpenses,
  dispatchClearExpenses: clearExpenses,
  dispatchChange: change,
};

GeneralIncomeForm.propTypes = {
  dispatchChange: PropTypes.func.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ salesSessionId: PropTypes.number }),
};

GeneralIncomeForm.defaultProps = {
  initialValues: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'GeneralIncomeForm',
  })(GeneralIncomeForm),
);
