export const FLAG_GETTING_EXTRAORDINARY_MOVEMENT_CONTROLS =
  'FLAG_GETTING_EXTRAORDINARY_MOVEMENT_CONTROLS';
export const GET_EXTRAORDINARY_MOVEMENT_CONTROLS =
  'GET_EXTRAORDINARY_MOVEMENT_CONTROLS';
export const CLEAR_EXTRAORDINARY_MOVEMENT_CONTROLS =
  'CLEAR_EXTRAORDINARY_MOVEMENT_CONTROLS';
export const FLAG_EXTRAORDINARY_MOVEMENT_CONTROL_ACTIVITY =
  'FLAG_EXTRAORDINARY_MOVEMENT_CONTROL_ACTIVITY';
export const GET_EXTRAORDINARY_MOVEMENT_CONTROL =
  'GET_EXTRAORDINARY_MOVEMENT_CONTROL';
export const CLEAR_EXTRAORDINARY_MOVEMENT_CONTROL =
  'CLEAR_EXTRAORDINARY_MOVEMENT_CONTROL';
export const POST_EXTRAORDINARY_MOVEMENT_CONTROL =
  'POST_EXTRAORDINARY_MOVEMENT_CONTROL';
