import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  DRIVER_BUS_ASSIGNMENT_PATH,
  NEW_DRIVER_BUS_ASSIGNMENT_PATH,
} from '../../../../config/paths';
import {
  getDriverBusAssignments,
  clearDriverBusAssignments,
} from '../../../../actions/traffic/DriverBusAssignment';
import { DRIVER_BUS_ASSIGNMENT_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class DriverBusAssignments extends Component {
  componentWillUnmount() {
    this.props.dispatchClearDriverBusAssignments();
  }

  render() {
    const {
      driverAssignments,
      loading,
      breadcrumbs,
      dispatchGetDriverBusAssignments,
    } = this.props;
    const data = driverAssignments.get('content') || [];
    const pages = driverAssignments.get('totalPages') || null;
    const defaultPageSize = driverAssignments.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Asignaciones de Conductores"
        buttonPath={NEW_DRIVER_BUS_ASSIGNMENT_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: DRIVER_BUS_ASSIGNMENT_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetDriverBusAssignments,
          modelPath: DRIVER_BUS_ASSIGNMENT_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetDriverBusAssignments: getDriverBusAssignments,
  dispatchClearDriverBusAssignments: clearDriverBusAssignments,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignaciones de Conductores',
      href: DRIVER_BUS_ASSIGNMENT_PATH,
    },
  ],
  driverAssignments: TrafficUnit.DriverBusAssignment.getIn(['all', 'content']),
  loading: TrafficUnit.DriverBusAssignment.getIn(['all', 'loading']),
});

DriverBusAssignments.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  driverAssignments: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDriverBusAssignments: PropTypes.func.isRequired,
  dispatchClearDriverBusAssignments: PropTypes.func.isRequired,
};

DriverBusAssignments.defaultProps = {
  driverAssignments: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverBusAssignments);
