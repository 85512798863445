import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Container, Row, Col, Input, Button } from 'reactstrap';
import { passengersPropType } from './ReceiverPropTypes';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { postFixPriceRequestForPostpaidBooking } from '../../../../actions';
import { tzNormalizeDate } from '../../../../utils/date';
import { numberFormatter } from '../../../../utils/number';

const showNewTicketPricePrefix = 'showNewTicketPrice';
const newTicketPricePrefix = 'newTicketPrice';

let initialState;

class PassengerDetails extends Component {
  static propTypes = {
    dispatchPostFixPriceRequestForPostpaidBooking: PropTypes.func.isRequired,
    finalPrice: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const { passengers } = props;
    const stateObject = {};
    // create and initial component state
    // acoording to the number of ticket prices
    passengers.forEach(({ ticketId }) => {
      stateObject[`${showNewTicketPricePrefix}${ticketId}`] = false;
      stateObject[`${newTicketPricePrefix}${ticketId}`] = '';
    });
    // save initial state for resetting purposes
    initialState = stateObject;
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    // reset component state after prices are updated
    if (prevProps.passengers !== this.props.passengers) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState(initialState);
  };

  handleEditPriceClick = ({ target }) => {
    const showNewTicketPriceState = {};
    showNewTicketPriceState[`${showNewTicketPricePrefix}${target.id}`] = true;
    this.setState(showNewTicketPriceState);
  };

  handleNewPriceChange = (event) => {
    const {
      value,
      dataset: { ticketid },
    } = event.target;
    const newTicketPriceState = {};
    newTicketPriceState[`${newTicketPricePrefix}${ticketid}`] = value;
    this.setState(newTicketPriceState);
  };

  handleCancelPriceChange = (event) => {
    const {
      dataset: { ticketid },
    } = event.target;
    const ticketPriceState = {};
    ticketPriceState[`${showNewTicketPricePrefix}${ticketid}`] = false;
    ticketPriceState[`${newTicketPricePrefix}${ticketid}`] = '';
    this.setState(ticketPriceState);
  };

  applyNewPrices = () => {
    const fixedTicketPrices = [];

    Object.keys(this.state).forEach((key) => {
      const value = this.state[key];
      if (key.startsWith(newTicketPricePrefix) && value && value.length) {
        const ticketId = key.substring(newTicketPricePrefix.length);
        fixedTicketPrices.push({
          ticketId: parseInt(ticketId, 10),
          fixedPrice: parseFloat(value),
        });
      }
    });
    this.props.dispatchPostFixPriceRequestForPostpaidBooking({
      fixedTicketPrices,
    });
  };

  shouldShowNewPricesButton = () => {
    // return true if at least one ticket's price it's
    // being modified
    const showButton = Object.keys(this.state).some((key) => {
      if (
        key.startsWith(showNewTicketPricePrefix) &&
        this.state[key] === true
      ) {
        return true;
      }

      return false;
    });
    return showButton;
  };

  render() {
    const { passengers, finalPrice } = this.props;

    const showApplyNewPrices = this.shouldShowNewPricesButton();
    let applyNewPricesButton;
    if (showApplyNewPrices) {
      applyNewPricesButton = (
        <Button
          onClick={this.applyNewPrices}
          type="button"
          color="primary"
          outline
        >
          Aplicar Nuevos Precios
        </Button>
      );
    }
    return (
      <>
        <Row>
          <Col>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Pasajero</th>
                  <th>Datos del viaje</th>
                  <th>Asiento</th>
                  <th>Precio</th>
                  <th>Editar Precio</th>
                </tr>
              </thead>
              <tbody>
                {passengers.map(
                  ({
                    ticketId,
                    id,
                    fullName,
                    trip: { source, destination, departureTime },
                    seatNumber,
                    salePrice,
                    identificationType,
                    idDocumentNumber,
                  }) => {
                    const showInput =
                      this.state[`${showNewTicketPricePrefix}${ticketId}`];

                    let priceTd = (
                      <i
                        id={ticketId}
                        onClick={this.handleEditPriceClick}
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      />
                    );

                    if (showInput) {
                      priceTd = (
                        <Container>
                          <Row>
                            <Col xs={8} md={5}>
                              <Input
                                value={
                                  this.state[
                                    `${newTicketPricePrefix}${ticketId}`
                                  ]
                                }
                                onChange={this.handleNewPriceChange}
                                data-ticketid={ticketId}
                                placeholder="Nuevo precio"
                                type="text"
                              />
                            </Col>
                            <Col xs={4} md={2}>
                              <i
                                className="fa fa-times fa-2x"
                                aria-hidden="true"
                                data-ticketid={ticketId}
                                onClick={this.handleCancelPriceChange}
                              />
                            </Col>
                          </Row>
                        </Container>
                      );
                    }

                    return (
                      <tr key={id}>
                        <td>
                          {fullName}
                          <br />
                          <small>
                            {identificationType}: {idDocumentNumber}
                          </small>
                        </td>
                        <td>
                          {source}
                          <span className="mr-3 ml-3"> &rarr; </span>
                          {destination}
                          <br />
                          <small>
                            Salida:{' '}
                            {tzNormalizeDate({
                              date: departureTime,
                              format: DATE_TIME_FORMAT,
                            })}
                          </small>
                        </td>
                        <td>{seatNumber}</td>
                        <td>{numberFormatter({ value: salePrice })}</td>
                        <td>{priceTd}</td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="flex row-reverse">{applyNewPricesButton}</Col>
        </Row>
        <Row>
          <Col className="flex row-reverse">
            <h3>
              <b>{`Precio Total: ${numberFormatter({
                style: 'currency',
                value: finalPrice,
              })}`}</b>
            </h3>
          </Col>
        </Row>
      </>
    );
  }
}

PassengerDetails.propTypes = {
  passengers: passengersPropType.isRequired,
};

const mapDispatchToProps = {
  dispatchPostFixPriceRequestForPostpaidBooking:
    postFixPriceRequestForPostpaidBooking,
};

export default connect(null, mapDispatchToProps)(PassengerDetails);
