import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { BUSINESS_PATH } from '../../../../config/paths';
import {
  putBusiness,
  getBusiness,
  clearBusiness,
} from '../../../../actions/user';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import BusinessForm from './BusinessForm';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { addressGenerator } from '../../../../utils/app/json-generator-from-reducer';

const generateInitialValues = (businessJson) => ({
  ...businessJson,
  addressId: businessJson.address && addressGenerator(businessJson.address),
});

const EditBusiness = ({
  breadcrumbs,
  dispatchClearBusiness,
  dispatchPutBusiness,
  dispatchGetBusiness,
  loading,
  business,
  match: {
    params: { id: businessId },
  },
}) => {
  useLayoutEffect(() => {
    dispatchGetBusiness({ businessId });

    return () => dispatchClearBusiness();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.addressId =
      formValues.addressId && formValues.addressId.value;
    dispatchPutBusiness({ businessId, values: newFormValues });
  };

  let content;

  if (loading) content = <Loader />;
  else if (business.isEmpty())
    content = <NoDataResource returnPage={BUSINESS_PATH} />;
  else {
    content = (
      <BusinessForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(business.toJS())}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Empresa"
      subtitle="Editar empresa"
      content={content}
    />
  );
};

EditBusiness.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutBusiness: PropTypes.func.isRequired,
  dispatchClearBusiness: PropTypes.func.isRequired,
  dispatchGetBusiness: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  business: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditBusiness.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchPutBusiness: putBusiness,
  dispatchGetBusiness: getBusiness,
  dispatchClearBusiness: clearBusiness,
};

const mapStateToProps = (
  { UserUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas',
      href: BUSINESS_PATH,
    },
    {
      text: 'Ver',
      href: `${BUSINESS_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  business: UserUnit.Business.getIn(['current', 'content']),
  loading: !UserUnit.Business.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBusiness);
