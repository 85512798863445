import Immutable from 'immutable';
import { HOME_PATH } from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'maciva-itineraries',
      title: 'Itinerarios',
      path: '/maciva/itineraries',
      icon: 'calendar-o',
      disabled: true,
    },
    {
      id: 'maciva-sales',
      title: 'Ventas',
      path: '/maciva/sales',
      icon: 'ticket',
      disabled: true,
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de MACIVA',
      tag: 'span',
      href: '/maciva-management',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
