import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearCustomers, getCustomers } from '../../../actions';
import { DEFAULT_QUERY } from '../../../config/queries';

class CustomerFilter extends Component {
  componentWillUnmount() {
    this.props.dispatchClearCustomers();
  }

  handleInputChange = (inputValue) => {
    const { dispatchGetCustomers } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetCustomers({ ...DEFAULT_QUERY, query: inputValue });
    }
  };

  userFilterOption = (customers) => customers;

  render() {
    const {
      customers,
      onChangeFilter,
      isMulti,
      loading,
      isClearable,
      placeholder,
    } = this.props;
    return (
      <SelectFilter
        placeholder={placeholder}
        isMulti={isMulti}
        onChangeFilter={onChangeFilter}
        onInputChange={this.handleInputChange}
        options={customers}
        isLoading={loading}
        filterOption={this.userFilterOption}
        isClearable={isClearable}
      />
    );
  }
}

const mapStateToProps = ({ UserUnit }) => ({
  customers: UserUnit.Customer.getIn(['all', 'content', 'content']).map(
    (customer) => ({
      value: customer.id,
      label: `${customer.idDocumentNumber} (${customer.idCountryOfOrigin}) - ${customer.fullName}`,
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      fullName: customer.fullName,
      idDocumentNumber: customer.idDocumentNumber,
      gender: customer.gender,
      idCountryOfOrigin: customer.idCountryOfOrigin,
      identificationType: customer.identificationType,
      email: customer.email,
      mobilePhone: customer.mobilePhone,
      dob: customer.dob,
      discountCode: customer.discountCode,
    }),
  ),
  loading: UserUnit.Customer.getIn(['all', 'loading']),
});

CustomerFilter.propTypes = {
  dispatchGetCustomers: PropTypes.func.isRequired,
  dispatchClearCustomers: PropTypes.func.isRequired,
  customers: optionsPropTypes,
  placeholder: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  isClearable: PropTypes.bool,
};

CustomerFilter.defaultProps = {
  customers: [],
  isMulti: false,
  loading: false,
  isClearable: false,
  placeholder: 'Ingrese Nombre o DNI',
};

const mapDispatchToprops = {
  dispatchGetCustomers: getCustomers,
  dispatchClearCustomers: clearCustomers,
};

export default connect(mapStateToProps, mapDispatchToprops)(CustomerFilter);
