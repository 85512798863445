import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import Loader from '../../../common/Loader';
import { Table } from '../../../common/Table';
import {
  clearKidsOnItinerary,
  deleteKidsOnItinerary,
  getKidsOnItinerary,
} from '../../../../actions/itinerary/KidsOnItinerary';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import {
  isRequired,
  validateIntegerNumber,
} from '../../../../utils/validators';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import FormFooter from '../../../common/forms/FormFooter';
import { RELATIONSHIP } from '../../../../config/constants';
import { generateKidOnItineraryColumns } from '../../../../config/columns';
import { enumToSelectOptions } from '../../../../utils/enum';
import Select from '../../../common/forms/select/Select';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

export class KidsOnItineraryForm extends Component {
  componentWillUnmount() {
    this.props.dispatchClearKidsOnItinerary();
  }

  onClickRemoveKidOnItinerary = (id, itineraryId) => {
    this.props.dispatchDeleteKidsOnItinerary({ id }, itineraryId);
  };

  renderPassengerList = () => {
    const { kidsOnItineraryList } = this.props;

    const data = kidsOnItineraryList.get('content') || [];

    return (
      <div className="mt-5">
        <h5>Menores de Edad en Itinerario seleccionado</h5>
        <Table
          columns={generateKidOnItineraryColumns(
            this.onClickRemoveKidOnItinerary,
          )}
          data={data}
          manual={false}
          defaultFilterMethod={filterMethodCaseInsensitive}
          filterable
        />
      </div>
    );
  };

  render() {
    const { handleSubmit, loadingKidsOnItinerary, dispatchGetKidsOnItinerary } =
      this.props;

    if (loadingKidsOnItinerary) return <Loader />;

    const passengerList = this.renderPassengerList();

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <h5>Datos del Itinerario</h5>
              <FormGroup row>
                <FormItem label="Itinerario" required>
                  <Field
                    name="itineraryId"
                    component={ItinerarySearchInput}
                    handleSelectItinerary={({ itineraryId }) =>
                      dispatchGetKidsOnItinerary({ itineraryId })
                    }
                    validate={[isRequired]}
                    props={{
                      form: 'KidsOnItineraryForm',
                    }}
                  />
                </FormItem>
              </FormGroup>
              <FormGroup row>
                <FormItem label="Asiento" required>
                  <Field
                    name="seatNumber"
                    component={TextInput}
                    type="text"
                    placeholder="Asiento"
                    validate={[isRequired, validateIntegerNumber]}
                  />
                </FormItem>
              </FormGroup>
              <FormGroup row>
                <FormItem label="Piso" required>
                  <Field
                    name="floorNumber"
                    component={TextInput}
                    type="text"
                    placeholder="Piso"
                    validate={[isRequired, validateIntegerNumber]}
                  />
                </FormItem>
              </FormGroup>
            </Col>
            <Col md={6}>
              <h5>Datos de los Pasajeros</h5>
              <CustomerInputGroup
                label="Pasajero"
                labelRequired
                name="parentCustomerId"
                form="KidsOnItineraryForm"
                mobilePhoneRequired
                validate={[isRequired]}
                showDetails
              />
              <CustomerInputGroup
                label="Menor de Edad"
                labelRequired
                name="kidCustomerId"
                form="KidsOnItineraryForm"
                validate={[isRequired]}
                showDetails
              />
              <FormGroup row>
                <FormItem label="Relación con el Menor" required>
                  <Field
                    name="relationship"
                    component={Select}
                    options={enumToSelectOptions(RELATIONSHIP)}
                    validate={[isRequired]}
                  />
                </FormItem>
              </FormGroup>
              <FormGroup row>
                <FormItem label="Observaciones">
                  <Field
                    name="comments"
                    component={TextInput}
                    type="textarea"
                    placeholder="Observaciones"
                  />
                </FormItem>
              </FormGroup>
            </Col>
          </Row>

          <FormFooter />
        </Form>
        {passengerList}
      </>
    );
  }
}

KidsOnItineraryForm.propTypes = {
  loadingKidsOnItinerary: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetKidsOnItinerary: PropTypes.func.isRequired,
  dispatchClearKidsOnItinerary: PropTypes.func.isRequired,
  kidsOnItineraryList: PropTypes.instanceOf(Immutable.Map),
  dispatchDeleteKidsOnItinerary: PropTypes.func.isRequired,
};

KidsOnItineraryForm.defaultProps = {
  kidsOnItineraryList: null,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loadingKidsOnItinerary: !ItineraryUnit.KidsOnItinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  kidsOnItineraryList: ItineraryUnit.KidsOnItinerary.getIn([
    'current',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchGetKidsOnItinerary: getKidsOnItinerary,
  dispatchClearKidsOnItinerary: clearKidsOnItinerary,
  dispatchDeleteKidsOnItinerary: deleteKidsOnItinerary,
};

export default reduxForm({
  form: 'KidsOnItineraryForm',
})(connect(mapStateToProps, mapDispatchToProps)(KidsOnItineraryForm));
