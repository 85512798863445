import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActivities, clearActivities } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { isIntegerNumber } from '../../../../utils/validators';

export const ActivitySelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearActivities,
  dispatchGetActivities,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearActivities(), []);

  const onInputChangeActivity = (inputValue) => {
    const inputValueLength = inputValue.trim().length;
    const inputValueIsNumber =
      isIntegerNumber(inputValue.trim()) && inputValueLength;

    if (inputValueIsNumber)
      dispatchGetActivities({ query: [`companyActivityId:${inputValue}`] });
    else if (inputValueLength && inputValueLength >= 4)
      dispatchGetActivities({ query: [`name:${inputValue}`] });
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeActivity}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

ActivitySelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetActivities: PropTypes.func.isRequired,
  dispatchClearActivities: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
};

ActivitySelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por código o nombre de actividad',
  noOptionsMessage: undefined,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.Activity.getIn([
    'all',
    'content',
    'content',
  ]).map(({ companyActivityId, id, name }) => ({
    value: id,
    label: `${companyActivityId} - ${name}`,
  })),
  loading: MechanicalMaintenanceUnit.Activity.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetActivities: getActivities,
  dispatchClearActivities: clearActivities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySelect);
