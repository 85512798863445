import React, { useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Table from '../../../../common/Table';
import { COLOR } from '../../../../../config/constants';
import { LIVE_ITINERARY_SALES_CONTROL_COLUMNS } from '../../../../../config/columns';
import { clearLiveItinerarySalesControl } from '../../../../../actions';
import Loader from '../../../../common/Loader';
import ItinerarySalesControlGaugeChart from './ItinerarySalesControlGaugeChart';

export const LiveItinerarySalesControlSearchResult = ({
  loading,
  itineraries,
  searchFormValues,
  fetchData,
  dispatchClearLiveItinerarySalesControl,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearLiveItinerarySalesControl();
    },
    [],
  );
  const generateStyle = useCallback((row) => {
    let background = '';

    if (row) {
      const {
        original: { occupancy },
      } = row;

      const formattedOccupancy = +occupancy.replace(',', '.');

      if (formattedOccupancy >= 0 && formattedOccupancy < 35) {
        background = COLOR.danger;
      } else if (formattedOccupancy >= 35 && formattedOccupancy < 60) {
        background = COLOR.orange;
      } else if (formattedOccupancy >= 60 && formattedOccupancy < 75) {
        background = COLOR.warning;
      } else if (formattedOccupancy >= 75 && formattedOccupancy <= 100) {
        background = COLOR.success;
      }
    }

    return {
      background,
    };
  }, []);

  const itinerariesSalesControlJson = itineraries.toJSON();

  const data = itinerariesSalesControlJson.page.content || [];
  const pages = itinerariesSalesControlJson.page.totalPages || null;
  const defaultPageSize = itinerariesSalesControlJson.page.size || 100;

  let table =
    data.length > 0 ? (
      <>
        <ItinerarySalesControlGaugeChart
          offeredSeats={
            itinerariesSalesControlJson.itineraryOccupancyDto.totalSeat
          }
          soldSeats={
            itinerariesSalesControlJson.itineraryOccupancyDto.soldSeats
          }
          pendingSeats={
            itinerariesSalesControlJson.itineraryOccupancyDto.freeSeats
          }
          listOfServices={
            itinerariesSalesControlJson.itineraryOccupancyDto.listOfServices
          }
          generateStyle={generateStyle}
        />
        <Table
          columns={LIVE_ITINERARY_SALES_CONTROL_COLUMNS}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          fetchData={fetchData}
          params={searchFormValues}
          generateStyle={generateStyle}
        />
      </>
    ) : null;

  if (loading && !data.length) table = <Loader />;

  return (
    <div className="mt-3">
      <div className="flex">
        <h4>Resultado de Monitoreo de Ventas en Vivo</h4>
      </div>
      {table}
    </div>
  );
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: ItineraryUnit.ItinerarySalesControl.getIn(['live', 'loading']),
  itineraries: ItineraryUnit.ItinerarySalesControl.getIn(['live', 'content']),
});

const mapDispatchToProps = {
  dispatchClearLiveItinerarySalesControl: clearLiveItinerarySalesControl,
};

LiveItinerarySalesControlSearchResult.propTypes = {
  itineraries: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearLiveItinerarySalesControl: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    sourceLocationId: PropTypes.number,
    destinationLocationId: PropTypes.number,
    departureDayString: PropTypes.string,
    departureTimeString: PropTypes.string,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
};

LiveItinerarySalesControlSearchResult.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveItinerarySalesControlSearchResult);
