import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { changeStep } from '../../../actions';
import { STEPS } from '../../../config/constants';
import Badge from '../../common/Badge';

const CONFIRMATION_STEP_INDEX = 3;

class BookingNavbar extends Component {
  constructor(props) {
    super(props);
    this.renderSteps = this.renderSteps.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(step) {
    this.props.changeStep(step);
  }

  renderSteps() {
    return STEPS.map((step, index) => {
      // current step is highlighted
      if (index === this.props.currentStep)
        return (
          <Col key={step.path}>
            <Badge color="info" text={index + 1} />
            &nbsp;{step.title}
          </Col>
        );

      // future steps are disabled and not clickable
      if (
        index > this.props.currentStep ||
        this.props.currentStep === CONFIRMATION_STEP_INDEX
      )
        return (
          <Col key={step.path}>
            <Badge color="light" text={index + 1} />
            &nbsp;{step.title}
          </Col>
        );

      // past steps are clickable
      return (
        <Col
          key={step.path}
          onClick={() => {
            this.handleClick(step);
          }}
          className="clickable"
        >
          <Badge color="success" text={index + 1} />
          &nbsp;{step.title}
        </Col>
      );
    });
  }

  render() {
    return (
      <div className="p-2 text-center">
        <Row>{this.renderSteps()}</Row>
      </div>
    );
  }
}

BookingNavbar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default connect(null, { changeStep })(BookingNavbar);
