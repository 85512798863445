import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, InputGroupText, Label, Row } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import DynamicForm from '../../../common/forms/DynamicForm';
import { CONTRACT_ITINERARY_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import {
  CONTRACT_DOCUMENT_TYPE,
  CONTRACT_MOVEMENT_TYPE,
  CONTRACT_STATUS,
} from '../../../../config/constants';
import { CURRENCY } from '../../../../config/locale';
import PaymentMethodSelect from '../../../common/forms/select/PaymentMethodSelect';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import { numberFormatter } from '../../../../utils/number';
import FormFooter from '../../../common/forms/FormFooter';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';

const validateRoute = (values) => {
  const errors = {};

  const contractItineraryListErrorsArray = [];

  if (values.contractItineraryList)
    values.contractItineraryList.forEach((contractItinerary, index) => {
      const contractItineraryErrors = {};
      if (
        !contractItinerary.routeId &&
        contractItinerary.movementType &&
        contractItinerary.movementType.value ===
          CONTRACT_MOVEMENT_TYPE.REGULAR.value
      ) {
        contractItineraryErrors.routeId = 'Seleccione una ruta';
        contractItineraryListErrorsArray[index] = contractItineraryErrors;
      }
      if (
        !contractItinerary.registeredBusId &&
        contractItinerary.movementType &&
        contractItinerary.movementType.value ===
          CONTRACT_MOVEMENT_TYPE.REGULAR.value
      ) {
        contractItineraryErrors.registeredBusId = 'Seleccione un bus';
        contractItineraryListErrorsArray[index] = contractItineraryErrors;
      }
    });

  if (contractItineraryListErrorsArray.length)
    errors.contractItineraryList = contractItineraryListErrorsArray;

  if (!values.contractItineraryList || !values.contractItineraryList.length)
    errors.contractItineraryListElements = 'Debe ingresar un movimiento';

  return errors;
};

const PrivateServiceForm = ({
  initialValues,
  dispatchChange,
  loading,
  handleSubmit,
}) => {
  const [isPerson, setIsPerson] = useState(
    initialValues &&
      initialValues.contractPaymentType ===
        CONTRACT_DOCUMENT_TYPE.BOLETA_INTERNA.value,
  );
  const [authorizedDiscount, setAuthorizedDiscount] = useState(0);
  const [amountBeforeDiscount, setAmountBeforeDiscount] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const onDidMount = () => {
    if (
      initialValues &&
      (initialValues.contractStatus.value === CONTRACT_STATUS.DONE.value ||
        initialValues.contractStatus.value === CONTRACT_STATUS.PAID.value)
    )
      setDisabled(true);
  };

  useLayoutEffect(() => onDidMount(), []);

  const calculateTotalAmount = ({ price = 0, discount = 0 }) => {
    const authorizedDiscountAmount = price * (discount / 100);
    const totalAmount = price - authorizedDiscountAmount;
    dispatchChange(
      'PrivateServiceForm',
      'totalAmount',
      numberFormatter({ value: totalAmount > 0 ? totalAmount : 0 }),
    );
  };

  const onChangeContractPaymentType = (option) => {
    dispatchChange('PrivateServiceForm', 'businessId', null);
    dispatchChange('PrivateServiceForm', 'customerId', null);
    setIsPerson(option.value === CONTRACT_DOCUMENT_TYPE.BOLETA_INTERNA.value);
  };

  const onChangeAuthorizedDiscount = ({ target: { value } }) => {
    setAuthorizedDiscount(value);
    calculateTotalAmount({
      price: amountBeforeDiscount,
      discount: value,
    });
  };

  const onChangeAmountBeforeDiscount = ({ target: { value } }) => {
    setAmountBeforeDiscount(value);
    calculateTotalAmount({
      price: value,
      discount: authorizedDiscount,
    });
  };

  if (loading) return <Loader />;

  let customerField = (
    <BusinessInputGroup
      label="Empresa"
      labelRequired
      name="businessId"
      form="PrivateServiceForm"
      validate={[isRequired]}
      disabled={disabled}
      showDetails
    />
  );

  if (isPerson)
    customerField = (
      <>
        <CustomerInputGroup
          label="Cliente"
          labelRequired
          name="customerId"
          form="PrivateServiceForm"
          validate={[isRequired]}
          disabled={disabled}
          showDetails
        />
        <FormGroup row>
          <FormItem label="Dirección" required>
            <Field
              name="clientAddress"
              id="clientAddress"
              component={TextInput}
              showToolTip
              type="textarea"
              placeholder="Dirección *"
              disabled={disabled}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      </>
    );

  const contractorDataFields = (
    <>
      <h3>Datos del Contratante</h3>
      {customerField}
    </>
  );

  const serviceDataFields = (
    <>
      <h3>Datos del Servicio</h3>
      <FormGroup row>
        <FormItem label="Número de Contrato" required>
          <Field
            name="contractNumber"
            component={TextInput}
            type="text"
            placeholder="Número de Contrato *"
            validate={[isRequired]}
            disabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Unidades" required>
          <Row>
            <Col lg={3} md={3}>
              <Field
                name="numberOfBusses"
                id="numberOfBusses"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Cantidad *"
                validate={[isRequired, validateIntegerNumber]}
                disabled={disabled}
              />
            </Col>
            <Col lg={9} md={9}>
              <Label className="mt-2">
                <Field
                  name="includeFood"
                  component="input"
                  disabled={disabled}
                  type="checkbox"
                />{' '}
                Con alimentación
              </Label>
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Observaciones" required>
          <Field
            name="serviceConcept"
            component={TextInput}
            type="textarea"
            placeholder="Observaciones *"
            validate={[isRequired]}
            disabled={disabled}
          />
        </FormItem>
      </FormGroup>
    </>
  );

  const itineraryDataFields = (
    <>
      <h3>Datos del Itinerario</h3>
      <FormGroup row>
        <FormItem label="Origen" required>
          <Row>
            <Col lg={5} md={5}>
              <Field
                name="source"
                id="source"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Origen *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
            <Col lg={7} md={7}>
              <Field
                name="sourceAddress"
                id="sourceAddress"
                component={TextInput}
                showToolTip
                type="textarea"
                placeholder="Dirección Origen *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Destino" required>
          <Row>
            <Col lg={5} md={5}>
              <Field
                name="destination"
                id="destination"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Destino *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
            <Col lg={7} md={7}>
              <Field
                name="destinationAddress"
                id="destinationAddress"
                component={TextInput}
                showToolTip
                type="textarea"
                placeholder="Dirección Destino *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <h4>Lista de Movimientos</h4>
      <Field
        name="contractItineraryListElements"
        component={TextInput}
        type="hidden"
      />
      <DynamicForm
        name="contractItineraryList"
        title="Movimiento"
        columns={CONTRACT_ITINERARY_FORM_COLUMNS}
        showRemoveButton={!disabled}
        showAddButton={!disabled}
      />
    </>
  );

  const payPerDriverServiceFields = (
    <>
      <h3>Pagos por Servicio al Conductor</h3>
      <FormGroup row>
        <FormItem label="Condiciones de Trabajo">
          <Row>
            <Col>
              <Field
                name="driverCommission"
                id="driverCommission"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Por Servicio"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
            <Col>
              <Field
                name="driverDailyPayment"
                id="driverDailyPayment"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Día Retén"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Otros Pagos">
          <Row>
            <Col>
              <Field
                name="driverPaymentForFood"
                id="driverPaymentForFood"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Alimentación"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
            <Col>
              <Field
                name="driverPaymentForAccommodation"
                id="driverPaymentForAccommodation"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Hospedaje"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
    </>
  );

  const payPerBusServiceFields = (
    <>
      <h3>Pagos por Servicio por Bus</h3>
      <FormGroup row>
        <FormItem label="Pagos">
          <Row>
            <Col>
              <Field
                name="busTollPayment"
                id="busTollPayment"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Peaje"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
            <Col>
              <Field
                name="busGaragePayment"
                id="busGaragePayment"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Cochera"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
            <Col>
              <Field
                name="busParkingPayment"
                id="busParkingPayment"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Parqueo"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
    </>
  );

  const contractAmountFields = (
    <>
      <h3>Datos del Contrato y Plazo de Pago</h3>
      <FormGroup row>
        <FormItem label="Estado del Contrato" required>
          <Field
            name="contractStatus"
            component={Select}
            options={enumToSelectOptions(CONTRACT_STATUS)}
            validate={[isRequired]}
            isDisabled
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Documento" required>
          <Field
            name="contractPaymentType"
            component={Select}
            options={enumToSelectOptions(CONTRACT_DOCUMENT_TYPE)}
            onChange={onChangeContractPaymentType}
            validate={[isRequired]}
            isClearable={false}
            isDisabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tarifas" required>
          <Row>
            <Col>
              <Field
                name="amountBeforeDiscount"
                id="amountBeforeDiscount"
                component={TextInput}
                showToolTip
                type="text"
                onChange={onChangeAmountBeforeDiscount}
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Tarifa Total *"
                validate={[isRequired, validateNumber]}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Field
                name="amountPerBus"
                id="amountPerBus"
                component={TextInput}
                showToolTip
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Tarifa por Bus"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descuento Autorizado">
          <Row>
            <Col lg={3} md={4} sm={5}>
              <Field
                name="authorizedDiscount"
                id="authorizedDiscount"
                component={TextInput}
                showToolTip
                type="text"
                onChange={onChangeAuthorizedDiscount}
                append={<InputGroupText>%</InputGroupText>}
                placeholder="Descuento Autorizado"
                disabled={disabled}
                validate={[validateNumber]}
              />
            </Col>
            <Col lg={9} md={8} sm={12}>
              <Field
                name="discountComment"
                id="discountComment"
                component={TextInput}
                showToolTip
                type="textarea"
                placeholder="Comentario sobre el descuento"
                disabled={disabled}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Tarifa Después de Descuento" required>
          <Field
            name="totalAmount"
            id="totalAmount"
            component={TextInput}
            showToolTip
            type="text"
            placeholder="Tarifa Después de Descuento"
            validate={[isRequired]}
            disabled
            size="lg"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Método de Pago" required>
          <Field
            name="paymentMethodId"
            component={PaymentMethodSelect}
            validate={[isRequired]}
            isDisabled={disabled}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="">
          <Label>
            <Field
              name="creditPayment"
              component="input"
              disabled={disabled}
              type="checkbox"
            />{' '}
            Pago al Crédito
          </Label>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Otros Datos">
          <Row>
            <Col lg={3} md={4} sm={5}>
              <Field
                name="operationCode"
                id="operationCode"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Número de Operación"
                disabled={disabled}
              />
            </Col>
            <Col lg={9} md={8} sm={12}>
              <Field
                name="creditComment"
                id="creditComment"
                component={TextInput}
                showToolTip
                type="textarea"
                placeholder="Comentario sobre el crédito o el pago"
                disabled={disabled}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
    </>
  );

  const executiveFields = (
    <>
      <h3>Datos del Ejecutivo</h3>
      <FormGroup row>
        <FormItem label="Nombre y Cargo" required>
          <Row>
            <Col lg={6} md={6}>
              <Field
                name="executiveName"
                id="executiveName"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Nombres y Apellidos *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
            <Col lg={6} md={6}>
              <Field
                name="executivePosition"
                id="executivePosition"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Cargo *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Contacto" required>
          <Row>
            <Col lg={6} md={6}>
              <Field
                name="executivePhone"
                id="executivePhone"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Celular *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
            <Col lg={6} md={6}>
              <Field
                name="executiveEmail"
                id="executiveEmail"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Email *"
                validate={[isRequired]}
                disabled={disabled}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      {serviceDataFields}
      <hr />
      {itineraryDataFields}
      <hr />
      {payPerDriverServiceFields}
      <hr />
      {payPerBusServiceFields}
      <hr />
      {contractAmountFields}
      <hr />
      {contractorDataFields}
      <hr />
      {executiveFields}
      <FormFooter />
    </Form>
  );
};

PrivateServiceForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    isPerson: PropTypes.bool,
    contractStatus: optionPropTypes.isRequired,
    contractPaymentType: optionPropTypes,
  }),
};

PrivateServiceForm.defaultProps = {
  initialValues: null,
};

const mapStateToProps = ({ ContractUnit }) => ({
  loading: !ContractUnit.PrivateService.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'PrivateServiceForm',
  validate: validateRoute,
})(PrivateServiceForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
