import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import { passengersPropType } from './PostpaidSummaryPropTypes';

const PassengersTable = ({ passengers }) => (
  <Row>
    <Col>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>N° Documento</th>
            <th>N° de Asiento</th>
          </tr>
        </thead>
        <tbody>
          {passengers.map(passenger => (
            <tr key={passenger.id}>
              <td>{passenger.fullName}</td>
              <td>{passenger.idDocumentNumber}</td>
              <td>{passenger.seatNumber}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  </Row>
);

PassengersTable.propTypes = {
  passengers: passengersPropType.isRequired,
};

export default PassengersTable;
