import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Col, Row } from 'reactstrap';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../../layout/Content';
import AssignActivitiesToMaintenanceServiceOrderForm from './AssignActivitiesToMaintenanceServiceOrderForm';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../../config/paths';
import Loader from '../../../../common/Loader';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Step from '../../../../common/Step';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
} from '../../../../../actions';
import { ACTIVITY_STATUS } from '../../../../../config/constants';
import { postExecutionRegistrationFromServiceOrder } from '../../../../../actions/mechanical-maintenance/ExecutionRegistration';
import { registeredBusGenerator } from '../../../../../utils/app/json-generator-from-reducer';
import MaintenanceServiceOrderBasicInformation from '../resource/MaintenanceServiceOrderBasicInformationResource';
import MaintenanceServiceOrderMaintenanceWarningBadgeListInformation from '../resource/MaintenanceServiceOrderMaintenanceWarningBadgeListInformation';
import Alert from '../../../../common/informative/Alert';

const AssignActivitiesToMaintenanceServiceOrder = ({
  dispacthGetMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder,
  loading,
  maintenanceServiceOrder,
  match: {
    params: { id: maintenanceServiceOrderId },
  },
  dispatchPostExecutionRegistrationFromServiceOrder,
  breadcrumbs,
}) => {
  useLayoutEffect(() => {
    dispacthGetMaintenanceServiceOrder({ maintenanceServiceOrderId });

    return () => {
      dispacthClearMaintenanceServiceOrder();
    };
  }, []);

  const onSubmitToStepAssignActivities = (formValues) => {
    const originalActivityBusExecutionList = maintenanceServiceOrder
      .toJS()
      .activityBusExecutionList.map((activityBusExecution) => ({
        activityBusId: activityBusExecution.activityBusId,
        id: activityBusExecution.id,
        serviceOrderId: maintenanceServiceOrderId,
      }));

    const newActivityBusExecutionList = formValues.activityBusExecutionList.map(
      (activityBusExecution) => ({
        activityBusId: activityBusExecution.activityBusId.value,
        id: activityBusExecution.id || null, // this value is necesary to validate before send data to backend
        comment:
          'This execution was registered automatically from service order',
        status: ACTIVITY_STATUS.OPEN.value,
        startDate: null,
        endDate: null,
        serviceOrderId: maintenanceServiceOrderId,
      }),
    );

    dispatchPostExecutionRegistrationFromServiceOrder(
      maintenanceServiceOrderId,
      originalActivityBusExecutionList,
      newActivityBusExecutionList,
    );
  };

  const generateInitialValues = (maintenanceServiceOrderJson) => ({
    activityBusExecutionList:
      maintenanceServiceOrderJson &&
      maintenanceServiceOrderJson.activityBusExecutionList.map(
        ({
          id,
          activityBus: {
            activity: { companyActivityId, name },
          },
          activityBusId,
        }) => ({
          id,
          activityBusId: {
            value: activityBusId,
            label: `${companyActivityId} - ${name}`,
          },
        }),
      ),
  });

  let content;

  if (loading) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_SERVICE_ORDER_PATH} />;
  else {
    const maintenanceServiceOrderJson = maintenanceServiceOrder.toJS();

    const badgeListComponent = (
      <MaintenanceServiceOrderMaintenanceWarningBadgeListInformation
        maintenanceWarningList={
          maintenanceServiceOrderJson.maintenanceWarningList
        }
      />
    );

    const formComponent = maintenanceServiceOrderJson.registeredBus ? (
      <AssignActivitiesToMaintenanceServiceOrderForm
        onSubmit={onSubmitToStepAssignActivities}
        initialValues={generateInitialValues(maintenanceServiceOrderJson)}
        maintenanceServiceOrderId={+maintenanceServiceOrderId}
        registeredBus={registeredBusGenerator(
          maintenanceServiceOrderJson.registeredBus,
        )}
      />
    ) : (
      <Alert
        type="danger"
        message="Debe seleccionar un bus para poder continuar."
      />
    );

    content = (
      <>
        <Step
          stepElements={[
            { text: 'Actualizar orden de servicio' },
            { text: 'Asociar actividad', active: true },
            { text: 'Asociar empleados' },
          ]}
        />
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Datos de la Orden de Servicio</h3>
            <MaintenanceServiceOrderBasicInformation
              {...maintenanceServiceOrderJson}
            />
          </Col>
          <Col lg={6}>{badgeListComponent}</Col>
        </Row>
        {formComponent}
      </>
    );
  }

  return <Content breadcrumbs={breadcrumbs} content={content} />;
};

AssignActivitiesToMaintenanceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes,
  dispacthGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPostExecutionRegistrationFromServiceOrder: PropTypes.func.isRequired,
};

AssignActivitiesToMaintenanceServiceOrder.defaultProps = {
  match: null,
};

const mapDispatchToProps = {
  dispacthGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
  dispatchPostExecutionRegistrationFromServiceOrder:
    postExecutionRegistrationFromServiceOrder,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignActivitiesToMaintenanceServiceOrder);
