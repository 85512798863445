import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IN_SYSTEM_NOTIFICATION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postInSystemNotification } from '../../../../actions';
import InSystemNotificationForm from './InSystemNotificationForm';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';

export class NewInSystemNotification extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      active: formValues.active || false,
      message: formValues.message,
      notificationSeverity: formValues.notificationSeverity.value,
      validFrom: tzNormalizeDate({
        date: formValues.validFrom,
        format: TIMESTAMP_FORMAT,
      }),
      validUntil: tzNormalizeDate({
        date: formValues.validUntil,
        format: TIMESTAMP_FORMAT,
      }),
    };
    this.props.dispatchPostInSystemNotification(newFormValues);
  };

  render() {
    const validFrom = tzNormalizeDate();
    const validUntil = tzNormalizeDate({ addTime: { amount: 1, unit: 'd' } });

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Notificación Interna del Sistema"
        subtitle="Crear una nueva notificación interna del sistema"
        content={
          <InSystemNotificationForm
            onSubmit={this.onSubmit}
            initialValues={{
              validFrom,
              validUntil,
            }}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ SystemUnit }) => ({
  breadcrumbs: [
    ...SystemUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Notificaciones Internas del Sistema',
      href: IN_SYSTEM_NOTIFICATION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostInSystemNotification: postInSystemNotification,
};

NewInSystemNotification.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostInSystemNotification: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewInSystemNotification);
