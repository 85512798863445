import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { manualVoucherDataPropTypes } from './proptypes/ManualVoucherPropTypes';
import printManualVoucher from '../../../../utils/printers/ManualVoucher';

const PrintManualVoucherButton = ({ manualVoucher }) => (
  <Button
    color="light"
    className="border"
    onClick={() => printManualVoucher(manualVoucher)}
  >
    <i className="fa fa-print" /> Imprimir
  </Button>
);

PrintManualVoucherButton.propTypes = {
  manualVoucher: PropTypes.shape(manualVoucherDataPropTypes).isRequired,
};

export default PrintManualVoucherButton;
