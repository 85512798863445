import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_BUS_ACTIVITY_ASSIGNMENTS,
  GET_BUS_ACTIVITY_ASSIGNMENTS,
  CLEAR_BUS_ACTIVITY_ASSIGNMENTS,
  FLAG_BUS_ACTIVITY_ASSIGNMENT_ACTIVITY,
  CLEAR_BUS_ACTIVITY_ASSIGNMENT,
} from '../types/mechanical-maintenance';
import {
  BUS_ACTIVITY_ASSIGMENT_ENDPOINT,
  BUS_ACTIVITY_MASSIVE_ASSIGMENT_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import {
  isErrorResponse,
  hadErrorInPromiseAllSettled,
} from '../../utils/error-handlers';
import { BUS_ACTIVITY_ASSIGNMENT_PATH } from '../../config/paths';
import { DEFAULT_QUERY_GET_ALL } from '../../config/queries';
import { createNewActivity } from './Activity';

const flagGettingBusActivityAssignments = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BUS_ACTIVITY_ASSIGNMENTS,
    payload: flag,
  });

const obtainBusActivityAssignments = async (tableFilters) => {
  const newTableFilters = { ...tableFilters };
  newTableFilters.query = [...tableFilters.query, 'active:true'];
  const url = `${BUS_ACTIVITY_ASSIGMENT_ENDPOINT}?${QueryString.stringify(
    newTableFilters,
  )}`;
  const promise = await fetch(url, DEFAULT_GET_CONFIG);
  await isErrorResponse(promise);
  const response = await promise.json();
  return response;
};

const getBusActivityAssignments = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingBusActivityAssignments(true));
    const response = await obtainBusActivityAssignments(tableFilters);
    dispatch({
      type: GET_BUS_ACTIVITY_ASSIGNMENTS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingBusActivityAssignments(false));
  }
};

const clearBusActivityAssignments = () => (dispatch) =>
  dispatch({
    type: CLEAR_BUS_ACTIVITY_ASSIGNMENTS,
  });

const flagBusActivityAssignmentActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_BUS_ACTIVITY_ASSIGNMENT_ACTIVITY,
    payload: flag,
  });

const clearBusActivityAssignment = () => (dispatch) =>
  dispatch({
    type: CLEAR_BUS_ACTIVITY_ASSIGNMENT,
  });

const validateBusActivityRelationship = async ({
  registeredBusIdList,
  activityIdList,
}) => {
  const params = { ...DEFAULT_QUERY_GET_ALL };
  params.query = [
    `registeredBusId:${registeredBusIdList.join('|')}`,
    `activityId:${activityIdList.join('|')}`,
  ];
  const busActivity = await obtainBusActivityAssignments(params);
  return busActivity;
};

const createNewBusActivityRelationship = async ({
  registeredBusIdList,
  activityIdList,
  isMassive = true,
}) => {
  // Validate if buses and activities selected had been registered before
  const busActivities = await validateBusActivityRelationship({
    registeredBusIdList,
    activityIdList,
  });

  // Generating fetch list according previous data
  const activityBusList = [];
  registeredBusIdList.forEach((registeredBusId) => {
    activityIdList.forEach((activityId) => {
      const busActivityFound = !!busActivities.content.find(
        (busActivity) =>
          busActivity.registeredBusId === registeredBusId &&
          busActivity.activityId === activityId,
      );
      if (!busActivityFound) {
        const activityBus = {
          registeredBusId,
          activityId,
          active: true,
        };
        activityBusList.push(activityBus);
      }
    });
  });
  let promise;
  if (isMassive)
    promise = await fetch(BUS_ACTIVITY_MASSIVE_ASSIGMENT_ENDPOINT, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(activityBusList),
    });
  else
    promise = await fetch(BUS_ACTIVITY_ASSIGMENT_ENDPOINT, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(activityBusList[0]),
    });
  await isErrorResponse(promise);
  const relationship = await promise.json();
  return relationship;
};

const postBusActivityAssignment =
  async ({ registeredBusIdList, activityIdList }) =>
  async (dispatch) => {
    try {
      dispatch(flagBusActivityAssignmentActivity(true));

      await createNewBusActivityRelationship({
        registeredBusIdList,
        activityIdList,
      });
      dispatch(push(BUS_ACTIVITY_ASSIGNMENT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBusActivityAssignmentActivity(false));
    }
  };

const deleteBusActivityAssignment =
  async ({ registeredBusIdList, activityIdList }) =>
  async (dispatch) => {
    try {
      dispatch(flagBusActivityAssignmentActivity(true));

      // Search busActivityASssignmentId by registeredBusId and activityId
      const params = { ...DEFAULT_QUERY_GET_ALL };
      params.query = [
        `registeredBusId:${registeredBusIdList.join('|')}`,
        `activityId:${activityIdList.join('|')}`,
      ];
      const busActivities = await obtainBusActivityAssignments(params);

      // Generating fetch list according previous data
      const fetchList = busActivities.content.map(({ id: busActivityId }) =>
        fetch(`${BUS_ACTIVITY_ASSIGMENT_ENDPOINT}/${busActivityId}`, {
          ...DEFAULT_DELETE_CONFIG,
        }).then((response) => response.json()),
      );
      const response = await Promise.allSettled(fetchList);
      await hadErrorInPromiseAllSettled(response);
      dispatch(push(BUS_ACTIVITY_ASSIGNMENT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBusActivityAssignmentActivity(false));
    }
  };

const postRegisterActivityAndBusActivity =
  async ({ activityId, activityLabel, registeredBusId, activity }) =>
  async (dispatch) => {
    try {
      dispatch(flagBusActivityAssignmentActivity(true));

      let newActivityId = activityId;
      // Register activity is not exist
      if (!activityId) {
        const newActivity = await createNewActivity(activity);
        newActivityId = newActivity.id;
      }

      // Register bus activity relationship
      const activityBus = await createNewBusActivityRelationship({
        registeredBusIdList: [registeredBusId],
        activityIdList: [newActivityId],
        isMassive: false,
      });

      const myActivityLabel =
        activityLabel || `${activity.companyActivityId} - ${activity.name}`;

      return {
        value: activityBus.id,
        label: myActivityLabel,
      };
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagBusActivityAssignmentActivity(false));
    }
  };

export {
  flagGettingBusActivityAssignments,
  getBusActivityAssignments,
  clearBusActivityAssignments,
  flagBusActivityAssignmentActivity,
  postBusActivityAssignment,
  clearBusActivityAssignment,
  deleteBusActivityAssignment,
  postRegisterActivityAndBusActivity,
};
