import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import Table from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import 'react-table/react-table.css';
import '../../../../config/columns.css';
import TextInput from '../../forms/input/TextInput';
import {
  COLOR,
  DATE_STRING_LENGTH,
  DEFAULT_ORDER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT,
} from '../../../../config/constants';
import * as ColumnFilterDataTypes from '../../../../config/columnFilterDataTypes';

const hasValidArrayColumnFilter = (value) => value.length > 0;

const hasValidDateColumnFilter = (value) => value.length === DATE_STRING_LENGTH;

const SelectionableTableHOC = selectTableHOC(Table);

class SelectionableTable extends Component {
  static isRequired = (value) =>
    value ? undefined : 'Debe seleccionar al menos un elemento de la tabla';

  constructor(props) {
    super(props);

    this.state = {
      selectAll: false,
      selection: [],
      allElements: [],
    };
  }

  componentDidMount() {
    this.onMount();
  }

  componentWillUnmount() {
    const { form, dispatchChange, name } = this.props;
    dispatchChange(form, name, null);
  }

  onMount = () => {
    const {
      data,
      keyField,
      returnOnlySelectedItems,
      dispatchChange,
      form,
      activeField,
      name,
    } = this.props;

    if (!returnOnlySelectedItems) {
      const allElements = [];

      const selection = [];

      data.forEach((element) => {
        allElements.push({
          id: element[keyField],
          checked: element[activeField],
        });

        if (element[activeField]) {
          selection.push(element[keyField]);
        }
      });

      this.setState({ allElements, selection });

      dispatchChange(form, name, allElements);
    }
  };

  onFetchData = (state, whenPressEnterKey = false) => {
    const { fetchData, params } = this.props;

    const { page, pageSize, sorted, filtered } = state;

    let sort;
    sort = sorted.map(({ id, desc }) => {
      const order = desc ? 'desc' : 'asc';
      return `${id},${order}`;
    });
    if (sort && !sort.length > 0) {
      sort = DEFAULT_SORT;
    }

    let validFetchData = true;

    let query = [];

    if (whenPressEnterKey) {
      ({ query, validFetchData } = this.generateQueryToFetchData(
        state,
        whenPressEnterKey,
      ));
    } else {
      if (filtered.length !== 0) {
        validFetchData = false;
      }

      if (filtered.length === 0) {
        if (sorted.length !== 0) {
          validFetchData = true;
        }
      } else {
        ({ query, validFetchData } = this.generateQueryToFetchData(
          state,
          whenPressEnterKey,
        ));
      }
    }

    if (validFetchData && fetchData) {
      this.setState({ selection: [], selectAll: false });

      fetchData({
        ...params,
        page: page || 0,
        sort: sort || DEFAULT_SORT,
        size: pageSize || DEFAULT_PAGE_SIZE,
        order: DEFAULT_ORDER,
        query,
      });
    }
  };

  /**
   * Toggle a single checkbox for select table
   */
  toggleSelection = (key) => {
    const { form, dispatchChange, name } = this.props;

    const { selection } = this.state;

    // start off with the existing state
    let newSelection = [...selection];

    const keyIndex = newSelection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      newSelection = [
        ...newSelection.slice(0, keyIndex),
        ...newSelection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      newSelection.push(key);
    }

    // update the state
    this.setState({ selection: newSelection });

    const items = this.mergeRecords(newSelection);

    dispatchChange(form, name, items.length > 0 ? items : null);
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { keyField, form, dispatchChange, name } = this.props;

    const { selectAll } = this.state;

    const newSelectAll = !selectAll;

    const selection = [];

    if (newSelectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        // eslint-disable-next-line no-underscore-dangle
        selection.push(item._original[keyField]);
      });
    }

    this.setState({ selectAll: newSelectAll, selection });

    const items = this.mergeRecords(selection);

    dispatchChange(form, name, items.length > 0 ? items : null);
  };

  generateQueryToFetchData(state, whenPressEnterKey = true) {
    const { filtered } = state;

    const { columns } = this.props;

    // If "whenPressEnterKey" param is true, it means that
    // onFetch is executed when user presses Enter Key.
    // If it's false, it means that onFetch is executed when
    // it detects a change in filtered state only for Filters of type Select
    let validFetchData = whenPressEnterKey;

    // Check if there is a date filter
    // If there is, only send it when having 10 characters
    // TODO: Implement a Date Filter Component
    const query = filtered.reduce((filters, filter) => {
      const { id } = filter;

      let { value } = filter;

      const column = columns.find((columnElement) => {
        if (!columnElement.id) {
          return columnElement.accessor === id;
        }
        return columnElement.id === id;
      });

      let validFilter = true;

      if (value === null) {
        validFilter = false;
      }

      if (column.dataType) {
        switch (column.dataType) {
          case ColumnFilterDataTypes.ARRAY:
            // If array column filter, concatenate with |
            if (hasValidArrayColumnFilter(value)) {
              value = value.join('|');
              validFilter = true;
              validFetchData = true;
            } else {
              validFilter = false;
            }
            break;
          case ColumnFilterDataTypes.DATE:
            // If date column filter and has no valid format, don't include in query
            validFilter = hasValidDateColumnFilter(value);
            validFetchData = !!validFilter;
            break;
          default:
            validFilter = true;
        }
      }
      if (validFilter) {
        filters.push(`${id}:${value}`);
      }
      return filters;
    }, []);

    return { query, validFetchData };
  }

  mergeRecords = (selections = []) => {
    const { returnOnlySelectedItems } = this.props;

    // if user want selected id's only
    if (returnOnlySelectedItems) return selections;

    // if user want all id's with their states
    const { allElements } = this.state;

    const newAllElements = [...allElements];

    // reset all elements
    newAllElements.forEach((element, index) => {
      newAllElements[index].checked = false;
    });

    // activate selected id's
    selections.forEach((selection) => {
      const index = allElements.findIndex(
        (element) => element.id === selection,
      );

      newAllElements[index].checked = true;
    });

    return newAllElements;
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = (key) => this.state.selection.includes(key);

  rowFn = (state, rowInfo) => {
    const { keyField } = this.props;

    const { selection } = this.state;

    const background =
      rowInfo &&
      selection.includes(rowInfo.original[keyField]) &&
      COLOR.selectedRow;

    return {
      onClick: (e, handleOriginal) => {
        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        } else {
          // call toggleSelection function when row is clicked
          this.toggleSelection(rowInfo.original[keyField]);
        }
      },
      style: {
        background,
      },
    };
  };

  render() {
    const {
      required,
      filterable,
      defaultPageSize,
      pages,
      name,
      defaultFilterMethod,
    } = this.props;

    return (
      <Fragment>
        <Field
          name={name}
          component={TextInput}
          type="hidden"
          validate={required ? [SelectionableTable.isRequired] : null}
        />
        <SelectionableTableHOC
          {...this.props}
          ref={(r) => {
            this.checkboxTable = r;
          }}
          toggleSelection={this.toggleSelection}
          selectAll={this.state.selectAll}
          selectType="checkbox"
          toggleAll={this.toggleAll}
          isSelected={this.isSelected}
          getTrProps={this.rowFn}
          className="-striped -highlight"
          filterable={filterable}
          onFetchData={this.onFetchData}
          defaultPageSize={defaultPageSize}
          pages={pages}
          defaultFilterMethod={defaultFilterMethod}
        />
      </Fragment>
    );
  }
}

SelectionableTable.propTypes = {
  keyField: PropTypes.string,
  required: PropTypes.bool,
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  defaultPageSize: PropTypes.number,
  loading: PropTypes.bool,
  returnOnlySelectedItems: PropTypes.bool,
  activeField: PropTypes.string,
  filterable: PropTypes.bool,
  fetchData: PropTypes.func,
  params: PropTypes.shape({}),
  pages: PropTypes.number,
  name: PropTypes.string,
  defaultFilterMethod: PropTypes.func,
};

SelectionableTable.defaultProps = {
  keyField: 'id',
  required: false,
  loading: false,
  returnOnlySelectedItems: false,
  activeField: 'active',
  filterable: false,
  fetchData: null,
  params: null,
  defaultPageSize: DEFAULT_PAGE_SIZE,
  pages: undefined,
  name: 'items',
  defaultFilterMethod: undefined,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

export default connect(null, mapDispatchToProps)(SelectionableTable);
