import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getGeneralIncome,
  clearGeneralIncome,
} from '../../../../actions/index';
import Loader from '../../../common/Loader';
import { GENERAL_INCOME_PATH } from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import GeneralIncomeToolbar from './GeneralIncomeToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import { numberFormatter } from '../../../../utils/number';
import { padStart } from '../../../../utils/string';

export class GeneralIncome extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: generalIncomeId },
      },
      dispatchGetGeneralIncome,
    } = this.props;

    dispatchGetGeneralIncome({ generalIncomeId });
  }

  componentWillUnmount() {
    this.props.dispatchClearGeneralIncome();
  }

  render() {
    const { breadcrumbs, income, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading || income.isEmpty()) {
      content = <Loader />;
    } else if (income.isEmpty()) {
      content = <NoDataResource returnPage={GENERAL_INCOME_PATH} />;
    } else {
      content = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="Documento:">
            {income.get('documentSeries')}-
            {padStart(income.get('documentCode'), 7)}
          </ResourceProperty>
          <ResourceProperty label="Pagador:">
            {income.get('payerCustomer').fullName}
          </ResourceProperty>
          <ResourceProperty label="Autorizador:">
            {income.get('authorizedUser').customer.fullName}
          </ResourceProperty>
          <h3>Item</h3>
          <ResourceProperty label="Tipo de Ingreso:">
            {income.get('transaction').generalIncomeType.name}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {income.get('description')}
          </ResourceProperty>
          <ResourceProperty label="Total:">
            {numberFormatter({ value: income.get('total') })}
          </ResourceProperty>
          <InternalResource
            id={income.get('id')}
            createDate={income.get('createDate')}
            lastUpdate={income.get('lastUpdate')}
          />
        </Fragment>
      );

      toolbar = <GeneralIncomeToolbar generalIncomeId={income.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Ingreso"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Ingresos', href: GENERAL_INCOME_PATH },
    { text: 'Ver', href: '' },
  ],
  income: SalesUnit.GeneralIncome.getIn(['current', 'content']),
  loading: !SalesUnit.GeneralIncome.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetGeneralIncome: getGeneralIncome,
  dispatchClearGeneralIncome: clearGeneralIncome,
};

GeneralIncome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  income: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetGeneralIncome: PropTypes.func.isRequired,
  dispatchClearGeneralIncome: PropTypes.func.isRequired,
};

GeneralIncome.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralIncome);
