import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PRICING_PROFILE_PATH } from '../../../../config/paths';

const PricingProfileEditButton = ({ pricingProfileId }) => (
  <Link
    className="btn btn-primary"
    to={`${PRICING_PROFILE_PATH}/${pricingProfileId}/edit`}
  >
    Editar
  </Link>
);

PricingProfileEditButton.propTypes = {
  pricingProfileId: PropTypes.number.isRequired,
};

export default PricingProfileEditButton;
