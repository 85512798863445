import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { USER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import UserForm from './UserForm';
import Loader from '../../../common/Loader';
import {
  clearUser,
  getUser,
  getUserLocales,
  clearUserLocales,
  putUser,
} from '../../../../actions';
import { DEFAULT_QUERY } from '../../../../config/queries';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditUser extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: userId },
      },
      dispatchGetUser,
      dispatchGetUserLocales,
    } = this.props;
    dispatchGetUser({ userId });
    dispatchGetUserLocales({ ...DEFAULT_QUERY });
  }

  componentWillUnmount() {
    const { dispatchClearUser, dispatchClearUserLocales } = this.props;
    dispatchClearUser();
    dispatchClearUserLocales();
  }

  onSubmit = (formValues) => {
    const userFormValues = { ...formValues };

    userFormValues.securityProfileSet = formValues.securityProfileSet.map(
      (profile) => ({
        id: profile.value,
      }),
    );
    userFormValues.customerId = formValues.customerId.value;
    userFormValues.locale = formValues.locale.value;
    userFormValues.newPassword = formValues.newPassword || null;
    userFormValues.confirmNewPassword = formValues.confirmNewPassword || null;
    userFormValues.authorizingPassword = formValues.authorizingPassword || null;

    const {
      dispatchPutUser,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutUser(id, userFormValues);
  };

  generateInitialValues = (user) => {
    const { locales } = this.props;

    const localeObject = locales.find(
      (locale) => locale.locale === user.get('locale'),
    );

    let locale = null;

    if (localeObject) {
      locale = {
        value: localeObject.locale,
        label: localeObject.name,
      };
    }

    const {
      customer,
      username,
      securityProfileSet,
      enabled,
      verified,
      blocked,
    } = user.toJS();

    return {
      username,
      customerId: {
        value: customer.id,
        label: `${customer.idDocumentNumber} (${customer.idCountryOfOrigin}) - ${customer.fullName}`,
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: customer.fullName,
        idDocumentNumber: customer.idDocumentNumber,
        gender: customer.gender,
        idCountryOfOrigin: customer.idCountryOfOrigin,
        identificationType: customer.identificationType,
        email: customer.email,
        mobilePhone: customer.mobilePhone,
        dob: customer.dob,
        discountCode: customer.discountCode,
      },
      locale,
      securityProfileSet: securityProfileSet.map((profile) => ({
        value: profile.id,
        label: profile.name,
      })),
      enabled,
      verified,
      blocked,
    };
  };

  render() {
    const { breadcrumbs, user, loading, locales } = this.props;

    let content;

    if (loading || !locales.length) {
      content = <Loader />;
    } else if (user.isEmpty()) {
      content = <NoDataResource returnPage={USER_PATH} />;
    } else {
      content = (
        <UserForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(user)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Usuario"
        subtitle="Editar usuario"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { UserUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios',
      href: USER_PATH,
    },
    {
      text: 'Ver',
      href: `${USER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  user: UserUnit.User.getIn(['current', 'content']),
  loading: !UserUnit.User.getIn(['current', 'activity']).isEmpty(),
  locales: UserUnit.UserLocale.getIn(['all', 'content', 'content']),
});

const mapDispatchToProps = {
  dispatchPutUser: putUser,
  dispatchGetUser: getUser,
  dispatchClearUser: clearUser,
  dispatchGetUserLocales: getUserLocales,
  dispatchClearUserLocales: clearUserLocales,
};

EditUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutUser: PropTypes.func.isRequired,
  dispatchGetUser: PropTypes.func.isRequired,
  dispatchClearUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  user: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetUserLocales: PropTypes.func.isRequired,
  dispatchClearUserLocales: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  locales: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EditUser.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
