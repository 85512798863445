import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  getTickets,
  clearTickets,
  getBaggages,
  clearBaggages,
} from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';

class TicketSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearTickets, dispatchClearBaggages, flagGetBaggages } =
      this.props;
    dispatchClearTickets();
    if (flagGetBaggages) dispatchClearBaggages();
  }

  onChange = (inputValue) => {
    const { dispatchGetTickets, flagGetBaggages, dispatchGetBaggages } =
      this.props;

    const value = inputValue.trim();

    if (value.length) {
      const ticket = value.split('-');

      // Search when "ticket" contains only a character -
      if (ticket.length === 2) {
        const [series, correlative] = ticket;

        if (series.length >= 2 && correlative.length > 6) {
          const query = [
            `documentSeries:${series}`,
            `documentCode:${correlative}`,
          ];

          dispatchGetTickets({ query });

          if (flagGetBaggages) {
            dispatchGetBaggages({ ticketCode: value });
          }
        }
      }
    }
  };

  render() {
    const { options, loading, isMulti, input, meta, ...rest } = this.props;

    return (
      <Select
        options={options}
        isLoading={loading}
        isMulti={isMulti}
        input={input}
        meta={meta}
        onInputChange={this.onChange}
        {...rest}
      />
    );
  }
}

TicketSelect.propTypes = {
  dispatchGetTickets: PropTypes.func.isRequired,
  dispatchClearTickets: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  meta: metaPropTypes,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  showDetailOnTheRight: PropTypes.bool,
  flagGetBaggages: PropTypes.bool,
  dispatchGetBaggages: PropTypes.func.isRequired,
  dispatchClearBaggages: PropTypes.func.isRequired,
};

TicketSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  loading: false,
  isMulti: false,
  options: [],
  placeholder: 'Ingrese Boleto',
  showDetailOnTheRight: false,
  flagGetBaggages: false,
};

const mapStateToProps = (
  { ReservationUnit, BaggageUnit },
  { flagGetBaggages },
) => {
  const baggages = flagGetBaggages
    ? BaggageUnit.Baggage.getIn(['all', 'content', 'content'])
    : null;
  const loadingBaggages = flagGetBaggages
    ? BaggageUnit.Baggage.getIn(['all', 'loading'])
    : false;

  const tickets = ReservationUnit.Ticket.getIn([
    'all',
    'content',
    'content',
  ]).map(
    ({
      id,
      ticketCode,
      documentCode,
      documentSeries,
      customer: {
        fullName,
        idDocumentNumber,
        identificationType,
        idCountryOfOrigin,
      },
      customerId,
      seatReservation,
      destinationLocation: { name: destinationLocationName },
      sourceLocation: { name: sourceLocationName },
      departureDate,
      ticketStatus,
    }) => ({
      value: id,
      label: ticketCode,
      documentCode,
      documentSeries,
      customerFullName: fullName,
      customerDocumentNumber: `${identificationType.name} ${idDocumentNumber}`,
      destinationLocationName,
      sourceLocationName,
      departureDate,
      itineraryId: seatReservation ? seatReservation.itineraryId : null,
      seatNumber: seatReservation ? seatReservation.seat.seatNumber : null,
      customerId,
      idCountryOfOrigin,
      ticketStatus,
      baggages,
    }),
  );
  const loadingTickets = ReservationUnit.Ticket.getIn(['all', 'loading']);

  return {
    options: tickets,
    loading: loadingTickets || loadingBaggages,
  };
};

const mapDispatchToprops = {
  dispatchGetTickets: getTickets,
  dispatchClearTickets: clearTickets,
  dispatchGetBaggages: getBaggages,
  dispatchClearBaggages: clearBaggages,
};

export default connect(mapStateToProps, mapDispatchToprops)(TicketSelect);
