import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { businessContactInformationPropTypes } from '../proptypes/BusinessPropTypes';

const BusinessContactInformationResource = ({
  primaryContact,
  email,
  phone,
  fax,
}) => {
  const phoneResource = phone ? <a href={`tel:${phone}`}>{phone}</a> : '-';
  const emailResource = email ? <a href={`mailto:${email}`}>{email}</a> : '-';
  return (
    <>
      <ResourceProperty label="Contacto Principal:">
        {primaryContact || '-'}
      </ResourceProperty>
      <ResourceProperty label="Email:">{emailResource}</ResourceProperty>
      <ResourceProperty label="Teléfono:">{phoneResource}</ResourceProperty>
      <ResourceProperty label="Fax:">{fax}</ResourceProperty>
    </>
  );
};

BusinessContactInformationResource.propTypes =
  businessContactInformationPropTypes;

export default BusinessContactInformationResource;
