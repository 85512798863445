import React, { createElement, useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '../../../../common/Table';
import Alert from '../../../../common/informative/Alert';
import { ITINERARY_WITHOUT_MANIFEST } from '../../../../../config/messages';
import ManifestCargoDetail from './ManifestCargoDetail';
import printCargoItemManifest from '../../../../../utils/printers/ItineraryManifest';
import { generateManifestCargoColumns } from '../../../../../config/columns';
import {
  clearManifestItinerary,
  getManifestCargoItemById,
  getManifestItemByItinerary,
} from '../../../../../actions/cargo/ItineraryManifest';
import Loader from '../../../../common/Loader';

const ManifestCargo = ({
  loading,
  itineraryId,
  itineraryManifest,
  dispatchGetManifestItemById,
  dispatchClearManifestItinerary,
  dispatchGetManifestItemByItinerary,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearManifestItinerary();
    },
    [],
  );

  const handleItemRemoved = (removed) => {
    if (removed) {
      dispatchGetManifestItemByItinerary({ itineraryId });
    }
  };

  const renderItineraryManifestDetailComponent = ({
    original: { id: manifestId, sourceLocation, destinationLocation },
  }) => {
    const component = createElement(ManifestCargoDetail, {
      manifestId,
      sourceLocation,
      destinationLocation,
      itineraryId,
      onItemRemoved: handleItemRemoved,
    });

    return <div className="mt-3 mb-2 ml-5 mr-5">{component}</div>;
  };

  const printManifest = (id) => {
    dispatchGetManifestItemById(id).then((response) => {
      printCargoItemManifest(response);
    });
  };

  if (loading) return <Loader />;

  const data = itineraryManifest.get('content') || [];
  const columns = generateManifestCargoColumns(printManifest);
  const pages = itineraryManifest.get('totalPages') || null;
  const defaultPageSize = itineraryManifest.get('size') || 100;

  const table =
    data.length > 0 ? (
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        rowComponent={renderItineraryManifestDetailComponent}
      />
    ) : (
      <Alert message={ITINERARY_WITHOUT_MANIFEST} type="warning" />
    );
  return table;
};

ManifestCargo.propTypes = {
  itineraryId: PropTypes.number.isRequired,
  dispatchGetManifestItemByItinerary: PropTypes.func.isRequired,
  dispatchClearManifestItinerary: PropTypes.func.isRequired,
  dispatchGetManifestItemById: PropTypes.func.isRequired,
  itineraryManifest: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ CargoUnit }) => ({
  itineraryManifest: CargoUnit.ItineraryManifest.getIn(['all', 'content']),
  loading: CargoUnit.ItineraryManifest.getIn(['all', 'loading']),
});

ManifestCargo.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetManifestItemByItinerary: getManifestItemByItinerary,
  dispatchClearManifestItinerary: clearManifestItinerary,
  dispatchGetManifestItemById: getManifestCargoItemById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManifestCargo);
