import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BUS_FUEL_GROUP_PATH } from '../../../../config/paths';
import {
  getBusFuelGroup,
  clearBusFuelGroup,
  putBusFuelGroup,
} from '../../../../actions';
import BusFuelGroupForm from './BusFuelGroupForm';
import Loader from '../../../common/Loader';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditBusFuelGroup extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.props.dispatchGetBusFuelGroup(id);
  }

  componentWillUnmount() {
    this.props.dispatchClearBusFuelGroup();
  }

  onSubmit = (formValues) => {
    const busFuelGroupId = parseInt(this.props.match.params.id, 10);
    const registeredBusList = [];
    if (formValues.registeredBus.indexOf(',') >= 0) {
      formValues.registeredBus.split(',').forEach((value) => {
        registeredBusList.push({ id: value });
      });
    } else {
      registeredBusList.push({ id: parseInt(formValues.registeredBus, 10) });
    }
    const putBusFuelGroupFormValues = {
      name: formValues.name,
      description: formValues.description,
      performance: formValues.performance,
      registeredBusList,
    };
    this.props.dispatchPutBusFuelGroup(
      busFuelGroupId,
      putBusFuelGroupFormValues,
    );
  };

  render() {
    const { busFuelGroup, loading } = this.props;
    let content;
    if (loading) {
      content = <Loader />;
    } else if (busFuelGroup.isEmpty()) {
      content = <NoDataResource returnPage={BUS_FUEL_GROUP_PATH} />;
    } else {
      const registeredBus = busFuelGroup
        .get('registeredBusList')
        .map((bus) => bus.id);
      const initialValues = {
        name: busFuelGroup.get('name'),
        description: busFuelGroup.get('description'),
        performance: busFuelGroup.get('performance')
          ? busFuelGroup.get('performance').toFixed(2)
          : null,
        registeredBus,
      };
      content = (
        <BusFuelGroupForm
          busFuelGroupId={this.props.match.params.id}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        />
      );
    }

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Editar Bus por Consumo de Combustible"
        subtitle="Editar un bus por comsumo de combustible"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    { text: 'Bus por consumo de combustible', href: BUS_FUEL_GROUP_PATH },
    {
      text: 'Ver',
      href: `${BUS_FUEL_GROUP_PATH}/${id}`,
    },
    { text: 'Editar', href: '' },
  ],
  busFuelGroup: TrafficUnit.BusFuelGroup.getIn(['current', 'content']),
  loading: !TrafficUnit.BusFuelGroup.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetBusFuelGroup: getBusFuelGroup,
  dispatchClearBusFuelGroup: clearBusFuelGroup,
  dispatchPutBusFuelGroup: putBusFuelGroup,
};

EditBusFuelGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  busFuelGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetBusFuelGroup: PropTypes.func.isRequired,
  dispatchClearBusFuelGroup: PropTypes.func.isRequired,
  dispatchPutBusFuelGroup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBusFuelGroup);
