import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormFeedback,
} from 'reactstrap';
import { voidTicket } from '../../../../../actions/index';
import { POST } from '../../../../../config/permissions';
import { generatePostVoidTicket } from '../../../../../config/endpoints';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import SaveButton from '../../../../common/button/SaveButton';

class VoidTicketButton extends Component {
  state = {
    showWarningModal: false,
    showJustificationModal: false,
    justification: '',
    validJustification: true,
  };

  toggleWarningModal = (show) => this.setState({ showWarningModal: show });

  handleToggleWarningModal = (show) => () => this.toggleWarningModal(show);

  showJustificationModal = () => {
    this.setState({ justification: '' });
    this.toggleWarningModal(false);
    this.setState({ showJustificationModal: true });
    this.setState({ validJustification: true });
  };

  closeJustificationModal = () => {
    this.setState({ showJustificationModal: false });
  };

  handleVoidTicket = () => {
    const { ticketId, dispatchVoidTicket } = this.props;

    const { justification } = this.state;

    if (justification.length) {
      this.closeJustificationModal();
      dispatchVoidTicket({ ticketId, justification });
    } else {
      this.setState({ validJustification: false });
    }
  };

  handleOnChangeJustification = (event) => {
    const { value } = event.target;

    this.setState({ justification: value });

    if (value && value.length) {
      this.setState({ validJustification: true });
    }
  };

  render() {
    const {
      showWarningModal,
      showJustificationModal,
      justification,
      validJustification,
    } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        title="Confirmación de Anulación"
        onClickConfirm={this.showJustificationModal}
        onClickCancel={this.handleToggleWarningModal(false)}
      />
    );

    const justificationModal = (
      <Modal isOpen={showJustificationModal}>
        <ModalHeader>Razón de Anulación</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            invalid={!validJustification}
            name="justification"
            id="justification"
            placeholder="Ingrese razón de anulación"
            value={justification}
            onChange={this.handleOnChangeJustification}
          />
          <FormFeedback>Favor ingresar la razón de la anulación.</FormFeedback>
        </ModalBody>
        <ModalFooter>
          <SaveButton action={this.handleVoidTicket} />{' '}
          <Button color="secondary" onClick={this.closeJustificationModal}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );

    return (
      <Fragment>
        <Button color="danger" onClick={() => this.toggleWarningModal(true)}>
          <i className="fa fa-ban" /> Anular
        </Button>
        {warningModal}
        {justificationModal}
      </Fragment>
    );
  }
}

VoidTicketButton.propTypes = {
  dispatchVoidTicket: PropTypes.func.isRequired,
  ticketId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchVoidTicket: voidTicket,
};

const connectedComponent = connect(null, mapDispatchToProps)(VoidTicketButton);

export default withEndpointAuthorization({
  url: generatePostVoidTicket(0),
  permissionType: POST,
})(connectedComponent);
