import React from 'react';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ManifestForm from './ManifestForm';

export const Manifest = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Manifiesto de Carga"
    subtitle="Manifiesto de Carga"
    content={<ManifestForm />}
  />
);

Manifest.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Manifiesto',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(Manifest);
