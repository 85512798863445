import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import './Floor.css';
import Seat from './Seat';
import SeatForPricingProfile from './SeatForPricingProfile';
import { range } from '../../../utils/array';
import { seatsPropTypes } from '../../units/bus/seat-map/proptypes/SeatMapPropTypes';

class Floor extends Component {
  constructor(props) {
    super(props);
    this.renderRows = this.renderRows.bind(this);
    this.renderColumns = this.renderColumns.bind(this);
    this.findSeat = this.findSeat.bind(this);
    this.renderSeatMapElements = this.renderSeatMapElements.bind(this);
  }

  findSeat({ xPos, yPos }) {
    return this.props.floor.seats.find(
      (seat) => seat.xPosition === xPos && seat.yPosition === yPos,
    );
  }

  renderSeatMapElements() {
    return this.renderRows();
  }

  renderRows() {
    return range(this.props.floor.length).map((yPos) => (
      <Row key={yPos}>{this.renderColumns({ yPos })}</Row>
    ));
  }

  renderColumns({ yPos }) {
    return range(this.props.floor.width).map((xPos) => (
      <Col key={xPos}>{this.renderSeat({ xPos, yPos })}</Col>
    ));
  }

  renderSeat({ xPos, yPos }) {
    const {
      sourceLocationId,
      destinationLocationId,
      itineraryId,
      handleSeatSelection,
      handleSeatDeselection,
      seatReservations,
      seatSelectionChangesInProgress,
      numPassengers,
      renderFor,
      floor: { floorNumber },
      enabledFloor,
    } = this.props;

    const seat = this.findSeat({ xPos, yPos });

    const {
      seatMapElement,
      seatNumber,
      seatStatus,
      id,
      price,
      xPosition,
      yPosition,
      specialPriceId,
    } = seat;

    let renderSeat = null;

    switch (renderFor) {
      case 'booking':
        renderSeat = (
          <Seat
            sourceLocationId={sourceLocationId}
            seatMapElement={seatMapElement}
            seatNumber={seatNumber}
            seatStatus={seatStatus}
            price={price}
            id={`${itineraryId}-${id}`}
            destinationLocationId={destinationLocationId}
            itineraryId={itineraryId}
            handleSeatSelection={handleSeatSelection}
            handleSeatDeselection={handleSeatDeselection}
            seatReservations={seatReservations}
            seatSelectionChangesInProgress={seatSelectionChangesInProgress}
            numPassengers={numPassengers}
            specialPriceId={specialPriceId}
          />
        );
        break;

      case 'pricingProfile':
        renderSeat = (
          <SeatForPricingProfile
            seatMapElement={seatMapElement}
            seatNumber={seatNumber}
            floorNumber={floorNumber}
            id={`${floorNumber}-${xPosition}-${yPosition}`}
            handleSeatSelection={handleSeatSelection}
            seatReservations={seatReservations}
            xPosition={xPosition}
            yPosition={yPosition}
            enabledFloor={enabledFloor}
          />
        );
        break;

      default:
        break;
    }

    return renderSeat;
  }

  render() {
    const { floorNumber } = this.props.floor;
    return (
      <div className="Floor">
        Piso {floorNumber}
        {this.renderSeatMapElements()}
      </div>
    );
  }
}

Floor.propTypes = {
  floor: PropTypes.shape({
    seats: seatsPropTypes.seats,
    floorNumber: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  sourceLocationId: PropTypes.number,
  destinationLocationId: PropTypes.number,
  itineraryId: PropTypes.number,
  handleSeatSelection: PropTypes.func.isRequired,
  handleSeatDeselection: PropTypes.func,
  seatReservations: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.Set),
    PropTypes.arrayOf(
      PropTypes.shape({
        xPosition: PropTypes.number,
        yPosition: PropTypes.number,
        floorNumber: PropTypes.number,
      }),
    ),
  ]),
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set),
  numPassengers: PropTypes.number,
  renderFor: PropTypes.string.isRequired,
  enabledFloor: PropTypes.number,
};

Floor.defaultProps = {
  sourceLocationId: null,
  destinationLocationId: null,
  itineraryId: null,
  handleSeatDeselection: null,
  seatReservations: null,
  seatSelectionChangesInProgress: null,
  numPassengers: null,
  enabledFloor: null,
};

export default Floor;
