import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BASE_PRICE_PER_KILOMETER_PATH } from '../../../../config/paths';

const EditBasePricePerKilometerButton = ({ basePricePerKilometerId }) => (
  <Link
    className="btn btn-primary"
    to={`${BASE_PRICE_PER_KILOMETER_PATH}/${basePricePerKilometerId}/edit`}
  >
    Editar
  </Link>
);

EditBasePricePerKilometerButton.propTypes = {
  basePricePerKilometerId: PropTypes.number.isRequired,
};

export default EditBasePricePerKilometerButton;
