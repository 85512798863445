import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  NEW_GEOGRAPHIC_ZONE_COMBINATION_PATH,
  GEOGRAPHIC_ZONE_COMBINATION_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { GEOGRAPHIC_ZONES_COMBINATION_COLUMNS } from '../../../../config/columns';
import {
  clearGeographicZoneCombinations,
  getGeographicZoneCombinations,
} from '../../../../actions/cargo/GeographicZonesCombination';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class GeographicZoneCombinations extends Component {
  componentWillUnmount() {
    const { dispatchClearGeographicZoneCombinations } = this.props;
    dispatchClearGeographicZoneCombinations();
  }

  render() {
    const {
      geographicZoneCombinations,
      loading,
      breadcrumbs,
      dispatchGetGeographicZoneCombinations,
    } = this.props;

    const data = geographicZoneCombinations.get('content') || [];
    const pages = geographicZoneCombinations.get('totalPages') || null;
    const defaultPageSize =
      geographicZoneCombinations.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Combinaciones de Zonas Geográficas"
        buttonPath={NEW_GEOGRAPHIC_ZONE_COMBINATION_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: GEOGRAPHIC_ZONES_COMBINATION_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: false,
          fetchData: dispatchGetGeographicZoneCombinations,
          modelPath: GEOGRAPHIC_ZONE_COMBINATION_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetGeographicZoneCombinations: getGeographicZoneCombinations,
  dispatchClearGeographicZoneCombinations: clearGeographicZoneCombinations,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Combinaciones de Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_COMBINATION_PATH,
    },
  ],
  geographicZoneCombinations: CargoUnit.GeographicZonesCombination.getIn([
    'all',
    'content',
  ]),
  loading: CargoUnit.GeographicZonesCombination.getIn(['all', 'loading']),
});

GeographicZoneCombinations.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  geographicZoneCombinations: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetGeographicZoneCombinations: PropTypes.func.isRequired,
  dispatchClearGeographicZoneCombinations: PropTypes.func.isRequired,
};

GeographicZoneCombinations.defaultProps = {
  geographicZoneCombinations: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeographicZoneCombinations);
