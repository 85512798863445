import React from 'react';
import PropTypes from 'prop-types';

const FileDetailResource = ({ file }) => {
  let resource = null;

  if (file) {
    resource = (
      <div className="shadow border p-3 mt-2 bg-white rounded">
        <h5>Archivo seleccionado</h5>
        <div>
          <strong>Nombre:</strong> {file.name}
        </div>
        <div>
          <strong>Tipo:</strong> {file.type}
        </div>
        <div>
          <strong>Tamaño en Megabytes:</strong>{' '}
          {(file.size / 1024 / 1024).toFixed(2)} MB
        </div>
      </div>
    );
  }

  return resource;
};

FileDetailResource.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
};

FileDetailResource.defaultProps = {
  file: null,
};

export default FileDetailResource;
