import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BUS_FUEL_GROUP_PATH } from '../../../../config/paths';
import { getBusFuelGroup, clearBusFuelGroup } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import BusFuelGroupToolbar from './BusFuelGroupToolbar';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

class BusFuelGroup extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    const { dispatchGetBusFuelGroup } = this.props;
    dispatchGetBusFuelGroup(id);
  }

  componentWillUnmount() {
    const { dispatchClearBusFuelGroup } = this.props;
    dispatchClearBusFuelGroup();
  }

  render() {
    const { breadcrumbs, busFuelGroup, loading } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (busFuelGroup.isEmpty()) {
      content = <NoDataResource returnPage={BUS_FUEL_GROUP_PATH} />;
    } else {
      content = (
        <Fragment>
          <ResourceProperty label="Nombre:">
            {busFuelGroup.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {busFuelGroup.get('description')}
          </ResourceProperty>
          <ResourceProperty label="Rendimiento Km/Galón:">
            {busFuelGroup.get('performance')
              ? busFuelGroup.get('performance').toFixed(2)
              : null}
          </ResourceProperty>
          <h3>Buses</h3>
          {busFuelGroup.get('registeredBusList').map((registeredBus) => (
            <ResourceProperty
              key={registeredBus.id}
              label={`${registeredBus.companyBusId} :`}
            >
              {registeredBus.serviceType}
            </ResourceProperty>
          ))}
          <InternalResource
            id={busFuelGroup.get('id')}
            createDate={busFuelGroup.get('createDate')}
            lastUpdate={busFuelGroup.get('lastUpdate')}
          />
        </Fragment>
      );

      toolbar = <BusFuelGroupToolbar busFuelGroupId={busFuelGroup.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Bus por consumo de combustible"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

BusFuelGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBusFuelGroup: PropTypes.func.isRequired,
  dispatchClearBusFuelGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  busFuelGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
};

BusFuelGroup.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Bus por consumo de combustible',
      href: BUS_FUEL_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  busFuelGroup: TrafficUnit.BusFuelGroup.getIn(['current', 'content']),
  loading: !TrafficUnit.BusFuelGroup.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetBusFuelGroup: getBusFuelGroup,
  dispatchClearBusFuelGroup: clearBusFuelGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusFuelGroup);
