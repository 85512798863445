import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  postCallCenterReport,
  clearErrorPostingCallCenterReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import CallCenterReportForm from './CallCenterReportForm';
import {
  NEW_CALL_CENTER_REPORT_PATH,
  CALL_CENTER_REPORTS_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';

class NewCallCenterReport extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    postCallCenterReport: PropTypes.func.isRequired,
    clearErrorPostingCallCenterReport: PropTypes.func.isRequired,
  };
  componentWillUnmount() {
    this.props.clearErrorPostingCallCenterReport();
  }
  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte Call Center</h1>
            <p id="text">Crear un nuevo reporte Call Center.</p>
          </Col>
        </Row>
        <CallCenterReportForm
          onSubmit={this.props.postCallCenterReport}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            force: true,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Call Center',
      href: CALL_CENTER_REPORTS_PATH,
    },
    {
      text: 'Crear',
      href: NEW_CALL_CENTER_REPORT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  postCallCenterReport,
  clearErrorPostingCallCenterReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCallCenterReport);
