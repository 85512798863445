import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AGENCY_GROUP_PATH } from '../../../../config/paths';

const AgencyGroupEditButton = ({ agencyGroupId }) => (
  <Link
    className="btn btn-primary"
    to={`${AGENCY_GROUP_PATH}/${agencyGroupId}/edit`}
  >
    Editar
  </Link>
);

AgencyGroupEditButton.propTypes = {
  agencyGroupId: PropTypes.number.isRequired,
};

export default AgencyGroupEditButton;
