import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { CARGO_ITEM_STATUS_OPTIONS } from '../../../config/constants';

const CargoItemStatusFilter = ({ onChangeFilter }) => (
  <ReactSelect
    isSearchable={false}
    isClearable
    menuPortalTarget={document.body}
    styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
    onChange={(option) => onChangeFilter(option ? option.value : null)}
    menuPosition="fixed"
    menuPlacement="bottom"
    placeholder="Selecciona ..."
    options={CARGO_ITEM_STATUS_OPTIONS}
  />
);

CargoItemStatusFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default CargoItemStatusFilter;
