import Immutable from 'immutable';
import {
  FLAG_GETTING_EXPENSE_STATUS,
  GET_EXPENSE_STATUS,
  CLEAR_EXPENSE_STATUS,
  GET_EXPENSE_STATUS_REMARK,
  CLEAR_EXPENSE_STATUS_REMARK,
  FLAG_EXPENSE_STATUS_REMARK_ACTIVITY,
} from '../../../actions/types/sales';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  remark: Immutable.Map({
    content: Immutable.Set(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_EXPENSE_STATUS:
      return state.setIn(['all', 'loading'], payload);
    case GET_EXPENSE_STATUS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_EXPENSE_STATUS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case GET_EXPENSE_STATUS_REMARK:
      return state.setIn(['remark', 'content'], Immutable.Set(payload));
    case CLEAR_EXPENSE_STATUS_REMARK:
      return state.setIn(['remark'], INITIAL_STATE.getIn(['remark']));
    case FLAG_EXPENSE_STATUS_REMARK_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['remark', 'activity'],
          state.getIn(['remark', 'activity']).push(payload),
        );
      }
      return state.setIn(
        ['remark', 'activity'],
        state.getIn(['remark', 'activity']).pop(),
      );
    default:
      return state;
  }
};
