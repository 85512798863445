import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../layout/Card';
import ModuleHome from '../../layout/ModuleHome';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

const ReportHome = ({ cards, breadcrumbs }) => (
  <ModuleHome
    title="Reportes"
    text="Éste es el módulo de reportes."
    cards={cards}
    breadcrumbs={breadcrumbs}
  />
);

const mapStateToProps = ({ ReportUnit }) => ({
  cards: ReportUnit.UnitHome.get('cards'),
  breadcrumbs: ReportUnit.UnitHome.get('breadcrumbs'),
});

ReportHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default connect(mapStateToProps)(ReportHome);
