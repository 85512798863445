import React, { Component, Fragment } from 'react';
import { FieldArray, Field } from 'redux-form';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Button,
  Col,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

class DynamicForm extends Component {
  constructor(props) {
    super(props);

    this.state = { rows: [] };
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    this.setState({ rows: this.props.initialValues });
  };

  onChangeComponent = (column, index, option) => {
    const rowValues = [...this.state.rows];
    if (!option) {
      delete rowValues[index][column.name];
    } else if (typeof option === 'string' && !option.trim()) {
      delete rowValues[index][column.name];
    } else {
      // Get the row value state acoording to the event parent container
      const indexValue = rowValues[index];
      rowValues[index] = {
        ...indexValue,
        [column.name]: option,
      };
    }

    this.setState({ rows: rowValues });

    column.onChange(index, option);
  };

  getValue = (column, index) => {
    const { rows } = this.state;

    let value = null;

    if (rows.length && rows[index][column.dependentKey]) {
      value = rows[index][column.dependentKey][column.name];

      if (column.Cell) value = column.Cell(value);
    }

    return value;
  };

  addNewFielGroup = (fields) => {
    const { onClickAdd } = this.props;

    const { rows } = this.state;
    const rowsState = [...rows, {}];
    this.setState({ rows: rowsState });

    if (onClickAdd) onClickAdd(fields);
    else fields.push({});
  };

  removeFieldGroup = (fields, index) => {
    const { onClickRemove } = this.props;

    const rowsState = [...this.state.rows];
    // Remove selected row from state
    rowsState.splice(index, 1);
    this.setState({ rows: rowsState });

    if (onClickRemove) onClickRemove(index, fields);
    else fields.remove(index);
  };

  renderNestedFields = ({ fields, columns }) => {
    const { showRemoveButton, nestedTittle, showAddButton } = this.props;

    const fieldGroups = fields.map((fieldName, index) => {
      const renderRemoveButton = showRemoveButton && (
        <Fragment>
          <Button
            type="button"
            color="danger"
            outline
            onClick={() => fields.remove(index)}
          >
            <i className="fa fa-minus-circle" /> Eliminar
          </Button>
        </Fragment>
      );

      const renderTitle = nestedTittle && (
        <h5>
          {nestedTittle} {index + 1}
        </h5>
      );

      return (
        <ListGroupItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {renderTitle}
          <FormGroup row style={{ marginBottom: 0 }}>
            {this.renderField(columns, index, fieldName, true)}
          </FormGroup>
          {renderRemoveButton}
        </ListGroupItem>
      );
    });

    const addButton = showAddButton ? (
      <Button
        type="button"
        color="primary"
        outline
        className="mt-2"
        onClick={() => fields.push({})}
      >
        <i className="fa fa-plus-circle" /> Agregar
      </Button>
    ) : null;

    return (
      <div className="mt-2 mb-4">
        <ListGroup flush>{fieldGroups}</ListGroup>
        {addButton}
      </div>
    );
  };

  renderField = (columns, index, fielName, isNested = false) =>
    columns.map((column) => {
      // Render fields without label and component
      if (column.customizedField) {
        let customizedField = null;

        if (column.customizedField === 'button') {
          const click = column.onClick ? () => column.onClick(index) : null;

          customizedField = (
            <Button
              type="button"
              onClick={click}
              outline
              className={column.class}
            >
              {column.label}
            </Button>
          );
        }

        return (
          <Col
            key={`col${column.name}${index.toString()}`}
            lg={column.lg}
            md={column.md}
            sm={column.sm}
            className="mb-2"
          >
            <Label>&nbsp;</Label>
            <div>{customizedField}</div>
          </Col>
        );
      }

      // Render dependent fields
      if (column.dependentKey) {
        return (
          <Col
            key={`col${column.name}${index.toString()}`}
            lg={column.lg}
            md={column.md}
            sm={column.sm}
            className="mb-2"
          >
            <Label for={column.name}>{column.label}</Label>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className="form-control disabled"
            >
              {this.getValue(column, index)}
            </div>
          </Col>
        );
      }

      // Render fields with ArrayColumns
      if (column.isFieldArray && !isNested) {
        return (
          <Col
            key={`col${column.name}${index.toString()}`}
            lg={column.lg}
            md={column.md}
            sm={column.sm}
            className="mb-2"
          >
            <Label>{column.label}</Label>
            <FieldArray
              name={`${fielName}.${column.name}`}
              component={this.renderNestedFields}
              columns={column.fields}
            />
          </Col>
        );
      }

      // Set props of component fields
      const props = {
        name: `${fielName}.${column.name}`,
        component: column.component,
        props: column.otherProps,
      };
      if (column.type) props.type = column.type;
      if (column.placeholder) props.placeholder = column.placeholder;
      if (column.validate) props.validate = column.validate;
      if (column.disabled) props.disabled = column.disabled;
      if (column.onChange)
        props.onChange = (option) =>
          this.onChangeComponent(column, index, option);

      if (column.noOptionsMessage)
        props.noOptionsMessage = ({ inputValue }) =>
          column.noOptionsMessage(index, inputValue);

      const requiredLabel = column.requiredLabel ? (
        <span className="text-danger">*</span>
      ) : null;

      // Render component fields
      return (
        <Col
          key={`col${column.name}${index.toString()}`}
          lg={column.lg}
          md={column.md}
          sm={column.sm}
          className="mb-2"
        >
          <Label for={column.name}>
            {column.label} {requiredLabel}
          </Label>
          <Field {...props} />
        </Col>
      );
    });

  renderFieldGroups = (
    columns,
    fields,
    title,
    showRemoveButton,
    otherButtonsPerRow,
  ) => {
    let fieldGroup = <>No hay elementos</>;

    if (fields.length > 0) {
      fieldGroup = fields.map((fielName, index) => {
        let renderOtherButtonsPerRow = null;

        if (otherButtonsPerRow) {
          renderOtherButtonsPerRow = otherButtonsPerRow.map(
            (button, buttonIndex) => (
              <Button
                key={`otherButtons${buttonIndex.toString()}`}
                color={button.color}
                outline
                onClick={() => button.onClick(index)}
              >
                {button.label}
              </Button>
            ),
          );
        }

        const renderRemoveButton = showRemoveButton && (
          <Fragment>
            <Button
              type="button"
              color="danger"
              outline
              onClick={() => this.removeFieldGroup(fields, index)}
              onKeyPress={() => this.removeFieldGroup(fields, index)}
            >
              <i className="fa fa-minus-circle" /> Eliminar
            </Button>{' '}
            {renderOtherButtonsPerRow}
          </Fragment>
        );

        const renderTitle = title && (
          <h5>
            {title} {index + 1}
          </h5>
        );

        return (
          <ListGroupItem
            key={+index.toString()}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {renderTitle}
            <FormGroup
              key={`form${index.toString()}`}
              row
              style={{ marginBottom: 0 }}
            >
              {this.renderField(columns, index, fielName)}
            </FormGroup>
            {renderRemoveButton}
          </ListGroupItem>
        );
      });
    }

    return fieldGroup;
  };

  renderDynamicForm = ({ fields }) => {
    const {
      columns,
      showRemoveButton,
      title,
      otherButtonsPerRow,
      showAddButton,
    } = this.props;

    const fieldGroups = this.renderFieldGroups(
      columns,
      fields,
      title,
      showRemoveButton,
      otherButtonsPerRow,
    );

    const addButton = showAddButton ? (
      <Button
        type="button"
        color="primary"
        outline
        className="mt-2"
        onClick={() => this.addNewFielGroup(fields)}
      >
        <i className="fa fa-plus-circle" /> Agregar
      </Button>
    ) : null;

    return (
      <div className="mt-2 mb-4">
        <ListGroup flush>{fieldGroups}</ListGroup>
        {addButton}
      </div>
    );
  };

  render() {
    const { name } = this.props;

    return <FieldArray name={name} component={this.renderDynamicForm} />;
  }
}

DynamicForm.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      label: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      name: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      validate: PropTypes.arrayOf(PropTypes.func),
      dependentKey: PropTypes.string,
      Cell: PropTypes.func,
      isFieldArray: PropTypes.bool,
    }),
  ).isRequired,
  onClickRemove: PropTypes.func,
  onClickAdd: PropTypes.func,
  showRemoveButton: PropTypes.bool,
  showAddButton: PropTypes.bool,
  title: PropTypes.string,
  nestedTittle: PropTypes.string,
  otherButtonsPerRow: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.arrayOf(PropTypes.object),
};

DynamicForm.defaultProps = {
  onClickRemove: null,
  onClickAdd: null,
  showRemoveButton: true,
  showAddButton: true,
  title: null,
  nestedTittle: null,
  otherButtonsPerRow: [],
  initialValues: [],
};

export default DynamicForm;
