import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MacivaManagementHome from './MacivaManagementHome';

const MacivaManagementModule = () => (
  <Switch>
    <Route exact path="/maciva-management" component={MacivaManagementHome} />
  </Switch>
);

export default MacivaManagementModule;
