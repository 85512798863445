import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import TableToolbar from '../../../common/TableToolbar';
import {
  NOT_BOARDED_TICKET_REPORTS_PATH,
  NEW_NOT_BOARDED_TICKET_REPORT_PATH,
} from '../../../../config/paths';
import Table from '../../../common/Table';
import {
  clearNotBoardedTicketReports,
  getNotBoardedTicketReports,
  clearErrorGettingNotBoardedTicketReports,
} from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import { REPORT_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

class NotBoardedTicketReports extends Component {
  constructor(props) {
    super(props);
    this.columns = REPORT_COLUMNS;
  }

  componentWillUnmount() {
    this.props.clearErrorGettingNotBoardedTicketReports();
    this.props.clearNotBoardedTicketReports();
  }

  renderContent() {
    const { reports, loading, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    const { columns } = this;

    if (reports) {
      data = reports.get('content');
      pages = reports.get('totalPages');
      defaultPageSize = reports.get('size');
    }

    return (
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={this.props.getNotBoardedTicketReports}
        modelPath={NOT_BOARDED_TICKET_REPORTS_PATH}
        loading={loading}
        navigateToModelOnRowClick
      />
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <TableToolbar buttonPath={NEW_NOT_BOARDED_TICKET_REPORT_PATH} />
        <h1>Reportes de Boletos No Abordados</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos No Abordados',
      href: NOT_BOARDED_TICKET_REPORTS_PATH,
    },
  ],
  reports: ReportUnit.NotBoardedTicketReport.get('all').get('content'),
  loading: ReportUnit.NotBoardedTicketReport.get('all').get('loading'),
  error: ReportUnit.NotBoardedTicketReport.get('all').get('error'),
});

const mapDispatchToProps = {
  getNotBoardedTicketReports,
  clearNotBoardedTicketReports,
  clearErrorGettingNotBoardedTicketReports,
};

NotBoardedTicketReports.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  getNotBoardedTicketReports: PropTypes.func.isRequired,
  clearNotBoardedTicketReports: PropTypes.func.isRequired,
  clearErrorGettingNotBoardedTicketReports: PropTypes.func.isRequired,
  reports: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

NotBoardedTicketReports.defaultProps = {
  reports: null,
  loading: false,
  error: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotBoardedTicketReports);
