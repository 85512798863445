import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import PrintStationEditButton from './PrintStationEditButton';
import PrintStationDeleteButton from './PrintStationDeleteButton';

const PrintStationToolbar = ({ printStationId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PrintStationEditButton printStationId={printStationId} />{' '}
      <PrintStationDeleteButton printStationId={printStationId} />
    </div>
  </ButtonToolbar>
);

PrintStationToolbar.propTypes = {
  printStationId: PropTypes.number.isRequired,
};

export default PrintStationToolbar;
