import Immutable from 'immutable';
import {
  FLAG_GETTING_DELAY_REASONS,
  GET_DELAY_REASONS,
  CLEAR_DELAY_REASONS,
} from '../../../actions/types/itinerary/DelayReason';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_GETTING_DELAY_REASONS:
      return state.setIn(['all', 'loading'], payload);
    case GET_DELAY_REASONS:
      return state.setIn(['all', 'content'], Immutable.Set(payload));
    case CLEAR_DELAY_REASONS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    default:
      return state;
  }
};
