import { arrayOf, shape, number, string } from 'prop-types';
import { endpointBasicInformationPropTypes } from '../../endpoint/proptypes/EndpointPropTypes';
import { viewBasicInformationPropTypes } from '../../view/proptypes/ViewPropTypes';

const viewAuthorizationListPropTypes = {
  viewAuthorizationList: arrayOf(
    shape({
      id: number,
      securityProfileId: number,
      viewId: number,
      view: shape(viewBasicInformationPropTypes),
    }),
  ),
};

const authorizedEndpointListPropTypes = {
  authorizedEndpointList: arrayOf(
    shape({
      id: number,
      securityProfileId: number,
      endpointId: number,
      endpoint: shape(endpointBasicInformationPropTypes),
    }),
  ),
};

const securityProfileBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string,
  securityVisibility: string,
  ...viewAuthorizationListPropTypes,
  ...authorizedEndpointListPropTypes,
};

export default securityProfileBasicInformationPropTypes;
