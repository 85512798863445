import { DATE_TIME_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';
import { numberFormatter } from '../number';
import {
  addQrCode,
  addText,
  createPdfDocument,
  paragraphSize,
  setFont,
  y,
} from '../pdf';

const myX = 3;
let myY = y;
const margin = 3;

const preFooter =
  'Representación Impresa del Comprobante de Venta\n' +
  'Electronica, Puede consultarlo en: www.civa.com.pe/\n' +
  'Autorizado mediante Resolución de Intendencia:\n' +
  'Número 0180050002242/SUNAT\n' +
  'Términos y Condiciones: www.civa.com.pe/\n';
const footer =
  'Al recibir el presente documento acepto todos los Términos\n' +
  'del Contrato del servicio de Transporte detallado en el\n' +
  'letrero, banner y/o Panel a la vista ubicados en el counter\n' +
  'de ventas al momento de la compra. Para mayor información\n' +
  'sobre todos los términos y condiciones en nuestra página\n' +
  'Web http://civa.com.pe/';

const qrWidth = 35;
const qrHeight = 35;

const printLine = (doc, factor = 1) => {
  addText(
    doc,
    '----------------------------------------------------------------------------',
    40,
    (myY += margin * factor),
    80,
  );
};

const printCompanyFields = (doc) => {
  setFont(doc, 'bold');
  doc.setFontSize(paragraphSize);
  addText(doc, 'TURISMO CIVA S.A.C.', 40, myY, 80);
  setFont(doc);
  addText(doc, 'Si va de viaje, ¡viaje en Civa!', 40, (myY += margin), 80);
  addText(doc, 'R.U.C 20102427891', 40, (myY += margin), 80);
  addText(doc, 'JR SANCHO DE RIVERA 1184', 40, (myY += margin), 80);
  addText(doc, 'URB. MONSERRATE LIMA', 40, (myY += margin), 80);
  addText(doc, 'Central Telefónica: (511) 418-1111', 40, (myY += margin), 80);
  printLine(doc);
};

const printExcessBaggageTicketFields = (
  doc,
  { issuingAgency, createDate, voucherType, ticketCode },
) => {
  const agencyName = doc.splitTextToSize(
    `Agencia de Emisión: ${issuingAgency}`,
    75,
  );
  addText(doc, agencyName, myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `Fecha/Hora Emisión: ${createDate}`,
    myX,
    (myY += margin * agencyName.length + 0.3),
    75,
    'left',
  );
  setFont(doc, 'bold');
  doc.setFontSize(14);
  myY += 2;

  const voucherText = doc.splitTextToSize(voucherType.toUpperCase(), 75);
  addText(doc, voucherText, 40, (myY += margin), 75);

  addText(doc, ticketCode, 40, (myY += 5.5 * voucherText.length), 75);
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printItineraryFields = (
  doc,
  { routeName, departureTime, sourceLocationName, destinationLocationName },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE ITINERARIO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, 'Nombre', myX, (myY += margin), 30, 'left');
  addText(doc, ':', 21, myY, 10, 'left');
  const newRouteName = doc.splitTextToSize(routeName, 55);
  addText(doc, newRouteName, 23, myY, 57, 'left');

  addText(
    doc,
    'Fecha y Hora',
    myX,
    (myY += margin * newRouteName.length),
    30,
    'left',
  );
  addText(doc, ':', 21, myY, 10, 'left');
  const newDepartureTime = doc.splitTextToSize(departureTime, 55);
  addText(doc, newDepartureTime, 23, myY, 57, 'left');

  addText(doc, 'Embarque', myX, (myY += margin), 30, 'left');
  addText(doc, ':', 21, myY, 10, 'left');
  const newSourceLocationName = doc.splitTextToSize(sourceLocationName, 55);
  addText(doc, newSourceLocationName, 23, myY, 57, 'left');

  addText(doc, 'Desembarque', myX, (myY += margin), 30, 'left');
  addText(doc, ':', 21, myY, 10, 'left');
  const newDestinationLocationName = doc.splitTextToSize(
    destinationLocationName,
    55,
  );
  addText(doc, newDestinationLocationName, 23, myY, 57, 'left');

  setFont(doc);
  printLine(doc, newDestinationLocationName.length);
};

const printBusinessFields = (doc, { companyDocumentNumber, companyName }) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE LA EMPRESA:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(
    doc,
    `RUC: ${companyDocumentNumber}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  const newCompanyName = doc.splitTextToSize(companyName, 75);
  addText(doc, newCompanyName, myX, (myY += margin), 75, 'left');
  printLine(doc, newCompanyName.length);
};

const printPassengerFields = (
  doc,
  { fullName, identificationType, idDocumentNumber, ticketList },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL PASAJERO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, `Nombre: ${fullName}`, myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `Tipo Documento: ${identificationType}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(
    doc,
    `Numero de Documento: ${idDocumentNumber}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(doc, 'Boleto(s) de Viaje:', myX, (myY += margin), 75, 'left');
  ticketList.forEach((ticket) => {
    addText(doc, ticket.documentCode, myX, (myY += margin), 75, 'left');
  });
  setFont(doc);
  printLine(doc);
};

const printExcessBaggageFields = (
  doc,
  { ticketPrice, ticketPriceBeforeDiscount, weight },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL EXCESO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, 'Cantidad: 01', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    'Descripción: Exceso de Equipaje',
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(doc, `Peso (kg): ${weight}`, myX, (myY += margin), 75, 'left');
  const newTicketPriceBeforeDiscount = ticketPriceBeforeDiscount || weight;
  addText(
    doc,
    `Descuento: ${numberFormatter({
      value: +newTicketPriceBeforeDiscount - ticketPrice,
    })}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(
    doc,
    `Monto: ${numberFormatter({ value: ticketPrice })}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  setFont(doc);
  printLine(doc);
};

const printPaymentFields = (
  doc,
  { ticketPrice, ticketPriceString, paymentMethod, userName },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE PAGO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, 'OP. GRATUITO', myX, (myY += margin), 25, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 25, myY, 50, 'left');
  addText(doc, 'OP. EXONERADO', myX, (myY += margin), 25, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 25, myY, 50, 'left');
  addText(doc, 'OP. INAFECTO', myX, (myY += margin), 25, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 25, myY, 50, 'left');
  addText(doc, 'OP. GRAVADA', myX, (myY += margin), 25, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 25, myY, 50, 'left');
  addText(doc, 'IGV', myX, (myY += margin), 25, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 25, myY, 50, 'left');
  setFont(doc, 'bold');
  addText(
    doc,
    `IMPORTE TOTAL A PAGAR : ${numberFormatter({ value: ticketPrice })}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  setFont(doc);
  addText(doc, `Son: ${ticketPriceString}`, myX, (myY += margin), 75, 'left');
  addText(doc, 'Tipo de Pago', myX, (myY += margin), 25, 'left');
  addText(doc, `: ${paymentMethod}`, myX + 25, myY, 50, 'left');
  addText(doc, 'Numero Tarjeta', myX, (myY += margin), 25, 'left');
  addText(doc, ': -', myX + 25, myY, 50, 'left');
  addText(doc, 'Numero Voucher', myX, (myY += margin), 25, 'left');
  addText(doc, ': -', myX + 25, myY, 50, 'left');
  addText(doc, 'Usuario', myX, (myY += margin), 25, 'left');
  addText(doc, `: ${userName}`, myX + 25, myY, 50, 'left');
  addText(doc, preFooter, myX, (myY += margin * 2), 74, 'left');
  myY += 15;
  addText(doc, footer, myX, (myY += margin * 1), 74, 'left');
};

const addTicket = async (
  doc,
  {
    excessBaggageTicketFields: {
      issuingAgency,
      createDate,
      voucherType,
      ticketCode,
    },
    passengerFields: {
      fullName,
      identificationType,
      idDocumentNumber,
      ticketList,
    },
    itineraryFields: {
      routeName,
      departureTime,
      sourceLocationName,
      destinationLocationName,
    },
    excessBaggageFields: { weight },
    paymentFields: {
      ticketPrice,
      ticketPriceBeforeDiscount,
      ticketPriceString,
      paymentMethod,
      userName,
      companyDocumentNumber,
      companyName,
    },
  },
) => {
  await addQrCode(doc, ticketCode, 22.5, 0, qrWidth, qrHeight);
  myY += 32;
  printCompanyFields(doc);
  printExcessBaggageTicketFields(doc, {
    issuingAgency,
    createDate,
    voucherType,
    ticketCode,
  });
  printItineraryFields(doc, {
    routeName,
    departureTime,
    sourceLocationName,
    destinationLocationName,
  });
  printPassengerFields(doc, {
    fullName,
    identificationType,
    idDocumentNumber,
    ticketList,
  });
  // FACTURA
  if (companyDocumentNumber)
    printBusinessFields(doc, {
      companyDocumentNumber,
      companyName,
    });
  printExcessBaggageFields(doc, {
    ticketPrice,
    ticketPriceBeforeDiscount,
    weight,
  });
  printPaymentFields(doc, {
    ticketPrice,
    ticketPriceString,
    paymentMethod,
    userName,
  });
  myY = 5;
};

const formatTicket = (ticket) => {
  const transaction = ticket.transactions[0];
  const formatedTicket = {
    excessBaggageTicketFields: {
      issuingAgency: `${ticket.agency.name}\n${ticket.agency.address}`,
      createDate: tzNormalizeDate({
        date: ticket.documentAssignmentDate,
        format: DATE_TIME_FORMAT,
      }),
      voucherType: transaction.voucherType.name,
      ticketCode: ticket.fullDocumentCode,
    },
    passengerFields: {
      fullName: ticket.customer.fullName,
      identificationType: ticket.customer.identificationType.name,
      idDocumentNumber: ticket.customer.idDocumentNumber,
      ticketList: ticket.ticketList,
    },
    itineraryFields: {
      routeName: ticket.itinerary.routeName,
      departureTime: tzNormalizeDate({
        date: ticket.itinerary.departureTime,
        format: DATE_TIME_FORMAT,
      }),
      sourceLocationName: ticket.itinerary.sourceLocation.name,
      destinationLocationName: ticket.itinerary.destinationLocation.name,
    },
    excessBaggageFields: {
      weight: ticket.weight,
    },
    paymentFields: {
      ticketPrice: ticket.price,
      ticketPriceBeforeDiscount: ticket.priceBeforeDiscount,
      ticketPriceString: ticket.priceString,
      paymentMethod: transaction.paymentMethod.name,
      userName: ticket.user.customer.fullName,
      companyDocumentNumber: ticket.business
        ? ticket.business.businessTaxId
        : null,
      companyName: ticket.business ? ticket.business.name : null,
    },
  };

  return formatedTicket;
};

const printExcessBaggage = async (ticket) => {
  const doc = createPdfDocument('p', 'mm', [80, 260]);
  const formatedTicket = formatTicket(ticket);
  await addTicket(doc, formatedTicket);
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

// eslint-disable-next-line import/prefer-default-export
export { printExcessBaggage };
