import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

// eslint-disable-next-line react/prefer-stateless-function
export class DeliveryGroupForm extends Component {
  render() {
    const { handleSubmit, loading } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Nombre" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Nombre"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

DeliveryGroupForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

DeliveryGroupForm.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.DeliveryGroup.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DeliveryGroupForm',
})(DeliveryGroupForm);

export default connect(mapStateToProps, null)(formComponent);
