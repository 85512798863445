import JsPdf from 'jspdf';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../config/locale';
import { tzNormalizeDate } from '../date';
import CivaLogoPng from '../../resources/images/logo-civa.png';
import ChinalcoLogoPng from '../../resources/images/logo-chinalco.jfif';
import PhoneIcon from '../../resources/images/phone.jpg';
import WorldIcon from '../../resources/images/world.jpg';
import FacebookIcon from '../../resources/images/facebook.jpg';
import InstagramIcon from '../../resources/images/instagram.png';
import LinkedInIcon from '../../resources/images/linkedin.jpg';

const x = 5;
let y = 5;
const margin = 3;

const chinalcoLogoWidth = 20;
const chinalcoLogoHeight = 20;

const civaLogoWidth = 35;
const civaLogoHeight = 10;

const font = 'helvetica';

const printCenterText = (doc, text) => {
  doc.text(38, (y += margin), text, null, null, 'center');
};

const printLine = (doc) => {
  doc.text(
    38,
    (y += margin),
    '--------------------------------------------------------------------------------------',
    null,
    null,
    'center',
  );
};

const printTitle = (doc, text) => {
  doc.setFont(font, 'bold');
  doc.setFontSize(8);
  doc.text(text, x, (y += margin * 2));
  doc.setFont(font, 'normal');
};

const printCompanyFields = (doc) => {
  doc.setFont(font, 'bold');
  doc.setFontSize(8);
  doc.text(38, y, 'TURISMO CIVA S.A.C.', null, null, 'center');
  doc.setFont(font, 'normal');
  doc.text(
    38,
    (y += margin),
    'Si va de viaje, ¡viaje en Civa!',
    null,
    null,
    'center',
  );
  doc.text(38, (y += margin), 'R.U.C 20102427891', null, null, 'center');
  doc.text(38, (y += margin), 'JR SANCHO DE RIVERA 1184', null, null, 'center');
  doc.text(38, (y += margin), 'URB. MONSERRATE LIMA', null, null, 'center');
  doc.text(
    38,
    (y += margin),
    'Central Telefónica: (511)418-1111',
    null,
    null,
    'center',
  );
  printLine(doc);
};

const printFooter = (doc) => {
  y += 31;
  doc.setFont(font, 'normal');
  doc.setFontSize(4.5);

  // Filled square
  doc.rect(0, y, 80, 8, 'F');

  y += 1.5;

  // Black square with rounded corners
  doc.setDrawColor(1); // set white draw color
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(3, y, 32, 5, 2, 2, 'FD');

  doc.setDrawColor(0); // set black draw color
  doc.setLineWidth(0.3);
  doc.line(29, y, 29, y + 6); // vertical line

  // DRAW WEB SITE FIELDS
  // Black square with rounded corners
  doc.setDrawColor(1); // set white draw color
  doc.roundedRect(37, y, 18, 5, 2, 2, 'FD');

  doc.setDrawColor(0); // set black draw color
  doc.setLineWidth(0.3);
  doc.line(49, y, 49, y + 6); // vertical line

  // DRAW SOCIAL MEDIA
  // Black square with rounded corners
  doc.setDrawColor(1); // set white draw color
  doc.roundedRect(57, y, 5, 5, 2, 2, 'FD'); // Facebook
  doc.roundedRect(64, y, 5, 5, 2, 2, 'FD'); // Instagram
  doc.roundedRect(71, y, 5, 5, 2, 2, 'FD'); // LinkedIn

  y += margin;

  doc.text('Contact Center: (511) 418 1111', x, y);
  doc.text('civa.com.pe', x + 34, y);

  // Add phone icon
  const phoneImage = new Image();
  phoneImage.src = PhoneIcon;
  doc.addImage(phoneImage, 'jpg', 30.5, y - 2, 3, 3);

  // Add www icon
  const worldImage = new Image();
  worldImage.src = WorldIcon;
  doc.addImage(worldImage, 'jpg', 50, y - 2, 3.5, 3.5);

  // Add facebook icon
  const facebookImage = new Image();
  facebookImage.src = FacebookIcon;
  doc.addImage(facebookImage, 'jpg', 58.5, y - 2, 2, 3);

  // Add instagram icon
  const instagramImage = new Image();
  instagramImage.src = InstagramIcon;
  doc.addImage(instagramImage, 'png', 65, y - 2, 3, 3);

  // Add linkedin icon
  const linkedInImage = new Image();
  linkedInImage.src = LinkedInIcon;
  doc.addImage(linkedInImage, 'jpg', 72, y - 2, 3, 3);
};

const printReservationFields = (doc, { createDate, ticketCode }) => {
  doc.text(`Fecha/Hora Emisión: ${createDate}`, x, (y += margin * 2));
  doc.setFont(font, 'bold');
  doc.setFontSize(14);
  y += 2;
  const voucherText = doc.splitTextToSize(
    'CONSTANCIA DE RESERVA'.toUpperCase(),
    50,
  );
  printCenterText(doc, voucherText);
  y += 8;
  printCenterText(doc, ticketCode);
  doc.setFontSize(8);
  doc.setFont(font, 'normal');
  printLine(doc);
};

const printTripFields = (
  doc,
  {
    origin,
    boarding,
    boardingAddress,
    disembarking,
    destination,
    disembarkingAddress,
    tripDate,
    tripTime,
    seatNumber,
    floor,
    serviceType,
  },
) => {
  printTitle(doc, 'DATOS DE VIAJE:');
  doc.text('Origen', x, (y += margin));
  doc.text(`: ${origin}`, 25, y);
  doc.setFont(font, 'bold');
  doc.text('Embarque', x, (y += margin));
  doc.text(`: ${boarding}`, 25, y);
  doc.text(`  ${boardingAddress}`, 25, (y += margin));
  doc.text('Desembarque', x, (y += margin));
  doc.text(`: ${disembarking}`, 25, y);
  doc.setFont(font, 'normal');
  doc.text('Destino', x, (y += margin));
  doc.text(`: ${destination}`, 25, y);
  doc.text(`  ${disembarkingAddress}`, 25, (y += margin));
  doc.text('Fecha', x, (y += margin * 2));
  doc.text(`: ${tripDate}`, 15, y);
  doc.text('Hora', 40, y);
  doc.text(`: ${tripTime}`, 50, y);
  doc.text('Asiento', x, (y += margin));
  doc.text(`: ${seatNumber}`, 15, y);
  doc.text('Piso', 40, y);
  doc.text(`: ${floor}`, 50, y);
  doc.text('Servicio', x, (y += margin));
  doc.text(`: ${serviceType}`, 15, y);
  doc.setFont(font, 'bold');
  doc.setFontSize(14);
  y += margin * 2;
  doc.text('*Presentarse 30 minutos antes', x, (y += margin));
  doc.setFontSize(8);
  doc.setFont(font, 'normal');
  printLine(doc);
};

const printPassengerFields = (
  doc,
  { fullName, identificationType, idDocumentNumber },
) => {
  printTitle(doc, 'DATOS DEL PASAJERO:');
  doc.text(`Nombre: ${fullName}`, x, (y += margin));
  doc.text(`Tipo Documento: ${identificationType}`, x, (y += margin));
  doc.text(`Numero de Documento: ${idDocumentNumber}`, x, (y += margin));
};

const addReservation = async (
  doc,
  {
    ticketFields: { issuingAgency, createDate, voucherType, ticketCode },
    tripFields: {
      origin,
      boarding,
      boardingAddress,
      disembarking,
      destination,
      disembarkingAddress,
      tripDate,
      tripTime,
      seatNumber,
      floor,
      serviceType,
      foodType = '',
    },
    passengerFields: { fullName, identificationType, idDocumentNumber },
  },
) => {
  const chinalcoLogo = new Image();
  chinalcoLogo.src = ChinalcoLogoPng;
  doc.addImage(
    chinalcoLogo,
    'jfif',
    30,
    5,
    chinalcoLogoWidth,
    chinalcoLogoHeight,
  );
  const civaLogo = new Image();
  civaLogo.src = CivaLogoPng;
  doc.addImage(civaLogo, 'png', 20, 25, civaLogoWidth, civaLogoHeight);
  y += 35;
  printCompanyFields(doc);
  printReservationFields(doc, {
    issuingAgency,
    createDate,
    voucherType,
    ticketCode,
  });
  printTripFields(doc, {
    origin,
    boarding,
    boardingAddress,
    disembarking,
    destination,
    disembarkingAddress,
    tripDate,
    tripTime,
    seatNumber,
    floor,
    serviceType,
    foodType,
  });
  printPassengerFields(doc, {
    fullName,
    identificationType,
    idDocumentNumber,
  });
  printFooter(doc);
  y = 5;
};

const formatPrintedReservation = (printedReservation) => {
  const formatedPrintedReservation = {
    ticketFields: {
      issuingAgency: printedReservation.issuingAgency,
      createDate: printedReservation.createDate,
      voucherType: printedReservation.voucherType,
      ticketCode: printedReservation.ticketCode,
    },
    tripFields: {
      origin: printedReservation.origin,
      boarding: printedReservation.boarding,
      boardingAddress: printedReservation.boardingAddress,
      disembarking: printedReservation.disembarking,
      destination: printedReservation.destination,
      disembarkingAddress: printedReservation.disembarkingAddress,
      tripDate: printedReservation.tripDate,
      tripTime: printedReservation.tripTime,
      seatNumber: printedReservation.seatNumber,
      floor: printedReservation.floor,
      serviceType: printedReservation.serviceType,
      foodType: printedReservation.foodType,
    },
    passengerFields: {
      fullName: printedReservation.passenger.fullName,
      identificationType: printedReservation.passenger.identificationType.name,
      idDocumentNumber: printedReservation.passenger.idDocumentNumber,
    },
    paymentFields: {
      ticketPrice: printedReservation.ticketPrice,
      ticketPriceString: printedReservation.ticketPriceString,
      paymentMethod: printedReservation.paymentMethod,
      userName: printedReservation.userName,
      companyDocumentNumber: printedReservation.companyDocumentNumber,
      companyName: printedReservation.companyName,
    },
  };
  return formatedPrintedReservation;
};

const printPrintedReservations = async (printedReservations) => {
  const height = 185;
  const doc = new JsPdf('p', 'mm', [80, height]);
  doc.setFont(font);
  // using for-of-loop for readability when using await inside a loop
  // where await is needed due to requirement of sequential steps
  // check for discussion: http://bit.ly/2JcMMLk
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, printedReservation] of printedReservations.entries()) {
    if (index > 0) {
      doc.addPage('p', 'mm', [80, height]);
      y = 5;
    }

    const formatedPrintedReservation =
      formatPrintedReservation(printedReservation);
    // eslint-disable-next-line no-await-in-loop
    await addReservation(doc, formatedPrintedReservation);
  }
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

const formatReservation = (ticket) => {
  const transaction = ticket.transactions[0];
  const formatedReservation = {
    ticketFields: {
      issuingAgency: `${ticket.agency.name}\n${ticket.agency.address}`,
      createDate: tzNormalizeDate({
        date: ticket.documentAssignmentDate,
        format: DATE_TIME_FORMAT,
      }),
      voucherType: transaction.voucherType.name,
      ticketCode: ticket.fullDocumentCode,
    },
    tripFields: {
      origin: ticket.sourceLocation.name,
      boarding: ticket.sourceLocation.name,
      boardingAddress: ticket.sourceLocation.address,
      disembarking: ticket.destinationLocation.name,
      destination: ticket.destinationLocation.name,
      disembarkingAddress: ticket.destinationLocation.address,
      tripDate: tzNormalizeDate({
        date: ticket.departureDate,
        format: DATE_FORMAT,
      }),
      tripTime: tzNormalizeDate({
        date: ticket.departureDate,
        format: TIME_FORMAT,
      }),
      seatNumber: ticket.seatReservation.seatNumber,
      floor: ticket.seatReservation.floorNumber,
      serviceType: ticket.itinerary.serviceType.name,
      foodType: ticket.food ? ticket.food.name : '',
    },
    passengerFields: {
      fullName: ticket.customer.fullName,
      identificationType: ticket.customer.identificationType.name,
      idDocumentNumber: ticket.customer.idDocumentNumber,
    },
    paymentFields: {
      ticketPrice: ticket.salePrice,
      ticketPriceString: ticket.salePriceString,
      paymentMethod: transaction.paymentMethod.name,
      userName: ticket.user.customer.fullName,
      companyDocumentNumber: transaction.business
        ? transaction.business.businessTaxId
        : undefined,
      companyName: transaction.business ? transaction.business.name : undefined,
    },
  };

  return formatedReservation;
};

const printReservation = async (ticket) => {
  const height = 185;
  const doc = new JsPdf('p', 'mm', [80, height]);
  doc.setFont(font);
  const formatedReservation = formatReservation(ticket);
  await addReservation(doc, formatedReservation);
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

export { printPrintedReservations, printReservation };
