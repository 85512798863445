import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { SEGMENT_PATH } from '../../../../config/paths';
import { getSegment, clearSegment } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import AddressResource from '../../../common/resource/AddressResource';
import SegmentToolbar from './SegmentToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import { KILOMETER_SYMBOL, MINUTE_SYMBOL } from '../../../../config/constants';
import Table from '../../../common/Table';
import Alert from '../../../common/informative/Alert';
import { SEGMENT_WITHOUT_TOLL_MESSAGE } from '../../../../config/messages';
import { numberFormatter } from '../../../../utils/number';

class Segment extends Component {
  componentDidMount() {
    const {
      dispatchGetSegment,
      match: {
        params: { id: segmentId },
      },
    } = this.props;

    dispatchGetSegment({ segmentId });
  }

  componentWillUnmount() {
    const { dispatchClearSegment } = this.props;
    dispatchClearSegment();
  }

  render() {
    const { breadcrumbs, segment, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (segment.isEmpty()) {
      content = <NoDataResource returnPage={SEGMENT_PATH} />;
    } else {
      const sourceLocationResource = (
        <Fragment>
          <h3>Origen</h3>
          <ResourceProperty label="Nombre:">
            {segment.get('sourceLocation').name}
          </ResourceProperty>
          <AddressResource address={segment.get('sourceLocation').address} />
        </Fragment>
      );

      const destinationLocationResource = (
        <Fragment>
          <h3>Destino</h3>
          <ResourceProperty label="Nombre:">
            {segment.get('destinationLocation').name}
          </ResourceProperty>
          <ResourceProperty label="Dirección:">
            <AddressResource
              address={segment.get('destinationLocation').address}
            />
          </ResourceProperty>
        </Fragment>
      );

      const travelResource = (
        <Fragment>
          <h3>Viaje</h3>
          <ResourceProperty label="Distancia:">
            {segment.get('distance')} {KILOMETER_SYMBOL}
          </ResourceProperty>
          <ResourceProperty label="Duración:">
            {segment.get('duration')} {MINUTE_SYMBOL}
          </ResourceProperty>
        </Fragment>
      );

      const tollData = segment.get('tollSegmentList');
      let tollList = (
        <Alert type="warning" message={SEGMENT_WITHOUT_TOLL_MESSAGE} />
      );
      if (tollData.length) {
        tollList = (
          <Table
            columns={[
              {
                Header: 'Nombre',
                accessor: 'toll.location.name',
                className: 'text-center',
              },
              {
                Header: 'Descripción',
                accessor: 'toll.location.description',
                className: 'text-center',
              },
              {
                Header: 'Precio por Eje',
                accessor: 'toll.pricePerAxle',
                Cell: (row) => numberFormatter({ value: row.value }),
                className: 'text-center',
              },
            ]}
            data={tollData}
            defaultPageSize={tollData.length}
            showPagination={false}
          />
        );
      }

      const tollResource = (
        <Fragment>
          <h3>Peajes</h3>
          <div className="mb-3">{tollList}</div>
        </Fragment>
      );

      const internalResource = (
        <InternalResource
          id={segment.get('id')}
          createDate={segment.get('createDate')}
          lastUpdate={segment.get('lastUpdate')}
        />
      );

      content = (
        <Fragment>
          <Row>
            <Col md={6}>{sourceLocationResource}</Col>
            <Col md={6}>{destinationLocationResource}</Col>
          </Row>
          {travelResource}
          {tollResource}
          {internalResource}
        </Fragment>
      );

      toolbar = <SegmentToolbar segmentId={segment.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Segmento"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

Segment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetSegment: PropTypes.func.isRequired,
  dispatchClearSegment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  segment: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Segment.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Segmentos',
      href: SEGMENT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  segment: RouteUnit.Segment.getIn(['current', 'content']),
  loading: !RouteUnit.Segment.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetSegment: getSegment,
  dispatchClearSegment: clearSegment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Segment);
