import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ITINERARY_SCHEDULE_PATH } from '../../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import {
  clearItineraries,
  getItinerariesByTemplate,
  putUpdateActiveStatusOfItineraries,
} from '../../../../../actions';
import Loader from '../../../../common/Loader';
import EditItineraryStatusForm from './EditItineraryStatusForm';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import ItineraryStatusSearchForm from './ItineraryStatusSearchForm';
import Content from '../../../../layout/Content';
import { tzNormalizeDate } from '../../../../../utils/date';

export class EditItineraryStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchBy: {},
      itineraries: [],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id: itineraryScheduleId },
      },
      dispatchGetItinerariesByTemplates,
    } = this.props;

    dispatchGetItinerariesByTemplates({ itineraryScheduleId });
  }

  componentDidUpdate() {
    this.onUpdate();
  }

  componentWillUnmount() {
    const { dispatchClearItineraries } = this.props;

    dispatchClearItineraries();
  }

  onUpdate = () => {
    const { itinerariesByTemplate } = this.props;

    const { itineraries } = this.state;

    const content = itinerariesByTemplate.get('content');

    if (!(content.length === 0) && itineraries.length === 0) {
      this.setState({
        itineraries: content.map(
          ({
            id,
            active,
            sourceLocationName,
            destinationLocationName,
            routeName,
            departuretime,
            serviceType,
            companyBusId,
            type,
            tripId,
            ticketsSold,
          }) => ({
            id,
            active,
            routeName,
            trip: !!tripId,
            departuretime: tzNormalizeDate({
              date: departuretime,
              format: DATE_TIME_FORMAT,
            }),
            sourceLocationName,
            destinationLocationName,
            serviceType,
            companyBusId,
            type,
            ticketsSold,
          }),
        ),
      });
    }
  };

  onSubmitSearch = (formValues) => {
    this.setState({ searchBy: formValues || {} });
  };

  onSubmitItineraryStatus = (formValues) => {
    const itineraryList = formValues.items.map((element) => ({
      itineraryId: element.id,
      active: element.checked,
    }));

    this.props.dispatchPutUpdateActiveStatusOfItineraries({ itineraryList });
  };

  generateFilteredItineraries = () => {
    const { itineraries, searchBy } = this.state;

    let itinerariesFiltered = [...itineraries];

    if (searchBy.trip) {
      itinerariesFiltered = itineraries.filter(
        (itinerary) => !!itinerary.tripId === searchBy.trip.value,
      );
    }

    return itinerariesFiltered;
  };

  render() {
    const { breadcrumbs, loadingItinerariesByTemplate, loadingItinerary } =
      this.props;

    let content = null;

    if (loadingItinerariesByTemplate || loadingItinerary) {
      content = <Loader />;
    } else {
      content = (
        <>
          <ItineraryStatusSearchForm onSubmit={this.onSubmitSearch} />
          <EditItineraryStatusForm
            onSubmit={this.onSubmitItineraryStatus}
            itineraries={this.generateFilteredItineraries()}
          />
        </>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Estado de Itinerarios"
        subtitle="Debe seleccionar los itinerarios de la lista que desea activar"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Plantillas de Itinerario',
      href: ITINERARY_SCHEDULE_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_SCHEDULE_PATH}/${id}`,
    },
    {
      text: 'Actualizar estados',
      href: '',
    },
  ],
  itinerariesByTemplate: ItineraryUnit.Itinerary.getIn(['all', 'content']),
  loadingItinerariesByTemplate: ItineraryUnit.Itinerary.getIn([
    'all',
    'loading',
  ]),
  loadingItinerary: !ItineraryUnit.Itinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutUpdateActiveStatusOfItineraries:
    putUpdateActiveStatusOfItineraries,
  dispatchGetItinerariesByTemplates: getItinerariesByTemplate,
  dispatchClearItineraries: clearItineraries,
};

EditItineraryStatus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutUpdateActiveStatusOfItineraries: PropTypes.func.isRequired,
  dispatchGetItinerariesByTemplates: PropTypes.func.isRequired,
  dispatchClearItineraries: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  itinerariesByTemplate: PropTypes.instanceOf(Immutable.Map),
  loadingItinerariesByTemplate: PropTypes.bool,
  loadingItinerary: PropTypes.bool,
};

EditItineraryStatus.defaultProps = {
  itinerariesByTemplate: [],
  loadingItinerariesByTemplate: false,
  loadingItinerary: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditItineraryStatus);
