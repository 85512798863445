import { string, bool, number, oneOfType } from 'prop-types';

const seatMapElementBasicInformationPropTypes = {
  id: oneOfType([string, number]),
  name: string.isRequired,
  description: string,
  elementIcon: string.isRequired,
  isSeat: bool.isRequired,
};

export default seatMapElementBasicInformationPropTypes;
