import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PostpaidBookings from './PostpaidBookings';
import NewPostpaidBooking from './NewPostpaidBooking';
import {
  POSTPAID_BOOKING_UNIT_PATH,
  POSTPAID_BOOKING_NEW_PATH,
} from '../../../config/paths';

const SalesUnit = () => (
  <Switch>
    <Route
      exact
      path={POSTPAID_BOOKING_UNIT_PATH}
      component={PostpaidBookings}
    />
    <Route path={POSTPAID_BOOKING_NEW_PATH} component={NewPostpaidBooking} />
  </Switch>
);

export default SalesUnit;
