import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  postRejectPostpaidBooking,
  replacePostpaidBooking,
} from '../../../../actions';
import { POST } from '../../../../config/permissions';
import { generateRejectPostpaidBookingEndpoint } from '../../../../config/endpoints';
import withEndpointAuthorization from '../../../units/authorization/withEndPointAuthorization';
import Loader from '../../Loader';

class RejectPostpaidBookingCell extends Component {
  static propTypes = {
    customerOrderId: PropTypes.number.isRequired,
    dispatchPostRejectPostpaidBooking: PropTypes.func.isRequired,
    dispatchReplacePostpaidBooking: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  state = {
    showRejectModal: false,
  };

  handleRejectPostpaidBooking = async () => {
    this.closeRejectModal();
    const {
      customerOrderId,
      dispatchPostRejectPostpaidBooking,
      dispatchReplacePostpaidBooking,
    } = this.props;
    const postpaidBooking = await dispatchPostRejectPostpaidBooking({
      customerOrderId,
    });
    if (postpaidBooking) {
      dispatchReplacePostpaidBooking({ postpaidBooking });
    }
  };

  showRejectModal = () => {
    this.setState({ showRejectModal: true });
  };

  closeRejectModal = () => {
    this.setState({ showRejectModal: false });
  };

  render() {
    const { loading } = this.props;
    let rejectModalContent;
    if (loading) {
      rejectModalContent = <Loader />;
    } else {
      rejectModalContent = (
        <>
          <ModalBody>
            Esta acción rechazará la reserva. ¿Desea continuar?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleRejectPostpaidBooking}>
              OK
            </Button>{' '}
            <Button color="secondary" onClick={this.closeRejectModal}>
              Cerrar
            </Button>
          </ModalFooter>
        </>
      );
    }

    const rejectModal = (
      <Modal isOpen={this.state.showRejectModal}>
        <ModalHeader>Confirmación</ModalHeader>
        {rejectModalContent}
      </Modal>
    );

    return (
      <>
        <i
          tabIndex="0"
          role="button"
          className="fa fa-times-circle text-danger"
          onKeyPress={this.showRejectModal}
          onClick={this.showRejectModal}
        />
        {rejectModal}
      </>
    );
  }
}

const mapStateToProps = ({ PostpaidBookingUnit }) => ({
  loading: PostpaidBookingUnit.PostpaidBooking.getIn(['all', 'activity']),
});

const mapDispatchToprops = {
  dispatchPostRejectPostpaidBooking: postRejectPostpaidBooking,
  dispatchReplacePostpaidBooking: replacePostpaidBooking,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToprops,
)(RejectPostpaidBookingCell);

export default withEndpointAuthorization({
  url: generateRejectPostpaidBookingEndpoint(),
  mapUrlParamsToProps: { customerOrderId: 'customerOrderId' },
  permissionType: POST,
})(connectedComponent);
