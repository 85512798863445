import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  postItineraryReport,
  clearErrorPostingItineraryReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import ItineraryReportForm from './ItineraryReportForm';
import {
  ITINERARY_REPORTS_PATH,
  NEW_ITINERARY_REPORT_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';

class NewItineraryReport extends Component {
  componentWillUnmount() {
    this.props.clearErrorPostingItineraryReport();
  }
  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte de Itinerarios</h1>
            <p id="text">Crear un nuevo reporte de itinerarios.</p>
          </Col>
        </Row>
        <ItineraryReportForm
          onSubmit={this.props.postItineraryReport}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            bus: null,
            route: null,
            driver: null,
            force: false,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_REPORTS_PATH,
    },
    {
      text: 'Crear',
      href: NEW_ITINERARY_REPORT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  postItineraryReport,
  clearErrorPostingItineraryReport,
};

NewItineraryReport.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  postItineraryReport: PropTypes.func.isRequired,
  clearErrorPostingItineraryReport: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewItineraryReport);
