import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DISCOUNT_CODE_DOCUMENT_WITH_DISCOUNT_ENDPOINT,
  UPLOAD_DOCUMENT_WITH_DISCOUNT_ENDPOINT,
} from '../../config/endpoints';
import { SALES_UNIT_PATH } from '../../config/paths';
import { BASIC_POST_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_DISCOUNT_TO_BENEFICIARY,
  FLAG_DISCOUNT_TO_BENEFICIARY_ACTIVITY,
} from '../types';

const flagDiscountToBeneficiaryActivity = flag => dispatch =>
  dispatch({
    type: FLAG_DISCOUNT_TO_BENEFICIARY_ACTIVITY,
    payload: flag,
  });

const postDiscountToBeneficiary = async ({
  file,
  discountCodeId,
  companyId,
}) => async dispatch => {
  try {
    dispatch(flagDiscountToBeneficiaryActivity(true));

    // Upload file to discount to beneficary
    const formData = new FormData();

    formData.append('file', file);

    const documentWithDiscountResponse = await fetch(
      UPLOAD_DOCUMENT_WITH_DISCOUNT_ENDPOINT,
      {
        ...BASIC_POST_CONFIG,
        body: formData,
      },
    );

    const documentWithDiscount = await documentWithDiscountResponse.json();

    // Post discount to beneficiary
    const payload = {
      documentWithDiscountId: documentWithDiscount.id,
      discountCodeId,
      companyId,
    };

    const discountToBeneficiaryResponse = await fetch(
      DISCOUNT_CODE_DOCUMENT_WITH_DISCOUNT_ENDPOINT,
      {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      },
    );

    await isErrorResponse(discountToBeneficiaryResponse);

    await discountToBeneficiaryResponse.json();

    dispatch(push(SALES_UNIT_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDiscountToBeneficiaryActivity(false));
  }
};

const clearDiscountToBeneficiary = () => dispatch =>
  dispatch({
    type: CLEAR_DISCOUNT_TO_BENEFICIARY,
  });

export {
  flagDiscountToBeneficiaryActivity,
  clearDiscountToBeneficiary,
  postDiscountToBeneficiary,
};
