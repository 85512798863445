import React from 'react';
import { connect } from 'react-redux';
import { SALES_CONTROL_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ItinerarySalesControlSearch from './ItinerarySalesControlSearch';

const ItinerarySalesControl = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Monitoreo de Ventas"
    subtitle="Busca por ubicaciones, por fechas y hora"
    content={<ItinerarySalesControlSearch />}
  />
);

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Monitoreo de Ventas',
      href: SALES_CONTROL_PATH,
    },
  ],
});

ItinerarySalesControl.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

export default connect(mapStateToProps)(ItinerarySalesControl);
