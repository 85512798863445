import React from 'react';
import PropTypes from 'prop-types';
import { numberFormatter } from '../../../../utils/number';

const TicketPaymentMethodCell = ({ paymentMethodList }) => {
  if (!paymentMethodList) {
    return null;
  }

  return (
    <div>
      {paymentMethodList.map((paymentMethod) => (
        <div key={paymentMethod.moneyFlowConcept}>
          {paymentMethod.moneyFlowConcept}:
          {numberFormatter({ value: paymentMethod.totalAmount })}
        </div>
      ))}
    </div>
  );
};

TicketPaymentMethodCell.propTypes = {
  paymentMethodList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string.isRequired,
      totalAmount: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default TicketPaymentMethodCell;
