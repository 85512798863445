import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import * as PostpaidSummaryPropTypes from './PostpaidSummaryPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

const PretransactionDetails = ({ pretransaction }) => {
  let deliveryAddress = null;
  if (pretransaction.customerDeliveryAddressId) {
    deliveryAddress = (
      <Row>
        <Col>
          <ResourceProperty label="Dirección de Delivery:">
            {pretransaction.customerDeliveryAddress.deliveryAddress}
          </ResourceProperty>
        </Col>
      </Row>
    );
  }

  // we are using voucherCode to store the pago efectivo code
  let voucherCode = null;
  if (pretransaction.voucherCode) {
    voucherCode = (
      <Row>
        <Col>
          <ResourceProperty label="Código PagoEfectivo:">
            {pretransaction.voucherCode}
          </ResourceProperty>
        </Col>
      </Row>
    );
  }

  // we are using pagoEfectivoExpirationDate to store the pago efectivo code
  let pagoEfectivoExpirationDate = null;
  if (pretransaction.pagoEfectivoExpirationDate) {
    pagoEfectivoExpirationDate = (
      <Row>
        <Col>
          <ResourceProperty label="Fecha de Expiración CIP:">
            {tzNormalizeDate({
              date: pretransaction.pagoEfectivoExpirationDate,
              format: DATE_TIME_FORMAT,
            })}
          </ResourceProperty>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <ResourceProperty label="Correo de Destino:">
            {pretransaction.customerEmailAddress.email}
          </ResourceProperty>
        </Col>
      </Row>
      <Row>
        <Col>
          <ResourceProperty label="Método de Pago:">
            {pretransaction.paymentMethod.name}
          </ResourceProperty>
        </Col>
      </Row>
      {deliveryAddress}
      {voucherCode}
      {pagoEfectivoExpirationDate}
    </Fragment>
  );
};

PretransactionDetails.propTypes = {
  pretransaction: PostpaidSummaryPropTypes.pretransactionPropType.isRequired,
};

export default PretransactionDetails;
