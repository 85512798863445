import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BAGGAGE_PATH } from '../../../../config/paths';

const EditBaggageButton = ({ baggageId }) => (
  <Link className="btn btn-primary" to={`${BAGGAGE_PATH}/${baggageId}/edit`}>
    <i className="fa fa-pencil-square-o" /> Editar
  </Link>
);

EditBaggageButton.propTypes = {
  baggageId: PropTypes.number.isRequired,
};

export default EditBaggageButton;
