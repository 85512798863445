import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditExpectedFuelConsumptionButton from './EditExpectedFuelConsumptionButton';
import DeleteExpectedFuelConsumptionButton from './DeleteExpectedFuelConsumptionButton';

const ExpectedFuelConsumptionToolbar = ({ expectedFuelConsumptionId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditExpectedFuelConsumptionButton
        expectedFuelConsumptionId={expectedFuelConsumptionId}
      />{' '}
      <DeleteExpectedFuelConsumptionButton
        expectedFuelConsumptionId={expectedFuelConsumptionId}
      />
    </div>
  </ButtonToolbar>
);

ExpectedFuelConsumptionToolbar.propTypes = {
  expectedFuelConsumptionId: PropTypes.number.isRequired,
};

export default ExpectedFuelConsumptionToolbar;
