import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { EXTRAORDINARY_MOVEMENT_CONTROL_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getExtraordinaryMovementControl,
  clearExtraordinaryMovementControl,
  putExtraordinaryMovementControl,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import ExtraordinaryMovementControlForm from './ExtraordinaryMovementControlForm';
import { EXTRAORDINARY_MOVEMENT_CONTROL_STATUS } from '../../../../config/constants';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditExtraordinaryMovementControl extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetExtraordinaryMovementControl,
    } = this.props;
    dispatchGetExtraordinaryMovementControl({ id });
  }

  componentWillUnmount() {
    const { dispatchClearExtraordinaryMovementControl } = this.props;
    dispatchClearExtraordinaryMovementControl();
  }

  onSubmit = (formValues) => {
    const {
      match: {
        params: { id },
      },
      dispatchPutExtraordinaryMovementControl,
      extraordinaryMovementControl,
    } = this.props;

    const newFormaValues = {
      ...formValues,
      initialArrivalTime:
        extraordinaryMovementControl.get('initialArrivalTime'),
      initialDepartureTime: extraordinaryMovementControl.get(
        'initialDepartureTime',
      ),
      itineraryId: extraordinaryMovementControl.get('itineraryId'),
      realArrivalTime: extraordinaryMovementControl.get('realArrivalTime'),
      realDepartureTime: extraordinaryMovementControl.get('realDepartureTime'),
      userId: extraordinaryMovementControl.get('userId'),
      authorizedFuelConsumption: 0,
      realAdblueConsumption: 0,
      createDate: extraordinaryMovementControl.get('createDate'),
    };

    dispatchPutExtraordinaryMovementControl(id, newFormaValues);
  };

  createInitialValues = (extraordinaryMovementControl) => {
    const {
      comments,
      initialSourceLocationName,
      realSourceLocationName,
      initialDestinationLocationName,
      realDestinationLocationName,
      initialKilometers,
      realKilometers,
      expectedFuelConsumption,
      authorizedFuelConsumption,
      expectedAdblueConsumption,
      realAdblueConsumption,
      initialDriverCommission,
      realDriverCommission,
      initialCabinCrewCommission,
      realCabinCrewCommission,
      initialOtherExpenses,
      realOtherExpenses,
      initialTollAmount,
      realTollAmount,
    } = extraordinaryMovementControl.toJS();

    const initialValues = {
      comments,
      initialSourceLocationName,
      realSourceLocationName,
      initialDestinationLocationName,
      realDestinationLocationName,
      initialKilometers,
      realKilometers,
      expectedFuelConsumption,
      authorizedFuelConsumption,
      expectedAdblueConsumption,
      realAdblueConsumption,
      initialDriverCommission,
      realDriverCommission,
      initialCabinCrewCommission,
      realCabinCrewCommission,
      initialOtherExpenses,
      realOtherExpenses,
      initialTollAmount,
      realTollAmount,
    };

    const { label: statusLabel } =
      EXTRAORDINARY_MOVEMENT_CONTROL_STATUS[
        extraordinaryMovementControl.get('status')
      ];

    initialValues.registeredBusId = {
      value: extraordinaryMovementControl.get('registeredBus').id,
      label: extraordinaryMovementControl.get('registeredBus').companyBusId,
    };
    initialValues.status = {
      value: extraordinaryMovementControl.get('status'),
      label: statusLabel,
    };

    return initialValues;
  };

  render() {
    const { breadcrumbs, extraordinaryMovementControl, loading } = this.props;

    let content;

    if (loading) content = <Loader />;
    else if (extraordinaryMovementControl.isEmpty())
      content = (
        <NoDataResource returnPage={EXTRAORDINARY_MOVEMENT_CONTROL_PATH} />
      );
    else
      content = (
        <ExtraordinaryMovementControlForm
          initialValues={this.createInitialValues(extraordinaryMovementControl)}
          onSubmit={this.onSubmit}
        />
      );

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Control Movimiento Extraordinario"
        subtitle="Editar control movimiento extraordinario"
        content={content}
      />
    );
  }
}

EditExtraordinaryMovementControl.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetExtraordinaryMovementControl: PropTypes.func.isRequired,
  dispatchClearExtraordinaryMovementControl: PropTypes.func.isRequired,
  dispatchPutExtraordinaryMovementControl: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  extraordinaryMovementControl: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditExtraordinaryMovementControl.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Control Movimiento Extraordinario',
      href: EXTRAORDINARY_MOVEMENT_CONTROL_PATH,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  extraordinaryMovementControl: TrafficUnit.ExtraordinaryMovementControl.getIn([
    'current',
    'content',
  ]),
  loading: !TrafficUnit.ExtraordinaryMovementControl.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExtraordinaryMovementControl: getExtraordinaryMovementControl,
  dispatchClearExtraordinaryMovementControl: clearExtraordinaryMovementControl,
  dispatchPutExtraordinaryMovementControl: putExtraordinaryMovementControl,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditExtraordinaryMovementControl);
