import { string, number, shape, arrayOf, oneOfType } from 'prop-types';
import { locationBasicInformationPropTypes } from '../../../location/location/proptypes/LocationPropTypes';
import busBodyworkBasicInformationPropTypes from '../../bus-bodywork/proptypes/BusBodyworkPropTypes';
import busChassisBasicInformationPropTypes from '../../bus-chassis/proptypes/BusChassisPropTypes';
import documentTypeBasicInformationPropTypes from '../../document-type/proptypes/DocumentTypePropTypes';
import { seatMapInformationPropTypes } from '../../seat-map/proptypes/SeatMapPropTypes';

const registeredBusBasicInformationPropTypes = {
  id: number,
  value: oneOfType([string, number]),
  companyBusId: string.isRequired,
  licensePlate: string.isRequired,
  busChassisId: number.isRequired,
  busChassis: shape(busChassisBasicInformationPropTypes),
  chassisSerialNumber: string.isRequired,
  busBodyworkId: number.isRequired,
  busBodywork: shape(busBodyworkBasicInformationPropTypes),
  bodyworkSerialNumber: string.isRequired,
  homeBaseLocationId: number,
  homeBaseLocation: shape(locationBasicInformationPropTypes),
  seatMapId: number.isRequired,
  seatMap: shape(seatMapInformationPropTypes).isRequired,
  year: number.isRequired,
  serviceType: string,
};

const registeredBusBasicInformationDefaultProps = {
  id: null,
  value: null,
  serviceType: null,
  homeBaseLocation: null,
  homeBaseLocationId: null,
  busChassis: null,
  busBodywork: null,
};

const registeredBusDocumentsInformationPropTypes = {
  documentSet: arrayOf(
    shape({
      documentTypeId: number,
      documentType: shape(documentTypeBasicInformationPropTypes),
      effectiveDate: number,
      expirationDate: number,
      id: number,
      lastUpdate: number,
      registeredBusId: number,
      serialNumber: string,
    }),
  ),
};

const registeredBusDocumentsInformationDefaultProps = {
  documentSet: [],
};

export {
  registeredBusBasicInformationPropTypes,
  registeredBusBasicInformationDefaultProps,
  registeredBusDocumentsInformationPropTypes,
  registeredBusDocumentsInformationDefaultProps,
};
