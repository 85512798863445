import React from 'react';
import { Table } from 'reactstrap';

const QualificationResource = () => (
  <Table striped responsive role="grid" className="text-center all-borders">
    <thead>
      <tr className="shadow">
        <th colSpan={2} className="title">
          CALIFICACIÓN
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>D.A. -5 gal &#62; Cons. Real</td>
        <td className="bg-light-green">Bueno</td>
      </tr>
      <tr>
        <td>D.A. +-5 gal = Cons. Real</td>
        <td>Regular</td>
      </tr>
      <tr>
        <td>D.A. +5 gal &#60; Cons. Real</td>
        <td className="bg-light-red">Malo</td>
      </tr>
    </tbody>
  </Table>
);

export default QualificationResource;
