import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEPOSIT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import DepositForm from './DepositForm';
import {
  clearDeposit,
  getDeposit,
  getDepositVoucher,
  putDeposit,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import { DEPOSIT_TYPE } from '../../../../config/constants';
import NoDataResource from '../../../common/resource/NoDataResource';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generateGetDepositEndpoint } from '../../../../config/endpoints';
import { PUT } from '../../../../config/permissions';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';

export class EditDeposit extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: depositId },
      },
      dispatchGetDeposit,
      dispatchGetDepositVoucher,
    } = this.props;
    dispatchGetDeposit({ depositId });
    dispatchGetDepositVoucher({ depositId });
  }

  componentWillUnmount() {
    this.props.dispatchClearDeposit();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutDeposit,
      deposit,
      match: {
        params: { id: depositId },
      },
    } = this.props;

    const depositType =
      deposit.get('depositType') || DEPOSIT_TYPE.DEFAULT.value;

    const newFormValues = {
      depositNoteList: formValues.depositNoteList,
      settled: formValues.settled || false,
      accountNumber: deposit.get('accountNumber'),
      bankName: deposit.get('bankName'),
      operationNumber: deposit.get('operationNumber'),
      depositDate: deposit.get('depositDate'),
      actualAmountDeposited: deposit.get('actualAmountDeposited'),
      expectedAmountToDeposit: deposit.get('expectedAmountToDeposit'),
      depositDifferenceAmount: deposit.get('depositDifferenceAmount'),
      depositDifferenceDescription: deposit.get('depositDifferenceDescription'),
      depositDifferenceReasonId: deposit.get('depositDifferenceReasonId'),
      dailyLiquidationSessionSet: deposit
        .get('dailyLiquidationSessionSet')
        .map(({ id }) => ({ id })),
      operatorUserId: deposit.get('operatorUserId'),
      depositType,
      file: formValues.file || null,
      createDate: deposit.get('createDate'),
    };

    dispatchPutDeposit(depositId, newFormValues);
  };

  generateInitialValues = (deposit) => {
    let expectedAmountToDeposit = 0;
    let agencyCommission = 0;
    let agencyCommissionTaxes = 0;

    deposit
      .get('dailyLiquidationSessionSet')
      .forEach(({ cashOnHand, commission, commissionTaxes }) => {
        expectedAmountToDeposit += cashOnHand;
        agencyCommission += commission;
        agencyCommissionTaxes += commissionTaxes;
      });

    const initialValues = {
      settled: deposit.get('settled'),
      accountNumber: deposit.get('accountNumber'),
      bankName: deposit.get('bankName'),
      operationNumber: deposit.get('operationNumber'),
      depositDate: tzNormalizeDate({ date: deposit.get('depositDate') }),
      expectedAmountToDeposit,
      agencyCommission,
      agencyCommissionTaxes,
      actualAmountDeposited: deposit.get('actualAmountDeposited'),
      dailyLiquidationSessionSet: deposit.get('dailyLiquidationSessionSet'),
      depositType: DEPOSIT_TYPE.DEFAULT,
    };

    const depositType =
      deposit.get('depositType') || DEPOSIT_TYPE.DEFAULT.value;

    if (depositType !== DEPOSIT_TYPE.DEFAULT.value) {
      initialValues.depositType = {
        value: DEPOSIT_TYPE[depositType].value,
        label: DEPOSIT_TYPE[depositType].label,
      };
    }

    if (deposit.get('depositDifferenceAmount') !== 0) {
      initialValues.depositDifferenceReasonId = {
        value: deposit.get('depositDifferenceReason').id,
        label: deposit.get('depositDifferenceReason').name,
      };
      initialValues.depositDifferenceDescription = deposit.get(
        'depositDifferenceDescription',
      );
    }

    return initialValues;
  };

  render() {
    const { breadcrumbs, deposit, loading, voucher } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (deposit.isEmpty()) {
      content = <NoDataResource returnPage={DEPOSIT_PATH} />;
    } else {
      content = (
        <DepositForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(deposit)}
          depositNoteList={deposit.get('depositNoteList')}
          voucher={voucher}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Depósito"
        subtitle="Edita este depósito"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { SalesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Depósitos',
      href: DEPOSIT_PATH,
    },
    {
      text: 'Ver',
      href: `${DEPOSIT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  deposit: SalesUnit.Deposit.getIn(['current', 'content']),
  loading: !SalesUnit.Deposit.getIn(['current', 'activity']).isEmpty(),
  voucher: SalesUnit.Deposit.getIn(['current', 'voucher']),
});

const mapDispatchToProps = {
  dispatchPutDeposit: putDeposit,
  dispatchGetDeposit: getDeposit,
  dispatchClearDeposit: clearDeposit,
  dispatchGetDepositVoucher: getDepositVoucher,
};

EditDeposit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  dispatchPutDeposit: PropTypes.func.isRequired,
  deposit: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetDeposit: PropTypes.func.isRequired,
  dispatchClearDeposit: PropTypes.func.isRequired,
  dispatchGetDepositVoucher: PropTypes.func.isRequired,
  voucher: PropTypes.instanceOf(Immutable.Set),
};

EditDeposit.defaultProps = {
  voucher: null,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDeposit);

export default withEndpointAuthorization({
  url: generateGetDepositEndpoint(0),
  permissionType: PUT,
})(connectedComponent);
