import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  ticketPaymentMethodSummaryListPropTypes,
  ticketSummaryListPropTypes,
} from './SalesSessionPropTypes';
import CollapsibleHeader from '../../../layout/CollapsibleHeader';
import TicketsTable from './TicketsTable';
import { TICKET_STATUS } from '../../../../config/constants';
import './TicketsSummary.css';
import { numberFormatter } from '../../../../utils/number';

const TicketsSummary = ({
  ticketSummaryList,
  ticketPaymentMethodSummaryList,
  salesSessionId,
  totalTicketIncome,
}) => {
  if (!ticketSummaryList.length) {
    return null;
  }

  return (
    <>
      <hr />
      <CollapsibleHeader
        headerText="Boletos"
        component={TicketsTable}
        salesSessionId={salesSessionId}
        downloadButton
      />
      <Row>
        <Col>
          {ticketSummaryList.map((ticketSummary, index) => (
            <div key={+index}>
              <p>{`Serie ${ticketSummary.documentSeries}: `}</p>
              <div className="summaryContainer">
                <p>
                  {`${ticketSummary.numberOfActiveTickets} ${TICKET_STATUS.ACTIVE.label}(s)`}
                </p>
                <p>
                  {`${ticketSummary.numberOfVoidedTickets} ${TICKET_STATUS.VOIDED.label}(s)`}
                </p>
                <p>
                  {`${ticketSummary.numberOfPostponedTickets} ${TICKET_STATUS.POSTPONED.label}(s)`}
                </p>
                <p>
                  {`${ticketSummary.numberOfCustomerChangedTickets} ${TICKET_STATUS.CUSTOMER_CHANGE.label}(s)`}
                </p>
                <p>
                  {`${ticketSummary.numberOfVoucherChangedTickets} ${TICKET_STATUS.VOUCHER_TYPE_CHANGE.label}(s)`}
                </p>
                <p>
                  {`${ticketSummary.numberOfRefundedTickets} ${TICKET_STATUS.REFUNDED.label}(s)`}
                </p>
              </div>
            </div>
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          {ticketPaymentMethodSummaryList.map((paymentMethodSales) => (
            <ResourceProperty
              key={paymentMethodSales.moneyFlowConcept}
              label={`${paymentMethodSales.moneyFlowConcept}: `}
            >
              {numberFormatter({ value: paymentMethodSales.totalAmount })}
            </ResourceProperty>
          ))}
          <ResourceProperty label="Total de Ventas: ">
            {numberFormatter({ value: totalTicketIncome })}
          </ResourceProperty>
        </Col>
      </Row>
    </>
  );
};

TicketsSummary.propTypes = {
  ticketPaymentMethodSummaryList:
    ticketPaymentMethodSummaryListPropTypes.isRequired,
  ticketSummaryList: ticketSummaryListPropTypes.isRequired,
  salesSessionId: PropTypes.number.isRequired,
  totalTicketIncome: PropTypes.number.isRequired,
};

export default TicketsSummary;
