import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getFuelStations,
  putFuelStation,
} from '../../../../actions/route/FuelStation';
import {
  FUEL_STATION_PATH,
  NEW_FUEL_STATION_PATH,
} from '../../../../config/paths';
import { FUEL_STATIONS_COLUMNS } from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';

export class FuelStations extends Component {
  constructor(props) {
    super(props);
    this.columns = FUEL_STATIONS_COLUMNS;
  }

  render() {
    const { fuelStations, loading, dispatchGetFuelStations, breadcrumbs } =
      this.props;
    const data = fuelStations.get('content') || [];
    const pages = fuelStations.get('totalPages') || null;
    const defaultPageSize = fuelStations.get('size') || DEFAULT_PAGE_SIZE;
    return (
      <ModuleList
        title="Grifos"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_FUEL_STATION_PATH}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetFuelStations,
          modelPath: FUEL_STATION_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grifos',
      href: '',
    },
  ],
  fuelStations: RouteUnit.FuelStation.getIn(['all', 'content']),
  loading: RouteUnit.FuelStation.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchPutFuelStation: putFuelStation,
  dispatchGetFuelStations: getFuelStations,
};

FuelStations.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  fuelStations: PropTypes.instanceOf(Immutable.Map),
  dispatchGetFuelStations: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

FuelStations.defaultProps = {
  fuelStations: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelStations);
