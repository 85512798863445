import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ type = 'danger', message }) => {
  if (message) return <div className={`alert alert-${type}`}>{message}</div>;

  return null;
};

Alert.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
};

Alert.defaultProps = {
  message: null,
  type: 'danger',
};

export default Alert;
