import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVoucherTypes, clearVoucherTypes } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

class VoucherTypeSelect extends Component {
  componentDidMount() {
    const { dispatchGetVoucherTypes } = this.props;
    dispatchGetVoucherTypes(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearVoucherTypes } = this.props;
    dispatchClearVoucherTypes();
  }

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

VoucherTypeSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetVoucherTypes: PropTypes.func.isRequired,
  dispatchClearVoucherTypes: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  displayInPaymentPage: PropTypes.bool,
};

VoucherTypeSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: true,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  displayInPaymentPage: false,
};

const mapStateToProps = ({ AccountingUnit }, { displayInPaymentPage }) => {
  const allVoucherTypes = AccountingUnit.VoucherType.getIn([
    'all',
    'content',
    'content',
  ]).map((voucherType) => ({
    value: voucherType.id,
    label: voucherType.name,
    voucherCode: voucherType.voucherCode,
    displayInPaymentsPage: voucherType.displayInPaymentsPage,
  }));
  return {
    options: displayInPaymentPage
      ? allVoucherTypes.filter(
          (voucherType) => voucherType.displayInPaymentsPage,
        )
      : allVoucherTypes,
    loading: AccountingUnit.VoucherType.getIn(['all', 'loading']),
  };
};

const mapDispatchToProps = {
  dispatchGetVoucherTypes: getVoucherTypes,
  dispatchClearVoucherTypes: clearVoucherTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTypeSelect);
