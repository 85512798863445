import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BUS_ACTIVITY_ASSIGNMENT_PATH } from '../../../../config/paths';
import { postBusActivityAssignment } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import BusActivityAssignmentForm from './BusActivityAssignmentForm';

const NewBusActivityAssignment = ({
  breadcrumbs,
  dispatchPostBusActivityAssignment,
}) => {
  const content = (
    <BusActivityAssignmentForm onSubmit={dispatchPostBusActivityAssignment} />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Asignación de Actividades a Buses"
      subtitle="Puede seleccionar los buses y las actividades para asociarlas"
      content={content}
    />
  );
};

NewBusActivityAssignment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostBusActivityAssignment: PropTypes.func.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignaciones de Actividades a Buses',
      href: BUS_ACTIVITY_ASSIGNMENT_PATH,
    },
    {
      text: 'Asignar',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostBusActivityAssignment: postBusActivityAssignment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewBusActivityAssignment);
