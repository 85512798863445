import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  GEOGRAPHIC_ZONE_PATH,
  NEW_GEOGRAPHIC_ZONE_PATH,
} from '../../../../config/paths';
import {
  getGeographicZones,
  clearGeographicZones,
} from '../../../../actions/location';
import { GEOGRAPHIC_ZONES_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class GeographicZones extends Component {
  componentWillUnmount() {
    const { dispatchClearGeographicZones } = this.props;
    dispatchClearGeographicZones();
  }

  render() {
    const {
      geographicZones,
      loading,
      breadcrumbs,
      dispatchGetGeographicZones,
    } = this.props;
    const data = geographicZones.get('content') || [];
    const pages = geographicZones.get('totalPages') || null;
    const defaultPageSize = geographicZones.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Zonas Geográficas"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_GEOGRAPHIC_ZONE_PATH}
        tableStructure={{
          columns: GEOGRAPHIC_ZONES_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetGeographicZones,
          loading,
          modelPath: GEOGRAPHIC_ZONE_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetGeographicZones: getGeographicZones,
  dispatchClearGeographicZones: clearGeographicZones,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_PATH,
    },
  ],
  geographicZones: LocationUnit.GeographicZone.getIn(['all', 'content']),
  loading: LocationUnit.GeographicZone.getIn(['all', 'loading']),
});

GeographicZones.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  geographicZones: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetGeographicZones: PropTypes.func.isRequired,
  dispatchClearGeographicZones: PropTypes.func.isRequired,
};

GeographicZones.defaultProps = {
  geographicZones: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeographicZones);
