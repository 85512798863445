import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getBusFuelGroups,
  clearBusFuelGroups,
} from '../../../../actions/index';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';

class BusFuelGroupSelect extends Component {
  static propTypes = {
    isGridVariant: PropTypes.bool,
    dispatchGetBusFuelGroups: PropTypes.func.isRequired,
    dispatchClearBusFuelGroups: PropTypes.func.isRequired,
    onValueChange: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    isMulti: PropTypes.bool,
    busFuelGroupOptions: optionsPropTypes,
    isClearable: PropTypes.bool,
    initFetch: PropTypes.bool,
    value: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  };

  static defaultProps = {
    isGridVariant: false,
    isMulti: false,
    initFetch: true,
    busFuelGroupOptions: [],
    isClearable: false,
    onValueChange: undefined,
    value: {},
  };

  constructor(props) {
    super(props);
    const { dispatchGetBusFuelGroups, initFetch } = this.props;
    // when initFetch false fetching won't be managed by this component
    // we'll only get the data from the store
    if (initFetch) {
      dispatchGetBusFuelGroups(DEFAULT_QUERY_GET_ALL);
    }
  }

  componentWillUnmount() {
    const { dispatchClearBusFuelGroups, initFetch } = this.props;
    // when initFetch is false fetching won't be managed by this component
    // so no need to perform cleanup here
    if (initFetch) {
      dispatchClearBusFuelGroups();
    }
  }

  render() {
    const {
      loading,
      busFuelGroupOptions,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={busFuelGroupOptions}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => {
  const busFuelGroupOptions = TrafficUnit.BusFuelGroup.getIn([
    'all',
    'content',
    'content',
  ]).map((busFuelGroup) => ({
    value: busFuelGroup.id,
    label: busFuelGroup.name,
  }));

  return {
    busFuelGroupOptions,
    loading: TrafficUnit.BusFuelGroup.getIn(['all', 'loading']),
  };
};

const mapDispatchToProps = {
  dispatchGetBusFuelGroups: getBusFuelGroups,
  dispatchClearBusFuelGroups: clearBusFuelGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusFuelGroupSelect);
