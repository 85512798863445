import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  PRICING_PROFILE_PATH,
  NEW_PRICING_PROFILE_PATH,
} from '../../../../config/paths';
import { PRICING_PROFILE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { clearPricingProfiles, getPricingProfiles } from '../../../../actions';

export class PricingProfiles extends Component {
  componentWillUnmount() {
    this.props.dispatchClearPricingProfiles();
  }

  render() {
    const { profiles, loading, breadcrumbs, dispatchGetPricingProfiles } =
      this.props;
    const data = profiles.get('content') || [];
    const pages = profiles.get('totalPages') || null;
    const defaultPageSize = profiles.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Perfiles de Precios"
        buttonPath={NEW_PRICING_PROFILE_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: PRICING_PROFILE_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetPricingProfiles,
          modelPath: PRICING_PROFILE_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetPricingProfiles: getPricingProfiles,
  dispatchClearPricingProfiles: clearPricingProfiles,
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles de Precios',
      href: PRICING_PROFILE_PATH,
    },
  ],
  profiles: SalesUnit.PricingProfile.getIn(['all', 'content']),
  loading: SalesUnit.PricingProfile.getIn(['all', 'loading']),
});

PricingProfiles.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  profiles: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetPricingProfiles: PropTypes.func.isRequired,
  dispatchClearPricingProfiles: PropTypes.func.isRequired,
};

PricingProfiles.defaultProps = {
  profiles: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingProfiles);
