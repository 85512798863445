import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postBaggageItemMovement } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import BaggageItemMovementForm from './BaggageItemMovementForm';
import { BAGGAGE_UNIT_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';
import { getSubstring } from '../../../../utils/string';

export class NewBaggageItemMovement extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostBaggageItemMovement } = this.props;

    const newFormValues = {
      locationId: formValues.locationId && formValues.locationId.value,
      remarks: formValues.remarks,
      itineraryId: formValues.itineraryId
        ? getSubstring(formValues.itineraryId)
        : null,
      status: formValues.status.value,
      itemIdList: formValues.items,
    };

    dispatchPostBaggageItemMovement(newFormValues, BAGGAGE_UNIT_PATH);
  };

  render() {
    const { breadcrumbs } = this.props;

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Nuevo Movimiento de Equipaje"
        subtitle="Busca el equipaje y luego genera su movimiento"
        content={<BaggageItemMovementForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

NewBaggageItemMovement.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostBaggageItemMovement: PropTypes.func.isRequired,
};

const mapStateToProps = ({ BaggageUnit }) => ({
  breadcrumbs: [
    ...BaggageUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Movimiento de Equipajes',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostBaggageItemMovement: postBaggageItemMovement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewBaggageItemMovement);
