import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MATERIAL_PATH } from '../../../../config/paths';
import { postMaterial } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaterialForm from './MaterialForm';

const NewMaterial = ({ breadcrumbs, dispatchPostMaterial }) => {
  const onSubmit = (formValues) =>
    dispatchPostMaterial({
      ...formValues,
      measurementUnit: formValues.measurementUnit.value,
    });

  const content = <MaterialForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Material"
      subtitle="Crear nuevo material"
      content={content}
    />
  );
};

NewMaterial.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMaterial: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostMaterial: postMaterial,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Materiales',
      href: MATERIAL_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMaterial);
