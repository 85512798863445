import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  FLAG_GETTING_DRIVER_BUS_ASSIGNMENTS,
  GET_DRIVER_BUS_ASSIGNMENTS,
  CLEAR_DRIVER_BUS_ASSIGNMENTS,
  FLAG_DRIVER_BUS_ASSIGNMENT_ACTIVITY,
  GET_DRIVER_BUS_ASSIGNMENT,
  CLEAR_DRIVER_BUS_ASSIGNMENT,
  DRIVER_BUS_ASSIGNMENT_INITIAL_VALUES,
} from '../../../actions/types/traffic';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
  initialValues: {},
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_DRIVER_BUS_ASSIGNMENTS:
      return state.setIn(['all', 'loading'], payload);
    case GET_DRIVER_BUS_ASSIGNMENTS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_DRIVER_BUS_ASSIGNMENTS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_DRIVER_BUS_ASSIGNMENT_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_DRIVER_BUS_ASSIGNMENT:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_DRIVER_BUS_ASSIGNMENT:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case DRIVER_BUS_ASSIGNMENT_INITIAL_VALUES:
      return state.setIn(['initialValues'], payload);
    default:
      return state;
  }
};
