import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonToolbar, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BAGGAGE_PATH, NEW_BAGGAGE_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import BaggageSearchForm from './BaggageSearchForm';
import BaggageSearchResult from './BaggageSearchResult';
import { getBaggages } from '../../../../actions';
import Content from '../../../layout/Content';

export class Baggages extends Component {
  state = {
    searchFormValues: {},
    size: 10,
  };

  onSubmit = (formValues) => {
    const { size } = this.state;
    const { dispatchGetBaggages } = this.props;
    const newFormValues = { size };
    newFormValues[formValues.searchBy.value] = formValues.ticket;
    dispatchGetBaggages(newFormValues);
  };

  render() {
    const { breadcrumbs } = this.props;
    const { searchFormValues } = this.state;

    const toolbar = (
      <ButtonToolbar className="pull-right">
        <ButtonGroup>
          <Link className="btn btn-primary" to={NEW_BAGGAGE_PATH}>
            <i className="fa fa-plus" /> Crear
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );

    const title = 'Búsqueda de Equipajes';

    const subtitle = (
      <>
        Busca por <strong>Boleto de Viaje</strong> o{' '}
        <strong>Boleta de Exceso</strong>.
      </>
    );

    const content = (
      <>
        <BaggageSearchForm onSubmit={this.onSubmit} />
        <BaggageSearchResult searchFormValues={searchFormValues} />
      </>
    );

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title={title}
        subtitle={subtitle}
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ BaggageUnit }) => ({
  breadcrumbs: [
    ...BaggageUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Equipajes y Excesos',
      href: BAGGAGE_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchGetBaggages: getBaggages,
};

Baggages.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBaggages: PropTypes.func.isRequired,
};

Baggages.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Baggages);
