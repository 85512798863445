import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DEFAULT_QUERY_GET_ALL } from '../../../../../config/queries';
import { getSubstring } from '../../../../../utils/string';
import Content from '../../../../layout/Content';
import {
  BILLING_PATH,
  ITINERARY_FOR_COUNTER_PATH,
} from '../../../../../config/paths';
import {
  clearBillings,
  getBillings,
  obtainBillingReport,
} from '../../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import { BILLING_PROCESS_TYPE } from '../../../../../config/constants';
import BillingItineraryToolBar from './BillingItineraryToolBar';
import BillingStatusReport from './BillingsItineraryReport';
import Loader from '../../../../common/Loader';
import Table from '../../../../common/Table';
import { BILLING_COLUMNS } from '../../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';

export const Billings = ({
  dispatchGetBillings,
  breadcrumbs,
  dispatchClearBillings,
  match: {
    params: { id: itineraryId },
  },
  dispatchGetObtainBillingReport,
  loading,
  printLoading,
  billings,
}) => {
  const [billingsReport, setBillingsReport] = useState(null);

  const newFormValues = {
    ...DEFAULT_QUERY_GET_ALL,
    searchBy: BILLING_PROCESS_TYPE.GUIA_REMISION_TRANSPORTISTA.value,
    itineraryId: getSubstring(itineraryId),
  };

  useEffect(() => {
    dispatchGetBillings(newFormValues);

    return () => {
      dispatchClearBillings();
    };
  }, []);

  const getBillingReport = () => {
    dispatchGetObtainBillingReport({ itineraryId }).then((response) => {
      setBillingsReport(response);
    });
    dispatchGetBillings(newFormValues);
  };

  const title = `Datos de Facturación del Itinerario #${itineraryId}`;

  const subtitle = (
    <>
      Busca los datos de{' '}
      <strong>Guías de Remisión Trasportista y Remitente</strong>
    </>
  );

  const table = (
    <Table
      columns={BILLING_COLUMNS}
      data={billings.get('content') || []}
      loading={loading}
      modelPath={BILLING_PATH}
      navigateToModelOnRowClick
      manual={false}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
      openPathInNewTab
    />
  );

  let content = null;

  if (loading || printLoading) {
    content = <Loader />;
  } else {
    content = (
      <>
        {billingsReport && <BillingStatusReport billings={billingsReport} />}
        {table}
      </>
    );
  }

  const toolbar = (
    <BillingItineraryToolBar
      itineraryId={itineraryId}
      handleChecking={getBillingReport}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title={title}
      toolbar={toolbar}
      subtitle={subtitle}
      content={content}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id: itineraryId } = ownProps.match.params;
  return {
    breadcrumbs: [
      ...state.ItineraryUnit.UnitHome.get('breadcrumbs'),
      {
        text: 'Itinerario',
        href: `${ITINERARY_FOR_COUNTER_PATH}/${itineraryId}`,
      },
      {
        text: 'Facturación',
        href: BILLING_PATH,
      },
    ],
    loading: !state.AccountingUnit.Billing.getIn([
      'current',
      'activity',
    ]).isEmpty(),
    printLoading: !state.ItineraryUnit.Itinerary.getIn([
      'current',
      'activity',
    ]).isEmpty(),
    billings: state.AccountingUnit.Billing.getIn(['all', 'content']),
  };
};

const mapDispatchToProps = {
  dispatchGetBillings: getBillings,
  dispatchClearBillings: clearBillings,
  dispatchGetObtainBillingReport: obtainBillingReport,
};

Billings.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBillings: PropTypes.func.isRequired,
  dispatchClearBillings: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  dispatchGetObtainBillingReport: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  printLoading: PropTypes.bool,
  billings: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Billings.defaultProps = {
  loading: false,
  printLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billings);
