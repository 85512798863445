import React from 'react';
import { expenseItemInformationPropTypes } from '../proptypes/ExpensePropTypes';
import Table from '../../../../common/Table';
import { EXPENSE_ITEMS } from '../../../../../config/columns';

const ExpenseItemInformationResource = ({ expense }) => (
  <div className="mb-3">
    <h3>Item</h3>
    <Table
      columns={[
        {
          Header: 'Descripción',
          Cell: () => expense.description,
          className: 'text-center',
        },
        ...EXPENSE_ITEMS,
      ]}
      data={expense.transactionList}
      defaultPageSize={
        expense.transactionList ? expense.transactionList.length : 0
      }
      showPagination={false}
    />
  </div>
);

ExpenseItemInformationResource.propTypes = {
  expense: expenseItemInformationPropTypes.isRequired,
};

export default ExpenseItemInformationResource;
