import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CARGO_CATEGORY_PATH } from '../../../../config/paths';

const EditCargoCategoryButton = ({ cargoCategoryId }) => (
  <Link
    className="btn btn-primary"
    to={`${CARGO_CATEGORY_PATH}/${cargoCategoryId}/edit`}
  >
    Editar
  </Link>
);

EditCargoCategoryButton.propTypes = {
  cargoCategoryId: PropTypes.number.isRequired,
};

export default EditCargoCategoryButton;
