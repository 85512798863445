import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import AgencyGroupEditButton from './AgencyGroupEditButton';
import AgencyGroupDeleteButton from './AgencyGroupDeleteButton';

const AgencyGroupToolbar = ({ agencyGroupId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <AgencyGroupEditButton agencyGroupId={agencyGroupId} />{' '}
      <AgencyGroupDeleteButton agencyGroupId={agencyGroupId} />
    </div>
  </ButtonToolbar>
);

AgencyGroupToolbar.propTypes = {
  agencyGroupId: PropTypes.number.isRequired,
};

export default AgencyGroupToolbar;
