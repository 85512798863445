import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { ITINERARY_SCHEDULE_ENDPOINT } from '../../config/endpoints';
import {
  generateItineraryStatusEditPath,
  ITINERARY_SCHEDULE_PATH,
} from '../../config/paths';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_ITINERARY_SCHEDULE,
  CLEAR_ITINERARY_SCHEDULES,
  FLAG_GETTING_ITINERARY_SCHEDULES,
  FLAG_ITINERARY_SCHEDULE_ACTIVITY,
  GET_ITINERARY_SCHEDULE,
  GET_ITINERARY_SCHEDULES,
} from '../types';

const flagGettingItinerarySchedules = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_ITINERARY_SCHEDULES,
    payload: flag,
  });

const clearItinerarySchedules = () => dispatch =>
  dispatch({
    type: CLEAR_ITINERARY_SCHEDULES,
  });

const flagItineraryScheduleActivity = flag => dispatch =>
  dispatch({
    type: FLAG_ITINERARY_SCHEDULE_ACTIVITY,
    payload: flag,
  });

const clearItinerarySchedule = () => dispatch =>
  dispatch({
    type: CLEAR_ITINERARY_SCHEDULE,
  });

const getItinerarySchedules = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingItinerarySchedules(true));

    const query = QueryString.stringify(tableFilters);
    const url = `${ITINERARY_SCHEDULE_ENDPOINT}?${query}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const itinerarySchedules = await response.json();
    dispatch({
      type: GET_ITINERARY_SCHEDULES,
      payload: itinerarySchedules,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingItinerarySchedules(false));
  }
};

const postItinerarySchedule = async ({
  name,
  description,
  itinerary,
  repeatEvery,
  repeatType,
  dailyRepeat,
  weeklyRepeat,
  monthlyRepeat,
  yearlyRepeat,
  noRepeat,
  endDate,
  itineraryScheduleSegmentList,
  itineraryCode,
  itineraryScheduleVersion,
  driverCommissionId,
  circuitId,
  serviceTypeId,
  createdFromApi,
  startDate,
  departureTime,
  isMaciva,
  itinerarySchedulePricingProfiles,
  cabinCrewCommissionId,
  companyId,
}) => async dispatch => {
  try {
    dispatch(flagItineraryScheduleActivity(true));

    const payload = {
      name,
      description,
      itinerary,
      repeatEvery,
      repeatType,
      dailyRepeat,
      weeklyRepeat,
      monthlyRepeat,
      yearlyRepeat,
      noRepeat,
      endDate,
      itineraryScheduleSegmentList,
      itineraryCode,
      itineraryScheduleVersion,
      driverCommissionId,
      circuitId,
      serviceTypeId,
      createdFromApi,
      startDate,
      departureTime,
      isMaciva,
      itinerarySchedulePricingProfiles,
      cabinCrewCommissionId,
      companyId,
    };

    const url = ITINERARY_SCHEDULE_ENDPOINT;

    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });

    await isErrorResponse(response);

    const itinerarySchedule = await response.json();

    dispatch(push(generateItineraryStatusEditPath(itinerarySchedule.id)));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItineraryScheduleActivity(false));
  }
};

const putItinerarySchedule = async (
  itineraryId,
  {
    name,
    description,
    itinerary,
    repeatEvery,
    repeatType,
    dailyRepeat,
    weeklyRepeat,
    monthlyRepeat,
    yearlyRepeat,
    noRepeat,
    endDate,
    itineraryCode,
    itineraryScheduleSegmentList,
    itineraryScheduleVersion,
    driverCommissionId,
    circuitId,
    serviceTypeId,
    createdFromApi,
    startDate,
    departureTime,
    isMaciva,
    itinerarySchedulePricingProfiles,
    cabinCrewCommissionId,
    companyId,
  },
) => async dispatch => {
  try {
    dispatch(flagItineraryScheduleActivity(true));
    const payload = {
      name,
      description,
      itinerary,
      repeatEvery,
      repeatType,
      dailyRepeat,
      weeklyRepeat,
      monthlyRepeat,
      yearlyRepeat,
      noRepeat,
      endDate,
      itineraryCode,
      itineraryScheduleSegmentList,
      itineraryScheduleVersion,
      driverCommissionId,
      circuitId,
      serviceTypeId,
      createdFromApi,
      startDate,
      departureTime,
      isMaciva,
      itinerarySchedulePricingProfiles,
      cabinCrewCommissionId,
      companyId,
      id: itineraryId,
    };
    const url = `${ITINERARY_SCHEDULE_ENDPOINT}/${itineraryId}`;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const itinerarySchedule = await response.json();
    dispatch(push(`${ITINERARY_SCHEDULE_PATH}/${itinerarySchedule.id}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItineraryScheduleActivity(false));
  }
};

const deleteItinerarySchedule = async ({ itineraryId }) => async dispatch => {
  try {
    dispatch(flagItineraryScheduleActivity(true));
    const url = `${ITINERARY_SCHEDULE_ENDPOINT}/${itineraryId}`;
    const response = await fetch(url, {
      ...DEFAULT_DELETE_CONFIG,
    });
    await isErrorResponse(response);
    await response.json();
    dispatch(push(ITINERARY_SCHEDULE_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItineraryScheduleActivity(false));
  }
};

const getItinerarySchedule = async ({
  itineraryScheduleId,
}) => async dispatch => {
  try {
    dispatch(flagItineraryScheduleActivity(true));
    const url = `${ITINERARY_SCHEDULE_ENDPOINT}/${itineraryScheduleId}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const itinerary = await response.json();
    dispatch({
      type: GET_ITINERARY_SCHEDULE,
      payload: itinerary,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItineraryScheduleActivity(false));
  }
};

export {
  flagGettingItinerarySchedules,
  clearItinerarySchedules,
  flagItineraryScheduleActivity,
  clearItinerarySchedule,
  getItinerarySchedules,
  postItinerarySchedule,
  getItinerarySchedule,
  putItinerarySchedule,
  deleteItinerarySchedule,
};
