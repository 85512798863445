import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import { getFuelVoucherOptions } from '../../../units/traffic/fuel-voucher/config/fetch';

class FuelVoucherSelect extends Component {
  state = {
    fuelVoucherOptions: [],
    disabled: false,
  };

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  onMount = async () => {
    let { fuelVoucherOptions } = this.props;

    this.setState({ disabled: true });

    if (!fuelVoucherOptions) {
      fuelVoucherOptions = await getFuelVoucherOptions({
        id: this.props.agencyId,
      });
    }

    this.setState({ fuelVoucherOptions });
  };

  onDidUpdate = () => {
    const { fuelVoucherOptions, disabled } = this.state;

    if (fuelVoucherOptions.length && disabled) {
      this.setState({ disabled: false });
    }
  };

  handleChange = (value) => {
    const { onValueChange } = this.props;

    this.setState({ disabled: true });

    onValueChange(value);
  };

  render() {
    const { isLoading, isMulti, value, onValueChange, isGridVariant, ...rest } =
      this.props;

    const { fuelVoucherOptions, disabled } = this.state;

    return (
      <Select
        options={fuelVoucherOptions}
        isLoading={isLoading}
        isMulti={isMulti}
        input={{ value }}
        onInputChange={this.handleInputChange}
        onValueChange={this.handleChange}
        isGridVariant={isGridVariant}
        isDisabled={disabled}
        {...rest}
      />
    );
  }
}

FuelVoucherSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  onValueChange: PropTypes.func,
  agencyId: PropTypes.number,
  fuelVoucherOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      documentSeries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      documentCode: PropTypes.number,
      circuitName: PropTypes.string,
    }),
  ),
};

FuelVoucherSelect.defaultProps = {
  isGridVariant: false,
  isLoading: false,
  isMulti: false,
  value: [],
  onValueChange: undefined,
  agencyId: null,
  fuelVoucherOptions: null,
};

export default FuelVoucherSelect;
