import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';

const ReportStatusCell = ({ value }) => {
  switch (value) {
    case 'READY':
      return (
        <div className="text-center">
          <Badge color="success" text="Listo" />
        </div>
      );
    case 'GENERATING':
      return (
        <div className="text-center">
          <Badge color="warning" text="Procesando" />
        </div>
      );
    default:
      return '';
  }
};

ReportStatusCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ReportStatusCell.defaultProps = {
  value: '',
};

export default ReportStatusCell;
