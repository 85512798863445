import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearRegisteredBuses, getRegisteredBuses } from '../../../actions';
import { registeredBusesGenerator } from '../../../utils/app/json-generator-from-reducer';

class RegisteredBusFilter extends Component {
  static propTypes = {
    dispatchGetRegisteredBuses: PropTypes.func.isRequired,
    dispatchClearRegisteredBuses: PropTypes.func.isRequired,
    buses: optionsPropTypes,
    onChangeFilter: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    buses: [],
    isMulti: false,
  };

  componentWillUnmount() {
    this.props.dispatchClearRegisteredBuses();
  }

  handleInputChange = (inputValue) => {
    if (inputValue.trim().length)
      this.props.dispatchGetRegisteredBuses({
        query: `companyBusId:${inputValue}`,
      });
  };

  agencyFilterOption = (buses) => buses;

  render() {
    const { buses, onChangeFilter, isMulti, isLoading } = this.props;
    return (
      <SelectFilter
        isMulti={isMulti}
        onChangeFilter={onChangeFilter}
        onInputChange={this.handleInputChange}
        isLoading={isLoading}
        options={buses}
        filterOption={this.agencyFilterOption}
      />
    );
  }
}

const mapStateToProps = ({ BusUnit }) => ({
  buses: registeredBusesGenerator(
    BusUnit.RegisteredBus.getIn(['all', 'content', 'content']),
  ),
  isLoading: BusUnit.RegisteredBus.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetRegisteredBuses: getRegisteredBuses,
  dispatchClearRegisteredBuses: clearRegisteredBuses,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(RegisteredBusFilter);
