import PropTypes from 'prop-types';

const busFuelReportHeaderPropTypes = PropTypes.shape({
  adblueEfficiency: PropTypes.number,
  busFuelGroup: PropTypes.string,
  busHomeBase: PropTypes.string,
  circuitQuantity: PropTypes.number,
  excess: PropTypes.number,
  expectedAdblueConsumption: PropTypes.number,
  expectedFuelConsumption: PropTypes.number,
  fuelEfficiency: PropTypes.number,
  performance: PropTypes.number,
  qualification: PropTypes.string,
  realAdblueConsumption: PropTypes.number,
  realFuelConsumption: PropTypes.number,
  saving: PropTypes.number,
  totalKilometers: PropTypes.number,
});

const busFuelReportRouteRowListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    baseFuelRefillGallon: PropTypes.number,
    circuitName: PropTypes.string,
    companyBusId: PropTypes.number,
    departureTime: PropTypes.number,
    destinationLocationName: PropTypes.string,
    driverSet: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    expectedFuelConsumption: PropTypes.number,
    fuelVoucherCodes: PropTypes.string,
    fuelVoucherRefillGallon: PropTypes.number,
    itineraryId: PropTypes.number,
    kilometers: PropTypes.number,
    realAdblueConsumption: PropTypes.number,
    realFuelConsumption: PropTypes.number,
    sourceLocationName: PropTypes.string,
  }),
);

const busFuelReportCircuitRowListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    adblueEfficiency: PropTypes.number,
    busFuelGroupId: PropTypes.number,
    busHomeBase: PropTypes.string,
    circuitName: PropTypes.string,
    companyBusId: PropTypes.number,
    departureTime: PropTypes.number,
    driverSet: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    expectedAdblueConsumption: PropTypes.number,
    expectedFuelConsumption: PropTypes.number,
    fuelEfficiency: PropTypes.number,
    performance: PropTypes.number,
    qualification: PropTypes.string,
    realAdblueConsumption: PropTypes.number,
    realFuelConsumption: PropTypes.number,
    totalKilometers: PropTypes.number,
  }),
);

const fuelConsumptionReportPerBusPropTypes = PropTypes.shape({
  busFuelReportHeaderPropTypes,
  busFuelReportRouteRowListPropTypes,
  busFuelReportCircuitRowListPropTypes,
});

export {
  busFuelReportHeaderPropTypes,
  busFuelReportRouteRowListPropTypes,
  busFuelReportCircuitRowListPropTypes,
  fuelConsumptionReportPerBusPropTypes,
};
