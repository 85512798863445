import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getItineraries, clearItineraries } from '../../../../../actions/index';
import {
  ITINERARY_PATH,
  NEW_ITINERARY_PATH,
} from '../../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import ModuleList from '../../../../layout/ModuleList';
import { ITINERARIES_COLUMNS } from '../../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../../common/resource/proptypes/CommonPropTypes';

export class Itineraries extends Component {
  constructor(props) {
    super(props);
    this.columns = ITINERARIES_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearItineraries();
  }

  onFetchData = (fetchData) => {
    const { dispatchGetItineraries } = this.props;

    const newFetchData = { ...fetchData };

    newFetchData.query = [...fetchData.query, ...['archived:false']];

    dispatchGetItineraries({ ...newFetchData });
  };

  render() {
    const { itineraries, loading, breadcrumbs } = this.props;

    const data = itineraries.get('content') || [];
    const pages = itineraries.get('totalPages') || null;
    const defaultPageSize = itineraries.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Itinerarios"
        buttonPath={NEW_ITINERARY_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: this.onFetchData,
          modelPath: ITINERARY_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_PATH,
    },
  ],
  itineraries: ItineraryUnit.Itinerary.getIn(['all', 'content']),
  loading: ItineraryUnit.Itinerary.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetItineraries: getItineraries,
  dispatchClearItineraries: clearItineraries,
};

Itineraries.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  itineraries: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetItineraries: PropTypes.func.isRequired,
  dispatchClearItineraries: PropTypes.func.isRequired,
};

Itineraries.defaultProps = {
  itineraries: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Itineraries);
