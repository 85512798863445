import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getExpense, clearExpense } from '../../../../actions/index';
import Loader from '../../../common/Loader';
import {
  EXPENSE_STATUS_PATH,
  generateExpenseStatusAddRemarkPath,
} from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ExpenseBasicInformationResource from '../expense/resource/ExpenseBasicInformationResource';
import ExpenseItemInformationResource from '../expense/resource/ExpenseItemInformationResource';
import NoDataResource from '../../../common/resource/NoDataResource';
import ExpensePerUserToolbar from './ExpensePerUserToolbar';
import ExpenseStatusRemarkResource from './resource/ExpenseStatusRemarkResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

export class ExpensePerUser extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetExpense,
    } = this.props;
    dispatchGetExpense(id);
  }

  componentWillUnmount() {
    this.props.dispatchClearExpense();
  }

  render() {
    const {
      expense,
      loading,
      match: {
        params: { id: expenseId },
      },
      breadcrumbs,
    } = this.props;

    let content;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (expense.isEmpty()) {
      content = <NoDataResource returnPage={EXPENSE_STATUS_PATH} />;
    } else {
      const expenseJson = expense.toJS();

      const basicInformationResource = (
        <ExpenseBasicInformationResource expense={expenseJson} />
      );

      const itemInformationResource = (
        <ExpenseItemInformationResource expense={expenseJson} />
      );

      const internalInformationResource = <InternalResource {...expenseJson} />;

      const expenseStatusRemarkResource = (
        <div className="mb-3">
          <ExpenseStatusRemarkResource expenseId={+expenseId} />
          <Link to={generateExpenseStatusAddRemarkPath(expenseId)}>
            Agregar nuevas notas
          </Link>
        </div>
      );

      content = (
        <Fragment>
          {basicInformationResource}
          {itemInformationResource}
          {expenseStatusRemarkResource}
          {internalInformationResource}
        </Fragment>
      );

      toolbar = (
        <ExpensePerUserToolbar
          expenseId={+expenseId}
          expenseStatus={expenseJson.expenseStatus}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Egreso"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Autorización de Egresos', href: EXPENSE_STATUS_PATH },
    { text: 'Ver', href: '' },
  ],
  expense: SalesUnit.Expense.getIn(['current', 'content']),
  loading: !SalesUnit.Expense.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExpense: getExpense,
  dispatchClearExpense: clearExpense,
};

ExpensePerUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  expense: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetExpense: PropTypes.func.isRequired,
  dispatchClearExpense: PropTypes.func.isRequired,
};

ExpensePerUser.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensePerUser);
