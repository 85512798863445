import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { Col, FormGroup, Label, ListGroupItem } from 'reactstrap';
import Select from '../../../../common/forms/select/Select';
import { optionsPropTypes } from '../../../../common/forms/select/SelectPropTypes';
import RenderTaskList from './RenderTaskList';

export const ActivityBusFields = ({
  index,
  activity,
  loadingMechanics,
  mechanics,
  onChange,
  employeeIdValues,
}) => {
  const [employee, setEmployee] = useState(employeeIdValues);

  // Update employee values from RenderTaskList > onChangeMechanic
  // because I am controlling its values manually
  useEffect(() => setEmployee(employeeIdValues), [employeeIdValues]);

  const onChangeEmployee = (options, inputActionMeta) => {
    setEmployee(options);
    onChange(options, inputActionMeta, index);
  };

  return (
    <ListGroupItem key={+index} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <FormGroup row>
        <Col lg={6} className="mb-2">
          <Label>Actividad</Label>
          <Field
            name={`${activity}.activityId`}
            component={Select}
            isDisabled
          />
        </Col>
        <Col lg={6}>
          <Label>Empleados</Label>
          <Field
            name={`${activity}.employeeId`}
            component={Select}
            options={mechanics}
            loading={loadingMechanics}
            input={{
              onChange: onChangeEmployee,
              value: employee,
            }}
            isMulti
            isDisabled={loadingMechanics}
          />
        </Col>
      </FormGroup>
      <FieldArray
        name={`${activity}.activityTaskList`}
        component={RenderTaskList}
        props={{ activityIndex: index }}
      />
    </ListGroupItem>
  );
};

ActivityBusFields.propTypes = {
  index: PropTypes.number.isRequired,
  activity: PropTypes.string.isRequired,
  loadingMechanics: PropTypes.bool.isRequired,
  mechanics: optionsPropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
  employeeIdValues: optionsPropTypes.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit, form }, { index }) => ({
  mechanics: MechanicalMaintenanceUnit.Mechanic.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, customer }) => ({
    value: id,
    label: customer.fullName,
  })),
  loadingMechanics: MechanicalMaintenanceUnit.Mechanic.getIn([
    'all',
    'loading',
  ]),
  employeeIdValues:
    form.AssignEmployeesToMaintenanceServiceOrderForm.values
      .activityBusExecutionList[index].employeeId,
});

export default connect(mapStateToProps)(ActivityBusFields);
