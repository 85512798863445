import React from 'react';
import { numberFormatter } from '../../../../utils/number';
import { parcelCreditSummaryPropTypes } from './SalesSessionPropTypes';
import Table from '../../../common/Table';

const ParcelCreditSummary = ({ parcelCreditSummaryList }) => {
  if (!parcelCreditSummaryList.length) {
    return null;
  }
  return (
    <>
      <hr />
      <h2>Crédito de Cargo</h2>
      <Table
        columns={[
          {
            Header: 'Método de Pago',
            accessor: 'moneyFlowConcept',
            className: 'text-center',
          },
          {
            Header: 'Total',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={parcelCreditSummaryList}
        defaultPageSize={parcelCreditSummaryList.length}
        showPagination={false}
      />
    </>
  );
};

ParcelCreditSummary.propTypes = {
  parcelCreditSummaryList: parcelCreditSummaryPropTypes.isRequired,
};

export default ParcelCreditSummary;
