import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import Table from '../../../../common/Table';
import {
  manualVoucherItemsDataDefaultProps,
  manualVoucherItemsDataPropTypes,
} from '../proptypes/ManualVoucherPropTypes';
import { numberFormatter } from '../../../../../utils/number';
import { SUNAT_INVOICE_CODE } from '../../../../../config/constants';

const ItemsInformationResource = ({
  items,
  totalAmountWithoutTaxes,
  taxes,
  totalAmount,
  detraction,
  detractionAmount,
  totalAmountPayable,
  currencyCode,
  voucherType,
}) => {
  const detractionResource = voucherType.voucherCode === SUNAT_INVOICE_CODE && (
    <>
      <ResourceProperty label="Porcentaje Detracción:">
        {numberFormatter({ style: 'percent', value: detraction })}
      </ResourceProperty>
      <ResourceProperty label="Monto Detracción:">
        {numberFormatter({
          style: 'currency',
          value: detractionAmount,
          currency: currencyCode,
        })}
      </ResourceProperty>
      <ResourceProperty label="Total a Pagar:">
        {numberFormatter({
          style: 'currency',
          value: totalAmountPayable,
          currency: currencyCode,
        })}
      </ResourceProperty>
    </>
  );
  return (
    <>
      <h3>Items a Facturar</h3>
      <div className="mb-3">
        <Table
          columns={[
            {
              Header: 'Cantidad',
              accessor: 'quantity',
              className: 'text-center',
            },
            {
              Header: 'Descripción',
              accessor: 'description',
              className: 'text-center',
            },
            {
              Header: 'Precio',
              accessor: 'unitaryPrice',
              Cell: (row) =>
                numberFormatter({
                  style: 'currency',
                  value: row.value,
                  currency: currencyCode,
                }),
              className: 'text-center',
            },
            {
              Header: 'Total',
              accessor: 'totalPrice',
              Cell: (row) =>
                numberFormatter({
                  style: 'currency',
                  value: row.value,
                  currency: currencyCode,
                }),
              className: 'text-center',
            },
          ]}
          data={items}
          defaultPageSize={items.length}
          showPagination={false}
        />
      </div>
      <ResourceProperty label="Total Gravada:">
        {numberFormatter({
          style: 'currency',
          value: totalAmountWithoutTaxes,
          currency: currencyCode,
        })}
      </ResourceProperty>
      <ResourceProperty label="Total IGV 18%:">
        {numberFormatter({
          style: 'currency',
          value: taxes,
          currency: currencyCode,
        })}
      </ResourceProperty>
      <ResourceProperty label="Importe Total:">
        {numberFormatter({
          style: 'currency',
          value: totalAmount,
          currency: currencyCode,
        })}
      </ResourceProperty>
      {detractionResource}
    </>
  );
};

ItemsInformationResource.propTypes = manualVoucherItemsDataPropTypes;

ItemsInformationResource.defaultProps = manualVoucherItemsDataDefaultProps;

export default ItemsInformationResource;
