import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  postChangePassenger,
  getTicketActionModal,
} from '../../../../../actions/index';
import Loader from '../../../../common/Loader';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import {
  generateTicketChangePassengerEndpoint,
  TICKET_CUSTOMER_CHANGE_MODAL_ENDPOINT,
} from '../../../../../config/endpoints';
import { POST } from '../../../../../config/permissions';
import PassengerChangeForm from './PassengerChangeForm';
import Modal from '../../../../common/modal/Modal';
import { MODAL_ACTION_CONFIRM_MESSAGE } from '../../../../../config/messages';

const PassengerChangeButton = ({
  dispatchPostChangePassenger,
  ticketId,
  loadingPassengerChange,
  dispatchGetTicketActionModal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(MODAL_ACTION_CONFIRM_MESSAGE);
  const [url, setUrl] = useState(null);

  const handleShowModal = async () => {
    setLoading(true);

    const response = await dispatchGetTicketActionModal({
      ticketId,
      url: TICKET_CUSTOMER_CHANGE_MODAL_ENDPOINT,
    });

    if (response && response.message && response.url) {
      setMessage(response.message);
      setUrl(response.url);
      setShowModal(true);
    }

    setLoading(false);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleChangePassenger = ({ customer }) =>
    dispatchPostChangePassenger(url, ticketId, customer.value);

  let body;

  if (loadingPassengerChange) body = <Loader />;
  else
    body = (
      <>
        <p>{message}</p>
        <PassengerChangeForm
          onSubmit={handleChangePassenger}
          onCancel={handleCloseModal}
        />
      </>
    );

  const modal = <Modal show={showModal} title="Cambiar Pasajero" body={body} />;

  return (
    <Fragment>
      <Button
        color="light"
        className="border"
        onClick={handleShowModal}
        disabled={loading}
      >
        <i className={`fa fa-${loading ? 'spinner fa-spin' : 'user'}`} />{' '}
        Cambiar Pasajero
      </Button>
      {modal}
    </Fragment>
  );
};

PassengerChangeButton.propTypes = {
  ticketId: PropTypes.number.isRequired,
  dispatchPostChangePassenger: PropTypes.func.isRequired,
  loadingPassengerChange: PropTypes.bool.isRequired,
  dispatchGetTicketActionModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ReservationUnit }) => ({
  loadingPassengerChange: ReservationUnit.Ticket.getIn([
    'current',
    'changingPassenger',
  ]),
});

const mapDispatchToProps = {
  dispatchPostChangePassenger: postChangePassenger,
  dispatchGetTicketActionModal: getTicketActionModal,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PassengerChangeButton);

export default withEndpointAuthorization({
  url: generateTicketChangePassengerEndpoint(0),
  permissionType: POST,
})(connectedComponent);
