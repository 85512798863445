import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { postBillingGrrByItinerary } from '../../../../../actions';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { generateGrrBillingByItineraryEndpoint } from '../../../../../config/endpoints';
import { POST } from '../../../../../config/permissions';

const ItinerarySendGrtButton = ({
  itineraryId,
  dispatchPostBillingGrrByItinerary,
}) => {
  const onSendGrr = () => dispatchPostBillingGrrByItinerary(itineraryId);

  return (
    <Button color="light" className="border" onClick={onSendGrr}>
      <i className="fa fa-send" /> Enviar GRR
    </Button>
  );
};

ItinerarySendGrtButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
  dispatchPostBillingGrrByItinerary: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostBillingGrrByItinerary: postBillingGrrByItinerary,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(ItinerarySendGrtButton);

export default withEndpointAuthorization({
  url: generateGrrBillingByItineraryEndpoint(0),
  permissionType: POST,
})(connectedComponent);
