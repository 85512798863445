import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getExpense, clearExpense } from '../../../../actions/index';
import Loader from '../../../common/Loader';
import { EXPENSE_PATH } from '../../../../config/paths';
import { TICKET_REFUND } from '../../../../config/constants';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ExpenseToolbar from './ExpenseToolbar';
import ExpenseBasicInformationResource from './resource/ExpenseBasicInformationResource';
import ExpenseItemInformationResource from './resource/ExpenseItemInformationResource';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

export class Expense extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetExpense,
    } = this.props;
    dispatchGetExpense(id);
  }

  componentWillUnmount() {
    this.props.dispatchClearExpense();
  }

  render() {
    const { expense, loading, breadcrumbs } = this.props;
    const {
      match: {
        params: { id: expenseId },
      },
    } = this.props;

    let content;
    let toolbar;

    const transactionNumber = expense.get('transactionList')
      ? expense.get('transactionList').length
      : 0;

    if (loading) {
      content = <Loader />;
    } else if (expense.isEmpty()) {
      content = <NoDataResource returnPage={EXPENSE_PATH} />;
    } else {
      if (
        expense.get('transaction').expenseType.expenseTypeLabel !==
        TICKET_REFUND
      ) {
        toolbar = (
          <ExpenseToolbar
            expenseId={+expenseId}
            isTripExpense={transactionNumber > 1}
          />
        );
      }

      const basicInformationResource = (
        <ExpenseBasicInformationResource expense={expense.toJS()} />
      );

      const itemInformationResource = (
        <ExpenseItemInformationResource expense={expense.toJS()} />
      );

      const internalInformationResource = (
        <InternalResource {...expense.toJS()} />
      );

      content = (
        <Fragment>
          {basicInformationResource}
          {itemInformationResource}
          {internalInformationResource}
        </Fragment>
      );
    }
    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Egreso"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Egresos', href: EXPENSE_PATH },
    { text: 'Ver', href: '' },
  ],
  expense: SalesUnit.Expense.getIn(['current', 'content']),
  loading: !SalesUnit.Expense.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExpense: getExpense,
  dispatchClearExpense: clearExpense,
};

Expense.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  expense: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetExpense: PropTypes.func.isRequired,
  dispatchClearExpense: PropTypes.func.isRequired,
};

Expense.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Expense);
