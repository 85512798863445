import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PRIVATE_SERVICE_PATH } from '../../../../config/paths';

const PrivateServiceRegisterPassengerButton = ({ privateServiceId }) => (
  <Link
    className="btn btn-light border"
    to={`${PRIVATE_SERVICE_PATH}/${privateServiceId}/register-passenger`}
  >
    <i className="fa fa-user" /> Pasajeros
  </Link>
);

PrivateServiceRegisterPassengerButton.propTypes = {
  privateServiceId: PropTypes.number.isRequired,
};

export default PrivateServiceRegisterPassengerButton;
