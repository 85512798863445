import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { getOccupancyReport, clearOccupancyReport } from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Table from '../../../common/Table';
import ReportToolbar from '../ReportToolbar';
import Loader from '../../../common/Loader';
import DateCell from '../../../common/cells/DateCell';
import Alert from '../../../common/informative/Alert';
import { OCCUPANCY_REPORTS_PATH } from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

class OccupancyReport extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        Header: 'Abordados',
        accessor: 'boardedTickets',
      },
      {
        Header: 'Capacidad',
        accessor: 'busCapacity',
      },
      {
        Header: 'Número de Bus',
        accessor: 'busNumber',
      },
      {
        Header: 'Hora de Salida',
        accessor: 'departureTime',
        Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
      },
      {
        Header: 'Distancia',
        accessor: 'distance',
      },
      {
        Header: 'Ingreso por Kilómetro',
        accessor: 'incomePerKilometer',
      },
      {
        Header: 'Itinerario',
        accessor: 'itineraryId',
      },
      {
        Header: 'Ingreso por Itinerario',
        accessor: 'itineraryIncome',
      },
      {
        Header: 'Ocupabilidad',
        accessor: 'occupancy',
      },
      {
        Header: 'Ruta',
        accessor: 'route',
      },
      {
        Header: 'Tipo de Servicio',
        accessor: 'serviceType',
      },
    ];
    this.download = this.download.bind(this);
  }

  componentWillMount() {
    // extract id from params injected by react router
    const { id } = this.props.match.params;
    this.props.getOccupancyReport({ id });
  }

  componentWillUnmount() {
    this.props.clearOccupancyReport();
  }

  download() {
    const { report } = this.props;
    const { id } = this.props.match.params;
    if (report) {
      return this.props.getOccupancyReport({
        id,
        reportFormat: 'csv',
        download: true,
      });
    }
    return false;
  }

  renderContent() {
    // check if report is loaded
    const { loading, report, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    // check if report is loading or empty
    if (loading || !report || report.isEmpty()) {
      return <Loader />;
    }

    // check if report is complete
    const reportStatus = report.get('reportStatus');
    if (reportStatus === 'GENERATING') {
      return <Loader />;
    }

    const occupancyReportRows = report.get('occupancyReportRows');
    let data = [];
    let pages = null;

    if (occupancyReportRows) {
      data = occupancyReportRows.content;
      pages = occupancyReportRows.totalPages;
    }
    const { columns } = this;
    return (
      <div>
        <ReportToolbar download={this.download} disabled={!data.length} />
        <Table columns={columns} data={data} pages={pages} loading={loading} />
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <h1>Reporte de Ocupabilidad</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ocupabilidad',
      href: OCCUPANCY_REPORTS_PATH,
    },
    {
      text: 'Ver',
      href: state.routing.location.pathname,
    },
  ],
  error: state.ReportUnit.OccupancyReport.get('current').get('error'),
  report: state.ReportUnit.OccupancyReport.get('current').get('content'),
  loading: state.ReportUnit.OccupancyReport.get('current').get('loading'),
});

const mapDispatchToProps = {
  getOccupancyReport,
  clearOccupancyReport,
};

OccupancyReport.propTypes = {
  getOccupancyReport: PropTypes.func.isRequired,
  clearOccupancyReport: PropTypes.func.isRequired,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  report: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
  match: matchPropTypes.isRequired,
};

OccupancyReport.defaultProps = {
  report: null,
  loading: false,
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(OccupancyReport);
