import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  NEW_DEPRECIATION_RULE_PATH,
  DEPRECIATION_RULE_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import DepreciationRuleForm from './DepreciationRuleForm';
import { postDepreciationRule } from '../../../../actions';

// eslint-disable-next-line react/prefer-stateless-function
class NewDepreciationRule extends Component {
  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1>Nueva Regla de Depreciación</h1>
            <p>Crear una nueva regla de depreciación</p>
          </Col>
        </Row>
        <DepreciationRuleForm
          onSubmit={this.props.dispatchPostDepreciationRule}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reglas de Depreciación',
      href: DEPRECIATION_RULE_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_DEPRECIATION_RULE_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostDepreciationRule: postDepreciationRule,
};

NewDepreciationRule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDepreciationRule: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDepreciationRule);
