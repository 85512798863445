export const FLAG_GETTING_ACTIVE_SALES_SESSION =
  'FLAG_GETTING_ACTIVE_SALES_SESSION';
export const GET_ACTIVE_SALES_SESSION = 'GET_ACTIVE_SALES_SESSION';
export const SHOW_ERROR_GETTING_ACTIVE_SALES_SESSION =
  'SHOW_ERROR_GETTING_ACTIVE_SALES_SESSION';
export const CLEAR_ERROR_GETTING_ACTIVE_SALES_SESSION =
  'CLEAR_ERROR_GETTING_ACTIVE_SALES_SESSION';
export const CLEAR_ACTIVE_SALES_SESSION = 'CLEAR_ACTIVE_SALES_SESSION';

// get all
export const FLAG_GETTING_SALES_SESSIONS = 'FLAG_GETTING_SALES_SESSIONS';
export const GET_SALES_SESSIONS = 'GET_SALES_SESSIONS';
export const CLEAR_SALES_SESSIONS = 'CLEAR_SALES_SESSIONS';

// get one
export const FLAG_SALES_SESSION_ACTIVITY = 'FLAG_SALES_SESSION_ACTIVIY';
export const GET_SALES_SESSION = 'GET_SALES_SESSION';
export const GET_OLD_SALES_SESSION = 'GET_OLD_SALES_SESSION';
export const CLEAR_SALES_SESSION = 'CLEAR_SALES_SESSION';
