import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  CLEAR_CARGO_CORPORATE_CREDIT,
  CLEAR_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES,
  CLEAR_CARGO_CORPORATE_CREDITS,
  CLEAR_CARGO_CORPORATE_INVOICES,
  FLAG_CARGO_CORPORATE_CREDIT_ACTIVITY,
  FLAG_GETTING_CARGO_CORPORATE_CREDITS,
  FLAG_GETTING_CARGO_CORPORATE_INVOICES,
  GET_CARGO_CORPORATE_CREDIT,
  GET_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES,
  GET_CARGO_CORPORATE_CREDITS,
  GET_CARGO_CORPORATE_INVOICES,
} from '../../../actions/types/cargo/CargoCorporateCredit';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    invoices: Immutable.Set(),
    loadingInvoices: false,
    manualVoucherValues: Immutable.Map(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_CARGO_CORPORATE_CREDITS:
      return state.setIn(['all', 'loading'], payload);
    case GET_CARGO_CORPORATE_CREDITS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_CARGO_CORPORATE_CREDITS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_CARGO_CORPORATE_CREDIT_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_CARGO_CORPORATE_CREDIT:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_CARGO_CORPORATE_CREDIT:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_GETTING_CARGO_CORPORATE_INVOICES:
      return state.setIn(['current', 'loadingInvoices'], payload);
    case GET_CARGO_CORPORATE_INVOICES:
      return state.setIn(['current', 'invoices'], Immutable.Set(payload));
    case CLEAR_CARGO_CORPORATE_INVOICES:
      return state.setIn(
        ['current', 'invoices'],
        INITIAL_STATE.getIn(['current', 'invoices']),
      );
    case GET_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES:
      return state.setIn(
        ['current', 'manualVoucherValues'],
        Immutable.Map(payload),
      );
    case CLEAR_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES:
      return state.setIn(
        ['current', 'manualVoucherValues'],
        INITIAL_STATE.getIn(['current', 'manualVoucherValues']),
      );
    default:
      return state;
  }
};
