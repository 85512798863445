import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { GENERAL_INCOME_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import GeneralIncomeForm from './GeneralIncomeForm';
import Loader from '../../../common/Loader';
import {
  clearGeneralIncome,
  getGeneralIncome,
  putGeneralIncome,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditGeneralIncome extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: generalIncomeId },
      },
      dispatchGetGeneralIncome,
    } = this.props;
    dispatchGetGeneralIncome({ generalIncomeId });
  }

  componentWillUnmount() {
    const { dispatchClearGeneralIncome } = this.props;
    dispatchClearGeneralIncome();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutGeneralIncome,
      match: {
        params: { id },
      },
    } = this.props;

    const newFormValues = {
      amount: formValues.amount,
      authorizerUserId: formValues.authorizerUserId.value,
      description: formValues.description || null,
      documentCode: formValues.documentCode,
      documentSeries: formValues.documentSeries,
      generalIncomeTypeId: formValues.generalIncomeTypeId.value,
      payerCustomerId: formValues.payerCustomerId.value,
    };

    dispatchPutGeneralIncome(id, newFormValues);
  };

  createInitialValues = (income) => ({
    salesSessionId: income.get('transaction').salesSessionId,
    amount: income.get('total'),
    description: income.get('description'),
    documentCode: income.get('documentCode'),
    documentSeries: income.get('documentSeries'),
    authorizerUserId: {
      value: income.get('authorizerUserId'),
      label: income.get('authorizedUser').customer.fullName,
    },
    generalIncomeTypeId: {
      value: income.get('transaction').generalIncomeTypeId,
      label: income.get('transaction').generalIncomeType.name,
    },
    payerCustomerId: {
      value: income.get('payerCustomerId'),
      label: income.get('payerCustomer').fullName,
    },
  });

  render() {
    const { breadcrumbs, income, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (income.isEmpty()) {
      content = <NoDataResource returnPage={GENERAL_INCOME_PATH} />;
    } else {
      content = (
        <GeneralIncomeForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(income)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Ingreso"
        subtitle="Edita este ingreso"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { SalesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ingresos',
      href: GENERAL_INCOME_PATH,
    },
    {
      text: 'Ver',
      href: `${GENERAL_INCOME_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  income: SalesUnit.GeneralIncome.getIn(['current', 'content']),
  loading: !SalesUnit.GeneralIncome.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutGeneralIncome: putGeneralIncome,
  dispatchGetGeneralIncome: getGeneralIncome,
  dispatchClearGeneralIncome: clearGeneralIncome,
};

EditGeneralIncome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutGeneralIncome: PropTypes.func.isRequired,
  dispatchGetGeneralIncome: PropTypes.func.isRequired,
  dispatchClearGeneralIncome: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  income: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditGeneralIncome.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGeneralIncome);
