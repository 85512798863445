import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditGeographicZonesCombinationButton from './EditGeographicZonesCombinationButton';
import DeleteGeographicZonesCombinationButton from './DeleteGeographicZonesCombinationButton';

const GeographicZonesCombinationToolbar = ({
  geographicZonesCombinationId,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditGeographicZonesCombinationButton
        geographicZonesCombinationId={geographicZonesCombinationId}
      />{' '}
      <DeleteGeographicZonesCombinationButton
        geographicZonesCombinationId={geographicZonesCombinationId}
      />
    </div>
  </ButtonToolbar>
);

GeographicZonesCombinationToolbar.propTypes = {
  geographicZonesCombinationId: PropTypes.number.isRequired,
};

export default GeographicZonesCombinationToolbar;
