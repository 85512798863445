import {
  CLEAR_AREA_CONTENT,
  CLEAR_DEPARTMENT_CONTENT,
  CLEAR_MANAGEMENT_CONTENT,
  CLEAR_SECTION_CONTENT,
  CLEAR_SPECIALITY_CONTENT,
  GET_AREA_CONTENT,
  GET_DEPARTMENT_CONTENT,
  GET_MANAGEMENT_CONTENT,
  GET_SECTION_CONTENT,
  GET_SPECIALITY_CONTENT,
} from '../actions/types';
import {
  SEARCH_STEP_PATH,
  PASSENGERS_STEP_PATH,
  PAYMENT_STEP_PATH,
  CONFIRMATION_STEP_PATH,
  POSTPAID_BOOKING_SEARCH_PATH,
  POSTPAID_BOOKING_PASSENGERS_PATH,
  POSTPAID_BOOKING_RECEIVER_PATH,
  POSTPAID_BOOKING_SUMMARY_PATH,
} from './paths';

export const DEFAULT_SELECT_PLACEHOLDER = 'Seleccione...';
export const PEN_SYMBOL = 'S/';
export const USD_SYMBOL = '$';
export const KILOGRAM_SYMBOL = 'kg.';
export const KILOMETER_SYMBOL = 'km.';
export const MINUTE_SYMBOL = 'min.';
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SORT = ['id,desc'];
export const DEFAULT_ORDER = 'desc';
export const MAX_DAYS_IN_REPORT = 120;
export const DATE_STRING_LENGTH = 10;
export const NUM_PASSENGERS_OPTIONS = [
  { value: null, label: 'Disponibles' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];
export const GENDERS = [
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Femenino' },
];

export const CREDIT_NOTE = 'CREDIT_NOTE';
export const VOUCHER_TYPE_BUSINESS_INVOICE_ID = 2;
export const VOUCHER_TYPE_INVOICE_ID = 1;
export const BUSINESS_INVOICE_ID = 2;

// transaction codes
export const TRANSACTION_TYPE_CREDIT_CARD = 'CREDIT_CARD';
export const TRANSACTION_TYPE_PAGO_EFECTIVO = 'PAGO_EFECTIVO';
export const TRANSACTION_TYPE_DELIVERY_CREDIT_CARD = 'DELIVERY_CREDIT_CARD';
export const TRANSACTION_TYPE_DELIVERY_CASH = 'DELIVERY_CASH';
export const TRANSACTION_TYPE_BANK_TRANSFER = 'BANK_TRANSFER';
export const TRANSACTION_TYPE_BUSINESS_CREDIT = 'BUSINESS_CREDIT';
export const TRANSACTION_TYPE_CONTRACT = 'CONTRACT';
// max page size due to restrictions with 32 bits
export const MAX_PAGE_SIZE = 2147483647;

export const BOOKING_SEARCH_STEP_INDEX = 0;
export const BOOKING_PASSENGERS_STEP_INDEX = 1;
export const BOOKING_PAYMENT_STEP_INDEX = 2;
export const BOOKING_CONFIRMATION_STEP_INDEX = 3;
export const STEPS = [
  {
    title: 'Búsqueda',
    path: SEARCH_STEP_PATH,
    statePropertyName: 'search',
  },
  {
    title: 'Pasajeros',
    path: PASSENGERS_STEP_PATH,
    statePropertyName: 'passengers',
  },
  {
    title: 'Pago',
    path: PAYMENT_STEP_PATH,
    statePropertyName: 'payment',
  },
  {
    title: 'Confirmación',
    path: CONFIRMATION_STEP_PATH,
    statePropertyName: 'confirmation',
  },
];

export const POSTPAID_BOOKING_SEARCH_STEP_INDEX = 0;
export const POSTPAID_BOOKING_PASSENGERS_STEP_INDEX = 1;
export const POSTPAID_BOOKING_RECEIVER_STEP_INDEX = 2;
export const POSTPAID_BOOKING_SUMMARY_STEP_INDEX = 3;
export const POSTPAID_BOOKING_STEPS = [
  {
    title: 'Búsqueda',
    path: POSTPAID_BOOKING_SEARCH_PATH,
    statePropertyName: 'search',
  },
  {
    title: 'Pasajeros',
    path: POSTPAID_BOOKING_PASSENGERS_PATH,
    statePropertyName: 'passengers',
  },
  {
    title: 'Destinatario',
    path: POSTPAID_BOOKING_RECEIVER_PATH,
    statePropertyName: 'receiver',
  },
  {
    title: 'Resumen',
    path: POSTPAID_BOOKING_SUMMARY_PATH,
    statePropertyName: 'summary',
  },
];

export const POSTPAID_BOOKING_PENDING = 0;
export const POSTPAID_BOOKING_APPROVED = 1;
export const POSTPAID_BOOKING_REJECTED = 2;

export const ITINERARY_ACTIVE_OPTIONS = [
  {
    value: true,
    label: 'Activo',
  },
  {
    value: false,
    label: 'Inactivo',
  },
];

export const POSTPAID_BOOKING_STATUS_OPTIONS = [
  {
    value: 0,
    label: 'Pendiente',
  },
  {
    value: 1,
    label: 'Aprobado',
  },
  {
    value: 2,
    label: 'Rechazado',
  },
];

export const QUERY_DELIMITER = ',';

export const DEBOUNCE_WAIT_TIME = 400;

export const ITINERARY_TYPE_CONTRACT = 'Contract';

// ticket status

export const TICKET_ACTIVE = 0;
export const TICKET_VOIDED = 1;
export const TICKET_POSTPONED = 2;
export const TICKET_POSTPONED_DESC = 'Ticket postponed';

export const TICKET_REFUND = 'TICKET_REFUND';

export const DNI_ENUM_VALUE = 'DNI_LIBRETA_ELECTORAL';
export const PASAPORTE_ENUM_VALUE = 'PASAPORTE';
export const CARNET_EXTRANJERIA_ENUM_VALUE = 'CARNET_EXTRANJERIA';
export const OTROS_ENUM_VALUE = 'OTROS';

// regex
export const EMAIL_PATTERN =
  /^[-\w.+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
export const TICKET_PATTERN = /^[a-zA-z0-9]{1,}-?[0-9]{1,}$/;
export const DEPOSIT_FILE_TYPE_PATTERN =
  /(image|application)\/(jpg|jpeg|png|gif|pdf)$/;
export const EXCEL_FILE_PATTERN = /^(.*\.((xls|xlsx)$))[^.]*$/gim;
export const BILLING_DATE_PATTERN =
  /^(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/;
export const BILLING_TIME_PATTERN =
  /^(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])$/;

// regex ID number
export const DNI_PATTERN = /^\d{8}$/;
export const CARNET_EXTRANJERIA_PATTERN = /^[a-zA-Z0-9]{12}$/;
export const PASAPORTE_PATTERN = /^[a-zA-Z0-9]{1,12}$/;
export const OTROS_PATTERN = /^[a-zA-Z0-9]{1,15}$/;

// two step authorization
export const TWO_STEP_AUTHORIZATION = 'TWO_STEP_AUTHORIZATION';

// debounce time in milliseconds
export const CUSTOMER_SEARCH_DEBOUNCE = 275;

export const TICKET_STATUS = {
  ACTIVE: { value: 'ACTIVE', label: 'Activo', color: 'success' },
  IN_SALE_PROCESS: {
    value: 'IN_SALE_PROCESS',
    label: 'En proceso de venta',
    color: 'info',
  },
  VOIDED: { value: 'VOIDED', label: 'Anulado', color: 'danger' },
  POSTPONED: { value: 'POSTPONED', label: 'Postergado', color: 'warning' },
  CUSTOMER_CHANGE: {
    value: 'CUSTOMER_CHANGE',
    label: 'Cambio de pasajero',
    color: 'warning',
  },
  VOUCHER_TYPE_CHANGE: {
    value: 'VOUCHER_TYPE_CHANGE',
    label: 'Cambio de comprobante',
    color: 'warning',
  },
  REFUNDED: { value: 'REFUNDED', label: 'Devuelto', color: 'warning' },
  IN_POSTPONE_PROCESS: {
    value: 'IN_POSTPONE_PROCESS',
    label: 'En proceso de postergación',
    color: 'warning',
  },
};

export const TICKET_STATUS_OPTIONS = [
  {
    value: TICKET_STATUS.ACTIVE.value,
    label: TICKET_STATUS.ACTIVE.label,
  },
  {
    value: TICKET_STATUS.VOIDED.value,
    label: TICKET_STATUS.VOIDED.label,
  },
  {
    value: TICKET_STATUS.POSTPONED.value,
    label: TICKET_STATUS.POSTPONED.label,
  },
  {
    value: TICKET_STATUS.CUSTOMER_CHANGE.value,
    label: TICKET_STATUS.CUSTOMER_CHANGE.label,
  },
  {
    value: TICKET_STATUS.VOUCHER_TYPE_CHANGE.value,
    label: TICKET_STATUS.VOUCHER_TYPE_CHANGE.label,
  },
  {
    value: TICKET_STATUS.REFUNDED.value,
    label: TICKET_STATUS.REFUNDED.label,
  },
];

export const EXTRAORDINARY_MOVEMENT_CONTROL_STATUS = {
  PENDING: { value: 'PENDING', label: 'Pendiente', color: 'info' },
  ALERT: { value: 'ALERT', label: 'Alerta', color: 'warning' },
  AUTHORIZED: { value: 'AUTHORIZED', label: 'Autorizado', color: 'success' },
};

export const FUEL_VOUCHER_STATUS = {
  CREATED: { value: 'CREATED', label: 'Creado', color: 'info' },
  INVOICED: { value: 'INVOICED', label: 'Facturado', color: 'success' },
  INACTIVE: { value: 'INACTIVE', label: 'Inactivo', color: 'danger' },
};

export const FUEL_VOUCHER_EXTRAORDINARY_OPTIONS = [
  { value: true, label: 'Extraordinario' },
  { value: false, label: 'Regular' },
];

export const EXPENSE_TYPE_LABEL = {
  FUEL: { value: 'FUEL', label: 'Combustible' },
  DRIVER_COMMISSION: {
    value: 'DRIVER_COMMISSION',
    label: 'Comisión de Piloto',
  },
  CABIN_CREW_COMMISSION: {
    value: 'CABIN_CREW_COMMISSION',
    label: 'Comisión de Servicio a Bordo',
  },
  TOLL: { value: 'TOLL', label: 'Peaje' },
  OTHER: { value: 'OTHER', label: 'Otro' },
  TICKET_VOID: { value: 'TICKET_VOID', label: 'Anulación de Boleto' },
  TICKET_REFUND: { value: 'TICKET_REFUND', label: 'Devolución de Boleto' },
  EXCESS_BAGGAGE_VOID: {
    value: 'EXCESS_BAGGAGE_VOID',
    label: 'Anulación Exceso de Equipaje',
  },
  CASH_OUTFLOW: {
    value: 'CASH_OUTFLOW',
    label: 'Salida de Caja',
  },
  PARCEL_REFUND: {
    value: 'PARCEL_REFUND',
    label: 'Devolución de carga',
  },
};

export const TRIP_STATUS = {
  scheduled: { value: 1, label: 'Programado' },
  departed: { value: 2, label: 'Partido' },
  arrived: { value: 3, label: 'Arribado' },
  deleted: { value: 4, label: 'Detenido' },
  diverted: { value: 5, label: 'Desviado' },
  cancelled: { value: 6, label: 'Cancelado' },
  transferred: { value: 7, label: 'Transferido' },
  terminated: { value: 8, label: 'Terminado' },
};

export const TRIP_STATUS_OPTIONS = [
  { value: TRIP_STATUS.departed.value, label: TRIP_STATUS.departed.label },
  { value: TRIP_STATUS.arrived.value, label: TRIP_STATUS.arrived.label },
];

export const ITINERARY_PRINT_BUTTON = {
  MANIFEST: { value: 'manifest', label: 'Manifiesto' },
  SEATING_CHART: { value: 'seating-chart', label: 'Croquis' },
  BAGGAGE_MANIFEST: { value: 'baggage-manifest', label: 'Manifiesto' },
  CARGO_MANIFEST: { value: 'cargo-manifest', label: 'Manifiesto' },
};

export const ITINERARY_PRINT_DROPDOWN_OPTIONS = [
  { value: 'header', label: 'Pasajeros' },
  { value: ITINERARY_PRINT_BUTTON.MANIFEST.value, label: 'Manifiesto' },
  { value: ITINERARY_PRINT_BUTTON.SEATING_CHART.value, label: 'Croquis' },
  { value: 'divider' },
  { value: 'header', label: 'Equipajes' },
  { value: ITINERARY_PRINT_BUTTON.BAGGAGE_MANIFEST.value, label: 'Manifiesto' },
  { value: 'header', label: 'Carga' },
  { value: ITINERARY_PRINT_BUTTON.CARGO_MANIFEST.value, label: 'Manifiesto' },
];

export const BAGGAGE_SEARCH_OPTIONS = [
  { value: 'ticketCode', label: 'Boleto de Viaje' },
  { value: 'excessCode', label: 'Boleta de Exceso' },
];

export const DEPRECIATION_TYPE = {
  ALL: { value: 'ALL', label: 'General' },
  CATEGORIZED: {
    value: 'CATEGORIZED',
    label: 'Por categorías',
  },
};

export const DEPRECIATION_PERIODICITY = {
  DAILY: { value: 'DAILY', label: 'Diaria' },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'Mensual',
  },
  YEARLY: {
    value: 'YEARLY',
    label: 'Anual',
  },
};

export const PARCEL_STATUS = {
  IN_ORIGIN_AGENCY: {
    value: 'IN_ORIGIN_AGENCY',
    label: 'En agencia de origen',
  },
  DELIVERED: {
    value: 'DELIVERED',
    label: 'Entregado',
  },
};

export const PARCEL_FINANCIAL_STATUS = {
  REFUNDED: { value: 'REFUNDED', label: 'Devuelto', color: 'danger' },
  ACTIVE: { value: 'ACTIVE', label: 'Activo', color: 'success' },
};

export const BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS = [
  { value: 0, label: 'Itinerario' },
  { value: 1, label: 'Estado de item' },
  { value: 2, label: 'Boleto de viaje' },
  { value: 3, label: 'Item de equipaje' },
];

export const BAGGAGE_MOVEMENT_TYPE_OPTIONS = [
  { value: 0, label: 'Itinerario' },
  { value: 1, label: 'Agencia' },
];

export const BAGGAGE_ITEM_STATUS = {
  IN_ORIGIN_AGENCY: {
    value: 'IN_ORIGIN_AGENCY',
    label: 'En Agencia de Origen',
  },
  ON_ITINERARY: { value: 'ON_ITINERARY', label: 'En Itinerario' },
  AGENCY_STOP: { value: 'AGENCY_STOP', label: 'Agencia de Paso' },
  DELIVERED: { value: 'DELIVERED', label: 'Entregado' },
  NOT_ARRIVED: { value: 'NOT_ARRIVED', label: 'No Arribado' },
  CLOSED: { value: 'CLOSED', label: 'Cerrado' },
  VOIDED: { value: 'VOIDED', label: 'Anulado' },
};

export const BAGGAGE_ITEM_STATUS_OPTIONS = [
  {
    value: BAGGAGE_ITEM_STATUS.IN_ORIGIN_AGENCY.value,
    label: BAGGAGE_ITEM_STATUS.IN_ORIGIN_AGENCY.label,
  },
  {
    value: BAGGAGE_ITEM_STATUS.ON_ITINERARY.value,
    label: BAGGAGE_ITEM_STATUS.ON_ITINERARY.label,
  },
  {
    value: BAGGAGE_ITEM_STATUS.AGENCY_STOP.value,
    label: BAGGAGE_ITEM_STATUS.AGENCY_STOP.label,
  },
  {
    value: BAGGAGE_ITEM_STATUS.DELIVERED.value,
    label: BAGGAGE_ITEM_STATUS.DELIVERED.label,
  },
  {
    value: BAGGAGE_ITEM_STATUS.NOT_ARRIVED.value,
    label: BAGGAGE_ITEM_STATUS.NOT_ARRIVED.label,
  },
  {
    value: BAGGAGE_ITEM_STATUS.CLOSED.value,
    label: BAGGAGE_ITEM_STATUS.CLOSED.label,
  },
];

export const SPOT_IN_ITINERARY = {
  START: BAGGAGE_ITEM_STATUS.ON_ITINERARY,
  END: BAGGAGE_ITEM_STATUS.DELIVERED, // Before was BAGGAGE_ITEM_STATUS.ARRIBED but in the backend doesn't exist
  OTHER: BAGGAGE_ITEM_STATUS.AGENCY_STOP,
};

export const USER_ENABLED_OPTIONS = [
  {
    value: true,
    label: 'Activo',
  },
  {
    value: false,
    label: 'Inactivo',
  },
];

export const ITINERARY_REPEAT_TYPE = {
  NO_REPEAT: { value: 'NO_REPEAT', label: 'Ninguna', unit: '' },
  DAILY_REPEAT: { value: 'DAILY_REPEAT', label: 'Diario', unit: 'días' },
  WEEKLY_REPEAT: { value: 'WEEKLY_REPEAT', label: 'Semanal', unit: 'semanas' },
  MONTHLY_REPEAT: { value: 'MONTHLY_REPEAT', label: 'Mensual', unit: 'meses' },
  YEARLY_REPEAT: { value: 'YEARLY_REPEAT', label: 'Anual', unit: 'años' },
};

export const ITINERARY_REPEAT_TYPE_OPTIONS = [
  {
    value: ITINERARY_REPEAT_TYPE.NO_REPEAT.value,
    label: ITINERARY_REPEAT_TYPE.NO_REPEAT.label,
    unit: ITINERARY_REPEAT_TYPE.NO_REPEAT.unit,
  },
  {
    value: ITINERARY_REPEAT_TYPE.DAILY_REPEAT.value,
    label: ITINERARY_REPEAT_TYPE.DAILY_REPEAT.label,
    unit: ITINERARY_REPEAT_TYPE.DAILY_REPEAT.unit,
  },
  {
    value: ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.value,
    label: ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.label,
    unit: ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.unit,
  },
  {
    value: ITINERARY_REPEAT_TYPE.MONTHLY_REPEAT.value,
    label: ITINERARY_REPEAT_TYPE.MONTHLY_REPEAT.label,
    unit: ITINERARY_REPEAT_TYPE.MONTHLY_REPEAT.unit,
  },
  {
    value: ITINERARY_REPEAT_TYPE.YEARLY_REPEAT.value,
    label: ITINERARY_REPEAT_TYPE.YEARLY_REPEAT.label,
    unit: ITINERARY_REPEAT_TYPE.YEARLY_REPEAT.unit,
  },
];

export const ITINERARY_SCHEDULE_MACIVA_OPTIONS = [
  {
    value: true,
    label: 'Si',
  },
  {
    value: false,
    label: 'No',
  },
];

export const TRIP_CIRCUIT_STATUS = {
  START: { value: 'START', label: 'Inicio' },
  IN_PROGRESS: { value: 'IN_PROGRESS', label: 'En progreso' },
  END: { value: 'END', label: 'Fin' },
  UNKNOWN: { value: 'UNKNOWN', label: 'Desconocido' },
};

export const TRIP_CIRCUIT_STATUS_OPTIONS = [
  {
    value: TRIP_CIRCUIT_STATUS.START.value,
    label: TRIP_CIRCUIT_STATUS.START.label,
  },
  {
    value: TRIP_CIRCUIT_STATUS.IN_PROGRESS.value,
    label: TRIP_CIRCUIT_STATUS.IN_PROGRESS.label,
  },
  {
    value: TRIP_CIRCUIT_STATUS.END.value,
    label: TRIP_CIRCUIT_STATUS.END.label,
  },
  {
    value: TRIP_CIRCUIT_STATUS.UNKNOWN.value,
    label: TRIP_CIRCUIT_STATUS.UNKNOWN.label,
  },
];

export const CONTRACT_TYPE = {
  DEFAULT: { value: 'DEFAULT', label: 'Agencia Civa' },
  AE_AGENCY: { value: 'AE_AGENCY', label: 'Concesionario' },
  CORPORATE: { value: 'CORPORATE', label: 'Cliente Corporativo' },
  SELF_SERVICE: { value: 'SELF_SERVICE', label: 'Autoventa' },
  CASH_OUTFLOW: { value: 'CASH_OUTFLOW', label: 'Salida de Caja' },
};

export const DEPOSIT_TYPE = {
  DEFAULT: { value: 'DEFAULT', label: 'Por defecto' },
  DETRACCION: { value: 'DETRACCION', label: 'Detracción' },
  COMMISSION: { value: 'COMMISSION', label: 'Comisión' },
};

export const DEPOSIT_TYPE_OPTIONS = [
  {
    value: DEPOSIT_TYPE.DETRACCION.value,
    label: DEPOSIT_TYPE.DETRACCION.label,
  },
  {
    value: DEPOSIT_TYPE.COMMISSION.value,
    label: DEPOSIT_TYPE.COMMISSION.label,
  },
];

export const COMPANY_TYPE = {
  CIVA: { value: 'CIVA', label: 'Civa' },
  OTHER: { value: 'OTHER', label: 'Otros' },
};

export const COMPANY_TYPE_OPTIONS = [
  {
    value: COMPANY_TYPE.CIVA.value,
    label: COMPANY_TYPE.CIVA.label,
  },
  {
    value: COMPANY_TYPE.OTHER.value,
    label: COMPANY_TYPE.OTHER.label,
  },
];

export const ITINERARY_SCHEDULE_EDIT_BUTTON = {
  ITINERARY_SCHEDULE_EDIT: {
    value: 'itinerary-schedule-edit',
    label: 'Plantilla',
  },
  ITINERARY_STATUSSES_EDIT: {
    value: 'itinerary-statusses-edit',
    label: 'Estado de Itinerarios',
  },
};

export const ITINERARY_SCHEDULE_EDIT_DROPDOWN_OPTIONS = [
  {
    value: ITINERARY_SCHEDULE_EDIT_BUTTON.ITINERARY_SCHEDULE_EDIT.value,
    label: ITINERARY_SCHEDULE_EDIT_BUTTON.ITINERARY_SCHEDULE_EDIT.label,
  },
  { value: 'divider' },
  {
    value: ITINERARY_SCHEDULE_EDIT_BUTTON.ITINERARY_STATUSSES_EDIT.value,
    label: ITINERARY_SCHEDULE_EDIT_BUTTON.ITINERARY_STATUSSES_EDIT.label,
  },
];

export const EXTRAORDINARY_MOVEMENT_TYPE = {
  TRANSFER: { value: 'TRANSFER', label: 'Transferido' },
  CONTRACT: { value: 'CONTRACT', label: 'Contrato' },
  SERVICE_REPLACE: { value: 'SERVICE_REPLACE', label: 'Reemplazo' },
  MAINTENANCE_TEST: { value: 'MAINTENANCE_TEST', label: 'Mantenimiento' },
  TRAFFIC: { value: 'TRAFFIC', label: 'Tráfico' },
  PARCEL: { value: 'PARCEL', label: 'Pasajes y Cargo' },
  AUTHORIZED_BY_MANAGMENT: {
    value: 'AUTHORIZED_BY_MANAGMENT',
    label: 'Autorizado por Gerencia',
  },
  OTHER: { value: 'OTHER', label: 'Otros' },
};

export const EXTRAORDINARY_MOVEMENT_TYPE_OPTIONS = [
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.TRANSFER.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.TRANSFER.label,
  },
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.SERVICE_REPLACE.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.SERVICE_REPLACE.label,
  },
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.CONTRACT.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.CONTRACT.label,
  },
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.MAINTENANCE_TEST.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.MAINTENANCE_TEST.label,
  },
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.TRAFFIC.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.TRAFFIC.label,
  },
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.PARCEL.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.PARCEL.label,
  },
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.AUTHORIZED_BY_MANAGMENT.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.AUTHORIZED_BY_MANAGMENT.label,
  },
  {
    value: EXTRAORDINARY_MOVEMENT_TYPE.OTHER.value,
    label: EXTRAORDINARY_MOVEMENT_TYPE.OTHER.label,
  },
];

export const ITINERARY_TYPE = {
  regular: { value: 'regular', label: 'Regular' },
  adicional: { value: 'adicional', label: 'Adicional' },
  contrato: { value: 'contrato', label: 'Contrato' },
  contract: { value: 'contract', label: 'Contrato' },
  parcel: { value: 'parcel', label: 'Cargo' },
  cargo: { value: 'Cargo', label: 'Cargo' }, // TODO remove this when backend has been estandardized
  extraordinario: { value: 'extraordinario', label: 'Extraordinario' },
};

export const ITINERARY_TYPE_OPTIONS = [
  {
    value: ITINERARY_TYPE.regular.value,
    label: ITINERARY_TYPE.regular.label,
  },
  {
    value: ITINERARY_TYPE.adicional.value,
    label: ITINERARY_TYPE.adicional.label,
  },
  {
    value: ITINERARY_TYPE.contrato.value,
    label: ITINERARY_TYPE.contrato.label,
  },
  {
    value: ITINERARY_TYPE.parcel.value,
    label: ITINERARY_TYPE.parcel.label,
  },
];

export const ITINERARY_STATUS_SEARCH_OPTIONS = [
  { value: true, label: 'Itinerario programado' },
  { value: false, label: 'Itinerario no programado' },
];

export const PURCHASE_ORDER_STATUS = {
  CREATED: { value: 'CREATED', label: 'Creado', color: 'warning' },
  APPROVED: { value: 'APPROVED', label: 'Aprobado', color: 'success' },
  REJECTED: { value: 'REJECTED', label: 'Rechazado', color: 'danger' },
};

export const PURCHASE_ORDER_STATUS_OPTIONS = [
  {
    value: PURCHASE_ORDER_STATUS.CREATED.value,
    label: PURCHASE_ORDER_STATUS.CREATED.label,
  },
  {
    value: PURCHASE_ORDER_STATUS.APPROVED.value,
    label: PURCHASE_ORDER_STATUS.APPROVED.label,
  },
  {
    value: PURCHASE_ORDER_STATUS.REJECTED.value,
    label: PURCHASE_ORDER_STATUS.REJECTED.label,
  },
];

export const IN_SYSTEM_NOTIFICATION_SEVERITY = {
  PRIMARY: { value: 'PRIMARY', label: 'Primary' },
  SECONDARY: { value: 'SECONDARY', label: 'Secondary' },
  SUCCESS: { value: 'SUCCESS', label: 'Success' },
  DANGER: { value: 'DANGER', label: 'Danger' },
  WARNING: { value: 'WARNING', label: 'Warning' },
  INFO: { value: 'INFO', label: 'Info' },
  LIGHT: { value: 'LIGHT', label: 'Light' },
  DARK: { value: 'DARK', label: 'Dark' },
};

export const MAX_SIZE_ENABLED_TO_UPLOAD = 2097152; // 2mb

export const EXPENSE_STATUS = {
  APPROVED: { value: 'APPROVED', label: 'Aprobado' },
  REJECTED: { value: 'REJECTED', label: 'Rechazado' },
  PENDING: { value: 'PENDING', label: 'Pendiente' },
  SOLVED: { value: 'SOLVED', label: 'Solucionado' },
  PRE_APPROVED: { value: 'PRE_APPROVED', label: 'Pre-Aprobado' },
};

export const ITINERARY_GROUP_STATUS = {
  CREATED: { value: 'CREATED', label: 'Creado' },
  CONFIRMED: { value: 'CONFIRMED', label: 'Confirmado' },
};

export const COLOR = {
  success: '#dff0d8',
  info: '#d9edf7',
  warning: '#fcf8e3',
  danger: '#f2dede',
  selectedRow: '#fff3cd',
  lightGray: '#d9d9d9',
  darkGray: '#696969',
  black: '#000000',
  white: '#ffffff',
  orange: '#fad6aa',
  skyBlue: '#4682B4',
  green: '#00A36C',
  red: '#FF0000',
};

export const CONTRACT_MOVEMENT_TYPE = {
  REGULAR: { value: 'REGULAR', label: 'Contrato Regular' },
  NON_REGULAR: { value: 'NON_REGULAR', label: 'Contrato No Regular' },
  LOCAL: { value: 'LOCAL', label: 'Contrato Local' },
};

export const CONTRACT_STATUS = {
  ACEPTED: { value: 'ACEPTED', label: 'Aceptado', color: 'warning' },
  IN_PROGRESS: { value: 'IN_PROGRESS', label: 'En progreso', color: 'info' },
  DONE: { value: 'DONE', label: 'Terminado', color: 'primary' },
  PAID: { value: 'PAID', label: 'Pagado', color: 'success' },
};

export const CONTRACT_DOCUMENT_TYPE = {
  FACTURA: { value: 'FACTURA', label: 'Boletos Facturados' },
  BOLETA_INTERNA: {
    value: 'BOLETA_INTERNA',
    label: 'Boletos con Valor Contable',
  },
  FACTURA_INTERNA: {
    value: 'FACTURA_INTERNA',
    label: 'Boletos Facturados sin Valor Contable',
  },
};

export const CREDIT_NOTE_STATUS_OPTIONS = [
  {
    value: true,
    label: 'Activo',
  },
  {
    value: false,
    label: 'Inactivo',
  },
];

export const MANUAL_VOUCHER_PAYMENT_TYPE = {
  CASH: { value: 'CASH', label: 'Contado' },
  CREDIT: { value: 'CREDIT', label: 'Crédito' },
};

export const CURRENCY_CODE = {
  PEN: { value: 'PEN', label: 'Soles', symbol: PEN_SYMBOL },
  USD: { value: 'USD', label: 'Dólares', symbol: USD_SYMBOL },
};

export const MANUAL_VOUCHER_SERVICE_TYPE = {
  TICKET_SERVICE: { value: 'TICKET_SERVICE', label: 'Pasajes' },
  PARCEL: { value: 'PARCEL', label: 'Cargo' },
  CONTRACT: { value: 'CONTRACT', label: 'Contratos' },
  MINE: { value: 'MINE', label: 'Mina' },
  CORPORATE: { value: 'CORPORATE', label: 'Corporativo' },
  AE_AGENCY: { value: 'AE_AGENCY', label: 'Agencia de viajes' },
};

export const SUNAT_INVOICE_CODE = '01';
export const SUNAT_TICKET_CODE = '03';
export const SUNAT_CREDIT_NOTE_CODE = '07';
export const SUNAT_DEBIT_NOTE_CODE = '08';

export const SUNAT_VOUCHER_TYPES = {
  '01': { value: '01', label: 'Factura' },
  '03': { value: '03', label: 'Boleta de Venta' },
  '07': { value: '07', label: 'Nota de Crédito' },
  '08': { value: '08', label: 'Nota de Débito' },
};

export const ORGANIZATION_CHART_AREA_TYPES = {
  management: {
    id: 1,
    name: 'management',
    get: GET_MANAGEMENT_CONTENT,
    clear: CLEAR_MANAGEMENT_CONTENT,
  },
  department: {
    id: 2,
    name: 'department',
    parent: 'management',
    get: GET_DEPARTMENT_CONTENT,
    clear: CLEAR_DEPARTMENT_CONTENT,
  },
  area: {
    id: 3,
    name: 'area',
    parent: 'department',
    get: GET_AREA_CONTENT,
    clear: CLEAR_AREA_CONTENT,
  },
  section: {
    id: 4,
    name: 'section',
    parent: 'area',
    get: GET_SECTION_CONTENT,
    clear: CLEAR_SECTION_CONTENT,
  },
  speciality: {
    id: 5,
    name: 'speciality',
    parent: 'section',
    get: GET_SPECIALITY_CONTENT,
    clear: CLEAR_SPECIALITY_CONTENT,
  },
};

export const MAINTENANCE_FACTOR_TYPE = {
  KILOMETER: { value: 'KILOMETER', label: 'Kilómetros' },
  DAY: { value: 'DAY', label: 'Días' },
};

export const ACTIVITY_STATUS = {
  PENDING: { value: 'PENDING', label: 'Pendiente', color: 'info' },
  EXTENDED: { value: 'EXTENDED', label: 'Extendido', color: 'warning' },
  DONE: { value: 'DONE', label: 'Realizado', color: 'success' },
  DEFAULT: { value: 'DEFAULT', label: 'Por defecto', color: 'secondary' },
  OPEN: { value: 'OPEN', label: 'Abierto', color: 'error' },
  IN_PROGRESS: { value: 'IN_PROGRESS', label: 'En progreso', color: 'info' },
};

export const CIP_DOCUMENT_TYPE = {
  DNI: { value: 'DNI', label: 'DNI' },
  PAR: { value: 'PAR', label: 'Partida' },
  PAS: { value: 'PAS', label: 'Pasaporte' },
  LMI: { value: 'LMI', label: 'Libreta Militar' },
  NAN: { value: 'NAN', label: 'Otros' },
};

export const REGISTER_OF_EXECUTION_TYPE = {
  false: { value: false, label: 'Preventivo', color: 'primary' },
  true: { value: true, label: 'Adelantado', color: 'danger' },
};

export const MATERIAL_MEASUREMENT_UNIT = [
  'MAN',
  'GAL',
  'UND',
  'MTS',
  'KGR',
  'PZA',
  'LTS',
  'JGO',
  'CTO',
  'CAJA',
  'CIENTO',
  'MIL',
  'PQTE',
  'TAL',
  'BIDON',
  'PAR',
  'BAL',
  'MTS3',
];

export const RELATIONSHIP = {
  MOTHER: { value: 'MOTHER', label: 'Mamá' },
  FATHER: { value: 'FATHER', label: 'Papá' },
  UNCLE: { value: 'UNCLE', label: 'Tío(a)' },
  GRANDFATHER: { value: 'GRANDFATHER', label: 'Abuelo(a)' },
  SIBLING: { value: 'SIBLING', label: 'Hermano(a)' },
  COUSIN: { value: 'COUSIN', label: 'Primo(a)' },
  OTHER: { value: 'OTHER', label: 'Otro' },
};

export const AGE_TYPES = {
  MONTHS: { value: 'MONTHS', label: 'Meses' },
  YEARS: { value: 'YEARS', label: 'Años' },
};

export const BILLING_PROCESS_TYPE = {
  GUIA_REMISION_TRANSPORTISTA: {
    value: 'GUIA_REMISION_TRANSPORTISTA',
    label: 'Guía de Remisión Transportista',
  },
};

export const BILLING_PROCESS_STATUS = {
  READY_FOR_PROCESS: {
    value: 'READY_FOR_PROCESS',
    label: 'Listo para procesar',
  },
  PROCESSED: { value: 'PROCESSED', label: 'Procesado' },
  ERROR: { value: 'ERROR', label: 'Error' },
};

export const BILLING_DOCUMENT_TYPE = {
  RUC: { value: 'RUC', label: 'RUC' },
  CARNET_EXTRANJERIA: {
    value: 'CARNET_EXTRANJERIA',
    label: 'CARNET_EXTRANJERIA',
  },
  PASAPORTE: { value: 'PASAPORTE', label: 'PASAPORTE' },
  DOC_TRIB_NO_DOM_SIN_RUC: {
    value: 'DOC_TRIB_NO_DOM_SIN_RUC',
    label: 'DOC_TRIB_NO_DOM_SIN_RUC',
  },
  DNI: { value: 'DNI', label: 'DNI' },
};

export const BILLING_VOUCHER_TYPE = {
  FACTURA: { value: 'FACTURA', label: 'FACTURA' },
  BOLETA_DE_VENTA: {
    value: 'BOLETA_DE_VENTA',
    label: 'BOLETA_DE_VENTA',
  },
  LIQUIDACION_DE_COMPRA: {
    value: 'LIQUIDACION_DE_COMPRA',
    label: 'LIQUIDACION_DE_COMPRA',
  },
  BOLETOS_TRANSPORTE_AEREO: {
    value: 'BOLETOS_TRANSPORTE_AEREO',
    label: 'BOLETOS_TRANSPORTE_AEREO',
  },
  CARTA_PORTE_AEREO: { value: 'CARTA_PORTE_AEREO', label: 'CARTA_PORTE_AEREO' },
};

export const MAINTENANCE_ACTIVITY_TYPE = {
  PREVENTIVE: { value: 'PREVENTIVE', label: 'Preventivo' },
  CORRECTIVE: { value: 'CORRECTIVE', label: 'Correctivo' },
  CAMPAIGN: { value: 'CAMPAIGN', label: 'Campaña' },
};

export const MAINTENANCE_WARNING_TYPE = {
  FAIL: { value: 'FAIL', label: 'Falla' },
  WORK_REQUEST: { value: 'WORK_REQUEST', label: 'Solicitud de trabajo' },
  ROUTE_EVENT: { value: 'ROUTE_EVENT', label: 'Evento en ruta' },
  DRIVER_BUS_BODYWORK_INCIDENCE: {
    value: 'DRIVER_BUS_BODYWORK_INCIDENCE',
    label: 'Incidencia Carroceria Conductor',
  },
  TIRES_INCIDENCE: { value: 'TIRES_INCIDENCE', label: 'Incidencia Neumaticos' },
};

export const MAINTENANCE_WARNING_PRIORITY = {
  LOW: {
    value: 'LOW',
    label: 'Baja',
    color: 'secondary',
    hexColor: COLOR.darkGray,
    icon: 'fa fa-level-down',
  },
  MEDIUM: {
    value: 'MEDIUM',
    label: 'Media',
    color: 'warning',
    hexColor: COLOR.warning,
    icon: 'fa fa-arrows-h',
  },
  HIGH: {
    value: 'HIGH',
    label: 'Alta',
    color: 'danger',
    hexColor: COLOR.danger,
    icon: 'fa fa-level-up',
  },
};

export const MAINTENANCE_WARNING_STATUS = {
  OPEN: { value: 'OPEN', label: 'Abierto', color: 'info' },
  IN_PROGRESS: { value: 'IN_PROGRESS', label: 'En progreso', color: 'warning' },
  REJECTED: { value: 'REJECTED', label: 'Rechazado', color: 'danger' },
  DONE: { value: 'DONE', label: 'Realizado', color: 'success' },
};

export const MAINTENANCE_WARNING_RECURRENCE = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

export const MAINTENANCE_SERVICE_ORDER_PRIORITY = {
  LOW: {
    value: 'LOW',
    label: 'Baja',
    color: 'secondary',
    hexColor: COLOR.darkGray,
    icon: 'fa fa-level-down',
  },
  MEDIUM: {
    value: 'MEDIUM',
    label: 'Media',
    color: 'warning',
    hexColor: COLOR.orange,
    icon: 'fa fa-arrows-h',
  },
  HIGH: {
    value: 'HIGH',
    label: 'Alta',
    color: 'danger',
    hexColor: COLOR.red,
    icon: 'fa fa-level-up',
  },
};

export const MAINTENANCE_SERVICE_ORDER_STATUS = {
  OPEN: {
    value: 'OPEN',
    label: 'Abierto',
    color: 'warning',
    hexColor: COLOR.orange,
  },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'En progreso',
    color: 'info',
    hexColor: COLOR.skyBlue,
  },
  DONE: {
    value: 'DONE',
    label: 'Realizado',
    color: 'success',
    hexColor: COLOR.green,
  },
};

export const ACTIVITY_TASK_EXECUTION_STATUS = {
  OPEN: { value: 'OPEN', label: 'Abierto' },
  ASSIGNED: { value: 'ASSIGNED', label: 'Asignado' },
  IN_PROGRESS: { value: 'IN_PROGRESS', label: 'En proceso' },
  DONE: { value: 'DONE', label: 'Realizado' },
};

export const ACTIVITY_ACTION = {
  REVIEW: { value: 'REVIEW', label: 'Revisar' },
  REPAIR: { value: 'REPAIR', label: 'Reparar' },
  CHANGE: { value: 'CHANGE', label: 'Cambiar' },
};

export const ACTIVITY_EXECUTION_METHOD = {
  CONDITION_BASED: { value: 'CONDITION_BASED', label: 'Basado en condición' },
  CYCLICAL_REPLACEMENT: {
    value: 'CYCLICAL_REPLACEMENT',
    label: 'Reemplazo cíclico',
  },
};

export const FLIT_ROBOT_CUSTOMER_ID = 9999999;

export const PARCEL_PAYMENT_STATUS = {
  WAITING_FOR_PAYMENT: {
    value: 'WAITING_FOR_PAYMENT',
    label: 'Esperando pago',
    color: 'danger',
  },
  PAID: { value: 'PAID', label: 'Pagado', color: 'success' },
};

export const PAYMENT_METHOD_PAGO_EN_DESTINO = 'PAYMENT_ON_ARRIVAL';
export const MANUAL_GRR_MOTIVE_OPTIONS = [
  {
    value: '01',
    label: 'Venta',
  },
  {
    value: '04',
    label: 'Traslado entre establecimientos de la misma empresa',
  },
  {
    value: '17',
    label: 'Traslado de bienes para transformación',
  },
  {
    value: '13',
    label: 'Otros',
  },
];

export const MANUAL_GRR_VOUCHER_TYPES_OPTIONS = [
  {
    value: 'FACTURA',
    label: 'Factura',
  },
  {
    value: 'BOLETA_DE_VENTA',
    label: 'Boleta de venta',
  },
  {
    value: 'LIQUIDACION_DE_COMPRA',
    label: 'Liquidación de compra',
  },
  {
    value: 'BOLETOS_TRANSPORTE_AEREO',
    label: 'Boleto de Transporte Aereo',
  },
  {
    value: 'CARTA_PORTE_AEREO',
    label: 'Carta de porte aéreo',
  },
];
export const GRR_MOTIVE_SALE = '01';
export const GRR_MOTIVE_INTERNAL_TRANSFER = '04';
export const GRR_MOTIVE_BUSINESS_TRANSFER = '17';
export const GRR_MOTIVE_OTHERS = '13';

export const CARGO_ITEM_CHANGE_STATUS_BUTTON = {
  UNCLAIMED: { value: 'UNCLAIMED', label: 'Sin reclamar' },
  DEPURATED: { value: 'DEPURATED', label: 'Depurado' },
  SEIZED: { value: 'SEIZED', label: 'Incautado' },
  LOST: { value: 'LOST', label: 'Perdido' },
};

export const CARGO_ITEM_CHANGE_STATUS_DROPDOWN_OPTIONS = [
  { value: 'header', label: 'Estados' },
  {
    value: CARGO_ITEM_CHANGE_STATUS_BUTTON.UNCLAIMED.value,
    label: 'Sin reclamar',
  },
  { value: CARGO_ITEM_CHANGE_STATUS_BUTTON.DEPURATED.value, label: 'Depurado' },
  { value: CARGO_ITEM_CHANGE_STATUS_BUTTON.SEIZED.value, label: 'Incautado' },
  { value: CARGO_ITEM_CHANGE_STATUS_BUTTON.LOST.value, label: 'Perdido' },
];

export const CARGO_ITEM_STATUS_OPTIONS = [
  { value: 'RECEIVED', label: 'Recibido' },
  { value: 'IN_TRANSIT', label: 'En tránsito' },
  { value: 'READY_FOR_PICKUP', label: 'Listo para recoger' },
  { value: 'REFUNDED', label: 'Devuelto' },
  { value: 'DELIVERED', label: 'Entregado' },
  { value: 'UNCLAIMED', label: 'No reclamado' },
  { value: 'DEPURATED', label: 'Depurado' },
  { value: 'LOST', label: 'Perdido' },
  { value: 'SEIZED', label: 'Incautado' },
];

export const CARGO_ITEM_PERISHABLE_OPTIONS = [
  {
    value: true,
    label: 'Perecible',
  },
  {
    value: false,
    label: 'No perecible',
  },
];

export const INTERNAL_CARGO_ITEM_CHANGE_STATUS_BUTTON = {
  SEIZED: { value: 'SEIZED', label: 'Incautado' },
  LOST: { value: 'LOST', label: 'Perdido' },
};

export const INTERNAL_CARGO_ITEM_CHANGE_STATUS_DROPDOWN_OPTIONS = [
  { value: 'header', label: 'Estados' },
  { value: CARGO_ITEM_CHANGE_STATUS_BUTTON.SEIZED.value, label: 'Incautado' },
  { value: CARGO_ITEM_CHANGE_STATUS_BUTTON.LOST.value, label: 'Perdido' },
];

export const SEAT_STATUS = {
  FREE: 'FREE',
  OCCUPIED: 'OCCUPIED',
  IN_PROGRESS: 'IN_PROGRESS',
  CALL_CENTER_RESERVATION: 'CALL_CENTER_RESERVATION',
};

export const BILL_PROCESS_STATUS_PROCESSED = 'PROCESSED';

export const PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT';

export const TICKET_BOARDED_STATUS = {
  UNREGISTERED: {
    value: 'UNREGISTERED',
    label: 'Sin Registrar',
    color: 'info',
  },
  UNBOARDED: { value: 'UNBOARDED', label: 'No embarcó', color: 'warning' },
  BOARDED: { value: 'BOARDED', label: 'Embarcado', color: 'success' },
};

export const CARGO_OPERATIONS = {
  ADD_ITEM: {
    value: 'ADD_ITEM',
    label: 'Subir encomienda',
    color: 'success',
  },
  UNLOAD_ITEM: {
    value: 'UNLOAD_ITEM',
    label: 'Bajar encomienda',
    color: 'warning',
  },
  MANIFEST: {
    value: 'MANIFEST',
    label: 'Manifiesto',
    color: 'info',
  },
};
