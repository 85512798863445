import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFuelStations, clearFuelStations } from '../../../../actions/index';
import { optionPropTypes, optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

class FuelStationSelect extends Component {
  componentDidMount() {
    const { dispatchGetFuelStations, initFetch } = this.props;
    if (initFetch) dispatchGetFuelStations(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearFuelStations, initFetch } = this.props;
    if (initFetch) dispatchClearFuelStations();
  }

  render() {
    const {
      loading,
      fuelStationOptions,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={fuelStationOptions}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

FuelStationSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetFuelStations: PropTypes.func.isRequired,
  dispatchClearFuelStations: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  fuelStationOptions: optionsPropTypes,
  isClearable: PropTypes.bool,
  initFetch: PropTypes.bool,
  value: optionPropTypes,
};

FuelStationSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  initFetch: true,
  fuelStationOptions: [],
  isClearable: false,
  onValueChange: undefined,
  value: {},
};

const mapStateToProps = ({ RouteUnit }) => ({
  fuelStationOptions: RouteUnit.FuelStation.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, location: { name } }) => ({
    value: id,
    label: name,
  })),
  loading: RouteUnit.FuelStation.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetFuelStations: getFuelStations,
  dispatchClearFuelStations: clearFuelStations,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelStationSelect);
