const { REACT_APP_ENV = 'local' } = process.env;
const DEVELOPMENT = 'dev';
const STAGING = 'staging';
const PRODUCTION = 'prod';

const isLocalEnvironment = () => {
  switch (REACT_APP_ENV.toLowerCase()) {
    case DEVELOPMENT.toLowerCase():
    case STAGING.toLowerCase():
    case PRODUCTION.toLowerCase():
      return false;
    default:
      return true;
  }
};

export {
  isLocalEnvironment,
  REACT_APP_ENV,
  PRODUCTION,
  STAGING,
  DEVELOPMENT,
};
