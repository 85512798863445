import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LIQUIDATION_SESSION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import LiquidationSessionForm from './LiquidationSessionForm';
import { postLiquidationSession } from '../../../../actions';
import Content from '../../../layout/Content';

export const NewLiquidationSession = ({
  dispatchPostLiquidationSession,
  breadcrumbs,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      dailyLiquidationDate: formValues.dailyLiquidationDate,
      agencyId: formValues.agencyId.value,
      invoiceNumber: formValues.invoiceNumber,
      cashOnHand: formValues.cashOnHand,
      liquidationDifferenceReasonId: formValues.liquidationDifferenceReasonId
        ? formValues.liquidationDifferenceReasonId.value
        : null,
      liquidationDifferenceReasonDescription:
        formValues.liquidationDifferenceReasonDescription || null,
    };

    dispatchPostLiquidationSession(newFormValues);
  };

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Liquidación de Agencia"
      subtitle="Liquidar las sesiones de venta para esta agencia"
      content={<LiquidationSessionForm onSubmit={onSubmit} />}
    />
  );
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Liquidaciones de Agencia',
      href: LIQUIDATION_SESSION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostLiquidationSession: postLiquidationSession,
};

NewLiquidationSession.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostLiquidationSession: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewLiquidationSession);
