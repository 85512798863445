import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, InputGroupText } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import UserSelect from '../../../common/forms/select/UserSelect';
import WorkstationSelect from '../../../common/forms/select/WorkstationSelect';
import { CONTRACT_TYPE } from '../../../../config/constants';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import { CURRENCY } from '../../../../config/locale';
import Select from '../../../common/forms/select/Select';
import FormFooter from '../../../common/forms/FormFooter';
import { enumToSelectOptions } from '../../../../utils/enum';

const hasHigherNumber = (currentValue, allValues) =>
  Number(currentValue) < Number(allValues.actualBalance)
    ? 'No debe ser menor que el saldo actual'
    : undefined;

const AgencyForm = ({ initialValues, handleSubmit }) => {
  const [contractTypeSelected, setContractTypeSelected] = useState(null);

  const onMount = () => {
    if (initialValues && initialValues.contractType)
      setContractTypeSelected(initialValues.contractType.value);
  };

  useLayoutEffect(() => {
    onMount();
  }, []);

  const onChangeContractType = (option) =>
    setContractTypeSelected(option ? option.value : null);

  const commissionContent =
    contractTypeSelected === CONTRACT_TYPE.AE_AGENCY.value ? (
      <>
        <FormGroup row>
          <FormItem label="Comisión">
            <Field
              name="commission"
              component={TextInput}
              type="text"
              placeholder="Comisión"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem>
            <Label>
              <Field name="taxExempt" component="input" type="checkbox" />{' '}
              Exenta de Impuestos
            </Label>
          </FormItem>
        </FormGroup>
      </>
    ) : null;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Ubicación" required>
          <Field
            name="locationId"
            component={LocationSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Usuarios">
          <Field name="userList" component={UserSelect} isMulti />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Cajas">
          <Field name="workstationList" component={WorkstationSelect} isMulti />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Compañia">
          <Field name="companyId" component={CompanySelect} isClearable />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Contrato">
          <Field
            name="contractType"
            component={Select}
            isClearable={false}
            options={enumToSelectOptions(CONTRACT_TYPE)}
            onChange={(option) => onChangeContractType(option)}
          />
        </FormItem>
      </FormGroup>
      {commissionContent}
      <FormGroup row>
        <FormItem label="Saldo Inicial">
          <Field
            name="initialBalance"
            component={TextInput}
            type="text"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
            placeholder="Saldo Inicial"
            validate={[validateNumber, hasHigherNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Saldo Actual">
          <Field
            name="actualBalance"
            component={TextInput}
            type="text"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
            placeholder="Saldo Actual"
            validate={[validateNumber]}
            disabled
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Día de Corte">
          <Field
            name="cutoffDay"
            component={TextInput}
            type="text"
            placeholder="Día de Corte"
            validate={[validateIntegerNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Próximo Día de Corte">
          <Field
            name="nextCutoffDate"
            component={TextInput}
            type="text"
            placeholder="Próximo Día de Corte"
            disabled
            validate={[validateIntegerNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Período de Gracia">
          <Field
            name="slack"
            component={TextInput}
            type="text"
            placeholder="Días"
            validate={[validateIntegerNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado">
          <Label>
            <Field name="active" component="input" type="checkbox" /> Activo
          </Label>
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

AgencyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    contractType: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    commission: PropTypes.number,
  }),
};

AgencyForm.defaultProps = {
  initialValues: null,
};

const formComponent = reduxForm({
  form: 'AgencyForm',
})(AgencyForm);

export default formComponent;
