import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { EXPECTED_FUEL_CONSUMPTION_PATH } from '../../../../config/paths';
import {
  getExpectedFuelConsumption,
  clearExpectedFuelConsumption,
} from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import ExpectedFuelConsumptionToolbar from './ExpectedFuelConsumptionToolbar';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

class ExpectedFuelConsumption extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    dispatchGetExpectedFuelConsumption: PropTypes.func.isRequired,
    dispatchClearExpectedFuelConsumption: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    match: matchPropTypes.isRequired,
    expectedFuelConsumption: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const { dispatchGetExpectedFuelConsumption } = this.props;
    dispatchGetExpectedFuelConsumption({ id });
  }

  componentWillUnmount() {
    const { dispatchClearExpectedFuelConsumption } = this.props;
    dispatchClearExpectedFuelConsumption();
  }

  render() {
    const { breadcrumbs, expectedFuelConsumption, loading } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (expectedFuelConsumption.isEmpty()) {
      content = <NoDataResource returnPage={EXPECTED_FUEL_CONSUMPTION_PATH} />;
    } else {
      content = (
        <Fragment>
          <ResourceProperty label="Ruta:">
            {expectedFuelConsumption.get('route').name}
          </ResourceProperty>
          <ResourceProperty label="Comentario:">
            {expectedFuelConsumption.get('comment')}
          </ResourceProperty>
          <h3>Galones</h3>
          {expectedFuelConsumption
            .get('busFuelGroupConsumptionList')
            .map((busFuelGroupConsumption) => (
              <ResourceProperty
                key={busFuelGroupConsumption.id}
                label={`${busFuelGroupConsumption.busFuelGroup.name} :`}
              >
                {`${busFuelGroupConsumption.numberOfGallon} galones y ${busFuelGroupConsumption.numberOfGallonAdblue} litros de ad blue`}
              </ResourceProperty>
            ))}
          <InternalResource
            id={expectedFuelConsumption.get('id')}
            createDate={expectedFuelConsumption.get('createDate')}
            lastUpdate={expectedFuelConsumption.get('lastUpdate')}
          />
        </Fragment>
      );

      toolbar = (
        <ExpectedFuelConsumptionToolbar
          expectedFuelConsumptionId={expectedFuelConsumption.get('id')}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Dotación de Combustible"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Dotaciones de Combustible',
      href: EXPECTED_FUEL_CONSUMPTION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  expectedFuelConsumption: TrafficUnit.ExpectedFuelConsumption.getIn([
    'current',
    'content',
  ]),
  loading: !TrafficUnit.ExpectedFuelConsumption.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExpectedFuelConsumption: getExpectedFuelConsumption,
  dispatchClearExpectedFuelConsumption: clearExpectedFuelConsumption,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpectedFuelConsumption);
