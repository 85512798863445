import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { Form, FormGroup, Button } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import '../../../modules/booking/passengers/Passengers.css';
import {
  postPostpaidBookingReservation,
  clearPostpaidBookingState,
} from '../../../../actions';
import validate from '../../../modules/booking/passengers/validate';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { DEFAULT_FOOD_OPTION } from '../../../../config/defaults';
import { POSTPAID_BOOKING_SEARCH_PATH } from '../../../../config/paths';
import { POSTPAID_BOOKING_PASSENGERS_STEP_INDEX } from '../../../../config/constants';
import Loader from '../../../common/Loader';
import PostpaidBookingPassengerForm from './PostpaidBookingPassengerForm';
import { orderBy } from '../../../../utils/array';

class PostpaidBookingPassengers extends Component {
  static renderPassengers({ fields }) {
    return fields.map((passenger, index) => (
      <PostpaidBookingPassengerForm
        passenger={passenger}
        key={passenger}
        index={index}
        fields={fields}
      />
    ));
  }

  constructor(props) {
    super(props);
    const {
      initialValues: { passengers },
    } = props;
    // send back to search step if passengers are not valid
    if (!passengers || !passengers.length) {
      props.dispatchReplace(POSTPAID_BOOKING_SEARCH_PATH);
    }
    const stepIndex = POSTPAID_BOOKING_PASSENGERS_STEP_INDEX;
    this.props.dispatchClearPostpaidBookingState(stepIndex);
  }

  componentDidUpdate() {
    const {
      initialValues: { passengers },
      dispatchPush,
    } = this.props;

    if (passengers.length === 0) {
      dispatchPush(POSTPAID_BOOKING_SEARCH_PATH);
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      postingReservation,
      loadingKidsOnPostpaidItinerary,
      dispatchPostPostpaidBookingReservation,
    } = this.props;
    if (postingReservation) {
      return <Loader />;
    }
    return (
      <div className="container Passengers">
        <Form onSubmit={handleSubmit(dispatchPostPostpaidBookingReservation)}>
          <FieldArray
            name="passengers"
            component={PostpaidBookingPassengers.renderPassengers}
          />
          <hr />
          <FormGroup row>
            <FormItem label="Código de descuento">
              <Field
                name="discountCode"
                component={TextInput}
                type="text"
                placeholder="Código de descuento"
              />
            </FormItem>
          </FormGroup>
          <div className="FormButtonGroup">
            <Button
              type="submit"
              className="clickable"
              disabled={submitting || loadingKidsOnPostpaidItinerary}
              color="primary"
              size="lg"
            >
              Reservar <i className="fa fa-arrow-circle-right" />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

PostpaidBookingPassengers.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        seatReservation: PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        idCountryOfOrigin: PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
        identificationTypeId: PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.string,
        }),
        food: PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  dispatchReplace: PropTypes.func.isRequired,
  dispatchPostPostpaidBookingReservation: PropTypes.func.isRequired,
  dispatchClearPostpaidBookingState: PropTypes.func.isRequired,
  postingReservation: PropTypes.bool.isRequired,
  dispatchPush: PropTypes.func.isRequired,
  loadingKidsOnPostpaidItinerary: PropTypes.bool,
};

PostpaidBookingPassengers.defaultProps = {
  loadingKidsOnPostpaidItinerary: false,
};

const mapStateToProps = ({ ItineraryUnit, PostpaidBookingUnit }) => {
  const seatReservations = PostpaidBookingUnit.PostpaidBooking.getIn([
    'seats',
    'seatReservations',
  ]).toJS();

  const seatReservationsOrdered = orderBy(
    seatReservations,
    ['itinerary.departureTime', 'itineraryId', 'seat.seatNumber'],
    ['asc', 'asc'],
  );

  const passengers = seatReservationsOrdered.map((seatReservation) => ({
    // each passenger gets a different seat reservation
    seatReservation: {
      value: seatReservation.id,
      label: seatReservation.seat.seatNumber,
      itinerary: seatReservation.itinerary || null,
      itineraryId: seatReservation.itineraryId || null,
      seatNumber: seatReservation.seat.seatNumber,
      floorNumber: seatReservation.seat.floorNumber,
    },
    food: DEFAULT_FOOD_OPTION,
  }));

  const loadingKidsOnPostpaidItinerary = !ItineraryUnit.KidsOnItinerary.getIn([
    'current',
    'activity',
  ]).isEmpty();

  return {
    postingReservation: PostpaidBookingUnit.PostpaidBooking.getIn([
      'passengers',
      'postingReservation',
    ]),
    initialValues: {
      passengers,
    },
    loadingKidsOnPostpaidItinerary,
  };
};

const mapDispatchToProps = {
  dispatchReplace: replace,
  dispatchPostPostpaidBookingReservation: postPostpaidBookingReservation,
  dispatchClearPostpaidBookingState: clearPostpaidBookingState,
  dispatchPush: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'PostpaidBookingPassengersForm',
    validate,
  })(PostpaidBookingPassengers),
);
