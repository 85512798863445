import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getCompanyTickets,
  clearCompanyTickets,
} from '../../../../actions/index';
import { COMPANY_TICKET_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { TICKETS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class CompanyTickets extends Component {
  componentWillUnmount() {
    this.props.dispatchClearTickets();
  }

  render() {
    const { tickets, loading, breadcrumbs, dispatchGetTickets } = this.props;

    const data = tickets.get('content') || [];
    const pages = tickets.get('totalPages') || null;
    const defaultPageSize = tickets.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Boletos"
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: TICKETS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetTickets,
          modelPath: COMPANY_TICKET_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos',
      href: COMPANY_TICKET_PATH,
    },
  ],
  tickets: ContractUnit.CompanyTicket.getIn(['all', 'content']),
  loading: ContractUnit.CompanyTicket.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetTickets: getCompanyTickets,
  dispatchClearTickets: clearCompanyTickets,
};

CompanyTickets.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  tickets: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetTickets: PropTypes.func.isRequired,
  dispatchClearTickets: PropTypes.func.isRequired,
};

CompanyTickets.defaultProps = {
  tickets: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTickets);
