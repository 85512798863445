import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CUSTOMER_PATH } from '../../../../config/paths';

const CustomerEditButton = ({ customerId }) => (
  <Link className="btn btn-primary" to={`${CUSTOMER_PATH}/${customerId}/edit`}>
    <i className="fa fa-edit" /> Editar
  </Link>
);

CustomerEditButton.propTypes = {
  customerId: PropTypes.number.isRequired,
};

export default CustomerEditButton;
