import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { NEW_ACTIVITY_PATH, ACTIVITY_PATH } from '../../../../config/paths';
import {
  getActivities,
  clearActivities,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { ACTIVITY_COLUMNS } from '../../../../config/columns';

export const Activities = ({
  breadcrumbs,
  activities,
  loading,
  dispatchGetActivities,
  dispatchClearActivities,
}) => {
  useLayoutEffect(() => () => dispatchClearActivities(), []);

  const data = activities.get('content') || [];
  const pages = activities.get('totalPages') || null;
  const defaultPageSize = activities.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Actividades"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_ACTIVITY_PATH}
      tableStructure={{
        columns: ACTIVITY_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetActivities,
        modelPath: ACTIVITY_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetActivities: getActivities,
  dispatchClearActivities: clearActivities,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Actividades',
      href: ACTIVITY_PATH,
    },
  ],
  activities: MechanicalMaintenanceUnit.Activity.getIn(['all', 'content']),
  loading: MechanicalMaintenanceUnit.Activity.getIn(['all', 'loading']),
});

Activities.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  activities: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetActivities: PropTypes.func.isRequired,
  dispatchClearActivities: PropTypes.func.isRequired,
};

Activities.defaultProps = {
  activities: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
