import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Container, Row, Col } from 'reactstrap';
import { Switch, Route } from 'react-router-dom';
import { getActiveSalesSession } from '../../../actions';
import Loader from '../../common/Loader';
import Alert from '../../common/informative/Alert';
import PostpaidBookingNavbar from './PostpaidBookingNavbar';
import {
  POSTPAID_BOOKING_NEW_PATH,
  POSTPAID_BOOKING_PASSENGERS_PATH,
  POSTPAID_BOOKING_RECEIVER_PATH,
  POSTPAID_BOOKING_SEARCH_PATH,
  POSTPAID_BOOKING_SUMMARY_PATH,
} from '../../../config/paths';
import Breadcrumbs from '../../layout/Breadcrumbs';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

import withoutReservation from './withoutReservation';
import PostpaidBookingPassengers from './passengers/PostpaidBookingPassengers';
import Receiver from './receiver/Receiver';
import PostpaidSummary from './summary/PostpaidSummary';
import PostpaidBookingRoundTrip from './PostpaidBookingRoundTrip';

const withoutReservationReceiver = withoutReservation(Receiver);
const withoutReservationPostpaidBookingPassengers = withoutReservation(
  PostpaidBookingPassengers,
);

class NewPostpaidBooking extends Component {
  constructor(props) {
    super(props);
    this.props.getActiveSalesSession({ source: POSTPAID_BOOKING_NEW_PATH });
  }

  render() {
    const { gettingActiveSalesSession, activeSalesSession, breadcrumbs } =
      this.props;
    if (gettingActiveSalesSession) {
      return <Loader />;
    }

    if (activeSalesSession.isEmpty()) {
      return (
        <Container>
          <Alert message="No hay ninguna sesión de ventas activa." />
        </Container>
      );
    }

    return (
      <div>
        <Container fluid>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Row>
            <Col>
              <h1>Reserva Pospago</h1>
            </Col>
          </Row>
          <PostpaidBookingNavbar currentStep={this.props.currentStep} />
          <Switch>
            <Route
              exact
              path={POSTPAID_BOOKING_NEW_PATH}
              component={PostpaidBookingRoundTrip}
            />
            <Route
              exact
              path={POSTPAID_BOOKING_SEARCH_PATH}
              component={PostpaidBookingRoundTrip}
            />
            <Route
              path={POSTPAID_BOOKING_PASSENGERS_PATH}
              component={withoutReservationPostpaidBookingPassengers}
            />
            <Route
              path={POSTPAID_BOOKING_RECEIVER_PATH}
              component={withoutReservationReceiver}
            />
            <Route
              path={POSTPAID_BOOKING_SUMMARY_PATH}
              component={PostpaidSummary}
            />
          </Switch>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentStep: state.PostpaidBookingUnit.PostpaidBooking.get('currentStep'),
  gettingActiveSalesSession: state.SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: state.SalesUnit.SalesSession.getIn(['active', 'content']),
  breadcrumbs: [
    ...state.PostpaidBookingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Nueva Reserva',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  getActiveSalesSession,
};

NewPostpaidBooking.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  currentStep: PropTypes.number.isRequired,
  getActiveSalesSession: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPostpaidBooking);
