import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_DRIVER_BUS_ASSIGNMENTS,
  GET_DRIVER_BUS_ASSIGNMENTS,
  CLEAR_DRIVER_BUS_ASSIGNMENTS,
  FLAG_DRIVER_BUS_ASSIGNMENT_ACTIVITY,
  DRIVER_BUS_ASSIGNMENT_INITIAL_VALUES,
  GET_DRIVER_BUS_ASSIGNMENT,
  CLEAR_DRIVER_BUS_ASSIGNMENT,
} from '../types/traffic';
import {
  DRIVER_BUS_ASSIGNMENT_ENDPOINT,
  DRIVER_BUS_UNASSIGN_ENDPOINT,
  DRIVER_BUS_ASSIGN_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_POST_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingDriverAssignments = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_DRIVER_BUS_ASSIGNMENTS,
    payload: flag,
  });

const getDriverBusAssignments = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingDriverAssignments(true));
    const query = tableFilters;
    const url = `${DRIVER_BUS_ASSIGNMENT_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const driverAssignments = await response.json();
    dispatch({
      type: GET_DRIVER_BUS_ASSIGNMENTS,
      payload: driverAssignments,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingDriverAssignments(false));
  }
};

const flagDriverBusAssignmentActivity = flag => dispatch =>
  dispatch({
    type: FLAG_DRIVER_BUS_ASSIGNMENT_ACTIVITY,
    payload: flag,
  });

const getDriverBusAssignment = async ({ id }) => async dispatch => {
  try {
    dispatch(flagDriverBusAssignmentActivity(true));
    const url = `${DRIVER_BUS_ASSIGNMENT_ENDPOINT}/${id}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const assignments = await response.json();
    dispatch({
      type: GET_DRIVER_BUS_ASSIGNMENT,
      payload: assignments,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDriverBusAssignmentActivity(false));
  }
};

const clearDriverBusAssignments = () => dispatch =>
  dispatch({
    type: CLEAR_DRIVER_BUS_ASSIGNMENTS,
  });

const clearDriverBusAssignment = () => dispatch =>
  dispatch({
    type: CLEAR_DRIVER_BUS_ASSIGNMENT,
  });

const setInitialValues = data => dispatch =>
  dispatch({
    type: DRIVER_BUS_ASSIGNMENT_INITIAL_VALUES,
    payload: data,
  });

const putDriverBusUnassign = async ({
  driverBusAssignmentId,
  companyBusId,
}) => async dispatch => {
  try {
    dispatch(flagDriverBusAssignmentActivity(true));
    const payload = { driverBusAssignmentId };
    const url = DRIVER_BUS_UNASSIGN_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    await response.json();
    dispatch(
      getDriverBusAssignments({ query: `companyBusId:${companyBusId}` }),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDriverBusAssignmentActivity(false));
  }
};

const postDriverBusAssign = async ({
  busId,
  driverId,
  driverTypeId,
  companyBusId,
}) => async dispatch => {
  try {
    dispatch(flagDriverBusAssignmentActivity(true));
    const payload = { busId, driverId, driverTypeId };
    const url = DRIVER_BUS_ASSIGN_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    await response.json();
    dispatch(
      getDriverBusAssignments({ query: `companyBusId:${companyBusId}` }),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDriverBusAssignmentActivity(false));
  }
};

export {
  flagGettingDriverAssignments,
  getDriverBusAssignments,
  clearDriverBusAssignments,
  getDriverBusAssignment,
  clearDriverBusAssignment,
  flagDriverBusAssignmentActivity,
  setInitialValues,
  putDriverBusUnassign,
  postDriverBusAssign,
};
