import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import paymentMethodForSecurityProfilePropTypes from '../proptypes/PaymentMethodForSecurityProfilePropTypes';

const PaymentMethodForSecurityProfileInformationResource = ({
  id,
  paymentMethod,
  securityProfile,
}) => (
  <>
    <ResourceProperty label="Código:">{id || '-'}</ResourceProperty>
    <ResourceProperty label="Metodo de Pago:">
      {paymentMethod.name || '-'}
    </ResourceProperty>
    <ResourceProperty label="Perfil de Seguridad:">
      {securityProfile.name || '-'}
    </ResourceProperty>
  </>
);

PaymentMethodForSecurityProfileInformationResource.propTypes =
  paymentMethodForSecurityProfilePropTypes;

export default PaymentMethodForSecurityProfileInformationResource;
