import Immutable from 'immutable';
import {
  FLAG_GETTING_ODOMETER_BY_BUS,
  GET_ODOMETER_BY_BUS,
  CLEAR_ODOMETER_BY_BUS,
} from '../../../actions/types/mechanical-maintenance';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.List([]),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_ODOMETER_BY_BUS:
      return state.setIn(['all', 'loading'], payload);
    case GET_ODOMETER_BY_BUS:
      return state.setIn(['all', 'content'], Immutable.List(payload));
    case CLEAR_ODOMETER_BY_BUS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    default:
      return state;
  }
};
