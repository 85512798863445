import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import AgencyDeleteButton from './AgencyDeleteButton';
import EditButton from '../../../common/button/EditButton';
import { AGENCY_PATH } from '../../../../config/paths';
import AgencyAddCreditLineButton from './AgencyAddCreditLineButton';

const AgencyToolbar = ({ agencyId, initialBalance }) => (
  <ButtonToolbar className="pull-right">
    <div>
      {initialBalance > 0 ? (
        <AgencyAddCreditLineButton agencyId={agencyId} />
      ) : null}{' '}
      <EditButton path={`${AGENCY_PATH}/${agencyId}/edit`} />{' '}
      <AgencyDeleteButton agencyId={agencyId} />
    </div>
  </ButtonToolbar>
);

AgencyToolbar.propTypes = {
  agencyId: PropTypes.number.isRequired,
  initialBalance: PropTypes.number,
};

AgencyToolbar.defaultProps = {
  initialBalance: 0,
};

export default AgencyToolbar;
