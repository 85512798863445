import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ORGANIZATION_CHART_PATH } from '../../../../config/paths';
import {
  clearOrganizationChart,
  getOrganizationChart,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import OrganizationChartBasicInformationResource from './resource/OrganizationChartBasicInformationResource';
import OrganizationChartToolbar from './OrganizationChartToolbar';

export const OrganizationChart = ({
  breadcrumbs,
  dispatchGetOrganizationChart,
  dispatchClearOrganizationChart,
  match: {
    params: { id: organizationChartId },
  },
  loading,
  organizationChart,
}) => {
  /*
   * The following hook overrides the componentDidUnmount and componentWillUnmount function.
   * View documentation in https://bit.ly/3OI2V0u
   */
  useLayoutEffect(() => {
    // This line overrides the componenDidUpdate function.
    dispatchGetOrganizationChart({ organizationChartId });

    // This line overrides the componentWillUnmount function.
    return () => dispatchClearOrganizationChart();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (organizationChart.isEmpty())
    content = <NoDataResource returnPage={ORGANIZATION_CHART_PATH} />;
  else {
    const organizationChartJson = organizationChart.toJS();

    content = (
      <>
        <h3>Información Básica</h3>
        <OrganizationChartBasicInformationResource {...organizationChartJson} />
      </>
    );

    toolbar = (
      <OrganizationChartToolbar
        organizationChartId={organizationChartJson.id}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Organigrama"
      content={content}
    />
  );
};

OrganizationChart.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearOrganizationChart: PropTypes.func.isRequired,
  dispatchGetOrganizationChart: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  organizationChart: PropTypes.instanceOf(Immutable.Map).isRequired,
};

OrganizationChart.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearOrganizationChart: clearOrganizationChart,
  dispatchGetOrganizationChart: getOrganizationChart,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Organigrama',
      href: ORGANIZATION_CHART_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  organizationChart: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationChart);
