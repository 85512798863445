import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { deleteExpectedFuelConsumption } from '../../../../actions';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class DeleteExpectedFuelConsumptionButton extends Component {
  state = {
    showWarningModal: false,
  };

  showDeleteModal = () => this.setState({ showWarningModal: true });

  closeDeleteModal = () => this.setState({ showWarningModal: false });

  handleDelete = () => {
    const { expectedFuelConsumptionId, dispatchDeleteExpectedFuelConsumption } =
      this.props;
    dispatchDeleteExpectedFuelConsumption({ expectedFuelConsumptionId });
  };

  render() {
    const { showWarningModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        onClickConfirm={this.handleDelete}
        onClickCancel={this.closeDeleteModal}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.showDeleteModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DeleteExpectedFuelConsumptionButton.propTypes = {
  expectedFuelConsumptionId: PropTypes.number.isRequired,
  dispatchDeleteExpectedFuelConsumption: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteExpectedFuelConsumption: deleteExpectedFuelConsumption,
};

export default connect(
  null,
  mapDispatchToProps,
)(DeleteExpectedFuelConsumptionButton);
