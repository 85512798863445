import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';

// eslint-disable-next-line react/prefer-stateless-function
class CargoCategoryForm extends Component {
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <FormGroup row>
          <FormItem label="Categoría" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Categoría"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción" required>
            <Field
              name="description"
              component={TextInput}
              type="text"
              placeholder="Descripción"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

CargoCategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'CargoCategoryForm' })(CargoCategoryForm);
