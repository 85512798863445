import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import {
  getDiscountCodeReport,
  clearDiscountCodeReport,
  clearErrorGettingDiscountCodeReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Table from '../../../common/Table';
import ReportToolbar from '../ReportToolbar';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { DISCOUNT_CODE_REPORTS_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { DISCOUNT_CODE_REPORT_COLUMNS } from '../../../../../src/config/columns';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';

class DiscountCodeReport extends Component {
  static propTypes = {
    getDiscountCodeReport: PropTypes.func.isRequired,
    clearDiscountCodeReport: PropTypes.func.isRequired,
    clearErrorGettingDiscountCodeReport: PropTypes.func.isRequired,
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    report: PropTypes.instanceOf(Immutable.Map),
    loading: PropTypes.bool,
    error: PropTypes.string,
    match: matchPropTypes.isRequired,
  };

  static defaultProps = {
    report: null,
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.columns = DISCOUNT_CODE_REPORT_COLUMNS;
    const { id } = this.props.match.params;
    this.props.getDiscountCodeReport({ id });
  }

  componentWillUnmount() {
    this.props.clearDiscountCodeReport();
    this.props.clearErrorGettingDiscountCodeReport();
  }

  handleOnFetchData = (state) => {
    // get id of report
    const { id } = this.props.match.params;
    this.props.getDiscountCodeReport({ ...state, id });
  };

  download = () => {
    const { report } = this.props;
    const { id } = this.props.match.params;
    if (report) {
      return this.props.getDiscountCodeReport({
        id,
        reportFormat: 'csv',
        download: true,
      });
    }
    return false;
  };

  renderContent() {
    // check if report is loaded
    const { loading, report, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    // check if report is loading or empty
    if (loading || !report || report.isEmpty()) {
      return <Loader />;
    }

    // check if report is complete
    const reportStatus = report.get('reportStatus');
    if (reportStatus === 'GENERATING') {
      return <Loader />;
    }

    const discountCodeReportRows = report.get('discountCodeReportRows');
    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    if (discountCodeReportRows) {
      data = discountCodeReportRows.content;
      pages = discountCodeReportRows.totalPages;
      defaultPageSize = discountCodeReportRows.size;
    }

    const { columns } = this;
    return (
      <div>
        <ReportToolbar download={this.download} disabled={!data.length} />
        <Table
          columns={columns}
          data={data}
          defaultPageSize={defaultPageSize}
          pages={pages}
          loading={loading}
        />
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <h1>Reporte Códigos de Descuento</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Códigos de Descuento',
      href: DISCOUNT_CODE_REPORTS_PATH,
    },
    {
      text: 'Ver',
      href: state.routing.location.pathname,
    },
  ],
  error: state.ReportUnit.DiscountCodeReport.get('current').get('error'),
  report: state.ReportUnit.DiscountCodeReport.get('current').get('content'),
  loading: state.ReportUnit.DiscountCodeReport.get('current').get('loading'),
});

const mapDispatchToProps = {
  getDiscountCodeReport,
  clearDiscountCodeReport,
  clearErrorGettingDiscountCodeReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCodeReport);
