import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form, FormGroup, InputGroupText } from 'reactstrap';
import {
  isRequired,
  validateLength,
  validateNumber,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import CountrySelect from '../../../common/forms/select/CountrySelect';
import FormFooter from '../../../common/forms/FormFooter';
import Loader from '../../../common/Loader';

const validateLength3 = validateLength(3, 'dígitos');

export const DetractionForm = ({ handleSubmit, loading }) =>
  loading ? (
    <Loader />
  ) : (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Porcentaje de detracción" required>
          <Field
            name="detractionRate"
            component={TextInput}
            type="text"
            placeholder="Porcentaje Detracción"
            append={<InputGroupText>%</InputGroupText>}
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Monto mínimo para la detracción" required>
          <Field
            name="minDetractionAmount"
            component={TextInput}
            type="text"
            placeholder="Monto mínimo para la detracción"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Código de detracción" required>
          <Field
            name="detractionCode"
            component={TextInput}
            type="text"
            validate={[isRequired, validateNumber, validateLength3]}
            placeholder="Código de detracción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="País" required>
          <Field
            name="countryId"
            component={CountrySelect}
            validate={[isRequired]}
            placeholder="Ingresa mínimo 4 caracteres"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Número de cuenta" required>
          <Field
            name="bankAccountNumber"
            component={TextInput}
            type="text"
            validate={[isRequired]}
            placeholder="Número de cuenta"
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );

const mapStateToProps = ({ AccountingUnit }) => ({
  loading: !AccountingUnit.Detraction.getIn(['current', 'activity']).isEmpty(),
});

DetractionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

DetractionForm.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'DetractionForm',
})(DetractionForm);

export default connect(mapStateToProps)(formComponent);
