const validate = (values) => {
  const errors = {};

  const passengersArrayErrors = [];

  values.passengers.forEach((passenger, index) => {
    const passengerErrors = {};

    if (!passenger || !passenger.passenger)
      passengerErrors.passenger = 'Seleccione un pasajero o ingrese uno nuevo';

    passengersArrayErrors[index] = passengerErrors;
  });

  if (passengersArrayErrors.length) {
    errors.passengers = passengersArrayErrors;
  }

  return errors;
};

export default validate;
