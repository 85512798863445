import React from 'react';
import { Col, Row } from 'reactstrap';
import PostpaidBookingItinerarySearch from './search/PostpaidBookingItinerarySearch';
import PostpaidBookingItinerarySearchResults from './itineraries/PostpaidBookingItinerarySearchResults';
import PostpaidBookingSeatSelection from './seats/PostpaidBookingSeatSelection';
import PostpaidBookingSelectedSeats from './seats/PostpaidBookingSelectedSeats';

const PostpaidBookingRoundTrip = () => (
  <div>
    <PostpaidBookingItinerarySearch />
    <Row>
      <Col lg={6} md={12}>
        <PostpaidBookingItinerarySearchResults />
      </Col>
      <Col lg={4} md={6}>
        <PostpaidBookingSeatSelection />
      </Col>
      <Col lg={2} md={6}>
        <PostpaidBookingSelectedSeats />
      </Col>
    </Row>
  </div>
);

export default PostpaidBookingRoundTrip;
