import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  getLiquidationSessions,
  clearLiquidationSessions,
} from '../../../../actions';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { numberFormatter } from '../../../../utils/number';
import { tzNormalizeDate } from '../../../../utils/date';

export const LiquidationSessionSelect = ({
  dispatchClearLiquidationSessions,
  cleanWhenUnmount,
  dispatchGetLiquidationSessions,
  query,
  loading,
  options,
  isGridVariant,
  value,
  isMulti,
  isClearable,
  onValueChange,
  placeholder,
  ...rest
}) => {
  useLayoutEffect(
    () => () => cleanWhenUnmount && dispatchClearLiquidationSessions(),
    [],
  );

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 3) {
      const filterQuery = {
        ...DEFAULT_QUERY_GET_ALL,
        query: [`agency.name:${inputValue}`],
      };
      if (query) filterQuery.deposited = query.deposited;
      dispatchGetLiquidationSessions(filterQuery);
    }
  };

  return (
    <Select
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      input={{ value }}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      placeholder={placeholder}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};

LiquidationSessionSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetLiquidationSessions: PropTypes.func.isRequired,
  dispatchClearLiquidationSessions: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  isClearable: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  cleanWhenUnmount: PropTypes.bool,
  query: PropTypes.shape({
    deposited: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
};

LiquidationSessionSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  isClearable: false,
  onValueChange: undefined,
  value: {},
  cleanWhenUnmount: true,
  query: null,
  placeholder: 'Ingrese una agencia',
};

const mapStateToProps = ({ SalesUnit }) => ({
  options: SalesUnit.LiquidationSession.getIn([
    'all',
    'content',
    'content',
  ]).map((liquidation) => ({
    value: liquidation.id,
    label: `${liquidation.agency.name} (${numberFormatter({
      value: liquidation.cashOnHand,
    })}) - ${tzNormalizeDate({
      date: liquidation.createDate,
      format: DATE_TIME_FORMAT,
    })}`,
    cashOnHand: liquidation.cashOnHand,
    commission: liquidation.commission,
    commissionTaxes: liquidation.commissionTaxes,
  })),
  loading: SalesUnit.LiquidationSession.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetLiquidationSessions: getLiquidationSessions,
  dispatchClearLiquidationSessions: clearLiquidationSessions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiquidationSessionSelect);
