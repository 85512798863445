import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  CLEAR_MANIFEST_ITINERARY,
  CLEAR_MANIFEST_ITINERARY_DETAIL,
  FLAG_GETTING_MANIFEST_ITINERARY,
  FLAG_GETTING_MANIFEST_ITINERARY_DETAIL,
  GET_MANIFEST_ITINERARY,
  GET_MANIFEST_ITINERARY_DETAIL,
} from '../../../actions/types/cargo';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
  detail: Immutable.Map({
    content: Immutable.Set([]),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_MANIFEST_ITINERARY:
      return state.setIn(['all', 'loading'], payload);
    case GET_MANIFEST_ITINERARY:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case GET_MANIFEST_ITINERARY_DETAIL:
      return state.setIn(['detail', 'content'], Immutable.Map(payload));
    case FLAG_GETTING_MANIFEST_ITINERARY_DETAIL:
      return state.setIn(['detail', 'loading'], payload);
    case CLEAR_MANIFEST_ITINERARY:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case CLEAR_MANIFEST_ITINERARY_DETAIL:
      return state.setIn(
        ['detail', 'content'],
        INITIAL_STATE.getIn(['detail', 'content']),
      );
    default:
      return state;
  }
};
