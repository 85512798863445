import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_AGENCIES,
  GET_AGENCIES,
  CLEAR_AGENCIES,
  FLAG_AGENCY_ACTIVITY,
  GET_AGENCY,
  CLEAR_AGENCY,
} from '../types';
import {
  AGENCY_ENDPOINT,
  generateEndpointToAddCreditLine,
  generateUserAgenciesEndpoint,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_PATCH_CONFIG,
} from '../../config/rest';
import { AGENCY_PATH } from '../../config/paths';

const flagGettingAgencies = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_AGENCIES,
    payload: flag,
  });

const getAgencies = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingAgencies(true));
    const query = tableFilters;
    const url = `${AGENCY_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    // check if it is an error response
    await isErrorResponse(response);

    const agencies = await response.json();
    dispatch({
      type: GET_AGENCIES,
      payload: agencies,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingAgencies(false));
  }
};

const clearAgencies = () => (dispatch) =>
  dispatch({
    type: CLEAR_AGENCIES,
  });

const getUserAgencies =
  ({ userId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingAgencies(true));
      const url = generateUserAgenciesEndpoint(userId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      // check if it is an error response
      await isErrorResponse(response);
      const agencies = await response.json();

      dispatch({
        type: GET_AGENCIES,
        payload: { content: agencies },
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingAgencies(false));
    }
  };

const clearAgency = () => (dispatch) =>
  dispatch({
    type: CLEAR_AGENCY,
  });

const flagAgencyActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_AGENCY_ACTIVITY,
    payload: flag,
  });

const postAgency =
  async ({
    name,
    description,
    locationId,
    userList,
    workstationList,
    companyId,
    contractType,
    initialBalance,
    actualBalance,
    cutoffDay,
    slack,
    active,
    commission,
    taxExempt,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyActivity(true));
      const payload = {
        name,
        description,
        locationId,
        userList,
        workstationList,
        companyId,
        contractType,
        initialBalance,
        actualBalance,
        cutoffDay,
        slack,
        active,
        commission,
        taxExempt,
      };

      const url = AGENCY_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      await response.json();

      dispatch(push(AGENCY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAgencyActivity(false));
    }
  };

const getAgency =
  async ({ agencyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyActivity(true));

      const url = `${AGENCY_ENDPOINT}/${agencyId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const agency = await response.json();

      dispatch({
        type: GET_AGENCY,
        payload: agency,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAgencyActivity(false));
    }
  };

const putAgency =
  async (
    agencyId,
    {
      name,
      description,
      locationId,
      userList,
      workstationList,
      companyId,
      contractType,
      initialBalance,
      actualBalance,
      cutoffDay,
      slack,
      active,
      commission,
      taxExempt,
      agencyGroupId,
      agencySunatCode,
      createDate,
      costCenterId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyActivity(true));

      const payload = {
        name,
        description,
        locationId,
        userList,
        workstationList,
        companyId,
        contractType,
        initialBalance,
        actualBalance,
        cutoffDay,
        slack,
        active,
        commission,
        taxExempt,
        agencyGroupId,
        agencySunatCode,
        createDate,
        costCenterId,
        id: agencyId,
      };

      const url = `${AGENCY_ENDPOINT}/${agencyId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      const user = await response.json();

      dispatch(push(`${AGENCY_PATH}/${user.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAgencyActivity(false));
    }
  };

const deleteAgency =
  async ({ agencyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyActivity(true));

      const url = `${AGENCY_ENDPOINT}/${agencyId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response);

      await response.json();

      dispatch(push(AGENCY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAgencyActivity(false));
    }
  };

const patchAgencyCreditLine =
  async ({ agencyId, amount }) =>
  async (dispatch) => {
    try {
      dispatch(flagAgencyActivity(true));

      const url = generateEndpointToAddCreditLine(agencyId, amount);
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(`${AGENCY_PATH}/${agencyId}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagAgencyActivity(false));
    }
  };

export {
  getAgencies,
  clearAgencies,
  getUserAgencies,
  postAgency,
  clearAgency,
  getAgency,
  putAgency,
  deleteAgency,
  patchAgencyCreditLine,
};
