import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getCompanyTicket,
  clearCompanyTicket,
} from '../../../../actions/index';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { TICKET_STATUS } from '../../../../config/constants';
import { COMPANY_TICKET_PATH } from '../../../../config/paths';
import TicketToolbar from '../../reservation/ticket/ticket-toolbar/TicketToolbar';
import TicketVoid from '../../reservation/ticket/TicketVoid';
import CreditNote from '../../reservation/ticket/CreditNote';
import Refund from '../../reservation/ticket/Refund';
import History from '../../reservation/ticket/History';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class CompanyTicket extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: companyTicketId },
      },
      dispatchGetCompanyTicket,
    } = this.props;

    dispatchGetCompanyTicket({ companyTicketId });
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id },
      },
    } = prevProps;

    const {
      match: {
        params: { id: newId },
      },
      dispatchGetCompanyTicket,
    } = this.props;

    if (id !== newId) {
      dispatchGetCompanyTicket({ companyTicketId: id });
    }
  }

  componentWillUnmount() {
    this.props.dispatchClearCompanyTicket();
  }

  render() {
    const {
      ticket,
      match: {
        params: { id },
      },
      loading,
      breadcrumbs,
    } = this.props;

    const ticketId = parseInt(id, 10);

    let ticketVoidComponent = null;

    let creditNoteComponent = null;

    let refundComponent = null;

    const showHistory =
      ticket.get('nextTicketId') || ticket.get('previousTicketId');

    const creditNote = ticket.get('creditNote');

    const status = ticket.get('ticketStatus');

    let content;
    let toolbar;
    let title = 'Boleto';

    if (loading) {
      content = <Loader />;
    } else if (ticket.isEmpty()) {
      content = <NoDataResource returnPage={COMPANY_TICKET_PATH} />;
    } else {
      const tripData = (
        <Fragment>
          <ResourceProperty label="Origen:">
            {ticket.get('sourceLocation').name}
          </ResourceProperty>
          <ResourceProperty label="Destino:">
            {ticket.get('destinationLocation').name}
          </ResourceProperty>
          <ResourceProperty label="Embarque:">
            {`${ticket.get('sourceLocation').name} - ${
              ticket.get('sourceLocation').address
            }`}
          </ResourceProperty>
          <ResourceProperty label="Desembarque:">
            {`${ticket.get('destinationLocation').name} - ${
              ticket.get('destinationLocation').address
            }`}
          </ResourceProperty>
        </Fragment>
      );

      const passengerData = (
        <Fragment>
          <ResourceProperty label="Nombre Completo:">
            {ticket.get('customer').fullName}
          </ResourceProperty>
          <ResourceProperty label="Documento de identificación:">
            {`${ticket.get('customer').identificationType.name} ${
              ticket.get('customer').idDocumentNumber
            }`}
          </ResourceProperty>
          <ResourceProperty label="Celular:">
            {ticket.get('customer').mobilePhone}
          </ResourceProperty>
          <ResourceProperty label="Email:">
            {ticket.get('customer').email}
          </ResourceProperty>
        </Fragment>
      );

      const paymentData = (
        <Fragment>
          <ResourceProperty label="Precio de Lista:">
            {ticket.get('listPrice') ? ticket.get('listPrice').toFixed(2) : ''}
          </ResourceProperty>
          <ResourceProperty label="Precio de Venta:">
            {ticket.get('salePrice') ? ticket.get('salePrice').toFixed(2) : ''}
          </ResourceProperty>
        </Fragment>
      );

      if (creditNote) {
        creditNoteComponent = (
          <CreditNote creditNote={ticket.get('creditNote')} />
        );
      }

      const ticketVoid = ticket.get('ticketVoid');
      if (ticketVoid) {
        ticketVoidComponent = <TicketVoid ticketVoid={ticketVoid} />;
      }

      if (status === TICKET_STATUS.REFUNDED.value) {
        refundComponent = <Refund />;
      }

      content = (
        <Fragment>
          <br />
          <h2>Viaje</h2>
          {tripData}
          <br />
          <h2>Pasajero</h2>
          {passengerData}
          <br />
          <h2>Pago</h2>
          {paymentData}
          <br />
          {showHistory && (
            <History
              nextTicketId={ticket.get('nextTicketId')}
              nextTicketCode={ticket.get('nextTicketCode')}
              previousTicketId={ticket.get('previousTicketId')}
              previousTicketCode={ticket.get('previousTicketCode')}
            />
          )}
          {ticketVoidComponent}
          {creditNoteComponent}
          {refundComponent}
        </Fragment>
      );

      const departureDate = ticket.get('departureDate');
      const voucherTypeId = ticket.get('transactions')[0].voucherType.id;

      toolbar = (
        <TicketToolbar
          creditNote={creditNote || null}
          ticketId={ticketId}
          fullDocumentCode={ticket.get('fullDocumentCode')}
          ticketStatus={status}
          voucherTypeId={voucherTypeId}
          departureDate={departureDate}
          ticket={ticket}
          forThirdParty
        />
      );

      title = `Boleto ${ticket.get('fullDocumentCode')}`;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title={title}
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

CompanyTicket.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetCompanyTicket: PropTypes.func.isRequired,
  dispatchClearCompanyTicket: PropTypes.func.isRequired,
  ticket: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
};

CompanyTicket.defaultProps = {
  breadcrumbs: [],
  loading: false,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos',
      href: COMPANY_TICKET_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  ticket: ContractUnit.CompanyTicket.getIn(['current', 'content']),
  loading: !ContractUnit.CompanyTicket.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCompanyTicket: getCompanyTicket,
  dispatchClearCompanyTicket: clearCompanyTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTicket);
