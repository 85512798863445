import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { SEGMENT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import SegmentForm from './SegmentForm';
import Loader from '../../../common/Loader';
import {
  clearSegment,
  clearSegments,
  getSegment,
  getSegments,
  putSegment,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditSegment extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: segmentId },
      },
      dispatchGetSegment,
    } = this.props;

    dispatchGetSegment({ segmentId });
  }

  componentWillUnmount() {
    this.props.dispatchClearSegment();
  }

  onSubmit = async (formValues) => {
    const {
      dispatchPutSegment,
      match: {
        params: { id },
      },
      segment,
      dispatchPush,
    } = this.props;

    const newFormValues = { ...formValues };

    newFormValues.sourceLocationId = formValues.sourceLocationId.value;
    newFormValues.destinationLocationId =
      formValues.destinationLocationId.value;
    newFormValues.lackingData = segment.get('lackingData');
    if (formValues.tollList)
      newFormValues.tollSegmentList = formValues.tollList.map(({ toll }) => ({
        tollId: toll.value,
        segmentId: id,
        id: toll.id || null,
      }));

    const response = await dispatchPutSegment(id, newFormValues);

    if (response) dispatchPush(SEGMENT_PATH);
  };

  generateInitialValues = ({
    sourceLocation,
    destinationLocation,
    distance,
    duration,
    isOneWay,
    tollSegmentList,
  }) => ({
    sourceLocationId: {
      value: sourceLocation.id,
      label: sourceLocation.name,
      city: sourceLocation.address.city,
    },
    destinationLocationId: {
      value: destinationLocation.id,
      label: destinationLocation.name,
      city: destinationLocation.address.city,
    },
    distance,
    duration,
    isOneWay,
    tollList: tollSegmentList.map(({ toll, tollId, id }) => ({
      toll: { value: tollId, label: toll.location.name, id },
    })),
  });

  render() {
    const { breadcrumbs, segment, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (segment.isEmpty()) {
      content = <NoDataResource returnPage={SEGMENT_PATH} />;
    } else {
      content = (
        <SegmentForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(segment.toJS())}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Segmento"
        subtitle="Editar segmento"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { RouteUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Segmentos',
      href: SEGMENT_PATH,
    },
    {
      text: 'Ver',
      href: `${SEGMENT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  segment: RouteUnit.Segment.getIn(['current', 'content']),
  loading: !RouteUnit.Segment.getIn(['current', 'activity']).isEmpty(),
  segments: RouteUnit.Segment.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetSegment: getSegment,
  dispatchClearSegment: clearSegment,
  dispatchPutSegment: putSegment,
  dispatchGetSegments: getSegments,
  dispatchClearSegments: clearSegments,
  dispatchPush: push,
};

EditSegment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutSegment: PropTypes.func.isRequired,
  dispatchGetSegment: PropTypes.func.isRequired,
  dispatchClearSegment: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  segment: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchPush: PropTypes.func.isRequired,
};

EditSegment.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSegment);
