import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Form, FormGroup, InputGroupText } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import RouteSelect from '../../../common/forms/select/RouteSelect';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import SeatMapSelect from '../../../common/forms/select/SeatMapSelect';
import CircuitSelect from '../../../common/forms/select/CircuitSelect';
import ServiceTypeSelect from '../../../common/forms/select/ServiceTypeSelect';
import {
  EXTRAORDINARY_MOVEMENT_TYPE_OPTIONS,
  KILOMETER_SYMBOL,
} from '../../../../config/constants';
import DatePicker from '../../../common/forms/input/DatePicker';
import TimeInput from '../../../common/forms/input/TimeInput';
import Select from '../../../common/forms/select/Select';
import {
  clearExpectedFuelConsumptions,
  getExpectedFuelConsumptions,
} from '../../../../actions';
import { DEFAULT_QUERY } from '../../../../config/queries';
import FormFooter from '../../../common/forms/FormFooter';

export class ExtraordinaryMovementForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showExtraordinaryMovementFields: true,
      showRescueItineraryFields: false,
      setToll: false,
      totalToll: 0,
      busAxles: 0,
      companyBusId: 0,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    this.onUpdate();
  }

  componentWillUnmount() {
    this.props.dispatchClearExpectedFuelConsumptions();
  }

  onMount = () => {
    const {
      initialValues: { sourceLocation, destinationLocation },
    } = this.props;

    const { showExtraordinaryMovementFields, showRescueItineraryFields } =
      this.state;

    if (
      sourceLocation &&
      destinationLocation &&
      showExtraordinaryMovementFields &&
      !showRescueItineraryFields
    ) {
      this.setState({
        showRescueItineraryFields: true,
        showExtraordinaryMovementFields: false,
      });
    }
  };

  onUpdate = () => {
    const { busAxles, totalToll, setToll } = this.state;

    if (setToll) {
      this.props.dispatchChange(
        'ExtraordinaryMovementForm',
        'tollExpenseAmount',
        busAxles * totalToll,
      );
      this.setState({ setToll: false });
    }

    this.calculateExpectedFuelConsumption();
  };

  onChangeBus = ({ busChassis, seatMap, label }) => {
    const { dispatchChange } = this.props;

    if (seatMap) {
      dispatchChange('ExtraordinaryMovementForm', 'seatMapId', {
        value: seatMap.id,
        label: seatMap.displayName,
      });

      if (seatMap.serviceType) {
        dispatchChange('ExtraordinaryMovementForm', 'serviceTypeId', {
          value: seatMap.serviceType.id,
          label: seatMap.serviceType.name,
        });
      }
    }

    this.setState({
      busAxles: busChassis.numberOfAxles,
      setToll: true,
      companyBusId: label,
    });
  };

  onChangeRoute = ({
    routeSegmentList,
    sourceLocation,
    destinationLocation,
    value,
  }) => {
    const { dispatchChange, dispatchGetExpectedFuelConsumptions } = this.props;

    let totalDistance = 0;
    let totalToll = 0;

    routeSegmentList.forEach(({ segment: { distance, tollSegmentList } }) => {
      totalDistance += +distance || 0;

      tollSegmentList.forEach(
        ({ toll: { pricePerAxle, taxPrePaymentPerAxle } }) => {
          totalToll += +pricePerAxle + +taxPrePaymentPerAxle;
        },
      );
    });

    dispatchChange('ExtraordinaryMovementForm', 'distance', totalDistance);
    dispatchChange(
      'ExtraordinaryMovementForm',
      'sourceLocation',
      sourceLocation.name,
    );
    dispatchChange(
      'ExtraordinaryMovementForm',
      'destinationLocation',
      destinationLocation.name,
    );

    const query = { ...DEFAULT_QUERY };
    query.query = [`routeId:${value}`];
    dispatchGetExpectedFuelConsumptions(query);

    this.setState({
      totalToll,
      setToll: true,
    });
  };

  calculateExpectedFuelConsumption = () => {
    // Get expected fuel consumptions for selected route
    const {
      expectedFuelConsumptions,
      expectedFuelConsumptionsLoading,
      dispatchChange,
    } = this.props;

    const { companyBusId } = this.state;

    if (
      !expectedFuelConsumptionsLoading &&
      !expectedFuelConsumptions.isEmpty()
    ) {
      const expectedFuels = expectedFuelConsumptions.get('content');

      let fuelGallons = 0;
      let adBlueLiters = 0;
      if (expectedFuels.length) {
        expectedFuels.forEach(({ busFuelGroupConsumptionList }) => {
          busFuelGroupConsumptionList.forEach(
            ({
              numberOfGallon,
              numberOfGallonAdblue,
              busFuelGroup: { registeredBusList },
            }) => {
              registeredBusList.forEach((bus) => {
                if (+bus.companyBusId === +companyBusId) {
                  fuelGallons = numberOfGallon;
                  adBlueLiters = numberOfGallonAdblue;
                }
              });
            },
          );
        });
      }

      dispatchChange('ExtraordinaryMovementForm', 'fuelGallons', fuelGallons);
      dispatchChange('ExtraordinaryMovementForm', 'adBlueLiters', adBlueLiters);
    }
  };

  showFields = () => {
    const {
      showExtraordinaryMovementFields,
      showRescueItineraryFields,
      companyBusId,
    } = this.state;

    const sourceAndDestination = (
      <Fragment>
        <FormGroup row>
          <FormItem label="Origen" required>
            <Field
              name="sourceLocation"
              component={TextInput}
              type="text"
              placeholder="Origen"
              validate={[isRequired]}
              disabled={showExtraordinaryMovementFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Destino" required>
            <Field
              name="destinationLocation"
              component={TextInput}
              type="text"
              placeholder="Destino"
              validate={[isRequired]}
              disabled={showExtraordinaryMovementFields}
            />
          </FormItem>
        </FormGroup>
      </Fragment>
    );

    let fields = null;

    if (showExtraordinaryMovementFields) {
      fields = (
        <Fragment>
          <FormGroup row>
            <FormItem label="Ruta" required>
              <Field
                name="routeId"
                component={RouteSelect}
                onChange={this.onChangeRoute}
                isClearable={false}
                validate={[isRequired]}
                isDisabled={companyBusId === 0}
              />
            </FormItem>
          </FormGroup>
          {sourceAndDestination}
          <FormGroup row>
            <FormItem label="Detalle de Ruta">
              <Field
                name="routeDetail"
                component={TextInput}
                type="text"
                placeholder="Detalle de Ruta"
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Circuito" required>
              <Field
                name="circuitId"
                component={CircuitSelect}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Mapa de Asientos">
              <Field name="seatMapId" component={SeatMapSelect} isClearable />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Tipo de Servicio">
              <Field
                name="serviceTypeId"
                component={ServiceTypeSelect}
                isClearable
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Fecha de Salida" required>
              <Field
                name="departureDate"
                component={DatePicker}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Hora de Salida" required>
              <Field
                name="departureTime"
                component={TimeInput}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Fecha de Llegada">
              <Field name="arrivalDate" component={DatePicker} />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Hora de Llegada">
              <Field name="arrivalTime" component={TimeInput} />
            </FormItem>
          </FormGroup>
        </Fragment>
      );
    }

    if (showRescueItineraryFields) {
      fields = sourceAndDestination;
    }

    return fields;
  };

  render() {
    const { loading, handleSubmit } = this.props;

    const { showRescueItineraryFields } = this.state;

    if (loading) {
      return <Loader />;
    }

    const fields = this.showFields();

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Tipo de Movimiento" required>
            <Field
              name="extraordinaryMovementType"
              component={Select}
              options={EXTRAORDINARY_MOVEMENT_TYPE_OPTIONS}
              isDisabled={showRescueItineraryFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Bus" required>
            <Field
              name="busId"
              component={RegisteredBusSelect}
              isClearable={false}
              onChange={this.onChangeBus}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {fields}
        <FormGroup row>
          <FormItem label="Monto de Comisión de Servicio de Bordo">
            <Field
              name="cabinCrewCommissionAmount"
              component={TextInput}
              type="text"
              placeholder="0.00"
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Monto de Comisión de Conductor">
            <Field
              name="driverCommissionAmount"
              component={TextInput}
              type="text"
              placeholder="0.00"
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Monto de Peaje">
            <Field
              name="tollExpenseAmount"
              component={TextInput}
              type="text"
              placeholder="Monto de Peaje"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Monto de Otros Egresos">
            <Field
              name="otherExpenseAmount"
              component={TextInput}
              type="text"
              placeholder="Monto de Otros Egresos"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Distancia">
            <Field
              name="distance"
              component={TextInput}
              type="text"
              placeholder="Distancia"
              append={<InputGroupText>{KILOMETER_SYMBOL}</InputGroupText>}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Galones">
            <Field
              name="fuelGallons"
              component={TextInput}
              type="text"
              placeholder="Galones"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Ad Blue (Litros)">
            <Field
              name="adBlueLiters"
              component={TextInput}
              type="text"
              placeholder="Ad Blue (Litros)"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentario">
            <Field
              name="comment"
              component={TextInput}
              type="textarea"
              placeholder="Comentario"
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

ExtraordinaryMovementForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    sourceLocation: PropTypes.string,
    destinationLocation: PropTypes.string,
  }),
  dispatchChange: PropTypes.func.isRequired,
  dispatchGetExpectedFuelConsumptions: PropTypes.func.isRequired,
  dispatchClearExpectedFuelConsumptions: PropTypes.func.isRequired,
  expectedFuelConsumptions: PropTypes.instanceOf(Immutable.Map).isRequired,
  expectedFuelConsumptionsLoading: PropTypes.bool.isRequired,
};

ExtraordinaryMovementForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ ItineraryUnit, TrafficUnit }) => ({
  loading: !ItineraryUnit.ExtraordinaryMovement.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  expectedFuelConsumptions: TrafficUnit.ExpectedFuelConsumption.getIn([
    'all',
    'content',
  ]),
  expectedFuelConsumptionsLoading: TrafficUnit.ExpectedFuelConsumption.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetExpectedFuelConsumptions: getExpectedFuelConsumptions,
  dispatchClearExpectedFuelConsumptions: clearExpectedFuelConsumptions,
};

const formComponent = reduxForm({
  form: 'ExtraordinaryMovementForm',
})(ExtraordinaryMovementForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
