import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { printContractServiceOrder } from '../../../../utils/printers/ContractServiceOrder';

const PrintContractServiceOrderButton = (contract) => (
  <Button
    color="light"
    className="border"
    onClick={() => printContractServiceOrder(contract)}
  >
    <i className="fa fa-print" /> Orden de Servicio
  </Button>
);

PrintContractServiceOrderButton.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default PrintContractServiceOrderButton;
