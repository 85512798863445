import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import {
  getUserAgencies,
  clearAgencies,
  postStartSalesSession,
} from '../../../../actions';
import { SALES_SESSION_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { isRequired } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import PrintTemplateTable from './PrintTemplateTable';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';

class NewSalesSession extends Component {
  static propTypes = {
    dispatchGetUserAgencies: PropTypes.func.isRequired,
    dispatchPostStartSalesSession: PropTypes.func.isRequired,
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    agencies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    agencyOptions: Select.propTypes.options,
    loadingAgencies: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      customer: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  static defaultProps = {
    agencies: [],
    agencyOptions: [],
    location: undefined,
  };

  constructor(props) {
    super(props);
    const { user, dispatchGetUserAgencies } = this.props;
    if (user) {
      dispatchGetUserAgencies({ userId: user.id });
    }
  }

  state = {
    workstationOptions: [],
    selectedAgency: undefined,
    selectedWorkstation: undefined,
    newPrintTemplateDocumentCodes: {},
  };

  onPrintTemplateChange = (printTemplateId, value) => {
    const { newPrintTemplateDocumentCodes } = this.state;
    newPrintTemplateDocumentCodes[printTemplateId] = value;
    this.setState({ newPrintTemplateDocumentCodes });
  };

  handleNewSalesSessionSubmit = (formValues) => {
    const {
      dispatchPostStartSalesSession,
      location: { search },
    } = this.props;
    const { newPrintTemplateDocumentCodes } = this.state;
    const sourcePath = QueryString.parse(search, {
      ignoreQueryPrefix: true,
    }).source;
    const overridePrintTemplates = [];
    Object.keys(newPrintTemplateDocumentCodes).forEach((key) => {
      overridePrintTemplates.push({
        printTemplateId: key,
        documentCode: newPrintTemplateDocumentCodes[key],
      });
    });
    dispatchPostStartSalesSession({
      agencyId: formValues.agency.value,
      workstationId: formValues.workstation.value,
      isOverrideGaplessSequenceNumber: formValues.overridePrintTemplate,
      overridePrintTemplates: overridePrintTemplates.length
        ? overridePrintTemplates
        : undefined,
      sourcePath,
    });
  };

  handleAgencyChange = (agencyOption) => {
    const { agencies } = this.props;
    // find agency and load workstations
    const selectedAgency = agencies.find(
      (agency) => agency.id === agencyOption.value,
    );
    if (selectedAgency) {
      this.setState({ selectedAgency });
      const workstationOptions = selectedAgency.workstationList.map(
        (workstation) => ({
          value: workstation.id,
          label: `${workstation.name} (${workstation.serialNumber})`,
        }),
      );
      this.setState({ workstationOptions });
    }
  };

  handleWorkstationChange = (workstationOption) => {
    const { selectedAgency } = this.state;
    if (selectedAgency) {
      const selectedWorkstation = selectedAgency.workstationList.find(
        (workstation) => workstation.id === workstationOption.value,
      );
      this.setState({ selectedWorkstation });
    }
  };

  render() {
    const { user, loadingAgencies, handleSubmit, agencyOptions, breadcrumbs } =
      this.props;
    const { selectedWorkstation, workstationOptions } = this.state;

    let content;

    if (loadingAgencies || !user) {
      content = <Loader />;
    } else {
      let printTemplateTable;
      if (selectedWorkstation) {
        printTemplateTable = (
          <PrintTemplateTable
            printStationList={selectedWorkstation.printStationList}
            handlePrintTemplateChange={this.onPrintTemplateChange}
          />
        );
      }

      content = (
        <Fragment>
          <ResourceProperty label="Usuario:" boldLabel={false}>
            {user.customer.fullName}
          </ResourceProperty>
          <Form onSubmit={handleSubmit(this.handleNewSalesSessionSubmit)}>
            <FormGroup row>
              <FormItem label="Agencias" required>
                <Field
                  name="agency"
                  component={Select}
                  options={agencyOptions}
                  validate={[isRequired]}
                  onChange={this.handleAgencyChange}
                />
              </FormItem>
            </FormGroup>
            <FormGroup row>
              <FormItem label="Caja" required>
                <Field
                  name="workstation"
                  component={Select}
                  options={workstationOptions}
                  validate={[isRequired]}
                  onChange={this.handleWorkstationChange}
                />
              </FormItem>
            </FormGroup>
            {printTemplateTable}
            <Row>
              <Col className="flex row-reverse">
                <ButtonToolbar>
                  <div>
                    <Button type="submit" color="primary">
                      Iniciar
                    </Button>
                  </div>
                </ButtonToolbar>
              </Col>
            </Row>
          </Form>
        </Fragment>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Nueva Sesión de Ventas"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit, authentication, HumanResourcesUnit }) => {
  const initialValues = {
    overridePrintTemplate: false,
  };
  const agencyOptions = HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.id,
    label: agency.name,
  }));

  return {
    initialValues,
    breadcrumbs: [
      ...SalesUnit.UnitHome.get('breadcrumbs'),
      {
        text: 'Sesiones de Venta',
        href: SALES_SESSION_PATH,
      },
      {
        text: 'Nueva Sesion de Venta',
        href: '',
      },
    ],
    user: authentication.get('user') || undefined,
    loadingAgencies: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
    agencies: HumanResourcesUnit.Agency.getIn(['all', 'content', 'content']),
    agencyOptions,
  };
};

const mapDispatchToProps = {
  dispatchGetUserAgencies: getUserAgencies,
  dispatchClearAgencies: clearAgencies,
  dispatchPostStartSalesSession: postStartSalesSession,
};

const formComponent = reduxForm({
  form: 'NewSalesSessionForm',
})(NewSalesSession);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
