import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { COLOR, DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  LIQUIDATION_SESSION_PATH,
  NEW_LIQUIDATION_SESSION_PATH,
} from '../../../../config/paths';
import { LIQUIDATION_SESSIONS_COLUMNS } from '../../../../config/columns';
import {
  clearLiquidationSessions,
  getLiquidationSessions,
} from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export const LiquidationSessions = ({
  dispatchClearLiquidationSessions,
  liquidations,
  loading,
  breadcrumbs,
  dispatchGetLiquidationSessions,
}) => {
  useLayoutEffect(() => () => dispatchClearLiquidationSessions(), []);

  const generateStyle = (row) => {
    let background = '';

    if (row) {
      const {
        original: {
          isSettled,
          dailyLiquidationSessionNoteList,
          depositId,
          liquidationDifferenceAmount,
          cashOnHand,
          amountPayable,
        },
      } = row;

      let differenceAmount = liquidationDifferenceAmount;

      if (amountPayable && amountPayable !== 0)
        differenceAmount = amountPayable - cashOnHand;

      if (dailyLiquidationSessionNoteList.length) background = COLOR.warning;

      if (!isSettled && differenceAmount !== 0) background = COLOR.danger;

      if (isSettled && !depositId) background = COLOR.info;

      if (isSettled && !!depositId) background = COLOR.success;
    }

    return {
      background,
    };
  };

  const data = liquidations.get('content') || [];
  const pages = liquidations.get('totalPages') || null;
  const defaultPageSize = liquidations.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Liquidaciones de Agencia"
      buttonPath={NEW_LIQUIDATION_SESSION_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: LIQUIDATION_SESSIONS_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetLiquidationSessions,
        modelPath: LIQUIDATION_SESSION_PATH,
        loading,
        navigateToModelOnRowClick: true,
        generateStyle,
      }}
    />
  );
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Liquidaciones de Agencia',
      href: LIQUIDATION_SESSION_PATH,
    },
  ],
  liquidations: SalesUnit.LiquidationSession.getIn(['all', 'content']),
  loading: SalesUnit.LiquidationSession.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetLiquidationSessions: getLiquidationSessions,
  dispatchClearLiquidationSessions: clearLiquidationSessions,
};

LiquidationSessions.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  liquidations: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetLiquidationSessions: PropTypes.func.isRequired,
  dispatchClearLiquidationSessions: PropTypes.func.isRequired,
};

LiquidationSessions.defaultProps = {
  liquidations: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiquidationSessions);
