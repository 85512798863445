import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateEndDate } from '../../../../utils/validators';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import DatePicker from '../../../common/forms/input/DatePicker';
import TextInput from '../../../common/forms/input/TextInput';

export class ItinerarySearchForm extends Component {
  validateDate = (value, allValues) =>
    validateEndDate(value, allValues.fromDateString);

  render() {
    const {
      sourceLocationIsRequired,
      destinationLocationIsRequired,
      handleSubmit,
      searchByDateRange,
      onModal,
      searchByDateRangeAndHour,
    } = this.props;

    const departureTimeStringField = (
      <FormGroup row>
        <FormItem label="Hora">
          <Field
            name="departureTimeString"
            component={TextInput}
            type="time"
            placeholder="HH:mm"
          />
        </FormItem>
      </FormGroup>
    );

    let fromDateLabel = 'Fecha';

    let fromDateName = 'departureDayString';

    const toDateStringField = (
      <FormGroup row>
        <FormItem label="Fecha Final" required>
          <Field
            name="toDateString"
            component={DatePicker}
            validate={[isRequired, this.validateDate]}
          />
        </FormItem>
      </FormGroup>
    );

    let field = departureTimeStringField;

    if (searchByDateRange) {
      field = toDateStringField;

      fromDateLabel = 'Fecha Inicial';

      fromDateName = 'fromDateString';
    }

    if (searchByDateRangeAndHour) {
      field = (
        <Fragment>
          {toDateStringField} {departureTimeStringField}
        </Fragment>
      );

      fromDateLabel = 'Fecha Inicial';

      fromDateName = 'fromDateString';
    }

    let formProps = { onSubmit: handleSubmit };
    let buttonProps = { type: 'submit' };

    if (onModal) {
      formProps = null;
      buttonProps = { onClick: handleSubmit, type: 'button' };
    }

    return (
      <Form {...formProps}>
        <FormGroup row>
          <FormItem label="Origen" required={sourceLocationIsRequired}>
            <Field
              name="sourceLocationId"
              component={LocationSelect}
              forSales
              validate={sourceLocationIsRequired ? [isRequired] : null}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Destino" required={destinationLocationIsRequired}>
            <Field
              name="destinationLocationId"
              component={LocationSelect}
              forSales
              validate={destinationLocationIsRequired ? [isRequired] : null}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label={fromDateLabel} required>
            <Field
              name={fromDateName}
              component={DatePicker}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {field}
        <Row>
          <Col className="flex row-reverse">
            <Button color="secondary" outline {...buttonProps}>
              <i className="fa fa-search" /> Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

ItinerarySearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sourceLocationIsRequired: PropTypes.bool,
  destinationLocationIsRequired: PropTypes.bool,
  searchByDateRange: PropTypes.bool,
  searchByDateRangeAndHour: PropTypes.bool,
  initialValues: PropTypes.shape({
    fromDateString: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    departureDayString: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }),
  onModal: PropTypes.bool,
};

ItinerarySearchForm.defaultProps = {
  sourceLocationIsRequired: true,
  destinationLocationIsRequired: true,
  searchByDateRange: false,
  searchByDateRangeAndHour: false,
  initialValues: {
    departureDayString: null,
  },
  onModal: false,
};

export default reduxForm({
  form: 'ItinerarySearchForm',
})(ItinerarySearchForm);
