import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import FormFooter from '../../../../common/forms/FormFooter';
import { PASSENGER_FLOW_COLUMNS } from '../../../../../config/columns';
import Loader from '../../../../common/Loader';
import Table from '../../../../common/Table';

export const ItineraryPassengerFlowForm = ({
  flow,
  handleSubmit,
  onSubmit,
  loading,
  type,
  index,
}) => {
  const flowData = flow[`${type}Flow`];
  const isBoarding = type === 'boarding';

  const onHandleSubmit = (formValues) => {
    const updatedItems = formValues.items.map((item) => {
      const ticketData = flowData.find(
        (ticket) => ticket.ticketTripRequestId === item.id,
      );

      return {
        ...item,
        ticket: ticketData.ticket,
      };
    });

    onSubmit({
      items: updatedItems,
      type,
      index,
    });
  };

  if (loading) return <Loader />;

  if (flow.wasSaved || !isBoarding)
    return (
      <Table
        columns={PASSENGER_FLOW_COLUMNS}
        data={flowData}
        defaultPageSize={flowData.length}
        showPagination={false}
      />
    );

  return (
    <>
      <h5>
        Seleccione los pasajeros que{' '}
        {isBoarding ? 'embarcaron' : 'desembarcaron'}
      </h5>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <SelectionableTable
          columns={PASSENGER_FLOW_COLUMNS}
          data={flowData}
          defaultPageSize={flowData.length}
          keyField="ticketTripRequestId"
          form="ItineraryPassengerFlowForm"
          showPagination={false}
        />
        <FormFooter />
      </Form>
    </>
  );
};

ItineraryPassengerFlowForm.propTypes = {
  flow: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

ItineraryPassengerFlowForm.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ItineraryUnit }, { index }) => ({
  loading: ItineraryUnit.Itinerary.getIn(['current', 'flowLoading']),
  flow: ItineraryUnit.Itinerary.getIn(['current', 'passengerFlow']).get(
    'passengerFlowForLocationList',
  )[index],
});

const formComponent = reduxForm({
  form: 'ItineraryPassengerFlowForm',
})(ItineraryPassengerFlowForm);

export default connect(mapStateToProps)(formComponent);
