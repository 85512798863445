import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './PostpaidSummary.css';
import {
  changeStep,
  getPretransaction,
  flagPostpaidBookingReservationCreated,
  clearItinerariesForPostpaidBooking,
  clearSeatsForPostpaidBooking,
} from '../../../../actions';
import PretransactionDetails from './PretransactionDetails';
import * as PostpaidSummaryPropTypes from './PostpaidSummaryPropTypes';
import PassengersTable from './PassengersTable';
import {
  POSTPAID_BOOKING_STEPS,
  POSTPAID_BOOKING_SEARCH_STEP_INDEX,
} from '../../../../config/constants';
import Loader from '../../../common/Loader';

class PostpaidSummary extends Component {
  static propTypes = {
    reservationId: PropTypes.number.isRequired,
    customerOrderId: PropTypes.number.isRequired,
    pretransaction: PostpaidSummaryPropTypes.pretransactionPropType.isRequired,
    passengers: PostpaidSummaryPropTypes.passengersPropType.isRequired,
    dispatchChangeStep: PropTypes.func.isRequired,
    dispatchGetPretransaction: PropTypes.func.isRequired,
    dispatchFlagPostpaidBookingReservationCreated: PropTypes.func.isRequired,
    dispatchClearItinerariesForPostpaidBooking: PropTypes.func.isRequired,
    dispatchClearSeatsForPostpaidBooking: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    reservationCreated: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { dispatchGetPretransaction, customerOrderId } = this.props;
    dispatchGetPretransaction({ customerOrderId });
  }

  componentDidUpdate(prevProps) {
    // set reservation created flag to true
    // once we are sure there is a reservation
    if (!prevProps.reservationCreated && !this.props.loading) {
      this.props.dispatchFlagPostpaidBookingReservationCreated(true);
    }
  }

  componentWillUnmount() {
    this.handleNewSearch(false);
  }

  handleNewSearch = (withChangeStep = true) => {
    const {
      dispatchChangeStep,
      dispatchClearItinerariesForPostpaidBooking,
      dispatchClearSeatsForPostpaidBooking,
    } = this.props;

    dispatchClearItinerariesForPostpaidBooking();
    dispatchClearSeatsForPostpaidBooking();

    if (withChangeStep) {
      const postpaidSearchstep =
        POSTPAID_BOOKING_STEPS[POSTPAID_BOOKING_SEARCH_STEP_INDEX];

      dispatchChangeStep(postpaidSearchstep);
    }
  };

  render() {
    const { reservationId, passengers, pretransaction, loading } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <Container>
        <Row className="summaryHeader">
          <Col>
            <h1>{`Reserva: ${reservationId}`}</h1>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="text-success text-center">
              Reserva pospago realizada con éxito
            </p>
          </Col>
        </Row>
        <PretransactionDetails pretransaction={pretransaction} />
        <br />
        <Row>
          <Col>
            <h2>Pasajeros</h2>
          </Col>
        </Row>
        <PassengersTable passengers={passengers} />
        <Button
          color="primary"
          className="pull-right"
          onClick={this.handleNewSearch}
          size="lg"
        >
          <i className="fa fa-search" /> Nueva Búsqueda
        </Button>
      </Container>
    );
  }
}

const mapStateToProps = ({ PostpaidBookingUnit }) => ({
  reservationId: PostpaidBookingUnit.PostpaidBooking.getIn([
    'receiver',
    'customerOrder',
    'reservationId',
  ]),
  customerOrderId: PostpaidBookingUnit.PostpaidBooking.getIn([
    'receiver',
    'customerOrder',
    'id',
  ]),
  passengers: PostpaidBookingUnit.PostpaidBooking.getIn([
    'receiver',
    'customerOrder',
    'passengers',
  ]),
  pretransaction: PostpaidBookingUnit.PostpaidBooking.getIn([
    'summary',
    'pretransaction',
  ]).toJS(),
  // show loader until pretransaction is not empty
  loading: PostpaidBookingUnit.PostpaidBooking.getIn([
    'summary',
    'pretransaction',
  ]).isEmpty(),
  reservationCreated:
    PostpaidBookingUnit.PostpaidBooking.get('reservationCreated'),
});

const mapDispatchToProps = {
  dispatchChangeStep: changeStep,
  dispatchGetPretransaction: getPretransaction,
  dispatchFlagPostpaidBookingReservationCreated:
    flagPostpaidBookingReservationCreated,
  dispatchClearItinerariesForPostpaidBooking:
    clearItinerariesForPostpaidBooking,
  dispatchClearSeatsForPostpaidBooking: clearSeatsForPostpaidBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostpaidSummary);
