import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  NEW_ORGANIZATION_CHART_PATH,
  ORGANIZATION_CHART_PATH,
} from '../../../../config/paths';
import {
  getOrganizationCharts,
  clearOrganizationCharts,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { ORGANIZATION_CHART_COLUMNS } from '../../../../config/columns';

export const OrganizationCharts = ({
  breadcrumbs,
  organizationCharts,
  loading,
  dispatchGetOrganizationCharts,
  dispatchClearOrganizationCharts,
}) => {
  /*
   * The following hook overrides the componentWillUnmount function.
   * View documentation in https://bit.ly/3OI2V0u
   */
  useLayoutEffect(() => () => dispatchClearOrganizationCharts(), []);

  const data = organizationCharts.get('content') || [];
  const pages = organizationCharts.get('totalPages') || null;
  const defaultPageSize = organizationCharts.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Organigrama"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_ORGANIZATION_CHART_PATH}
      tableStructure={{
        columns: ORGANIZATION_CHART_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetOrganizationCharts,
        modelPath: ORGANIZATION_CHART_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetOrganizationCharts: getOrganizationCharts,
  dispatchClearOrganizationCharts: clearOrganizationCharts,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Organigrama',
      href: ORGANIZATION_CHART_PATH,
    },
  ],
  organizationCharts: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'loading',
  ]),
});

OrganizationCharts.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  organizationCharts: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetOrganizationCharts: PropTypes.func.isRequired,
  dispatchClearOrganizationCharts: PropTypes.func.isRequired,
};

OrganizationCharts.defaultProps = {
  organizationCharts: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCharts);
