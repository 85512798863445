import React, { Component, Fragment } from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { getUnclaimedCargoItems } from '../../../../actions/cargo/LegalAbandonment';
import Loader from '../../../common/Loader';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import { LEGAL_ABANDONMENT_CARGO_ITEMS_COLUMNS } from '../../../../config/columns';
import FormFooter from '../../../common/forms/FormFooter';

export class LegalAbandonmentForm extends Component {
  constructor(props) {
    super(props);

    this.props.dispatchGetUnclaimedCargoItems();
  }

  render() {
    const { data, loading, handleSubmit } = this.props;

    const dataArray = data.get('content');

    let table =
      dataArray.length > 0 ? (
        <Form onSubmit={handleSubmit}>
          <SelectionableTable
            columns={LEGAL_ABANDONMENT_CARGO_ITEMS_COLUMNS}
            data={dataArray}
            defaultPageSize={10}
            returnOnlySelectedItems
            keyField="id"
            loading={loading}
            form="LegalAbandonmentForm"
          />

          <FormFooter />
        </Form>
      ) : (
        <div className="alert alert-info">
          No hay parcelas para abandono legal
        </div>
      );

    if (loading && !data.length) table = <Loader />;
    return <Fragment>{table}</Fragment>;
  }
}

LegalAbandonmentForm.propTypes = {
  dispatchGetUnclaimedCargoItems: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: CargoUnit.LegalAbandonment.getIn(['all', 'loading']),
  data: CargoUnit.LegalAbandonment.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetUnclaimedCargoItems: getUnclaimedCargoItems,
};

const formComponent = reduxForm({
  form: 'LegalAbandonmentForm',
})(LegalAbandonmentForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
