import { string, shape, number } from 'prop-types';
import { registeredBusBasicInformationPropTypes } from '../../../bus/registered-bus/proptypes/RegisteredBusPropTypes';

const baseFuelRefillBasicInformationPropTypes = {
  id: number.isRequired,
  registeredBusId: number.isRequired,
  registeredBus: shape(registeredBusBasicInformationPropTypes).isRequired,
  createDate: number.isRequired,
  itineraryId: number,
  fuelQuantity: number,
  adblueQuantity: number,
  comment: string,
  fuelStationId: number,
};

const baseFuelRefillBasicInformationDefaultProps = {
  itineraryId: null,
  fuelQuantity: null,
  adblueQuantity: null,
  comment: null,
  fuelStationId: null,
};

export {
  baseFuelRefillBasicInformationPropTypes,
  baseFuelRefillBasicInformationDefaultProps,
};
