import { number, shape, string } from 'prop-types';

const paymentMethodPropTypes = shape({
  id: number,
  name: string,
  transactionCode: string,
  description: string,
  createDate: number,
  lastUpdate: number,
});

const securityProfilePropTypes = shape({
  id: number,
  name: string,
});

const paymentMethodForSecurityProfilePropTypes = shape({
  id: number,
  paymentMethod: paymentMethodPropTypes,
  securityProfile: securityProfilePropTypes,
}).isRequired;

export default paymentMethodForSecurityProfilePropTypes;
