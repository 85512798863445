import React, { Component, createElement } from 'react';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ButtonToolbar } from 'reactstrap';
import Table from '../../../common/Table';
import { COLOR } from '../../../../config/constants';
import { ITINERARY_SALES_CONTROL_COLUMNS } from '../../../../config/columns';
import { clearItinerarySalesControl } from '../../../../actions';
import ItinerarySalesControlDetailComponent from './ItinerarySalesControlDetailComponent';
import { ITINERARY_SALES_CONTROL_ENDPOINT } from '../../../../config/endpoints';
import Loader from '../../../common/Loader';

export class ItinerarySalesControlSearchResult extends Component {
  componentWillUnmount() {
    this.props.dispatchClearItinerarySalesControl();
  }

  generateStyle = (row) => {
    let background = '';

    if (row) {
      const {
        original: { occupancy },
      } = row;

      const formattedOccupancy = +occupancy.replace(',', '.');

      if (formattedOccupancy > 0 && formattedOccupancy < 50)
        background = COLOR.danger;
      if (formattedOccupancy >= 50 && formattedOccupancy <= 75)
        background = COLOR.warning;
      if (formattedOccupancy > 75) background = COLOR.success;
    }

    return {
      background,
    };
  };

  renderItinerarySalesControlDetailComponent = ({
    original: { id: itineraryId },
  }) => {
    const component = createElement(ItinerarySalesControlDetailComponent, {
      itineraryId,
    });

    return <div className="mt-3 mb-2 ml-5 mr-5">{component}</div>;
  };

  render() {
    const { loading, itineraries, searchFormValues, fetchData } = this.props;

    const data = itineraries.get('content') || [];
    const columns = ITINERARY_SALES_CONTROL_COLUMNS;
    const pages = itineraries.get('totalPages') || null;
    const defaultPageSize = itineraries.get('size') || 100;

    const href = `${ITINERARY_SALES_CONTROL_ENDPOINT}?${QueryString.stringify(
      searchFormValues,
    )}&download=true`;

    const toolbar = data.length > 0 && (
      <ButtonToolbar className="pull-right">
        <a className="btn btn-outline-primary" href={href}>
          <i className="fa fa-download" /> Descargar
        </a>
      </ButtonToolbar>
    );

    let table =
      data.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          fetchData={fetchData}
          rowComponent={this.renderItinerarySalesControlDetailComponent}
          params={searchFormValues}
          generateStyle={this.generateStyle}
        />
      ) : null;

    if (loading && !data.length) table = <Loader />;

    return (
      <div className="mt-3">
        {toolbar}
        <div className="flex">
          <h4>Resultado de Monitoreo de Ventas</h4>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: ItineraryUnit.ItinerarySalesControl.getIn(['all', 'loading']),
  itineraries: ItineraryUnit.ItinerarySalesControl.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchClearItinerarySalesControl: clearItinerarySalesControl,
};

ItinerarySalesControlSearchResult.propTypes = {
  itineraries: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearItinerarySalesControl: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    sourceLocationId: PropTypes.number,
    destinationLocationId: PropTypes.number,
    departureDayString: PropTypes.string,
    departureTimeString: PropTypes.string,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
};

ItinerarySalesControlSearchResult.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerarySalesControlSearchResult);
