import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import {
  FLAG_EXECUTION_REGISTRATION_ACTIVITY,
  CLEAR_EXECUTION_REGISTRATION,
  FLAG_GETTING_EXECUTION_REGISTRATIONS,
  GET_EXECUTION_REGISTRATIONS,
  CLEAR_EXECUTION_REGISTRATIONS,
  GET_EXECUTION_REGISTRATION,
} from '../types/mechanical-maintenance';
import { REGISTER_OF_EXECUTION_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  hadErrorInPromiseAllSettled,
  isErrorResponse,
} from '../../utils/error-handlers';
import {
  generateEditMaintenanceServiceOrderStep3,
  MAINTENANCE_SERVICE_ORDER_PATH,
} from '../../config/paths';

const flagExecutionRegistrationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXECUTION_REGISTRATION_ACTIVITY,
    payload: flag,
  });

const clearExecutionRegistration = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXECUTION_REGISTRATION,
  });

const createExecutionRegistration = async (activityBusExecutionList) => {
  const response = await fetch(REGISTER_OF_EXECUTION_ENDPOINT, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(activityBusExecutionList),
  });

  await isErrorResponse(response);

  return response.json();
};

const postExecutionRegistration =
  async (activityBusExecutionList) => async (dispatch) => {
    try {
      dispatch(flagExecutionRegistrationActivity(true));

      const response = await createExecutionRegistration(
        activityBusExecutionList,
      );

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagExecutionRegistrationActivity(false));
    }
  };

const flagGettingExecutionRegistrations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXECUTION_REGISTRATIONS,
    payload: flag,
  });

const getExecutionRegistrations = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingExecutionRegistrations(true));
    const url = `${REGISTER_OF_EXECUTION_ENDPOINT}?${QueryString.stringify(
      tableFilters,
    )}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_EXECUTION_REGISTRATIONS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingExecutionRegistrations(false));
  }
};

const clearExecutionRegistrations = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXECUTION_REGISTRATIONS,
  });

const postExecutionRegistrationFromServiceOrder =
  async (
    maintenanceServiceOrderId,
    originalActivityBusExecutionList,
    newActivityBusExecutionList,
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagExecutionRegistrationActivity(true));

      const { length: originalListLength } = originalActivityBusExecutionList;
      const { length: newListLength } = newActivityBusExecutionList;

      // When originalActivityBusExecutionList is empty then I register newActivityBusExecutionList as new registers
      if (originalListLength === 0 && newListLength > 0)
        await createExecutionRegistration(newActivityBusExecutionList);
      else if (originalListLength > 0 && newListLength === 0) {
        // Delete all activityBusExecutions
        const fetchList = originalActivityBusExecutionList.map(
          (activityBusExecution) =>
            fetch(
              `${REGISTER_OF_EXECUTION_ENDPOINT}/${activityBusExecution.id}`,
              {
                ...DEFAULT_DELETE_CONFIG,
              },
            ).then((res) => res.json()),
        );
        const response = await Promise.allSettled(fetchList);
        await hadErrorInPromiseAllSettled(response);
      } else if (originalListLength > 0 && newListLength > 0) {
        // When both arrays have data
        const activityBusExecutionToDelete = [];
        originalActivityBusExecutionList.forEach(
          (originalActivityBusExecution) => {
            // Find index
            const foundActivityBusIndex = newActivityBusExecutionList.findIndex(
              (newActivityBusExecution) =>
                originalActivityBusExecution.activityBusId ===
                newActivityBusExecution.activityBusId,
            );
            // Set activity bus execution Id to evitate duplicity
            if (foundActivityBusIndex >= 0) {
              // eslint-disable-next-line no-param-reassign
              newActivityBusExecutionList[foundActivityBusIndex].id =
                originalActivityBusExecution.id;
            }

            // Some activityBusExecution of originalActivityBusExecutionList was deleted
            if (
              !newActivityBusExecutionList.find(
                (newActivityBusExecution) =>
                  newActivityBusExecution.id ===
                  originalActivityBusExecution.id,
              )
            )
              activityBusExecutionToDelete.push(originalActivityBusExecution);
          },
        );

        if (activityBusExecutionToDelete.length > 0) {
          // Delete ActivityBusExecution
          const fetchList = activityBusExecutionToDelete.map(
            (activityBusExecution) =>
              fetch(
                `${REGISTER_OF_EXECUTION_ENDPOINT}/${activityBusExecution.id}`,
                {
                  ...DEFAULT_DELETE_CONFIG,
                },
              ).then((res) => res.json()),
          );
          const response = await Promise.allSettled(fetchList);
          await hadErrorInPromiseAllSettled(response);
        }

        // If newActivityBusExecutionList has new elements
        // Save only elements without id
        const activityBusExecutionToRegister =
          newActivityBusExecutionList.filter(
            (activityBusExecution) => !activityBusExecution.id,
          );
        if (activityBusExecutionToRegister.length > 0)
          createExecutionRegistration(activityBusExecutionToRegister);
      }

      dispatch(
        push(
          generateEditMaintenanceServiceOrderStep3(maintenanceServiceOrderId),
        ),
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExecutionRegistrationActivity(false));
    }
  };

const putActivityBusExecution =
  async (
    activityBusExecutionId,
    {
      activityBusId,
      comment,
      status,
      extensionFactor,
      startDate,
      endDate,
      workingHour,
      baseLocationId,
      employeeList,
      activityTaskExecutionList,
      serviceOrderId,
      createDate,
      userId,
      advanced,
      virtualOdometer,
    },
    isDispatch = false,
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagExecutionRegistrationActivity(true));

      const payload = {
        activityBusId,
        comment,
        status,
        extensionFactor,
        startDate,
        endDate,
        workingHour,
        baseLocationId,
        employeeList,
        activityTaskExecutionList,
        serviceOrderId,
        createDate,
        userId,
        advanced,
        virtualOdometer,
        id: activityBusExecutionId,
      };

      const url = `${REGISTER_OF_EXECUTION_ENDPOINT}/${activityBusExecutionId}`;

      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(promise);

      const response = await promise.json();

      if (isDispatch)
        dispatch(
          push(`${MAINTENANCE_SERVICE_ORDER_PATH}/${serviceOrderId}/execution`),
        );

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagExecutionRegistrationActivity(false));
    }
  };

const getActivityBusExecution =
  async ({ activityBusExecutionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagExecutionRegistrationActivity(true));
      const url = `${REGISTER_OF_EXECUTION_ENDPOINT}/${activityBusExecutionId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_EXECUTION_REGISTRATION,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExecutionRegistrationActivity(false));
    }
  };

export {
  flagExecutionRegistrationActivity,
  postExecutionRegistration,
  clearExecutionRegistration,
  getExecutionRegistrations,
  clearExecutionRegistrations,
  postExecutionRegistrationFromServiceOrder,
  putActivityBusExecution,
  getActivityBusExecution,
  createExecutionRegistration,
};
