import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../layout/Card';
import ModuleHome from '../../layout/ModuleHome';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

const BusHome = ({ cards, breadcrumbs }) => (
  <ModuleHome
    title="Gestión de Buses"
    text="Éste es el módulo de Gestión de Buses."
    cards={cards}
    breadcrumbs={breadcrumbs}
  />
);

const mapStateToProps = ({ BusUnit }) => ({
  cards: BusUnit.UnitHome.get('cards'),
  breadcrumbs: BusUnit.UnitHome.get('breadcrumbs'),
});

BusHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default connect(mapStateToProps)(BusHome);
