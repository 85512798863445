import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DISTRICT_PATH } from '../../../../config/paths';

const EditDistrictButton = ({ districtId }) => (
  <Link className="btn btn-primary" to={`${DISTRICT_PATH}/${districtId}/edit`}>
    Editar
  </Link>
);

EditDistrictButton.propTypes = {
  districtId: PropTypes.number.isRequired,
};

export default EditDistrictButton;
