import PropTypes from 'prop-types';
import { DATE_FORMAT } from '../../../config/locale';
import { tzNormalizeDate } from '../../../utils/date';

const DateCell = ({ value, format }) =>
  value ? tzNormalizeDate({ date: value, format }) : null;

DateCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};

DateCell.defaultProps = {
  value: null,
  format: DATE_FORMAT,
};

export default DateCell;
