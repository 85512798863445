import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  NEW_CARGO_CATEGORY_PATH,
  CARGO_CATEGORY_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CargoCategoryForm from './CargoCategoryForm';
import { postCargoCategory } from '../../../../actions/cargo';
import Loader from '../../../common/Loader';

// eslint-disable-next-line react/prefer-stateless-function
class NewCargoCategory extends Component {
  render() {
    const { breadcrumbs, loading, dispatchPostCargoCategory } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Crear Categoría</h1>
            <p>Crear Categoría en Cargo</p>
          </Col>
        </Row>
        <CargoCategoryForm onSubmit={dispatchPostCargoCategory} />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Categoría',
      href: CARGO_CATEGORY_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_CARGO_CATEGORY_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostCargoCategory: postCargoCategory,
};

NewCargoCategory.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCargoCategory: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewCargoCategory.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCargoCategory);
