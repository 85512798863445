import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getDeposit,
  clearDeposit,
  getDepositVoucher,
} from '../../../../actions/index';
import Loader from '../../../common/Loader';
import { DEPOSIT_PATH } from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import IsOpen from '../../../common/cells/IsOpen';
import DepositToolbar from './DepositToolbar';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import DepositDifferenceResource from '../deposit/resource/DepositDifferenceResource';
import DepositLiquidationsReource from './resource/DepositLiquitionsResource';
import LiquidationNoteResource from '../liquidation-session/resource/LiquidationNoteResource';
import { DEPOSIT_TYPE } from '../../../../config/constants';
import DepositVoucherResource from './resource/DepositVoucherResource';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import { tzNormalizeDate } from '../../../../utils/date';
import { numberFormatter } from '../../../../utils/number';

export class Deposit extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: depositId },
      },
      dispatchGetDeposit,
      dispatchGetDepositVoucher,
    } = this.props;
    dispatchGetDeposit({ depositId });
    dispatchGetDepositVoucher({ depositId });
  }

  componentWillUnmount() {
    this.props.dispatchClearDeposit();
  }

  render() {
    const { breadcrumbs, deposit, loading, voucher } = this.props;

    let content = null;
    let toolbar = null;

    if (loading || deposit.isEmpty()) content = <Loader />;
    else if (deposit.isEmpty())
      content = <NoDataResource returnPage={DEPOSIT_PATH} />;
    else {
      let expectedAmountToDeposit = 0;
      let agencyCommission = 0;
      let agencyCommissionTaxes = 0;

      deposit
        .get('dailyLiquidationSessionSet')
        .forEach(({ cashOnHand, commission, commissionTaxes }) => {
          expectedAmountToDeposit += cashOnHand;
          agencyCommission += commission;
          agencyCommissionTaxes += commissionTaxes;
        });

      let commissionResource = null;

      if (agencyCommission > 0) {
        commissionResource = (
          <>
            <ResourceProperty label="Comisión:">
              {numberFormatter({ value: agencyCommission })}
            </ResourceProperty>
            <ResourceProperty label="IGV:">
              {numberFormatter({ value: agencyCommissionTaxes })}
            </ResourceProperty>
            <ResourceProperty label="Total a Facturar:">
              {numberFormatter({
                value: agencyCommission + agencyCommissionTaxes,
              })}
            </ResourceProperty>
          </>
        );
      }

      content = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="Nombre del Banco:">
            {deposit.get('bankName')}
          </ResourceProperty>
          <ResourceProperty label="Número de Cuenta:">
            {deposit.get('accountNumber')}
          </ResourceProperty>
          <ResourceProperty label="Número de Operación:">
            {deposit.get('operationNumber')}
          </ResourceProperty>
          <DepositLiquidationsReource
            dailyLiquidationSessionSet={deposit.get(
              'dailyLiquidationSessionSet',
            )}
          />
          <ResourceProperty label="Tipo de Depósito:">
            {deposit.get('depositType') &&
            deposit.get('depositType') !== DEPOSIT_TYPE.DEFAULT.value
              ? DEPOSIT_TYPE[deposit.get('depositType')].label
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Fecha de Depósito:">
            {tzNormalizeDate({
              date: deposit.get('depositDate'),
              format: DATE_TIME_FORMAT,
            })}
          </ResourceProperty>
          {commissionResource}
          <ResourceProperty label="Monto Esperado para Depositar:">
            {numberFormatter({ value: expectedAmountToDeposit })}
          </ResourceProperty>
          <ResourceProperty label="Importe Real Depositado:">
            {numberFormatter({ value: deposit.get('actualAmountDeposited') })}
          </ResourceProperty>
          <DepositDifferenceResource
            depositDifferenceAmount={deposit.get('depositDifferenceAmount')}
            depositDifferenceReason={deposit.get('depositDifferenceReason')}
            depositDifferenceDescription={deposit.get(
              'depositDifferenceDescription',
            )}
          />
          <LiquidationNoteResource
            dailyLiquidationSessionNoteList={deposit.get('depositNoteList')}
          />
          <ResourceProperty label="Estado:">
            <IsOpen value={deposit.get('settled')} />
          </ResourceProperty>
          <DepositVoucherResource file={voucher} />
          <InternalResource
            id={deposit.get('id')}
            createDate={deposit.get('createDate')}
            lastUpdate={deposit.get('lastUpdate')}
          />
        </Fragment>
      );

      toolbar = <DepositToolbar depositId={deposit.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Depósito"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Depósitos', href: DEPOSIT_PATH },
    { text: 'Ver', href: '' },
  ],
  deposit: SalesUnit.Deposit.getIn(['current', 'content']),
  loading: !SalesUnit.Deposit.getIn(['current', 'activity']).isEmpty(),
  voucher: SalesUnit.Deposit.getIn(['current', 'voucher']),
});

const mapDispatchToProps = {
  dispatchGetDeposit: getDeposit,
  dispatchClearDeposit: clearDeposit,
  dispatchGetDepositVoucher: getDepositVoucher,
};

Deposit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  deposit: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetDeposit: PropTypes.func.isRequired,
  dispatchClearDeposit: PropTypes.func.isRequired,
  dispatchGetDepositVoucher: PropTypes.func.isRequired,
  voucher: PropTypes.instanceOf(Immutable.Set),
};

Deposit.defaultProps = {
  voucher: null,
};

Deposit.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
