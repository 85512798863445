import { tzNormalizeDate } from '../date';
import { COLOR } from '../../config/constants';
import {
  createPdfDocument,
  addText,
  paragraphSize,
  titleSize,
  subtitleSize,
  x,
  y,
  textSpacing,
  setFont,
} from '../pdf';
import { numberFormatter } from '../number';
import { DATE_FORMAT } from '../../config/locale';

const myX = x;
let myY = y;
const indentX = myX + 32;
const pageWidth = 90;
const pageHeigth = 70;
const halfPage = pageWidth / 2;
const quarterX = myX / 4;
const halfX = myX / 2;
const heightPerDriver = 25;

const printDocumentHeader = (doc, expense) => {
  doc.setFontSize(titleSize);
  setFont(doc, 'bold');
  addText(doc, 'SALIDA DE CAJA', halfPage, myY, pageWidth);

  doc.setFontSize(subtitleSize);
  addText(
    doc,
    `${expense.documentSeries}-${expense.documentCode
      .toString()
      .padStart(7, '0')}`,
    halfPage,
    (myY += 5),
    pageWidth,
  );
};

const printDocumentBody = (doc, expense) => {
  // Date
  doc.setFontSize(paragraphSize);
  addText(doc, myX, (myY += textSpacing * 2), 'Fecha:');
  setFont(doc);
  addText(
    doc,
    indentX,
    myY,
    tzNormalizeDate({ date: expense.createDate, format: DATE_FORMAT }),
  );

  // Driver
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Conductor:');
  setFont(doc);
  addText(doc, indentX, myY, expense.driverFullName);

  // Bus
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'N° Bus:');
  setFont(doc);
  addText(doc, indentX, myY, expense.companyBusId.toString());

  // Itinerary
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Itinerario:');
  setFont(doc);
  addText(doc, indentX, myY, expense.itinerarySourceAndDestination);

  // Expenses
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Peaje:');
  setFont(doc);
  addText(doc, indentX, myY, numberFormatter({ value: expense.tollPayment }));
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Condición de Trabajo:');
  setFont(doc);
  addText(
    doc,
    indentX,
    myY,
    numberFormatter({ value: expense.commissionPayment }),
  );

  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Otros Pagos:');
  setFont(doc);
  addText(doc, indentX, myY, numberFormatter({ value: expense.otherPayment }));

  // Total
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing * 2), 'Total a Pagar:');
  doc.setDrawColor(COLOR.black);
  doc.setFillColor(COLOR.white);
  doc.roundedRect(indentX, myY - 6, pageWidth - indentX - myX, 10, 2, 2, 'FD');
  doc.setFontSize(paragraphSize + 2);
  addText(
    doc,
    numberFormatter({ value: expense.totalPayment }),
    indentX + (pageWidth - indentX - myX) / 2,
    myY,
    halfPage,
  );
  setFont(doc);
};

const printDocumentFooter = (doc, drivers) => {
  doc.setFontSize(paragraphSize);

  addText(
    doc,
    'PAGADOR',
    halfPage + halfPage / 2 - halfX,
    (myY += textSpacing * 6),
    halfPage,
  );
  doc.line(halfPage + quarterX, myY - 4, pageWidth - myX, myY - 4);

  drivers.forEach((driver, index) => {
    addText(
      doc,
      `CONDUCTOR ${index + 1}`,
      halfPage / 2 + quarterX,
      (myY += index === 0 ? 0 : heightPerDriver),
      halfPage,
    );
    doc.line(myX, myY - 4, halfPage - quarterX, myY - 4);
  });
};

const printExpense = async (tripExpenseList, drivers) => {
  const doc = createPdfDocument('p', 'mm', [
    pageWidth,
    pageHeigth + drivers.length * heightPerDriver,
  ]);

  tripExpenseList.forEach((tripExpense, index) => {
    myY = 8;

    printDocumentHeader(doc, tripExpense);
    printDocumentBody(doc, tripExpense);
    printDocumentFooter(doc, drivers);

    if (tripExpenseList[index + 1]) doc.addPage();
  });

  doc.save(`Gasto-de-Viaje.pdf`);
};

// eslint-disable-next-line import/prefer-default-export
export { printExpense };
