import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearAgencies, getAgencies } from '../../../actions';

const AgencyFilter = ({
  agencies,
  onChangeFilter,
  loading,
  dispatchClearAgencies,
  dispatchGetAgencies,
}) => {
  useLayoutEffect(() => () => dispatchClearAgencies(), []);

  const handleInputChange = (inputValue) =>
    inputValue.trim().length &&
    inputValue.trim().length >= 4 &&
    dispatchGetAgencies({ query: inputValue });

  const agencyFilterOption = (options) => options;

  return (
    <SelectFilter
      isMulti
      isLoading={loading}
      onChangeFilter={onChangeFilter}
      onInputChange={handleInputChange}
      options={agencies}
      filterOption={agencyFilterOption}
      placeholder="Ingrese ubicación"
    />
  );
};

AgencyFilter.propTypes = {
  dispatchGetAgencies: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
  agencies: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

AgencyFilter.defaultProps = {
  agencies: [],
  loading: false,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  agencies: HumanResourcesUnit.Agency.getIn(['all', 'content', 'content']).map(
    (identificationType) => ({
      value: identificationType.id,
      label: identificationType.name,
    }),
  ),
  loading: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetAgencies: getAgencies,
  dispatchClearAgencies: clearAgencies,
};

export default connect(mapStateToProps, mapDispatchToprops)(AgencyFilter);
