import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import WorkstationEditButton from './WorkstationEditButton';
import WorkstationDeleteButton from './WorkstationDeleteButton';

const WorkstationToolbar = ({ workstationId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <WorkstationEditButton workstationId={workstationId} />{' '}
      <WorkstationDeleteButton workstationId={workstationId} />
    </div>
  </ButtonToolbar>
);

WorkstationToolbar.propTypes = {
  workstationId: PropTypes.number.isRequired,
};

export default WorkstationToolbar;
