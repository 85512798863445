import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ITINERARY_SEARCH_OMISSION_PATH } from '../../../../config/paths';

const EditItinerarySearchOmissionButton = ({ itinerarySearchOmissionId }) => (
  <Link
    className="btn btn-primary"
    to={`${ITINERARY_SEARCH_OMISSION_PATH}/${itinerarySearchOmissionId}/edit`}
    style={{ visibility: 'hidden' }}
  >
    Editar
  </Link>
);

EditItinerarySearchOmissionButton.propTypes = {
  itinerarySearchOmissionId: PropTypes.number.isRequired,
};

export default EditItinerarySearchOmissionButton;
