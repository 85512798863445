import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  FUEL_PROFILE_PATH,
  NEW_FUEL_PROFILE_PATH,
} from '../../../../config/paths';
import {
  getFuelProfiles,
  clearFuelProfiles,
} from '../../../../actions/traffic/FuelProfile';
import { FUEL_PROFILE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class FuelProfiles extends Component {
  constructor(props) {
    super(props);
    this.columns = FUEL_PROFILE_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearFuelProfiles();
  }

  render() {
    const { fuelProfiles, loading, breadcrumbs, dispatchGetFuelProfiles } =
      this.props;
    const data = fuelProfiles.get('content') || [];
    const pages = fuelProfiles.get('totalPages') || null;
    const defaultPageSize = fuelProfiles.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Rellenos Combustible por Circuito"
        buttonPath={NEW_FUEL_PROFILE_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetFuelProfiles,
          loading,
          modelPath: FUEL_PROFILE_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetFuelProfiles: getFuelProfiles,
  dispatchClearFuelProfiles: clearFuelProfiles,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos Combustible por Circuito',
      href: FUEL_PROFILE_PATH,
    },
  ],
  fuelProfiles: TrafficUnit.FuelProfile.getIn(['all', 'content']),
  loading: TrafficUnit.FuelProfile.getIn(['all', 'loading']),
});

FuelProfiles.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  fuelProfiles: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetFuelProfiles: PropTypes.func.isRequired,
  dispatchClearFuelProfiles: PropTypes.func.isRequired,
};

FuelProfiles.defaultProps = {
  fuelProfiles: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelProfiles);
