import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import {
  Button,
  ButtonGroup,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import MaintenanceServiceOrderBasicInformation from './resource/MaintenanceServiceOrderBasicInformationResource';
import { putActivityBusExecution } from '../../../../actions/mechanical-maintenance/ExecutionRegistration';
import {
  ACTIVITY_STATUS,
  ACTIVITY_TASK_EXECUTION_STATUS,
} from '../../../../config/constants';
import { tzDiff, tzNormalizeDate } from '../../../../utils/date';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
  putActivityTaskExecution,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import { MY_MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import { userBasicInformationPropTypes } from '../../user/user/proptypes/UserPropTypes';
import MaintenanceServiceOrderMaintenanceWarningBadgeListInformation from './resource/MaintenanceServiceOrderMaintenanceWarningBadgeListInformation';
import {
  clearCompanyAreaResponsable,
  getCompanyAreaResponsable,
} from '../../../../actions/mechanical-maintenance/CompanyAreaResponsable';

const MyMaintenanceServiceOrderForm = ({
  match: {
    params: { id: maintenanceServiceOrderId },
  },
  maintenanceServiceOrder,
  dispatchPutActivityBusExecution,
  dispacthGetMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder,
  loading,
  breadcrumbs,
  authenticatedUser,
  dispatchPutActivityTaskExecution,
  dispatchGetCompanyAreaResponsable,
  dispatchClearCompanyAreaResponsable,
  companyAreaResponsable,
  loadingCompanyAreaResponsable,
}) => {
  const [listGroupItems, setListGroupItems] = useState([]);
  const [loadingDispatch, setLoadingDispatch] = useState(false);

  useEffect(() => {
    dispacthGetMaintenanceServiceOrder({ maintenanceServiceOrderId });
    dispatchGetCompanyAreaResponsable();

    return () => {
      dispacthClearMaintenanceServiceOrder();
      dispatchClearCompanyAreaResponsable();
    };
  }, []);

  const generateListGroupItems = (
    activityBusExecutionList,
    customerId,
    companyAreaResponsableList,
  ) => {
    const isResponsable = !!companyAreaResponsableList.find(
      (area) => area.employee.customerId === authenticatedUser.customerId,
    );

    const newItems = [];

    activityBusExecutionList.forEach((activityBusExecution, activityIndex) => {
      if (isResponsable) {
        newItems.push({
          type: 'activity',
          name: activityBusExecution.activityBus.activity.name,
          startDate: activityBusExecution.startDate,
          endDate: activityBusExecution.endDate,
          workingHour: activityBusExecution.workingHour,
          status: activityBusExecution.status,
          activityBusExecution,
          activityTaskExecution: null,
          activityIndex,
        });
      } else {
        // Search if current employee has asociated activities
        const employeeHasActivity = activityBusExecution.employeeList.find(
          (employee) => employee.employee.customerId === customerId,
        );

        // Add to newItems
        if (employeeHasActivity)
          newItems.push({
            type: 'activity',
            name: activityBusExecution.activityBus.activity.name,
            startDate: activityBusExecution.startDate,
            endDate: activityBusExecution.endDate,
            workingHour: activityBusExecution.workingHour,
            status: activityBusExecution.status,
            activityBusExecution,
            activityTaskExecution: null,
            activityIndex,
          });
      }

      if (isResponsable) {
        activityBusExecution.activityTaskExecutionList.forEach(
          (asociatedActivityTask) => {
            // Verify if asociated activityTask is in activity activityTaskList of activity
            const employeeActivityTask =
              activityBusExecution.activityBus.activity.activityTaskList.find(
                (activityTask) =>
                  activityTask.id === asociatedActivityTask.activityTaskId,
              );

            if (employeeActivityTask)
              newItems.push({
                type: 'task',
                name: employeeActivityTask.description,
                startDate: asociatedActivityTask.startDate,
                endDate: asociatedActivityTask.endDate,
                workingHour: null,
                status: asociatedActivityTask.status,
                activityBusExecution,
                activityTaskExecution: asociatedActivityTask,
                activityIndex,
              });
          },
        );
      } else {
        // Search if current employee has asociated tasks
        const asociatedActivityTasks =
          activityBusExecution.activityTaskExecutionList.filter(
            (activityTaskExecution) =>
              activityTaskExecution.employee.customerId === customerId,
          );

        if (asociatedActivityTasks.length > 0) {
          asociatedActivityTasks.forEach((asociatedActivityTask) => {
            // Verify if asociated activityTask is in activity activityTaskList of activity
            const employeeActivityTask =
              activityBusExecution.activityBus.activity.activityTaskList.find(
                (activityTask) =>
                  activityTask.id === asociatedActivityTask.activityTaskId,
              );

            if (employeeActivityTask)
              newItems.push({
                type: 'task',
                name: employeeActivityTask.description,
                startDate: asociatedActivityTask.startDate,
                endDate: asociatedActivityTask.endDate,
                workingHour: null,
                status: asociatedActivityTask.status,
                activityBusExecution,
                activityTaskExecution: asociatedActivityTask,
                activityIndex,
              });
          });
        }
      }
    });

    return newItems;
  };

  useEffect(() => {
    if (
      listGroupItems.length === 0 &&
      !maintenanceServiceOrder.isEmpty() &&
      !companyAreaResponsable.isEmpty()
    ) {
      setListGroupItems(
        generateListGroupItems(
          maintenanceServiceOrder.toJS().activityBusExecutionList,
          authenticatedUser.customerId,
          companyAreaResponsable.toJS(),
        ),
      );
    }
  }, [maintenanceServiceOrder, companyAreaResponsable]);

  const onClickStart = async (
    type,
    index,
    activityBusExecution,
    activityTaskExecution,
    activityIndex,
  ) => {
    setLoadingDispatch(true);

    try {
      const newListGroupItems = [...listGroupItems];

      let response;

      const startDate = tzNormalizeDate();

      if (type === 'activity')
        response = await dispatchPutActivityBusExecution(
          activityBusExecution.id,
          {
            status: ACTIVITY_STATUS.IN_PROGRESS.value,
            startDate,
            activityBusId: activityBusExecution.activityBusId,
            comment: activityBusExecution.comment,
            extensionFactor: activityBusExecution.extensionFactor,
            endDate: activityBusExecution.endDate,
            workingHour: activityBusExecution.workingHour,
            baseLocationId: activityBusExecution.baseLocationId,
            employeeList: activityBusExecution.employeeList,
            activityTaskExecutionList:
              activityBusExecution.activityTaskExecutionList,
            serviceOrderId: activityBusExecution.serviceOrderId,
            createDate: activityBusExecution.createDate,
            userId: activityBusExecution.userId,
            advanced: activityBusExecution.advanced,
            virtualOdometer: activityBusExecution.virtualOdometer,
          },
        );
      else if (type === 'task')
        response = await dispatchPutActivityTaskExecution(
          activityTaskExecution.id,
          {
            startDate,
            status: ACTIVITY_TASK_EXECUTION_STATUS.IN_PROGRESS.value,
            endDate: activityTaskExecution.endDate,
            activityBusExecutionId:
              activityTaskExecution.activityBusExecutionId,
            employeeId: activityTaskExecution.employeeId,
            activityTaskId: activityTaskExecution.activityTaskId,
            workingHour: activityTaskExecution.workingHour,
            comment: activityTaskExecution.comment,
            createDate: activityTaskExecution.createDate,
            userId: activityTaskExecution.userId,
          },
        );

      if (response) {
        newListGroupItems[index] = {
          ...newListGroupItems[index],
          startDate,
          status:
            type === 'activity'
              ? ACTIVITY_STATUS.IN_PROGRESS.value
              : ACTIVITY_TASK_EXECUTION_STATUS.IN_PROGRESS.value,
        };
        setListGroupItems(newListGroupItems);

        if (type === 'task') {
          // Update activityBusExecution when activity hasn't start date
          const indexListGroupItem = newListGroupItems.findIndex(
            (listGroupItem) => listGroupItem.activityIndex === activityIndex,
          );

          const foundListGroupItem = newListGroupItems[indexListGroupItem];

          // Only change status when activity has status OPEN
          if (foundListGroupItem.status === ACTIVITY_STATUS.OPEN.value) {
            const response2 = await dispatchPutActivityBusExecution(
              foundListGroupItem.activityBusExecution.id,
              {
                status: ACTIVITY_STATUS.IN_PROGRESS.value,
                startDate,
                activityBusId:
                  foundListGroupItem.activityBusExecution.activityBusId,
                comment: foundListGroupItem.activityBusExecution.comment,
                extensionFactor:
                  foundListGroupItem.activityBusExecution.extensionFactor,
                endDate: foundListGroupItem.activityBusExecution.endDate,
                workingHour:
                  foundListGroupItem.activityBusExecution.workingHour,
                baseLocationId:
                  foundListGroupItem.activityBusExecution.baseLocationId,
                employeeList:
                  foundListGroupItem.activityBusExecution.employeeList,
                activityTaskExecutionList:
                  foundListGroupItem.activityBusExecution
                    .activityTaskExecutionList,
                serviceOrderId:
                  foundListGroupItem.activityBusExecution.serviceOrderId,
                createDate: foundListGroupItem.activityBusExecution.createDate,
                userId: foundListGroupItem.activityBusExecution.userId,
                advanced: foundListGroupItem.activityBusExecution.advanced,
                virtualOdometer:
                  foundListGroupItem.activityBusExecution.virtualOdometer,
              },
            );
            if (response2) {
              newListGroupItems[indexListGroupItem] = {
                ...newListGroupItems[indexListGroupItem],
                startDate,
                status: ACTIVITY_STATUS.IN_PROGRESS.value,
              };
              setListGroupItems(newListGroupItems);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingDispatch(false);
    }
  };

  const onClickEnd = async (
    type,
    index,
    activityBusExecution,
    activityTaskExecution,
    activityIndex,
  ) => {
    setLoadingDispatch(true);

    try {
      const newListGroupItems = [...listGroupItems];

      const { startDate } = listGroupItems[index];
      const endDate = tzNormalizeDate();
      const workingHour = tzDiff({ startDate, endDate, unit: 'hours' });

      let response;

      if (type === 'activity')
        response = await dispatchPutActivityBusExecution(
          activityBusExecution.id,
          {
            status: ACTIVITY_STATUS.DONE.value,
            endDate,
            workingHour,
            activityBusId: activityBusExecution.activityBusId,
            comment: activityBusExecution.comment,
            extensionFactor: activityBusExecution.extensionFactor,
            startDate,
            baseLocationId: activityBusExecution.baseLocationId,
            employeeList: activityBusExecution.employeeList,
            activityTaskExecutionList:
              activityBusExecution.activityTaskExecutionList,
            serviceOrderId: activityBusExecution.serviceOrderId,
            createDate: activityBusExecution.createDate,
            userId: activityBusExecution.userId,
            advanced: activityBusExecution.advanced,
            virtualOdometer: activityBusExecution.virtualOdometer,
          },
        );
      else if (type === 'task')
        response = await dispatchPutActivityTaskExecution(
          activityTaskExecution.id,
          {
            status: ACTIVITY_TASK_EXECUTION_STATUS.DONE.value,
            endDate,
            workingHour,
            startDate,
            activityBusExecutionId:
              activityTaskExecution.activityBusExecutionId,
            employeeId: activityTaskExecution.employeeId,
            activityTaskId: activityTaskExecution.activityTaskId,
            comment: activityTaskExecution.comment,
            createDate: activityTaskExecution.createDate,
            userId: activityTaskExecution.userId,
          },
        );

      if (response) {
        newListGroupItems[index] = {
          ...newListGroupItems[index],
          endDate,
          status:
            type === 'activity'
              ? ACTIVITY_STATUS.DONE.value
              : ACTIVITY_TASK_EXECUTION_STATUS.DONE.value,
        };
        setListGroupItems(newListGroupItems);

        if (type === 'task') {
          // Validate if all activity tasks are DONE
          const associatedActivityIndex = newListGroupItems.findIndex(
            (item) =>
              item.type === 'activity' &&
              item.activityIndex === activityIndex &&
              item.status !== ACTIVITY_STATUS.DONE.value,
          );

          if (associatedActivityIndex !== -1) {
            const associatedTasks = newListGroupItems.filter(
              (item) =>
                item.type === 'task' && item.activityIndex === activityIndex,
            );

            const allTasksDone = associatedTasks.every(
              (task) => task.status === 'DONE',
            );

            // Change activity status when all status tasks are DONE
            if (allTasksDone) {
              const foundListGroupItem =
                newListGroupItems[associatedActivityIndex];

              const activityStartDate =
                foundListGroupItem.activityBusExecution.startDate;
              const activityWorkingHour = tzDiff({
                startDate: activityStartDate,
                endDate,
                unit: 'hours',
              });

              const response2 = await dispatchPutActivityBusExecution(
                foundListGroupItem.activityBusExecution.id,
                {
                  status: ACTIVITY_STATUS.DONE.value,
                  endDate,
                  workingHour: activityWorkingHour,
                  activityBusId:
                    foundListGroupItem.activityBusExecution.activityBusId,
                  comment: foundListGroupItem.activityBusExecution.comment,
                  extensionFactor:
                    foundListGroupItem.activityBusExecution.extensionFactor,
                  startDate: activityStartDate,
                  baseLocationId:
                    foundListGroupItem.activityBusExecution.baseLocationId,
                  employeeList:
                    foundListGroupItem.activityBusExecution.employeeList,
                  activityTaskExecutionList:
                    foundListGroupItem.activityBusExecution
                      .activityTaskExecutionList,
                  serviceOrderId:
                    foundListGroupItem.activityBusExecution.serviceOrderId,
                  createDate:
                    foundListGroupItem.activityBusExecution.createDate,
                  userId: foundListGroupItem.activityBusExecution.userId,
                  advanced: foundListGroupItem.activityBusExecution.advanced,
                  virtualOdometer:
                    foundListGroupItem.activityBusExecution.virtualOdometer,
                },
              );
              if (response2) {
                newListGroupItems[associatedActivityIndex].status =
                  ACTIVITY_STATUS.DONE.value;
                newListGroupItems[associatedActivityIndex].endDate = endDate;
                setListGroupItems(newListGroupItems);
              }
            }
          }
        }

        // Validate if all elements are DONE
        const allDone = newListGroupItems.every(
          (item) => item.status === ACTIVITY_STATUS.DONE.value,
        );

        if (allDone) {
          // When all elements will be DONE change status to service order
          console.log('All elements are DONE');
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingDispatch(false);
    }
  };

  let content;

  if (loading || loadingCompanyAreaResponsable) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty())
    content = <NoDataResource returnPage={MY_MAINTENANCE_SERVICE_ORDER_PATH} />;
  else {
    const {
      registeredBus,
      deadline,
      priority,
      description,
      status,
      maintenanceWarningList,
    } = maintenanceServiceOrder.toJS();

    const badgeListComponent = (
      <MaintenanceServiceOrderMaintenanceWarningBadgeListInformation
        maintenanceWarningList={maintenanceWarningList}
      />
    );

    content = (
      <>
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Datos de la Orden de Servicio</h3>
            <MaintenanceServiceOrderBasicInformation
              deadline={deadline}
              priority={priority}
              description={description}
              status={status}
              registeredBus={registeredBus}
            />
          </Col>
          <Col lg={6}>{badgeListComponent}</Col>
        </Row>
        <h3>Lista de Actividades</h3>
        <ListGroup>
          {listGroupItems.map(
            (
              {
                status: itemStatus,
                type,
                activityBusExecution,
                activityTaskExecution,
                name,
                activityIndex,
              },
              index,
            ) => {
              const disableStartButton =
                (type === 'task' &&
                  (itemStatus ===
                    ACTIVITY_TASK_EXECUTION_STATUS.IN_PROGRESS.value ||
                    itemStatus ===
                      ACTIVITY_TASK_EXECUTION_STATUS.DONE.value)) ||
                (type === 'activity' &&
                  (itemStatus === ACTIVITY_STATUS.IN_PROGRESS.value ||
                    itemStatus === ACTIVITY_STATUS.DONE.value));

              const disabledEndButton =
                (type === 'task' &&
                  itemStatus !==
                    ACTIVITY_TASK_EXECUTION_STATUS.IN_PROGRESS.value) ||
                (type === 'activity' &&
                  itemStatus !== ACTIVITY_STATUS.IN_PROGRESS.value);

              return (
                <ListGroupItem key={+index}>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className={`${type === 'task' && 'pl-4'}`}>
                      {name}
                    </span>
                    <ButtonGroup>
                      <Button
                        className="border-success"
                        color="light"
                        type="button"
                        size="lg"
                        disabled={disableStartButton || loadingDispatch}
                        onClick={() =>
                          onClickStart(
                            type,
                            index,
                            activityBusExecution,
                            activityTaskExecution,
                            activityIndex,
                          )
                        }
                      >
                        <i className="fa fa-play text-success" />
                      </Button>
                      <Button
                        className="border-danger"
                        color="light"
                        type="button"
                        size="lg"
                        disabled={disabledEndButton || loadingDispatch}
                        onClick={() =>
                          onClickEnd(
                            type,
                            index,
                            activityBusExecution,
                            activityTaskExecution,
                            activityIndex,
                          )
                        }
                      >
                        <i className="fa fa-stop text-danger" />
                      </Button>
                    </ButtonGroup>
                  </div>
                </ListGroupItem>
              );
            },
          )}
        </ListGroup>
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Mi Orden de Servicio"
      subtitle="Mi orden de servicio"
      content={content}
    />
  );
};

MyMaintenanceServiceOrderForm.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispacthGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispatchPutActivityBusExecution: PropTypes.func.isRequired,
  dispatchPutActivityTaskExecution: PropTypes.func.isRequired,
  dispacthClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.shape(userBasicInformationPropTypes).isRequired,
  loading: PropTypes.bool.isRequired,
  match: matchPropTypes.isRequired,
  dispatchGetCompanyAreaResponsable: PropTypes.func.isRequired,
  dispatchClearCompanyAreaResponsable: PropTypes.func.isRequired,
  companyAreaResponsable: PropTypes.instanceOf(Immutable.Set).isRequired,
  loadingCompanyAreaResponsable: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit, authentication }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Mis Ordenes de Servicio',
      href: MY_MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  authenticatedUser: authentication.getIn(['user']),
  companyAreaResponsable:
    MechanicalMaintenanceUnit.CompanyAreaResponsable.getIn(['all', 'content']),
  loadingCompanyAreaResponsable:
    MechanicalMaintenanceUnit.CompanyAreaResponsable.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispacthGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispatchPutActivityBusExecution: putActivityBusExecution,
  dispacthClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
  dispatchPutActivityTaskExecution: putActivityTaskExecution,
  dispatchGetCompanyAreaResponsable: getCompanyAreaResponsable,
  dispatchClearCompanyAreaResponsable: clearCompanyAreaResponsable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyMaintenanceServiceOrderForm);
