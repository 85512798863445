import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  postAccountingReport,
  clearErrorPostingAccountingReport,
} from '../../../../actions';
import {
  ACCOUNTING_REPORTS_PATH,
  NEW_ACCOUNTING_REPORT_PATH,
} from '../../../../config/paths';
import AccountingReportForm from './AccountingReportForm';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';

class NewAccountingReport extends Component {
  componentWillUnmount() {
    this.props.clearErrorPostingAccountingReport();
  }
  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte de Contabilidad</h1>
            <p id="text">Crear un nuevo reporte de Contabilidad.</p>
          </Col>
        </Row>
        <AccountingReportForm
          onSubmit={this.props.postAccountingReport}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            force: false,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Contabilidad',
      href: ACCOUNTING_REPORTS_PATH,
    },
    {
      text: 'Crear',
      href: NEW_ACCOUNTING_REPORT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  postAccountingReport,
  clearErrorPostingAccountingReport,
};

NewAccountingReport.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  postAccountingReport: PropTypes.func.isRequired,
  clearErrorPostingAccountingReport: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewAccountingReport);
