import Immutable from 'immutable';
import {
  FLAG_REGISTERED_BUS_ACTIVITY,
  FLAG_GETTING_REGISTERED_BUSES,
  GET_REGISTERED_BUS,
  GET_REGISTERED_BUSES,
  CLEAR_REGISTERED_BUSES,
  CLEAR_REGISTERED_BUS,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    loading: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_REGISTERED_BUSES:
      return state.setIn(['all', 'loading'], payload);
    case GET_REGISTERED_BUSES:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_REGISTERED_BUSES:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case GET_REGISTERED_BUS:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case FLAG_REGISTERED_BUS_ACTIVITY:
      // raising flag
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      // lowering flag
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case CLEAR_REGISTERED_BUS:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
