import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BASE_FUEL_REFILL_PATH } from '../../../../config/paths';
import { postBaseFuelRefill } from '../../../../actions/traffic';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import BaseFuelRefillForm from './BaseFuelRefillForm';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const NewBaseFuelRefill = ({ breadcrumbs, dispatchPostBaseFuelRefill }) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.registeredBusId =
      formValues.registeredBusId && formValues.registeredBusId.value;
    newFormValues.createDate = tzNormalizeDate({
      date: formValues.createDate,
      format: TIMESTAMP_FORMAT,
    });
    dispatchPostBaseFuelRefill(newFormValues);
  };

  const now = tzNormalizeDate();
  const content = (
    <BaseFuelRefillForm
      onSubmit={onSubmit}
      initialValues={{ createDate: now }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Relleno de Combustible en Base"
      subtitle="Crear nuevo relleno de combustible en base"
      content={content}
    />
  );
};

NewBaseFuelRefill.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostBaseFuelRefill: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostBaseFuelRefill: postBaseFuelRefill,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos de Combustible en Base',
      href: BASE_FUEL_REFILL_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewBaseFuelRefill);
