import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DISTRICT_PATH } from '../../../../config/paths';
import { postDistrict } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import DistrictForm from './DistrictForm';
import Content from '../../../layout/Content';

export class NewDistrict extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostDistrict } = this.props;
    const newFormValues = {
      cityId: formValues.cityId.value,
      districts: formValues.districts.map((district) => ({
        name: district.name,
        ubigeo: district.ubigeo,
      })),
    };
    dispatchPostDistrict(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Distrito"
        subtitle="Puedes crear uno o varios distritos"
        content={<DistrictForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Distrito',
      href: DISTRICT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostDistrict: postDistrict,
};

NewDistrict.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDistrict: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDistrict);
