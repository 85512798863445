import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { EXPENSE_PATH } from '../../../../config/paths';

const EditExpenseButton = ({ expenseId, isTripExpense }) => {
  if (isTripExpense) {
    return null;
  }

  return (
    <Link className="btn btn-primary" to={`${EXPENSE_PATH}/${expenseId}/edit`}>
      Editar
    </Link>
  );
};

EditExpenseButton.propTypes = {
  expenseId: PropTypes.number.isRequired,
  isTripExpense: PropTypes.bool.isRequired,
};

export default connect(null, null)(EditExpenseButton);
