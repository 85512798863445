import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { clearCompanies, getCompanies } from '../../../../actions';
import { COMPANY_PATH, NEW_COMPANY_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { COMPANY_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Companies extends Component {
  componentWillUnmount() {
    this.props.dispatchClearCompanies();
  }

  render() {
    const { companies, loading, dispatchGetCompanies, breadcrumbs } =
      this.props;
    const data = companies.get('content') || [];
    const pages = companies.get('totalPages') || null;
    const defaultPageSize = companies.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Empresas Terceras"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_COMPANY_PATH}
        tableStructure={{
          columns: COMPANY_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetCompanies,
          modelPath: COMPANY_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetCompanies: getCompanies,
  dispatchClearCompanies: clearCompanies,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas Terceras',
      href: COMPANY_PATH,
    },
  ],
  companies: ContractUnit.Company.getIn(['all', 'content']),
  loading: ContractUnit.Company.getIn(['all', 'loading']),
});

Companies.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  companies: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCompanies: PropTypes.func.isRequired,
  dispatchClearCompanies: PropTypes.func.isRequired,
};

Companies.defaultProps = {
  companies: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
