import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Button, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import DatePicker from '../../../common/forms/input/DatePicker';
import { isRequired } from '../../../../utils/validators';
import Alert from '../../../common/informative/Alert';
import ServiceTypeSelect from '../../../common/forms/select/ServiceTypeSelect';
import RouteSelect from '../../../common/forms/select/RouteSelect';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import DriverSelect from '../../../common/forms/select/DriverSelect';

const ItineraryReportForm = ({
  handleSubmit,
  submitting,
  errorMessage,
  error,
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>
          Fecha de Inicio <span className="text-danger">*</span>
        </Label>
      </Col>
      <Col sm={9} xs={12}>
        <Field name="fromDate" component={DatePicker} validate={[isRequired]} />
      </Col>
    </FormGroup>

    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>
          Fecha de Fin <span className="text-danger">*</span>
        </Label>
      </Col>
      <Col sm={9} xs={12}>
        <Field name="toDate" component={DatePicker} validate={[isRequired]} />
      </Col>
    </FormGroup>

    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>Bus</Label>
      </Col>
      <Col sm={9} xs={12}>
        <Field name="bus" component={RegisteredBusSelect} />
      </Col>
    </FormGroup>

    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>Tipo de Servicio</Label>
      </Col>
      <Col sm={9} xs={12}>
        <Field name="serviceType" component={ServiceTypeSelect} />
      </Col>
    </FormGroup>

    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>Conductor</Label>
      </Col>
      <Col sm={9} xs={12}>
        <Field name="driver" component={DriverSelect} />
      </Col>
    </FormGroup>

    <FormGroup row>
      <Col sm={3} xs={12}>
        <Label>Ruta</Label>
      </Col>
      <Col sm={9} xs={12}>
        <Field name="route" component={RouteSelect} />
      </Col>
    </FormGroup>

    <FormGroup row>
      <Col xs={12}>
        <Label>
          <strong>Opciones</strong>
        </Label>
      </Col>
      <Col xs={12}>
        <Field name="force" component="input" type="checkbox" />
        &nbsp; Forzar la regeneración del reporte. Sólo aplica si ya existe un
        reporte para las fechas indicadas.
      </Col>
    </FormGroup>
    <Alert message={errorMessage} />
    <Alert message={error} />
    <div className="FormButtonGroup">
      <Button type="submit" disabled={submitting} color="primary" outline>
        {submitting ? <i className="fa fa-spinner fa-spin" /> : 'Crear'}
      </Button>
    </div>
  </Form>
);

const mapStateToProps = (state) => ({
  // this component uses registered buses in a
  // dropdown so map out the required fields
  registeredBuses: state.BusUnit.RegisteredBus.get('all')
    .get('content')
    .get('content')
    .map((registeredBus) => ({
      value: registeredBus.id,
      label: registeredBus.companyBusId,
    })),
  // this component uses drivers in a
  // dropdown so map out the required fields
  drivers: state.TrafficUnit.Driver.get('all')
    .get('content')
    .get('content')
    .map((driver) => ({
      value: driver.id,
      label: driver.customer.fullName,
    })),
  errorMessage: state.ReportUnit.ItineraryReport.get('form').get('error'),
});

ItineraryReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.string,
};

ItineraryReportForm.defaultProps = {
  errorMessage: null,
  error: null,
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'ItineraryReportForm',
  })(ItineraryReportForm),
);
