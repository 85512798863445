import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getCompany, clearCompany } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import CompanyToolbar from './CompanyToolbar';
import { COMPANY_PATH } from '../../../../config/paths';
import { COMPANY_TYPE } from '../../../../config/constants';
import Content from '../../../layout/Content';

class Company extends Component {
  componentDidMount() {
    const {
      dispatchGetCompany,
      match: {
        params: { id: companyId },
      },
    } = this.props;
    dispatchGetCompany({ companyId });
  }

  componentWillUnmount() {
    const { dispatchClearCompany } = this.props;
    dispatchClearCompany();
  }

  render() {
    const { breadcrumbs, company, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (company.isEmpty()) {
      content = <NoDataResource returnPage={COMPANY_PATH} />;
    } else {
      content = (
        <Fragment>
          <ResourceProperty label="Nombre:">
            {company.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Empresa Relacionada:">
            {company.get('business') ? company.get('business').name : '-'}
          </ResourceProperty>
          <ResourceProperty label="Tipo de Empresa Relacionada:">
            {COMPANY_TYPE[company.get('companyType')].label}
          </ResourceProperty>
        </Fragment>
      );

      toolbar = <CompanyToolbar companyId={company.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Empresa Tercera"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

Company.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCompany: PropTypes.func.isRequired,
  dispatchClearCompany: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  company: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Company.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas Terceras',
      href: COMPANY_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  company: ContractUnit.Company.getIn(['current', 'content']),
  loading: !ContractUnit.Company.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCompany: getCompany,
  dispatchClearCompany: clearCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
