import Immutable from 'immutable';
import { FETCH_CITIES, FLAG_FETCHING_CITIES } from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  allCities: [],
  fetchingCities: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CITIES:
      // todo: handle pagination
      return state.set('allCities', action.payload);
    case FLAG_FETCHING_CITIES:
      return state.set('fetchingCities', action.payload);
    default:
      return state;
  }
};
