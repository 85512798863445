import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ checked }) => (
  <i className={`fa fa-${checked ? 'check-' : ''}square-o`} />
);
Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default Checkbox;
