import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import LegalAbandonmentForm from './LegalAbandonmentForm';
import {
  getUnclaimedCargoItems,
  putStatusToDepured,
} from '../../../../actions/cargo/LegalAbandonment';

const LegalAbandonment = ({
  breadcrumbs,
  dispatchPutStatusToDepured,
  dispatchGetUnclaimedCargoItems,
}) => {
  const onSubmitLegalAbandonment = (formValues) => {
    const LegalAbandonmentRequest = formValues.items;

    dispatchPutStatusToDepured(LegalAbandonmentRequest).then(() => {
      dispatchGetUnclaimedCargoItems();
    });
  };

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Abandono Legal"
      subtitle="Items listo para Depurar"
      content={<LegalAbandonmentForm onSubmit={onSubmitLegalAbandonment} />}
    />
  );
};

LegalAbandonment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutStatusToDepured: PropTypes.func.isRequired,
  dispatchGetUnclaimedCargoItems: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Abandono Legal',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPutStatusToDepured: putStatusToDepured,
  dispatchGetUnclaimedCargoItems: getUnclaimedCargoItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalAbandonment);
