import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import EditButton from '../../../common/button/EditButton';
import { MAINTENANCE_WARNING_PATH } from '../../../../config/paths';
import ChangeStatusMaintenanceWarningButton from './MaintenanceWarningRejectedButton';
import { MAINTENANCE_WARNING_STATUS } from '../../../../config/constants';

const MaintenanceWarningToolbar = ({ maintenanceWarningId, status }) =>
  status !== MAINTENANCE_WARNING_STATUS.REJECTED.value && (
    <ButtonToolbar className="pull-right">
      <div>
        <ChangeStatusMaintenanceWarningButton
          maintenanceWarningId={maintenanceWarningId}
          action={MAINTENANCE_WARNING_STATUS.REJECTED.value}
        />{' '}
        <EditButton
          path={`${MAINTENANCE_WARNING_PATH}/${maintenanceWarningId}/edit`}
        />
      </div>
    </ButtonToolbar>
  );

MaintenanceWarningToolbar.propTypes = {
  maintenanceWarningId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default MaintenanceWarningToolbar;
