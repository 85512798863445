import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditFuelProfileButton from './EditFuelProfileButton';
import SwitchStateFuelProfileButton from './SwitchStateFuelProfileButton';

const FuelProfileToolbar = ({
  fuelProfileId,
  switchIsActive,
  onChangeState,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <SwitchStateFuelProfileButton
        fuelProfileId={fuelProfileId}
        isActive={switchIsActive}
        onChangeState={onChangeState}
      />
      <EditFuelProfileButton fuelProfileId={fuelProfileId} />
    </div>
  </ButtonToolbar>
);

FuelProfileToolbar.propTypes = {
  fuelProfileId: PropTypes.number.isRequired,
  switchIsActive: PropTypes.bool.isRequired,
  onChangeState: PropTypes.func.isRequired,
};

export default FuelProfileToolbar;
