import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { EXPENSE_TYPE_PATH } from '../../../../config/paths';
import { deleteExpenseType } from './fetch';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class DeleteExpenseTypeButton extends Component {
  state = {
    showWarningModal: false,
  };

  showDeleteModal = () => this.setState({ showWarningModal: true });

  closeDeleteModal = () => this.setState({ showWarningModal: false });

  handleDelete = async () => {
    const { dispatchPush, expenseTypeId } = this.props;
    // delete
    const result = await deleteExpenseType({ id: expenseTypeId });
    if (result) {
      dispatchPush(EXPENSE_TYPE_PATH);
    }
  };

  render() {
    const { showWarningModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        onClickConfirm={this.handleDelete}
        onClickCancel={this.closeDeleteModal}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.showDeleteModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DeleteExpenseTypeButton.propTypes = {
  expenseTypeId: PropTypes.number.isRequired,
  dispatchPush: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPush: push,
};

export default connect(null, mapDispatchToProps)(DeleteExpenseTypeButton);
