import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Form, FormGroup, Container, InputGroupText, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import { getActiveSalesSession } from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import Loader from '../../../common/Loader';
import { NEW_CREDIT_NOTE_PATH } from '../../../../config/paths';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import { CURRENCY } from '../../../../config/locale';
import DatePicker from '../../../common/forms/input/DatePicker';
import FormFooter from '../../../common/forms/FormFooter';

export class CreditNoteForm extends Component {
  state = {
    disableAmount: false,
  };

  componentDidMount() {
    this.onDidMount();
  }

  onDidMount = () => {
    const { dispatchGetActiveSalesSession, initialValues } = this.props;

    dispatchGetActiveSalesSession({
      source: NEW_CREDIT_NOTE_PATH,
    });

    if (initialValues && initialValues.amount)
      this.setState({ disableAmount: true });
  };

  render() {
    const {
      gettingActiveSalesSession,
      loading,
      activeSalesSession,
      handleSubmit,
    } = this.props;

    if (gettingActiveSalesSession || loading) return <Loader />;

    if (activeSalesSession.isEmpty())
      return (
        <Container>
          <Alert message="No hay ninguna sesión de ventas activa." />
        </Container>
      );

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <CustomerInputGroup
            label="Cliente"
            labelRequired
            name="customerId"
            form="CreditNoteForm"
            validate={[isRequired]}
            showDetails
          />
          <FormGroup row>
            <FormItem label="Referencia">
              <Field
                name="reference"
                component={TextInput}
                type="text"
                placeholder="Referencia"
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Descripción">
              <Field
                name="description"
                component={TextInput}
                type="textarea"
                placeholder="Descripción"
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Monto" required>
              <Field
                name="amount"
                component={TextInput}
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="0.00"
                validate={[isRequired, validateNumber]}
                disabled={this.state.disableAmount}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem>
              <Label>
                <Field name="isValid" component="input" type="checkbox" />{' '}
                Válido
              </Label>
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Fecha de Expiración" required>
              <Field
                name="expirationDate"
                component={DatePicker}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormFooter />
        </Form>
      </>
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  gettingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  loading: !SalesUnit.CreditNote.getIn(['current', 'activity']).isEmpty(),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
});

const mapDispatchToProps = {
  dispatchGetActiveSalesSession: getActiveSalesSession,
};

CreditNoteForm.propTypes = {
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

CreditNoteForm.defaultProps = {
  initialValues: null,
};

const formComponent = reduxForm({
  form: 'CreditNoteForm',
})(CreditNoteForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
