import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import Loader from '../../../common/Loader';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateEmail,
  validateLength,
  validateNumber,
  validateRuc,
} from '../../../../utils/validators';
import validate from '../customer/validate';
import AddressInputGroup from '../../../common/forms/AddressInputGroup';
import FormFooter from '../../../common/forms/FormFooter';

const validateLength11 = validateLength(11, 'dígitos');

const BusinessForm = ({
  handleSubmit,
  loading,
  onModal,
  mobilePhoneRequired,
  showAddressSummary,
}) => {
  if (loading) return <Loader />;

  let onSubmitForForm = handleSubmit;
  let buttonType = 'submit';
  let onClickForButton = null;

  if (onModal) {
    onSubmitForForm = null;
    buttonType = 'button';
    onClickForButton = handleSubmit;
  }

  const validateMobilePhone = [];

  if (mobilePhoneRequired) validateMobilePhone.push(isRequired);

  const addressSummaryField = showAddressSummary && (
    <FormGroup row>
      <FormItem label="Dirección Resumida" required>
        <Field
          name="addressSummary"
          component={TextInput}
          type="text"
          placeholder="Dirección Resumida"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
  );

  return (
    <Form onSubmit={onSubmitForForm}>
      <h3>Información Básica</h3>
      <FormGroup row>
        <FormItem label="RUC" required>
          <Field
            name="businessTaxId"
            component={TextInput}
            placeholder="Número de RUC"
            validate={[
              isRequired,
              validateNumber,
              validateLength11,
              validateRuc,
            ]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Razón Social" required>
          <Field
            name="name"
            component={TextInput}
            placeholder="Razón Social"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <h3>Información de Contacto</h3>
      <FormGroup row>
        <FormItem label="Contacto Principal">
          <Field
            name="primaryContact"
            component={TextInput}
            placeholder="Contacto Principal"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Email" required>
          <Field
            name="email"
            component={TextInput}
            placeholder="ejemplo@gmail.com"
            validate={[isRequired, validateEmail]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Celular">
          <Field name="phone" component={TextInput} placeholder="Celular" />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fax">
          <Field name="fax" component={TextInput} placeholder="Fax" />
        </FormItem>
      </FormGroup>
      <h3>Dirección</h3>
      {addressSummaryField}
      <AddressInputGroup
        label="Dirección"
        name="addressId"
        form="BusinessForm"
        required
        validate={[isRequired]}
      />
      <FormFooter
        saveButtonType={buttonType}
        saveButtonAction={onClickForButton}
      />
    </Form>
  );
};

BusinessForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onModal: PropTypes.bool,
  mobilePhoneRequired: PropTypes.bool,
  showAddressSummary: PropTypes.bool,
};

BusinessForm.defaultProps = {
  loading: false,
  onModal: false,
  mobilePhoneRequired: false,
  showAddressSummary: false,
};

const mapStateToProps = ({ UserUnit }) => ({
  loading: !UserUnit.Business.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'BusinessForm',
  validate,
})(BusinessForm);

export default connect(mapStateToProps)(formComponent);
