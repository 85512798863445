const filterMethodCaseInsensitive = (filter, row) => {
  const { id, value } = filter;

  let rowValue = row[id];

  if (rowValue !== null) {
    if (typeof rowValue === 'number') rowValue = rowValue.toString();

    // eslint-disable-next-line no-nested-ternary
    return value
      ? value.trim()
        ? String(rowValue.toLowerCase()).includes(value.trim().toLowerCase())
        : true
      : true;
  }

  return false;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  filterMethodCaseInsensitive,
};
