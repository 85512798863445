import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postKidsOnItinerary } from '../../../../actions/itinerary';
import KidsOnItineraryForm from './KidsOnItineraryForm';
import Content from '../../../layout/Content';
import { getSubstring } from '../../../../utils/string';

export class KidsOnItinerary extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      itineraryId: getSubstring(formValues.itineraryId),
      floorNumber: formValues.floorNumber,
      seatNumber: formValues.seatNumber,
      kidCustomerId: formValues.kidCustomerId.value,
      parentCustomerId: formValues.parentCustomerId.value,
      relationship: formValues.relationship.value,
      comments: formValues.comments || null,
    };

    this.props.dispatchPostKidsOnItinerary(newFormValues, true);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Asignar Menor de Edad a Itinerario"
        subtitle="Puede agregar o quitar uno o varios menores de edad a un itinerario para luego ser mostrado(s) en el manifiesto"
        content={<KidsOnItineraryForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignar Menor de Edad a Itinerario',
      href: NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostKidsOnItinerary: postKidsOnItinerary,
};

KidsOnItinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostKidsOnItinerary: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(KidsOnItinerary);
