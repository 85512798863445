export const FLAG_GETTING_MANIFEST_ITEMS = 'FLAG_GETTING_MANIFEST_ITEMS';
export const GET_MANIFEST_ITEMS = 'GET_MANIFEST_ITEMS';
export const CLEAR_MANIFEST_ITEMS = 'CLEAR_MANIFEST_ITEMS';
export const UPDATE_MANIFEST_ITEMS = 'UPDATE_MANIFEST_ITEMS';
export const DELETE_MANIFEST_ITEMS = 'DELETE_MANIFEST_ITEMS';
export const FLAG_MANIFEST_ITEMS_ACTIVITY = 'FLAG_MANIFEST_ITEMS_ACTIVITY';
export const GET_MANIFEST_ITEMS_BY_PARCEL = 'GET_MANIFEST_ITEMS_BY_PARCEL';
export const CLEAR_MANIFEST_ITEMS_BY_PARCEL = 'CLEAR_MANIFEST_ITEMS_BY_PARCEL';
export const UPDATE_MANIFEST_ITEMS_BY_PARCEL =
  'UPDATE_MANIFEST_ITEMS_BY_PARCEL';
