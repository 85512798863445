// post
export const FLAG_POSTING_ACCOUNTING_REPORT = 'FLAG_POSTING_ACCOUNTING_TICKET_REPORT';
export const SHOW_ERROR_POSTING_ACCOUNTING_REPORT = 'SHOW_ERROR_POSTING_ACCOUNTING_REPORT';
export const CLEAR_ERROR_POSTING_ACCOUNTING_REPORT = 'CLEAR_ERROR_POSTING_ACCOUNTING_REPORT';
export const POST_ACCOUNTING_REPORT = 'POST_ACCOUNTING_REPORT';
// get
export const FLAG_GETTING_ACCOUNTING_REPORTS = 'FLAG_GETTING_ACCOUNTING_REPORTS';
export const SHOW_ERROR_GETTING_ACCOUNTING_REPORTS = 'SHOW_ERROR_GETTING_ACCOUNTING_REPORTS';
export const CLEAR_ERROR_GETTING_ACCOUNTING_REPORTS = 'CLEAR_ERROR_GETTING_ACCOUNTING_REPORTS';
export const GET_ACCOUNTING_REPORTS = 'GET_ACCOUNTING_REPORTS';
export const CLEAR_ACCOUNTING_REPORTS = 'CLEAR_ACCOUNTING_REPORTS';
// getting one
export const FLAG_GETTING_ACCOUNTING_REPORT = 'FLAG_GETTING_ACCOUNTING_REPORT';
export const SHOW_ERROR_GETTING_ACCOUNTING_REPORT = 'SHOW_ERROR_GETTING_ACCOUNTING_REPORT';
export const CLEAR_ERROR_GETTING_ACCOUNTING_REPORT = 'CLEAR_ERROR_GETTING_ACCOUNTING_REPORT';
export const GET_ACCOUNTING_REPORT = 'GET_ACCOUNTING_REPORT';
export const CLEAR_ACCOUNTING_REPORT = 'CLEAR_ACCOUNTING_REPORT';
// downloading
export const DOWNLOAD_ACCOUNTING_REPORT = 'DOWNLOAD_ACCOUNTING_REPORT';
