import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import Table from '../../../../common/Table';
import { CONTRACT_MOVEMENT_TYPE } from '../../../../../config/constants';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import {
  privateServiceItineraryDataDefaultProps,
  privateServiceItineraryDataPropTypes,
} from '../proptypes/PrivateServicePropTypes';
import DateCell from '../../../../common/cells/DateCell';

const ItineraryInformationResource = ({
  source,
  sourceAddress,
  destination,
  destinationAddress,
  contractItineraryList,
}) => (
  <Fragment>
    <h3>Datos del Itinerario</h3>
    <ResourceProperty label="Origen:">
      {source} - {sourceAddress}
    </ResourceProperty>
    <ResourceProperty label="Destino:">
      {destination} - {destinationAddress}
    </ResourceProperty>
    <div className="mb-3">
      <h5>Lista de Movimientos</h5>
      <Table
        columns={[
          {
            Header: 'Itinerario',
            accessor: 'itineraryId',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 100,
          },
          {
            Header: 'Tipo de Movimiento',
            accessor: 'movementType',
            Cell: (row) => CONTRACT_MOVEMENT_TYPE[row.value].label,
            className: 'text-center',
            width: 150,
          },
          {
            Header: 'Embarque',
            accessor: 'departureTime',
            Cell: (row) => (
              <DateCell value={row.value} format={DATE_TIME_FORMAT} />
            ),
            className: 'text-center',
            width: 150,
          },
          {
            Header: 'Ruta',
            accessor: 'route',
            Cell: (row) => (row.value ? row.value.name : '-'),
            className: 'text-center',
            width: 300,
          },
          {
            Header: 'Tipo de Servicio',
            accessor: 'serviceType',
            Cell: (row) => (row.value ? row.value.name : '-'),
            className: 'text-center',
            width: 150,
          },
          {
            Header: 'Número de Bus',
            accessor: 'registeredBus',
            Cell: (row) => row.value && row.value.companyBusId,
            className: 'text-center',
            width: 80,
          },
          {
            Header: 'Detalle de Ruta',
            accessor: 'routeDetail',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 300,
          },
          {
            Header: 'Paradas',
            accessor: 'authorizedStop',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 60,
          },
          {
            Header: 'Desembarque',
            accessor: 'arrivalTime',
            Cell: (row) => (
              <DateCell value={row.value} format={DATE_TIME_FORMAT} />
            ),
            className: 'text-center',
            width: 150,
          },
          {
            Header: 'Kilometraje',
            accessor: 'kilometers',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 80,
          },
          {
            Header: 'Galones',
            accessor: 'expectedFuelConsumption',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 80,
          },
          {
            Header: 'Dirección de Origen',
            accessor: 'sourceAddress',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 350,
          },
          {
            Header: 'Dirección de Destino',
            accessor: 'destinationAddress',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 350,
          },
          {
            Header: 'Conductor 1',
            accessor: 'firstDriver.customer.fullName',
            Cell: (row) => row.value || '-',
            className: 'text-center',
            width: 220,
          },
          {
            Header: 'Conductor 2',
            accessor: 'secondDriver.customer.fullName',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 220,
          },
          {
            Header: 'Conductor 3',
            accessor: 'thirdDriver.customer.fullName',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 220,
          },
          {
            Header: 'Cantidad Servicio a Bordo',
            accessor: 'cabinCrewQuantity',
            className: 'text-center',
            Cell: (row) => row.value || '-',
            width: 150,
          },
        ]}
        data={contractItineraryList}
        defaultPageSize={contractItineraryList.length}
        showPagination={false}
      />
    </div>
  </Fragment>
);

ItineraryInformationResource.propTypes = privateServiceItineraryDataPropTypes;

ItineraryInformationResource.defaultProps =
  privateServiceItineraryDataDefaultProps;

export default ItineraryInformationResource;
