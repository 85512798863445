import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { CUSTOMER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CustomerForm from './CustomerForm';
import Loader from '../../../common/Loader';
import { clearCustomer, getCustomer, putCustomer } from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';
import { GENDERS } from '../../../../config/constants';
import { addressGenerator } from '../../../../utils/app/json-generator-from-reducer';
import { INT_DATE_FORMAT } from '../../../../config/locale';

export class EditCustomer extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: customerId },
      },
      dispatchGetCustomer,
    } = this.props;
    dispatchGetCustomer({ customerId });
  }

  componentWillUnmount() {
    this.props.dispatchClearCustomer();
  }

  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    newFormValues.gender = formValues.gender.value;
    newFormValues.identificationTypeId = formValues.identificationTypeId.value;
    newFormValues.idCountryOfOrigin = formValues.idCountryOfOrigin.value;
    newFormValues.dob = formValues.dateOfBirth
      ? tzNormalizeDate({ date: formValues.dateOfBirth, format: INT_DATE_FORMAT })
      : null;
    if (formValues.addressId) {
      newFormValues.addressId = formValues.addressId.value;
      newFormValues.address = {
        id: formValues.addressId.value,
        address: formValues.addressId.address,
        address2: formValues.addressId.address2,
        district: formValues.addressId.district,
        cityId: formValues.addressId.cityId,
        postalCode: formValues.addressId.postalCode,
        phone: formValues.addressId.phone,
      };
    }

    const {
      match: {
        params: { id },
      },
      dispatchPutCustomer,
    } = this.props;
    dispatchPutCustomer(id, newFormValues);
  };

  generateInitialValues = (customer) => {
    const customerJson = customer.toJS();

    const identificationTypeId = customerJson.identificationType
      ? {
          value: customerJson.identificationTypeId,
          label: customerJson.identificationType.name,
        }
      : null;
    const idCountryOfOrigin = customerJson.idCountryOfOrigin
      ? {
          value: customerJson.idCountryOfOrigin,
          label: customerJson.idCountryOfOrigin,
        }
      : null;
    const addressId =
      customerJson.address && addressGenerator(customerJson.address);

    return {
      firstName: customerJson.firstName,
      lastName: customerJson.lastName,
      gender: GENDERS.find((gender) => gender.value === customerJson.gender),
      identificationTypeId,
      idDocumentNumber: customerJson.idDocumentNumber,
      idCountryOfOrigin,
      dateOfBirth: customerJson.dob,
      email: customerJson.email,
      mobilePhone: customerJson.mobilePhone,
      homePhone: customerJson.homePhone,
      workPhone: customerJson.workPhone,
      fax: customerJson.fax,
      addressId,
    };
  };

  render() {
    const { breadcrumbs, customer, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (customer.isEmpty()) {
      content = <NoDataResource returnPage={CUSTOMER_PATH} />;
    } else {
      content = (
        <CustomerForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(customer)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Cliente"
        subtitle="Editar cliente"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { UserUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Clientes',
      href: CUSTOMER_PATH,
    },
    {
      text: 'Ver',
      href: `${CUSTOMER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  customer: UserUnit.Customer.getIn(['current', 'content']),
  loading: !UserUnit.Customer.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutCustomer: putCustomer,
  dispatchGetCustomer: getCustomer,
  dispatchClearCustomer: clearCustomer,
};

EditCustomer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutCustomer: PropTypes.func.isRequired,
  dispatchGetCustomer: PropTypes.func.isRequired,
  dispatchClearCustomer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  customer: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCustomer.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
