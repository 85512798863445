import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BILLING_PATH } from '../../../../config/paths';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import BillingSearchForm from './BillingSearchForm';
import BillingSearchResult from './BillingSearchResult';
import { getBillings } from '../../../../actions';
import Content from '../../../layout/Content';
import { BILLING_PROCESS_TYPE } from '../../../../config/constants';
import { getSubstring } from '../../../../utils/string';

export const Billings = ({ dispatchGetBillings, breadcrumbs }) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      ...DEFAULT_QUERY_GET_ALL,
      searchBy: formValues.searchBy.value,
      itineraryId: getSubstring(formValues.itineraryId),
    };
    dispatchGetBillings(newFormValues);
  };

  const title = 'Búsqueda de Datos de Facturación';

  const subtitle = (
    <>
      Busca los datos de <strong>Guía de Remisión Transportista</strong> para
      que puedas modificarlos y volver declararlos a SUNAT.
    </>
  );

  const content = (
    <>
      <BillingSearchForm
        onSubmit={onSubmit}
        initialValues={{
          searchBy: BILLING_PROCESS_TYPE.GUIA_REMISION_TRANSPORTISTA,
        }}
      />
      <BillingSearchResult />
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title={title}
      subtitle={subtitle}
      content={content}
    />
  );
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Facturación',
      href: BILLING_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchGetBillings: getBillings,
};

Billings.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBillings: PropTypes.func.isRequired,
};

Billings.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Billings);
