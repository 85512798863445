import React from 'react';
import { InputGroupText } from 'reactstrap';
import TextInput from '../components/common/forms/input/TextInput';
import {
  isRequired,
  validateDynamicFormDuplicateElements,
  validateIntegerNumber,
  validateLength,
  validateNumber,
} from '../utils/validators';
import CargoCategorySelect from '../components/common/forms/select/CargoCategorySelect';
import CommonProductSelect from '../components/common/forms/select/CommonProductSelect';
import DriverSelect from '../components/common/forms/select/DriverSelect';
import DriverTypeSelect from '../components/common/forms/select/DriverTypeSelect';
import CargoItemSelect from '../components/common/forms/select/CargoItemSelect';
import PrintTemplateSelect from '../components/common/forms/select/PrintTemplateSelect';
import DatePicker from '../components/common/forms/input/DatePicker';
import Select from '../components/common/forms/select/Select';
import { CURRENCY, DATE_TIME_FORMAT } from './locale';
import LiquidationSessionSelect from '../components/common/forms/select/LiquidationSessionSelect';
import { DepositForm } from '../components/units/sales/deposit/DepositForm';
import AgencySelect from '../components/common/forms/select/AgencySelect';
import ItemCategorySelect from '../components/common/forms/select/ItemCategorySelect';
import TicketSelect from '../components/common/forms/select/TicketSelect';
import { tzNormalizeDate } from '../utils/date';
import TollSelect from '../components/common/forms/select/TollSelect';
import DriverDocumentTypeSelect from '../components/common/forms/select/DriverDocumentTypeSelect';
import DriverContractTypeSelect from '../components/common/forms/select/DriverContractTypeSelect';
import RegisteredBusSelect from '../components/common/forms/select/RegisteredBusSelect';
import ContractMovementTypeSelect from '../components/common/forms/select/ContractMovementTypeSelect';
import RouteSelect from '../components/common/forms/select/RouteSelect';
import ServiceTypeSelect from '../components/common/forms/select/ServiceTypeSelect';
import MaterialSelect from '../components/common/forms/select/MaterialSelect';
import { enumToSelectOptions } from '../utils/enum';
import { BILLING_DOCUMENT_TYPE } from './constants';
import InternalCommonProductSelect from '../components/common/forms/select/InternalCommonProductSelect';
import BusActivitySelect from '../components/common/forms/select/BusActivitySelect';
import OrganizationChartSelect from '../components/common/forms/select/OrganizationChartSelect';
import MaintenanceWarningSelect from '../components/common/forms/select/MaintenanceWarningSelect';
import CitySelect from '../components/common/forms/select/CitySelect';

const generateCargoItemColumns = (
  onQuantityInputChange,
  onCommonProductChange,
  onWeightChange,
) => [
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'Cantidad',
    requiredLabel: true,
    component: TextInput,
    name: 'quantity',
    type: 'text',
    onChange: onQuantityInputChange,
    validate: [isRequired, validateNumber],
  },
  {
    lg: 2,
    md: 3,
    sm: 12,
    label: 'Producto Frecuente',
    component: CommonProductSelect,
    name: 'commonProductId',
    onChange: onCommonProductChange,
    otherProps: { cleanWhenUnmount: false },
  },
  {
    lg: 2,
    md: 3,
    sm: 12,
    label: 'Categoría',
    requiredLabel: true,
    component: CargoCategorySelect,
    name: 'cargoCategoryId',
    otherProps: { cleanWhenUnmount: false },
    validate: [isRequired],
  },
  {
    lg: 3,
    md: 4,
    sm: 12,
    label: 'Descripción',
    requiredLabel: true,
    component: TextInput,
    name: 'description',
    type: 'text',
    validate: [isRequired],
  },
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'Peso',
    requiredLabel: true,
    component: TextInput,
    name: 'weight',
    type: 'text',
    onChange: onWeightChange,
    validate: [isRequired, validateNumber],
  },
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'Perecible',
    name: 'perishable',
    component: 'input',
    type: 'checkbox',
    otherProps: { className: 'mx-4' },
  },
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'P. Lista',
    requiredLabel: true,
    component: TextInput,
    name: 'listPrice',
    type: 'text',
    validate: [isRequired, validateNumber],
    otherProps: { disabled: true },
  },
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'P. Venta',
    requiredLabel: true,
    component: TextInput,
    name: 'salePrice',
    type: 'text',
    otherProps: { disabled: true },
    validate: [isRequired, validateNumber],
  },
];

const generateInternalTransferItemColumns = (
  onQuantityInputChange,
  onCommonProductChange,
  onWeightChange,
) => [
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'Cantidad',
    requiredLabel: true,
    component: TextInput,
    name: 'quantity',
    type: 'text',
    onChange: onQuantityInputChange,
    validate: [isRequired, validateNumber],
  },
  {
    lg: 2,
    md: 3,
    sm: 12,
    label: 'Producto Frecuente',
    component: InternalCommonProductSelect,
    name: 'commonProductId',
    onChange: onCommonProductChange,
    otherProps: { cleanWhenUnmount: false },
  },
  {
    lg: 2,
    md: 3,
    sm: 12,
    label: 'Categoría',
    component: CargoCategorySelect,
    name: 'cargoCategoryId',
    otherProps: { cleanWhenUnmount: false },
  },
  {
    lg: 3,
    md: 4,
    sm: 12,
    label: 'Descripción',
    requiredLabel: true,
    component: TextInput,
    name: 'description',
    type: 'text',
    validate: [isRequired],
  },
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'Peso',
    requiredLabel: true,
    component: TextInput,
    name: 'weight',
    type: 'text',
    onChange: onWeightChange,
    validate: [isRequired, validateNumber],
  },
];

const generateManualGrrColumns = () => [
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'Cantidad',
    requiredLabel: true,
    component: TextInput,
    name: 'quantity',
    type: 'text',
    validate: [isRequired, validateNumber],
  },
  {
    lg: 3,
    md: 4,
    sm: 12,
    label: 'Descripción',
    requiredLabel: true,
    component: TextInput,
    name: 'description',
    type: 'text',
    validate: [isRequired],
  },
  {
    lg: 1,
    md: 2,
    sm: 12,
    label: 'Peso',
    requiredLabel: true,
    component: TextInput,
    name: 'weight',
    type: 'text',
    validate: [isRequired, validateNumber],
  },
];

const generateDriverAssignmentColumns = (onDriverChange) => [
  {
    lg: 7,
    md: 7,
    sm: 12,
    label: 'Conductor',
    requiredLabel: true,
    component: DriverSelect,
    onChange: onDriverChange,
    name: 'driver',
    validate: [isRequired],
    otherProps: { cleanWhenUnmount: false },
  },
  {
    lg: 4,
    md: 4,
    sm: 12,
    label: 'Tipo de Conductor',
    requiredLabel: true,
    component: DriverTypeSelect,
    name: 'driverType',
    validate: [isRequired],
    otherProps: { cleanWhenUnmount: false },
  },
];

const generateCargoItemToAgencyColumns = (onCargoItemChange) => [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Correlación de Item',
    requiredLabel: true,
    component: CargoItemSelect,
    onChange: onCargoItemChange,
    name: 'item',
    validate: [isRequired],
    otherProps: { cleanWhenUnmount: false, isClearable: false },
  },
];

const PRINT_STATION_FORM_COLUMNS = [
  {
    lg: 4,
    md: 4,
    sm: 4,
    label: 'Plantilla de Impresión',
    requiredLabel: true,
    component: PrintTemplateSelect,
    name: 'printTemplateType',
    validate: [isRequired],
    otherProps: { cleanWhenUnmount: false, isClearable: false },
  },
  {
    lg: 3,
    md: 6,
    sm: 3,
    label: 'Serie',
    requiredLabel: true,
    component: TextInput,
    name: 'documentSeries',
    validate: [isRequired],
    type: 'text',
  },
  {
    lg: 4,
    md: 4,
    sm: 4,
    label: 'Correlativo',
    requiredLabel: true,
    component: TextInput,
    name: 'documentCode',
    validate: [isRequired],
    type: 'text',
  },
];

const PRICE_PER_RANGE_DATES_FORM_COLUMNS = [
  {
    lg: 4,
    md: 4,
    sm: 4,
    label: 'De',
    requiredLabel: true,
    component: DatePicker,
    name: 'startDate',
  },
  {
    lg: 4,
    md: 4,
    sm: 4,
    label: 'Hasta',
    requiredLabel: true,
    component: DatePicker,
    name: 'endDate',
  },
  {
    lg: 3,
    md: 3,
    sm: 3,
    label: 'Diferencia',
    requiredLabel: true,
    component: TextInput,
    name: 'absoluteChange',
  },
  {
    lg: 1,
    md: 1,
    sm: 1,
    component: TextInput,
    name: 'id',
    type: 'hidden',
  },
];

const generatePriceVariationByZonesColumns = (
  floorOptions,
  onClickStart,
  onClickEnd,
) => [
  {
    lg: 6,
    md: 6,
    sm: 6,
    label: 'Piso',
    requiredLabel: true,
    component: Select,
    name: 'itemfloorNumber',
    validate: [isRequired],
    otherProps: { options: floorOptions, isClearable: false },
  },
  {
    lg: 6,
    md: 6,
    sm: 6,
    label: 'Precio Base',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'basePriceOverride',
    validate: [isRequired, validateNumber],
    otherProps: { append: <InputGroupText>{CURRENCY}</InputGroupText> },
  },
  {
    lg: 4,
    md: 12,
    sm: 4,
    label: 'Arriba Izq.',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'startXPosition',
    validate: [isRequired, validateIntegerNumber],
    otherProps: { append: <InputGroupText>(x)</InputGroupText> },
  },
  {
    lg: 4,
    md: 12,
    sm: 4,
    label: 'Arriba Izq.',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'startYPosition',
    validate: [isRequired, validateIntegerNumber],
    otherProps: { append: <InputGroupText>(y)</InputGroupText> },
  },
  {
    lg: 4,
    md: 12,
    sm: 4,
    name: 'xPositionButton',
    customizedField: 'button',
    label: 'Seleccionar',
    onClick: onClickStart,
  },
  {
    lg: 4,
    md: 12,
    sm: 4,
    label: 'Abajo Der.',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'endXPosition',
    validate: [isRequired, validateIntegerNumber],
    otherProps: { append: <InputGroupText>(x)</InputGroupText> },
  },
  {
    lg: 4,
    md: 12,
    sm: 4,
    label: 'Abajo Der.',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'endYPosition',
    validate: [isRequired, validateIntegerNumber],
    otherProps: { append: <InputGroupText>(y)</InputGroupText> },
  },
  {
    lg: 4,
    md: 12,
    sm: 4,
    name: 'yPositionButton',
    customizedField: 'button',
    label: 'Seleccionar',
    onClick: onClickEnd,
  },
  {
    lg: 4,
    md: 12,
    sm: 4,
    name: 'promotionQuantity',
    label: 'Cantidad de Asientos por Promoción',
    component: TextInput,
    validate: [validateNumber],
  },
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Asientos por Zona',
    name: `priceZoneSegmentCityList`,
    isFieldArray: true,
    fields: [
      {
        name: 'sourceCity',
        label: 'Origen',
        component: CitySelect,
        requiredLabel: true,
        validate: [isRequired],
      },
      {
        name: 'destinationCity',
        label: 'Destino',
        component: CitySelect,
        requiredLabel: true,
        validate: [isRequired],
      },
      {
        name: 'promotionBasePrice',
        label: 'Precio Base Promocional',
        component: TextInput,
        validate: [isRequired, validateNumber],
        requiredLabel: true,
      },
    ],
  },
];

const NOTES_FORM_COLUMNS = [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Nota',
    requiredLabel: true,
    component: TextInput,
    name: 'note',
    validate: [isRequired],
  },
];

const DAILY_LIQUIDATION_FORM_COLUMNS = [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Liquidación',
    requiredLabel: true,
    component: LiquidationSessionSelect,
    name: 'dailyLiquidationSession',
    validate: [isRequired, DepositForm.hasValidLiquidation],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
      query: { deposited: false },
    },
  },
];

const AGENCY_GROUP_FORM_COLUMNS = [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Agencia',
    requiredLabel: true,
    component: AgencySelect,
    name: 'agency',
    validate: [
      isRequired,
      ({ value }, allValues) =>
        validateDynamicFormDuplicateElements(
          value,
          allValues.agencyList,
          'agency',
        ),
    ],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
    },
  },
];

const EXPENSE_STATUS_REMARK_FORM_COLUMNS = [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Nota',
    requiredLabel: true,
    component: TextInput,
    type: 'textarea',
    name: 'remarks',
    validate: [isRequired],
  },
];

const generateBaggageTicketFormColumns = (onChangeTicket, disabled) => [
  {
    md: 2,
    label: 'Boleto',
    requiredLabel: true,
    component: TicketSelect,
    onChange: onChangeTicket,
    name: 'ticket',
    validate: [isRequired],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
      isDisabled: disabled,
      flagGetBaggages: true,
    },
  },
  {
    md: 2,
    label: 'Pasajero',
    name: 'customerFullName',
    dependentKey: 'ticket',
  },
  {
    md: 2,
    label: 'Doc. Identidad',
    name: 'customerDocumentNumber',
    dependentKey: 'ticket',
  },
  {
    md: 2,
    label: 'Origen',
    name: 'sourceLocationName',
    dependentKey: 'ticket',
  },
  {
    md: 2,
    label: 'Destino',
    name: 'destinationLocationName',
    dependentKey: 'ticket',
  },
  {
    md: 2,
    label: 'Fecha',
    name: 'departureDate',
    dependentKey: 'ticket',
    Cell: (value) => tzNormalizeDate({ date: value, format: DATE_TIME_FORMAT }),
  },
];

const generateBaggageItemFormColumns = (disabled) => [
  {
    lg: 3,
    md: 3,
    sm: 12,
    label: 'Categoría',
    requiredLabel: true,
    component: ItemCategorySelect,
    name: 'itemCategoryId',
    validate: [isRequired],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
      isDisabled: disabled,
    },
  },
  {
    lg: 2,
    md: 2,
    sm: 5,
    label: 'Serie (ticket)',
    requiredLabel: true,
    component: TextInput,
    name: 'itemSeries',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 2,
    sm: 7,
    label: 'Correlativo (ticket)',
    requiredLabel: true,
    component: TextInput,
    name: 'itemCode',
    validate: [isRequired],
  },
  {
    lg: 3,
    md: 3,
    sm: 9,
    label: 'Descripción',
    requiredLabel: true,
    component: TextInput,
    name: 'description',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 2,
    sm: 3,
    label: 'Peso (kg)',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'weight',
    validate: [isRequired, validateNumber],
    otherProps: { disabled },
  },
];

const TOLL_FORM_COLUMNS = [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Peaje',
    requiredLabel: true,
    component: TollSelect,
    name: 'toll',
    validate: [
      isRequired,
      ({ value }, allValues) =>
        validateDynamicFormDuplicateElements(value, allValues.tollList, 'toll'),
    ],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
    },
  },
];

const DRIVER_DOCUMENT_FORM_COLUMNS = [
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Tipo de Documento',
    requiredLabel: true,
    component: DriverDocumentTypeSelect,
    name: 'driverDocumentTypeId',
    validate: [isRequired],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
    },
  },
  {
    lg: 2,
    md: 3,
    sm: 6,
    label: 'Número de Documento',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'documentNumber',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 3,
    sm: 6,
    label: 'Fecha de Expedición',
    requiredLabel: true,
    component: DatePicker,
    name: 'effectiveDate',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 3,
    sm: 6,
    label: 'Fecha de Expiración',
    requiredLabel: true,
    component: DatePicker,
    name: 'expirationDate',
    validate: [isRequired],
  },
  {
    lg: 3,
    md: 12,
    sm: 12,
    label: 'Notas',
    component: TextInput,
    type: 'text',
    name: 'note',
  },
];

const DRIVER_CONTRACT_FORM_COLUMNS = [
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Tipo de Contrato',
    requiredLabel: true,
    component: DriverContractTypeSelect,
    name: 'driverContractTypeId',
    validate: [isRequired],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
    },
  },
  {
    lg: 2,
    md: 3,
    sm: 6,
    label: 'Fecha de Expedición',
    requiredLabel: true,
    component: DatePicker,
    name: 'effectiveDate',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 3,
    sm: 6,
    label: 'Fecha de Expiración',
    component: DatePicker,
    name: 'expirationDate',
  },
  {
    lg: 2,
    md: 3,
    sm: 6,
    label: 'Razón de terminación',
    component: TextInput,
    type: 'text',
    name: 'reason',
  },
  {
    lg: 3,
    md: 12,
    sm: 12,
    label: 'Notas',
    component: TextInput,
    type: 'text',
    name: 'note',
  },
];

const CONTRACT_ITINERARY_FORM_COLUMNS = [
  // First row
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Tipo de Movimiento',
    requiredLabel: true,
    component: ContractMovementTypeSelect,
    name: 'movementType',
    validate: [isRequired],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
    },
  },
  {
    lg: 2,
    md: 3,
    sm: 6,
    label: 'Número de Bus',
    component: RegisteredBusSelect,
    name: 'registeredBusId',
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: true,
    },
  },
  {
    lg: 4,
    md: 3,
    sm: 6,
    label: 'Ruta',
    component: RouteSelect,
    name: 'routeId',
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: true,
      placeholder: 'Seleccione...',
    },
  },
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Tipo de Servicio',
    requiredLabel: true,
    component: ServiceTypeSelect,
    name: 'serviceTypeId',
    validate: [isRequired],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
      placeholder: 'Seleccione...',
    },
  },
  // Second row
  {
    lg: 3,
    md: 8,
    sm: 6,
    label: 'Dirección de Origen',
    component: TextInput,
    type: 'text',
    name: 'sourceAddress',
  },
  {
    lg: 3,
    md: 4,
    sm: 6,
    label: 'Embarque',
    requiredLabel: true,
    component: DatePicker,
    name: 'departureTime',
    validate: [isRequired],
    otherProps: { time: true },
  },
  {
    lg: 3,
    md: 8,
    sm: 6,
    label: 'Dirección de Destino',
    component: TextInput,
    type: 'text',
    name: 'destinationAddress',
  },
  {
    lg: 3,
    md: 4,
    sm: 6,
    label: 'Desembarque',
    requiredLabel: true,
    component: DatePicker,
    name: 'arrivalTime',
    validate: [isRequired],
    otherProps: { time: true },
  },
  // Third row
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Conductor 1',
    component: DriverSelect,
    name: 'firstDriverId',
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: true,
    },
  },
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Conductor 2',
    component: DriverSelect,
    name: 'secondDriverId',
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: true,
    },
  },
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Conductor 3',
    component: DriverSelect,
    name: 'thirdDriverId',
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: true,
    },
  },
  {
    lg: 3,
    md: 3,
    sm: 6,
    label: 'Cantidad Servicio a Bordo',
    component: TextInput,
    type: 'text',
    name: 'cabinCrewQuantity',
    validate: [validateIntegerNumber],
  },
  // Fourth row
  {
    lg: 6,
    md: 6,
    sm: 12,
    label: 'Descripción del Servicio',
    component: TextInput,
    type: 'textarea',
    name: 'routeDetail',
  },
  {
    lg: 2,
    md: 2,
    sm: 4,
    label: 'Paradas',
    component: TextInput,
    type: 'text',
    name: 'authorizedStop',
    validate: [validateIntegerNumber],
  },
  {
    lg: 2,
    md: 2,
    sm: 4,
    label: 'Kilometraje',
    component: TextInput,
    type: 'text',
    name: 'kilometers',
    validate: [validateNumber],
  },
  {
    lg: 2,
    md: 2,
    sm: 4,
    label: 'Dotación',
    component: TextInput,
    type: 'text',
    name: 'expectedFuelConsumption',
    validate: [validateNumber],
  },
];

const generateManualVoucherFormColumns = (
  onChangeQuantity,
  onChangeUnitaryPrice,
) => [
  {
    lg: 2,
    md: 2,
    sm: 3,
    label: 'Cantidad',
    requiredLabel: true,
    component: TextInput,
    onChange: onChangeQuantity,
    type: 'text',
    name: 'quantity',
    validate: [isRequired, validateIntegerNumber],
  },
  {
    lg: 6,
    md: 6,
    sm: 9,
    label: 'Descripción',
    requiredLabel: true,
    component: TextInput,
    type: 'textarea',
    name: 'description',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 2,
    sm: 3,
    label: 'Precio',
    requiredLabel: true,
    component: TextInput,
    onChange: onChangeUnitaryPrice,
    type: 'text',
    name: 'unitaryPrice',
    validate: [isRequired, validateNumber],
  },
  {
    lg: 2,
    md: 2,
    sm: 3,
    label: 'Total',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'totalPrice',
    validate: [isRequired],
    otherProps: {
      disabled: true,
    },
  },
];

const ACTIVITY_MATERIAL_FORM_COLUMNS = [
  {
    lg: 8,
    md: 8,
    sm: 12,
    label: 'Material',
    requiredLabel: true,
    component: MaterialSelect,
    name: 'materialId',
    validate: [
      isRequired,
      ({ value }, allValues) =>
        validateDynamicFormDuplicateElements(
          value,
          allValues.materialList,
          'materialId',
        ),
    ],
    otherProps: {
      cleanWhenUnmount: false,
      isClearable: false,
    },
  },
  {
    lg: 4,
    md: 4,
    sm: 12,
    label: 'Cantidad',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'quantity',
    validate: [validateNumber],
    otherProps: {
      placeholder: '1',
    },
  },
];

const BILL_ITEM_LIST_FORM_COLUMNS = [
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'ID',
    requiredLabel: true,
    component: TextInput,
    validate: [validateNumber],
    name: 'id',
  },
  {
    lg: 3,
    md: 3,
    sm: 3,
    label: 'Peso',
    requiredLabel: true,
    component: TextInput,
    validate: [validateNumber],
    name: 'weight',
  },
  {
    lg: 7,
    md: 7,
    sm: 7,
    label: 'Descripción',
    requiredLabel: true,
    component: TextInput,
    name: 'description',
  },
];

const BILL_DRIVER_LIST_FORM_COLUMNS = [
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'Tipo Doc.',
    requiredLabel: true,
    component: Select,
    name: 'documentType',
    otherProps: { options: enumToSelectOptions(BILLING_DOCUMENT_TYPE) },
  },
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'Núm. Doc.',
    requiredLabel: true,
    component: TextInput,
    name: 'documentNumber',
  },
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'Nombre',
    requiredLabel: true,
    component: TextInput,
    name: 'name',
  },
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'Apellido',
    requiredLabel: true,
    component: TextInput,
    name: 'lastName',
  },
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'Licencia',
    requiredLabel: true,
    component: TextInput,
    name: 'driverAuthorization',
  },
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'Tipo',
    component: TextInput,
    name: 'driverType',
  },
];

const generateMaintenanceServiceOrderListFormColumns = (
  noOptionsMessage,
  registeredBusId,
) => [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: 'Actividad',
    component: BusActivitySelect,
    name: 'activityBusId',
    noOptionsMessage,
    validate: [isRequired],
    otherProps: {
      registeredBusId,
      cleanWhenUnmount: false,
    },
  },
];

const ACTIVITY_TASK_FORM_COLUMNS = [
  {
    lg: 5,
    md: 8,
    sm: 12,
    label: 'Descripción',
    requiredLabel: true,
    component: TextInput,
    name: 'description',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 4,
    sm: 4,
    label: 'Hrs. Trabajadas',
    requiredLabel: true,
    component: TextInput,
    type: 'text',
    name: 'workingHours',
    validate: [isRequired, validateNumber],
  },
  {
    lg: 3,
    md: 6,
    sm: 6,
    label: 'Organigrama',
    requiredLabel: true,
    component: OrganizationChartSelect,
    name: 'companyAreaId',
    validate: [isRequired],
  },
  {
    lg: 2,
    md: 2,
    sm: 2,
    label: 'Orden',
    component: TextInput,
    name: 'taskOrder',
    validate: [validateNumber],
  },
];

const generateMaintenanceWarningColumns = (
  hasEqualWarning,
  registeredBusId,
  typeLabel,
) => [
  {
    lg: 12,
    md: 12,
    sm: 12,
    label: `Ocurrencia ${typeLabel}`,
    requiredLabel: true,
    component: MaintenanceWarningSelect,
    name: 'maintenanceWarningId',
    validate: [isRequired, hasEqualWarning],
    otherProps: {
      registeredBusId,
    },
  },
];

const validateLength6 = validateLength(6);
const DISTRICT_ITEM_LIST_FORM_COLUMNS = [
  {
    lg: 6,
    md: 6,
    sm: 12,
    label: 'Nombre',
    requiredLabel: true,
    component: TextInput,
    name: 'name',
    validate: [isRequired],
  },
  {
    lg: 6,
    md: 6,
    sm: 12,
    label: 'Ubigeo',
    requiredLabel: true,
    component: TextInput,
    name: 'ubigeo',
    validate: [isRequired, validateNumber, validateLength6],
  },
];

export {
  generateCargoItemColumns,
  generateInternalTransferItemColumns,
  generateManualGrrColumns,
  generateDriverAssignmentColumns,
  generateCargoItemToAgencyColumns,
  PRINT_STATION_FORM_COLUMNS,
  PRICE_PER_RANGE_DATES_FORM_COLUMNS,
  generatePriceVariationByZonesColumns,
  NOTES_FORM_COLUMNS,
  DAILY_LIQUIDATION_FORM_COLUMNS,
  AGENCY_GROUP_FORM_COLUMNS,
  EXPENSE_STATUS_REMARK_FORM_COLUMNS,
  generateBaggageItemFormColumns,
  generateBaggageTicketFormColumns,
  TOLL_FORM_COLUMNS,
  DRIVER_DOCUMENT_FORM_COLUMNS,
  DRIVER_CONTRACT_FORM_COLUMNS,
  CONTRACT_ITINERARY_FORM_COLUMNS,
  generateManualVoucherFormColumns,
  ACTIVITY_MATERIAL_FORM_COLUMNS,
  BILL_ITEM_LIST_FORM_COLUMNS,
  BILL_DRIVER_LIST_FORM_COLUMNS,
  generateMaintenanceServiceOrderListFormColumns,
  ACTIVITY_TASK_FORM_COLUMNS,
  generateMaintenanceWarningColumns,
  DISTRICT_ITEM_LIST_FORM_COLUMNS,
};
