import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_MECHANICS,
  GET_MECHANICS,
  CLEAR_MECHANICS,
  FLAG_MECHANIC_ACTIVITY,
  GET_MECHANIC,
  CLEAR_MECHANIC,
} from '../types/mechanical-maintenance';
import { MECHANIC_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { MECHANIC_PATH } from '../../config/paths';

const flagGettingMechanics = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MECHANICS,
    payload: flag,
  });

const getMechanics = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingMechanics(true));
    const query = tableFilters;
    const url = `${MECHANIC_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_MECHANICS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingMechanics(false));
  }
};

const clearMechanics = () => (dispatch) =>
  dispatch({
    type: CLEAR_MECHANICS,
  });

const flagMechanicActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MECHANIC_ACTIVITY,
    payload: flag,
  });

const getMechanic =
  async ({ mechanicId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMechanicActivity(true));
      const url = `${MECHANIC_ENDPOINT}/${mechanicId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_MECHANIC,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMechanicActivity(false));
    }
  };

const clearMechanic = () => (dispatch) =>
  dispatch({
    type: CLEAR_MECHANIC,
  });

const postMechanic =
  async ({
    customerId,
    companyId,
    managementId,
    departmentId,
    areaId,
    sectionId,
    specialityId,
    bossId,
    securePassword,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagMechanicActivity(true));
      const payload = {
        customerId,
        companyId,
        managementId,
        departmentId,
        areaId,
        sectionId,
        specialityId,
        bossId,
        securePassword,
      };
      const url = MECHANIC_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(MECHANIC_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMechanicActivity(false));
    }
  };

const deleteMechanic =
  async ({ mechanicId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMechanicActivity(true));
      const url = `${MECHANIC_ENDPOINT}/${mechanicId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(MECHANIC_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMechanicActivity(false));
    }
  };

const putMechanic =
  async (
    mechanicId,
    {
      customerId,
      companyId,
      managementId,
      departmentId,
      areaId,
      sectionId,
      specialityId,
      bossId,
      securePassword,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagMechanicActivity(true));
      const payload = {
        id: mechanicId,
        customerId,
        companyId,
        managementId,
        departmentId,
        areaId,
        sectionId,
        specialityId,
        bossId,
        securePassword,
      };
      const url = `${MECHANIC_ENDPOINT}/${mechanicId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(MECHANIC_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMechanicActivity(false));
    }
  };

export {
  flagGettingMechanics,
  getMechanics,
  clearMechanics,
  flagMechanicActivity,
  getMechanic,
  postMechanic,
  clearMechanic,
  deleteMechanic,
  putMechanic,
};
