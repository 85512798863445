import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import { isRequired } from '../../../../../utils/validators';
import Loader from '../../../../common/Loader';
import ItineraryScheduleFormFields from '../ItineraryScheduleFormFields';
import Select from '../../../../common/forms/select/Select';
import FormFooter from '../../../../common/forms/FormFooter';

const TripForm = ({ loading, handleSubmit, initialValues }) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Itinerario" required>
          <Field
            name="itineraryId"
            component={Select}
            isDisabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <ItineraryScheduleFormFields
        form="TripForm"
        itineraryId={initialValues.itineraryId.value}
      />
      <FormFooter />
    </Form>
  );
};

TripForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    companyBusId: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    comment: PropTypes.string,
    itineraryId: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

TripForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'TripForm',
})(TripForm);

export default connect(mapStateToProps)(formComponent);
