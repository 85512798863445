import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import printCreditNote from '../../../../../utils/printers/CreditNote';

class PrintCreditNoteButton extends Component {
  handlePrintCreditNote = () => {
    const { ticket } = this.props;
    printCreditNote(ticket.toJS());
  };

  render() {
    return (
      <Button
        color="light"
        className="border"
        onClick={this.handlePrintCreditNote}
      >
        <i className="fa fa-print" /> Imprimir Postergación
      </Button>
    );
  }
}

PrintCreditNoteButton.propTypes = {
  ticket: PropTypes.instanceOf(Map).isRequired,
};

export default PrintCreditNoteButton;
