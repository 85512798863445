import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../../utils/date';
import IsActive from '../../../../common/cells/IsActive';
import { creditNoteBasicInformationPropTypes } from '../proptypes/CreditNotePropTypes';
import { numberFormatter } from '../../../../../utils/number';
import { padStart } from '../../../../../utils/string';
import { DATE_FORMAT } from '../../../../../config/locale';

const CreditNoteBasicInformationResource = ({
  documentSeries,
  documentCode,
  amount,
  reference,
  description,
  balance,
  expirationDate,
  isValid,
  customer: { fullName, idDocumentNumber, idCountryOfOrigin },
}) => {
  const documentNumber =
    documentSeries &&
    documentCode &&
    `${documentSeries}-${padStart(documentCode.toString(), 7)}`;

  return (
    <Fragment>
      <h3>Información básica</h3>
      <ResourceProperty label="Documento:">
        {documentNumber || '-'}
      </ResourceProperty>
      <ResourceProperty label="Referencia:">
        {reference || '-'}
      </ResourceProperty>
      <ResourceProperty label="Cliente:">
        {idDocumentNumber} ({idCountryOfOrigin}) - {fullName}
      </ResourceProperty>
      <ResourceProperty label="Descripción:">
        {description || '-'}
      </ResourceProperty>
      <ResourceProperty label="Monto:">
        {numberFormatter({ value: amount })}
      </ResourceProperty>
      <ResourceProperty label="Balance:">{balance || '-'}</ResourceProperty>
      <ResourceProperty label="Fecha de Expiración:">
        {tzNormalizeDate({ date: expirationDate, format: DATE_FORMAT })}
      </ResourceProperty>
      <ResourceProperty label="Estado:">
        <IsActive value={isValid} />
      </ResourceProperty>
    </Fragment>
  );
};

CreditNoteBasicInformationResource.propTypes =
  creditNoteBasicInformationPropTypes;

export default CreditNoteBasicInformationResource;
