import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getDistrict,
  clearDistrict,
} from '../../../../actions/location/District';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { DISTRICT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import DistrictToolbar from './DistrictToolbar';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import InternalResource from '../../../common/resource/InternalResource';

export class District extends Component {
  componentDidMount() {
    const {
      dispatchGetDistrict,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDistrict({ id });
  }

  componentWillUnmount() {
    const { dispatchClearDistrict } = this.props;
    dispatchClearDistrict();
  }

  render() {
    const { district, loading, breadcrumbs } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (district.isEmpty()) {
      content = <NoDataResource returnPage={DISTRICT_PATH} />;
    } else {
      content = (
        <Fragment>
          <ResourceProperty label="Nombre:">
            {district.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Ciudad:">
            {district.get('city').name}
          </ResourceProperty>
          <ResourceProperty label="Departamento:">
            {district.get('city').region.name}
          </ResourceProperty>
          <ResourceProperty label="Pais:">
            {district.get('city').region.country.name}
          </ResourceProperty>
          <ResourceProperty label="Ubigeo:">
            {district.get('ubigeo')}
          </ResourceProperty>
          <InternalResource
            id={district.get('id')}
            createDate={district.get('createDate')}
            lastUpdate={district.get('lastUpdate')}
          />
        </Fragment>
      );

      toolbar = <DistrictToolbar districtId={district.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Distrito"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Distritos',
      href: DISTRICT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  district: LocationUnit.District.getIn(['current', 'content']),
  loading: !LocationUnit.District.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDistrict: getDistrict,
  dispatchClearDistrict: clearDistrict,
};

District.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDistrict: PropTypes.func.isRequired,
  dispatchClearDistrict: PropTypes.func.isRequired,
  district: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
};

District.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(District);
