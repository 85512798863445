import React from 'react';
import PropTypes from 'prop-types';

const ClearButtonCell = ({ handleClear, data, id, showClearButton }) =>
  showClearButton && (
    <>
      <i
        tabIndex="0"
        role="button"
        className="fa fa-minus-circle text-danger"
        onKeyPress={() => handleClear(data, id)}
        onClick={() => handleClear(data, id)}
        id={`btnConfirm${id}`}
      />
    </>
  );

ClearButtonCell.propTypes = {
  handleClear: PropTypes.func.isRequired,
  data: PropTypes.shape({ status: PropTypes.string }).isRequired,
  id: PropTypes.number.isRequired,
  showClearButton: PropTypes.bool,
};

ClearButtonCell.defaultProps = {
  showClearButton: true,
};

export default ClearButtonCell;
