const BASIC_POST_CONFIG = {
  method: 'POST',
  credentials: 'include',
};

const DEFAULT_POST_CONFIG = {
  ...BASIC_POST_CONFIG,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const DEFAULT_GET_CONFIG = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  credentials: 'include',
};

const PDF_GET_CONFIG = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/pdf',
  },
};

const DEFAULT_DELETE_CONFIG = {
  method: 'DELETE',
  credentials: 'include',
};

const DEFAULT_PATCH_CONFIG = {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
};

const DEFAULT_PUT_CONFIG = {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
};

const getDefaultConfig = (method) => {
  switch (method) {
    case 'post':
      return DEFAULT_POST_CONFIG;
    case 'get':
      return DEFAULT_GET_CONFIG;
    case 'delete':
      return DEFAULT_DELETE_CONFIG;
    case 'patch':
      return DEFAULT_PATCH_CONFIG;
    case 'put':
      return DEFAULT_PUT_CONFIG;
    default:
      return DEFAULT_POST_CONFIG;
  }
};

export {
  BASIC_POST_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_PUT_CONFIG,
  PDF_GET_CONFIG,
  getDefaultConfig,
};
