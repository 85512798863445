import * as Sentry from '@sentry/browser';
import { REACT_APP_ENV } from './environments';
import { LOCALE } from './locale';

const PUBLIC_KEY = process.env.REACT_APP_SENTRY_PUBLIC_KEY;
const HOST = process.env.REACT_APP_SENTRY_HOST;
const PROJECT_ID = process.env.REACT_APP_SENTRY_PROJECT_ID;

const CONFIG = {
  dsn: `https://${PUBLIC_KEY}@${HOST}/${PROJECT_ID}`,
  environment: REACT_APP_ENV,
  beforeSend(event) {
    // check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        title: 'Parece que estamos teniendo problemas.',
        subtitle: 'Nuestro equipo ha sido notificado.',
        subtitle2: 'Si nos quieres ayudar, dinos qué ha pasado a continuación.',
        labelComments: '¿Qué ha pasado?',
        labelName: 'Nombre',
        labelEmail: 'Correo Electrónico',
        labelClose: 'Cerrar',
        labelSubmit: 'Enviar Informe',
        // todo: remove this to detect automatically
        lang: LOCALE,
      });
    }
    return event;
  },
};

export { PUBLIC_KEY, HOST, PROJECT_ID, CONFIG };
