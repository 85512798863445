import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  EVENT_PATH,
  IN_SYSTEM_NOTIFICATION_PATH,
  NEW_IN_SYSTEM_NOTIFICATION_PATH,
  SYSTEM_UNIT_PATH,
} from '../../../config/paths';
import Event from './event/Event';
import Events from './event/Events';
import EditInSystemNotification from './in-system-notification/EditInSystemNotification';
import InSystemNotification from './in-system-notification/InSystemNotification';
import InSystemNotifications from './in-system-notification/InSystemNotifications';
import NewInSystemNotification from './in-system-notification/NewInSystemNotification';
import SystemHome from './SystemHome';

const SystemUnit = () => (
  <Switch>
    <Route exact path={SYSTEM_UNIT_PATH} component={SystemHome} />
    <Route exact path={EVENT_PATH} component={Events} />
    <Route exact path={`${EVENT_PATH}/:id`} component={Event} />
    <Route
      exact
      path={IN_SYSTEM_NOTIFICATION_PATH}
      component={InSystemNotifications}
    />
    <Route
      exact
      path={NEW_IN_SYSTEM_NOTIFICATION_PATH}
      component={NewInSystemNotification}
    />
    <Route
      exact
      path={`${IN_SYSTEM_NOTIFICATION_PATH}/:id`}
      component={InSystemNotification}
    />
    <Route
      exact
      path={`${IN_SYSTEM_NOTIFICATION_PATH}/:id/edit`}
      component={EditInSystemNotification}
    />
  </Switch>
);

export default SystemUnit;
