import { number, shape, string } from 'prop-types';
import { addressBasicInformationPropTypes } from '../../../location/address/proptypes/AddressPropTypes';
import identificacionTypeBasicInformationPropTypes from '../../identification-type/proptypes/IdentificationTypePropTypes';

const customerBasicInformationPropTypes = {
  id: number,
  firstName: string,
  lastName: string,
  fullName: string,
  gender: string,
  idCountryOfOrigin: string,
  identificationType: shape(identificacionTypeBasicInformationPropTypes),
  idDocumentNumber: string,
  dob: string,
  address: shape(addressBasicInformationPropTypes),
};

const customerBasicInformationDefaultProps = {
  id: null,
  firstName: null,
  lastName: null,
  fullName: null,
  gender: null,
  idCountryOfOrigin: null,
  identificationType: null,
  idDocumentNumber: null,
  dob: null,
};

const customerContactInformationPropTypes = {
  email: string,
  mobilePhone: string,
  homePhone: string,
  workPhone: string,
  fax: string,
};

const customerContactInformationDefaultProps = {
  email: null,
  mobilePhone: null,
  homePhone: null,
  workPhone: null,
  fax: null,
};

export {
  customerBasicInformationPropTypes,
  customerContactInformationPropTypes,
  customerBasicInformationDefaultProps,
  customerContactInformationDefaultProps,
};
