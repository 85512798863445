import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ItineraryGroupEditButton from './ItineraryGroupEditButton';
import ItineraryGroupDeleteButton from './ItineraryGroupDeleteButton';

const ItineraryGroupToolbar = ({ itineraryGroupId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <ItineraryGroupEditButton itineraryGroupId={itineraryGroupId} />{' '}
      <ItineraryGroupDeleteButton itineraryGroupId={itineraryGroupId} />
    </div>
  </ButtonToolbar>
);

ItineraryGroupToolbar.propTypes = {
  itineraryGroupId: PropTypes.number.isRequired,
};

export default ItineraryGroupToolbar;
