import QueryString from 'query-string';
import {
  FLAG_GETTING_IDENTIFICATION_TYPES,
  SHOW_ERROR_GETTING_IDENTIFICATION_TYPES,
  CLEAR_ERROR_GETTING_IDENTIFICATION_TYPES,
  GET_IDENTIFICATION_TYPES,
  CLEAR_IDENTIFICATION_TYPES,
} from '../types';
import { DEFAULT_QUERY } from '../../config/queries';
import { IDENTIFICATION_TYPE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { handleResponseError } from '../../utils/error-handlers';

const flagGettingIdentificationTypes = flag => dispatch => dispatch({
  type: FLAG_GETTING_IDENTIFICATION_TYPES,
  payload: flag,
});

const showErrorGettingIdentificationTypes = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_IDENTIFICATION_TYPES,
  payload: error,
});

const clearErrorGettingIdentificationTypes = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_IDENTIFICATION_TYPES,
});

const getIdentificationTypes = async () => async (dispatch) => {
  dispatch(clearErrorGettingIdentificationTypes());
  dispatch(flagGettingIdentificationTypes(true));
  try {
    const parameters = {
      ...DEFAULT_QUERY,
    };
    const url = `${IDENTIFICATION_TYPE_ENDPOINT}?${QueryString.stringify(parameters)}`;
    const response = await fetch(
      url,
      DEFAULT_GET_CONFIG,
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingIdentificationTypes(false));
      return dispatch(showErrorGettingIdentificationTypes(serverError));
    }
    const identificationTypes = await response.json();
    dispatch({
      type: GET_IDENTIFICATION_TYPES,
      payload: identificationTypes,
    });
    return dispatch(flagGettingIdentificationTypes(false));
  } catch (error) {
    console.error(error);
    dispatch(flagGettingIdentificationTypes(false));
    const errMsg = 'error obteniendo tipos de identificación';
    return dispatch(showErrorGettingIdentificationTypes(errMsg));
  }
};

const clearIdentificationTypes = () => dispatch => dispatch({
  type: CLEAR_IDENTIFICATION_TYPES,
});

export {
  flagGettingIdentificationTypes,
  showErrorGettingIdentificationTypes,
  clearErrorGettingIdentificationTypes,
  getIdentificationTypes,
  clearIdentificationTypes,
};
