import PropTypes from 'prop-types';

const passengersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    idDocumentNumber: PropTypes.string.isRequired,
    seatNumber: PropTypes.number.isRequired,
    salePrice: PropTypes.number.isRequired,
  }),
);

const customerOrderPropType = PropTypes.shape({
  passengers: passengersPropType.isRequired,
  totalPrice: PropTypes.number.isRequired,
});

export { passengersPropType, customerOrderPropType };
