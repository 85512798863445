import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import {
  clearActivities,
  clearRegisteredBuses,
  getActivities,
  getRegisteredBuses,
} from '../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import { ACTIVITY_COLUMN, BUS_COLUMN } from '../../../../config/columns';

const generateRegisteredBusData = (registeredBuses) =>
  registeredBuses.get('content');

const generateActivityData = (activities) => activities.get('content');

export const BusActivityAssignmentForm = ({
  handleSubmit,
  loading,
  dispatchGetActivities,
  dispatchClearActivities,
  loadingRegisteredBuses,
  registeredBuses,
  loadingActivities,
  activities,
  unassign,
  dispatchGetRegisteredBuses,
  dispatchClearRegisteredBuses,
}) => {
  const [buttonText, setButtonText] = useState('Asignar');
  const [buttonIcon, setButtonIcon] = useState('fa fa-link');
  const [buttonColor, setButtonColor] = useState('primary');

  useLayoutEffect(() => {
    if (unassign) {
      setButtonText('Desasignar');
      setButtonIcon('fa fa-chain-broken');
      setButtonColor('danger');
    }

    dispatchGetRegisteredBuses({ ...DEFAULT_QUERY_GET_ALL });
    dispatchGetActivities({ ...DEFAULT_QUERY_GET_ALL, size: 10000 });

    return () => {
      dispatchClearRegisteredBuses();
      dispatchClearActivities();
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Col md={4}>
          <Label>
            Bus <span className="text-danger">*</span>
          </Label>
          <SelectionableTable
            name="registeredBusIdList"
            columns={BUS_COLUMN}
            data={generateRegisteredBusData(registeredBuses)}
            defaultPageSize={10}
            keyField="id"
            loading={loadingRegisteredBuses}
            form="BusActivityAssignmentForm"
            required
            returnOnlySelectedItems
            filterable
            defaultFilterMethod={filterMethodCaseInsensitive}
          />
        </Col>
        <Col md={8}>
          <Label>
            Actividades <span className="text-danger">*</span>
          </Label>
          <SelectionableTable
            name="activityIdList"
            columns={ACTIVITY_COLUMN}
            data={generateActivityData(activities)}
            defaultPageSize={10}
            keyField="id"
            loading={loadingActivities}
            form="BusActivityAssignmentForm"
            required
            returnOnlySelectedItems
            filterable
            defaultFilterMethod={filterMethodCaseInsensitive}
          />
        </Col>
      </FormGroup>
      <FormFooter
        saveButtonIcon={buttonIcon}
        saveButtonText={buttonText}
        saveButtonColor={buttonColor}
      />
    </Form>
  );
};

BusActivityAssignmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetActivities: PropTypes.func.isRequired,
  dispatchClearActivities: PropTypes.func.isRequired,
  activities: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingActivities: PropTypes.bool.isRequired,
  unassign: PropTypes.bool,
  dispatchGetRegisteredBuses: PropTypes.func.isRequired,
  dispatchClearRegisteredBuses: PropTypes.func.isRequired,
  loadingRegisteredBuses: PropTypes.bool.isRequired,
  registeredBuses: PropTypes.instanceOf(Immutable.Map).isRequired,
};

BusActivityAssignmentForm.defaultProps = {
  unassign: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit, BusUnit }) => ({
  loading: !MechanicalMaintenanceUnit.BusActivityAssignment.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  registeredBuses: BusUnit.RegisteredBus.getIn(['all', 'content']),
  loadingRegisteredBuses: BusUnit.RegisteredBus.getIn(['all', 'loading']),
  activities: MechanicalMaintenanceUnit.Activity.getIn(['all', 'content']),
  loadingActivities: MechanicalMaintenanceUnit.Activity.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetActivities: getActivities,
  dispatchClearActivities: clearActivities,
  dispatchGetRegisteredBuses: getRegisteredBuses,
  dispatchClearRegisteredBuses: clearRegisteredBuses,
};

const formComponent = reduxForm({
  form: 'BusActivityAssignmentForm',
})(BusActivityAssignmentForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
