import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import FormFooter from '../../../common/forms/FormFooter';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';

const MyMaintenanceServiceOrderSearchForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit} className="mb-4">
    <FormGroup row>
      <FormItem label="Ingresa el Bus" required>
        <Field
          name="companyBusId"
          component={RegisteredBusSelect}
          isClearable
        />
      </FormItem>
    </FormGroup>
    <FormFooter saveButtonText="Buscar" saveButtonIcon="fa fa-search" />
  </Form>
);

MyMaintenanceServiceOrderSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'MyMaintenanceServiceOrderSearchForm',
})(MyMaintenanceServiceOrderSearchForm);
