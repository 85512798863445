import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  postDiscountCodeReport,
  clearErrorPostingDiscountCodeReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import DiscountCodeReportForm from './DiscountCodeReportForm';
import {
  NEW_DISCOUNT_CODE_REPORT_PATH,
  DISCOUNT_CODE_REPORTS_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';

class NewDiscountCodeReport extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    postDiscountCodeReport: PropTypes.func.isRequired,
    clearErrorPostingDiscountCodeReport: PropTypes.func.isRequired,
  };
  componentWillUnmount() {
    this.props.clearErrorPostingDiscountCodeReport();
  }
  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte de Códigos de Descuento</h1>
            <p id="text">Crear un nuevo reporte de Códigos de Descuento.</p>
          </Col>
        </Row>
        <DiscountCodeReportForm
          onSubmit={this.props.postDiscountCodeReport}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            force: true,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Códigos de Descuento',
      href: DISCOUNT_CODE_REPORTS_PATH,
    },
    {
      text: 'Crear',
      href: NEW_DISCOUNT_CODE_REPORT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  postDiscountCodeReport,
  clearErrorPostingDiscountCodeReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDiscountCodeReport);
