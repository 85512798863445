import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';

const IsActive = ({ value }) => {
  if (value) return <Badge color="success" text="Activo" />;

  return <Badge color="danger" text="Inactivo" />;
};

IsActive.propTypes = {
  value: PropTypes.bool,
};

IsActive.defaultProps = {
  value: false,
};

export default IsActive;
