import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getLiquidationSession,
  clearLiquidationSession,
  getPaymentMethods,
} from '../../../../actions/index';
import Loader from '../../../common/Loader';
import { LIQUIDATION_SESSION_PATH } from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import LiquidationBasicInformationResource from './resource/LiquidationBasicInformationResource';
import LiquidationIncomeResource from './resource/LiquidationIncomeResource';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import IsOpen from '../../../common/cells/IsOpen';
import LiquidationExpenseResource from './resource/LiquidationExpenseResource';
import LiquidationCashResource from './resource/LiquidationCashResource';
import LiquidationDifferenceResource from './resource/LiquidationDifferenceResource';
import LiquidationSessionToolbar from './LiquidationSessionToolbar';
import LiquidationNoteResource from './resource/LiquidationNoteResource';
import LiquidationCommissionResource from './resource/LiquidationCommissionResource';
import LiquidationCreditNoteResource from './resource/LiquidationCreditNoteResource';
import LiquidationDepositsResource from './resource/LiquidationDepositsResource';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import LiquidationSalesSessionsResource from './resource/LiquidationSalesSessionsResource';
import LiquidationParcelCreditResource from './resource/LiquidationParcelCreditResource';
import LiquidationParcelOtherIncomesResource from './resource/LiquidationParcelOtherIncomesResource';

export const LiquidationSession = ({
  match: {
    params: { id: liquidationSessionId },
  },
  dispatchGetLiquidationSession,
  breadcrumbs,
  liquidation,
  loading,
  dispatchClearLiquidationSession,
  paymentMethods,
  dispatchGetPaymentMethods,
}) => {
  useLayoutEffect(() => {
    dispatchGetPaymentMethods();
    dispatchGetLiquidationSession({ liquidationSessionId });

    return () => dispatchClearLiquidationSession();
  }, []);

  let content = null;
  let toolbar = null;

  if (loading) content = <Loader />;
  else if (liquidation.isEmpty())
    content = <NoDataResource returnPage={LIQUIDATION_SESSION_PATH} />;
  else {
    const cashToDeposit =
      liquidation.get('amountPayable') !== 0
        ? liquidation.get('amountPayable') -
            liquidation.get('liquidationDifferenceAmount') || 0
        : liquidation.get('cashToDeposit');

    // Get all cash payment method
    const cashPaymentMethodList = paymentMethods
      .get('content')
      .filter(({ transactionCode }) => transactionCode === 'CASH');

    content = (
      <>
        <ResourceProperty label="ID:">
          {liquidationSessionId || '-'}
        </ResourceProperty>
        <ResourceProperty label="# Factura Comisión:">
          {liquidation.get('invoiceNumber')}
        </ResourceProperty>
        <LiquidationBasicInformationResource
          agencyName={liquidation.get('agency')}
          createDate={liquidation.get('createDate')}
          exchangeRateSet={liquidation.get('exchangeRateSet')}
          operatorUserFullName={liquidation.get('operatorUser')}
        />
        <LiquidationIncomeResource
          ticketSummaryList={liquidation.get('ticketSummaryList')}
          ticketPaymentMethodSummaryList={liquidation.get(
            'ticketPaymentMethodSummaryList',
          )}
          generalIncomeSummaryList={liquidation.get('generalIncomeSummaryList')}
          generalIncomeTypeSummaryList={liquidation.get(
            'generalIncomeTypeSummaryList',
          )}
          generalIncomePaymentMethodSummaryList={liquidation.get(
            'generalIncomePaymentMethodSummaryList',
          )}
          totalSalesPaymentMethodSummaryList={liquidation.get(
            'totalSalesPaymentMethodSummaryList',
          )}
          excessBaggageSummaryList={liquidation.get('excessBaggageSummaryList')}
          parcelIncomeSummaryList={liquidation.get('parcelIncomeSummaryList')}
          cashPaymentMethodList={cashPaymentMethodList}
        />
        <LiquidationParcelCreditResource
          parcelCreditSummaryList={liquidation.get('parcelCreditSummaryList')}
        />
        <LiquidationParcelOtherIncomesResource
          parcelOtherIncomesSummaryList={liquidation.get(
            'parcelOtherIncomesSummaryList',
          )}
        />
        {/* Notes */}
        <LiquidationNoteResource
          dailyLiquidationSessionNoteList={liquidation.get(
            'dailyLiquidationSessionNoteList',
          )}
        />
        {/* Notes end */}
        <ResourceProperty label="Estado:">
          <IsOpen value={liquidation.get('settled')} />
        </ResourceProperty>
        <LiquidationExpenseResource
          expenseTypeSummaryList={liquidation.get('expenseTypeSummaryList')}
          expenseSummaryList={liquidation.get('expenseSummaryList')}
        />
        <LiquidationCashResource
          totalCashIncome={liquidation.get('totalCashIncome')}
          totalCashExpense={liquidation.get('totalCashExpense')}
          totalCash={liquidation.get('totalCash')}
        />
        <LiquidationCreditNoteResource
          generatedCreditNoteSet={liquidation.get('generatedCreditNoteSet')}
          creditNoteSet={liquidation.get('creditNoteSet')}
        />
        <LiquidationDepositsResource deposits={liquidation.get('deposits')} />
        <LiquidationCommissionResource
          commission={liquidation.get('commission')}
          commissionTaxes={liquidation.get('commissionTaxes')}
          amountPayable={liquidation.get('amountPayable')}
        />
        <LiquidationDifferenceResource
          liquidationDifferenceAmount={liquidation.get(
            'liquidationDifferenceAmount',
          )}
          liquidationDifferenceReason={liquidation.get(
            'liquidationDifferenceReason',
          )}
          liquidationDifferenceReasonDescription={liquidation.get(
            'liquidationDifferenceReasonDescription',
          )}
          cashToDeposit={cashToDeposit}
        />
        <LiquidationSalesSessionsResource
          salesSessions={liquidation.get('salesSessionSummaryList')}
        />
      </>
    );

    toolbar = (
      <LiquidationSessionToolbar liquidationSessionId={+liquidationSessionId} />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Liquidación de Agencia"
      toolbar={toolbar}
      content={content}
    />
  );
};

LiquidationSession.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  liquidation: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetLiquidationSession: PropTypes.func.isRequired,
  dispatchClearLiquidationSession: PropTypes.func.isRequired,
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.instanceOf(Immutable.Map).isRequired,
};

LiquidationSession.defaultProps = {
  breadcrumbs: [],
};

const mapStateToProps = ({ SalesUnit, AccountingUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Liquidaciones de Agencia', href: LIQUIDATION_SESSION_PATH },
    { text: 'Ver', href: '' },
  ],
  liquidation: SalesUnit.LiquidationSession.getIn(['current', 'content']),
  loading: !SalesUnit.LiquidationSession.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  paymentMethods: AccountingUnit.PaymentMethod.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetLiquidationSession: getLiquidationSession,
  dispatchClearLiquidationSession: clearLiquidationSession,
  dispatchGetPaymentMethods: getPaymentMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquidationSession);
