import { toastr } from 'react-redux-toastr';
import { FUEL_CONSUMPTION_REPORT_PER_DRIVER_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_FUEL_CONSUMPTION_REPORT_PER_DRIVER,
  FLAG_FUEL_CONSUMPTION_REPORT_PER_DRIVER_ACTIVITY,
  POST_FUEL_CONSUMPTION_REPORT_PER_DRIVER,
} from '../types';

const flagFuelConsumptionReportPerDriverActivity = flag => dispatch =>
  dispatch({
    type: FLAG_FUEL_CONSUMPTION_REPORT_PER_DRIVER_ACTIVITY,
    payload: flag,
  });

const postFuelConsumptionReportPerDriver = async ({
  fromDate,
  toDate,
  driverIdSet,
}) => async dispatch => {
  try {
    dispatch(flagFuelConsumptionReportPerDriverActivity(true));
    const payload = { fromDate, toDate, driverIdSet };
    const url = FUEL_CONSUMPTION_REPORT_PER_DRIVER_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const fuelConsumption = await response.json();
    dispatch({
      type: POST_FUEL_CONSUMPTION_REPORT_PER_DRIVER,
      payload: fuelConsumption,
    });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagFuelConsumptionReportPerDriverActivity(false));
  }
};

const clearFuelConsumptionReportPerDriver = () => dispatch =>
  dispatch({
    type: CLEAR_FUEL_CONSUMPTION_REPORT_PER_DRIVER,
  });

export {
  flagFuelConsumptionReportPerDriverActivity,
  postFuelConsumptionReportPerDriver,
  clearFuelConsumptionReportPerDriver,
};
