import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { BASE_FUEL_REFILL_PATH } from '../../../../config/paths';

const BaseFuelRefillToolbar = ({ baseFuelRefillId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${BASE_FUEL_REFILL_PATH}/${baseFuelRefillId}/edit`} />{' '}
    </div>
  </ButtonToolbar>
);

BaseFuelRefillToolbar.propTypes = {
  baseFuelRefillId: PropTypes.number.isRequired,
};

export default BaseFuelRefillToolbar;
