import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getDriverContractTypes,
  clearDriverContractTypes,
} from '../../../../actions';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';

class DriverContractTypeSelect extends Component {
  componentDidMount() {
    const { dispatchGetDriverContractTypes } = this.props;
    dispatchGetDriverContractTypes(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearDriverContractTypes, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearDriverContractTypes();
    }
  }

  render() {
    const { options, isLoading, isMulti, input, meta, ...rest } = this.props;
    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
        {...rest}
      />
    );
  }
}

DriverContractTypeSelect.propTypes = {
  dispatchGetDriverContractTypes: PropTypes.func.isRequired,
  dispatchClearDriverContractTypes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  cleanWhenUnmount: PropTypes.bool,
};

DriverContractTypeSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  options: [],
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  options: TrafficUnit.DriverContractType.getIn([
    'all',
    'content',
    'content',
  ]).map((type) => ({
    value: type.id,
    label: type.name,
  })),
  loading: TrafficUnit.DriverContractType.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetDriverContractTypes: getDriverContractTypes,
  dispatchClearDriverContractTypes: clearDriverContractTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(DriverContractTypeSelect);
