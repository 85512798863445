import React from 'react';
import GridEditableInput from '../components/common/grid/GridEditableInput';
import BusFuelGroupSelect from '../components/common/forms/select/BusFuelGroupSelect';
import FuelVoucherSelect from '../components/common/forms/select/FuelVoucherSelect';
import { tzNormalizeDate } from '../utils/date';
import { DATE_FORMAT } from './locale';

const BUS_GROUP_GALLONS_GRID_COLUMNS = [
  {
    key: 'busFuelGroupId',
    title: 'Grupo',
    editableComponent: (onValueChange, value) => (
      <BusFuelGroupSelect
        initFetch={false}
        onValueChange={onValueChange}
        value={value}
        isGridVariant
        isClearable
      />
    ),
  },
  {
    key: 'numberOfGallon',
    title: 'Galones',
    editableComponent: (onValueChange, value) => (
      <GridEditableInput onValueChange={onValueChange} value={value} />
    ),
  },
  {
    key: 'numberOfGallonAdblue',
    title: 'Ad Blue(L)',
    editableComponent: (onValueChange, value) => (
      <GridEditableInput onValueChange={onValueChange} value={value} />
    ),
  },
];

const generateNewPurchaseOrderGridColumns = (agencyId, fuelVoucherOptions) => [
  {
    key: 'fuelVoucher',
    title: 'Voucher',
    width: '300',
    editableComponent: (onValueChange, value) => (
      <FuelVoucherSelect
        onValueChange={onValueChange}
        value={value}
        agencyId={agencyId}
        isGridVariant
        fuelVoucherOptions={fuelVoucherOptions}
      />
    ),
  },
  {
    dependentKey: 'fuelVoucher',
    dependentValue: 'fuelStationBusinessName',
    title: 'Grifo',
    width: '200',
  },
  {
    dependentKey: 'fuelVoucher',
    dependentValue: 'numberOfGallons',
    title: 'Galones Autorizados',
    width: '100',
  },
  {
    key: 'realNumberOfGallons',
    title: 'Galones Reales',
    width: '100',
    editableComponent: (onValueChange, value, disabled = false) => (
      <GridEditableInput
        onValueChange={onValueChange}
        value={value}
        disabled={disabled}
      />
    ),
  },
  {
    dependentKey: 'fuelVoucher',
    dependentValue: 'createDate',
    title: 'Fecha de Creación',
    cell: (value) =>
      value ? tzNormalizeDate({ date: value, format: DATE_FORMAT }) : null,
    width: '100',
  },
  {
    dependentKey: 'fuelVoucher',
    dependentValue: 'companyBusId',
    title: 'Número de Bus',
    width: '100',
  },
];

const TICKET_GRID_COLUMNS = [
  {
    key: 'documentCode',
    title: 'Boletos',
    editableComponent: (onValueChange, value) => (
      <GridEditableInput onValueChange={onValueChange} value={value} />
    ),
  },
];

export {
  BUS_GROUP_GALLONS_GRID_COLUMNS,
  generateNewPurchaseOrderGridColumns,
  TICKET_GRID_COLUMNS,
};
