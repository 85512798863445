import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { getPrivateService, clearPrivateService } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import PrivateServiceToolbar from './PrivateServiceToolbar';
import { PRIVATE_SERVICE_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';
import ContractorInformationResource from './resource/ContractInformationResource';
import ServiceInformationResource from './resource/ServiceInformationResource';
import ItineraryInformationResource from './resource/ItineraryInformationResource';
import PayPerDriverServiceInformationResource from './resource/PayPerDriverServiceInformationResource';
import PayPerBusServiceInformationResource from './resource/PayPerBusServiceInformationResource';
import ContractAmountInformationResource from './resource/ContractAmountInformationResource';
import ExecutiveInformationResource from './resource/ExecutiveInformationResource';

const PrivateService = ({
  dispatchGetPrivateService,
  match: {
    params: { id: privateServiceId },
  },
  breadcrumbs,
  privateService,
  loading,
  dispatchClearPrivateService,
}) => {
  useLayoutEffect(() => {
    dispatchGetPrivateService({ privateServiceId });

    return () => dispatchClearPrivateService();
  }, []);

  let content = null;
  let toolbar = null;

  if (loading) content = <Loader />;
  else if (privateService.isEmpty())
    content = <NoDataResource returnPage={PRIVATE_SERVICE_PATH} />;
  else {
    const privateServiceJson = privateService.toJS();

    const sortedContractItineraryList =
      privateServiceJson.contractItineraryList.sort((a, b) => {
        if (a.departureTime > b.departureTime) return 1;
        if (a.departureTime < b.departureTime) return -1;
        return 0;
      });

    privateServiceJson.contractItineraryList = sortedContractItineraryList;

    content = (
      <>
        <Row>
          <Col md={6} className="mb-3">
            <ContractorInformationResource {...privateServiceJson} />
          </Col>
          <Col md={6} className="mb-3">
            <ServiceInformationResource {...privateServiceJson} />
          </Col>
        </Row>
        <div className="mb-4">
          <ItineraryInformationResource {...privateServiceJson} />
        </div>
        <Row>
          <Col md={6} className="mb-3">
            <PayPerDriverServiceInformationResource {...privateServiceJson} />
          </Col>
          <Col md={6} className="mb-3">
            <PayPerBusServiceInformationResource {...privateServiceJson} />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <ContractAmountInformationResource {...privateServiceJson} />
          </Col>
          <Col md={6} className="mb-3">
            <ExecutiveInformationResource {...privateServiceJson} />
          </Col>
        </Row>
      </>
    );

    toolbar = <PrivateServiceToolbar contract={privateServiceJson} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Servicio Privado"
      toolbar={toolbar}
      content={content}
    />
  );
};

PrivateService.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetPrivateService: PropTypes.func.isRequired,
  dispatchClearPrivateService: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  privateService: PropTypes.instanceOf(Immutable.Map).isRequired,
};

PrivateService.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Servicios Privados',
      href: PRIVATE_SERVICE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  privateService: ContractUnit.PrivateService.getIn(['current', 'content']),
  loading: !ContractUnit.PrivateService.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetPrivateService: getPrivateService,
  dispatchClearPrivateService: clearPrivateService,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateService);
