import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import UserEditButton from './UserEditButton';
import UserDeleteButton from './UserDeleteButton';
import { USER_PATH, USER_SELF_PATH } from '../../../../config/paths';

const UserToolbar = ({ userId, self }) => {
  let deleteButton = null;
  let userEditPath = `${USER_SELF_PATH}/edit`;

  if (!self) {
    deleteButton = <UserDeleteButton userId={userId} />;
    userEditPath = `${USER_PATH}/${userId}/edit`;
  }

  return (
    <ButtonToolbar className="pull-right">
      <div>
        <UserEditButton path={userEditPath} /> {deleteButton}
      </div>
    </ButtonToolbar>
  );
};

UserToolbar.propTypes = {
  userId: PropTypes.number.isRequired,
  self: PropTypes.bool,
};

UserToolbar.defaultProps = {
  self: false,
};

export default UserToolbar;
