import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import CargoMinimalPriceForm from './CargoMinimalPriceForm';
import { CARGO_MINIMAL_PRICE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getCargoMinimalPrice,
  clearCargoMinimalPrice,
  putCargoMinimalPrice,
} from '../../../../actions';
import Loader from '../../../common/Loader';

export class EditCargoMinimalPrice extends Component {
  componentDidMount() {
    const {
      dispatchGetCargoMinimalPrice,
      match: {
        params: { id: minimalPriceId },
      },
    } = this.props;
    dispatchGetCargoMinimalPrice({ minimalPriceId });
  }

  componentWillUnmount() {
    const { dispatchClearCargoMinimalPrice } = this.props;
    dispatchClearCargoMinimalPrice();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutCargoMinimalPrice,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutCargoMinimalPrice(id, {
      minimalPrice: formValues.minimalPrice,
    });
  };

  render() {
    const { breadcrumbs, price, loading } = this.props;
    if (loading || price.isEmpty()) {
      return <Loader />;
    }
    const initialValues = {
      minimalPrice: price.get('minimalPrice'),
    };
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Editar Precio Mínimo de Carga</h1>
        <p>Editar precio mínimo de carga</p>
        <CargoMinimalPriceForm
          onSubmit={this.onSubmit}
          initialValues={initialValues}
        />
      </Container>
    );
  }
}

EditCargoMinimalPrice.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoMinimalPrice: PropTypes.func.isRequired,
  dispatchClearCargoMinimalPrice: PropTypes.func.isRequired,
  dispatchPutCargoMinimalPrice: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  price: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCargoMinimalPrice.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Precio Mínimo de Carga',
      href: CARGO_MINIMAL_PRICE_PATH,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  price: CargoUnit.CargoMinimalPrice.getIn(['current', 'content']),
  loading: !CargoUnit.CargoMinimalPrice.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoMinimalPrice: getCargoMinimalPrice,
  dispatchClearCargoMinimalPrice: clearCargoMinimalPrice,
  dispatchPutCargoMinimalPrice: putCargoMinimalPrice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCargoMinimalPrice);
