import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import ModuleList from '../../../layout/ModuleList';
import { CARGO_ITEMS_COLUMNS_TABLE } from '../../../../config/columns';
import { CARGO_ITEM_PATH } from '../../../../config/paths';
import { clearCargoItems, getCargoItemsV2 } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';

export const CargoItems = ({
  breadcrumbs,
  cargoItems,
  loading,
  dispatchGetCargoItems,
  dispatchClearCargoItems,
}) => {
  useLayoutEffect(() => () => dispatchClearCargoItems(), []);

  const data = cargoItems.get('content') || [];
  const pages = cargoItems.get('totalPages') || null;
  const defaultPageSize = cargoItems.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Encomiendas"
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: CARGO_ITEMS_COLUMNS_TABLE,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetCargoItems,
        modelPath: CARGO_ITEM_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetCargoItems: getCargoItemsV2,
  dispatchClearCargoItems: clearCargoItems,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Encomiendas',
      href: CARGO_ITEM_PATH,
    },
  ],
  cargoItems: CargoUnit.CargoItem.getIn(['all', 'content']),
  loading: CargoUnit.CargoItem.getIn(['all', 'loading']),
});

CargoItems.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cargoItems: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCargoItems: PropTypes.func.isRequired,
  dispatchClearCargoItems: PropTypes.func.isRequired,
};

CargoItems.defaultProps = {
  cargoItems: Immutable.Map(),
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoItems);
