import React from 'react';
import PropTypes from 'prop-types';
import { FULL_DATE_TIME_FORMAT } from '../../../config/locale';
import ResourceProperty from './ResourceProperty';
import { tzNormalizeDate } from '../../../utils/date';

const InternalResource = ({ id, createDate, lastUpdate }) => (
  <>
    <h3>Información Interna</h3>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Fecha de Creación:">
      {createDate
        ? tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Última actualización:">
      {lastUpdate
        ? tzNormalizeDate({ date: lastUpdate, format: FULL_DATE_TIME_FORMAT })
        : '-'}
    </ResourceProperty>
  </>
);

InternalResource.propTypes = {
  id: PropTypes.number.isRequired,
  createDate: PropTypes.number,
  lastUpdate: PropTypes.number,
};

InternalResource.defaultProps = {
  createDate: null,
  lastUpdate: null,
};

export default InternalResource;
