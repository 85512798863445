import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { MANUAL_VOUCHER_PATH, USER_PATH } from '../../../../config/paths';
import { getManualVoucher, clearManualVoucher } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import ContractorInformationResource from '../../contract/private-service/resource/ContractInformationResource';
import VoucherInformationResource from './resource/VoucherInformationResource';
import PaymentInformationResource from './resource/PaymentInformationResource';
import ItemsInformationResource from './resource/ItemsInformationResource';
import ManualVoucherToolbar from './ManualVoucherToolbar';
import BaseDocument from './BaseDocument';

class ManualVoucher extends Component {
  componentDidMount() {
    const {
      dispatchGetManualVoucher,
      match: {
        params: { id: manualVoucherId },
      },
    } = this.props;
    dispatchGetManualVoucher({ manualVoucherId });
  }

  componentWillUnmount() {
    const { dispatchClearManualVoucher } = this.props;
    dispatchClearManualVoucher();
  }

  render() {
    const { breadcrumbs, voucher, loading } = this.props;

    let content;
    let toolbar;

    if (loading) content = <Loader />;
    else if (voucher.isEmpty())
      content = <NoDataResource returnPage={USER_PATH} />;
    else {
      const voucherJson = voucher.toJS();

      content = (
        <>
          <ContractorInformationResource {...voucherJson} />
          <Row>
            <Col md={6}>
              <VoucherInformationResource {...voucherJson} />
            </Col>
            <Col md={6}>
              <PaymentInformationResource {...voucherJson} />
            </Col>
          </Row>
          <ItemsInformationResource {...voucherJson} />
          <BaseDocument {...voucherJson.previousManualVoucher} />
        </>
      );

      toolbar = <ManualVoucherToolbar manualVoucher={voucherJson} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Comprobante Manual"
        content={content}
      />
    );
  }
}

ManualVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetManualVoucher: PropTypes.func.isRequired,
  dispatchClearManualVoucher: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  voucher: PropTypes.instanceOf(Immutable.Map).isRequired,
};

ManualVoucher.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Comprobantes Manuales',
      href: MANUAL_VOUCHER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  voucher: AccountingUnit.ManualVoucher.getIn(['current', 'content']),
  loading: !AccountingUnit.ManualVoucher.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetManualVoucher: getManualVoucher,
  dispatchClearManualVoucher: clearManualVoucher,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualVoucher);
