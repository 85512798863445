import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearItineraryGroups,
  getItineraryGroups,
} from '../../../../actions/route/ItineraryGroup';
import {
  ITINERARY_GROUP_PATH,
  NEW_ITINERARY_GROUP_PATH,
} from '../../../../config/paths';
import { ITINERARY_GROUPS_COLUMNS } from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';

export class ItineraryGroups extends Component {
  componentWillUnmount() {
    this.props.dispatchClearItineraryGroups();
  }

  render() {
    const { circuitGroups, loading, dispatchGetItineraryGroups, breadcrumbs } =
      this.props;
    const data = circuitGroups.get('content') || [];
    const pages = circuitGroups.get('totalPages') || null;
    const defaultPageSize = circuitGroups.get('size') || DEFAULT_PAGE_SIZE;
    return (
      <ModuleList
        title="Grupos de Itinerarios por Circuito"
        buttonPath={NEW_ITINERARY_GROUP_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: ITINERARY_GROUPS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetItineraryGroups,
          modelPath: ITINERARY_GROUP_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Itinerarios por Circuito',
      href: '',
    },
  ],
  circuitGroups: RouteUnit.ItineraryGroup.getIn(['all', 'content']),
  loading: RouteUnit.ItineraryGroup.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchClearItineraryGroups: clearItineraryGroups,
  dispatchGetItineraryGroups: getItineraryGroups,
};

ItineraryGroups.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItineraryGroups: PropTypes.func.isRequired,
  dispatchClearItineraryGroups: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  circuitGroups: PropTypes.instanceOf(Immutable.Map),
};

ItineraryGroups.defaultProps = {
  circuitGroups: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryGroups);
