import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReservationHome from './ReservationHome';
import {
  TICKET_PATH,
  TICKET_CUSTOMER_PATH,
  TICKET_MASSIVE_POSTPONE_PATH,
} from '../../../config/paths';
import Tickets from './ticket/Tickets';
import Ticket from './ticket/Ticket';
import TicketCustomers from './ticket-customer/TicketCustomers';
import TicketMassivePostpone from './ticket-massive-postpone/TicketMassivePostpone';

const ReservationUnit = () => (
  <Switch>
    <Route exact path="/reservation" component={ReservationHome} />
    <Route exact path={TICKET_PATH} component={Tickets} />
    <Route exact path={`${TICKET_PATH}/:id`} component={Ticket} />
    <Route exact path={TICKET_CUSTOMER_PATH} component={TicketCustomers} />
    <Route
      exact
      path={TICKET_MASSIVE_POSTPONE_PATH}
      component={TicketMassivePostpone}
    />
  </Switch>
);

export default ReservationUnit;
