import Immutable from 'immutable';
import { HOME_PATH } from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'bus-chassis',
      title: 'Chasís',
      path: '/bus/bus-chassis',
      icon: 'bus',
      disabled: true,
    },
    {
      key: 'bus-bodywork',
      title: 'Carrocerías',
      path: '/bus/bus-bodywork',
      icon: 'bus',
      disabled: true,
    },
    {
      key: 'registered-buses',
      title: 'Buses',
      path: '/bus/registered-buses',
      icon: 'book',
      disabled: true,
    },
    {
      key: 'service-types',
      title: 'Tipos de Servicio',
      path: '/bus/service-types',
      icon: 'server',
      disabled: true,
    },
    {
      key: 'seat-maps',
      title: 'Mapa de Asientos',
      path: '/bus/seat-maps',
      icon: 'table',
      disabled: true,
    },
    {
      key: 'elements',
      title: 'Elementos',
      path: '/bus/elements',
      icon: 'cube',
      disabled: true,
    },
    {
      key: 'document-types',
      title: 'Tipos de Documentos',
      path: '/bus/document-types',
      icon: 'file-text',
      disabled: true,
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Buses',
      tag: 'span',
      href: '/bus',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
