import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BUS_FUEL_GROUP_PATH } from '../../../../config/paths';

const EditBusFuelGroupButton = ({ busFuelGroupId }) => (
  <Link
    className="btn btn-primary"
    to={`${BUS_FUEL_GROUP_PATH}/${busFuelGroupId}/edit`}
  >
    Editar
  </Link>
);

EditBusFuelGroupButton.propTypes = {
  busFuelGroupId: PropTypes.number.isRequired,
};

export default EditBusFuelGroupButton;
