const getFuelConsumptionQualification = ({
  expectedFuelConsumption,
  realFuelConsumption,
}) => {
  let qualificationText = 'Regular';
  let qualificationColor = null;
  if (expectedFuelConsumption - 5 > realFuelConsumption) {
    qualificationText = 'Bueno';
    qualificationColor = 'bg-light-green';
  }
  if (expectedFuelConsumption + 5 < realFuelConsumption) {
    qualificationText = 'Malo';
    qualificationColor = 'bg-light-red';
  }
  return { qualificationColor, qualificationText };
};

// eslint-disable-next-line import/prefer-default-export
export { getFuelConsumptionQualification };
