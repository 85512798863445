import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { CREDIT_NOTE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CreditNoteForm from './CreditNoteForm';
import Loader from '../../../common/Loader';
import {
  clearCreditNote,
  getCreditNote,
  putCreditNote,
} from '../../../../actions/sales/CreditNote';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

export class EditCreditNote extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: creditNoteId },
      },
      dispatchGetCreditNote,
    } = this.props;
    dispatchGetCreditNote({ creditNoteId });
  }

  componentWillUnmount() {
    this.props.dispatchClearCreditNote();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutCreditNote,
      match: {
        params: { id },
      },
      creditNote,
    } = this.props;

    const {
      amount,
      balance,
      companyId,
      documentCode,
      documentSeries,
      previousTicketId,
      creditNoteTransactionList,
    } = creditNote.toJS();

    const newFormValues = {
      customerId: formValues.customerId.value,
      reference: formValues.reference,
      description: formValues.description,
      isValid: formValues.isValid,
      expirationDate: tzNormalizeDate({
        date: formValues.expirationDate,
        format: TIMESTAMP_FORMAT,
      }),
      amount,
      balance,
      companyId,
      documentCode,
      documentSeries,
      previousTicketId,
      creditNoteTransactionList: creditNoteTransactionList.map(
        ({ creditNoteId, transactionId }) => ({
          creditNoteId,
          transactionId,
        }),
      ),
    };

    dispatchPutCreditNote(id, newFormValues);
  };

  generateInitialValues = ({
    reference,
    description,
    amount,
    isValid,
    expirationDate,
    customer,
    creditNoteTransactionList,
  }) => ({
    reference,
    description,
    amount,
    isValid,
    expirationDate: tzNormalizeDate({ date: expirationDate }),
    customerId: {
      value: customer.id,
      label: `${customer.idDocumentNumber} (${customer.idCountryOfOrigin}) - ${customer.fullName}`,
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      fullName: customer.fullName,
      idDocumentNumber: customer.idDocumentNumber,
      gender: customer.gender,
      idCountryOfOrigin: customer.idCountryOfOrigin,
      identificationType: customer.identificationType,
      email: customer.email,
      mobilePhone: customer.mobilePhone,
      dob: customer.dob,
      discountCode: customer.discountCode,
    },
    creditNoteTransactionList,
  });

  render() {
    const { breadcrumbs, creditNote, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (creditNote.isEmpty()) {
      content = <NoDataResource returnPage={CREDIT_NOTE_PATH} />;
    } else {
      content = (
        <CreditNoteForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(creditNote.toJS())}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Nota de Crédito"
        subtitle="Edita nota de crédito"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { SalesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Notas de Crédito',
      href: CREDIT_NOTE_PATH,
    },
    {
      text: 'Ver',
      href: `${CREDIT_NOTE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  creditNote: SalesUnit.CreditNote.getIn(['current', 'content']),
  loading: !SalesUnit.CreditNote.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutCreditNote: putCreditNote,
  dispatchGetCreditNote: getCreditNote,
  dispatchClearCreditNote: clearCreditNote,
};

EditCreditNote.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutCreditNote: PropTypes.func.isRequired,
  dispatchGetCreditNote: PropTypes.func.isRequired,
  dispatchClearCreditNote: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  creditNote: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCreditNote.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCreditNote);
