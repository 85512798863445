import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

// TODO test
const TicketVoid = ({ ticketVoid }) => (
  <Alert color="danger">
    <h3>Anulación</h3>
    <p>Este boleto ha sido anulado</p>
    <ResourceProperty label="Justificación:">
      {ticketVoid.description}
    </ResourceProperty>
    <ResourceProperty label="Fecha:">
      {tzNormalizeDate({
        date: ticketVoid.createDate,
        format: DATE_TIME_FORMAT,
      })}
    </ResourceProperty>
    <ResourceProperty label="Usuario:">
      {ticketVoid.user.customer.fullName}
    </ResourceProperty>
  </Alert>
);

TicketVoid.propTypes = {
  ticketVoid: PropTypes.shape({
    description: PropTypes.string.isRequired,
    createDate: PropTypes.number.isRequired,
    user: PropTypes.shape({
      customer: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default TicketVoid;
