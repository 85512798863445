import React from 'react';
import { ListGroupItem } from 'reactstrap';
import BoardColumnElement from './BoardColumnElement';
import { BoardColumnPropTypes } from './prop-types/BoardPropTypes';
import './BoardColumn.css';

const BoardColumn = ({ status, elements }) => (
  <ListGroupItem className="flex-fill bg-light pl-2 pt-2 pr-2 board-column-width">
    <h4 className="ml-1 mr-1">{status}</h4>
    <div>
      <div className="overflow-auto board-column-element-height">
        {elements.length === 0 ? (
          <i className="mb-1 ml-1 mr-1 text-muted">
            No hay elementos a mostrar
          </i>
        ) : (
          elements.map(({ id, onClickElement, body }, index, array) => (
            <BoardColumnElement
              key={id}
              id={id}
              body={body}
              onClickElement={onClickElement}
              lastElement={!array[index + 1]}
            />
          ))
        )}
      </div>
    </div>
  </ListGroupItem>
);

BoardColumn.propTypes = BoardColumnPropTypes;

export default BoardColumn;
