import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import Select from '../../../common/forms/select/Select';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import { CONTRACT_STATUS } from '../../../../config/constants';
import Alert from '../../../common/informative/Alert';
import FormFooter from '../../../common/forms/FormFooter';

class PrivateServiceRegisterPassengerForm extends Component {
  state = {
    disabled: false,
  };

  componentDidMount() {
    this.onDidMount();
  }

  onDidMount = () => {
    const { initialValues } = this.props;

    if (
      initialValues &&
      (initialValues.contractStatus === CONTRACT_STATUS.DONE.value ||
        initialValues.contractStatus === CONTRACT_STATUS.PAID.value)
    )
      this.setState({ disabled: true });
  };

  static renderContractItineraryCustomer({ fields }, disabled) {
    return fields.map((contractItineraryCustomer, index) => (
      <div key={`row${index.toString()}`} className="mt-4 mb-5">
        <FormGroup row>
          <FormItem label={`Asiento ${+index + 1}`} required>
            <Field
              name={`${contractItineraryCustomer}.seatId`}
              component={Select}
              isDisabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <CustomerInputGroup
          label={`Pasajero ${index + 1}`}
          name={`${contractItineraryCustomer}.customerId`}
          form="PrivateServiceRegisterPassengerForm"
          mobilePhoneRequired
          disabled={disabled}
          showDetails
        />
      </div>
    ));
  }

  static renderContractItinerary({ fields }, disabled) {
    return fields.map((contractItinerary, index) => (
      <Col key={`col${index.toString()}`} className="border-right">
        <FormGroup row>
          <FormItem label={`Bus ${+index + 1}`} required>
            <Field
              name={`${contractItinerary}.contractItineraryId`}
              component={Select}
              isDisabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <h5 className="pt-2">Asientos Disponibles</h5>
        <FieldArray
          name={`${contractItinerary}.contractItineraryCustomerList`}
          component={(e) =>
            PrivateServiceRegisterPassengerForm.renderContractItineraryCustomer(
              e,
              disabled,
            )
          }
        />
      </Col>
    ));
  }

  render() {
    const {
      initialValues: { contractItineraryList },
    } = this.props;

    const { disabled } = this.state;

    const hasntBusAndRouteSelected = contractItineraryList.some(
      ({ contractItineraryId }) => contractItineraryId === null,
    );

    let passengerList = (
      <Alert
        type="warning"
        message="Hay movimientos registrados sin Bus o Ruta"
      />
    );

    if (!hasntBusAndRouteSelected)
      passengerList = (
        <>
          <Row>
            <FieldArray
              name="contractItineraryList"
              component={(e) =>
                PrivateServiceRegisterPassengerForm.renderContractItinerary(
                  e,
                  disabled,
                )
              }
            />
          </Row>
          <FormFooter saveButtonDisabled={disabled} />
        </>
      );

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <h3>Lista de Pasajeros</h3>
        {passengerList}
      </Form>
    );
  }
}

PrivateServiceRegisterPassengerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    contractStatus: PropTypes.string.isRequired,
    contractItineraryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
};

PrivateServiceRegisterPassengerForm.defaultProps = {
  initialValues: null,
};

const formComponent = reduxForm({
  form: 'PrivateServiceRegisterPassengerForm',
})(PrivateServiceRegisterPassengerForm);

export default formComponent;
