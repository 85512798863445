import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DEPOSIT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import DepositForm from './DepositForm';
import { postDeposit } from '../../../../actions';
import { DEPOSIT_TYPE } from '../../../../config/constants';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

export class NewDeposit extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      accountNumber: formValues.accountNumber,
      bankName: formValues.bankName,
      operationNumber: formValues.operationNumber,
      depositDate: tzNormalizeDate({
        date: formValues.depositDate,
        format: TIMESTAMP_FORMAT,
      }),
      actualAmountDeposited: formValues.actualAmountDeposited,
      expectedAmountToDeposit: formValues.expectedAmountToDeposit,
      depositDifferenceAmount: formValues.depositDifferenceAmount || 0,
      depositDifferenceDescription:
        formValues.depositDifferenceDescription || null,
      depositDifferenceReasonId: formValues.depositDifferenceReasonId
        ? formValues.depositDifferenceReasonId.value
        : null,
      depositType: formValues.depositType
        ? formValues.depositType.value
        : DEPOSIT_TYPE.DEFAULT.value,
      dailyLiquidationSessionSet: formValues.dailyLiquidationSessionSet.map(
        ({ dailyLiquidationSession }) => ({
          id: dailyLiquidationSession.value,
        }),
      ),
      file: formValues.file || null,
    };

    this.props.dispatchPostDeposit(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Depósito"
        subtitle="Crea un nuevo depósito"
        content={<DepositForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Depósitos',
      href: DEPOSIT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostDeposit: postDeposit,
};

NewDeposit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDeposit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDeposit);
