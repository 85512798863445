import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  CONTRACT_DOCUMENT_TYPE,
  CONTRACT_STATUS,
} from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../../config/locale';
import {
  privateServiceContractAmountDefaultProps,
  privateServiceContractAmountPropTypes,
} from '../proptypes/PrivateServicePropTypes';
import EnumStatus from '../../../../common/EnumStatus';
import { numberFormatter } from '../../../../../utils/number';
import Checkbox from '../../../../common/forms/Checkbox';

const ContractAmountInformationResource = ({
  amountBeforeDiscount,
  amountPerBus,
  authorizedDiscount,
  discountComment,
  totalAmount,
  paymentMethod,
  creditPayment,
  operationCode,
  creditComment,
  contractPaymentType,
  contractStatus,
  createDate,
}) => (
  <Fragment>
    <h3>Datos del Contrato y Plazo de Pago</h3>
    <ResourceProperty label="Fecha del Contrato:">
      {createDate
        ? tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Estado del Contrato:">
      <EnumStatus enumObject={CONTRACT_STATUS} enumValue={contractStatus} />
    </ResourceProperty>
    <ResourceProperty label="Tipo de Documento:">
      {contractPaymentType
        ? CONTRACT_DOCUMENT_TYPE[contractPaymentType].label
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Tarifa Total:">
      {numberFormatter({ value: amountBeforeDiscount })}
    </ResourceProperty>
    <ResourceProperty label="Tarifa por Bus:">
      {numberFormatter({ value: amountPerBus })}
    </ResourceProperty>
    <ResourceProperty label="Descuento Autorizado:">
      {authorizedDiscount
        ? numberFormatter({ value: authorizedDiscount })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Comentario sobre el descuento:">
      {discountComment || '-'}
    </ResourceProperty>
    <ResourceProperty label="Tarifa Después de Descuento:">
      {totalAmount ? numberFormatter({ value: totalAmount }) : '-'}
    </ResourceProperty>
    <ResourceProperty label="Método de Pago:">
      {paymentMethod.name || '-'}
    </ResourceProperty>
    <ResourceProperty label="Pagó al Crédito:">
      <Checkbox checked={creditPayment || false} />
    </ResourceProperty>
    <ResourceProperty label="Número de Operación:">
      {operationCode || '-'}
    </ResourceProperty>
    <ResourceProperty label="Comentario sobre el crédito o el pago:">
      {creditComment || '-'}
    </ResourceProperty>
  </Fragment>
);

ContractAmountInformationResource.propTypes =
  privateServiceContractAmountPropTypes;

ContractAmountInformationResource.defaultProps =
  privateServiceContractAmountDefaultProps;

export default ContractAmountInformationResource;
