import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getCompanyCreditNote,
  clearCompanyCreditNote,
} from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import { COMPANY_CREDIT_NOTE_PATH } from '../../../../config/paths';
import DateCell from '../../../common/cells/DateCell';
import IsValid from '../../../common/cells/IsValid';
import Table from '../../../common/Table';
import Content from '../../../layout/Content';
import { numberFormatter } from '../../../../utils/number';

class CompanyCreditNote extends Component {
  componentDidMount() {
    const {
      dispatchGetCompanyCreditNote,
      match: {
        params: { id: companyCreditNoteId },
      },
    } = this.props;
    dispatchGetCompanyCreditNote({ companyCreditNoteId });
  }

  componentWillUnmount() {
    const { dispatchClearCompanyCreditNote } = this.props;
    dispatchClearCompanyCreditNote();
  }

  render() {
    const { breadcrumbs, companyCreditNote, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (companyCreditNote.isEmpty()) {
      content = <NoDataResource returnPage={COMPANY_CREDIT_NOTE_PATH} />;
    } else {
      content = (
        <Fragment>
          <ResourceProperty label="Cliente:">
            {companyCreditNote.get('customer').fullName}
          </ResourceProperty>
          <ResourceProperty label="PIN:">
            {companyCreditNote.get('reference')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {companyCreditNote.get('description')}
          </ResourceProperty>
          <ResourceProperty label="Monto:">
            {numberFormatter({ value: companyCreditNote.get('amount') })}
          </ResourceProperty>
          <ResourceProperty label="Saldo:">
            {companyCreditNote.get('balance')
              ? numberFormatter({ value: companyCreditNote.get('balance') })
              : null}
          </ResourceProperty>
          <ResourceProperty label="Fecha de Expiración:">
            <DateCell value={companyCreditNote.get('expirationDate')} />
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            <IsValid value={companyCreditNote.get('isValid')} />
          </ResourceProperty>
          <h3>Transacciones</h3>
          <Table
            columns={[
              {
                Header: 'Fecha',
                accessor: 'transaction.createDate',
                Cell: (row) => <DateCell value={row.value} />,
                className: 'text-center',
              },
              {
                Header: 'Usado',
                accessor: 'transaction.amount',
                Cell: (row) => numberFormatter({ value: row.value }),
                className: 'text-center',
              },
            ]}
            data={companyCreditNote.get('creditNoteTransactionList')}
            defaultPageSize={
              companyCreditNote.get('creditNoteTransactionList').length
            }
            showPagination={false}
          />
        </Fragment>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Nota de Credito"
        content={content}
      />
    );
  }
}

CompanyCreditNote.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCompanyCreditNote: PropTypes.func.isRequired,
  dispatchClearCompanyCreditNote: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  companyCreditNote: PropTypes.instanceOf(Immutable.Map).isRequired,
};

CompanyCreditNote.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Notas de Crédito',
      href: COMPANY_CREDIT_NOTE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  companyCreditNote: ContractUnit.CompanyCreditNote.getIn([
    'current',
    'content',
  ]),
  loading: !ContractUnit.CompanyCreditNote.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCompanyCreditNote: getCompanyCreditNote,
  dispatchClearCompanyCreditNote: clearCompanyCreditNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreditNote);
