import Immutable from 'immutable';
import {
  HOME_PATH,
  SALES_SESSION_PATH,
  DEPOSIT_PATH,
  PRICING_PROFILE_PATH,
  LIQUIDATION_SESSION_PATH,
  GENERAL_INCOME_PATH,
  DISCOUNT_TO_BENEFICIARIES_PATH,
  EXPENSE_STATUS_PATH,
  CREDIT_NOTE_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'pricing-profiles',
      title: 'Perfiles de Precios',
      path: PRICING_PROFILE_PATH,
      icon: 'list-ul',
    },
    {
      key: 'sales-session',
      title: 'Sesiones de Ventas',
      path: SALES_SESSION_PATH,
      icon: 'calculator',
    },
    {
      key: 'customer-orders',
      title: 'Órdenes',
      path: '/sales/customer-orders',
      icon: 'book',
      disabled: true,
    },
    {
      key: 'discount-codes',
      title: 'Códigos de Descuento',
      path: '/sales/discount-codes',
      icon: 'minus-square-o',
      disabled: true,
    },
    {
      key: 'expenses',
      title: 'Egresos',
      path: '/sales/expense',
      icon: 'usd',
    },
    {
      key: 'liquidation-session',
      title: 'Liquidaciones de Agencia',
      path: LIQUIDATION_SESSION_PATH,
      icon: 'legal',
    },
    {
      key: 'credit-notes',
      title: 'Notas de Crédito',
      path: CREDIT_NOTE_PATH,
      icon: 'credit-card-alt',
    },
    {
      key: 'general-income',
      title: 'Ingresos',
      path: GENERAL_INCOME_PATH,
      icon: 'usd',
    },
    {
      key: 'deposits',
      title: 'Depósitos',
      path: DEPOSIT_PATH,
      icon: 'usd',
    },
    {
      key: 'discount-to-beneficiaries',
      title: 'Descuento para Beneficiaros',
      path: DISCOUNT_TO_BENEFICIARIES_PATH,
      icon: 'file-excel-o',
    },
    {
      key: 'expense-authorization',
      title: 'Autorización de Egresos',
      path: EXPENSE_STATUS_PATH,
      icon: 'lock',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Ventas',
      tag: 'span',
      href: '/sales',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
