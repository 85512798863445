import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  NEW_PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
  NEW_PROFILE_AUTHORIZED_BY_PROFILE_PATH,
  PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
  PROFILE_AUTHORIZED_BY_PROFILE_PATH,
  SECURITY_UNIT_PATH,
} from '../../../config/paths';
import SecurityHome from './SecurityHome';
import AuthorizedProfilesByProfile from './security/AuthorizedProfilesByProfile';
import NewAuthorizedProfileByProfile from './security/NewAuthorizedProfileByProfile';
import AuthorizedProfileByProfile from './security/AuthorizedProfileByProfile';
import EditAuthorizedProfileByProfile from './security/EditAuthorizedProfileByProfile';
import PaymentMethodsForSecurityProfiles from './payment-method-security-profile/PaymentMethodsForSecurityProfiles';
import PaymentMethodForSecurityProfile from './payment-method-security-profile/PaymentMethodForSecurityProfile';
import NewPaymentMethodForSecurityProfile from './payment-method-security-profile/NewPaymentMethodForSecurityProfile';
import EditPaymentMethodForSecurityProfile from './payment-method-security-profile/EditPaymentMethodForSecurityProfile';

const SecurityUnit = () => (
  <Switch>
    <Route exact path={SECURITY_UNIT_PATH} component={SecurityHome} />
    <Route
      exact
      path={PROFILE_AUTHORIZED_BY_PROFILE_PATH}
      component={AuthorizedProfilesByProfile}
    />
    <Route
      exact
      path={NEW_PROFILE_AUTHORIZED_BY_PROFILE_PATH}
      component={NewAuthorizedProfileByProfile}
    />
    <Route
      exact
      path={`${PROFILE_AUTHORIZED_BY_PROFILE_PATH}/:id`}
      component={AuthorizedProfileByProfile}
    />
    <Route
      exact
      path={`${PROFILE_AUTHORIZED_BY_PROFILE_PATH}/:id/edit`}
      component={EditAuthorizedProfileByProfile}
    />
    <Route
      exact
      path={NEW_PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}
      component={NewPaymentMethodForSecurityProfile}
    />
    <Route
      exact
      path={PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}
      component={PaymentMethodsForSecurityProfiles}
    />
    <Route
      exact
      path={`${PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}/:id`}
      component={PaymentMethodForSecurityProfile}
    />
    <Route
      exact
      path={`${PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}/:id/edit`}
      component={EditPaymentMethodForSecurityProfile}
    />
  </Switch>
);

export default SecurityUnit;
