import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_ACTIVITIES,
  GET_ACTIVITIES,
  CLEAR_ACTIVITIES,
  FLAG_ACTIVITY_ACTIVITY,
  GET_ACTIVITY,
  CLEAR_ACTIVITY,
} from '../types/mechanical-maintenance';
import { ACTIVITY_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { ACTIVITY_PATH } from '../../config/paths';

const flagGettingActivities = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ACTIVITIES,
    payload: flag,
  });

const getActivities = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingActivities(true));
    const query = tableFilters;
    const url = `${ACTIVITY_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();

    dispatch({
      type: GET_ACTIVITIES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingActivities(false));
  }
};

const clearActivities = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVITIES,
  });
};

const flagActivityActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ACTIVITY_ACTIVITY,
    payload: flag,
  });

const getActivity =
  async ({ activityId }) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityActivity(true));
      const url = `${ACTIVITY_ENDPOINT}/${activityId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_ACTIVITY,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityActivity(false));
    }
  };

const clearActivity = () => (dispatch) =>
  dispatch({
    type: CLEAR_ACTIVITY,
  });

const createNewActivity = async ({
  name,
  description,
  factor,
  factorType,
  activityType,
  companyAreaId,
  companyActivityId,
  materialList,
  activityTaskList,
}) => {
  const payload = {
    name,
    description,
    factor,
    factorType,
    activityType,
    companyAreaId,
    companyActivityId,
    materialList,
    activityTaskList,
  };
  const url = ACTIVITY_ENDPOINT;
  const promise = await fetch(url, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(payload),
  });
  await isErrorResponse(promise);
  const activity = await promise.json();
  return activity;
};

const postActivity =
  async ({
    name,
    description,
    factor,
    factorType,
    activityType,
    companyAreaId,
    companyActivityId,
    materialList,
    activityTaskList,
    action,
    executionMethod,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityActivity(true));
      await createNewActivity({
        name,
        description,
        factor,
        factorType,
        activityType,
        companyAreaId,
        companyActivityId,
        materialList,
        activityTaskList,
        action,
        executionMethod,
      });
      dispatch(push(ACTIVITY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityActivity(false));
    }
  };

const deleteActivity =
  async ({ activityId }) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityActivity(true));
      const url = `${ACTIVITY_ENDPOINT}/${activityId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ACTIVITY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityActivity(false));
    }
  };

const putActivity =
  async (
    activityId,
    {
      name,
      description,
      factor,
      factorType,
      activityType,
      companyAreaId,
      companyActivityId,
      materialList,
      activityTaskList,
      action,
      executionMethod,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityActivity(true));
      const payload = {
        id: activityId,
        name,
        description,
        factor,
        factorType,
        activityType,
        companyAreaId,
        companyActivityId,
        materialList,
        activityTaskList,
        action,
        executionMethod,
      };
      const url = `${ACTIVITY_ENDPOINT}/${activityId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ACTIVITY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityActivity(false));
    }
  };

export {
  flagGettingActivities,
  getActivities,
  clearActivities,
  flagActivityActivity,
  getActivity,
  postActivity,
  clearActivity,
  deleteActivity,
  putActivity,
  createNewActivity,
};
