import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Button, Label } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import ExpenseTypeLabelSelect from '../../../common/forms/select/ExpenseTypeLabelSelect';
import { EXPENSE_TYPE_PATH } from '../../../../config/paths';
import CostCenterSelect from '../../../common/forms/select/CostCenterSelect';

class ExpenseTypeForm extends Component {
  onHandleSubmit = async (formValues) => {
    const { dispatchPush, createPayload, fetchFunction } = this.props;

    const payload = createPayload({
      formValues,
    });

    const newExpenseType = await fetchFunction(payload);

    if (newExpenseType) {
      // redirect to newly created resource
      dispatchPush(`${EXPENSE_TYPE_PATH}/${newExpenseType.id}`);
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Nombre" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Nombre"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Código de Egreso" required>
            <Field
              name="expenseCode"
              component={TextInput}
              type="text"
              placeholder="Código de Egreso"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Etiqueta de Tipo de Egreso" required>
            <Field
              name="expenseTypeLabel"
              component={ExpenseTypeLabelSelect}
              type="text"
              placeholder="Etiqueta de Tipo de Egreso"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="">
            <Label>
              <Field name="internal" component="input" type="checkbox" />{' '}
              Interno
            </Label>
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Centro de costo">
            <Field
              name="costCenter"
              component={CostCenterSelect}
              placeholder="Centro de costo"
              isClearable
            />
          </FormItem>
        </FormGroup>
        <div className="FormButtonGroup">
          <Button type="submit" color="primary" outline>
            Enviar
          </Button>
        </div>
      </Form>
    );
  }
}

ExpenseTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchPush: PropTypes.func.isRequired,
  fetchFunction: PropTypes.func.isRequired,
  createPayload: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPush: push,
};

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ExpenseForm',
  })(ExpenseTypeForm),
);
