import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getBusinesses, clearBusinesses } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';
import { isNumber } from '../../../../utils/number';

export const BusinessSelect = ({
  dispatchClearBusinesses,
  dispatchGetBusinesses,
  options,
  loading,
  noOptionsMessage,
  input,
  meta,
  isDisabled,
  isClearable,
  isMulti,
  placeholder,
}) => {
  useLayoutEffect(() => () => dispatchClearBusinesses(), []);

  const handleBusinessChange = (inputValue) => {
    if (inputValue.trim() && inputValue.trim().length >= 4) {
      const query = isNumber(inputValue)
        ? [`businessTaxId:${inputValue}`]
        : [`${inputValue}`];
      dispatchGetBusinesses({
        ...DEFAULT_QUERY,
        query,
      });
    }
  };

  const props = {
    options,
    onInputChange: handleBusinessChange,
    isLoading: loading,
    noOptionsMessage,
    input,
    meta,
    isDisabled,
    isClearable,
    isMulti,
    placeholder,
  };

  return <Select {...props} />;
};

BusinessSelect.propTypes = {
  dispatchGetBusinesses: PropTypes.func.isRequired,
  dispatchClearBusinesses: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
};

BusinessSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  loading: false,
  isMulti: false,
  placeholder: 'Ingrese RUC',
  isDisabled: false,
  options: [],
  isClearable: false,
  noOptionsMessage: undefined,
};

const mapStateToProps = ({ UserUnit }) => ({
  options: UserUnit.Business.getIn(['all', 'content', 'content']).map(
    ({
      id,
      businessTaxId,
      name,
      email,
      primaryContact,
      phone,
      fax,
      addressSummary,
      addressId,
      address,
    }) => ({
      value: id,
      label: `${businessTaxId} - ${name}`,
      businessTaxId,
      name,
      email,
      primaryContact,
      phone,
      fax,
      addressSummary,
      addressId,
      address,
    }),
  ),
  loading: UserUnit.Business.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetBusinesses: getBusinesses,
  dispatchClearBusinesses: clearBusinesses,
};

export default connect(mapStateToProps, mapDispatchToprops)(BusinessSelect);
