import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { change } from 'redux-form';
import ActivityBusFields from './ActivityBusFields';
import {
  deleteActivityBusExecutionEmployee,
  postActivityBusExecutionEmployee,
} from '../../../../../actions/mechanical-maintenance/ActivityBusExecutionEmployee';
import Alert from '../../../../common/informative/Alert';
import { SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE } from '../../../../../config/messages';

const RenderActivityBusFields = ({
  fields,
  maintenanceServiceOrder,
  dispatchPostActivityBusExecutionEmployee,
  dispatchDeleteActivityBusExecutionEmployee,
  dispatchChange,
}) => {
  const { activityBusExecutionList } = maintenanceServiceOrder.toJS();

  const onChangeMechanic = async (options, inputActionMeta, index) => {
    const { id: activityBusExecutionId } = activityBusExecutionList[index];
    if (inputActionMeta.action === 'select-option') {
      await dispatchPostActivityBusExecutionEmployee({
        activityBusExecutionId,
        employeeId: inputActionMeta.option.value,
      });
    }
    if (inputActionMeta.action === 'remove-value') {
      await dispatchDeleteActivityBusExecutionEmployee({
        activityBusExecutionId,
        employeeId: inputActionMeta.removedValue.value,
      });
    }
    // Changing values in reducer to manipulate it in RenderTaskList
    dispatchChange(
      'AssignEmployeesToMaintenanceServiceOrderForm',
      `activityBusExecutionList[${index}].employeeId`,
      options,
    );
  };

  return fields.length > 0 ? (
    fields.map((activity, index) => (
      <ActivityBusFields
        activity={activity}
        key={activity}
        index={index}
        fields={fields}
        onChange={onChangeMechanic}
      />
    ))
  ) : (
    <Alert
      type="danger"
      message={SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE}
    />
  );
};

RenderActivityBusFields.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPostActivityBusExecutionEmployee: PropTypes.func.isRequired,
  dispatchDeleteActivityBusExecutionEmployee: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
});

const mapDispatchToProps = {
  dispatchPostActivityBusExecutionEmployee: postActivityBusExecutionEmployee,
  dispatchDeleteActivityBusExecutionEmployee:
    deleteActivityBusExecutionEmployee,
  dispatchChange: change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenderActivityBusFields);
