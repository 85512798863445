import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
} from '../../../../actions/cargo/CargoCorporateCredit';
import CargoCorporateCreditInformationResource from './resource/CargoCorporateCreditInformationResource';
import CargoCorporateCreditToolBar from './CargoCorporateCreditToolBar';

export const CargoCorporateCredit = ({
  breadcrumbs,
  dispatchGetCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
  match: {
    params: { id: cargoCorporateCreditId },
  },
  loading,
  cargoCorporateCredit,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });

    return () => dispatchClearCargoCorporateCredit();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty())
    content = <NoDataResource returnPage={CARGO_CORPORATE_CREDIT_PATH} />;
  else {
    const cargoCorporateCreditJson = cargoCorporateCredit.toJS();

    content = (
      <CargoCorporateCreditInformationResource {...cargoCorporateCreditJson} />
    );

    toolbar = (
      <CargoCorporateCreditToolBar
        cargoCorporateCreditId={cargoCorporateCreditJson.id}
        initialBalance={cargoCorporateCreditJson.initialBalance}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Crédito Corporativo de Carga"
      content={content}
    />
  );
};

CargoCorporateCredit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
};

CargoCorporateCredit.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CargoCorporateCredit);
