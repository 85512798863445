import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsers, clearUsers } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';

export const UserSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearUsers,
  dispatchGetUsers,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearUsers(), []);

  const onInputChangeUser = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      const index = inputValue.indexOf(',');

      let query = [`customer.firstName:${inputValue}`];

      if (index >= 0) {
        const { length } = inputValue;
        const firstName = inputValue.substring(0, index).trim();
        const lastName = inputValue.substring(index + 1, length).trim();
        query = [];
        if (firstName.length) query.push(`customer.firstName:${firstName}`);
        if (lastName.length) query.push(`customer.lastName:${lastName}`);
      }

      dispatchGetUsers({ query });
    }
  };

  const filterOption = (users) => users;

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeUser}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      filterOption={filterOption}
      {...rest}
    />
  );
};

UserSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetUsers: PropTypes.func.isRequired,
  dispatchClearUsers: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

UserSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca así: nombre,apellido',
};

const mapStateToProps = ({ UserUnit }) => ({
  options: UserUnit.User.getIn(['all', 'content', 'content']).map((user) => ({
    value: user.id,
    label: user.customer.fullName,
  })),
  loading: UserUnit.User.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetUsers: getUsers,
  dispatchClearUsers: clearUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
