import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ManualVoucherEditButton from './ManualVoucherEditButton';
import PrintManualVoucherButton from './PrintManualVoucherButton';
import { manualVoucherDataPropTypes } from './proptypes/ManualVoucherPropTypes';

const ManualVoucherToolbar = ({ manualVoucher }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PrintManualVoucherButton manualVoucher={manualVoucher} />{' '}
      <ManualVoucherEditButton manualVoucherId={manualVoucher.id} />
    </div>
  </ButtonToolbar>
);

ManualVoucherToolbar.propTypes = {
  manualVoucher: PropTypes.shape(manualVoucherDataPropTypes).isRequired,
};

export default ManualVoucherToolbar;
