import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getServiceTypes,
  clearErrorGettingServiceTypes,
} from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';

class ServiceTypeSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearErrorGettingServiceTypes } = this.props;
    dispatchClearErrorGettingServiceTypes();
  }

  handleInputChange = (inputValue) => {
    const { dispatchGetServiceTypes } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetServiceTypes(inputValue);
    }
  };

  render() {
    const {
      loading,
      options,
      isGridVariant,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        onInputChange={this.handleInputChange}
        options={options}
        isMulti={isMulti}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

ServiceTypeSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetServiceTypes: PropTypes.func.isRequired,
  dispatchClearErrorGettingServiceTypes: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
};

ServiceTypeSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
};

const mapStateToProps = ({ BusUnit }) => ({
  options: BusUnit.ServiceType.getIn(['all', 'content', 'content']).map(
    (service) => ({
      value: service.id,
      label: service.name,
    }),
  ),
  loading: BusUnit.ServiceType.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetServiceTypes: getServiceTypes,
  dispatchClearErrorGettingServiceTypes: clearErrorGettingServiceTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTypeSelect);
