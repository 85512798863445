import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { BUSINESS_PATH } from '../../../../config/paths';
import { clearBusiness, getBusiness } from '../../../../actions/user';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import BusinessBasicInformationResource from './resource/BusinessBasicInformationResource';
import BusinessContactInformationResource from './resource/BusinessContactInformationResource';
import AddressResource from '../../../common/resource/AddressResource';
import BusinessToolbar from './BusinessToolbar';

const Business = ({
  breadcrumbs,
  dispatchGetBusiness,
  dispatchClearBusiness,
  match: {
    params: { id: businessId },
  },
  loading,
  business,
}) => {
  useLayoutEffect(() => {
    dispatchGetBusiness({ businessId });

    return () => dispatchClearBusiness();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (business.isEmpty())
    content = <NoDataResource returnPage={BUSINESS_PATH} />;
  else {
    const businessJson = business.toJS();

    content = (
      <>
        <Row>
          <Col md={6} className="mb-2">
            <h3>Información Básica</h3>
            <BusinessBasicInformationResource {...businessJson} />
            <h3>Información de Contacto</h3>
            <BusinessContactInformationResource {...businessJson} />
          </Col>
          <Col md={6} className="mb-2">
            <h3>Dirección</h3>
            <AddressResource address={businessJson.address} />
          </Col>
        </Row>
      </>
    );

    toolbar = <BusinessToolbar businessId={businessJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Empresa"
      content={content}
    />
  );
};

Business.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearBusiness: PropTypes.func.isRequired,
  dispatchGetBusiness: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  business: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Business.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearBusiness: clearBusiness,
  dispatchGetBusiness: getBusiness,
};

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas',
      href: BUSINESS_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  business: UserUnit.Business.getIn(['current', 'content']),
  loading: !UserUnit.Business.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Business);
