import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import DepositEditButton from './DepositEditButton';
import DepositDeleteButton from './DepositDeleteButton';

const DepositToolbar = ({ depositId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <DepositEditButton depositId={depositId} />{' '}
      <DepositDeleteButton depositId={depositId} />
    </div>
  </ButtonToolbar>
);

DepositToolbar.propTypes = {
  depositId: PropTypes.number.isRequired,
};

export default DepositToolbar;
