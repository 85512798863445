import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../../common/Loader';
import { fuelConsumptionReportPerBusPropTypes } from './FuelConsumptionReportPerBusPropTypes';
import BusFuelReportHeaderResource from './BusFuelReportHeaderResource';
import BusFuelReportCircuitRowListResource from './BusFuelReportCircuitRowListResource';
import BusFuelReportRouteRowListResource from './BusFuelReportRouteRowListResource';

import './BusFuelReportHeaderResource.css';

const FuelConsumptionReportePerBusSearchResult = ({
  loading,
  fuelReportPerBus,
  selectedBuses,
}) => {
  if (loading) {
    return <Loader />;
  }

  const reportHeader = fuelReportPerBus.get('reportHeader');
  const busFuelReportCircuitRowList = fuelReportPerBus.get(
    'busFuelReportCircuitRowList',
  );
  const busFuelReportRouteRowList = fuelReportPerBus.get(
    'busFuelReportRouteRowList',
  );

  return (
    <div className="mt-4">
      <BusFuelReportHeaderResource
        data={reportHeader}
        selectedBuses={selectedBuses}
      />
      <BusFuelReportCircuitRowListResource data={busFuelReportCircuitRowList} />
      <BusFuelReportRouteRowListResource data={busFuelReportRouteRowList} />
    </div>
  );
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.FuelConsumptionReportPerBus.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  fuelReportPerBus: TrafficUnit.FuelConsumptionReportPerBus.getIn([
    'current',
    'content',
  ]),
});

FuelConsumptionReportePerBusSearchResult.propTypes = {
  loading: PropTypes.bool,
  selectedBuses: PropTypes.bool.isRequired,
  fuelReportPerBus: fuelConsumptionReportPerBusPropTypes,
};

FuelConsumptionReportePerBusSearchResult.defaultProps = {
  loading: false,
  fuelReportPerBus: null,
};

export default connect(mapStateToProps)(
  FuelConsumptionReportePerBusSearchResult,
);
