import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, FormGroup, Col } from 'reactstrap';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import { isRequired } from '../../../../utils/validators';

const MaintenanceWarningRejectedForm = ({ handleSubmit, onCancel }) => (
  <>
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Col>
          <Field
            name="statusComment"
            component={TextInput}
            type="textarea"
            validate={[isRequired]}
            placeholder="Ingrese un comentario"
            maxLength={0}
          />
        </Col>
      </FormGroup>
      <FormFooter
        saveButtonColor="secondary"
        saveButtonText="Continuar"
        saveButtonIcon={null}
      >
        <Button color="danger" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  </>
);

MaintenanceWarningRejectedForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ChangeStatusMaintenanceWarningForm',
})(MaintenanceWarningRejectedForm);
