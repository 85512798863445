import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, Form, change } from 'redux-form';
import { FormGroup, Label, InputGroupText } from 'reactstrap';
import FormItem from '../../../../common/forms/FormItem';
import { isRequired } from '../../../../../utils/validators';
import TextInput from '../../../../common/forms/input/TextInput';
import Loader from '../../../../common/Loader';
import {
  DATE_TIME_FORMAT,
  INT_DATE_FORMAT,
  INT_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../../../../config/locale';
import DatePicker from '../../../../common/forms/input/DatePicker';
import RouteSelect from '../../../../common/forms/select/RouteSelect';
import ServiceTypeSelect from '../../../../common/forms/select/ServiceTypeSelect';
import CircuitSelect from '../../../../common/forms/select/CircuitSelect';
import PricingProfileSelect from '../../../../common/forms/select/PricingProfileSelect';
import SeatMapSelect from '../../../../common/forms/select/SeatMapSelect';
import DriverCommissionSelect from '../../../../common/forms/select/DriverCommissionSelect';
import CabinCrewCommissionSelect from '../../../../common/forms/select/CabinCrewCommissionSelect';
import Select from '../../../../common/forms/select/Select';
import {
  ITINERARY_REPEAT_TYPE_OPTIONS,
  ITINERARY_REPEAT_TYPE,
  ITINERARY_TYPE_OPTIONS,
} from '../../../../../config/constants';
import TimetableTemplateEditable from '../TimetableTemplateEditable';
import Alert from '../../../../common/informative/Alert';
import { timeFormat, tzNormalizeDate } from '../../../../../utils/date';
import CompanySelect from '../../../../common/forms/select/CompanySelect';
import TimeInput from '../../../../common/forms/input/TimeInput';
import FormFooter from '../../../../common/forms/FormFooter';

class ItineraryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frecuency: 'Ninguno',
      repeatType: ITINERARY_REPEAT_TYPE.NO_REPEAT.value,
      routeSegmentList: null,
      durationAdjustmentList: [],
      layoverList: [],
      departureTime: null,
      startDate: null,
      newRouteSegmentList: [],
      isMaciva: false,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const {
      initialValues: {
        departureTime,
        startDate,
        repeatType,
        routeSegmentList,
        isMaciva,
        durationAdjustmentList,
        layoverList,
      },
    } = this.props;

    const state = {
      departureTime,
      startDate,
      routeSegmentList,
      isMaciva,
    };

    if (repeatType) {
      state.repeatType = repeatType.value;
      state.frecuency = repeatType.unit;
    }

    if (durationAdjustmentList)
      state.durationAdjustmentList = durationAdjustmentList;

    if (layoverList) state.layoverList = layoverList;

    if (!isMaciva) {
      this.generateTimetableData(
        routeSegmentList,
        departureTime,
        startDate,
        durationAdjustmentList,
        layoverList,
      );
    }

    this.setState(state);
  };

  onHandleSubmit = (formValues) => {
    const { onSubmit } = this.props;

    const { newRouteSegmentList } = this.state;

    const newFormValues = { ...formValues };

    newFormValues.itineraryScheduleSegmentList = newRouteSegmentList;

    onSubmit(newFormValues);
  };

  onValueChangeDurationAdjustment = ({ id, value }) => {
    const {
      routeSegmentList,
      departureTime,
      startDate,
      durationAdjustmentList,
      layoverList,
    } = this.state;

    durationAdjustmentList[id] = value;

    this.setState({ durationAdjustmentList });

    this.generateTimetableData(
      routeSegmentList,
      departureTime,
      startDate,
      durationAdjustmentList,
      layoverList,
    );
  };

  onValueChangeLayover = ({ id, value }) => {
    const {
      routeSegmentList,
      departureTime,
      startDate,
      durationAdjustmentList,
      layoverList,
    } = this.state;

    layoverList[id] = value;

    this.setState({ layoverList });

    this.generateTimetableData(
      routeSegmentList,
      departureTime,
      startDate,
      durationAdjustmentList,
      layoverList,
    );
  };

  onChangeRepeatType = (type) =>
    this.setState({ frecuency: type.unit, repeatType: type.value });

  onChangeRoute = (route) => {
    const routeSegmentList = route ? route.routeSegmentList : null;

    this.setState({ routeSegmentList });

    if (routeSegmentList) {
      const { departureTime, startDate, durationAdjustmentList, layoverList } =
        this.state;

      this.generateTimetableData(
        routeSegmentList,
        departureTime,
        startDate,
        durationAdjustmentList,
        layoverList,
      );
    }
  };

  onChangeDepartureTime = (departureTime) => {
    const { routeSegmentList, startDate, durationAdjustmentList, layoverList } =
      this.state;

    const { dispatchChange } = this.props;

    dispatchChange('ItineraryForm', 'departureTime', departureTime);

    this.setState({ departureTime });

    this.generateTimetableData(
      routeSegmentList,
      departureTime,
      startDate,
      durationAdjustmentList,
      layoverList,
    );
  };

  onChangeMaciva = ({ target: { checked } }) => {
    this.setState({ isMaciva: checked });
  };

  onChangeStartDate = (startDate) => {
    const {
      routeSegmentList,
      departureTime,
      durationAdjustmentList,
      layoverList,
    } = this.state;

    const { dispatchChange } = this.props;

    dispatchChange('ItineraryForm', 'startDate', startDate);

    this.setState({ startDate });

    this.generateTimetableData(
      routeSegmentList,
      departureTime,
      startDate,
      durationAdjustmentList,
      layoverList,
    );
  };

  generateTimetableData = (
    routeSegmentList,
    departureTime,
    startDate,
    durationAdjustmentList,
    layoverList,
  ) => {
    const timetableData = [];

    const formattedDepartureTime = departureTime
      ? tzNormalizeDate({ date: departureTime, format: TIME_FORMAT })
      : null;
    const formattedStartDate = startDate
      ? tzNormalizeDate({ date: startDate, format: INT_DATE_FORMAT })
      : null;

    let lastArrivalTimeWithoutFormat = null;
    let lastDurationAdjusment = 0;
    let lastLayover = 0;

    if (routeSegmentList) {
      routeSegmentList.forEach(
        (
          {
            segment: {
              id: segmentId,
              sourceLocation,
              destinationLocation,
              duration,
            },
          },
          index,
          array,
        ) => {
          const timetable = {
            segmentId,
            sourceLocationName: sourceLocation.name,
            destinationLocationName: destinationLocation.name,
            duration: timeFormat(duration),
            startOffset: '-',
            departureTime: '-',
            arrivalTime: '-',
          };

          if (startDate && departureTime) {
            if (index === 0) {
              const date = formattedStartDate;

              const time = formattedDepartureTime;

              timetable.startOffset = 0;

              timetable.departureTimeWithoutFormat = `${date} ${time}`;

              timetable.departureTime = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
              });

              timetable.departureTimeFormatted = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                format: DATE_TIME_FORMAT,
              });
            } else {
              const { segment } = array[index - 1];

              const { startOffset: lastStartOffset } = timetableData[index - 1];

              timetable.startOffset =
                segment.duration +
                lastDurationAdjusment +
                lastLayover +
                lastStartOffset;

              timetable.departureTimeWithoutFormat =
                lastArrivalTimeWithoutFormat;

              timetable.departureTime = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                addTime: { amount: lastLayover, unit: 'minutes' },
              });

              timetable.departureTimeFormatted = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                addTime: { amount: lastLayover, unit: 'minutes' },
                format: DATE_TIME_FORMAT,
              });
            }

            lastDurationAdjusment = durationAdjustmentList[segmentId]
              ? Number(durationAdjustmentList[segmentId])
              : 0;

            timetable.segmentAdjustment = lastDurationAdjusment;

            timetable.arrivalTimeWithoutFormat = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: {
                amount: duration + timetable.segmentAdjustment + lastLayover,
                unit: 'minutes',
              },
              format: INT_DATE_TIME_FORMAT,
            });

            timetable.arrivalTime = tzNormalizeDate({
              date: timetable.arrivalTimeWithoutFormat,
            });

            lastArrivalTimeWithoutFormat = timetable.arrivalTimeWithoutFormat;

            timetable.arrivalTimeFormatted = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: {
                amount: duration + timetable.segmentAdjustment + lastLayover,
                unit: 'minutes',
              },
              format: DATE_TIME_FORMAT,
            });

            lastLayover = layoverList[segmentId]
              ? Number(layoverList[segmentId])
              : 0;

            timetable.layover = lastLayover;
          }

          timetableData.push(timetable);
        },
      );
    }

    this.setState({ newRouteSegmentList: timetableData });
  };

  render() {
    const {
      loading,
      handleSubmit,
      initialValues: { itineraryCode },
    } = this.props;

    const {
      frecuency,
      repeatType,
      routeSegmentList,
      newRouteSegmentList,
      startDate,
      departureTime,
      isMaciva,
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    const inputGroupAppend = <InputGroupText>{frecuency}</InputGroupText>;

    const repeatEveryField = (
      <FormGroup row>
        <FormItem label="Repetir cada" required>
          <Field
            name="repeatEvery"
            component={TextInput}
            type="text"
            placeholder="Frecuencia"
            append={inputGroupAppend}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    );

    const endDateField = (
      <FormGroup row>
        <FormItem label="Fecha de Fin" required>
          <Field
            name="endDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    );

    let frecuencyFields = null;

    if (repeatType === ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.value) {
      frecuencyFields = (
        <Fragment>
          {endDateField}
          <FormGroup row>
            <FormItem label="Días">
              <Label>
                <Field name="monday" component="input" type="checkbox" /> Lunes
              </Label>{' '}
              <br />
              <Label>
                <Field name="tuesday" component="input" type="checkbox" />{' '}
                Martes
              </Label>
              <br />
              <Label>
                <Field name="wednesday" component="input" type="checkbox" />{' '}
                Miércoles
              </Label>
              <br />
              <Label>
                <Field name="thursday" component="input" type="checkbox" />{' '}
                Jueves
              </Label>
              <br />
              <Label>
                <Field name="friday" component="input" type="checkbox" />{' '}
                Viernes
              </Label>
              <br />
              <Label>
                <Field name="saturday" component="input" type="checkbox" />{' '}
                Sábado
              </Label>
              <br />
              <Label>
                <Field name="sunday" component="input" type="checkbox" />{' '}
                Domingo
              </Label>
            </FormItem>
          </FormGroup>
          {repeatEveryField}
        </Fragment>
      );
    }

    if (repeatType === ITINERARY_REPEAT_TYPE.MONTHLY_REPEAT.value) {
      frecuencyFields = (
        <Fragment>
          {endDateField}
          <FormGroup row>
            <FormItem>
              <Label>
                <Field
                  name="repeatByDayOfMonth"
                  component="input"
                  type="checkbox"
                />{' '}
                Repetir por día del mes
              </Label>
            </FormItem>
          </FormGroup>
          {repeatEveryField}
        </Fragment>
      );
    }

    if (
      repeatType === ITINERARY_REPEAT_TYPE.DAILY_REPEAT.value ||
      repeatType === ITINERARY_REPEAT_TYPE.YEARLY_REPEAT.value
    ) {
      frecuencyFields = (
        <Fragment>
          {endDateField}
          {repeatEveryField}
        </Fragment>
      );
    }

    let timetableResource = (
      <Alert message="Seleccione una ruta y fecha de inicio" />
    );

    if (routeSegmentList) {
      timetableResource = (
        <TimetableTemplateEditable
          routeSegmentList={newRouteSegmentList}
          onValueChangeLayover={this.onValueChangeLayover}
          onValueChangeDurationAdjustment={this.onValueChangeDurationAdjustment}
          setInitialValues={!!itineraryCode}
        />
      );
    }

    let pricingProfileField = null;

    let frecuencySection = null;

    if (!isMaciva) {
      pricingProfileField = (
        <FormGroup row>
          <FormItem label="Perfil de Precio">
            <Field
              name="pricingProfileId"
              component={PricingProfileSelect}
              isMulti
              isClearable
            />
          </FormItem>
        </FormGroup>
      );

      frecuencySection = (
        <Fragment>
          <h3>Frecuencia</h3>
          <FormGroup row>
            <FormItem label="Tipo de Repetición" required>
              <Field
                name="repeatType"
                component={Select}
                isClearable={false}
                options={ITINERARY_REPEAT_TYPE_OPTIONS}
                onChange={this.onChangeRepeatType}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Hora de Salida" required>
              <Field
                name="departureTime"
                component={TimeInput}
                input={{
                  onChange: this.onChangeDepartureTime,
                  value: departureTime,
                }}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Fecha de Inicio" required>
              <Field
                name="startDate"
                component={DatePicker}
                input={{
                  onChange: this.onChangeStartDate,
                  value: startDate,
                }}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          {frecuencyFields}
          <h3>Horarios</h3>
          {timetableResource}
        </Fragment>
      );
    }

    let itineraryScheduleVersionField = null;

    if (itineraryCode) {
      itineraryScheduleVersionField = (
        <FormGroup row>
          <FormItem label="Versión de Plantilla de Itinerario" required>
            <Field
              name="itineraryScheduleVersion"
              component={TextInput}
              disabled
              type="text"
              placeholder="Versión de Plantilla de Itinerario"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      );
    }

    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <h3>Itinerario</h3>
        <FormGroup row>
          <FormItem label="Código de Itinerario" required>
            <Field
              name="itineraryCode"
              component={TextInput}
              disabled={!!itineraryCode}
              type="text"
              placeholder="Código de Itinerario"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {itineraryScheduleVersionField}
        <FormGroup row>
          <FormItem label="Nombre" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Nombre"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="text"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="isMaciva"
                onChange={this.onChangeMaciva}
                component="input"
                type="checkbox"
              />{' '}
              MACIVA
            </Label>
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Ruta" required>
            <Field
              name="routeId"
              component={RouteSelect}
              validate={[isRequired]}
              onChange={this.onChangeRoute}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Tipo de Servicio" required>
            <Field
              name="serviceTypeId"
              component={ServiceTypeSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Mapa de Asientos" required>
            <Field
              name="seatMapId"
              component={SeatMapSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {pricingProfileField}
        <FormGroup row>
          <FormItem label="Comisión del Conductor" required>
            <Field
              name="driverCommissionId"
              component={DriverCommissionSelect}
              validate={[isRequired]}
              isClearable
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Circuito" required>
            <Field
              name="circuitId"
              component={CircuitSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Tipo de Itinerario" required>
            <Field
              name="type"
              component={Select}
              options={ITINERARY_TYPE_OPTIONS}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comisión del Tripulante">
            <Field
              name="cabinCrewCommissionId"
              component={CabinCrewCommissionSelect}
              isClearable
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Compañia">
            <Field name="companyId" component={CompanySelect} isClearable />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Estado">
            <Label>
              <Field name="active" component="input" type="checkbox" /> Activo
            </Label>
          </FormItem>
        </FormGroup>
        {frecuencySection}
        <FormFooter />
      </Form>
    );
  }
}

ItineraryForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    departureTime: PropTypes.instanceOf(Date),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    // eslint-disable-next-line react/forbid-prop-types
    routeSegmentList: PropTypes.arrayOf(PropTypes.object),
    layoverList: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    durationAdjustmentList: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    isMaciva: PropTypes.bool,
    repeatType: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unit: PropTypes.string,
    }),
    itineraryCode: PropTypes.string,
  }),
  dispatchChange: PropTypes.func.isRequired,
};

ItineraryForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = { dispatchChange: change };

const formComponent = reduxForm({
  form: 'ItineraryForm',
})(ItineraryForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
