import { CURRENCY_CODE } from '../config/constants';
import { PERUVIAN_LOCALE } from '../config/locale';

const hasDigit = (string) => /\d/.test(string);

const isNumber = (value) => !Number.isNaN(Number(value));

const numberFormatter = ({
  style = 'currency',
  value = 0,
  locale = PERUVIAN_LOCALE,
  currency = CURRENCY_CODE.PEN.value,
  digits,
  ...rest
}) => {
  const defaultOptions = {
    style,
    minimumFractionDigits: 2,
    ...rest,
  };

  let options = { ...defaultOptions };
  if (style === 'currency')
    options = {
      ...defaultOptions,
      currency,
      currencyDisplay: 'symbol',
    };

  if (digits !== undefined) {
    const numberString = String(style === 'percent' ? value / 100 : value);
    return numberString.padStart(digits, '0');
  }

  return new Intl.NumberFormat(locale, options).format(
    style === 'percent' ? value / 100 : value,
  );
};

const currencyToNumber = (value) =>
  typeof value === 'string' ? Number(value.replace(/[^0-9.-]+/g, '')) : value;

export { hasDigit, isNumber, numberFormatter, currencyToNumber };
