import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BASE_FUEL_REFILL_PATH } from '../../../../config/paths';
import {
  clearBaseFuelRefill,
  getBaseFuelRefill,
} from '../../../../actions/traffic';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import BaseFuelRefillBasicInformationResource from './resource/BaseFuelRefillBasicInformationResource';
import BaseFuelRefillToolbar from './BaseFuelRefillToolbar';

export const BaseFuelRefill = ({
  breadcrumbs,
  dispatchGetBaseFuelRefill,
  dispatchClearBaseFuelRefill,
  match: {
    params: { id: baseFuelRefillId },
  },
  loading,
  baseFuelRefill,
}) => {
  useLayoutEffect(() => {
    dispatchGetBaseFuelRefill({ baseFuelRefillId });

    return () => dispatchClearBaseFuelRefill();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (baseFuelRefill.isEmpty())
    content = <NoDataResource returnPage={BASE_FUEL_REFILL_PATH} />;
  else {
    const baseFuelRefillJson = baseFuelRefill.toJS();

    content = (
      <>
        <h3>Información Básica</h3>
        <BaseFuelRefillBasicInformationResource {...baseFuelRefillJson} />
      </>
    );

    toolbar = (
      <BaseFuelRefillToolbar baseFuelRefillId={baseFuelRefillJson.id} />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Relleno de Combustible en Base"
      content={content}
    />
  );
};

BaseFuelRefill.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearBaseFuelRefill: PropTypes.func.isRequired,
  dispatchGetBaseFuelRefill: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  baseFuelRefill: PropTypes.instanceOf(Immutable.Map).isRequired,
};

BaseFuelRefill.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearBaseFuelRefill: clearBaseFuelRefill,
  dispatchGetBaseFuelRefill: getBaseFuelRefill,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos de Combustible en Base',
      href: BASE_FUEL_REFILL_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  baseFuelRefill: TrafficUnit.BaseFuelRefill.getIn(['current', 'content']),
  loading: !TrafficUnit.BaseFuelRefill.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseFuelRefill);
