import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MANUAL_VOUCHER_PATH } from '../../../../config/paths';

const ManualVoucherEditButton = ({ manualVoucherId, disabled }) =>
  disabled ? (
    <Button color="primary" disabled>
      <i className="fa fa-pencil-square-o" /> Editar
    </Button>
  ) : (
    <Link
      className="btn btn-primary"
      to={`${MANUAL_VOUCHER_PATH}/${manualVoucherId}/edit`}
    >
      <i className="fa fa-pencil-square-o" /> Editar
    </Link>
  );

ManualVoucherEditButton.propTypes = {
  manualVoucherId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

ManualVoucherEditButton.defaultProps = {
  disabled: false,
};

export default ManualVoucherEditButton;
