import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
} from '../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';
import MaintenanceServiceOrderToolbar from './MaintenanceServiceOrderToolbar';
import MaintenanceServiceOrderBasicInformation from './resource/MaintenanceServiceOrderBasicInformationResource';
import MaintenanceServiceOrderInternalInformation from './resource/MaintenanceServiceOrderInternalInformation';
import MaintenanceServiceOrderActivityBusExecutionListInformation from './resource/MaintenanceServiceOrderActivityBusExecutionListInformation';
import MaintenanceServiceOrderMaintenanceWarningListInformation from './resource/MaintenanceServiceOrderMaintenanceWarningListInformation';

const MaintenanceServiceOrder = ({
  match: {
    params: { id: maintenanceServiceOrderId },
  },
  dispacthGetMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder,
  maintenanceServiceOrder,
  loading,
  breadcrumbs,
}) => {
  useLayoutEffect(() => {
    dispacthGetMaintenanceServiceOrder({ maintenanceServiceOrderId });

    return () => dispacthClearMaintenanceServiceOrder();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_SERVICE_ORDER_PATH} />;
  else {
    const maintenanceServiceOrderJson = maintenanceServiceOrder.toJS();

    toolbar = (
      <MaintenanceServiceOrderToolbar
        maintenanceServiceOrderId={+maintenanceServiceOrderId}
      />
    );

    content = (
      <>
        <Row>
          <Col md={6}>
            <h3>Información Básica</h3>
            <MaintenanceServiceOrderBasicInformation
              {...maintenanceServiceOrderJson}
            />
          </Col>
          <Col md={6}>
            <h3>Información Interna</h3>
            <MaintenanceServiceOrderInternalInformation
              {...maintenanceServiceOrderJson}
            />
          </Col>
        </Row>
        <div className="mb-2">
          <h3>Lista de Reportes de Ocurrencia</h3>
          <MaintenanceServiceOrderMaintenanceWarningListInformation
            {...maintenanceServiceOrderJson}
          />
        </div>
        <h3>Lista de Actividades</h3>
        <MaintenanceServiceOrderActivityBusExecutionListInformation
          {...maintenanceServiceOrderJson}
        />
      </>
    );
  }

  return (
    <Content
      title="Orden de Servicio"
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      content={content}
    />
  );
};

MaintenanceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  dispacthGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

MaintenanceServiceOrder.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispacthGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceServiceOrder);
