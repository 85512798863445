import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import PaymentMethodForSecurityProfileInformationResource from './resource/PaymentMethodForSecurityProfileInformationResource';
import {
  clearPaymentMethodForSecurityProfile,
  getPaymentMethodForSecurityProfile,
} from '../../../../actions/security/PaymentMethodForSecurityProfile';
import PaymentMethodForSecurityProfileToolBar from './PaymentMethodForSecurityProfileToolBar';

export const PaymentMethodForSecurityProfile = ({
  breadcrumbs,
  dispatchGetPaymentMethodForSecurityProfile,
  dispatchClearPaymentMethodForSecurityProfile,
  match: {
    params: { id: paymentMethodForSecurityProfileId },
  },
  loading,
  paymentMethodForSecurityProfile,
}) => {
  useLayoutEffect(() => {
    dispatchGetPaymentMethodForSecurityProfile({
      paymentMethodForSecurityProfileId,
    });

    return () => dispatchClearPaymentMethodForSecurityProfile();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (paymentMethodForSecurityProfile.isEmpty())
    content = (
      <NoDataResource returnPage={PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH} />
    );
  else {
    const paymentMethodForSecurityProfileJson =
      paymentMethodForSecurityProfile.toJS();

    content = (
      <PaymentMethodForSecurityProfileInformationResource
        {...paymentMethodForSecurityProfileJson}
      />
    );

    toolbar = (
      <PaymentMethodForSecurityProfileToolBar
        paymentMethodForSecurityProfileId={
          paymentMethodForSecurityProfileJson.id
        }
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Metodo de Pago por Perfil de Seguridad"
      content={content}
    />
  );
};

PaymentMethodForSecurityProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearPaymentMethodForSecurityProfile: PropTypes.func.isRequired,
  dispatchGetPaymentMethodForSecurityProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  paymentMethodForSecurityProfile: PropTypes.instanceOf(Immutable.Map)
    .isRequired,
};

PaymentMethodForSecurityProfile.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearPaymentMethodForSecurityProfile:
    clearPaymentMethodForSecurityProfile,
  dispatchGetPaymentMethodForSecurityProfile:
    getPaymentMethodForSecurityProfile,
};

const mapStateToProps = ({ SecurityUnit }) => ({
  breadcrumbs: [
    ...SecurityUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Metodos de Pago Por Perfiles de Seguridad',
      href: PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  paymentMethodForSecurityProfile:
    SecurityUnit.PaymentMethodForSecurityProfile.getIn(['current', 'content']),
  loading: !SecurityUnit.PaymentMethodForSecurityProfile.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethodForSecurityProfile);
