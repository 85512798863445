import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, change, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import HierarchySelect from '../../../common/forms/select/HierarchySelect';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import Select from '../../../common/forms/select/Select';
import { ORGANIZATION_CHART_AREA_TYPES } from '../../../../config/constants';
import {
  clearOrganizationCharts,
  getOrganizationCharts,
} from '../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import { parentCompanyAreaPropTypes } from './proptypes/OrganizationChartPropTypes';

export const OrganizationChartForm = ({
  handleSubmit,
  loading,
  dispatchGetOrganizationCharts,
  dispatchClearOrganizationCharts,
  organizationCharts,
  loadingOrganizationCharts,
  dispatchChange,
  initialValues,
}) => {
  const [flagToGetOrganizationCharts, setFlagToGetOrganizationCharts] =
    useState(false);
  const [organizationChartOptions, setOrganizationChartOptions] = useState([]);

  const getOrganizationChartParent = (value) =>
    Object.values(ORGANIZATION_CHART_AREA_TYPES).find(({ id }) => id === value);

  useLayoutEffect(() => {
    if (initialValues.companyAreaTypeId) {
      const organizationChartFound = getOrganizationChartParent(
        initialValues.companyAreaTypeId.value,
      );

      if (ORGANIZATION_CHART_AREA_TYPES[organizationChartFound.parent]) {
        const params = { ...DEFAULT_QUERY_GET_ALL };

        params.query = [
          `companyAreaTypeId:${
            ORGANIZATION_CHART_AREA_TYPES[organizationChartFound.parent].id
          }`,
        ];

        setFlagToGetOrganizationCharts(true);
        dispatchGetOrganizationCharts({ ...params });
      }
    }

    return () => dispatchClearOrganizationCharts();
  }, []);

  useLayoutEffect(() => {
    if (flagToGetOrganizationCharts && organizationCharts.length > 0) {
      setOrganizationChartOptions(organizationCharts);
      setFlagToGetOrganizationCharts(false);
    }
  }, [organizationCharts]);

  const onChangeHierarchy = ({ value }) => {
    setOrganizationChartOptions([]);
    dispatchChange('OrganizationChartForm', 'parentCompanyAreaId', null);

    const organizationChartFound = getOrganizationChartParent(value);

    if (ORGANIZATION_CHART_AREA_TYPES[organizationChartFound.parent]) {
      const params = { ...DEFAULT_QUERY_GET_ALL };

      params.query = [
        `companyAreaTypeId:${
          ORGANIZATION_CHART_AREA_TYPES[organizationChartFound.parent].id
        }`,
      ];

      setFlagToGetOrganizationCharts(true);
      dispatchGetOrganizationCharts({ ...params });
    }
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Empresa" required>
          <Field
            name="companyId"
            component={CompanySelect}
            isDisabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Jerarquía" required>
          <Field
            name="companyAreaTypeId"
            component={HierarchySelect}
            onChange={onChangeHierarchy}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Organigrama Padre">
          <Field
            name="parentCompanyAreaId"
            component={Select}
            options={organizationChartOptions}
            isLoading={loadingOrganizationCharts}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

OrganizationChartForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetOrganizationCharts: PropTypes.func.isRequired,
  dispatchClearOrganizationCharts: PropTypes.func.isRequired,
  organizationCharts: optionsPropTypes,
  loadingOrganizationCharts: PropTypes.bool.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    ...parentCompanyAreaPropTypes,
    companyAreaTypeId: PropTypes.shape({
      value: PropTypes.number,
    }),
  }),
};

OrganizationChartForm.defaultProps = {
  organizationCharts: [],
  initialValues: null,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  organizationCharts: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, name }) => ({
    value: id,
    label: name,
  })),
  loadingOrganizationCharts: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetOrganizationCharts: getOrganizationCharts,
  dispatchClearOrganizationCharts: clearOrganizationCharts,
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'OrganizationChartForm',
})(OrganizationChartForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
