import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem, Row, Col } from 'reactstrap';
import './ItinerarySearchResult.css';
import { TIME_FORMAT } from '../../../../config/locale';
import { numberFormatter } from '../../../../utils/number';
import { timeFormat, tzNormalizeDate } from '../../../../utils/date';

const ItinerarySearchResult = (props) => {
  const { itinerarySearchResult, index } = props;

  const {
    seatMapDisplayName,
    lowestAvailablePrice,
    duration,
    arrivalTime,
    departureTime,
    origin,
    destination,
    availableSeats,
    itinerary: {
      route: { routeSegmentList },
      type,
    },
  } = itinerarySearchResult;

  const route = routeSegmentList.map(
    (routSegment) => routSegment.segment.destinationLocation.name,
  );
  route.unshift(routeSegmentList[0].segment.sourceLocation.name);
  const fullRoute = route.join(' - ');

  // departure
  const formattedDepartureTime = tzNormalizeDate({
    date: departureTime,
    format: TIME_FORMAT,
  });

  // arrival
  const formattedArrivalTime = tzNormalizeDate({
    date: arrivalTime,
    format: TIME_FORMAT,
  });

  // duration
  const formattedDuration = timeFormat(duration);

  return (
    <ListGroupItem
      className="ItinerarySearchResult mb-3"
      onClick={() =>
        props.handleSelectItinerary({ itinerarySearchResult, index })
      }
    >
      <Row>
        <Col xs="12" sm="2" md="3">
          <div>
            <h5>{seatMapDisplayName}</h5>
            <h6>
              Desde
              <strong>
                {numberFormatter({ value: lowestAvailablePrice })}
              </strong>
            </h6>
            <h6>Tipo: {type}</h6>
            <h6>{`Asientos Disp. ${availableSeats}`}</h6>
            <h6>{`Duración: ${formattedDuration}`}</h6>
          </div>
        </Col>
        <Col xs="12" sm="10" md="9">
          <Row className="itineraryResult">
            <Col className="d-flex justify-content-around align-items-stretch">
              <div>
                <h5>{origin.name}</h5>
                <h6>{`${formattedDepartureTime}`}</h6>
              </div>
              <div>
                <i className="fa fa-arrow-right fa-2x" />
              </div>
              <div>
                <h5>{destination.name}</h5>
                <h6>{`${formattedArrivalTime}`}</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-around">
              {`Ruta: ${fullRoute}`}
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

ItinerarySearchResult.propTypes = {
  index: PropTypes.number.isRequired,
  itinerarySearchResult: PropTypes.shape({
    seatMapDisplayName: PropTypes.string.isRequired,
    lowestAvailablePrice: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    arrivalTime: PropTypes.number.isRequired,
    departureTime: PropTypes.number.isRequired,
    origin: PropTypes.PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    destination: PropTypes.PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    availableSeats: PropTypes.number.isRequired,
    itinerary: PropTypes.shape({
      route: PropTypes.shape({
        routeSegmentList: PropTypes.arrayOf(
          PropTypes.shape({
            segment: PropTypes.shape({
              sourceLocation: PropTypes.shape({ name: PropTypes.string }),
            }),
          }),
        ),
      }),
      type: PropTypes.string.isRequired,
    }),
  }).isRequired,
  handleSelectItinerary: PropTypes.func.isRequired,
};

export default ItinerarySearchResult;
