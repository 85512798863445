import { string, number, shape } from 'prop-types';
import { customerBasicInformationPropTypes } from '../../../user/customer/proptypes/CustomerPropTypes';
import { organizationChartBasicInformationPropTypes } from '../../organization-chart/proptypes/OrganizationChartPropTypes';

const mechanicCustomerInformationPropTypes = {
  customer: shape(customerBasicInformationPropTypes).isRequired,
  customerId: number.isRequired,
};

const mechanicHierarchyInformationPropTypes = {
  management: shape(organizationChartBasicInformationPropTypes).isRequired,
  managementId: number.isRequired,
  department: shape(organizationChartBasicInformationPropTypes),
  departmentId: number,
  area: shape(organizationChartBasicInformationPropTypes),
  areaId: number,
  section: shape(organizationChartBasicInformationPropTypes),
  sectionId: number,
  speciality: shape(organizationChartBasicInformationPropTypes),
  specialityId: number,
};

const mechanicBossInformationPropTypes = {
  boss: string,
  bossId: number,
  department: string,
  departmentId: number,
  area: string,
  areaId: number,
  section: string,
  sectionId: number,
  speciality: string,
  specialityId: number,
};

const mechanicBossInformationDefaultProps = {
  boss: null,
  bossId: null,
  department: null,
  departmentId: null,
  area: null,
  areaId: null,
  section: null,
  sectionId: null,
  speciality: null,
  specialityId: null,
};

export {
  mechanicCustomerInformationPropTypes,
  mechanicHierarchyInformationPropTypes,
  mechanicBossInformationPropTypes,
  mechanicBossInformationDefaultProps,
};
