import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getExpenseTypes, clearExpenseTypes } from '../../../actions';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';

class ExpenseTypeFilter extends Component {
  static propTypes = {
    dispatchGetExpenseTypes: PropTypes.func.isRequired,
    dispatchClearExpenseTypes: PropTypes.func.isRequired,
    expenseTypes: optionsPropTypes,
    onChangeFilter: PropTypes.func,
  };

  static defaultProps = {
    expenseTypes: null,
    onChangeFilter: () => {},
  };

  constructor(props) {
    super(props);
    this.props.dispatchGetExpenseTypes();
  }

  componentWillUnmount() {
    this.props.dispatchClearExpenseTypes();
  }

  render() {
    const { expenseTypes, onChangeFilter } = this.props;
    return (
      <SelectFilter
        isMulti
        onChangeFilter={onChangeFilter}
        options={expenseTypes}
      />
    );
  }
}

const mapStateToProps = ({ AccountingUnit }) => {
  const expenseTypes = AccountingUnit.ExpenseType.getIn([
    'all',
    'content',
    'content',
  ]).map((expenseType) => ({
    value: expenseType.id,
    label: expenseType.name,
  }));
  return {
    expenseTypes,
  };
};

const mapDispatchToprops = {
  dispatchGetExpenseTypes: getExpenseTypes,
  dispatchClearExpenseTypes: clearExpenseTypes,
};

export default connect(mapStateToProps, mapDispatchToprops)(ExpenseTypeFilter);
