import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { LIQUIDATION_SESSION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import LiquidationSessionForm from './LiquidationSessionForm';
import {
  getLiquidationSession,
  patchLiquidationSession,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export const EditLiquidationSession = ({
  dispatchGetLiquidationSession,
  match: {
    params: { id: liquidationSessionId },
  },
  dispatchPatchLiquidationSession,
  breadcrumbs,
  liquidation,
  loading,
}) => {
  useLayoutEffect(() => {
    dispatchGetLiquidationSession({ liquidationSessionId });
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      dailyLiquidationSessionNoteList:
        formValues.dailyLiquidationSessionNoteList,
      settled: formValues.isSettled || false,
    };

    dispatchPatchLiquidationSession(liquidationSessionId, newFormValues);
  };

  const generateInitialValues = (liquidationJson) => {
    const cashOnHand = liquidationJson.amountPayable
      ? liquidationJson.amountPayable
      : liquidationJson.totalCash;
    const initialValues = {
      agencyId: {
        value: liquidationJson.agencyId,
        label: liquidationJson.agency,
      },
      invoiceNumber: liquidationJson.invoiceNumber,
      cashOnHand: cashOnHand - liquidationJson.liquidationDifferenceAmount,
      isSettled: liquidationJson.settled,
    };

    if (
      liquidationJson.liquidationDifferenceAmount !== 0 &&
      liquidationJson.liquidationDifferenceReason
    ) {
      initialValues.liquidationDifferenceReasonId = {
        value: liquidationJson.liquidationDifferenceReason.id,
        label: liquidationJson.liquidationDifferenceReason.name,
      };
      initialValues.liquidationDifferenceReasonDescription =
        liquidationJson.liquidationDifferenceReasonDescription;
    }

    return initialValues;
  };

  let content = null;

  if (loading || liquidation.isEmpty()) content = <Loader />;
  else if (liquidation.isEmpty())
    content = <NoDataResource returnPage={LIQUIDATION_SESSION_PATH} />;
  else
    content = (
      <LiquidationSessionForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(liquidation.toJS())}
        dailyLiquidationSessionNoteList={liquidation.get(
          'dailyLiquidationSessionNoteList',
        )}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Liquidación de Agencia"
      subtitle="Edita esta liquidación de agencia"
      content={content}
    />
  );
};

const mapStateToProps = (
  { SalesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Liquidaciones de Agencia',
      href: LIQUIDATION_SESSION_PATH,
    },
    {
      text: 'Ver',
      href: `${LIQUIDATION_SESSION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  liquidation: SalesUnit.LiquidationSession.getIn(['current', 'content']),
  loading: !SalesUnit.LiquidationSession.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPatchLiquidationSession: patchLiquidationSession,
  dispatchGetLiquidationSession: getLiquidationSession,
};

EditLiquidationSession.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  dispatchPatchLiquidationSession: PropTypes.func.isRequired,
  liquidation: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetLiquidationSession: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditLiquidationSession);
