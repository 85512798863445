import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Floors from '../../seat-map/Floors';
import { floorsPropTypes } from '../../../units/bus/seat-map/proptypes/SeatMapPropTypes';

const SeatMap = props => {
  const {
    destinationLocationId,
    sourceLocationId,
    itineraryId,
    floors,
    handleSeatSelection,
    handleSeatDeselection,
    seatReservations,
    seatSelectionChangesInProgress,
    numPassengers,
    orientation,
    renderFor,
    enabledFloor,
  } = props;

  return (
    <div>
      <Floors
        floors={floors}
        destinationLocationId={destinationLocationId}
        sourceLocationId={sourceLocationId}
        itineraryId={itineraryId}
        handleSeatSelection={handleSeatSelection}
        handleSeatDeselection={handleSeatDeselection}
        seatReservations={seatReservations}
        seatSelectionChangesInProgress={seatSelectionChangesInProgress}
        numPassengers={numPassengers}
        orientation={orientation}
        renderFor={renderFor}
        enabledFloor={enabledFloor}
      />
    </div>
  );
};

SeatMap.propTypes = {
  sourceLocationId: PropTypes.number,
  destinationLocationId: PropTypes.number,
  itineraryId: PropTypes.number,
  ...floorsPropTypes,
  handleSeatSelection: PropTypes.func.isRequired,
  handleSeatDeselection: PropTypes.func,
  seatReservations: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.Set),
    PropTypes.arrayOf(
      PropTypes.shape({
        xPosition: PropTypes.number,
        yPosition: PropTypes.number,
        floorNumber: PropTypes.number,
      }),
    ),
  ]),
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set),
  numPassengers: PropTypes.number,
  orientation: PropTypes.string,
  renderFor: PropTypes.string,
  enabledFloor: PropTypes.number,
};

SeatMap.defaultProps = {
  sourceLocationId: null,
  destinationLocationId: null,
  itineraryId: null,
  numPassengers: null,
  handleSeatDeselection: null,
  seatReservations: null,
  seatSelectionChangesInProgress: null,
  orientation: 'v',
  renderFor: 'booking',
  enabledFloor: null,
};

export default SeatMap;
