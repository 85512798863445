import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Button, Row, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import BusinessSelect from '../../../common/forms/select/BusinessSelect';

export const FuelStationForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Ubicación" required>
        <Field
          name="locationId"
          component={LocationSelect}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Empresa" required>
        <Field
          name="businessId"
          component={BusinessSelect}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <Row className="mt-5">
      <Col className="flex row-reverse">
        <Button type="submit" color="primary">
          Enviar
        </Button>
      </Col>
    </Row>
  </Form>
);

FuelStationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    locationId: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
    businessId: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
    commission: PropTypes.number,
  }),
};

FuelStationForm.defaultProps = {
  initialValues: null,
};

const formComponent = reduxForm({
  form: 'FuelStationForm',
})(FuelStationForm);

export default formComponent;
