import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PROFILE_AUTHORIZED_BY_PROFILE_PATH } from '../../../../config/paths';

const AuthorizedProfileByProfileEditButton = ({ authorizedProfileId }) => (
  <Link
    className="btn btn-primary"
    to={`${PROFILE_AUTHORIZED_BY_PROFILE_PATH}/${authorizedProfileId}/edit`}
  >
    Editar
  </Link>
);

AuthorizedProfileByProfileEditButton.propTypes = {
  authorizedProfileId: PropTypes.number.isRequired,
};

export default AuthorizedProfileByProfileEditButton;
