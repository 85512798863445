import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, ButtonToolbar, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { COMMON_PRODUCT_PATH } from '../../../../config/paths';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  getCommonProduct,
  clearCommonProduct,
} from '../../../../actions/cargo';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import EditCommonProductButton from './EditCommonProductButton';
import DeleteCommonProductButton from './DeleteCommonProductButton';
import Checkbox from '../../../common/forms/Checkbox';
import IsActive from '../../../common/cells/IsActive';

class CommonProduct extends Component {
  componentDidMount() {
    const {
      dispatchGetCommonProduct,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetCommonProduct({ id });
  }

  componentWillUnmount() {
    const { dispatchClearCommonProduct } = this.props;
    dispatchClearCommonProduct();
  }

  render() {
    const {
      breadcrumbs,
      commonProduct,
      loading,
      match: {
        params: { id },
      },
    } = this.props;

    const commonProductId = parseInt(id, 10);

    if (loading || commonProduct.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Fragment>
          <ButtonToolbar className="pull-right">
            <div>
              <EditCommonProductButton commonProductId={commonProductId} />{' '}
              <DeleteCommonProductButton commonProductId={commonProductId} />
            </div>
          </ButtonToolbar>
        </Fragment>
        <Fragment>
          <Row>
            <Col>
              <h1>Producto Frecuente</h1>
            </Col>
          </Row>
          <hr />
        </Fragment>
        <Fragment>
          <ResourceProperty label="Nombre Producto:">
            {commonProduct.get('productName')}
          </ResourceProperty>
          <ResourceProperty label="Descripción Producto:">
            {commonProduct.get('productDescription')}
          </ResourceProperty>
          <ResourceProperty label="Precio de Envío:">
            {commonProduct.get('shippingPrice')}
          </ResourceProperty>
          <ResourceProperty label="Incremento por Trasbordo (%):">
            {commonProduct.get('transshipmentIncreasePercentage')}
          </ResourceProperty>
          <ResourceProperty label="Producto Interno:">
            <Checkbox checked={commonProduct.get('internalProduct')} />
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            <IsActive value={commonProduct.get('active')} />
          </ResourceProperty>
        </Fragment>
      </Container>
    );
  }
}

CommonProduct.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCommonProduct: PropTypes.func.isRequired,
  dispatchClearCommonProduct: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  commonProduct: PropTypes.instanceOf(Immutable.Map),
};

CommonProduct.defaultProps = {
  loading: false,
  commonProduct: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Productos Frecuentes',
      href: COMMON_PRODUCT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  commonProduct: CargoUnit.CommonProduct.getIn(['current', 'content']),
  loading: CargoUnit.CommonProduct.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCommonProduct: getCommonProduct,
  dispatchClearCommonProduct: clearCommonProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonProduct);
