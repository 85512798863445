import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { generateManifestCargoDetailsColumns } from '../../../../../config/columns';
import Loader from '../../../../common/Loader';
import Table from '../../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { DEFAULT_QUERY_GET_ALL } from '../../../../../config/queries';
import {
  clearManifestItineraryDetail,
  getManifestItemDetail,
} from '../../../../../actions/cargo/ItineraryManifest';
import { putRemoveItemFromManifest } from '../../../../../actions';

const ManifestCargoDetail = ({
  manifestId,
  dispatchGetManifestItemDetail,
  dispatchPutRemoveItemFromManifest,
  loading,
  sourceLocation,
  itineraryId,
  onItemRemoved,
}) => {
  const [details, setDetails] = useState({});

  useEffect(() => {
    const query = [`manifestCargoItemId:${manifestId}`];

    const getManifestItemDetailFunction = async () => {
      const newDetails = await dispatchGetManifestItemDetail({
        query,
        size: DEFAULT_QUERY_GET_ALL.size,
      });

      setDetails(newDetails);
    };

    getManifestItemDetailFunction();
  }, []);

  const onClickRemoveItemFromManifest = async (row) => {
    const removed = await dispatchPutRemoveItemFromManifest({
      cargoItemId: row.item.id,
      itineraryId,
      locationId: sourceLocation.id,
      internalCargoItem: row.item.internal,
    });

    onItemRemoved(removed.empty);

    const query = [`manifestCargoItemId:${manifestId}`];

    const newDetails = await dispatchGetManifestItemDetail({
      query,
      size: DEFAULT_QUERY_GET_ALL.size,
    });

    setDetails(newDetails);
  };

  const data = details.content || [];

  let table =
    data.length > 0 ? (
      <Table
        manual={false}
        columns={generateManifestCargoDetailsColumns(
          onClickRemoveItemFromManifest,
        )}
        data={data}
        defaultPageSize={20}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
        loading={loading}
      />
    ) : null;

  if (loading && !data.length) table = <Loader />;
  return (
    <>
      <h4>Detalle del Manifiesto</h4>
      {table}
    </>
  );
};

ManifestCargoDetail.propTypes = {
  manifestId: PropTypes.number.isRequired,
  dispatchGetManifestItemDetail: PropTypes.func.isRequired,
  dispatchPutRemoveItemFromManifest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sourceLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  itineraryId: PropTypes.number.isRequired,
  onItemRemoved: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: CargoUnit.ItineraryManifest.getIn(['detail', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetManifestItemDetail: getManifestItemDetail,
  dispatchClearManifestItineraryDetail: clearManifestItineraryDetail,
  dispatchPutRemoveItemFromManifest: putRemoveItemFromManifest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManifestCargoDetail);
