import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import Select from '../../../../common/forms/select/Select';
import { ITINERARY_STATUS_SEARCH_OPTIONS } from '../../../../../config/constants';

const ItineraryStatusSearchForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Buscar por">
        <Field
          name="trip"
          component={Select}
          options={ITINERARY_STATUS_SEARCH_OPTIONS}
        />
      </FormItem>
    </FormGroup>
    <Row>
      <Col className="flex row-reverse">
        <Button type="submit" color="secondary" outline>
          <i className="fa fa-search" /> Buscar
        </Button>
      </Col>
    </Row>
  </Form>
);

ItineraryStatusSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ItineraryStatusSearchForm',
})(ItineraryStatusSearchForm);
