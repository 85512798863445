import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getExpenseTypes,
  clearExpenseTypes,
} from '../../../../actions/accounting/';
import {
  EXPENSE_TYPE_PATH,
  NEW_EXPENSE_TYPE_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { EXPENSE_TYPES_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export const ExpenseTypes = ({
  dispatchClearExpenseTypes,
  expenseTypes,
  loading,
  dispatchGetExpenseTypes,
  breadcrumbs,
}) => {
  useLayoutEffect(() => () => dispatchClearExpenseTypes(), []);

  const data = expenseTypes.get('content') || [];
  const pages = expenseTypes.get('totalPages') || null;
  const defaultPageSize = expenseTypes.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Tipos de Egreso"
      buttonPath={NEW_EXPENSE_TYPE_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: EXPENSE_TYPES_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetExpenseTypes,
        modelPath: EXPENSE_TYPE_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetExpenseTypes: getExpenseTypes,
  dispatchClearExpenseTypes: clearExpenseTypes,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tipos de Egreso',
      href: EXPENSE_TYPE_PATH,
    },
  ],
  expenseTypes: AccountingUnit.ExpenseType.getIn(['all', 'content']),
  loading: AccountingUnit.ExpenseType.getIn(['all', 'loading']),
});

ExpenseTypes.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  expenseTypes: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetExpenseTypes: PropTypes.func.isRequired,
  dispatchClearExpenseTypes: PropTypes.func.isRequired,
};

ExpenseTypes.defaultProps = {
  expenseTypes: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTypes);
