const FLAG_REQUIRES_TWO_STEP_AUTHORIZATION =
  'FLAG_REQUIRES_TWO_STEP_AUTHORIZATION';
const SET_TWO_STEP_AUTHORIZATION_URL = 'SET_TWO_STEP_AUTHORIZATION_URL';
const FLAG_SENDING_TWO_STEP_AUTHORIZATION_REQUEST =
  'FLAG_SENDING_TWO_STEP_AUTHORIZATION_REQUEST';
const SET_TWO_STEP_AUTHORIZATION_CALLBACK =
  'SET_TWO_STEP_AUTHORIZATION_CALLBACK';
const SET_TWO_STEP_AUTHORIZATION_METHOD = 'SET_TWO_STEP_AUTHORIZATION_METHOD';

export {
  FLAG_REQUIRES_TWO_STEP_AUTHORIZATION,
  SET_TWO_STEP_AUTHORIZATION_URL,
  FLAG_SENDING_TWO_STEP_AUTHORIZATION_REQUEST,
  SET_TWO_STEP_AUTHORIZATION_CALLBACK,
  SET_TWO_STEP_AUTHORIZATION_METHOD,
};
