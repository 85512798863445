import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import {
  getExpenseReport,
  clearExpenseReport,
  clearErrorGettingExpenseReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Table from '../../../common/Table';
import ReportToolbar from '../ReportToolbar';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { EXPENSE_REPORTS_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import DateCell from '../../../common/cells/DateCell';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

class ExpenseReport extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        Header: 'Agencia',
        accessor: 'agency',
      },
      {
        Header: 'Nombre Counter',
        accessor: 'counterFirstName',
      },
      {
        Header: 'Apellido Counter',
        accessor: 'counterLastName',
      },
      {
        Header: 'Tipo de Comprobante',
        accessor: 'voucherType',
      },
      {
        Header: 'Serie',
        accessor: 'documentSeries',
      },
      {
        Header: 'Correlativo',
        accessor: 'documentCode',
      },
      {
        Header: 'Ruc',
        accessor: 'businessTaxId',
      },
      {
        Header: 'Razón Social',
        accessor: 'businessName',
      },
      {
        Header: 'Tipo de Egreso',
        accessor: 'expenseType',
      },
      {
        Header: 'Descripción',
        accessor: 'description',
      },
      {
        Header: 'Total',
        accessor: 'totalAmount',
      },
      {
        Header: 'Fecha',
        accessor: 'createDate',
        Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
      },
    ];
    this.download = this.download.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
  }

  componentWillMount() {
    // extract id from params injected by react router
    const { id } = this.props.match.params;
    this.props.getExpenseReport({ id });
  }

  componentWillUnmount() {
    this.props.clearExpenseReport();
    this.props.clearErrorGettingExpenseReport();
  }

  handleOnFetchData(state) {
    // get id of report
    const { id } = this.props.match.params;
    this.props.getExpenseReport({ ...state, id });
  }

  download() {
    const { report } = this.props;
    const { id } = this.props.match.params;
    if (report) {
      return this.props.getExpenseReport({
        id,
        reportFormat: 'csv',
        download: true,
      });
    }
    return false;
  }

  renderContent() {
    // check if report is loaded
    const { loading, report, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    // check if report is loading or empty
    if (loading || !report || report.isEmpty()) {
      return <Loader />;
    }

    // check if report is complete
    const reportStatus = report.get('reportStatus');
    if (reportStatus === 'GENERATING') {
      return <Loader />;
    }

    const expenseReportRows = report.get('expenseReportRows');
    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    if (expenseReportRows) {
      data = expenseReportRows.content;
      pages = expenseReportRows.totalPages;
      defaultPageSize = expenseReportRows.size;
    }

    const { columns } = this;
    return (
      <div>
        <ReportToolbar download={this.download} disabled={!data.length} />
        <Table
          columns={columns}
          data={data}
          defaultPageSize={defaultPageSize}
          pages={pages}
          loading={loading}
        />
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <h1>Reporte Egresos</h1>
        <p>Egresos</p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Egresos',
      href: EXPENSE_REPORTS_PATH,
    },
    {
      text: 'Ver',
      href: state.routing.location.pathname,
    },
  ],
  error: state.ReportUnit.ExpenseReport.get('current').get('error'),
  report: state.ReportUnit.ExpenseReport.get('current').get('content'),
  loading: state.ReportUnit.ExpenseReport.get('current').get('loading'),
});

const mapDispatchToProps = {
  getExpenseReport,
  clearExpenseReport,
  clearErrorGettingExpenseReport,
};

ExpenseReport.propTypes = {
  getExpenseReport: PropTypes.func.isRequired,
  clearExpenseReport: PropTypes.func.isRequired,
  clearErrorGettingExpenseReport: PropTypes.func.isRequired,
  // todo: fix once issue http://bit.ly/2EPChzJ is fixed
  // eslint-disable-next-line react/require-default-props,react/no-typos
  breadcrumbs: Breadcrumbs.propTypes.breadcrumbs,
  report: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
  }).isRequired,
};

ExpenseReport.defaultProps = {
  report: null,
  loading: false,
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReport);
