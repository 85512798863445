import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { ITINERARY_FOR_SCHEDULING_PATH } from '../../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../../common/resource/proptypes/CommonPropTypes';
import ItinerarySearchForm from '../ItinerarySearchForm';
import { INT_DATE_FORMAT } from '../../../../../config/locale';
import {
  searchItineraryBetweenDates,
  clearItineraries,
  putAssignBusses,
} from '../../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../../config/queries';
import {
  BUSES_WITHOUT_TRIP_CIRCUIT_STATUS_ERROR_MESSAGE,
  DATA_NOT_REGISTERED_ERROR_MESSAGE,
} from '../../../../../config/messages';
import Content from '../../../../layout/Content';
import { tzNormalizeDate } from '../../../../../utils/date';
import ItinerariesForSchedulingTable from './ItinerariesForSchedulingTable';
import { hadResponseSuccessWithErrorCode } from '../../../../../utils/error-handlers';

const ItinerariesForScheduling = ({
  dispatchPutAssignBusses,
  dispatchClearItineraries,
  dispatchSearchItineraryBetweenDates,
  breadcrumbs,
}) => {
  const [searchValues, setSearchValues] = useState({
    sourceLocationId: 1,
    destinationLocationId: 1,
    fromDateString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
    toDateString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
    ...DEFAULT_QUERY_GET_ALL,
  });

  const searchItinerary = (searchFormValuesx) => {
    dispatchClearItineraries();
    dispatchSearchItineraryBetweenDates(searchFormValuesx);
  };

  const onSubmitToSearch = (formValues) => {
    const values = {
      sourceLocationId: formValues.sourceLocationId
        ? formValues.sourceLocationId.value
        : null,
      destinationLocationId: formValues.destinationLocationId
        ? formValues.destinationLocationId.value
        : null,
      fromDateString: tzNormalizeDate({
        date: formValues.fromDateString,
        format: INT_DATE_FORMAT,
      }),
      toDateString: tzNormalizeDate({
        date: formValues.toDateString,
        format: INT_DATE_FORMAT,
      }),
      size: DEFAULT_QUERY_GET_ALL.size,
    };
    setSearchValues(values);
    searchItinerary(values);
  };

  const validateValues = (formValues) => {
    let valid = true;
    formValues.forEach(({ companyBusId, tripCircuitStatus }) => {
      if (!companyBusId) valid = false;
      if (!tripCircuitStatus) valid = false;
      if (!companyBusId && !tripCircuitStatus) valid = true;
    });
    return valid;
  };

  const onClickBusAssignment = async (formValues) => {
    const valid = validateValues(formValues);

    if (!valid)
      toastr.error('Error', BUSES_WITHOUT_TRIP_CIRCUIT_STATUS_ERROR_MESSAGE);
    else {
      const registerFormValues = formValues.map(
        ({ companyBusId, tripCircuitStatus, id }) => ({
          registeredBusId: companyBusId.value,
          tripCircuitStatus: tripCircuitStatus.value,
          itineraryId: id,
        }),
      );

      const response = await dispatchPutAssignBusses(registerFormValues);
      const error = hadResponseSuccessWithErrorCode(response);
      if (error) toastr.error('Error', DATA_NOT_REGISTERED_ERROR_MESSAGE);
      else searchItinerary(searchValues);
    }
  };

  const today = tzNormalizeDate();
  const tomorrow = tzNormalizeDate({ addTime: { amount: 1, unit: 'd' } });

  const subtitle = (
    <span>
      Busca los itinerarios que deseas programar, selecciona los buses y
      asígnalos hacliendo click en <strong>Asignar buses</strong>
    </span>
  );

  const content = (
    <>
      <ItinerarySearchForm
        onSubmit={onSubmitToSearch}
        initialValues={{
          fromDateString: today,
          toDateString: tomorrow,
        }}
        sourceLocationIsRequired={false}
        destinationLocationIsRequired={false}
        searchByDateRange
      />
      <ItinerariesForSchedulingTable
        onHandleBusAssignment={onClickBusAssignment}
      />
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Itinerarios para Programar"
      subtitle={subtitle}
      content={content}
    />
  );
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios para Programar',
      href: ITINERARY_FOR_SCHEDULING_PATH,
    },
  ],
  loading: ItineraryUnit.Itinerary.getIn(['all', 'loading']),
});

ItinerariesForScheduling.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchSearchItineraryBetweenDates: PropTypes.func.isRequired,
  dispatchClearItineraries: PropTypes.func.isRequired,
  dispatchPutAssignBusses: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchSearchItineraryBetweenDates: searchItineraryBetweenDates,
  dispatchClearItineraries: clearItineraries,
  dispatchPutAssignBusses: putAssignBusses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerariesForScheduling);
