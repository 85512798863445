import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ITINERARY_GROUP_PATH } from '../../../../config/paths';

const ItineraryGroupEditButton = ({ itineraryGroupId }) => (
  <Link
    className="btn btn-primary"
    to={`${ITINERARY_GROUP_PATH}/${itineraryGroupId}/edit`}
  >
    Editar
  </Link>
);

ItineraryGroupEditButton.propTypes = {
  itineraryGroupId: PropTypes.number.isRequired,
};

export default ItineraryGroupEditButton;
