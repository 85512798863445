import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  CARGO_DISTANCE_PATH,
  NEW_CARGO_DISTANCE_PATH,
} from '../../../../config/paths';
import {
  getCargoDistances,
  clearCargoDistances,
} from '../../../../actions/cargo';
import { CARGO_DISTANCE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class CargoDistances extends Component {
  componentWillUnmount() {
    const { dispatchClearCargoDistances } = this.props;
    dispatchClearCargoDistances();
  }

  render() {
    const { kilometers, loading, breadcrumbs, dispatchGetCargoDistances } =
      this.props;
    const data = kilometers.get('content') || [];
    const pages = kilometers.get('totalPages') || null;
    const defaultPageSize = kilometers.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Kilómetros entre Ciudades"
        buttonPath={NEW_CARGO_DISTANCE_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: CARGO_DISTANCE_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetCargoDistances,
          loading,
          modelPath: CARGO_DISTANCE_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetCargoDistances: getCargoDistances,
  dispatchClearCargoDistances: clearCargoDistances,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Kilómetros entre Ciudades',
      href: CARGO_DISTANCE_PATH,
    },
  ],
  kilometers: CargoUnit.CargoDistance.getIn(['all', 'content']),
  loading: CargoUnit.CargoDistance.getIn(['all', 'loading']),
});

CargoDistances.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  kilometers: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCargoDistances: PropTypes.func.isRequired,
  dispatchClearCargoDistances: PropTypes.func.isRequired,
};

CargoDistances.defaultProps = {
  kilometers: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoDistances);
