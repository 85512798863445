import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, ListGroup } from 'reactstrap';
import ItinerarySearchResult from '../../../modules/booking/itineraries/ItinerarySearchResult';
import Loader from '../../../common/Loader';
import { selectPostpaidBookingItinerarySearchResult } from '../../../../actions';
import {
  CONTRACT_TYPE,
  ITINERARY_TYPE_CONTRACT,
} from '../../../../config/constants';
import { ROUTE_WITHOUT_ITINERARIES } from '../../../../config/messages';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

const renderItinerarySearchResultList = (
  itinerarySearchResults,
  index,
  handleSelectItinerary,
  agencyContractType,
) => {
  if (!itinerarySearchResults)
    return (
      <div className="border rounded p-3">
        <h5>Lista de Itinerarios</h5>
        <span className="text-muted">
          <i>No hay itinerarios</i>
        </span>
      </div>
    );

  if (!itinerarySearchResults.length)
    return <Alert color="danger">{ROUTE_WITHOUT_ITINERARIES}</Alert>;

  const newAgencyContractType =
    agencyContractType || CONTRACT_TYPE.DEFAULT.value;

  const currentDate = tzNormalizeDate({ format: TIMESTAMP_FORMAT });

  const filteredItinenaries = itinerarySearchResults.filter(
    (itinerarySearchResult) => {
      if (
        itinerarySearchResult.itinerary.type.trim().toLowerCase() ===
          ITINERARY_TYPE_CONTRACT.toLowerCase() ||
        (itinerarySearchResult.departureTime <= currentDate &&
          newAgencyContractType !== CONTRACT_TYPE.DEFAULT.value)
      ) {
        return false;
      }
      return true;
    },
  );

  return filteredItinenaries.map((itinerarySearchResult) => (
    <ItinerarySearchResult
      key={itinerarySearchResult.itinerarySegmentList[0].id}
      itinerarySearchResult={itinerarySearchResult}
      handleSelectItinerary={handleSelectItinerary}
      index={index}
    />
  ));
};

const PostpaidBookingItinerarySearchResults = ({
  itineraries,
  searchingForItineraries,
  dispatchSelectPostpaidBookingItinerarySearchResult,
  agencyContractType,
}) => {
  if (searchingForItineraries) {
    return <Loader />;
  }
  // todo: show different indexes for multi-step reservations
  const index = 0;
  return (
    <div className="mt-4">
      <ListGroup>
        {renderItinerarySearchResultList(
          itineraries,
          index,
          dispatchSelectPostpaidBookingItinerarySearchResult,
          agencyContractType,
        )}
      </ListGroup>
    </div>
  );
};

PostpaidBookingItinerarySearchResults.propTypes = {
  itineraries: PropTypes.arrayOf(
    PropTypes.shape({
      seatMapDisplayName: PropTypes.string,
      lowestAvailablePrice: PropTypes.number,
      duration: PropTypes.number,
      arrivalTime: PropTypes.number,
      departureTime: PropTypes.number,
      origin: PropTypes.PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
      destination: PropTypes.PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ),
  searchingForItineraries: PropTypes.bool.isRequired,
  source: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  destination: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  dispatchSelectPostpaidBookingItinerarySearchResult: PropTypes.func.isRequired,
  agencyContractType: PropTypes.string,
};

PostpaidBookingItinerarySearchResults.defaultProps = {
  itineraries: null,
  source: null,
  destination: null,
  agencyContractType: null,
};

const mapStateToProps = ({ PostpaidBookingUnit, SalesUnit }) => ({
  source: PostpaidBookingUnit.PostpaidBooking.getIn([
    'search',
    'query',
    'source',
  ]),
  destination: PostpaidBookingUnit.PostpaidBooking.getIn([
    'search',
    'query',
    'destination',
  ]),
  time: PostpaidBookingUnit.PostpaidBooking.getIn(['search', 'query', 'time']),
  itineraries:
    PostpaidBookingUnit.PostpaidBooking.getIn([
      'itineraries',
      'results',
      'content',
    ]) || null,
  searchingForItineraries: PostpaidBookingUnit.PostpaidBooking.getIn([
    'itineraries',
    'searchingForItineraries',
  ]),
  agencyContractType: SalesUnit.SalesSession.getIn([
    'active',
    'content',
    'agency',
    'contractType',
  ]),
});

const mapDispatchToProps = {
  dispatchSelectPostpaidBookingItinerarySearchResult:
    selectPostpaidBookingItinerarySearchResult,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostpaidBookingItinerarySearchResults);
