import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { AGENCY_GROUP_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import AgencyGroupForm from './AgencyGroupForm';
import Loader from '../../../common/Loader';
import {
  clearAgencyGroup,
  getAgencyGroup,
  putAgencyGroup,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditAgencyGroup extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: agencyGroupId },
      },
      dispatchGetAgencyGroup,
    } = this.props;

    dispatchGetAgencyGroup({ agencyGroupId });
  }

  componentWillUnmount() {
    this.props.dispatchClearAgencyGroup();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutAgencyGroup,
      match: {
        params: { id: agencyId },
      },
    } = this.props;

    const newFormValues = {
      name: formValues.name,
      description: formValues.description,
      agencies: [],
    };

    if (formValues.agencyList) {
      newFormValues.agencies = formValues.agencyList.map(
        ({ agency: { id } }) => ({
          id,
        }),
      );
    }

    dispatchPutAgencyGroup(agencyId, newFormValues);
  };

  generateInitialValues = (agencyGroup) => ({
    name: agencyGroup.get('name'),
    description: agencyGroup.get('description'),
    agencyList: agencyGroup.get('agencies').map((agency) => ({
      agency: { ...agency, value: agency.id, label: agency.name },
    })),
  });

  render() {
    const { breadcrumbs, agencyGroup, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (agencyGroup.isEmpty()) {
      content = <NoDataResource returnPage={AGENCY_GROUP_PATH} />;
    } else {
      content = (
        <AgencyGroupForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(agencyGroup)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Grupo de Agencias"
        subtitle="Edita el grupo de agencias"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { HumanResourcesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Agencias',
      href: AGENCY_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: `${AGENCY_GROUP_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  agencyGroup: HumanResourcesUnit.AgencyGroup.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.AgencyGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetAgencyGroup: getAgencyGroup,
  dispatchClearAgencyGroup: clearAgencyGroup,
  dispatchPutAgencyGroup: putAgencyGroup,
};

EditAgencyGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutAgencyGroup: PropTypes.func.isRequired,
  dispatchGetAgencyGroup: PropTypes.func.isRequired,
  dispatchClearAgencyGroup: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  agencyGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditAgencyGroup.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAgencyGroup);
