import _ from 'lodash';

const range = (start = 0, end = null) => {
  let response = [];

  if (end || end === 0) {
    if (end > start)
      for (let index = start; index < end; index += 1) {
        response.push(index);
      }
    else
      for (let index = start; index > end; index -= 1) {
        response.push(index);
      }
  } else response = [...Array(start).keys()];

  return response;
};

const orderBy = (collection, fields = ['id'], orders = ['asc']) =>
  _.orderBy(collection, fields, orders);

const convertToCSV = (array) => {
  const myarray = [Object.keys(array[0])].concat(array);

  return myarray.map((it) => Object.values(it).toString()).join('\n');
};

export { range, orderBy, convertToCSV };
