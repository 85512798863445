import React from 'react';
import PropTypes from 'prop-types';
import { TimeInput as TimeInputWidget } from 'react-widgets';
import { FormText } from 'reactstrap';
import { TIME_FORMAT } from '../../../../config/locale';

const TimeInput = ({
  input: { onChange, value },
  meta: { touched, error, warning },
  format,
  disabled,
  onSelect,
  noClearButton,
}) => {
  const props = {
    style: { width: 'auto' },
    onChange,
    onSelect,
    format: format || TIME_FORMAT,
    disabled,
    placeholder: TIME_FORMAT.toLowerCase(),
    noClearButton,
  };

  if (value) props.value = new Date(value);

  return (
    <div>
      <TimeInputWidget {...props} />
      <FormText color="danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </FormText>
    </div>
  );
};

TimeInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  format: PropTypes.string,
  time: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  noClearButton: PropTypes.bool,
};

TimeInput.defaultProps = {
  format: null,
  time: false,
  disabled: false,
  onSelect: () => {},
  noClearButton: false,
};

export default TimeInput;
