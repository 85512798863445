import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const SaveButton = ({ type, action, disabled, color, text, icon }) => {
  const iconElement = icon && <i className={icon} />;

  return (
    <Button color={color} type={type} onClick={action} disabled={disabled}>
      {iconElement} {text}
    </Button>
  );
};

SaveButton.propTypes = {
  action: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
};

SaveButton.defaultProps = {
  action: null,
  disabled: false,
  type: 'submit',
  color: 'primary',
  text: 'Guardar',
  icon: 'fa fa-save',
};

export default SaveButton;
