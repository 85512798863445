import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import { isRequired } from '../../../../../utils/validators';
import FormFooter from '../../../../common/forms/FormFooter';
import TextInput from '../../../../common/forms/input/TextInput';

export const InternalParcelSearchForm = ({ handleSubmit, loading }) => {
  const icon = loading ? 'fa fa-spinner fa-spin' : 'fa fa-search';
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Serie" required>
            <Field
              name="internalParcelDocumentSerie"
              component={TextInput}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Correlativo" required>
            <Field
              name="internalParcelDocumentCorrelation"
              component={TextInput}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter
          saveButtonText="Buscar"
          saveButtonIcon={icon}
          saveButtonDisabled={loading}
        />
      </Form>
    </>
  );
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.InternalTransfer.getIn(['current', 'activity']).isEmpty(),
});

InternalParcelSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

InternalParcelSearchForm.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'InternalParcelSearchForm',
})(InternalParcelSearchForm);

export default connect(mapStateToProps, null)(formComponent);
