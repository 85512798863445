import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { getFuelConsumptionQualification } from '../../../../utils/units/traffic';
import { driverFuelReportCircuitRowListPropTypes } from './FuelConsumptionReportPerDriverPropTypes';

const DriverFuelReportCircuitRowListResource = ({ data }) => {
  if (!data) {
    return null;
  }

  const tbody = data.map((row, index) => {
    const expectedFuelConsumption = +row.expectedFuelConsumption;
    const realFuelConsumption = +row.realFuelConsumption;

    const {
      qualificationText,
      qualificationColor,
    } = getFuelConsumptionQualification({
      expectedFuelConsumption,
      realFuelConsumption,
    });

    return (
      <tr key={+index}>
        <td className="border-right-black">{+index + 1}</td>
        <td className="border-right-black">{row.driver.customer.fullName}</td>
        <td className="border-right-black">
          {row.driver.customer.idDocumentNumber}
        </td>
        <td className="border-right-black">{row.circuitQuantity}</td>
        <td className="border-right-black">{row.circuitDriverEfficiency}</td>
        <td className={`border-right-black ${qualificationColor}`}>
          {qualificationText}
        </td>
        <td className="border-right-black">{+row.bonus}</td>
        <td className="border-right-black">{row.totalKilometers}</td>
        <td className="border-right-black">{row.expectedFuelConsumption}</td>
        <td className="border-right-black">{row.realFuelConsumption}</td>
        <td className="border-right-black">{row.performance}</td>
        <td className="border-right-black">{row.adblueEfficiency}</td>
        <td className="border-right-black">{row.expectedAdblueConsumption}</td>
        <td className="border-right-black">{row.realAdblueConsumption}</td>
      </tr>
    );
  });

  return (
    <Row>
      <Col>
        <Table
          striped
          responsive
          role="grid"
          className="border-black text-center"
        >
          <thead>
            <tr>
              <th colSpan={11} className="title border-black">
                COMBUSTIBLE - REPORTE CONTROL CONDUCTOR(ES)
              </th>
              <th colSpan={3} className="title border-black">
                AdBlue
              </th>
            </tr>
            <tr className="shadow">
              <th className="border-black">&nbsp;</th>
              <th className="border-black">Nombre Conductor</th>
              <th className="border-black">DNI</th>
              <th className="border-black">Total Circuitos</th>
              <th className="border-black">
                Eficiencia Consumo (/n conductores)
              </th>
              <th className="border-black">Calificación</th>
              <th className="border-black">Bono S/.</th>
              <th className="border-black">Km</th>
              <th className="border-black">Dotación Aut.</th>
              <th className="border-black">Consumo Real</th>
              <th className="border-black">Rendimiento km/gal</th>
              <th className="border-black">Eficiencia Consumo</th>
              <th className="border-black">Dotación Aut.</th>
              <th className="border-black">Consumo Real</th>
            </tr>
          </thead>
          <tbody>{tbody}</tbody>
        </Table>
      </Col>
    </Row>
  );
};

DriverFuelReportCircuitRowListResource.propTypes = {
  data: driverFuelReportCircuitRowListPropTypes,
};

DriverFuelReportCircuitRowListResource.defaultProps = {
  data: null,
};

export default DriverFuelReportCircuitRowListResource;
