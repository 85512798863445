import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateNumber } from '../../../../utils/validators';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isCircuitExtraordinary,
  clearIsCircuitExtraordinary,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import DatePicker from '../../../common/forms/input/DatePicker';

export const BaseFuelRefillForm = ({
  dispatchClearIsCircuitExtraordinary,
  handleSubmit,
  circuitExtraordinary,
  loading,
  dispatchIsCircuitExtraordinary,
}) => {
  useLayoutEffect(() => () => dispatchClearIsCircuitExtraordinary(), []);

  const onChangeRegisteredBusChange = (registeredBusOption) => {
    dispatchClearIsCircuitExtraordinary();
    if (registeredBusOption.value)
      dispatchIsCircuitExtraordinary({
        registeredBusId: registeredBusOption.value,
      });
  };

  if (loading) return <Loader />;

  let warningComponent = null;
  if (circuitExtraordinary)
    warningComponent = (
      <span className="text-warning">
        <i className="fa fa-warning" /> <strong>Advertencia:</strong> Este bus
        ha tenido una ruta / circuito extraordinario
      </span>
    );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Bus" required>
            <Field
              name="registeredBusId"
              component={RegisteredBusSelect}
              validate={[isRequired]}
              onChange={onChangeRegisteredBusChange}
            />
            {warningComponent}
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Fecha de Abastecimiento" required>
            <Field
              name="createDate"
              component={DatePicker}
              placeholder="Fecha de Abastecimiento"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Combustible Abastecido" required>
            <Field
              name="fuelQuantity"
              component={TextInput}
              type="text"
              placeholder="Combustible Abastecido"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Adblue Abastecido" required>
            <Field
              name="adblueQuantity"
              component={TextInput}
              type="text"
              placeholder="Adblue Abastecido"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentario">
            <Field
              name="comment"
              component={TextInput}
              type="textarea"
              placeholder="Comentario"
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    </>
  );
};

BaseFuelRefillForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  circuitExtraordinary: PropTypes.bool,
  dispatchIsCircuitExtraordinary: PropTypes.func.isRequired,
  dispatchClearIsCircuitExtraordinary: PropTypes.func.isRequired,
};

BaseFuelRefillForm.defaultProps = {
  circuitExtraordinary: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  circuitExtraordinary: TrafficUnit.BaseFuelRefill.getIn(['extraordinary']),
  loading: !TrafficUnit.BaseFuelRefill.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchIsCircuitExtraordinary: isCircuitExtraordinary,
  dispatchClearIsCircuitExtraordinary: clearIsCircuitExtraordinary,
};

const formComponent = reduxForm({
  form: 'BaseFuelRefillForm',
})(BaseFuelRefillForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
