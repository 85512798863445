import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getUserAgencies } from '../../../units/user/user/fetch';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { USER } from '../../../../config/shapes';

class UserAgenciesSelect extends Component {
  static propTypes = {
    user: PropTypes.shape(USER),
    isMulti: PropTypes.bool,
    input: inputPropTypes,
    meta: metaPropTypes,
  };

  static defaultProps = {
    input: inputDefaultProps,
    meta: metaDefaultProps,
    isMulti: false,
    user: null,
  };

  state = {
    isLoading: false,
    options: [],
  };

  componentDidMount() {
    const fetchData = async () => {
      const { user } = this.props;

      if (user) {
        this.setState({ isLoading: true });
        const agencies = await getUserAgencies({ userId: user.id });
        let options = [];
        if (agencies && agencies.length)
          options = agencies.map((agency) => ({
            value: agency.id,
            label: agency.name,
          }));
        this.setState({ isLoading: false, options });
      }
    };

    fetchData();
  }

  render() {
    const { isMulti, input, meta, ...rest } = this.props;

    const { isLoading, options } = this.state;

    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        {...rest}
      />
    );
  }
}

const mapStateToProps = ({ authentication }) => ({
  user: authentication.get('user'),
});

export default connect(mapStateToProps)(UserAgenciesSelect);
