import { API_ERROR } from './error-names';

class ApiError extends Error {
  constructor(code = null, ...params) {
    super(...params);
    this.name = API_ERROR;
    this.code = code;
  }
}

export default ApiError;
