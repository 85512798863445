import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  AUTHORIZED_PROFILES_PER_LOGGED_IN_USER_ENDPOINT,
  AUTHORIZED_PROFILE_BY_PROFILE_ID_ENDPOINT,
  AUTHORIZED_PROFILE_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_AUTHORIZED_PROFILES,
  GET_AUTHORIZED_PROFILES,
  CLEAR_AUTHORIZED_PROFILES,
  GET_AUTHORIZED_PROFILE,
  CLEAR_AUTHORIZED_PROFILE,
  FLAG_AUTHORIZED_PROFILE_ACTIVITY,
  GET_AUTHORIZED_PROFILES_BY_PROFILE_ID,
  CLEAR_AUTHORIZED_PROFILES_BY_PROFILE_ID,
  CLEAR_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER,
  GET_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER,
  FLAG_GETTING_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER,
} from '../types/security';
import { PROFILE_AUTHORIZED_BY_PROFILE_PATH } from '../../config/paths';

const flagGettingAuthorizedProfiles = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_AUTHORIZED_PROFILES,
    payload: flag,
  });

const getAuthorizedProfiles = async (tableFilters = null) => async dispatch => {
  try {
    dispatch(flagGettingAuthorizedProfiles(true));
    const query = tableFilters;
    const url = `${AUTHORIZED_PROFILE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const profiles = await response.json();
    dispatch({ type: GET_AUTHORIZED_PROFILES, payload: profiles });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingAuthorizedProfiles(false));
  }
};

const flagAuthorizedProfileActivity = flag => dispatch =>
  dispatch({
    type: FLAG_AUTHORIZED_PROFILE_ACTIVITY,
    payload: flag,
  });

const getAuthorizedProfile = async ({
  authorizedProfileId,
}) => async dispatch => {
  try {
    dispatch(flagAuthorizedProfileActivity(true));
    const url = `${AUTHORIZED_PROFILE_ENDPOINT}/${authorizedProfileId}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const profile = await response.json();
    dispatch({
      type: GET_AUTHORIZED_PROFILE,
      payload: profile,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagAuthorizedProfileActivity(false));
  }
};

const clearAuthorizedProfiles = () => dispatch =>
  dispatch({
    type: CLEAR_AUTHORIZED_PROFILES,
  });

const clearAuthorizedProfile = () => dispatch =>
  dispatch({
    type: CLEAR_AUTHORIZED_PROFILE,
  });

const getAuthorizedProfilesByProfileId = async ({
  profileId,
}) => async dispatch => {
  try {
    dispatch(flagAuthorizedProfileActivity(true));
    const url = `${AUTHORIZED_PROFILE_BY_PROFILE_ID_ENDPOINT}/${profileId}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const profiles = await response.json();
    dispatch({
      type: GET_AUTHORIZED_PROFILES_BY_PROFILE_ID,
      payload: profiles,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagAuthorizedProfileActivity(false));
  }
};

const clearAuthorizedProfilesByProfileId = () => dispatch =>
  dispatch({
    type: CLEAR_AUTHORIZED_PROFILES_BY_PROFILE_ID,
  });

const postAuthorizedProfile = async ({
  profileId,
  authorizedSecurityProfileId,
}) => async dispatch => {
  try {
    dispatch(flagAuthorizedProfileActivity(true));

    const url = AUTHORIZED_PROFILE_ENDPOINT;

    const profileLength = authorizedSecurityProfileId.length;
    let count = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const profile of authorizedSecurityProfileId) {
      const payload = {
        profileId,
        authorizedSecurityProfileId: profile.value,
      };

      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      // eslint-disable-next-line no-await-in-loop
      await isErrorResponse(response);

      // response.json();

      count += 1;
    }

    if (count === profileLength) {
      dispatch(push(PROFILE_AUTHORIZED_BY_PROFILE_PATH));
    }
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagAuthorizedProfileActivity(false));
  }
};

const putAuthorizedProfile = async (
  authorizedProfileId,
  { profileId, authorizedSecurityProfileId },
) => async dispatch => {
  try {
    dispatch(flagAuthorizedProfileActivity(true));
    const payload = {
      profileId,
      authorizedSecurityProfileId,
      id: authorizedProfileId,
    };
    const url = `${AUTHORIZED_PROFILE_ENDPOINT}/${authorizedProfileId}`;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const profile = await response.json();
    dispatch(push(`${PROFILE_AUTHORIZED_BY_PROFILE_PATH}/${profile.id}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagAuthorizedProfileActivity(false));
  }
};

const deleteAuthorizedProfile = async ({
  authorizedProfileId,
}) => async dispatch => {
  try {
    dispatch(flagAuthorizedProfileActivity(true));
    const url = `${AUTHORIZED_PROFILE_ENDPOINT}/${authorizedProfileId}`;
    const response = await fetch(url, {
      ...DEFAULT_DELETE_CONFIG,
    });
    await isErrorResponse(response);
    await response.json();
    dispatch(push(PROFILE_AUTHORIZED_BY_PROFILE_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagAuthorizedProfileActivity(false));
  }
};

const flagGettingAuthorizedProfilesPerLoggedInUser = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER,
    payload: flag,
  });

const getAuthorizedProfilesPerLoggedInUser = async () => async dispatch => {
  try {
    dispatch(flagGettingAuthorizedProfilesPerLoggedInUser(true));
    const url = AUTHORIZED_PROFILES_PER_LOGGED_IN_USER_ENDPOINT;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const profiles = await response.json();
    dispatch({
      type: GET_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER,
      payload: profiles,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingAuthorizedProfilesPerLoggedInUser(false));
  }
};

const clearAuthorizedProfilesPerLoggedInUser = () => dispatch =>
  dispatch({
    type: CLEAR_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER,
  });

export {
  flagGettingAuthorizedProfiles,
  getAuthorizedProfiles,
  clearAuthorizedProfiles,
  flagAuthorizedProfileActivity,
  getAuthorizedProfile,
  clearAuthorizedProfile,
  postAuthorizedProfile,
  putAuthorizedProfile,
  deleteAuthorizedProfile,
  getAuthorizedProfilesByProfileId,
  clearAuthorizedProfilesByProfileId,
  getAuthorizedProfilesPerLoggedInUser,
  clearAuthorizedProfilesPerLoggedInUser,
};
