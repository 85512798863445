import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../../config/locale';
import {
  userInternalInformationDefaultProps,
  userInternalInformationPropTypes,
} from '../proptypes/UserPropTypes';

const UserInternalResource = ({ id, lastLogin, lastUpdate, createDate }) => (
  <Fragment>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Último inicio de sesión:">
      {lastLogin
        ? tzNormalizeDate({ date: lastLogin, format: FULL_DATE_TIME_FORMAT })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Fecha de Creación:">
      {createDate
        ? tzNormalizeDate({ date: createDate, format: FULL_DATE_TIME_FORMAT })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Última actualización:">
      {lastUpdate
        ? tzNormalizeDate({ date: lastUpdate, format: FULL_DATE_TIME_FORMAT })
        : '-'}
    </ResourceProperty>
  </Fragment>
);

UserInternalResource.propTypes = userInternalInformationPropTypes;

UserInternalResource.defaultProps = userInternalInformationDefaultProps;

export default UserInternalResource;
