import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  clearInSystemNotifications,
  getInSystemNotifications,
} from '../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../config/queries';
import Loader from '../common/Loader';
import Alert from '../common/informative/Alert';

class SystemNotificationList extends Component {
  componentDidMount() {
    const query = { ...DEFAULT_QUERY_GET_ALL, query: 'current' };
    this.props.dispatchGetInSystemNotifications(query);
  }

  componentWillUnmount() {
    this.props.dispatchClearInSystemNotifications();
  }

  render() {
    const { notifications, loading } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (!notifications.isEmpty()) {
      return notifications
        .get('content')
        .map(({ notificationSeverity, message, id }) => (
          <Alert
            key={id}
            type={notificationSeverity.toLowerCase()}
            message={message}
          />
        ));
    }

    return null;
  }
}

SystemNotificationList.propTypes = {
  dispatchGetInSystemNotifications: PropTypes.func.isRequired,
  dispatchClearInSystemNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
};

SystemNotificationList.defaultProps = {
  notifications: null,
  loading: false,
};

const mapStateToProps = ({ SystemUnit }) => ({
  notifications: SystemUnit.InSystemNotification.getIn(['all', 'content']),
  loading: SystemUnit.InSystemNotification.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetInSystemNotifications: getInSystemNotifications,
  dispatchClearInSystemNotifications: clearInSystemNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemNotificationList);
