import React, { useState } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  MAINTENANCE_FACTOR_TYPE,
  MAINTENANCE_ACTIVITY_TYPE,
  ACTIVITY_ACTION,
  ACTIVITY_EXECUTION_METHOD,
} from '../../../../config/constants';
import OrganizationChartSelect from '../../../common/forms/select/OrganizationChartSelect';
import DynamicForm from '../../../common/forms/DynamicForm';
import {
  ACTIVITY_MATERIAL_FORM_COLUMNS,
  ACTIVITY_TASK_FORM_COLUMNS,
} from '../../../../config/dynamicFormFields';

export const ActivityFormFields = ({ showActivityLabel, initialValues }) => {
  const [fieldRequired, setIsFieldRequired] = useState(
    initialValues &&
      initialValues.activityType.value !==
        MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value,
  );

  const onChangeActivityType = (option) =>
    setIsFieldRequired(
      !!(option && option.value !== MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value),
    );

  return (
    <>
      <FormGroup row>
        <FormItem label="Tipo de Actividad" required={fieldRequired}>
          <Field
            name="activityType"
            component={Select}
            options={enumToSelectOptions(MAINTENANCE_ACTIVITY_TYPE)}
            onChange={onChangeActivityType}
            isClearable={false}
            validate={fieldRequired ? [isRequired] : []}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem
          label={showActivityLabel ? 'Actividad' : ''}
          required={showActivityLabel}
        >
          <Row>
            <Col lg={2} md={2} sm={6}>
              <Field
                name="companyActivityId"
                id="companyActivityId"
                component={TextInput}
                type="text"
                showToolTip
                placeholder="Código"
                validate={[isRequired]}
              />
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Field
                name="action"
                id="action"
                component={Select}
                options={enumToSelectOptions(ACTIVITY_ACTION)}
                showToolTip
                placeholder="Acción"
                validate={fieldRequired ? [isRequired] : []}
              />
            </Col>
            <Col lg={7} md={7} sm={12}>
              <Field
                name="name"
                id="name"
                component={TextInput}
                showToolTip
                placeholder="Nombre"
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Factor" required={fieldRequired}>
          <Row>
            <Col lg={3} md={4} sm={5}>
              <Field
                name="factor"
                id="factor"
                component={TextInput}
                type="input"
                placeholder="Factor"
                showToolTip
                validate={fieldRequired ? [isRequired] : []}
              />
            </Col>
            <Col lg={5} md={4} sm={5}>
              <Field
                name="factorType"
                id="factorType"
                component={Select}
                options={enumToSelectOptions(MAINTENANCE_FACTOR_TYPE)}
                showToolTip
                placeholder="Tipo de Factor"
                isClearable={false}
                validate={fieldRequired ? [isRequired] : []}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Método de Ejecución" required={fieldRequired}>
          <Field
            name="executionMethod"
            component={Select}
            options={enumToSelectOptions(ACTIVITY_EXECUTION_METHOD)}
            isClearable={false}
            validate={fieldRequired ? [isRequired] : []}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Organigrama" required={fieldRequired}>
          <Field
            name="companyAreaId"
            component={OrganizationChartSelect}
            validate={fieldRequired ? [isRequired] : []}
          />
        </FormItem>
      </FormGroup>
      <h3>Materiales asignados</h3>
      <DynamicForm
        name="materialList"
        columns={ACTIVITY_MATERIAL_FORM_COLUMNS}
      />
      <h3>Tareas asignadas</h3>
      <DynamicForm
        name="activityTaskList"
        columns={ACTIVITY_TASK_FORM_COLUMNS}
      />
    </>
  );
};

ActivityFormFields.propTypes = {
  showActivityLabel: PropTypes.bool,
  initialValues: PropTypes.shape({
    activityType: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

ActivityFormFields.defaultProps = {
  showActivityLabel: true,
  initialValues: null,
};

export default ActivityFormFields;
