import React from 'react';
import PropTypes from 'prop-types';
import { BoardColumnElementPropTypes } from './prop-types/BoardPropTypes';
import './BoardColumnElement.css';

const BoardColumnElement = ({ onClickElement, lastElement, body }) => (
  <div
    onClick={onClickElement}
    onKeyPress={onClickElement}
    className={`pointer card ${lastElement ? '' : 'mb-2'}`}
    role="button"
    tabIndex="0"
  >
    {body}
  </div>
);

BoardColumnElement.propTypes = {
  ...BoardColumnElementPropTypes,
  lastElement: PropTypes.bool.isRequired,
};

export default BoardColumnElement;
