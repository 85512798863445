import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { replace } from 'react-router-redux';
import { patchPurchaseOrderStatus } from '../../../../actions';
import { PATCH } from '../../../../config/permissions';
import { generateChangeStatusPurchaseOrderEndpoint } from '../../../../config/endpoints';
import withEndpointAuthorization from '../../../units/authorization/withEndPointAuthorization';
import Modal from '../../../common/modal/Modal';
import { PURCHASE_ORDER_STATUS } from '../../../../config/constants';
import { PURCHASE_ORDER_PATH } from '../../../../config/paths';

class ChangeStatusPurchaseOrderButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      comments: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.onDidMount();
  }

  onDidMount = () => {
    this.setState({ comments: this.props.comments });
  };

  onChangeComment = (e) => this.setState({ comments: e.target.value });

  onClickConfirmButton = async () => {
    const {
      purchaseOrderId,
      action,
      dispatchPatchPurchaseOrderStatus,
      dispatch,
    } = this.props;

    const { comments } = this.state;

    this.setState({ loading: true });

    const response = await dispatchPatchPurchaseOrderStatus({
      id: purchaseOrderId,
      status: action,
      comments,
    });

    if (response) {
      this.setState({ loading: false });

      dispatch(replace(PURCHASE_ORDER_PATH));
    }
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { action } = this.props;

    const { comments, loading, showModal } = this.state;

    let actionInBody = 'rechazará';
    let color = 'danger';
    let text = 'Rechazar';
    let commentField = (
      <textarea
        className="form-control"
        onChange={this.onChangeComment}
        placeholder="Debe ingresar un motivo"
        value={comments || ''}
      />
    );
    let disabled = action === PURCHASE_ORDER_STATUS.REJECTED.value && !comments;

    if (action === PURCHASE_ORDER_STATUS.APPROVED.value) {
      color = 'success';
      text = 'Aprobar';
      actionInBody = 'aprobará';
      commentField = null;
    }

    const body = (
      <>
        <span>
          Esta acción {actionInBody} la Orden de Compra. ¿Desea continuar?
        </span>
        {commentField}
      </>
    );

    let confirmButtonText = 'Confirmar';

    if (loading) {
      disabled = loading;
      confirmButtonText = (
        <>
          <i className="fa fa-spinner fa-spin fa-fw" /> Cargando
        </>
      );
    }

    return (
      <>
        <Modal
          show={showModal}
          body={body}
          title="Confirmación"
          onClickConfirm={this.onClickConfirmButton}
          onClickCancel={this.closeModal}
          onClickClose={this.closeModal}
          confirmButtonText={confirmButtonText}
          cancelButtonText="Cancelar"
          size="md"
          disableConfirmButton={disabled}
        />
        <Button color={color} onClick={this.showModal}>
          {text}
        </Button>
      </>
    );
  }
}

ChangeStatusPurchaseOrderButton.propTypes = {
  purchaseOrderId: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  dispatchPatchPurchaseOrderStatus: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  comments: PropTypes.string,
};

ChangeStatusPurchaseOrderButton.defaultProps = {
  comments: null,
};

const mapDispatchToprops = {
  dispatchPatchPurchaseOrderStatus: patchPurchaseOrderStatus,
};

const connectedComponent = connect(
  null,
  mapDispatchToprops,
)(ChangeStatusPurchaseOrderButton);

export default withEndpointAuthorization({
  url: generateChangeStatusPurchaseOrderEndpoint(),
  mapUrlParamsToProps: { purchaseOrderId: 'purchaseOrderId' },
  permissionType: PATCH,
})(connectedComponent);
