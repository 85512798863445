import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
} from 'reactstrap';
import { inputPropTypes, metaPropTypes } from './InputPropTypes';

class TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.input.value || '',
    };
  }

  onChangeTextArea = (e, onChange) => {
    this.setState({ value: e.target.value || '' });

    if (onChange) onChange(e);
  };

  render() {
    {
      const {
        id,
        input: { onChange, onBlur, value },
        name,
        placeholder,
        type = 'text',
        meta: { touched, error, warning },
        disabled,
        invalid,
        append,
        maxLength,
        showToolTip,
        size,
        setFieldToBeFocused,
        className,
      } = this.props;

      let appendButton = null;
      if (append)
        appendButton = (
          <InputGroupAddon addonType="append">{append}</InputGroupAddon>
        );

      const props = {
        disabled,
        invalid,
        onChange,
        onBlur,
        value,
        name,
        placeholder,
        type,
        bsSize: size,
        className,
      };

      if (setFieldToBeFocused)
        props.innerRef = (element) => setFieldToBeFocused(element);

      let characterCountComponent = null;
      if (type === 'textarea' && maxLength > 0) {
        const { value: stateValue } = this.state;

        props.maxLength = maxLength;
        props.onChange = (e) => this.onChangeTextArea(e, onChange);
        props.value = stateValue || value;
        characterCountComponent = (
          <FormText color="secondary" className="float-right">
            {maxLength - props.value.length} de {maxLength} caracteres
          </FormText>
        );
      }

      let toolTipComponent = null;
      if (showToolTip && id)
        toolTipComponent = (
          <UncontrolledTooltip placement="top" target={id}>
            {placeholder}
          </UncontrolledTooltip>
        );

      // TODO: To do this component to accepts file type

      return (
        <div>
          <div id={id}>
            <InputGroup>
              <Input {...props} />
              {appendButton}
            </InputGroup>
          </div>
          {toolTipComponent}
          <div>
            {characterCountComponent}
            <FormText color="danger">
              {touched &&
                ((error && <span>{error}</span>) ||
                  (warning && <span>{warning}</span>))}
            </FormText>
          </div>
        </div>
      );
    }
  }
}

TextInput.propTypes = {
  id: PropTypes.string,
  input: inputPropTypes.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  meta: metaPropTypes.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  append: PropTypes.element,
  maxLength: PropTypes.number,
  showToolTip: PropTypes.bool,
  size: PropTypes.string,
  setFieldToBeFocused: PropTypes.func,
};

TextInput.defaultProps = {
  id: null,
  name: '',
  placeholder: '',
  type: 'text',
  disabled: false,
  invalid: false,
  append: null,
  maxLength: 200,
  showToolTip: false,
  size: 'md',
  setFieldToBeFocused: null,
  className: '',
};

export default TextInput;
