import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ExtraordinaryMovementForm from './ExtraordinaryMovementForm';
import { postExtraordinaryMovement } from '../../../../actions/itinerary';
import { INT_DATE_FORMAT, TIME_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';

export class NewExtraordinaryMovement extends Component {
  onSubmit = (formValues) => {
    const formattedDepartureDate = formValues.departureDate
      ? tzNormalizeDate({
          date: formValues.departureDate,
          format: INT_DATE_FORMAT,
        })
      : tzNormalizeDate({ format: INT_DATE_FORMAT });
    const formattedDepartureTime = formValues.departureTime
      ? tzNormalizeDate({
          date: formValues.departureTime,
          format: TIME_FORMAT,
        })
      : tzNormalizeDate({ format: TIME_FORMAT });

    const formattedArrivalDate = formValues.arrivalDate
      ? tzNormalizeDate({
          date: formValues.arrivalDate,
          format: INT_DATE_FORMAT,
        })
      : tzNormalizeDate({ format: INT_DATE_FORMAT });
    const formattedArrivalTime = formValues.arrivalTime
      ? tzNormalizeDate({
          date: formValues.arrivalTime,
          format: TIME_FORMAT,
        })
      : tzNormalizeDate({ format: TIME_FORMAT });

    const newFormValues = {
      extraordinaryMovementType: formValues.extraordinaryMovementType.value,
      busId: formValues.busId.value,
      routeId: formValues.routeId.value,
      sourceLocation: formValues.sourceLocation,
      destinationLocation: formValues.destinationLocation,
      routeDetail: formValues.routeDetail,
      circuitId: formValues.circuitId.value,
      seatMapId: formValues.seatMapId ? formValues.seatMapId.value : null,
      serviceTypeId: formValues.serviceTypeId
        ? formValues.serviceTypeId.value
        : null,
      departureTime: tzNormalizeDate({
        date: `${formattedDepartureDate} ${formattedDepartureTime}`,
      }),
      arrivalTime: tzNormalizeDate({
        date: `${formattedArrivalDate} ${formattedArrivalTime}`,
      }),
      cabinCrewCommissionAmount: formValues.cabinCrewCommissionAmount || 0,
      driverCommissionAmount: formValues.driverCommissionAmount || 0,
      tollExpenseAmount: formValues.tollExpenseAmount || 0,
      otherExpenseAmount: formValues.otherExpenseAmount || 0,
      distance: formValues.distance || 0,
      fuelGallons: formValues.fuelGallons || 0,
      adBlueLiters: formValues.adBlueLiters || 0,
      comment: formValues.comment,
    };

    this.props.dispatchPostExtraordinaryMovement(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Crear Movimiento Extraordinario"
        subtitle="Crear un nuevo movimiento extraordinario"
        content={<ExtraordinaryMovementForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    // TODO link to list of extraordinary movement goes here
    {
      text: 'Nuevo Movimiento Extraordinario',
      href: NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostExtraordinaryMovement: postExtraordinaryMovement,
};

NewExtraordinaryMovement.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostExtraordinaryMovement: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewExtraordinaryMovement);
