import Immutable from 'immutable';
import {
  FLAG_GETTING_USERS,
  GET_USERS,
  CLEAR_USERS,
  GET_USER,
  CLEAR_USER,
  FLAG_USER_ACTIVITY,
} from '../../../actions/types/user/User';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_GETTING_USERS:
      return state.setIn(['all', 'loading'], payload);
    case GET_USERS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_USERS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case GET_USER:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_USER:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_USER_ACTIVITY:
      // raising flag
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      // lowering flag
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    default:
      return state;
  }
};
