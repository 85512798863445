import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getGeographicZones,
  clearGeographicZones,
} from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

class GeographicZoneSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearGeographicZones } = this.props;
    dispatchClearGeographicZones();
  }

  handleInputChange = (inputValue) => {
    const { dispatchGetGeographicZones } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetGeographicZones({ query: `name:${inputValue}` });
    }
  };

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        onInputChange={this.handleInputChange}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

GeographicZoneSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetGeographicZones: PropTypes.func.isRequired,
  dispatchClearGeographicZones: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

GeographicZoneSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

const mapStateToProps = ({ LocationUnit }) => ({
  options: LocationUnit.GeographicZone.getIn(['all', 'content', 'content']).map(
    (geographicZone) => ({
      value: geographicZone.id,
      label: geographicZone.name,
    }),
  ),
  loading: LocationUnit.GeographicZone.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetGeographicZones: getGeographicZones,
  dispatchClearGeographicZones: clearGeographicZones,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeographicZoneSelect);
