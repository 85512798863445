import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserLocales, clearUserLocales } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_QUERY } from '../../../../config/queries';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

class UserLocaleSelect extends Component {
  componentDidMount() {
    const { dispatchGetUserLocales } = this.props;
    dispatchGetUserLocales({ ...DEFAULT_QUERY });
  }

  componentWillUnmount() {
    const { dispatchClearUserLocales } = this.props;
    dispatchClearUserLocales();
  }

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

UserLocaleSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetUserLocales: PropTypes.func.isRequired,
  dispatchClearUserLocales: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

UserLocaleSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

const mapStateToProps = ({ UserUnit }) => ({
  options: UserUnit.UserLocale.getIn(['all', 'content', 'content']).map(
    (locale) => ({ value: locale.locale, label: locale.name }),
  ),
  loading: UserUnit.UserLocale.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetUserLocales: getUserLocales,
  dispatchClearUserLocales: clearUserLocales,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLocaleSelect);
