import React, { useState } from 'react';
import {
  maintenanceServiceOrderMaintenanceWarningListDefaultProps,
  maintenanceServiceOrderMaintenanceWarningListPropTypes,
} from '../prop-types/MaintenanceServiceOrderPropTypes';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_WARNING_PRIORITY,
  MAINTENANCE_WARNING_TYPE,
} from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import MaintenanceWarningBasicInformation from '../../maintenance-warning/resource/MaintenanceWarningBasicInformation';
import BadgeList from '../../../../common/BadgeList';
import Modal from '../../../../common/modal/Modal';
import Alert from '../../../../common/informative/Alert';

const MaintenanceServiceOrderMaintenanceWarningBadgeListInformation = ({
  maintenanceWarningList,
}) => {
  const [showList, setShowList] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const onShowModal = (state, body = null) => {
    if (body) setModalBody(<MaintenanceWarningBasicInformation {...body} />);

    setShowModal(state);
  };

  const toggle = () => setShowList(!showList);

  const icon = (
    <i
      onClick={toggle}
      onKeyPress={toggle}
      role="button"
      tabIndex={0}
      className={`fa fa-${
        showList ? 'minus' : 'plus'
      }-square-o text-primary clickable`}
    />
  );

  let component = (
    <Alert type="info" message="No hay reportes de ocurrencia asociados" />
  );

  if (maintenanceWarningList.length > 0) {
    const textArray = maintenanceWarningList.map((warning) => {
      const typeText = warning.maintenanceWarningType
        ? ` - ${
            MAINTENANCE_WARNING_TYPE[warning.maintenanceWarningType].label
          } `
        : '';

      let failModeText =
        warning.maintenanceFailMode && warning.maintenanceFailMode.description
          ? ` - ${warning.maintenanceFailMode.description} `
          : '';
      if (
        warning.creationType === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value &&
        warning.activityBus
      )
        failModeText = `${warning.activityBus.activity.companyActivityId} - ${warning.activityBus.activity.name}`;

      const text = (
        <span
          role="button"
          onClick={() => onShowModal(true, warning)}
          onKeyPress={() => onShowModal(true, warning)}
          tabIndex={0}
        >
          {warning.id} -{' '}
          {tzNormalizeDate({
            date: warning.issueDate,
            format: DATE_TIME_FORMAT,
          })}
          {typeText}
          {failModeText}({MAINTENANCE_ACTIVITY_TYPE[warning.creationType].label}
          )
        </span>
      );

      return {
        text,
        color: MAINTENANCE_WARNING_PRIORITY[warning.priority].color,
      };
    });

    component = (
      <>
        <BadgeList textArray={textArray} pill />
        <Modal
          show={showModal}
          title="Reporte de Ocurrencia"
          body={modalBody}
          onClickClose={() => onShowModal(false)}
        />
      </>
    );
  }

  return (
    <>
      <h3>Reportes de Ocurrencia asociadas {icon}</h3>
      {showList && component}
    </>
  );
};

MaintenanceServiceOrderMaintenanceWarningBadgeListInformation.propTypes =
  maintenanceServiceOrderMaintenanceWarningListPropTypes;

MaintenanceServiceOrderMaintenanceWarningBadgeListInformation.defaultProps =
  maintenanceServiceOrderMaintenanceWarningListDefaultProps;

export default MaintenanceServiceOrderMaintenanceWarningBadgeListInformation;
