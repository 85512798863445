import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import ExpectedFuelConsumptionForm from './ExpectedFuelConsumptionForm';
import { EXPECTED_FUEL_CONSUMPTION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getExpectedFuelConsumption,
  clearExpectedFuelConsumption,
  putExpectedFuelConsumption,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';

export class EditExpectedFuelConsumption extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const { dispatchGetExpectedFuelConsumption } = this.props;
    dispatchGetExpectedFuelConsumption({ id });
  }

  componentWillUnmount() {
    const { dispatchClearExpectedFuelConsumption } = this.props;
    dispatchClearExpectedFuelConsumption();
  }

  handleExpectedFuelConsumptionSubmit = (formValues) => {
    const { dispatchPutExpectedFuelConsumption } = this.props;
    const expectedFuelConsumptionId = parseInt(this.props.match.params.id, 10);
    dispatchPutExpectedFuelConsumption(expectedFuelConsumptionId, formValues);
  };

  render() {
    const { breadcrumbs, expectedFuelConsumption, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (expectedFuelConsumption.isEmpty()) {
      content = <NoDataResource returnPage={EXPECTED_FUEL_CONSUMPTION_PATH} />;
    } else {
      const initialValues = {
        route: {
          label: expectedFuelConsumption.get('route').name,
          value: expectedFuelConsumption.get('route').id,
        },
        comment: expectedFuelConsumption.get('comment'),
        busFuelGroupConsumptionList: expectedFuelConsumption
          .get('busFuelGroupConsumptionList')
          .map((busFuelGroup) => ({
            busFuelGroupId: {
              value: busFuelGroup.busFuelGroupId,
              label: busFuelGroup.busFuelGroup.name,
            },
            numberOfGallon: busFuelGroup.numberOfGallon,
            numberOfGallonAdblue: busFuelGroup.numberOfGallonAdblue,
          })),
      };

      content = (
        <ExpectedFuelConsumptionForm
          onSubmit={this.handleExpectedFuelConsumptionSubmit}
          initialValues={initialValues}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Dotación de Combustible"
        subtitle="Editar dotación de combustible"
        content={content}
      />
    );
  }
}

EditExpectedFuelConsumption.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetExpectedFuelConsumption: PropTypes.func.isRequired,
  dispatchClearExpectedFuelConsumption: PropTypes.func.isRequired,
  dispatchPutExpectedFuelConsumption: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  expectedFuelConsumption: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditExpectedFuelConsumption.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Dotaciones de Combustible',
      href: EXPECTED_FUEL_CONSUMPTION_PATH,
    },
    {
      text: 'Ver',
      href: `${EXPECTED_FUEL_CONSUMPTION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  expectedFuelConsumption: TrafficUnit.ExpectedFuelConsumption.getIn([
    'current',
    'content',
  ]),
  loading: !TrafficUnit.ExpectedFuelConsumption.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExpectedFuelConsumption: getExpectedFuelConsumption,
  dispatchClearExpectedFuelConsumption: clearExpectedFuelConsumption,
  dispatchPutExpectedFuelConsumption: putExpectedFuelConsumption,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditExpectedFuelConsumption);
