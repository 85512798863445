import React from 'react';
import { connect } from 'react-redux';

import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ItineraryManifestForm from './ItineraryManifestForm';

export const ManifestItinerary = ({
  breadcrumbs,
  match: {
    params: { id: itineraryId },
  },
}) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Manifiesto del Itinerario"
    subtitle="Manifiesto del Itinerario"
    content={<ItineraryManifestForm itineraryId={itineraryId} />}
  />
);

ManifestItinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Manifiesto de Itinerarios',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(ManifestItinerary);
