import Immutable from 'immutable';
import {
  HOME_PATH,
  TICKET_PATH,
  TICKET_CUSTOMER_PATH,
  TICKET_MASSIVE_POSTPONE_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'reservations',
      title: 'Gestión de Reservas',
      path: '/reservation/reservations',
      icon: 'book',
      disabled: true,
    },
    {
      key: 'tickets',
      title: 'Boletos',
      path: TICKET_PATH,
      icon: 'ticket',
    },
    {
      key: 'ticketscustomer',
      title: 'Boletos por Pedido',
      path: TICKET_CUSTOMER_PATH,
      icon: 'ticket',
    },
    {
      key: 'ticket-massive-postpone',
      title: 'Postergación Masiva',
      path: TICKET_MASSIVE_POSTPONE_PATH,
      icon: 'unlink',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Reservaciones',
      tag: 'span',
      href: '/reservation',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
