import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Button } from 'reactstrap';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { getVoucherTypes, getBusinesses } from '../../../actions';
import {
  isRequired,
  validateNumber,
  validateLength,
  validateRuc,
} from '../../../utils/validators';
import TextInput from '../forms/input/TextInput';
import { DEFAULT_SELF_SERVICE_VOUCHER_TYPE_OPTION } from '../../../config/defaults';
import { BUSINESS_INVOICE_ID, CONTRACT_TYPE } from '../../../config/constants';
import Select from './select/Select';
import BusinessSelect from './select/BusinessSelect';

const validateLength11 = validateLength(11, 'dígitos');

class VoucherTypeInputGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBusiness: false,
      newBusiness: false,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const { dispatchGetVoucherTypes, agencyOfSale, salesSessionUserId } =
      this.props;

    let showBusiness = false;

    if (!salesSessionUserId) {
      showBusiness =
        agencyOfSale.contractType === CONTRACT_TYPE.CORPORATE.value;
    }

    this.setState({ showBusiness });

    dispatchGetVoucherTypes();
  };

  handleBusinessChange = (business) => {
    if (business.value) {
      this.setState({ newBusiness: false });
      this.cleanNewBusiness();
    }
  };

  handleNewBusiness = () => {
    const { change, businessFieldName } = this.props;
    change(businessFieldName, null);
    this.setState({ newBusiness: true });
    this.cleanNewBusiness();
  };

  cleanNewBusiness = () => {
    const {
      change,
      businessTaxIdFieldName,
      businessNameFieldName,
      addressSummaryFieldName,
    } = this.props;
    change(businessTaxIdFieldName, null);
    change(businessNameFieldName, null);
    change(addressSummaryFieldName, null);
  };

  handleVoucherChange = (voucher) => {
    const showBusiness = voucher.value === BUSINESS_INVOICE_ID;
    if (!showBusiness) {
      const { change, businessFieldName } = this.props;
      change(businessFieldName, null);
      this.cleanNewBusiness();
      this.setState({ newBusiness: false });
    }
    this.setState({ showBusiness });
  };

  render() {
    const {
      voucherTypes,
      businessFieldName,
      voucherTypeFieldName,
      businessTaxIdFieldName,
      businessNameFieldName,
      addressSummaryFieldName,
      agencyOfSale,
      salesSessionUserId,
    } = this.props;

    let disabled = false;

    if (!salesSessionUserId)
      disabled = agencyOfSale.contractType === CONTRACT_TYPE.CORPORATE.value;

    let newBusiness = null;
    if (this.state.newBusiness) {
      newBusiness = (
        <>
          <FormGroup row>
            <Col sm={3} xs={12}>
              <Label>
                RUC <span className="text-danger">*</span>
              </Label>
            </Col>
            <Col sm={8} xs={12}>
              <Field
                name={businessTaxIdFieldName}
                component={TextInput}
                type="text"
                placeholder="Número de RUC"
                validate={[
                  isRequired,
                  validateNumber,
                  validateLength11,
                  validateRuc,
                ]}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3} xs={12}>
              <Label>
                Razón Social <span className="text-danger">*</span>
              </Label>
            </Col>
            <Col sm={8} xs={12}>
              <Field
                name={businessNameFieldName}
                component={TextInput}
                type="text"
                placeholder="Razón social"
                validate={[isRequired]}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3} xs={12}>
              <Label>
                Dirección <span className="text-danger">*</span>
              </Label>
            </Col>
            <Col sm={8} xs={12}>
              <Field
                name={addressSummaryFieldName}
                component={TextInput}
                type="text"
                placeholder="Dirección"
                validate={[isRequired]}
              />
            </Col>
          </FormGroup>
        </>
      );
    }

    let businessSelect = null;
    if (this.state.showBusiness) {
      businessSelect = (
        <FormGroup row>
          <Col sm={3} xs={12}>
            <Label>
              Empresa <span className="text-danger">*</span>
            </Label>
          </Col>
          <Col sm={7} xs={12}>
            <Field
              name={businessFieldName}
              component={BusinessSelect}
              isDisabled={disabled}
              onChange={this.handleBusinessChange}
              validate={this.state.newBusiness ? null : [isRequired]}
            />
          </Col>
          {!disabled ? (
            <Col sm={2} xs={12}>
              <Button
                className="pull-right"
                type="button"
                color="primary"
                outline
                onClick={this.handleNewBusiness}
              >
                Crear
              </Button>
            </Col>
          ) : null}
        </FormGroup>
      );
    }
    return (
      <>
        <FormGroup row>
          <Col sm={3} xs={12}>
            <Label>
              Comprobante <span className="text-danger">*</span>
            </Label>
          </Col>
          <Col sm={8} xs={12}>
            <Field
              name={voucherTypeFieldName}
              component={Select}
              options={voucherTypes}
              isDisabled={disabled}
              onChange={this.handleVoucherChange}
              validate={[isRequired]}
            />
          </Col>
        </FormGroup>
        {businessSelect}
        {newBusiness}
      </>
    );
  }
}

VoucherTypeInputGroup.propTypes = {
  voucherTypes: Select.propTypes.options.isRequired,
  dispatchGetVoucherTypes: PropTypes.func.isRequired,
  businessFieldName: PropTypes.string.isRequired,
  voucherTypeFieldName: PropTypes.string.isRequired,
  businessTaxIdFieldName: PropTypes.string.isRequired,
  businessNameFieldName: PropTypes.string.isRequired,
  addressSummaryFieldName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  agencyOfSale: PropTypes.shape({
    contractType: PropTypes.string,
    company: PropTypes.shape({
      business: PropTypes.shape({
        name: PropTypes.string,
        businessTaxId: PropTypes.string,
      }),
    }),
  }),
  salesSessionUserId: PropTypes.number,
};

VoucherTypeInputGroup.defaultProps = {
  salesSessionUserId: null,
  agencyOfSale: null,
};

const mapStateToProps = (state) => {
  let voucherTypes = state.AccountingUnit.VoucherType.getIn([
    'all',
    'content',
    'content',
  ]).reduce((options, voucherType) => {
    if (voucherType.displayInPaymentsPage) {
      options.push({
        value: voucherType.id,
        label: voucherType.name,
      });
    }
    return options;
  }, []);

  // Checking corporate sale
  const activeSaleSession = state.SalesUnit.SalesSession.getIn([
    'active',
    'content',
  ]);
  const agencyOfSale = activeSaleSession
    ? activeSaleSession.get('agency')
    : null;
  // End

  // Checking self service sale
  const { salesSessionUserId } = state.authentication.get('user');

  if (salesSessionUserId)
    voucherTypes = [DEFAULT_SELF_SERVICE_VOUCHER_TYPE_OPTION];

  return {
    voucherTypes,
    agencyOfSale,
    salesSessionUserId,
  };
};

const mapDispatchToProps = {
  dispatchGetVoucherTypes: getVoucherTypes,
  dispatchGetBusinesses: getBusinesses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoucherTypeInputGroup);
