import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { COMPANY_USER_CONFIGURATION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CompanyUserConfigurationForm from './CompanyUserConfigurationForm';
import { postCompanyUserConfiguration } from '../../../../actions';
import Content from '../../../layout/Content';

export class NewCompanyUserConfiguration extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      userId: formValues.userId.value,
      companyId: formValues.companyId.value,
      parentUserId: formValues.parentUserId.value,
    };

    this.props.dispatchPostCompanyUserConfiguration(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Configuración de Usuario"
        subtitle="Crea una nueva configuración de usuario"
        content={<CompanyUserConfigurationForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuraciones de Usuarios',
      href: COMPANY_USER_CONFIGURATION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostCompanyUserConfiguration: postCompanyUserConfiguration,
};

NewCompanyUserConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCompanyUserConfiguration: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCompanyUserConfiguration);
