import React from 'react';
import PropTypes from 'prop-types';
import {
  POSTPAID_BOOKING_APPROVED,
  POSTPAID_BOOKING_REJECTED,
  TRANSACTION_TYPE_PAGO_EFECTIVO,
} from '../../../../config/constants';
import '../ActionsCell.css';
import ApprovePostpaidBookingCell from './ApprovePostpaidBookingCell';
import RejectPostpaidBookingCell from './RejectPostpaidBookingCell';

const PostpaidBookingActionsCell = ({
  customerOrderId,
  transactionCode,
  status,
}) => {
  if (
    status === POSTPAID_BOOKING_APPROVED ||
    status === POSTPAID_BOOKING_REJECTED ||
    transactionCode === TRANSACTION_TYPE_PAGO_EFECTIVO
  )
    return null;

  return (
    <div className="actionsContainer">
      <ApprovePostpaidBookingCell
        customerOrderId={customerOrderId}
        transactionCode={transactionCode}
      />{' '}
      <RejectPostpaidBookingCell customerOrderId={customerOrderId} />
    </div>
  );
};

PostpaidBookingActionsCell.propTypes = {
  customerOrderId: PropTypes.number.isRequired,
  transactionCode: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default PostpaidBookingActionsCell;
