import React, { Fragment } from 'react';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';
import { EXPENSE_TYPE_PATH } from '../../../../config/paths';

const EditExpenseTypeButton = ({ expenseTypeId }) => (
  <Fragment>
    <Link
      className="btn btn-primary"
      to={`${EXPENSE_TYPE_PATH}/${expenseTypeId}/edit`}
    >
      Editar
    </Link>
  </Fragment>
);

EditExpenseTypeButton.propTypes = {
  expenseTypeId: PropTypes.number.isRequired,
};

export default EditExpenseTypeButton;
