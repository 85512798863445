import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getIdentificationTypes,
  clearIdentificationTypes,
} from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

class IdentificationTypeSelect extends Component {
  componentDidMount = () => {
    const { dispatchGetIdentificationTypes } = this.props;
    dispatchGetIdentificationTypes();
  };

  componentWillUnmount() {
    const { dispatchClearIdentificationTypes } = this.props;
    dispatchClearIdentificationTypes();
  }

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        onInputChange={this.handleInputChange}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

IdentificationTypeSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetIdentificationTypes: PropTypes.func.isRequired,
  dispatchClearIdentificationTypes: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

IdentificationTypeSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

const mapStateToProps = ({ UserUnit }) => ({
  options: UserUnit.IdentificationType.getIn(['all', 'content', 'content']).map(
    (identificationType) => ({
      value: identificationType.id,
      label: identificationType.name,
      identificationInCountry: identificationType.identificationInCountry,
    }),
  ),
  loading: UserUnit.IdentificationType.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetIdentificationTypes: getIdentificationTypes,
  dispatchClearIdentificationTypes: clearIdentificationTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentificationTypeSelect);
