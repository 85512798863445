import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import Decimal from 'decimal.js';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateNumber,
  isValidNumber,
} from '../../../../utils/validators';
import Grid from '../../../common/grid/Grid';
import { generateNewPurchaseOrderGridColumns } from '../../../../config/gridColumns';
import { PURCHASE_ORDER_PATH } from '../../../../config/paths';
import UserAgenciesSelect from '../../../common/forms/select/UserAgenciesSelect';
import {
  DEBOUNCE_WAIT_TIME,
  PURCHASE_ORDER_STATUS,
} from '../../../../config/constants';
import Alert from '../../../common/informative/Alert';
import { getFuelVoucherOptions } from '../fuel-voucher/config/fetch';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import { numberFormatter } from '../../../../utils/number';
import FormFooter from '../../../common/forms/FormFooter';

const selector = formValueSelector('PurchaseOrderForm');

class PurchaseOrderForm extends Component {
  state = {
    fuelVoucherGridValues: [],
    totalAmount: 0,
    totalGallons: 0,
    agencyId: null,
    showGridComponent: false,
    gridInitialValues: [],
    allFuelVoucherOptions: [],
    selectFuelVoucherOptions: [],
    formDisabled: false,
  };

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate({ pricePerGalonValue: previousPricePerGalonValue }) {
    const { pricePerGalonValue } = this.props;
    const { fuelVoucherGridValues } = this.state;
    if (pricePerGalonValue !== previousPricePerGalonValue) {
      this.calculateTotals(fuelVoucherGridValues, pricePerGalonValue || 0);
    }
  }

  onMount = async () => {
    const { initialValues } = this.props;
    if (initialValues) {
      const totalGallons = initialValues.purchaseOrderDetailList.reduce(
        (accumulated, purchaseOrderDetail) =>
          accumulated + purchaseOrderDetail.realNumberOfGallons,
        0,
      );

      let formDisabled = false;

      if (
        initialValues &&
        initialValues.status !== PURCHASE_ORDER_STATUS.CREATED.value
      )
        formDisabled = true;

      this.setState({
        showGridComponent: true,
        totalAmount: initialValues.totalAmount,
        totalGallons,
        gridInitialValues: initialValues.purchaseOrderDetailList,
        formDisabled,
      });

      this.getFuelVoucherOptions(initialValues.agency.value);
    }
  };

  onHandleSubmit = async (formValues) => {
    const { fuelVoucherGridValues, totalAmount } = this.state;
    const { setLoading, dispatchPush, createPayload, fetchFunction } =
      this.props;
    if (this.validateFuelVoucherGridValues(fuelVoucherGridValues)) {
      const payload = createPayload({
        formValues,
        fuelVoucherGridValues,
        totalAmount,
      });
      // load parent component
      setLoading(true);
      const newPurchaseOrder = await fetchFunction(payload);
      setLoading(false);
      if (newPurchaseOrder) {
        // redirect to newly created resource
        dispatchPush(`${PURCHASE_ORDER_PATH}/${newPurchaseOrder.id}`);
      }
    }
  };

  getSelectedFuelVouchers = (fuelVoucherGridValues) =>
    this.setState({
      selectFuelVoucherOptions: fuelVoucherGridValues.map(
        (fuelVoucherGridValue) => fuelVoucherGridValue.fuelVoucher,
      ),
    });

  getNoSelectedFuelVoucher = () => {
    const { allFuelVoucherOptions, selectFuelVoucherOptions } = this.state;
    const noSelectedFuelVoucher = allFuelVoucherOptions.filter(
      (fuelVoucher) => selectFuelVoucherOptions.indexOf(fuelVoucher) === -1,
    );
    return noSelectedFuelVoucher;
  };

  getFuelVoucherOptions = async (agencyId) => {
    const fuelVoucherOptions = await getFuelVoucherOptions({
      id: agencyId,
    });
    this.setState({ allFuelVoucherOptions: fuelVoucherOptions });
  };

  handleGridValueChange = (fuelVoucherGridValues) => {
    this.setState({ fuelVoucherGridValues });
    const { pricePerGalonValue } = this.props;
    this.getSelectedFuelVouchers(fuelVoucherGridValues);
    this.calculateTotals(fuelVoucherGridValues, pricePerGalonValue);
  };

  calculateTotals = (fuelVoucherGridValues, pricePerGalonValue = 0) => {
    let amount = 0;
    let gallons = 0;
    const { totalGallons, totalAmount } = this.state;
    if (isValidNumber(pricePerGalonValue)) {
      fuelVoucherGridValues.forEach(({ realNumberOfGallons }) => {
        if (isValidNumber(realNumberOfGallons)) {
          // perform calculation only if pricePerGalonValue and realNumberOfGallons
          // are valid numbers
          amount = new Decimal(amount)
            .plus(
              new Decimal(realNumberOfGallons).times(
                new Decimal(pricePerGalonValue),
              ),
            )
            .toNumber();
          gallons = new Decimal(gallons)
            .plus(new Decimal(realNumberOfGallons))
            .toNumber();
        }
      });
      if (fuelVoucherGridValues.length === 0) {
        this.setState({
          totalAmount,
          totalGallons,
        });
      } else {
        this.setState({
          totalAmount: amount,
          totalGallons: gallons,
        });
      }
    }
  };

  validateFuelVoucherGridValues = (gridValues) => {
    let isValid = true;
    if (gridValues.length) {
      const isInvalidData = gridValues.some((rowValue) => {
        // validate the content of key in each row
        if (!(rowValue.fuelVoucher && rowValue.fuelVoucher.value)) {
          toastr.error('Error', 'Seleccione un voucher de combustible.');
          return true;
        }
        if (
          !(
            rowValue.realNumberOfGallons &&
            isValidNumber(rowValue.realNumberOfGallons)
          )
        ) {
          toastr.error('Error', 'Ingrese un número de galones reales válido.');
          return true;
        }
        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error', 'Ingrese datos de voucher.');
      isValid = false;
    }
    return isValid;
  };

  generateGridInitialValues = () => this.state.gridInitialValues;

  handleUserAgenciesChange = (agency) => {
    this.initializeGrid(agency);
  };

  initializeGrid = async (agency) => {
    this.setState({
      gridInitialValues: [],
      showGridComponent: false,
    });
    await new Promise((resolve) => setTimeout(resolve, DEBOUNCE_WAIT_TIME));
    if (agency.value) {
      this.setState({
        agencyId: agency.value,
        showGridComponent: true,
      });
      this.getFuelVoucherOptions(agency.value);
    } else {
      this.setState({ agencyId: null });
    }
  };

  showGridComponent = () => {
    const { agencyId, showGridComponent, gridInitialValues, formDisabled } =
      this.state;

    const { initialValues } = this.props;

    let gridComponent = null;
    if (agencyId === null)
      gridComponent = <Alert message="Seleccione una agencia" />;
    else gridComponent = <Alert message="Cargando..." />;

    if (showGridComponent || gridInitialValues.length !== 0) {
      gridComponent = (
        <Grid
          columns={generateNewPurchaseOrderGridColumns(
            initialValues ? initialValues.agency.value : agencyId,
            this.getNoSelectedFuelVoucher(),
          )}
          initialValues={this.generateGridInitialValues()}
          onValueChange={this.handleGridValueChange}
          disabled={formDisabled}
        />
      );
    }

    return gridComponent;
  };

  render() {
    const { totalAmount, totalGallons, formDisabled } = this.state;

    const { handleSubmit } = this.props;

    const gridComponent = this.showGridComponent();

    const saveButton = !formDisabled ? <FormFooter /> : null;

    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Serie: " required>
            <Field
              name="documentSeries"
              component={TextInput}
              type="text"
              disabled
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Correlativo: " required>
            <Field
              name="documentCode"
              component={TextInput}
              type="text"
              disabled
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Agencia" required>
            <Field
              name="agency"
              component={UserAgenciesSelect}
              onChange={this.handleUserAgenciesChange}
              isDisabled={formDisabled}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Precio por galón: " required>
            <Field
              name="pricePerGalon"
              component={TextInput}
              type="text"
              validate={[isRequired, validateNumber]}
              disabled={formDisabled}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentarios: " required>
            <Field
              name="comments"
              component={TextInput}
              type="textarea"
              validate={[isRequired]}
              disabled={formDisabled}
            />
          </FormItem>
        </FormGroup>
        {gridComponent}
        <Row className="mt-4 d-flex justify-content-end">
          <Col lg={3} md={5} sm={7} xs={8}>
            <h4>
              <b>Total Galones reales:</b>
            </h4>
          </Col>
          <Col lg={2} md={3} sm={4} xs={4}>
            <h4 className="text-right">
              <b>{`${totalGallons}`}</b>
            </h4>
          </Col>
        </Row>
        <Row className="mt-1 flex justify-content-end">
          <Col lg={3} md={5} sm={7} xs={8}>
            <h4>
              <b>Total:</b>
            </h4>
          </Col>
          <Col lg={2} md={3} sm={4} xs={4}>
            <h4 className="text-right">
              <b>{numberFormatter({ value: totalAmount })}</b>
            </h4>
          </Col>
        </Row>
        {saveButton}
      </Form>
    );
  }
}

PurchaseOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pricePerGalonValue: PropTypes.number,
  setLoading: PropTypes.func.isRequired,
  dispatchPush: PropTypes.func.isRequired,
  createPayload: PropTypes.func.isRequired,
  fetchFunction: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    documentSeries: PropTypes.string.isRequired,
    documentCode: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    purchaseOrderDetailList: PropTypes.arrayOf(
      PropTypes.shape({
        realNumberOfGallons: PropTypes.number.isRequired,
      }),
    ),
    status: PropTypes.string,
    agency: optionPropTypes,
  }),
};

PurchaseOrderForm.defaultProps = {
  pricePerGalonValue: 0,
  initialValues: null,
};

const mapStateToProps = (state) => {
  const pricePerGalonValue = Number(selector(state, 'pricePerGalon') || 0);
  return {
    pricePerGalonValue,
  };
};

const mapDispatchToProps = {
  dispatchPush: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'PurchaseOrderForm',
  })(PurchaseOrderForm),
);
