import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import ItineraryQualityControlForm from './ItineraryQualityControlForm';
import {
  getItineraryQuality,
  clearItineraryQuality,
  putItineraryQuality,
  postItineraryQuality,
} from '../../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../../common/Loader';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { generateGetItineraryQualityEndpoint } from '../../../../../config/endpoints';
import { PUT } from '../../../../../config/permissions';
import Content from '../../../../layout/Content';

export class EditItineraryQualityControl extends Component {
  state = {
    itineraryQualityId: 0,
  };

  componentDidMount() {
    const {
      dispatchGetItineraryQuality,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetItineraryQuality({ itineraryId: id });
  }

  componentWillUnmount() {
    const { dispatchClearItineraryQuality } = this.props;
    dispatchClearItineraryQuality();
  }

  onSubmit = (formValues) => {
    const { itineraryQualityId } = this.state;
    const {
      dispatchPostItineraryQuality,
      dispatchPutItineraryQuality,
      match: {
        params: { id: itineraryId },
      },
    } = this.props;
    const formValuesItineraryQuality = {
      comments: formValues.comments,
      itineraryId,
    };
    if (formValues.delayReasonList) {
      formValuesItineraryQuality.delayReasonList =
        formValues.delayReasonList.map((delayReason) => ({
          id: delayReason.value,
          reason: delayReason.label,
        }));
    }
    if (itineraryQualityId === 0) {
      dispatchPostItineraryQuality(formValuesItineraryQuality);
    } else {
      dispatchPutItineraryQuality(
        itineraryQualityId,
        formValuesItineraryQuality,
      );
    }
  };

  generateInitialValues = (itineraryQuality) => {
    const { itineraryQualityId } = this.state;
    if (!itineraryQuality.isEmpty()) {
      if (itineraryQualityId === 0) {
        this.setState({ itineraryQualityId: itineraryQuality.get('id') });
      }
      return {
        comments: itineraryQuality.get('comments'),
        delayReasonList: itineraryQuality
          .get('delayReasonList')
          .map((delayReason) => ({
            value: delayReason.id,
            label: delayReason.reason,
          })),
      };
    }
    return {};
  };

  render() {
    const { breadcrumbs, itineraryQuality, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else {
      content = (
        <ItineraryQualityControlForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(itineraryQuality)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Control Calidad"
        subtitle="Editar datos de control calidad"
        content={content}
      />
    );
  }
}

EditItineraryQualityControl.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItineraryQuality: PropTypes.func.isRequired,
  dispatchClearItineraryQuality: PropTypes.func.isRequired,
  dispatchPostItineraryQuality: PropTypes.func.isRequired,
  dispatchPutItineraryQuality: PropTypes.func.isRequired,
  itineraryQuality: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
};

EditItineraryQualityControl.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_FOR_COUNTER_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_FOR_COUNTER_PATH}/${id}`,
    },
    {
      text: 'Control Calidad',
      href: '',
    },
  ],
  itineraryQuality: ItineraryUnit.ItineraryQuality.getIn([
    'current',
    'content',
  ]),
  loading: !ItineraryUnit.ItineraryQuality.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItineraryQuality: getItineraryQuality,
  dispatchClearItineraryQuality: clearItineraryQuality,
  dispatchPutItineraryQuality: putItineraryQuality,
  dispatchPostItineraryQuality: postItineraryQuality,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditItineraryQualityControl);

export default withEndpointAuthorization({
  url: generateGetItineraryQualityEndpoint(0),
  permissionType: PUT,
})(connectedComponent);
