import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHierarchies, clearHierarchies } from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

const HierarchySelect = ({
  dispatchGetHierarchies,
  dispatchClearHierarchies,
  options,
  loading,
  noOptionsMessage,
  input,
  meta,
  isDisabled,
  isClearable,
}) => {
  /*
   * The following hook overrides the componentDidUnmount and componentWillUnmount function.
   * View documentation in https://bit.ly/3OI2V0u
   */
  useLayoutEffect(() => {
    // This line overrides the componenDidUpdate function.
    dispatchGetHierarchies({ ...DEFAULT_QUERY_GET_ALL });

    // This line overrides the componentWillUnmount function.
    return () => dispatchClearHierarchies();
  }, []);

  const props = {
    options,
    isLoading: loading,
    noOptionsMessage,
    input,
    meta,
    isDisabled,
    isClearable,
  };

  return <Select {...props} />;
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.Hierarchy.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, name }) => ({
    value: id,
    label: name,
  })),
  loading: MechanicalMaintenanceUnit.Hierarchy.getIn(['all', 'loading']),
});

HierarchySelect.propTypes = {
  options: optionsPropTypes,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetHierarchies: PropTypes.func.isRequired,
  dispatchClearHierarchies: PropTypes.func.isRequired,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
};

HierarchySelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  noOptionsMessage: undefined,
  isDisabled: false,
  isClearable: false,
  options: [],
};
const mapDispatchToProps = {
  dispatchGetHierarchies: getHierarchies,
  dispatchClearHierarchies: clearHierarchies,
};

export default connect(mapStateToProps, mapDispatchToProps)(HierarchySelect);
