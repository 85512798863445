import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import DeleteBusFuelGroupButton from './DeleteBusFuelGroupButton';
import EditBusFuelGroupButton from './EditBusFuelGroupButton';

const BusFuelGroupToolbar = ({ busFuelGroupId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditBusFuelGroupButton busFuelGroupId={busFuelGroupId} />{' '}
      <DeleteBusFuelGroupButton busFuelGroupId={busFuelGroupId} />
    </div>
  </ButtonToolbar>
);

BusFuelGroupToolbar.propTypes = {
  busFuelGroupId: PropTypes.number.isRequired,
};

export default BusFuelGroupToolbar;
