import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { DISTRICT_PATH, NEW_DISTRICT_PATH } from '../../../../config/paths';
import { getDistricts, clearDistricts } from '../../../../actions/location';
import { DISTRICTS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Districts extends Component {
  componentWillUnmount() {
    const { dispatchClearDistricts } = this.props;
    dispatchClearDistricts();
  }

  render() {
    const { districts, loading, breadcrumbs, dispatchGetDistricts } =
      this.props;
    const data = districts.get('content') || [];
    const pages = districts.get('totalPages') || null;
    const defaultPageSize = districts.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Distritos"
        buttonPath={NEW_DISTRICT_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: DISTRICTS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetDistricts,
          loading,
          modelPath: DISTRICT_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetDistricts: getDistricts,
  dispatchClearDistricts: clearDistricts,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Distritos',
      href: DISTRICT_PATH,
    },
  ],
  districts: LocationUnit.District.getIn(['all', 'content']),
  loading: LocationUnit.District.getIn(['all', 'loading']),
});

Districts.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  districts: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDistricts: PropTypes.func.isRequired,
  dispatchClearDistricts: PropTypes.func.isRequired,
};

Districts.defaultProps = {
  districts: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Districts);
