import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Alert from '../informative/Alert';
import { NO_DATA_ERROR_MESSAGE } from '../../../config/messages';
import ReturnButton from '../button/ReturnButton';

const NoDataResource = ({ returnPage }) => {
  const button = returnPage ? <ReturnButton path={returnPage} /> : null;
  return (
    <Fragment>
      <Alert message={NO_DATA_ERROR_MESSAGE} />
      {button}
    </Fragment>
  );
};

NoDataResource.propTypes = {
  returnPage: PropTypes.string,
};

NoDataResource.defaultProps = {
  returnPage: null,
};

export default NoDataResource;
