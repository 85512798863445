import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditDeliveryGroupButton from './EditDeliveryGroupButton';
import DeleteDeliveryGroupButton from './DeleteDeliveryGroupButton';

const DeliveryGroupToolbar = ({ deliveryGroupId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditDeliveryGroupButton deliveryGroupId={deliveryGroupId} />{' '}
      <DeleteDeliveryGroupButton deliveryGroupId={deliveryGroupId} />
    </div>
  </ButtonToolbar>
);

DeliveryGroupToolbar.propTypes = {
  deliveryGroupId: PropTypes.number.isRequired,
};

export default DeliveryGroupToolbar;
