import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import Loader from '../../../common/Loader';
import GeographicZoneSelect from '../../../common/forms/select/GeographicZoneSelect';
import FormFooter from '../../../common/forms/FormFooter';

// eslint-disable-next-line react/prefer-stateless-function
class GeographicZonesCombinationForm extends Component {
  render() {
    const { handleSubmit, loading } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Zona Origen" required>
            <Field
              name="sourceGeographicZone"
              component={GeographicZoneSelect}
              placeholder="Zona Origen"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Zona Destino" required>
            <Field
              name="destinationGeographicZone"
              component={GeographicZoneSelect}
              placeholder="Zona Destino"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Factor" required>
            <Field
              name="factor"
              component={TextInput}
              type="text"
              placeholder="Factor"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

GeographicZonesCombinationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

GeographicZonesCombinationForm.defaultProps = {
  loading: PropTypes.bool,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: CargoUnit.GeographicZonesCombination.getIn(['all', 'loading']),
});

const formComponent = reduxForm({
  form: 'GeographicZonesCombinationForm',
})(GeographicZonesCombinationForm);

export default connect(mapStateToProps, null)(formComponent);
