import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_DEPRECIATION_RULES,
  GET_DEPRECIATION_RULES,
  CLEAR_DEPRECIATION_RULES,
  GET_DEPRECIATION_RULE,
  FLAG_DEPRECIATION_RULE_ACTIVITY,
  CLEAR_DEPRECIATION_RULE,
} from '../types';
import { DEPRECIATION_RULE_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEPRECIATION_RULE_PATH } from '../../config/paths';

const flagGettingDepreciationRules = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_DEPRECIATION_RULES,
    payload: flag,
  });

const getDepreciationRules = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingDepreciationRules(true));
    const query = tableFilters;
    const url = `${DEPRECIATION_RULE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const DepreciationRules = await response.json();
    dispatch({
      type: GET_DEPRECIATION_RULES,
      payload: DepreciationRules,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingDepreciationRules(false));
  }
};

const flagDepreciationRuleActivity = flag => dispatch =>
  dispatch({
    type: FLAG_DEPRECIATION_RULE_ACTIVITY,
    payload: flag,
  });

const getDepreciationRule = async ({ id }) => async dispatch => {
  try {
    dispatch(flagDepreciationRuleActivity(true));
    const url = `${DEPRECIATION_RULE_ENDPOINT}/${id}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    // check if it is an error response
    await isErrorResponse(response);
    const depreciationRule = await response.json();
    dispatch({
      type: GET_DEPRECIATION_RULE,
      payload: depreciationRule,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDepreciationRuleActivity(false));
  }
};

const clearDepreciationRules = () => dispatch =>
  dispatch({
    type: CLEAR_DEPRECIATION_RULES,
  });

const clearDepreciationRule = () => dispatch =>
  dispatch({
    type: CLEAR_DEPRECIATION_RULE,
  });

const deleteDepreciationRule = async ({
  depreciationRuleId,
}) => async dispatch => {
  try {
    dispatch(flagDepreciationRuleActivity(true));
    const url = `${DEPRECIATION_RULE_ENDPOINT}/${depreciationRuleId}`;
    const response = await fetch(url, {
      ...DEFAULT_DELETE_CONFIG,
    });
    await isErrorResponse(response);
    await response.json();
    dispatch(push(DEPRECIATION_RULE_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDepreciationRuleActivity(false));
  }
};

const postDepreciationRule = async ({
  depreciationType,
  itemCategoryId,
  depreciationPeriodicity,
  depreciationPercentage,
  description,
}) => async dispatch => {
  try {
    dispatch(flagDepreciationRuleActivity(true));
    const payload = {
      depreciationType: depreciationType.value,
      itemCategoryId: itemCategoryId ? itemCategoryId.value : itemCategoryId,
      depreciationPeriodicity: depreciationPeriodicity.value,
      depreciationPercentage,
      description,
    };
    const url = DEPRECIATION_RULE_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    await response.json();
    dispatch(push(DEPRECIATION_RULE_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDepreciationRuleActivity(false));
  }
};

const putDepreciationRule = async (
  depreciationRuleId,
  {
    depreciationType,
    itemCategoryId,
    depreciationPeriodicity,
    depreciationPercentage,
    description,
  },
) => async dispatch => {
  try {
    dispatch(flagDepreciationRuleActivity(true));
    const payload = {
      id: depreciationRuleId,
      depreciationType: depreciationType.value,
      itemCategoryId: itemCategoryId ? itemCategoryId.value : itemCategoryId,
      depreciationPeriodicity: depreciationPeriodicity.value,
      depreciationPercentage,
      description,
    };
    const url = `${DEPRECIATION_RULE_ENDPOINT}/${depreciationRuleId}`;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const depreciationRule = await response.json();
    dispatch(push(`${DEPRECIATION_RULE_PATH}/${depreciationRule.id}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagDepreciationRuleActivity(false));
  }
};

export {
  flagGettingDepreciationRules,
  getDepreciationRules,
  clearDepreciationRules,
  getDepreciationRule,
  clearDepreciationRule,
  deleteDepreciationRule,
  postDepreciationRule,
  putDepreciationRule,
};
