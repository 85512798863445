import { toastr } from 'react-redux-toastr';
import {
  PURCHASE_ORDER_ENDPOINT,
  generatePurchaseOrderEndpoint,
} from '../../../../../config/endpoints';
import Fetch from '../../../../../utils/Fetch';

const getPurchaseOrder = async ({ id }) => {
  try {
    const url = `${PURCHASE_ORDER_ENDPOINT}/${id}`;
    const purchaseOrder = await Fetch.get(url);
    return purchaseOrder;
  } catch (error) {
    toastr.error('Error',error.message);
    return null;
  }
};

const postPurchaseOrder = async ({
  comments,
  totalAmount,
  agencyId,
  purchaseOrderDetailList,
  status,
}) => {
  try {
    const payload = {
      comments,
      totalAmount,
      agencyId,
      purchaseOrderDetailList,
      status,
    };
    const purchaseOrder = await Fetch.post({
      url: PURCHASE_ORDER_ENDPOINT,
      payload,
    });
    return purchaseOrder;
  } catch (error) {
    toastr.error('Error',error.message);
    return null;
  }
};

const putPurchaseOrder = async ({
  id,
  documentSeries,
  documentCode,
  comments,
  totalAmount,
  agencyId,
  purchaseOrderDetailList,
  status,
  userId,
}) => {
  try {
    const payload = {
      id,
      documentSeries,
      documentCode,
      comments,
      totalAmount,
      agencyId,
      purchaseOrderDetailList,
      status,
      userId,
    };
    const purchaseOrder = await Fetch.put({
      url: `${PURCHASE_ORDER_ENDPOINT}/${id}`,
      payload,
    });
    return purchaseOrder;
  } catch (error) {
    toastr.error('Error',error.message);
    return null;
  }
};

const deletePurchaseOrder = async ({ id }) => {
  try {
    await Fetch.delete({ url: generatePurchaseOrderEndpoint(id) });
    return true;
  } catch (error) {
    toastr.error('Error',error.message);
    return false;
  }
};

export {
  getPurchaseOrder,
  postPurchaseOrder,
  putPurchaseOrder,
  deletePurchaseOrder,
};
