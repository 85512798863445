import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { deleteGeographicZonesCombination } from '../../../../actions';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class DeleteGeographicZonesCombinationButton extends Component {
  state = {
    showWarningModal: false,
  };

  showDeleteModal = () => this.setState({ showWarningModal: true });

  closeDeleteModal = () => this.setState({ showWarningModal: false });

  handleDelete = () => {
    const {
      geographicZonesCombinationId,
      dispatchDeleteGeographicZonesCombination,
    } = this.props;
    dispatchDeleteGeographicZonesCombination({ geographicZonesCombinationId });
  };

  render() {
    const { showWarningModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        onClickConfirm={this.handleDelete}
        onClickCancel={this.closeDeleteModal}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.showDeleteModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DeleteGeographicZonesCombinationButton.propTypes = {
  geographicZonesCombinationId: PropTypes.number.isRequired,
  dispatchDeleteGeographicZonesCombination: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteGeographicZonesCombination: deleteGeographicZonesCombination,
};

export default connect(
  null,
  mapDispatchToProps,
)(DeleteGeographicZonesCombinationButton);
