import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import CargoCorporateCreditForm from '../cargo-corporate-credit/CargoCorporateCreditForm';
import { postCargoCorporateCredit } from '../../../../actions/cargo/CargoCorporateCredit';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const NewCargoCorporateCredit = ({
  breadcrumbs,
  dispatchPostCargoCorporateCredit,
}) => {
  const onSubmit = (formValues) => {
    dispatchPostCargoCorporateCredit({
      ...formValues,
      companyId: formValues.companyId.value,
      actualBalance: formValues.initialBalance,
      createDate: tzNormalizeDate({
        format: TIMESTAMP_FORMAT,
      }),
      lastUpdate: tzNormalizeDate({
        format: TIMESTAMP_FORMAT,
      }),
    });
  };
  const content = <CargoCorporateCreditForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Crédito Corporativo de Carga"
      subtitle="Crear crédito corporativo de carga"
      content={content}
    />
  );
};

NewCargoCorporateCredit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCargoCorporateCredit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCargoCorporateCredit: postCargoCorporateCredit,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCargoCorporateCredit);
