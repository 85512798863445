import React from 'react';
import { connect } from 'react-redux';
import { LIVE_SALES_CONTROL_PATH } from '../../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../../layout/Content';
import LiveItinerarySalesControlSearch from './LiveItinerarySalesControlSearch';

const LiveItinerarySalesControl = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Monitoreo de Ventas en Vivo"
    subtitle="Busca por ubicaciones, por fechas y hora"
    content={<LiveItinerarySalesControlSearch />}
  />
);

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Monitoreo de Ventas en Vivo',
      href: LIVE_SALES_CONTROL_PATH,
    },
  ],
});

LiveItinerarySalesControl.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

export default connect(mapStateToProps)(LiveItinerarySalesControl);
