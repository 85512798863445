import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateTicket } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import { BAGGAGE_SEARCH_OPTIONS } from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';

const BaggageSearchForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Buscar por" required>
        <Field
          name="searchBy"
          component={Select}
          options={BAGGAGE_SEARCH_OPTIONS}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Boleto" required>
        <Field
          name="ticket"
          component={TextInput}
          type="text"
          placeholder="Boleto"
          validate={[isRequired, validateTicket]}
        />
      </FormItem>
    </FormGroup>
    <Row>
      <Col className="flex row-reverse">
        <Button type="submit" outline>
          <i className="fa fa-search" /> Buscar
        </Button>
      </Col>
    </Row>
  </Form>
);

BaggageSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'BaggageSearchForm',
})(BaggageSearchForm);
