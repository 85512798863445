import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
} from '../../../../config/constants';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import {
  clearMaintenanceWarnings,
  getMaintenanceWarnings,
  patchChangeServiceOrderIdOfMaintenanceWarning,
} from '../../../../actions';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import DynamicForm from '../../../common/forms/DynamicForm';
import { generateMaintenanceWarningColumns } from '../../../../config/dynamicFormFields';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import Alert from '../../../common/informative/Alert';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { maintenanceWarningGenerator } from '../../../../utils/app/json-generator-from-reducer';
import { maintenanceWarningBasicInformationPropTypes } from '../maintenance-warning/prop-types/MaintenanceWarningPropTypes';

const hasEqualWarning = ({ value }, formValues) => {
  let duplicateCount = 0;
  formValues.preventiveMaintenanceWarningList.forEach((warning) => {
    if (
      warning.maintenanceWarningId &&
      warning.maintenanceWarningId.value === value
    )
      duplicateCount += 1;
  });
  formValues.correctiveMaintenanceWarningList.forEach((warning) => {
    if (
      warning.maintenanceWarningId &&
      warning.maintenanceWarningId.value === value
    )
      duplicateCount += 1;
  });

  return duplicateCount >= 2 ? 'Ocurrencia repetida' : undefined;
};

export const MaintenanceServiceOrderForm = ({
  handleSubmit,
  loading,
  dispatchChange,
  editionMode,
  stepMode,
  dispatchGetMaintenanceWarnings,
  dispatchClearMaintenanceWarnings,
  maintenanceWarnings,
  loadingMaintenanceWarnings,
  initialValues,
  dispatchPatchChangeServiceOrderIdOfMaintenanceWarning,
  maintenanceServiceOrderId,
}) => {
  const [registeredBus, setRegisteredBus] = useState(
    initialValues && initialValues.registeredBusId,
  );

  useEffect(() => {
    if (maintenanceWarnings.length > 0) {
      const preventiveMaintenanceWarningListValue = maintenanceWarnings.filter(
        (warning) =>
          warning.creationType === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value,
      );

      dispatchChange(
        'MaintenanceServiceOrderForm',
        'preventiveMaintenanceWarningList',
        preventiveMaintenanceWarningListValue.map((warning) => ({
          maintenanceWarningId: maintenanceWarningGenerator(warning),
        })),
      );

      const correctiveMaintenanceWarningListValue = maintenanceWarnings.filter(
        (warning) =>
          warning.creationType === MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value,
      );

      dispatchChange(
        'MaintenanceServiceOrderForm',
        'correctiveMaintenanceWarningList',
        correctiveMaintenanceWarningListValue.map((warning) => ({
          maintenanceWarningId: maintenanceWarningGenerator(warning),
        })),
      );
    }

    return () => dispatchClearMaintenanceWarnings();
  }, [maintenanceWarnings]);

  if (loading) return <Loader />;

  const onChangeRegisteredBus = (option) => {
    if (
      !registeredBus ||
      (registeredBus && registeredBus.value !== option.value)
    ) {
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'preventiveMaintenanceWarningList',
        null,
      );
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'correctiveMaintenanceWarningList',
        null,
      );

      dispatchClearMaintenanceWarnings();
      dispatchGetMaintenanceWarnings({
        ...DEFAULT_QUERY_GET_ALL,
        query: [`registeredBusId:${option.value}`, `serviceOrderId:Ø`],
      });
    }

    setRegisteredBus(option);
  };

  const onClickRemove = async (index, fields, type) => {
    const maintenanceWarningList =
      type === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value
        ? initialValues.preventiveMaintenanceWarningList[index]
        : initialValues.correctiveMaintenanceWarningList[index];

    if (maintenanceWarningList) {
      const {
        maintenanceWarningId: { value },
      } = maintenanceWarningList;
      const response =
        await dispatchPatchChangeServiceOrderIdOfMaintenanceWarning({
          maintenanceWarningId: value,
          serviceOrderId: 0,
        });

      if (response) fields.remove(index);
    } else fields.remove(index);
  };

  const footer =
    stepMode && maintenanceServiceOrderId ? (
      <FormFooter
        saveButtonIcon="fa fa-arrow-circle-right"
        saveButtonText="Continuar"
      >
        <Link
          className="btn btn-secondary"
          type="button"
          to={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}`}
        >
          Cancelar
        </Link>
      </FormFooter>
    ) : (
      <FormFooter />
    );

  let preventiveMaintenanceWarningListComponent = (
    <Alert type="info" message="Seleccione un bus" />
  );
  let correctiveMaintenanceWarningListComponent = (
    <Alert type="info" message="Seleccione un bus" />
  );
  if (loadingMaintenanceWarnings) {
    preventiveMaintenanceWarningListComponent = <Loader />;
    correctiveMaintenanceWarningListComponent = <Loader />;
  } else if (registeredBus) {
    const preventiveDynamicFormProps = {
      name: 'preventiveMaintenanceWarningList',
      columns: generateMaintenanceWarningColumns(
        hasEqualWarning,
        registeredBus.value,
        'Preventiva',
      ),
    };
    if (editionMode)
      preventiveDynamicFormProps.onClickRemove = (index, fields) =>
        onClickRemove(
          index,
          fields,
          MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value,
        );
    // TODO if front lock screen when there are many data
    // shows DynamicForm component implementing local state
    preventiveMaintenanceWarningListComponent = (
      <DynamicForm {...preventiveDynamicFormProps} />
    );

    const correctiveDynamicFormProps = {
      name: 'correctiveMaintenanceWarningList',
      columns: generateMaintenanceWarningColumns(
        hasEqualWarning,
        registeredBus.value,
        'Correctiva',
      ),
    };
    if (editionMode)
      correctiveDynamicFormProps.onClickRemove = (index, fields) =>
        onClickRemove(
          index,
          fields,
          MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value,
        );
    // TODO if front lock screen when there are many data
    // shows DynamicForm component implementing local state
    correctiveMaintenanceWarningListComponent = (
      <DynamicForm {...correctiveDynamicFormProps} />
    );
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Bus" required>
            <Field
              name="registeredBusId"
              component={RegisteredBusSelect}
              onChange={onChangeRegisteredBus}
              validate={[isRequired]}
              isDisabled={editionMode}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Fecha Límite" required>
            <Field
              name="deadline"
              component={TextInput}
              type="datetime-local"
              placeholder={DATE_TIME_FORMAT}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Prioridad" required>
            <Field
              name="priority"
              component={Select}
              options={enumToSelectOptions(MAINTENANCE_SERVICE_ORDER_PRIORITY)}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              maxLength={0}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Estado" required>
            <Field
              name="status"
              component={Select}
              options={enumToSelectOptions(MAINTENANCE_SERVICE_ORDER_STATUS)}
              validate={[isRequired]}
              isDisabled={!editionMode}
            />
          </FormItem>
        </FormGroup>
        <h3>Reportes de Ocurrencia</h3>
        <Row>
          <Col md="6">{preventiveMaintenanceWarningListComponent}</Col>
          <Col md="6">{correctiveMaintenanceWarningListComponent}</Col>
        </Row>
        {footer}
      </Form>
    </>
  );
};

MaintenanceServiceOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  editionMode: PropTypes.bool,
  stepMode: PropTypes.bool,
  dispatchGetMaintenanceWarnings: PropTypes.func.isRequired,
  dispatchClearMaintenanceWarnings: PropTypes.func.isRequired,
  maintenanceWarnings: PropTypes.arrayOf(
    PropTypes.shape(maintenanceWarningBasicInformationPropTypes),
  ).isRequired,
  loadingMaintenanceWarnings: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    registeredBusId: optionPropTypes,
    preventiveMaintenanceWarningList: PropTypes.arrayOf(
      PropTypes.shape(maintenanceWarningBasicInformationPropTypes),
    ),
    correctiveMaintenanceWarningList: PropTypes.arrayOf(
      PropTypes.shape(maintenanceWarningBasicInformationPropTypes),
    ),
  }),
  dispatchPatchChangeServiceOrderIdOfMaintenanceWarning:
    PropTypes.func.isRequired,
  maintenanceServiceOrderId: PropTypes.number,
};

MaintenanceServiceOrderForm.defaultProps = {
  initialValues: null,
  editionMode: false,
  stepMode: false,
  maintenanceServiceOrderId: null,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  maintenanceWarnings: MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'all',
    'content',
    'content',
  ]),
  loadingMaintenanceWarnings:
    MechanicalMaintenanceUnit.MaintenanceWarning.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetMaintenanceWarnings: getMaintenanceWarnings,
  dispatchClearMaintenanceWarnings: clearMaintenanceWarnings,
  dispatchPatchChangeServiceOrderIdOfMaintenanceWarning:
    patchChangeServiceOrderIdOfMaintenanceWarning,
};

const formComponent = reduxForm({
  form: 'MaintenanceServiceOrderForm',
})(MaintenanceServiceOrderForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
