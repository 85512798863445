import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CARGO_DISTANCE_PATH } from '../../../../config/paths';

const EditCargoDistanceButton = ({ cargoDistanceId }) => (
  <Link
    className="btn btn-primary"
    to={`${CARGO_DISTANCE_PATH}/${cargoDistanceId}/edit`}
  >
    Editar
  </Link>
);

EditCargoDistanceButton.propTypes = {
  cargoDistanceId: PropTypes.number.isRequired,
};

export default EditCargoDistanceButton;
