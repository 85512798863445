import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Table from '../../../common/Table';
import { BILLING_COLUMNS } from '../../../../config/columns';
import { clearBillings, getBillings } from '../../../../actions';
import { BILLING_PATH } from '../../../../config/paths';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

export const BillingSearchResult = ({
  loading,
  billings,
  dispatchClearBillings,
}) => {
  useLayoutEffect(() => () => dispatchClearBillings(), []);

  return (
    <div className="mt-3">
      <Table
        columns={BILLING_COLUMNS}
        data={billings.get('content') || []}
        loading={loading}
        modelPath={BILLING_PATH}
        navigateToModelOnRowClick
        manual={false}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
    </div>
  );
};

const mapStateToProps = ({ AccountingUnit }) => ({
  loading: AccountingUnit.Billing.getIn(['all', 'loading']),
  billings: AccountingUnit.Billing.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchClearBillings: clearBillings,
  dispatchGetBillings: getBillings,
};

BillingSearchResult.propTypes = {
  billings: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearBillings: PropTypes.func.isRequired,
};

BillingSearchResult.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingSearchResult);
