import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  FLAG_GETTING_PUBLIC_CITIES,
  GET_PUBLIC_CITIES,
} from '../../../actions/types/booking';

const INITIAL_STATE = Immutable.Map({
  form: Immutable.Map({
    error: null,
    activity: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    activity: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    activity: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PUBLIC_CITIES:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case FLAG_GETTING_PUBLIC_CITIES:
      return state.setIn(['all', 'activity'], action.payload);
    default:
      return state;
  }
};
