import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { FormText } from 'reactstrap';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';
import { debounce } from '../../../../utils/promise';

const FormSelect = ({
  input: { onChange, value },
  name,
  options,
  isLoading,
  isClearable,
  isMulti,
  onInputChange,
  placeholder,
  isDisabled,
  meta: { touched, error, warning },
  autoFocus,
  noOptionsMessage,
  filterOption,
  setFieldToBeFocused,
}) => {
  const props = {
    isDisabled,
    onChange,
    onInputChange: debounce(onInputChange),
    value,
    name,
    options,
    isClearable,
    isMulti,
    isLoading,
    placeholder,
    autoFocus,
    noOptionsMessage,
    loadingMessage: () => 'Cargando...',
  };

  if (setFieldToBeFocused)
    props.ref = (element) => setFieldToBeFocused(element);

  if (filterOption) props.filterOption = filterOption;

  return (
    <div>
      <ReactSelect {...props} />
      <FormText color="danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </FormText>
    </div>
  );
};

FormSelect.propTypes = {
  input: inputPropTypes,
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: optionsPropTypes,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  meta: metaPropTypes,
  autoFocus: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  filterOption: PropTypes.func,
  setFieldToBeFocused: PropTypes.func,
};

FormSelect.defaultProps = {
  name: '',
  options: [],
  input: inputDefaultProps,
  meta: metaDefaultProps,
  onInputChange: () => {},
  isLoading: false,
  isClearable: false,
  isMulti: false,
  isDisabled: false,
  placeholder: 'Selecciona ...',
  autoFocus: false,
  noOptionsMessage: () => 'No hay resultados.',
  filterOption: null,
  setFieldToBeFocused: null,
};

export default FormSelect;
