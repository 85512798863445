import { combineReducers } from 'redux';
import SeatMap from './SeatMap';
import RegisteredBus from './RegisteredBus';
import ServiceType from './ServiceType';
import UnitHome from './UnitHome';

export default combineReducers({
  SeatMap,
  RegisteredBus,
  UnitHome,
  ServiceType,
});
