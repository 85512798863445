import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { DEPRECIATION_RULE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getDepreciationRule,
  clearDepreciationRule,
  putDepreciationRule,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import DepreciationRuleForm from './DepreciationRuleForm';
import {
  DEPRECIATION_TYPE,
  DEPRECIATION_PERIODICITY,
} from '../../../../config/constants';

export class EditDepreciationRule extends Component {
  componentDidMount() {
    const {
      dispatchGetDepreciationRule,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDepreciationRule({ id });
  }

  componentWillUnmount() {
    const { dispatchClearDepreciationRule } = this.props;
    dispatchClearDepreciationRule();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutDepreciationRule,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutDepreciationRule(id, formValues);
  };

  createInitialValues = (depreciationRule) => {
    const depreciationRuleJson = {
      depreciationType:
        DEPRECIATION_TYPE[depreciationRule.get('depreciationType')],
      depreciationPeriodicity:
        DEPRECIATION_PERIODICITY[
          depreciationRule.get('depreciationPeriodicity')
        ],
      depreciationPercentage: depreciationRule.get('depreciationPercentage'),

      description: depreciationRule.get('description'),
    };

    const itemCategory = depreciationRule.get('itemCategory');

    if (itemCategory) {
      depreciationRuleJson.itemCategoryId = {
        label: itemCategory.name,
        value: itemCategory.id,
      };
    }
    return depreciationRuleJson;
  };

  render() {
    const { breadcrumbs, depreciationRule, loading } = this.props;

    if (loading || depreciationRule.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Editar Regla de Depreciación</h1>
          </Col>
        </Row>
        <DepreciationRuleForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(depreciationRule)}
        />
      </Container>
    );
  }
}

EditDepreciationRule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDepreciationRule: PropTypes.func.isRequired,
  dispatchClearDepreciationRule: PropTypes.func.isRequired,
  dispatchPutDepreciationRule: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  depreciationRule: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDepreciationRule.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reglas de Depreciación',
      href: DEPRECIATION_RULE_PATH,
    },
    {
      text: 'Ver',
      href: `${DEPRECIATION_RULE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  depreciationRule: CargoUnit.DepreciationRule.getIn(['current', 'content']),
  loading: CargoUnit.DepreciationRule.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDepreciationRule: getDepreciationRule,
  dispatchClearDepreciationRule: clearDepreciationRule,
  dispatchPutDepreciationRule: putDepreciationRule,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDepreciationRule);
