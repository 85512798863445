import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import materialBasicInformationPropTypes from '../proptypes/MaterialPropTypes';

const MaterialBasicInformationResource = ({
  name,
  externalId,
  measurementUnit,
}) => (
  <>
    <ResourceProperty label="Código:">{externalId || '-'}</ResourceProperty>
    <ResourceProperty label="Nombre:">{name || '-'}</ResourceProperty>
    <ResourceProperty label="Unidad de Medida:">
      {measurementUnit || '-'}
    </ResourceProperty>
  </>
);

MaterialBasicInformationResource.propTypes = materialBasicInformationPropTypes;

export default MaterialBasicInformationResource;
