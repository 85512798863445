import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form';
import {
  clearManifestItinerary,
  getManifestCargoItemById,
  getManifestItemByItinerary,
} from '../../../../actions/cargo/ItineraryManifest';
import Table from '../../../common/Table';
import Loader from '../../../common/Loader';
import ItineraryManifestDetail from './ItineraryManifestDetail';
import DateCell from '../../../common/cells/DateCell';
import { TIME_FORMAT } from '../../../../config/locale';
import printCargoItemManifest from '../../../../utils/printers/ItineraryManifest';

export class ItineraryManifestForm extends Component {
  componentDidMount() {
    const { dispatchGetManifestItemByItinerary, itineraryId } = this.props;
    dispatchGetManifestItemByItinerary({ itineraryId });
  }

  componentWillUnmount() {
    this.props.dispatchClearManifestItinerary();
  }
  printManifest = (id) => {
    const { dispatchGetManifestItemById } = this.props;

    dispatchGetManifestItemById(id).then((response) => {
      printCargoItemManifest(response);
    });
  };

  ITINERARY_MANIFEST_COLUMNS = [
    {
      className: 'text-center',
      Expander: (expander) => {
        if (expander.original.manifestCargoItemDetails === 0) return null;

        if (expander.isExpanded) return <i className="fa fa-caret-up" />;

        return <i className="fa fa-caret-down" />;
      },
      getProps: (state, rowInfo) => {
        if (rowInfo && rowInfo.original.manifestCargoItemDetails === 0) {
          return {
            onClick: () => {},
          };
        }
        return {
          className: 'show-pointer',
        };
      },
      expander: true,
    },
    {
      Header: 'Fecha de Salida',
      accessor: 'exitDate',
      className: 'text-center',
      Cell: (row) => <DateCell value={row.value} />,
    },
    {
      Header: 'Origen',
      id: 'sourceLocation',
      accessor: 'sourceLocation.name',
      className: 'text-center',
      Cell: (row) => row.value,
    },
    {
      Header: 'Destino',
      id: 'destinationLocation',
      accessor: 'destinationLocation.name',
      className: 'text-center',
      Cell: (row) => row.value,
    },
    {
      Header: 'Hora de Salida',
      accessor: 'exitDate',
      className: 'text-center',
      Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
    },
    {
      Header: 'Acción',
      className: 'text-center',
      Cell: (row) => (
        <Button onClick={() => this.printManifest(row.original.id)}>
          Imprimir
        </Button>
      ),
    },
  ];

  handleItemRemoved = (removed) => {
    const { dispatchGetManifestItemByItinerary, itineraryId } = this.props;

    if (removed) {
      dispatchGetManifestItemByItinerary({ itineraryId });
    }
  };

  renderItineraryManifestDetailComponent = ({
    original: {
      id: manifestId,
      sourceLocation,
      destinationLocation,
      itinerary,
    },
  }) => {
    const component = createElement(ItineraryManifestDetail, {
      manifestId,
      sourceLocation,
      destinationLocation,
      itineraryId: itinerary.id,
      onItemRemoved: this.handleItemRemoved,
    });

    return <div className="mt-3 mb-2 ml-5 mr-5">{component}</div>;
  };

  render() {
    const { itineraryManifest, loading, itineraryId } = this.props;

    const data = itineraryManifest.get('content') || [];
    const columns = this.ITINERARY_MANIFEST_COLUMNS;
    const pages = itineraryManifest.get('totalPages') || null;
    const defaultPageSize = itineraryManifest.get('size') || 100;

    let table =
      data.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          rowComponent={this.renderItineraryManifestDetailComponent}
          generateStyle={this.generateStyle}
        />
      ) : (
        <div className="alert alert-info">
          Para ver datos seleccione un Itinerario
        </div>
      );

    if (loading && !data.length) table = <Loader />;

    return (
      <>
        <h4>Resultado de Manifiestos del Itinerario #{itineraryId}</h4>
        {table}
      </>
    );
  }
}

ItineraryManifestForm.propTypes = {
  dispatchGetManifestItemByItinerary: PropTypes.func.isRequired,
  dispatchClearManifestItinerary: PropTypes.func.isRequired,
  dispatchGetManifestItemById: PropTypes.func.isRequired,
  itineraryManifest: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  itineraryId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  itineraryManifest: CargoUnit.ItineraryManifest.getIn(['all', 'content']),
  loading: CargoUnit.ItineraryManifest.getIn(['all', 'loading']),
});

ItineraryManifestForm.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetManifestItemByItinerary: getManifestItemByItinerary,
  dispatchClearManifestItinerary: clearManifestItinerary,
  dispatchGetManifestItemById: getManifestCargoItemById,
};

const formComponent = reduxForm({
  form: 'ItineraryManifestForm',
})(ItineraryManifestForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
