import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  clearMaintenanceFailModes,
  getMaintenanceFailModes,
} from '../../../../actions';

const MaintenanceFailModeSelect = ({
  options,
  isLoading,
  isMulti,
  input,
  noOptionsMessage,
  meta,
  isDisabled,
  dispatchGetMaintenanceFailModes,
  dispatchClearMaintenanFailModes,
  ...rest
}) => {
  useEffect(() => {
    dispatchGetMaintenanceFailModes({ ...DEFAULT_QUERY_GET_ALL });

    return () => dispatchClearMaintenanFailModes();
  }, []);

  return (
    <Select
      options={options}
      noOptionsMessage={noOptionsMessage}
      input={input}
      meta={meta}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};

MaintenanceFailModeSelect.propTypes = {
  noOptionsMessage: PropTypes.func,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
  dispatchGetMaintenanceFailModes: PropTypes.func.isRequired,
  dispatchClearMaintenanFailModes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
};

MaintenanceFailModeSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  options: [],
  noOptionsMessage: undefined,
  isDisabled: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.MaintenanceFailMode.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, description, categoryId, subCategoryId, priority }) => ({
    value: id,
    label: description,
    categoryId,
    subCategoryId,
    priority,
  })),
  loading: MechanicalMaintenanceUnit.MaintenanceFailMode.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetMaintenanceFailModes: getMaintenanceFailModes,
  dispatchClearMaintenanFailModes: clearMaintenanceFailModes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceFailModeSelect);
