import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountingtHome from './AccountingHome';
import {
  ACCOUNTING_UNIT_PATH,
  BILLING_PATH,
  DETRACTION_PATH,
  EXPENSE_TYPE_PATH,
  MANUAL_VOUCHER_PATH,
  NEW_DETRACTION_PATH,
  NEW_EXPENSE_TYPE_PATH,
  NEW_MANUAL_VOUCHER_PATH,
  TICKET_TAXABLE_INFORMATION_PATH,
} from '../../../config/paths';
import ExpenseTypes from './expense-type/ExpenseTypes';
import ExpenseType from './expense-type/ExpenseType';
import NewExpenseType from './expense-type/NewExpenseType';
import EditExpenseType from './expense-type/EditExpenseType';
import EditTicketTaxableInformation from './ticket-taxable-information/EditTicketTaxableInformation';
import ManualVouchers from './manual-voucher/ManualVouchers';
import NewManualVoucher from './manual-voucher/NewManualVoucher';
import ManualVoucher from './manual-voucher/ManualVoucher';
import EditManualVoucher from './manual-voucher/EditManualVoucher';
import Billings from './billing/Billings';
import Billing from './billing/Billing';
import EditBilling from './billing/EditBilling';
import Detractions from './detraction/Detractions';
import NewDetraction from './detraction/NewDetraction';
import Detraction from './detraction/Detraction';
import EditDetraction from './detraction/EditDetraction';

const AccountingUnit = () => (
  <Switch>
    <Route exact path={ACCOUNTING_UNIT_PATH} component={AccountingtHome} />
    <Route exact path={EXPENSE_TYPE_PATH} component={ExpenseTypes} />
    <Route exact path={NEW_EXPENSE_TYPE_PATH} component={NewExpenseType} />
    <Route exact path={`${EXPENSE_TYPE_PATH}/:id`} component={ExpenseType} />
    <Route
      exact
      path={`${EXPENSE_TYPE_PATH}/:id/edit`}
      component={EditExpenseType}
    />
    <Route
      exact
      path={TICKET_TAXABLE_INFORMATION_PATH}
      component={EditTicketTaxableInformation}
    />
    <Route exact path={MANUAL_VOUCHER_PATH} component={ManualVouchers} />
    <Route exact path={NEW_MANUAL_VOUCHER_PATH} component={NewManualVoucher} />
    <Route
      exact
      path={`${MANUAL_VOUCHER_PATH}/:id`}
      component={ManualVoucher}
    />
    <Route
      exact
      path={`${MANUAL_VOUCHER_PATH}/:id/edit`}
      component={EditManualVoucher}
    />
    <Route exact path={BILLING_PATH} component={Billings} />
    <Route exact path={`${BILLING_PATH}/:id`} component={Billing} />
    <Route exact path={`${BILLING_PATH}/:id/edit`} component={EditBilling} />
    <Route exact path={DETRACTION_PATH} component={Detractions} />
    <Route exact path={NEW_DETRACTION_PATH} component={NewDetraction} />
    <Route exact path={`${DETRACTION_PATH}/:id`} component={Detraction} />
    <Route
      exact
      path={`${DETRACTION_PATH}/:id/edit`}
      component={EditDetraction}
    />
  </Switch>
);

export default AccountingUnit;
