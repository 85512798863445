import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import CollapsibleHeader from '../../../layout/CollapsibleHeader';
import ExcessBaggageTable from './ExcessBaggageTable';
import { excessBaggageSummaryPropTypes } from './SalesSessionPropTypes';
import { numberFormatter } from '../../../../utils/number';
import Table from '../../../common/Table';

const ExcessBaggageSummary = ({
  excessBaggageSummaryList,
  salesSessionId,
  excessBaggagePaymentMethodSummaryList,
}) => {
  if (excessBaggageSummaryList.length === 0) return null;

  let table = null;
  if (excessBaggageSummaryList.length > 0)
    table = (
      <div className="mb-3">
        <Table
          columns={[
            {
              Header: 'Serie',
              accessor: 'documentSeries',
              className: 'text-center',
            },
            {
              Header: 'Monto de activos',
              accessor: 'activeExcessBaggageAmount',
              Cell: ({ value }) => numberFormatter({ value }),
              Footer: (row) =>
                `Total: ${numberFormatter({
                  value: row.data.reduce(
                    (accumulated, data) =>
                      accumulated + data.activeExcessBaggageAmount,
                    0,
                  ),
                })}`,
              className: 'text-right',
            },
            {
              Header: 'Monto en efectivo de activos',
              accessor: 'activeExcessBaggageCashAmount',
              Cell: ({ value }) => numberFormatter({ value }),
              Footer: (row) =>
                `Total: ${numberFormatter({
                  value: row.data.reduce(
                    (accumulated, data) =>
                      accumulated + data.activeExcessBaggageCashAmount,
                    0,
                  ),
                })}`,
              className: 'text-right',
            },
            {
              Header: 'Número de anulados',
              accessor: 'voidedExcessBaggageNumber',
              Cell: ({ value }) => numberFormatter({ value }),
              Footer: (row) =>
                `Total: ${numberFormatter({
                  value: row.data.reduce(
                    (accumulated, data) =>
                      accumulated + data.voidedExcessBaggageNumber,
                    0,
                  ),
                })}`,
              className: 'text-right',
            },
            {
              Header: 'Monto de anulados',
              accessor: 'voidedExcessBaggageAmount',
              Cell: ({ value }) => numberFormatter({ value }),
              Footer: (row) =>
                `Total: ${numberFormatter({
                  value: row.data.reduce(
                    (accumulated, data) =>
                      accumulated + data.voidedExcessBaggageAmount,
                    0,
                  ),
                })}`,
              className: 'text-right',
            },
          ]}
          data={excessBaggageSummaryList}
          defaultPageSize={excessBaggageSummaryList.length}
          showPagination={false}
        />
      </div>
    );

  return (
    <>
      <hr />
      <CollapsibleHeader
        headerText="Exceso de Equipajes"
        component={ExcessBaggageTable}
        salesSessionId={salesSessionId}
      />
      {table}
      {excessBaggagePaymentMethodSummaryList.map((paymentMethod) => (
        <ResourceProperty
          key={paymentMethod.moneyFlowConcept}
          label={`${paymentMethod.moneyFlowConcept}: `}
        >
          {numberFormatter({ value: paymentMethod.totalAmount })}
        </ResourceProperty>
      ))}
    </>
  );
};

ExcessBaggageSummary.propTypes = {
  excessBaggageSummaryList: excessBaggageSummaryPropTypes.isRequired,
  salesSessionId: PropTypes.number.isRequired,
  excessBaggagePaymentMethodSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string.isRequired,
      totalAmount: PropTypes.number.isRequired,
    }),
  ),
};

ExcessBaggageSummary.defaultProps = {
  excessBaggagePaymentMethodSummaryList: [],
};

export default ExcessBaggageSummary;
