export const GET_GEOGRAPHIC_ZONE_COMBINATIONS =
  'GET_GEOGRAPHIC_ZONE_COMBINATIONS';
export const FLAG_GETTING_GEOGRAPHIC_ZONE_COMBINATIONS =
  'FLAG_GETTING_GEOGRAPHIC_ZONE_COMBINATIONS';
export const CLEAR_GEOGRAPHIC_ZONE_COMBINATIONS =
  'CLEAR_GEOGRAPHIC_ZONE_COMBINATIONS';
export const GET_GEOGRAPHIC_ZONES_COMBINATION =
  'GET_GEOGRAPHIC_ZONES_COMBINATION';
export const FLAG_GEOGRAPHIC_ZONES_COMBINATION_ACTIVITY =
  'FLAG_GEOGRAPHIC_ZONES_COMBINATION_ACTIVITY';
export const CLEAR_GEOGRAPHIC_ZONES_COMBINATION =
  'CLEAR_GEOGRAPHIC_ZONES_COMBINATION';
