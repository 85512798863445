import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Button } from 'reactstrap';
import printTicketCargo from '../../../../../utils/printers/CargoTicket';

const PrintCargoTicketButton = ({ ticket }) => {
  const printTicket = () => {
    printTicketCargo(typeof ticket === 'object' ? ticket : ticket.toJSON());
  };

  return (
    <Button color="light" className="border" onClick={printTicket}>
      <i className="fa fa-print" /> Imprimir tickets
    </Button>
  );
};

PrintCargoTicketButton.propTypes = {
  ticket: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.object])
    .isRequired,
};

export default PrintCargoTicketButton;
