import Immutable from 'immutable';
import {
  FLAG_GETTING_EXPENSE_TYPES,
  GET_EXPENSE_TYPES,
  CLEAR_EXPENSE_TYPES,
} from '../../../actions/types/accounting/ExpenseType';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const EXPENSE_TYPE_INITIAL_STATE = Immutable.Map({
  form: Immutable.Map({
    loading: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: false,
  }),
});

export default (state = EXPENSE_TYPE_INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_EXPENSE_TYPES:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_EXPENSE_TYPES:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_EXPENSE_TYPES:
      return state.setIn(
        ['all', 'content'],
        EXPENSE_TYPE_INITIAL_STATE.getIn(['all', 'content']),
      );
    default:
      return state;
  }
};
