import React, { Component } from 'react';
import Loader from '../Loader';
import { matchPropTypes } from './proptypes/CommonPropTypes';

const ContainerComponent =
  ({
    // must be a async function that returns a payload
    getFunction,
  }) =>
  (ChildComponent) => {
    class ComposedComponent extends Component {
      static propTypes = {
        match: matchPropTypes.isRequired,
      };

      state = {
        done: false,
        payload: null,
      };

      componentDidMount() {
        const fetchData = async () => {
          const { id } = this.props.match.params;
          const payload = await getFunction({ id });
          this.setState({ done: true, payload });
        };
        fetchData();
      }

      render() {
        const { done, payload } = this.state;

        if (!done) {
          return <Loader />;
        }

        // TODO: define approach when payload is null
        return <ChildComponent payload={payload} />;
      }
    }

    return ComposedComponent;
  };

export default ContainerComponent;
