import { toastr } from 'react-redux-toastr';
import Fetch from '../../../../utils/Fetch';
import { generateUserAgenciesEndpoint } from '../../../../config/endpoints';

const getUserAgencies = async ({ userId }) => {
  try {
    const url = generateUserAgenciesEndpoint(userId);
    const purchaseOrder = await Fetch.get(url);
    return purchaseOrder;
  } catch (error) {
    toastr.error('Error',error.message);
    return null;
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getUserAgencies,
};
