import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DEPOSIT_PATH } from '../../../../config/paths';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generateGetDepositEndpoint } from '../../../../config/endpoints';
import { PUT } from '../../../../config/permissions';

const DepositEditButton = ({ depositId }) => (
  <Link className="btn btn-primary" to={`${DEPOSIT_PATH}/${depositId}/edit`}>
    Editar
  </Link>
);

DepositEditButton.propTypes = {
  depositId: PropTypes.number.isRequired,
};

export default withEndpointAuthorization({
  url: generateGetDepositEndpoint(),
  mapUrlParamsToProps: { depositId: 'depositId' },
  permissionType: PUT,
})(DepositEditButton);
