const getSubstring = (text, position = 0, separator = '-') => {
  let response = null;

  if (typeof text === 'string')
    response = text.split(separator)[position].trim();

  if (typeof text === 'number') response = text.toString();

  return response;
};

const padStart = (value, length = 0, fillString = '0') =>
  value && value.toString().padStart(length, fillString);

export { getSubstring, padStart };
