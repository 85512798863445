import { toastr } from 'react-redux-toastr';
import {
  FLAG_EXTRA_FUEL_CONSUMPTION_ACTIVITY,
  GET_EXTRA_FUEL_CONSUMPTION,
  CLEAR_EXTRA_FUEL_CONSUMPTION,
} from '../types';
import { EXTRA_FUEL_CONSUMPTION_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagExtraFuelConsumptionActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXTRA_FUEL_CONSUMPTION_ACTIVITY,
    payload: flag,
  });

const getExtraConsumptionByItineraryIdListAsync = async ({
  itineraryIdList,
}) => {
  const promises = itineraryIdList.map(({ itineraryId }) =>
    fetch(`${EXTRA_FUEL_CONSUMPTION_ENDPOINT}/${itineraryId}`, {
      ...DEFAULT_GET_CONFIG,
    }).then((response) => response.json()),
  );

  const extraConsumptions = await Promise.allSettled(promises);

  return extraConsumptions;
};

const getExtraFuelConsumption =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagExtraFuelConsumptionActivity(true));
      const url = `${EXTRA_FUEL_CONSUMPTION_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const extraFuelConsumption = await response.json();
      dispatch({
        type: GET_EXTRA_FUEL_CONSUMPTION,
        payload: extraFuelConsumption,
      });
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagExtraFuelConsumptionActivity(false));
    }
  };

const clearExtraFuelConsumption = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXTRA_FUEL_CONSUMPTION,
  });

const postExtraFuelConsumption =
  async ({ itineraryId, fuelGallons, adblueGallons, comment, createDate }) =>
  async (dispatch) => {
    try {
      dispatch(flagExtraFuelConsumptionActivity(true));
      const payload = {
        itineraryId,
        fuelGallons,
        adblueGallons,
        comment,
        createDate,
      };
      const url = EXTRA_FUEL_CONSUMPTION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      // TODO redirect or dispatch something
      // Hack for register new extra consumption from fuel report preview
      // Back-end return empty response
      return true;
    } catch ({ message }) {
      toastr.error('Error',message);
      return false;
    } finally {
      dispatch(flagExtraFuelConsumptionActivity(false));
    }
  };

export {
  getExtraFuelConsumption,
  clearExtraFuelConsumption,
  postExtraFuelConsumption,
  getExtraConsumptionByItineraryIdListAsync,
};
