import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_TRIP_STATUSES,
  GET_TRIP_STATUSES,
  CLEAR_TRIP_STATUSES,
} from '../types';
import { TRIP_STATUS_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingTripStatuses = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_TRIP_STATUSES,
    payload: flag,
  });

const getTripStatuses = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingTripStatuses(true));
    const query = tableFilters;
    const url = `${TRIP_STATUS_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const statuses = await response.json();
    dispatch({
      type: GET_TRIP_STATUSES,
      payload: statuses,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingTripStatuses(false));
  }
};

const clearTripStatuses = () => dispatch =>
  dispatch({
    type: CLEAR_TRIP_STATUSES,
  });

export { flagGettingTripStatuses, getTripStatuses, clearTripStatuses };
