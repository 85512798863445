import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';

const Step = ({ stepElements }) => (
  <ul className="nav justify-content-center mb-3">
    {stepElements.map(({ text, active }, index) => (
      <li key={+index} className="nav-item text-center">
        <div className="nav-link">
          <Badge color={active ? 'primary' : 'secondary'}>{+index + 1}</Badge>
          <p>{text}</p>
        </div>
      </li>
    ))}
  </ul>
);

Step.propTypes = {
  stepElements: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }),
  ).isRequired,
};

export default Step;
