import Immutable from 'immutable';
import {
  BUSINESS_PATH,
  CUSTOMER_PATH,
  HOME_PATH,
  USER_PATH,
  USER_UNIT_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'user',
      title: 'Usuarios',
      path: USER_PATH,
      icon: 'users',
    },
    {
      id: 'customer',
      title: 'Clientes',
      path: CUSTOMER_PATH,
      icon: 'street-view',
    },
    {
      id: 'business',
      title: 'Empresas',
      path: BUSINESS_PATH,
      icon: 'industry',
    },
    {
      id: 'identification-types',
      title: 'Tipos de Identificación',
      path: '/user/identification-types',
      icon: 'list-alt',
      disabled: true,
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Usuarios',
      tag: 'span',
      href: USER_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
