import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { Field, reduxForm, Form, change } from 'redux-form';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateNumber,
  validateRuc,
} from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import TicketSelect from '../../../common/forms/select/TicketSelect';
import CustomerSelect from '../../../common/forms/select/CustomerSelect';
import IdentificationTypeSelect from '../../../common/forms/select/IdentificationTypeSelect';
import BusinessSelect from '../../../common/forms/select/BusinessSelect';
import { clearTicket, getTicket } from '../../../../actions';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

export class TicketTaxableInformationForm extends Component {
  static businessFieldIsRequired(value, allValues) {
    if (allValues.businessId && !value) return 'Campo requerido';

    return undefined;
  }

  constructor(props) {
    super(props);

    this.state = { disabledFields: true, flagGetTicket: false };
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    this.props.dispatchClearTicket();
  }

  onDidUpdate = () => {
    const { ticket, dispatchChange } = this.props;

    if (this.state.flagGetTicket && !ticket.isEmpty()) {
      const { customer, transactions } = ticket.toJS();
      const { business } = transactions[0];

      dispatchChange('TicketTaxableInformationForm', 'customerId', {
        value: customer.id,
        label: `${customer.idDocumentNumber} - ${customer.fullName}`,
      });
      dispatchChange(
        'TicketTaxableInformationForm',
        'firstName',
        customer.firstName,
      );
      dispatchChange(
        'TicketTaxableInformationForm',
        'lastName',
        customer.lastName,
      );
      dispatchChange('TicketTaxableInformationForm', 'identificationTypeId', {
        value: customer.identificationTypeId,
        label: customer.identificationType.name,
      });
      dispatchChange(
        'TicketTaxableInformationForm',
        'idDocumentNumber',
        customer.idDocumentNumber,
      );
      dispatchChange(
        'TicketTaxableInformationForm',
        'businessId',
        business
          ? {
              value: business.id,
              label: business.name,
            }
          : null,
      );
      dispatchChange(
        'TicketTaxableInformationForm',
        'businessTaxId',
        business ? business.businessTaxId : null,
      );
      dispatchChange(
        'TicketTaxableInformationForm',
        'name',
        business ? business.name : null,
      );
      this.setState({ disabledFields: false, flagGetTicket: false });
    }
  };

  onChangeBusiness = ({ businessTaxId, name }) => {
    const { dispatchChange } = this.props;

    dispatchChange(
      'TicketTaxableInformationForm',
      'businessTaxId',
      businessTaxId || null,
    );
    dispatchChange('TicketTaxableInformationForm', 'name', name || null);
  };

  onChangeCustomer = ({
    firstName,
    lastName,
    identificationType,
    idDocumentNumber,
  }) => {
    const { dispatchChange } = this.props;

    dispatchChange('TicketTaxableInformationForm', 'firstName', firstName);
    dispatchChange('TicketTaxableInformationForm', 'lastName', lastName);
    dispatchChange('TicketTaxableInformationForm', 'identificationTypeId', {
      value: identificationType.id,
      label: identificationType.name,
    });
    dispatchChange(
      'TicketTaxableInformationForm',
      'idDocumentNumber',
      idDocumentNumber,
    );
  };

  onChangeTicket = (option) => {
    const { dispatchGetTicket, dispatchClearTicket, dispatchChange } =
      this.props;

    dispatchClearTicket();
    dispatchGetTicket({ ticketId: option.value });

    dispatchChange(
      'TicketTaxableInformationForm',
      'idCountryOfOrigin',
      option.idCountryOfOrigin,
    );

    this.setState({ flagGetTicket: true });
  };

  render() {
    const { handleSubmit, loading } = this.props;

    const { disabledFields } = this.state;

    if (loading) return <Loader />;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Boleto" required>
            <Field
              name="ticketId"
              component={TicketSelect}
              isClearable={false}
              onChange={this.onChangeTicket}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <h3>Información del Cliente</h3>
        <FormGroup row>
          <FormItem label="Cliente" required>
            <Field
              name="customerId"
              component={CustomerSelect}
              onChange={this.onChangeCustomer}
              isClearable={false}
              validate={[isRequired]}
              isDisabled={disabledFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Nombres" required>
            <Field
              name="firstName"
              component={TextInput}
              placeholder="Nombres"
              validate={[isRequired]}
              disabled={disabledFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Apellidos" required>
            <Field
              name="lastName"
              component={TextInput}
              placeholder="Apellidos"
              validate={[isRequired]}
              disabled={disabledFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Tipo de Documento" required>
            <Field
              name="identificationTypeId"
              component={IdentificationTypeSelect}
              isClearable={false}
              validate={[isRequired]}
              isDisabled={disabledFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Número de Documento" required>
            <Field
              name="idDocumentNumber"
              component={TextInput}
              type="text"
              placeholder="Número de Documento"
              validate={[isRequired]}
              disabled={disabledFields}
            />
          </FormItem>
        </FormGroup>
        <h3>Información de la Empresa</h3>
        <FormGroup row>
          <FormItem label="Empresa">
            <Field
              name="businessId"
              component={BusinessSelect}
              onChange={this.onChangeBusiness}
              isDisabled={disabledFields}
              isClearable
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="RUC">
            <Field
              name="businessTaxId"
              component={TextInput}
              placeholder="RUC"
              validate={[
                validateNumber,
                validateRuc,
                TicketTaxableInformationForm.businessFieldIsRequired,
              ]}
              disabled={disabledFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Razón Social">
            <Field
              name="name"
              component={TextInput}
              placeholder="Razón Social"
              disabled={disabledFields}
              validate={[TicketTaxableInformationForm.businessFieldIsRequired]}
            />
          </FormItem>
        </FormGroup>
        <Field
          name="idCountryOfOrigin"
          component={TextInput}
          type="hidden"
          placeholder="País"
          disabled
        />
        <FormFooter />
      </Form>
    );
  }
}

const mapStateToProps = ({ ReservationUnit }) => ({
  loading: !ReservationUnit.Ticket.getIn(['current', 'activity']).isEmpty(),
  ticket: ReservationUnit.Ticket.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetTicket: getTicket,
  dispatchClearTicket: clearTicket,
};

TicketTaxableInformationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  dispatchGetTicket: PropTypes.func.isRequired,
  dispatchClearTicket: PropTypes.func.isRequired,
  ticket: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

TicketTaxableInformationForm.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'TicketTaxableInformationForm',
})(TicketTaxableInformationForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
