import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_DRIVER_DOCUMENT_TYPES,
  GET_DRIVER_DOCUMENT_TYPES,
  CLEAR_DRIVER_DOCUMENT_TYPES,
  FLAG_DRIVER_DOCUMENT_TYPE_ACTIVITY,
  GET_DRIVER_DOCUMENT_TYPE,
  CLEAR_DRIVER_DOCUMENT_TYPE,
} from '../types/traffic';
import { DRIVER_DOCUMENT_TYPE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingDriverDocumentTypes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DRIVER_DOCUMENT_TYPES,
    payload: flag,
  });

const getDriverDocumentTypes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDriverDocumentTypes(true));
    const query = tableFilters;
    const url = `${DRIVER_DOCUMENT_TYPE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const types = await response.json();
    dispatch({
      type: GET_DRIVER_DOCUMENT_TYPES,
      payload: types,
    });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingDriverDocumentTypes(false));
  }
};

const clearDriverDocumentTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_DOCUMENT_TYPES,
  });

const flagDriverDocumentTypeActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DRIVER_DOCUMENT_TYPE_ACTIVITY,
    payload: flag,
  });

const getDriverDocumentType =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverDocumentTypeActivity(true));
      const url = `${DRIVER_DOCUMENT_TYPE_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response);
      const type = await response.json();
      dispatch({
        type: GET_DRIVER_DOCUMENT_TYPE,
        payload: type,
      });
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagDriverDocumentTypeActivity(false));
    }
  };

const clearDriverDocumentType = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_DOCUMENT_TYPE,
  });

export {
  flagGettingDriverDocumentTypes,
  getDriverDocumentTypes,
  clearDriverDocumentTypes,
  getDriverDocumentType,
  clearDriverDocumentType,
};
