import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, ButtonToolbar, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { BASE_PRICE_PER_KILOMETER_PATH } from '../../../../config/paths';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  getBasePricePerKilometer,
  clearBasePricePerKilometer,
} from '../../../../actions/cargo';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import EditBasePricePerKilometerButton from './EditBasePricePerKilometerButton';
import DeleteBasePricePerKilometerButton from './DeleteBasePricePerKilometerButton';

class BasePricePerKilometer extends Component {
  componentDidMount() {
    const {
      dispatchGetBasePricePerKilometer,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetBasePricePerKilometer({ id });
  }

  componentWillUnmount() {
    const { dispatchClearBasePricePerKilometer } = this.props;
    dispatchClearBasePricePerKilometer();
  }

  render() {
    const {
      breadcrumbs,
      basePricePerKilometer,
      loading,
      match: {
        params: { id },
      },
    } = this.props;

    const basePricePerKilometerId = parseInt(id, 10);

    if (loading || basePricePerKilometer.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Fragment>
          <ButtonToolbar className="pull-right">
            <div>
              <EditBasePricePerKilometerButton
                basePricePerKilometerId={basePricePerKilometerId}
              />{' '}
              <DeleteBasePricePerKilometerButton
                basePricePerKilometerId={basePricePerKilometerId}
              />
            </div>
          </ButtonToolbar>
        </Fragment>
        <Fragment>
          <Row>
            <Col>
              <h1>Precio Base por Kilómetro</h1>
            </Col>
          </Row>
          <hr />
        </Fragment>
        <Fragment>
          <ResourceProperty label="Desde Kilómetro:">
            {basePricePerKilometer.get('fromKilometer')}
          </ResourceProperty>
          <ResourceProperty label="Hasta Kilómetro:">
            {basePricePerKilometer.get('toKilometer')}
          </ResourceProperty>
          <ResourceProperty label="Precio Base:">
            {basePricePerKilometer.get('price')}
          </ResourceProperty>
          <ResourceProperty label="Factor Kilometraje:">
            {basePricePerKilometer.get('kilometerFactor')}
          </ResourceProperty>
        </Fragment>
      </Container>
    );
  }
}

BasePricePerKilometer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBasePricePerKilometer: PropTypes.func.isRequired,
  dispatchClearBasePricePerKilometer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  basePricePerKilometer: PropTypes.instanceOf(Immutable.Map),
};

BasePricePerKilometer.defaultProps = {
  loading: false,
  basePricePerKilometer: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Precios Base por Kilómetro',
      href: BASE_PRICE_PER_KILOMETER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  basePricePerKilometer: CargoUnit.BasePricePerKilometer.getIn([
    'current',
    'content',
  ]),
  loading: CargoUnit.BasePricePerKilometer.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetBasePricePerKilometer: getBasePricePerKilometer,
  dispatchClearBasePricePerKilometer: clearBasePricePerKilometer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BasePricePerKilometer);
