import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Form } from 'reactstrap';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import { ITINERARY_STATUS_COLUMNS } from '../../../../../config/columns';
import FormFooter from '../../../../common/forms/FormFooter';

const EditItineraryStatusForm = ({
  handleSubmit,
  loadingItineraries,
  itineraries,
}) => (
  <Form onSubmit={handleSubmit} className="mt-3">
    <SelectionableTable
      columns={ITINERARY_STATUS_COLUMNS}
      data={itineraries}
      defaultPageSize={10}
      keyField="id"
      loading={loadingItineraries}
      form="EditItineraryStatusForm"
    />
    <FormFooter />
  </Form>
);

EditItineraryStatusForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadingItineraries: PropTypes.bool,
  itineraries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.bool,
      route: PropTypes.shape({
        name: PropTypes.string,
      }),
      departureTime: PropTypes.string,
    }),
  ),
};

EditItineraryStatusForm.defaultProps = {
  loadingItineraries: false,
  itineraries: [],
};

export default reduxForm({
  form: 'EditItineraryStatusForm',
})(EditItineraryStatusForm);
