import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Loader from '../../../common/Loader';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import UserToolbar from './UserToolbar';
import UserBasicInformationResource from './resource/UserBasicInformationResource';
import UserContactInformationResource from './resource/UserContactInformationResource';
import UserInternalResource from './resource/UserInternalResource';
import { HOME_PATH } from '../../../../config/paths';
import CustomerBasicInformationResource from '../customer/resource/CustomerBasicInformationResource';
import UserSecurityProfileResource from './resource/UserSecurityProfileResource';
import { userBasicInformationPropTypes } from './proptypes/UserPropTypes';

const UserSelf = ({ breadcrumbs, user, authenticating, authenticated }) => {
  let content = null;
  let toolbar = null;

  if (authenticating && authenticated) content = <Loader />;
  else {
    const { securityProfileSet, username, locale, customer, id } = user;

    content = (
      <Fragment>
        <Row>
          <Col md={6}>
            <h3>Información Básica</h3>
            <UserBasicInformationResource username={username} locale={locale} />
            <h3>Cliente</h3>
            <CustomerBasicInformationResource {...customer} />
            <h3>Información de Contacto</h3>
            <UserContactInformationResource {...customer} />
          </Col>
          <Col md={6}>
            <h3>Perfiles de Seguridad</h3>
            <UserSecurityProfileResource
              securityProfileSet={securityProfileSet}
            />
            <UserInternalResource {...user} />
          </Col>
        </Row>
      </Fragment>
    );

    toolbar = <UserToolbar userId={id} self />;
  }

  return (
    <Container fluid>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {toolbar}
      <h1>Mi Usuario</h1>
      <hr />
      {content}
    </Container>
  );
};

UserSelf.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  authenticating: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape(userBasicInformationPropTypes).isRequired,
};

const mapStateToProps = ({ authentication }) => ({
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Mi Usuario',
      href: '',
    },
  ],
  authenticated: authentication.getIn(['authenticated']),
  authenticating: authentication.getIn(['authenticating']),
  user: authentication.getIn(['user']),
});

export default connect(mapStateToProps)(UserSelf);
