// types should always be imperative verbs

export const SHOW_ERROR_POSTING_CALL_CENTER_REPORT =
  'SHOW_ERROR_POSTING_CALL_CENTER_REPORT';
export const CLEAR_ERROR_POSTING_CALL_CENTER_REPORT =
  'CLEAR_ERROR_POSTING_CALL_CENTER_REPORT';
export const SHOW_ERROR_GETTING_CALL_CENTER_REPORT =
  'SHOW_ERROR_GETTING_CALL_CENTER_REPORT';
export const SHOW_ERROR_GETTING_CALL_CENTER_REPORTS =
  'SHOW_ERROR_GETTING_CALL_CENTER_REPORTS';
export const CLEAR_ERROR_GETTING_CALL_CENTER_REPORT =
  'CLEAR_ERROR_GETTING_CALL_CENTER_REPORT';
export const CLEAR_ERROR_GETTING_CALL_CENTER_REPORTS =
  'CLEAR_ERROR_GETTING_CALL_CENTER_REPORTS';
export const CLEAR_CALL_CENTER_REPORTS = 'CLEAR_CALL_CENTER_REPORTS';
export const CLEAR_CALL_CENTER_REPORT = 'CLEAR_CALL_CENTER_REPORT';
export const POST_CALL_CENTER_REPORT = 'POST_CALL_CENTER_REPORT';
export const GET_CALL_CENTER_REPORTS = 'GET_CALL_CENTER_REPORTS';
export const GET_CALL_CENTER_REPORT = 'GET_CALL_CENTER_REPORT';
export const DOWNLOAD_CALL_CENTER_REPORT = 'DOWNLOAD_CALL_CENTER_REPORT';
export const FLAG_GETTING_CALL_CENTER_REPORT =
  'FLAG_GETTING_CALL_CENTER_REPORT';
export const FLAG_GETTING_CALL_CENTER_REPORTS =
  'FLAG_GETTING_CALL_CENTER_REPORTS';
export const FLAG_POSTING_CALL_CENTER_REPORT =
  'FLAG_POSTING_CALL_CENTER_REPORT';
