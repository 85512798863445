import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CREDIT_NOTE_PATH } from '../../../../config/paths';

const CreditNoteEditButton = ({ creditNoteId, disabled }) =>
  disabled ? (
    <Button color="primary" disabled>
      <i className="fa fa-pencil-square-o" /> Editar
    </Button>
  ) : (
    <Link
      className="btn btn-primary"
      to={`${CREDIT_NOTE_PATH}/${creditNoteId}/edit`}
    >
      <i className="fa fa-pencil-square-o" /> Editar
    </Link>
  );

CreditNoteEditButton.propTypes = {
  creditNoteId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

CreditNoteEditButton.defaultProps = {
  disabled: false,
};

export default CreditNoteEditButton;
