import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_ITINERARY_SEARCH_OMISSIONS,
  GET_ITINERARY_SEARCH_OMISSIONS,
  CLEAR_ITINERARY_SEARCH_OMISSIONS,
  GET_ITINERARY_SEARCH_OMISSION,
  CLEAR_ITINERARY_SEARCH_OMISSION,
  FLAG_ITINERARY_SEARCH_OMISSION_ACTIVITY,
} from '../types/itinerary';
import { ITINERARY_SEARCH_OMISSION_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { ITINERARY_SEARCH_OMISSION_PATH } from '../../config/paths';

const flagGettingItinerarySearchOmissions = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_ITINERARY_SEARCH_OMISSIONS,
    payload: flag,
  });

const getItinerarySearchOmissions = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingItinerarySearchOmissions(true));
    const query = tableFilters;
    const url = `${ITINERARY_SEARCH_OMISSION_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const itinerarySearchOmissions = await response.json();
    dispatch({
      type: GET_ITINERARY_SEARCH_OMISSIONS,
      payload: itinerarySearchOmissions,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingItinerarySearchOmissions(false));
  }
};

const clearItinerarySearchOmissions = () => dispatch =>
  dispatch({
    type: CLEAR_ITINERARY_SEARCH_OMISSIONS,
  });

const flagItinerarySearchOmissionActivity = flag => dispatch =>
  dispatch({
    type: FLAG_ITINERARY_SEARCH_OMISSION_ACTIVITY,
    payload: flag,
  });

const getItinerarySearchOmission = async ({ id }) => async dispatch => {
  try {
    dispatch(flagItinerarySearchOmissionActivity(true));
    const url = `${ITINERARY_SEARCH_OMISSION_ENDPOINT}/${id}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const itinerarySearchOmission = await response.json();
    dispatch({
      type: GET_ITINERARY_SEARCH_OMISSION,
      payload: itinerarySearchOmission,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItinerarySearchOmissionActivity(false));
  }
};

const clearItinerarySearchOmission = () => dispatch =>
  dispatch({
    type: CLEAR_ITINERARY_SEARCH_OMISSION,
  });

const postItinerarySearchOmission = async ({
  sourceLocationId,
  destinationLocationId,
}) => async dispatch => {
  try {
    dispatch(flagItinerarySearchOmissionActivity(true));
    const payload = {
      sourceLocationId: sourceLocationId.value,
      destinationLocationId: destinationLocationId.value,
    };
    const url = ITINERARY_SEARCH_OMISSION_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const itinerarySearchOmission = await response.json();
    dispatch(
      push(`${ITINERARY_SEARCH_OMISSION_PATH}/${itinerarySearchOmission.id}`),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItinerarySearchOmissionActivity(false));
  }
};

const putItinerarySearchOmission = async (
  itinerarySearchOmissionId,
  { sourceLocationId, destinationLocationId },
) => async dispatch => {
  try {
    dispatch(flagItinerarySearchOmissionActivity(true));
    const payload = {
      id: itinerarySearchOmissionId,
      sourceLocationId,
      destinationLocationId,
    };
    const url = `${ITINERARY_SEARCH_OMISSION_ENDPOINT}/${itinerarySearchOmissionId}`;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const itinerarySearchOmission = await response.json();
    dispatch(
      push(`${ITINERARY_SEARCH_OMISSION_PATH}/${itinerarySearchOmission.id}`),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItinerarySearchOmissionActivity(false));
  }
};

const deleteItinerarySearchOmission = async ({
  itinerarySearchOmissionId,
}) => async dispatch => {
  try {
    dispatch(flagItinerarySearchOmissionActivity(true));
    const url = `${ITINERARY_SEARCH_OMISSION_ENDPOINT}/${itinerarySearchOmissionId}`;
    const response = await fetch(url, {
      ...DEFAULT_DELETE_CONFIG,
    });
    await isErrorResponse(response);
    await response;
    dispatch(push(ITINERARY_SEARCH_OMISSION_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItinerarySearchOmissionActivity(false));
  }
};

export {
  flagGettingItinerarySearchOmissions,
  getItinerarySearchOmissions,
  clearItinerarySearchOmissions,
  getItinerarySearchOmission,
  clearItinerarySearchOmission,
  postItinerarySearchOmission,
  putItinerarySearchOmission,
  deleteItinerarySearchOmission,
};
