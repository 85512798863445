import React from 'react';
import PropTypes from 'prop-types';
import { addressBasicInformationPropTypes } from '../../units/location/address/proptypes/AddressPropTypes';

const AddressResource = ({ address }) => {
  let content = (
    <span className="font-italic text-muted">No hay dirección asignada</span>
  );

  if (address) {
    content = (
      <address>
        {address.address}
        <br />
        {address.district}
        <br />
        {address.city.name}, {address.postalCode}
        <br />
        {address.city.region.name}, {address.city.region.country.name}
      </address>
    );
  }

  return content;
};

AddressResource.propTypes = {
  address: PropTypes.shape(addressBasicInformationPropTypes),
};

AddressResource.defaultProps = { address: null };

export default AddressResource;
