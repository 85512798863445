import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ADDRESS_PATH } from '../../../../config/paths';
import { clearAddress, getAddress, putAddress } from '../../../../actions';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import AddressForm from './AddressForm';
import Content from '../../../layout/Content';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';

const EditAddress = ({
  breadcrumbs,
  dispatchGetAddress,
  dispatchClearAddress,
  dispatchPutAddress,
  match: {
    params: { id: addressId },
  },
  loading,
  address,
}) => {
  useLayoutEffect(() => {
    dispatchGetAddress({ addressId });
    return () => dispatchClearAddress();
  }, []);

  const onSubmit = (formValues) => {
    const {
      address: addressName,
      address2,
      city,
      phone,
      postalCode,
      selectedDistrict,
    } = formValues;
    const newFormValues = {
      address: addressName,
      address2,
      district: selectedDistrict.label,
      postalCode,
      phone,
      cityId: city.value,
      districtId: selectedDistrict.value,
    };
    dispatchPutAddress({ ...newFormValues, addressId });
  };

  const generateInitialValues = ({
    address: addressName,
    address2,
    district,
    postalCode,
    phone,
    city,
    districtObj,
  }) => ({
    address: addressName,
    address2,
    district,
    postalCode,
    phone,
    city: {
      value: city.id,
      label: city.name,
    },
    selectedDistrict: districtObj
      ? {
          value: districtObj.id,
          label: districtObj.name,
        }
      : null,
  });
  let content;

  if (loading) content = <Loader />;
  else if (address.isEmpty())
    content = <NoDataResource returnPage={ADDRESS_PATH} />;
  else
    content = (
      <AddressForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(address.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Dirección"
      subtitle="Editar address"
      content={content}
    />
  );
};

EditAddress.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearAddress: PropTypes.func.isRequired,
  dispatchGetAddress: PropTypes.func.isRequired,
  dispatchPutAddress: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  address: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditAddress.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearAddress: clearAddress,
  dispatchGetAddress: getAddress,
  dispatchPutAddress: putAddress,
};

const mapStateToProps = (
  { LocationUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Direcciones',
      href: ADDRESS_PATH,
    },
    {
      text: 'Ver',
      href: `${ADDRESS_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  address: LocationUnit.Address.getIn(['current', 'content']),
  loading: !LocationUnit.Address.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
