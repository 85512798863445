import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import CargoItemStatusCell from '../../../../common/cells/cargo/cargo-item/CargoItemStatusCell';
import { numberFormatter } from '../../../../../utils/number';
import ParcelPaymentStatusCell from '../../../../common/cells/cargo/cargo/ParcelPaymentStatusCell';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import { SALES_SESSION_PATH } from '../../../../../config/paths';
import IsPerishable from '../../../../common/cells/cargo/cargo-item/IsPerishable';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';

const CargoItemDetailBasicInformationResource = ({
  cargoItemId,
  cargoItemCorrelation,
  cargoItemDetail,
  cargoItemItineraryId,
  cargoItemLocationName,
  source,
  destination,
  senderCustomer,
  consigneeCustomer,
  parcelPaymentStatus,
  customer,
  salesSessionId,
  parcelCreateDate,
  parcelLastUpdate,
  createUserFullName,
  lastUpdateUser,
}) => (
  <Row>
    <Col>
      <ResourceProperty label="Código:">{cargoItemId}</ResourceProperty>
      <ResourceProperty label="Correlativo de Encomienda:">
        {cargoItemCorrelation}
      </ResourceProperty>
      <ResourceProperty label="Descripción:">
        {cargoItemDetail.description}
      </ResourceProperty>
      <ResourceProperty label="Categoría:">
        {cargoItemDetail.cargoCategoryName}
      </ResourceProperty>
      <ResourceProperty label="Caducidad:">
        <IsPerishable value={cargoItemDetail.perishable} />
      </ResourceProperty>
      <ResourceProperty label="Cantidad:">
        {cargoItemDetail.quantity}
      </ResourceProperty>
      <ResourceProperty label="Peso:">
        {cargoItemDetail.weight} kg
      </ResourceProperty>
      <ResourceProperty label="Longitud:">
        {cargoItemDetail.length !== null ? cargoItemDetail.length : '-'}
      </ResourceProperty>
      <ResourceProperty label="Alto:">
        {cargoItemDetail.height !== null ? cargoItemDetail.height : '-'}
      </ResourceProperty>
      <ResourceProperty label="Ancho:">
        {cargoItemDetail.width !== null ? cargoItemDetail.width : '-'}
      </ResourceProperty>
      <ResourceProperty label="Origen:">{source}</ResourceProperty>
      <ResourceProperty label="Destino:">{destination}</ResourceProperty>
      {cargoItemItineraryId && (
        <ResourceProperty label="Itinerario:">
          {cargoItemItineraryId}
        </ResourceProperty>
      )}
      {cargoItemLocationName && (
        <ResourceProperty label="Ubicación:">
          {cargoItemLocationName}
        </ResourceProperty>
      )}
      <ResourceProperty label="Estado:">
        <CargoItemStatusCell value={cargoItemDetail.status} />
      </ResourceProperty>
      <ResourceProperty label="Monto:">
        {numberFormatter({ value: cargoItemDetail.price })}
      </ResourceProperty>
      <ResourceProperty label="Estado de Pago:">
        <ParcelPaymentStatusCell value={parcelPaymentStatus} />
      </ResourceProperty>
      <ResourceProperty label="Sesión de Ventas:">
        <LinkToModuleResource
          text={salesSessionId}
          href={`${SALES_SESSION_PATH}/${salesSessionId}`}
          isExternalPath
        />
      </ResourceProperty>
    </Col>
    <Col>
      <h3>Remitente</h3>
      <ResourceProperty label="Nombre:">
        {senderCustomer.fullName}
      </ResourceProperty>
      <ResourceProperty label="Tipo de Documento:">
        {senderCustomer.identificationTypeName}
      </ResourceProperty>
      <ResourceProperty label="Número de Documento:">
        {senderCustomer.idDocumentNumber}
      </ResourceProperty>
      <ResourceProperty label="Teléfono:">
        {senderCustomer.phone !== null ? senderCustomer.phone : '-'}
      </ResourceProperty>
      <h3>Consignado</h3>
      <ResourceProperty label="Nombre:">
        {consigneeCustomer.fullName}
      </ResourceProperty>
      <ResourceProperty label="Tipo de Documento:">
        {consigneeCustomer.identificationTypeName}
      </ResourceProperty>
      <ResourceProperty label="Número de Documento:">
        {consigneeCustomer.idDocumentNumber}
      </ResourceProperty>
      <ResourceProperty label="Teléfono:">
        {consigneeCustomer ? consigneeCustomer.phone : '-'}
      </ResourceProperty>
      <h3>Cliente</h3>
      <ResourceProperty label="Nombre:">
        {customer ? customer.fullName : '-'}
      </ResourceProperty>
      <ResourceProperty label="Tipo de Documento:">
        {customer ? customer.identificationTypeName : '-'}
      </ResourceProperty>
      <ResourceProperty label="Número de Documento:">
        {customer ? customer.idDocumentNumber : '-'}
      </ResourceProperty>
      <ResourceProperty label="Teléfono:">
        {customer && customer.phone !== null ? customer.phone : '-'}
      </ResourceProperty>
    </Col>
    <Col>
      <h3>Información Interna</h3>
      <ResourceProperty label="Fecha de Creación:">
        {tzNormalizeDate({
          date: parcelCreateDate,
          format: DATE_TIME_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Última Actualización:">
        {tzNormalizeDate({
          date: parcelLastUpdate,
          format: DATE_TIME_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Creado por:">
        {createUserFullName}
      </ResourceProperty>
      <ResourceProperty label="Actualizado por:">
        {lastUpdateUser ? lastUpdateUser.fullName : '-'}
      </ResourceProperty>
    </Col>
  </Row>
);

CargoItemDetailBasicInformationResource.propTypes = {
  cargoItemId: PropTypes.number.isRequired,
  cargoItemCorrelation: PropTypes.string.isRequired,
  cargoItemItineraryId: PropTypes.number,
  cargoItemLocationName: PropTypes.string,
  cargoItemDetail: PropTypes.shape({
    description: PropTypes.string,
    cargoCategoryName: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    weight: PropTypes.number,
    length: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    price: PropTypes.number.isRequired,
    taxes: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    perishable: PropTypes.bool,
  }).isRequired,
  source: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  senderCustomer: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    phone: PropTypes.string,
    identificationTypeName: PropTypes.string.isRequired,
    idDocumentNumber: PropTypes.string.isRequired,
  }).isRequired,
  consigneeCustomer: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    phone: PropTypes.string,
    identificationTypeName: PropTypes.string.isRequired,
    idDocumentNumber: PropTypes.string.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    phone: PropTypes.string,
    identificationTypeName: PropTypes.string.isRequired,
    idDocumentNumber: PropTypes.string.isRequired,
  }).isRequired,
  parcelPaymentStatus: PropTypes.string.isRequired,
  salesSessionId: PropTypes.number.isRequired,
  parcelCreateDate: PropTypes.number.isRequired,
  parcelLastUpdate: PropTypes.number.isRequired,
  createUserFullName: PropTypes.string.isRequired,
  lastUpdateUser: PropTypes.shape({
    fullName: PropTypes.string,
  }).isRequired,
};

CargoItemDetailBasicInformationResource.defaultProps = {
  cargoItemItineraryId: 0,
  cargoItemLocationName: '',
};

export default CargoItemDetailBasicInformationResource;
