import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isExternalUrl } from '../../utils/validators';
import { breadcrumbsPropTypes } from '../common/resource/proptypes/CommonPropTypes';

const renderBreadcrumbLink = (breadcrumbs) => {
  const { href, text } = breadcrumbs;
  if (isExternalUrl(href)) {
    return <a href={href}>{text}</a>;
  }
  return <Link to={href}>{text}</Link>;
};

const renderBreadcrumb = ({ breadcrumb, index, numBreadcrumbs }) => {
  if (index !== numBreadcrumbs - 1) {
    return renderBreadcrumbLink(breadcrumb);
  }
  return breadcrumb.text;
};

const Breadcrumbs = ({ breadcrumbs }) => {
  const numBreadcrumbs = breadcrumbs.length;
  return (
    <div className="Breadcrumbs">
      <Breadcrumb tag="nav">
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbItem key={breadcrumb.href}>
            {renderBreadcrumb({ breadcrumb, index, numBreadcrumbs })}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

export default Breadcrumbs;
