import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { CHASSIS_BODYWORK_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  FLAG_GETTING_CHASSIS_BODYWORKS,
  GET_CHASSIS_BODYWORKS,
  CLEAR_CHASSIS_BODYWORKS,
} from '../types/traffic/ChassisBodywork';

const flagGettingChassisBodyworks = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_CHASSIS_BODYWORKS,
    payload: flag,
  });

const getChassisBodyworks = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingChassisBodyworks(true));
    const query = tableFilters;

    const url = `${CHASSIS_BODYWORK_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const chassisBodywork = await response.json();
    dispatch({
      type: GET_CHASSIS_BODYWORKS,
      payload: chassisBodywork,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingChassisBodyworks(false));
  }
};

const clearChassisBodyworks = () => dispatch =>
  dispatch({
    type: CLEAR_CHASSIS_BODYWORKS,
  });

export {
  flagGettingChassisBodyworks,
  getChassisBodyworks,
  clearChassisBodyworks,
};
