import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generateRescueItineraryPath } from '../../../../config/paths';

const ExtraordinaryMovementEditButton = ({ itineraryId }) => (
  <Link
    className="btn btn-warning"
    to={generateRescueItineraryPath(itineraryId)}
  >
    <i className="fa fa-bus" /> Rescatar
  </Link>
);

ExtraordinaryMovementEditButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default ExtraordinaryMovementEditButton;
