import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ModuleList from '../../../layout/ModuleList';
import {
  clearDetractions,
  getDetractions,
} from '../../../../actions/accounting/Detraction';
import { DETRACTION_PATH, NEW_DETRACTION_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { DETRACTION_COLUMNS } from '../../../../config/columns';

export const Detractions = ({
  breadcrumbs,
  detractions,
  loading,
  dispatchGetDetractions,
  dispatchClearDetractions,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearDetractions();
    },
    [],
  );

  const data = detractions.get('content') || [];
  const pages = detractions.get('totalPages') || null;
  const defaultPageSize = detractions.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Detracciones"
      buttonPath={NEW_DETRACTION_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: DETRACTION_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetDetractions,
        loading,
        modelPath: DETRACTION_PATH,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetDetractions: getDetractions,
  dispatchClearDetractions: clearDetractions,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Detracciones',
      href: DETRACTION_PATH,
    },
  ],
  detractions: AccountingUnit.Detraction.getIn(['all', 'content']),
  loading: AccountingUnit.Detraction.getIn(['all', 'loading']),
});

Detractions.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  detractions: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDetractions: PropTypes.func.isRequired,
  dispatchClearDetractions: PropTypes.func.isRequired,
};

Detractions.defaultProps = {
  detractions: [],
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detractions);
