import { combineReducers } from 'redux';
import Address from './Address';
import City from './City';
import Country from './Country';
import UnitHome from './UnitHome';
import Location from './Location';
import District from './District';
import GeographicZone from './GeographicZone';

export default combineReducers({
  UnitHome,
  Address,
  City,
  Country,
  Location,
  District,
  GeographicZone,
});
