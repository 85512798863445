import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ButtonToolbar } from 'reactstrap';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DATE_FORMAT } from '../../../../config/locale';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import Content from '../../../layout/Content';
import PrintManualSenderRemissionGuideButton from './PrintManualSenderRemissionGuideButton';
import {
  clearManualSenderRemissionGuide,
  getManualSenderRemissionGuide,
} from '../../../../actions/cargo/ManualSenderRemissionGuide';
import { MANUAL_SENDER_REMISSION_GUIDE_PATH } from '../../../../config/paths';

const ManualSenderRemissionGuide = (props) => {
  const {
    breadcrumbs,
    dispatchGetManualSenderRemissionGuide,
    dispatchClearManualSenderRemissionGuide,
    loading,
    match: {
      params: { id: SenderRemissionGuideId },
    },
    manualGrr,
  } = props;

  useEffect(() => {
    dispatchGetManualSenderRemissionGuide({ SenderRemissionGuideId });

    return () => {
      dispatchClearManualSenderRemissionGuide();
    };
  }, [SenderRemissionGuideId]);

  const toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        <PrintManualSenderRemissionGuideButton
          manualRemissionGuide={manualGrr}
        />
      </div>
    </ButtonToolbar>
  );

  let content = null;
  if (loading || manualGrr.isEmpty()) {
    content = <Loader />;
  } else {
    content = (
      <>
        <ResourceProperty label="Número de Guía:">
          {manualGrr.get('guideNumber')}
        </ResourceProperty>
        <ResourceProperty label="Remitente:">
          {manualGrr.get('sender')}
        </ResourceProperty>
        <ResourceProperty label="Consignado:">
          {manualGrr.get('consignee')}
        </ResourceProperty>
        <ResourceProperty label="Origen:">
          {manualGrr.get('sourceName')}
        </ResourceProperty>
        <ResourceProperty label="Destino:">
          {manualGrr.get('destinationName')}
        </ResourceProperty>
        <ResourceProperty label="Creado el:">
          {tzNormalizeDate({
            date: manualGrr.get('createDate'),
            format: DATE_FORMAT,
          })}
        </ResourceProperty>
        <ResourceProperty label="Descripción:">
          {manualGrr.get('description')}
        </ResourceProperty>
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Guia de Remision Remitente"
      content={content}
      toolbar={toolbar}
    />
  );
};
ManualSenderRemissionGuide.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetManualSenderRemissionGuide: PropTypes.func.isRequired,
  dispatchClearManualSenderRemissionGuide: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  manualGrr: PropTypes.instanceOf(Immutable.Map).isRequired,
};

ManualSenderRemissionGuide.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Guias de Remision Remitente',
      href: MANUAL_SENDER_REMISSION_GUIDE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  manualGrr: CargoUnit.ManualSenderRemissionGuide.getIn(['current', 'content']),
  loading: !CargoUnit.ManualSenderRemissionGuide.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetManualSenderRemissionGuide: getManualSenderRemissionGuide,
  dispatchClearManualSenderRemissionGuide: clearManualSenderRemissionGuide,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManualSenderRemissionGuide);
