import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FUEL_STATION_PATH } from '../../../../config/paths';
import { deleteFuelStation } from '../../../../actions/route/FuelStation';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class FuelStationToolbar extends Component {
  state = {
    showWarningModal: false,
  };

  toggleWarningModal = (show) => this.setState({ showWarningModal: show });

  handleToggleWarningModal = (show) => () => this.toggleWarningModal(show);

  handleDeleteFuelStation = () => {
    const { fuelStationId, dispatchDeleteFuelStation } = this.props;
    dispatchDeleteFuelStation({ fuelStationId });
  };

  render() {
    const { showWarningModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        onClickConfirm={this.handleDeleteFuelStation}
        onClickCancel={this.handleToggleWarningModal(false)}
      />
    );

    let content = null;
    content = (
      <Fragment>
        <ButtonToolbar className="pull-right">
          <div>
            <Link
              className="btn btn-primary"
              to={`${FUEL_STATION_PATH}/${this.props.fuelStationId}/edit`}
            >
              Editar
            </Link>{' '}
            <Button
              color="danger"
              onClick={() => this.toggleWarningModal(true)}
            >
              Eliminar
            </Button>
          </div>
        </ButtonToolbar>
        {warningModal}
      </Fragment>
    );
    return content;
  }
}

FuelStationToolbar.propTypes = {
  fuelStationId: PropTypes.number,
  dispatchDeleteFuelStation: PropTypes.func.isRequired,
};

FuelStationToolbar.defaultProps = {
  fuelStationId: null,
};

const mapDispatchToProps = { dispatchDeleteFuelStation: deleteFuelStation };

export default connect(null, mapDispatchToProps)(FuelStationToolbar);
