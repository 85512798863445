import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DETRACTION_PATH } from '../../../../config/paths';
import { clearDetraction, getDetraction } from '../../../../actions';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import DetractionToolbar from './DetractionToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import DetractionBasicInformationResource from './resource/DetractionBasicInformationResource';

export const Detraction = ({
  breadcrumbs,
  match: {
    params: { id: detractionId },
  },
  detraction,
  loading,
  dispatchGetDetraction,
  dispatchClearDetraction,
}) => {
  useLayoutEffect(() => {
    dispatchGetDetraction({ detractionId });

    return () => dispatchClearDetraction();
  }, []);

  let content = null;
  let toolbar = null;
  if (loading) content = <Loader />;
  else if (detraction.isEmpty()) {
    content = <NoDataResource returnPage={DETRACTION_PATH} />;
  } else {
    const detractionJSON = detraction.toJS();
    content = <DetractionBasicInformationResource {...detractionJSON} />;
    toolbar = <DetractionToolbar detractionId={detractionJSON.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Detracción"
      toolbar={toolbar}
      content={content}
    />
  );
};

Detraction.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  detraction: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  dispatchGetDetraction: PropTypes.func.isRequired,
  dispatchClearDetraction: PropTypes.func.isRequired,
};

Detraction.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Detracciones',
      href: DETRACTION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  detraction: AccountingUnit.Detraction.getIn(['current', 'content']),
  loading: !AccountingUnit.Detraction.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDetraction: getDetraction,
  dispatchClearDetraction: clearDetraction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detraction);
