import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  WORKSTATION_PATH,
  NEW_WORKSTATION_PATH,
} from '../../../../config/paths';
import { WORKSTATION_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { clearWorkstations, getWorkstations } from '../../../../actions';

export class Workstations extends Component {
  componentWillUnmount() {
    this.props.dispatchClearWorkstations();
  }

  render() {
    const { workstations, loading, breadcrumbs, dispatchGetWorkstations } =
      this.props;
    const data = workstations.get('content') || [];
    const pages = workstations.get('totalPages') || null;
    const defaultPageSize = workstations.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Cajas"
        buttonPath={NEW_WORKSTATION_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: WORKSTATION_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetWorkstations,
          modelPath: WORKSTATION_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetWorkstations: getWorkstations,
  dispatchClearWorkstations: clearWorkstations,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Cajas',
      href: WORKSTATION_PATH,
    },
  ],
  workstations: HumanResourcesUnit.Workstation.getIn(['all', 'content']),
  loading: HumanResourcesUnit.Workstation.getIn(['all', 'loading']),
});

Workstations.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  workstations: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetWorkstations: PropTypes.func.isRequired,
  dispatchClearWorkstations: PropTypes.func.isRequired,
};

Workstations.defaultProps = {
  workstations: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workstations);
