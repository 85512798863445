import React, { Component } from 'react';
import { connect } from 'react-redux';
import Decimal from 'decimal.js';
import { PURCHASE_ORDER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import PurchaseOrderForm from './PurchaseOrderForm';
import Loader from '../../../common/Loader';
import { postPurchaseOrder } from './config/fetch';
import { PURCHASE_ORDER_STATUS } from '../../../../config/constants';
import Content from '../../../layout/Content';

class NewPurchaseOrder extends Component {
  state = {
    loading: false,
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  createPayload = ({ formValues, fuelVoucherGridValues, totalAmount }) => ({
    comments: formValues.comments,
    totalAmount,
    agencyId: formValues.agency.value,
    purchaseOrderDetailList: fuelVoucherGridValues.map(
      ({ fuelVoucher, realNumberOfGallons }) => ({
        fuelVoucherId: fuelVoucher.value,
        fuelVoucher: {
          realNumberOfGallons: new Decimal(realNumberOfGallons).toNumber(),
          pricePerGallon: new Decimal(formValues.pricePerGalon).toNumber(),
        },
      }),
    ),
    status: PURCHASE_ORDER_STATUS.CREATED.value,
  });

  render() {
    const { loading } = this.state;
    const { breadcrumbs } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else {
      content = (
        <PurchaseOrderForm
          setLoading={this.setLoading}
          createPayload={this.createPayload}
          fetchFunction={postPurchaseOrder}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Nueva Orden de Compra"
        subtitle="Crear una nueva orden de compra"
        content={content}
      />
    );
  }
}

NewPurchaseOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Orden de Compra',
      href: PURCHASE_ORDER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(NewPurchaseOrder);
