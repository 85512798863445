import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { COMMON_PRODUCT_PATH } from '../../../../config/paths';

const EditCommonProductButton = ({ commonProductId }) => (
  <Link
    className="btn btn-primary"
    to={`${COMMON_PRODUCT_PATH}/${commonProductId}/edit`}
  >
    Editar
  </Link>
);

EditCommonProductButton.propTypes = {
  commonProductId: PropTypes.number.isRequired,
};

export default EditCommonProductButton;
