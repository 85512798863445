// types should always be imperative verbs

export const SHOW_ERROR_POSTING_DISCOUNT_CODE_REPORT =
  'SHOW_ERROR_POSTING_DISCOUNT_CODE_REPORT';
export const CLEAR_ERROR_POSTING_DISCOUNT_CODE_REPORT =
  'CLEAR_ERROR_POSTING_DISCOUNT_CODE_REPORT';
export const SHOW_ERROR_GETTING_DISCOUNT_CODE_REPORT =
  'SHOW_ERROR_GETTING_DISCOUNT_CODE_REPORT';
export const SHOW_ERROR_GETTING_DISCOUNT_CODE_REPORTS =
  'SHOW_ERROR_GETTING_DISCOUNT_CODE_REPORTS';
export const CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORT =
  'CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORT';
export const CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORTS =
  'CLEAR_ERROR_GETTING_DISCOUNT_CODE_REPORTS';
export const CLEAR_DISCOUNT_CODE_REPORTS = 'CLEAR_DISCOUNT_CODE_REPORTS';
export const CLEAR_DISCOUNT_CODE_REPORT = 'CLEAR_DISCOUNT_CODE_REPORT';
export const POST_DISCOUNT_CODE_REPORT = 'POST_DISCOUNT_CODE_REPORT';
export const GET_DISCOUNT_CODE_REPORTS = 'GET_DISCOUNT_CODE_REPORTS';
export const GET_DISCOUNT_CODE_REPORT = 'GET_DISCOUNT_CODE_REPORT';
export const DOWNLOAD_DISCOUNT_CODE_REPORT = 'DOWNLOAD_DISCOUNT_CODE_REPORT';
export const FLAG_GETTING_DISCOUNT_CODE_REPORT =
  'FLAG_GETTING_DISCOUNT_CODE_REPORT';
export const FLAG_GETTING_DISCOUNT_CODE_REPORTS =
  'FLAG_GETTING_DISCOUNT_CODE_REPORTS';
export const FLAG_POSTING_DISCOUNT_CODE_REPORT =
  'FLAG_POSTING_DISCOUNT_CODE_REPORT';
