import React from 'react';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BASE_FUEL_REFILL_PATH } from '../../../../../config/paths';

const NewBaseFuelRefillButton = () => (
  <ButtonToolbar className="pull-right">
    <ButtonGroup>
      <Link
        target="_blank"
        className="btn btn-outline-secondary"
        to={BASE_FUEL_REFILL_PATH}
      >
        Registrar Relleno en Base
      </Link>
    </ButtonGroup>
  </ButtonToolbar>
);

export default NewBaseFuelRefillButton;
