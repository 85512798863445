import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ORGANIZATION_CHART_PATH } from '../../../../config/paths';
import {
  clearOrganizationChart,
  getOrganizationChart,
  putOrganizationChart,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import OrganizationChartForm from './OrganizationChartForm';
import { DEFAULT_COMPANY_OPTION } from '../../../../config/defaults';

const EditOrganizationChart = ({
  breadcrumbs,
  dispatchGetOrganizationChart,
  dispatchClearOrganizationChart,
  dispatchPutOrganizationChart,
  match: {
    params: { id: organizationChartId },
  },
  loading,
  organizationChart,
}) => {
  /*
   * The following hook overrides the componentDidUnmount and componentWillUnmount function.
   * View documentation in https://bit.ly/3OI2V0u
   */
  useLayoutEffect(() => {
    // This line overrides the componenDidUpdate function.
    dispatchGetOrganizationChart({ organizationChartId });

    // This line overrides the componentWillUnmount function.
    return () => dispatchClearOrganizationChart();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.companyAreaTypeId = formValues.companyAreaTypeId.value;
    newFormValues.companyId = formValues.companyId.value;
    newFormValues.parentCompanyAreaId =
      formValues.parentCompanyAreaId && formValues.parentCompanyAreaId.value;
    dispatchPutOrganizationChart(organizationChartId, newFormValues);
  };

  const generateInitialValues = (organizationChartJson) => ({
    name: organizationChartJson.name,
    description: organizationChartJson.description,
    companyAreaTypeId: {
      value: organizationChartJson.companyAreaType.id,
      label: organizationChartJson.companyAreaType.name,
    },
    companyId: DEFAULT_COMPANY_OPTION,
    parentCompanyAreaId: organizationChartJson.parentCompanyArea && {
      value: organizationChartJson.parentCompanyArea.id,
      label: organizationChartJson.parentCompanyArea.name,
    },
  });

  let content;

  if (loading) content = <Loader />;
  else if (organizationChart.isEmpty())
    content = <NoDataResource returnPage={ORGANIZATION_CHART_PATH} />;
  else
    content = (
      <OrganizationChartForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(organizationChart.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Organigrama"
      subtitle="Editar organigrama"
      content={content}
    />
  );
};

EditOrganizationChart.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearOrganizationChart: PropTypes.func.isRequired,
  dispatchGetOrganizationChart: PropTypes.func.isRequired,
  dispatchPutOrganizationChart: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  organizationChart: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditOrganizationChart.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearOrganizationChart: clearOrganizationChart,
  dispatchGetOrganizationChart: getOrganizationChart,
  dispatchPutOrganizationChart: putOrganizationChart,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Organigrama',
      href: ORGANIZATION_CHART_PATH,
    },
    {
      text: 'Ver',
      href: `${ORGANIZATION_CHART_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  organizationChart: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditOrganizationChart);
