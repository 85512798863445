import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table, FormGroup, Label } from 'reactstrap';
import { Field } from 'redux-form';
import EditableTextTd from '../../../common/forms/table/EditableTextTd';

const PrintTemplateTable = ({
  printStationList,
  handlePrintTemplateChange,
}) => (
  <>
    <Row>
      <Col>
        <Table striped responsive role="grid">
          <thead>
            <tr>
              <th>Tipo de Plantilla</th>
              <th>Código de Serie</th>
              <th>Próximo Correlativo</th>
            </tr>
          </thead>
          <tbody>
            {printStationList.map((printStation) =>
              printStation.printTemplateList.map((printTemplate) => (
                <tr key={printTemplate.id}>
                  <td>{printTemplate.printTemplateType}</td>
                  <td>{printTemplate.documentSeries}</td>
                  <td>
                    <EditableTextTd
                      text={printTemplate.documentCode}
                      padStart
                      padLength={7}
                      padText="0"
                      id={printTemplate.id}
                      onValueChange={handlePrintTemplateChange}
                      name="documentCode"
                    />
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
    <FormGroup check>
      <Label check>
        <Field name="overridePrintTemplate" component="input" type="checkbox" />{' '}
        Ignorar Restricción de Correlativos Consecutivos
      </Label>
      <p className="text-muted">
        Usualmente se espera que los correlativos sean números consecutivos
        entre sesiones. Para ignorar esta restricción, debido a un boleto dañado
        o alguna otra excepción, se debe marcar esta opción
      </p>
    </FormGroup>
  </>
);

PrintTemplateTable.propTypes = {
  handlePrintTemplateChange: PropTypes.func.isRequired,
  printStationList: PropTypes.arrayOf(
    PropTypes.shape({
      printTemplateList: PropTypes.arrayOf(
        PropTypes.shape({
          documentSeries: PropTypes.string.isRequired,
          printTemplateType: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
};

PrintTemplateTable.defaultProps = {
  printStationList: [],
};

export default PrintTemplateTable;
