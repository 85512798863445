import Immutable from 'immutable';
import {
  FLAG_POSTING_ACCOUNTING_REPORT,
  SHOW_ERROR_POSTING_ACCOUNTING_REPORT,
  CLEAR_ERROR_POSTING_ACCOUNTING_REPORT,
  POST_ACCOUNTING_REPORT,
  GET_ACCOUNTING_REPORTS,
  FLAG_GETTING_ACCOUNTING_REPORTS,
  SHOW_ERROR_GETTING_ACCOUNTING_REPORTS,
  CLEAR_ERROR_GETTING_ACCOUNTING_REPORTS,
  CLEAR_ACCOUNTING_REPORTS,
  FLAG_GETTING_ACCOUNTING_REPORT,
  SHOW_ERROR_GETTING_ACCOUNTING_REPORT,
  CLEAR_ERROR_GETTING_ACCOUNTING_REPORT,
  GET_ACCOUNTING_REPORT,
  CLEAR_ACCOUNTING_REPORT,
  DOWNLOAD_ACCOUNTING_REPORT,
} from '../../../actions/types';
import downloadCsv from '../../../utils/download-csv';

const INITIAL_STATE = Immutable.Map({
  form: Immutable.Map({
    error: null,
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // getting all
    case FLAG_GETTING_ACCOUNTING_REPORTS:
      return state.setIn(['all', 'loading'], action.payload);
    case SHOW_ERROR_GETTING_ACCOUNTING_REPORTS:
      return state.setIn(['all', 'error'], action.payload);
    case CLEAR_ERROR_GETTING_ACCOUNTING_REPORTS:
      return state.deleteIn(['all', 'error']);
    case GET_ACCOUNTING_REPORTS:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_ACCOUNTING_REPORTS:
      return state.deleteIn(['all', 'content']);

    // posting
    case FLAG_POSTING_ACCOUNTING_REPORT:
      return state.setIn(['form', 'loading'], action.payload);
    case SHOW_ERROR_POSTING_ACCOUNTING_REPORT:
      return state.setIn(['form', 'error'], action.payload);
    case CLEAR_ERROR_POSTING_ACCOUNTING_REPORT:
      return state.deleteIn(['form', 'error']);
    case POST_ACCOUNTING_REPORT:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));

    // getting one
    case FLAG_GETTING_ACCOUNTING_REPORT:
      return state.setIn(['current', 'loading'], action.payload);
    case SHOW_ERROR_GETTING_ACCOUNTING_REPORT:
      return state.setIn(['current', 'error'], action.payload);
    case CLEAR_ERROR_GETTING_ACCOUNTING_REPORT:
      return state.deleteIn(['current', 'error']);
    case GET_ACCOUNTING_REPORT:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case CLEAR_ACCOUNTING_REPORT:
      return state.deleteIn(['current', 'content']);

    // downloading
    case DOWNLOAD_ACCOUNTING_REPORT:
      downloadCsv(action.payload, 'accounting-report.csv');
      return state;
    default:
      return state;
  }
};
