import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Button } from 'reactstrap';
import { postSendTicketByEmail } from '../../../../../actions/index';
import { POST } from '../../../../../config/permissions';
import { generateSendTicketByEmailEndpoint } from '../../../../../config/endpoints';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import SendTicketForm from './SendTicketForm';
import Modal from '../../../../common/modal/Modal';

class SendTicketButton extends Component {
  static propTypes = {
    dispatchPostSendTicketByEmail: PropTypes.func.isRequired,
    ticketId: PropTypes.number.isRequired,
  };

  state = {
    showModal: false,
  };

  showConfirmationModal = () => this.setState({ showModal: true });

  closeConfirmationModal = () => this.setState({ showModal: false });

  handleSendTicket = async ({ email }) => {
    const { dispatchPostSendTicketByEmail, ticketId } = this.props;
    this.setState({ showModal: false });
    const emailSent = await dispatchPostSendTicketByEmail({
      ticketId,
      email,
    });

    if (emailSent)
      toastr.success('Exito', 'Se envió ticket por correo satisfactoriamente');
  };

  render() {
    const body = (
      <>
        <p>
          Favor especificar el correo electrónico al que se le va a enviar un
          PDF de este boleto.
        </p>
        <SendTicketForm
          onSubmit={this.handleSendTicket}
          onCancel={this.closeConfirmationModal}
        />
      </>
    );

    const modal = (
      <Modal
        show={this.state.showModal}
        title="Enviar Boleto"
        body={body}
        size="md"
      />
    );

    // This component before has used <ApiButton /> component to send the ticket
    return (
      <>
        <Button
          color="light"
          className="border"
          onClick={this.showConfirmationModal}
        >
          <i className="fa fa-envelope" /> Enviar
        </Button>
        {modal}
      </>
    );
  }
}

const mapDispatchToProps = {
  // todo: connect with backend via action
  dispatchPostSendTicketByEmail: postSendTicketByEmail,
};

const connectedComponent = connect(null, mapDispatchToProps)(SendTicketButton);

export default withEndpointAuthorization({
  url: generateSendTicketByEmailEndpoint(0),
  permissionType: POST,
})(connectedComponent);
