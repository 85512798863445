import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MATERIAL_PATH } from '../../../../config/paths';
import {
  clearMaterial,
  getMaterial,
  putMaterial,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import MaterialForm from './MaterialForm';

const EditMaterial = ({
  breadcrumbs,
  dispatchGetMaterial,
  dispatchClearMaterial,
  dispatchPutMaterial,
  match: {
    params: { id: materialId },
  },
  loading,
  material,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaterial({ materialId });

    return () => dispatchClearMaterial();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutMaterial(materialId, {
      ...formValues,
      measurementUnit: formValues.measurementUnit.value,
    });

  const generateInitialValues = ({ name, externalId, measurementUnit }) => ({
    name,
    externalId,
    measurementUnit: {
      value: measurementUnit,
      label: measurementUnit,
    },
  });

  let content;

  if (loading) content = <Loader />;
  else if (material.isEmpty())
    content = <NoDataResource returnPage={MATERIAL_PATH} />;
  else
    content = (
      <MaterialForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(material.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Material"
      subtitle="Editar material"
      content={content}
    />
  );
};

EditMaterial.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMaterial: PropTypes.func.isRequired,
  dispatchGetMaterial: PropTypes.func.isRequired,
  dispatchPutMaterial: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  material: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditMaterial.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMaterial: clearMaterial,
  dispatchGetMaterial: getMaterial,
  dispatchPutMaterial: putMaterial,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Materiales',
      href: MATERIAL_PATH,
    },
    {
      text: 'Ver',
      href: `${MATERIAL_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  material: MechanicalMaintenanceUnit.Material.getIn(['current', 'content']),
  loading: !MechanicalMaintenanceUnit.Material.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMaterial);
