import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { generalIncomeTypeSummaryListPropTypes } from './SalesSessionPropTypes';
import CollapsibleHeader from '../../../layout/CollapsibleHeader';
import IncomeTable from './IncomeTable';
import { numberFormatter } from '../../../../utils/number';

const IncomeSummary = ({
  generalIncomeTypeSummaryList,
  salesSessionId,
  totalGeneralIncome,
  numberOfGeneralIncomes,
}) => {
  if (!generalIncomeTypeSummaryList.length) return null;

  return (
    <>
      <hr />
      <CollapsibleHeader
        headerText="Ingresos"
        component={IncomeTable}
        salesSessionId={salesSessionId}
      />
      <Row>
        <Col>
          <ResourceProperty label="Cantidad: " boldLabel={false}>
            {numberOfGeneralIncomes}
          </ResourceProperty>
        </Col>
      </Row>
      <Row>
        <Col>
          {generalIncomeTypeSummaryList.map((incomeType) => (
            <ResourceProperty
              key={incomeType.moneyFlowConcept}
              label={`${incomeType.moneyFlowConcept}: `}
            >
              {numberFormatter({ value: incomeType.totalAmount })}
            </ResourceProperty>
          ))}
          <ResourceProperty label="Total de Ingresos: ">
            {numberFormatter({ value: totalGeneralIncome })}
          </ResourceProperty>
        </Col>
      </Row>
    </>
  );
};

IncomeSummary.propTypes = {
  generalIncomeTypeSummaryList:
    generalIncomeTypeSummaryListPropTypes.isRequired,
  salesSessionId: PropTypes.number.isRequired,
  totalGeneralIncome: PropTypes.number.isRequired,
  numberOfGeneralIncomes: PropTypes.number.isRequired,
};

export default IncomeSummary;
