import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_ACTIVE_SALES_SESSION,
  GET_ACTIVE_SALES_SESSION,
  SHOW_ERROR_GETTING_ACTIVE_SALES_SESSION,
  CLEAR_ERROR_GETTING_ACTIVE_SALES_SESSION,
  FLAG_GETTING_SALES_SESSIONS,
  GET_SALES_SESSIONS,
  CLEAR_SALES_SESSIONS,
  FLAG_SALES_SESSION_ACTIVITY,
  GET_SALES_SESSION,
  CLEAR_SALES_SESSION,
  GET_OLD_SALES_SESSION,
  CLEAR_ACTIVE_SALES_SESSION,
} from '../types';
import {
  SALES_SESSION_ENDPOINT,
  generateSalesSessionDetailsEndpoint,
  generateSalesSessionEndEndPoint,
  SALES_SESSION_START_ENDPOINT,
} from '../../config/endpoints';
import {
  handleResponseError,
  isErrorResponse,
} from '../../utils/error-handlers';
import { NEW_SALES_SESSION_PATH, SALES_SESSION_PATH } from '../../config/paths';
import { NO_ACTIVE_SALES_SESSION_ERROR_MESSAGE } from '../../config/messages';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import {
  DIFFERENT_CASH_ON_HAND_AMOUNT,
  INCORRECT_DIFFERENCE_AMOUNT,
} from '../../utils/errors/error-codes';

const flagGettingActiveSalesSession = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_ACTIVE_SALES_SESSION,
  });

const showErrorGettingActiveSalesSession = (error) => (dispatch) =>
  dispatch({
    payload: error,
    type: SHOW_ERROR_GETTING_ACTIVE_SALES_SESSION,
  });

const clearErrorGettingActiveSalesSession = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_ACTIVE_SALES_SESSION,
  });

const getActiveSalesSession =
  async ({ source = null }) =>
  async (dispatch) => {
    dispatch(clearErrorGettingActiveSalesSession());
    dispatch(flagGettingActiveSalesSession(true));

    const url = `${SALES_SESSION_ENDPOINT}/actions/get-current-active`;
    try {
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      // handle an error response from the server
      const serverError = await handleResponseError(response);
      if (serverError) {
        toastr.error('Error', serverError);
        if (serverError === NO_ACTIVE_SALES_SESSION_ERROR_MESSAGE) {
          const redirect = source
            ? `${NEW_SALES_SESSION_PATH}?source=${source}`
            : NEW_SALES_SESSION_PATH;
          dispatch(push(redirect));
          return dispatch(flagGettingActiveSalesSession(false));
        }
        dispatch(flagGettingActiveSalesSession(false));
        return dispatch(showErrorGettingActiveSalesSession(serverError));
      }

      // the response is a response instance
      // parse the data into a usable format using `.json()`
      const routes = await response.json();

      dispatch(flagGettingActiveSalesSession(false));
      return dispatch({
        type: GET_ACTIVE_SALES_SESSION,
        payload: routes,
      });
    } catch (err) {
      dispatch(flagGettingActiveSalesSession(false));
      return console.error(err);
    }
  };

const getCurrentActiveSalesSession = async () => async (dispatch) => {
  try {
    dispatch(flagGettingActiveSalesSession(true));
    const url = `${SALES_SESSION_ENDPOINT}/actions/get-current-active`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    // handle an error response from the server
    await isErrorResponse(response);

    const currentActiveSalesSession = await response.json();

    await dispatch({
      type: GET_ACTIVE_SALES_SESSION,
      payload: currentActiveSalesSession,
    });
  } catch (error) {
    if (error.message !== NO_ACTIVE_SALES_SESSION_ERROR_MESSAGE) {
      toastr.error('Error', error.toString());
    }
  } finally {
    dispatch(flagGettingActiveSalesSession(false));
  }
};

const clearActiveSalesSession = () => (dispatch) =>
  dispatch({
    type: CLEAR_ACTIVE_SALES_SESSION,
  });

const flagGettingSalesSessions = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_SALES_SESSIONS,
    payload: flag,
  });

const getSalesSessions = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingSalesSessions(true));
    const query = {
      ...tableFilters,
    };
    const url = `${SALES_SESSION_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    // check if it is an error response
    await isErrorResponse(response);
    const salesSessions = await response.json();
    dispatch({
      type: GET_SALES_SESSIONS,
      payload: salesSessions,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingSalesSessions(false));
  }
};

const clearSalesSessions = () => (dispatch) =>
  dispatch({
    type: CLEAR_SALES_SESSIONS,
  });

const flagSalesSessionActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_SALES_SESSION_ACTIVITY,
    payload: flag,
  });

const getOldSalesSession = async ({ salesSessionId }) => {
  try {
    const url = `${SALES_SESSION_ENDPOINT}/${salesSessionId}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response);

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const getSalesSession =
  async ({ salesSessionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagSalesSessionActivity(true));
      const url = generateSalesSessionDetailsEndpoint(salesSessionId);

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response);
      const salesSession = await response.json();
      // get and dispatch old sales session
      // remove when back updates to new sales session
      const oldSalesSession = await getOldSalesSession({ salesSessionId });
      await dispatch({
        type: GET_OLD_SALES_SESSION,
        payload: oldSalesSession,
      });
      await dispatch({
        type: GET_SALES_SESSION,
        payload: salesSession,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagSalesSessionActivity(false));
    }
  };

const clearSalesSession = () => (dispatch) =>
  dispatch({
    type: CLEAR_SALES_SESSION,
  });

// replace oldSalesSession with new one
// when backend changes get implemented
const postEndSalesSession =
  async ({
    salesSessionId,
    cashOnHand,
    differenceReasonId = null,
    differenceDescription = null,
    differenceAmount = null,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagSalesSessionActivity(true));
      const payload = { cashOnHand };
      // add difference to payload if needed
      if (differenceReasonId) {
        payload.difference = {
          differenceReasonId,
          description: differenceDescription,
          amount: differenceAmount,
        };
      }
      const url = generateSalesSessionEndEndPoint(salesSessionId);
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);
      const endedSalesSession = await response.json();
      await dispatch(getSalesSession({ salesSessionId: endedSalesSession.id }));
    } catch ({ code, message }) {
      // refresh sales session page if any amount inconsistency is recieved as error
      if (
        code &&
        code.includes([
          DIFFERENT_CASH_ON_HAND_AMOUNT,
          INCORRECT_DIFFERENCE_AMOUNT,
        ])
      ) {
        dispatch(getSalesSession({ salesSessionId }));
      }
      toastr.error('Error', message);
    } finally {
      dispatch(flagSalesSessionActivity(false));
    }
  };

const postStartSalesSession =
  async ({
    agencyId,
    workstationId,
    isOverrideGaplessSequenceNumber,
    overridePrintTemplates = null,
    sourcePath = null,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagSalesSessionActivity(true));
      const payload = {
        agencyId,
        workstationId,
        isOverrideGaplessSequenceNumber,
        overridePrintTemplates,
      };
      const url = SALES_SESSION_START_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      if (sourcePath) {
        dispatch(push(sourcePath));
      } else {
        dispatch(push(SALES_SESSION_PATH));
      }
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagSalesSessionActivity(false));
    }
  };

export {
  clearErrorGettingActiveSalesSession,
  getActiveSalesSession,
  getCurrentActiveSalesSession,
  clearActiveSalesSession,
  getSalesSessions,
  clearSalesSessions,
  getSalesSession,
  clearSalesSession,
  postEndSalesSession,
  postStartSalesSession,
};
