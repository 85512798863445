import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { isRequired } from '../../../../../utils/validators';
import FormFooter from '../../../../common/forms/FormFooter';
import TextInput from '../../../../common/forms/input/TextInput';

export const AddCargoToItineraryByParcelForm = ({ handleSubmit, loading }) => {
  const icon = loading ? 'fa fa-spinner fa-spin' : 'fa fa-search';

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Col xs="12" sm="3">
            <Label for="documentSeries" className="mb-0">
              Correlativo de Comprobante:
            </Label>
          </Col>
          <Col xs="12" sm="4" className="input-container">
            <Field
              name="parcelDocumentSerie"
              type="text"
              component={TextInput}
              validate={[isRequired]}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            -
          </Col>
          <Col xs="12" sm="4" className="input-container">
            <Field
              name="parcelDocumentCorrelation"
              type="text"
              component={TextInput}
              validate={[isRequired]}
            />
          </Col>
        </FormGroup>
        <FormFooter
          saveButtonText="Buscar"
          saveButtonIcon={icon}
          saveButtonDisabled={loading}
        />
      </Form>
    </>
  );
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.ManifestCargo.getIn(['current', 'activity']).isEmpty(),
});

AddCargoToItineraryByParcelForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

AddCargoToItineraryByParcelForm.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'AddCargoToItineraryByParcelForm',
})(AddCargoToItineraryByParcelForm);

export default connect(mapStateToProps, null)(formComponent);
