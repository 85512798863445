import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import AddressResource from '../../../../common/resource/AddressResource';
import { GENDERS } from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import {
  businessBasicInformationPropTypes,
  businessContactInformationPropTypes,
} from '../../../user/business/proptypes/BusinessPropTypes';
import {
  customerBasicInformationPropTypes,
  customerContactInformationPropTypes,
} from '../../../user/customer/proptypes/CustomerPropTypes';
import { DATE_FORMAT } from '../../../../../config/locale';

const ContractorInformationResource = ({
  business,
  customer,
  clientAddress,
}) => {
  let resource = null;

  if (business) {
    const { businessTaxId, name, address, primaryContact, phone, email } =
      business;

    const primaryContactResource = primaryContact && (
      <li className="list-inline-item">
        <i className="fa fa-user-o" /> {primaryContact}
      </li>
    );
    const phoneResource = phone && (
      <li className="list-inline-item">
        <a href={`tel:${phone}`}>
          <i className="fa fa-phone" /> {phone}
        </a>
      </li>
    );
    const emailResource = email && (
      <li className="list-inline-item">
        <a href={`mailto:${email}`}>
          <i className="fa fa-envelope-o" /> {email}
        </a>
      </li>
    );

    resource = (
      <>
        <ResourceProperty label="RUC:">{businessTaxId}</ResourceProperty>
        <ResourceProperty label="Razón Social:">{name}</ResourceProperty>
        <ResourceProperty label="Datos de Contacto:">
          <ul className="list-inline m-0">
            {primaryContactResource}
            {phoneResource}
            {emailResource}
          </ul>
        </ResourceProperty>
        <ResourceProperty label="Dirección:">
          <AddressResource address={address} />
        </ResourceProperty>
      </>
    );
  }

  if (customer) {
    const {
      identificationType,
      idDocumentNumber,
      firstName,
      lastName,
      gender,
      idCountryOfOrigin,
      mobilePhone,
      email,
      dob,
    } = customer;

    const phoneResource = mobilePhone && (
      <li className="list-inline-item">
        <a href={`tel:${mobilePhone}`}>
          <i className="fa fa-phone" /> {mobilePhone}
        </a>
      </li>
    );
    const emailResource = email && (
      <li className="list-inline-item">
        <a href={`mailto:${email}`}>
          <i className="fa fa-envelope-o" /> {email}
        </a>
      </li>
    );

    let genderLabel = '-';

    if (gender) {
      const genderObject = GENDERS.find(
        (customerGender) => customerGender.value === gender,
      );

      genderLabel = genderObject && genderObject.label;
    }

    resource = (
      <Fragment>
        <ResourceProperty label={`${identificationType.name}:`}>
          {idDocumentNumber}
        </ResourceProperty>
        <ResourceProperty label="Nombre Completo:">
          {firstName} {lastName}
        </ResourceProperty>
        <ResourceProperty label="Género:">{genderLabel}</ResourceProperty>
        <ResourceProperty label="Nacionalidad:">
          {idCountryOfOrigin || '-'}
        </ResourceProperty>
        <ResourceProperty label="Datos de Contacto:">
          <ul className="list-inline m-0">
            {phoneResource}
            {emailResource}
          </ul>
        </ResourceProperty>
        <ResourceProperty label="Nacimiento:">
          {dob ? tzNormalizeDate({ date: dob, format: DATE_FORMAT }) : '-'}
        </ResourceProperty>
        <ResourceProperty label="Dirección:">{clientAddress}</ResourceProperty>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <h3>Datos del Contratante</h3>
      {resource}
    </Fragment>
  );
};

ContractorInformationResource.propTypes = {
  business: PropTypes.shape(
    Object.assign(
      businessBasicInformationPropTypes,
      businessContactInformationPropTypes,
    ),
  ),
  customer: PropTypes.shape(
    Object.assign(
      customerBasicInformationPropTypes,
      customerContactInformationPropTypes,
    ),
  ),
  clientAddress: PropTypes.string,
};

ContractorInformationResource.defaultProps = {
  business: null,
  customer: null,
  clientAddress: null,
};

export default ContractorInformationResource;
