import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ItinerarySearchForm from './ItinerarySearchForm';
import ItinerarySearchResult from './ItinerarySearchResult';
import { searchItinerary } from '../../../../actions';
import { INT_DATE_FORMAT } from '../../../../config/locale';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../config/paths';
import { tzNormalizeDate } from '../../../../utils/date';

export class ItinerarySearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFormValues: {
        sourceLocationId: 1,
        destinationLocationId: 1,
        departureDayString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
      },
    };
  }

  onSubmit = (formValues) => {
    const { dispatchSearchItinerary } = this.props;

    const itinerarySearchFormValues = {
      sourceLocationId: formValues.sourceLocationId
        ? formValues.sourceLocationId.value
        : null,
      destinationLocationId: formValues.destinationLocationId
        ? formValues.destinationLocationId.value
        : null,
      departureDayString: tzNormalizeDate({
        date: formValues.departureDayString,
        format: INT_DATE_FORMAT,
      }),
      departureTimeString: formValues.departureTimeString
        ? `${formValues.departureTimeString}:00`
        : null,
    };

    this.setState({ searchFormValues: itinerarySearchFormValues });

    dispatchSearchItinerary(itinerarySearchFormValues);
  };

  render() {
    const date = tzNormalizeDate();

    const { searchFormValues } = this.state;

    const {
      getRowData,
      sourceLocationIsRequired,
      destinationLocationIsRequired,
      modelPath,
      openPathInNewTab,
      onModal,
    } = this.props;

    return (
      <div>
        <ItinerarySearchForm
          onSubmit={this.onSubmit}
          initialValues={{
            departureDayString: date,
          }}
          sourceLocationIsRequired={sourceLocationIsRequired}
          destinationLocationIsRequired={destinationLocationIsRequired}
          onModal={onModal}
        />
        <ItinerarySearchResult
          getRowData={getRowData}
          searchFormValues={searchFormValues}
          modelPath={modelPath}
          openPathInNewTab={openPathInNewTab}
        />
      </div>
    );
  }
}

ItinerarySearch.propTypes = {
  getRowData: PropTypes.func,
  dispatchSearchItinerary: PropTypes.func.isRequired,
  sourceLocationIsRequired: PropTypes.bool,
  destinationLocationIsRequired: PropTypes.bool,
  modelPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  openPathInNewTab: PropTypes.bool,
  onModal: PropTypes.bool,
};

ItinerarySearch.defaultProps = {
  getRowData: null,
  sourceLocationIsRequired: true,
  destinationLocationIsRequired: true,
  modelPath: ITINERARY_FOR_COUNTER_PATH,
  openPathInNewTab: false,
  onModal: false,
};

const mapDispatchToProps = {
  dispatchSearchItinerary: searchItinerary,
};

export default connect(null, mapDispatchToProps)(ItinerarySearch);
