import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearCargoCategories, getCargoCategories } from '../../../actions';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import SelectFilter from './SelectFilter';

const CargoCategoryFilter = ({
  dispatchGetCargoCategory,
  dispatchClearCargoCategory,
  options,
  onChangeFilter,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCategory();
    return () => dispatchClearCargoCategory();
  }, []);

  return (
    <SelectFilter isMulti onChangeFilter={onChangeFilter} options={options} />
  );
};

CargoCategoryFilter.propTypes = {
  dispatchGetCargoCategory: PropTypes.func.isRequired,
  dispatchClearCargoCategory: PropTypes.func.isRequired,
  options: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
};

CargoCategoryFilter.defaultProps = {
  options: [],
};

const mapStateToProps = ({ CargoUnit }) => ({
  options: CargoUnit.CargoCategory.getIn(['all', 'content', 'content']).map(
    (option) => ({
      value: option.id,
      label: option.name,
    }),
  ),
});

const mapDispatchToprops = {
  dispatchGetCargoCategory: getCargoCategories,
  dispatchClearCargoCategory: clearCargoCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(CargoCategoryFilter);
