import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { clearExpenses, getAllExpenses } from '../../../../actions/index';
import ModuleList from '../../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { EXPENSE_COLUMNS } from '../../../../config/columns';
import { EXPENSE_PATH, NEW_EXPENSE_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Table from '../../../common/Table';

export class Expenses extends Component {
  componentWillUnmount() {
    this.props.dispatchClearExpenses();
  }

  render() {
    const {
      expenses,
      loading,
      breadcrumbs,
      dispatchGetAllExpenses,
      showOnModal,
      getRowData,
    } = this.props;

    const data = expenses.get('content') || [];
    const pages = expenses.get('totalPages') || null;
    const defaultPageSize = expenses.get('size') || DEFAULT_PAGE_SIZE;

    const tableProps = {
      columns: EXPENSE_COLUMNS,
      data,
      pages,
      defaultPageSize,
      filterable: true,
      fetchData: dispatchGetAllExpenses,
      loading,
      navigateToModelOnRowClick: true,
    };

    if (showOnModal)
      return (
        <Table {...tableProps} getRowData={getRowData} highlightSelectedRow />
      );

    return (
      <ModuleList
        title="Egresos"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_EXPENSE_PATH}
        tableStructure={{
          ...tableProps,
          modelPath: EXPENSE_PATH,
        }}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Egresos', href: '' },
  ],
  expenses: SalesUnit.Expense.getIn(['all', 'content']),
  loading: SalesUnit.Expense.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetAllExpenses: getAllExpenses,
  dispatchClearExpenses: clearExpenses,
};

Expenses.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  expenses: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetAllExpenses: PropTypes.func.isRequired,
  dispatchClearExpenses: PropTypes.func.isRequired,
  showOnModal: PropTypes.bool,
  getRowData: PropTypes.func,
};

Expenses.defaultProps = {
  breadcrumbs: [],
  showOnModal: false,
  getRowData: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
