import Immutable from 'immutable';
import {
  EVENT_PATH,
  HOME_PATH,
  IN_SYSTEM_NOTIFICATION_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'events',
      title: 'Eventos',
      path: EVENT_PATH,
      icon: 'tasks',
    },
    {
      id: 'in-system-notifications',
      title: 'Notificaciones internas del Sistema',
      path: IN_SYSTEM_NOTIFICATION_PATH,
      icon: 'envelope',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Sistema',
      tag: 'span',
      href: '/system',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
