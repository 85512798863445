import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import AddressResource from './AddressResource';

const BusinessResource = ({ business }) => {
  if (!business) return null;

  const primaryContactResource = business.primaryContact && (
    <li className="list-inline-item">
      <i className="fa fa-user-o" /> {business.primaryContact}
    </li>
  );
  const phoneResource = business.phone && (
    <li className="list-inline-item">
      <a href={`tel:${business.phone}`}>
        <i className="fa fa-phone" /> {business.phone}
      </a>
    </li>
  );
  const emailResource = business.email && (
    <li className="list-inline-item">
      <a href={`mailto:${business.email}`}>
        <i className="fa fa-envelope-o" /> {business.email}
      </a>
    </li>
  );

  return (
    <div className="mt-3">
      <Row>
        <Col>
          <div className="mb-2">
            <small>
              <strong>RUC:</strong>
            </small>
            <br />
            {business.businessTaxId}
          </div>
          <div className="mb-2">
            <small>
              <strong>Razón Social:</strong>
            </small>
            <br />
            {business.name}
          </div>
          <div className="mb-2">
            <small>
              <strong>Datos de Contacto:</strong>
            </small>
            <ul className="list-inline">
              {primaryContactResource}
              {phoneResource}
              {emailResource}
            </ul>
          </div>
        </Col>
        <Col>
          <div className="mb-2">
            <small>
              <strong>Dirección:</strong>
            </small>
            <br />
            <AddressResource address={business.address} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

BusinessResource.propTypes = {
  business: PropTypes.shape({
    businessTaxId: PropTypes.string,
    name: PropTypes.string,
    addressSummary: PropTypes.string,
    address: PropTypes.shape({}),
    email: PropTypes.string,
    primaryContact: PropTypes.string,
    phone: PropTypes.string,
  }),
};

BusinessResource.defaultProps = {
  business: null,
};

export default BusinessResource;
