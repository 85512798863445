import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  BUS_FUEL_GROUP_PATH,
  NEW_BUS_FUEL_GROUP_PATH,
} from '../../../../config/paths';
import {
  getBusFuelGroups,
  clearBusFuelGroups,
} from '../../../../actions/traffic/BusFuelGroup';
import { BUS_FUEL_GROUP_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class BusFuelGroups extends Component {
  constructor(props) {
    super(props);
    this.columns = BUS_FUEL_GROUP_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearBusFuelGroups();
  }

  render() {
    const { fuelGroups, loading, breadcrumbs, dispatchGetBusFuelGroups } =
      this.props;
    const data = fuelGroups.get('content') || [];
    const pages = fuelGroups.get('totalPages') || null;
    const defaultPageSize = fuelGroups.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Buses por consumo de combustible"
        buttonPath={NEW_BUS_FUEL_GROUP_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetBusFuelGroups,
          modelPath: BUS_FUEL_GROUP_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetBusFuelGroups: getBusFuelGroups,
  dispatchClearBusFuelGroups: clearBusFuelGroups,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Buses por consumo de combustible',
      href: BUS_FUEL_GROUP_PATH,
    },
  ],
  fuelGroups: TrafficUnit.BusFuelGroup.getIn(['all', 'content']),
  loading: TrafficUnit.BusFuelGroup.getIn(['all', 'loading']),
});

BusFuelGroups.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  fuelGroups: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetBusFuelGroups: PropTypes.func.isRequired,
  dispatchClearBusFuelGroups: PropTypes.func.isRequired,
};

BusFuelGroups.defaultProps = {
  fuelGroups: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusFuelGroups);
