import Immutable from 'immutable';
import {
  HOME_PATH,
  EXPECTED_FUEL_CONSUMPTION_PATH,
  TRAFFIC_UNIT_PATH,
  BUS_FUEL_GROUP_PATH,
  FUEL_PROFILE_PATH,
  PURCHASE_ORDER_PATH,
  EXTRAORDINARY_MOVEMENT_CONTROL_PATH,
  FUEL_VOUCHER_PATH,
  BASE_FUEL_REFILL_PATH,
  DRIVER_BUS_ASSIGNMENT_PATH,
  FUEL_CONSUMPTION_REPORT_PER_BUS_PATH,
  FUEL_CONSUMPTION_REPORT_PER_DRIVER_PATH,
  FUEL_REPORT_PREVIEW_PATH,
  DRIVER_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'drivers',
      title: 'Conductores',
      path: DRIVER_PATH,
      icon: 'male',
    },
    {
      id: 'cabin-crew',
      title: 'Servicio a Bordo',
      path: '/traffic-management/cabin-crew',
      icon: 'signing',
      disabled: true,
    },
    {
      id: 'monitoring',
      title: 'Monitoreo de tráfico',
      path: '/traffic-management/monitoring',
      icon: 'binoculars',
      disabled: true,
    },
    {
      id: 'drivers-assignment',
      title: 'Asignación de Conductores',
      path: DRIVER_BUS_ASSIGNMENT_PATH,
      icon: 'certificate',
    },
    {
      id: 'bus-fuel-group',
      title: 'Buses por consumo de combustible',
      path: BUS_FUEL_GROUP_PATH,
      icon: 'bus',
    },
    {
      id: 'expected-fuel-consumption',
      title: 'Dotación de Combustible',
      path: EXPECTED_FUEL_CONSUMPTION_PATH,
      icon: 'thermometer-full',
    },
    {
      id: 'fuel-profiles',
      title: 'Rellenos Combustible por Circuito',
      path: FUEL_PROFILE_PATH,
      icon: 'free-code-camp',
    },
    {
      id: 'fuel-voucher',
      title: 'Vales de Combustible',
      path: FUEL_VOUCHER_PATH,
      icon: 'file-text-o',
    },
    {
      id: 'extraordinary-movement-control',
      title: 'Control Movimiento Extraordinario',
      path: EXTRAORDINARY_MOVEMENT_CONTROL_PATH,
      icon: 'tachometer',
    },
    {
      id: 'purchase-order',
      title: 'Ordenes de Compra',
      path: PURCHASE_ORDER_PATH,
      icon: 'cart-arrow-down',
    },
    {
      id: 'base-fuel-refill',
      title: 'Relleno Combustible en Base',
      path: BASE_FUEL_REFILL_PATH,
      icon: 'fire',
    },
    {
      id: 'fuel-report-preview',
      title: 'Vista Previa - Reporte de Combustible',
      path: FUEL_REPORT_PREVIEW_PATH,
      icon: 'clipboard',
    },
    {
      id: 'fuel-consumption-report-per-bus',
      title: 'Reporte de Consumo de Combustible por Bus',
      path: FUEL_CONSUMPTION_REPORT_PER_BUS_PATH,
      icon: 'file-excel-o',
    },
    {
      id: 'fuel-consumption-report-per-driver',
      title: 'Reporte de Consumo de Combustible por Conductor',
      path: FUEL_CONSUMPTION_REPORT_PER_DRIVER_PATH,
      icon: 'file-excel-o',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Tráfico',
      tag: 'span',
      href: TRAFFIC_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
