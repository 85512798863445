import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import FuelProfileForm from './FuelProfileForm';
import { FUEL_PROFILE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getFuelProfile,
  clearFuelProfile,
  putFuelProfile,
  getExpectedFuelConsumptionsByCircuit,
  clearExpectedFuelConsumptions,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditFuelProfile extends Component {
  componentDidMount() {
    const {
      dispatchGetFuelProfile,
      dispatchClearExpectedFuelConsumptions,
      dispatchGetExpectedFuelConsumptionsByCircuit,
      match: {
        params: { id },
      },
      fuelProfile,
    } = this.props;
    dispatchGetFuelProfile({ id });
    if (!fuelProfile.isEmpty()) {
      dispatchClearExpectedFuelConsumptions();
      dispatchGetExpectedFuelConsumptionsByCircuit(
        fuelProfile.get('circuit').id,
      );
    }
  }

  componentWillUnmount() {
    const { dispatchClearFuelProfile } = this.props;
    dispatchClearFuelProfile();
  }

  handleFuelProfileSubmit = (formValues) => {
    const {
      dispatchPutFuelProfile,
      match: {
        params: { id },
      },
    } = this.props;
    const fuelProfileId = id;
    dispatchPutFuelProfile(fuelProfileId, formValues);
  };

  generateInitialValues = ({
    refuellingStopList,
    name,
    description,
    circuit: { id: circuitId, name: circuitName },
  }) => {
    const newRefuellingStopList = [];
    // Loop refuellingStopList array to create grid component data
    refuellingStopList.forEach(
      ({
        fuelStationId,
        fuelStation: { location },
        refuellingStopBusFuelGroupList,
      }) => {
        const row = {};
        // Create fuelStationId prop
        row.fuelStationId = {
          value: fuelStationId,
          label: location.name,
        };
        // Loop refuellingStopBusFuelGroupList array to create numberOfGallon prop according to groups number
        refuellingStopBusFuelGroupList.forEach((group) => {
          row[`numberOfGallon${group.busFuelGroupId}`] = +group.numberOfGallon;
        });
        newRefuellingStopList.push(row);
      },
    );

    // creating initial values for FuelProfileForm component
    return {
      name,
      description,
      circuitId: {
        label: `${circuitId} - ${circuitName}`,
        value: circuitId,
      },
      refuellingStopList: newRefuellingStopList,
    };
  };

  render() {
    const { breadcrumbs, fuelProfile, expectedFuels, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (fuelProfile.isEmpty()) {
      content = <NoDataResource returnPage={FUEL_PROFILE_PATH} />;
    } else {
      content = (
        <FuelProfileForm
          onSubmit={this.handleFuelProfileSubmit}
          initialValues={this.generateInitialValues(fuelProfile.toJS())}
          expectedFuels={expectedFuels}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Relleno Combustible por Circuito"
        subtitle="Editar relleno combustible por circuito"
        content={content}
      />
    );
  }
}

EditFuelProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetFuelProfile: PropTypes.func.isRequired,
  dispatchClearFuelProfile: PropTypes.func.isRequired,
  dispatchPutFuelProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  fuelProfile: PropTypes.instanceOf(Immutable.Map).isRequired,
  expectedFuels: PropTypes.instanceOf(Immutable.Map),
  dispatchGetExpectedFuelConsumptionsByCircuit: PropTypes.func.isRequired,
  dispatchClearExpectedFuelConsumptions: PropTypes.func.isRequired,
};

EditFuelProfile.defaultProps = {
  loading: false,
  expectedFuels: null,
};

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos Combustible por Circuito',
      href: FUEL_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: `${FUEL_PROFILE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  fuelProfile: TrafficUnit.FuelProfile.getIn(['current', 'content']),
  loading: !TrafficUnit.FuelProfile.getIn(['current', 'activity']).isEmpty(),
  expectedFuels: TrafficUnit.ExpectedFuelConsumption.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetFuelProfile: getFuelProfile,
  dispatchClearFuelProfile: clearFuelProfile,
  dispatchPutFuelProfile: putFuelProfile,
  dispatchGetExpectedFuelConsumptionsByCircuit:
    getExpectedFuelConsumptionsByCircuit,
  dispatchClearExpectedFuelConsumptions: clearExpectedFuelConsumptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFuelProfile);
