import React from 'react';
import PropTypes from 'prop-types';
import LocationSelect from './LocationSelect';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

const CargoLocationSelect = ({
  value,
  isMulti,
  isClearable,
  onValueChange,
  placeholder,
  isDisabled,
  ...rest
}) => (
  <LocationSelect
    isMulti={isMulti}
    input={{ value }}
    onValueChange={onValueChange}
    isClearable={isClearable}
    placeholder={placeholder}
    filterOption={(locations) => locations}
    isDisabled={isDisabled}
    {...rest}
    forCargo
  />
);

CargoLocationSelect.propTypes = {
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

CargoLocationSelect.defaultProps = {
  isDisabled: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

export default CargoLocationSelect;
