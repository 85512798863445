import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, FormText, Row } from 'reactstrap';
import { Field, change, reduxForm } from 'redux-form';
import {
  isRequired,
  validateIntegerNumber,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import { ORGANIZATION_CHART_AREA_TYPES } from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';
import {
  clearOrganizationCharts,
  getOrganizationCharts,
} from '../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  optionPropTypes,
  optionsPropTypes,
} from '../../../common/forms/select/SelectPropTypes';
import MechanicSelect from '../../../common/forms/select/MechanicSelect';
import TextInput from '../../../common/forms/input/TextInput';
import { PERSONAL_PASSWORD_FOR_DISPATCH_INFO_MESSAGE } from '../../../../config/messages';

export const MechanicForm = ({
  handleSubmit,
  loading,
  dispatchGetOrganizationCharts,
  dispatchClearOrganizationCharts,
  organizationCharts,
  loadingOrganizationCharts,
  dispatchChange,
  initialValues,
}) => {
  const [showResetFields, setShowResetFields] = useState(false);

  useLayoutEffect(() => {
    dispatchGetOrganizationCharts({ ...DEFAULT_QUERY_GET_ALL });

    return () => dispatchClearOrganizationCharts();
  }, []);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState([]);

  const getOrganizationChartElements = (hierarchy, parentId, setState) => {
    const options = organizationCharts.filter(
      ({ companyAreaTypeId, parentCompanyAreaId }) =>
        +companyAreaTypeId === +ORGANIZATION_CHART_AREA_TYPES[hierarchy].id &&
        parentCompanyAreaId === parentId,
    );

    setState(options);
  };

  useLayoutEffect(() => {
    const { managementId, departmentId, areaId, sectionId } = initialValues;
    if (organizationCharts.length > 0 && managementId) {
      if (managementId)
        getOrganizationChartElements(
          'department',
          managementId.value,
          setDepartmentOptions,
        );
      if (departmentId)
        getOrganizationChartElements(
          'area',
          departmentId.value,
          setAreaOptions,
        );
      if (areaId)
        getOrganizationChartElements(
          'section',
          areaId.value,
          setSectionOptions,
        );
      if (sectionId)
        getOrganizationChartElements(
          'speciality',
          sectionId.value,
          setSpecialityOptions,
        );
    }
  }, [organizationCharts]);

  const onChangeManagement = ({ value }) => {
    dispatchChange('MechanicForm', 'departmentId', null);
    dispatchChange('MechanicForm', 'areaId', null);
    setAreaOptions([]);
    dispatchChange('MechanicForm', 'sectionId', null);
    setSectionOptions([]);
    dispatchChange('MechanicForm', 'specialityId', null);
    setSpecialityOptions([]);
    getOrganizationChartElements('department', value, setDepartmentOptions);
  };

  const onChangeDepartment = ({ value }) => {
    dispatchChange('MechanicForm', 'areaId', null);
    dispatchChange('MechanicForm', 'sectionId', null);
    setSectionOptions([]);
    dispatchChange('MechanicForm', 'specialityId', null);
    setSpecialityOptions([]);
    getOrganizationChartElements('area', value, setAreaOptions);
  };

  const onChangeArea = ({ value }) => {
    dispatchChange('MechanicForm', 'sectionId', null);
    dispatchChange('MechanicForm', 'specialityId', null);
    setSpecialityOptions([]);
    getOrganizationChartElements('section', value, setSectionOptions);
  };

  const onChangeSection = ({ value }) => {
    dispatchChange('MechanicForm', 'specialityId', null);
    getOrganizationChartElements('speciality', value, setSpecialityOptions);
  };

  const onClickCancel = () => {
    setShowResetFields(false);
    dispatchChange('MechanicForm', 'securePassword', null);
  };

  if (loading || loadingOrganizationCharts) return <Loader />;

  const managementOptions = organizationCharts.filter(
    ({ companyAreaTypeId }) =>
      +companyAreaTypeId === +ORGANIZATION_CHART_AREA_TYPES.management.id,
  );

  const passwordField = (
    <FormGroup row>
      <FormItem label="Contraseña" required>
        <Field
          name="securePassword"
          component={TextInput}
          placeholder="Contraseña"
          type="password"
          validate={[isRequired, validateIntegerNumber]}
        />
        <FormText>{PERSONAL_PASSWORD_FOR_DISPATCH_INFO_MESSAGE}</FormText>
      </FormItem>
    </FormGroup>
  );

  let newPasswordField = passwordField;

  if (initialValues.customerId) {
    if (showResetFields)
      newPasswordField = (
        <>
          {passwordField}
          <Row>
            <Col sm={3} />
            <Col sm={8}>
              <Button
                type="button"
                color="primary"
                outline
                onClick={onClickCancel}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </>
      );
    else
      newPasswordField = (
        <>
          <Button
            type="button"
            className="btn btn-danger"
            onClick={() => setShowResetFields(true)}
          >
            Restablecer
          </Button>
          <br />
          <br />
        </>
      );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <CustomerInputGroup
        label="Cliente"
        name="customerId"
        labelRequired
        validate={[isRequired]}
        form="MechanicForm"
        showDetails
      />
      <FormGroup row>
        <FormItem label="Empresa" required>
          <Field
            name="companyId"
            component={CompanySelect}
            isDisabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Gerencia" required>
          <Field
            name="managementId"
            component={Select}
            options={managementOptions}
            onChange={onChangeManagement}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Departamento">
          <Field
            name="departmentId"
            component={Select}
            options={departmentOptions}
            onChange={onChangeDepartment}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Área">
          <Field
            name="areaId"
            component={Select}
            options={areaOptions}
            onChange={onChangeArea}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Sección">
          <Field
            name="sectionId"
            component={Select}
            options={sectionOptions}
            onChange={onChangeSection}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Especialidad">
          <Field
            name="specialityId"
            component={Select}
            options={specialityOptions}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Jefe">
          <Field name="bossId" component={MechanicSelect} />
        </FormItem>
      </FormGroup>
      <h3>Contraseña</h3>
      {newPasswordField}
      <FormFooter />
    </Form>
  );
};

MechanicForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetOrganizationCharts: PropTypes.func.isRequired,
  dispatchClearOrganizationCharts: PropTypes.func.isRequired,
  organizationCharts: optionsPropTypes,
  loadingOrganizationCharts: PropTypes.bool.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    customerId: optionPropTypes,
    managementId: optionPropTypes,
    departmentId: optionPropTypes,
    areaId: optionPropTypes,
    sectionId: optionPropTypes,
    specialityId: optionPropTypes,
    bossId: optionPropTypes,
  }),
};

MechanicForm.defaultProps = {
  organizationCharts: [],
  initialValues: {},
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.Mechanic.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  organizationCharts: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, name, companyAreaTypeId, parentCompanyAreaId }) => ({
    value: id,
    label: name,
    companyAreaTypeId,
    parentCompanyAreaId,
  })),
  loadingOrganizationCharts: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetOrganizationCharts: getOrganizationCharts,
  dispatchClearOrganizationCharts: clearOrganizationCharts,
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'MechanicForm',
})(MechanicForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
