import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AGENCY_GROUP_PATH } from '../../../../config/paths';
import { postAgencyGroup } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import AgencyGroupForm from './AgencyGroupForm';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';

export class NewAgencyGroup extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      name: formValues.name,
      description: formValues.description,
      agencies: [],
    };

    if (formValues.agencyList) {
      newFormValues.agencies = formValues.agencyList.map(
        ({ agency: { id } }) => ({
          id,
        }),
      );
    }

    const { dispatchPostAgencyGroup } = this.props;

    dispatchPostAgencyGroup(newFormValues);
  };

  render() {
    const { loading } = this.props;

    const content = loading ? (
      <Loader />
    ) : (
      <AgencyGroupForm onSubmit={this.onSubmit} />
    );

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Grupo de Agencias"
        subtitle="Crear un nuevo grupo de agencias"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Agencias',
      href: AGENCY_GROUP_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  loading: !HumanResourcesUnit.AgencyGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPostAgencyGroup: postAgencyGroup,
};

NewAgencyGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostAgencyGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewAgencyGroup.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAgencyGroup);
