import React from 'react';
import { connect } from 'react-redux';
import { TICKET_MASSIVE_POSTPONE_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import TicketMassivePostponeSearch from './TicketMassivePostponeSearch';

const ItinerarySalesControl = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Postergación Masiva de Boletos"
    subtitle="Busca por fechas, embarque y desembarque"
    content={<TicketMassivePostponeSearch />}
  />
);

const mapStateToProps = ({ ReservationUnit }) => ({
  breadcrumbs: [
    ...ReservationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Postergación Masiva de Boletos',
      href: TICKET_MASSIVE_POSTPONE_PATH,
    },
  ],
});

ItinerarySalesControl.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

export default connect(mapStateToProps)(ItinerarySalesControl);
