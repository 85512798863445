import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import PrivateServiceEditButton from './PrivateServiceEditButton';
import PrivateServiceRegisterPassengerButton from './PrivateServiceRegisterPassengerButton';
import PrintContractServiceOrderButton from './PrintContractServiceOrderButton';
import ChangePrivateServiceContractStatusButton from './ChangePrivateServiceContractStatusButton';
import { CONTRACT_STATUS } from '../../../../config/constants';
import PrintTicketsButton from './PrintTicketsButton';

const PrivateServiceToolbar = ({ contract }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PrintTicketsButton
        privateServiceId={contract.id}
        disabled={
          contract.contractStatus === CONTRACT_STATUS.ACEPTED.value ||
          contract.contractStatus === CONTRACT_STATUS.IN_PROGRESS.value
        }
      />{' '}
      <PrintContractServiceOrderButton contract={contract} />{' '}
      <PrivateServiceRegisterPassengerButton privateServiceId={contract.id} />{' '}
      <ChangePrivateServiceContractStatusButton contract={contract} />{' '}
      <PrivateServiceEditButton
        privateServiceId={contract.id}
        disabled={
          contract.contractStatus === CONTRACT_STATUS.DONE.value ||
          contract.contractStatus === CONTRACT_STATUS.PAID.value
        }
      />
    </div>
  </ButtonToolbar>
);

PrivateServiceToolbar.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contractStatus: PropTypes.string.isRequired,
  }).isRequired,
};

export default PrivateServiceToolbar;
