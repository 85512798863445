import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LocationHome from './LocationHome';
import {
  LOCATION_PATH,
  NEW_LOCATION_PATH,
  COUNTRY_PATH,
  NEW_COUNTRY_PATH,
  NEW_DISTRICT_PATH,
  DISTRICT_PATH,
  GEOGRAPHIC_ZONE_PATH,
  NEW_GEOGRAPHIC_ZONE_PATH,
  LOCATION_UNIT_PATH,
  ADDRESS_PATH,
  NEW_ADDRESS_PATH,
} from '../../../config/paths';
import Locations from './location/Locations';
import NewLocation from './location/NewLocation';
import Districts from './district/Districts';
import District from './district/District';
import NewDistrict from './district/NewDistrict';
import EditDistrict from './district/EditDistrict';
import Countries from './country/Countries';
import Country from './country/Country';
import NewCountry from './country/NewCountry';
import EditCountry from './country/EditCountry';
import GeographicZones from './geographic-zone/GeographicZones';
import NewGeographicZone from './geographic-zone/NewGeographicZone';
import GeographicZone from './geographic-zone/GeographicZone';
import EditGeographicZone from './geographic-zone/EditGeographicZone';
import Location from './location/Location';
import EditLocation from './location/EditLocation';
import Addresses from './address/Addresses';
import NewAddress from './address/NewAddress';
import Address from './address/Address';
import EditAddress from './address/EditAddress';

const LocationUnit = () => (
  <Switch>
    <Route exact path={LOCATION_UNIT_PATH} component={LocationHome} />
    <Route exact path={LOCATION_PATH} component={Locations} />
    <Route exact path={NEW_LOCATION_PATH} component={NewLocation} />
    <Route exact path={`${LOCATION_PATH}/:id`} component={Location} />
    <Route exact path={`${LOCATION_PATH}/:id/edit`} component={EditLocation} />
    <Route exact path={DISTRICT_PATH} component={Districts} />
    <Route exact path={NEW_DISTRICT_PATH} component={NewDistrict} />
    <Route exact path={`${DISTRICT_PATH}/:id`} component={District} />
    <Route exact path={`${DISTRICT_PATH}/:id/edit`} component={EditDistrict} />
    <Route exact path={COUNTRY_PATH} component={Countries} />
    <Route exact path={NEW_COUNTRY_PATH} component={NewCountry} />
    <Route exact path={`${COUNTRY_PATH}/:id`} component={Country} />
    <Route exact path={`${COUNTRY_PATH}/:id/edit`} component={EditCountry} />
    <Route exact path={GEOGRAPHIC_ZONE_PATH} component={GeographicZones} />
    <Route
      exact
      path={NEW_GEOGRAPHIC_ZONE_PATH}
      component={NewGeographicZone}
    />
    <Route
      exact
      path={`${GEOGRAPHIC_ZONE_PATH}/:id`}
      component={GeographicZone}
    />
    <Route
      exact
      path={`${GEOGRAPHIC_ZONE_PATH}/:id/edit`}
      component={EditGeographicZone}
    />
    <Route exact path={NEW_ADDRESS_PATH} component={NewAddress} />
    <Route exact path={ADDRESS_PATH} component={Addresses} />
    <Route exact path={`${ADDRESS_PATH}/:id`} component={Address} />
    <Route exact path={`${ADDRESS_PATH}/:id/edit`} component={EditAddress} />
  </Switch>
);

export default LocationUnit;
