import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import TableToolbar from '../../../common/TableToolbar';
import {
  NEW_PRICING_PROFILE_REPORT_PATH,
  PRICING_PROFILE_REPORTS_PATH,
} from '../../../../config/paths';
import Table from '../../../common/Table';
import {
  clearPricingProfileReports,
  getPricingProfileReports,
  clearErrorGettingPricingProfileReports,
} from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import { PRICING_PROFILE_REPORT_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

class PricingProfileReports extends Component {
  constructor(props) {
    super(props);
    this.columns = PRICING_PROFILE_REPORT_COLUMNS;
  }

  componentWillUnmount() {
    this.props.clearErrorGettingPricingProfileReports();
    this.props.clearPricingProfileReports();
  }

  renderContent() {
    const { reports, loading, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    const { columns } = this;

    if (reports) {
      data = reports.get('content');
      pages = reports.get('totalPages');
      defaultPageSize = reports.get('size');
    }

    return (
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={this.props.getPricingProfileReports}
        modelPath={PRICING_PROFILE_REPORTS_PATH}
        loading={loading}
        navigateToModelOnRowClick
      />
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <TableToolbar buttonPath={NEW_PRICING_PROFILE_REPORT_PATH} />
        <h1>Reportes de Perfiles de Precios</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles de Precios',
      href: PRICING_PROFILE_REPORTS_PATH,
    },
  ],
  reports: ReportUnit.PricingProfileReport.get('all').get('content'),
  loading: ReportUnit.PricingProfileReport.get('all').get('loading'),
  error: ReportUnit.PricingProfileReport.get('all').get('error'),
});

const mapDispatchToProps = {
  getPricingProfileReports,
  clearPricingProfileReports,
  clearErrorGettingPricingProfileReports,
};

PricingProfileReports.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  getPricingProfileReports: PropTypes.func.isRequired,
  clearPricingProfileReports: PropTypes.func.isRequired,
  clearErrorGettingPricingProfileReports: PropTypes.func.isRequired,
  reports: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

PricingProfileReports.defaultProps = {
  reports: null,
  loading: false,
  error: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricingProfileReports);
