import React, { Component } from 'react';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { getPurchaseOrder, putPurchaseOrder } from './config/fetch';
import ContainerComponent from '../../../common/resource/ContainerComponent';
import PurchaseOrderForm from './PurchaseOrderForm';
import { PURCHASE_ORDERS_BREADCRUMBS } from '../../../../config/breadcrumbs';
import { generatePurchaseOrderViewPath } from '../../../../config/paths';
import Loader from '../../../common/Loader';
import DeletePurchaseOrderButton from './DeletePurchaseOrderButton';
import { PURCHASE_ORDER_STATUS } from '../../../../config/constants';
import Content from '../../../layout/Content';

class EditPurchaseOrder extends Component {
  state = {
    loading: false,
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  createPurchaseOrderListInitialValues = () => {
    const {
      payload: { purchaseOrderDetailList },
    } = this.props;
    return purchaseOrderDetailList.map(({ fuelVoucherId, fuelVoucher }) => ({
      fuelVoucher: {
        value: fuelVoucherId,
        label: `${fuelVoucher.documentSeries}-${fuelVoucher.documentCode} - ${fuelVoucher.circuitName}`,
        resource: {
          numberOfGallons: fuelVoucher.numberOfGallons,
          fuelStationBusinessName: fuelVoucher.fuelStationBusinessName,
          companyBusId: fuelVoucher.companyBusId,
        },
      },
      realNumberOfGallons: fuelVoucher.realNumberOfGallons,
    }));
  };

  createPayload = ({ formValues, fuelVoucherGridValues, totalAmount }) => {
    const {
      payload: { id, status, userId },
    } = this.props;

    return {
      id,
      documentSeries: formValues.documentSeries,
      documentCode: formValues.documentCode,
      comments: formValues.comments,
      totalAmount,
      agencyId: formValues.agency.value,
      purchaseOrderDetailList: fuelVoucherGridValues.map(
        ({ fuelVoucher, realNumberOfGallons }) => ({
          fuelVoucherId: fuelVoucher.value,
          fuelVoucher: {
            realNumberOfGallons: new Decimal(realNumberOfGallons).toNumber(),
            pricePerGallon: new Decimal(formValues.pricePerGalon).toNumber(),
          },
        }),
      ),
      status,
      userId,
    };
  };

  render() {
    const {
      payload: {
        id,
        documentSeries,
        documentCode,
        totalAmount,
        agency,
        comments,
        purchaseOrderDetailList,
        status,
      },
    } = this.props;

    const { loading } = this.state;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else {
      toolbar = (
        <ButtonToolbar className="pull-right">
          <div>
            <DeletePurchaseOrderButton purchaseOrderId={id} />
          </div>
        </ButtonToolbar>
      );

      if (status !== PURCHASE_ORDER_STATUS.CREATED.value) toolbar = null;

      content = (
        <PurchaseOrderForm
          initialValues={{
            documentSeries,
            documentCode,
            totalAmount,
            agency: {
              label: agency.name,
              value: agency.id,
            },
            comments,
            // currently we are posting only one pricePerGallon
            // but internally it's being stored inside each purchaseOrderDetail
            // thus the need of this [0] hack
            pricePerGalon: purchaseOrderDetailList.length
              ? purchaseOrderDetailList[0].fuelVoucher.pricePerGallon
              : 0,
            purchaseOrderDetailList:
              this.createPurchaseOrderListInitialValues(),
            status,
          }}
          createPayload={this.createPayload}
          fetchFunction={putPurchaseOrder}
          setLoading={this.setLoading}
        />
      );
    }

    return (
      <Content
        breadcrumbs={[
          ...PURCHASE_ORDERS_BREADCRUMBS,
          {
            text: 'Ver',
            href: generatePurchaseOrderViewPath(id),
          },
          {
            text: 'Edición',
            href: '',
          },
        ]}
        title="Editar Orden de Compra"
        subtitle="Editar una orden de compra"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

EditPurchaseOrder.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.number.isRequired,
    documentSeries: PropTypes.string.isRequired,
    documentCode: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    agency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    comments: PropTypes.string.isRequired,
    purchaseOrderDetailList: PropTypes.arrayOf(
      PropTypes.shape({
        fuelVoucher: PropTypes.shape({
          pricePerGallon: PropTypes.number.isRequired,
        }),
      }),
    ),
    status: PropTypes.string,
    userId: PropTypes.number,
  }).isRequired,
};

export default ContainerComponent({ getFunction: getPurchaseOrder })(
  EditPurchaseOrder,
);
