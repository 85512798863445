import { string, number } from 'prop-types';

const viewBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string,
  route: string.isRequired,
  lastUpdate: number,
  createDate: number,
};

const viewBasicInformationDefaultProps = {
  description: null,
  lastUpdate: null,
  createDate: null,
};

export { viewBasicInformationPropTypes, viewBasicInformationDefaultProps };
