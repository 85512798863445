import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import Card from '../layout/Card';
import Breadcrumbs from '../layout/Breadcrumbs';
import { breadcrumbsPropTypes } from '../common/resource/proptypes/CommonPropTypes';

const ModuleHome = ({ title, text, cards, breadcrumbs }) => (
  <Container fluid>
    <Breadcrumbs breadcrumbs={breadcrumbs} />
    <Row>
      <Col>
        <h1 id="title">{title}</h1>
        <p id="text">{text}</p>
      </Col>
    </Row>
    <div className="row text-xs-center">
      {cards.map((card) => (
        <Card key={card.id} {...card} />
      ))}
    </div>
  </Container>
);

ModuleHome.defaultProps = {
  text: '',
  title: '',
};

ModuleHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default ModuleHome;
