import React from 'react';
import PropTypes from 'prop-types';

const ConfirmButtonCell = ({ handleConfirm, data, id, showConfirmButton }) =>
  showConfirmButton && (
    <>
      <i
        tabIndex="0"
        role="button"
        className="fa fa-plus-circle text-success"
        onKeyPress={() => handleConfirm(data, id)}
        onClick={() => handleConfirm(data, id)}
        id={`btnConfirm${id}`}
      />
    </>
  );

ConfirmButtonCell.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  id: PropTypes.number.isRequired,
  showConfirmButton: PropTypes.bool,
};

ConfirmButtonCell.defaultProps = {
  showConfirmButton: true,
};

export default ConfirmButtonCell;
