import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import UserLocaleSelect from '../../../common/forms/select/UserLocaleSelect';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import { PASSWORD_CHANGE_INFO_MESSAGE } from '../../../../config/messages';
import InformationText from '../../../common/informative/InformationText';
import UserSecurityProfileSelect from '../../../common/forms/select/UserSecurityProfileSelect';
import FormFooter from '../../../common/forms/FormFooter';

const validatePassword = (confirmPassword, { newPassword }) => {
  if (!confirmPassword) return undefined;

  return confirmPassword !== newPassword
    ? 'Las contraseñas no coinciden'
    : undefined;
};

const UserForm = ({
  loading,
  handleSubmit,
  initialValues,
  self,
  dispatchChange,
}) => {
  const [showResetFields, setShowResetFields] = useState(false);

  const onClickCancel = () => {
    setShowResetFields(false);

    dispatchChange('UserForm', 'authorizingPassword', null);
    dispatchChange('UserForm', 'newPassword', null);
    dispatchChange('UserForm', 'confirmNewPassword', null);
  };

  if (loading) return <Loader />;

  let basicInformationFields = null;
  let activityFields = null;

  const passwordFields = (
    <Fragment>
      <FormGroup row>
        <FormItem label="Nueva contraseña" required>
          <Field
            name="newPassword"
            component={TextInput}
            type="password"
            placeholder="Nueva contraseña"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Confirmar nueva contraseña" required>
          <Field
            name="confirmNewPassword"
            component={TextInput}
            type="password"
            placeholder="Confirmar nueva contraseña"
            validate={[isRequired, validatePassword]}
          />
        </FormItem>
      </FormGroup>
    </Fragment>
  );

  let newPasswordFields = passwordFields;

  if (initialValues.username) {
    if (showResetFields) {
      newPasswordFields = (
        <Fragment>
          <FormGroup row>
            <FormItem label="Contraseña de Autorización" required>
              <Field
                name="authorizingPassword"
                component={TextInput}
                type="password"
                placeholder="Contraseña de Autorización"
                validate={[isRequired]}
              />
              <InformationText text={PASSWORD_CHANGE_INFO_MESSAGE} />
            </FormItem>
          </FormGroup>
          {passwordFields}
          <Row>
            <Col sm={3} />
            <Col sm={8}>
              <Button
                type="button"
                color="primary"
                outline
                onClick={onClickCancel}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Fragment>
      );
    } else {
      newPasswordFields = (
        <Fragment>
          <Button
            type="button"
            className="btn btn-danger"
            onClick={() => setShowResetFields(true)}
          >
            Restablecer
          </Button>
          <br />
          <br />
        </Fragment>
      );
    }
  }

  if (!self) {
    basicInformationFields = (
      <Fragment>
        <h3>Información Básica</h3>
        <FormGroup row>
          <FormItem label="Usuario (Email)" required>
            <Field
              name="username"
              component={TextInput}
              type="text"
              placeholder="Usuario (Email)"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Idioma" required>
            <Field
              name="locale"
              component={UserLocaleSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <CustomerInputGroup
          label="Cliente"
          name="customerId"
          labelRequired
          validate={[isRequired]}
          form="UserForm"
          showDetails
        />
        <FormGroup row>
          <FormItem label="Perfiles de Seguridad" required>
            <Field
              name="securityProfileSet"
              component={UserSecurityProfileSelect}
              showAuthorizedOnly
              isMulti
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      </Fragment>
    );

    activityFields = (
      <Fragment>
        <h3>Actividad</h3>
        <FormGroup row>
          <FormItem label="Estado">
            <Label>
              <Field name="enabled" component="input" type="checkbox" /> Activo
            </Label>
            <br />
            <Label>
              <Field name="verified" component="input" type="checkbox" />{' '}
              Verificado
            </Label>
            <br />
            <Label>
              <Field name="blocked" component="input" type="checkbox" />{' '}
              Bloqueado
            </Label>
          </FormItem>
        </FormGroup>
      </Fragment>
    );
  } else {
    newPasswordFields = (
      <Fragment>
        <FormGroup row>
          <FormItem label="Contraseña Actual" required>
            <Field
              name="currentPassword"
              component={TextInput}
              type="password"
              placeholder="Contraseña Actual"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {passwordFields}
      </Fragment>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      {basicInformationFields}
      <h3>Contraseña</h3>
      {newPasswordFields}
      {activityFields}
      <FormFooter />
    </Form>
  );
};

UserForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    username: PropTypes.string,
  }),
  dispatchChange: PropTypes.func.isRequired,
  self: PropTypes.bool,
};

UserForm.defaultProps = {
  initialValues: {},
  self: false,
};

const mapStateToProps = ({ UserUnit }) => ({
  loading: !UserUnit.User.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = { dispatchChange: change };

const formComponent = reduxForm({
  form: 'UserForm',
})(UserForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
