import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../../common/Loader';
import { fuelConsumptionReportPerDriverPropTypes } from './FuelConsumptionReportPerDriverPropTypes';
import DriverFuelReportHeaderResource from './DriverFuelReportHeaderResource';
import DriverFuelReportCircuitRowListResource from './DriverFuelReportCircuitRowListResource';
import DriverFuelReportRouteRowListResource from './DriverFuelReportRouteRowListResource';

import '../fuel-consumption-report-per-bus/BusFuelReportHeaderResource.css';

const FuelConsumptionReportePerDriverSearchResult = ({
  loading,
  fuelReportPerDriver,
  showDriverHeaderSummary,
}) => {
  if (loading) {
    return <Loader />;
  }

  const reportHeader = fuelReportPerDriver.get('reportHeader');
  const driverFuelReportCircuitRowList = fuelReportPerDriver.get(
    'driverFuelReportCircuitRowList',
  );
  const driverFuelReportRouteRowList = fuelReportPerDriver.get(
    'driverFuelReportRouteRowList',
  );

  return (
    <div className="mt-4">
      <DriverFuelReportHeaderResource
        data={reportHeader}
        showDriverHeaderSummary={showDriverHeaderSummary}
      />
      <DriverFuelReportCircuitRowListResource
        data={driverFuelReportCircuitRowList}
      />
      <DriverFuelReportRouteRowListResource
        data={driverFuelReportRouteRowList}
      />
    </div>
  );
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.FuelConsumptionReportPerDriver.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  fuelReportPerDriver: TrafficUnit.FuelConsumptionReportPerDriver.getIn([
    'current',
    'content',
  ]),
});

FuelConsumptionReportePerDriverSearchResult.propTypes = {
  loading: PropTypes.bool,
  showDriverHeaderSummary: PropTypes.bool.isRequired,
  fuelReportPerDriver: fuelConsumptionReportPerDriverPropTypes,
};

FuelConsumptionReportePerDriverSearchResult.defaultProps = {
  loading: false,
  fuelReportPerDriver: null,
};

export default connect(mapStateToProps)(
  FuelConsumptionReportePerDriverSearchResult,
);
