import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { GENERAL_INCOME_TYPE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import {
  FLAG_GETTING_GENERAL_INCOME_TYPES,
  GET_GENERAL_INCOME_TYPES,
  CLEAR_GENERAL_INCOME_TYPES,
} from '../types/accounting/GeneralIncomeType';

const flagGettingGeneralIncomeTypes = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_GENERAL_INCOME_TYPES,
    payload: flag,
  });

const getGeneralIncomeTypes = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingGeneralIncomeTypes(true));

    const query = tableFilters;

    const url = `${GENERAL_INCOME_TYPE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response);

    const types = await response.json();

    dispatch({ type: GET_GENERAL_INCOME_TYPES, payload: types });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingGeneralIncomeTypes(false));
  }
};

const clearGeneralIncomeTypes = () => dispatch =>
  dispatch({
    type: CLEAR_GENERAL_INCOME_TYPES,
  });

export {
  flagGettingGeneralIncomeTypes,
  getGeneralIncomeTypes,
  clearGeneralIncomeTypes,
};
