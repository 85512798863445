import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import { AGENCY_GROUP_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import FormFooter from '../../../common/forms/FormFooter';

const AgencyGroupForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Nombre" required>
        <Field
          name="name"
          component={TextInput}
          placeholder="Nombre"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Descripción">
        <Field
          name="description"
          component={TextInput}
          type="textarea"
          placeholder="Descripción"
        />
      </FormItem>
    </FormGroup>
    <h3>Agencias</h3>
    <DynamicForm name="agencyList" columns={AGENCY_GROUP_FORM_COLUMNS} />
    <FormFooter />
  </Form>
);

AgencyGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'AgencyGroupForm',
})(AgencyGroupForm);

export default formComponent;
