import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  postPricingProfileReport,
  clearErrorPostingPricingProfileReport,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import PricingProfileReportForm from './PricingProfileReportForm';
import {
  NEW_PRICING_PROFILE_REPORT_PATH,
  PRICING_PROFILE_REPORTS_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';

class NewPricingProfileReport extends Component {
  componentWillUnmount() {
    const { dispatchClearErrorPostingPricingProfileReport } = this.props;
    dispatchClearErrorPostingPricingProfileReport();
  }

  handleSubmit = (formValues) => {
    const { dispatchPostPricingProfileReport } = this.props;
    const payload = {
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
      force: formValues.force,
    };
    dispatchPostPricingProfileReport(payload);
  };

  render() {
    const { loading } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte de Perfiles de Precios</h1>
            <p id="text">Crear un nuevo reporte de perfiles de precios.</p>
          </Col>
        </Row>
        <PricingProfileReportForm
          onSubmit={this.handleSubmit}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            force: false,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles de Precios',
      href: PRICING_PROFILE_REPORTS_PATH,
    },
    {
      text: 'Crear',
      href: NEW_PRICING_PROFILE_REPORT_PATH,
    },
  ],
  loading: ReportUnit.PricingProfileReport.getIn(['form', 'loading']),
});

const mapDispatchToProps = {
  dispatchPostPricingProfileReport: postPricingProfileReport,
  dispatchClearErrorPostingPricingProfileReport:
    clearErrorPostingPricingProfileReport,
};

NewPricingProfileReport.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPricingProfileReport: PropTypes.func.isRequired,
  dispatchClearErrorPostingPricingProfileReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPricingProfileReport);
