import Immutable from 'immutable';
import {
  HOME_PATH,
  LOCATION_PATH,
  COUNTRY_PATH,
  DISTRICT_PATH,
  GEOGRAPHIC_ZONE_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'locations',
      title: 'Ubicaciones',
      path: LOCATION_PATH,
      icon: 'building',
    },
    {
      key: 'addresses',
      title: 'Direcciones',
      path: '/location/address',
      icon: 'map-marker',
    },
    {
      key: 'district',
      title: 'Distritos',
      path: DISTRICT_PATH,
      icon: 'institution',
    },
    {
      key: 'cities',
      title: 'Ciudades',
      path: '/location/city',
      icon: 'dot-circle-o',
      disabled: true,
    },
    {
      key: 'regions',
      title: 'Departamentos',
      path: '/location/region',
      icon: 'map',
      disabled: true,
    },
    {
      key: 'countries',
      title: 'Países',
      path: COUNTRY_PATH,
      icon: 'globe',
    },
    {
      key: 'geographic-zone',
      title: 'Zona Geográfica',
      path: GEOGRAPHIC_ZONE_PATH,
      icon: 'location-arrow',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Ubicaciones',
      tag: 'span',
      href: '/location',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
