import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createBrowserHistory } from 'history';
import ReactGa from 'react-ga';
import { routerMiddleware } from 'react-router-redux';
import ReduxThunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import reducers from '../reducers';
import { flagAuthenticatingUser } from '../actions';

export const history = createBrowserHistory();

/**
 * Configures the store and returns it along with the history for the router
 * @param state
 * @returns {{store: Store<any>, history}}
 */
const configureStore = (state) => {
  // Apply history to the middleware
  history.listen((location) => {
    ReactGa.set({ page: location.pathname });
    ReactGa.pageview(location.pathname);
  });

  // Create the store
  const store = createStore(
    reducers,
    state,
    composeWithDevTools(
      applyMiddleware(ReduxThunk, ReduxPromise, routerMiddleware(history)),
    ),
  );

  // TODO: update with a better action to signal that app is loading
  store.dispatch(flagAuthenticatingUser(true));

  return store;
};

export default configureStore;
