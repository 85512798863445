import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DETRACTION_PATH } from '../../../../config/paths';
import {
  clearDetraction,
  getDetraction,
  putDetraction,
} from '../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import DetractionForm from './DetractionForm';

const EditDetraction = ({
  breadcrumbs,
  dispatchGetDetraction,
  dispatchClearDetraction,
  dispatchPutDetraction,
  match: {
    params: { id: detractionId },
  },
  loading,
  detraction,
}) => {
  useLayoutEffect(() => {
    dispatchGetDetraction({ detractionId });

    return () => dispatchClearDetraction();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutDetraction(detractionId, {
      ...formValues,
      countryCode: formValues.countryId.value,
    });

  const generateInitialValues = ({
    detractionRate,
    minDetractionAmount,
    country,
    detractionCode,
    bankAccountNumber,
  }) => ({
    detractionRate,
    minDetractionAmount,
    countryId: {
      value: country.countryCode,
      label: country.name,
    },
    detractionCode,
    bankAccountNumber,
  });

  let content;

  if (loading) content = <Loader />;
  else if (detraction.isEmpty())
    content = <NoDataResource returnPage={DETRACTION_PATH} />;
  else
    content = (
      <DetractionForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(detraction.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Detracción"
      subtitle="Editar detracción"
      content={content}
    />
  );
};

EditDetraction.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearDetraction: PropTypes.func.isRequired,
  dispatchGetDetraction: PropTypes.func.isRequired,
  dispatchPutDetraction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  detraction: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDetraction.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearDetraction: clearDetraction,
  dispatchGetDetraction: getDetraction,
  dispatchPutDetraction: putDetraction,
};

const mapStateToProps = (
  { AccountingUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Detracciones',
      href: DETRACTION_PATH,
    },
    {
      text: 'Ver',
      href: `${DETRACTION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  detraction: AccountingUnit.Detraction.getIn(['current', 'content']),
  loading: !AccountingUnit.Detraction.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDetraction);
