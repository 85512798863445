import React, { useState } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Modal from '../../../../common/modal/Modal';
import AddCargoToItineraryByParcelSearch from './AddCargoToItineraryByParcelSearch';

const AddCargoToItineraryByParcelButton = ({
  disabled,
  onClickAddItemToItinerary,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const newModalBody = (
    <AddCargoToItineraryByParcelSearch
      onClickAddItemToItinerary={onClickAddItemToItinerary}
    />
  );

  return (
    <>
      <Modal
        show={showModal}
        title="Buscar Encomiendas por Comprobante"
        body={newModalBody}
        size="lg"
        onClickClose={closeModal}
        onClickCancel={closeModal}
      />
      <Button color="info" onClick={openModal} disabled={disabled}>
        <i className="fa fa-search" /> Buscar por Comprobante
      </Button>
    </>
  );
};

AddCargoToItineraryByParcelButton.propTypes = {
  disabled: PropTypes.bool,
  onClickAddItemToItinerary: PropTypes.func.isRequired,
};

AddCargoToItineraryByParcelButton.defaultProps = {
  disabled: false,
};

export default AddCargoToItineraryByParcelButton;
