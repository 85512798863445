import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getCountries, clearCountries } from '../../../../actions/location';
import { COUNTRY_PATH, NEW_COUNTRY_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { COUNTRIES_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Countries extends Component {
  constructor(props) {
    super(props);
    this.columns = COUNTRIES_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearCountries();
  }

  render() {
    const { countries, loading } = this.props;
    const data = countries.get('content') || [];
    const pages = countries.get('totalPages') || null;
    const defaultPageSize = countries.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Países"
        buttonPath={NEW_COUNTRY_PATH}
        breadcrumbs={this.props.breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: this.props.dispatchGetCountries,
          modelPath: COUNTRY_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetCountries: getCountries,
  dispatchClearCountries: clearCountries,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Países',
      href: COUNTRY_PATH,
    },
  ],
  countries: LocationUnit.Country.getIn(['all', 'content']),
  loading: LocationUnit.Country.getIn(['all', 'loading']),
});

Countries.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  countries: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCountries: PropTypes.func.isRequired,
  dispatchClearCountries: PropTypes.func.isRequired,
};

Countries.defaultProps = {
  countries: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
