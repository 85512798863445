import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getCompanyUserConfiguration,
  clearCompanyUserConfiguration,
} from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import CompanyUserConfigurationToolbar from './CompanyUserConfigurationToolbar';
import { COMPANY_USER_CONFIGURATION_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';

class CompanyUserConfiguration extends Component {
  componentDidMount() {
    const {
      dispatchGetCompanyUserConfiguration,
      match: {
        params: { id: companyUserConfigurationId },
      },
    } = this.props;
    dispatchGetCompanyUserConfiguration({ companyUserConfigurationId });
  }

  componentWillUnmount() {
    const { dispatchClearCompanyUserConfiguration } = this.props;
    dispatchClearCompanyUserConfiguration();
  }

  render() {
    const { breadcrumbs, companyUserConfiguration, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (companyUserConfiguration.isEmpty()) {
      content = <NoDataResource returnPage={COMPANY_USER_CONFIGURATION_PATH} />;
    } else {
      content = (
        <Fragment>
          <ResourceProperty label="Usuario:">
            {companyUserConfiguration.get('user').customer.fullName}
          </ResourceProperty>
          <ResourceProperty label="Usuario Padre:">
            {companyUserConfiguration.get('parentUser').customer.fullName}
          </ResourceProperty>
          <ResourceProperty label="Empresa Tercera:">
            {companyUserConfiguration.get('company').name}
          </ResourceProperty>
        </Fragment>
      );

      toolbar = (
        <CompanyUserConfigurationToolbar
          companyUserConfigurationId={companyUserConfiguration.get('id')}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Configuración de Usuario"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

CompanyUserConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCompanyUserConfiguration: PropTypes.func.isRequired,
  dispatchClearCompanyUserConfiguration: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  companyUserConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
};

CompanyUserConfiguration.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuraciones de Usuario',
      href: COMPANY_USER_CONFIGURATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  companyUserConfiguration: ContractUnit.CompanyUserConfiguration.getIn([
    'current',
    'content',
  ]),
  loading: !ContractUnit.CompanyUserConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCompanyUserConfiguration: getCompanyUserConfiguration,
  dispatchClearCompanyUserConfiguration: clearCompanyUserConfiguration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyUserConfiguration);
