import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditExpenseButton from './EditExpenseButton';

const ExpenseToolbar = ({ expenseId, isTripExpense }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditExpenseButton expenseId={expenseId} isTripExpense={isTripExpense} />{' '}
    </div>
  </ButtonToolbar>
);

ExpenseToolbar.propTypes = {
  expenseId: PropTypes.number.isRequired,
  isTripExpense: PropTypes.bool.isRequired,
};

export default ExpenseToolbar;
