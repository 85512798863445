import { toastr } from 'react-redux-toastr';
import { generateEnableDisableFuelProfileEndpoint } from '../../../../../config/endpoints';
import Fetch from '../../../../../utils/Fetch';

const patchStateFuelProfile = async ({ fuelProfileId, active }) => {
  try {
    const payload = {
      id: fuelProfileId,
      active,
    };
    const fuelProfile = await Fetch.patch({
      url: generateEnableDisableFuelProfileEndpoint(fuelProfileId),
      payload,
    });
    return fuelProfile;
  } catch (error) {
    toastr.error('Error',error.message);
    return null;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { patchStateFuelProfile };
