import { string, number, shape } from 'prop-types';
import { organizationChartBasicInformationPropTypes } from '../../organization-chart/proptypes/OrganizationChartPropTypes';
import { registeredBusBasicInformationPropTypes } from '../../../bus/registered-bus/proptypes/RegisteredBusPropTypes';
import { customerBasicInformationPropTypes } from '../../../user/customer/proptypes/CustomerPropTypes';

const maintenanceWarningBasicInformationPropTypes = {
  issueDate: number,
  registeredBusId: number,
  registeredBus: shape(registeredBusBasicInformationPropTypes),
  category: shape(organizationChartBasicInformationPropTypes),
  subcategory: shape(organizationChartBasicInformationPropTypes),
  maintenanceFailModeId: number,
  maintenanceFailMode: shape({
    description: string,
  }),
  maintenanceWarningType: string,
  priority: string,
  comments: string,
  reporterCustomerId: number,
  reporterCustomer: shape(customerBasicInformationPropTypes),
  status: string,
  serviceOrderId: number,
  recurrence: number,
  actualVirtualOdometer: number,
  actualRegisteredBusOdometer: number,
};

const maintenanceWarningBasicDefaultProps = {
  comments: null,
  serviceOrderId: null,
  recurrence: null,
};

const maintenanceWarningInternalInformationPropTypes = {
  id: number.isRequired,
  createDate: number.isRequired,
  lastUpdate: number,
};

const maintenanceWarningInternalDefaultProps = {
  lastUpdate: null,
};

export {
  maintenanceWarningBasicInformationPropTypes,
  maintenanceWarningBasicDefaultProps,
  maintenanceWarningInternalInformationPropTypes,
  maintenanceWarningInternalDefaultProps,
};
