import { toastr } from 'react-redux-toastr';
import { PRINT_TEMPLATE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  FLAG_GETTING_PRINT_TEMPLATE_TYPES,
  GET_PRINT_TEMPLATE_TYPES,
  CLEAR_PRINT_TEMPLATE_TYPES,
} from '../types/human-resources/PrintTemplateType';

const flagGettingPrintTemplateTypes = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_PRINT_TEMPLATE_TYPES,
    payload: flag,
  });

const getPrintTemplateTypes = async () => async dispatch => {
  try {
    dispatch(flagGettingPrintTemplateTypes(true));

    const url = PRINT_TEMPLATE_ENDPOINT;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await isErrorResponse(response);

    const templates = await response.json();

    dispatch({
      type: GET_PRINT_TEMPLATE_TYPES,
      payload: templates,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingPrintTemplateTypes(false));
  }
};

const clearPrintTemplateTypes = () => dispatch =>
  dispatch({
    type: CLEAR_PRINT_TEMPLATE_TYPES,
  });

export {
  flagGettingPrintTemplateTypes,
  getPrintTemplateTypes,
  clearPrintTemplateTypes,
};
