import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../Badge';

const TicketReportAgenciesCell = ({ agencies }) => {
  let agencyBadges = <Badge key="all-agencies" color="primary" text="Todas" />;
  if (agencies && agencies.length)
    agencyBadges = agencies.map((agency) => (
      <Badge key={agency.id} color="primary" text={agency.name} />
    ));

  return (
    <div className="d-flex flex-column justify-content-between align-items-center">
      {agencyBadges}
    </div>
  );
};

TicketReportAgenciesCell.propTypes = {
  agencies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default TicketReportAgenciesCell;
