import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import {
  getPricingProfileReport,
  clearPricingProfileReport,
  clearErrorGettingPricingProfileReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Table from '../../../common/Table';
import ReportToolbar from '../ReportToolbar';
import Loader from '../../../common/Loader';
import DateCell from '../../../common/cells/DateCell';
import Alert from '../../../common/informative/Alert';
import { PRICING_PROFILE_REPORTS_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';

class PricingProfileReport extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Fecha Inicio',
        accessor: 'startDate',
        Cell: (row) => <DateCell value={row.value} />,
      },
      {
        Header: 'Fecha Final',
        accessor: 'endDate',
        Cell: (row) => <DateCell value={row.value} />,
      },
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Precio Base',
        accessor: 'basePrice',
      },
      {
        Header: 'Nombre del Grupo de Agencia',
        accessor: 'agencyGroupName',
      },
      {
        Header: 'Nombre de Agencia',
        accessor: 'agencyName',
      },
      {
        Header: 'Maciva',
        accessor: 'isMaciva',
      },
      {
        Header: 'Zona',
        accessor: 'zone',
      },
      {
        Header: 'Piso Zona',
        accessor: 'zoneFloorNumber',
      },
      {
        Header: 'Precio Zona',
        accessor: 'priceZone',
      },
      {
        Header: 'Lunes',
        accessor: 'monday',
      },
      {
        Header: 'Martes',
        accessor: 'tuesday',
      },
      {
        Header: 'Miércoles',
        accessor: 'wednesday',
      },
      {
        Header: 'Jueves',
        accessor: 'thursday',
      },
      {
        Header: 'Viernes',
        accessor: 'friday',
      },
      {
        Header: 'Sábado',
        accessor: 'saturday',
      },
      {
        Header: 'Domingo',
        accessor: 'sunday',
      },
    ];
    this.download = this.download.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
  }

  componentWillMount() {
    // extract id from params injected by react router
    const { id } = this.props.match.params;
    this.props.getPricingProfileReport({ id });
  }

  componentWillUnmount() {
    this.props.clearPricingProfileReport();
    this.props.clearErrorGettingPricingProfileReport();
  }

  handleOnFetchData(state) {
    // get id of report
    const { id } = this.props.match.params;
    this.props.getPricingProfileReport({ ...state, id });
  }

  download() {
    const { report } = this.props;
    const { id } = this.props.match.params;
    if (report) {
      return this.props.getPricingProfileReport({
        id,
        reportFormat: 'csv',
        download: true,
      });
    }
    return false;
  }

  renderContent() {
    // check if report is loaded
    const { loading, report, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    // check if report is loading or empty
    if (loading || !report || report.isEmpty()) {
      return <Loader />;
    }

    // check if report is complete
    const reportStatus = report.get('reportStatus');
    if (reportStatus === 'GENERATING') {
      return <Loader />;
    }

    const pricingProfileReportRows = report.get('pricingProfileReportRows');
    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    if (pricingProfileReportRows) {
      data = pricingProfileReportRows.content;
      pages = pricingProfileReportRows.totalPages;
      defaultPageSize = pricingProfileReportRows.size;
    }

    const { columns } = this;
    return (
      <div>
        <ReportToolbar download={this.download} disabled={!data.length} />
        <Table
          columns={columns}
          data={data}
          defaultPageSize={defaultPageSize}
          pages={pages}
          loading={loading}
        />
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <h1>Reporte de Perfiles de Precios</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles de Precios',
      href: PRICING_PROFILE_REPORTS_PATH,
    },
    {
      text: 'Ver',
      href: state.routing.location.pathname,
    },
  ],
  error: state.ReportUnit.PricingProfileReport.get('current').get('error'),
  report: state.ReportUnit.PricingProfileReport.get('current').get('content'),
  loading: state.ReportUnit.PricingProfileReport.get('current').get('loading'),
});

const mapDispatchToProps = {
  getPricingProfileReport,
  clearPricingProfileReport,
  clearErrorGettingPricingProfileReport,
};

PricingProfileReport.propTypes = {
  getPricingProfileReport: PropTypes.func.isRequired,
  clearPricingProfileReport: PropTypes.func.isRequired,
  clearErrorGettingPricingProfileReport: PropTypes.func.isRequired,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  report: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
  match: matchPropTypes.isRequired,
};

PricingProfileReport.defaultProps = {
  report: null,
  loading: false,
  error: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricingProfileReport);
