import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import FuelConsumptionReportPerDriverSearchForm from './FuelConsumptionReportPerDriverSearchForm';
import { FUEL_CONSUMPTION_REPORT_PER_DRIVER_PATH } from '../../../../config/paths';
import {
  clearFuelConsumptionReportPerDriver,
  postFuelConsumptionReportPerDriver,
} from '../../../../actions';
import FuelConsumptionReportPerDriverSearchResult from './FuelConsumptionReportPerDriverSearchResult';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';

export class FuelConsumptionReportPerDriver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDriverHeaderSummary: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatchClearFuelConsumptionReportPerDriver();
  }

  onSubmit = (formValues) => {
    this.setState({ showDriverHeaderSummary: false });

    const newFormValues = {
      fromDate: tzNormalizeDate({
        date: formValues.fromDate,
        format: TIMESTAMP_FORMAT,
      }),
      toDate: tzNormalizeDate({
        date: formValues.toDate,
        format: TIMESTAMP_FORMAT,
      }),
    };

    if (formValues.driverIdSet && formValues.driverIdSet.length) {
      const driverIdSet = formValues.driverIdSet.map(({ value }) => value);
      newFormValues.driverIdSet = driverIdSet;

      if (formValues.driverIdSet.length === 1)
        this.setState({ showDriverHeaderSummary: true });
    }

    this.props.dispatchPostFuelConsumptionReportPerDriver(newFormValues);
  };

  render() {
    const { breadcrumbs } = this.props;

    const subtitle = (
      <span>
        Busca por <strong>Bus</strong> y/o <strong>Fecha</strong>.
      </span>
    );

    const content = (
      <Fragment>
        <FuelConsumptionReportPerDriverSearchForm onSubmit={this.onSubmit} />
        <FuelConsumptionReportPerDriverSearchResult
          showDriverHeaderSummary={this.state.showDriverHeaderSummary}
        />
      </Fragment>
    );

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Reporte de Consumo de Combustible por Conductor"
        subtitle={subtitle}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reporte de Consumo de Combustible por Conductor',
      href: FUEL_CONSUMPTION_REPORT_PER_DRIVER_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostFuelConsumptionReportPerDriver:
    postFuelConsumptionReportPerDriver,
  dispatchClearFuelConsumptionReportPerDriver:
    clearFuelConsumptionReportPerDriver,
};

FuelConsumptionReportPerDriver.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostFuelConsumptionReportPerDriver: PropTypes.func.isRequired,
  dispatchClearFuelConsumptionReportPerDriver: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuelConsumptionReportPerDriver);
