// get all
export const FLAG_GETTING_TICKETS = 'FLAG_GETTING_TICKETS';
export const GET_TICKETS = 'GET_TICKETS';
export const CLEAR_TICKETS = 'CLEAR_TICKETS';

// get one
export const FLAG_TICKET_ACTIVITY = 'FLAG_TICKET_ACTIVITY';
export const GET_TICKET = 'GET_TICKET';
export const CLEAR_TICKET = 'CLEAR_TICKET';
export const FLAG_CHANGING_PASSENGER = 'FLAG_CHANGING_PASSENGER';
