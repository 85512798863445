import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { EVENT_PATH } from '../../../../config/paths';
import { getEvent, clearEvent } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

class Event extends Component {
  componentDidMount() {
    const {
      dispatchGetEvent,
      match: {
        params: { id: eventId },
      },
    } = this.props;
    dispatchGetEvent({ eventId });
  }

  componentWillUnmount() {
    const { dispatchClearEvent } = this.props;
    dispatchClearEvent();
  }

  render() {
    const { breadcrumbs, event, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (event.isEmpty()) {
      content = <NoDataResource returnPage={EVENT_PATH} />;
    } else {
      const { url, uri, method, parameters, user, duration, startTime } =
        event.toJS();

      const basicInformationResource = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="URL:">{url}</ResourceProperty>
          <ResourceProperty label="URI:">{uri}</ResourceProperty>
          <ResourceProperty label="Method:">{method}</ResourceProperty>
          <ResourceProperty label="Parameters:">{parameters}</ResourceProperty>
          <ResourceProperty label="User:">
            {user.customer.fullName} {user.username}
          </ResourceProperty>
          <ResourceProperty label="Fecha y Hora de Inicio:">
            {tzNormalizeDate({ date: startTime, format: DATE_TIME_FORMAT })}
          </ResourceProperty>
          <ResourceProperty label="Duración:">{duration}</ResourceProperty>
        </Fragment>
      );

      content = <Fragment>{basicInformationResource}</Fragment>;
    }

    return (
      <Content breadcrumbs={breadcrumbs} title="Evento" content={content} />
    );
  }
}

Event.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetEvent: PropTypes.func.isRequired,
  dispatchClearEvent: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  event: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Event.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ SystemUnit }) => ({
  breadcrumbs: [
    ...SystemUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Eventos',
      href: EVENT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  event: SystemUnit.Event.getIn(['current', 'content']),
  loading: !SystemUnit.Event.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetEvent: getEvent,
  dispatchClearEvent: clearEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
