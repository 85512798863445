import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import {
  getItineraryReport,
  clearItineraryReport,
  clearErrorGettingItineraryReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Table from '../../../common/Table';
import ReportToolbar from '../ReportToolbar';
import Loader from '../../../common/Loader';
import DateCell from '../../../common/cells/DateCell';
import Alert from '../../../common/informative/Alert';
import { ITINERARY_REPORTS_PATH } from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

class ItineraryReport extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        Header: 'Itinerario',
        accessor: 'itineraryId',
      },
      {
        Header: 'Ruta',
        accessor: 'routeName',
      },
      {
        Header: 'Estado',
        accessor: 'tripStatus',
      },
      {
        Header: 'Maciva',
        accessor: 'isMaciva',
      },
      {
        Header: 'Viaje',
        accessor: 'tripId',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
      },
      {
        Header: 'Bus',
        accessor: 'busNumber',
      },
      {
        Header: 'Conductor',
        accessor: 'driver',
      },
      {
        Header: 'Hora de Salida',
        accessor: 'departureTime',
        Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
      },
      {
        Header: 'Hora Estimada de Salida',
        accessor: 'estimatedDepartureTime',
        Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
      },
      {
        Header: 'Hora Estimada de Llegada',
        accessor: 'estimatedArrivalTime',
        Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
      },
      {
        Header: 'Boletos Vendidos',
        accessor: 'boardedTickets',
      },
    ];
    this.download = this.download.bind(this);
  }

  componentWillMount() {
    // extract id from params injected by react router
    const { id } = this.props.match.params;
    this.props.getItineraryReport({ id });
  }

  componentWillUnmount() {
    this.props.clearItineraryReport();
    this.props.clearErrorGettingItineraryReport();
  }

  download() {
    const { report } = this.props;
    const { id } = this.props.match.params;
    if (report) {
      return this.props.getItineraryReport({
        id,
        reportFormat: 'csv',
        download: true,
      });
    }
    return false;
  }

  renderContent() {
    // check if report is loaded
    const { loading, report, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    // check if report is loading or empty
    if (loading || !report || report.isEmpty()) {
      return <Loader />;
    }

    // check if report is complete
    const reportStatus = report.get('reportStatus');
    if (reportStatus === 'GENERATING') {
      return <Loader />;
    }

    const itineraryReportRows = report.get('itineraryReportRows');
    let data = [];
    let pages = null;

    if (itineraryReportRows) {
      data = itineraryReportRows.content;
      pages = itineraryReportRows.totalPages;
    }
    const { columns } = this;
    return (
      <div>
        <ReportToolbar download={this.download} disabled={!data.length} />
        <Table columns={columns} data={data} pages={pages} loading={loading} />
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <h1>Reporte de Itinerarios</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_REPORTS_PATH,
    },
    {
      text: 'Ver',
      href: state.routing.location.pathname,
    },
  ],
  error: state.ReportUnit.ItineraryReport.get('current').get('error'),
  report: state.ReportUnit.ItineraryReport.get('current').get('content'),
  loading: state.ReportUnit.ItineraryReport.get('current').get('loading'),
});

const mapDispatchToProps = {
  getItineraryReport,
  clearItineraryReport,
  clearErrorGettingItineraryReport,
};

ItineraryReport.propTypes = {
  getItineraryReport: PropTypes.func.isRequired,
  clearItineraryReport: PropTypes.func.isRequired,
  clearErrorGettingItineraryReport: PropTypes.func.isRequired,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  report: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
  match: matchPropTypes.isRequired,
};

ItineraryReport.defaultProps = {
  report: null,
  loading: false,
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryReport);
