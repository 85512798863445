import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import './Card.css';
import { hasViewAuthorization } from '../../utils/auth-validator';

export const Card = ({
  title,
  path,
  icon,
  disabled,
  authenticated,
  user,
  className,
}) => {
  // return card only if user is authorized
  if (
    authenticated &&
    user &&
    hasViewAuthorization({
      path,
      securityProfileSet: user.securityProfileSet,
    })
  ) {
    return (
      <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mt-4">
        <Link to={path} className={`silent ${disabled ? 'disabled' : ''}`}>
          <div className={`card module ${className} clickable`}>
            <div className="card-body">
              <h3 className="card-title">{title}</h3>
              <p className="card-text mt-4">
                <i className={`fa fa-${icon} fa-5x`} />
              </p>
            </div>
          </div>
        </Link>
      </Col>
    );
  }

  return null;
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.get('user'),
  authenticated: authentication.get('authenticated'),
});

Card.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    securityProfileSet: PropTypes.arrayOf(
      PropTypes.shape({
        viewAuthorizationList: PropTypes.arrayOf(
          PropTypes.shape({
            view: PropTypes.shape({
              route: PropTypes.string,
            }),
          }),
        ).isRequired,
      }),
    ).isRequired,
  }),
  authenticated: PropTypes.bool,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  user: null,
  authenticated: false,
  disabled: false,
  className: null,
};

export default connect(mapStateToProps)(Card);
