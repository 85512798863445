import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { TOLL_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { FLAG_GETTING_TOLLS, GET_TOLLS, CLEAR_TOLLS } from '../types';

const flagGettingTolls = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_TOLLS,
    payload: flag,
  });

const getTolls =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingTolls(true));
      const query = tableFilters;
      const url = `${TOLL_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const tolls = await response.json();
      dispatch({ type: GET_TOLLS, payload: tolls });
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagGettingTolls(false));
    }
  };

const clearTolls = () => (dispatch) =>
  dispatch({
    type: CLEAR_TOLLS,
  });

export { flagGettingTolls, getTolls, clearTolls };
