import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  userBasicInformationDefaultProps,
  userBasicInformationPropTypes,
} from '../proptypes/UserPropTypes';

const UserBasicInformationResource = ({ username, locale }) => (
  <Fragment>
    <ResourceProperty label="Usuario:">{username}</ResourceProperty>
    <ResourceProperty label="Idioma:">{locale}</ResourceProperty>
  </Fragment>
);

UserBasicInformationResource.propTypes = userBasicInformationPropTypes;

UserBasicInformationResource.defaultProps = userBasicInformationDefaultProps;

export default UserBasicInformationResource;
