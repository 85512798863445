import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import AuthorizedProfileByProfileForm from './AuthorizedProfileByProfileForm';
import { postAuthorizedProfile } from '../../../../actions';
import { PROFILE_AUTHORIZED_BY_PROFILE_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';

export class NewAuthorizedProfileByProfile extends Component {
  onSubmit = (formValues) =>
    this.props.dispatchPostAuthorizedProfile({
      profileId: formValues.profileId.value,
      authorizedSecurityProfileId: formValues.authorizedSecurityProfileId,
    });

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Perfil Autorizado"
        subtitle="Crea un nuevo perfil autorizado"
        content={
          <AuthorizedProfileByProfileForm
            onSubmit={this.onSubmit}
            initialValues={{ enabled: true, verified: true, blocked: false }}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ SecurityUnit }) => ({
  breadcrumbs: [
    ...SecurityUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles Autorizados por Perfil de Seguridad',
      href: PROFILE_AUTHORIZED_BY_PROFILE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostAuthorizedProfile: postAuthorizedProfile,
};

NewAuthorizedProfileByProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostAuthorizedProfile: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewAuthorizedProfileByProfile);
