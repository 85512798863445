import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  NEW_ITINERARY_SEARCH_OMISSION_PATH,
  ITINERARY_SEARCH_OMISSION_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ItinerarySearchOmissionForm from './ItinerarySearchOmissionForm';
import { postItinerarySearchOmission } from '../../../../actions/itinerary';
import Content from '../../../layout/Content';

const NewItinerarySearchOmission = ({
  breadcrumbs,
  dispatchPostItinerarySearchOmission,
}) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Crear Parada Oculta"
    subtitle="Crear parada oculta para itinerarios"
    content={
      <ItinerarySearchOmissionForm
        onSubmit={dispatchPostItinerarySearchOmission}
      />
    }
  />
);

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Parada Oculta',
      href: ITINERARY_SEARCH_OMISSION_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_ITINERARY_SEARCH_OMISSION_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostItinerarySearchOmission: postItinerarySearchOmission,
};

NewItinerarySearchOmission.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostItinerarySearchOmission: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewItinerarySearchOmission);
