import React from 'react';
import PropTypes from 'prop-types';
import { EDIT_EXPENSE_TYPE_BREADCRUMBS } from './config/breadcrumbs';
import ExpenseTypeForm from './ExpenseTypeForm';
import { getExpenseType, putExpenseType } from './fetch';
import ContainerComponent from '../../../common/resource/ContainerComponent';
import { EXPENSE_TYPE_LABEL } from '../../../../config/constants';
import Content from '../../../layout/Content';

const EditExpenseType = ({
  payload: {
    id,
    name,
    expenseCode,
    description,
    expenseTypeLabel: selectValue,
    internal,
    costCenter,
  },
}) => {
  let costCenterOption = null;
  if (costCenter) {
    costCenterOption = { value: costCenter.id, label: costCenter.description };
  }
  const initialValues = {
    id,
    name,
    internal,
    costCenter: costCenterOption,
    description,
    expenseCode,
    expenseTypeLabel: EXPENSE_TYPE_LABEL[selectValue],
  };

  const createPayload = ({ formValues }) => ({
    id: formValues.id,
    name: formValues.name,
    expenseCode: formValues.expenseCode,
    description: formValues.description,
    expenseTypeLabel: formValues.expenseTypeLabel.value,
    internal: formValues.internal,
    costCenterId: formValues.costCenter ? formValues.costCenter.value : null,
  });

  return (
    <Content
      breadcrumbs={EDIT_EXPENSE_TYPE_BREADCRUMBS}
      title="Editar Tipo de Egreso"
      subtitle="Editar tipo de egreso"
      content={
        <ExpenseTypeForm
          fetchFunction={putExpenseType}
          createPayload={createPayload}
          initialValues={initialValues}
        />
      }
    />
  );
};

EditExpenseType.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    expenseCode: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    internal: PropTypes.bool.isRequired,
    costCenter: PropTypes.shape({
      costCenterCode: PropTypes.string.isRequired,
      createDate: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
    expenseTypeLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContainerComponent({
  getFunction: getExpenseType,
})(EditExpenseType);
