import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Button, PopoverHeader, Popover, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux';
import { changeStep, deselectSeat } from '../../../../actions/index';
import {
  BOOKING_PASSENGERS_STEP_INDEX,
  STEPS,
} from '../../../../config/constants';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { numberFormatter } from '../../../../utils/number';
import Badge from '../../../common/Badge';
import { tzNormalizeDate } from '../../../../utils/date';

class SelectedSeats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpenList: [],
    };
  }

  componentDidUpdate() {
    this.didUpdate();
  }

  didUpdate = () => {
    const { seatReservations } = this.props;

    const { popoverOpenList } = this.state;

    const newPopoverOpenList = [];

    let index = 0;
    seatReservations.forEach(() => {
      newPopoverOpenList[index] = false;
      index += 1;
    });

    if (newPopoverOpenList.length !== popoverOpenList.length)
      this.setState({ popoverOpenList: newPopoverOpenList });
  };

  toggle = (index) => {
    const { popoverOpenList } = this.state;
    popoverOpenList[index] = !popoverOpenList[index];

    this.setState({ popoverOpenList });
  };

  openPopover = (index) => {
    const { popoverOpenList } = this.state;
    popoverOpenList[index] = true;

    this.setState({ popoverOpenList });
  };

  closePopover = (index) => {
    const { popoverOpenList } = this.state;
    popoverOpenList[index] = false;

    this.setState({ popoverOpenList });
  };

  handleNextButtonClick = () => {
    const passengersStep = STEPS[BOOKING_PASSENGERS_STEP_INDEX];
    this.props.changeStep(passengersStep);
  };

  renderSeats = () => {
    const {
      seatReservations,
      dispatchDeselectSeat,
      seatSelectionChangesInProgress,
    } = this.props;

    if (!seatReservations.length)
      return (
        <span className="text-muted">
          <i>No hay asientos seleccionados</i>
        </span>
      );

    let total = 0;

    const items = seatReservations.map((seatReservation, index) => {
      const {
        seat: { seatNumber },
        listPrice,
        id: seatReservationId,
        seatId,
        itineraryId,
        itinerary: {
          originName,
          destinationName,
          departureTime,
          arrivalTime,
          serviceName,
        },
      } = seatReservation;

      total += listPrice || 0;

      // departure
      const formattedDepartureTime = tzNormalizeDate({
        date: departureTime,
        format: DATE_TIME_FORMAT,
      });

      // arrival
      const formattedArrivalTime = tzNormalizeDate({
        date: arrivalTime,
        format: DATE_TIME_FORMAT,
      });

      const amount = listPrice ? numberFormatter({ value: listPrice }) : null;

      let deselectSeatFunction = null;

      if (seatSelectionChangesInProgress.size === 0) {
        deselectSeatFunction = () =>
          dispatchDeselectSeat({
            seatReservationId,
            itineraryId,
            seatId,
          });
      }

      const item = (
        <li className="list-group-item p-0" key={`td_${index.toString()}`}>
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item border-0 pl-1 pr-1 pt-2 pb-2 w20p">
              <Badge
                className="colorPurple clickable"
                onFocus={() => this.openPopover(index)}
                onBlur={() => this.closePopover(index)}
                onMouseEnter={() => this.openPopover(index)}
                onMouseLeave={() => this.closePopover(index)}
                onClick={() => this.openPopover(index)}
                id={`pp_${index}`}
                role="button"
                text={seatNumber}
              />
              <Popover
                placement="bottom"
                isOpen={this.state.popoverOpenList[index]}
                target={`pp_${index}`}
                toggle={() => this.toggle(index)}
              >
                <PopoverHeader>{serviceName}</PopoverHeader>
                <PopoverBody>
                  <p>
                    Origen: {originName}
                    <br />
                    Salida: {formattedDepartureTime}
                  </p>
                  <p>
                    Destino: {destinationName}
                    <br />
                    Llegada: {formattedArrivalTime}
                  </p>
                </PopoverBody>
              </Popover>
            </li>
            <li className="list-group-item border-0 pl-1 pr-1 pt-2 pb-2 text-right w60p">
              {amount}
            </li>
            <li className="list-group-item border-0 pl-1 pr-1 pt-2 pb-2 text-right w20p">
              <i
                className="fa fa-times-circle text-danger clickable"
                role="button"
                onClick={deselectSeatFunction}
                onKeyPress={deselectSeatFunction}
                tabIndex={0}
              />
            </li>
          </ul>
        </li>
      );

      return item;
    });

    let footer = null;

    if (total !== 0) {
      footer = (
        <li className="list-group-item p-0" key="total">
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item border-0 pl-1 pr-1 pt-2 pb-2 w20p">
              &nbsp;
            </li>
            <li className="list-group-item border-0 pl-1 pr-1 pt-2 pb-2 w60p text-right font-weight-bold">
              TOTAL: {numberFormatter({ value: total })}
            </li>
            <li className="list-group-item border-0 pl-1 pr-1 pt-2 pb-2 w20p">
              &nbsp;
            </li>
          </ul>
        </li>
      );
    }

    return (
      <ul className="list-group list-group-flush">
        {items}
        {footer}
      </ul>
    );
  };

  renderNextButton() {
    return (
      <Button
        className="mt-3"
        disabled={!this.props.allSeatsSelected}
        color="primary"
        onClick={this.handleNextButtonClick}
        size="lg"
      >
        Siguiente <i className="fa fa-arrow-circle-right" />
      </Button>
    );
  }

  render() {
    return (
      <div className="mt-4">
        <div className="card">
          <div className="card-body p-3">
            <h5 className="card-title">Asientos</h5>
            <div className="card-text">{this.renderSeats()}</div>
            <div className="text-right">
              {this.renderNextButton(this.props.allSeatsSelected)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectedSeats.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  seatReservations: PropTypes.array,
  allSeatsSelected: PropTypes.bool.isRequired,
  changeStep: PropTypes.func.isRequired,
  dispatchDeselectSeat: PropTypes.func.isRequired,
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
};

SelectedSeats.defaultProps = { seatReservations: [] };

const mapStateToProps = (state) => {
  const numPassengers = state.BookingUnit.Booking.getIn([
    'search',
    'query',
    'numPassengers',
  ]);

  const seatReservations = state.BookingUnit.Booking.getIn([
    'seats',
    'seatReservations',
  ]).toJS();

  const seatSelectionChangesInProgress = state.BookingUnit.Booking.getIn([
    'seats',
    'seatSelectionChangesInProgress',
  ]);

  let allSeatsSelected = false;

  // validation when numPassengers is null
  // there is no limit of seats
  if (
    !numPassengers &&
    seatReservations.length >= 1 &&
    !seatSelectionChangesInProgress.size
  ) {
    allSeatsSelected = true;
    // validation when there is a numPassengers
    // there is a limit of seats
  } else if (
    numPassengers &&
    parseInt(numPassengers, 10) === seatReservations.length &&
    !seatSelectionChangesInProgress.size
  ) {
    allSeatsSelected = true;
  }
  return {
    seatReservations,
    allSeatsSelected,
    seatSelectionChangesInProgress,
  };
};

const mapDispatchToProps = {
  changeStep,
  dispatchDeselectSeat: deselectSeat,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedSeats);
