import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Form, Button } from 'reactstrap';
import BusinessInputGroup from '../../../../common/forms/BusinessInputGroup';
import FormFooter from '../../../../common/forms/FormFooter';
import { isRequired } from '../../../../../utils/validators';

const VoucherTypeChangeForm = ({ handleSubmit, onCancel }) => (
  <>
    <Form onSubmit={handleSubmit}>
      <BusinessInputGroup
        label="Empresa"
        name="business"
        labelRequired
        form="VoucherTypeChangeForm"
        validate={[isRequired]}
      />
      <FormFooter saveButtonColor="secondary" saveButtonIcon={null}>
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  </>
);

VoucherTypeChangeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'VoucherTypeChangeForm',
})(VoucherTypeChangeForm);
