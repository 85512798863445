import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row, Table } from 'reactstrap';
import { getFuelConsumptionQualification } from '../../../../utils/units/traffic';
import { driverFuelReportHeaderPropTypes } from './FuelConsumptionReportPerDriverPropTypes';
import QualificationResource from '../fuel-consumption-report-per-bus/QualificationResource';

const DriverFuelReportHeaderResource = ({ data, showDriverHeaderSummary }) => {
  if (!data) {
    return null;
  }

  const expectedFuelConsumption = +data.expectedFuelConsumption;
  const realFuelConsumption = +data.realFuelConsumption;

  const totalKilometers = +data.totalKilometers;

  const {
    qualificationText,
    qualificationColor,
  } = getFuelConsumptionQualification({
    expectedFuelConsumption,
    realFuelConsumption,
  });

  const summaryResource = showDriverHeaderSummary ? (
    <>
      <Col xl={5} lg={6} md={12}>
        <Table striped responsive role="grid" className="border-black">
          <thead>
            <tr className="shadow">
              <th colSpan={4} className="text-center border-black title">
                RESUMEN
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="label">Total Circuitos:</td>
              <td>{data.circuitQuantity || 0}</td>
              <td className="label">Base:</td>
              <td>{data.driverHomeBase || 0}</td>
            </tr>
            <tr>
              <td className="label">Total KM:</td>
              <td>{totalKilometers || 0}</td>
              <td className="label">Calificación:</td>
              <td className={`border-right-black ${qualificationColor}`}>
                {qualificationText}
              </td>
            </tr>
            <tr>
              <td className="label">Dotación Autorizada:</td>
              <td>{expectedFuelConsumption || 0}</td>
              <td className="label">Ahorro:</td>
              <td className="border-right-black">{data.saving || 0}</td>
            </tr>
            <tr>
              <td className="label">Consumo Real:</td>
              <td>{realFuelConsumption || 0}</td>
              <td className="label">Exceso:</td>
              <td className="border-right-black">{data.excess || 0}</td>
            </tr>
            <tr>
              <td className="label">Eficiencia Consumo:</td>
              <td>{data.fuelEfficiency.toFixed(2)}</td>
              <td className="label">Rendimiento km/gal:</td>
              <td className="border-right-black">
                {data.performance.toFixed(2) || 0}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col xl={3} lg={3} md={6}>
        <Table striped responsive role="grid" className="border-black">
          <thead>
            <tr className="shadow">
              <th colSpan={2} className="text-center border-black title">
                ADBLUE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="label">Dotación Autorizada:</td>
              <td>{data.expectedAdblueConsumption || 0}</td>
            </tr>
            <tr>
              <td className="label">Consumo Real:</td>
              <td>{data.realAdblueConsumption || 0}</td>
            </tr>
            <tr>
              <td className="label">Eficiencia Consumo:</td>
              <td>{data.adblueEfficiency.toFixed(2)}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </>
  ) : null;

  return (
    <Row className="justify-content-md-center">
      {summaryResource}
      <Col xl={3} lg={3} md={6}>
        <QualificationResource />
      </Col>
    </Row>
  );
};

DriverFuelReportHeaderResource.propTypes = {
  data: driverFuelReportHeaderPropTypes,
  showDriverHeaderSummary: PropTypes.bool.isRequired,
};

DriverFuelReportHeaderResource.defaultProps = {
  data: null,
};

export default DriverFuelReportHeaderResource;
