import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  getDriverCommissions,
  clearDriverCommissions,
} from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { isValidNumber } from '../../../../utils/validators';

class DriverCommissionSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearDriverCommissions } = this.props;
    dispatchClearDriverCommissions();
  }

  handleDriverCommissionChange = (inputValue) => {
    const { dispatchGetDriverCommissions } = this.props;

    const value = inputValue.trim();

    if (value.length) {
      let query = [];

      if (isValidNumber(value)) {
        query = [`id:${value}`];
      } else if (value.length >= 4) {
        query = [`name:${value}`];
      }

      dispatchGetDriverCommissions({ query });
    }
  };

  render() {
    const { options, isLoading, isMulti, input, meta, ...rest } = this.props;

    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        onInputChange={this.handleDriverCommissionChange}
        {...rest}
      />
    );
  }
}

DriverCommissionSelect.propTypes = {
  dispatchGetDriverCommissions: PropTypes.func.isRequired,
  dispatchClearDriverCommissions: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
};

DriverCommissionSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  options: [],
  placeholder: 'Busque por ID o nombre de Comisión de Conductor',
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  options: HumanResourcesUnit.DriverCommission.getIn([
    'all',
    'content',
    'content',
  ]).map((option) => ({
    value: option.id,
    label: `${option.id} - ${option.name}`,
  })),
  loading: HumanResourcesUnit.DriverCommission.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetDriverCommissions: getDriverCommissions,
  dispatchClearDriverCommissions: clearDriverCommissions,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(DriverCommissionSelect);
