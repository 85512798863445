import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  CARGO_CORPORATE_CREDIT_PATH,
  NEW_CARGO_CORPORATE_CREDIT_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { CARGO_CORPORATE_CREDIT_COLUMNS } from '../../../../config/columns';
import {
  clearCargoCorporateCredits,
  getCargoCorporateCredits,
} from '../../../../actions/cargo/CargoCorporateCredit';

export const CargoCorporateCredits = ({
  breadcrumbs,
  cargoCorporateCredits,
  loading,
  dispatchGetCargoCorporateCredits,
  dispatchClearCargoCorporateCredits,
}) => {
  useLayoutEffect(() => () => dispatchClearCargoCorporateCredits(), []);

  const data = cargoCorporateCredits.get('content') || [];
  const pages = cargoCorporateCredits.get('totalPages') || null;
  const defaultPageSize =
    cargoCorporateCredits.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Créditos Corporativos"
      buttonPath={NEW_CARGO_CORPORATE_CREDIT_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: CARGO_CORPORATE_CREDIT_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetCargoCorporateCredits,
        loading,
        modelPath: CARGO_CORPORATE_CREDIT_PATH,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredits: getCargoCorporateCredits,
  dispatchClearCargoCorporateCredits: clearCargoCorporateCredits,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
  ],
  cargoCorporateCredits: CargoUnit.CargoCorporateCredit.getIn([
    'all',
    'content',
  ]),
  loading: CargoUnit.CargoCorporateCredit.getIn(['all', 'loading']),
});

CargoCorporateCredits.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cargoCorporateCredits: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCargoCorporateCredits: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredits: PropTypes.func.isRequired,
};

CargoCorporateCredits.defaultProps = {
  cargoCorporateCredits: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CargoCorporateCredits);
