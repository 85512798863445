import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { convertToCSV } from '../../../utils/array';
import downloadCsv from '../../../utils/download-csv';

const onClickDownloadCsv = ({ data, csvName = 'data.csv' }) => {
  const csvData = convertToCSV(data);
  downloadCsv(csvData, csvName, 'text/csv;charset=utf-8;');
};

const DownloadButton = ({ data, csvName, buttonText }) => (
  <Button
    type="button"
    color="light"
    className="border"
    onClick={() => onClickDownloadCsv({ data, csvName })}
  >
    <i className="fa fa-download" /> {buttonText}
  </Button>
);

DownloadButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  csvName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

DownloadButton.defaultProps = {
  buttonText: 'Descargar CSV',
};

export default DownloadButton;
