import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MECHANIC_PATH } from '../../../../config/paths';
import {
  clearMechanic,
  getMechanic,
  putMechanic,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MechanicForm from './MechanicForm';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DEFAULT_COMPANY_OPTION } from '../../../../config/defaults';

const EditMechanic = ({
  breadcrumbs,
  dispatchPutMechanic,
  dispatchGetMechanic,
  dispatchClearMechanic,
  match: {
    params: { id: mechanicId },
  },
  loading,
  mechanic,
}) => {
  useLayoutEffect(() => {
    dispatchGetMechanic({ mechanicId });

    return () => dispatchClearMechanic();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.customerId =
      formValues.customerId && formValues.customerId.value;
    newFormValues.companyId =
      formValues.companyId && formValues.companyId.value;
    newFormValues.managementId =
      formValues.managementId && formValues.managementId.value;
    newFormValues.departmentId =
      formValues.departmentId && formValues.departmentId.value;
    newFormValues.areaId = formValues.areaId && formValues.areaId.value;
    newFormValues.sectionId =
      formValues.sectionId && formValues.sectionId.value;
    newFormValues.specialityId =
      formValues.specialityId && formValues.specialityId.value;
    newFormValues.bossId = formValues.bossId && formValues.bossId.value;
    dispatchPutMechanic(mechanicId, newFormValues);
  };

  const generateInitialValues = (mechanicJson) => ({
    customerId: {
      value: mechanicJson.customer.id,
      label: `${mechanicJson.customer.idDocumentNumber} (${mechanicJson.customer.idCountryOfOrigin}) - ${mechanicJson.customer.fullName}`,
      id: mechanicJson.customer.id,
      firstName: mechanicJson.customer.firstName,
      lastName: mechanicJson.customer.lastName,
      fullName: mechanicJson.customer.fullName,
      idDocumentNumber: mechanicJson.customer.idDocumentNumber,
      gender: mechanicJson.customer.gender,
      idCountryOfOrigin: mechanicJson.customer.idCountryOfOrigin,
      identificationType: mechanicJson.customer.identificationType,
      email: mechanicJson.customer.email,
      mobilePhone: mechanicJson.customer.mobilePhone,
      dob: mechanicJson.customer.dob,
      discountCode: mechanicJson.customer.discountCode,
    },
    companyId: DEFAULT_COMPANY_OPTION,
    managementId: {
      value: mechanicJson.management.id,
      label: mechanicJson.management.name,
    },
    departmentId: mechanicJson.department && {
      value: mechanicJson.department.id,
      label: mechanicJson.department.name,
    },
    areaId: mechanicJson.area && {
      value: mechanicJson.area.id,
      label: mechanicJson.area.name,
    },
    sectionId: mechanicJson.section && {
      value: mechanicJson.section.id,
      label: mechanicJson.section.name,
    },
    specialityId: mechanicJson.speciality && {
      value: mechanicJson.speciality.id,
      label: mechanicJson.speciality.name,
    },
    bossId: mechanicJson.boss && {
      value: mechanicJson.boss.id,
      label: mechanicJson.boss.customer.fullName,
    },
  });

  let content;

  if (loading) content = <Loader />;
  else if (mechanic.isEmpty())
    content = <NoDataResource returnPage={MECHANIC_PATH} />;
  else
    content = (
      <MechanicForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(mechanic.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Personal"
      subtitle="Editar personal"
      content={content}
    />
  );
};

EditMechanic.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutMechanic: PropTypes.func.isRequired,
  dispatchClearMechanic: PropTypes.func.isRequired,
  dispatchGetMechanic: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  mechanic: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditMechanic.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchPutMechanic: putMechanic,
  dispatchGetMechanic: getMechanic,
  dispatchClearMechanic: clearMechanic,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Personal',
      href: MECHANIC_PATH,
    },
    {
      text: 'Ver',
      href: `${MECHANIC_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  mechanic: MechanicalMaintenanceUnit.Mechanic.getIn(['current', 'content']),
  loading: !MechanicalMaintenanceUnit.Mechanic.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMechanic);
