import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DRIVER_PATH } from '../../../../config/paths';

const DriverEditButton = ({ driverId }) => (
  <Link className="btn btn-primary" to={`${DRIVER_PATH}/${driverId}/edit`}>
    Editar
  </Link>
);

DriverEditButton.propTypes = {
  driverId: PropTypes.number.isRequired,
};

export default DriverEditButton;
