import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Table from '../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { EXPENSE_STATUS_COLUMNS } from '../../../../config/columns';
import { EXPENSE_STATUS_PATH } from '../../../../config/paths';
import {
  clearExpensePerUser,
  getExpensesPerUser,
} from '../../../../actions/sales/ExpenseStatus';

export class ExpenseStatusSearchResult extends Component {
  componentWillUnmount() {
    this.props.dispatchClearExpensePerUser();
  }

  render() {
    const { loading, expenses, searchFormValues, dispatchGetExpensesPerUser } =
      this.props;

    const data = expenses.get('content') || [];
    const columns = EXPENSE_STATUS_COLUMNS;
    const pages = expenses.get('totalPages') || null;
    const defaultPageSize = expenses.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <div className="mt-3">
        <Table
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          modelPath={EXPENSE_STATUS_PATH}
          navigateToModelOnRowClick
          fetchData={dispatchGetExpensesPerUser}
          params={searchFormValues}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  loading: SalesUnit.ExpenseStatus.getIn(['all', 'loading']),
  expenses: SalesUnit.ExpenseStatus.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetExpensesPerUser: getExpensesPerUser,
  dispatchClearExpensePerUser: clearExpensePerUser,
};

ExpenseStatusSearchResult.propTypes = {
  expenses: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearExpensePerUser: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    expenseStatus: PropTypes.string,
  }).isRequired,
  dispatchGetExpensesPerUser: PropTypes.func.isRequired,
};

ExpenseStatusSearchResult.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseStatusSearchResult);
