import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DRIVER_BUS_ASSIGNMENT_PATH } from '../../../../config/paths';
import DriverBusAssignmentForm from './DriverBusAssignmentForm';
import { postDriverBusAssign } from '../../../../actions';
import Content from '../../../layout/Content';

export class NewDriverBusAssignment extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostDriverBusAssign } = this.props;
    const driverListLength = formValues.driverList.length;
    const newFormValues = {
      busId: formValues.bus.value,
      companyBusId: formValues.bus.label,
      driverId: formValues.driverList[driverListLength - 1].driver.value,
      driverTypeId:
        formValues.driverList[driverListLength - 1].driverType.value,
    };
    dispatchPostDriverBusAssign(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Asignación de Conductores"
        subtitle="Nueva asignación de conductores"
        content={<DriverBusAssignmentForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignaciones de Conductores',
      href: DRIVER_BUS_ASSIGNMENT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = { dispatchPostDriverBusAssign: postDriverBusAssign };

NewDriverBusAssignment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDriverBusAssign: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDriverBusAssignment);
