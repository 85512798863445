import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  locationBasicInformationDefaultProps,
  locationBasicInformationPropTypes,
} from '../proptypes/LocationPropTypes';

const LocationBasicInformationResource = ({
  name,
  description,
  locationCorrelationId,
  agencyCode,
  ubigeo,
  latitude,
  longitude,
}) => (
  <Fragment>
    <ResourceProperty label="Nombre:">{name}</ResourceProperty>
    <ResourceProperty label="Descripción:">
      {description || '-'}
    </ResourceProperty>
    <ResourceProperty label="ID de Correlación Civa:">
      {locationCorrelationId || '-'}
    </ResourceProperty>
    <ResourceProperty label="Ubigeo:">{ubigeo || '-'}</ResourceProperty>
    <ResourceProperty label="Latitud:">{latitude || '-'}</ResourceProperty>
    <ResourceProperty label="Longitud:">{longitude || '-'}</ResourceProperty>
    <ResourceProperty label="Codigo de Establecimiento:">
      {agencyCode || '-'}
    </ResourceProperty>
  </Fragment>
);

LocationBasicInformationResource.propTypes = locationBasicInformationPropTypes;

LocationBasicInformationResource.defaultProps =
  locationBasicInformationDefaultProps;

export default LocationBasicInformationResource;
