import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import ItineraryForm from './ItineraryForm';
import { ITINERARY_PATH } from '../../../../../config/paths';
import {
  getItinerary,
  clearItinerary,
  postNewTrip,
  getItinerarySchedule,
  clearItinerarySchedule,
  putItinerary,
} from '../../../../../actions';
import Loader from '../../../../common/Loader';
import {
  INT_DATE_FORMAT,
  TIMESTAMP_FORMAT,
} from '../../../../../config/locale';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Content from '../../../../layout/Content';
import { ITINERARY_TYPE } from '../../../../../config/constants';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../../utils/date';

export class EditItinerary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfUpdates: 0,
    };
  }

  componentDidMount() {
    const {
      dispatchGetItinerary,
      match: {
        params: { id: itineraryId },
      },
    } = this.props;
    dispatchGetItinerary({ itineraryId });
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    const { dispatchClearItinerary, dispatchClearItinerarySchedule } =
      this.props;
    dispatchClearItinerary();
    dispatchClearItinerarySchedule();
  }

  onDidUpdate = () => {
    const { itinerary, dispatchGetItinerarySchedule } = this.props;

    const { numberOfUpdates } = this.state;

    if (itinerary.get('itineraryScheduleId') && numberOfUpdates === 0) {
      dispatchGetItinerarySchedule({
        itineraryScheduleId: itinerary.get('itineraryScheduleId'),
      });

      this.setState({ numberOfUpdates: 1 });
    }
  };

  onSubmit = (formValues) => {
    const {
      match: {
        params: { id: itineraryId },
      },
      dispatchPutItinerary,
      itinerary,
    } = this.props;

    const newFormValues = {
      routeId: formValues.routeId.value,
      seatMapId: formValues.seatMapId ? formValues.seatMapId.value : null,
      departureTime: tzNormalizeDate({
        date: formValues.departureTime,
        format: TIMESTAMP_FORMAT,
      }),
      departureDate: tzNormalizeDate({
        date: formValues.startDate,
        format: INT_DATE_FORMAT,
      }),
      active: formValues.active,
      type: formValues.type.value,
      itineraryScheduleId: itinerary.get('itineraryScheduleId'),
      followsTemplate: itinerary.get('followsTemplate'),
      tripId: itinerary.get('tripId'),
      isInternal: itinerary.get('isInternal'),
      isMaciva: formValues.isMaciva,
      driverCommissionId: formValues.driverCommissionId
        ? formValues.driverCommissionId.value
        : null,
      itineraryCode: itinerary.get('itineraryCode'),
      circuitId: formValues.circuitId.value,
      fuelProfileId: itinerary.get('fuelProfileId'),
      cabinCrewCommissionId: formValues.cabinCrewCommissionId
        ? formValues.cabinCrewCommissionId.value
        : null,
      serviceTypeId: formValues.serviceTypeId.value,
      itineraryCorrelationId: formValues.itineraryCorrelationId || null,
      createdFromApi: itinerary.get('createdFromApi'),
      archived: itinerary.get('archived'),
      itineraryMigrationGroupId: itinerary.get('itineraryMigrationGroupId'),
      foodGroupId: itinerary.get('foodGroupId'),
      seatMapAssigments: itinerary.get('seatMapAssigments'),
      version: itinerary.get('version'),
      extraordinary: itinerary.get('extraordinary'),
      comment: itinerary.get('comment'),
      totalIncome: itinerary.get('totalIncome'),
      firstFloorPriceVariation: 0,
      secondFloorPriceVariation: 0,
      companyId: formValues.companyId ? formValues.companyId.value : null,
    };

    if (formValues.pricingProfileId) {
      newFormValues.itineraryPricingProfiles = formValues.pricingProfileId.map(
        (pricingProfile) => ({
          pricingProfileId: pricingProfile.value,
          itineraryId,
        }),
      );
    }

    if (formValues.seatMapId) {
      const { numberOfFloors } = formValues.seatMapId;

      if (numberOfFloors === 1)
        newFormValues.firstFloorPriceVariation =
          formValues.firstFloorPriceVariation;

      if (numberOfFloors > 1) {
        newFormValues.firstFloorPriceVariation =
          formValues.firstFloorPriceVariation;
        newFormValues.secondFloorPriceVariation =
          formValues.secondFloorPriceVariation;
      }
    }

    if (itinerary.get('trip')) {
      newFormValues.trip = {
        id: itinerary.get('tripId'),
        assignedRegisteredBusId: formValues.companyBusId
          ? formValues.companyBusId.value
          : null,
        tripStatusId: formValues.tripStatusId.value,
        initialOdometerReading: formValues.initialOdometerReading || null,
        finalOdometerReading: formValues.finalOdometerReading || null,
        tripCircuitStatus: formValues.tripCircuitStatus.value,
        estimatedDepartureTime: formValues.estimatedDepartureTime
          ? tzNormalizeDate({
              date: formValues.estimatedDepartureTime,
              format: TIMESTAMP_FORMAT,
            })
          : null,
        actualDepartureTime: formValues.actualDepartureTime
          ? tzNormalizeDate({
              date: formValues.actualDepartureTime,
              format: TIMESTAMP_FORMAT,
            })
          : null,
        estimatedArrivalTime: formValues.estimatedArrivalTime
          ? tzNormalizeDate({
              date: formValues.estimatedArrivalTime,
              format: TIMESTAMP_FORMAT,
            })
          : null,
        actualArrivalTime: formValues.actualArrivalTime
          ? tzNormalizeDate({
              date: formValues.actualArrivalTime,
              format: TIMESTAMP_FORMAT,
            })
          : null,
        driverAssignmentList: formValues.driverIdList
          ? formValues.driverIdList.map((driver) => ({
              driverId: driver.value,
            }))
          : [],
        cabinCrewAssignmentList: formValues.cabinCrewIdList
          ? formValues.cabinCrewIdList.map((cabinCrew) => ({
              cabinCrewId: cabinCrew.value,
            }))
          : [],
      };
    }

    dispatchPutItinerary(itineraryId, newFormValues);
  };

  generateInitialValues = (itinerary, itinerarySchedule) => {
    let seatMapId = null;

    if (itinerary.seatMap) {
      seatMapId = {
        value: itinerary.seatMapId,
        label: itinerary.seatMap.name,
        numberOfFloors: itinerary.seatMap.numberOfFloors,
      };
    }

    const pricingProfileId = itinerary.itineraryPricingProfiles.map(
      (itineraryPricingProfile) => ({
        value: itineraryPricingProfile.pricingProfileId,
        label: `${itineraryPricingProfile.pricingProfileId} - ${itineraryPricingProfile.pricingProfile.name}`,
      }),
    );

    let cabinCrewCommissionId = null;

    if (itinerary.cabinCrewCommission) {
      cabinCrewCommissionId = {
        value: itinerary.cabinCrewCommissionId,
        label: itinerary.cabinCrewCommission.name,
      };
    }

    let driverCommissionId = null;

    if (itinerary.driverCommission) {
      driverCommissionId = {
        value: itinerary.driverCommissionId,
        label: `${itinerary.driverCommissionId} - ${itinerary.driverCommission.name}`,
      };
    }

    const initialValues = {
      itineraryId: itinerary.id,
      routeId: {
        value: itinerary.routeId,
        label: `${itinerary.routeId} - ${itinerary.route.name}`,
      },
      seatMapId,
      circuitId: {
        value: itinerary.circuitId,
        label: `${itinerary.circuitId} - ${itinerary.circuit.name}`,
      },
      type: {
        value: itinerary.type,
        label: ITINERARY_TYPE[itinerary.type.toLowerCase()].label,
      },
      pricingProfileId,
      driverCommissionId,
      cabinCrewCommissionId,
      isMaciva: itinerary.isMaciva,
      active: itinerary.active,
      startDate: tzNormalizeDate({ date: itinerary.departureTime }),
      departureTime: tzNormalizeDate({ date: itinerary.departureTime }),
      itineraryScheduleSegmentList:
        itinerarySchedule.itineraryScheduleSegmentList,
      firstFloorPriceVariation: itinerary.firstFloorPriceVariation,
      secondFloorPriceVariation: itinerary.secondFloorPriceVariation,
      itineraryCorrelationId: itinerary.itineraryCorrelationId,
      trip: !!itinerary.trip,
    };

    if (itinerary.serviceType) {
      initialValues.serviceTypeId = {
        value: itinerary.serviceType.id,
        label: itinerary.serviceType.name,
      };
    }

    if (itinerary.company) {
      initialValues.companyId = {
        value: itinerary.company.id,
        label: itinerary.company.name,
      };
    }

    if (itinerary.trip) {
      const {
        tripCircuitStatus,
        tripStatusId,
        tripStatus,
        driverAssignmentList,
        cabinCrewAssignmentList,
        assignedRegisteredBus,
        assignedRegisteredBusId,
        estimatedDepartureTime,
        actualDepartureTime,
        estimatedArrivalTime,
        actualArrivalTime,
        initialOdometerReading,
        finalOdometerReading,
      } = itinerary.trip;

      initialValues.tripCircuitStatus = {
        value: tripCircuitStatus,
        label: tripCircuitStatus,
      };
      initialValues.tripStatusId = {
        value: tripStatusId,
        label: tripStatus.name,
      };
      initialValues.driverIdList = driverAssignmentList.map((driver) => ({
        value: driver.driverId,
        label: driver.driver.customer.fullName,
      }));
      initialValues.cabinCrewIdList = cabinCrewAssignmentList.map(
        (cabinCrew) => ({
          value: cabinCrew.cabinCrewId,
          label: cabinCrew.cabinCrew.customer.fullName,
        }),
      );
      initialValues.companyBusId = assignedRegisteredBusId
        ? {
            value: assignedRegisteredBus.id,
            label: assignedRegisteredBus.companyBusId,
          }
        : null;
      initialValues.estimatedDepartureTime = estimatedDepartureTime
        ? tzNormalizeDate({ date: estimatedDepartureTime })
        : null;
      initialValues.actualDepartureTime = actualDepartureTime
        ? tzNormalizeDate({ date: actualDepartureTime })
        : null;
      initialValues.estimatedArrivalTime = estimatedArrivalTime
        ? tzNormalizeDate({ date: estimatedArrivalTime })
        : null;
      initialValues.actualArrivalTime = actualArrivalTime
        ? tzNormalizeDate({ date: actualArrivalTime })
        : null;
      initialValues.initialOdometerReading = initialOdometerReading;
      initialValues.finalOdometerReading = finalOdometerReading;
    }

    return initialValues;
  };

  render() {
    const {
      breadcrumbs,
      itinerary,
      loading,
      itinerarySchedule,
      loadingItinerarySchedule,
    } = this.props;

    let content;

    if (loading || loadingItinerarySchedule || itinerarySchedule.isEmpty()) {
      content = <Loader />;
    } else if (itinerary.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_PATH} />;
    } else {
      content = (
        <ItineraryForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(
            itinerary.toJS(),
            itinerarySchedule.toJS(),
          )}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Itinerario"
        subtitle="Editar itinerario"
        content={content}
      />
    );
  }
}

EditItinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetItinerarySchedule: PropTypes.func.isRequired,
  dispatchClearItinerarySchedule: PropTypes.func.isRequired,
  itinerarySchedule: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingItinerarySchedule: PropTypes.bool.isRequired,
  dispatchPutItinerary: PropTypes.func.isRequired,
};

EditItinerary.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_PATH}/${id}`,
    },
    {
      text: 'Editar Itinerario',
      href: '',
    },
  ],
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  itinerarySchedule: ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'content',
  ]),
  loadingItinerarySchedule: !ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItinerary: getItinerary,
  dispatchGetItinerarySchedule: getItinerarySchedule,
  dispatchClearItinerary: clearItinerary,
  dispatchClearItinerarySchedule: clearItinerarySchedule,
  dispatchPostNewTrip: postNewTrip,
  dispatchPutItinerary: putItinerary,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItinerary);
