import { shape, number, string } from 'prop-types';

const detractionBasicInformationPropTypes = {
  detractionRate: number.isRequired,
  minDetractionAmount: number.isRequired,
  country: shape({
    name: string.isRequired,
  }).isRequired,
  detractionCode: number.isRequired,
  bankAccountNumber: string.isRequired,
};

export default detractionBasicInformationPropTypes;
