import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import RefundButton from './RefundButton';
import PrintCargoTicketButton from '../../reservation/ticket/ticket-toolbar/PrintCargoTicketButton';
import PrintCargoVoucherButton from '../../reservation/ticket/ticket-toolbar/PrintCargoVoucherButton';
import PayParcelButton from './PayParcelButton';
import {
  PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT,
  PARCEL_STATUS,
} from '../../../../config/constants';
import DeliverParcelButton from './DeliverParcelButton';
import ChangeSecretCodeButton from './ChangeSecretCodeButton';

const ParcelToolbar = ({ parcel }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PrintCargoTicketButton ticket={parcel} />{' '}
      <PrintCargoVoucherButton voucher={parcel} />{' '}
      <RefundButton parcelId={parcel.id} status={parcel.status} />
      {parcel.paymentStatus === PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT && (
        <PayParcelButton parcel={parcel} />
      )}{' '}
      {parcel.paymentStatus !== PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT &&
        parcel.status !== PARCEL_STATUS.DELIVERED.value && (
          <DeliverParcelButton parcel={parcel} />
        )}{' '}
      {parcel.status !== PARCEL_STATUS.DELIVERED.value && (
        <ChangeSecretCodeButton parcel={parcel} />
      )}
    </div>
  </ButtonToolbar>
);

ParcelToolbar.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
};

export default ParcelToolbar;
