import { COLOR } from '../../config/constants';
import { DATE_FORMAT } from '../../config/locale';
import LogoCiva from '../../resources/images/logo-civa.png';
import { tzNormalizeDate } from '../date';
import { numberFormatter } from '../number';
import {
  x,
  y,
  createPdfDocument,
  addText,
  addImage,
  paragraphSize,
  titleSize,
  setFont,
  isNewPageNeeded,
} from '../pdf';

const initialY = y;
let myY = initialY;
const documentWidth = 292;
const newTitleSize = titleSize - 2;

let page = 1;

const printDocumentHeader = async (doc, companyAreaName, date) => {
  addImage(doc, LogoCiva, x, myY - 2, 35, 10);

  doc.setFontSize(newTitleSize);
  setFont(doc, 'bold');
  addText(
    doc,
    `RDMP - ${companyAreaName.toUpperCase()} AL: ${tzNormalizeDate({
      date,
      format: DATE_FORMAT,
    })}`,
    150,
    (myY += 5),
    277,
  );

  doc.setFontSize(paragraphSize);
  setFont(doc);
  addText(doc, 'R = Realizado || E = Extendido', 272, (myY += 4), 40);
};

const printTableHeader = (doc) => {
  doc.setFontSize(paragraphSize);
  setFont(doc);
  // Add page number
  addText(doc, `Página ${page}`, documentWidth, 5, 30, 'right');
  page += 1;

  doc.line(x, (myY += 2), documentWidth, myY); // top line
  doc.setFontSize(paragraphSize);
  setFont(doc, 'bold');
  addText(doc, 'KM\nExcedido', x + 139, (myY += 4.5), 15);
  addText(doc, 'Hora\nInicio', x + 230, myY, 20);
  addText(doc, 'Hora\nFin', x + 250, myY, 20);

  addText(doc, 'Actividad', x + 55, (myY += 2), 15);
  addText(doc, 'Factor', x + 101, myY, 15);
  addText(doc, 'Ult. Mant.', x + 120, myY, 15);
  addText(doc, 'R', x + 152.5, myY, 5);
  addText(doc, 'E', x + 157.5, myY, 5);
  addText(doc, 'Observación', x + 190, myY, 20);
  addText(doc, 'Técnico(s)', x + 270, myY, 20);
  doc.line(x, (myY += 4), documentWidth, myY); // bottom line
};

const addPage = (doc, cellHeight) => {
  myY = isNewPageNeeded(doc, x, myY, cellHeight);

  if (myY === 5) printTableHeader(doc);
};

const printTableBody = (doc, data) =>
  data.forEach(({ companyBusId, activities }) => {
    addPage(doc, 5);

    doc.setFillColor(COLOR.lightGray);
    doc.rect(x, (myY += 0.1), documentWidth - 5, 7, 'F');
    setFont(doc, 'bold');
    addText(doc, companyBusId.toString(), x + 1, (myY += 5), 10, 'left');
    myY += 2;
    activities.forEach(
      ({
        activityName,
        factor,
        lastExecutionDate,
        companyActivityId,
        exceededKilometers,
        extended,
      }) => {
        addPage(doc, 4);

        setFont(doc);
        addText(
          doc,
          companyActivityId.toString(),
          x + 10,
          (myY += 3.5),
          10,
          'right',
        );
        const activity =
          activityName.length > 52
            ? `${activityName.substring(0, 52)}...`
            : activityName;
        addText(doc, activity, x + 14, myY, 85, 'left');
        addText(
          doc,
          numberFormatter({
            style: 'decimal',
            value: factor,
            minimumFractionDigits: 0,
          }).toString(),
          x + 110,
          myY,
          20,
          'right',
        );
        const asterisk = extended ? ' *' : '';
        addText(
          doc,
          lastExecutionDate ? `${lastExecutionDate}${asterisk}` : null,
          x + 120,
          myY,
          20,
        );
        addText(
          doc,
          numberFormatter({
            style: 'decimal',
            value: exceededKilometers,
            minimumFractionDigits: 0,
          }).toString(),
          x + 146,
          myY,
          16,
          'right',
        );

        doc.setFillColor(COLOR.success);
        doc.rect(x + 150, myY - 3.4, 5, 5, 'F');
        doc.setFillColor(COLOR.white);
        doc.rect(x + 151, myY - 2.4, 3, 3, 'DF');

        doc.setFillColor(COLOR.warning);
        doc.rect(x + 155, myY - 3.4, 5, 5, 'F');
        doc.setFillColor(COLOR.white);
        doc.rect(x + 156, myY - 2.4, 3, 3, 'DF');

        doc.line(x + 162, (myY += 0.5), documentWidth, myY); // line of observation data
        myY += 1;
      },
    );
  });

const printPreventiveMaintenanceReport = (data, date) => {
  const doc = createPdfDocument('l');

  page = 1;

  myY = initialY;

  let newData = [];

  const defaultAreaValue = 5; // ID in local with production dump
  // Checking if user has selected Mecanica option
  const indexMechanics = data.findIndex((row) => row.id === defaultAreaValue);
  if (indexMechanics >= 0) {
    // Getting data before Mecanica option
    const initialData = data.slice(0, indexMechanics);
    // Getting data after Mecanica option
    const endData = data.slice(indexMechanics + 1, data.length);

    // Setting default option: Mecanica 1
    const mechanicsData = [{ area: 'Mecanica 1', busList: [] }];
    // Find any companyBusId as a pair
    const busPairFound = data[indexMechanics].busList.some(
      ({ companyBusId }) => companyBusId % 2 === 0,
    );
    // If exists as a pair, set default option: Mecanica 2
    if (busPairFound) mechanicsData.push({ area: 'Mecanica 2', busList: [] });

    // Assign bus about its pair number
    data[indexMechanics].busList.forEach((bus) => {
      if (bus.companyBusId % 2 === 0) mechanicsData[1].busList.push(bus);
      else mechanicsData[0].busList.push(bus);
    });

    // Delete default option: Mecanica 1 if has not busList
    if (mechanicsData[0].busList.length === 0) mechanicsData.splice(0, 1);

    newData = [...initialData, ...mechanicsData, ...endData];
  } else newData = [...data];

  newData.forEach(({ area, busList }, index) => {
    if (index > 0) {
      doc.addPage();
      myY = 5;
    }

    printDocumentHeader(doc, area, date);
    printTableHeader(doc);
    printTableBody(doc, busList);
  });

  window.open(doc.output('bloburl'), '_blank');
};

export default printPreventiveMaintenanceReport;
