import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Modal from '../../../common/modal/Modal';
import ChangeSecretCodeForm from './ChangeSecretCodeForm';
import { postParcelChangeSecretCode } from '../../../../actions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generatePostParcelChangeCode } from '../../../../config/endpoints';
import { PUT } from '../../../../config/permissions';

export const ChangeSecretCodeButton = ({
  parcel,
  dispatchPostParcelChangeSecretCode,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const closeModal = () => setShowModal(false);

  const onModalSubmit = async ({ secretCode }) => {
    await dispatchPostParcelChangeSecretCode({
      parcelId: parcel.id,
      secretCode,
    });
    setShowModal(false);
    setModalBody(null);
  };

  const openModal = () => {
    const newModalBody = (
      <ChangeSecretCodeForm
        onCancel={closeModal}
        handleProcess={onModalSubmit}
      />
    );
    setModalBody(newModalBody);
    setShowModal(true);
  };

  return (
    <>
      <Modal
        show={showModal}
        title="Cambiar Clave Secreta"
        body={modalBody}
        size="lg"
      />
      <Button color="warning" onClick={openModal}>
        <i className="fa fa-key" /> Cambiar Clave
      </Button>
    </>
  );
};

ChangeSecretCodeButton.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
  dispatchPostParcelChangeSecretCode: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostParcelChangeSecretCode: postParcelChangeSecretCode,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(ChangeSecretCodeButton);

export default withEndpointAuthorization({
  url: generatePostParcelChangeCode(0),
  permissionType: PUT,
})(connectedComponent);
