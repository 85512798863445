import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Immutable from 'immutable';
import { clearPreventiveMaintenanceReport } from '../../../../actions';
import {
  ACTIVITY_STATUS,
  COLOR,
  REGISTER_OF_EXECUTION_TYPE,
} from '../../../../config/constants';
import Table from '../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import { generateExecutionRegistrationColumns } from '../../../../config/columns';
import Modal from '../../../common/modal/Modal';
import { numberFormatter } from '../../../../utils/number';
import ExecutionRegistrationForm from './ExecutionRegistrationForm';
import Badge from '../../../common/Badge';
import FormFooter from '../../../common/forms/FormFooter';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { tzNormalizeDate } from '../../../../utils/date';
import { orderBy } from '../../../../utils/array';
import { formatMaintenanceReportData } from '../preventive-maintenance-report/PreventiveMaintenanceReport';

const generateStyle = (row) => {
  let background = '';

  if (row) {
    const {
      original: { status, mechanics },
    } = row;

    if (status === ACTIVITY_STATUS.DONE.value && mechanics)
      background = COLOR.success;
    if (status === ACTIVITY_STATUS.EXTENDED.value && mechanics)
      background = COLOR.warning;
  }

  return { background };
};

export const PreventiveExecutionRegisterTable = ({
  loadingReport,
  activities,
  onHandleRegisterExecution,
  dispatchClearPreventiveMaintenanceReport,
  loading,
  selectedOrganizationChart,
}) => {
  useEffect(() => () => dispatchClearPreventiveMaintenanceReport(), []);

  const [formattedData, setFormattedData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Registro de Datos');
  const [modalBody, setModalBody] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const didUpdate = () => {
    const content = activities.get('content') || [];

    if (content.length !== formattedData.length) {
      const data = content.filter(({ areaName }) =>
        selectedOrganizationChart.includes(areaName),
      );

      const myFormattedData = formatMaintenanceReportData(data);

      const sortedMyFormattedData = orderBy(myFormattedData, [
        'areaName',
        'companyBusId',
        'companyActivityId',
      ]);

      setFormattedData(sortedMyFormattedData);
      setOriginalData(sortedMyFormattedData);
    }
  };

  useEffect(() => {
    didUpdate();
  }, [activities]);

  const onShowModal = () => setShowModal(true);

  const onCloseModal = (index) => {
    // Hack to put focus in next confirm button
    // TODO remove when upgrade dependencies: reactstrap and react-table
    const buttonFocus = document.getElementById(
      `btnConfirm${index || selectedRowIndex}`,
    );
    if (buttonFocus) buttonFocus.focus();

    setShowModal(false);
    setSelectedRowIndex(null);
  };

  const getFormattedDataIndex = ({ activityBusId }) =>
    formattedData.findIndex((data) => data.activityBusId === activityBusId);

  const onSubmitForm = (formValues, index) => {
    const activityBusIndex = getFormattedDataIndex({
      activityBusId: formValues.activityBusId,
    });
    const newFormattedData = [...formattedData];
    newFormattedData[activityBusIndex] = {
      ...newFormattedData[activityBusIndex],
      status: formValues.status.value,
      extensionFactor:
        formValues.status.value === ACTIVITY_STATUS.EXTENDED.value
          ? formValues.extensionFactor
          : null,
      startDate: tzNormalizeDate({ date: formValues.startDate }),
      endDate: tzNormalizeDate({ date: formValues.endDate }),
      workingHour: formValues.workingHour,
      busHomeBaseId: formValues.baseLocationId.value,
      busHomeBaseName: formValues.baseLocationId.label,
      comment: formValues.comment,
      mechanics: formValues.mechanics,
    };
    setFormattedData(newFormattedData);
    onCloseModal(index + 1);
  };

  const generateInitialValues = ({
    busHomeBaseId,
    busHomeBaseName,
    extensionFactor,
    status,
    workingHour,
    comment,
    startDate,
    endDate,
    activityBusId,
    mechanics,
  }) => {
    const newStartDate = startDate
      ? tzNormalizeDate({ date: startDate })
      : tzNormalizeDate();

    const newEndDate = endDate
      ? tzNormalizeDate({ date: endDate })
      : tzNormalizeDate({ addTime: { amount: 1, unit: 'h' } });

    const newWorkingHour = workingHour || 1;

    const baseLocationId = {
      value: busHomeBaseId,
      label: busHomeBaseName,
    };

    return {
      status:
        status === ACTIVITY_STATUS.PENDING.value
          ? ACTIVITY_STATUS.DONE
          : ACTIVITY_STATUS[status],
      extensionFactor,
      workingHour: newWorkingHour,
      startDate: newStartDate.slice(0, 16),
      endDate: newEndDate.slice(0, 16),
      baseLocationId,
      comment,
      activityBusId,
      mechanics,
    };
  };

  const handleConfirm = (row, index) => {
    setSelectedRowIndex(index);

    const { activityName, areaName, companyActivityId, companyBusId, factor } =
      row;

    const body = (
      <>
        <Row className="text-center">
          <Col sm={3} xs={6}>
            <p>
              <strong>Bus</strong>
              <br />
              {companyBusId}
            </p>
          </Col>
          <Col sm={3} xs={6}>
            <p>
              <strong>Factor</strong>
              <br />
              {numberFormatter({
                style: 'decimal',
                value: factor,
                minimumFractionDigits: 0,
              })}
            </p>
          </Col>
          <Col sm={6} xs={12}>
            <p>
              <strong>Área</strong>
              <br />
              {areaName}
            </p>
          </Col>
        </Row>
        <ExecutionRegistrationForm
          onSubmit={(data) => onSubmitForm(data, index)}
          initialValues={generateInitialValues(row)}
        />
      </>
    );

    setModalTitle(
      <>
        <Badge
          color={REGISTER_OF_EXECUTION_TYPE.false.color}
          text={REGISTER_OF_EXECUTION_TYPE.false.label}
        />{' '}
        Registro de Datos para la Actividad
        <br />
        {companyActivityId} - {activityName}
      </>,
    );
    setModalBody(body);
    onShowModal(true);
  };

  const handleClear = (row) => {
    const index = getFormattedDataIndex(row);
    const newFormattedData = [...formattedData];
    newFormattedData[index] = originalData[index];
    setFormattedData(newFormattedData);
    setSelectedRowIndex(null);
  };

  const modal = (
    <Modal
      show={showModal}
      title={modalTitle}
      onClickClose={onCloseModal}
      body={modalBody}
      size="lg"
    />
  );

  const confirmationModal = (
    <ConfirmationModal
      show={showConfirmationModal}
      onClickConfirm={() => {
        onHandleRegisterExecution(formattedData);
        setShowConfirmationModal(false);
      }}
      onClickCancel={() => setShowConfirmationModal(false)}
    />
  );

  const saveButtonDisabled = loading || formattedData.length === 0;

  return (
    <div className="mt-4">
      <Table
        manual={false}
        columns={generateExecutionRegistrationColumns({
          handleConfirm,
          handleClear,
        })}
        data={formattedData}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
        loading={loadingReport || loading}
        generateStyle={generateStyle}
      />
      <div className="mt-3">
        <FormFooter
          saveButtonAction={() => setShowConfirmationModal(true)}
          saveButtonDisabled={saveButtonDisabled}
          saveButtonText="Registrar Ejecución"
          saveButtonType="button"
        />
      </div>
      {modal}
      {confirmationModal}
    </div>
  );
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loadingReport: MechanicalMaintenanceUnit.PreventiveMaintenanceReport.getIn([
    'all',
    'loading',
  ]),
  activities: MechanicalMaintenanceUnit.PreventiveMaintenanceReport.getIn([
    'all',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchClearPreventiveMaintenanceReport: clearPreventiveMaintenanceReport,
};

PreventiveExecutionRegisterTable.propTypes = {
  loadingReport: PropTypes.bool,
  dispatchClearPreventiveMaintenanceReport: PropTypes.func.isRequired,
  onHandleRegisterExecution: PropTypes.func.isRequired,
  activities: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedOrganizationChart: PropTypes.arrayOf(PropTypes.string),
};

PreventiveExecutionRegisterTable.defaultProps = {
  loadingReport: false,
  selectedOrganizationChart: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreventiveExecutionRegisterTable);
