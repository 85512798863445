import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getExtraordinaryMovementControls,
  clearExtraordinaryMovementControls,
} from '../../../../actions/index';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { EXTRAORDINARY_MOVEMENT_CONTROL_PATH } from '../../../../config/paths';
import { EXTRAORDINARY_MOVEMENT_CONTROL_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class ExtraordinaryMovementControls extends Component {
  componentWillUnmount() {
    const { dispatchClearExtraordinaryMovementControls } = this.props;
    dispatchClearExtraordinaryMovementControls();
  }

  render() {
    const {
      extraordinaryMovementControls,
      loading,
      dispatchGetExtraordinaryMovementControls,
      breadcrumbs,
    } = this.props;

    const data = extraordinaryMovementControls.get('content') || [];
    const pages = extraordinaryMovementControls.get('totalPages') || null;
    const defaultPageSize =
      extraordinaryMovementControls.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Control Movimientos Extraordinarios"
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: EXTRAORDINARY_MOVEMENT_CONTROL_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetExtraordinaryMovementControls,
          modelPath: EXTRAORDINARY_MOVEMENT_CONTROL_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Control Movimientos Extraordinarios',
      href: EXTRAORDINARY_MOVEMENT_CONTROL_PATH,
    },
  ],
  extraordinaryMovementControls: TrafficUnit.ExtraordinaryMovementControl.getIn(
    ['all', 'content'],
  ),
  loading: TrafficUnit.ExtraordinaryMovementControl.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetExtraordinaryMovementControls: getExtraordinaryMovementControls,
  dispatchClearExtraordinaryMovementControls:
    clearExtraordinaryMovementControls,
};

ExtraordinaryMovementControls.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetExtraordinaryMovementControls: PropTypes.func.isRequired,
  dispatchClearExtraordinaryMovementControls: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  extraordinaryMovementControls: PropTypes.instanceOf(Immutable.Map),
};

ExtraordinaryMovementControls.defaultProps = {
  extraordinaryMovementControls: undefined,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExtraordinaryMovementControls);
