import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GEOGRAPHIC_ZONE_PATH } from '../../../../config/paths';
import { postGeographicZone } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import GeographicZoneForm from './GeographicZoneForm';
import Content from '../../../layout/Content';

export class NewGeographicZone extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      name: formValues.name,
      description: formValues.description,
      cityList: formValues.cityList.map((city) => ({
        id: city.city.value,
        name: city.city.label,
      })),
    };
    const { dispatchPostGeographicZone } = this.props;
    dispatchPostGeographicZone(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Zona Geográfica"
        subtitle="Crear una nueva zona geográfica"
        content={<GeographicZoneForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostGeographicZone: postGeographicZone,
};

NewGeographicZone.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostGeographicZone: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGeographicZone);
