import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../layout/Card';
import ModuleHome from '../../layout/ModuleHome';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

export const HumanResourcesHome = ({ cards, breadcrumbs }) => (
  <ModuleHome
    title="Recursos Humanos"
    text="Éste es el módulo de Recursos Humanos."
    cards={cards}
    breadcrumbs={breadcrumbs}
  />
);

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  cards: HumanResourcesUnit.UnitHome.get('cards'),
  breadcrumbs: HumanResourcesUnit.UnitHome.get('breadcrumbs'),
});

HumanResourcesHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default connect(mapStateToProps)(HumanResourcesHome);
