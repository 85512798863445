import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, FormGroup, InputGroupText, Label, Input } from 'reactstrap';
import { Field, reduxForm, Form, change, formValueSelector } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateNumber } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import { CURRENCY } from '../../../../config/locale';
import DatePicker from '../../../common/forms/input/DatePicker';
import { EXPECTED_AMOUNT_TO_DEPOSIT_INFO_MESSAGE } from '../../../../config/messages';
import {
  DAILY_LIQUIDATION_FORM_COLUMNS,
  NOTES_FORM_COLUMNS,
} from '../../../../config/dynamicFormFields';
import LiquidationDifferenceReasonSelect from '../../../common/forms/select/LiquidationDifferenceReasonSelect';
import DepositLiquidationsReource from './resource/DepositLiquitionsResource';
import NoteListResource from '../../../common/resource/NoteListResource';
import {
  DEPOSIT_TYPE,
  DEPOSIT_TYPE_OPTIONS,
  DEPOSIT_FILE_TYPE_PATTERN,
} from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';
import Loader from '../../../common/Loader';
import DepositVoucherResource from './resource/DepositVoucherResource';
import InformationText from '../../../common/informative/InformationText';
import FileDetailResource from '../../../common/resource/FileDetailResource';
import { numberFormatter } from '../../../../utils/number';
import FormFooter from '../../../common/forms/FormFooter';

const selector = formValueSelector('DepositForm');

export class DepositForm extends Component {
  static hasValidLiquidation({ value }, allValues) {
    let duplicateCount = 0;
    allValues.dailyLiquidationSessionSet.forEach((liquidation) => {
      const { dailyLiquidationSession } = liquidation;
      if (dailyLiquidationSession && dailyLiquidationSession.value === value) {
        duplicateCount += 1;
      }
    });

    return duplicateCount >= 2 ? 'Liquidación repetida' : undefined;
  }

  constructor(props) {
    super(props);

    this.state = {
      amountDeposited: 0,
      showLiquidationDifference: false,
      editingMode: false,
      selectedFile: null,
      depositType: null,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    const {
      dispatchChange,
      expectedAmountToDeposit,
      agencyCommission,
      agencyCommissionTaxes,
    } = this.props;

    dispatchChange(
      'DepositForm',
      'expectedAmountToDeposit',
      expectedAmountToDeposit,
    );
    dispatchChange('DepositForm', 'agencyCommission', agencyCommission);
    dispatchChange(
      'DepositForm',
      'agencyCommissionTaxes',
      agencyCommissionTaxes,
    );
    dispatchChange(
      'DepositForm',
      'totalToBill',
      (+agencyCommission + +agencyCommissionTaxes).toFixed(2),
    );
  }

  onMount = () => {
    const { initialValues } = this.props;

    if (initialValues) {
      this.setState({
        editingMode: true,
        amountDeposited: initialValues.actualAmountDeposited,
        depositType: initialValues.depositType.value,
      });

      if (
        Number(initialValues.expectedAmountToDeposit) !==
          Number(initialValues.actualAmountDeposited) &&
        initialValues.depositType.value !== DEPOSIT_TYPE.COMMISSION.value
      ) {
        this.setState({ showLiquidationDifference: true });
      }
    }
  };

  onChangeVoucher = (event) =>
    this.setState({ selectedFile: event.target.files[0] });

  onChangeDepositType = (option) =>
    this.setState({ depositType: option ? option.value : null });

  onBlurCashOnHand = () => {
    this.validateDepositDifference();
  };

  validateDepositDifference = () => {
    const { amountDeposited, depositType } = this.state;

    let showLiquidationDifference = false;

    if (depositType !== DEPOSIT_TYPE.COMMISSION.value) {
      const { expectedAmountToDeposit } = this.props;

      if (Number(amountDeposited) === Number(expectedAmountToDeposit)) {
        showLiquidationDifference = false;
      } else {
        showLiquidationDifference = true;
      }

      this.setState({ showLiquidationDifference });
    }

    return showLiquidationDifference;
  };

  validateImageFile = () => {
    const { selectedFile } = this.state;

    let valid = true;

    if (selectedFile && !selectedFile.type.match(DEPOSIT_FILE_TYPE_PATTERN)) {
      toastr.error('Error', 'Sólo se permiten archivo de tipo: imagen/pdf');
      valid = false;
    }

    return valid;
  };

  handleDepositDifference = (formValues) => {
    const { onSubmit, expectedAmountToDeposit } = this.props;

    const { amountDeposited, selectedFile } = this.state;

    const newFormValues = {
      depositDate: formValues.depositDate,
      accountNumber: formValues.accountNumber,
      bankName: formValues.bankName,
      operationNumber: formValues.operationNumber,
      expectedAmountToDeposit,
      actualAmountDeposited: formValues.actualAmountDeposited,
      depositDifferenceDescription: formValues.depositDifferenceDescription,
      depositDifferenceReasonId: formValues.depositDifferenceReasonId,
      dailyLiquidationSessionSet: formValues.dailyLiquidationSessionSet,
      depositType: formValues.depositType,
    };

    const { editingMode } = this.state;

    if (editingMode) {
      newFormValues.depositNoteList = formValues.depositNoteList;
      newFormValues.settled = formValues.settled;
    }

    if (
      !formValues.dailyLiquidationSessionSet ||
      !formValues.dailyLiquidationSessionSet.length
    ) {
      toastr.error('Error', 'Debe seleccionar al menos una liquidación');
    } else if (this.validateDepositDifference()) {
      if (formValues.depositDifferenceReasonId) {
        newFormValues.depositDifferenceAmount =
          +expectedAmountToDeposit - +amountDeposited;

        if (this.validateImageFile()) {
          newFormValues.file = selectedFile;
          onSubmit(newFormValues);
        }
      }
    } else {
      delete newFormValues.depositDifferenceReasonId;
      delete newFormValues.depositDifferenceDescription;

      if (this.validateImageFile()) {
        newFormValues.file = selectedFile;
        onSubmit(newFormValues);
      }
    }
  };

  render() {
    const {
      handleSubmit,
      expectedAmountToDeposit,
      initialValues,
      depositNoteList,
      loading,
      voucher,
      agencyCommission,
      agencyCommissionTaxes,
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    const {
      showLiquidationDifference,
      amountDeposited,
      editingMode,
      selectedFile,
    } = this.state;

    let depositDifference;

    if (showLiquidationDifference) {
      depositDifference = (
        <Fragment>
          <h2>Discrepancia</h2>
          <h5>
            Monto de Discrepancia:{' '}
            {numberFormatter({
              value: +expectedAmountToDeposit - +amountDeposited,
            })}
          </h5>
          <FormGroup row>
            <FormItem label="Razón" required>
              <Field
                name="depositDifferenceReasonId"
                component={LiquidationDifferenceReasonSelect}
                isDisabled={editingMode}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Descripción">
              <Field
                name="depositDifferenceDescription"
                component={TextInput}
                disabled={editingMode}
                type="textarea"
              />
            </FormItem>
          </FormGroup>
        </Fragment>
      );
    }

    let liquidationTable = (
      <Fragment>
        <h3>Liquidaciones</h3>
        <DynamicForm
          name="dailyLiquidationSessionSet"
          columns={DAILY_LIQUIDATION_FORM_COLUMNS}
        />
      </Fragment>
    );

    let editingFields = null;

    if (editingMode) {
      liquidationTable = (
        <DepositLiquidationsReource
          dailyLiquidationSessionSet={initialValues.dailyLiquidationSessionSet}
        />
      );

      editingFields = (
        <Fragment>
          <br />
          <h3>Notas</h3>
          <NoteListResource values={depositNoteList} />
          <DynamicForm name="depositNoteList" columns={NOTES_FORM_COLUMNS} />
          <FormGroup row>
            <FormItem label="Estado">
              <Label>
                <Field name="settled" component="input" type="checkbox" />{' '}
                Aprobado
              </Label>
            </FormItem>
          </FormGroup>
        </Fragment>
      );
    }

    const fileDetailResource = <FileDetailResource file={selectedFile} />;

    let commissionFields = null;

    if (agencyCommission > 0) {
      commissionFields = (
        <>
          <FormGroup row>
            <FormItem label="Comisión" required>
              <Field
                name="agencyCommission"
                component={TextInput}
                type="text"
                disabled
                placeholder="0.00"
                validate={[isRequired, validateNumber]}
                value={agencyCommission}
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="IGV" required>
              <Field
                name="agencyCommissionTaxes"
                component={TextInput}
                type="text"
                disabled
                placeholder="0.00"
                validate={[isRequired, validateNumber]}
                value={agencyCommissionTaxes}
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Total a Facturar" required>
              <Field
                name="totalToBill"
                component={TextInput}
                type="text"
                disabled
                placeholder="0.00"
                validate={[isRequired, validateNumber]}
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
              />
            </FormItem>
          </FormGroup>
        </>
      );
    }

    return (
      <Form onSubmit={handleSubmit(this.handleDepositDifference)}>
        <FormGroup row>
          <FormItem label="Nombre del Banco" required>
            <Field
              name="bankName"
              component={TextInput}
              placeholder="Nombre del Banco"
              disabled={editingMode}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Número de Cuenta" required>
            <Field
              name="accountNumber"
              component={TextInput}
              placeholder="Número de Cuenta"
              disabled={editingMode}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Número de Operación" required>
            <Field
              name="operationNumber"
              component={TextInput}
              placeholder="Número de Operación"
              disabled={editingMode}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Tipo de Depósito">
            <Field
              name="depositType"
              component={Select}
              isDisabled={editingMode}
              onChange={this.onChangeDepositType}
              options={DEPOSIT_TYPE_OPTIONS}
              isClearable
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Fecha de Depósito" required>
            <Field
              name="depositDate"
              props={{
                time: true,
              }}
              component={DatePicker}
              placeholder="Fecha de Depósito"
              disabled={editingMode}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {liquidationTable}
        {commissionFields}
        <FormGroup row>
          <FormItem label="Monto Esperado para Depositar" required>
            <Field
              name="expectedAmountToDeposit"
              component={TextInput}
              type="text"
              disabled
              placeholder="0.00"
              validate={[isRequired, validateNumber]}
              value={expectedAmountToDeposit}
              append={<InputGroupText>{CURRENCY}</InputGroupText>}
            />
            <InformationText text={EXPECTED_AMOUNT_TO_DEPOSIT_INFO_MESSAGE} />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Importe Real Depositado" required>
            <Field
              name="actualAmountDeposited"
              component={TextInput}
              type="text"
              placeholder="0.00"
              append={<InputGroupText>{CURRENCY}</InputGroupText>}
              onBlur={this.onBlurCashOnHand}
              onChange={(e) =>
                this.setState({ amountDeposited: e.target.value })
              }
              disabled={editingMode}
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        {depositDifference}
        {editingFields}
        <FormGroup row>
          <Col sm={3} xs={12}>
            <Label>Comprobante del Depósito</Label>
          </Col>
          <Col sm={9} xs={12}>
            <Input
              name="voucher"
              type="file"
              onChange={(e) => this.onChangeVoucher(e)}
            />
            {fileDetailResource}
          </Col>
        </FormGroup>
        <DepositVoucherResource file={voucher} />
        <FormFooter />
      </Form>
    );
  }
}

DepositForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  expectedAmountToDeposit: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  agencyCommission: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  agencyCommissionTaxes: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  initialValues: PropTypes.shape({
    actualAmountDeposited: PropTypes.number,
    expectedAmountToDeposit: PropTypes.number,
    dailyLiquidationSessionSet: PropTypes.arrayOf(
      PropTypes.shape({
        agency: PropTypes.shape({
          name: PropTypes.string,
        }),
        createDate: PropTypes.number,
        cashToDeposit: PropTypes.number,
        operatorUser: PropTypes.shape({
          customer: PropTypes.shape({
            fullName: PropTypes.string,
          }),
        }),
      }),
    ),
    depositType: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  depositNoteList: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
    }),
  ),
  voucher: PropTypes.instanceOf(Immutable.Set),
};

DepositForm.defaultProps = {
  expectedAmountToDeposit: 0,
  agencyCommission: 0,
  agencyCommissionTaxes: 0,
  initialValues: null,
  depositNoteList: null,
  voucher: null,
};

const mapStateToProps = (state, { initialValues }) => {
  const dailyLiquidationSessionSet = selector(
    state,
    'dailyLiquidationSessionSet',
  );

  let expectedAmountToDeposit = 0;
  let agencyCommission = 0;
  let agencyCommissionTaxes = 0;

  if (initialValues) {
    // eslint-disable-next-line prefer-destructuring
    expectedAmountToDeposit = initialValues.expectedAmountToDeposit;
    // eslint-disable-next-line prefer-destructuring
    agencyCommission = initialValues.agencyCommission;
    // eslint-disable-next-line prefer-destructuring
    agencyCommissionTaxes = initialValues.agencyCommissionTaxes;
  } else if (dailyLiquidationSessionSet) {
    dailyLiquidationSessionSet.forEach(({ dailyLiquidationSession }) => {
      expectedAmountToDeposit += dailyLiquidationSession
        ? dailyLiquidationSession.cashOnHand
        : 0;
      agencyCommission += dailyLiquidationSession
        ? dailyLiquidationSession.commission
        : 0;
      agencyCommissionTaxes += dailyLiquidationSession
        ? dailyLiquidationSession.commissionTaxes
        : 0;
    });
  }

  return {
    expectedAmountToDeposit: expectedAmountToDeposit.toFixed(2),
    agencyCommission: agencyCommission.toFixed(2),
    agencyCommissionTaxes: agencyCommissionTaxes.toFixed(2),
    loading: !state.SalesUnit.Deposit.getIn(['current', 'activity']).isEmpty(),
  };
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'DepositForm',
})(DepositForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
