import { push } from 'react-router-redux';
import {
  AUTHENTICATE_USER,
  FLAG_AUTHENTICATING_USER,
  CLEAR_ERROR_AUTHENTICATING_USER,
  SHOW_ERROR_AUTHENTICATING_USER,
  SHOW_ERROR_FETCHING_SELF,
  FETCH_SELF,
  DEAUTHENTICATE_USER,
  SHOW_ERROR_DEAUTHENTICATING_USER,
  FLAG_DEAUTHENTICATING_USER,
  CLEAR_ERROR_DEAUTHENTICATING_USER,
} from './types';
import { HOME_PATH } from '../config/paths';
import {
  ENDPOINT_CONFIGURATION_ENDPOINT,
  SIGN_IN_ENDPOINT,
  SIGN_OUT_ENDPOINT,
  BASE_PUBLIC_URL,
  SELF_ENDPOINT,
} from '../config/endpoints';
import { handleResponseError } from '../utils/error-handlers';
import Cookie from '../utils/Cookie';

// todo: handle different environments
// requires pointing 127.0.0.1 to local.civa.io
const AUTH_HEADER = 'Basic bXktdHJ1c3RlZC1jbGllbnQ6MTIzNDU=';

const flagAuthenticatingUser = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_AUTHENTICATING_USER,
  });

const flagDeauthenticatingUser = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_DEAUTHENTICATING_USER,
  });

const clearErrorAuthenticatingUser = () => ({
  type: CLEAR_ERROR_AUTHENTICATING_USER,
});

const clearErrorDeauthenticatingUser = () => ({
  type: CLEAR_ERROR_DEAUTHENTICATING_USER,
});

const fetchSelf = async () => async (dispatch) => {
  dispatch(flagAuthenticatingUser(true));
  const url = SELF_ENDPOINT;
  try {
    const response = await fetch(url, { credentials: 'include' });
    const error = await handleResponseError(response);
    if (error) {
      dispatch(flagAuthenticatingUser(false));
      dispatch({
        type: SHOW_ERROR_FETCHING_SELF,
        payload: error,
      });
    }
    const user = await response.json();
    // this dispatch has to come before lowering
    // the flag otherwise the redirects kick in
    dispatch({
      type: FETCH_SELF,
      payload: user,
    });
    dispatch(flagAuthenticatingUser(false));
  } catch (err) {
    console.error(err);
    dispatch(flagAuthenticatingUser(false));
    dispatch({
      type: SHOW_ERROR_FETCHING_SELF,
    });
  }
};

const authenticateUser =
  async ({ email, password }) =>
  async (dispatch) => {
    dispatch(clearErrorAuthenticatingUser());
    dispatch(flagAuthenticatingUser(true));
    const url = BASE_PUBLIC_URL + ENDPOINT_CONFIGURATION_ENDPOINT;
    try {
      const authorizationServerResponse = await fetch(url);

      // check for response errors
      const authorizationServerError = await handleResponseError(
        authorizationServerResponse,
      );
      if (authorizationServerError) {
        dispatch({
          type: SHOW_ERROR_AUTHENTICATING_USER,
          payload: authorizationServerError,
        });
      }

      // prepare form
      const formData = new FormData();
      formData.append('username', email);
      formData.append('password', password);
      formData.append('grant_type', 'password');

      const { authorizationServer } = await authorizationServerResponse.json();
      const loginUrl = authorizationServer + SIGN_IN_ENDPOINT;
      const loginResponse = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          Authorization: AUTH_HEADER,
        },
        body: formData,
      });

      // check for response errors
      const loginError = await handleResponseError(loginResponse);
      if (loginError) {
        dispatch({
          type: SHOW_ERROR_AUTHENTICATING_USER,
          payload: loginError,
        });
      }

      // the response is a response instance
      // parse the data into a usable format using `.json()`
      const accessObj = await loginResponse.json();
      const accessToken = accessObj.access_token;
      if (accessToken) {
        // update state to indicate that the user is authenticated
        dispatch({
          type: AUTHENTICATE_USER,
          payload: accessToken,
        });
        await dispatch(fetchSelf());

        // if there is a redirect handle it here
        const redirect = sessionStorage.getItem('redirect');
        if (redirect) {
          sessionStorage.removeItem('redirect');
          dispatch(push(redirect));
        }

        // todo: remove when all front end has been migrated
        if (HOME_PATH !== '/') {
          window.location.href = HOME_PATH;
        }
        dispatch(push(HOME_PATH));
      }

      dispatch({
        type: SHOW_ERROR_AUTHENTICATING_USER,
        payload:
          'Se produjo un error al ingresar a la aplicación. Ficha de acceso inválida.',
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: SHOW_ERROR_AUTHENTICATING_USER,
        payload: 'Error en el proceso de autenticación.',
      });
    } finally {
      dispatch(flagAuthenticatingUser(false));
    }
  };

const deauthenticateUser = async () => async (dispatch) => {
  dispatch(clearErrorDeauthenticatingUser());
  dispatch(flagDeauthenticatingUser(true));
  const url = BASE_PUBLIC_URL + ENDPOINT_CONFIGURATION_ENDPOINT;
  const accessToken = Cookie.get('access_token');
  if (accessToken) {
    try {
      const authorizationServerResponse = await fetch(url);

      // check for response errors
      const authorizationServerError = await handleResponseError(
        authorizationServerResponse,
      );
      if (authorizationServerError) {
        return dispatch({
          type: SHOW_ERROR_DEAUTHENTICATING_USER,
          payload: authorizationServerError,
        });
      }

      const { authorizationServer } = await authorizationServerResponse.json();
      const signOutUrl = `${
        authorizationServer + SIGN_OUT_ENDPOINT
      }/${accessToken}`;
      const signOutResponse = await fetch(signOutUrl, {
        method: 'DELETE',
      });
      // check for response errors
      const signOutError = await handleResponseError(signOutResponse);
      if (signOutError) {
        return dispatch({
          type: SHOW_ERROR_DEAUTHENTICATING_USER,
          payload: signOutError,
        });
      }
      dispatch(flagDeauthenticatingUser(false));
      // update state to indicate that the user is deauthenticated
      return dispatch({
        type: DEAUTHENTICATE_USER,
      });
    } catch (err) {
      // lower flag
      dispatch(flagDeauthenticatingUser(false));
      console.error(err);
      return dispatch({
        type: SHOW_ERROR_DEAUTHENTICATING_USER,
        payload: 'Error en el proceso de salida de la aplicación.',
      });
    }
  }
  return dispatch({
    type: DEAUTHENTICATE_USER,
  });
};

export * from './report';
export * from './bus';
export * from './traffic';
export * from './route';
export * from './booking';
export * from './location';
export * from './accounting';
export * from './user';
export * from './sales';
export * from './itinerary';
export * from './reservation';
export * from './human-resources';
export * from './authentication';
export * from './baggage';
export * from './cargo';
export * from './contract';
export * from './security';
export * from './system';
export * from './mechanical-maintenance';

export {
  authenticateUser,
  clearErrorAuthenticatingUser,
  fetchSelf,
  deauthenticateUser,
  flagAuthenticatingUser,
};
