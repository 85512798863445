import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import { EXTRAORDINARY_MOVEMENT_CONTROL_STATUS } from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import FormFooter from '../../../common/forms/FormFooter';

const ExtraordinaryMovementControlForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Bus" required>
        <Field
          name="registeredBusId"
          component={RegisteredBusSelect}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Comentarios">
        <Field
          name="comments"
          component={TextInput}
          type="textarea"
          placeholder="Comentarios"
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Estado" required>
        <Field
          name="status"
          component={Select}
          options={enumToSelectOptions(EXTRAORDINARY_MOVEMENT_CONTROL_STATUS)}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Origen" required>
        <Row>
          <Col sm="6">
            <Field
              name="initialSourceLocationName"
              component={TextInput}
              type="text"
              placeholder="Origen Inicial"
              validate={[isRequired]}
              disabled
            />
          </Col>
          <Col sm="6">
            <Field
              name="realSourceLocationName"
              component={TextInput}
              type="text"
              placeholder="Origen Real"
              validate={[isRequired]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Destino" required>
        <Row>
          <Col sm="6">
            <Field
              name="initialDestinationLocationName"
              disabled
              component={TextInput}
              type="text"
              placeholder="Destino Inicial"
              validate={[isRequired]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="realDestinationLocationName"
              component={TextInput}
              type="text"
              placeholder="Destino Real"
              validate={[isRequired]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Kilometraje" required>
        <Row>
          <Col sm="6">
            <Field
              name="initialKilometers"
              disabled
              component={TextInput}
              type="text"
              placeholder="Kilometraje Inicial"
              validate={[isRequired, validateNumber]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="realKilometers"
              component={TextInput}
              type="text"
              placeholder="Kilometraje Real"
              validate={[isRequired, validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Combustible" required>
        <Row>
          <Col sm="6">
            <Field
              name="expectedFuelConsumption"
              component={TextInput}
              type="text"
              placeholder="Combustible Inicial"
              validate={[isRequired, validateNumber]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="authorizedFuelConsumption"
              component={TextInput}
              disabled
              type="text"
              placeholder="Combustible Real"
              validate={[isRequired, validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Consumo Adblue" required>
        <Row>
          <Col sm="6">
            <Field
              name="expectedAdblueConsumption"
              component={TextInput}
              type="text"
              placeholder="Consumo Adblue Inicial"
              validate={[isRequired, validateNumber]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="realAdblueConsumption"
              component={TextInput}
              disabled
              type="text"
              placeholder="Consumo Adblue Real"
              validate={[isRequired, validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Peaje" required>
        <Row>
          <Col sm="6">
            <Field
              name="initialTollAmount"
              disabled
              component={TextInput}
              type="text"
              placeholder="Peaje Inicial"
              validate={[isRequired, validateNumber]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="realTollAmount"
              component={TextInput}
              type="text"
              placeholder="Peaje Real"
              validate={[isRequired, validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Comisión Piloto" required>
        <Row>
          <Col sm="6">
            <Field
              name="initialDriverCommission"
              disabled
              component={TextInput}
              type="text"
              placeholder="Comisión Piloto Inicial"
              validate={[isRequired, validateNumber]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="realDriverCommission"
              component={TextInput}
              type="text"
              placeholder="Comisión Piloto Real"
              validate={[isRequired, validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Comisión Tripulante" required>
        <Row>
          <Col sm="6">
            <Field
              name="initialCabinCrewCommission"
              disabled
              component={TextInput}
              type="text"
              placeholder="Comisión Tripulante Inicial"
              validate={[isRequired, validateNumber]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="realCabinCrewCommission"
              component={TextInput}
              type="text"
              placeholder="Comisión Tripulante Real"
              validate={[isRequired, validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Otros Gastos" required>
        <Row>
          <Col sm="6">
            <Field
              name="initialOtherExpenses"
              disabled
              component={TextInput}
              type="text"
              placeholder="Otros Gastos Inicial"
              validate={[isRequired, validateNumber]}
            />
          </Col>
          <Col sm="6">
            <Field
              name="realOtherExpenses"
              component={TextInput}
              type="text"
              placeholder="Otros Gastos Real"
              validate={[isRequired, validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

ExtraordinaryMovementControlForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ExtraordinaryMovementControlForm',
})(ExtraordinaryMovementControlForm);
