import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { numberFormatter } from '../../../../../utils/number';

const LiquidationCashResource = ({
  totalCashIncome,
  totalCashExpense,
  totalCash,
}) => (
  <Fragment>
    <hr />
    <h3>Efectivo</h3>
    <ResourceProperty label="Ingresos Totales en Efectivo:">
      {numberFormatter({ value: totalCashIncome })}
    </ResourceProperty>
    <ResourceProperty label="Egresos Totales en Efectivo:">
      {numberFormatter({ value: totalCashExpense })}
    </ResourceProperty>
    <h4 className="text-right">
      Ingresos Netos en Efectivo: {numberFormatter({ value: totalCash })}
    </h4>
  </Fragment>
);

LiquidationCashResource.propTypes = {
  totalCashIncome: PropTypes.number.isRequired,
  totalCashExpense: PropTypes.number.isRequired,
  totalCash: PropTypes.number.isRequired,
};

export default LiquidationCashResource;
