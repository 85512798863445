import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH } from '../../config/paths';
import {
  CLEAR_PAYMENT_METHOD_FOR_SECURITY_PROFILE,
  CLEAR_PAYMENT_METHODS_FOR_SECURITY_PROFILES,
  FLAG_GETTING_PAYMENT_METHODS_FOR_SECURITY_PROFILES,
  FLAG_PAYMENT_METHOD_FOR_SECURITY_PROFILE_ACTIVITY,
  GET_PAYMENT_METHOD_FOR_SECURITY_PROFILE,
  GET_PAYMENT_METHODS_FOR_SECURITY_PROFILES,
} from '../types/security/PaymentMethodForSecurityProfile';
import { PAYMENT_METHOD_FOR_SECURITY_PROFILE_ENDPOINT } from '../../config/endpoints';

const flagGettingPaymentMethodsForSecurityProfiles = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PAYMENT_METHODS_FOR_SECURITY_PROFILES,
    payload: flag,
  });

const getPaymentMethodsForSecurityProfiles =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingPaymentMethodsForSecurityProfiles(true));
      const query = tableFilters;
      const url = `${PAYMENT_METHOD_FOR_SECURITY_PROFILE_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_PAYMENT_METHODS_FOR_SECURITY_PROFILES,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingPaymentMethodsForSecurityProfiles(false));
    }
  };

const clearPaymentMethodsForSecurityProfiles = () => (dispatch) =>
  dispatch({
    type: CLEAR_PAYMENT_METHODS_FOR_SECURITY_PROFILES,
  });

const flagPaymentMethodForSecurityProfileActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PAYMENT_METHOD_FOR_SECURITY_PROFILE_ACTIVITY,
    payload: flag,
  });

const getPaymentMethodForSecurityProfile =
  async ({ paymentMethodForSecurityProfileId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPaymentMethodForSecurityProfileActivity(true));
      const url = `${PAYMENT_METHOD_FOR_SECURITY_PROFILE_ENDPOINT}/${paymentMethodForSecurityProfileId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_PAYMENT_METHOD_FOR_SECURITY_PROFILE,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPaymentMethodForSecurityProfileActivity(false));
    }
  };

const clearPaymentMethodForSecurityProfile = () => (dispatch) =>
  dispatch({
    type: CLEAR_PAYMENT_METHOD_FOR_SECURITY_PROFILE,
  });

const postPaymentMethodForSecurityProfile =
  async ({ securityProfileId, paymentMethodId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPaymentMethodForSecurityProfileActivity(true));
      const payload = {
        securityProfileId,
        paymentMethodId,
      };
      const url = PAYMENT_METHOD_FOR_SECURITY_PROFILE_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPaymentMethodForSecurityProfileActivity(false));
    }
  };

const deletePaymentMethodForSecurityProfile =
  async ({ paymentMethodForSecurityProfileId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPaymentMethodForSecurityProfileActivity(true));
      const url = `${PAYMENT_METHOD_FOR_SECURITY_PROFILE_ENDPOINT}/${paymentMethodForSecurityProfileId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPaymentMethodForSecurityProfileActivity(false));
    }
  };

const putPaymentMethodForSecurityProfile =
  async (
    paymentMethodForSecurityProfileId,
    { securityProfileId, paymentMethodId },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagPaymentMethodForSecurityProfileActivity(true));
      const payload = {
        id: paymentMethodForSecurityProfileId,
        securityProfileId,
        paymentMethodId,
      };
      const url = `${PAYMENT_METHOD_FOR_SECURITY_PROFILE_ENDPOINT}/${paymentMethodForSecurityProfileId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagPaymentMethodForSecurityProfileActivity(false));
    }
  };

export {
  getPaymentMethodsForSecurityProfiles,
  clearPaymentMethodsForSecurityProfiles,
  getPaymentMethodForSecurityProfile,
  postPaymentMethodForSecurityProfile,
  clearPaymentMethodForSecurityProfile,
  deletePaymentMethodForSecurityProfile,
  putPaymentMethodForSecurityProfile,
};
