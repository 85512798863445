import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';

const CargoCorporateCreditAddCreditLineButton = ({
  cargoCorporateCreditId,
}) => (
  <Link
    className="btn btn-light border"
    to={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/add-credit-line`}
  >
    <i className="fa fa-dollar" /> Agregar saldo
  </Link>
);

CargoCorporateCreditAddCreditLineButton.propTypes = {
  cargoCorporateCreditId: PropTypes.number.isRequired,
};

export default CargoCorporateCreditAddCreditLineButton;
