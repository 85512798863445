import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import BusinessSelect from '../../../common/forms/select/BusinessSelect';
import { COMPANY_TYPE_OPTIONS } from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';
import FormFooter from '../../../common/forms/FormFooter';

export const CompanyForm = ({ loading, handleSubmit }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Empresa Relacionada" required>
          <Field
            name="businessId"
            component={BusinessSelect}
            placeholder="Busca por el RUC de la empresa"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Empresa Relacionada" required>
          <Field
            name="companyType"
            component={Select}
            isClearable={false}
            options={COMPANY_TYPE_OPTIONS}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

CompanyForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ContractUnit }) => ({
  loading: !ContractUnit.Company.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'CompanyForm',
})(CompanyForm);

export default connect(mapStateToProps)(formComponent);
