import React from 'react';
import { numberFormatter } from '../../../../utils/number';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { liquidationSessionPropTypes } from './SalesSessionPropTypes';

const Difference = ({ liquidationSession }) => {
  if (!liquidationSession.liquidationDifferenceReason) {
    return null;
  }

  return (
    <>
      <hr />
      <h2>Diferencia</h2>
      <ResourceProperty label="Monto: ">
        {numberFormatter({
          value: liquidationSession.liquidationDifferenceAmount,
        })}
      </ResourceProperty>
      <ResourceProperty label="Motivo: ">
        {liquidationSession.liquidationDifferenceReason.name}
      </ResourceProperty>
      <ResourceProperty label="Descripción: ">
        {liquidationSession.liquidationDifferenceReasonDescription}
      </ResourceProperty>
    </>
  );
};

Difference.propTypes = {
  liquidationSession: liquidationSessionPropTypes,
};

Difference.defaultProps = {
  liquidationSession: undefined,
};

export default Difference;
