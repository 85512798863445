// types should always be imperative verbs

export const SHOW_ERROR_POSTING_EXPENSE_REPORT =
  'SHOW_ERROR_POSTING_EXPENSE_REPORT';
export const CLEAR_ERROR_POSTING_EXPENSE_REPORT =
  'CLEAR_ERROR_POSTING_EXPENSE_REPORT';
export const SHOW_ERROR_GETTING_EXPENSE_REPORT =
  'SHOW_ERROR_GETTING_EXPENSE_REPORT';
export const SHOW_ERROR_GETTING_EXPENSE_REPORTS =
  'SHOW_ERROR_GETTING_EXPENSE_REPORTS';
export const CLEAR_ERROR_GETTING_EXPENSE_REPORT =
  'CLEAR_ERROR_GETTING_EXPENSE_REPORT';
export const CLEAR_ERROR_GETTING_EXPENSE_REPORTS =
  'CLEAR_ERROR_GETTING_EXPENSE_REPORTS';
export const CLEAR_EXPENSE_REPORTS = 'CLEAR_EXPENSE_REPORTS';
export const CLEAR_EXPENSE_REPORT = 'CLEAR_EXPENSE_REPORT';
export const POST_EXPENSE_REPORT = 'POST_EXPENSE_REPORT';
export const GET_EXPENSE_REPORTS = 'GET_EXPENSE_REPORTS';
export const GET_EXPENSE_REPORT = 'GET_EXPENSE_REPORT';
export const DOWNLOAD_EXPENSE_REPORT = 'DOWNLOAD_EXPENSE_REPORT';
export const FLAG_GETTING_EXPENSE_REPORT = 'FLAG_GETTING_EXPENSE_REPORT';
export const FLAG_GETTING_EXPENSE_REPORTS = 'FLAG_GETTING_EXPENSE_REPORTS';
export const FLAG_POSTING_EXPENSE_REPORT = 'FLAG_POSTING_EXPENSE_REPORT';
