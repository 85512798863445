import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { TICKET_STATUS_OPTIONS } from '../../../config/constants';

const TicketStatusFilter = ({ onChangeFilter }) => (
  <ReactSelect
    isSearchable={false}
    isClearable
    menuPortalTarget={document.body}
    styles={{ menuPortal: base => ({ ...base, zIndex: 1000 }) }}
    onChange={option => onChangeFilter(option ? option.value : null)}
    menuPosition="fixed"
    menuPlacement="bottom"
    placeholder="Selecciona ..."
    options={TICKET_STATUS_OPTIONS}
  />
);

TicketStatusFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default TicketStatusFilter;
