import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { padStart } from '../../../../utils/string';
import { tzNormalizeDate } from '../../../../utils/date';

const EditableTextTd = ({
  text,
  onValueChange,
  id,
  padStart: padStartFlag,
  padLength,
  padText,
  type,
  placeholder,
  name,
  showControlOnly,
}) => {
  const [editable, setEditable] = useState(false);
  const [innerText, setInnerText] = useState(text);

  const handleTdOnClick = () => !editable && setEditable(true);

  const handleInputBlur = () => setEditable(false);

  const handleInputFocus = () => {
    if (text === '') {
      const now = tzNormalizeDate().slice(0, 16);
      setInnerText(now);
      onValueChange({ id, value: now, displayText: now, columnKey: name });
    }
  };

  const handleInputChange = ({ target: { value } }) => {
    setInnerText(value);
    onValueChange({ id, value, displayText: value, columnKey: name });
  };

  if (editable || showControlOnly) {
    const componentProps = {
      type,
      autoFocus: true,
      value: innerText,
      onChange: handleInputChange,
      onBlur: handleInputBlur,
      className: 'text-center form-control',
      name,
    };

    if (placeholder) componentProps.placeholder = placeholder;
    if (showControlOnly) componentProps.autoFocus = false;
    if (type === 'datetime-local') componentProps.onFocus = handleInputFocus;

    return <input {...componentProps} />;
  }

  return (
    <div
      onClick={handleTdOnClick}
      onKeyPress={handleTdOnClick}
      role="button"
      tabIndex={0}
    >
      {padStartFlag
        ? padStart(innerText, padLength, padText)
        : innerText || '-'}
    </div>
  );
};

EditableTextTd.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onValueChange: PropTypes.func.isRequired,
  padStart: PropTypes.bool,
  padLength: PropTypes.number,
  padText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  showControlOnly: PropTypes.bool,
};

EditableTextTd.defaultProps = {
  padStart: false,
  padLength: null,
  padText: null,
  placeholder: null,
  type: 'text',
  showControlOnly: false,
};

export default EditableTextTd;
