import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import Select from '../../../common/forms/select/Select';
import { MATERIAL_MEASUREMENT_UNIT } from '../../../../config/constants';

export const MaterialForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Código" required>
          <Field
            name="externalId"
            component={TextInput}
            placeholder="Código"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Unidad de Medida" required>
          <Field
            name="measurementUnit"
            component={Select}
            options={MATERIAL_MEASUREMENT_UNIT.map((unit) => ({
              value: unit,
              label: unit,
            }))}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

MaterialForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.Material.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'MaterialForm',
})(MaterialForm);

export default connect(mapStateToProps)(formComponent);
