import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { tzNormalizeDate } from '../../../../utils/date';
import { driverFuelReportRouteRowListPropTypes } from './FuelConsumptionReportPerDriverPropTypes';
import { DATE_FORMAT } from '../../../../config/locale';

const DriverFuelReportRouteRowListResource = ({ data }) => {
  if (!data) {
    return null;
  }

  const dataSorted = data.sort((a, b) => a.departureTime - b.departureTime);

  const tbody = dataSorted.map((row, index) => (
    <tr key={+index}>
      <td className="border-right-black">{+index + 1}</td>
      <td className="border-right-black">{row.driver.customer.fullName}</td>
      <td className="border-right-black">
        {tzNormalizeDate({ date: row.departureTime, format: DATE_FORMAT })}
      </td>
      <td className="border-right-black">{row.companyBusId}</td>
      <td className="border-right-black">{row.sourceLocationName}</td>
      <td className="border-right-black">{row.destinationLocationName}</td>
      <td className="border-right-black">{row.kilometers}</td>
      <td className="border-right-black">{row.expectedFuelConsumption}</td>
      <td className="border-right-black">{row.realFuelConsumption}</td>
      <td className="border-right-black">{row.fuelVoucherCodes}</td>
      <td className="border-right-black">{row.baseFuelRefillGallon}</td>
      <td className="border-right-black">{null}</td>
      <td className="border-right-black">{row.coDriverName}</td>
      <td className="border-right-black">{row.busFuelGroupId}</td>
    </tr>
  ));

  return (
    <Row>
      <Col>
        <Table
          striped
          responsive
          role="grid"
          className="border-black text-center"
        >
          <thead>
            <tr>
              <th colSpan={15} className="title border-black">
                COMBUSTIBLE - REPORTE CONTROL CONDUCTOR (Detalle Itinerarios)
              </th>
            </tr>
            <tr className="shadow">
              <th className="border-black">&nbsp;</th>
              <th className="border-black">Nombre Conductor</th>
              <th className="border-black">Fecha</th>
              <th className="border-black"># Bus</th>
              <th className="border-black">Origen</th>
              <th className="border-black">Destino</th>
              <th className="border-black">Km</th>
              <th className="border-black">Dotación Aut.</th>
              <th className="border-black">Gal. Relleno(s)</th>
              <th className="border-black"># Vale(s) Combustible</th>
              <th className="border-black">Abastecimiento en Base (gal)</th>
              <th className="border-black">AdBlue</th>
              <th className="border-black">Conductor Acompañante</th>
              <th className="border-black">Grupo Bus</th>
            </tr>
          </thead>
          <tbody>{tbody}</tbody>
        </Table>
      </Col>
    </Row>
  );
};

DriverFuelReportRouteRowListResource.propTypes = {
  data: driverFuelReportRouteRowListPropTypes,
};

DriverFuelReportRouteRowListResource.defaultProps = {
  data: null,
};

export default DriverFuelReportRouteRowListResource;
