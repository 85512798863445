import { string, shape, number } from 'prop-types';

const parentCompanyAreaPropTypes = {
  parentCompanyArea: shape({ name: string }),
};

const organizationChartBasicInformationPropTypes = {
  name: string.isRequired,
  description: string,
  companyAreaType: shape({
    id: number.isRequired,
    name: string.isRequired,
  }).isRequired,
  ...parentCompanyAreaPropTypes,
};

const organizationChartBasicInformationDefaultProps = {
  description: null,
  parentCompanyArea: null,
};

export {
  organizationChartBasicInformationPropTypes,
  organizationChartBasicInformationDefaultProps,
  parentCompanyAreaPropTypes,
};
