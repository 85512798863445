import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Button } from 'reactstrap';
import GridRow from './GridRow';
import { gridColumnsPropTypes } from './GridPropTypes';

class Grid extends Component {
  static propTypes = {
    columns: gridColumnsPropTypes.isRequired,
    onValueChange: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    initialValues: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    initialValues: [],
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = { rows: this.props.initialValues };
  }

  componentDidMount() {
    this.sendRowsToParentComponent();
  }

  handleValueChange = (rowIndex) => (columnKey) => (newValue) => {
    const rowValues = [...this.state.rows];
    if (!newValue) {
      delete rowValues[rowIndex][columnKey];
    } else if (typeof newValue === 'string' && !newValue.trim()) {
      delete rowValues[rowIndex][columnKey];
    } else {
      // get the row value state acoording to the event parent container
      const indexValue = rowValues[rowIndex];
      rowValues[rowIndex] = {
        ...indexValue,
        [columnKey]: newValue,
      };
    }

    this.setState({ rows: rowValues }, () => {
      this.sendRowsToParentComponent();
    });
  };

  sendRowsToParentComponent = () => {
    // onValueChange comunicates vales to parent component
    const { onValueChange } = this.props;
    const { rows } = this.state;
    // send only rows that have at least one value
    const filteredRows = rows.reduce((array, row) => {
      const hasValues = Boolean(Object.values(row).length);
      if (hasValues) {
        array.push(row);
      }
      return array;
    }, []);
    onValueChange(filteredRows);
  };

  addNewRow = () => {
    const { rows } = this.state;
    const rowsState = [...rows, {}];
    this.setState({ rows: rowsState });
  };

  removeRow = (rowIndex) => () => {
    const rowsState = [...this.state.rows];
    // remove selected row from state
    rowsState.splice(rowIndex, 1);
    this.setState({ rows: rowsState }, () => {
      this.sendRowsToParentComponent();
    });
  };

  render() {
    const { rows } = this.state;

    const { columns, disabled } = this.props;

    const rowComponents = rows.map((row, index) => (
      <GridRow
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        data={row}
        columns={columns}
        removeRow={this.removeRow(index)}
        onValueChange={this.handleValueChange(index)}
        disabled={disabled}
      />
    ));

    const addButton = !disabled ? (
      <Row>
        <Col>
          <div className="flex row-reverse">
            <Button
              type="button"
              color="primary"
              outline
              onClick={this.addNewRow}
            >
              Agregar
            </Button>
          </div>
        </Col>
      </Row>
    ) : null;

    const thAdditional = !disabled ? <th /> : null;

    return (
      <>
        <Row>
          <Col>
            <Table striped responsive role="grid">
              <thead>
                <tr>
                  {columns.map((column) => {
                    let style = null;

                    if (column.width) style = { minWidth: `${column.width}px` };

                    return (
                      <th
                        key={column.key || column.dependentValue}
                        style={style}
                      >
                        {column.title}
                      </th>
                    );
                  })}
                  {thAdditional}
                </tr>
              </thead>
              <tbody>{rowComponents}</tbody>
            </Table>
          </Col>
        </Row>
        {addButton}
      </>
    );
  }
}

export default Grid;
