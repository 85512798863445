import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getDeposits, clearDeposits } from '../../../../actions/index';
import ModuleList from '../../../layout/ModuleList';
import { COLOR, DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { DEPOSITS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DEPOSIT_PATH, NEW_DEPOSIT_PATH } from '../../../../config/paths';

export class Deposits extends Component {
  componentWillUnmount() {
    this.props.dispatchClearDeposits();
  }

  generateStyle = (row) => {
    let background = '';

    if (row) {
      const {
        original: { settled, depositNoteList, depositDifferenceAmount },
      } = row;

      if (depositNoteList.length) background = COLOR.warning;

      if (!settled && depositDifferenceAmount !== 0) background = COLOR.danger;

      if (settled) background = COLOR.success;
    }

    return { background };
  };

  render() {
    const { deposits, loading, dispatchGetDeposits } = this.props;

    const data = deposits.get('content') || [];
    const pages = deposits.get('totalPages') || null;
    const defaultPageSize = deposits.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Depósitos"
        breadcrumbs={this.props.breadcrumbs}
        buttonPath={NEW_DEPOSIT_PATH}
        tableStructure={{
          columns: DEPOSITS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetDeposits,
          modelPath: DEPOSIT_PATH,
          loading,
          navigateToModelOnRowClick: true,
          generateStyle: this.generateStyle,
        }}
      />
    );
  }
}

Deposits.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  deposits: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDeposits: PropTypes.func.isRequired,
  dispatchClearDeposits: PropTypes.func.isRequired,
};

Deposits.defaultProps = {
  deposits: null,
  loading: false,
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Depósitos',
      href: '',
    },
  ],
  deposits: SalesUnit.Deposit.getIn(['all', 'content']),
  loading: SalesUnit.Deposit.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDeposits: getDeposits,
  dispatchClearDeposits: clearDeposits,
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposits);
