import Moment from 'moment-timezone';
import { DATE_TIME_FORMAT, TIME_ZONE } from '../config/locale';
import { padStart } from './string';

// Normalizes date to follow pet time zone
const tzNormalizeDate = (
  props = { date: null, format: null, time: 'current', addTime: null },
) => {
  const { date, format, time, addTime } = props;

  const momentObject = date ? Moment(date) : Moment();
  let hour = momentObject.hour();
  let minute = momentObject.minute();

  // Pick date out of long time string
  const day = padStart(momentObject.date(), 2);
  // Month's are zero-indexed
  const month = padStart(momentObject.month() + 1, 2);
  const year = momentObject.year();

  if (time === 'start') {
    hour = '00';
    minute = '00';
  }
  if (time === 'end') {
    hour = '23';
    minute = '59';
  }

  const newDate = `${day}/${month}/${year} ${hour}:${minute}`;

  const dateOnly = Moment(newDate, DATE_TIME_FORMAT);
  // Set to pet time zone
  const petDate = dateOnly.clone().tz(TIME_ZONE);
  let newPetDate = petDate;
  if (addTime) newPetDate = petDate.add(addTime.amount, addTime.unit);

  if (!format) return newPetDate.format();
  return newPetDate.format(format);
};

const tzDiff = ({ startDate, endDate, unit = 'days' }) => {
  const startDateObject = Moment(startDate).clone().tz(TIME_ZONE);
  const endDateObject = Moment(endDate).clone().tz(TIME_ZONE);
  return endDateObject.diff(startDateObject, unit);
};

const timeFormat = (minutesTotals) => {
  let time = '-';
  if (minutesTotals > 0) {
    const hours = Math.floor(minutesTotals / 60);
    const minutes = minutesTotals % 60;
    time = `${hours > 0 ? `${hours} hrs` : ''} ${
      minutes > 0 ? `${minutes} min` : ''
    }`;
  }
  return time;
};

export { tzNormalizeDate, timeFormat, tzDiff };
