import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

// eslint-disable-next-line react/prefer-stateless-function
export class CargoMinimalPriceForm extends Component {
  render() {
    const { handleSubmit, loading } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Precio Mínimo" required>
            <Field
              name="minimalPrice"
              component={TextInput}
              type="text"
              placeholder="0.00"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

CargoMinimalPriceForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

CargoMinimalPriceForm.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.CargoDistance.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'CargoMinimalPriceForm',
})(CargoMinimalPriceForm);

export default connect(mapStateToProps)(formComponent);
