import React from 'react';
import { Alert } from 'reactstrap';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';
import {
  DEVELOPMENT,
  STAGING,
  PRODUCTION,
  REACT_APP_ENV,
} from '../config/environments';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#007bff',
    },
    secondary: {
      main: '#493652',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

/**
 * renders a message in the top of the app when
 * in the staging or development environments
 * @returns {*}
 */
const renderEnvironmentMessage = () => {
  switch (REACT_APP_ENV.toLowerCase()) {
    case DEVELOPMENT.toLowerCase():
      return (
        <Alert color="warning" style={{ marginBottom: 0 }}>
          Éste es el ambiente de desarrollo.
        </Alert>
      );
    case STAGING.toLowerCase():
      return (
        <Alert color="warning" style={{ marginBottom: 0 }}>
          Éste es el ambiente de prueba.
        </Alert>
      );
    case PRODUCTION.toLowerCase():
    default:
      return <div />;
  }
};

const Root = () => (
  <div>
    <MuiThemeProvider theme={theme}>
      {renderEnvironmentMessage()}
      <App />
    </MuiThemeProvider>
  </div>
);

export default Root;
