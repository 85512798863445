import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BILLING_PATH } from '../../../../config/paths';

const BillingEditButton = ({ billingId }) => (
  <Link className="btn btn-primary" to={`${BILLING_PATH}/${billingId}/edit`}>
    <i className="fa fa-pencil-square-o" /> Editar &#34;Datos Enviados&#34;
  </Link>
);

BillingEditButton.propTypes = {
  billingId: PropTypes.number.isRequired,
};

export default BillingEditButton;
