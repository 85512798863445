import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Row, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import {
  isRequired,
  validateLength,
  validateNumber,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import CitySelect from '../../../common/forms/select/CitySelect';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import DynamicForm from '../../../common/forms/DynamicForm';
import { DISTRICT_ITEM_LIST_FORM_COLUMNS } from '../../../../config/dynamicFormFields';

const validateLength6 = validateLength(6);
export class DistrictForm extends Component {
  onHandleSubmit = (formValues) => {
    const { onSubmit, initialValues } = this.props;
    const { districts } = formValues;

    if (!initialValues) {
      if (!districts || districts.length === 0) {
        toastr.error('Error', 'Ingrese al menos un distrito.');
        return;
      }
      onSubmit(formValues);
      return;
    }
    onSubmit(formValues);
  };

  renderDistrict = () => {
    const { initialValues } = this.props;
    if (initialValues) {
      return (
        <>
          <FormGroup row>
            <FormItem label="Nombre" required>
              <Field
                name="name"
                component={TextInput}
                type="text"
                placeholder="Nombre"
                validate={[isRequired]}
              />
            </FormItem>
            <FormItem label="Ubigeo" required>
              <Field
                name="ubigeo"
                component={TextInput}
                type="text"
                placeholder="Ubigeo"
                validate={[isRequired, validateLength6, validateNumber]}
              />
            </FormItem>
          </FormGroup>
        </>
      );
    }
    return (
      <div>
        <Row>
          <Col>
            <h5>Distrito</h5>
          </Col>
        </Row>
        <DynamicForm
          name="districts"
          columns={DISTRICT_ITEM_LIST_FORM_COLUMNS}
        />
      </div>
    );
  };

  render() {
    const { handleSubmit, loading } = this.props;
    if (loading) return <Loader />;

    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Ciudad" required>
            <Field
              name="cityId"
              component={CitySelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {this.renderDistrict()}
        <FormFooter />
      </Form>
    );
  }
}

DistrictForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    cityId: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    name: PropTypes.string,
  }),
};

DistrictForm.defaultProps = {
  initialValues: null,
  loading: false,
};

const mapStateToProps = ({ LocationUnit }) => ({
  loading: !LocationUnit.District.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DistrictForm',
})(DistrictForm);

export default connect(mapStateToProps, null)(formComponent);
