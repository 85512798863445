import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearCommonProducts, getCommonProducts } from '../../../actions';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import SelectFilter from './SelectFilter';

export const CommonProductFilter = ({
  dispatchGetCommonProduct,
  dispatchClearCommonProduct,
  options,
  onChangeFilter,
}) => {
  useEffect(() => {
    dispatchGetCommonProduct();
    return () => dispatchClearCommonProduct();
  }, []);

  return (
    <SelectFilter isMulti onChangeFilter={onChangeFilter} options={options} />
  );
};

CommonProductFilter.propTypes = {
  dispatchGetCommonProduct: PropTypes.func.isRequired,
  dispatchClearCommonProduct: PropTypes.func.isRequired,
  options: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
};

CommonProductFilter.defaultProps = {
  options: [],
};

const mapStateToProps = ({ CargoUnit }) => ({
  options: CargoUnit.CommonProduct.getIn(['all', 'content', 'content']).map(
    (option) => ({
      value: option.id,
      label: option.productName,
    }),
  ),
});

const mapDispatchToprops = {
  dispatchGetCommonProduct: getCommonProducts,
  dispatchClearCommonProduct: clearCommonProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(CommonProductFilter);
