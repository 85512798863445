import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import CreditNoteEditButton from './CreditNoteEditButton';

const CreditNoteToolbar = ({ creditNoteId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <CreditNoteEditButton creditNoteId={creditNoteId} />
    </div>
  </ButtonToolbar>
);

CreditNoteToolbar.propTypes = {
  creditNoteId: PropTypes.number.isRequired,
};

export default CreditNoteToolbar;
