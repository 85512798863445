import { string, number, bool, arrayOf, shape } from 'prop-types';
import { paymentMethodBasicInformationPropTypes } from '../../../accounting/payment-method/proptypes/PaymentMethodPropTypes';
import { registeredBusBasicInformationPropTypes } from '../../../bus/registered-bus/proptypes/RegisteredBusPropTypes';
import { serviceTypeBasicInformationPropTypes } from '../../../bus/service-type/proptypes/ServiceTypePropTypes';
import { routeBasicInformationPropTypes } from '../../../route/route/proptypes/RoutePropTypes';
import { driverBasicInformationPropTypes } from '../../../traffic/driver/proptypes/DriverPropTypes';

const privateServiceDataPropTypes = {
  serviceConcept: string,
  contractNumber: string,
  numberOfBusses: number,
  serviceType: string,
  includeFood: bool.isRequired,
};

const privateServiceDataDefaultProps = {
  serviceConcept: null,
  contractNumber: null,
  numberOfBusses: null,
  serviceType: null,
};

const privateServiceItineraryDataPropTypes = {
  source: string,
  sourceAddress: string,
  destination: string,
  destinationAddress: string,
  contractItineraryList: arrayOf(
    shape({
      movementType: string,
      arrivalTime: number,
      authorizedStop: string,
      registeredBus: shape(registeredBusBasicInformationPropTypes),
      route: shape(routeBasicInformationPropTypes),
      serviceType: shape(serviceTypeBasicInformationPropTypes),
      routeDetail: string,
      itineraryId: number,
      kilometers: number,
      sourceAddress: string,
      departureTime: number,
      destinationAddress: string,
      firstDriver: shape(driverBasicInformationPropTypes),
      secondDriver: shape(driverBasicInformationPropTypes),
      thirdDriver: shape(driverBasicInformationPropTypes),
      cabinCrewQuantity: number,
    }),
  ),
};

const privateServiceItineraryDataDefaultProps = {
  source: '-',
  sourceAddress: '-',
  destination: '-',
  destinationAddress: '-',
  contractItineraryList: [],
};

const privateServicePayPerDriverPropTypes = {
  driverCommission: number,
  driverDailyPayment: number,
  driverPaymentForFood: number,
  driverPaymentForAccommodation: number,
};

const privateServicePayPerDriverDefaultProps = {
  driverCommission: null,
  driverDailyPayment: null,
  driverPaymentForFood: null,
  driverPaymentForAccommodation: null,
};

const privateServicePayPerBusPropTypes = {
  busTollPayment: number,
  busGaragePayment: number,
  busParkingPayment: number,
};

const privateServicePayPerBusDefaultProps = {
  busTollPayment: null,
  busGaragePayment: null,
  busParkingPayment: null,
};

const privateServiceContractAmountPropTypes = {
  amountBeforeDiscount: number.isRequired,
  amountPerBus: number,
  authorizedDiscount: number,
  discountComment: string,
  totalAmount: number.isRequired,
  paymentMethod: shape(paymentMethodBasicInformationPropTypes),
  creditPayment: bool,
  operationCode: string,
  creditComment: string,
  contractPaymentType: string.isRequired,
  contractStatus: string.isRequired,
  createDate: number.isRequired,
};

const privateServiceContractAmountDefaultProps = {
  amountPerBus: null,
  authorizedDiscount: null,
  discountComment: null,
  paymentMethod: null,
  operationCode: null,
  creditComment: null,
  creditPayment: false,
};

const privateServiceExecutivePropTypes = {
  executiveName: string.isRequired,
  executivePosition: string.isRequired,
  executivePhone: string.isRequired,
  executiveEmail: string.isRequired,
};

export {
  privateServiceDataPropTypes,
  privateServiceDataDefaultProps,
  privateServiceItineraryDataPropTypes,
  privateServiceItineraryDataDefaultProps,
  privateServicePayPerDriverPropTypes,
  privateServicePayPerDriverDefaultProps,
  privateServicePayPerBusPropTypes,
  privateServicePayPerBusDefaultProps,
  privateServiceContractAmountPropTypes,
  privateServiceContractAmountDefaultProps,
  privateServiceExecutivePropTypes,
};
