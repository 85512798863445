import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GENERAL_INCOME_PATH } from '../../../../config/paths';
import { postGeneralIncome } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import GeneralIncomeForm from './GeneralIncomeForm';
import Content from '../../../layout/Content';

export class NewGeneralIncome extends Component {
  onSubmit = (formValues) =>
    this.props.dispatchPostGeneralIncome({
      amount: formValues.amount,
      authorizerUserId: formValues.authorizerUserId.value,
      description: formValues.description || null,
      documentCode: formValues.documentCode,
      documentSeries: formValues.documentSeries,
      generalIncomeTypeId: formValues.generalIncomeTypeId.value,
      payerCustomerId: formValues.payerCustomerId.value,
    });

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Ingreso"
        subtitle="Crear nuevo ingreso"
        content={<GeneralIncomeForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Ingresos', href: GENERAL_INCOME_PATH },
    { text: 'Nuevo', href: '' },
  ],
});

const mapDispatchToProps = { dispatchPostGeneralIncome: postGeneralIncome };

NewGeneralIncome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchPostGeneralIncome: PropTypes.func.isRequired,
};

NewGeneralIncome.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGeneralIncome);
