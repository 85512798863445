import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import Business from './Business';
import Customer from './Customer';
import IdentificationType from './IdentificationType';
import User from './User';
import UserLocale from './UserLocale';
import UserSecurityProfile from './UserSecurityProfile';

export default combineReducers({
  UnitHome,
  Business,
  Customer,
  IdentificationType,
  User,
  UserLocale,
  UserSecurityProfile,
});
