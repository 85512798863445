import React from 'react';
import PropTypes from 'prop-types';
import GridCell from './GridCell';
import { gridColumnsPropTypes } from './GridPropTypes';

const GridRow = ({
  columns,
  index,
  removeRow,
  onValueChange,
  data,
  disabled,
}) => {
  const getValue = column => {
    if (column.key) {
      return data[column.key];
    }
    if (
      column.dependentKey &&
      column.dependentValue &&
      data[column.dependentKey]
    ) {
      // in case of a dependentKey and dependentValue
      // a resource key containing the recpective values is expected
      // TODO: make it work for nested values
      return data[column.dependentKey].resource[column.dependentValue];
    }
    return undefined;
  };

  const removeButton = !disabled ? (
    <td className="flex row-reverse">
      <i
        tabIndex="0"
        role="button"
        className="fa fa-times fa-2x text-danger clickable"
        onClick={removeRow}
        onKeyPress={removeRow}
      />
    </td>
  ) : null;

  return (
    <tr data-index={index}>
      {columns.map((column, columnIndex) => (
        <GridCell
          key={column.key || column.dependentValue}
          column={column}
          value={getValue(column)}
          index={columnIndex}
          onValueChange={onValueChange(column.key)}
          disabled={disabled}
        />
      ))}
      {removeButton}
    </tr>
  );
};

GridRow.propTypes = {
  columns: gridColumnsPropTypes.isRequired,
  index: PropTypes.number.isRequired,
  removeRow: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool,
};

GridRow.defaultProps = {
  data: {},
  disabled: false,
};

export default GridRow;
