import React from 'react';
import {
  activityMaterialInformationDefaultProps,
  activityMaterialInformationPropTypes,
} from '../proptypes/ActivityPropTypes';
import Table from '../../../../common/Table';
import { MATERIAL_COLUMNS } from '../../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import Alert from '../../../../common/informative/Alert';

const ActivityMaterialInformationResource = ({ materialList }) => {
  if (materialList.length === 0)
    return (
      <Alert type="warning" message="Actividad no tiene materiales asignados." />
    );

  const data = materialList.map(({ material, quantity }) => ({
    ...material,
    quantity,
  }));
  return (
    <Table
      columns={[
        ...MATERIAL_COLUMNS,
        {
          Header: 'Cantidad',
          accessor: 'quantity',
          className: 'text-center',
          minWidth: 180,
        },
      ]}
      data={data}
      filterable
      manual={false}
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
  );
};

ActivityMaterialInformationResource.propTypes =
  activityMaterialInformationPropTypes;

ActivityMaterialInformationResource.defaultProps =
  activityMaterialInformationDefaultProps;

export default ActivityMaterialInformationResource;
