import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  FormFeedback,
} from 'reactstrap';
import {
  postApprovePostpaidBooking,
  replacePostpaidBooking,
  patchPostpaidBookingVoucherCode,
} from '../../../../actions';
import { POST } from '../../../../config/permissions';
import { generateApprovePostpaidBookingEndpoint } from '../../../../config/endpoints';
import withEndpointAuthorization from '../../../units/authorization/withEndPointAuthorization';
import Loader from '../../Loader';
import { TRANSACTION_TYPE_BANK_TRANSFER } from '../../../../config/constants';

class ApprovePostpaidBookingCell extends Component {
  static propTypes = {
    customerOrderId: PropTypes.number.isRequired,
    transactionCode: PropTypes.string.isRequired,
    dispatchPostApprovePostpaidBooking: PropTypes.func.isRequired,
    dispatchReplacePostpaidBooking: PropTypes.func.isRequired,
    dispatchPatchPostpaidBookingVoucherCode: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  state = {
    showApproveModal: false,
    showOperationNumber: false,
    operationNumber: '',
    validOperationNumber: true,
  };

  handleApprovePostpaidBooking = () => {
    const { transactionCode } = this.props;
    if (transactionCode === TRANSACTION_TYPE_BANK_TRANSFER) {
      this.setState({ showOperationNumber: true });
    }
    this.showApproveModal();
  };

  handleOperationNumberChange = event => {
    const { value } = event.target;
    this.setState({
      operationNumber: value,
      validOperationNumber: Boolean(value && value.length),
    });
  };

  sendOperationNumber = async () => {
    const { operationNumber } = this.state;
    if (operationNumber && operationNumber.length) {
      this.closeApproveModal();
      const {
        customerOrderId,
        dispatchPatchPostpaidBookingVoucherCode,
      } = this.props;
      const response = await dispatchPatchPostpaidBookingVoucherCode({
        customerOrderId,
        voucherCode: operationNumber,
      });
      if (response) {
        this.approvePostpaidBooking();
      }
    } else {
      this.setState({ validOperationNumber: false });
    }
  };

  approvePostpaidBooking = async () => {
    this.closeApproveModal();
    const {
      customerOrderId,
      dispatchPostApprovePostpaidBooking,
      dispatchReplacePostpaidBooking,
    } = this.props;
    const postpaidBooking = await dispatchPostApprovePostpaidBooking({
      customerOrderId,
    });
    if (postpaidBooking) {
      dispatchReplacePostpaidBooking({ postpaidBooking });
    }
  };

  showApproveModal = () => {
    this.setState({ showApproveModal: true });
  };

  closeApproveModal = () => {
    this.setState({ showApproveModal: false });
  };

  render() {
    const { loading } = this.props;

    const { showOperationNumber } = this.state;

    let approveModalContent;

    if (loading) {
      approveModalContent = <Loader />;
    } else if (showOperationNumber) {
      approveModalContent = (
        <>
          <ModalBody>
            Ingrese el número de operación:
            <div>
              <br />
              <Row>
                <Col xs={4}>
                  <p>
                    <b>Número de operación: </b>
                  </p>
                </Col>
                <Col xs={8}>
                  <Input
                    invalid={!this.state.validOperationNumber}
                    type="text"
                    onChange={this.handleOperationNumberChange}
                    value={this.state.operationNumber}
                  />
                  <FormFeedback>Ingrese un número de operación</FormFeedback>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.sendOperationNumber}>
              OK
            </Button>{' '}
            <Button color="secondary" onClick={this.closeApproveModal}>
              Cerrar
            </Button>
          </ModalFooter>
        </>
      );
    } else {
      approveModalContent = (
        <>
          <ModalBody>
            Esta acción aprobará la reserva. ¿Desea continuar?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.approvePostpaidBooking}>
              OK
            </Button>{' '}
            <Button color="secondary" onClick={this.closeApproveModal}>
              Cerrar
            </Button>
          </ModalFooter>
        </>
      );
    }

    const approveModal = (
      <Modal isOpen={this.state.showApproveModal}>
        <ModalHeader>Confirmación</ModalHeader>
        {approveModalContent}
      </Modal>
    );

    return (
      <>
        <i
          tabIndex="0"
          role="button"
          className="fa fa-check-circle text-success"
          onKeyPress={this.handleApprovePostpaidBooking}
          onClick={this.handleApprovePostpaidBooking}
        />
        {approveModal}
      </>
    );
  }
}

const mapStateToProps = ({ PostpaidBookingUnit }) => ({
  loading: PostpaidBookingUnit.PostpaidBooking.getIn(['all', 'activity']),
});

const mapDispatchToprops = {
  dispatchPostApprovePostpaidBooking: postApprovePostpaidBooking,
  dispatchReplacePostpaidBooking: replacePostpaidBooking,
  dispatchPatchPostpaidBookingVoucherCode: patchPostpaidBookingVoucherCode,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToprops,
)(ApprovePostpaidBookingCell);

export default withEndpointAuthorization({
  url: generateApprovePostpaidBookingEndpoint(),
  mapUrlParamsToProps: { customerOrderId: 'customerOrderId' },
  permissionType: POST,
})(connectedComponent);
