import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_PRINT_STATIONS,
  GET_PRINT_STATIONS,
  CLEAR_PRINT_STATIONS,
  CLEAR_PRINT_STATION,
  FLAG_PRINT_STATION_ACTIVITY,
  GET_PRINT_STATION,
} from '../types';
import { PRINT_STATION_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { PRINT_STATION_PATH } from '../../config/paths';

const flagGettingPrintStations = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PRINT_STATIONS,
    payload: flag,
  });

const getPrintStations = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingPrintStations(true));

    const query = tableFilters;

    const url = `${PRINT_STATION_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response);

    const printStation = await response.json();

    dispatch({
      type: GET_PRINT_STATIONS,
      payload: printStation,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingPrintStations(false));
  }
};

const clearPrintStations = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRINT_STATIONS,
  });

const clearPrintStation = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRINT_STATION,
  });

const flagPrintStationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PRINT_STATION_ACTIVITY,
    payload: flag,
  });

const postPrintStation =
  async ({ name, description, serialNumber, printTemplateList }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrintStationActivity(true));
      const payload = {
        name,
        description,
        serialNumber,
        printTemplateList,
      };

      const url = PRINT_STATION_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      await response.json();

      dispatch(push(PRINT_STATION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPrintStationActivity(false));
    }
  };

const getPrintStation =
  async ({ printStationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrintStationActivity(true));

      const url = `${PRINT_STATION_ENDPOINT}/${printStationId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const printStation = await response.json();

      dispatch({
        type: GET_PRINT_STATION,
        payload: printStation,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPrintStationActivity(false));
    }
  };

const putPrintStation =
  async (
    printStationId,
    {
      name,
      description,
      serialNumber,
      printTemplateList,
      virtualPrinter,
      workstationId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagPrintStationActivity(true));

      const payload = {
        name,
        description,
        serialNumber,
        printTemplateList,
        virtualPrinter,
        workstationId,
        id: printStationId,
      };

      const url = `${PRINT_STATION_ENDPOINT}/${printStationId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      const printStation = await response.json();

      dispatch(push(`${PRINT_STATION_PATH}/${printStation.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPrintStationActivity(false));
    }
  };

const deletePrintStation =
  async ({ printStationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrintStationActivity(true));

      const url = `${PRINT_STATION_ENDPOINT}/${printStationId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response);

      await response.json();

      dispatch(push(PRINT_STATION_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPrintStationActivity(false));
    }
  };

export {
  getPrintStations,
  clearPrintStations,
  clearPrintStation,
  postPrintStation,
  getPrintStation,
  putPrintStation,
  deletePrintStation,
};
