import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CARGO_PATH } from '../../../../config/paths';
import { SALES_SESSION_PARCELS_COLUMNS } from '../../../../config/columns';
import { clearParcels, getSalesSessionParcels } from '../../../../actions';
import Table from '../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';

const ParcelsTable = ({
  pages,
  loading,
  defaultPageSize,
  salesSessionId,
  salesSessionParcels,
  dispatchClearParcels,
  dispatchGetSalesSessionParcels,
}) => {
  useEffect(
    () => () => {
      dispatchClearParcels();
    },
    [],
  );

  const onFetchData = (searchValues) => {
    dispatchGetSalesSessionParcels({ salesSessionId }, searchValues);
  };

  return (
    <>
      <Table
        columns={SALES_SESSION_PARCELS_COLUMNS}
        data={salesSessionParcels}
        filterable
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={onFetchData}
        loading={loading}
        navigateToModelOnRowClick
        modelPath={CARGO_PATH}
        openPathInNewTab
      />
      <br />
    </>
  );
};

ParcelsTable.propTypes = {
  salesSessionId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  pages: PropTypes.number,
  defaultPageSize: PropTypes.number,
  dispatchGetSalesSessionParcels: PropTypes.func.isRequired,
  dispatchClearParcels: PropTypes.func.isRequired,
  salesSessionParcels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
};

ParcelsTable.defaultProps = {
  salesSessionParcels: undefined,
  pages: null,
  defaultPageSize: DEFAULT_PAGE_SIZE,
};

const mapStateToProps = ({ CargoUnit }) => ({
  salesSessionParcels: CargoUnit.Cargo.getIn(['all', 'content', 'content']),
  loading: CargoUnit.Cargo.getIn(['all', 'loading']),
  pages: CargoUnit.Cargo.getIn(['all', 'content', 'totalPages']),
  defaultPageSize: CargoUnit.Cargo.getIn(['all', 'content', 'size']),
});

const mapDispatchToProps = {
  dispatchGetSalesSessionParcels: getSalesSessionParcels,
  dispatchClearParcels: clearParcels,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelsTable);
