import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { TRIP_CIRCUIT_STATUS_OPTIONS } from '../../../../config/constants';

const TripCircuitStatusSelect = ({
  input,
  meta,
  isGridVariant,
  onValueChange,
  onBlur,
  ...rest
}) => (
  <Select
    options={TRIP_CIRCUIT_STATUS_OPTIONS}
    input={input}
    meta={meta}
    isGridVariant={isGridVariant}
    onValueChange={onValueChange}
    onBlur={onBlur}
    {...rest}
  />
);

TripCircuitStatusSelect.propTypes = {
  input: inputPropTypes,
  meta: metaPropTypes,
  isGridVariant: PropTypes.bool,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
};

TripCircuitStatusSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isGridVariant: false,
  onValueChange: undefined,
  onBlur: undefined,
};

export default TripCircuitStatusSelect;
