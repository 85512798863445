import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  PRINT_STATION_PATH,
  NEW_PRINT_STATION_PATH,
} from '../../../../config/paths';
import { PRINT_STATION_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { clearPrintStations, getPrintStations } from '../../../../actions';

export class PrintStations extends Component {
  componentWillUnmount() {
    this.props.dispatchClearPrintStations();
  }

  render() {
    const { printStations, loading, breadcrumbs, dispatchGetPrintStations } =
      this.props;
    const data = printStations.get('content') || [];
    const pages = printStations.get('totalPages') || null;
    const defaultPageSize = printStations.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Impresoras"
        buttonPath={NEW_PRINT_STATION_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: PRINT_STATION_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: false,
          fetchData: dispatchGetPrintStations,
          modelPath: PRINT_STATION_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetPrintStations: getPrintStations,
  dispatchClearPrintStations: clearPrintStations,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Impresoras',
      href: PRINT_STATION_PATH,
    },
  ],
  printStations: HumanResourcesUnit.PrintStation.getIn(['all', 'content']),
  loading: HumanResourcesUnit.PrintStation.getIn(['all', 'loading']),
});

PrintStations.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  printStations: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetPrintStations: PropTypes.func.isRequired,
  dispatchClearPrintStations: PropTypes.func.isRequired,
};

PrintStations.defaultProps = {
  printStations: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintStations);
