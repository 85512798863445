import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  ADD_SHAPE_DETAIL_FROM_PARCEL,
  CLEAR_ALL_DETAILS_FROM_PARCELS_TO_PICKUP,
  CLEAR_DETAIL_FROM_PARCEL,
  CLEAR_PARCELS_TO_PICKUP,
  DELETE_PARCELS_TO_PICKUP,
  FLAG_GETTING_DETAIL_FROM_PARCEL,
  FLAG_GETTING_PARCELS_TO_PICKUP,
  GET_DETAIL_FROM_PARCEL,
  GET_PARCELS_TO_PICKUP,
} from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  details: Immutable.Set(),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_PARCELS_TO_PICKUP:
      return state.setIn(['all', 'loading'], payload);
    case GET_PARCELS_TO_PICKUP:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_PARCELS_TO_PICKUP:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case DELETE_PARCELS_TO_PICKUP:
      return state.updateIn(['all', 'content', 'content'], (content) =>
        content.filter(
          (parcel) =>
            parcel.id !== payload.id || parcel.internal !== payload.internal,
        ),
      );
    case ADD_SHAPE_DETAIL_FROM_PARCEL:
      return state.update('details', (details) =>
        details.add(Immutable.Map(payload)),
      );
    case FLAG_GETTING_DETAIL_FROM_PARCEL:
      return state.update('details', (details) =>
        details.map((detail) =>
          detail.get('parcelId') === payload.parcelId &&
          detail.get('internal') === payload.internal
            ? detail.set('loading', payload.flag)
            : detail,
        ),
      );
    case GET_DETAIL_FROM_PARCEL:
      return state.update('details', (details) =>
        details.map((detail) =>
          detail.get('parcelId') === payload.parcelId &&
          detail.get('internal') === payload.internal
            ? detail.set('content', payload.content)
            : detail,
        ),
      );
    case CLEAR_DETAIL_FROM_PARCEL:
      return state.update('details', (details) =>
        details.filter(
          (detail) =>
            detail.get('parcelId') !== payload.parcelId ||
            detail.get('internal') !== payload.internal,
        ),
      );
    case CLEAR_ALL_DETAILS_FROM_PARCELS_TO_PICKUP:
      return state.setIn(['details'], INITIAL_STATE.getIn(['details']));
    default:
      return state;
  }
};
