import React, { Fragment } from 'react';
import { MANUAL_VOUCHER_SERVICE_TYPE } from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import { padStart } from '../../../../../utils/string';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  manualVoucherDataDefaultProps,
  manualVoucherDataPropTypes,
} from '../proptypes/ManualVoucherPropTypes';
import { DATE_FORMAT } from '../../../../../config/locale';

const VoucherInformationResource = ({
  documentCode,
  documentSeries,
  voucherType,
  serviceType,
  issueDate,
  description,
}) => {
  const resource = (
    <Fragment>
      <ResourceProperty label="Tipo de Comprobante:">
        {voucherType.name}
      </ResourceProperty>
      <ResourceProperty label="Número de Comprobante:">
        {documentSeries}-{padStart(documentCode, 7)}
      </ResourceProperty>
      <ResourceProperty label="Tipo de Servicio:">
        {MANUAL_VOUCHER_SERVICE_TYPE[serviceType].label}
      </ResourceProperty>
      <ResourceProperty label="Fecha de Expedición:">
        {issueDate
          ? tzNormalizeDate({ date: issueDate, format: DATE_FORMAT })
          : '-'}
      </ResourceProperty>
      <ResourceProperty label="Motivo o Sustento:">
        {description || '-'}
      </ResourceProperty>
    </Fragment>
  );
  return (
    <Fragment>
      <h3>Datos del Comprobante</h3>
      {resource}
    </Fragment>
  );
};

VoucherInformationResource.propTypes = manualVoucherDataPropTypes;

VoucherInformationResource.defaultProps = manualVoucherDataDefaultProps;

export default VoucherInformationResource;
