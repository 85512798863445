import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_ACTIVITY_TASKS,
  GET_ACTIVITY_TASKS,
  CLEAR_ACTIVITY_TASKS,
  FLAG_ACTIVITY_TASK_ACTIVITY,
  GET_ACTIVITY_TASK,
  CLEAR_ACTIVITY_TASK,
} from '../types/mechanical-maintenance';
import { ACTIVITY_TASK_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { ACTIVITY_TASK_PATH } from '../../config/paths';

const flagGettingActivityTasks = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ACTIVITY_TASKS,
    payload: flag,
  });

const getActivityTasks = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingActivityTasks(true));
    const query = tableFilters;
    const url = `${ACTIVITY_TASK_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_ACTIVITY_TASKS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingActivityTasks(false));
  }
};

const clearActivityTasks = () => (dispatch) =>
  dispatch({
    type: CLEAR_ACTIVITY_TASKS,
  });

const flagActivityTaskActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ACTIVITY_TASK_ACTIVITY,
    payload: flag,
  });

const getActivityTask =
  async ({ activityTaskId }) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityTaskActivity(true));
      const url = `${ACTIVITY_TASK_ENDPOINT}/${activityTaskId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_ACTIVITY_TASK,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityTaskActivity(false));
    }
  };

const clearActivityTask = () => (dispatch) =>
  dispatch({
    type: CLEAR_ACTIVITY_TASK,
  });

const postActivityTask =
  async ({
    order,
    description,
    workingHours,
    companyAreaId,
    activityId,
    orderDependent,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityTaskActivity(true));
      const payload = {
        order,
        description,
        workingHours,
        companyAreaId,
        activityId,
        orderDependent,
      };
      const url = ACTIVITY_TASK_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ACTIVITY_TASK_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityTaskActivity(false));
    }
  };

const deleteActivityTask =
  async ({ activityTaskId }) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityTaskActivity(true));
      const url = `${ACTIVITY_TASK_ENDPOINT}/${activityTaskId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ACTIVITY_TASK_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityTaskActivity(false));
    }
  };

const putActivityTask =
  async (
    activityTaskId,
    {
      order,
      description,
      workingHours,
      companyAreaId,
      activityId,
      orderDependent,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagActivityTaskActivity(true));
      const payload = {
        id: activityTaskId,
        order,
        description,
        workingHours,
        companyAreaId,
        activityId,
        orderDependent,
      };
      const url = `${ACTIVITY_TASK_ENDPOINT}/${activityTaskId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ACTIVITY_TASK_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagActivityTaskActivity(false));
    }
  };

export {
  flagGettingActivityTasks,
  getActivityTasks,
  clearActivityTasks,
  flagActivityTaskActivity,
  getActivityTask,
  postActivityTask,
  clearActivityTask,
  deleteActivityTask,
  putActivityTask,
};
