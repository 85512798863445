import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FUEL_VOUCHER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import FuelVoucherForm from './FuelVoucherForm';
import { postExtraordinaryFuelVoucher } from '../../../../actions';
import Content from '../../../layout/Content';
import { getSubstring } from '../../../../utils/string';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

export class NewFuelVoucher extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostExtraordinaryFuelVoucher } = this.props;
    const postFuelVoucherFormValues = {
      validFrom: formValues.validFrom
        ? tzNormalizeDate({
            date: formValues.validFrom,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      validTo: formValues.validTo
        ? tzNormalizeDate({
            date: formValues.validTo,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      companyBusId: formValues.companyBusId,
      fuelReceiverName: formValues.fuelReceiverName,
      numberOfGallons: formValues.numberOfGallons,
      itineraryId: getSubstring(formValues.itineraryId),
      fuelStationId: formValues.fuelStationId
        ? formValues.fuelStationId.value
        : null,
      comments: formValues.comments,
    };
    dispatchPostExtraordinaryFuelVoucher(postFuelVoucherFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Vale de Combustible Extraordinario"
        subtitle="Crear nuevo vale de combustible extraordinario"
        content={<FuelVoucherForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Vales de Combustible',
      href: FUEL_VOUCHER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostExtraordinaryFuelVoucher: postExtraordinaryFuelVoucher,
};

NewFuelVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostExtraordinaryFuelVoucher: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFuelVoucher);
