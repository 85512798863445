import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { putGenerateReport } from '../../../../actions';
import { FUEL_REPORT_PREVIEW_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import FuelReportPreviewForm from './FuelReportPreviewForm';
import Loader from '../../../common/Loader';

export class FuelReportPreview extends Component {
  onSubmit = ({ itineraryGroupId }) =>
    this.props.dispatchPutGenerateReport({ itineraryGroupId });

  render() {
    const { loading, breadcrumbs } = this.props;

    if (loading) return <Loader />;

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Vista Previa - Reporte de Combustible"
        subtitle="Valida los siguientes datos de combustible para generar el reporte por bus y conductor"
        content={<FuelReportPreviewForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit, RouteUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Vista Previa - Reporte de Combustible',
      href: FUEL_REPORT_PREVIEW_PATH,
    },
  ],
  loading: !RouteUnit.ItineraryGroup.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutGenerateReport: putGenerateReport,
};

FuelReportPreview.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutGenerateReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelReportPreview);
