import React from 'react';
import Content from '../../../layout/Content';
import { NEW_EXPENSE_TYPE_BREADCRUMBS } from './config/breadcrumbs';
import ExpenseTypeForm from './ExpenseTypeForm';
import { postExpenseType } from './fetch';

const NewExpenseType = () => {
  const createPayload = ({ formValues }) => ({
    name: formValues.name,
    expenseCode: formValues.expenseCode,
    description: formValues.description,
    expenseTypeLabel: formValues.expenseTypeLabel.value,
    internal: formValues.internal,
    costCenterId: formValues.costCenter ? formValues.costCenter.value : null,
  });
  return (
    <Content
      breadcrumbs={NEW_EXPENSE_TYPE_BREADCRUMBS}
      title="Nuevo Tipo de Egreso"
      subtitle="Crear Nuevo Tipo de Egreso"
      content={
        <ExpenseTypeForm
          fetchFunction={postExpenseType}
          createPayload={createPayload}
        />
      }
    />
  );
};

export default NewExpenseType;
