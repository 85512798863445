import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BILLINGS_ITINERARY_PATH } from '../../../../../config/paths';

const ViewGuidesButton = ({ itineraryId }) => (
  <Link
    className="btn btn-info border"
    to={`${BILLINGS_ITINERARY_PATH}/${itineraryId}`}
  >
    <i className="fa fa-file-pdf-o" /> Ver Guías
  </Link>
);

ViewGuidesButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default ViewGuidesButton;
