import React, { Fragment } from 'react';
import {
  CURRENCY_CODE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
} from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  manualVoucherPaymentDefaultProps,
  manualVoucherPaymentPropTypes,
} from '../proptypes/ManualVoucherPropTypes';
import { DATE_FORMAT } from '../../../../../config/locale';
import Checkbox from '../../../../common/forms/Checkbox';

const PaymentInformationResource = ({
  affectedByTaxes,
  currencyCode,
  paymentType,
  installments,
  dueDate,
}) => {
  const resource = (
    <Fragment>
      <ResourceProperty label="Afecta a Impuestos:">
        <Checkbox checked={affectedByTaxes} />
      </ResourceProperty>
      <ResourceProperty label="Moneda:">
        {CURRENCY_CODE[currencyCode].label}
      </ResourceProperty>
      <ResourceProperty label="Método de Pago:">
        {MANUAL_VOUCHER_PAYMENT_TYPE[paymentType].label}
      </ResourceProperty>
      <ResourceProperty label="Cuotas:">{installments || '-'}</ResourceProperty>
      <ResourceProperty label="Fecha de Expiración:">
        {dueDate
          ? tzNormalizeDate({ date: dueDate, format: DATE_FORMAT })
          : '-'}
      </ResourceProperty>
    </Fragment>
  );
  return (
    <Fragment>
      <h3>Datos del Pago</h3>
      {resource}
    </Fragment>
  );
};

PaymentInformationResource.propTypes = manualVoucherPaymentPropTypes;

PaymentInformationResource.defaultProps = manualVoucherPaymentDefaultProps;

export default PaymentInformationResource;
