import { toastr } from 'react-redux-toastr';
import { KIDS_ON_ITINERARY_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_KIDS_ON_ITINERARY,
  FLAG_KIDS_ON_ITINERARY_ACTIVITY,
  GET_KIDS_ON_ITINERARY,
} from '../types';

const flagKidsOnItineraryActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_KIDS_ON_ITINERARY_ACTIVITY,
    payload: flag,
  });

const getKidsOnItinerary =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagKidsOnItineraryActivity(true));

      const url = `${KIDS_ON_ITINERARY_ENDPOINT}/${itineraryId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const itinerary = await response.json();

      dispatch({ type: GET_KIDS_ON_ITINERARY, payload: itinerary });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagKidsOnItineraryActivity(false));
    }
  };

const clearKidsOnItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_KIDS_ON_ITINERARY,
  });

const postKidsOnItineraryRequest = async ({
  itineraryId,
  seatNumber,
  floorNumber,
  parentCustomerId,
  kidCustomerId,
  relationship,
  comments,
}) => {
  const payload = {
    itineraryId,
    seatNumber,
    floorNumber,
    parentCustomerId,
    kidCustomerId,
    relationship,
    comments,
  };

  const response = await fetch(KIDS_ON_ITINERARY_ENDPOINT, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(payload),
  });

  await isErrorResponse(response);

  const kidOnItinerary = await response.json();

  return kidOnItinerary;
};

const postKidsOnItinerary =
  async (payload, getKids = false) =>
  async (dispatch) => {
    try {
      dispatch(flagKidsOnItineraryActivity(true));

      const response = await postKidsOnItineraryRequest(payload);

      if (getKids) {
        await dispatch(
          getKidsOnItinerary({ itineraryId: payload.itineraryId }),
        );
      }

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagKidsOnItineraryActivity(false));
    }
  };

const deleteKidsOnItineraryRequest = async ({ id }) => {
  const url = `${KIDS_ON_ITINERARY_ENDPOINT}/${id}`;

  const response = await fetch(url, {
    ...DEFAULT_DELETE_CONFIG,
  });

  await isErrorResponse(response);

  const kidOnItinerary = await response.json();

  return kidOnItinerary;
};

const deleteKidsOnItinerary =
  async (id, itineraryId = null) =>
  async (dispatch) => {
    try {
      dispatch(flagKidsOnItineraryActivity(true));

      const response = await deleteKidsOnItineraryRequest(id);

      if (itineraryId) {
        await dispatch(getKidsOnItinerary({ itineraryId }));
      }

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagKidsOnItineraryActivity(false));
    }
  };

export {
  flagKidsOnItineraryActivity,
  getKidsOnItinerary,
  clearKidsOnItinerary,
  deleteKidsOnItinerary,
  postKidsOnItinerary,
  postKidsOnItineraryRequest,
  deleteKidsOnItineraryRequest,
};
