import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import SaveButton from '../button/SaveButton';

const FormFooter = ({
  saveButtonAction,
  saveButtonType,
  saveButtonDisabled,
  children,
  saveButtonColor,
  saveButtonText,
  saveButtonIcon,
}) => (
  <div className="mt-2">
    <ButtonToolbar className="flex row-reverse">
      <div>
        {children}{' '}
        <SaveButton
          action={saveButtonAction}
          type={saveButtonType}
          disabled={saveButtonDisabled}
          color={saveButtonColor}
          text={saveButtonText}
          icon={saveButtonIcon}
        />
      </div>
    </ButtonToolbar>
  </div>
);

FormFooter.propTypes = {
  saveButtonAction: PropTypes.func,
  saveButtonType: PropTypes.string,
  saveButtonDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  saveButtonColor: PropTypes.string,
  saveButtonText: PropTypes.string,
  saveButtonIcon: PropTypes.string,
};

FormFooter.defaultProps = {
  saveButtonAction: null,
  saveButtonType: 'submit',
  saveButtonDisabled: false,
  children: null,
  saveButtonColor: 'primary',
  saveButtonText: 'Guardar',
  saveButtonIcon: 'fa fa-save',
};

export default FormFooter;
