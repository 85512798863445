import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { NEW_MATERIAL_PATH, MATERIAL_PATH } from '../../../../config/paths';
import {
  getMaterials,
  clearMaterials,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { MATERIAL_COLUMNS } from '../../../../config/columns';

export const Materials = ({
  breadcrumbs,
  materials,
  loading,
  dispatchGetMaterials,
  dispatchClearMaterials,
}) => {
  useLayoutEffect(() => () => dispatchClearMaterials(), []);

  const data = materials.get('content') || [];
  const pages = materials.get('totalPages') || null;
  const defaultPageSize = materials.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Materiales"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_MATERIAL_PATH}
      tableStructure={{
        columns: MATERIAL_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetMaterials,
        modelPath: MATERIAL_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetMaterials: getMaterials,
  dispatchClearMaterials: clearMaterials,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Materiales',
      href: MATERIAL_PATH,
    },
  ],
  materials: MechanicalMaintenanceUnit.Material.getIn(['all', 'content']),
  loading: MechanicalMaintenanceUnit.Material.getIn(['all', 'loading']),
});

Materials.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  materials: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetMaterials: PropTypes.func.isRequired,
  dispatchClearMaterials: PropTypes.func.isRequired,
};

Materials.defaultProps = {
  materials: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
