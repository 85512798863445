import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  getTicketActionModal,
  postTicketVoucherTypeChange,
} from '../../../../../actions/index';
import { VOUCHER_TYPE_BUSINESS_INVOICE_ID } from '../../../../../config/constants';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { POST } from '../../../../../config/permissions';
import {
  generateTicketChangeVoucherTypeEndpoint,
  TICKET_CHANGE_VOUCHER_TYPE_MODAL_ENDPOINT,
} from '../../../../../config/endpoints';
import Modal from '../../../../common/modal/Modal';
import VoucherTypeChangeForm from './VoucherTypeChangeForm';
import { MODAL_ACTION_CONFIRM_MESSAGE } from '../../../../../config/messages';

const VoucherTypeChangeButton = ({
  voucherTypeId,
  ticketId,
  dispatchGetTicketActionModal,
  dispatchPostTicketVoucherTypeChange,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(MODAL_ACTION_CONFIRM_MESSAGE);
  const [url, setUrl] = useState(null);

  const handleShowModal = async () => {
    setLoading(true);

    const response = await dispatchGetTicketActionModal({
      ticketId,
      url: TICKET_CHANGE_VOUCHER_TYPE_MODAL_ENDPOINT,
    });

    if (response && response.message && response.url) {
      setMessage(response.message);
      setUrl(response.url.replace('/ticket/{ticketId}', ''));
      setShowConfirmationModal(true);
    }

    setLoading(false);
  };

  const handleCloseModal = () => setShowConfirmationModal(false);

  const generateTicketVoucherTypeChangePayload = (formValues) => {
    const { business } = formValues;

    return {
      ticketId,
      voucherTypeId: VOUCHER_TYPE_BUSINESS_INVOICE_ID,
      businessId: business.value,
    };
  };

  const handleChangeVoucherType = (formValues) => {
    const payload = generateTicketVoucherTypeChangePayload(formValues);
    dispatchPostTicketVoucherTypeChange(url, payload);
  };

  // Don't show if it is a business invoice
  if (voucherTypeId === VOUCHER_TYPE_BUSINESS_INVOICE_ID) return null;

  const body = (
    <>
      <p>{message}</p>
      <VoucherTypeChangeForm
        onSubmit={handleChangeVoucherType}
        onCancel={handleCloseModal}
      />
    </>
  );

  const modal = (
    <Modal show={showConfirmationModal} title="Cambiar a Factura" body={body} />
  );

  return (
    <Fragment>
      <Button color="light" className="border" onClick={handleShowModal}>
        <i className={`fa fa-${loading ? 'spinner fa-spin' : 'file-text'}`} />{' '}
        Cambiar a Factura
      </Button>
      {modal}
    </Fragment>
  );
};

VoucherTypeChangeButton.propTypes = {
  dispatchGetTicketActionModal: PropTypes.func.isRequired,
  dispatchPostTicketVoucherTypeChange: PropTypes.func.isRequired,
  ticketId: PropTypes.number.isRequired,
  voucherTypeId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchGetTicketActionModal: getTicketActionModal,
  dispatchPostTicketVoucherTypeChange: postTicketVoucherTypeChange,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(VoucherTypeChangeButton);

export default withEndpointAuthorization({
  url: generateTicketChangeVoucherTypeEndpoint(0),
  permissionType: POST,
})(connectedComponent);
