import Immutable from 'immutable';
import {
  // CARGO_PICKUP_PATH,
  // UNLOADING_CARGO_PATH,
  // MANIFEST_ITINERARY_PATH,
  // ADD_CARGO_ITEM_TO_ITINERARY_PATH,
  HOME_PATH,
  BASE_PRICE_PER_KILOMETER_PATH,
  CARGO_UNIT_PATH,
  GEOGRAPHIC_ZONE_COMBINATION_PATH,
  DELIVERY_GROUP_PATH,
  DEPRECIATION_RULE_PATH,
  COMMON_PRODUCT_PATH,
  CARGO_DISTANCE_PATH,
  CARGO_CATEGORY_PATH,
  CARGO_PATH,
  CARGO_MINIMAL_PRICE_PATH,
  INTERNAL_TRANSFER_PATH,
  LEGAL_ABANDONMENT_PATH,
  MANUAL_SENDER_REMISSION_GUIDE_PATH,
  CARGO_CORPORATE_CREDIT_PATH,
  CARGO_ITEM_PATH,
  MANIFEST_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    // {
    //   key: 'unloading-cargo',
    //   title: 'Descarga de encomiendas',
    //   path: UNLOADING_CARGO_PATH,
    //   icon: 'minus-circle',
    // },
    // {
    //   key: 'pickup-cargo',
    //   title: 'Entrega de carga',
    //   path: CARGO_PICKUP_PATH,
    //   icon: 'handshake-o',
    // },
    // {
    //   key: 'manifest-itinerary',
    //   title: 'Manifiesto de Itinerarios',
    //   path: MANIFEST_ITINERARY_PATH,
    //   icon: 'paste',
    // },
    // {
    //   key: 'add-item-to-itinerary',
    //   title: 'Agregar Item a Itinerario',
    //   path: ADD_CARGO_ITEM_TO_ITINERARY_PATH,
    //   icon: 'plus-circle',
    // },
    {
      key: 'basePricePerKilometer',
      title: 'Precio Base por Kilómetro',
      path: BASE_PRICE_PER_KILOMETER_PATH,
      icon: 'compress',
    },
    {
      key: 'geographicZonesCombination',
      title: 'Combinación de Zonas Geográficas',
      path: GEOGRAPHIC_ZONE_COMBINATION_PATH,
      icon: 'retweet',
    },
    {
      key: 'kilometers-between-cities',
      title: 'Kilómetros entre Ciudades',
      path: CARGO_DISTANCE_PATH,
      icon: 'road',
    },
    {
      key: 'delivery-group',
      title: 'Grupo Delivery',
      path: DELIVERY_GROUP_PATH,
      icon: 'motorcycle',
    },
    {
      key: 'depreciation-rule',
      title: 'Reglas de Depreciación',
      path: DEPRECIATION_RULE_PATH,
      icon: 'percent',
    },
    {
      key: 'commonProduct',
      title: 'Producto Frecuente',
      path: COMMON_PRODUCT_PATH,
      icon: 'product-hunt',
    },
    {
      key: 'cargoCategory',
      title: 'Categorías',
      path: CARGO_CATEGORY_PATH,
      icon: 'filter',
    },
    {
      key: 'minimalPrice',
      title: 'Precio mínimo',
      path: CARGO_MINIMAL_PRICE_PATH,
      icon: 'sort-numeric-desc',
    },
    {
      key: 'cargo',
      title: 'Carga',
      path: CARGO_PATH,
      icon: 'archive',
    },
    {
      key: 'internalTransfer',
      title: 'Translado Interno',
      path: INTERNAL_TRANSFER_PATH,
      icon: 'archive',
    },
    {
      key: 'cargo-item',
      title: 'Encomienda',
      path: CARGO_ITEM_PATH,
      icon: 'cube',
    },
    {
      key: 'manifest',
      title: 'Manifiesto de Carga',
      path: MANIFEST_PATH,
      icon: 'paste',
    },
    {
      key: 'legal-abandonment',
      title: 'Abandono Legal',
      path: LEGAL_ABANDONMENT_PATH,
      icon: 'home',
    },
    {
      key: 'manual-grr',
      title: 'Guía de Remision Remitente Manual',
      path: MANUAL_SENDER_REMISSION_GUIDE_PATH,
      icon: 'file-pdf-o',
    },
    {
      key: 'cargo-corporate-credit',
      title: 'Crédito Corporativo',
      path: CARGO_CORPORATE_CREDIT_PATH,
      icon: 'credit-card',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Cargo',
      tag: 'span',
      href: CARGO_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
