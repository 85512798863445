import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  DELIVERY_GROUP_PATH,
  NEW_DELIVERY_GROUP_PATH,
} from '../../../../config/paths';
import {
  getDeliveryGroups,
  clearDeliveryGroups,
} from '../../../../actions/cargo';
import { DELIVERY_GROUPS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class DeliveryGroups extends Component {
  componentWillUnmount() {
    const { dispatchClearDeliveryGroups } = this.props;
    dispatchClearDeliveryGroups();
  }

  render() {
    const { deliveryGroups, loading, breadcrumbs, dispatchGetDeliveryGroups } =
      this.props;
    const data = deliveryGroups.get('content') || [];
    const pages = deliveryGroups.get('totalPages') || null;
    const defaultPageSize = deliveryGroups.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Grupos Delivery"
        buttonPath={NEW_DELIVERY_GROUP_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: DELIVERY_GROUPS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetDeliveryGroups,
          loading,
          modelPath: DELIVERY_GROUP_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetDeliveryGroups: getDeliveryGroups,
  dispatchClearDeliveryGroups: clearDeliveryGroups,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos Delivery',
      href: DELIVERY_GROUP_PATH,
    },
  ],
  deliveryGroups: CargoUnit.DeliveryGroup.getIn(['all', 'content']),
  loading: CargoUnit.DeliveryGroup.getIn(['all', 'loading']),
});

DeliveryGroups.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  deliveryGroups: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDeliveryGroups: PropTypes.func.isRequired,
  dispatchClearDeliveryGroups: PropTypes.func.isRequired,
};

DeliveryGroups.defaultProps = {
  deliveryGroups: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryGroups);
