import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LOCATION_PATH } from '../../../../config/paths';

const LocationEditButton = ({ locationId }) => (
  <Link className="btn btn-primary" to={`${LOCATION_PATH}/${locationId}/edit`}>
    Editar
  </Link>
);

LocationEditButton.propTypes = {
  locationId: PropTypes.number.isRequired,
};

export default LocationEditButton;
