import React from 'react';
import { connect } from 'react-redux';
import { TICKET_CUSTOMER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import TicketCustomerSearch from './TicketCustomerSearch';

const TicketCustomers = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Boletos por Pedido"
    subtitle="Busca por pedido los boletos"
    content={<TicketCustomerSearch />}
  />
);

const mapStateToProps = ({ ReservationUnit }) => ({
  breadcrumbs: [
    ...ReservationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos por Pedido',
      href: TICKET_CUSTOMER_PATH,
    },
  ],
});

TicketCustomers.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

export default connect(mapStateToProps, null)(TicketCustomers);
