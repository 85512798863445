import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { enumToSelectOptions } from '../../../../utils/enum';
import { DEPRECIATION_PERIODICITY } from '../../../../config/constants';

const DepreciationPeriodicitySelect = ({
  noOptionsMessage,
  input,
  meta,
  isDisabled,
}) => (
  <Select
    options={enumToSelectOptions(DEPRECIATION_PERIODICITY)}
    noOptionsMessage={noOptionsMessage}
    input={input}
    meta={meta}
    isDisabled={isDisabled}
  />
);
DepreciationPeriodicitySelect.propTypes = {
  noOptionsMessage: PropTypes.func,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
};

DepreciationPeriodicitySelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  noOptionsMessage: undefined,
  isDisabled: false,
};

export default DepreciationPeriodicitySelect;
