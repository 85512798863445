import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Container } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import { getActiveSalesSession } from '../../../actions';
import BookingNavbar from './BookingNavbar';
import Passengers from './passengers/Passengers';
import Payment from './payment/Payment';
import Confirmation from './confirmation/Confirmation';
import Loader from '../../common/Loader';
import Alert from '../../common/informative/Alert';
import {
  BOOKING_UNIT_PATH,
  CONFIRMATION_STEP_PATH,
  PASSENGERS_STEP_PATH,
  PAYMENT_STEP_PATH,
  SEARCH_STEP_PATH,
} from '../../../config/paths';
import RoundTrip from './RoundTrip';

class BookingModule extends Component {
  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const { dispatchGetActiveSalesSession, user } = this.props;

    // if user doesn't logged (user-prop is null) then it does nothing
    if (user) {
      // if salesSessionUserId is not null then user is a chinalco's user
      if (!user.salesSessionUserId) {
        dispatchGetActiveSalesSession({ source: '/booking/search' });
      }
    }
  };

  render() {
    const { gettingActiveSalesSession, activeSalesSession, currentStep, user } =
      this.props;

    if (gettingActiveSalesSession) {
      return <Loader />;
    }

    // if user doesn't logged then it doesn't show nothing
    if (user) {
      if (!user.salesSessionUserId && activeSalesSession.isEmpty()) {
        return (
          <Container>
            <Alert message="No hay ninguna sesión de ventas activa." />
          </Container>
        );
      }
    }

    return (
      <div>
        <Container fluid>
          <h1>Nueva Reserva</h1>
          <BookingNavbar currentStep={currentStep} />
          <Switch>
            <Route exact path={BOOKING_UNIT_PATH} component={RoundTrip} />
            <Route exact path={SEARCH_STEP_PATH} component={RoundTrip} />
            <Route path={PASSENGERS_STEP_PATH} component={Passengers} />
            <Route path={PAYMENT_STEP_PATH} component={Payment} />
            <Route path={CONFIRMATION_STEP_PATH} component={Confirmation} />
          </Switch>
        </Container>
      </div>
    );
  }
}

BookingModule.propTypes = {
  currentStep: PropTypes.number.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    securityProfileSet: PropTypes.arrayOf(
      PropTypes.shape({
        viewAuthorizationList: PropTypes.arrayOf(
          PropTypes.shape({
            view: PropTypes.shape({
              route: PropTypes.string,
            }),
          }),
        ).isRequired,
      }),
    ).isRequired,
    salesSessionUserId: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ BookingUnit, SalesUnit, authentication }) => ({
  currentStep: BookingUnit.Booking.get('currentStep'),
  gettingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  user: authentication.get('user'),
});

const mapDispatchToProps = {
  dispatchGetActiveSalesSession: getActiveSalesSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingModule);
