import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getPrintStations,
  clearPrintStations,
} from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

class PrintStationSelect extends Component {
  componentDidMount() {
    const { dispatchGetPrintStations } = this.props;
    dispatchGetPrintStations(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearPrintStations } = this.props;
    dispatchClearPrintStations();
  }

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

PrintStationSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetPrintStations: PropTypes.func.isRequired,
  dispatchClearPrintStations: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

PrintStationSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  options: HumanResourcesUnit.PrintStation.getIn([
    'all',
    'content',
    'content',
  ]).map((printStation) => ({
    ...printStation,
    value: printStation.id,
    label: `${printStation.name} (${printStation.serialNumber})`,
  })),
  loading: HumanResourcesUnit.PrintStation.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetPrintStations: getPrintStations,
  dispatchClearPrintStations: clearPrintStations,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintStationSelect);
