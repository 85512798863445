import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { READ, WRITE } from '../../../config/permissions';

const withEndpointAuthorization =
  ({ url, mapUrlParamsToProps, permissionType }) =>
  (ChildComponent) => {
    const ComposedComponent = (props) => {
      const { securityProfiles } = props;
      let endpoint = url;
      // if mapPropsToUrlParams has elements it means the url contains parameters
      // that need to be replaced
      if (mapUrlParamsToProps) {
        Object.keys(mapUrlParamsToProps).forEach((prop) => {
          const urlParam = mapUrlParamsToProps[prop];
          endpoint = endpoint.replace(`:${urlParam}`, props[prop]);
        });
      }
      let allowed = false;
      if (
        securityProfiles &&
        securityProfiles.length &&
        endpoint &&
        permissionType
      ) {
        // validate if the user has the correct permission for the given endpoint url
        allowed = securityProfiles.some((securityProfile) =>
          securityProfile.authorizedEndpointList.some((authorizedEndpoint) => {
            // TODO: remove first validation once security profiles have the correct
            // endpoints
            if (
              authorizedEndpoint.endpoint.route !== '.*' &&
              endpoint.match(authorizedEndpoint.endpoint.route)
            ) {
              // by default if the user is authorized to write
              // to this endpoint, then he/she can read
              if (authorizedEndpoint.endpoint.permissionType === WRITE) {
                return true;
              } else if (
                permissionType === READ &&
                authorizedEndpoint.endpoint.permissionType === READ
              ) {
                return true;
              }
              return false;
            }
            return false;
          }),
        );
      }
      if (allowed) {
        return <ChildComponent {...props} />;
      }
      return null;
    };

    ComposedComponent.propTypes = {
      securityProfiles: PropTypes.arrayOf(
        PropTypes.shape({
          authorizedEndpointList: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              endpoint: PropTypes.shape({
                id: PropTypes.number.isRequired,
                route: PropTypes.string.isRequired,
                permissionType: PropTypes.string.isRequired,
              }),
            }),
          ),
        }),
      ).isRequired,
    };

    const mapStateToProps = ({ authentication }) => ({
      securityProfiles: authentication.get('user').securityProfileSet,
    });

    return connect(mapStateToProps)(ComposedComponent);
  };

export default withEndpointAuthorization;
