import React from 'react';
import PropTypes from 'prop-types';
import SelectFilter from './SelectFilter';
import { enumToSelectOptions } from '../../../utils/enum';

const EnumSelectFilter = ({ isMulti, onChangeFilter, enumOptions }) => (
  <SelectFilter
    isMulti={isMulti}
    onChangeFilter={onChangeFilter}
    options={enumToSelectOptions(enumOptions)}
  />
);

EnumSelectFilter.propTypes = {
  isMulti: PropTypes.bool,
  onChangeFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  enumOptions: PropTypes.objectOf(PropTypes.object).isRequired,
};

EnumSelectFilter.defaultProps = {
  isMulti: false,
};

export default EnumSelectFilter;
