import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { FUEL_STATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import FuelStationForm from './FuelStationForm';
import {
  putFuelStation,
  getFuelStation,
} from '../../../../actions/route/FuelStation';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditFuelStaion extends Component {
  componentDidMount() {
    const {
      dispatchGetFuelStation,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetFuelStation({ id });
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutFuelStation,
      match: {
        params: { id },
      },
    } = this.props;
    const values = {
      locationId: formValues.locationId.value,
      businessId: formValues.businessId.value,
    };
    dispatchPutFuelStation(id, values);
  };

  createInitialValues = (fuelStation) => ({
    id: fuelStation.get('id'),
    locationId: {
      value: fuelStation.get('location').id,
      label: fuelStation.get('location').name,
    },
    businessId: {
      value: fuelStation.get('business').id,
      label: `${fuelStation.get('business').businessTaxId} - ${
        fuelStation.get('business').name
      }`,
    },
  });

  render() {
    const { breadcrumbs, activity, fuelStation } = this.props;

    let content;

    if (activity) {
      content = <Loader />;
    } else if (fuelStation.isEmpty()) {
      content = <NoDataResource returnPage={FUEL_STATION_PATH} />;
    } else {
      content = (
        <FuelStationForm
          initialValues={this.createInitialValues(fuelStation)}
          onSubmit={this.onSubmit}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Grifo"
        subtitle="Editar grifo"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { RouteUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grifos',
      href: FUEL_STATION_PATH,
    },
    {
      text: 'Ver',
      href: `${FUEL_STATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  fuelStation: RouteUnit.FuelStation.getIn(['current', 'content']),
  activity: !RouteUnit.FuelStation.getIn(['current', 'activity']).isEmpty(),
});

EditFuelStaion.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetFuelStation: PropTypes.func.isRequired,
  dispatchPutFuelStation: PropTypes.func.isRequired,
  fuelStation: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  activity: PropTypes.bool.isRequired,
};

EditFuelStaion.defaultProps = {
  breadcrumbs: [],
};

const mapDispatchToProps = {
  dispatchGetFuelStation: getFuelStation,
  dispatchPutFuelStation: putFuelStation,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFuelStaion);
