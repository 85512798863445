import Immutable from 'immutable';
import {
  FLAG_ITINERARY_QUALITY_ACTIVITY,
  GET_ITINERARY_QUALITY,
  CLEAR_ITINERARY_QUALITY,
} from '../../../actions/types/itinerary/ItineraryQuality';

const INITIAL_STATE = Immutable.Map({
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_ITINERARY_QUALITY_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_ITINERARY_QUALITY:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_ITINERARY_QUALITY:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
