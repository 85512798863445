import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { driverStatusInformationPropTypes } from '../proptypes/DriverPropTypes';

const DriverStatusInformationResource = ({
  driverType,
  homeBase,
  crewStatus,
}) => (
  <Fragment>
    <ResourceProperty label="Tipo:">{driverType.name}</ResourceProperty>
    <ResourceProperty label="Base:">{homeBase.name}</ResourceProperty>
    <ResourceProperty label="Estado:">{crewStatus.name}</ResourceProperty>
  </Fragment>
);

DriverStatusInformationResource.propTypes = driverStatusInformationPropTypes;

export default DriverStatusInformationResource;
