import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { COMPANY_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CompanyForm from './CompanyForm';
import Loader from '../../../common/Loader';
import { clearCompany, getCompany, putCompany } from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import { COMPANY_TYPE } from '../../../../config/constants';
import Content from '../../../layout/Content';

export class EditCompany extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: companyId },
      },
      dispatchGetCompany,
    } = this.props;
    dispatchGetCompany({ companyId });
  }

  componentWillUnmount() {
    const { dispatchClearCompany } = this.props;
    dispatchClearCompany();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutCompany,
      match: {
        params: { id },
      },
    } = this.props;

    const newFormValues = {
      name: formValues.name,
      businessId: formValues.businessId.value,
      companyType: formValues.companyType.value,
    };

    dispatchPutCompany(id, newFormValues);
  };

  createInitialValues = (company) => ({
    name: company.get('name'),
    businessId: company.get('business')
      ? {
          value: company.get('business').id,
          label: company.get('business').name,
        }
      : null,
    companyType: {
      value: COMPANY_TYPE[company.get('companyType')].value,
      label: COMPANY_TYPE[company.get('companyType')].label,
    },
  });

  render() {
    const { breadcrumbs, company, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (company.isEmpty()) {
      content = <NoDataResource returnPage={COMPANY_PATH} />;
    } else {
      content = (
        <CompanyForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(company)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Empresa Tercera"
        subtitle="Edita esta empresa tercera"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { ContractUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas Terceras',
      href: COMPANY_PATH,
    },
    {
      text: 'Ver',
      href: `${COMPANY_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  company: ContractUnit.Company.getIn(['current', 'content']),
  loading: !ContractUnit.Company.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutCompany: putCompany,
  dispatchGetCompany: getCompany,
  dispatchClearCompany: clearCompany,
};

EditCompany.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutCompany: PropTypes.func.isRequired,
  dispatchGetCompany: PropTypes.func.isRequired,
  dispatchClearCompany: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  company: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCompany.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
