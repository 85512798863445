import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  DEPRECIATION_RULE_PATH,
  NEW_DEPRECIATION_RULE_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { DEPRECIATION_RULES_COLUMNS } from '../../../../config/columns';
import {
  clearDepreciationRules,
  getDepreciationRules,
} from '../../../../actions/cargo';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class DepreciationRules extends Component {
  constructor(props) {
    super(props);
    this.columns = DEPRECIATION_RULES_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearDepreciationRules();
  }

  render() {
    const {
      depreciationRules,
      loading,
      breadcrumbs,
      dispatchGetDepreciationRules,
    } = this.props;
    const data = depreciationRules.get('content') || [];
    const pages = depreciationRules.get('totalPages') || null;
    const defaultPageSize = depreciationRules.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Reglas de Depreciación"
        buttonPath={NEW_DEPRECIATION_RULE_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetDepreciationRules,
          modelPath: DEPRECIATION_RULE_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetDepreciationRules: getDepreciationRules,
  dispatchClearDepreciationRules: clearDepreciationRules,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reglas de Depreciación',
      href: DEPRECIATION_RULE_PATH,
    },
  ],
  depreciationRules: CargoUnit.DepreciationRule.getIn(['all', 'content']),
  loading: CargoUnit.DepreciationRule.getIn(['all', 'loading']),
});

DepreciationRules.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  depreciationRules: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetDepreciationRules: PropTypes.func.isRequired,
  dispatchClearDepreciationRules: PropTypes.func.isRequired,
};

DepreciationRules.defaultProps = {
  depreciationRules: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepreciationRules);
