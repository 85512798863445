import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_COUNTRIES,
  GET_COUNTRIES,
  CLEAR_COUNTRIES,
  FLAG_COUNTRY_ACTIVITY,
  GET_COUNTRY,
  CLEAR_COUNTRY,
  POST_COUNTRY,
  PUT_COUNTRY,
  FLAG_POSTING_COUNTRY,
} from '../types';
import { COUNTRY_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { COUNTRY_PATH } from '../../config/paths';

const flagGettingCountries = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COUNTRIES,
    payload: flag,
  });

const getCountries = async (tableFilters) => async (dispatch) => {
  dispatch(flagGettingCountries(true));
  try {
    const query = tableFilters;

    const url = `${COUNTRY_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const countries = await response.json();
    dispatch({
      type: GET_COUNTRIES,
      payload: countries,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingCountries(false));
  }
};

const clearCountries = () => (dispatch) =>
  dispatch({
    type: CLEAR_COUNTRIES,
  });

const flagCountryActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COUNTRY_ACTIVITY,
    payload: flag,
  });

const getCountry = async (countryId) => async (dispatch) => {
  dispatch(flagCountryActivity(true));
  try {
    const url = `${COUNTRY_ENDPOINT}/${countryId}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const country = await response.json();
    dispatch({
      type: GET_COUNTRY,
      payload: country,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagCountryActivity(false));
  }
};

const flagPostingCountry = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_POSTING_COUNTRY,
    payload: flag,
  });

const postCountry =
  async ({ name, countryCode }) =>
  async (dispatch) => {
    dispatch(flagCountryActivity(true));
    try {
      const payload = { name, countryCode };
      const url = COUNTRY_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const country = await response.json();
      dispatch({
        type: POST_COUNTRY,
        payload: country,
      });
      dispatch(push(COUNTRY_PATH));
    } catch (error) {
      toastr.error('Error',error.message);
    } finally {
      dispatch(flagCountryActivity(false));
    }
  };

const putCountry =
  async (countryId, { name, countryCode }) =>
  async (dispatch) => {
    try {
      dispatch(flagCountryActivity(true));
      const payload = { name, countryCode };
      const url = `${COUNTRY_ENDPOINT}/${countryId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const country = await response.json();
      dispatch({
        type: PUT_COUNTRY,
        payload: country,
      });
      dispatch(push(COUNTRY_PATH));
    } catch (error) {
      toastr.error('Error',error.message);
    } finally {
      dispatch(flagCountryActivity(false));
    }
  };

const clearCountry = () => (dispatch) =>
  dispatch({
    type: CLEAR_COUNTRY,
  });

const deleteCountry =
  async ({ countryId }) =>
  async (dispatch) => {
    dispatch(flagCountryActivity(true));
    try {
      const url = `${COUNTRY_ENDPOINT}/${countryId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      isErrorResponse(response);
      const voidedTicket = await response.json();
      dispatch({
        type: GET_COUNTRY,
        payload: voidedTicket,
      });
      dispatch(push(`${COUNTRY_PATH}`));
    } catch (error) {
      toastr.error('Error',error.message);
    } finally {
      dispatch(flagCountryActivity(false));
    }
  };

export {
  flagGettingCountries,
  getCountries,
  clearCountries,
  flagCountryActivity,
  getCountry,
  clearCountry,
  postCountry,
  flagPostingCountry,
  putCountry,
  deleteCountry,
};
