import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_PAYMENT_METHODS,
  SHOW_ERROR_GETTING_PAYMENT_METHODS,
  CLEAR_ERROR_GETTING_PAYMENT_METHODS,
  GET_PAYMENT_METHODS,
  CLEAR_PAYMENT_METHODS,
} from '../types';
import { DEFAULT_QUERY_GET_ALL } from '../../config/queries';
import {
  PAYMENT_METHOD_ENDPOINT,
  PAYMENT_METHOD_SECURITY_PROFILE_ENDPOINT,
} from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingPaymentMethods = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PAYMENT_METHODS,
    payload: flag,
  });

const showErrorGettingPaymentMethods = (error) => (dispatch) =>
  dispatch({
    type: SHOW_ERROR_GETTING_PAYMENT_METHODS,
    payload: error,
  });

const clearErrorGettingPaymentMethods = () => (dispatch) =>
  dispatch({
    type: CLEAR_ERROR_GETTING_PAYMENT_METHODS,
  });

const getPaymentMethods =
  async (query = null) =>
  async (dispatch) => {
    dispatch(flagGettingPaymentMethods(true));

    try {
      const parameters = {
        ...DEFAULT_QUERY_GET_ALL,
        query,
      };
      const url = `${PAYMENT_METHOD_ENDPOINT}?${QueryString.stringify(
        parameters,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const paymentMethods = await response.json();
      dispatch({
        type: GET_PAYMENT_METHODS,
        payload: paymentMethods,
      });
    } catch ({ message }) {
      dispatch(flagGettingPaymentMethods(false));
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingPaymentMethods(false));
    }
  };

const getPaymentMethodsPerSecurityProfile =
  async (query = null) =>
  async (dispatch) => {
    dispatch(clearErrorGettingPaymentMethods());
    dispatch(flagGettingPaymentMethods(true));

    try {
      const parameters = {
        ...DEFAULT_QUERY_GET_ALL,
        query,
      };
      const url = `${PAYMENT_METHOD_SECURITY_PROFILE_ENDPOINT}?${QueryString.stringify(
        parameters,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const paymentMethods = await response.json();
      dispatch({
        type: GET_PAYMENT_METHODS,
        payload: paymentMethods,
      });
    } catch ({ message }) {
      dispatch(flagGettingPaymentMethods(false));
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingPaymentMethods(false));
    }
  };

const clearPaymentMethods = () => (dispatch) =>
  dispatch({
    type: CLEAR_PAYMENT_METHODS,
  });

export {
  flagGettingPaymentMethods,
  showErrorGettingPaymentMethods,
  clearErrorGettingPaymentMethods,
  getPaymentMethods,
  clearPaymentMethods,
  getPaymentMethodsPerSecurityProfile,
};
