import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPaymentMethods, clearPaymentMethods } from '../../../actions/index';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';

class PaymentMethodFilter extends Component {
  static propTypes = {
    dispatchGetPaymentMethods: PropTypes.func.isRequired,
    dispatchClearPaymentMethods: PropTypes.func.isRequired,
    options: optionsPropTypes,
    onChangeFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: [],
  };

  constructor(props) {
    super(props);
    this.props.dispatchGetPaymentMethods();
  }

  componentWillUnmount() {
    this.props.dispatchClearPaymentMethods();
  }

  render() {
    const { options, onChangeFilter } = this.props;
    return (
      <SelectFilter isMulti onChangeFilter={onChangeFilter} options={options} />
    );
  }
}

const mapStateToProps = ({ AccountingUnit }) => ({
  options: AccountingUnit.PaymentMethod.getIn([
    'all',
    'content',
    'content',
  ]).map((option) => ({
    value: option.id,
    label: option.name,
    transactionCode: option.transactionCode,
  })),
});

const mapDispatchToprops = {
  dispatchGetPaymentMethods: getPaymentMethods,
  dispatchClearPaymentMethods: clearPaymentMethods,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(PaymentMethodFilter);
