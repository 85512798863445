import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMaterials, clearMaterials } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';

export const MaterialSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearMaterials,
  dispatchGetMaterials,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearMaterials(), []);

  const onInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      const index = inputValue.indexOf(',');

      let query = [`externalId:${inputValue}`];

      if (index >= 0) {
        const { length } = inputValue;

        const externalId = inputValue.substring(0, index).trim();

        const name = inputValue.substring(index + 1, length).trim();

        query = [];

        if (externalId.length) query.push(`externalId:${externalId}`);

        if (name.length) query.push(`name:${name}`);
      }

      dispatchGetMaterials({ query });
    }
  };

  const filterOption = (users) => users;

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChange}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      filterOption={filterOption}
      {...rest}
    />
  );
};

MaterialSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetMaterials: PropTypes.func.isRequired,
  dispatchClearMaterials: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

MaterialSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca así: código,nombre',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.Material.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, externalId, name, measurementUnit }) => ({
    value: id,
    label: `${externalId} - ${name} - ${measurementUnit}`,
  })),
  loading: MechanicalMaintenanceUnit.Material.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetMaterials: getMaterials,
  dispatchClearMaterials: clearMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSelect);
