import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { MECHANIC_PATH } from '../../../../config/paths';
import MechanicDeleteButton from './MechanicDeleteButton';

const MechanicToolbar = ({ mechanicId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${MECHANIC_PATH}/${mechanicId}/edit`} />{' '}
      <MechanicDeleteButton mechanicId={mechanicId} />
    </div>
  </ButtonToolbar>
);

MechanicToolbar.propTypes = {
  mechanicId: PropTypes.number.isRequired,
};

export default MechanicToolbar;
