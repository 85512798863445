import Immutable from 'immutable';
import { GET_ENDPOINT_CONFIGURATION } from '../actions/types';

const INITIAL_STATE = Immutable.Map();

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ENDPOINT_CONFIGURATION:
      return Immutable.Map(action.payload);
    default:
      return state;
  }
};
