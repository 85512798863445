import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, ListGroup } from 'reactstrap';
import ItinerarySearchResult from './ItinerarySearchResult';
import Loader from '../../../common/Loader';
import { selectItinerarySearchResult } from '../../../../actions/booking';
import {
  CONTRACT_TYPE,
  ITINERARY_TYPE_CONTRACT,
} from '../../../../config/constants';
import { ROUTE_WITHOUT_ITINERARIES } from '../../../../config/messages';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

const renderItinerarySearchResultList = (
  itinerarySearchResults,
  index,
  handleSelectItinerary,
  salesSessionUserId,
  agencyContractType,
) => {
  if (!itinerarySearchResults)
    return (
      <div className="border rounded p-3">
        <h5>Lista de Itinerarios</h5>
        <span className="text-muted">
          <i>No hay itinerarios</i>
        </span>
      </div>
    );

  if (!itinerarySearchResults.length)
    return <Alert color="danger">{ROUTE_WITHOUT_ITINERARIES}</Alert>;

  const newAgencyContractType =
    agencyContractType || CONTRACT_TYPE.DEFAULT.value;

  const currentDate = tzNormalizeDate({ format: TIMESTAMP_FORMAT });

  const filteredItinenaries = itinerarySearchResults.filter(
    (itinerarySearchResult) => {
      if (
        (itinerarySearchResult.itinerary.type.trim().toLowerCase() ===
          ITINERARY_TYPE_CONTRACT.toLowerCase() &&
          !salesSessionUserId) ||
        (itinerarySearchResult.departureTime <= currentDate &&
          newAgencyContractType !== CONTRACT_TYPE.DEFAULT.value)
      ) {
        return false;
      }
      return true;
    },
  );

  return filteredItinenaries.map((itinerarySearchResult, itineraryIndex) => (
    <ItinerarySearchResult
      key={`${itinerarySearchResult.itinerary.id}-${+itineraryIndex}`}
      itinerarySearchResult={itinerarySearchResult}
      handleSelectItinerary={handleSelectItinerary}
      index={index}
    />
  ));
};

const ItinerarySearchResults = ({
  itineraries,
  searchingForItineraries,
  dispatchSelectItinerarySearchResult,
  user,
  agencyContractType,
}) => {
  // if user doesn't logged then it doesn't show nothing
  if (user) {
    if (searchingForItineraries) {
      return <Loader />;
    }
    // todo: show different indexes for multi-step reservations
    const index = 0;
    return (
      <div className="mt-4">
        <ListGroup>
          {renderItinerarySearchResultList(
            itineraries,
            index,
            dispatchSelectItinerarySearchResult,
            user.salesSessionUserId,
            agencyContractType,
          )}
        </ListGroup>
      </div>
    );
  }

  return null;
};

ItinerarySearchResults.propTypes = {
  itineraries: PropTypes.arrayOf(
    PropTypes.shape({
      seatMapDisplayName: PropTypes.string.isRequired,
      lowestAvailablePrice: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired,
      arrivalTime: PropTypes.number.isRequired,
      departureTime: PropTypes.number.isRequired,
      origin: PropTypes.PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
      destination: PropTypes.PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ),
  searchingForItineraries: PropTypes.bool.isRequired,
  source: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  destination: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  dispatchSelectItinerarySearchResult: PropTypes.func.isRequired,
  user: PropTypes.shape({
    salesSessionUserId: PropTypes.number,
  }).isRequired,
  agencyContractType: PropTypes.string,
};

ItinerarySearchResults.defaultProps = {
  itineraries: [],
  source: null,
  destination: null,
  agencyContractType: null,
};

const mapStateToProps = ({ BookingUnit, authentication, SalesUnit }) => ({
  source: BookingUnit.Booking.getIn(['search', 'query', 'source']),
  destination: BookingUnit.Booking.getIn(['search', 'query', 'destination']),
  time: BookingUnit.Booking.getIn(['search', 'query', 'time']),
  itineraries:
    BookingUnit.Booking.getIn(['itineraries', 'results', 'content']) || null,
  searchingForItineraries: BookingUnit.Booking.getIn([
    'itineraries',
    'searchingForItineraries',
  ]),
  user: authentication.get('user'),
  agencyContractType: SalesUnit.SalesSession.getIn([
    'active',
    'content',
    'agency',
    'contractType',
  ]),
});

const mapDispatchToProps = {
  dispatchSelectItinerarySearchResult: selectItinerarySearchResult,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerarySearchResults);
