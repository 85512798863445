import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import TextInput from '../../../../common/forms/input/TextInput';
import Loader from '../../../../common/Loader';
import { getDelayReasons, clearDelayReasons } from '../../../../../actions';
import Select from '../../../../common/forms/select/Select';
import FormFooter from '../../../../common/forms/FormFooter';

class ItineraryQualityControlForm extends Component {
  componentDidMount() {
    const { dispatchGetDelayReasons } = this.props;
    dispatchGetDelayReasons();
  }

  componentWillUnmount() {
    const { dispatchClearDelayReasons } = this.props;
    dispatchClearDelayReasons();
  }

  render() {
    const { loading, handleSubmit, delayReasons } = this.props;

    if (loading) {
      return <Loader />;
    }

    const delayReasonOptions = [];
    delayReasons.forEach((delayReason) => {
      delayReasonOptions.push({
        value: delayReason.id,
        label: delayReason.reason,
      });
    });

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Comentarios Puntualidad:">
            <Field
              name="comments"
              component={TextInput}
              type="textarea"
              placeholder="Comentarios Puntualidad"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Motivo Tardanza:">
            <Field
              name="delayReasonList"
              component={Select}
              isMulti
              options={delayReasonOptions}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

ItineraryQualityControlForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetDelayReasons: PropTypes.func.isRequired,
  dispatchClearDelayReasons: PropTypes.func.isRequired,
  delayReasons: PropTypes.instanceOf(Immutable.Set).isRequired,
  initialValues: PropTypes.shape({}),
};

ItineraryQualityControlForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  delayReasons: ItineraryUnit.DelayReason.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetDelayReasons: getDelayReasons,
  dispatchClearDelayReasons: clearDelayReasons,
};

const formComponent = reduxForm({
  form: 'ItineraryQualityControlForm',
})(ItineraryQualityControlForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
