import { string, number, arrayOf, shape } from 'prop-types';
import { customerBasicInformationPropTypes } from '../../customer/proptypes/CustomerPropTypes';

const businessBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  businessTaxId: string.isRequired,
};

const businessContactInformationPropTypes = {
  primaryContact: string,
  email: string,
  phone: string,
  fax: string,
  addressSummary: string,
};

const businessContactInformationDefaultProps = {
  primaryContact: null,
  email: null,
  phone: null,
  fax: null,
  addressSummary: null,
};

const businessCustomerListPropTypes = {
  customerList: arrayOf(shape(customerBasicInformationPropTypes)),
};

const businessCustomerListDefaultProps = {
  customerList: null,
};

export {
  businessBasicInformationPropTypes,
  businessContactInformationPropTypes,
  businessContactInformationDefaultProps,
  businessCustomerListPropTypes,
  businessCustomerListDefaultProps,
};
