import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { numberFormatter } from '../../../../../utils/number';
import Table from '../../../../common/Table';

const LiquidationExpenseResource = ({
  expenseSummaryList,
  expenseTypeSummaryList,
}) => {
  if (expenseSummaryList.length === 0) return null;

  const [show, setShow] = useState(false);

  const expenseTypeSummaryContent = expenseTypeSummaryList.map(
    ({ moneyFlowConcept, totalAmount }) => (
      <ResourceProperty key={moneyFlowConcept} label={`${moneyFlowConcept}:`}>
        {numberFormatter({ value: totalAmount })}
      </ResourceProperty>
    ),
  );

  const iconType = show ? 'minus' : 'plus';

  const expenseSummaryContent = show && (
    <div className="mb-3">
      <Table
        columns={[
          {
            Header: 'Serie',
            accessor: 'documentSeries',
            className: 'text-center',
          },
          {
            Header: 'Total de Ventas',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={expenseSummaryList}
        defaultPageSize={expenseSummaryList.length}
        showPagination={false}
      />
    </div>
  );

  const icon = expenseSummaryList.length > 0 && (
    <i
      className={`fa fa-${iconType}-square-o text-primary`}
      tabIndex="0"
      role="button"
      onKeyPress={() => setShow(!show)}
      onClick={() => setShow(!show)}
    />
  );

  const total = expenseTypeSummaryList.reduce(
    (accumulated, data) => accumulated + data.totalAmount,
    0,
  );

  return (
    <>
      <hr />
      <h3>Egresos {icon}</h3>
      {expenseSummaryContent}
      {expenseTypeSummaryContent}
      <h4 className="text-right">
        Egresos Totales:{' '}
        {numberFormatter({
          value: total,
        })}
      </h4>
    </>
  );
};

LiquidationExpenseResource.propTypes = {
  expenseSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      documentSeries: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  expenseTypeSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
};

LiquidationExpenseResource.defaultProps = {
  expenseSummaryList: [],
  expenseTypeSummaryList: [],
};

export default LiquidationExpenseResource;
