import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  FLAG_GETTING_BAGGAGES,
  GET_BAGGAGES,
  CLEAR_BAGGAGES,
  FLAG_BAGGAGE_ACTIVITY,
  GET_BAGGAGE,
  CLEAR_BAGGAGE,
  FLAG_GETTING_BAGGAGE_EXCESSES,
  CLEAR_BAGGAGE_EXCESSES,
  GET_BAGGAGE_EXCESSES,
} from '../../../actions/types/baggage';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
  excess: Immutable.Map({
    content: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_BAGGAGES:
      return state.setIn(['all', 'loading'], payload);
    case GET_BAGGAGES:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_BAGGAGES:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_BAGGAGE_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_BAGGAGE:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_BAGGAGE:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_GETTING_BAGGAGE_EXCESSES:
      return state.setIn(['excess', 'loading'], payload);
    case GET_BAGGAGE_EXCESSES:
      return state.setIn(['excess', 'content'], Immutable.Set(payload));
    case CLEAR_BAGGAGE_EXCESSES:
      return state.setIn(
        ['excess', 'content'],
        INITIAL_STATE.getIn(['excess', 'content']),
      );
    default:
      return state;
  }
};
