import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteDriver } from '../../../../actions';

class DriverDeleteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  onClickDelete = () => {
    const { dispatchDeleteDriver, driverId } = this.props;
    dispatchDeleteDriver({ driverId });
  };

  onClickShowModal = () => this.setState({ showModal: true });

  onClickCancel = () => this.setState({ showModal: false });

  render() {
    const { showModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showModal}
        onClickConfirm={this.onClickDelete}
        onClickCancel={this.onClickCancel}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.onClickShowModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DriverDeleteButton.propTypes = {
  driverId: PropTypes.number.isRequired,
  dispatchDeleteDriver: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteDriver: deleteDriver,
};

export default connect(null, mapDispatchToProps)(DriverDeleteButton);
