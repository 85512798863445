import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import AgencyToFuelStationForm from './AgencyToFuelStationForm';
import { putFuelStation } from '../../../../actions';
import Content from '../../../layout/Content';

export class AgencyToFuelStation extends Component {
  onSubmit = (formValues) => {
    this.props.dispatchPutFuelStation(formValues.fuelStationId, formValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Asignar Agencia a Grifo"
        subtitle="AsignaraAgencia a grifo"
        content={<AgencyToFuelStationForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignar Agencia a Grifo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPutFuelStation: putFuelStation,
};

AgencyToFuelStation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutFuelStation: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencyToFuelStation);
