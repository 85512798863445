import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  HOME_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
  USER_SELF_PATH,
} from '../../config/paths';
import logo from '../../resources/images/logo-civa.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      open: false,
    };
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  renderLinks() {
    if (!this.props.authenticated) {
      return [
        <NavItem key={1}>
          <NavLink tag={Link} to={SIGN_IN_PATH}>
            Iniciar Sesión
          </NavLink>
        </NavItem>,
      ];
    }
    return [
      <NavItem key={1}>
        <NavLink
          href="https://drive.google.com/file/d/1n_M95FIVVpeCSrYCw7OFB7y0EJ-nLDX2/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Soluciones Rápidas
        </NavLink>
      </NavItem>,
      <NavItem key={2}>
        <NavLink
          href="https://goo.gl/forms/3KMrfqVOvzMriL7E3"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reportar Problema
        </NavLink>
      </NavItem>,
      <NavItem key={3}>
        <NavLink tag={Link} to={USER_SELF_PATH}>
          Mi Usuario
        </NavLink>
      </NavItem>,
      <NavItem key={4}>
        <NavLink tag={Link} to={SIGN_OUT_PATH}>
          Cerrar Sesión
        </NavLink>
      </NavItem>,
    ];
  }

  render() {
    return (
      <header>
        <Navbar color="faded" light expand="md">
          <NavbarBrand href={HOME_PATH}>
            <img src={logo} className="header-logo" alt="Flit" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.open} navbar>
            <Nav className="ml-auto" navbar>
              {this.renderLinks()}
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

Header.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

export default Header;
