import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { clearAddress, getAddress } from '../../../../actions';
import { ADDRESS_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import AddressBasicInformationResource from './resource/AddressBasicInformationResource';
import AddressToolbar from './AddressToolbar';

export const Address = ({
  breadcrumbs,
  match: {
    params: { id: addressId },
  },
  address,
  loading,
  dispatchGetAddress,
  dispatchClearAddress,
}) => {
  useLayoutEffect(() => {
    dispatchGetAddress({ addressId });
    return () => dispatchClearAddress();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (address.isEmpty())
    content = <NoDataResource returnPage={ADDRESS_PATH} />;
  else {
    const addressJson = address.toJS();
    content = <AddressBasicInformationResource {...addressJson} />;
    toolbar = <AddressToolbar addressId={addressJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Dirección"
      content={content}
    />
  );
};

Address.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearAddress: PropTypes.func.isRequired,
  dispatchGetAddress: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  address: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Address.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetAddress: getAddress,
  dispatchClearAddress: clearAddress,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Direcciones',
      href: ADDRESS_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  address: LocationUnit.Address.getIn(['current', 'content']),
  loading: !LocationUnit.Address.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Address);
