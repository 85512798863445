import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Col, Row } from 'reactstrap';
import { push } from 'react-router-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../../layout/Content';
import AssignEmployeesToMaintenanceServiceOrderForm from './AssignEmployeesToMaintenanceServiceOrderForm';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../../config/paths';
import Loader from '../../../../common/Loader';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Step from '../../../../common/Step';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
} from '../../../../../actions';
import MaintenanceServiceOrderBasicInformation from '../resource/MaintenanceServiceOrderBasicInformationResource';
import MaintenanceServiceOrderMaintenanceWarningBadgeListInformation from '../resource/MaintenanceServiceOrderMaintenanceWarningBadgeListInformation';

const AssignEmployeesToMaintenanceServiceOrder = ({
  dispacthGetMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder,
  loading,
  maintenanceServiceOrder,
  match: {
    params: { id: maintenanceServiceOrderId },
  },
  breadcrumbs,
  dispatchPush,
}) => {
  useLayoutEffect(() => {
    dispacthGetMaintenanceServiceOrder({ maintenanceServiceOrderId });

    return () => {
      dispacthClearMaintenanceServiceOrder();
    };
  }, []);

  const onSubmitToStepAssignEmployees = () =>
    dispatchPush(MAINTENANCE_SERVICE_ORDER_PATH);

  const generateInitialValues = (maintenanceServiceOrderJson) => ({
    activityBusExecutionList:
      maintenanceServiceOrderJson &&
      maintenanceServiceOrderJson.activityBusExecutionList.map(
        ({
          activityBus: {
            activity: { companyActivityId, name, id, activityTaskList },
          },
          employeeList,
          activityTaskExecutionList,
        }) => ({
          activityId: {
            value: id,
            label: `${companyActivityId} - ${name}`,
          },
          employeeId: employeeList.map((employee) => ({
            value: employee.employeeId,
            label: employee.employee.customer.fullName,
          })),
          activityTaskList: activityTaskList.map((activityTask) => {
            const activityTaskExecutionFound = activityTaskExecutionList.find(
              (activityTaskExecution) =>
                activityTaskExecution.activityTaskId === activityTask.id,
            );
            const employeeId = activityTaskExecutionFound
              ? {
                  value: activityTaskExecutionFound.employeeId,
                  label: activityTaskExecutionFound.employee.customer.fullName,
                }
              : null;
            return {
              taskId: {
                value: activityTask.id,
                label: activityTask.description,
              },
              employeeId,
            };
          }),
        }),
      ),
  });

  let content;

  if (loading) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_SERVICE_ORDER_PATH} />;
  else {
    const maintenanceServiceOrderJson = maintenanceServiceOrder.toJS();

    const badgeListComponent = (
      <MaintenanceServiceOrderMaintenanceWarningBadgeListInformation
        maintenanceWarningList={
          maintenanceServiceOrderJson.maintenanceWarningList
        }
      />
    );
    content = (
      <>
        <Step
          stepElements={[
            { text: 'Actualizar orden de servicio' },
            { text: 'Asociar actividad' },
            { text: 'Asociar empleados', active: true },
          ]}
        />
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Datos de la Orden de Servicio</h3>
            <MaintenanceServiceOrderBasicInformation
              {...maintenanceServiceOrderJson}
            />
          </Col>
          <Col lg={6}>{badgeListComponent}</Col>
        </Row>
        <AssignEmployeesToMaintenanceServiceOrderForm
          onSubmit={onSubmitToStepAssignEmployees}
          initialValues={generateInitialValues(maintenanceServiceOrder.toJS())}
          maintenanceServiceOrderId={+maintenanceServiceOrderId}
        />
      </>
    );
  }
  return <Content breadcrumbs={breadcrumbs} content={content} />;
};

AssignEmployeesToMaintenanceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes,
  dispacthGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPush: PropTypes.func.isRequired,
};

AssignEmployeesToMaintenanceServiceOrder.defaultProps = {
  match: null,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispacthGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
  dispatchPush: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignEmployeesToMaintenanceServiceOrder);
