import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import { putDisabledBillingsByItinerayId } from '../../../../../actions';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { PUT } from '../../../../../config/permissions';
import { generateDisabledBillingsByItinerayIdEndpoint } from '../../../../../config/endpoints';

const DeleteBillingsByItineraryButton = ({
  dispatchPutDisabledBillingsByItinerayId,
  itineraryId,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const onClickDelete = () => {
    dispatchPutDisabledBillingsByItinerayId({ itineraryId });
    setShowModal(false);
  };

  return (
    <>
      <Button color="danger" onClick={openModal}>
        <i className="fa fa-trash-o" /> Eliminar Guías
      </Button>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
    </>
  );
};

DeleteBillingsByItineraryButton.propTypes = {
  dispatchPutDisabledBillingsByItinerayId: PropTypes.func.isRequired,
  itineraryId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchPutDisabledBillingsByItinerayId: putDisabledBillingsByItinerayId,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(DeleteBillingsByItineraryButton);

export default withEndpointAuthorization({
  url: generateDisabledBillingsByItinerayIdEndpoint(0),
  permissionType: PUT,
})(connectedComponent);
