import React from 'react';
import PropTypes from 'prop-types';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import { BAGGAGE_ITEM_STATUS } from '../../../../../config/constants';

const BaggageItemMovementResource = ({ original: { itemMovementList } }) => {
  let component = null;

  if (itemMovementList.length === 0) {
    component = (
      <p className="text-center">
        <i>No hay movimientos para este equipaje</i>
      </p>
    );
  } else {
    const movementContent = itemMovementList.map(
      ({ itemItineraryId, location, itemStatus, remmarks }, index) => (
        <tr key={+index}>
          <td>{index + 1}</td>
          <td>
            {itemItineraryId ? (
              <a
                href={`${ITINERARY_FOR_COUNTER_PATH}/${itemItineraryId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {itemItineraryId}
              </a>
            ) : null}
          </td>
          <td>{location ? location.name : null}</td>
          <td>{BAGGAGE_ITEM_STATUS[itemStatus].label}</td>
          <td>{remmarks}</td>
        </tr>
      ),
    );

    component = (
      <>
        <h5>Lista de Movimientos</h5>
        <table className="table table-striped table-sm text-center">
          <thead>
            <tr>
              <td>#</td>
              <td>Itinerario</td>
              <td>Agencia</td>
              <td>Estado</td>
              <td>Comentarios</td>
            </tr>
          </thead>
          <tbody>{movementContent}</tbody>
        </table>
      </>
    );
  }

  return <div className="mt-3 mb-2 ml-5 mr-5">{component}</div>;
};

BaggageItemMovementResource.propTypes = {
  original: PropTypes.shape({
    itemMovementList: PropTypes.arrayOf(
      PropTypes.shape({
        itemItineraryId: PropTypes.string,
        location: PropTypes.string,
        itemStatus: PropTypes.string,
        remmarks: PropTypes.string,
      }),
    ),
  }),
};

BaggageItemMovementResource.defaultProps = {
  original: null,
};

export default BaggageItemMovementResource;
