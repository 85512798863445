import React from 'react';
import { Alert } from 'reactstrap';

const Refund = () => (
  <Alert color="danger">
    <h3>Devuelto</h3>
    <p>Se devolvió el dinero al cliente</p>
  </Alert>
);

export default Refund;
