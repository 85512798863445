import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import Agency from './Agency';
import DriverCommission from './DriverCommission';
import CabinCrewCommission from './CabinCrewCommission';
import Workstation from './Workstation';
import PrintStation from './PrintStation';
import PrintTemplateType from './PrintTemplateType';
import AgencyGroup from './AgencyGroup';

export default combineReducers({
  UnitHome,
  Agency,
  DriverCommission,
  CabinCrewCommission,
  Workstation,
  PrintStation,
  PrintTemplateType,
  AgencyGroup,
});
