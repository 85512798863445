import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Table from '../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { BAGGAGES_COLUMNS } from '../../../../config/columns';
import { clearBaggages, getBaggages } from '../../../../actions';
import { BAGGAGE_PATH } from '../../../../config/paths';

export class BaggageSearchResult extends Component {
  componentWillUnmount() {
    this.props.dispatchClearBaggages();
  }

  render() {
    const {
      loading,
      baggages,
      searchFormValues,
      dispatchGetBaggages,
    } = this.props;

    const data = baggages.get('content') || [];
    const columns = BAGGAGES_COLUMNS;
    const pages = baggages.get('totalPages') || null;
    const defaultPageSize = baggages.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <div className="mt-3">
        <Table
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          modelPath={BAGGAGE_PATH}
          navigateToModelOnRowClick
          fetchData={dispatchGetBaggages}
          params={searchFormValues}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ BaggageUnit }) => ({
  loading: BaggageUnit.Baggage.getIn(['all', 'loading']),
  baggages: BaggageUnit.Baggage.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchClearBaggages: clearBaggages,
  dispatchGetBaggages: getBaggages,
};

BaggageSearchResult.propTypes = {
  baggages: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearBaggages: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    ticketCode: PropTypes.string,
    excessCode: PropTypes.string,
  }).isRequired,
  dispatchGetBaggages: PropTypes.func.isRequired,
};

BaggageSearchResult.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaggageSearchResult);
