import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EXPECTED_FUEL_CONSUMPTION_PATH } from '../../../../config/paths';

const EditExpectedFuelConsumptionButton = ({ expectedFuelConsumptionId }) => (
  <Link
    className="btn btn-primary"
    to={`${EXPECTED_FUEL_CONSUMPTION_PATH}/${expectedFuelConsumptionId}/edit`}
  >
    Editar
  </Link>
);

EditExpectedFuelConsumptionButton.propTypes = {
  expectedFuelConsumptionId: PropTypes.number.isRequired,
};

export default EditExpectedFuelConsumptionButton;
