import Immutable from 'immutable';
import {
  FLAG_GETTING_CUSTOMERS,
  GET_CUSTOMERS,
  CLEAR_CUSTOMERS,
  FLAG_GETTING_CUSTOMER_EMAILS,
  GET_CUSTOMER_EMAILS,
  CLEAR_CUSTOMER_EMAILS,
  FLAG_GETTING_CUSTOMER_DELIVERY_ADDRESSES,
  GET_CUSTOMER_DELIVERY_ADDRESSES,
  CLEAR_CUSTOMER_DELIVERY_ADDRESSES,
  GET_CUSTOMER,
  CLEAR_CUSTOMER,
  FLAG_CUSTOMER_ACTIVITY,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
  emails: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  deliveryAddresses: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_GETTING_CUSTOMERS:
      return state.setIn(['all', 'loading'], payload);
    case GET_CUSTOMERS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_CUSTOMERS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_GETTING_CUSTOMER_EMAILS:
      return state.setIn(['emails', 'loading'], payload);
    case GET_CUSTOMER_EMAILS:
      return state.setIn(['emails', 'content'], Immutable.Map(payload));
    case CLEAR_CUSTOMER_EMAILS:
      return state.setIn(
        ['emails', 'content'],
        INITIAL_STATE.getIn(['emails', 'content']),
      );
    case FLAG_GETTING_CUSTOMER_DELIVERY_ADDRESSES:
      return state.setIn(['deliveryAddresses', 'loading'], payload);
    case GET_CUSTOMER_DELIVERY_ADDRESSES:
      return state.setIn(
        ['deliveryAddresses', 'content'],
        Immutable.Map(payload),
      );
    case CLEAR_CUSTOMER_DELIVERY_ADDRESSES:
      return state.setIn(
        ['deliveryAddresses', 'content'],
        INITIAL_STATE.getIn(['deliveryAddresses', 'content']),
      );
    case GET_CUSTOMER:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_CUSTOMER:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_CUSTOMER_ACTIVITY:
      // raising flag
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      // lowering flag
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    default:
      return state;
  }
};
