import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { PUT } from '../../../../config/permissions';
import { generatePutAssignActivityToMaintenanceServiceOrderEndpoint } from '../../../../config/endpoints';

const MaintenanceServiceOrderAssignActivityButton = ({
  maintenanceServiceOrderId,
}) => (
  <Link
    className="btn btn-light border"
    to={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/update-information`}
  >
    <i className="fa fa-tasks" /> Asignar actividades
  </Link>
);

MaintenanceServiceOrderAssignActivityButton.propTypes = {
  maintenanceServiceOrderId: PropTypes.number.isRequired,
};

const connectedComponent = connect()(
  MaintenanceServiceOrderAssignActivityButton,
);

export default withEndpointAuthorization({
  url: generatePutAssignActivityToMaintenanceServiceOrderEndpoint(0),
  permissionType: PUT,
})(connectedComponent);
