import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserHome from './UserHome';
import {
  USER_UNIT_PATH,
  USER_PATH,
  NEW_USER_PATH,
  USER_SELF_PATH,
  CUSTOMER_PATH,
  NEW_CUSTOMER_PATH,
  BUSINESS_PATH,
  NEW_BUSINESS_PATH,
} from '../../../config/paths';
import Users from './user/Users';
import User from './user/User';
import NewUser from './user/NewUser';
import EditUser from './user/EditUser';
import UserSelf from './user/UserSelf';
import EditUserSelf from './user/EditUserSelf';
import Customers from './customer/Customers';
import NewCustomer from './customer/NewCustomer';
import Customer from './customer/Customer';
import EditCustomer from './customer/EditCustomer';
import Businesses from './business/Businesses';
import NewBusiness from './business/NewBusiness';
import Business from './business/Business';
import EditBusiness from './business/EditBusiness';

const UserUnit = () => (
  <Switch>
    <Route exact path={USER_UNIT_PATH} component={UserHome} />
    <Route exact path={USER_SELF_PATH} component={UserSelf} />
    <Route exact path={`${USER_SELF_PATH}/edit`} component={EditUserSelf} />
    <Route exact path={USER_PATH} component={Users} />
    <Route exact path={NEW_USER_PATH} component={NewUser} />
    <Route exact path={`${USER_PATH}/:id`} component={User} />
    <Route exact path={`${USER_PATH}/:id/edit`} component={EditUser} />
    <Route exact path={CUSTOMER_PATH} component={Customers} />
    <Route exact path={NEW_CUSTOMER_PATH} component={NewCustomer} />
    <Route exact path={`${CUSTOMER_PATH}/:id`} component={Customer} />
    <Route exact path={`${CUSTOMER_PATH}/:id/edit`} component={EditCustomer} />
    <Route exact path={BUSINESS_PATH} component={Businesses} />
    <Route exact path={NEW_BUSINESS_PATH} component={NewBusiness} />
    <Route exact path={`${BUSINESS_PATH}/:id`} component={Business} />
    <Route exact path={`${BUSINESS_PATH}/:id/edit`} component={EditBusiness} />
  </Switch>
);

export default UserUnit;
