import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Button } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import DepreciationTypeSelect from '../../../common/forms/select/DepreciationTypeSelect';
import ItemCategorySelect from '../../../common/forms/select/ItemCategorySelect';
import DepreciationPeriodicitySelect from '../../../common/forms/select/DepreciationPeriodicitySelect';

// eslint-disable-next-line react/prefer-stateless-function
export class DepreciationRuleForm extends Component {
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <FormGroup row>
          <FormItem label="Tipo de Depreciación" required>
            <Field
              name="depreciationType"
              component={DepreciationTypeSelect}
              type="text"
              placeholder="Tipo de Depreciación"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Categoría">
            <Field
              name="itemCategoryId"
              component={ItemCategorySelect}
              type="text"
              placeholder="Categoría"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Periodo" required>
            <Field
              name="depreciationPeriodicity"
              component={DepreciationPeriodicitySelect}
              type="text"
              placeholder="Periodo"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Porcentaje de Dep." required>
            <Field
              name="depreciationPercentage"
              component={TextInput}
              type="text"
              placeholder="Porcentaje de Dep."
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <div className="FormButtonGroup">
          <Button type="submit" color="primary" outline>
            Enviar
          </Button>
        </div>
      </Form>
    );
  }
}

DepreciationRuleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'DepreciationRuleForm',
})(DepreciationRuleForm);
