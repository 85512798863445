import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from 'reactstrap';

const DropdownButton = ({
  title,
  options,
  activeValue,
  handleClick,
  color,
}) => {
  const dropDownItems = options.map((status, index) => {
    if (status.value === 'header')
      return (
        <DropdownItem header key={`dropdownItem-${index.toString()}`}>
          {status.label}
        </DropdownItem>
      );

    if (status.value === 'divider')
      return <DropdownItem divider key={`dropdownItem-${index.toString()}`} />;

    const dropdownItemProps = {
      key: `dropdownItem-${index.toString()}`,
      active: status.value === activeValue,
    };

    if (status.value !== activeValue)
      dropdownItemProps.onClick = () => handleClick(`${status.value}`);

    return <DropdownItem {...dropdownItemProps}>{status.label}</DropdownItem>;
  });

  return (
    <UncontrolledButtonDropdown style={{ display: 'inline' }}>
      <DropdownToggle color={color} caret>
        {title}
      </DropdownToggle>
      <DropdownMenu>{dropDownItems}</DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

DropdownButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  activeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleClick: PropTypes.func,
  color: PropTypes.string,
};

DropdownButton.defaultProps = {
  activeValue: null,
  handleClick: () => {},
  color: 'primary',
};

export default DropdownButton;
