import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BAGGAGE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getBaggage,
  putBaggage,
  clearBaggage,
  postExcessBaggageVoid,
} from '../../../../actions';
import BaggageForm from './BaggageForm';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import { numberFormatter } from '../../../../utils/number';

export class EditBaggage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetBaggage,
    } = this.props;
    dispatchGetBaggage({ id });
  }

  componentWillUnmount() {
    const { dispatchClearBaggage } = this.props;
    dispatchClearBaggage();
  }

  onSubmit = (formValues) => {
    const newFormValues = {
      totalWeight: formValues.weight,
      ...formValues,
    };
    const {
      dispatchPutBaggage,
      dispatchPostExcessBaggageVoid,
      baggage,
      match: {
        params: { id },
      },
    } = this.props;

    if (formValues.void)
      dispatchPostExcessBaggageVoid(id, baggage.get('excessBaggageId'), {
        baggageRequest: formValues,
        description: formValues.voidDescription,
      });
    else dispatchPutBaggage(id, newFormValues);
  };

  generateInitialValues = (baggage) => {
    const excessBaggage = baggage.get('excessBaggage');

    const ticketList = baggage
      .get('ticketList')
      .map(
        ({
          id,
          ticketCode,
          documentCode,
          documentSeries,
          customer: { fullName, idDocumentNumber, identificationType },
          customerId,
          seatReservation,
          destinationLocation: { name: destinationLocationName },
          sourceLocation: { name: sourceLocationName },
          departureDate,
        }) => ({
          ticket: {
            value: id,
            label: ticketCode,
            documentCode,
            documentSeries,
            customerFullName: fullName,
            customerDocumentNumber: `${identificationType.name} ${idDocumentNumber}`,
            itineraryId: seatReservation && seatReservation.itineraryId,
            destinationLocationName,
            sourceLocationName,
            departureDate,
            seatNumber:
              seatReservation &&
              seatReservation.seat &&
              seatReservation.seat.seatNumber,
            customerId,
          },
        }),
      );

    const initialValues = {
      weight: baggage.get('weight'),
      comment: baggage.get('comment'),
      ticketList,
      baggageItemList: baggage
        .get('itemList')
        .map(({ itemCategory, itemSeries, itemCode, description, weight }) => ({
          itemCategoryId: { value: itemCategory.id, label: itemCategory.name },
          itemSeries,
          itemCode,
          description,
          weight,
        })),
      hasExcess: excessBaggage,
    };

    if (excessBaggage) {
      initialValues.hasExcess = excessBaggage;
      initialValues.excessBaggageId = excessBaggage.id;
      initialValues.excessWeight = excessBaggage.weight;
      initialValues.excessComment = excessBaggage.comment;
      initialValues.voucherTypeId = excessBaggage.transaction.voucherType && {
        value: excessBaggage.transaction.voucherType.id,
        label: excessBaggage.transaction.voucherType.name,
        voucherCode: excessBaggage.transaction.voucherType.voucherCode,
      };
      initialValues.excessPaymentMethodId = {
        label: excessBaggage.paymentMethod.name,
        value: excessBaggage.paymentMethod.id,
      };
      initialValues.excessPriceBeforeDiscount = numberFormatter({
        value: excessBaggage.priceBeforeDiscount,
      });
      initialValues.excessPrice = numberFormatter({
        value: excessBaggage.price,
      });
      if (excessBaggage.transaction.business) {
        initialValues.businessId = {
          value: excessBaggage.transaction.business.id,
          label: `${excessBaggage.transaction.business.businessTaxId} - ${excessBaggage.transaction.business.name}`,
          businessTaxId: excessBaggage.transaction.business.businessTaxId,
          name: excessBaggage.transaction.business.name,
          email: excessBaggage.transaction.business.email,
          primaryContact: excessBaggage.transaction.business.primaryContact,
          phone: excessBaggage.transaction.business.phone,
          fax: excessBaggage.transaction.business.fax,
          addressSummary: excessBaggage.transaction.business.addressSummary,
          addressId: excessBaggage.transaction.business.addressId,
          address: excessBaggage.transaction.business.address,
        };
      }
      initialValues.excessPrice = numberFormatter({
        value: excessBaggage.price,
      });
      initialValues.discountAmount = excessBaggage.discountAmount;
      initialValues.payerCustomerId = {
        label: `${excessBaggage.payerCustomer.idDocumentNumber} (${excessBaggage.payerCustomer.idCountryOfOrigin}) - ${excessBaggage.payerCustomer.fullName}`,
        value: excessBaggage.payerCustomer.id,
      };
      initialValues.voucherCode = excessBaggage.voucherCode;
    }

    return initialValues;
  };

  render() {
    const { breadcrumbs, baggage, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (baggage.isEmpty()) {
      content = <NoDataResource returnPage={BAGGAGE_PATH} />;
    } else {
      content = (
        <BaggageForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(baggage)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Equipaje"
        subtitle="Editar equipaje"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { BaggageUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...BaggageUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Equipajes y Excesos',
      href: BAGGAGE_PATH,
    },
    {
      text: 'Ver',
      href: `${BAGGAGE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  baggage: BaggageUnit.Baggage.getIn(['current', 'content']),
  loading: !BaggageUnit.Baggage.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutBaggage: putBaggage,
  dispatchGetBaggage: getBaggage,
  dispatchClearBaggage: clearBaggage,
  dispatchPostExcessBaggageVoid: postExcessBaggageVoid,
};

EditBaggage.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutBaggage: PropTypes.func.isRequired,
  dispatchGetBaggage: PropTypes.func.isRequired,
  dispatchClearBaggage: PropTypes.func.isRequired,
  dispatchPostExcessBaggageVoid: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  baggage: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditBaggage.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBaggage);
