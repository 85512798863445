import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import PricingProfileEditButton from './PricingProfileEditButton';
import PricingProfileDeleteButton from './PricingProfileDeleteButton';

const PricingProfileToolbar = ({ pricingProfileId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PricingProfileEditButton pricingProfileId={pricingProfileId} />{' '}
      <PricingProfileDeleteButton pricingProfileId={pricingProfileId} />
    </div>
  </ButtonToolbar>
);

PricingProfileToolbar.propTypes = {
  pricingProfileId: PropTypes.number.isRequired,
};

export default PricingProfileToolbar;
