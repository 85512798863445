export const FLAG_GETTING_COMPANY_USER_CONFIGURATIONS =
  'FLAG_GETTING_COMPANY_USER_CONFIGURATIONS';
export const GET_COMPANY_USER_CONFIGURATIONS =
  'GET_COMPANY_USER_CONFIGURATIONS';
export const CLEAR_COMPANY_USER_CONFIGURATIONS =
  'CLEAR_COMPANY_USER_CONFIGURATIONS';
export const FLAG_COMPANY_USER_CONFIGURATION_ACTIVITY =
  'FLAG_COMPANY_USER_CONFIGURATION_ACTIVITY';
export const GET_COMPANY_USER_CONFIGURATION = 'GET_COMPANY_USER_CONFIGURATION';
export const CLEAR_COMPANY_USER_CONFIGURATION =
  'CLEAR_COMPANY_USER_CONFIGURATION';
