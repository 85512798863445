import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table } from 'reactstrap';
import EditableTextTd from '../../../common/forms/table/EditableTextTd';

const TimetableTemplateEditable = ({
  routeSegmentList,
  onValueChangeLayover,
  onValueChangeDurationAdjustment,
  setInitialValues,
}) => (
  <Row>
    <Col>
      <Table striped responsive role="grid" className="text-center">
        <thead>
          <tr>
            <th>Minuto #</th>
            <th>Origen</th>
            <th>Destino</th>
            <th>Hora de Salida</th>
            <th>Duración</th>
            <th>Ajuste de Duración</th>
            <th>Hora de Llegada</th>
            <th>Tiempo de Escala</th>
          </tr>
        </thead>
        <tbody>
          {routeSegmentList.map((route, index) => {
            let lastLayoverEditable = (
              <td>
                <EditableTextTd
                  text={setInitialValues ? route.layover : 0}
                  id={route.segmentId}
                  onValueChange={onValueChangeLayover}
                  name="layover"
                />
              </td>
            );

            if (index === routeSegmentList.length - 1) {
              lastLayoverEditable = <td />;
            }

            return (
              <tr key={route.segmentId}>
                <td>{route.startOffset}</td>
                <td>{route.sourceLocationName}</td>
                <td>{route.destinationLocationName}</td>
                <td>{route.departureTimeFormatted}</td>
                <td>{route.duration}</td>
                <td>
                  <EditableTextTd
                    text={setInitialValues ? route.segmentAdjustment : 0}
                    id={route.segmentId}
                    onValueChange={onValueChangeDurationAdjustment}
                    name="durationAdjustment"
                  />
                </td>
                <td>{route.arrivalTimeFormatted}</td>
                {lastLayoverEditable}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Col>
  </Row>
);

TimetableTemplateEditable.propTypes = {
  onValueChangeLayover: PropTypes.func.isRequired,
  onValueChangeDurationAdjustment: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routeSegmentList: PropTypes.arrayOf(PropTypes.object),
  setInitialValues: PropTypes.bool,
};

TimetableTemplateEditable.defaultProps = {
  routeSegmentList: [],
  setInitialValues: false,
};

export default TimetableTemplateEditable;
