import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GEOGRAPHIC_ZONE_COMBINATION_PATH } from '../../../../config/paths';

const EditGeographicZonesCombinationButton = ({
  geographicZonesCombinationId,
}) => (
  <Link
    className="btn btn-primary"
    to={`${GEOGRAPHIC_ZONE_COMBINATION_PATH}/${geographicZonesCombinationId}/edit`}
  >
    Editar
  </Link>
);

EditGeographicZonesCombinationButton.propTypes = {
  geographicZonesCombinationId: PropTypes.number.isRequired,
};

export default EditGeographicZonesCombinationButton;
