import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Form, FormGroup, InputGroupText, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import LiquidationBasicInformationResource from './resource/LiquidationBasicInformationResource';
import {
  clearLiquidationSession,
  getLiquidationSessionByAgency,
  getPaymentMethods,
} from '../../../../actions';
import LiquidationIncomeResource from './resource/LiquidationIncomeResource';
import LiquidationExpenseResource from './resource/LiquidationExpenseResource';
import LiquidationCashResource from './resource/LiquidationCashResource';
import LiquidationCommissionResource from './resource/LiquidationCommissionResource';
import { CURRENCY } from '../../../../config/locale';
import LiquidationDifferenceReasonSelect from '../../../common/forms/select/LiquidationDifferenceReasonSelect';
import DynamicForm from '../../../common/forms/DynamicForm';
import { NOTES_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import NoteListResource from '../../../common/resource/NoteListResource';
import LiquidationCreditNoteResource from './resource/LiquidationCreditNoteResource';
import UserAgenciesSelect from '../../../common/forms/select/UserAgenciesSelect';
import { numberFormatter } from '../../../../utils/number';
import FormFooter from '../../../common/forms/FormFooter';
import LiquidationSalesSessionsResource from './resource/LiquidationSalesSessionsResource';
import LiquidationParcelCreditResource from './resource/LiquidationParcelCreditResource';
import LiquidationParcelOtherIncomesResource from './resource/LiquidationParcelOtherIncomesResource';

export const LiquidationSessionForm = ({
  loading,
  liquidation,
  handleSubmit,
  dailyLiquidationSessionNoteList,
  initialValues,
  dispatchGetLiquidationSessionByAgency,
  dispatchClearLiquidationSession,
  onSubmit,
  operatorUserFullName,
  dispatchGetPaymentMethods,
  paymentMethods,
}) => {
  const [cashSales, setCashSales] = useState(0);
  const [showLiquidationDifference, setShowLiquidationDifference] =
    useState(false);
  const [editingMode, setEditingMode] = useState(false);

  const onMount = () => {
    dispatchGetPaymentMethods();

    if (initialValues) {
      setEditingMode(true);
      setCashSales(initialValues.cashOnHand);

      const liquidationAmountPayable = liquidation.get('amountPayable') || 0;

      const amountPayable =
        liquidationAmountPayable !== 0
          ? liquidationAmountPayable.toFixed(2)
          : liquidation.get('totalCash');

      if (Number(amountPayable) !== Number(initialValues.cashOnHand))
        setShowLiquidationDifference(true);
    }
  };

  useLayoutEffect(() => {
    onMount();

    return () => dispatchClearLiquidationSession();
  }, []);

  const onChangeAgency = ({ value }) => {
    dispatchClearLiquidationSession();

    if (value) dispatchGetLiquidationSessionByAgency({ agencyId: value });
  };

  const validateLiquidationDifference = () => {
    let showDifference = false;

    const liquidationAmountPayable = liquidation.get('amountPayable') || 0;
    const amountPayable =
      liquidationAmountPayable !== 0
        ? liquidationAmountPayable
        : liquidation.get('totalCash');

    if (Number(cashSales) !== Number(amountPayable)) showDifference = true;

    setShowLiquidationDifference(showDifference);

    return showDifference;
  };

  const onBlurCashOnHand = () => validateLiquidationDifference();

  const handleLiquidationDifference = (formValues) => {
    const newFormValues = {
      dailyLiquidationDate: liquidation.get('createDate'),
      agencyId: formValues.agencyId,
      invoiceNumber: formValues.invoiceNumber,
      liquidationDifferenceReasonId: formValues.liquidationDifferenceReasonId,
      liquidationDifferenceReasonDescription:
        formValues.liquidationDifferenceReasonDescription,
      cashOnHand: formValues.cashOnHand,
    };

    if (editingMode) {
      newFormValues.dailyLiquidationSessionNoteList =
        formValues.dailyLiquidationSessionNoteList;
      newFormValues.isSettled = formValues.isSettled;
    }

    if (validateLiquidationDifference()) {
      if (formValues.liquidationDifferenceReasonId) onSubmit(newFormValues);
    } else {
      delete newFormValues.liquidationDifferenceReasonId;
      delete newFormValues.liquidationDifferenceReasonDescription;
      onSubmit(newFormValues);
    }
  };

  if (loading) return <Loader />;

  let fields = null;

  if (!liquidation.isEmpty() && !paymentMethods.isEmpty()) {
    const liquidationAmountPayable = liquidation.get('amountPayable') || 0;
    const amountPayable =
      liquidationAmountPayable !== 0
        ? liquidationAmountPayable
        : liquidation.get('totalCash');

    const totalLabel = amountPayable !== 0 ? 'Total' : 'Efectivo';

    let liquidationDifference;

    if (showLiquidationDifference) {
      const difference = +amountPayable - +cashSales;

      liquidationDifference = (
        <>
          <h2>Discrepancia</h2>
          <h5>
            Monto de Discrepancia: {numberFormatter({ value: difference })}
          </h5>
          <FormGroup row>
            <FormItem label="Razón" required>
              <Field
                name="liquidationDifferenceReasonId"
                component={LiquidationDifferenceReasonSelect}
                isDisabled={editingMode}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Descripción">
              <Field
                name="liquidationDifferenceReasonDescription"
                component={TextInput}
                disabled={editingMode}
                type="textarea"
              />
            </FormItem>
          </FormGroup>
          <h3>
            {totalLabel} a Depositar: {numberFormatter({ value: cashSales })}
          </h3>
        </>
      );
    }

    let editingFields = null;

    if (editingMode) {
      editingFields = (
        <>
          <hr />
          <h3>Notas</h3>
          <NoteListResource values={dailyLiquidationSessionNoteList} />
          <DynamicForm
            name="dailyLiquidationSessionNoteList"
            columns={NOTES_FORM_COLUMNS}
          />
          <FormGroup row>
            <FormItem label="Estado">
              <Label>
                <Field name="isSettled" component="input" type="checkbox" />{' '}
                Aprobado
              </Label>
            </FormItem>
          </FormGroup>
        </>
      );
    }

    // Get all cash payment method
    const cashPaymentMethodList = paymentMethods
      .get('content')
      .filter(({ transactionCode }) => transactionCode === 'CASH');

    fields = (
      <>
        <hr />
        <LiquidationBasicInformationResource
          agencyName={liquidation.get('agency')}
          createDate={liquidation.get('createDate')}
          exchangeRateSet={liquidation.get('exchangeRateSet')}
          operatorUserFullName={operatorUserFullName}
        />
        <LiquidationIncomeResource
          ticketSummaryList={liquidation.get('ticketSummaryList')}
          ticketPaymentMethodSummaryList={liquidation.get(
            'ticketPaymentMethodSummaryList',
          )}
          generalIncomeSummaryList={liquidation.get('generalIncomeSummaryList')}
          generalIncomeTypeSummaryList={liquidation.get(
            'generalIncomeTypeSummaryList',
          )}
          generalIncomePaymentMethodSummaryList={liquidation.get(
            'generalIncomePaymentMethodSummaryList',
          )}
          totalSalesPaymentMethodSummaryList={liquidation.get(
            'totalSalesPaymentMethodSummaryList',
          )}
          excessBaggageSummaryList={liquidation.get('excessBaggageSummaryList')}
          parcelIncomeSummaryList={liquidation.get('parcelIncomeSummaryList')}
          cashPaymentMethodList={cashPaymentMethodList}
        />
        <LiquidationParcelCreditResource
          parcelCreditSummaryList={liquidation.get('parcelCreditSummaryList')}
        />
        <LiquidationParcelOtherIncomesResource
          parcelOtherIncomesSummaryList={liquidation.get(
            'parcelOtherIncomesSummaryList',
          )}
        />
        <LiquidationExpenseResource
          expenseTypeSummaryList={liquidation.get('expenseTypeSummaryList')}
          expenseSummaryList={liquidation.get('expenseSummaryList')}
        />
        <LiquidationCashResource
          totalCashIncome={liquidation.get('totalCashIncome')}
          totalCashExpense={liquidation.get('totalCashExpense')}
          totalCash={liquidation.get('totalCash')}
        />
        <LiquidationCreditNoteResource
          generatedCreditNoteSet={liquidation.get('generatedCreditNoteSet')}
          creditNoteSet={liquidation.get('creditNoteSet')}
        />
        <LiquidationCommissionResource
          commission={liquidation.get('commission')}
          commissionTaxes={liquidation.get('commissionTaxes')}
          amountPayable={liquidation.get('amountPayable')}
        />
        <FormGroup row>
          <FormItem label="Dinero en Mano" required>
            <Field
              name="cashOnHand"
              component={TextInput}
              type="text"
              placeholder="0.00"
              append={<InputGroupText>{CURRENCY}</InputGroupText>}
              onBlur={onBlurCashOnHand}
              onChange={(e) => setCashSales(e.target.value)}
              validate={[isRequired, validateNumber]}
              disabled={editingMode}
            />
          </FormItem>
        </FormGroup>
        {liquidationDifference}
        <LiquidationSalesSessionsResource
          salesSessions={liquidation.get('salesSessionSummaryList')}
        />
        {editingFields}
        <FormFooter />
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit(handleLiquidationDifference)}>
      <FormGroup row>
        <FormItem label="Agencia" required>
          <Field
            name="agencyId"
            component={UserAgenciesSelect}
            onChange={onChangeAgency}
            validate={[isRequired]}
            isDisabled={editingMode}
            autoFocus
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="# Factura Comisión">
          <Field
            name="invoiceNumber"
            component={TextInput}
            disabled={editingMode}
          />
        </FormItem>
      </FormGroup>
      {fields}
    </Form>
  );
};

LiquidationSessionForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  liquidation: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetLiquidationSessionByAgency: PropTypes.func.isRequired,
  dispatchClearLiquidationSession: PropTypes.func.isRequired,
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    cashOnHand: PropTypes.number,
  }),
  dailyLiquidationSessionNoteList: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string.isRequired,
    }),
  ),
  operatorUserFullName: PropTypes.string,
  paymentMethods: PropTypes.instanceOf(Immutable.Map).isRequired,
};

LiquidationSessionForm.defaultProps = {
  initialValues: null,
  dailyLiquidationSessionNoteList: [],
  operatorUserFullName: null,
};

const mapStateToProps = ({ SalesUnit, authentication, AccountingUnit }) => ({
  loading: !SalesUnit.LiquidationSession.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  liquidation: SalesUnit.LiquidationSession.getIn(['current', 'content']),
  operatorUserFullName:
    authentication.get('user') && authentication.get('user').customer.fullName,
  loadingPaymentMethods: AccountingUnit.PaymentMethod.getIn(['all', 'loading']),
  paymentMethods: AccountingUnit.PaymentMethod.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetLiquidationSessionByAgency: getLiquidationSessionByAgency,
  dispatchClearLiquidationSession: clearLiquidationSession,
  dispatchGetPaymentMethods: getPaymentMethods,
};

const formComponent = reduxForm({
  form: 'LiquidationSessionForm',
})(LiquidationSessionForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
