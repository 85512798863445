import { toastr } from 'react-redux-toastr';
import { FUEL_VOUCHER_CURRENT_ACTIVE_ENDPOINT } from '../../../../../config/endpoints';
import Fetch from '../../../../../utils/Fetch';

const getFuelVouchers = async ({ id }) => {
  try {
    const url = `${FUEL_VOUCHER_CURRENT_ACTIVE_ENDPOINT}/${id}`;
    const fuelVouchers = await Fetch.get(url);
    return fuelVouchers;
  } catch (error) {
    toastr.error('Error', error.message);
    return [];
  }
};

const getFuelVoucherOptions = async ({ id }) => {
  const fuelVouchers = await getFuelVouchers({ id });
  let fuelVoucherOptions = [];
  if (fuelVouchers.length) {
    fuelVoucherOptions = fuelVouchers.map((fuelVoucher) => {
      const circuitName = fuelVoucher.circuitName
        ? ` - ${fuelVoucher.circuitName}`
        : '';
      return {
        value: fuelVoucher.id,
        label: `${fuelVoucher.documentSeries}-${fuelVoucher.documentCode} ${circuitName}`,
        resource: fuelVoucher,
      };
    });
  }
  return fuelVoucherOptions;
};

export { getFuelVouchers, getFuelVoucherOptions };
