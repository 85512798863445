import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Table } from '../../../common/Table';

class InformativeTable extends Component {
  static propTypes = {
    expectedFuels: PropTypes.arrayOf(
      PropTypes.shape({
        route: PropTypes.shape({
          name: PropTypes.string,
          routeSegmentList: PropTypes.arrayOf(
            PropTypes.shape({
              segment: PropTypes.shape({
                distance: PropTypes.number,
              }),
            }),
          ),
        }),
        busFuelGroupConsumptionList: PropTypes.arrayOf(
          PropTypes.shape({
            busFuelGroupId: PropTypes.number,
            numberOfGallon: PropTypes.number,
          }),
        ),
      }),
    ).isRequired,
    busFuelGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  };

  static defaultProps = {
    busFuelGroups: [],
  };

  // Coordinate with the backend to optimize the response since too much data processing is being done
  createInformativeComponentData = (expectedFuels, busFuelGroups) => {
    const data = [];

    expectedFuels.forEach((expectedFuel) => {
      const row = {};
      let km = 0;
      row.routeName = expectedFuel.route.name;
      expectedFuel.route.routeSegmentList.forEach((segment) => {
        km += segment.segment.distance;
      });
      row.distance = km;
      expectedFuel.busFuelGroupConsumptionList.forEach((group) => {
        row[`Group${group.busFuelGroupId}`] = group.numberOfGallon;
        busFuelGroups.forEach((value) => {
          if (!row[`Group${value.id}`]) row[`Group${value.id}`] = 0;
        });
      });
      data.push(row);
    });

    return data;
  };

  createInformativeComponentColumns = (
    informativeComponentData,
    busFuelGroups,
  ) => {
    const columns = [];
    columns.push({
      Header: 'Ruta',
      accessor: 'routeName',
      className: 'text-center',
      Footer: (
        <span>
          <strong>Totales:</strong>
        </span>
      ),
    });
    columns.push({
      Header: 'KM',
      accessor: 'distance',
      className: 'text-center',
      Footer: (
        <span>
          <strong>
            {informativeComponentData.reduce(
              (accumulated, data) => accumulated + data.distance,
              0,
            )}
          </strong>
        </span>
      ),
    });
    busFuelGroups.forEach(({ name, id }) => {
      columns.push({
        Header: `Grupo ${name}`,
        accessor: `Group${id}`,
        className: 'text-center',
        Footer: (
          <span>
            <strong>
              {informativeComponentData.reduce(
                (accumulated, data) => accumulated + data[`Group${id}`],
                0,
              )}
            </strong>
          </span>
        ),
      });
    });

    return columns;
  };

  render() {
    const { expectedFuels, busFuelGroups } = this.props;
    const informativeComponentData = this.createInformativeComponentData(
      expectedFuels,
      busFuelGroups,
    );
    if (informativeComponentData.length > 0) {
      return (
        <Fragment>
          <Row>
            <Col>
              <h5>Dotación Informativo</h5>
            </Col>
          </Row>
          <Table
            columns={this.createInformativeComponentColumns(
              informativeComponentData,
              busFuelGroups,
            )}
            data={informativeComponentData}
            defaultPageSize={informativeComponentData.length}
            showPagination={false}
          />
          <br />
        </Fragment>
      );
    }
    return null;
  }
}

export default InformativeTable;
