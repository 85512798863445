import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import CustomerEditButton from './CustomerEditButton';
import CustomerDeleteButton from './CustomerDeleteButton';

const CustomerToolbar = ({ customerId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <CustomerEditButton customerId={customerId} />{' '}
      <CustomerDeleteButton customerId={customerId} />
    </div>
  </ButtonToolbar>
);

CustomerToolbar.propTypes = {
  customerId: PropTypes.number.isRequired,
};

export default CustomerToolbar;
