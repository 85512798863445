import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditDistrictButton from './EditDistrictButton';
import DeleteDistrictButton from './DeleteDistrictButton';

const DistrictToolbar = ({ districtId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditDistrictButton districtId={districtId} />{' '}
      <DeleteDistrictButton districtId={districtId} />
    </div>
  </ButtonToolbar>
);

DistrictToolbar.propTypes = {
  districtId: PropTypes.number.isRequired,
};

export default DistrictToolbar;
