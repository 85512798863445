import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../../common/forms/FormItem';
import FormFooter from '../../../../common/forms/FormFooter';
import Select from '../../../../common/forms/select/Select';

const AddCargoToItineraryModalForm = ({ items, handleSubmit, options }) => (
  <Form onSubmit={handleSubmit}>
    {items.map((item) => (
      <div key={item.correlation}>
        <div className="mb-2">
          <span className="d-block">
            <strong>Correlativo:</strong> {item.correlation}
          </span>
          <span className="d-block">
            <strong>Descripción:</strong> {item.description}
          </span>
          <span className="d-block">
            <strong>Destino Final:</strong>
            {item.destinationCityName}
          </span>
        </div>
        <FormGroup row>
          <FormItem label="Destino">
            <Field
              name={`destinationLocationId-${item.correlation}`}
              component={Select}
              isClearable
              options={options}
            />
          </FormItem>
        </FormGroup>
      </div>
    ))}
    <FormFooter />
  </Form>
);

AddCargoToItineraryModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
};

AddCargoToItineraryModalForm.defaultProps = {};

const formComponent = reduxForm({
  form: 'AddCargoToItineraryModalForm',
})(AddCargoToItineraryModalForm);

export default connect()(formComponent);
