import { number, shape } from 'prop-types';
import { locationBasicInformationPropTypes } from '../../../location/location/proptypes/LocationPropTypes';

const tollPropTypes = {
  id: number.isRequired,
  location: shape(locationBasicInformationPropTypes).isRequired,
  locationId: number.isRequired,
  pricePerAxle: number.isRequired,
  taxPrePaymentPerAxle: number.isRequired,
};

export default tollPropTypes;
