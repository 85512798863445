import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import TreeSelect from '../../../../components/common/forms/select/TreeSelect';
import { getRegisteredBuses, clearRegisteredBuses } from '../../../../actions';
import {
  getChassisBodyworks,
  clearChassisBodyworks,
} from '../../../../actions/traffic/ChassisBodywork';
import Loader from '../../../common/Loader';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import FormFooter from '../../../common/forms/FormFooter';

export class BusFuelGroupForm extends Component {
  constructor(props) {
    super(props);
    this.props.dispatchGetChassisBodyworks();
    this.props.dispatchGetRegisteredBuses(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    this.props.dispatchClearChassisBodyworks();
    this.props.dispatchClearRegisteredBuses();
  }

  onChange = (currentNode, selectedNodes) => {
    const buses = [];
    for (let index = 0; index < selectedNodes.length; index += 1) {
      if (typeof selectedNodes[index].value === 'object') {
        selectedNodes[index].value.forEach((value) => {
          buses.push(value);
        });
      } else {
        buses.push(selectedNodes[index].value);
      }
    }
    this.props.change('BusFuelGroupForm', 'registeredBus', buses.toString());
  };

  createTreeData = (chassisBodyworks, registeredBus, busFuelGroupId) => {
    const objectBus = [];
    chassisBodyworks.forEach((value) => {
      const arrayValue = [];
      const childrenNodes = [];
      registeredBus.get('content').forEach((bus) => {
        if (
          value.busBodyworkId === bus.busBodyworkId &&
          value.busChassisId === bus.busChassisId
        ) {
          if (
            !bus.busFuelGroupId ||
            bus.busFuelGroupId === parseInt(busFuelGroupId, 10)
          ) {
            arrayValue.push(bus.id);
            childrenNodes.push({
              label: `${bus.companyBusId}`,
              checked: bus.busFuelGroupId === parseInt(busFuelGroupId, 10),
              value: bus.id,
            });
          }
        }
      });

      if (childrenNodes.length > 0) {
        const parentNodes = {
          label: `${value.busChassis.make} ${value.busChassis.model} ${value.busBodywork.model}`,
          value: arrayValue,
          children: childrenNodes,
        };
        objectBus.push(parentNodes);
      }
    });

    return objectBus;
  };

  render() {
    const {
      loading,
      chassisBodyworks,
      loadingChassisBodyworks,
      registeredBus,
      loadingRegisteredBus,
      busFuelGroupId,
      handleSubmit,
    } = this.props;

    if (loading || loadingChassisBodyworks || loadingRegisteredBus) {
      return <Loader />;
    }

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Nombre de Grupo" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Nombre de Grupo"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Rendimiento Km/Galón" required>
            <Field
              name="performance"
              component={TextInput}
              type="text"
              placeholder="Rendimiento Km/Galón"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Bus" required>
            <Field
              data={this.createTreeData(
                chassisBodyworks,
                registeredBus,
                busFuelGroupId,
              )}
              component={TreeSelect}
              name="tree"
              keepTreeOnSearch
              keepChildrenOnSearch
              keepOpenOnSelect
              showPartiallySelected
              onInputChange={this.onChange}
            />
            <Field
              name="registeredBus"
              component={TextInput}
              type="hidden"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

BusFuelGroupForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  chassisBodyworks: PropTypes.instanceOf(Immutable.Set).isRequired,
  loadingChassisBodyworks: PropTypes.bool.isRequired,
  registeredBus: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingRegisteredBus: PropTypes.bool.isRequired,
  dispatchGetChassisBodyworks: PropTypes.func.isRequired,
  dispatchClearChassisBodyworks: PropTypes.func.isRequired,
  dispatchGetRegisteredBuses: PropTypes.func.isRequired,
  dispatchClearRegisteredBuses: PropTypes.func.isRequired,
  busFuelGroupId: PropTypes.number,
};

BusFuelGroupForm.defaultProps = {
  busFuelGroupId: null,
};

const mapStateToProps = ({ TrafficUnit, BusUnit }) => ({
  loading: !TrafficUnit.BusFuelGroup.getIn(['current', 'activity']).isEmpty(),
  chassisBodyworks: TrafficUnit.ChassisBodywork.getIn(['all', 'content']),
  loadingChassisBodyworks: TrafficUnit.ChassisBodywork.getIn([
    'all',
    'loading',
  ]),
  registeredBus: BusUnit.RegisteredBus.getIn(['all', 'content']),
  loadingRegisteredBus: BusUnit.RegisteredBus.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetChassisBodyworks: getChassisBodyworks,
  dispatchGetRegisteredBuses: getRegisteredBuses,
  dispatchClearChassisBodyworks: clearChassisBodyworks,
  dispatchClearRegisteredBuses: clearRegisteredBuses,
  change,
};

export default reduxForm({
  form: 'BusFuelGroupForm',
})(connect(mapStateToProps, mapDispatchToProps)(BusFuelGroupForm));
