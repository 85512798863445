import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Loader from '../../../common/Loader';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import CargoCategoryForm from './CargoCategoryForm';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { CARGO_CATEGORY_PATH } from '../../../../config/paths';
import {
  clearCargoCategory,
  getCargoCategory,
  putCargoCategory,
} from '../../../../actions/cargo';

class EditCargoCategory extends Component {
  componentDidMount() {
    const {
      dispatchGetCargoCategory,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetCargoCategory({ id });
  }

  componentWillUnmount() {
    const { dispatchClearCargoCategory } = this.props;
    dispatchClearCargoCategory();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutCargoCategory,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutCargoCategory(id, formValues);
  };

  createInitialValues = (cargoCategory) => {
    const cargoCategoryJson = {
      name: cargoCategory.get('name'),
      description: cargoCategory.get('description'),
    };

    return cargoCategoryJson;
  };

  render() {
    const { breadcrumbs, cargoCategory, loading } = this.props;

    if (loading || cargoCategory.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Editar Categoría</h1>
          </Col>
        </Row>
        <CargoCategoryForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(cargoCategory)}
        />
      </Container>
    );
  }
}

EditCargoCategory.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoCategory: PropTypes.func.isRequired,
  dispatchClearCargoCategory: PropTypes.func.isRequired,
  dispatchPutCargoCategory: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cargoCategory: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCargoCategory.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Categoría',
      href: CARGO_CATEGORY_PATH,
    },
    {
      text: 'Ver',
      href: `${CARGO_CATEGORY_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  cargoCategory: CargoUnit.CargoCategory.getIn(['current', 'content']),
  loading: CargoUnit.CargoCategory.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCargoCategory: getCargoCategory,
  dispatchClearCargoCategory: clearCargoCategory,
  dispatchPutCargoCategory: putCargoCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCargoCategory);
