import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import Loader from '../../../common/Loader';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateNumber } from '../../../../utils/validators';
import DatePicker from '../../../common/forms/input/DatePicker';
import FormFooter from '../../../common/forms/FormFooter';
import { tzNormalizeDate } from '../../../../utils/date';

const ExtraFuelConsumptionForm = ({ handleSubmit, loading, onModal }) => {
  if (loading) return <Loader />;

  let onSubmitForForm = handleSubmit;
  let buttonType = 'submit';
  let onClickForButton = null;

  if (onModal) {
    onSubmitForForm = null;
    buttonType = 'button';
    onClickForButton = handleSubmit;
  }

  return (
    <Form onSubmit={onSubmitForForm}>
      <FormGroup row>
        {/* TODO add itinerary search field */}
        <FormItem label="Combustible" required>
          <Field
            name="fuelGallons"
            component={TextInput}
            type="text"
            placeholder="Combustible"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Adblue" required>
          <Field
            name="adblueGallons"
            component={TextInput}
            type="text"
            placeholder="Adblue"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha" required>
          <Field
            max={tzNormalizeDate()}
            name="createDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Comentario" required>
          <Field
            name="comment"
            component={TextInput}
            type="textarea"
            placeholder="Comentario"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter
        saveButtonType={buttonType}
        saveButtonAction={onClickForButton}
      />
    </Form>
  );
};

ExtraFuelConsumptionForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onModal: PropTypes.bool,
};

ExtraFuelConsumptionForm.defaultProps = {
  loading: false,
  onModal: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.ExtraFuelConsumption.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'ExtraFuelConsumptionForm',
})(ExtraFuelConsumptionForm);

export default connect(mapStateToProps)(formComponent);
