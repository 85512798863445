import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import AddCreditLineForm from '../../human-resources/agency/AddCreditLineForm';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
  patchCargoCorporateCreditLine,
} from '../../../../actions/cargo/CargoCorporateCredit';

export const AddCreditLineCargoCorporateCredit = ({
  match: {
    params: { id: cargoCorporateCreditId },
  },
  dispatchGetCargoCorporateCredit,
  breadcrumbs,
  cargoCorporateCredit,
  loading,
  dispatchPatchCargoCorporateCreditLine,
  dispatchClearCargoCorporateCredit,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });

    return () => dispatchClearCargoCorporateCredit();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPatchCargoCorporateCreditLine({
      cargoCorporateCreditId,
      amount: formValues.amount,
    });

  const generateInitialValues = (cargoCorporateCreditJson) => ({
    name: cargoCorporateCreditJson.name,
    initialBalance: cargoCorporateCreditJson.initialBalance,
    actualBalance: cargoCorporateCreditJson.actualBalance,
  });

  let content = null;

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty())
    content = <NoDataResource returnPage={CARGO_CORPORATE_CREDIT_PATH} />;
  else
    content = (
      <AddCreditLineForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(cargoCorporateCredit.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Incrementar Saldo"
      subtitle="Incrementar saldo"
      content={content}
    />
  );
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPatchCargoCorporateCreditLine: patchCargoCorporateCreditLine,
};

AddCreditLineCargoCorporateCredit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPatchCargoCorporateCreditLine: PropTypes.func.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

AddCreditLineCargoCorporateCredit.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCreditLineCargoCorporateCredit);
