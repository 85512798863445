import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PROFILE_AUTHORIZED_BY_PROFILE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import AuthorizedProfileByProfileForm from './AuthorizedProfileByProfileForm';
import Loader from '../../../common/Loader';
import {
  clearAuthorizedProfile,
  getAuthorizedProfile,
  putAuthorizedProfile,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditAuthorizedProfileByProfile extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: authorizedProfileId },
      },
      dispatchGetAuthorizedProfile,
    } = this.props;
    dispatchGetAuthorizedProfile({ authorizedProfileId });
  }

  componentWillUnmount() {
    const { dispatchClearAuthorizedProfile } = this.props;
    dispatchClearAuthorizedProfile();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutAuthorizedProfile,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutAuthorizedProfile(id, {
      profileId: formValues.profileId.value,
      authorizedSecurityProfileId: formValues.authorizedSecurityProfileId.value,
    });
  };

  createInitialValues = (authorizedProfile) => ({
    profileId: {
      value: authorizedProfile.get('profileId'),
      label: authorizedProfile.get('profile').name,
    },
    authorizedSecurityProfileId: {
      value: authorizedProfile.get('authorizedSecurityProfileId'),
      label: authorizedProfile.get('authorizedSecurityProfile').name,
    },
  });

  render() {
    const { breadcrumbs, authorizedProfile, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (authorizedProfile.isEmpty()) {
      content = (
        <NoDataResource returnPage={PROFILE_AUTHORIZED_BY_PROFILE_PATH} />
      );
    } else {
      content = (
        <AuthorizedProfileByProfileForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(authorizedProfile)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Perfil Autorizado por Perfil de Seguridad"
        subtitle="Editar perfil autorizado por perfil de seguridad"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { SecurityUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SecurityUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles Autorizados por Perfil de Seguridad',
      href: PROFILE_AUTHORIZED_BY_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: `${PROFILE_AUTHORIZED_BY_PROFILE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  authorizedProfile: SecurityUnit.AuthorizedProfile.getIn([
    'current',
    'content',
  ]),
  loading: !SecurityUnit.AuthorizedProfile.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutAuthorizedProfile: putAuthorizedProfile,
  dispatchGetAuthorizedProfile: getAuthorizedProfile,
  dispatchClearAuthorizedProfile: clearAuthorizedProfile,
};

EditAuthorizedProfileByProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutAuthorizedProfile: PropTypes.func.isRequired,
  dispatchGetAuthorizedProfile: PropTypes.func.isRequired,
  dispatchClearAuthorizedProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  authorizedProfile: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditAuthorizedProfileByProfile.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAuthorizedProfileByProfile);
