import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getDriverDocumentTypes,
  clearDriverDocumentTypes,
} from '../../../../actions';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';

class DriverDocumentTypeSelect extends Component {
  componentDidMount() {
    const { dispatchGetDriverDocumentTypes } = this.props;
    dispatchGetDriverDocumentTypes(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearDriverDocumentTypes, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearDriverDocumentTypes();
    }
  }

  render() {
    const { options, isLoading, isMulti, input, meta, ...rest } = this.props;
    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
        {...rest}
      />
    );
  }
}

DriverDocumentTypeSelect.propTypes = {
  dispatchGetDriverDocumentTypes: PropTypes.func.isRequired,
  dispatchClearDriverDocumentTypes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  cleanWhenUnmount: PropTypes.bool,
};

DriverDocumentTypeSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  options: [],
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  options: TrafficUnit.DriverDocumentType.getIn([
    'all',
    'content',
    'content',
  ]).map((type) => ({
    value: type.id,
    label: type.name,
  })),
  loading: TrafficUnit.DriverDocumentType.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetDriverDocumentTypes: getDriverDocumentTypes,
  dispatchClearDriverDocumentTypes: clearDriverDocumentTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(DriverDocumentTypeSelect);
