import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BUSINESS_PATH } from '../../../../config/paths';
import { postBusiness } from '../../../../actions/user';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import BusinessForm from './BusinessForm';

const NewBusiness = ({ breadcrumbs, dispatchPostBusiness }) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.addressId =
      formValues.addressId && formValues.addressId.value;
    dispatchPostBusiness({ values: newFormValues });
  };

  const content = <BusinessForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Empresa"
      subtitle="Crear nueva empresa"
      content={content}
    />
  );
};

NewBusiness.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostBusiness: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostBusiness: postBusiness,
};

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas',
      href: BUSINESS_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewBusiness);
