import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { debounce } from '../../../utils/promise';

class GridSelect extends Component {
  constructor(props) {
    super(props);
    this.hiddenSelect = createRef();
  }

  handleChange = (selected) => {
    const { onValueChange } = this.props;
    onValueChange(selected);
  };

  render() {
    const {
      onInputChange,
      isMulti,
      noOptionsMessage,
      placeholder,
      isDisabled,
      onBlur,
      autoFocus,
      ...rest
    } = this.props;

    return (
      <>
        <ReactSelect
          isDisabled={isDisabled}
          onInputChange={debounce(onInputChange)}
          menuPortalTarget={document.body}
          onChange={this.handleChange}
          isMulti={isMulti}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          onBlur={onBlur}
          autoFocus={autoFocus}
          {...rest}
        />
      </>
    );
  }
}

GridSelect.propTypes = {
  loading: PropTypes.bool,
  isMulti: PropTypes.bool,
  onInputChange: PropTypes.func,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
};

GridSelect.defaultProps = {
  loading: false,
  isMulti: false,
  isDisabled: false,
  options: [],
  isClearable: false,
  onInputChange: undefined,
  placeholder: 'Selecciona ...',
  noOptionsMessage: () => 'No hay resultados.',
  onBlur: undefined,
  autoFocus: false,
};

export default GridSelect;
