import { DEBOUNCE_WAIT_TIME } from '../config/constants';

// TODO improve function for several arrays
const mergeObjectsByIds = (
  mainArray,
  mainArrayKey,
  secondArray,
  secondArrayKey,
) =>
  mainArray.map((mainElement) => {
    const foundObject = secondArray.find(
      (secondElement) =>
        secondElement.status === 'fulfilled' &&
        mainElement[mainArrayKey] === secondElement.value[secondArrayKey],
    );

    return { ...mainElement, data: foundObject ? foundObject.value : null };
  });

const debounce = (callback, wait = DEBOUNCE_WAIT_TIME) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export { mergeObjectsByIds, debounce };
