import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getLiquidationDifferenceReasons,
  clearLiquidationDifferenceReasons,
} from '../../../../actions/index';
import Select from './Select';
import { optionsPropTypes } from './SelectPropTypes';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

class LiquidationDifferenceReasonSelect extends Component {
  componentDidMount() {
    const { dispatchGetLiquidationDifferenceReasons } = this.props;
    dispatchGetLiquidationDifferenceReasons({ ...DEFAULT_QUERY_GET_ALL });
  }

  componentWillUnmount() {
    const { dispatchClearLiquidationDifferenceReasons } = this.props;
    dispatchClearLiquidationDifferenceReasons();
  }

  render() {
    const { reasons, isLoading, isMulti, input, meta, isDisabled } = this.props;

    return (
      <Select
        options={reasons}
        isLoading={isLoading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        isDisabled={isDisabled}
      />
    );
  }
}

LiquidationDifferenceReasonSelect.propTypes = {
  dispatchGetLiquidationDifferenceReasons: PropTypes.func.isRequired,
  dispatchClearLiquidationDifferenceReasons: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  reasons: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

LiquidationDifferenceReasonSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  reasons: [],
  isDisabled: false,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  reasons: AccountingUnit.LiquidationDifferenceReason.getIn([
    'all',
    'content',
    'content',
  ]).map((reason) => ({
    value: reason.id,
    label: reason.name,
  })),
  loading: AccountingUnit.LiquidationDifferenceReason.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetLiquidationDifferenceReasons: getLiquidationDifferenceReasons,
  dispatchClearLiquidationDifferenceReasons: clearLiquidationDifferenceReasons,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(LiquidationDifferenceReasonSelect);
