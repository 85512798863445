import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Label, Button, Col, Row } from 'reactstrap';
import DatePicker from '../../../common/forms/input/DatePicker';
import { getPublicCities } from '../../../../actions';
import { NUM_PASSENGERS_OPTIONS } from '../../../../config/constants';
import { isRequired, validateEndDate } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import { tzNormalizeDate } from '../../../../utils/date';

class ItinerarySearchForm extends Component {
  constructor(props) {
    super(props);
    this.props.getPublicCities();
  }

  render() {
    const { handleSubmit, submitting, cities, loadingCities } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={4} md={3} sm={6}>
            <FormGroup>
              <Label>
                De <span className="text-danger">*</span>
              </Label>
              <Field
                name="source"
                component={Select}
                options={cities}
                isLoading={loadingCities}
                validate={[isRequired]}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={3} sm={6}>
            <FormGroup>
              <Label>
                A <span className="text-danger">*</span>
              </Label>
              <Field
                name="destination"
                component={Select}
                options={cities}
                isLoading={loadingCities}
                validate={[isRequired]}
                autoFocus
              />
            </FormGroup>
          </Col>
          <Col lg={2} md={3} sm={6}>
            <FormGroup>
              <Label>
                Fecha <span className="text-danger">*</span>
              </Label>
              <Field
                min={tzNormalizeDate()}
                name="time"
                component={DatePicker}
                validate={[isRequired, validateEndDate]}
              />
            </FormGroup>
          </Col>
          <Col lg={2} md={3} sm={6}>
            <FormGroup>
              <Label>
                # Pasajeros <span className="text-danger">*</span>
              </Label>
              <Field
                name="numPassengers"
                options={NUM_PASSENGERS_OPTIONS}
                component={Select}
                validate={[isRequired]}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="FormButtonGroup mt-0">
          <Button type="submit" disabled={submitting} color="primary">
            <i className="fa fa-search" /> Buscar
          </Button>
        </div>
      </Form>
    );
  }
}

ItinerarySearchForm.propTypes = {
  getPublicCities: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loadingCities: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const query = state.BookingUnit.Booking.getIn(['search', 'query']);
  const initialValues = {
    source: {
      label: query.get('source').label,
      value: query.get('source').value,
    },
    time: query.get('time'),
  };

  // number of passengers can be fixed or variable
  // and is stored in the reducer as a number
  const numPassengers = query.get('numPassengers');
  if (numPassengers) {
    initialValues.numPassengers = NUM_PASSENGERS_OPTIONS[numPassengers];
  } else {
    const [defaultOption] = NUM_PASSENGERS_OPTIONS;
    initialValues.numPassengers = defaultOption;
  }

  // destination is stored in the reducer as an object
  if (query.get('destination')) {
    initialValues.destination = {
      label: query.get('destination').label,
      value: query.get('destination').value,
    };
  }
  return {
    initialValues,
    fetchingCities: state.BookingUnit.City.get('fetchingCities'),
    // this component uses registered buses in a
    // dropdown so map out the required fields
    cities: state.BookingUnit.PublicCities.get('all')
      .get('content')
      .get('content')
      .map((city) => ({
        value: city.id,
        label: city.name,
      })),
    loadingCities: state.BookingUnit.PublicCities.get('all').get('activity'),
  };
};

const mapDispatchToProps = {
  getPublicCities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'BookingItinerarySearchForm',
  })(ItinerarySearchForm),
);
