import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { DETRACTION_PATH } from '../../../../config/paths';
import DetractionDeleteButton from './DetractionDeleteButton';

const DetractionToolbar = ({ detractionId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${DETRACTION_PATH}/${detractionId}/edit`} />{' '}
      <DetractionDeleteButton detractionId={detractionId} />
    </div>
  </ButtonToolbar>
);

DetractionToolbar.propTypes = {
  detractionId: PropTypes.number.isRequired,
};

export default DetractionToolbar;
