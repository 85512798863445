import Immutable from 'immutable';
import {
  AGENCY_GROUP_PATH,
  AGENCY_PATH,
  HOME_PATH,
  PRINT_STATION_PATH,
  WORKSTATION_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'agencies',
      title: 'Agencias',
      path: AGENCY_PATH,
      icon: 'building',
    },
    {
      id: 'agency-groups',
      title: 'Grupo de Agencias',
      path: AGENCY_GROUP_PATH,
      icon: 'list-ul',
    },
    {
      id: 'driver-comissions',
      title: 'Condiciones de Trabajo de Conductores',
      path: '/human-resources/driver-comissions',
      icon: 'percent',
      disabled: true,
    },
    {
      id: 'workstations',
      title: 'Cajas',
      path: WORKSTATION_PATH,
      icon: 'desktop',
    },
    {
      id: 'print-stations',
      title: 'Impresoras',
      path: PRINT_STATION_PATH,
      icon: 'print',
    },
    {
      id: 'cabin-crew-commissions',
      title: 'Condiciones de Trabajo de Servicio Abordo',
      path: '/human-resources/cabin-crew-commissions',
      icon: 'percent',
      disabled: true,
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Recursos Humanos',
      tag: 'span',
      href: '/human-resources',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
