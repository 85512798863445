import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditCargoDistanceButton from './EditCargoDistanceButton';
import DeleteCargoDistanceButton from './DeleteCargoDistanceButton';

const CargoDistanceToolbar = ({ cargoDistanceId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditCargoDistanceButton cargoDistanceId={cargoDistanceId} />{' '}
      <DeleteCargoDistanceButton cargoDistanceId={cargoDistanceId} />
    </div>
  </ButtonToolbar>
);

CargoDistanceToolbar.propTypes = {
  cargoDistanceId: PropTypes.number.isRequired,
};

export default CargoDistanceToolbar;
