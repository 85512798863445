import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  NEW_ITINERARY_GROUP_PATH,
  ITINERARY_GROUP_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postItineraryGroup } from '../../../../actions';
import ItineraryGroupForm from './ItineraryGroupForm';
import { ITINERARY_GROUP_STATUS } from '../../../../config/constants';
import Content from '../../../layout/Content';

export class NewItineraryGroup extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      circuitGroupItineraryList: formValues.circuitGroupItineraryList.map(
        ({ value: itineraryId }) => ({
          itineraryId,
        }),
      ),
      circuitId: formValues.circuitId.value,
      comments: formValues.comments,
      registeredBusId: formValues.registeredBusId.value,
      status: formValues.status.value,
    };
    this.props.dispatchPostItineraryGroup(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Grupo de Itinerarios por Circuito"
        subtitle="Crear un nuevo grupo de itinerarios por circuito"
        content={
          <ItineraryGroupForm
            onSubmit={this.onSubmit}
            initialValues={{ status: ITINERARY_GROUP_STATUS.CREATED }}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Itinerarios por Circuito',
      href: ITINERARY_GROUP_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_ITINERARY_GROUP_PATH,
    },
  ],
});

const mapDispatchToProps = { dispatchPostItineraryGroup: postItineraryGroup };

NewItineraryGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostItineraryGroup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewItineraryGroup);
