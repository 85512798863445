import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { DATE_FORMAT } from '../../../../../config/locale';
import { Table } from '../../../../common/Table';
import { numberFormatter } from '../../../../../utils/number';
import { tzNormalizeDate } from '../../../../../utils/date';
import Alert from '../../../../common/informative/Alert';
import { THERE_ARE_NOT_DATA_TO_SHOW } from '../../../../../config/messages';

const LiquidationBasicInformationResource = ({
  agencyName,
  createDate,
  exchangeRateSet,
  operatorUserFullName,
}) => {
  const exchangeRateTable =
    exchangeRateSet.length > 0 ? (
      <div className="mb-2">
        <Table
          columns={[
            {
              Header: 'Moneda',
              accessor: 'currency',
              className: 'text-center',
            },
            {
              Header: 'Valor',
              accessor: 'rate',
              className: 'text-center',
              Cell: ({ value }) => numberFormatter({ value }),
            },
          ]}
          data={exchangeRateSet}
          defaultPageSize={exchangeRateSet.length}
          showPagination={false}
        />
      </div>
    ) : (
      <Alert type="warning" message={THERE_ARE_NOT_DATA_TO_SHOW} />
    );
  return (
    <>
      <ResourceProperty label="Agencia:">{agencyName}</ResourceProperty>
      <ResourceProperty label="Fecha:">
        {tzNormalizeDate({ date: createDate, format: DATE_FORMAT })}
      </ResourceProperty>
      <ResourceProperty label="Usuario:">
        {operatorUserFullName || '-'}
      </ResourceProperty>
      <h3>Tipos de Cambio</h3>
      {exchangeRateTable}
    </>
  );
};

LiquidationBasicInformationResource.propTypes = {
  agencyName: PropTypes.string.isRequired,
  createDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  exchangeRateSet: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  operatorUserFullName: PropTypes.string,
};

LiquidationBasicInformationResource.defaultProps = {
  exchangeRateSet: [],
  operatorUserFullName: null,
};

export default LiquidationBasicInformationResource;
