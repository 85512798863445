import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  NEW_COMMON_PRODUCT_PATH,
  COMMON_PRODUCT_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CommonProductForm from './CommonProductForm';
import { postCommonProduct } from '../../../../actions/cargo';
import Loader from '../../../common/Loader';

// eslint-disable-next-line react/prefer-stateless-function
class NewCommonProduct extends Component {
  render() {
    const { breadcrumbs, loading } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Crear Producto Frecuente</h1>
            <p>Crear Producto Frecuente</p>
          </Col>
        </Row>
        <CommonProductForm onSubmit={this.props.dispatchPostCommonProduct} />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Producto Frecuente',
      href: COMMON_PRODUCT_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_COMMON_PRODUCT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostCommonProduct: postCommonProduct,
};

NewCommonProduct.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCommonProduct: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewCommonProduct.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCommonProduct);
