import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCrewStatuses, clearCrewStatuses } from '../../../../actions';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';

class CrewStatusSelect extends Component {
  componentDidMount() {
    const { dispatchGetCrewStatuses } = this.props;
    dispatchGetCrewStatuses(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearCrewStatuses, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearCrewStatuses();
    }
  }

  render() {
    const { options, isLoading, isMulti, input, meta } = this.props;
    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
      />
    );
  }
}

CrewStatusSelect.propTypes = {
  dispatchGetCrewStatuses: PropTypes.func.isRequired,
  dispatchClearCrewStatuses: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  cleanWhenUnmount: PropTypes.bool,
};

CrewStatusSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  options: [],
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  options: TrafficUnit.CrewStatus.getIn(['all', 'content', 'content']).map(
    (status) => ({
      value: status.id,
      label: status.name,
    }),
  ),
  loading: TrafficUnit.CrewStatus.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetCrewStatuses: getCrewStatuses,
  dispatchClearCrewStatuses: clearCrewStatuses,
};

export default connect(mapStateToProps, mapDispatchToprops)(CrewStatusSelect);
