import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Content from '../../../layout/Content';
import AddressForm from './AddressForm';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { ADDRESS_PATH } from '../../../../config/paths';
import { postAddress } from '../../../../actions';

const NewAddress = ({ breadcrumbs, dispatchPostAddress }) => {
  const onSubmit = (formValues) => {
    const { address, address2, city, phone, postalCode, selectedDistrict } =
      formValues;
    const newFormValues = {
      address,
      address2,
      district: selectedDistrict.label,
      postalCode,
      phone,
      cityId: city.value,
      districtId: selectedDistrict.value,
    };
    dispatchPostAddress(newFormValues);
  };

  const content = <AddressForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Dirección"
      subtitle="Crear nueva dirección"
      content={content}
    />
  );
};

NewAddress.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostAddress: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostAddress: postAddress,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Direcciones',
      href: ADDRESS_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAddress);
