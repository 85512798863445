import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ACTIVITY_PATH } from '../../../../config/paths';
import {
  clearActivity,
  getActivity,
  putActivity,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ActivityForm from './ActivityForm';
import {
  MAINTENANCE_FACTOR_TYPE,
  MAINTENANCE_ACTIVITY_TYPE,
  ACTIVITY_ACTION,
  ACTIVITY_EXECUTION_METHOD,
} from '../../../../config/constants';

const EditActivity = ({
  breadcrumbs,
  dispatchGetActivity,
  dispatchClearActivity,
  dispatchPutActivity,
  match: {
    params: { id: activityId },
  },
  loading,
  activity,
}) => {
  useLayoutEffect(() => {
    dispatchGetActivity({ activityId });

    return () => dispatchClearActivity();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.action = formValues.action && formValues.action.value;
    newFormValues.executionMethod =
      formValues.executionMethod && formValues.executionMethod.value;
    newFormValues.factorType =
      formValues.factorType && formValues.factorType.value;
    newFormValues.companyAreaId =
      formValues.companyAreaId && formValues.companyAreaId.value;
    newFormValues.activityType =
      formValues.activityType && formValues.activityType.value;
    newFormValues.materialList =
      formValues.materialList &&
      formValues.materialList.map(({ materialId, quantity }) => ({
        materialId: materialId.value,
        quantity: quantity || 1,
      }));
    newFormValues.activityTaskList =
      formValues.activityTaskList &&
      formValues.activityTaskList.map(
        ({ description, workingHours, companyAreaId, taskOrder }) => ({
          description,
          workingHours: +workingHours,
          companyAreaId: companyAreaId.value,
          taskOrder: +taskOrder,
          orderDependent: !!taskOrder,
        }),
      );
    dispatchPutActivity(activityId, newFormValues);
  };

  const generateInitialValues = ({
    name,
    description,
    activityType,
    factor,
    factorType,
    companyArea,
    companyActivityId,
    materialList,
    activityTaskList,
    action,
    executionMethod,
  }) => ({
    action: action ? ACTIVITY_ACTION[action] : null,
    executionMethod: executionMethod
      ? ACTIVITY_EXECUTION_METHOD[executionMethod]
      : null,
    name,
    description,
    activityType: MAINTENANCE_ACTIVITY_TYPE[activityType],
    factor,
    factorType: factorType && MAINTENANCE_FACTOR_TYPE[factorType],
    companyAreaId: companyArea && {
      value: companyArea.id,
      label: companyArea.name,
    },
    companyActivityId,
    materialList: materialList.map(({ materialId, material, quantity }) => ({
      materialId: {
        value: materialId,
        label: `${material.externalId} - ${material.name} - ${material.measurementUnit}`,
      },
      quantity,
    })),
    activityTaskList: activityTaskList.map((task) => ({
      description: task.description,
      workingHours: task.workingHours,
      companyAreaId: {
        value: task.companyAreaId,
        label: task.companyArea.name,
      },
      taskOrder: task.taskOrder,
    })),
  });

  let content;

  if (loading) content = <Loader />;
  else if (activity.isEmpty())
    content = <NoDataResource returnPage={ACTIVITY_PATH} />;
  else
    content = (
      <ActivityForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(activity.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Actividad"
      subtitle="Editar actividad"
      content={content}
    />
  );
};

EditActivity.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearActivity: PropTypes.func.isRequired,
  dispatchGetActivity: PropTypes.func.isRequired,
  dispatchPutActivity: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  activity: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditActivity.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearActivity: clearActivity,
  dispatchGetActivity: getActivity,
  dispatchPutActivity: putActivity,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Actividades',
      href: ACTIVITY_PATH,
    },
    {
      text: 'Ver',
      href: `${ACTIVITY_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  activity: MechanicalMaintenanceUnit.Activity.getIn(['current', 'content']),
  loading: !MechanicalMaintenanceUnit.Activity.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActivity);
