import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { WORKSTATION_PATH } from '../../../../config/paths';

const WorkstationEditButton = ({ workstationId }) => (
  <Link
    className="btn btn-primary"
    to={`${WORKSTATION_PATH}/${workstationId}/edit`}
  >
    Editar
  </Link>
);

WorkstationEditButton.propTypes = {
  workstationId: PropTypes.number.isRequired,
};

export default WorkstationEditButton;
