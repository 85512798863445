import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_CREDIT_NOTES,
  GET_CREDIT_NOTES,
  CLEAR_CREDIT_NOTES,
  FLAG_CREDIT_NOTE_ACTIVITY,
  CLEAR_CREDIT_NOTE,
  GET_CREDIT_NOTE,
} from '../types';
import { CREDIT_NOTE_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CREDIT_NOTE_PATH } from '../../config/paths';

const flagGettingCreditNotes = (flag) => (dispatch) => {
  dispatch({
    type: FLAG_GETTING_CREDIT_NOTES,
    payload: flag,
  });
};

const clearCreditNotes = () => (dispatch) => {
  dispatch({
    type: CLEAR_CREDIT_NOTES,
  });
};

const getCreditNotes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCreditNotes(true));

    const query = tableFilters;
    const url = `${CREDIT_NOTE_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(promise);

    const response = await promise.json();
    dispatch({ type: GET_CREDIT_NOTES, payload: response });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingCreditNotes(false));
  }
};

const flagCreditNoteActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CREDIT_NOTE_ACTIVITY,
    payload: flag,
  });

const clearCreditNote = () => (dispatch) =>
  dispatch({
    type: CLEAR_CREDIT_NOTE,
  });

const getCreditNote =
  async ({ creditNoteId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCreditNoteActivity(true));

      const url = `${CREDIT_NOTE_ENDPOINT}/${creditNoteId}`;

      const promise = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(promise);

      const response = await promise.json();

      dispatch({ type: GET_CREDIT_NOTE, payload: response });
    } catch ({ message }) {
      toastr.error('Error',{ message });
    } finally {
      dispatch(flagCreditNoteActivity(false));
    }
  };

const postCreditNote =
  async ({
    customerId,
    reference,
    description,
    amount,
    isValid,
    expirationDate,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagCreditNoteActivity(true));

      const payload = {
        customerId,
        reference,
        description,
        amount,
        isValid,
        expirationDate,
      };

      const url = CREDIT_NOTE_ENDPOINT;

      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(promise);

      const response = await promise.json();

      dispatch(push(`${CREDIT_NOTE_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagCreditNoteActivity(false));
    }
  };

const putCreditNote =
  async (
    creditNoteId,
    {
      customerId,
      reference,
      description,
      amount,
      isValid,
      expirationDate,
      balance,
      companyId,
      documentCode,
      documentSeries,
      previousTicketId,
      creditNoteTransactionList,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCreditNoteActivity(true));

      const payload = {
        customerId,
        reference,
        description,
        amount,
        isValid,
        expirationDate,
        balance,
        companyId,
        documentCode,
        documentSeries,
        previousTicketId,
        creditNoteTransactionList,
        id: creditNoteId,
      };

      const url = `${CREDIT_NOTE_ENDPOINT}/${creditNoteId}`;

      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(promise);

      const response = await promise.json();

      dispatch(push(`${CREDIT_NOTE_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagCreditNoteActivity(false));
    }
  };

export {
  clearCreditNotes,
  getCreditNotes,
  clearCreditNote,
  postCreditNote,
  getCreditNote,
  putCreditNote,
};
