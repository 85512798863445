import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteCompany } from '../../../../actions';

class CompanyDeleteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  onClickDelete = () => {
    const { dispatchDeleteCompany, companyId } = this.props;
    dispatchDeleteCompany({ companyId });
  };

  onClickShowModal = () => this.setState({ showModal: true });

  onClickCancel = () => this.setState({ showModal: false });

  render() {
    const { showModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showModal}
        onClickConfirm={this.onClickDelete}
        onClickCancel={this.onClickCancel}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.onClickShowModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

CompanyDeleteButton.propTypes = {
  companyId: PropTypes.number.isRequired,
  dispatchDeleteCompany: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteCompany: deleteCompany,
};

export default connect(null, mapDispatchToProps)(CompanyDeleteButton);
