import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { EXPENSE_TYPE_BREADCRUMBS } from './config/breadcrumbs';
import ContainerComponent from '../../../common/resource/ContainerComponent';
import { getExpenseType } from './fetch';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import EditExpenseTypeButton from './EditExpenseTypeButton';
import DeleteExpenseTypeButton from './DeleteExpenseTypeButton';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

const ExpenseType = ({
  payload: {
    id,
    name,
    expenseCode,
    description,
    expenseTypeLabel,
    internal,
    costCenter,
    createDate,
    lastUpdate,
  },
}) => (
  <Content
    breadcrumbs={EXPENSE_TYPE_BREADCRUMBS}
    title="Tipo de Egreso"
    toolbar={
      <ButtonToolbar className="pull-right">
        <div>
          <EditExpenseTypeButton expenseTypeId={id} />{' '}
          <DeleteExpenseTypeButton expenseTypeId={id} />
        </div>
      </ButtonToolbar>
    }
    content={
      <Fragment>
        <ResourceProperty label="Nombre:">{name}</ResourceProperty>
        <ResourceProperty label="Código de Egreso:">
          {expenseCode}
        </ResourceProperty>
        <ResourceProperty label="Descripción:">{description}</ResourceProperty>
        <ResourceProperty label="Etiqueta de Egreso:">
          {expenseTypeLabel}
        </ResourceProperty>
        {/* "internal" means that these expenses type will not be listed in the expense creation for counters */}
        <ResourceProperty label="Interno:">
          {internal ? 'Si' : 'No'}
        </ResourceProperty>
        <ResourceProperty label="Centro de Costo:">
          {costCenter
            ? `${costCenter.costCenterCode} - ${costCenter.description}`
            : '-'}
        </ResourceProperty>
        <InternalResource
          id={id}
          createDate={createDate}
          lastUpdate={lastUpdate}
        />
      </Fragment>
    }
  />
);

ExpenseType.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    expenseCode: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    internal: PropTypes.bool.isRequired,
    costCenter: PropTypes.shape({
      costCenterCode: PropTypes.string.isRequired,
      createDate: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
    expenseTypeLabel: PropTypes.string.isRequired,
    createDate: PropTypes.number,
    lastUpdate: PropTypes.number,
  }).isRequired,
};

export default ContainerComponent({ getFunction: getExpenseType })(ExpenseType);
