import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import {
  getDeliveryGroup,
  clearDeliveryGroup,
} from '../../../../actions/cargo';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { DELIVERY_GROUP_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import DeliveryGroupToolbar from './DeliveryGroupToolbar';

export class DeliveryGroup extends Component {
  componentDidMount() {
    const {
      dispatchGetDeliveryGroup,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDeliveryGroup({ deliveryGroupId: id });
  }

  componentWillUnmount() {
    const { dispatchClearDeliveryGroup } = this.props;
    dispatchClearDeliveryGroup();
  }

  render() {
    const { deliveryGroup, loading, breadcrumbs } = this.props;
    if (loading || deliveryGroup.isEmpty()) {
      return <Loader />;
    }
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <DeliveryGroupToolbar deliveryGroupId={deliveryGroup.get('id')} />
        <Row>
          <Col>
            <h1>Grupo Delivery</h1>
          </Col>
        </Row>
        <hr />
        <Fragment>
          <ResourceProperty label="Nombre:">
            {deliveryGroup.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {deliveryGroup.get('description')}
          </ResourceProperty>
        </Fragment>
      </Container>
    );
  }
}

DeliveryGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDeliveryGroup: PropTypes.func.isRequired,
  dispatchClearDeliveryGroup: PropTypes.func.isRequired,
  deliveryGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
};

DeliveryGroup.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos Delivery',
      href: DELIVERY_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  deliveryGroup: CargoUnit.DeliveryGroup.getIn(['current', 'content']),
  loading: CargoUnit.DeliveryGroup.getIn(['current', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDeliveryGroup: getDeliveryGroup,
  dispatchClearDeliveryGroup: clearDeliveryGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryGroup);
