import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  generateChangeExpenseStatusEndpoint,
  generateExpenseStatusEndpoint,
  generateExpenseStatusRemarkEndpoint,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_EXPENSE_STATUS,
  GET_EXPENSE_STATUS,
  CLEAR_EXPENSE_STATUS,
  GET_EXPENSE_STATUS_REMARK,
  FLAG_EXPENSE_STATUS_REMARK_ACTIVITY,
  CLEAR_EXPENSE_STATUS_REMARK,
} from '../types/sales';
import { EXPENSE_STATUS_PATH } from '../../config/paths';
import { flagExpenseActivity } from './Expense';

const flagGettingExpenseStatus = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXPENSE_STATUS,
    payload: flag,
  });

const flagExpenseStatusRemarkActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXPENSE_STATUS_REMARK_ACTIVITY,
    payload: flag,
  });

const getExpensesPerUser = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingExpenseStatus(true));
    const { expenseStatus } = tableFilters;
    const query = tableFilters;
    const url = `${generateExpenseStatusEndpoint(
      expenseStatus,
    )}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const expenses = await response.json();
    dispatch({ type: GET_EXPENSE_STATUS, payload: expenses });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingExpenseStatus(false));
  }
};

const clearExpensePerUser = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_STATUS,
  });

const getExpenseStatusRemark = async (expenseId) => async (dispatch) => {
  try {
    dispatch(flagExpenseStatusRemarkActivity(true));
    const url = generateExpenseStatusRemarkEndpoint(expenseId);
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const remarks = await response.json();
    dispatch({ type: GET_EXPENSE_STATUS_REMARK, payload: remarks });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagExpenseStatusRemarkActivity(false));
  }
};

const clearExpenseStatusRemark = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_STATUS_REMARK,
  });

const postExpenseStatusRemark =
  async ({ expenseId, remarks }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseStatusRemarkActivity(true));
      const payload = {
        expenseId,
        remarks,
      };
      const url = generateExpenseStatusRemarkEndpoint(expenseId);
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(`${EXPENSE_STATUS_PATH}/${expenseId}`));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagExpenseStatusRemarkActivity(false));
    }
  };

const putExpenseStatus =
  async ({ expenseId, expenseStatus }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseActivity(true));
      const payload = {
        expenseId,
        expenseStatus,
      };
      const url = generateChangeExpenseStatusEndpoint(expenseId, expenseStatus);
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(EXPENSE_STATUS_PATH));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagExpenseActivity(false));
    }
  };

export {
  flagGettingExpenseStatus,
  getExpensesPerUser,
  clearExpensePerUser,
  getExpenseStatusRemark,
  clearExpenseStatusRemark,
  postExpenseStatusRemark,
  putExpenseStatus,
};
