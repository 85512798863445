import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  USER_ENDPOINT,
  EXPENSE_AUTHORIZER_USER_ENDPOINT,
  SELF_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_USERS,
  GET_USERS,
  CLEAR_USERS,
  GET_USER,
  CLEAR_USER,
  FLAG_USER_ACTIVITY,
} from '../types/user/User';
import { USER_PATH, USER_SELF_PATH } from '../../config/paths';

const flagGettingUsers = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_USERS,
    payload: flag,
  });

const getUsers = async (tableFilters = null) => async dispatch => {
  try {
    dispatch(flagGettingUsers(true));
    const query = tableFilters;
    const url = `${USER_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const users = await response.json();
    dispatch({ type: GET_USERS, payload: users });
  } catch ({message}) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingUsers(false));
  }
};

const flagUserActivity = flag => dispatch =>
  dispatch({
    type: FLAG_USER_ACTIVITY,
    payload: flag,
  });

const getUser = async ({ userId }) => async dispatch => {
  try {
    dispatch(flagUserActivity(true));
    const url = `${USER_ENDPOINT}/${userId}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const user = await response.json();
    dispatch({
      type: GET_USER,
      payload: user,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagUserActivity(false));
  }
};

const getExpenseAuthorizerUser = async (
  tableFilters = null,
) => async dispatch => {
  try {
    dispatch(flagGettingUsers(true));
    const query = tableFilters;
    const url = `${EXPENSE_AUTHORIZER_USER_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const users = await response.json();
    dispatch({ type: GET_USERS, payload: users });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingUsers(false));
  }
};

const clearUsers = () => dispatch =>
  dispatch({
    type: CLEAR_USERS,
  });

const clearUser = () => dispatch =>
  dispatch({
    type: CLEAR_USER,
  });

const postUser = async ({
  username,
  newPassword,
  enabled,
  verified,
  blocked,
  locale,
  customerId,
  confirmNewPassword,
  securityProfileSet,
}) => async dispatch => {
  try {
    dispatch(flagUserActivity(true));
    const payload = {
      username,
      newPassword,
      enabled,
      verified,
      blocked,
      locale,
      customerId,
      confirmNewPassword,
      securityProfileSet,
    };
    const url = USER_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const user = await response.json();
    dispatch(push(`${USER_PATH}/${user.id}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagUserActivity(false));
  }
};

const putUser = async (
  userId,
  {
    username,
    newPassword,
    enabled,
    verified,
    blocked,
    locale,
    customerId,
    confirmNewPassword,
    securityProfileSet,
    authorizingPassword,
  },
) => async dispatch => {
  try {
    dispatch(flagUserActivity(true));
    const payload = {
      username,
      newPassword,
      enabled,
      verified,
      blocked,
      locale,
      customerId,
      confirmNewPassword,
      securityProfileSet,
      authorizingPassword,
      id: userId,
    };
    const url = `${USER_ENDPOINT}/${userId}`;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const user = await response.json();
    dispatch(push(`${USER_PATH}/${user.id}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagUserActivity(false));
  }
};

const deleteUser = async ({ userId }) => async dispatch => {
  try {
    dispatch(flagUserActivity(true));
    const url = `${USER_ENDPOINT}/${userId}`;
    const response = await fetch(url, {
      ...DEFAULT_DELETE_CONFIG,
    });
    await isErrorResponse(response);
    await response.json();
    dispatch(push(USER_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagUserActivity(false));
  }
};

const putUserSelf = async (
  userId,
  { confirmPassword, currentPassword, newPassword },
) => async dispatch => {
  try {
    dispatch(flagUserActivity(true));

    const payload = {
      confirmPassword,
      currentPassword,
      newPassword,
      id: userId,
    };

    const url = `${SELF_ENDPOINT}/${userId}`;

    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });

    await isErrorResponse(response);

    await response.json();

    dispatch(push(USER_SELF_PATH));
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagUserActivity(false));
  }
};

export {
  flagGettingUsers,
  getUsers,
  clearUsers,
  getExpenseAuthorizerUser,
  flagUserActivity,
  getUser,
  clearUser,
  postUser,
  putUser,
  deleteUser,
  putUserSelf,
};
