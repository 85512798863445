import { string, number, shape, arrayOf, bool } from 'prop-types';
import { transactionPropTypes } from '../../../common/proptypes/TransactionPropTypes';
import { customerBasicInformationPropTypes } from '../../../user/customer/proptypes/CustomerPropTypes';

const creditNoteBasicInformationPropTypes = {
  documentSeries: string,
  documentCode: number,
  isValid: bool,
  expirationDate: number,
  reference: string,
  description: string,
  balance: string,
  customer: shape(customerBasicInformationPropTypes),
};

const creditNoteTransactionInformationPropTypes = {
  creditNoteTransactionList: arrayOf(
    shape({
      id: number.isRequired,
      creditNoteId: number.isRequired,
      transactionId: number.isRequired,
      transaction: shape(transactionPropTypes),
    }),
  ),
};

export {
  creditNoteBasicInformationPropTypes,
  creditNoteTransactionInformationPropTypes,
};
