import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateNumber } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';

// eslint-disable-next-line react/prefer-stateless-function
class BasePricePerKilometerForm extends Component {
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <FormGroup row>
          <FormItem label="Desde Kilómetro" required>
            <Field
              name="fromKilometer"
              component={TextInput}
              type="text"
              placeholder="Desde Kilómetro"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Hasta Kilómetro" required>
            <Field
              name="toKilometer"
              component={TextInput}
              type="text"
              placeholder="Hasta Kilómetro"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Precio Base" required>
            <Field
              name="price"
              component={TextInput}
              type="text"
              placeholder="Precio Base"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Factor Kilometraje" required>
            <Field
              name="kilometerFactor"
              component={TextInput}
              type="text"
              placeholder="Factor Kilometraje"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

BasePricePerKilometerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'BasePricePerKilometerForm' })(
  BasePricePerKilometerForm,
);
