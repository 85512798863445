export const FLAG_ITEM_TO_ITINERARY_ACTIVITY =
  'FLAG_ITEM_TO_ITINERARY_ACTIVITY';
export const GET_ITEMS_TO_ITINERARY = 'GET_ITEMS_TO_ITINERARY';
export const CLEAR_ITEMS_TO_ITINERARY = 'CLEAR_ITEMS_TO_ITINERARY';
export const UPDATE_ITEM_TO_ITINERARY = 'UPDATE_ITEM_TO_ITINERARY';
export const DELETE_ITEM_FROM_ITINERARY = 'DELETE_ITEM_FROM_ITINERARY';
export const GET_ITEMS_TO_ITINERARY_BY_PARCEL =
  'GET_ITEMS_TO_ITINERARY_BY_PARCEL';
export const CLEAR_ITEMS_TO_ITINERARY_BY_PARCEL =
  'CLEAR_ITEMS_TO_ITINERARY_BY_PARCEL';
export const UPDATE_ITEMS_TO_ITINERARY_BY_PARCEL =
  'UPDATE_ITEMS_TO_ITINERARY_BY_PARCEL';
