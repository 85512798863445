import Immutable from 'immutable';
import {
  FLAG_GETTING_ITEM_CATEGORIES,
  GET_ITEM_CATEGORIES,
  CLEAR_ITEM_CATEGORIES,
} from '../../../actions/types/baggage/ItemCategory';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_ITEM_CATEGORIES:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_ITEM_CATEGORIES:
      return state.setIn(['all', 'content'], Immutable.Set(action.payload));
    case CLEAR_ITEM_CATEGORIES:
      return state.setIn(['all', 'content'], Immutable.Map());
    default:
      return state;
  }
};
