import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Table from '../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { TICKETS_COLUMNS } from '../../../../config/columns';
import { getTicketCustomer, clearTickets } from '../../../../actions';
import { TICKET_PATH } from '../../../../config/paths';

export class TicketCustomerSearchResult extends Component {
  componentWillUnmount() {
    this.props.dispatchCleartickets();
  }

  render() {
    const {
      manual,
      loading,
      tickets,
      getRowData,
      searchFormValues,
      dispatchGetTicketCustomer,
      modelPath,
      openPathInNewTab,
    } = this.props;

    const data = tickets.get('content') || [];
    const columns = TICKETS_COLUMNS;
    const pages = tickets.get('totalPages') || null;
    const defaultPageSize = tickets.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <div className="mt-5">
        <Table
          manual={manual}
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          getRowData={getRowData}
          modelPath={modelPath}
          navigateToModelOnRowClick
          fetchData={dispatchGetTicketCustomer}
          params={searchFormValues}
          openPathInNewTab={openPathInNewTab}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ ReservationUnit }) => ({
  tickets: ReservationUnit.Ticket.getIn(['all', 'content']),
  loading: ReservationUnit.Ticket.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchCleartickets: clearTickets,
  dispatchGetTicketCustomer: getTicketCustomer,
};

TicketCustomerSearchResult.propTypes = {
  manual: PropTypes.bool,
  tickets: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  getRowData: PropTypes.func,
  dispatchCleartickets: PropTypes.func.isRequired,
  dispatchGetTicketCustomer: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    customerOrderId: PropTypes.number,
  }).isRequired,
  modelPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  openPathInNewTab: PropTypes.bool,
};

TicketCustomerSearchResult.defaultProps = {
  manual: true,
  loading: false,
  getRowData: null,
  modelPath: TICKET_PATH,
  openPathInNewTab: true,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TicketCustomerSearchResult);
