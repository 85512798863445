import React from 'react';
import { numberFormatter } from '../../../../utils/number';
import { parcelOtherIncomesSummaryPropTypes } from './SalesSessionPropTypes';
import Table from '../../../common/Table';

const ParcelOtherIncomesSummary = ({ parcelOtherIncomesSummaryList }) => {
  if (!parcelOtherIncomesSummaryList.length) {
    return null;
  }
  return (
    <>
      <hr />
      <h2>Otros Ingresos de Cargo</h2>
      <Table
        columns={[
          {
            Header: 'Método de Pago',
            accessor: 'moneyFlowConcept',
            className: 'text-center',
          },
          {
            Header: 'Total',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={parcelOtherIncomesSummaryList}
        defaultPageSize={parcelOtherIncomesSummaryList.length}
        showPagination={false}
      />
    </>
  );
};

ParcelOtherIncomesSummary.propTypes = {
  parcelOtherIncomesSummaryList: parcelOtherIncomesSummaryPropTypes.isRequired,
};

export default ParcelOtherIncomesSummary;
