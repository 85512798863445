import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { AGENCY_PATH, NEW_AGENCY_PATH } from '../../../../config/paths';
import { AGENCY_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { clearAgencies, getAgencies } from '../../../../actions';

export const Agencies = ({
  dispatchClearAgencies,
  agencies,
  loading,
  breadcrumbs,
  dispatchGetAgencies,
}) => {
  useLayoutEffect(() => () => dispatchClearAgencies(), []);

  const data = agencies.get('content') || [];
  const pages = agencies.get('totalPages') || null;
  const defaultPageSize = agencies.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Agencias"
      buttonPath={NEW_AGENCY_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: AGENCY_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetAgencies,
        modelPath: AGENCY_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetAgencies: getAgencies,
  dispatchClearAgencies: clearAgencies,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Agencias',
      href: AGENCY_PATH,
    },
  ],
  agencies: HumanResourcesUnit.Agency.getIn(['all', 'content']),
  loading: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
});

Agencies.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  agencies: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetAgencies: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
};

Agencies.defaultProps = {
  agencies: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Agencies);
