import { toastr } from 'react-redux-toastr';
import {
  FLAG_FUEL_REPORT_PREVIEW_ACTIVITY,
  CLEAR_FUEL_REPORT_PREVIEW,
  GET_FUEL_VOUCHERS,
  GET_FUEL_REPORT_PREVIEW,
  GET_BASE_FUEL_REFILLS,
} from '../types';
import { getExtraordinaryMovementControlsAsync } from './ExtraordinaryMovementControl';
import {
  clearFuelVouchers,
  flagGettingFuelVouchers,
  getFuelVouchersAsync,
} from './FuelVoucher';
import { getExpectedFuelConsumptionsAsync } from './ExpectedFuelConsumption';
import { getItinerariesByItineraryIdListAsync } from '../itinerary';
import { DEFAULT_QUERY, DEFAULT_QUERY_GET_ALL } from '../../config/queries';
import {
  flagGettingBaseFuelRefill,
  getBaseFuelRefillByRegisteredBusIdAsync,
} from './BaseFuelRefill';
import { getExtraConsumptionByItineraryIdListAsync } from './ExtraFuelConsumption';
import { FUEL_VOUCHER_STATUS } from '../../config/constants';

const flagFuelReportPreviewActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_FUEL_REPORT_PREVIEW_ACTIVITY,
    payload: flag,
  });

const getFuelReportPreview =
  async ({ circuitGroupItineraryList, registeredBusId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingFuelVouchers(true));
      dispatch(flagGettingBaseFuelRefill(true));
      dispatch(flagFuelReportPreviewActivity(true));

      dispatch(clearFuelVouchers());

      // Join itineraries by "|"
      const itinerariesJoined = circuitGroupItineraryList
        .map(({ itineraryId }) => itineraryId)
        .join('|');

      // Get created fuel vouchers
      const fuelVoucherQuery = [`itineraryId:${itinerariesJoined}`];
      const createdFuelVouchers = await getFuelVouchersAsync({
        ...DEFAULT_QUERY_GET_ALL,
        query: [
          ...fuelVoucherQuery,
          `fuelVoucherStatus:${FUEL_VOUCHER_STATUS.CREATED.value}`,
        ],
      });
      // Get invoiced fuel vouchers
      const invoicedFuelVouchers = await getFuelVouchersAsync({
        ...DEFAULT_QUERY_GET_ALL,
        query: [
          ...fuelVoucherQuery,
          `fuelVoucherStatus:${FUEL_VOUCHER_STATUS.INVOICED.value}`,
        ],
      });

      // Get only ten base fuel refill for show in table
      const tenBaseRefills = await getBaseFuelRefillByRegisteredBusIdAsync(
        registeredBusId,
        DEFAULT_QUERY,
      );
      // Get all base fuel refills
      const allBaseRefills = await getBaseFuelRefillByRegisteredBusIdAsync(
        registeredBusId,
        DEFAULT_QUERY_GET_ALL,
      );

      // constant to save itineraries
      const itineraries = [];

      // Get itineraries per itinerary id list
      const itineraryPromisesReponse =
        await getItinerariesByItineraryIdListAsync({
          itineraryIdList: circuitGroupItineraryList,
        });

      // Get extra consumptions per itinerary id list
      const extraConsumptionPromisesResponse =
        await getExtraConsumptionByItineraryIdListAsync({
          itineraryIdList: circuitGroupItineraryList,
        });

      // Get expected fuel consumption
      const routesJoined = itineraryPromisesReponse
        .map(({ status, value: { routeId } }) =>
          status === 'fulfilled' ? routeId : 0,
        )
        .join('|');
      const routeQuery = {
        ...DEFAULT_QUERY_GET_ALL,
        query: [`route.id:${routesJoined}`],
      };
      const expectedConsumptions = await getExpectedFuelConsumptionsAsync(
        routeQuery,
      );

      // Get extraordinary movement data (kilometers, expected consumption, real consumption)
      const extraordinaryMovementControls =
        await getExtraordinaryMovementControlsAsync({
          ...DEFAULT_QUERY_GET_ALL,
          query: [`itineraryId:${itinerariesJoined}`],
        });

      // Calculate kilometers for regular itineraries
      let totalKilometers = 0;

      // Calculate extra consumptions
      let totalExtraFuelConsumption = 0;

      // Calculate expected consumptions
      let totalExpectedFuelConsumption = 0;

      // Calculate fuel general in fuel vouchers
      let regularFuelInFuelVouchers = 0;
      createdFuelVouchers.content.forEach(
        ({ realNumberOfGallons, numberOfGallons }) => {
          regularFuelInFuelVouchers += +realNumberOfGallons || +numberOfGallons;
        },
      );
      invoicedFuelVouchers.content.forEach(
        ({ realNumberOfGallons, numberOfGallons }) => {
          regularFuelInFuelVouchers += +realNumberOfGallons || +numberOfGallons;
        },
      );

      // Calculate fuel general in base refill
      let regularFuelOnBase = 0;
      allBaseRefills.content.forEach(({ fuelQuantity, itineraryId }) => {
        const foundItinerary = circuitGroupItineraryList.some(
          (circuitGroup) => +circuitGroup.itineraryId === +itineraryId,
        );
        if (foundItinerary) regularFuelOnBase += +fuelQuantity;
      });

      // For know if there are itineraries without expected fuel consumption
      let hasNotExpectedFuelConsumptions = false;
      let hasItinerariesWithoutExtraordinaryRealValue = false;

      // Calculate fuel consumption for extraordinary itineraries
      let extraordinaryFuelConsumption = 0;
      extraordinaryMovementControls.content.forEach(
        ({ authorizedFuelConsumption }) => {
          extraordinaryFuelConsumption += authorizedFuelConsumption;
        },
      );

      // Real fuel consumption for regular and extraordinaries itineraries
      const totalFuelConsumption =
        regularFuelOnBase +
        regularFuelInFuelVouchers +
        extraordinaryFuelConsumption;

      let generalFuelInBase = 0;

      // Generate data for itineraries
      itineraryPromisesReponse.forEach(
        ({
          status: itineraryStatus,
          value: {
            id,
            route,
            serviceType,
            active,
            trip,
            departureTime,
            type,
            extraordinary,
            circuit,
            routeId,
          },
        }) => {
          if (itineraryStatus === 'fulfilled') {
            let kilometers = 0;
            let expectedFuelConsumption = 0;
            let expectedAdblueConsumption = 0;
            let fuelInFuelVoucher = 0;
            let fuelOnBase = 0;
            let AdblueInBase = 0;
            let calculatedRealFuelConsumption = 0;
            let extraFuelConsumption = 0;
            let extraAdblueConsumption = 0;

            // Calculate extra consumption per itinerary
            extraConsumptionPromisesResponse.forEach(
              ({ status: extraConsumptionStatus, value }) => {
                if (
                  extraConsumptionStatus === 'fulfilled' &&
                  value.itineraryId === +id
                ) {
                  extraFuelConsumption += value.fuelGallons;
                  extraAdblueConsumption += value.adblueGallons;
                }
              },
            );

            // Calculate fuel in fuel vouchers per itinerary
            createdFuelVouchers.content.forEach(
              ({ itineraryId, realNumberOfGallons, numberOfGallons }) => {
                if (itineraryId === +id)
                  fuelInFuelVoucher += +realNumberOfGallons || +numberOfGallons;
              },
            );
            invoicedFuelVouchers.content.forEach(
              ({ itineraryId, realNumberOfGallons, numberOfGallons }) => {
                if (itineraryId === +id)
                  fuelInFuelVoucher += +realNumberOfGallons || +numberOfGallons;
              },
            );

            // Calculate fuel in base refill per itinerary
            allBaseRefills.content.forEach(
              ({ itineraryId, fuelQuantity, adblueQuantity }) => {
                if (itineraryId === +id) {
                  fuelOnBase += +fuelQuantity || 0;
                  AdblueInBase += +adblueQuantity || 0;

                  generalFuelInBase = +fuelOnBase;
                }
              },
            );

            let hasNotExtraordinaryRealValue = false;

            // If itinerary is regular
            if (!extraordinary) {
              route.routeSegmentList.forEach(({ segment: { distance } }) => {
                kilometers += distance;
              });

              // Find expected consumptions per routeId
              const foundExpectedConsumptions =
                expectedConsumptions.content.find(
                  (expectedConsumption) =>
                    expectedConsumption.routeId === +routeId,
                );

              if (foundExpectedConsumptions) {
                foundExpectedConsumptions.busFuelGroupConsumptionList.forEach(
                  ({
                    numberOfGallon,
                    numberOfGallonAdblue,
                    busFuelGroupId,
                  }) => {
                    if (
                      busFuelGroupId ===
                      trip.assignedRegisteredBus.busFuelGroupId
                    ) {
                      expectedFuelConsumption += +numberOfGallon || 0;
                      expectedAdblueConsumption += +numberOfGallonAdblue || 0;
                    }
                  },
                );
              }

              // Set base fuel refill for all itineraries
              fuelOnBase = generalFuelInBase;
            } else {
              const foundExtraordinaryMovementControls =
                extraordinaryMovementControls.content.find(
                  ({ itineraryId }) => itineraryId === +id,
                );

              if (foundExtraordinaryMovementControls) {
                kilometers =
                  foundExtraordinaryMovementControls.realKilometers ||
                  foundExtraordinaryMovementControls.initialKilometers;
                calculatedRealFuelConsumption =
                  foundExtraordinaryMovementControls.authorizedFuelConsumption;
                AdblueInBase =
                  foundExtraordinaryMovementControls.realAdblueConsumption;
                ({ expectedFuelConsumption, expectedAdblueConsumption } =
                  foundExtraordinaryMovementControls);

                if (
                  (!foundExtraordinaryMovementControls.initialSourceLocationName &&
                    !foundExtraordinaryMovementControls.realSourceLocationName) ||
                  (!foundExtraordinaryMovementControls.initialDestinationLocationName &&
                    !foundExtraordinaryMovementControls.realDestinationLocationName) ||
                  (!foundExtraordinaryMovementControls.initialKilometers &&
                    !foundExtraordinaryMovementControls.realKilometers) ||
                  (foundExtraordinaryMovementControls.initialKilometers === 0 &&
                    foundExtraordinaryMovementControls.realKilometers === 0)
                ) {
                  hasNotExtraordinaryRealValue = true;
                  hasItinerariesWithoutExtraordinaryRealValue = true;
                }
              }
            }

            totalKilometers += kilometers;
            totalExtraFuelConsumption += extraFuelConsumption;
            totalExpectedFuelConsumption += expectedFuelConsumption;

            if (expectedFuelConsumption === 0)
              hasNotExpectedFuelConsumptions = true;

            // Set itinerary data
            itineraries.push({
              id,
              route,
              serviceType,
              active,
              trip,
              departureTime,
              type,
              extraordinary,
              circuit,
              routeId,
              kilometers,
              expectedFuelConsumption,
              expectedAdblueConsumption,
              fuelInFuelVoucher,
              calculatedRealFuelConsumption,
              AdblueInBase,
              fuelOnBase,
              extraAdblueConsumption,
              extraFuelConsumption,
              hasNotExtraordinaryRealValue,
            });
          }
        },
      );

      const factorOfTotalFuelConsumption =
        totalFuelConsumption / totalKilometers;

      // Calculate real fuel consumption per itinerary
      itineraries.forEach(({ kilometers }, index) => {
        itineraries[index].calculatedRealFuelConsumption = +(
          factorOfTotalFuelConsumption * kilometers
        );
      });

      const totalOfCalculatedFuelConsumption = +(
        factorOfTotalFuelConsumption * totalKilometers
      );

      // Add extra fuel consumption
      totalExpectedFuelConsumption += totalExtraFuelConsumption;

      const header = {
        totalKilometers,
        totalExpectedFuelConsumption,
        totalOfCalculatedFuelConsumption,
        hasNotExpectedFuelConsumptions,
        hasItinerariesWithoutExtraordinaryRealValue,
      };

      const fuelReportPreview = {
        header,
        itineraries,
      };

      // Dispatch fuel vouchers
      const fuelVouchers = { ...createdFuelVouchers };
      fuelVouchers.content = [
        ...createdFuelVouchers.content,
        ...invoicedFuelVouchers.content,
      ];
      dispatch({
        type: GET_FUEL_VOUCHERS,
        payload: fuelVouchers,
      });

      // Dispatch base fuel refill
      dispatch({
        type: GET_BASE_FUEL_REFILLS,
        payload: tenBaseRefills,
      });

      // Dispatch fuel reporte preview data
      dispatch({
        type: GET_FUEL_REPORT_PREVIEW,
        payload: fuelReportPreview,
      });
    } catch ({ message }) {
      toastr.error(
        'Error',
        `Hubo en error al obtener los datos. Reporte el siguiente error a TI: ${message}`,
      );
    } finally {
      dispatch(flagGettingBaseFuelRefill(false));
      dispatch(flagGettingFuelVouchers(false));
      dispatch(flagFuelReportPreviewActivity(false));
    }
  };

const clearFuelReportPreview = () => (dispatch) =>
  dispatch({
    type: CLEAR_FUEL_REPORT_PREVIEW,
  });

export { getFuelReportPreview, clearFuelReportPreview };
