import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText } from 'reactstrap';
import AddressSelect from '../select/AddressSelect';
import { optionsPropTypes } from '../select/SelectPropTypes';

const AddressField = ({
  fieldName,
  onChange,
  noOptionsMessage,
  isDisabled,
  validate,
  placeholder,
  isClearable,
  options,
}) => (
  <>
    <Field
      name={fieldName}
      component={AddressSelect}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled}
      validate={validate}
      placeholder={placeholder}
      isClearable={isClearable}
      options={options}
    />
    <FormText>Ingresa mínimo 4 caracteres</FormText>
  </>
);

AddressField.propTypes = {
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
  isClearable: PropTypes.bool,
  options: optionsPropTypes,
};

AddressField.defaultProps = {
  fieldName: '',
  onChange: undefined,
  noOptionsMessage: undefined,
  isDisabled: false,
  placeholder: 'Selecciona ...',
  validate: undefined,
  isClearable: true,
  options: [],
};

export default AddressField;
