import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import Modal from '../../../../common/modal/Modal';
import ExtraFuelConsumptionForm from '../../extra-fuel-consumption/ExtraFuelConsumptionForm';
import {
  clearExtraFuelConsumption,
  postExtraFuelConsumption,
} from '../../../../../actions/traffic/ExtraFuelConsumption';
import { tzNormalizeDate } from '../../../../../utils/date';
import {
  DATE_TIME_FORMAT,
  TIMESTAMP_FORMAT,
} from '../../../../../config/locale';
import Alert from '../../../../common/informative/Alert';
import { EXPECTED_FUEL_CONSUMPTION_PATH } from '../../../../../config/paths';

export class ExtraConsumptionToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatchClearExtraFuelConsumption();
  }

  onSubmitExtraFuelConsumption = async ({
    comment,
    adblueGallons,
    fuelGallons,
    createDate,
  }) => {
    const {
      itinerary: { id: itineraryId },
      dispatchPostExtraFuelConsumption,
      onSubmitExtraConsumption,
    } = this.props;

    const newFormValues = {
      fuelGallons,
      adblueGallons,
      comment,
      itineraryId,
      createDate: tzNormalizeDate({
        date: createDate,
        format: TIMESTAMP_FORMAT,
      }),
    };

    const extraConsumption = await dispatchPostExtraFuelConsumption(
      newFormValues,
    );

    if (extraConsumption) {
      this.closeModal();

      onSubmitExtraConsumption();
    }
  };

  closeModal = () => this.setState({ showModal: false });

  showModal = () => this.setState({ showModal: true });

  renderModal = () => {
    const { itinerary } = this.props;

    if (!itinerary) {
      return null;
    }

    const {
      id,
      route: { name: routeName },
      departureTime,
    } = itinerary;

    const { showModal } = this.state;

    const itineraryInfo = (
      <Row>
        <Col sm={2}>
          <strong>ID</strong>
        </Col>
        <Col sm={10}>{id}</Col>
        <Col sm={2}>
          <strong>Ruta</strong>
        </Col>
        <Col sm={10}>{routeName}</Col>
        <Col sm={2}>
          <strong>Fecha</strong>
        </Col>
        <Col sm={10}>
          {tzNormalizeDate({ date: departureTime, format: DATE_TIME_FORMAT })}
        </Col>
      </Row>
    );

    const modalBody = (
      <Fragment>
        <p>Usted ha seleccionado el siguiente itinerario:</p>
        <Alert type="info" message={itineraryInfo} />
        <p>A continuación puede registrar la información de consumo extra</p>
        <ExtraFuelConsumptionForm
          onSubmit={this.onSubmitExtraFuelConsumption}
          onModal
        />
      </Fragment>
    );

    return (
      <Modal
        show={showModal}
        title="Registrar consumo extra de Itinerario"
        onClickClose={this.closeModal}
        body={modalBody}
      />
    );
  };

  render() {
    const { itinerary } = this.props;

    let newExtraConsumptionButton = null;

    if (itinerary) {
      newExtraConsumptionButton = (
        <Button type="button" color="primary" outline onClick={this.showModal}>
          Registrar consumo extra
        </Button>
      );
    }

    const modal = this.renderModal();

    return (
      <Fragment>
        <ButtonToolbar className="pull-right">
          <div>
            {newExtraConsumptionButton}{' '}
            <Link
              target="_blank"
              className="btn btn-outline-secondary"
              to={EXPECTED_FUEL_CONSUMPTION_PATH}
            >
              Registrar Dotación
            </Link>
          </div>
        </ButtonToolbar>
        {modal}
      </Fragment>
    );
  }
}

ExtraConsumptionToolbar.propTypes = {
  itinerary: PropTypes.shape({
    id: PropTypes.number.isRequired,
    route: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    departureTime: PropTypes.number.isRequired,
  }),
  dispatchPostExtraFuelConsumption: PropTypes.func.isRequired,
  dispatchClearExtraFuelConsumption: PropTypes.func.isRequired,
  onSubmitExtraConsumption: PropTypes.func.isRequired,
};

ExtraConsumptionToolbar.defaultProps = {
  itinerary: null,
};

const mapDispatchToProps = {
  dispatchClearExtraFuelConsumption: clearExtraFuelConsumption,
  dispatchPostExtraFuelConsumption: postExtraFuelConsumption,
};

export default connect(null, mapDispatchToProps)(ExtraConsumptionToolbar);
