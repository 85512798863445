export const POSTPAID_BOOKING_CHANGE_STEP = 'POSTPAID_BOOKING_CHANGE_STEP';
export const POSTPAID_BOOKING_FLAG_SEARCHING_FOR_ITINERARIES =
  'POSTPAID_BOOKING_FLAG_SEARCHING_FOR_ITINERARIES';
export const POSTPAID_BOOKING_SEARCH_FOR_ITINERARIES =
  'POSTPAID_BOOKING_SEARCH_FOR_ITINERARIES';
export const POSTPAID_BOOKING_FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS =
  'POSTPAID_BOOKING_FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS';
export const POSTPAID_BOOKING_SELECT_SEAT = 'POSTPAID_BOOKING_SELECT_SEAT';
export const POSTPAID_BOOKING_DESELECT_SEAT = 'POSTPAID_BOOKING_DESELECT_SEAT';
export const POSTPAID_BOOKING_SELECT_ITINERARY =
  'POSTPAID_BOOKING_SELECT_ITINERARY';
export const POSTPAID_BOOKING_CLEAR_SELECT_ITINERARY =
  'POSTPAID_BOOKING_CLEAR_SELECT_ITINERARY';
export const POSTPAID_BOOKING_CLEAR_ITINERARY =
  'POSTPAID_BOOKING_CLEAR_ITINERARY';
export const POSTPAID_BOOKING_CLEAR_SEATS = 'POSTPAID_BOOKING_CLEAR_SEATS';
export const POSTPAID_BOOKING_FLAG_POSTING_RESERVATION =
  'POSTPAID_BOOKING_FLAG_POSTING_RESERVATION';
export const POSTPAID_BOOKING_POST_RESERVATION_REQUESTS =
  'POSTPAID_BOOKING_POST_RESERVATION_REQUESTS';
export const POSTPAID_BOOKING_GET_ITINERARY_FOOD_GROUP =
  'POSTPAID_BOOKING_GET_ITINERARY_FOOD_GROUP';
export const POSTPAID_BOOKING_FLAG_POSTING_PRETRANSACTION =
  'POSTPAID_BOOKING_FLAG_POSTING_PRETRANSACTION';
export const POSTPAID_BOOKING_POST_PRETRANSACTION =
  'POSTPAID_BOOKING_POST_PRETRANSACTION';

// steps reset
export const POSTPAID_BOOKING_RESET_STEP = 'POSTPAID_BOOKING_RESET_STEP';
export const POSTPAID_BOOKING_FLAG_DELETING_CUSTOMER_ORDER =
  'POSTPAID_BOOKING_FLAG_DELETING_CUSTOMER_ORDER';

// get all
export const POSTPAID_BOOKING_FLAG_GETTING_ALL =
  'POSTPAID_BOOKING_FLAG_GETTING_ALL';
export const POSTPAID_BOOKING_GET_ALL = 'POSTPAID_BOOKING_GET_ALL';
export const POSTPAID_BOOKING_CLEAR_ALL = 'POSTPAID_BOOKING_CLEAR_ALL';
export const POSTPAID_BOOKINGS_REPLACE_ONE = 'POSTPAID_BOOKINGS_REPLACE_ONE';

// approve-reject
export const POSTPAID_BOOKING_FLAG_APPROVING =
  'POSTPAID_BOOKING_FLAG_APPROVING';
export const POSTPAID_BOOKING_FLAG_REJECTING =
  'POSTPAID_BOOKING_FLAG_REJECTING';
export const FLAG_ADDING_VOUCHER_TO_POSTPAID_BOOKING =
  'FLAG_ADDING_VOUCHER_TO_POSTPAID_BOOKING';

export const FLAG_GETTING_PRETRANSACTION_FOR_POSTPAID_BOOKING =
  'FLAG_GETTING_PRETRANSACTION_FOR_POSTPAID_BOOKING';
export const POSTPAID_BOOKING_GET_PRETRANSACTION =
  'POSTPAID_BOOKING_GET_PRETRANSACTION';

export const FLAG_POSTPAID_BOOKING_RESERVATION_CREATED =
  'FLAG_POSTPAID_BOOKING_RESERVATION_CREATED';

// fixed price
export const FLAG_POSTING_FIX_PRICE_REQUEST_FOR_POSTPAID_BOOKING =
  'FLAG_POSTING_FIX_PRICE_REQUEST_FOR_POSTPAID_BOOKING';
export const SET_FIXED_PRICE_FOR_CURRENT_POSTPAID_BOOKING =
  'SET_FIXED_PRICE_FOR_CURRENT_POSTPAID_BOOKING';
