import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { printFuelVoucher } from '../../../../../src/utils/printers/FuelVoucher';
import {
  getPreviewFuelVoucher,
  postPrintFuelVoucher,
} from '../../../../actions';
import Modal from '../../../common/modal/Modal';
import PreviewFuelVoucher from './PreviewFuelVoucher';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generateGetPreviewFuelVoucherEndpoint } from '../../../../config/endpoints';
import { GET } from '../../../../config/permissions';

class PrintFuelVoucherButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalBody: null,
      loading: false,
    };
  }

  onClickGenerateFuelVoucher = async () => {
    const { itinerary, dispatchPostPrintFuelVoucher } = this.props;

    const fuelVouchers = await dispatchPostPrintFuelVoucher({
      itineraryId: itinerary.get('id'),
    });

    if (fuelVouchers) {
      printFuelVoucher(fuelVouchers);
    }
  };

  onClickPreview = async () => {
    this.setState({ loading: true });

    const { itinerary, dispatchGetPreviewFuelVoucher } = this.props;

    const fuelVouchers = await dispatchGetPreviewFuelVoucher({
      itineraryId: itinerary.get('id'),
    });

    this.setState({ loading: false });

    if (fuelVouchers) {
      const modalBody = <PreviewFuelVoucher fuelVouchers={fuelVouchers} />;

      this.setState({ showModal: true, modalBody });
    }
  };

  render() {
    const { showModal, modalBody, loading } = this.state;

    const loadingIcon = loading ? (
      <i className="fa fa-spinner fa-spin fa-fw" />
    ) : (
      <i className="fa fa-print" />
    );

    return (
      <>
        <Modal
          show={showModal}
          title="Vista previa - Vales de Combustible"
          body={modalBody}
          onClickConfirm={() => this.onClickGenerateFuelVoucher()}
          onClickCancel={() => this.setState({ showModal: false })}
          confirmButtonText="Generar Vales de Combustible"
          cancelButtonText="Cancelar"
          size="md"
        />
        <Button color="light" className="border" onClick={this.onClickPreview}>
          {loadingIcon} Vales de combustible
        </Button>
      </>
    );
  }
}

PrintFuelVoucherButton.propTypes = {
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPostPrintFuelVoucher: PropTypes.func.isRequired,
  dispatchGetPreviewFuelVoucher: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchPostPrintFuelVoucher: postPrintFuelVoucher,
  dispatchGetPreviewFuelVoucher: getPreviewFuelVoucher,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintFuelVoucherButton);

export default withEndpointAuthorization({
  url: generateGetPreviewFuelVoucherEndpoint(0),
  permissionType: GET,
})(connectedComponent);
