import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import PaymentMethodForSecurityProfileForm from './PaymentMethodForSecurityProfileForm';
import {
  clearPaymentMethodForSecurityProfile,
  getPaymentMethodForSecurityProfile,
  putPaymentMethodForSecurityProfile,
} from '../../../../actions/security/PaymentMethodForSecurityProfile';

const EditPaymentMethodForSecurityProfile = ({
  breadcrumbs,
  dispatchClearPaymentMethodForSecurityProfile,
  dispatchGetPaymentMethodForSecurityProfile,
  dispatchPutPaymentMethodForSecurityProfile,
  match: {
    params: { id: paymentMethodForSecurityProfileId },
  },
  loading,
  paymentMethodForSecurityProfile,
}) => {
  useLayoutEffect(() => {
    dispatchGetPaymentMethodForSecurityProfile({
      paymentMethodForSecurityProfileId,
    });

    return () => dispatchClearPaymentMethodForSecurityProfile();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutPaymentMethodForSecurityProfile(
      paymentMethodForSecurityProfileId,
      {
        securityProfileId: formValues.securityProfileId.value,
        paymentMethodId: formValues.paymentMethodId.value,
      },
    );

  const generateInitialValues = ({ paymentMethod, securityProfile }) => ({
    securityProfileId: {
      value: securityProfile.id,
      label: securityProfile.name,
    },
    paymentMethodId: {
      value: paymentMethod.id,
      label: paymentMethod.name,
    },
  });

  let content;

  if (loading) content = <Loader />;
  else if (paymentMethodForSecurityProfile.isEmpty())
    content = (
      <NoDataResource returnPage={PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH} />
    );
  else
    content = (
      <PaymentMethodForSecurityProfileForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(
          paymentMethodForSecurityProfile.toJS(),
        )}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Metodos de Pago por Perfil de Seguridad"
      subtitle="Editar metodos de pago por perfil de seguridad"
      content={content}
    />
  );
};

EditPaymentMethodForSecurityProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearPaymentMethodForSecurityProfile: PropTypes.func.isRequired,
  dispatchGetPaymentMethodForSecurityProfile: PropTypes.func.isRequired,
  dispatchPutPaymentMethodForSecurityProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  paymentMethodForSecurityProfile: PropTypes.instanceOf(Immutable.Map)
    .isRequired,
};

EditPaymentMethodForSecurityProfile.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearPaymentMethodForSecurityProfile:
    clearPaymentMethodForSecurityProfile,
  dispatchGetPaymentMethodForSecurityProfile:
    getPaymentMethodForSecurityProfile,
  dispatchPutPaymentMethodForSecurityProfile:
    putPaymentMethodForSecurityProfile,
};

const mapStateToProps = (
  { SecurityUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SecurityUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Metodos de Pago Por Perfiles de Seguridad',
      href: PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: `${PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  paymentMethodForSecurityProfile:
    SecurityUnit.PaymentMethodForSecurityProfile.getIn(['current', 'content']),
  loading: !SecurityUnit.PaymentMethodForSecurityProfile.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPaymentMethodForSecurityProfile);
