import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ItinerarySearchForm from '../../itinerary/itinerary/ItinerarySearchForm';
import {
  getTicketsForMassivePostpone,
  clearTickets,
  postTicketMassivePostpone,
} from '../../../../actions';
import { DEFAULT_QUERY } from '../../../../config/queries';
import TicketMassivePostponeSearchResultForm from './TicketMassivePostponeSearchResultForm';
import { tzNormalizeDate } from '../../../../utils/date';

export class TicketMassivePostponeSearch extends Component {
  componentWillUnmount() {
    this.props.dispatchClearTickets();
  }

  onSearchTickets = (formValues) => {
    const newFormValues = {
      toDate: new Date(formValues.toDateString),
      fromDate: new Date(formValues.fromDateString),
      ...DEFAULT_QUERY,
      size: 1000,
    };

    if (formValues.sourceLocationId)
      newFormValues.sourceLocationId = formValues.sourceLocationId.value;

    if (formValues.destinationLocationId)
      newFormValues.destinationLocationId =
        formValues.destinationLocationId.value;

    const { dispatchClearTickets, dispatchGetTicketsForMassivePostpone } =
      this.props;

    dispatchClearTickets();
    dispatchGetTicketsForMassivePostpone(newFormValues);
  };

  onSubmit = (formValues) => {
    const newFormValues = {
      comment: formValues.comment,
      ticketIdList: formValues.items,
    };
    this.props.dispatchPostTicketMassivePostpone(newFormValues);
  };

  render() {
    const date = tzNormalizeDate();

    return (
      <div>
        <ItinerarySearchForm
          onSubmit={this.onSearchTickets}
          initialValues={{
            fromDateString: date,
            toDateString: date,
          }}
          searchByDateRange
          sourceLocationIsRequired={false}
          destinationLocationIsRequired={false}
        />
        <TicketMassivePostponeSearchResultForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}

TicketMassivePostponeSearch.propTypes = {
  dispatchGetTicketsForMassivePostpone: PropTypes.func.isRequired,
  dispatchClearTickets: PropTypes.func.isRequired,
  dispatchPostTicketMassivePostpone: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetTicketsForMassivePostpone: getTicketsForMassivePostpone,
  dispatchClearTickets: clearTickets,
  dispatchPostTicketMassivePostpone: postTicketMassivePostpone,
};

export default connect(null, mapDispatchToProps)(TicketMassivePostponeSearch);
