import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generateEditPurchaseOrderPath } from '../../../../config/paths';

const EditPurchaseOrderButton = ({ purchaseOrderId }) => (
  <Link
    className="btn btn-primary"
    to={generateEditPurchaseOrderPath(purchaseOrderId)}
  >
    Editar
  </Link>
);

EditPurchaseOrderButton.propTypes = {
  purchaseOrderId: PropTypes.number.isRequired,
};

export default EditPurchaseOrderButton;
