import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Button, Col, Row, FormGroup } from 'reactstrap';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import { TICKETS_COLUMNS } from '../../../../config/columns';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';

const TicketMassivePostponeSearchResultForm = ({
  handleSubmit,
  loading,
  tickets,
}) => {
  const data = tickets.get('content');

  return (
    <Form onSubmit={handleSubmit} className="mt-3">
      <h3>Seleccione los boletos que desee postergar</h3>
      <FormGroup>
        <SelectionableTable
          columns={TICKETS_COLUMNS}
          data={data}
          defaultPageSize={10}
          keyField="id"
          loading={loading}
          form="TicketMassivePostponeSearchResultForm"
          required
          returnOnlySelectedItems
          filterable
        />
      </FormGroup>
      <FormGroup row>
        <FormItem label="Comentario" required>
          <Field
            name="comment"
            component={TextInput}
            type="textarea"
            placeholder="Comentario"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <Row className="mt-5">
        <Col className="flex row-reverse">
          <Button type="submit" color="primary">
            Postergar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

TicketMassivePostponeSearchResultForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  tickets: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
};

TicketMassivePostponeSearchResultForm.defaultProps = {
  loading: false,
  tickets: null,
};

const formComponent = reduxForm({
  form: 'TicketMassivePostponeSearchResultForm',
})(TicketMassivePostponeSearchResultForm);

const mapStateToProps = ({ ReservationUnit }) => ({
  tickets: ReservationUnit.Ticket.getIn(['all', 'content']),
  loading: ReservationUnit.Ticket.getIn(['all', 'loading']),
});

export default connect(mapStateToProps)(formComponent);
