import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { patchStateFuelProfile } from './config/fetch';

class SwitchStateFuelProfileButton extends Component {
  state = {
    status: false,
    disabled: false,
  };

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const { onChangeState, isActive } = this.props;
    onChangeState(isActive);
    this.setState({ status: isActive });
  };

  handleChange = async event => {
    const { fuelProfileId, onChangeState } = this.props;
    const {
      target: { checked },
    } = event;
    this.setState({ disabled: true });
    const fuelProfile = await patchStateFuelProfile({
      fuelProfileId,
      active: checked,
    });
    this.setState({ disabled: false });
    if (fuelProfile) {
      onChangeState(checked);
      this.setState({ status: checked });
    }
  };

  render() {
    const { disabled, status } = this.state;
    return (
      <div style={{ display: 'inline' }}>
        <Switch
          color="primary"
          disabled={disabled}
          checked={status}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

SwitchStateFuelProfileButton.propTypes = {
  fuelProfileId: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onChangeState: PropTypes.func.isRequired,
};

export default SwitchStateFuelProfileButton;
