import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateEndDate } from '../../../../utils/validators';
import DatePicker from '../../../common/forms/input/DatePicker';
import FormFooter from '../../../common/forms/FormFooter';

const validateDate = (value, allValues) =>
  validateEndDate(value, allValues.fromDateString);

export const ParcelSearchForm = ({ handleSubmit }) => {
  const formProps = { onSubmit: handleSubmit };
  return (
    <Form {...formProps}>
      <FormGroup row>
        <FormItem label="Desde" required>
          <Field
            name="fromDateString"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Hasta" required>
          <Field
            name="toDateString"
            component={DatePicker}
            validate={[isRequired, validateDate]}
          />
        </FormItem>
      </FormGroup>
      <Row>
        <Col className="flex row-reverse">
          <FormFooter
            saveButtonText="Buscar"
            saveButtonIcon="fa fa-search"
            saveButtonColor="secondary"
          />
        </Col>
      </Row>
    </Form>
  );
};

ParcelSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    fromDateString: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    departureDayString: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }),
};

ParcelSearchForm.defaultProps = {
  initialValues: {
    departureDayString: null,
  },
};

export default reduxForm({
  form: 'ParcelSearchForm',
})(ParcelSearchForm);
