import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BUS_ACTIVITY_ASSIGNMENT_PATH } from '../../../../config/paths';
import { deleteBusActivityAssignment } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import BusActivityAssigmentForm from './BusActivityAssignmentForm';

export const BusActivityUnassignment = ({
  breadcrumbs,
  dispatchGetDeleteActivityAssignment,
}) => {
  const content = (
    <BusActivityAssigmentForm
      onSubmit={dispatchGetDeleteActivityAssignment}
      unassign
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Desasignación de Actividades a Buses"
      subtitle="Puede seleccionar los buses y las actividades para desasignarlas"
      content={content}
    />
  );
};

BusActivityUnassignment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDeleteActivityAssignment: PropTypes.func.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignaciones de Actividades a Buses',
      href: BUS_ACTIVITY_ASSIGNMENT_PATH,
    },
    {
      text: 'Desasignar',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchGetDeleteActivityAssignment: deleteBusActivityAssignment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusActivityUnassignment);
