import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { Table } from '../../../common/Table';
import { PURCHASE_ORDER_DETAILS_COLUMNS_TABLE } from '../../../../config/columns';
import { PURCHASE_ORDER_BREADCRUMBS } from '../../../../config/breadcrumbs';
import ContainerComponent from '../../../common/resource/ContainerComponent';
import { getPurchaseOrder } from './config/fetch';
import PurchaseOrderToolBar from './PurchaseOrderToolBar';
import { PURCHASE_ORDER_STATUS } from '../../../../config/constants';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import EnumStatus from '../../../common/EnumStatus';
import { numberFormatter } from '../../../../utils/number';

const PurchaseOrder = ({
  payload: {
    id,
    documentSeries,
    documentCode,
    totalAmount,
    comments,
    purchaseOrderDetailList,
    status,
    createDate,
    lastUpdate,
  },
}) => {
  const content = (
    <Fragment>
      <ResourceProperty label="Documento:">
        {`${documentSeries}-${documentCode}`}
      </ResourceProperty>
      <ResourceProperty label="Monto Total:">
        {numberFormatter({ value: totalAmount })}
      </ResourceProperty>
      <ResourceProperty label="Estado:">
        <EnumStatus enumObject={PURCHASE_ORDER_STATUS} value={status} />
      </ResourceProperty>
      <ResourceProperty label="Comentarios:">{comments}</ResourceProperty>
      <Table
        columns={PURCHASE_ORDER_DETAILS_COLUMNS_TABLE}
        data={purchaseOrderDetailList}
        showPagination={false}
        defaultPageSize={purchaseOrderDetailList.length}
      />
      <InternalResource
        id={id}
        createDate={createDate}
        lastUpdate={lastUpdate}
      />
    </Fragment>
  );
  return (
    <Content
      breadcrumbs={PURCHASE_ORDER_BREADCRUMBS}
      title="Orden de Compra"
      toolbar={
        <PurchaseOrderToolBar
          purchaseOrderId={id}
          status={status}
          comments={comments}
        />
      }
      content={content}
    />
  );
};

PurchaseOrder.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.number.isRequired,
    documentSeries: PropTypes.string.isRequired,
    documentCode: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    comments: PropTypes.string.isRequired,
    purchaseOrderDetailList: PropTypes.arrayOf(
      PropTypes.shape({
        fuelVoucher: PropTypes.shape({
          documentSeries: PropTypes.string.isRequired,
          documentCode: PropTypes.string.isRequired,
        }),
      }),
    ),
    status: PropTypes.string,
    createDate: PropTypes.number,
    lastUpdate: PropTypes.number,
  }).isRequired,
};

export default ContainerComponent({ getFunction: getPurchaseOrder })(
  PurchaseOrder,
);
