import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import GeneralInconeEditButton from './GeneralIncomeEditButton';
import DepositDeleteButton from './GeneralIncomeDeleteButton';

const GeneralIncomeToolbar = ({ generalIncomeId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <GeneralInconeEditButton generalIncomeId={generalIncomeId} />{' '}
      <DepositDeleteButton generalIncomeId={generalIncomeId} />
    </div>
  </ButtonToolbar>
);

GeneralIncomeToolbar.propTypes = {
  generalIncomeId: PropTypes.number.isRequired,
};

export default GeneralIncomeToolbar;
