// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { isErrorResponse } from '../../utils/error-handlers';
import { CALL_CENTER_REPORT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import {
  GET_CALL_CENTER_REPORTS,
  FLAG_GETTING_CALL_CENTER_REPORTS,
  CLEAR_ERROR_GETTING_CALL_CENTER_REPORTS,
  CLEAR_CALL_CENTER_REPORTS,
  CLEAR_ERROR_POSTING_CALL_CENTER_REPORT,
  POST_CALL_CENTER_REPORT,
  FLAG_POSTING_CALL_CENTER_REPORT,
  DOWNLOAD_CALL_CENTER_REPORT,
  GET_CALL_CENTER_REPORT,
  CLEAR_ERROR_GETTING_CALL_CENTER_REPORT,
  FLAG_GETTING_CALL_CENTER_REPORT,
  CLEAR_CALL_CENTER_REPORT,
} from '../types/index';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { getReportsServerEndpoint } from './Report';
import { CALL_CENTER_REPORTS_PATH } from '../../config/paths';
import { validateReportDateRange } from '../../utils/validators';

const flagGettingCallCenterReports = flag => dispatch =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_CALL_CENTER_REPORTS,
  });

const clearErrorGettingCallCenterReports = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR_GETTING_CALL_CENTER_REPORTS,
  });

const getCallCenterReports = async ({
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
} = {}) => async (dispatch, getState) => {
  try {
    dispatch(flagGettingCallCenterReports(true));

    const reportsServerEndpoint = await getReportsServerEndpoint(
      dispatch,
      getState,
    );
    // make request
    const url = reportsServerEndpoint + CALL_CENTER_REPORT_ENDPOINT;
    const query = {
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      DEFAULT_GET_CONFIG,
    );

    await isErrorResponse(response);
    const reports = await response.json();
    dispatch({
      type: GET_CALL_CENTER_REPORTS,
      payload: reports,
    });
  } catch (err) {
    console.error(err);
    toastr.error('Error',err);
  } finally {
    dispatch(flagGettingCallCenterReports(false));
  }
};

const clearCallCenterReports = () => dispatch =>
  dispatch({
    type: CLEAR_CALL_CENTER_REPORTS,
  });

const clearErrorPostingCallCenterReport = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR_POSTING_CALL_CENTER_REPORT,
  });

const flagPostingCallCenterReport = flag => dispatch =>
  dispatch({
    type: FLAG_POSTING_CALL_CENTER_REPORT,
    payload: flag,
  });

const postCallCenterReport = async ({ fromDate, toDate, force }) => async (
  dispatch,
  getState,
) => {
  dispatch(clearErrorPostingCallCenterReport());

  // validate before raising the flag and outside the
  // try/catch so that the error is caught by redux form
  validateReportDateRange(fromDate, toDate);

  try {
    // raise flag
    dispatch(flagPostingCallCenterReport(true));

    const reportsServerEndpoint = await getReportsServerEndpoint(
      dispatch,
      getState,
    );
    const query = {
      force,
    };
    // make request
    const url = reportsServerEndpoint + CALL_CENTER_REPORT_ENDPOINT;
    const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify({
        fromDate,
        toDate,
      }),
    });
    await isErrorResponse(response);

    const report = await response.json();
    dispatch({
      type: POST_CALL_CENTER_REPORT,
      payload: report,
    });
    // redirect to report
    dispatch(push(`${CALL_CENTER_REPORTS_PATH}/${report.id}`));
  } catch (err) {
    console.error(err);
    toastr.error('Error',err);
  } finally {
    dispatch(flagPostingCallCenterReport(false));
  }
};

const flagGettingCallCenterReport = flag => dispatch =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_CALL_CENTER_REPORT,
  });

const clearErrorGettingCallCenterReport = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR_GETTING_CALL_CENTER_REPORT,
  });

const getCallCenterReport = async ({
  id,
  reportFormat = 'json',
  download = false,
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
}) => async (dispatch, getState) => {
  try {
    dispatch(flagGettingCallCenterReport(true));

    const reportsServerEndpoint = await getReportsServerEndpoint(
      dispatch,
      getState,
    );
    // make request
    const url = `${reportsServerEndpoint + CALL_CENTER_REPORT_ENDPOINT}/${id}`;
    const query = {
      reportFormat,
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      DEFAULT_GET_CONFIG,
    );

    await isErrorResponse(response);
    // parse json or text depending on format
    const text = reportFormat === 'csv';
    const report = text ? await response.text() : await response.json();
    if (download) {
      dispatch({
        type: DOWNLOAD_CALL_CENTER_REPORT,
        payload: report,
      });
    } else {
      dispatch({
        type: GET_CALL_CENTER_REPORT,
        payload: report,
      });
    }
  } catch (err) {
    console.error(err);
    toastr.error('Error',err);
  } finally {
    dispatch(flagGettingCallCenterReport(false));
  }
};

const clearCallCenterReport = () => dispatch =>
  dispatch({
    type: CLEAR_CALL_CENTER_REPORT,
  });

export {
  getCallCenterReports,
  clearErrorGettingCallCenterReports,
  clearCallCenterReports,
  clearErrorPostingCallCenterReport,
  postCallCenterReport,
  getCallCenterReport,
  clearCallCenterReport,
  clearErrorGettingCallCenterReport,
};
