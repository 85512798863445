import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { Container, Row, Col } from 'reactstrap';
import Immutable from 'immutable';
import CostSummary from './CostSummary';
import PaymentForm from './PaymentForm';
import Loader from '../../../common/Loader';
import { clearBookingState } from '../../../../actions';
import { SEARCH_STEP_PATH } from '../../../../config/paths';
import { BOOKING_PAYMENT_STEP_INDEX } from '../../../../config/constants';

// todo: remove when class is complete
class Payment extends Component {
  static propTypes = {
    trip: PropTypes.shape({
      source: PropTypes.string.isRequired,
      destination: PropTypes.string.isRequired,
      departureTime: PropTypes.number.isRequired,
    }).isRequired,
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        idDocumentNumber: PropTypes.string.isRequired,
        salePrice: PropTypes.number.isRequired,
      }),
    ).isRequired,
    totalPrice: PropTypes.number.isRequired,
    creditNote: PropTypes.instanceOf(Immutable.Map).isRequired,
    seatsNumber: PropTypes.number.isRequired,
    postingPayment: PropTypes.bool.isRequired,
    dispatchClearBookingState: PropTypes.func.isRequired,
    dispatchReplace: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { dispatchReplace, seatsNumber, dispatchClearBookingState } = props;

    if (seatsNumber === 0) {
      dispatchReplace(SEARCH_STEP_PATH);
    }

    dispatchClearBookingState(BOOKING_PAYMENT_STEP_INDEX);
  }

  render() {
    const { trip, passengers, totalPrice, creditNote, postingPayment } =
      this.props;

    if (!trip) {
      return null;
    }

    if (postingPayment) {
      return <Loader />;
    }

    return (
      <Container>
        <Row>
          <Col>
            <CostSummary
              costs={passengers}
              total={totalPrice}
              creditNote={creditNote}
            />
          </Col>
        </Row>
        <PaymentForm
          totalPrice={totalPrice}
          creditNote={this.props.creditNote}
          seatsNumber={this.props.seatsNumber}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const passengersTickets = state.BookingUnit.Booking.getIn([
    'payment',
    'customerOrder',
    'passengers',
  ]);

  let totalPrice = 0;

  if (passengersTickets)
    passengersTickets.forEach((passengerTicket) => {
      totalPrice += passengerTicket.salePrice;
    });

  return {
    postingPayment: state.BookingUnit.Booking.getIn([
      'payment',
      'postingPayment',
    ]),
    trip: state.BookingUnit.Booking.getIn(['payment', 'customerOrder', 'trip']),
    passengers: state.BookingUnit.Booking.getIn([
      'payment',
      'customerOrder',
      'passengers',
    ]),
    totalPrice,
    creditNote: state.BookingUnit.Booking.getIn(['payment', 'creditNote']),
    seatsNumber: state.BookingUnit.Booking.getIn([
      'seats',
      'seatReservations',
    ]).count(),
  };
};

const mapDispatchToProps = {
  dispatchReplace: replace,
  dispatchClearBookingState: clearBookingState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
