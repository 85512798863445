import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSalesSessionExpense, clearExpenses } from '../../../../actions';
import { SALES_SESSION_EXPENSE_COLUMNS } from '../../../../config/columns';
import { EXPENSE_PATH } from '../../../../config/paths';
import Table from '../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

class ExpenseTable extends Component {
  static propTypes = {
    salesSessionId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    dispatchGetSalesSessionExpense: PropTypes.func.isRequired,
    dispatchClearExpenses: PropTypes.func.isRequired,
    salesSessionExpense: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        documentSeries: PropTypes.string.isRequired,
        documentCode: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
      }),
    ),
  };

  static defaultProps = {
    salesSessionExpense: undefined,
  };

  constructor(props) {
    super(props);
    const { salesSessionId, dispatchGetSalesSessionExpense } = this.props;
    dispatchGetSalesSessionExpense({ salesSessionId });
  }

  componentWillUnmount() {
    const { dispatchClearExpenses } = this.props;
    dispatchClearExpenses();
  }

  render() {
    const { salesSessionExpense, loading } = this.props;

    return (
      <>
        <Table
          manual={false}
          columns={SALES_SESSION_EXPENSE_COLUMNS}
          data={salesSessionExpense}
          loading={loading}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
          navigateToModelOnRowClick
          modelPath={EXPENSE_PATH}
          openPathInNewTab
        />
        <br />
      </>
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => {
  const salesSessionExpense = SalesUnit.Expense.getIn([
    'all',
    'content',
    'content',
  ]);
  const loading = SalesUnit.Expense.getIn(['all', 'loading']);
  return {
    loading,
    salesSessionExpense,
  };
};

const mapDispatchToProps = {
  dispatchGetSalesSessionExpense: getSalesSessionExpense,
  dispatchClearExpenses: clearExpenses,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTable);
