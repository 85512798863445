import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import Alert from '../../../../common/informative/Alert';

const BillingStatusReport = ({ billings }) => (
  <>
    <h3>Reporte de Facturación</h3>
    <h5>Transportista</h5>
    <ResourceProperty label="Listo para Procesar:">
      {billings.billGrt.billReadyForProcess}
    </ResourceProperty>
    <ResourceProperty label="En Progreso:">
      {billings.billGrt.billInProgress}
    </ResourceProperty>
    <ResourceProperty label="Errores:">
      {billings.billGrt.billError}
    </ResourceProperty>
    <ResourceProperty label="Procesados:">
      {billings.billGrt.billProcessed}
    </ResourceProperty>
    <Alert message={billings.billGrt.message} type={billings.billGrt.status} />
    <h5>Remitente</h5>
    <ResourceProperty label="Listo para Procesar:">
      {billings.billGrr.billReadyForProcess}
    </ResourceProperty>
    <ResourceProperty label="En Progreso:">
      {billings.billGrr.billInProgress}
    </ResourceProperty>
    <ResourceProperty label="Errores:">
      {billings.billGrr.billError}
    </ResourceProperty>
    <ResourceProperty label="Procesados:">
      {billings.billGrr.billProcessed}
    </ResourceProperty>
    <Alert message={billings.billGrr.message} type={billings.billGrr.status} />
  </>
);

BillingStatusReport.propTypes = {
  billings: PropTypes.shape({
    billGrt: PropTypes.shape({
      billReadyForProcess: PropTypes.number.isRequired,
      billInProgress: PropTypes.number.isRequired,
      billProcessed: PropTypes.number.isRequired,
      billError: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
    billGrr: PropTypes.shape({
      billReadyForProcess: PropTypes.number.isRequired,
      billInProgress: PropTypes.number.isRequired,
      billProcessed: PropTypes.number.isRequired,
      billError: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default BillingStatusReport;
