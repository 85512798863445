import React, { Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import DropdownButton from '../../../../common/button/DropdownButton';
import {
  ITINERARY_SCHEDULE_EDIT_BUTTON,
  ITINERARY_SCHEDULE_EDIT_DROPDOWN_OPTIONS,
} from '../../../../../config/constants';
import {
  generateItineraryStatusEditPath,
  ITINERARY_SCHEDULE_PATH,
} from '../../../../../config/paths';

class ItineraryStatusEditDropdownButton extends Component {
  handleClick = async (value) => {
    const { dispatchPush, itineraryId } = this.props;

    switch (value) {
      case ITINERARY_SCHEDULE_EDIT_BUTTON.ITINERARY_SCHEDULE_EDIT.value: {
        dispatchPush(`${ITINERARY_SCHEDULE_PATH}/${itineraryId}/edit`);
        break;
      }
      case ITINERARY_SCHEDULE_EDIT_BUTTON.ITINERARY_STATUSSES_EDIT.value: {
        dispatchPush(generateItineraryStatusEditPath(itineraryId));
        break;
      }
      default:
        break;
    }
  };

  render() {
    return (
      <DropdownButton
        title="Editar"
        options={ITINERARY_SCHEDULE_EDIT_DROPDOWN_OPTIONS}
        handleClick={this.handleClick}
      />
    );
  }
}

ItineraryStatusEditDropdownButton.propTypes = {
  itineraryId: PropType.number.isRequired,
  dispatchPush: PropType.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPush: push,
};

export default connect(
  null,
  mapDispatchToProps,
)(ItineraryStatusEditDropdownButton);
