import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { USER_PATH } from '../../../../config/paths';
import { getUser, clearUser } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import UserToolbar from './UserToolbar';
import UserBasicInformationResource from './resource/UserBasicInformationResource';
import UserContactInformationResource from './resource/UserContactInformationResource';
import UserSecurityProfileResource from './resource/UserSecurityProfileResource';
import UserInternalResource from './resource/UserInternalResource';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import CustomerBasicInformationResource from '../customer/resource/CustomerBasicInformationResource';
import UserActivityResource from './resource/UserActivityResource';

class User extends Component {
  componentDidMount() {
    const {
      dispatchGetUser,
      match: {
        params: { id: userId },
      },
    } = this.props;
    dispatchGetUser({ userId });
  }

  componentWillUnmount() {
    const { dispatchClearUser } = this.props;
    dispatchClearUser();
  }

  render() {
    const { breadcrumbs, user, loading } = this.props;

    let content;
    let toolbar;

    if (loading) content = <Loader />;
    else if (user.isEmpty())
      content = <NoDataResource returnPage={USER_PATH} />;
    else {
      const userJson = user.toJS();

      content = (
        <Fragment>
          <Row>
            <Col md={6}>
              <h3>Información Básica</h3>
              <UserBasicInformationResource
                username={userJson.username}
                locale={userJson.locale}
              />
              <h3>Cliente</h3>
              <CustomerBasicInformationResource {...userJson.customer} />
              <h3>Información de Contacto</h3>
              <UserContactInformationResource {...userJson.customer} />
            </Col>
            <Col md={6}>
              <h3>Perfiles de Seguridad</h3>
              <UserSecurityProfileResource
                securityProfileSet={userJson.securityProfileSet}
              />
              <UserActivityResource {...userJson} />
              <br />
              <h3>Interno</h3>
              <UserInternalResource {...userJson} />
            </Col>
          </Row>
        </Fragment>
      );

      toolbar = <UserToolbar userId={userJson.id} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Usuario"
        content={content}
      />
    );
  }
}

User.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetUser: PropTypes.func.isRequired,
  dispatchClearUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  user: PropTypes.instanceOf(Immutable.Map).isRequired,
};

User.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios',
      href: USER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  user: UserUnit.User.getIn(['current', 'content']),
  loading: !UserUnit.User.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetUser: getUser,
  dispatchClearUser: clearUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
