import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getFuelVouchers, clearFuelVouchers } from '../../../../actions/index';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  FUEL_VOUCHER_PATH,
  NEW_FUEL_VOUCHER_PATH,
} from '../../../../config/paths';
import { FUEL_VOUCHER_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class FuelVouchers extends Component {
  componentWillUnmount() {
    const { dispatchClearFuelVouchers } = this.props;
    dispatchClearFuelVouchers();
  }

  render() {
    const { fuelVouchers, loading, dispatchGetFuelVouchers, breadcrumbs } =
      this.props;

    const data = fuelVouchers.get('content') || [];
    const pages = fuelVouchers.get('totalPages') || null;
    const defaultPageSize = fuelVouchers.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Vales de Combustible"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_FUEL_VOUCHER_PATH}
        tableStructure={{
          columns: FUEL_VOUCHER_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetFuelVouchers,
          modelPath: FUEL_VOUCHER_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Vales de Combustible',
      href: FUEL_VOUCHER_PATH,
    },
  ],
  fuelVouchers: TrafficUnit.FuelVoucher.getIn(['all', 'content']),
  loading: TrafficUnit.FuelVoucher.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetFuelVouchers: getFuelVouchers,
  dispatchClearFuelVouchers: clearFuelVouchers,
};

FuelVouchers.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetFuelVouchers: PropTypes.func.isRequired,
  dispatchClearFuelVouchers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fuelVouchers: PropTypes.instanceOf(Immutable.Map),
};

FuelVouchers.defaultProps = {
  fuelVouchers: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelVouchers);
