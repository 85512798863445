import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  privateServiceDataDefaultProps,
  privateServiceDataPropTypes,
} from '../proptypes/PrivateServicePropTypes';
import Checkbox from '../../../../common/forms/Checkbox';

const ServiceInformationResource = ({
  serviceConcept,
  contractNumber,
  numberOfBusses,
  includeFood,
}) => (
  <Fragment>
    <h3>Datos del Servicio</h3>
    <ResourceProperty label="Número de Contrato:">
      {contractNumber || '-'}
    </ResourceProperty>
    <ResourceProperty label="Cantidad de Unidades:">
      {numberOfBusses || '-'}
    </ResourceProperty>
    <ResourceProperty label="Con Alimentación:">
      <Checkbox checked={includeFood} />
    </ResourceProperty>
    <ResourceProperty label="Observación:">
      {serviceConcept || '-'}
    </ResourceProperty>
  </Fragment>
);

ServiceInformationResource.propTypes = privateServiceDataPropTypes;

ServiceInformationResource.defaultProps = privateServiceDataDefaultProps;

export default ServiceInformationResource;
