import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import { DATE_FORMAT, TIMESTAMP_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import { tzNormalizeDate } from '../../../../utils/date';
import CargoCorporateCreditForm from '../cargo-corporate-credit/CargoCorporateCreditForm';
import {
  clearCargoCorporateCredit,
  getCargoCorporateCredit,
  putCargoCorporateCredit,
} from '../../../../actions/cargo/CargoCorporateCredit';

export const EditCargoCorporateCredit = ({
  match: {
    params: { id: cargoCorporateCreditId },
  },
  dispatchGetCargoCorporateCredit,
  breadcrumbs,
  cargoCorporateCredit,
  loading,
  dispatchPutCargoCorporateCredit,
  dispatchClearCargoCorporateCredit,
}) => {
  useLayoutEffect(() => {
    dispatchGetCargoCorporateCredit({ cargoCorporateCreditId });

    return () => dispatchClearCargoCorporateCredit();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    newFormValues.id = cargoCorporateCredit.get('id');
    newFormValues.createDate = cargoCorporateCredit.get('createDate');

    if (formValues.customerList)
      newFormValues.customerList = formValues.customerList.map((customer) => ({
        id: customer.value,
      }));

    newFormValues.companyId = formValues.companyId
      ? formValues.companyId.value
      : null;

    newFormValues.actualBalance =
      cargoCorporateCredit.get('actualBalance') === 0 &&
      cargoCorporateCredit.get('initialBalance') === 0 &&
      formValues.initialBalance !== 0
        ? formValues.initialBalance
        : cargoCorporateCredit.get('actualBalance');

    newFormValues.lastUpdate = tzNormalizeDate({
      format: TIMESTAMP_FORMAT,
    });

    dispatchPutCargoCorporateCredit(cargoCorporateCreditId, newFormValues);
  };

  const generateInitialValues = (cargoCorporateCreditJson) => ({
    name: cargoCorporateCreditJson.name,
    description: cargoCorporateCreditJson.description,
    customerList: cargoCorporateCreditJson.customerList.map((customer) => ({
      value: customer.id,
      label: customer.fullName,
    })),
    companyId: {
      value: cargoCorporateCreditJson.company.id,
      label: cargoCorporateCreditJson.company.name,
    },
    initialBalance: cargoCorporateCreditJson.initialBalance,
    actualBalance: cargoCorporateCreditJson.actualBalance,
    cutoffDay: cargoCorporateCreditJson.cutoffDay,
    nextCutoffDate: cargoCorporateCreditJson.nextCutoffDate
      ? tzNormalizeDate({
          date: cargoCorporateCreditJson.nextCutoffDate,
          format: DATE_FORMAT,
        })
      : null,
    slack: cargoCorporateCreditJson.slack,
    active: cargoCorporateCreditJson.active,
    commission: cargoCorporateCreditJson.commission,
  });

  let content = null;

  if (loading) content = <Loader />;
  else if (cargoCorporateCredit.isEmpty())
    content = <NoDataResource returnPage={CARGO_CORPORATE_CREDIT_PATH} />;
  else
    content = (
      <CargoCorporateCreditForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(cargoCorporateCredit.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Crédito Corporativo de Carga"
      subtitle="Edita este crédito corporativo"
      content={content}
    />
  );
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Créditos Corporativos',
      href: CARGO_CORPORATE_CREDIT_PATH,
    },
    {
      text: 'Ver',
      href: `${CARGO_CORPORATE_CREDIT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  cargoCorporateCredit: CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoCorporateCredit: getCargoCorporateCredit,
  dispatchClearCargoCorporateCredit: clearCargoCorporateCredit,
  dispatchPutCargoCorporateCredit: putCargoCorporateCredit,
};

EditCargoCorporateCredit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchGetCargoCorporateCredit: PropTypes.func.isRequired,
  dispatchClearCargoCorporateCredit: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  cargoCorporateCredit: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditCargoCorporateCredit.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCargoCorporateCredit);
