export const FLAG_MANUAL_SENDER_REMISSION_GUIDE_ACTIVITY =
  'FLAG_MANUAL_SENDER_REMISSION_GUIDE_ACTIVITY';
export const FLAG_GETTING_MANUAL_SENDER_REMISSION_GUIDE =
  'FLAG_GETTING_MANUAL_SENDER_REMISSION_GUIDE';
export const GET_MANUAL_SENDER_REMISSION_GUIDES =
  'GET_MANUAL_SENDER_REMISSION_GUIDES';
export const CLEAR_MANUAL_SENDER_REMISSION_GUIDES =
  'CLEAR_MANUAL_SENDER_REMISSION_GUIDES';
export const GET_MANUAL_SENDER_REMISSION_GUIDE =
  'GET_MANUAL_SENDER_REMISSION_GUIDE';
export const CLEAR_MANUAL_SENDER_REMISSION_GUIDE =
  'CLEAR_MANUAL_SENDER_REMISSION_GUIDE';
