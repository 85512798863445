import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { numberFormatter } from '../../../../../utils/number';

const LiquidationCommissionResource = ({
  commission,
  commissionTaxes,
  amountPayable,
}) => {
  if (!commission && !commissionTaxes && !amountPayable) return null;

  let totalToBillResource = null;

  if (commission)
    totalToBillResource = (
      <ResourceProperty label="Total a Facturar:">
        {numberFormatter({ value: commission + (commissionTaxes || 0) })}
      </ResourceProperty>
    );

  return (
    <Fragment>
      <hr />
      <ResourceProperty label="Comisión:">
        {numberFormatter({ value: commission })}
      </ResourceProperty>
      <ResourceProperty label="IGV:">
        {numberFormatter({ value: commissionTaxes })}
      </ResourceProperty>
      {totalToBillResource}
      <h3>
        <strong>
          Total a pagar: {numberFormatter({ value: amountPayable || 0 })}
        </strong>
      </h3>
    </Fragment>
  );
};

LiquidationCommissionResource.propTypes = {
  commission: PropTypes.number,
  commissionTaxes: PropTypes.number,
  amountPayable: PropTypes.number,
};

LiquidationCommissionResource.defaultProps = {
  commission: null,
  commissionTaxes: null,
  amountPayable: null,
};

export default LiquidationCommissionResource;
