import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  putAddItemToItineraryV2,
  getManifestItemsByParcelCorrelation,
} from '../../../../../actions';
import AddCargoToItineraryByParcelResultForm from './AddCargoToItineraryByParcelResultForm';
import AddCargoToItineraryByParcelForm from './AddCargoToItineraryByParcelForm';

export const AddCargoToItineraryByParcelSearch = ({
  dispatchGetManifestItemsByParcelCorrelation,
  onClickAddItemToItinerary,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      documentSeries: formValues.parcelDocumentSerie,
      documentCode: formValues.parcelDocumentCorrelation,
    };

    dispatchGetManifestItemsByParcelCorrelation(newFormValues);
  };

  return (
    <div>
      <AddCargoToItineraryByParcelForm onSubmit={onSubmit} />
      <AddCargoToItineraryByParcelResultForm
        onSubmit={onClickAddItemToItinerary}
      />
    </div>
  );
};

AddCargoToItineraryByParcelSearch.propTypes = {
  dispatchGetManifestItemsByParcelCorrelation: PropTypes.func.isRequired,
  onClickAddItemToItinerary: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPutAddItemToItineraryV2: putAddItemToItineraryV2,
  dispatchGetManifestItemsByParcelCorrelation:
    getManifestItemsByParcelCorrelation,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddCargoToItineraryByParcelSearch);
