import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';
import { clearManifestItemsByParcelCorrelation } from '../../../../../actions';
import { MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS } from '../../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import FormFooter from '../../../../common/forms/FormFooter';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';

export const AddCargoToItineraryByParcelResultForm = ({
  loading,
  items,
  dispatchClearManifestItemsByParcelCorrelation,
  handleSubmit,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearManifestItemsByParcelCorrelation();
    },
    [],
  );

  const data = items.toJS() || [];

  return (
    <div className="mt-3">
      <Form onSubmit={handleSubmit}>
        <h5>Selecione las encomiendas a subir</h5>
        <SelectionableTable
          manual={false}
          name="itemsToAddCorrelations"
          columns={MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS}
          data={data}
          loading={loading}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
          returnOnlySelectedItems
          keyField="correlation"
          form="AddCargoToItineraryByParcelResultForm"
        />
        {data.length > 0 && <FormFooter saveButtonDisabled={loading} />}
      </Form>
    </div>
  );
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.ManifestCargo.getIn(['current', 'activity']).isEmpty(),
  items: CargoUnit.ManifestCargo.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchClearManifestItemsByParcelCorrelation:
    clearManifestItemsByParcelCorrelation,
};

AddCargoToItineraryByParcelResultForm.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  loading: PropTypes.bool,
  dispatchClearManifestItemsByParcelCorrelation: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

AddCargoToItineraryByParcelResultForm.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'AddCargoToItineraryByParcelResultForm',
})(AddCargoToItineraryByParcelResultForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
