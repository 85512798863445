import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { CardBody, CardText, CardTitle } from 'reactstrap';
import {
  MAINTENANCE_SERVICE_ORDER_PATH,
  MY_MAINTENANCE_SERVICE_ORDER_PATH,
} from '../../../../config/paths';
import {
  getMyMaintenanceServiceOrders,
  clearMaintenanceServiceOrders,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Board from '../../../common/board/Board';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import {
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
} from '../../../../config/constants';
import {
  maintenanceServiceOrderActivityBusExecutionListPropTypes,
  maintenanceServiceOrderBasicInformationPropTypes,
} from './prop-types/MaintenanceServiceOrderPropTypes';
import { userBasicInformationPropTypes } from '../../user/user/proptypes/UserPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { enumToSelectOptions } from '../../../../utils/enum';
import { orderBy } from '../../../../utils/array';
import MyMaintenanceServiceOrderSearchForm from './MyMaintenanceServiceOrderSearchForm';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

export const MyMaintenanceServiceOrders = ({
  breadcrumbs,
  serviceOrders,
  loading,
  dispatchGetMyMaintenanceServiceOrders,
  dispatchClearMaintenanceServiceOrders,
  authenticatedUser,
  dispatchPush,
}) => {
  const [columns, setColumns] = useState([]);

  useLayoutEffect(() => {
    dispatchGetMyMaintenanceServiceOrders({ ...DEFAULT_QUERY_GET_ALL });

    return () => dispatchClearMaintenanceServiceOrders();
  }, []);

  const onClickElement = (element) =>
    dispatchPush(`${MY_MAINTENANCE_SERVICE_ORDER_PATH}/${element.id}`);

  const generateElements = ({ services, status }) => {
    const foundServices = services.filter(
      ({ status: serviceStatus }) => serviceStatus === status,
    );
    const unsortedElements = foundServices.map((element) => ({
      id: element.id,
      body: (
        <CardBody>
          <CardTitle className="d-flex justify-content-between">
            <span>
              <i className="fa fa-hashtag" /> {element.id}
            </span>
            <span>
              {MAINTENANCE_SERVICE_ORDER_PRIORITY[element.priority].label}
            </span>
          </CardTitle>
          <CardText className="d-flex justify-content-between">
            <span className="text-muted">
              <i className="fa fa-clock-o" />{' '}
              {tzNormalizeDate({
                date: element.deadline,
                format: DATE_TIME_FORMAT,
              })}
            </span>
            <span className="text-muted">
              <i className="fa fa-bus" /> {element.registeredBus.companyBusId}
            </span>
          </CardText>
        </CardBody>
      ),
      onClickElement: () => onClickElement(element),
    }));
    // sort unsortedElements
    return orderBy(unsortedElements, ['id'], ['desc']);
  };

  useEffect(() => {
    if (columns.length === 0 && serviceOrders.length > 0) {
      const newColumns = enumToSelectOptions(
        MAINTENANCE_SERVICE_ORDER_STATUS,
      ).map((maintenanceServiceOrderStatus) => ({
        status: maintenanceServiceOrderStatus.label,
        elements: generateElements({
          services: serviceOrders,
          status: maintenanceServiceOrderStatus.value,
          user: authenticatedUser.customer.firstName,
        }),
      }));
      setColumns(newColumns);
    }
  }, [serviceOrders]);

  const onSearchServiceOrders = (formValues) => {
    setColumns([]);
    dispatchClearMaintenanceServiceOrders();

    const queryParams = { ...DEFAULT_QUERY_GET_ALL };

    if (formValues.companyBusId)
      queryParams.registeredBusId = formValues.companyBusId.value;

    dispatchGetMyMaintenanceServiceOrders(queryParams);
  };

  let content;
  if (loading) content = <Loader />;
  else if (serviceOrders.length === 0)
    content = (
      <Alert
        type="info"
        message="Actualmente no tiene órdenes de servicio asignadas"
      />
    );
  else content = <Board columns={columns} />;

  return (
    <Content
      title="Mis Ordenes de Servicio"
      breadcrumbs={breadcrumbs}
      content={
        <>
          <MyMaintenanceServiceOrderSearchForm
            onSubmit={onSearchServiceOrders}
          />
          {content}
        </>
      }
    />
  );
};

const mapDispatchToProps = {
  dispatchGetMyMaintenanceServiceOrders: getMyMaintenanceServiceOrders,
  dispatchClearMaintenanceServiceOrders: clearMaintenanceServiceOrders,
  dispatchPush: push,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit, authentication }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
  ],
  serviceOrders: MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'all',
    'content',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'all',
    'loading',
  ]),
  authenticatedUser: authentication.getIn(['user']),
});

MyMaintenanceServiceOrders.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  serviceOrders: PropTypes.arrayOf(
    PropTypes.shape({
      ...maintenanceServiceOrderBasicInformationPropTypes,
      ...maintenanceServiceOrderActivityBusExecutionListPropTypes,
    }),
  ),
  loading: PropTypes.bool,
  dispatchGetMyMaintenanceServiceOrders: PropTypes.func.isRequired,
  dispatchClearMaintenanceServiceOrders: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.shape(userBasicInformationPropTypes).isRequired,
  dispatchPush: PropTypes.func.isRequired,
};

MyMaintenanceServiceOrders.defaultProps = {
  serviceOrders: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyMaintenanceServiceOrders);
