import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateEndDate } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import { IN_SYSTEM_NOTIFICATION_SEVERITY } from '../../../../config/constants';
import DatePicker from '../../../common/forms/input/DatePicker';
import Alert from '../../../common/informative/Alert';
import FormFooter from '../../../common/forms/FormFooter';
import { tzNormalizeDate } from '../../../../utils/date';

export class InSystemNotificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      severity: null,
    };
  }

  componentDidMount() {
    this.didMount();
  }

  didMount = () => {
    const { initialValues } = this.props;

    if (initialValues.notificationSeverity && initialValues.message) {
      this.setState({
        message: initialValues.message,
        severity: initialValues.notificationSeverity.value,
      });
    }
  };

  validateDate = (value, allValues) =>
    validateEndDate(value, allValues.ValidFrom, true);

  render() {
    const { loading, handleSubmit } = this.props;

    if (loading) {
      return <Loader />;
    }

    const { message, severity } = this.state;

    const preview =
      message && severity ? (
        <Alert type={severity.toLowerCase()} message={message} />
      ) : (
        <i className="text-muted">Ingrese Mensaje y Seleccione Gravedad</i>
      );

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Mensaje" required>
            <Field
              name="message"
              component={TextInput}
              type="text"
              placeholder="Mensaje"
              onChange={(e) =>
                this.setState({ message: e.target.value || null })
              }
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Gravedad" required>
            <Field
              name="notificationSeverity"
              component={Select}
              options={enumToSelectOptions(IN_SYSTEM_NOTIFICATION_SEVERITY)}
              placeholder="Gravedad"
              onChange={(option) =>
                this.setState({ severity: option.value || null })
              }
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Válido Desde" required>
            <Field
              name="validFrom"
              props={{
                min: tzNormalizeDate(),
              }}
              time
              component={DatePicker}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Válido Hasta" required>
            <Field
              name="validUntil"
              props={{
                min: tzNormalizeDate(),
              }}
              time
              component={DatePicker}
              validate={[isRequired, this.validateDate]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Estado">
            <Label>
              <Field name="active" component="input" type="checkbox" /> Activo
            </Label>
          </FormItem>
        </FormGroup>
        <h3>Vista Previa</h3>
        {preview}
        <FormFooter />
      </Form>
    );
  }
}

InSystemNotificationForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    notificationSeverity: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    message: PropTypes.string,
  }),
};

InSystemNotificationForm.defaultProps = {
  initialValues: {
    notificationSeverity: null,
    message: null,
  },
};

const mapStateToProps = ({ SystemUnit }) => ({
  loading: !SystemUnit.InSystemNotification.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'InSystemNotificationForm',
})(InSystemNotificationForm);

export default connect(mapStateToProps)(formComponent);
