// get all
export const FLAG_GETTING_COUNTRIES = 'FLAG_GETTING_COUNTRIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const CLEAR_COUNTRIES = 'CLEAR_COUNTRIES';

// get one
export const FLAG_COUNTRY_ACTIVITY = 'FLAG_COUNTRY_ACTIVITY';
export const GET_COUNTRY = 'GET_COUNTRY';
export const CLEAR_COUNTRY = 'CLEAR_COUNTRY';
export const FLAG_POSTING_COUNTRY = 'FLAG_POSTING_COUNTRY';
export const POST_COUNTRY = 'POST_COUNTRY';
export const PUT_COUNTRY = 'PUT_COUNTRY';
