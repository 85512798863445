import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  getCabinCrewCommissions,
  clearCabinCrewCommissions,
} from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';

class CabinCrewCommissionSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearCabinCrewCommissions } = this.props;
    dispatchClearCabinCrewCommissions();
  }

  handleCabinCrewCommissionChange = (inputValue) => {
    const { dispatchGetCabinCrewCommissions } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetCabinCrewCommissions({ query: inputValue });
    }
  };

  render() {
    const { options, isLoading, isMulti, input, meta, ...rest } = this.props;
    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        onInputChange={this.handleCabinCrewCommissionChange}
        {...rest}
      />
    );
  }
}

CabinCrewCommissionSelect.propTypes = {
  dispatchGetCabinCrewCommissions: PropTypes.func.isRequired,
  dispatchClearCabinCrewCommissions: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
};

CabinCrewCommissionSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  options: [],
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  options: HumanResourcesUnit.CabinCrewCommission.getIn([
    'all',
    'content',
    'content',
  ]).map((option) => ({
    value: option.id,
    label: option.name,
  })),
  loading: HumanResourcesUnit.CabinCrewCommission.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetCabinCrewCommissions: getCabinCrewCommissions,
  dispatchClearCabinCrewCommissions: clearCabinCrewCommissions,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(CabinCrewCommissionSelect);
