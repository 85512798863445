import {
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT,
} from '../../config/locale';
import { tzNormalizeDate } from '../date';
import { numberFormatter } from '../number';
import {
  addQrCode,
  addText,
  createPdfDocument,
  paragraphSize,
  setFont,
  y,
} from '../pdf';
import { padStart } from '../string';

const myX = 3;
let myY = y;
const margin = 3;

const preFooter =
  'Autorizado mediante Resolución de Intendencia: Número 0180050002242/SUNAT Para mayor información sobre todos los términos y condiciones puede ingresar a nuestra página web: http://www.civa.com.pe';

const footer =
  'Al recibir el presente documento acepto todos los términos del contrato del servicio de transporte publicados en las agencias y página web. Documentos de embarque: el pasajero debe presentar obligatoriamente el boleto de viaje en físico o virtual y documento de identificación en físico (DNI o pasaporte o carnet de extranjería o cédula de identificación). Nota: La condición migratoria es responsabilidad del pasajero. Pasajeros menores de edad que viajen solos o en compañía de un adulto que no sean sus padres, deben presentar DNI y el acta de autorización de viaje notarial suscrita por uno de los padres para viajes nacionales, para viajes internacionales deben presentar el acta de autorización de viaje notarial suscrita por ambos padres. Cambio de Titularidad: La anticipación mínima es de 8 horas antes de la fecha y hora de viaje. Postergación: La anticipación mínima para el cambio de ruta, hora o tipo de servicio, es de 24 horas antes de la fecha y hora de viaje. La postergación o Cambio de titularidad lo puede realizar solo de manera virtual al siguiente correo electrónico: postventa@civa.com.pe (el horario de atención de respuesta es de 6:00 am - 10:00 pm). Para realizar el trámite debe enviar la siguiente información por correo: ASUNTO: "Solicito Cambio de Titularidad / Postergación" CONTENIDO DEL MENSAJE: Debe indicar Nombres y Apellidos del pasajero, adjuntar documentos legibles: Documento de identificación (DNI / pasaporte / CE / CI) y Boleto de Viaje.';

const qrWidth = 35;
const qrHeight = 35;

const printLine = (doc) => {
  addText(
    doc,
    '----------------------------------------------------------------------------',
    40,
    (myY += margin),
    80,
  );
};

const printCompanyFields = (doc) => {
  setFont(doc, 'bold');
  doc.setFontSize(paragraphSize);
  addText(doc, 'TURISMO CIVA S.A.C.', 40, myY, 80);
  setFont(doc);
  addText(doc, 'Si va de viaje, ¡viaje en Civa!', 40, (myY += margin), 80);
  addText(doc, 'R.U.C 20102427891', 40, (myY += margin), 80);
  addText(doc, 'JR SANCHO DE RIVERA 1184', 40, (myY += margin), 80);
  addText(doc, 'URB. MONSERRATE LIMA', 40, (myY += margin), 80);
  addText(doc, 'Central Telefónica: (511) 418-1111', 40, (myY += margin), 80);
  printLine(doc);
};

const printTicketFields = (doc, { issuingAgency, createDate, ticketCode }) => {
  const agencyNameText = doc.splitTextToSize(
    `Agencia de Emisión: ${issuingAgency}`,
    75,
  );
  agencyNameText.forEach((text) => {
    addText(doc, text, myX, (myY += margin), 75, 'left');
  });
  addText(
    doc,
    `Fecha/Hora Emisión: ${createDate}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  setFont(doc, 'bold');
  doc.setFontSize(14);

  const voucherText = doc.splitTextToSize('NOTA DE CREDITO ELECTRONICA', 75);
  voucherText.forEach((text) => {
    addText(doc, text, 40, (myY += 5), 75);
  });

  addText(doc, ticketCode, 40, (myY += 5), 75);
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printModifiedDocument = (
  doc,
  {
    voucherType,
    createDate,
    ticketCode,
    companyDocumentNumber,
    companyName,
    fullName,
    identificationType,
    idDocumentNumber,
    motive,
    pin,
  },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DOCUMENTO QUE MODIFICA:', myX, (myY += margin * 2), 50, 'left');

  setFont(doc);
  addText(
    doc,
    `${voucherType.toUpperCase()}: ${ticketCode}`,
    myX,
    (myY += margin),
    75,
    'left',
  );

  addText(doc, `FECHA: ${createDate}`, myX, (myY += margin), 75, 'left');

  const documentTypeText = companyDocumentNumber ? 'RUC' : identificationType;
  const documentTypeValue = companyDocumentNumber || idDocumentNumber;
  addText(
    doc,
    `${documentTypeText}: ${documentTypeValue}`,
    myX,
    (myY += margin),
    75,
    'left',
  );

  const companyNameValue = companyName || fullName;
  const companyNameText = doc.splitTextToSize(
    `SEÑOR(ES): ${companyNameValue}`,
    75,
  );
  companyNameText.forEach((text) => {
    addText(doc, text, myX, (myY += margin), 75, 'left');
  });

  addText(doc, `TIPO DE MONEDA: SOLES`, myX, (myY += margin), 75, 'left');

  if (motive)
    addText(
      doc,
      `SUSTENTO: ${motive.toUpperCase()}`,
      myX,
      (myY += margin),
      75,
      'left',
    );

  addText(doc, `PIN: ${pin}`, myX, (myY += margin), 75, 'left');

  printLine(doc);
};

const printPaymentFields = (
  doc,
  { ticketPrice, ticketPriceString, paymentMethod, userName },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE PAGO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, 'OP. GRAVADA', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'OP. GRATUITO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'OP. EXONERADO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: ticketPrice })}`, myX + 34, myY);
  addText(doc, 'OP. INAFECTO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'IGV', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'IMPORTE TOTAL', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: ticketPrice })}`, myX + 34, myY);
  addText(doc, 'Son:', myX, (myY += margin), 10, 'left');
  const ticketPriceStringText = doc.splitTextToSize(ticketPriceString, 75);
  ticketPriceStringText.forEach((text, index) => {
    addText(doc, text, myX + 6, (myY += index === 0 ? 0 : margin), 75, 'left');
  });
  addText(doc, 'Tipo de Pago', myX, (myY += margin), 75, 'left');
  addText(doc, ':', myX + 34, myY);
  const paymentMethodText = doc.splitTextToSize(paymentMethod, 40);
  paymentMethodText.forEach((text, index) => {
    addText(doc, text, myX + 36, (myY += index === 0 ? 0 : margin), 40, 'left');
  });
  addText(doc, 'Numero Tarjeta', myX, (myY += margin), 75, 'left');
  addText(doc, `: -`, myX + 34, myY);
  addText(doc, 'Numero Voucher', myX, (myY += margin), 75, 'left');
  addText(doc, `: -`, myX + 34, myY);
  addText(doc, 'Usuario', myX, (myY += margin), 75, 'left');
  addText(doc, ':', myX + 34, myY);
  const userNameText = doc.splitTextToSize(userName, 40);
  userNameText.forEach((text, index) => {
    addText(doc, text, myX + 36, (myY += index === 0 ? 0 : margin), 40, 'left');
  });

  setFont(doc, 'bold');
  doc.setFontSize(14);
  myY += 10;
  addText(doc, 'Asegurado con SOAT RIMAC', 40, myY, 80);
  addText(doc, 'POLIZA N°2003-8678718', 40, (myY += 5.5), 80);
  doc.setFontSize(paragraphSize);
  addText(doc, preFooter, myX, (myY += margin * 2), 74, 'justify');
  myY += 15;
  setFont(doc);
  const footerText = doc.splitTextToSize(footer, 74);
  footerText.forEach((text) => {
    addText(doc, text, myX, (myY += margin), 74, 'left');
  });
  addText(
    doc,
    'Representación impresa de la Nota de Crédito',
    40,
    (myY += margin * 2),
    74,
    'center',
  );
};

const addTicket = async (
  doc,
  {
    ticketFields: { issuingAgency, createDate, ticketCode, voucherType },
    creditNoteFields: { creditNoteCode, creditNoteCreateDate, pin },
    paymentFields: {
      ticketPrice,
      ticketPriceString,
      paymentMethod,
      userName,
      companyDocumentNumber = null,
      companyName = null,
      motive = null,
    },
    passengerFields: { fullName, identificationType, idDocumentNumber },
  },
) => {
  await addQrCode(doc, ticketCode, 22.5, 0, qrWidth, qrHeight);
  myY += 32;
  printCompanyFields(doc);
  printTicketFields(doc, {
    issuingAgency,
    createDate: creditNoteCreateDate,
    ticketCode: creditNoteCode,
  });
  printModifiedDocument(doc, {
    voucherType,
    createDate,
    ticketCode,
    companyDocumentNumber,
    companyName,
    fullName,
    identificationType,
    idDocumentNumber,
    motive,
    pin,
  });
  printPaymentFields(doc, {
    ticketPrice,
    ticketPriceString,
    paymentMethod,
    userName,
  });
  myY = 5;
};

const formatTicket = (ticket) => {
  const transaction = ticket.transactions[0];
  const voucherPrefix = transaction.business ? 'F' : 'B';
  const formatedTicket = {
    ticketFields: {
      issuingAgency: `${ticket.agency.name}\n${ticket.agency.address}`,
      createDate: tzNormalizeDate({
        date: ticket.documentAssignmentDate,
        format: DATE_TIME_FORMAT,
      }),
      voucherType: transaction.voucherType.name,
      ticketCode: ticket.fullDocumentCode,
    },
    creditNoteFields: {
      creditNoteCreateDate: tzNormalizeDate({
        date: ticket.creditNote.createDate,
        format: DATE_TIME_FORMAT,
      }),
      creditNoteCode: `${voucherPrefix}${
        ticket.creditNote.documentSeries
      }-${padStart(ticket.creditNote.documentCode, 7)}`,
      pin: ticket.creditNote.reference,
    },
    tripFields: {
      origin: ticket.sourceLocation.name,
      boarding: ticket.sourceLocation.name,
      boardingAddress: ticket.sourceLocation.address,
      disembarking: ticket.destinationLocation.name,
      destination: ticket.destinationLocation.name,
      disembarkingAddress: ticket.destinationLocation.address,
      tripDate: tzNormalizeDate({
        date: ticket.departureDate,
        format: DATE_FORMAT,
      }),
      tripTime: tzNormalizeDate({
        date: ticket.departureDate,
        format: TIME_FORMAT,
      }),
    },
    passengerFields: {
      fullName: ticket.customer.fullName,
      identificationType: ticket.customer.identificationType.name,
      idDocumentNumber: ticket.customer.idDocumentNumber,
    },
    paymentFields: {
      ticketPrice: ticket.salePrice,
      ticketPriceString: ticket.salePriceString,
      paymentMethod: transaction.paymentMethod.name,
      userName: ticket.user.customer.fullName,
      companyDocumentNumber: transaction.business
        ? transaction.business.businessTaxId
        : undefined,
      companyName: transaction.business ? transaction.business.name : undefined,
      // TODO active is necessary
      // motive: ticket.ticketVoid ? ticket.ticketVoid.description : undefined,
      motive: 'Anulación de la Operación',
    },
  };

  return formatedTicket;
};

const printCreditNote = async (ticket) => {
  const doc = createPdfDocument('p', 'mm', [80, 280]);
  const formattedTicket = formatTicket(ticket);
  await addTicket(doc, formattedTicket);
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

export default printCreditNote;
