import { HOME_PATH, TRAFFIC_UNIT_PATH, PURCHASE_ORDER_PATH } from './paths';

const HOME_BREADCRUMB = [
  {
    text: 'Inicio',
    tag: 'a',
    href: HOME_PATH,
  },
];

const TRAFFIC_UNIT_BREADCRUMBS = [
  ...HOME_BREADCRUMB,
  {
    text: 'Gestión de Tráfico',
    tag: 'span',
    href: TRAFFIC_UNIT_PATH,
  },
];

const PURCHASE_ORDERS_BREADCRUMBS = [
  ...TRAFFIC_UNIT_BREADCRUMBS,
  {
    text: 'Ordenes de Compra',
    href: PURCHASE_ORDER_PATH,
  },
];

const PURCHASE_ORDER_BREADCRUMBS = [
  ...PURCHASE_ORDERS_BREADCRUMBS,
  {
    text: 'Ver',
    href: '',
  },
];

export {
  HOME_BREADCRUMB,
  TRAFFIC_UNIT_BREADCRUMBS,
  PURCHASE_ORDERS_BREADCRUMBS,
  PURCHASE_ORDER_BREADCRUMBS,
};
