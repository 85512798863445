import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { reduxForm, Field, change } from 'redux-form';
import Immutable from 'immutable';
import { Form, Button, FormGroup, Col } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import {
  BAGGAGE_ITEM_STATUS_OPTIONS,
  BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS,
  BAGGAGE_MOVEMENT_TYPE_OPTIONS,
  BAGGAGE_ITEM_STATUS,
  DEFAULT_PAGE_SIZE,
} from '../../../../config/constants';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import {
  clearActiveSalesSession,
  clearBaggages,
  getBaggageByItemCorrelation,
  getBaggageByItemStatus,
  getBaggageByItinerary,
  getBaggageByTicketItineraryId,
  getBaggages,
  getCurrentActiveSalesSession,
} from '../../../../actions';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import Select from '../../../common/forms/select/Select';
import Loader from '../../../common/Loader';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import FormFooter from '../../../common/forms/FormFooter';
import { getSubstring } from '../../../../utils/string';
import { DEFAULT_QUERY } from '../../../../config/queries';
import BadgeList from '../../../common/BadgeList';

export class BaggageItemMovementForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchOptionSelected: null,
      itinerarySearchParamsId: null,
      statusSearchParam: null,
      ticketSearchParam: null,
      itemSearchParam: null,
      movementType: null,
      locationSearchParam: null,
      size: DEFAULT_PAGE_SIZE,
      page: 0,
    };
  }

  componentDidMount() {
    this.onDidMount();
  }

  componentWillUnmount() {
    const { dispatchClearBaggages, dispatchClearActiveSalesSession } =
      this.props;

    dispatchClearBaggages();
    dispatchClearActiveSalesSession();
  }

  onDidMount = () => {
    const {
      initialValues: { searchBy },
      dispatchGetCurrentActiveSalesSession,
    } = this.props;

    dispatchGetCurrentActiveSalesSession();

    // TODO remove next code commented because its functionality is not clear. View BaggageItemMovement.js
    if (searchBy) {
      const {
        dispatchGetBaggageByItinerary,
        // dispatchGetBaggageByTicketItineraryId,
        initialValues: {
          searchBy: { value: searchByValue },
          movementType,
          itinerarySearchParamsId,
          // currentUserSpot,
        },
      } = this.props;

      if (searchByValue === BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[0].value) {
        const setState = {
          itinerarySearchParamsId,
          didUpdate: true,
          searchOptionSelected: BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[0].value,
        };

        if (movementType) setState.movementType = movementType.value;

        this.setState(setState);

        // if (currentUserSpot === 'START')
        //   dispatchGetBaggageByTicketItineraryId({
        //     itineraryId: itinerarySearchParamsId,
        //   });
        // else
        dispatchGetBaggageByItinerary({
          itineraryId: getSubstring(itinerarySearchParamsId),
        });
      }
    }
  };

  onChangeSearchBy = (option) => this.resetForm(option);

  onSearchItemBaggages = (query) => {
    const {
      dispatchGetBaggages,
      dispatchGetBaggageByItemStatus,
      dispatchGetBaggageByItinerary,
      dispatchGetBaggageByItemCorrelation,
    } = this.props;

    const {
      searchOptionSelected,
      itinerarySearchParamsId,
      statusSearchParam,
      ticketSearchParam,
      itemSearchParam,
      locationSearchParam,
    } = this.state;

    let isValid = true;

    if (searchOptionSelected !== null) {
      if (
        searchOptionSelected ===
          BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[0].value &&
        !itinerarySearchParamsId
      ) {
        toastr.error('Error', 'Itinerario es obligatorio');
        isValid = false;
      }

      if (
        searchOptionSelected ===
          BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[1].value &&
        !statusSearchParam
      ) {
        toastr.error('Error', 'Estado es obligatorio');
        isValid = false;
      }

      if (
        searchOptionSelected ===
          BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[2].value &&
        !ticketSearchParam
      ) {
        toastr.error('Error', 'Boleto de viaje es obligatorio');
        isValid = false;
      }

      if (
        searchOptionSelected ===
          BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[3].value &&
        !itemSearchParam
      ) {
        toastr.error('Error', 'Item de equipaje es obligatorio');
        isValid = false;
      }
    } else {
      toastr.error('Error', 'Debe seleccionar un criterio de búsqueda');
      isValid = false;
    }

    if (isValid) {
      switch (searchOptionSelected) {
        case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[0].value:
          dispatchGetBaggageByItinerary({
            itineraryId: getSubstring(itinerarySearchParamsId),
            query,
          });
          break;
        case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[1].value:
          dispatchGetBaggageByItemStatus({
            itemStatus: statusSearchParam.value,
            locationId: locationSearchParam.value,
            query,
          });
          break;
        case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[2].value: {
          const newQuery = { ...query };
          newQuery.ticketCode = ticketSearchParam;
          dispatchGetBaggages(newQuery);
          break;
        }
        case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[3].value:
          dispatchGetBaggageByItemCorrelation({
            searchTerm: itemSearchParam,
            query,
          });
          break;
        default:
          break;
      }
    }
  };

  onChangeStatusSearchParams = (option) => {
    const { activeSalesSession, dispatchChange } = this.props;

    let value = null;
    if (activeSalesSession.get('agency'))
      value = {
        value: activeSalesSession.get('agency').locationId,
        label: activeSalesSession.get('agency').location.name,
      };

    dispatchChange('BaggageItemMovementForm', 'locationSearchParam', value);

    this.setState({
      statusSearchParam: option,
      locationSearchParam: value,
    });
  };

  onChangeLocationSearchParam = (option) =>
    this.setState({
      locationSearchParam: option,
    });

  onFetchData = (query) => {
    if (this.state.searchOptionSelected != null) {
      this.setState({ size: query.size, page: query.page });
      this.onSearchItemBaggages(query);
    }
  };

  clearSearchParams = () =>
    this.setState({
      itinerarySearchParamsId: null,
      statusSearchParam: null,
      ticketSearchParam: null,
      itemSearchParam: null,
    });

  resetForm = (option = null) => {
    const { reset, dispatchClearBaggages } = this.props;

    reset();

    dispatchClearBaggages();

    this.clearSearchParams();

    const setState = {
      baggages: [],
    };

    if (option) setState.searchOptionSelected = option.value;

    this.setState(setState);
  };

  generateBaggageItemColumns = () => [
    {
      Header: 'Boletos de Viaje',
      accessor: 'tickets',
      className: 'text-center',
      Cell: (row) => {
        if (!row.value) return null;
        const tickets = row.value.map(({ documentCode }) => ({
          text: documentCode,
        }));
        return <BadgeList cell textArray={tickets} />;
      },
    },
    {
      Header: 'Desembarque',
      accessor: 'destinations',
      className: 'text-center',
      Cell: (row) => {
        if (!row.value) return null;
        const destinations = row.value.map(({ name }) => ({ text: name }));
        return <BadgeList cell textArray={destinations} />;
      },
    },
    {
      Header: 'Correlativo de Item',
      accessor: 'itemCorrelation',
      className: 'text-center',
    },
    {
      Header: 'Descripción',
      accessor: 'description',
      className: 'text-center',
    },
    {
      Header: 'Peso',
      accessor: 'weight',
      className: 'text-center',
    },
  ];

  generateBaggageItemData = () => {
    const data = [];

    this.props.baggages
      .get('content')
      .forEach(({ ticketDtos, ticketList, itemList }) => {
        const tickets = [];
        const destinations = [];

        ticketDtos.forEach(({ documentCode }) => {
          tickets.push({ documentCode });
        });

        ticketList.forEach(({ destinationLocation }) => {
          destinations.push({ name: destinationLocation.name });
        });

        itemList.forEach(({ description, id, itemCorrelation, weight }) => {
          data.push({
            description,
            id,
            itemCorrelation,
            weight,
            tickets,
            destinations,
          });
        });
      });

    return data;
  };

  handleSelectItinerary = ({ itineraryId }) => {
    const { dispatchClearBaggages } = this.props;

    dispatchClearBaggages();

    this.setState({
      itinerarySearchParamsId: itineraryId,
    });
  };

  renderSearchFields = () => {
    const { searchOptionSelected, statusSearchParam } = this.state;

    let searchFields = null;
    let agencySearchField = null;
    if (
      statusSearchParam &&
      statusSearchParam.value !== BAGGAGE_ITEM_STATUS.ON_ITINERARY.value
    )
      agencySearchField = (
        <FormGroup row>
          <FormItem label="Agencia" required>
            <Field
              name="locationSearchParam"
              component={LocationSelect}
              forSales
              placeholder="Agencia"
              onChange={this.onChangeLocationSearchParam}
              validate={[isRequired]}
              isClearable={false}
            />
          </FormItem>
        </FormGroup>
      );

    switch (searchOptionSelected) {
      case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[0].value:
        searchFields = (
          <FormGroup row>
            <FormItem label="Itinerario" required>
              <Field
                name="itinerarySearchParamsId"
                placeholder="Itinerario"
                validate={[isRequired]}
                handleSelectItinerary={this.handleSelectItinerary}
                props={{
                  form: 'BaggageItemMovementForm',
                }}
              />
            </FormItem>
          </FormGroup>
        );
        break;
      case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[1].value:
        searchFields = (
          <>
            <FormGroup row>
              <FormItem label="Estado" required>
                <Field
                  name="statusSearchParam"
                  component={Select}
                  options={BAGGAGE_ITEM_STATUS_OPTIONS}
                  placeholder="Estado"
                  onChange={this.onChangeStatusSearchParams}
                  isClearable={false}
                  validate={[isRequired]}
                />
              </FormItem>
            </FormGroup>
            {agencySearchField}
          </>
        );
        break;
      case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[2].value:
        searchFields = (
          <FormGroup row>
            <FormItem label="Boleto" required>
              <Field
                name="ticketSearchParam"
                component={TextInput}
                placeholder="Boleto"
                onChange={(e) =>
                  this.setState({ ticketSearchParam: e.target.value })
                }
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
        );
        break;
      case BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[3].value:
        searchFields = (
          <FormGroup row>
            <FormItem label="Item" required>
              <Field
                name="itemSearchParam"
                component={TextInput}
                placeholder="Item"
                onChange={(e) =>
                  this.setState({ itemSearchParam: e.target.value })
                }
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
        );
        break;
      default:
        break;
    }

    return searchFields;
  };

  render() {
    const {
      handleSubmit,
      loadingBaggages,
      cancelRedirectPath,
      loadingCurrentSalesSession,
      loadingbaggage,
      baggages,
    } = this.props;

    if (loadingCurrentSalesSession || loadingbaggage) return <Loader />;

    const { movementType, size, page } = this.state;

    const searchFields = this.renderSearchFields();

    const searchForm = (
      <>
        <h5>Parámetros de búsqueda</h5>
        <FormGroup row>
          <FormItem label="Buscar por" required>
            <Field
              name="searchBy"
              component={Select}
              options={BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS}
              validate={[isRequired]}
              isClearable={false}
              onChange={this.onChangeSearchBy}
            />
          </FormItem>
        </FormGroup>
        {searchFields}
        <FormGroup row>
          <Col className="flex row-reverse">
            <Button
              color="secondary"
              outline
              type="button"
              onClick={() =>
                this.onSearchItemBaggages({ ...DEFAULT_QUERY, size, page })
              }
            >
              <i className="fa fa-search" /> Buscar
            </Button>
          </Col>
        </FormGroup>
      </>
    );

    const data = this.generateBaggageItemData();

    let cancelButton = (
      <Button type="button" disabled={!data.length} onClick={this.resetForm}>
        Cancelar
      </Button>
    );

    if (cancelRedirectPath) {
      cancelButton = (
        <Link to={cancelRedirectPath} className="btn btn-secondary">
          Cancelar
        </Link>
      );
    }

    let movementTypeField = null;

    if (movementType === 0) {
      movementTypeField = (
        <FormGroup row>
          <FormItem label="Itinerario" required>
            <Field
              name="itineraryId"
              component={ItinerarySearchInput}
              validate={[isRequired]}
              props={{
                form: 'BaggageItemMovementForm',
              }}
            />
          </FormItem>
        </FormGroup>
      );
    }

    if (movementType === 1) {
      movementTypeField = (
        <FormGroup row>
          <FormItem label="Agencia" required>
            <Field
              name="locationId"
              component={LocationSelect}
              forSales
              placeholder="Agencia"
              isDisabled={!data.length}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      );
    }

    const tableColumns = this.generateBaggageItemColumns();
    const pages = baggages.get('totalPages') || null;
    const defaultPageSize = baggages.get('size') || DEFAULT_PAGE_SIZE;

    const movementForm = (
      <>
        <div className="mb-3">
          <h5>Items de Equipaje</h5>
          <SelectionableTable
            columns={tableColumns}
            data={data}
            pages={pages}
            defaultPageSize={defaultPageSize}
            keyField="id"
            loading={loadingBaggages}
            required
            form="BaggageItemMovementForm"
            returnOnlySelectedItems
            fetchData={this.onFetchData}
            manual
          />
        </div>
        <h5>Datos de movimiento</h5>
        <FormGroup row>
          <FormItem label="Tipo Movimiento" required>
            <Field
              name="movementType"
              component={Select}
              options={BAGGAGE_MOVEMENT_TYPE_OPTIONS}
              validate={[isRequired]}
              isClearable={false}
              isDisabled={!data.length}
              onChange={(option) =>
                this.setState({ movementType: option.value })
              }
            />
          </FormItem>
        </FormGroup>
        {movementTypeField}
        <FormGroup row>
          <FormItem label="Estado" required>
            <Field
              name="status"
              component={Select}
              options={BAGGAGE_ITEM_STATUS_OPTIONS}
              placeholder="Estado"
              isDisabled={!data.length}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentario">
            <Field
              name="remarks"
              component={TextInput}
              type="textarea"
              disabled={!data.length}
              placeholder="Comentario"
            />
          </FormItem>
        </FormGroup>
        <FormFooter saveButtonDisabled={!data.length}>
          {cancelButton}
        </FormFooter>
      </>
    );

    return (
      <Form onSubmit={handleSubmit}>
        {searchForm}
        {movementForm}
      </Form>
    );
  }
}

BaggageItemMovementForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadingBaggages: PropTypes.bool,
  baggages: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetBaggages: PropTypes.func.isRequired,
  dispatchClearBaggages: PropTypes.func.isRequired,
  dispatchGetBaggageByItemStatus: PropTypes.func.isRequired,
  dispatchGetBaggageByItinerary: PropTypes.func.isRequired,
  // dispatchGetBaggageByTicketItineraryId: PropTypes.func.isRequired,
  dispatchGetBaggageByItemCorrelation: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    searchBy: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    movementType: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    currentUserSpot: PropTypes.string,
    itinerarySearchParamsId: PropTypes.string,
  }),
  cancelRedirectPath: PropTypes.string,
  dispatchClearActiveSalesSession: PropTypes.func.isRequired,
  dispatchGetCurrentActiveSalesSession: PropTypes.func.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingCurrentSalesSession: PropTypes.bool.isRequired,
  loadingbaggage: PropTypes.bool,
  dispatchChange: PropTypes.func.isRequired,
};

BaggageItemMovementForm.defaultProps = {
  loadingBaggages: false,
  initialValues: {},
  cancelRedirectPath: null,
  loadingbaggage: false,
};

const mapStateToProps = ({ BaggageUnit, SalesUnit }) => ({
  loadingBaggages: BaggageUnit.Baggage.getIn(['all', 'loading']),
  baggages: BaggageUnit.Baggage.getIn(['all', 'content']),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  loadingCurrentSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  loadingbaggage: !BaggageUnit.Baggage.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetBaggages: getBaggages,
  dispatchClearBaggages: clearBaggages,
  dispatchGetBaggageByItemStatus: getBaggageByItemStatus,
  dispatchGetBaggageByItinerary: getBaggageByItinerary,
  dispatchGetBaggageByItemCorrelation: getBaggageByItemCorrelation,
  dispatchGetBaggageByTicketItineraryId: getBaggageByTicketItineraryId,
  dispatchGetCurrentActiveSalesSession: getCurrentActiveSalesSession,
  dispatchClearActiveSalesSession: clearActiveSalesSession,
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'BaggageItemMovementForm',
})(BaggageItemMovementForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
