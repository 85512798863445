import React from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { getPdfManualSenderRemissionGuide } from '../../../../actions/cargo/ManualSenderRemissionGuide';

const PrintManualSenderRemissionGuideButton = (props) => {
  const { manualRemissionGuide, dispatchGetPdfManualSenderRemissionGuide } =
    props;

  const printManualSenderRemissionGuideButton = () => {
    dispatchGetPdfManualSenderRemissionGuide(
      manualRemissionGuide.get('ticket'),
    );
  };

  return (
    <Button
      color="light"
      className="border"
      onClick={printManualSenderRemissionGuideButton}
    >
      <i className="fa fa-print" /> Imprimir
    </Button>
  );
};

PrintManualSenderRemissionGuideButton.propTypes = {
  manualRemissionGuide: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetPdfManualSenderRemissionGuide: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetPdfManualSenderRemissionGuide: getPdfManualSenderRemissionGuide,
};

export default connect(
  null,
  mapDispatchToProps,
)(PrintManualSenderRemissionGuideButton);
