import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DISCOUNT_TO_BENEFICIARIES_PATH } from '../../../../config/paths';
import DiscountToBeneficiaryForm from './DiscountToBeneficiaryForm';
import { postDiscountToBeneficiary } from '../../../../actions';
import Content from '../../../layout/Content';

export class DiscountToBeneficiary extends Component {
  onSubmit = (formValues) =>
    this.props.dispatchPostDiscountToBeneficiary({
      file: formValues.file,
      discountCodeId: formValues.discountCodeId,
      companyId: formValues.companyId.value,
    });

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Descuento para Beneficiarios"
        subtitle="Sube un excel con las columnas Número de Documento e ID de Tipo de Documento"
        content={<DiscountToBeneficiaryForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Descuento para Beneficiarios',
      href: DISCOUNT_TO_BENEFICIARIES_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostDiscountToBeneficiary: postDiscountToBeneficiary,
};

DiscountToBeneficiary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDiscountToBeneficiary: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscountToBeneficiary);
