import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import AuthenticateForm from './AuthenticateForm';

// exporting unconnected component for testing
// https://bit.ly/2HtSJlT
export class SignIn extends Component {
  constructor(props) {
    super(props);
    // redirect to home if already authenticated
    if (this.props.authenticated) {
      // if there is a redirect handle it here
      const redirect = sessionStorage.getItem('redirect');
      if (redirect) {
        sessionStorage.removeItem('redirect');
        this.props.replace(redirect);
      } else {
        this.props.replace('/');
      }
    }
  }

  componentDidUpdate() {
    // redirect to home if already authenticated
    const { authenticated, user } = this.props;
    if (authenticated && user) {
      // if there is a redirect handle it here
      const redirect = sessionStorage.getItem('redirect');
      if (redirect) {
        sessionStorage.removeItem('redirect');
        this.props.replace(redirect);
      } else {
        this.props.replace('/');
      }
    }
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1 className="title">Bienvenido</h1>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container>
          <AuthenticateForm />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => ({
  authenticated: authentication.get('authenticated'),
  user: authentication.get('user'),
});

const mapDispatchToProps = {
  replace,
};

SignIn.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  replace: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

SignIn.defaultProps = {
  user: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignIn);
