import Immutable from 'immutable';
import {
  FLAG_GETTING_CITIES,
  GET_CITIES,
  CLEAR_CITIES,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';


const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    error: null,
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_CITIES:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_CITIES:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_CITIES:
      return state.setIn(['all', 'content'], INITIAL_STATE.getIn(['all', 'content']));
    default:
      return state;
  }
};
