import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  MAINTENANCE_SERVICE_ORDER_PATH,
  NEW_MAINTENANCE_SERVICE_ORDER_PATH,
} from '../../../../config/paths';
import {
  getMaintenanceServiceOrders,
  clearMaintenanceServiceOrders,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { MAINTENANCE_SERVICE_ORDER_COLUMNS } from '../../../../config/columns';

export const MaintenanceServiceOrders = ({
  breadcrumbs,
  services,
  loading,
  dispatchGetMaintenanceServiceOrders,
  dispatchClearMaintenanceServiceOrders,
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenanceServiceOrders(), []);

  const data = services.get('content') || [];
  const pages = services.get('totalPages') || null;
  const defaultPageSize = services.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Ordenes de Servicio"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_MAINTENANCE_SERVICE_ORDER_PATH}
      tableStructure={{
        columns: MAINTENANCE_SERVICE_ORDER_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetMaintenanceServiceOrders,
        modelPath: MAINTENANCE_SERVICE_ORDER_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetMaintenanceServiceOrders: getMaintenanceServiceOrders,
  dispatchClearMaintenanceServiceOrders: clearMaintenanceServiceOrders,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
  ],
  services: MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'all',
    'loading',
  ]),
});

MaintenanceServiceOrders.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  services: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetMaintenanceServiceOrders: PropTypes.func.isRequired,
  dispatchClearMaintenanceServiceOrders: PropTypes.func.isRequired,
};

MaintenanceServiceOrders.defaultProps = {
  services: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceServiceOrders);
