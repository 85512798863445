import { DEFAULT_SORT, DEFAULT_PAGE_SIZE, DEFAULT_ORDER } from './constants';

const DEFAULT_QUERY = {
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_SORT,
  order: DEFAULT_ORDER,
  query: '',
};

const DEFAULT_QUERY_GET_ALL = {
  page: 0,
  size: 1000,
  sort: 'id,desc',
  order: DEFAULT_ORDER,
  query: '',
};

export { DEFAULT_QUERY, DEFAULT_QUERY_GET_ALL };
