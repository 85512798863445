import React from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { numberFormatter } from '../../../../../utils/number';

const DepositDifferenceResource = ({
  depositDifferenceAmount,
  depositDifferenceReason,
  depositDifferenceDescription,
}) => {
  if (depositDifferenceAmount === 0 || !depositDifferenceReason) {
    return null;
  }

  return (
    <Card body className="alert alert-danger">
      <h3>Discrepancia</h3>
      <ResourceProperty label="Monto:">
        {numberFormatter({ value: depositDifferenceAmount })}
      </ResourceProperty>
      <ResourceProperty label="Razón:">
        {depositDifferenceReason.name}
      </ResourceProperty>
      <ResourceProperty label="Descripción:">
        {depositDifferenceDescription}
      </ResourceProperty>
    </Card>
  );
};

DepositDifferenceResource.propTypes = {
  depositDifferenceAmount: PropTypes.number,
  depositDifferenceReason: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  depositDifferenceDescription: PropTypes.string,
};

DepositDifferenceResource.defaultProps = {
  depositDifferenceAmount: 0,
  depositDifferenceReason: null,
  depositDifferenceDescription: null,
};

export default DepositDifferenceResource;
