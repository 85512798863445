import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_HIERARCHIES,
  GET_HIERARCHIES,
  CLEAR_HIERARCHIES,
} from '../types';
import { HIERARCHY_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingHierarchies = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_HIERARCHIES,
  });

const getHierarchies = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingHierarchies(true));
    const query = tableFilters;
    const url = `${HIERARCHY_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_HIERARCHIES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingHierarchies(false));
  }
};

const clearHierarchies = () => (dispatch) =>
  dispatch({ type: CLEAR_HIERARCHIES });

export { clearHierarchies, getHierarchies };
