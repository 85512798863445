import { number, string, arrayOf, shape, bool } from 'prop-types';
import seatMapElementBasicInformationPropTypes from '../../seat-map-element/proptypes/SeatMapElementPropTypes';
import { serviceTypeBasicInformationPropTypes } from '../../service-type/proptypes/ServiceTypePropTypes';

const seatsPropTypes = {
  seats: arrayOf(
    shape({
      floorNumber: number,
      seatMapElement: shape(seatMapElementBasicInformationPropTypes),
      seatMapElementId: number,
      seatMapId: number,
      seatNumber: number,
      seatStatus: string,
      xPosition: number,
      yPosition: number,
    }),
  ).isRequired,
};

const floorsPropTypes = {
  floors: arrayOf(
    shape({
      floorNumber: number,
      length: number,
      width: number,
      ...seatsPropTypes,
    }),
  ),
};

const seatMapInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  displayName: string.isRequired,
  description: string,
  serviceTypeCorrelationId: string,
  totalSeats: number,
  isCargoOnly: bool,
  numberOfFloors: number,
  serviceType: shape(serviceTypeBasicInformationPropTypes).isRequired,
  ...floorsPropTypes,
};

const seatMapormationDefaultProps = {
  serviceTypeCorrelationId: null,
  numberOfFloors: null,
  description: null,
  totalSeats: null,
  isCargoOnly: null,
};

export {
  seatMapInformationPropTypes,
  seatMapormationDefaultProps,
  floorsPropTypes,
  seatsPropTypes,
};
