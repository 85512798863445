import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import {
  clearItinerarySalesControlDetail,
  getItinerarySalesControlDetail,
} from '../../../../actions';
import Table from '../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import { ITINERARY_SALES_CONTROL_DETAIL_COLUMNS } from '../../../../config/columns';
import { generateItinerarySalesControlDetailEndpoint } from '../../../../config/endpoints';

class ItinerarySalesControlDetailComponent extends Component {
  constructor(props) {
    super(props);

    const { itineraryId, dispatchGetItinerarySalesControlDetail } = this.props;

    dispatchGetItinerarySalesControlDetail(itineraryId);
  }

  componentWillUnmount() {
    this.props.dispatchClearItinerarySalesControlDetail();
  }

  render() {
    const { loading, detail, itineraryId } = this.props;

    const href = `${generateItinerarySalesControlDetailEndpoint(
      itineraryId,
    )}?download=true`;

    const toolbar = detail.length ? (
      <ButtonToolbar className="pull-right">
        <a className="btn btn-outline-primary" href={href}>
          <i className="fa fa-download" /> Descargar
        </a>
      </ButtonToolbar>
    ) : null;

    return (
      <Fragment>
        {toolbar}
        <div className="flex">
          <h4>Detalle de Itinerario</h4>
        </div>
        <Table
          manual={false}
          columns={ITINERARY_SALES_CONTROL_DETAIL_COLUMNS}
          data={detail}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
          loading={loading}
        />
      </Fragment>
    );
  }
}

ItinerarySalesControlDetailComponent.propTypes = {
  itineraryId: PropTypes.number.isRequired,
  dispatchGetItinerarySalesControlDetail: PropTypes.func.isRequired,
  dispatchClearItinerarySalesControlDetail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  detail: PropTypes.arrayOf(
    PropTypes.shape({
      documentAssignmentDate: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: ItineraryUnit.ItinerarySalesControl.getIn(['detail', 'loading']),
  detail: ItineraryUnit.ItinerarySalesControl.getIn([
    'detail',
    'content',
  ]).toJS(),
});

const mapDispatchToProps = {
  dispatchGetItinerarySalesControlDetail: getItinerarySalesControlDetail,
  dispatchClearItinerarySalesControlDetail: clearItinerarySalesControlDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerarySalesControlDetailComponent);
