import Immutable from 'immutable';
import { HOME_PATH, POSTPAID_BOOKING_UNIT_PATH } from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Reservas Pospago',
      tag: 'span',
      href: POSTPAID_BOOKING_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
