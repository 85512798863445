import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { getPrintedTicket } from '../../../../../actions/index';
import PostponeTicketButton from './PostponeTicketButton';
import PassengerChangeButton from './PassengerChangeButton';
import SendTicketButton from './SendTicketButton';
import VoucherTypeChangeButton from './../ticket-toolbar/VoucherTypeChangeButton';
import RefundButton from './RefundButton';
import { TICKET_STATUS } from '../../../../../config/constants';
import PrintTicketButton from './PrintTicketButton';
import VoidTicketButton from './VoidTicketButton';
import PrintCreditNoteButton from './PrintCreditNoteButton';

const TicketToolbar = ({
  ticketStatus,
  ticketId,
  creditNote,
  departureDate,
  voucherTypeId,
  forThirdParty,
  ticket,
  user: { salesSessionUserId },
}) => {
  let content = null;
  if (ticketStatus === TICKET_STATUS.ACTIVE.value) {
    content = (
      <ButtonToolbar className="pull-right">
        <div>
          <PassengerChangeButton ticketId={ticketId} />{' '}
          <PostponeTicketButton
            ticketId={ticketId}
            forThirdParty={forThirdParty}
          />{' '}
          <VoucherTypeChangeButton
            ticketId={ticketId}
            voucherTypeId={voucherTypeId}
            departureDate={departureDate}
          />{' '}
          <SendTicketButton ticketId={ticketId} />{' '}
          <RefundButton ticketId={ticketId} forThirdParty={forThirdParty} />{' '}
          <PrintTicketButton
            ticket={ticket}
            salesSessionUserId={salesSessionUserId}
          />{' '}
          <VoidTicketButton ticketId={ticketId} />
        </div>
      </ButtonToolbar>
    );
  }

  if (creditNote) {
    const refundButton = creditNote.valid && (
      <Fragment>
        {' '}
        <RefundButton ticketId={ticketId} departureDate={departureDate} />
      </Fragment>
    );
    content = (
      <ButtonToolbar className="pull-right">
        <div>
          <PrintCreditNoteButton ticket={ticket} />
          {refundButton}
        </div>
      </ButtonToolbar>
    );
  }

  return content;
};

TicketToolbar.propTypes = {
  ticketId: PropTypes.number.isRequired,
  ticketStatus: PropTypes.string.isRequired,
  ticket: PropTypes.instanceOf(Map),
  departureDate: PropTypes.number,
  voucherTypeId: PropTypes.number.isRequired,
  forThirdParty: PropTypes.bool,
  creditNote: PropTypes.shape({
    reference: PropTypes.string,
    amount: PropTypes.number,
    valid: PropTypes.bool,
  }),
  user: PropTypes.shape({
    salesSessionUserId: PropTypes.number,
  }).isRequired,
};

TicketToolbar.defaultProps = {
  departureDate: null,
  ticket: null,
  forThirdParty: false,
  creditNote: null,
};

const mapStateToProps = (state, { ticket }) => {
  const customers = state.UserUnit.Customer.getIn([
    'all',
    'content',
    'content',
  ]).map((customer) => ({
    value: customer.id,
    label: `${customer.idDocumentNumber} - ${customer.fullName}`,
  }));

  const isCustomerLoading = state.UserUnit.Customer.getIn(['all', 'content']);

  const loadingPassengerChange = state.ReservationUnit.Ticket.getIn([
    'current',
    'changingPassenger',
  ]);

  const newTicket =
    ticket || state.ReservationUnit.Ticket.getIn(['current', 'content']);

  return {
    customers,
    isCustomerLoading,
    loadingPassengerChange,
    ticket: newTicket,
    user: state.authentication.get('user'),
  };
};

const mapDispatchToProps = { getPrintedTicket };

export default connect(mapStateToProps, mapDispatchToProps)(TicketToolbar);
