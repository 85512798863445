import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '../../../../common/Table';
import CollapsibleHeader from '../../../../layout/CollapsibleHeader';
import { numberFormatter } from '../../../../../utils/number';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { DEPOSIT_PATH } from '../../../../../config/paths';

const LiquidationDepositsResource = ({ deposits }) => {
  if (!deposits.length) return null;

  const depositList = () => (
    <Table
      columns={[
        {
          Header: 'Banco',
          accessor: 'bankName',
          className: 'text-center',
        },
        {
          Header: 'Número de Cuenta',
          accessor: 'accountNumber',
          className: 'text-center',
        },
        {
          Header: 'Número de Operación',
          accessor: 'operationNumber',
          className: 'text-center',
        },
        {
          Header: 'Monto Depositado',
          accessor: 'actualAmountDeposited',
          Cell: ({ value }) => numberFormatter({ value }),
          className: 'text-center',
        },
      ]}
      data={deposits}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
      navigateToModelOnRowClick
      openPathInNewTab
      manual={false}
      modelPath={DEPOSIT_PATH}
    />
  );

  return (
    <>
      <hr />
      <CollapsibleHeader headerText="Depósitos" component={depositList} />
    </>
  );
};

LiquidationDepositsResource.propTypes = {
  deposits: PropTypes.arrayOf(
    PropTypes.shape({
      bankName: PropTypes.string,
      accountNumber: PropTypes.string,
      operationNumber: PropTypes.string,
      actualAmountDeposited: PropTypes.number,
    }),
  ),
};

LiquidationDepositsResource.defaultProps = {
  deposits: [],
};

export default LiquidationDepositsResource;
