import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import Immutable from 'immutable';
import SeatSelectionHeader from './SeatSelectionHeader';
import SeatMap from './SeatMap';
import { deselectSeat, selectSeat } from '../../../../actions/booking';

const SeatSelection = ({
  selectedItinerarySearchResults,
  dispatchSelectSeat,
  dispatchDeselectSeat,
  seatReservations,
  seatSelectionChangesInProgress,
  numPassengers,
}) => {
  // todo: handle multiple itineraries
  const itinerarySearchResult = selectedItinerarySearchResults.get(0);

  if (!itinerarySearchResult)
    return (
      <div className="border rounded p-3 mt-4">
        <h5>Selección de Asientos</h5>
        <span className="text-muted">
          <i>Debe seleccionar un itinerario</i>
        </span>
      </div>
    );

  const seatMap = itinerarySearchResult.seatMapDTO;

  const { floors } = seatMap;

  const destinationLocationId = itinerarySearchResult.destination.id;

  const sourceLocationId = itinerarySearchResult.origin.id;

  const itineraryId = itinerarySearchResult.itinerary.id;

  return (
    <div className="mt-4">
      <SeatSelectionHeader itinerarySearchResult={itinerarySearchResult} />
      <SeatMap
        destinationLocationId={destinationLocationId}
        sourceLocationId={sourceLocationId}
        itineraryId={itineraryId}
        floors={floors}
        handleSeatSelection={dispatchSelectSeat}
        handleSeatDeselection={dispatchDeselectSeat}
        seatReservations={seatReservations}
        seatSelectionChangesInProgress={seatSelectionChangesInProgress}
        numPassengers={numPassengers}
      />
    </div>
  );
};

SeatSelection.propTypes = {
  selectedItinerarySearchResults: PropTypes.instanceOf(Immutable.List)
    .isRequired,
  dispatchSelectSeat: PropTypes.func.isRequired,
  dispatchDeselectSeat: PropTypes.func.isRequired,
  seatReservations: PropTypes.instanceOf(Immutable.Set).isRequired,
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
  numPassengers: PropTypes.number,
};

SeatSelection.defaultProps = {
  numPassengers: null,
};

const mapStateToProps = (state) => ({
  source: state.BookingUnit.Booking.getIn(['search', 'query', 'source']),
  destination: state.BookingUnit.Booking.getIn([
    'search',
    'query',
    'destination',
  ]),
  time: state.BookingUnit.Booking.getIn(['search', 'query', 'time']),
  selectedItinerarySearchResults: state.BookingUnit.Booking.getIn([
    'itineraries',
    'selectedItinerarySearchResults',
  ]),
  seatReservations: state.BookingUnit.Booking.getIn([
    'seats',
    'seatReservations',
  ]),
  seatSelectionChangesInProgress: state.BookingUnit.Booking.getIn([
    'seats',
    'seatSelectionChangesInProgress',
  ]),
  numPassengers: state.BookingUnit.Booking.getIn([
    'search',
    'query',
    'numPassengers',
  ]),
});

const mapDispatchToProps = {
  dispatchReplace: replace,
  dispatchSelectSeat: selectSeat,
  dispatchDeselectSeat: deselectSeat,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatSelection);
