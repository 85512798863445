import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import SeatMapElement from '../seat-map-element/SeatMapElement';
import seatMapElementBasicInformationPropTypes from '../../units/bus/seat-map-element/proptypes/SeatMapElementPropTypes';
import { SEAT_STATUS } from '../../../config/constants';

// exporting unconnected component for testing
// https://bit.ly/2HtSJlT
// TODO: enhance isSelected calculation, currently
export class Seat extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.openPopover = this.openPopover.bind(this);
    this.handleSeatClick = this.handleSeatClick.bind(this);
    this.handleSeatKeyPress = this.handleSeatKeyPress.bind(this);
    this.handleSeatInteraction = this.handleSeatInteraction.bind(this);
    this.isFree = this.isFree.bind(this);
    this.state = {
      popoverOpen: false,
    };
  }

  isFree() {
    return (
      this.props.seatMapElement.isSeat &&
      this.props.seatStatus === SEAT_STATUS.FREE
    );
  }

  toggle() {
    if (this.isFree()) {
      this.setState({
        popoverOpen: !this.state.popoverOpen,
      });
    }
  }

  openPopover() {
    if (this.isFree()) {
      this.setState({ popoverOpen: true });
    }
  }

  closePopover() {
    if (this.isFree()) {
      this.setState({ popoverOpen: false });
    }
  }

  handleSeatClick() {
    this.handleSeatInteraction();
  }

  handleSeatKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSeatInteraction();
    }
  }

  numSelectedSeats = () => {
    const { seatReservations, seatSelectionChangesInProgress } = this.props;
    return seatReservations.size + seatSelectionChangesInProgress.size;
  };

  allPassengersHaveSeats = () => {
    const { numPassengers } = this.props;
    const numSelectedSeats = this.numSelectedSeats();
    return numPassengers
      ? numSelectedSeats >= numPassengers
      : numSelectedSeats >= 1;
  };

  isSelected = () => {
    const { seatReservations, id } = this.props;

    const selected = seatReservations.find(
      (seatReservation) =>
        `${seatReservation.itineraryId}-${seatReservation.seatId}` === id,
    );

    if (selected) {
      return true;
    }

    return false;
  };

  seatReservationId = () => {
    const { seatReservations, id, itineraryId } = this.props;
    const seatReservation = seatReservations.find(
      (seatReservationElement) =>
        `${itineraryId}-${seatReservationElement.seat.id}` === id,
    );
    if (seatReservation) {
      return seatReservation.id;
    }
    return null;
  };

  isTransitioning = () => {
    const { seatSelectionChangesInProgress, id } = this.props;

    return seatSelectionChangesInProgress.find(
      (seatSelectionChange) => seatSelectionChange === id,
    );
  };

  handleSeatInteraction() {
    if (this.isFree()) {
      const {
        handleSeatSelection,
        sourceLocationId,
        destinationLocationId,
        id,
        handleSeatDeselection,
        seatSelectionChangesInProgress,
        user: { salesSessionUserId },
        seatReservations,
        specialPriceId,
      } = this.props;

      const [itineraryId, seatId] = id.split('-');

      if (seatSelectionChangesInProgress.size === 0) {
        if (!this.isSelected()) {
          // We verify if the user is from chinalco
          if (salesSessionUserId) {
            // Chinalco user can only select one seat
            if (seatReservations.size === 0) {
              handleSeatSelection({
                sourceLocationId,
                destinationLocationId,
                itineraryId,
                seatId,
              });
            }
          } else {
            handleSeatSelection({
              sourceLocationId,
              destinationLocationId,
              itineraryId,
              seatId,
              specialPriceId,
            });
          }
        } else {
          handleSeatDeselection({
            seatReservationId: this.seatReservationId(),
            itineraryId,
            seatId,
          });
        }
      }
    }
  }

  render() {
    const {
      seatMapElement,
      seatNumber,
      seatStatus,
      price,
      id,
      seatSelectionChangesInProgress,
      specialPriceId,
    } = this.props;

    const free = seatStatus === SEAT_STATUS.FREE;
    const selected = this.isSelected();

    if (this.isTransitioning()) {
      return (
        <div id={`id_${id}`}>
          <i className="fa fa-2x fa-spinner fa-spin" />
        </div>
      );
    }

    return (
      <div>
        <SeatMapElement
          onMouseEnter={this.openPopover}
          onMouseLeave={this.closePopover}
          onFocus={this.openPopover}
          onBlur={this.closePopover}
          seatNumber={seatNumber}
          onClick={this.handleSeatClick}
          onKeyPress={this.handleSeatKeyPress}
          {...seatMapElement}
          id={`id_${id}`}
          selected={selected}
          free={free}
          className={this.isFree() ? 'clickable' : ''}
          seatStatus={seatStatus}
          disabled={seatSelectionChangesInProgress.size > 0}
          specialPriceId={specialPriceId}
        />
        <Popover
          placement="bottom"
          isOpen={this.state.popoverOpen}
          target={`id_${id}`}
          toggle={this.toggle}
        >
          <PopoverHeader>{seatMapElement.name}</PopoverHeader>
          <PopoverBody>{price} PEN</PopoverBody>
        </Popover>
      </div>
    );
  }
}

Seat.defaultProps = {
  seatReservationId: null,
};

Seat.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  seatNumber: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  handleSeatDeselection: PropTypes.func.isRequired,
  handleSeatSelection: PropTypes.func.isRequired,
  sourceLocationId: PropTypes.number.isRequired,
  destinationLocationId: PropTypes.number.isRequired,
  seatReservationId: PropTypes.number,
  itineraryId: PropTypes.number.isRequired,
  seatMapElement: PropTypes.shape(seatMapElementBasicInformationPropTypes)
    .isRequired,
  seatStatus: PropTypes.string.isRequired,
  seatReservations: PropTypes.instanceOf(Immutable.Set).isRequired,
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
  numPassengers: PropTypes.number,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    securityProfileSet: PropTypes.arrayOf(
      PropTypes.shape({
        viewAuthorizationList: PropTypes.arrayOf(
          PropTypes.shape({
            view: PropTypes.shape({
              route: PropTypes.string,
            }),
          }),
        ).isRequired,
      }),
    ).isRequired,
    salesSessionUserId: PropTypes.number,
  }).isRequired,
  specialPriceId: PropTypes.string
};

Seat.defaultProps = {
  numPassengers: null,
  specialPriceId: null,
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.get('user'),
});

export default connect(mapStateToProps)(Seat);
