import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_DEPOSITS,
  GET_DEPOSITS,
  CLEAR_DEPOSITS,
  FLAG_DEPOSIT_ACTIVITY,
  GET_DEPOSIT,
  CLEAR_DEPOSIT,
  GET_DEPOSIT_VOUCHER,
} from '../types';
import {
  DEPOSIT_ENDPOINT,
  DEPOSIT_FILE_UPLOAD_ENDPOINT,
  DEPOSIT_SEARCH_ENDPOINT,
  generateGetDepositEndpoint,
  GET_DEPOSIT_VOUCHER_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  BASIC_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { DEPOSIT_PATH } from '../../config/paths';

const flagGettingDeposits = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DEPOSITS,
    payload: flag,
  });

const getDeposits = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDeposits(true));

    const query = tableFilters;

    const url = `${DEPOSIT_SEARCH_ENDPOINT}?${QueryString.stringify(query)}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response);

    const deposits = await response.json();

    dispatch({
      type: GET_DEPOSITS,
      payload: deposits,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingDeposits(false));
  }
};

const clearDeposits = () => (dispatch) =>
  dispatch({
    type: CLEAR_DEPOSITS,
  });

const flagDepositActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DEPOSIT_ACTIVITY,
    payload: flag,
  });

const postDeposit =
  async ({
    accountNumber,
    bankName,
    operationNumber,
    depositDate,
    actualAmountDeposited,
    expectedAmountToDeposit,
    depositDifferenceDescription,
    depositDifferenceAmount,
    depositDifferenceReasonId,
    dailyLiquidationSessionSet,
    depositType,
    file,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagDepositActivity(true));

      const payload = {
        accountNumber,
        bankName,
        operationNumber,
        depositDate,
        actualAmountDeposited,
        expectedAmountToDeposit,
        depositDifferenceDescription,
        depositDifferenceAmount,
        depositDifferenceReasonId,
        dailyLiquidationSessionSet,
        depositType,
      };

      const depositUrl = DEPOSIT_ENDPOINT;

      const depositResponse = await fetch(depositUrl, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(depositResponse);

      const deposit = await depositResponse.json();

      if (file) {
        const formData = new FormData();

        formData.append('file', file);

        const fileUploadUrl = `${DEPOSIT_FILE_UPLOAD_ENDPOINT}/${deposit.id}`;

        await fetch(fileUploadUrl, {
          ...BASIC_POST_CONFIG,
          body: formData,
        });
      }

      dispatch(push(`${DEPOSIT_PATH}/${deposit.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDepositActivity(false));
    }
  };

const clearDeposit = () => (dispatch) =>
  dispatch({
    type: CLEAR_DEPOSIT,
  });

const getDeposit =
  async ({ depositId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDepositActivity(true));

      const url = generateGetDepositEndpoint(depositId);

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const deposit = await response.json();

      dispatch({ type: GET_DEPOSIT, payload: deposit });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDepositActivity(false));
    }
  };

const putDeposit =
  async (
    depositId,
    {
      depositNoteList,
      settled,
      accountNumber,
      bankName,
      operationNumber,
      depositDate,
      actualAmountDeposited,
      expectedAmountToDeposit,
      depositDifferenceAmount,
      depositDifferenceDescription,
      depositDifferenceReasonId,
      dailyLiquidationSessionSet,
      depositType,
      operatorUserId,
      file,
      createDate,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagDepositActivity(true));

      const payload = {
        depositNoteList,
        settled,
        accountNumber,
        bankName,
        operationNumber,
        depositDate,
        actualAmountDeposited,
        expectedAmountToDeposit,
        depositDifferenceAmount,
        depositDifferenceDescription,
        depositDifferenceReasonId,
        dailyLiquidationSessionSet,
        operatorUserId,
        depositType,
        createDate,
        id: depositId,
      };

      const url = generateGetDepositEndpoint(depositId);

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      const deposit = await response.json();

      if (file) {
        const formData = new FormData();

        formData.append('file', file);

        const fileUploadUrl = `${DEPOSIT_FILE_UPLOAD_ENDPOINT}/${deposit.id}`;

        await fetch(fileUploadUrl, {
          ...BASIC_POST_CONFIG,
          body: formData,
        });
      }

      dispatch(push(`${DEPOSIT_PATH}/${deposit.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDepositActivity(false));
    }
  };

const deleteDeposit =
  async ({ depositId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDepositActivity(true));

      const url = generateGetDepositEndpoint(depositId);

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response);

      await response.json();

      dispatch(push(DEPOSIT_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDepositActivity(false));
    }
  };

const getDepositVoucher =
  async ({ depositId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDepositActivity(true));

      const url = `${GET_DEPOSIT_VOUCHER_ENDPOINT}/${depositId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const voucher = await response.json();

      dispatch({ type: GET_DEPOSIT_VOUCHER, payload: voucher });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagDepositActivity(false));
    }
  };

export {
  getDeposits,
  clearDeposits,
  postDeposit,
  getDeposit,
  clearDeposit,
  putDeposit,
  deleteDeposit,
  getDepositVoucher,
};
