import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { clearPrivateServices, getPrivateServices } from '../../../../actions';
import {
  PRIVATE_SERVICE_PATH,
  NEW_PRIVATE_SERVICE_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { PRIVATE_SERVICE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Table from '../../../common/Table';

export class PrivateServices extends Component {
  componentWillUnmount() {
    this.props.dispatchClearPrivateServices();
  }

  onFetchData = (data) => {
    const newData = { ...data };

    // TODO remove this hack when ModuleList implements multiple filter on a same column
    const businessTaxIdFound =
      newData.query &&
      newData.query.length &&
      newData.query.filter(
        (column) =>
          column.substring(0, column.indexOf(':')) === 'business.businessTaxId',
      );

    if (businessTaxIdFound && businessTaxIdFound[0]) {
      const businessTaxIdValue = businessTaxIdFound[0].substring(
        businessTaxIdFound[0].indexOf(':') + 1,
      );
      const businessTaxIdIndex = newData.query.indexOf(businessTaxIdFound[0]);
      const column =
        businessTaxIdValue.length === 8
          ? 'customer.idDocumentNumber'
          : 'business.businessTaxId';
      newData.query[businessTaxIdIndex] = `${column}:${businessTaxIdValue}`;
    }

    this.props.dispatchGetPrivateServices(newData);
  };

  render() {
    const { services, loading, breadcrumbs, showOnModal, getRowData } =
      this.props;

    const data = services.get('content') || [];
    const pages = services.get('totalPages') || null;
    const defaultPageSize = services.get('size') || DEFAULT_PAGE_SIZE;

    const tableProps = {
      columns: PRIVATE_SERVICE_COLUMNS,
      data,
      pages,
      defaultPageSize,
      filterable: true,
      fetchData: this.onFetchData,
      loading,
      navigateToModelOnRowClick: true,
    };

    if (showOnModal)
      return (
        <Table {...tableProps} getRowData={getRowData} highlightSelectedRow />
      );

    return (
      <ModuleList
        title="Servicios Privados"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_PRIVATE_SERVICE_PATH}
        tableStructure={{
          ...tableProps,
          modelPath: PRIVATE_SERVICE_PATH,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetPrivateServices: getPrivateServices,
  dispatchClearPrivateServices: clearPrivateServices,
};

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Servicios Privados',
      href: PRIVATE_SERVICE_PATH,
    },
  ],
  services: ContractUnit.PrivateService.getIn(['all', 'content']),
  loading: ContractUnit.PrivateService.getIn(['all', 'loading']),
});

PrivateServices.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  services: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetPrivateServices: PropTypes.func.isRequired,
  dispatchClearPrivateServices: PropTypes.func.isRequired,
  showOnModal: PropTypes.bool,
  getRowData: PropTypes.func,
};

PrivateServices.defaultProps = {
  services: null,
  loading: false,
  showOnModal: false,
  getRowData: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateServices);
