import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SEGMENT_PATH } from '../../../../config/paths';

const SegmentEditButton = ({ segmentId }) => (
  <Link className="btn btn-primary" to={`${SEGMENT_PATH}/${segmentId}/edit`}>
    Editar
  </Link>
);

SegmentEditButton.propTypes = {
  segmentId: PropTypes.number.isRequired,
};

export default SegmentEditButton;
