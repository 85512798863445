import { combineReducers } from 'redux';
import Itinerary from './Itinerary';
import DelayReason from './DelayReason';
import ItineraryQuality from './ItineraryQuality';
import ServiceMerge from './ServiceMerge';
import UnitHome from './UnitHome';
import ItinerarySearchOmission from './ItinerarySearchOmission';
import LocationForItinerarySearchOmission from './LocationForItinerarySearchOmission';
import ItineraryType from './ItineraryType';
import ItinerarySchedule from './ItinerarySchedule';
import TripStatus from './TripStatus';
import ExtraordinaryMovement from './ExtraordinaryMovement';
import KidsOnItinerary from './KidsOnItinerary';
import ItinerarySalesControl from './ItinerarySalesControl';

export default combineReducers({
  Itinerary,
  DelayReason,
  ItineraryQuality,
  ServiceMerge,
  UnitHome,
  ItinerarySearchOmission,
  LocationForItinerarySearchOmission,
  ItineraryType,
  ItinerarySchedule,
  TripStatus,
  ExtraordinaryMovement,
  KidsOnItinerary,
  ItinerarySalesControl,
});
