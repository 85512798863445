import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
} from '../../../../actions/mechanical-maintenance/MaintenanceServiceOrder';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import { ACTIVITY_TASK_EXECUTION_STATUS } from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  clearActivityTaskExecution,
  getActivityTaskExecution,
  putActivityTaskExecution,
} from '../../../../actions/mechanical-maintenance/ActivityTaskExecution';
import ActivityTaskExecutionForm from '../activity-task-execution/ActivityTaskExecutionForm';
import MaintenanceServiceOrderBasicInformation from './resource/MaintenanceServiceOrderBasicInformationResource';
import MaintenanceServiceOrderMaintenanceWarningBadgeListInformation from './resource/MaintenanceServiceOrderMaintenanceWarningBadgeListInformation';

const EditMaintenanceServiceOrderActivityTaskExecution = ({
  breadcrumbs,
  dispatchPutActivityTaskExecution,
  dispatchGetMaintenanceServiceOrder,
  dispatchClearMaintenanceServiceOrder,
  maintenanceServiceOrder,
  loading,
  match: {
    params: { id: maintenanceServiceOrderId, ateId: activityTaskExecutionId },
  },
  dispatchGetActivityTaskExecution,
  dispatchClearActivityTaskExecution,
  loadingActivityTaskExecution,
  activityTaskExecution,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenanceServiceOrder({ maintenanceServiceOrderId });
    dispatchGetActivityTaskExecution({ activityTaskExecutionId });

    return () => {
      dispatchClearMaintenanceServiceOrder();
      dispatchClearActivityTaskExecution();
    };
  }, []);

  const onSubmit = (formValues) => {
    const { createDate, userId, activityTaskId, activityBusExecutionId } =
      activityTaskExecution.toJS();

    const newActivityTaskExecutionPayload = {
      status: formValues.status.value,
      startDate: formValues.startDate
        ? tzNormalizeDate({ date: formValues.startDate })
        : null,
      comment: formValues.comment,
      endDate: formValues.endDate
        ? tzNormalizeDate({ date: formValues.endDate })
        : null,
      workingHour: formValues.workingHour,
      employeeId: formValues.employeeId.value,
      serviceOrderId: maintenanceServiceOrderId,
      createDate,
      userId,
      activityBusExecutionId,
      activityTaskId,
    };

    dispatchPutActivityTaskExecution(
      activityTaskExecutionId,
      newActivityTaskExecutionPayload,
      true,
    );
  };

  const generateInitialValues = (activityTaskExecutionJson) => {
    const { status, workingHour, comment, startDate, endDate, employee } =
      activityTaskExecutionJson;

    const newStartDate = startDate
      ? tzNormalizeDate({ date: startDate })
      : tzNormalizeDate();

    const newEndDate = endDate
      ? tzNormalizeDate({ date: endDate })
      : tzNormalizeDate({ addTime: { amount: 1, unit: 'h' } });

    const newWorkingHour = workingHour || 1;

    return {
      status: ACTIVITY_TASK_EXECUTION_STATUS[status],
      workingHour: newWorkingHour,
      startDate: newStartDate.slice(0, 16),
      endDate: newEndDate.slice(0, 16),
      comment,
      employeeId: {
        value: employee.id,
        label: employee.customer.fullName,
      },
    };
  };

  let content;

  if (loading || loadingActivityTaskExecution) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty() || activityTaskExecution.isEmpty())
    content = (
      <NoDataResource
        returnPage={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/execution`}
      />
    );
  else {
    const {
      registeredBus,
      deadline,
      priority,
      description,
      status,
      maintenanceWarningList,
    } = maintenanceServiceOrder.toJS();

    const activityTaskExecutionJson = activityTaskExecution.toJS();

    const badgeListComponent = (
      <MaintenanceServiceOrderMaintenanceWarningBadgeListInformation
        maintenanceWarningList={maintenanceWarningList}
      />
    );

    content = (
      <>
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Datos de la Orden de Servicio</h3>
            <MaintenanceServiceOrderBasicInformation
              deadline={deadline}
              priority={priority}
              description={description}
              status={status}
              registeredBus={registeredBus}
            />
          </Col>
          <Col lg={6}>{badgeListComponent}</Col>
        </Row>
        <h3>Tarea: {activityTaskExecutionJson.activityTask.description}</h3>
        <ActivityTaskExecutionForm
          onSubmit={onSubmit}
          initialValues={generateInitialValues(activityTaskExecutionJson)}
        />
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Ejecución de Tarea"
      subtitle="Editar ejecución de tarea"
      content={content}
    />
  );
};

EditMaintenanceServiceOrderActivityTaskExecution.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes,
  dispatchPutActivityTaskExecution: PropTypes.func.isRequired,
  dispatchGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispatchClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetActivityTaskExecution: PropTypes.func.isRequired,
  dispatchClearActivityTaskExecution: PropTypes.func.isRequired,
  activityTaskExecution: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingActivityTaskExecution: PropTypes.bool.isRequired,
};

EditMaintenanceServiceOrderActivityTaskExecution.defaultProps = {
  match: null,
};

const mapDispatchToProps = {
  dispatchPutActivityTaskExecution: putActivityTaskExecution,
  dispatchGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispatchClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
  dispatchGetActivityTaskExecution: getActivityTaskExecution,
  dispatchClearActivityTaskExecution: clearActivityTaskExecution,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Ejecuciones',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}/execution`,
    },
    {
      text: 'Editar Ejecución de Tarea',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  activityTaskExecution: MechanicalMaintenanceUnit.ActivityTaskExecution.getIn([
    'current',
    'content',
  ]),
  loadingActivityTaskExecution:
    !MechanicalMaintenanceUnit.ActivityTaskExecution.getIn([
      'current',
      'activity',
    ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceServiceOrderActivityTaskExecution);
