import PropTypes from 'prop-types';

const optionPropTypes = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const optionsPropTypes = PropTypes.arrayOf(optionPropTypes);

export { optionPropTypes, optionsPropTypes };
