import { string, shape, number, arrayOf } from 'prop-types';
import { organizationChartBasicInformationPropTypes } from '../../organization-chart/proptypes/OrganizationChartPropTypes';
import materialBasicInformationPropTypes from '../../material/proptypes/MaterialPropTypes';

const activityBasicInformationPropTypes = {
  id: number.isRequired,
  companyActivityId: string,
  name: string.isRequired,
  description: string,
  factor: number.isRequired,
  factorType: string.isRequired,
  activityType: string.isRequired,
  companyArea: shape(organizationChartBasicInformationPropTypes).isRequired,
};

const activityMaterialInformationPropTypes = {
  materialList: arrayOf(
    shape({
      material: shape(materialBasicInformationPropTypes),
      quantity: number.isRequired,
    }),
  ),
};

const activityTaskInformationPropTypes = {
  activityTaskList: arrayOf(
    shape({
      companyArea: shape(organizationChartBasicInformationPropTypes),
      companyAreaId: number.isRequired,
      description: string.isRequired,
      id: number.isRequired,
      orderDependent: number.isRequired,
      taskOrder: number,
      workingHours: number.isRequired,
    }),
  ),
};

const activityBasicInformationDefaultProps = {
  description: null,
  activityType: null,
  companyActivityId: null,
};

const activityMaterialInformationDefaultProps = [];

export {
  activityBasicInformationPropTypes,
  activityBasicInformationDefaultProps,
  activityMaterialInformationPropTypes,
  activityMaterialInformationDefaultProps,
  activityTaskInformationPropTypes,
};
