import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getCostCenters, clearCostCenters } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

class CostCenterSelect extends Component {
  componentDidMount() {
    const { dispatchGetCostCenters } = this.props;
    dispatchGetCostCenters({ ...DEFAULT_QUERY_GET_ALL });
  }

  componentWillUnmount() {
    const { dispatchClearCostCenters } = this.props;
    dispatchClearCostCenters();
  }

  render() {
    const { costCenters, isLoading, isMulti, input, meta, ...rest } =
      this.props;
    return (
      <Select
        options={costCenters}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
        {...rest}
      />
    );
  }
}

CostCenterSelect.propTypes = {
  dispatchGetCostCenters: PropTypes.func.isRequired,
  dispatchClearCostCenters: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  costCenters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
};

CostCenterSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  costCenters: [],
};

const mapStateToProps = ({ AccountingUnit }) => ({
  costCenters: AccountingUnit.CostCenter.getIn([
    'all',
    'content',
    'content',
  ]).map((costCenter) => ({
    value: costCenter.id,
    label: costCenter.name,
  })),
  loading: AccountingUnit.CostCenter.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetCostCenters: getCostCenters,
  dispatchClearCostCenters: clearCostCenters,
};

export default connect(mapStateToProps, mapDispatchToprops)(CostCenterSelect);
