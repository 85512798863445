import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_BASE_PRICES_PER_KILOMETER,
  GET_BASE_PRICES_PER_KILOMETER,
  CLEAR_BASE_PRICES_PER_KILOMETER,
  GET_BASE_PRICE_PER_KILOMETER,
  CLEAR_BASE_PRICE_PER_KILOMETER,
  FLAG_BASE_PRICE_PER_KILOMETER_ACTIVITY,
} from '../types/cargo';
import { BASE_PRICE_PER_KILOMETER_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { BASE_PRICE_PER_KILOMETER_PATH } from '../../config/paths';

const flagGettingBasePricesPerKilometer = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_BASE_PRICES_PER_KILOMETER,
    payload: flag,
  });

const getBasePricesPerKilometer = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingBasePricesPerKilometer(true));
    const query = tableFilters;
    const url = `${BASE_PRICE_PER_KILOMETER_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const basePricesPerKilometer = await response.json();
    dispatch({
      type: GET_BASE_PRICES_PER_KILOMETER,
      payload: basePricesPerKilometer,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingBasePricesPerKilometer(false));
  }
};

const clearBasePricesPerKilometer = () => dispatch =>
  dispatch({
    type: CLEAR_BASE_PRICES_PER_KILOMETER,
  });

const flagBasePricePerKilometerActivity = flag => dispatch =>
  dispatch({
    type: FLAG_BASE_PRICE_PER_KILOMETER_ACTIVITY,
    payload: flag,
  });

const getBasePricePerKilometer = async ({ id }) => async dispatch => {
  try {
    dispatch(flagBasePricePerKilometerActivity(true));
    const url = `${BASE_PRICE_PER_KILOMETER_ENDPOINT}/${id}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const basePricePerKilometer = await response.json();
    dispatch({
      type: GET_BASE_PRICE_PER_KILOMETER,
      payload: basePricePerKilometer,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagBasePricePerKilometerActivity(false));
  }
};

const clearBasePricePerKilometer = () => dispatch =>
  dispatch({
    type: CLEAR_BASE_PRICE_PER_KILOMETER,
  });

const postBasePricePerKilometer = async ({
  fromKilometer,
  toKilometer,
  price,
  kilometerFactor,
}) => async dispatch => {
  try {
    dispatch(flagBasePricePerKilometerActivity(true));
    const payload = {
      fromKilometer,
      toKilometer,
      price,
      kilometerFactor,
    };
    const url = BASE_PRICE_PER_KILOMETER_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const basePricePerKilometer = await response.json();
    dispatch(
      push(`${BASE_PRICE_PER_KILOMETER_PATH}/${basePricePerKilometer.id}`),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagBasePricePerKilometerActivity(false));
  }
};

const putBasePricePerKilometer = async (
  basePricePerKilometerId,
  { fromKilometer, toKilometer, price, kilometerFactor },
) => async dispatch => {
  try {
    dispatch(flagBasePricePerKilometerActivity(true));
    const payload = {
      id: basePricePerKilometerId,
      fromKilometer,
      toKilometer,
      price,
      kilometerFactor,
    };
    const url = `${BASE_PRICE_PER_KILOMETER_ENDPOINT}/${basePricePerKilometerId}`;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const basePricePerKilometer = await response.json();
    dispatch(
      push(`${BASE_PRICE_PER_KILOMETER_PATH}/${basePricePerKilometer.id}`),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagBasePricePerKilometerActivity(false));
  }
};

const deleteBasePricePerKilometer = async ({
  basePricePerKilometerId,
}) => async dispatch => {
  try {
    dispatch(flagBasePricePerKilometerActivity(true));
    const url = `${BASE_PRICE_PER_KILOMETER_ENDPOINT}/${basePricePerKilometerId}`;
    const response = await fetch(url, {
      ...DEFAULT_DELETE_CONFIG,
    });
    await isErrorResponse(response);
    await response;
    dispatch(push(BASE_PRICE_PER_KILOMETER_PATH));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagBasePricePerKilometerActivity(false));
  }
};

export {
  flagGettingBasePricesPerKilometer,
  getBasePricesPerKilometer,
  clearBasePricesPerKilometer,
  getBasePricePerKilometer,
  clearBasePricePerKilometer,
  postBasePricePerKilometer,
  putBasePricePerKilometer,
  deleteBasePricePerKilometer,
};
