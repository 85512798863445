import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditBaggageButton from './EditBaggageButton';
import DeleteBaggageButton from './DeleteBaggageButton';
import PrintExcessBaggageTicketButton from './PrintExcessBaggageTicketButton';

const BaggageToolbar = ({ baggageId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PrintExcessBaggageTicketButton />{' '}
      <EditBaggageButton baggageId={baggageId} />{' '}
      <DeleteBaggageButton baggageId={baggageId} />
    </div>
  </ButtonToolbar>
);

BaggageToolbar.propTypes = {
  baggageId: PropTypes.number.isRequired,
};

export default BaggageToolbar;
