export const FLAG_GETTING_AUTHORIZED_PROFILES =
  'FLAG_GETTING_AUTHORIZED_PROFILES';
export const GET_AUTHORIZED_PROFILES = 'GET_AUTHORIZED_PROFILES';
export const CLEAR_AUTHORIZED_PROFILES = 'CLEAR_AUTHORIZED_PROFILES';
export const FLAG_AUTHORIZED_PROFILE_ACTIVITY =
  'FLAG_AUTHORIZED_PROFILE_ACTIVITY';
export const GET_AUTHORIZED_PROFILE = 'GET_AUTHORIZED_PROFILE';
export const CLEAR_AUTHORIZED_PROFILE = 'CLEAR_AUTHORIZED_PROFILE';
export const GET_AUTHORIZED_PROFILES_BY_PROFILE_ID =
  'GET_AUTHORIZED_PROFILES_BY_PROFILE_ID';
export const CLEAR_AUTHORIZED_PROFILES_BY_PROFILE_ID =
  'CLEAR_AUTHORIZED_PROFILES_BY_PROFILE_ID';

// authorized security profiles by logged user
export const FLAG_GETTING_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER =
  'FLAG_GETTING_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER';
export const GET_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER =
  'GET_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER';
export const CLEAR_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER =
  'CLEAR_ALL_AUTHORIZED_PROFILES_PER_LOGGED_IN_USER';
