// types should always be imperative verbs

export const SHOW_ERROR_POSTING_PRICING_PROFILE_REPORT =
  'SHOW_ERROR_POSTING_PRICING_PROFILE_REPORT';
export const CLEAR_ERROR_POSTING_PRICING_PROFILE_REPORT =
  'CLEAR_ERROR_POSTING_PRICING_PROFILE_REPORT';
export const SHOW_ERROR_GETTING_PRICING_PROFILE_REPORT =
  'SHOW_ERROR_GETTING_PRICING_PROFILE_REPORT';
export const SHOW_ERROR_GETTING_PRICING_PROFILE_REPORTS =
  'SHOW_ERROR_GETTING_PRICING_PROFILE_REPORTS';
export const CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORT =
  'CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORT';
export const CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORTS =
  'CLEAR_ERROR_GETTING_PRICING_PROFILE_REPORTS';
export const CLEAR_PRICING_PROFILE_REPORTS = 'CLEAR_PRICING_PROFILE_REPORTS';
export const CLEAR_PRICING_PROFILE_REPORT = 'CLEAR_PRICING_PROFILE_REPORT';
export const POST_PRICING_PROFILE_REPORT = 'POST_PRICING_PROFILE_REPORT';
export const GET_PRICING_PROFILE_REPORTS = 'GET_PRICING_PROFILE_REPORTS';
export const GET_PRICING_PROFILE_REPORT = 'GET_PRICING_PROFILE_REPORT';
export const DOWNLOAD_PRICING_PROFILE_REPORT =
  'DOWNLOAD_PRICING_PROFILE_REPORT';
export const FLAG_GETTING_PRICING_PROFILE_REPORT =
  'FLAG_GETTING_PRICING_PROFILE_REPORT';
export const FLAG_GETTING_PRICING_PROFILE_REPORTS =
  'FLAG_GETTING_PRICING_PROFILE_REPORTS';
export const FLAG_POSTING_PRICING_PROFILE_REPORT =
  'FLAG_POSTING_PRICING_PROFILE_REPORT';
