import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';
import { connect } from 'react-redux';
import { Form, FormGroup, Row, Col, Button } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { postEndSalesSession } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { validateNumber, isRequired } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import { liquidationDifferenceReasonsPropTypes } from './SalesSessionPropTypes';
import CashBalance from './CashBalance';
import { numberFormatter } from '../../../../utils/number';

const selector = formValueSelector('EndSalesSessionForm');

class CashBalanceForm extends Component {
  state = {
    showLiquidationDifference: false,
  };

  validateEndSalesSession = () => {
    const { cashOnHandValue, cashSales, handleSubmit } = this.props;
    const submit = handleSubmit(this.handleEndSalesSession);
    if (Number(cashOnHandValue) === cashSales) {
      this.setState({ showLiquidationDifference: false });
    } else {
      this.setState({ showLiquidationDifference: true });
    }

    submit();
  };

  handleEndSalesSession = (formValues) => {
    const { cashSales, dispatchPostEndSalesSession, salesSessionId } =
      this.props;
    const cashOnHand = Number(formValues.cashOnHand);

    if (cashOnHand === cashSales) {
      dispatchPostEndSalesSession({ salesSessionId, cashOnHand });
    } else if (
      formValues.differenceReason &&
      formValues.differenceDescription
    ) {
      dispatchPostEndSalesSession({
        salesSessionId,
        cashOnHand,
        differenceReasonId: formValues.differenceReason.value,
        differenceDescription: formValues.differenceDescription,
        differenceAmount: new Decimal(cashSales)
          .minus(Number(cashOnHand))
          .valueOf(),
      });
    }
  };

  render() {
    const {
      cashSales,
      handleSubmit,
      cashOnHandValue,
      liquidationDifferenceReasons,
    } = this.props;
    const { showLiquidationDifference } = this.state;

    let liquidationDifference;
    if (showLiquidationDifference) {
      const liquidationDifferenceReasonOptions =
        liquidationDifferenceReasons.map((liquidationDifferenceReason) => ({
          value: liquidationDifferenceReason.id,
          label: liquidationDifferenceReason.name,
        }));

      liquidationDifference = (
        <>
          <Row>
            <Col>
              <h2>Discrepancia</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <ResourceProperty label="Monto de Discrepancia: ">
                {numberFormatter({ value: +cashSales - +cashOnHandValue })}
              </ResourceProperty>
            </Col>
          </Row>
          <FormGroup row>
            <FormItem label="Justificación:" labelSmWidth={2} required>
              <Field
                name="differenceReason"
                component={Select}
                options={liquidationDifferenceReasonOptions}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Descripción:" labelSmWidth={2} required>
              <Field
                name="differenceDescription"
                component={TextInput}
                type="textarea"
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
        </>
      );
    }

    return (
      <>
        <CashBalance cashOnHand={cashSales} />
        <Form onSubmit={handleSubmit(this.handleEndSalesSession)}>
          <FormGroup row>
            <FormItem label="Efectivo:" labelSmWidth={2} required>
              <Field
                name="cashOnHand"
                component={TextInput}
                type="text"
                placeholder="0.00"
                validate={[isRequired, validateNumber]}
              />
            </FormItem>
          </FormGroup>
          {liquidationDifference}
          <FormGroup row>
            <Col className="flex row-reverse">
              <Button
                type="button"
                onClick={this.validateEndSalesSession}
                color="primary"
              >
                Cerrar Sesión de Ventas
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </>
    );
  }
}

CashBalanceForm.propTypes = {
  salesSessionId: PropTypes.number.isRequired,
  cashSales: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchPostEndSalesSession: PropTypes.func.isRequired,
  cashOnHandValue: PropTypes.string,
  liquidationDifferenceReasons:
    liquidationDifferenceReasonsPropTypes.isRequired,
};

CashBalanceForm.defaultProps = {
  cashOnHandValue: '0',
};

const mapStateToProps = (state) => {
  const cashOnHandValue = selector(state, 'cashOnHand');
  return {
    cashOnHandValue,
  };
};

const mapDispatchToProps = {
  dispatchPostEndSalesSession: postEndSalesSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'EndSalesSessionForm' })(CashBalanceForm));
