import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { MATERIAL_PATH } from '../../../../config/paths';
import MaterialDeleteButton from './MaterialDeleteButton';

const MaterialToolbar = ({ materialId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${MATERIAL_PATH}/${materialId}/edit`} />{' '}
      <MaterialDeleteButton materialId={materialId} />
    </div>
  </ButtonToolbar>
);

MaterialToolbar.propTypes = {
  materialId: PropTypes.number.isRequired,
};

export default MaterialToolbar;
