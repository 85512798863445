import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { DELIVERY_GROUP_PATH } from '../../../../config/paths';
import { postDeliveryGroup } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import DeliveryGroupForm from './DeliveryGroupForm';

// eslint-disable-next-line react/prefer-stateless-function
export class NewDeliveryGroup extends Component {
  render() {
    const { breadcrumbs, dispatchPostDeliveryGroup } = this.props;
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Nuevo Grupo Delivery</h1>
            <p>Crear Nuevo Grupo Delivery.</p>
          </Col>
        </Row>
        <DeliveryGroupForm onSubmit={dispatchPostDeliveryGroup} />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos Delivery',
      href: DELIVERY_GROUP_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostDeliveryGroup: postDeliveryGroup,
};

NewDeliveryGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDeliveryGroup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDeliveryGroup);
