import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  clearCurrentUserSpotInItinerary,
  clearItinerary,
  getCurrentUserSpotInItinerary,
  getItineraryForCounter,
  postBaggageItemMovement,
} from '../../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import BaggageItemMovementForm from '../../../baggage/baggage-item-movement/BaggageItemMovementForm';
import {
  ITINERARY_FOR_COUNTER_PATH,
  ITINERARY_PATH,
} from '../../../../../config/paths';
import Loader from '../../../../common/Loader';
import {
  BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS,
  BAGGAGE_MOVEMENT_TYPE_OPTIONS,
  SPOT_IN_ITINERARY,
} from '../../../../../config/constants';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { BAGGAGE_ITEMS_MOVEMENT_ENDPOINT } from '../../../../../config/endpoints';
import { POST } from '../../../../../config/permissions';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Content from '../../../../layout/Content';
import { getSubstring } from '../../../../../utils/string';
import { tzNormalizeDate } from '../../../../../utils/date';

export class BaggageItemMovement extends Component {
  componentDidMount() {
    const {
      dispatchGetItineraryForCounter,
      dispatchGetCurrentUserSpotInItinerary,
      match: {
        params: { id: itineraryId },
      },
    } = this.props;

    dispatchGetItineraryForCounter(itineraryId);
    dispatchGetCurrentUserSpotInItinerary({ itineraryId });
  }

  componentWillUnmount() {
    const { dispatchClearItinerary, dispatchClearCurrentUserSpotInItinerary } =
      this.props;

    dispatchClearItinerary();
    dispatchClearCurrentUserSpotInItinerary();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPostBaggageItemMovement,
      match: {
        params: { id },
      },
    } = this.props;

    const newFormValues = {
      locationId: formValues.locationId && formValues.locationId.value,
      remarks: formValues.remarks,
      itineraryId: formValues.itineraryId
        ? getSubstring(formValues.itineraryId)
        : null,
      status: formValues.status.value,
      itemIdList: formValues.items,
    };

    dispatchPostBaggageItemMovement(
      newFormValues,
      `${ITINERARY_FOR_COUNTER_PATH}/${id}`,
    );
  };

  generateInitialValues = (itinerary, currentUserSpot) => {
    const itineraryRoute = `${itinerary.get('routeName')} ${tzNormalizeDate({
      date: itinerary.get('estimatedDepartureTime'),
      format: DATE_TIME_FORMAT,
    })}`;

    const itineraryId = itinerary.get('id');

    const initialValues = {
      // TODO remove next code commented because its functionality is not clear in onDidMount func of BaggageItemMovementForm.js
      searchBy: BAGGAGE_ITEM_MOVEMENT_SEARCH_OPTIONS[0],
      itinerarySearchParamsId: `${itineraryId} - ${itineraryRoute}`,
      // status: SPOT_IN_ITINERARY[currentUserSpot],
      status: SPOT_IN_ITINERARY.OTHER,
      currentUserSpot,
      movementType: BAGGAGE_MOVEMENT_TYPE_OPTIONS[1],
    };

    if (currentUserSpot === 'END') {
      const itineraryScheduleSegmentList = itinerary.get(
        'itineraryScheduleSegmentList',
      );

      const lastItinerary =
        itineraryScheduleSegmentList[itineraryScheduleSegmentList.length - 1];

      initialValues.locationId = {
        value: lastItinerary.segment.destinationLocationId,
        label: lastItinerary.segment.destinationLocation.name,
      };

      initialValues.status = SPOT_IN_ITINERARY.END;
    }

    return initialValues;
  };

  render() {
    const {
      breadcrumbs,
      itinerary,
      currentUserSpot,
      loading,
      match: {
        params: { id },
      },
    } = this.props;

    let content;

    if (loading || !currentUserSpot) content = <Loader />;
    else if (itinerary.isEmpty())
      content = <NoDataResource returnPage={ITINERARY_PATH} />;
    else
      content = (
        <BaggageItemMovementForm
          onSubmit={this.onSubmit}
          cancelRedirectPath={`${ITINERARY_FOR_COUNTER_PATH}/${id}`}
          initialValues={this.generateInitialValues(itinerary, currentUserSpot)}
        />
      );

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Movimiento de Equipajes"
        subtitle="Moviemiento de equipajes"
        content={content}
      />
    );
  }
}

BaggageItemMovement.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
  dispatchPostBaggageItemMovement: PropTypes.func.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  dispatchGetCurrentUserSpotInItinerary: PropTypes.func.isRequired,
  dispatchClearCurrentUserSpotInItinerary: PropTypes.func.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  currentUserSpot: PropTypes.string,
};

BaggageItemMovement.defaultProps = {
  loading: false,
  currentUserSpot: null,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_FOR_COUNTER_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_FOR_COUNTER_PATH}/${id}`,
    },
    {
      text: 'Movimiento de equipajes',
      href: '',
    },
  ],
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  currentUserSpot: ItineraryUnit.Itinerary.getIn(['current', 'userSpot']),
});

const mapDispatchToProps = {
  dispatchPostBaggageItemMovement: postBaggageItemMovement,
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchClearItinerary: clearItinerary,
  dispatchGetCurrentUserSpotInItinerary: getCurrentUserSpotInItinerary,
  dispatchClearCurrentUserSpotInItinerary: clearCurrentUserSpotInItinerary,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaggageItemMovement);

export default withEndpointAuthorization({
  url: BAGGAGE_ITEMS_MOVEMENT_ENDPOINT,
  permissionType: POST,
})(connectedComponent);
