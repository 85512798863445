import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MATERIAL_PATH } from '../../../../config/paths';
import {
  clearMaterial,
  getMaterial,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import MaterialToolbar from './MaterialToolbar';
import MaterialBasicInformationResource from './resource/MaterialBasicInformationResource';

export const Material = ({
  breadcrumbs,
  dispatchGetMaterial,
  dispatchClearMaterial,
  match: {
    params: { id: materialId },
  },
  loading,
  material,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaterial({ materialId });

    return () => dispatchClearMaterial();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (material.isEmpty())
    content = <NoDataResource returnPage={MATERIAL_PATH} />;
  else {
    const materialJson = material.toJS();

    content = <MaterialBasicInformationResource {...materialJson} />;

    toolbar = <MaterialToolbar materialId={materialJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Material"
      content={content}
    />
  );
};

Material.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMaterial: PropTypes.func.isRequired,
  dispatchGetMaterial: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  material: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Material.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMaterial: clearMaterial,
  dispatchGetMaterial: getMaterial,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Materiales',
      href: MATERIAL_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  material: MechanicalMaintenanceUnit.Material.getIn(['current', 'content']),
  loading: !MechanicalMaintenanceUnit.Material.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Material);
