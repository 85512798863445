import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  NEW_ACTIVITY_TASK_PATH,
  ACTIVITY_TASK_PATH,
} from '../../../../config/paths';
import {
  getActivityTasks,
  clearActivityTasks,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { ACTIVITY_TASK_COLUMNS } from '../../../../config/columns';

export const ActivityTasks = ({
  breadcrumbs,
  tasks,
  loading,
  dispatchGetActivityTasks,
  dispatchClearActivityTasks,
}) => {
  useLayoutEffect(() => () => dispatchClearActivityTasks(), []);

  const data = tasks.get('content') || [];
  const pages = tasks.get('totalPages') || null;
  const defaultPageSize = tasks.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Tareas de Actividad"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_ACTIVITY_TASK_PATH}
      tableStructure={{
        columns: ACTIVITY_TASK_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetActivityTasks,
        modelPath: ACTIVITY_TASK_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetActivityTasks: getActivityTasks,
  dispatchClearActivityTasks: clearActivityTasks,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Tareas de Actividad',
      href: ACTIVITY_TASK_PATH,
    },
  ],
  tasks: MechanicalMaintenanceUnit.ActivityTask.getIn(['all', 'content']),
  loading: MechanicalMaintenanceUnit.ActivityTask.getIn(['all', 'loading']),
});

ActivityTasks.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  tasks: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetActivityTasks: PropTypes.func.isRequired,
  dispatchClearActivityTasks: PropTypes.func.isRequired,
};

ActivityTasks.defaultProps = {
  tasks: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTasks);
