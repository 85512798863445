import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  NEW_ITINERARY_SEARCH_OMISSION_PATH,
  ITINERARY_SEARCH_OMISSION_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { ITINERARY_SEARCH_OMISSION_COLUMNS } from '../../../../config/columns';
import {
  clearItinerarySearchOmissions,
  getItinerarySearchOmissions,
} from '../../../../actions/itinerary/ItinerarySearchOmission';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class ItinerarySearchOmissions extends Component {
  constructor(props) {
    super(props);
    this.columns = ITINERARY_SEARCH_OMISSION_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearItinerarySearchOmissions();
  }

  render() {
    const {
      itinerarySearchOmissions,
      loading,
      breadcrumbs,
      dispatchGetItinerarySearchOmissions,
    } = this.props;

    const data = itinerarySearchOmissions.get('content') || [];
    const pages = itinerarySearchOmissions.get('totalPages') || null;
    const defaultPageSize =
      itinerarySearchOmissions.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Paradas Ocultas"
        buttonPath={NEW_ITINERARY_SEARCH_OMISSION_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetItinerarySearchOmissions,
          modelPath: ITINERARY_SEARCH_OMISSION_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetItinerarySearchOmissions: getItinerarySearchOmissions,
  dispatchClearItinerarySearchOmissions: clearItinerarySearchOmissions,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Paradas Ocultas',
      href: ITINERARY_SEARCH_OMISSION_PATH,
    },
  ],
  itinerarySearchOmissions: ItineraryUnit.ItinerarySearchOmission.getIn([
    'all',
    'content',
  ]),
  loading: ItineraryUnit.ItinerarySearchOmission.getIn(['all', 'loading']),
});

ItinerarySearchOmissions.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  itinerarySearchOmissions: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetItinerarySearchOmissions: PropTypes.func.isRequired,
  dispatchClearItinerarySearchOmissions: PropTypes.func.isRequired,
};

ItinerarySearchOmissions.defaultProps = {
  itinerarySearchOmissions: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerarySearchOmissions);
