import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearHierarchies, getHierarchies } from '../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../config/queries';

const HierarchyFilter = ({
  options,
  onChangeFilter,
  loading,
  dispatchClearHierarchies,
  dispatchGetHierarchies,
}) => {
  useLayoutEffect(() => {
    dispatchGetHierarchies({ ...DEFAULT_QUERY_GET_ALL });

    return () => dispatchClearHierarchies();
  }, []);

  return (
    <SelectFilter
      isMulti
      isLoading={loading}
      onChangeFilter={onChangeFilter}
      options={options}
    />
  );
};

HierarchyFilter.propTypes = {
  dispatchGetHierarchies: PropTypes.func.isRequired,
  dispatchClearHierarchies: PropTypes.func.isRequired,
  options: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

HierarchyFilter.defaultProps = {
  options: [],
  loading: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.Hierarchy.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, name }) => ({
    value: id,
    label: name,
  })),
  loading: MechanicalMaintenanceUnit.Hierarchy.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetHierarchies: getHierarchies,
  dispatchClearHierarchies: clearHierarchies,
};

export default connect(mapStateToProps, mapDispatchToprops)(HierarchyFilter);
