import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { IN_SYSTEM_NOTIFICATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import InSystemNotificationForm from './InSystemNotificationForm';
import Loader from '../../../common/Loader';
import {
  clearInSystemNotification,
  getInSystemNotification,
  putInSystemNotification,
} from '../../../../actions';
import { IN_SYSTEM_NOTIFICATION_SEVERITY } from '../../../../config/constants';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

export class EditInSystemNotification extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: inSystemNotificationId },
      },
      dispatchGetInSystemNotification,
    } = this.props;
    dispatchGetInSystemNotification({ inSystemNotificationId });
  }

  componentWillUnmount() {
    const { dispatchClearInSystemNotification } = this.props;
    dispatchClearInSystemNotification();
  }

  onSubmit = (formValues) => {
    const newFormValues = {
      active: formValues.active || false,
      message: formValues.message,
      notificationSeverity: formValues.notificationSeverity.value,
      validFrom: tzNormalizeDate({
        date: formValues.validFrom,
        format: TIMESTAMP_FORMAT,
      }),
      validUntil: tzNormalizeDate({
        date: formValues.validUntil,
        format: TIMESTAMP_FORMAT,
      }),
    };

    const {
      dispatchPutInSystemNotification,
      match: {
        params: { id },
      },
    } = this.props;

    dispatchPutInSystemNotification(id, newFormValues);
  };

  generateInitialValues = (inSystemNotification) => ({
    message: inSystemNotification.get('message'),
    notificationSeverity:
      IN_SYSTEM_NOTIFICATION_SEVERITY[
        inSystemNotification.get('notificationSeverity')
      ],
    active: inSystemNotification.get('active'),
    validFrom: tzNormalizeDate({ date: inSystemNotification.get('validFrom') }),
    validUntil: tzNormalizeDate({
      date: inSystemNotification.get('validUntil'),
    }),
  });

  render() {
    const { breadcrumbs, inSystemNotification, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (inSystemNotification.isEmpty()) {
      content = <NoDataResource returnPage={IN_SYSTEM_NOTIFICATION_PATH} />;
    } else {
      content = (
        <InSystemNotificationForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(inSystemNotification)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Notificación Interna del Sistema"
        subtitle="Editar notificación interna del sistema"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { SystemUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SystemUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Notificaciones Internas del Sistema',
      href: IN_SYSTEM_NOTIFICATION_PATH,
    },
    {
      text: 'Ver',
      href: `${IN_SYSTEM_NOTIFICATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  inSystemNotification: SystemUnit.InSystemNotification.getIn([
    'current',
    'content',
  ]),
  loading: !SystemUnit.InSystemNotification.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutInSystemNotification: putInSystemNotification,
  dispatchGetInSystemNotification: getInSystemNotification,
  dispatchClearInSystemNotification: clearInSystemNotification,
};

EditInSystemNotification.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutInSystemNotification: PropTypes.func.isRequired,
  dispatchGetInSystemNotification: PropTypes.func.isRequired,
  dispatchClearInSystemNotification: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  inSystemNotification: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditInSystemNotification.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditInSystemNotification);
