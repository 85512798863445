import Immutable from 'immutable';
import {
  FLAG_GETTING_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
  GET_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
  CLEAR_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION,
} from '../../../actions/types/itinerary/LocationForItinerarySearchOmission';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION:
      return state.setIn(['all', 'content'], Immutable.Set(action.payload));
    case CLEAR_LOCATIONS_FOR_ITINERARY_SEARCH_OMISSION:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    default:
      return state;
  }
};
