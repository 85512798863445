import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getPrintedTickets, clearPrintedTickets } from '../../../../actions';
import PrintedTickets from './PrintedTickets';
import Loader from '../../../common/Loader';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    const { reservationId } = this.props;
    if (reservationId) {
      this.props.getPrintedTickets(reservationId);
    }
  }

  componentWillUnmount() {
    this.props.clearPrintedTickets();
  }

  render() {
    if (this.props.loading) return <Loader />;

    return (
      <Container className="Confirmation">
        <h1>Confirmación</h1>
        <PrintedTickets printedTickets={this.props.printedTickets} />
      </Container>
    );
  }
}

const mapStateToProps = ({ BookingUnit }) => ({
  printedTickets: BookingUnit.Booking.getIn(['confirmation', 'printedTickets']),
  reservationId: BookingUnit.Booking.getIn(['confirmation', 'reservationId']),
  loading: BookingUnit.Booking.getIn(['confirmation', 'loading']),
});

const mapDispatchToProps = {
  getPrintedTickets,
  clearPrintedTickets,
};

Confirmation.propTypes = {
  loading: PropTypes.bool,
  clearPrintedTickets: PropTypes.func.isRequired,
  getPrintedTickets: PropTypes.func.isRequired,
  printedTickets: PropTypes.instanceOf(Immutable.Set).isRequired,
  reservationId: PropTypes.number.isRequired,
};

Confirmation.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
