import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getItinerariesForPostpaidBooking,
  clearPostpaidBookingState,
} from '../../../../actions';

import { POSTPAID_BOOKING_SEARCH_STEP_INDEX } from '../../../../config/constants';
import PostpaidItinerarySearchForm from './PostpaidBookingItinerarySearchForm';

class PostpaidBookingItinerarySearch extends Component {
  constructor(props) {
    super(props);
    const stepIndex = POSTPAID_BOOKING_SEARCH_STEP_INDEX;
    this.props.dispatchClearPostpaidBookingState(stepIndex);
  }

  render() {
    return (
      <div>
        <PostpaidItinerarySearchForm
          onSubmit={this.props.getItinerariesForPostpaidBooking}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getItinerariesForPostpaidBooking,
  dispatchClearPostpaidBookingState: clearPostpaidBookingState,
};

PostpaidBookingItinerarySearch.propTypes = {
  getItinerariesForPostpaidBooking: PropTypes.func.isRequired,
  dispatchClearPostpaidBookingState: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(PostpaidBookingItinerarySearch);
