import React from 'react';
import PropTypes from 'prop-types';
import { gridColumnPropTypes } from './GridPropTypes';

const GridCell = ({ column, index, onValueChange, value, disabled }) => {
  const width = column.width ? `width="${column.width}"` : null;

  if (column.editableComponent) {
    return (
      <td data-index={index} style={{ minWidth: width, width }}>
        {column.editableComponent(onValueChange, value, disabled)}
      </td>
    );
  }

  if (column.cell) {
    return <td style={{ minWidth: width, width }}>{column.cell(value)}</td>;
  }

  return <td style={{ minWidth: width, width }}>{value || ''}</td>;
};

GridCell.propTypes = {
  column: gridColumnPropTypes.isRequired,
  index: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
};

GridCell.defaultProps = {
  value: undefined,
  disabled: false,
};

export default GridCell;
