import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Loader.css';

const Loader = () => (
  <Container className="Loader">
    <Row>
      <Col>
        <div className="d-flex justify-content-center align-items-center">
          <i className="fa fa-spinner fa-spin fa-fw fa-5x" />
        </div>
      </Col>
    </Row>
  </Container>
);

export default Loader;
