import { toastr } from 'react-redux-toastr';
import {
  FLAG_BAGGAGE_CONFIGURATION_ACTIVITY,
  GET_BAGGAGE_CONFIGURATION,
  CLEAR_BAGGAGE_CONFIGURATION,
} from '../types/baggage';
import { BAGGAGE_CONFIGURATION_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagBaggageConfigurationActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_BAGGAGE_CONFIGURATION_ACTIVITY,
    payload: flag,
  });

const getBaggageConfiguration = async () => async (dispatch) => {
  try {
    dispatch(flagBaggageConfigurationActivity(true));

    const url = BAGGAGE_CONFIGURATION_ENDPOINT;
    const promise = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(promise);
    const response = await promise.json();

    dispatch({
      type: GET_BAGGAGE_CONFIGURATION,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagBaggageConfigurationActivity(false));
  }
};

const clearBaggageConfiguration = () => (dispatch) =>
  dispatch({
    type: CLEAR_BAGGAGE_CONFIGURATION,
  });

export {
  flagBaggageConfigurationActivity,
  getBaggageConfiguration,
  clearBaggageConfiguration,
};
