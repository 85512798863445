import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ButtonToolbar } from 'reactstrap';
import FuelVoucherForm from './FuelVoucherForm';
import { FUEL_VOUCHER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getFuelVoucher,
  clearFuelVoucher,
  putFuelVoucher,
  getFuelStation,
  clearFuelStation,
  getItinerary,
  clearItinerary,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import FuelVoucherDisableButton from './FuelVoucherDisableButton';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { getSubstring } from '../../../../utils/string';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

export class EditFuelVoucher extends Component {
  state = {
    gotFuelStation: false,
    gotItinerary: false,
  };

  componentDidMount() {
    const {
      dispatchGetFuelVoucher,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetFuelVoucher({ id });
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    const {
      dispatchClearFuelVoucher,
      dispatchClearFuelStation,
      dispatchClearItinerary,
    } = this.props;
    dispatchClearFuelVoucher();
    dispatchClearFuelStation();
    dispatchClearItinerary();
  }

  onDidUpdate = () => {
    const {
      dispatchGetFuelStation,
      fuelVoucher,
      itinerary,
      fuelStation,
      dispatchGetItinerary,
    } = this.props;

    const { gotFuelStation, gotItinerary } = this.state;

    if (!gotFuelStation && !fuelVoucher.isEmpty() && fuelStation.isEmpty()) {
      dispatchGetFuelStation({ id: fuelVoucher.get('fuelStationId') });
      this.setState({ gotFuelStation: true });
    }

    if (
      !gotItinerary &&
      fuelVoucher.get('itineraryId') &&
      itinerary.isEmpty()
    ) {
      dispatchGetItinerary({ itineraryId: fuelVoucher.get('itineraryId') });
      this.setState({ gotItinerary: true });
    }
  };

  onSubmit = (formValues) => {
    const {
      dispatchPutFuelVoucher,
      match: {
        params: { id: fuelVoucherId },
      },
      fuelVoucher,
    } = this.props;
    const newFormValues = {
      companyBusId: formValues.companyBusId,
      fuelReceiverName: formValues.fuelReceiverName,
      numberOfGallons: formValues.numberOfGallons,
      realNumberOfGallons: formValues.realNumberOfGallons,
      itineraryId: getSubstring(formValues.itineraryId),
      amount: formValues.amount,
      pricePerGallon: formValues.pricePerGallon,
      fuelStationId: formValues.fuelStationId
        ? formValues.fuelStationId.value
        : null,
      comments: formValues.comments,
      isValid: formValues.isValid,
      isSettled: formValues.isSettled,
      redeemed: formValues.redeemed,
      extraordinary: fuelVoucher.get('extraordinary'),
      fuelVoucherStatus: fuelVoucher.get('fuelVoucherStatus'),
    };
    dispatchPutFuelVoucher(fuelVoucherId, newFormValues);
  };

  generateInitialValues = (
    {
      documentSeries,
      documentCode,
      validFrom,
      validTo,
      companyBusId,
      fuelReceiverName,
      numberOfGallons,
      realNumberOfGallons,
      pricePerGallon,
      itineraryId,
      comments,
      amount,
      isValid,
      redeemed,
      isSettled,
      extraordinary,
      fuelStationId,
    },
    { location },
    { route, departureTime },
  ) => {
    const initialValues = {
      documentSeries,
      documentCode,
      validFrom,
      validTo,
      companyBusId,
      fuelReceiverName,
      numberOfGallons,
      realNumberOfGallons,
      pricePerGallon,
      itineraryId,
      comments,
      amount,
      isValid,
      redeemed,
      isSettled,
      extraordinary,
    };

    if (fuelStationId)
      initialValues.fuelStationId = {
        label: location.name,
        value: fuelStationId,
      };

    if (itineraryId)
      initialValues.itineraryId = `${itineraryId} - ${route.name} ${tzNormalizeDate({
        date: departureTime,
        format: DATE_TIME_FORMAT,
      })}`;

    return initialValues;
  };

  render() {
    const { breadcrumbs, fuelVoucher, loading, fuelStation, itinerary } =
      this.props;

    let content;
    let toolbar;

    if (
      loading ||
      fuelStation.isEmpty() ||
      (fuelVoucher.get('itineraryId') && itinerary.isEmpty())
    ) {
      content = <Loader />;
    } else if (fuelVoucher.isEmpty()) {
      content = <NoDataResource returnPage={FUEL_VOUCHER_PATH} />;
    } else {
      const fuelVoucherJson = fuelVoucher.toJS();

      content = (
        <FuelVoucherForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(
            fuelVoucherJson,
            fuelStation.toJS(),
            itinerary.toJS(),
          )}
        />
      );

      toolbar = (
        <ButtonToolbar className="pull-right">
          <div>
            <FuelVoucherDisableButton
              fuelVoucherId={fuelVoucherJson.id}
              fuelVoucherState={fuelVoucherJson.fuelVoucherStatus}
            />
          </div>
        </ButtonToolbar>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Vale de Combustible"
        subtitle="Editar vale de combustible"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

EditFuelVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetFuelVoucher: PropTypes.func.isRequired,
  dispatchClearFuelVoucher: PropTypes.func.isRequired,
  dispatchGetFuelStation: PropTypes.func.isRequired,
  dispatchClearFuelStation: PropTypes.func.isRequired,
  dispatchGetItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  fuelVoucher: PropTypes.instanceOf(Immutable.Map).isRequired,
  fuelStation: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPutFuelVoucher: PropTypes.func.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditFuelVoucher.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { TrafficUnit, RouteUnit, ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Vales de Combustible',
      href: FUEL_VOUCHER_PATH,
    },
    {
      text: 'Ver',
      href: `${FUEL_VOUCHER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  fuelVoucher: TrafficUnit.FuelVoucher.getIn(['current', 'content']),
  loading: !TrafficUnit.FuelVoucher.getIn(['current', 'activity']).isEmpty(),
  fuelStation: RouteUnit.FuelStation.getIn(['current', 'content']),
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchGetFuelVoucher: getFuelVoucher,
  dispatchClearFuelVoucher: clearFuelVoucher,
  dispatchGetFuelStation: getFuelStation,
  dispatchClearFuelStation: clearFuelStation,
  dispatchPutFuelVoucher: putFuelVoucher,
  dispatchGetItinerary: getItinerary,
  dispatchClearItinerary: clearItinerary,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFuelVoucher);
