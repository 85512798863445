import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { activityBasicInformationPropTypes } from '../proptypes/ActivityPropTypes';
import {
  ACTIVITY_ACTION,
  ACTIVITY_EXECUTION_METHOD,
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_FACTOR_TYPE,
} from '../../../../../config/constants';
import { numberFormatter } from '../../../../../utils/number';

const ActivityBasicInformationResource = ({
  companyActivityId,
  name,
  description,
  factor,
  factorType,
  activityType,
  companyArea,
  action,
  executionMethod,
}) => (
  <>
    <ResourceProperty label="Código de la Actividad:">
      {companyActivityId || '-'}
    </ResourceProperty>
    <ResourceProperty label="Acción:">
      {action ? ACTIVITY_ACTION[action].label : '-'}
    </ResourceProperty>
    <ResourceProperty label="Nombre de la Actividad:">{name}</ResourceProperty>
    <ResourceProperty label="Descripción:">
      {description || '-'}
    </ResourceProperty>
    <ResourceProperty label="Factor:">
      {factor
        ? numberFormatter({
            style: 'decimal',
            value: factor,
            minimumFractionDigits: 0,
          })
        : '-'}{' '}
      {factorType ? MAINTENANCE_FACTOR_TYPE[factorType].label : ''}
    </ResourceProperty>
    <ResourceProperty label="Método de Ejecución:">
      {executionMethod ? ACTIVITY_EXECUTION_METHOD[executionMethod].label : '-'}
    </ResourceProperty>
    <ResourceProperty label="Tipo de Mantenimiento:">
      {activityType ? MAINTENANCE_ACTIVITY_TYPE[activityType].label : '-'}
    </ResourceProperty>
    <ResourceProperty label="Organigrama:">
      {companyArea ? companyArea.name : '-'}
    </ResourceProperty>
  </>
);

ActivityBasicInformationResource.propTypes = activityBasicInformationPropTypes;

export default ActivityBasicInformationResource;
