import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Modal from '../modal/Modal';
import PrivateServices from '../../units/contract/private-service/PrivateServices';

const PrivateServiceSearchButton = ({ getRowData, title, size }) => {
  const [showModal, setShowModal] = useState(false);

  const onSelectRow = (row) => {
    if (getRowData) getRowData(row);
    setShowModal(false);
  };

  const button = (
    <Button
      type="button"
      outline
      onClick={() => setShowModal(true)}
      className="mb-2"
      style={{ zIndex: 0 }}
      size={size}
    >
      <i className="fa fa-files-o" /> {title}
    </Button>
  );

  const modal = (
    <Modal
      show={showModal}
      title={title}
      body={<PrivateServices getRowData={onSelectRow} showOnModal />}
      onClickClose={() => setShowModal(false)}
      size="xl"
    />
  );

  return (
    <>
      {modal}
      {button}
    </>
  );
};

PrivateServiceSearchButton.propTypes = {
  title: PropTypes.string.isRequired,
  getRowData: PropTypes.func,
  size: PropTypes.string,
};

PrivateServiceSearchButton.defaultProps = {
  getRowData: null,
  size: 'md',
};

export default PrivateServiceSearchButton;
