import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import Route from './Route';
import Circuit from './Circuit';
import FuelStation from './FuelStation';
import ItineraryGroup from './ItineraryGroup';
import Segment from './Segment';
import Toll from './Toll';

export default combineReducers({
  UnitHome,
  Route,
  Circuit,
  FuelStation,
  ItineraryGroup,
  Segment,
  Toll,
});
