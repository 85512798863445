import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import AuthorizedProfileByProfileEditButton from './AuthorizedProfileByProfileEditButton';
import AuthorizedProfileByProfileDeleteButton from './AuthorizedProfileByProfileDeleteButton';

const AuthorizedProfileByProfileToolbar = ({ authorizedProfileId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <AuthorizedProfileByProfileEditButton
        authorizedProfileId={authorizedProfileId}
      />{' '}
      <AuthorizedProfileByProfileDeleteButton
        authorizedProfileId={authorizedProfileId}
      />
    </div>
  </ButtonToolbar>
);

AuthorizedProfileByProfileToolbar.propTypes = {
  authorizedProfileId: PropTypes.number.isRequired,
};

export default AuthorizedProfileByProfileToolbar;
