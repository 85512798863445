import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { COMPANY_PATH } from '../../../../config/paths';

const CompanyEditButton = ({ companyId }) => (
  <Link className="btn btn-primary" to={`${COMPANY_PATH}/${companyId}/edit`}>
    Editar
  </Link>
);

CompanyEditButton.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyEditButton;
