// types should always be imperative verbs

export const SHOW_ERROR_POSTING_TICKET_REPORT = 'SHOW_ERROR_POSTING_TICKET_REPORT';
export const CLEAR_ERROR_POSTING_TICKET_REPORT = 'CLEAR_ERROR_POSTING_TICKET_REPORT';
export const SHOW_ERROR_GETTING_TICKET_REPORT = 'SHOW_ERROR_GETTING_TICKET_REPORT';
export const SHOW_ERROR_GETTING_TICKET_REPORTS = 'SHOW_ERROR_GETTING_TICKET_REPORTS';
export const CLEAR_ERROR_GETTING_TICKET_REPORT = 'CLEAR_ERROR_GETTING_TICKET_REPORT';
export const CLEAR_ERROR_GETTING_TICKET_REPORTS = 'CLEAR_ERROR_GETTING_TICKET_REPORTS';
export const CLEAR_TICKET_REPORTS = 'CLEAR_TICKET_REPORTS';
export const CLEAR_TICKET_REPORT = 'CLEAR_TICKET_REPORT';
export const POST_TICKET_REPORT = 'POST_TICKET_REPORT';
export const GET_TICKET_REPORTS = 'GET_TICKET_REPORTS';
export const GET_TICKET_REPORT = 'GET_TICKET_REPORT';
export const DOWNLOAD_TICKET_REPORT = 'DOWNLOAD_TICKET_REPORT';
export const FLAG_GETTING_TICKET_REPORT = 'FLAG_GETTING_TICKET_REPORT';
export const FLAG_GETTING_TICKET_REPORTS = 'FLAG_GETTING_TICKET_REPORTS';
export const FLAG_POSTING_TICKET_REPORT = 'FLAG_POSTING_TICKET_REPORT';
