import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

const CargoOperationsButtons = ({
  operations,
  onClickHandlers,
  showAll,
  toShow,
  notToShow,
  showNothing,
}) => {
  const filteredOperations = Object.values(operations).filter((operation) => {
    if (showAll) {
      return !notToShow.includes(operation.value);
    }

    return toShow.includes(operation.value);
  });

  return !showNothing ? (
    <div className="mb-3">
      {filteredOperations.map((operation) => (
        <Button
          type="submit"
          key={operation.value}
          color={operation.color}
          onClick={onClickHandlers[operation.value]}
          className="mx-1"
        >
          {operation.label}
        </Button>
      ))}
    </div>
  ) : null;
};

CargoOperationsButtons.propTypes = {
  operations: PropTypes.instanceOf(Object).isRequired,
  onClickHandlers: PropTypes.instanceOf(Object).isRequired,
  showAll: PropTypes.bool,
  toShow: PropTypes.instanceOf(Object),
  notToShow: PropTypes.instanceOf(Object),
  showNothing: PropTypes.bool,
};

CargoOperationsButtons.defaultProps = {
  showAll: true,
  toShow: [],
  notToShow: [],
  showNothing: false,
};

export default CargoOperationsButtons;
