import { combineReducers } from 'redux';
import Booking from './Booking';
import PublicCities from './PublicCities';
import City from './City';

export default combineReducers({
  Booking,
  PublicCities,
  City,
});
