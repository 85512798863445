import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_PURCHASE_ORDERS,
  GET_PURCHASE_ORDERS,
  CLEAR_PURCHASE_ORDERS,
} from '../types';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  PURCHASE_ORDER_ENDPOINT,
  generatePurchaseOrderEndpoint,
  generateChangeStatusPurchaseOrderEndpoint,
} from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_DELETE_CONFIG } from '../../config/rest';
import Fetch from '../../utils/Fetch';
import { PURCHASE_ORDER_PATH } from '../../config/paths';

const flagGettingPurchaseOrders = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PURCHASE_ORDERS,
    payload: flag,
  });

const getPurchaseOrders = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingPurchaseOrders(true));
    const query = {
      ...tableFilters,
    };
    const url = `${PURCHASE_ORDER_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    // check if it is an error response
    await isErrorResponse(response);
    const purchaseOrders = await response.json();
    dispatch({
      type: GET_PURCHASE_ORDERS,
      payload: purchaseOrders,
    });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingPurchaseOrders(false));
  }
};

const clearPurchaseOrders = () => (dispatch) =>
  dispatch({
    type: CLEAR_PURCHASE_ORDERS,
  });

const getPurchaseOrder = async ({ id }) => {
  try {
    const url = `${PURCHASE_ORDER_ENDPOINT}/${id}`;
    const purchaseOrder = await Fetch.get(url);
    return purchaseOrder;
  } catch ({ message }) {
    toastr.error('Error',message);
    return null;
  }
};

const postPurchaseOrder =
  ({
    documentSeries,
    documentCode,
    comments,
    totalAmount,
    agencyId,
    purchaseOrderDetailList,
  }) =>
  async () => {
    try {
      const payload = {
        documentSeries,
        documentCode,
        comments,
        totalAmount,
        agencyId,
        purchaseOrderDetailList,
      };
      const purchaseOrder = await Fetch.post({
        PURCHASE_ORDER_ENDPOINT,
        payload,
      });
      return purchaseOrder;
    } catch ({ message }) {
      toastr.error('Error',message);
      return null;
    }
  };

const deletePurchaseOrder =
  async ({ purchaseOrderId }) =>
  async (dispatch) => {
    try {
      const url = `${generatePurchaseOrderEndpoint(purchaseOrderId)}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      // check if it is an error response
      await isErrorResponse(
        response,
        () => push(PURCHASE_ORDER_PATH),
        dispatch,
        'delete',
      );
      return false;
    } catch ({ message }) {
      toastr.error('Error',message);
      return false;
    }
  };

const patchPurchaseOrderStatus =
  ({ id, status, comments = null }) =>
  async () => {
    try {
      const payload = {
        id,
        status,
      };

      if (comments) payload.comments = comments;

      const purchaseOrder = await Fetch.patch({
        url: generateChangeStatusPurchaseOrderEndpoint(id),
        payload,
      });

      return purchaseOrder;
    } catch ({ message }) {
      toastr.error('Error',message);
      return null;
    }
  };

export {
  getPurchaseOrders,
  clearPurchaseOrders,
  getPurchaseOrder,
  postPurchaseOrder,
  deletePurchaseOrder,
  patchPurchaseOrderStatus,
};
