import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getCountry, putCountry } from '../../../../actions/location/Country';
import { COUNTRY_PATH } from '../../../../config/paths';
import CountryForm from './CountryForm';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

class EditCountry extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetCountry,
    } = this.props;
    dispatchGetCountry(id);
  }

  onSubmit = (formValues) => {
    const {
      match: {
        params: { id },
      },
      dispatchPutCountry,
    } = this.props;
    dispatchPutCountry(id, formValues);
  };

  render() {
    const { country, loading, breadcrumbs } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (country.isEmpty()) {
      content = <NoDataResource returnPage={COUNTRY_PATH} />;
    } else {
      content = (
        <CountryForm
          initialValues={{
            name: country.get('name'),
            countryCode: country.get('countryCode'),
          }}
          onSubmit={this.onSubmit}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar País"
        subtitle="Editar país"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { LocationUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Países',
      href: COUNTRY_PATH,
    },
    {
      text: 'Ver',
      href: `${COUNTRY_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  country: LocationUnit.Country.getIn(['current', 'content']),
  loading: LocationUnit.Country.getIn(['current', 'activity']),
});

EditCountry.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetCountry: PropTypes.func.isRequired,
  dispatchPutCountry: PropTypes.func.isRequired,
  country: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
};

EditCountry.defaultProps = {
  breadcrumbs: [],
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetCountry: getCountry,
  dispatchPutCountry: putCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCountry);
