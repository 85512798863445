import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Button } from 'reactstrap';
import { putMaintenanceWarning } from '../../../../actions';
import { PATCH } from '../../../../config/permissions';
import { generateChangeStatusMaintenanceWarningEndpoint } from '../../../../config/endpoints';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import Modal from '../../../common/modal/Modal';
import MaintenanceWarningRejectedForm from './MaintenanceWarningRejectedForm';
import Loader from '../../../common/Loader';
import { tzNormalizeDate } from '../../../../utils/date';
import { MAINTENANCE_WARNING_STATUS } from '../../../../config/constants';

const MaintenanceWarningRejectedButton = ({
  maintenanceWarningId,
  dispatchPutMaintenanceWarning,
  maintenanceWarning,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => setShowModal(false);

  const onSubmitChangeStatus = async (formValues) => {
    setLoading(true);

    const {
      issueDate,
      registeredBusId,
      categoryId,
      subCategoryId,
      maitenanceFailModeId,
      maintenanceWarningType,
      priority,
      comments,
      reporterCustomerId,
      createDate,
      userId,
    } = maintenanceWarning.toJS();

    const response = await dispatchPutMaintenanceWarning(maintenanceWarningId, {
      issueDate: tzNormalizeDate({ date: issueDate }),
      registeredBusId,
      categoryId,
      subCategoryId,
      maitenanceFailModeId,
      maintenanceWarningType,
      priority,
      comments,
      reporterCustomerId,
      status: MAINTENANCE_WARNING_STATUS.REJECTED.value,
      statusComment: formValues.statusComment,
      createDate,
      userId,
    });

    if (response) setShowModal(false);
  };

  let body;
  if (loading) body = <Loader />;
  else
    body = (
      <>
        <p>Esta acción rechazará la alerta. ¿Desea continuar?</p>
        <MaintenanceWarningRejectedForm
          onSubmit={onSubmitChangeStatus}
          onCancel={closeModal}
        />
      </>
    );

  return (
    <>
      <Modal show={showModal} body={body} title="Confirmación" size="lg" />
      <Button color="danger" outline onClick={() => setShowModal(true)}>
        <i className="fa fa-times-circle" /> Rechazar
      </Button>
    </>
  );
};

MaintenanceWarningRejectedButton.propTypes = {
  maintenanceWarningId: PropTypes.number.isRequired,
  dispatchPutMaintenanceWarning: PropTypes.func.isRequired,
  maintenanceWarning: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  maintenanceWarning: MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'current',
    'content',
  ]),
});

const mapDispatchToprops = {
  dispatchPutMaintenanceWarning: putMaintenanceWarning,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToprops,
)(MaintenanceWarningRejectedButton);

export default withEndpointAuthorization({
  url: generateChangeStatusMaintenanceWarningEndpoint(),
  mapUrlParamsToProps: { maintenanceWarningId: 'maintenanceWarningId' },
  permissionType: PATCH,
})(connectedComponent);
