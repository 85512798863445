import React from 'react';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';
import { Col, Row } from 'reactstrap';
import Table from '../../../../common/Table';
import {
  ITINERARIES_OCCUPANCY_COLUMS,
  SEATS_INFORMATION_COLUMS,
} from '../../../../../config/columns';

const ItinerarySalesControlGaugeChart = ({
  offeredSeats,
  soldSeats,
  pendingSeats,
  listOfServices,
  generateStyle,
}) => {
  const percentage = soldSeats / offeredSeats;

  const generateDataItineraryOccupancyData = (dataList) => {
    const newArrayData = [];

    dataList.forEach((service) => {
      newArrayData.push({
        serviceType: service.serviceType,
        occupancy: service.percent,
        itineraries: service.numberOfItineraries,
      });
    });

    return newArrayData;
  };

  const seatsInformationData = [
    {
      offeredSeats,
      soldSeats,
      pendingSeats,
    },
  ];

  return (
    <Row>
      <Col md={6}>
        <div style={{ transform: 'scale(0.8)', transformOrigin: 'center' }}>
          <GaugeChart
            nrOfLevels={420}
            percent={percentage}
            textColor="#000000"
            arcPadding={0.02}
            cornerRadius={3}
            colors={['#FF0000', '#FFA500', '#FFFF00', '#00FF00']}
            arcsLength={[0.35, 0.25, 0.15, 0.25]}
            formatTextValue={() => `${(percentage * 100).toFixed(2)}%`}
          />
        </div>
      </Col>
      <Col md={6}>
        <Row>
          <Col md={12}>
            <Table
              columns={SEATS_INFORMATION_COLUMS}
              data={seatsInformationData}
              manual={false}
              showPagination={false}
              defaultPageSize={seatsInformationData.length}
            />
          </Col>
          <Col md={12}>
            <Table
              columns={ITINERARIES_OCCUPANCY_COLUMS}
              data={generateDataItineraryOccupancyData(listOfServices)}
              manual={false}
              showPagination={false}
              defaultPageSize={
                generateDataItineraryOccupancyData(listOfServices).length
              }
              generateStyle={generateStyle}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ItinerarySalesControlGaugeChart.propTypes = {
  offeredSeats: PropTypes.number.isRequired,
  soldSeats: PropTypes.number.isRequired,
  pendingSeats: PropTypes.number.isRequired,
  listOfServices: PropTypes.arrayOf(
    PropTypes.shape({
      serviceType: PropTypes.string,
      percent: PropTypes.string,
      numberOfItineraries: PropTypes.number,
      totalSeats: PropTypes.number,
      soldSeats: PropTypes.number,
    }),
  ).isRequired,
  generateStyle: PropTypes.func.isRequired,
};

export default ItinerarySalesControlGaugeChart;
