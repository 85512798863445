import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  postExpenseReport,
  clearErrorPostingExpenseReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import ExpenseReportForm from './ExpenseReportForm';
import {
  NEW_EXPENSE_REPORT_PATH,
  EXPENSE_REPORTS_PATH,
} from '../../../../config/paths';
import { tzNormalizeDate } from '../../../../utils/date';

class NewExpenseReport extends Component {
  componentWillUnmount() {
    this.props.clearErrorPostingExpenseReport();
  }
  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte de Egresos</h1>
            <p id="text">Crear un nuevo reporte de Egresos.</p>
          </Col>
        </Row>
        <ExpenseReportForm
          onSubmit={this.props.postExpenseReport}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            force: true,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Egreso',
      href: EXPENSE_REPORTS_PATH,
    },
    {
      text: 'Crear',
      href: NEW_EXPENSE_REPORT_PATH,
    },
  ],
});

const mapDispatchToProps = {
  postExpenseReport,
  clearErrorPostingExpenseReport,
};

NewExpenseReport.propTypes = {
  // todo: fix once issue http://bit.ly/2EPChzJ is fixed
  // eslint-disable-next-line react/require-default-props,react/no-typos
  breadcrumbs: Breadcrumbs.propTypes.breadcrumbs,
  postExpenseReport: PropTypes.func.isRequired,
  clearErrorPostingExpenseReport: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewExpenseReport);
