import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AGENCY_PATH } from '../../../../config/paths';
import { postAgency } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import AgencyForm from './AgencyForm';
import Loader from '../../../common/Loader';
import { CONTRACT_TYPE } from '../../../../config/constants';
import { DEFAULT_CONTRACT_TYPE_OPTION } from '../../../../config/defaults';
import Content from '../../../layout/Content';

export const NewAgency = ({ dispatchPostAgency, loading, breadcrumbs }) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    newFormValues.locationId = formValues.locationId.value;

    if (formValues.userList)
      newFormValues.userList = formValues.userList.map((user) => ({
        id: user.value,
      }));

    if (formValues.workstationList)
      newFormValues.workstationList = formValues.workstationList.map(
        (workstation) => ({
          id: workstation.value,
          name: workstation.label,
          serialNumber: workstation.serialNumber,
          printStationList: workstation.printStationList,
        }),
      );

    newFormValues.companyId = formValues.companyId
      ? formValues.companyId.value
      : null;

    newFormValues.contractType = formValues.contractType
      ? formValues.contractType.value
      : CONTRACT_TYPE.DEFAULT.value;

    if (
      formValues.contractType &&
      formValues.contractType.value === CONTRACT_TYPE.AE_AGENCY.value
    ) {
      newFormValues.commission = formValues.commission;
      newFormValues.taxExempt = formValues.taxExempt || false;
    }

    newFormValues.actualBalance = formValues.initialBalance;

    dispatchPostAgency(newFormValues);
  };

  const content = loading ? (
    <Loader />
  ) : (
    <AgencyForm
      onSubmit={onSubmit}
      initialValues={{ contractType: DEFAULT_CONTRACT_TYPE_OPTION }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Agencia"
      subtitle="Crear una agencia"
      content={content}
    />
  );
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Agencia',
      href: AGENCY_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  loading: !HumanResourcesUnit.Agency.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPostAgency: postAgency,
};

NewAgency.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostAgency: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewAgency.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAgency);
