import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCities, clearCities } from '../../../actions/index';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';

class CityFilter extends Component {
  static propTypes = {
    dispatchGetCities: PropTypes.func.isRequired,
    dispatchClearCities: PropTypes.func.isRequired,
    options: optionsPropTypes,
    onChangeFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: [],
  };

  componentWillUnmount() {
    this.props.dispatchClearCities();
  }

  handleInputChange = (inputValue) =>
    inputValue.trim().length &&
    inputValue.trim().length >= 4 &&
    this.props.dispatchGetCities({ query: inputValue });

  render() {
    const { options, onChangeFilter } = this.props;
    return (
      <SelectFilter
        isMulti
        onChangeFilter={onChangeFilter}
        onInputChange={this.handleInputChange}
        options={options}
      />
    );
  }
}

const mapStateToProps = ({ LocationUnit }) => ({
  options: LocationUnit.City.getIn(['all', 'content', 'content']).map(
    (option) => ({
      value: option.id,
      label: option.name,
    }),
  ),
});

const mapDispatchToprops = {
  dispatchGetCities: getCities,
  dispatchClearCities: clearCities,
};

export default connect(mapStateToProps, mapDispatchToprops)(CityFilter);
