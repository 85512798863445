import React, { Component } from 'react';
import { Form, FormGroup, Label, Button, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {
  getBusinesses,
  validateCreditNote,
  applyCreditNote,
  postPayment,
  getPaymentMethodsPerSecurityProfile,
} from '../../../../actions';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import {
  TRANSACTION_TYPE_CREDIT_CARD,
  CREDIT_NOTE,
  CONTRACT_TYPE,
  TRANSACTION_TYPE_BUSINESS_CREDIT,
} from '../../../../config/constants';
import {
  DEFAULT_CORPORATE_VOUCHER_TYPE_OPTION,
  DEFAULT_PAYMENT_METHOD_OPTION,
  DEFAULT_SELF_SERVICE_VOUCHER_TYPE_OPTION,
  DEFAULT_VOUCHER_TYPE_OPTION,
  SELF_SERVICE_PAYMENT_METHOD_OPTION,
} from '../../../../config/defaults';
import VoucherTypeInputGroup from '../../../common/forms/VoucherTypeInputGroup';
import Select from '../../../common/forms/select/Select';
import { DEFAULT_QUERY } from '../../../../config/queries';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';

const selector = formValueSelector('PaymentForm');

export class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVoucherCode: false,
      showCreditNoteInput: false,
      creditNotePaymentMethodId: null,
    };
  }

  componentDidMount() {
    this.props.getPaymentMethodsPerSecurityProfile();
  }

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({
      showVoucherCode:
        paymentMethod.transactionCode &&
        paymentMethod.transactionCode === TRANSACTION_TYPE_CREDIT_CARD,
    });
    if (this.props.seatsNumber === 1) {
      this.setState({
        showCreditNoteInput:
          paymentMethod.transactionCode &&
          paymentMethod.transactionCode === CREDIT_NOTE,
      });
    }
    if (this.state.showVoucherCode) {
      const { change } = this.props;
      change('voucherCode', '');
    }
  };

  handleBusinessInputChange = (inputValue) =>
    this.props.getBusinesses({ ...DEFAULT_QUERY, query: inputValue });

  cleanNewBusiness = () => {
    const { change } = this.props;
    change('businessTaxId', null);
    change('businessName', null);
    change('addressSummary', null);
  };

  handleCreditNote = async () => {
    const { change } = this.props;
    const {
      paymentMethodValue,
      creditNoteValue,
      initialValues: { customer },
    } = this.props;
    const validCreditNote = await validateCreditNote(
      creditNoteValue,
      customer.value,
    );
    if (validCreditNote) {
      this.setState({
        creditNotePaymentMethodId: paymentMethodValue.value,
        showCreditNoteInput: false,
      });
      this.props.applyCreditNote(validCreditNote);
      change('paymentMethod', DEFAULT_PAYMENT_METHOD_OPTION);
      change('creditNote', null);
    }
  };

  showPaymentMethod = () =>
    this.props.creditNote.isEmpty() ||
    (!this.props.creditNote.isEmpty() &&
      this.props.creditNote.get('amount') < this.props.totalPrice);

  hasPaymentLeft = () =>
    !this.props.creditNote.isEmpty() &&
    this.props.creditNote.get('amount') < this.props.totalPrice;

  handlePaymentSubmit = (formValues) => {
    const transactions = [];
    const { totalPrice, paymentMethodValue } = this.props;
    if (
      paymentMethodValue.transactionCode === CREDIT_NOTE &&
      this.props.creditNote.isEmpty()
    ) {
      toastr.error('Error', 'Aplicar anticipo');
    } else {
      if (!this.props.creditNote.isEmpty()) {
        let amount = this.props.creditNote.get('amount');
        if (amount > totalPrice) {
          amount = totalPrice;
        }
        const creditNoteTransaction = {
          customerId: formValues.customer.value,
          paymentMethodId: this.state.creditNotePaymentMethodId,
          creditNoteId: this.props.creditNote.get('id'),
          voucherTypeId: formValues.voucherType.value,
          voucherCode: formValues.voucherCode || null,
          businessId: formValues.business ? formValues.business.value : null,
          amount,
        };
        transactions.push(creditNoteTransaction);
      }
      if (this.showPaymentMethod()) {
        let amount = totalPrice;
        // calculate amount if there is a creditNote
        if (!this.props.creditNote.isEmpty()) {
          const creditNoteAmount = this.props.creditNote.get('amount');
          const totalAmount = totalPrice - creditNoteAmount;
          // we round due to possible js calculation error
          amount = totalAmount >= 0 ? Math.round(totalAmount * 100) / 100 : 0;
        }
        const transaction = {
          customerId: formValues.customer.value,
          paymentMethodId: formValues.paymentMethod.value,
          voucherTypeId: formValues.voucherType.value,
          voucherCode: formValues.voucherCode || null,
          businessId: formValues.business ? formValues.business.value : null,
          amount,
        };
        transactions.push(transaction);
      }
      this.props.postPayment({
        transactions,
        customerOrderId: formValues.customerOrderId,
        businessTaxId: formValues.businessTaxId,
        businessName: formValues.businessName,
        addressSummary: formValues.addressSummary,
      });
    }
  };

  render() {
    const {
      handleSubmit,
      paymentMethods,
      clients,
      submitting,
      seatsNumber,
      paymentMethodValue,
      change,
    } = this.props;
    let paymentMethodComponent = null;
    let creditNoteWarning = null;
    if (
      seatsNumber > 1 &&
      paymentMethodValue &&
      paymentMethodValue.transactionCode &&
      paymentMethodValue.transactionCode === CREDIT_NOTE
    ) {
      creditNoteWarning = (
        <p className="text-danger">
          Este método de pago sólo aplica para venta un solo pasaje
        </p>
      );
    }
    if (this.showPaymentMethod()) {
      paymentMethodComponent = (
        <FormGroup row>
          <Col sm={3} xs={12}>
            <Label>
              Método de Pago <span className="text-danger">*</span>
            </Label>
          </Col>
          <Col sm={8} xs={12}>
            <Field
              name="paymentMethod"
              component={Select}
              options={paymentMethods}
              onChange={this.handlePaymentMethodChange}
              validate={[isRequired]}
              autoFocus
            />
            {creditNoteWarning}
          </Col>
        </FormGroup>
      );
    }

    const voucherCode = this.state.showVoucherCode ? (
      <FormGroup row>
        <Col sm={3} xs={12}>
          <Label>
            Nro Referencia (POS) <span className="text-danger">*</span>
          </Label>
        </Col>
        <Col sm={8} xs={12}>
          <Field
            name="voucherCode"
            component={TextInput}
            type="text"
            placeholder="Código de Voucher"
            validate={[isRequired]}
          />
        </Col>
      </FormGroup>
    ) : null;

    let creditNote = null;
    if (this.state.showCreditNoteInput) {
      creditNote = (
        <FormGroup row>
          <Col sm={3} xs={12}>
            <Label>
              PIN(Anticipo) <span className="text-danger">*</span>
            </Label>
          </Col>
          <Col sm={7} xs={12}>
            <Field name="creditNote" component={TextInput} />
          </Col>
          <Col sm={2} xs={12}>
            <Button
              className="pull-right"
              type="button"
              color="primary"
              outline
              onClick={this.handleCreditNote}
            >
              Aplicar
            </Button>
          </Col>
        </FormGroup>
      );
    }

    const headerText = this.hasPaymentLeft() ? 'Pago de Diferencia' : 'Pago';
    return (
      <div>
        <br />
        <h2>{headerText}</h2>
        <Form onSubmit={handleSubmit(this.handlePaymentSubmit)}>
          <FormGroup row>
            <Col sm={3} xs={12}>
              <Label>
                Cliente <span className="text-danger">*</span>
              </Label>
            </Col>
            <Col sm={8} xs={12}>
              <Field
                name="customer"
                component={Select}
                options={clients}
                validate={[isRequired]}
              />
            </Col>
          </FormGroup>
          {paymentMethodComponent}
          {creditNote}
          {voucherCode}

          <VoucherTypeInputGroup
            voucherTypeFieldName="voucherType"
            businessFieldName="business"
            businessTaxIdFieldName="businessTaxId"
            businessNameFieldName="businessName"
            addressSummaryFieldName="addressSummary"
            change={change}
          />
          <div className="FormButtonGroup">
            <Button
              type="submit"
              disabled={submitting}
              color="primary"
              size="lg"
            >
              Pagar <i className="fa fa-arrow-circle-right" />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

PaymentForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paymentMethods: Select.propTypes.options.isRequired,
  clients: Select.propTypes.options.isRequired,
  getPaymentMethodsPerSecurityProfile: PropTypes.func.isRequired,
  getBusinesses: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  creditNoteValue: PropTypes.string,
  applyCreditNote: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  creditNote: PropTypes.instanceOf(Immutable.Map).isRequired,
  postPayment: PropTypes.func.isRequired,
  seatsNumber: PropTypes.number.isRequired,
  paymentMethodValue: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    transactionCode: PropTypes.string,
  }),
  initialValues: PropTypes.shape({
    customer: optionPropTypes,
  }),
};

PaymentForm.defaultProps = {
  creditNoteValue: null,
  paymentMethodValue: null,
  initialValues: null,
};

const mapStateToProps = (state) => {
  const clients = state.BookingUnit.Booking.getIn([
    'payment',
    'customerOrder',
    'passengers',
  ]).map((passenger) => ({
    value: passenger.id,
    label: passenger.fullName,
  }));

  const paymentMethods = state.AccountingUnit.PaymentMethod.getIn([
    'all',
    'content',
    'content',
  ]).map((paymentMethod) => ({
    value: paymentMethod.id,
    label: paymentMethod.name,
    transactionCode: paymentMethod.transactionCode,
  }));

  let newPaymentMethods = [];

  let defaultPaymentMethod = DEFAULT_PAYMENT_METHOD_OPTION;

  let defaultVoucherType = DEFAULT_VOUCHER_TYPE_OPTION;

  let defaultBusiness = null;

  // Checking self service sale
  const { salesSessionUserId } = state.authentication.get('user');

  if (!salesSessionUserId) {
    // Checking corporate sale
    const activeSaleSession = state.SalesUnit.SalesSession.getIn([
      'active',
      'content',
    ]);

    const { contractType, company } = activeSaleSession.get('agency');

    if (contractType === CONTRACT_TYPE.CORPORATE.value) {
      defaultPaymentMethod = null;

      defaultVoucherType = DEFAULT_CORPORATE_VOUCHER_TYPE_OPTION;

      paymentMethods.forEach((method) => {
        if (
          method.transactionCode === CREDIT_NOTE ||
          method.transactionCode === TRANSACTION_TYPE_BUSINESS_CREDIT
        ) {
          newPaymentMethods.push(method);
        }
      });

      if (company) {
        defaultBusiness = {
          value: company.business.businessTaxId,
          label: company.business.name,
        };
      }
    } else {
      newPaymentMethods = paymentMethods;
    }
  } else {
    defaultPaymentMethod = SELF_SERVICE_PAYMENT_METHOD_OPTION;

    newPaymentMethods.push(defaultPaymentMethod);

    defaultVoucherType = DEFAULT_SELF_SERVICE_VOUCHER_TYPE_OPTION;
  }

  const businesses = state.UserUnit.Business.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, businessTaxId, name }) => ({
    value: id,
    label: `${businessTaxId} - ${name}`,
  }));

  const customerOrderId = state.BookingUnit.Booking.getIn([
    'payment',
    'customerOrder',
    'id',
  ]);

  const initialValues = {
    customerOrderId,
    // by default select the first client as the one who pays
    customer: clients[0],
    voucherType: defaultVoucherType,
    paymentMethod: null,
    business: defaultBusiness,
  };

  const creditNoteValue = selector(state, 'creditNote');
  const paymentMethodValue = selector(state, 'paymentMethod');

  return {
    paymentMethods: newPaymentMethods,
    clients,
    businesses,
    initialValues,
    creditNoteValue,
    paymentMethodValue,
  };
};

const mapDispatchToProps = {
  getPaymentMethodsPerSecurityProfile,
  getBusinesses,
  applyCreditNote,
  postPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'PaymentForm',
  })(PaymentForm),
);
