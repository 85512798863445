import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PRINT_STATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import PrintStationForm from './PrintStationForm';
import Loader from '../../../common/Loader';
import {
  clearPrintStation,
  getPrintStation,
  putPrintStation,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditPrintStation extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: printStationId },
      },
      dispatchGetPrintStation,
    } = this.props;

    dispatchGetPrintStation({ printStationId });
  }

  componentWillUnmount() {
    this.props.dispatchClearPrintStation();
  }

  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    const {
      dispatchPutPrintStation,
      printStation,
      match: {
        params: { id },
      },
    } = this.props;

    if (formValues.printTemplateList) {
      newFormValues.printTemplateList = formValues.printTemplateList.map(
        (template) => ({
          printTemplateType: template.printTemplateType.value,
          documentCode: template.documentCode,
          documentSeries: template.documentSeries,
        }),
      );
    }

    newFormValues.virtualPrinter = printStation.get('virtualPrinter');
    newFormValues.workstationId = printStation.get('workstationId');

    dispatchPutPrintStation(id, newFormValues);
  };

  generateInitialValues = (printStation) => ({
    serialNumber: printStation.get('serialNumber'),
    name: printStation.get('name'),
    description: printStation.get('description'),
    printTemplateList: printStation
      .get('printTemplateList')
      .map((template) => ({
        printTemplateType: {
          value: template.printTemplateType,
          label: template.printTemplateType,
        },
        documentSeries: template.documentSeries,
        documentCode: template.documentCode,
      })),
  });

  render() {
    const { breadcrumbs, printStation, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (printStation.isEmpty()) {
      content = <NoDataResource returnPage={PRINT_STATION_PATH} />;
    } else {
      content = (
        <PrintStationForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(printStation)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Impresora"
        subtitle="Editar impresora"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { HumanResourcesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Impresoras',
      href: PRINT_STATION_PATH,
    },
    {
      text: 'Ver',
      href: `${PRINT_STATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  printStation: HumanResourcesUnit.PrintStation.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.PrintStation.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetPrintStation: getPrintStation,
  dispatchClearPrintStation: clearPrintStation,
  dispatchPutPrintStation: putPrintStation,
};

EditPrintStation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutPrintStation: PropTypes.func.isRequired,
  dispatchGetPrintStation: PropTypes.func.isRequired,
  dispatchClearPrintStation: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  printStation: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditPrintStation.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPrintStation);
