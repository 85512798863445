import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  AGENCY_GROUP_PATH,
  NEW_AGENCY_GROUP_PATH,
} from '../../../../config/paths';
import { AGENCY_GROUP_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { clearAgencyGroups, getAgencyGroups } from '../../../../actions';

export class AgencyGroups extends Component {
  componentWillUnmount() {
    this.props.dispatchClearAgencyGroups();
  }

  render() {
    const { agencyGroups, loading, breadcrumbs, dispatchGetAgencyGroups } =
      this.props;

    const data = agencyGroups.get('content') || [];

    const pages = agencyGroups.get('totalPages') || null;

    const defaultPageSize = agencyGroups.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Grupos de Agencias"
        buttonPath={NEW_AGENCY_GROUP_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: AGENCY_GROUP_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: false,
          fetchData: dispatchGetAgencyGroups,
          modelPath: AGENCY_GROUP_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetAgencyGroups: getAgencyGroups,
  dispatchClearAgencyGroups: clearAgencyGroups,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Agencias',
      href: AGENCY_GROUP_PATH,
    },
  ],
  agencyGroups: HumanResourcesUnit.AgencyGroup.getIn(['all', 'content']),
  loading: HumanResourcesUnit.AgencyGroup.getIn(['all', 'loading']),
});

AgencyGroups.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  agencyGroups: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetAgencyGroups: PropTypes.func.isRequired,
  dispatchClearAgencyGroups: PropTypes.func.isRequired,
};

AgencyGroups.defaultProps = {
  agencyGroups: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyGroups);
