import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_BUS_ACTIVITY_HISTORY,
  CLEAR_BUS_ACTIVITY_HISTORY,
  GET_BUS_ACTIVITY_HISTORY,
} from '../types';
import {
  BUS_ACTIVITY_HISTORY_ENDPOINT,
  generateExecutionRegistrationDeleteEndpoint,
} from '../../config/endpoints';
import { DEFAULT_DELETE_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import {
  hadErrorInPromiseAllSettled,
  isErrorResponse,
} from '../../utils/error-handlers';

const flagGettingBusActivityHistory = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BUS_ACTIVITY_HISTORY,
    payload: flag,
  });

const clearBusActivityHistory = () => (dispatch) =>
  dispatch({
    type: CLEAR_BUS_ACTIVITY_HISTORY,
  });

const getBusActivityHistory =
  async ({
    page,
    size,
    order,
    sort,
    registeredBusIdList,
    homeBaseLocationId,
    daily,
    dateFrom,
    dateTo,
    simulationDays,
    simulationKilometers,
  }) =>
  async (dispatch) => {
    try {
      const params = {
        page,
        size,
        order,
        sort,
      };
      const payload = {
        registeredBusIdList,
        homeBaseLocationId,
        daily,
        dateTo,
        dateFrom,
        simulationDays,
        simulationKilometers,
      };
      dispatch(flagGettingBusActivityHistory(true));
      const url = `${BUS_ACTIVITY_HISTORY_ENDPOINT}?${QueryString.stringify(
        params,
      )}`;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({ type: GET_BUS_ACTIVITY_HISTORY, payload: response });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBusActivityHistory(false));
    }
  };

const deleteBusActivityHistory =
  async ({ busActivityExecutionIdList }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBusActivityHistory(true));
      const fetchList = busActivityExecutionIdList.map(
        (busActivityExecutionId) =>
          fetch(
            generateExecutionRegistrationDeleteEndpoint(busActivityExecutionId),
            {
              ...DEFAULT_DELETE_CONFIG,
            },
          ).then((response) => response.json()),
      );
      const response = await Promise.allSettled(fetchList);
      await hadErrorInPromiseAllSettled(response);
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagGettingBusActivityHistory(false));
    }
  };

export {
  clearBusActivityHistory,
  getBusActivityHistory,
  deleteBusActivityHistory,
};
