import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { CARGO_CORPORATE_CREDIT_PATH } from '../../../../config/paths';
import CargoCorporateCreditAddCreditLineButton from './CargoCorporateCreditAddCreditLineButton';
import CargoCorporateCreditDeleteButton from './CargoCorporateCreditDeleteButton';
import CargoCorporateCreditUnifyInvoicesButton from './CargoCorporateCreditUnifyInvoicesButton';

const CargoCorporateCreditToolBar = ({
  cargoCorporateCreditId,
  initialBalance,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <CargoCorporateCreditUnifyInvoicesButton
        cargoCorporateCreditId={cargoCorporateCreditId}
      />{' '}
      {initialBalance > 0 ? (
        <CargoCorporateCreditAddCreditLineButton
          cargoCorporateCreditId={cargoCorporateCreditId}
        />
      ) : null}{' '}
      <EditButton
        path={`${CARGO_CORPORATE_CREDIT_PATH}/${cargoCorporateCreditId}/edit`}
      />{' '}
      <CargoCorporateCreditDeleteButton
        cargoCorporateCreditId={cargoCorporateCreditId}
      />
    </div>
  </ButtonToolbar>
);

CargoCorporateCreditToolBar.propTypes = {
  cargoCorporateCreditId: PropTypes.number.isRequired,
  initialBalance: PropTypes.number.isRequired,
};

export default CargoCorporateCreditToolBar;
