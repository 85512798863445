import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Floor from './Floor';
import { floorsPropTypes } from '../../units/bus/seat-map/proptypes/SeatMapPropTypes';

const Floors = ({
  destinationLocationId,
  sourceLocationId,
  itineraryId,
  floors,
  handleSeatSelection,
  handleSeatDeselection,
  seatReservations,
  seatSelectionChangesInProgress,
  numPassengers,
  orientation,
  renderFor,
  enabledFloor,
}) => {
  const columns = 12;

  const floorNumbers = floors.length;

  const lg = columns / floorNumbers;

  const sm = columns;

  const xs = columns;

  const vFloors = floors.map(floor => {
    const vFloor = (
      <div className="text-center" key={floor.floorNumber}>
        <Floor
          floor={floor}
          destinationLocationId={destinationLocationId}
          sourceLocationId={sourceLocationId}
          itineraryId={itineraryId}
          handleSeatSelection={handleSeatSelection}
          handleSeatDeselection={handleSeatDeselection}
          seatReservations={seatReservations}
          seatSelectionChangesInProgress={seatSelectionChangesInProgress}
          numPassengers={numPassengers}
          renderFor={renderFor}
          enabledFloor={enabledFloor}
        />
      </div>
    );

    return orientation === 'h' ? (
      <Col key={floor.floorNumber} lg={lg} sm={sm} xs={xs}>
        {vFloor}
      </Col>
    ) : (
      vFloor
    );
  });

  return orientation === 'h' ? <Row>{vFloors}</Row> : vFloors;
};

Floors.propTypes = {
  ...floorsPropTypes,
  sourceLocationId: PropTypes.number,
  destinationLocationId: PropTypes.number,
  itineraryId: PropTypes.number,
  handleSeatSelection: PropTypes.func.isRequired,
  handleSeatDeselection: PropTypes.func,
  seatReservations: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.Set),
    PropTypes.arrayOf(
      PropTypes.shape({
        xPosition: PropTypes.number,
        yPosition: PropTypes.number,
        floorNumber: PropTypes.number,
      }),
    ),
  ]),
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set),
  numPassengers: PropTypes.number,
  orientation: PropTypes.string.isRequired,
  renderFor: PropTypes.string.isRequired,
  enabledFloor: PropTypes.number,
};

Floors.defaultProps = {
  sourceLocationId: null,
  destinationLocationId: null,
  itineraryId: null,
  handleSeatDeselection: null,
  numPassengers: null,
  seatReservations: null,
  seatSelectionChangesInProgress: null,
  enabledFloor: null,
};

export default Floors;
