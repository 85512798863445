import React from 'react';
import PropTypes from 'prop-types';

const InformationText = ({ text, styles }) => (
  <span className={`${styles}`}>{text}</span>
);

InformationText.propTypes = {
  text: PropTypes.string.isRequired,
  styles: PropTypes.string,
};

InformationText.defaultProps = {
  styles: 'text-muted',
};

export default InformationText;
