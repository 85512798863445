// types should always be imperative verbs

export const GET_ITINERARY_REPORTS = 'GET_ITINERARY_REPORTS';
export const FLAG_GETTING_ITINERARY_REPORTS = 'FLAG_GETTING_ITINERARY_REPORTS';
export const CLEAR_ERROR_GETTING_ITINERARY_REPORTS = 'CLEAR_ERROR_GETTING_ITINERARY_REPORTS';
export const SHOW_ERROR_GETTING_ITINERARY_REPORTS = 'SHOW_ERROR_GETTING_ITINERARY_REPORTS';
export const CLEAR_ITINERARY_REPORTS = 'CLEAR_ITINERARY_REPORTS';
export const CLEAR_ERROR_POSTING_ITINERARY_REPORT = 'CLEAR_ERROR_POSTING_ITINERARY_REPORT';
export const POST_ITINERARY_REPORT = 'POST_ITINERARY_REPORT';
export const SHOW_ERROR_POSTING_ITINERARY_REPORT = 'SHOW_ERROR_POSTING_ITINERARY_REPORT';
export const FLAG_POSTING_ITINERARY_REPORT = 'FLAG_POSTING_ITINERARY_REPORT';
export const DOWNLOAD_ITINERARY_REPORT = 'DOWNLOAD_ITINERARY_REPORT';
export const GET_ITINERARY_REPORT = 'GET_ITINERARY_REPORT';
export const SHOW_ERROR_GETTING_ITINERARY_REPORT = 'SHOW_ERROR_GETTING_ITINERARY_REPORT';
export const CLEAR_ERROR_GETTING_ITINERARY_REPORT = 'CLEAR_ERROR_GETTING_ITINERARY_REPORT';
export const FLAG_GETTING_ITINERARY_REPORT = 'FLAG_GETTING_ITINERARY_REPORT';
export const CLEAR_ITINERARY_REPORT = 'CLEAR_ITINERARY_REPORT';
