import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getGeographicZone,
  clearGeographicZone,
} from '../../../../actions/location/GeographicZone';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { GEOGRAPHIC_ZONE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { Table } from '../../../common/Table';
import GeographicZoneToolbar from './GeographicZoneToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

export class GeographicZone extends Component {
  componentDidMount() {
    const {
      dispatchGetGeographicZone,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetGeographicZone({ geographicZoneId: id });
  }

  componentWillUnmount() {
    const { dispatchClearGeographicZone } = this.props;
    dispatchClearGeographicZone();
  }

  render() {
    const { geographicZone, loading, breadcrumbs } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (geographicZone.isEmpty()) {
      content = <NoDataResource returnPage={GEOGRAPHIC_ZONE_PATH} />;
    } else {
      const cityList = geographicZone.get('cityList');
      const cities = (
        <div>
          <h3>Ciudades</h3>
          <Table
            columns={[
              {
                Header: 'Ciudad',
                accessor: 'name',
                className: 'text-center',
              },
              {
                Header: 'Departamento',
                accessor: 'region.name',
                className: 'text-center',
              },
              {
                Header: 'País',
                accessor: 'region.country.name',
                className: 'text-center',
              },
            ]}
            data={cityList}
            defaultPageSize={cityList.length}
            showPagination={false}
          />
        </div>
      );

      content = (
        <Fragment>
          <ResourceProperty label="Nombre:">
            {geographicZone.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {geographicZone.get('description')}
          </ResourceProperty>
          {cities}
          <InternalResource
            id={geographicZone.get('id')}
            createDate={geographicZone.get('createDate')}
            lastUpdate={geographicZone.get('lastUpdate')}
          />
        </Fragment>
      );

      toolbar = (
        <GeographicZoneToolbar geographicZoneId={geographicZone.get('id')} />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Zona Geográfica"
        content={content}
      />
    );
  }
}

GeographicZone.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetGeographicZone: PropTypes.func.isRequired,
  dispatchClearGeographicZone: PropTypes.func.isRequired,
  geographicZone: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
};

GeographicZone.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  geographicZone: LocationUnit.GeographicZone.getIn(['current', 'content']),
  loading: !LocationUnit.GeographicZone.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetGeographicZone: getGeographicZone,
  dispatchClearGeographicZone: clearGeographicZone,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeographicZone);
