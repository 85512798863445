import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import CitySelect from '../../../common/forms/select/CitySelect';
import FormFooter from '../../../common/forms/FormFooter';

// eslint-disable-next-line react/prefer-stateless-function
export class CargoDistanceForm extends Component {
  render() {
    const { handleSubmit, loading } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Ciudad Origen" required>
            <Field
              name="sourceCity"
              component={CitySelect}
              type="text"
              placeholder="Ciudad Origen"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Ciudad Destino" required>
            <Field
              name="destinationCity"
              component={CitySelect}
              type="text"
              placeholder="Ciudad Origen"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Kilómetros" required>
            <Field
              name="kilometers"
              component={TextInput}
              type="text"
              placeholder="Kilómetros"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Trasbordos">
            <Field
              name="transships"
              component={TextInput}
              type="text"
              placeholder="Trasbordos"
              validate={[validateIntegerNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    );
  }
}

CargoDistanceForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

CargoDistanceForm.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.CargoDistance.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'CargoDistanceForm',
})(CargoDistanceForm);

export default connect(mapStateToProps, null)(formComponent);
