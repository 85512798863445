import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import SeatMapElement from '../seat-map-element/SeatMapElement';

// exporting unconnected component for testing
// https://bit.ly/2HtSJlT
// TODO: enhance isSelected calculation, currently
export class SeatForPricingProfile extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.openPopover = this.openPopover.bind(this);
    this.handleSeatClick = this.handleSeatClick.bind(this);
    this.handleSeatKeyPress = this.handleSeatKeyPress.bind(this);
    this.handleSeatInteraction = this.handleSeatInteraction.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.isSeat = this.isSeat.bind(this);
    this.state = {
      popoverOpen: false,
    };
  }

  isSeat() {
    return this.props.seatMapElement.isSeat;
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  openPopover() {
    this.setState({ popoverOpen: true });
  }

  closePopover() {
    this.setState({ popoverOpen: false });
  }

  handleSeatClick() {
    this.handleSeatInteraction();
  }

  handleSeatKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSeatInteraction();
    }
  }

  isSelected = () => {
    const { seatReservations, floorNumber, xPosition, yPosition } = this.props;

    const selected =
      this.isSeat() &&
      seatReservations &&
      seatReservations.find(
        (seatReservation) =>
          seatReservation.xPosition === xPosition &&
          seatReservation.yPosition === yPosition &&
          seatReservation.floorNumber === floorNumber,
      );

    if (selected) return true;

    return false;
  };

  isDisabled = () => {
    const { enabledFloor, floorNumber } = this.props;

    return floorNumber !== enabledFloor;
  };

  handleSeatInteraction() {
    const { handleSeatSelection, xPosition, yPosition } = this.props;

    if (!this.isDisabled()) {
      handleSeatSelection({
        xPosition,
        yPosition,
      });
    }
  }

  render() {
    const { id, seatMapElement, seatNumber, xPosition, yPosition } = this.props;

    const selected = this.isSelected();

    const disabled = this.isDisabled();

    return (
      <div>
        <SeatMapElement
          onMouseEnter={this.openPopover}
          onMouseLeave={this.closePopover}
          onFocus={this.openPopover}
          onBlur={this.closePopover}
          seatNumber={seatNumber}
          onClick={this.handleSeatClick}
          onKeyPress={this.handleSeatKeyPress}
          {...seatMapElement}
          id={`id_${id}`}
          selected={selected}
          free
          className="clickable"
          disabled={disabled}
        />
        <Popover
          placement="bottom"
          isOpen={this.state.popoverOpen}
          target={`id_${id}`}
          toggle={this.toggle}
        >
          <PopoverHeader>{seatMapElement.name}</PopoverHeader>
          <PopoverBody>
            <p>
              <strong>Coordenadas</strong> <br />
              x: {xPosition}
              <br />
              y: {yPosition}
            </p>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

SeatForPricingProfile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  seatNumber: PropTypes.number.isRequired,
  floorNumber: PropTypes.number.isRequired,
  xPosition: PropTypes.number.isRequired,
  yPosition: PropTypes.number.isRequired,
  handleSeatSelection: PropTypes.func.isRequired,
  seatMapElement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    elementIcon: PropTypes.string.isRequired,
    isSeat: PropTypes.bool.isRequired,
  }).isRequired,
  seatReservations: PropTypes.arrayOf(
    PropTypes.shape({
      xPosition: PropTypes.number,
      yPosition: PropTypes.number,
      floorNumber: PropTypes.number,
    }),
  ),
  enabledFloor: PropTypes.number,
};

SeatForPricingProfile.defaultProps = {
  seatReservations: [],
  enabledFloor: null,
};

export default connect()(SeatForPricingProfile);
