import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Map } from 'immutable';
import printUnitTicketCargo from '../../../../utils/printers/UnitCargoTicket';

const PrintUnitCargoTicketButton = ({ cargoItemDetail }) => {
  const printUnitCargoTicket = () => {
    printUnitTicketCargo(cargoItemDetail.toJSON());
  };

  return (
    <Button color="light" className="border" onClick={printUnitCargoTicket}>
      <i className="fa fa-print" /> Imprimir ticket
    </Button>
  );
};

PrintUnitCargoTicketButton.propTypes = {
  cargoItemDetail: PropTypes.instanceOf(Map).isRequired,
};

const connectedComponent = connect(null)(PrintUnitCargoTicketButton);

export default connectedComponent;
