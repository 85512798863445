import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BUS_UNIT_PATH } from '../../../config/paths';
import BusHome from './BusHome';

const BusUnit = () => (
  <Switch>
    <Route exact path={BUS_UNIT_PATH} component={BusHome} />
  </Switch>
);

export default BusUnit;
