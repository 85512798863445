import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { CARGO_MINIMAL_PRICE_PATH } from '../../../../config/paths';
import {
  postCargoMinimalPrice,
  getCargoMinimalPrices,
  clearCargoMinimalPrices,
} from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CargoMinimalPriceForm from './CargoMinimalPriceForm';
import Alert from '../../../common/informative/Alert';

export class NewCargoMinimalPrice extends Component {
  componentDidMount() {
    const { dispatchGetCargoMinimalPrices } = this.props;
    dispatchGetCargoMinimalPrices();
  }

  componentWillUnmount() {
    const { dispatchClearCargoMinimalPrices } = this.props;
    dispatchClearCargoMinimalPrices();
  }

  onSubmit = (formValues) => {
    const { dispatchPostCargoMinimalPrice } = this.props;
    dispatchPostCargoMinimalPrice({ minimalPrice: formValues.minimalPrice });
  };

  render() {
    const { breadcrumbs, prices } = this.props;
    let form = <CargoMinimalPriceForm onSubmit={this.onSubmit} />;
    if (prices.get('numberOfElements') > 0) {
      form = <Alert message="No puede agregar todo precio mínimo" />;
    }
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Nuevo Precio Mínimo de Carga</h1>
            <p>Crear nuevo precio mínimo de carga.</p>
          </Col>
        </Row>
        {form}
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Precio Mínimo de Carga',
      href: CARGO_MINIMAL_PRICE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  prices: CargoUnit.CargoMinimalPrice.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchPostCargoMinimalPrice: postCargoMinimalPrice,
  dispatchGetCargoMinimalPrices: getCargoMinimalPrices,
  dispatchClearCargoMinimalPrices: clearCargoMinimalPrices,
};

NewCargoMinimalPrice.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  prices: PropTypes.instanceOf(Immutable.Map),
  dispatchPostCargoMinimalPrice: PropTypes.func.isRequired,
  dispatchGetCargoMinimalPrices: PropTypes.func.isRequired,
  dispatchClearCargoMinimalPrices: PropTypes.func.isRequired,
};

NewCargoMinimalPrice.defaultProps = {
  prices: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCargoMinimalPrice);
