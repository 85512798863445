import { number, string } from 'prop-types';

const voucherTypeBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string,
  voucherCode: string,
};

const voucherTypeBasicInformationDefaultProps = {
  description: null,
  voucherCode: null,
};

export {
  voucherTypeBasicInformationPropTypes,
  voucherTypeBasicInformationDefaultProps,
};
