import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, Form, change } from 'redux-form';
import { FormGroup, Label } from 'reactstrap';
import FormItem from '../../../../common/forms/FormItem';
import { isRequired, validateNumber } from '../../../../../utils/validators';
import TextInput from '../../../../common/forms/input/TextInput';
import Loader from '../../../../common/Loader';
import DatePicker from '../../../../common/forms/input/DatePicker';
import RouteSelect from '../../../../common/forms/select/RouteSelect';
import ServiceTypeSelect from '../../../../common/forms/select/ServiceTypeSelect';
import CircuitSelect from '../../../../common/forms/select/CircuitSelect';
import PricingProfileSelect from '../../../../common/forms/select/PricingProfileSelect';
import SeatMapSelect from '../../../../common/forms/select/SeatMapSelect';
import DriverCommissionSelect from '../../../../common/forms/select/DriverCommissionSelect';
import CabinCrewCommissionSelect from '../../../../common/forms/select/CabinCrewCommissionSelect';
import TimetableTemplate from '../TimetableTemplate';
import { PENDING_SCHEDULING_WARNING_MESSAGE } from '../../../../../config/messages';
import Alert from '../../../../common/informative/Alert';
import ItineraryScheduleFormFields from '../ItineraryScheduleFormFields';
import CompanySelect from '../../../../common/forms/select/CompanySelect';
import {
  ITINERARY_TYPE_CONTRACT,
  ITINERARY_TYPE_OPTIONS,
} from '../../../../../config/constants';
import TimeInput from '../../../../common/forms/input/TimeInput';
import Select from '../../../../common/forms/select/Select';
import FormFooter from '../../../../common/forms/FormFooter';

class ItineraryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfFloorsSelected: 0,
      showCompanySelect: false,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const { initialValues } = this.props;

    if (initialValues) {
      this.setState({
        numberOfFloorsSelected: initialValues.seatMapId
          ? initialValues.seatMapId.numberOfFloors
          : 0,
        showCompanySelect: initialValues.type.value === ITINERARY_TYPE_CONTRACT,
      });
    }
  };

  onChangeItineraryType = (option) => {
    this.setState({
      showCompanySelect: option.value === ITINERARY_TYPE_CONTRACT,
    });
  };

  onChangeSeatMap = (option) => {
    this.setState({ numberOfFloorsSelected: option.numberOfFloors || 0 });
  };

  render() {
    const { loading, handleSubmit, initialValues } = this.props;

    const { numberOfFloorsSelected, showCompanySelect } = this.state;

    if (loading) {
      return <Loader />;
    }

    let scheduledItineraryMessage = (
      <Alert type="warning" message={PENDING_SCHEDULING_WARNING_MESSAGE} />
    );

    let scheduledItineraryFields = null;

    if (initialValues.trip) {
      scheduledItineraryMessage = null;

      scheduledItineraryFields = (
        <Fragment>
          <br />
          <h3>Viaje</h3>
          <ItineraryScheduleFormFields
            form="ItineraryForm"
            itineraryId={initialValues.itineraryId}
            disableTripFields
          />
        </Fragment>
      );
    }

    let priceVariationContent = null;

    const firstPriceVariationContent = (
      <FormGroup row>
        <FormItem label="Variación de Precio del Primer Piso">
          <Field
            name="firstFloorPriceVariation"
            component={TextInput}
            type="text"
            placeholder="0.00"
            validate={[validateNumber]}
          />
        </FormItem>
      </FormGroup>
    );

    if (numberOfFloorsSelected === 1) {
      priceVariationContent = firstPriceVariationContent;
    } else if (numberOfFloorsSelected > 1) {
      priceVariationContent = (
        <Fragment>
          {firstPriceVariationContent}
          <FormGroup row>
            <FormItem label="Variación de Precio del Segundo Piso">
              <Field
                name="secondFloorPriceVariation"
                component={TextInput}
                type="text"
                placeholder="0.00"
                validate={[validateNumber]}
              />
            </FormItem>
          </FormGroup>
        </Fragment>
      );
    }

    let businessSelect = null;

    if (showCompanySelect) {
      businessSelect = (
        <FormGroup row>
          <FormItem label="Compañia Contrato">
            <Field name="companyId" component={CompanySelect} />
          </FormItem>
        </FormGroup>
      );
    }

    return (
      <Fragment>
        {scheduledItineraryMessage}
        <Form onSubmit={handleSubmit}>
          <h3>Itinerario</h3>
          <FormGroup row>
            <FormItem label="ID de Correlación Civa">
              <Field
                name="itineraryCorrelationId"
                component={TextInput}
                type="text"
                placeholder="ID de Correlación Civa"
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Ruta" required>
              <Field
                name="routeId"
                component={RouteSelect}
                validate={[isRequired]}
                onChange={this.onChangeRoute}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Tipo de Servicio" required>
              <Field
                name="serviceTypeId"
                component={ServiceTypeSelect}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Mapa de Asientos" required>
              <Field
                name="seatMapId"
                component={SeatMapSelect}
                onChange={this.onChangeSeatMap}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Perfil de Precio">
              <Field
                name="pricingProfileId"
                component={PricingProfileSelect}
                isMulti
                isClearable
              />
            </FormItem>
          </FormGroup>
          {priceVariationContent}
          <FormGroup row>
            <FormItem label="Comisión del Conductor">
              <Field
                name="driverCommissionId"
                component={DriverCommissionSelect}
                isClearable
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Circuito" required>
              <Field
                name="circuitId"
                component={CircuitSelect}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Tipo de Itinerario" required>
              <Field
                name="type"
                component={Select}
                options={ITINERARY_TYPE_OPTIONS}
                onChange={this.onChangeItineraryType}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          {businessSelect}
          <FormGroup row>
            <FormItem label="Comisión del Tripulante">
              <Field
                name="cabinCrewCommissionId"
                component={CabinCrewCommissionSelect}
                isClearable
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Tipo de Servicio" required>
              <Field
                name="serviceTypeId"
                component={ServiceTypeSelect}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem>
              <Label>
                <Field name="isMaciva" component="input" type="checkbox" />{' '}
                MACIVA
              </Label>
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Estado">
              <Label>
                <Field name="active" component="input" type="checkbox" /> Activo
              </Label>
            </FormItem>
          </FormGroup>
          <h3>Horarios</h3>
          <FormGroup row>
            <FormItem label="Fecha de Salida" required>
              <Field
                name="startDate"
                component={DatePicker}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Hora de Salida" required>
              <Field
                name="departureTime"
                component={TimeInput}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <h3>Segmentos</h3>
          <TimetableTemplate
            itineraryScheduleSegmentList={
              initialValues.itineraryScheduleSegmentList
            }
            estimatedDepartureTime={initialValues.departureTime}
          />
          {scheduledItineraryFields}
          <FormFooter />
        </Form>
      </Fragment>
    );
  }
}

ItineraryForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    departureTime: PropTypes.instanceOf(Date),
    // eslint-disable-next-line react/forbid-prop-types
    itineraryScheduleSegmentList: PropTypes.arrayOf(PropTypes.object),
    trip: PropTypes.bool,
    itineraryId: PropTypes.number,
    seatMapId: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      numberOfFloors: PropTypes.number,
    }),
    type: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
};

ItineraryForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = { dispatchChange: change };

const formComponent = reduxForm({
  form: 'ItineraryForm',
})(ItineraryForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
