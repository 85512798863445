import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { PRIVATE_SERVICE_PATH } from '../../../../config/paths';

const PrivateServiceEditButton = ({ privateServiceId, disabled }) =>
  disabled ? (
    <Button color="primary" disabled>
      <i className="fa fa-pencil-square-o" /> Editar
    </Button>
  ) : (
    <Link
      className="btn btn-primary"
      to={`${PRIVATE_SERVICE_PATH}/${privateServiceId}/edit`}
    >
      <i className="fa fa-pencil-square-o" /> Editar
    </Link>
  );

PrivateServiceEditButton.propTypes = {
  privateServiceId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PrivateServiceEditButton;
