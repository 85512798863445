import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { TICKET_PATH } from '../../../../config/paths';

const History = ({
  nextTicketId,
  nextTicketCode,
  previousTicketId,
  previousTicketCode,
}) => {
  let nextTicketComponent;
  let previousTikcetComponent;

  if (previousTicketId) {
    previousTikcetComponent = (
      <Row>
        <Col>
          <ResourceProperty label="Boleto Anterior:">
            <a href={`${TICKET_PATH}/${previousTicketId}`}>
              {previousTicketCode}
            </a>
          </ResourceProperty>
        </Col>
      </Row>
    );
  }

  if (nextTicketId) {
    nextTicketComponent = (
      <Row>
        <Col>
          <ResourceProperty label="Siguiente Boleto:">
            <a href={`${TICKET_PATH}/${nextTicketId}`}>{nextTicketCode}</a>
          </ResourceProperty>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <h3>Historial</h3>
      {nextTicketComponent}
      {previousTikcetComponent}
    </>
  );
};

History.propTypes = {
  nextTicketId: PropTypes.number,
  nextTicketCode: PropTypes.string,
  previousTicketId: PropTypes.number,
  previousTicketCode: PropTypes.string,
};

History.defaultProps = {
  nextTicketId: undefined,
  nextTicketCode: undefined,
  previousTicketId: undefined,
  previousTicketCode: undefined,
};

export default History;
