import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Label } from 'reactstrap';

// TODO test
const FormItem = ({
  label,
  labelSmWidth,
  labelXsWidth,
  itemSmWidth,
  itemXsWidth,
  required,
  children,
}) => (
  <Fragment>
    <Col sm={labelSmWidth} xs={labelXsWidth}>
      <Label>
        {label} {required ? <span className="text-danger">*</span> : null}
      </Label>
    </Col>
    <Col sm={itemSmWidth} xs={itemXsWidth}>
      {children}
    </Col>
  </Fragment>
);

FormItem.propTypes = {
  label: PropTypes.string,
  labelSmWidth: PropTypes.number,
  labelXsWidth: PropTypes.number,
  itemSmWidth: PropTypes.number,
  itemXsWidth: PropTypes.number,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
};

FormItem.defaultProps = {
  labelSmWidth: 3,
  labelXsWidth: 12,
  itemSmWidth: 9,
  itemXsWidth: 12,
  required: false,
  label: '',
};

export default FormItem;
