import React from 'react';
import PropTypes from 'prop-types';
import { Badge as ReactBadge } from 'reactstrap';

const Badge = ({ text, color, pill, cell, className, ...rest }) => {
  const myClassName = color === 'light' ? `border ${className}` : className;
  const badgeComponent = (
    <ReactBadge color={color} pill={pill} className={myClassName} {...rest}>
      {text}
    </ReactBadge>
  );
  let returnedComponent = badgeComponent;
  if (cell) returnedComponent = <div>{badgeComponent}</div>;
  return returnedComponent;
};

Badge.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  color: PropTypes.string,
  pill: PropTypes.bool,
  cell: PropTypes.bool,
  className: PropTypes.string,
};

Badge.defaultProps = {
  color: 'light',
  pill: false,
  cell: false,
  className: '',
};

export default Badge;
