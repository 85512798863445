export const FLAG_GETTING_BAGGAGES = 'FLAG_GETTING_BAGGAGES';
export const GET_BAGGAGES = 'GET_BAGGAGES';
export const CLEAR_BAGGAGES = 'CLEAR_BAGGAGES';
export const FLAG_BAGGAGE_ACTIVITY = 'FLAG_BAGGAGE_ACTIVITY';
export const GET_BAGGAGE = 'GET_BAGGAGE';
export const CLEAR_BAGGAGE = 'CLEAR_BAGGAGE';
export const FLAG_GETTING_DEPRECIATION_RULES =
  'FLAG_GETTING_DEPRECIATION_RULES';
// Excess baggage
export const FLAG_GETTING_BAGGAGE_EXCESSES = 'FLAG_GETTING_BAGGAGE_EXCESSES';
export const GET_BAGGAGE_EXCESSES = 'GET_BAGGAGE_EXCESSES';
export const CLEAR_BAGGAGE_EXCESSES = 'CLEAR_BAGGAGE_EXCESSES';
