import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTolls, clearTolls } from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

class TollSelect extends Component {
  componentDidMount() {
    this.props.dispatchGetTolls({ ...DEFAULT_QUERY_GET_ALL });
  }

  componentWillUnmount() {
    const { dispatchClearTolls } = this.props;
    dispatchClearTolls();
  }

  render() {
    const {
      options,
      loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
    } = this.props;

    const props = {
      options,
      isLoading: loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
    };

    return <Select {...props} />;
  }
}

const mapStateToProps = ({ RouteUnit }) => ({
  options: RouteUnit.Toll.getIn(['all', 'content', 'content']).map(
    ({ id, location: { name } }) => ({
      value: id,
      label: name,
    }),
  ),
  loading: RouteUnit.Toll.getIn(['all', 'loading']),
});

TollSelect.propTypes = {
  options: optionsPropTypes,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetTolls: PropTypes.func.isRequired,
  dispatchClearTolls: PropTypes.func.isRequired,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
};

TollSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  noOptionsMessage: undefined,
  isDisabled: false,
  isClearable: false,
  options: [],
};
const mapDispatchToProps = {
  dispatchGetTolls: getTolls,
  dispatchClearTolls: clearTolls,
};

export default connect(mapStateToProps, mapDispatchToProps)(TollSelect);
