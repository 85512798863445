import React from 'react';
import FormFooter from '../../../common/forms/FormFooter';
import { DELETE } from '../../../../config/permissions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { generateExecutionRegistrationDeleteEndpoint } from '../../../../config/endpoints';

export const BusActivityHistoryDeleteButton = () => (
  <div className="mt-3">
    <FormFooter
      saveButtonColor="danger"
      saveButtonText="Eliminar"
      saveButtonIcon="fa fa-trash-o"
    />
  </div>
);

export default withEndpointAuthorization({
  url: generateExecutionRegistrationDeleteEndpoint(0),
  permissionType: DELETE,
})(BusActivityHistoryDeleteButton);
