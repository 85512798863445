import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { COUNTRY_PATH } from '../../../../config/paths';
import { deleteCountry } from '../../../../actions/location/Country';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class CountryToolbar extends Component {
  state = {
    showWarningModal: false,
  };

  toggleWarningModal = (show) => this.setState({ showWarningModal: show });

  handleToggleWarningModal = (show) => () => this.toggleWarningModal(show);

  handleDeleteCountry = () => {
    const { countryId } = this.props;
    this.props.dispatchDeleteCountry({ countryId });
  };

  render() {
    const { showWarningModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        onClickConfirm={this.handleDeleteCountry}
        onClickCancel={this.toggleWarningModal(false)}
      />
    );

    let content = null;
    content = (
      <Fragment>
        <ButtonToolbar className="pull-right">
          <div>
            <Link
              className="btn btn-primary"
              to={`${COUNTRY_PATH}/${this.props.countryId}/edit`}
            >
              Editar
            </Link>{' '}
            <Button color="danger" onClick={this.toggleWarningModal}>
              Eliminar
            </Button>
          </div>
        </ButtonToolbar>
        {warningModal}
      </Fragment>
    );
    return content;
  }
}

CountryToolbar.propTypes = {
  countryId: PropTypes.number,
  dispatchDeleteCountry: PropTypes.func.isRequired,
};

CountryToolbar.defaultProps = {
  countryId: null,
};

const mapDispatchToProps = { dispatchDeleteCountry: deleteCountry };

export default connect(null, mapDispatchToProps)(CountryToolbar);
