import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { EXTRAORDINARY_MOVEMENT_CONTROL_PATH } from '../../../../config/paths';
import {
  getExtraordinaryMovementControl,
  clearExtraordinaryMovementControl,
} from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import { Table } from '../../../common/Table';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  EXTRAORDINARY_MOVEMENT_CONTROL_STATUS,
  KILOMETER_SYMBOL,
} from '../../../../config/constants';
import ExtraordinaryMovementControlToolbar from './ExtraordinaryMovementControlToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

class ExtraordinaryMovementControl extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    dispatchGetExtraordinaryMovementControl: PropTypes.func.isRequired,
    dispatchClearExtraordinaryMovementControl: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    match: matchPropTypes.isRequired,
    extraordinaryMovementControl: PropTypes.instanceOf(Immutable.Map)
      .isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { dispatchGetExtraordinaryMovementControl } = this.props;
    dispatchGetExtraordinaryMovementControl({ id });
  }

  componentWillUnmount() {
    const { dispatchClearExtraordinaryMovementControl } = this.props;
    dispatchClearExtraordinaryMovementControl();
  }

  createTableData = (extraordinaryMovementControl) => [
    {
      label: 'Origen',
      initialValue: extraordinaryMovementControl.get(
        'initialSourceLocationName',
      ),
      realValue: extraordinaryMovementControl.get('realSourceLocationName'),
    },
    {
      label: 'Destino',
      initialValue: extraordinaryMovementControl.get(
        'initialDestinationLocationName',
      ),
      realValue: extraordinaryMovementControl.get(
        'realDestinationLocationName',
      ),
    },
    {
      label: 'Kilometraje',
      initialValue: `${extraordinaryMovementControl.get(
        'initialKilometers',
      )} ${KILOMETER_SYMBOL}`,
      realValue: `${extraordinaryMovementControl.get(
        'realKilometers',
      )} ${KILOMETER_SYMBOL}`,
    },
    {
      label: 'Combustible',
      initialValue: `${extraordinaryMovementControl.get(
        'expectedFuelConsumption',
      )} galones`,
      realValue: `${extraordinaryMovementControl.get(
        'authorizedFuelConsumption',
      )} galones`,
    },
    {
      label: 'Consumo Adblue',
      initialValue: `${extraordinaryMovementControl.get(
        'expectedAdblueConsumption',
      )} litros`,
      realValue: `${extraordinaryMovementControl.get(
        'realAdblueConsumption',
      )} litros`,
    },
    {
      label: 'Peaje',
      initialValue: extraordinaryMovementControl
        .get('initialTollAmount')
        .toFixed(2),
      realValue: extraordinaryMovementControl.get('realTollAmount').toFixed(2),
    },
    {
      label: 'Comisión Piloto',
      initialValue: extraordinaryMovementControl
        .get('initialDriverCommission')
        .toFixed(2),
      realValue: extraordinaryMovementControl
        .get('realDriverCommission')
        .toFixed(2),
    },
    {
      label: 'Comisión Tripulante',
      initialValue: extraordinaryMovementControl
        .get('initialCabinCrewCommission')
        .toFixed(2),
      realValue: extraordinaryMovementControl
        .get('realCabinCrewCommission')
        .toFixed(2),
    },
    {
      label: 'Otros Gastos',
      initialValue: extraordinaryMovementControl
        .get('initialOtherExpenses')
        .toFixed(2),
      realValue: extraordinaryMovementControl
        .get('realOtherExpenses')
        .toFixed(2),
    },
  ];

  render() {
    const { breadcrumbs, extraordinaryMovementControl, loading } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (extraordinaryMovementControl.isEmpty()) {
      content = (
        <NoDataResource returnPage={EXTRAORDINARY_MOVEMENT_CONTROL_PATH} />
      );
    } else {
      const { label: statusLabel, value: statusValue } =
        EXTRAORDINARY_MOVEMENT_CONTROL_STATUS[
          extraordinaryMovementControl.get('status')
        ];

      const data = this.createTableData(extraordinaryMovementControl);

      if (
        statusValue !== EXTRAORDINARY_MOVEMENT_CONTROL_STATUS.AUTHORIZED.value
      ) {
        toolbar = (
          <ExtraordinaryMovementControlToolbar
            extraordinaryMovementControlId={extraordinaryMovementControl.get(
              'id',
            )}
          />
        );
      }

      content = (
        <Fragment>
          <ResourceProperty label="Bus:">
            {extraordinaryMovementControl.get('registeredBus').companyBusId}
          </ResourceProperty>
          <ResourceProperty label="Comentarios:">
            {extraordinaryMovementControl.get('comments')}
          </ResourceProperty>
          <ResourceProperty label="Estado:">{statusLabel}</ResourceProperty>
          <Table
            columns={[
              {
                Header: '',
                accessor: 'label',
                className: 'text-center',
              },
              {
                Header: 'Inicial',
                accessor: 'initialValue',
                className: 'text-center',
              },
              {
                Header: 'Real',
                accessor: 'realValue',
                className: 'text-center',
              },
            ]}
            data={data}
            showPagination={false}
            defaultPageSize={data.length}
          />
        </Fragment>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Control Movimiento Extraordinario"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Control Movimientos Extraordinarios',
      href: EXTRAORDINARY_MOVEMENT_CONTROL_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  extraordinaryMovementControl: TrafficUnit.ExtraordinaryMovementControl.getIn([
    'current',
    'content',
  ]),
  loading: !TrafficUnit.ExtraordinaryMovementControl.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExtraordinaryMovementControl: getExtraordinaryMovementControl,
  dispatchClearExtraordinaryMovementControl: clearExtraordinaryMovementControl,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExtraordinaryMovementControl);
