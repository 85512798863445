import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import QueryString from 'query-string';
import {
  FLAG_IS_CIRCUIT_EXTRAORDINARY,
  FLAG_BASE_FUEL_REFILL_ACTIVITY,
  CLEAR_BASE_FUEL_REFILL,
  FLAG_GETTING_BASE_FUEL_REFILLS,
  GET_BASE_FUEL_REFILLS,
  CLEAR_BASE_FUEL_REFILLS,
  GET_BASE_FUEL_REFILL,
} from '../types';
import {
  generateIsCircuitExtraordinaryEndpoint,
  BASE_FUEL_REFILL_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { BASE_FUEL_REFILL_PATH } from '../../config/paths';

const isCircuitExtraordinary =
  async ({ registeredBusId }) =>
  async (dispatch) => {
    try {
      const url = generateIsCircuitExtraordinaryEndpoint(registeredBusId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const extraordinary = await response.json();
      dispatch({
        type: FLAG_IS_CIRCUIT_EXTRAORDINARY,
        payload: extraordinary,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    }
  };

const clearIsCircuitExtraordinary = () => (dispatch) =>
  dispatch({
    type: FLAG_IS_CIRCUIT_EXTRAORDINARY,
    payload: false,
  });

const flagBaseFuelRefillActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_BASE_FUEL_REFILL_ACTIVITY,
    payload: flag,
  });

const flagGettingBaseFuelRefill = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BASE_FUEL_REFILLS,
    payload: flag,
  });

const getBaseFuelRefills =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingBaseFuelRefill(true));
      const url = `${BASE_FUEL_REFILL_ENDPOINT}?${QueryString.stringify(
        tableFilters,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_BASE_FUEL_REFILLS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBaseFuelRefill(false));
    }
  };

const postBaseFuelRefill =
  async ({
    registeredBusId,
    fuelQuantity,
    adblueQuantity,
    comment,
    createDate,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagBaseFuelRefillActivity(true));
      const payload = {
        registeredBusId,
        fuelQuantity,
        adblueQuantity,
        comment,
        createDate,
      };
      const url = BASE_FUEL_REFILL_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(BASE_FUEL_REFILL_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaseFuelRefillActivity(false));
    }
  };

const clearBaseFuelRefill = () => (dispatch) =>
  dispatch({
    type: CLEAR_BASE_FUEL_REFILL,
  });

const clearBaseFuelRefills = () => (dispatch) =>
  dispatch({
    type: CLEAR_BASE_FUEL_REFILLS,
  });

const getBaseFuelRefill =
  async ({ baseFuelRefillId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBaseFuelRefillActivity(true));
      const url = `${BASE_FUEL_REFILL_ENDPOINT}/${baseFuelRefillId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_BASE_FUEL_REFILL,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaseFuelRefillActivity(false));
    }
  };

const putBaseFuelRefill =
  async (
    baseFuelRefillId,
    { registeredBusId, fuelQuantity, adblueQuantity, comment, createDate },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagBaseFuelRefillActivity(true));
      const payload = {
        registeredBusId,
        fuelQuantity,
        adblueQuantity,
        comment,
        createDate,
        id: baseFuelRefillId,
      };
      const url = `${BASE_FUEL_REFILL_ENDPOINT}/${baseFuelRefillId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      dispatch(push(BASE_FUEL_REFILL_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagBaseFuelRefillActivity(false));
    }
  };

const getBaseFuelRefillByRegisteredBusIdAsync = async (
  registeredBusId,
  tableFilters,
) => {
  const url = `${BASE_FUEL_REFILL_ENDPOINT}/registered-bus/${registeredBusId}?${QueryString.stringify(
    tableFilters,
  )}`;

  const response = await fetch(url, DEFAULT_GET_CONFIG);
  await isErrorResponse(response);
  const refills = await response.json();

  return refills;
};

const getBaseFuelRefillByRegisteredBusId =
  async (registeredBusId, tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingBaseFuelRefill(true));

      const refills = await getBaseFuelRefillByRegisteredBusIdAsync(
        registeredBusId,
        tableFilters,
      );

      dispatch({
        type: GET_BASE_FUEL_REFILLS,
        payload: refills,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingBaseFuelRefill(false));
    }
  };

const patchItineraryInBaseFuelRefill =
  async ({ id, itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagBaseFuelRefillActivity(true));

      const payload = {
        itineraryId,
        id,
      };

      const url = BASE_FUEL_REFILL_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      const baseFuelRefill = await response.json();

      return baseFuelRefill;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagBaseFuelRefillActivity(false));
    }
  };

export {
  isCircuitExtraordinary,
  clearIsCircuitExtraordinary,
  postBaseFuelRefill,
  clearBaseFuelRefill,
  getBaseFuelRefillByRegisteredBusId,
  clearBaseFuelRefills,
  patchItineraryInBaseFuelRefill,
  getBaseFuelRefillByRegisteredBusIdAsync,
  flagGettingBaseFuelRefill,
  getBaseFuelRefills,
  putBaseFuelRefill,
  getBaseFuelRefill,
};
