import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Table from '../../../common/Table';
import {
  addShapeDetailFromParcel,
  clearDetailFromParcel,
  clearParcelsToPickup,
  getDetailFromParcel,
} from '../../../../actions/cargo/CargoPickup';

export const CargoPickupDetail = ({
  searchValues,
  columns,
  details,
  dispatchGetDetailFromParcel,
  dispatchAddShapeDetailFromParcel,
  dispatchClearDetailFromParcel,
}) => {
  const [loading, setLoading] = useState(true);
  const [cargoItems, setCargoItems] = useState({});

  useEffect(() => {
    const payload = {
      loading: true,
      content: Immutable.Map(),
      parcelId: searchValues.parcelId,
      internal: searchValues.internal,
    };
    dispatchAddShapeDetailFromParcel(payload);
    return () => {
      dispatchClearDetailFromParcel(payload);
    };
  }, []);

  useEffect(() => {
    const matchedDetail = details.find(
      (detail) =>
        detail.get('parcelId') === searchValues.parcelId &&
        detail.get('internal') === searchValues.internal,
    );

    if (matchedDetail) {
      setLoading(matchedDetail.get('loading'));
      setCargoItems(matchedDetail.get('content'));
    }
  }, [details, searchValues]);

  const data = cargoItems.content || [];
  const pages = cargoItems.totalPages || null;
  const defaultPageSize = cargoItems.size || DEFAULT_PAGE_SIZE;

  return (
    <div className="mt-3">
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        loading={loading}
        fetchData={dispatchGetDetailFromParcel}
        params={searchValues}
      />
    </div>
  );
};

const mapStateToProps = ({ CargoUnit }) => ({
  details: CargoUnit.CargoPickup.getIn(['details']),
});

const mapDispatchToProps = {
  dispatchGetDetailFromParcel: getDetailFromParcel,
  dispatchClearParcelsToPickup: clearParcelsToPickup,
  dispatchAddShapeDetailFromParcel: addShapeDetailFromParcel,
  dispatchClearDetailFromParcel: clearDetailFromParcel,
};

CargoPickupDetail.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
  searchValues: PropTypes.shape({
    parcelId: PropTypes.number,
    internal: PropTypes.bool,
  }).isRequired,
  details: PropTypes.instanceOf(Immutable.Set).isRequired,
  dispatchGetDetailFromParcel: PropTypes.func.isRequired,
  dispatchAddShapeDetailFromParcel: PropTypes.func.isRequired,
  dispatchClearDetailFromParcel: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoPickupDetail);
