import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getGeneralIncomes,
  clearGeneralIncomes,
} from '../../../../actions/index';
import ModuleList from '../../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { GENERAL_INCOME_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  GENERAL_INCOME_PATH,
  NEW_GENERAL_INCOME_PATH,
} from '../../../../config/paths';

export class GeneralIncomes extends Component {
  componentWillUnmount() {
    this.props.dispatchClearGeneralIncomes();
  }

  render() {
    const { incomes, loading, dispatchGetGeneralIncomes, breadcrumbs } =
      this.props;

    const data = incomes.get('content') || [];
    const pages = incomes.get('totalPages') || null;
    const defaultPageSize = incomes.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Ingresos"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_GENERAL_INCOME_PATH}
        tableStructure={{
          columns: GENERAL_INCOME_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: false,
          fetchData: dispatchGetGeneralIncomes,
          modelPath: GENERAL_INCOME_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

GeneralIncomes.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  incomes: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetGeneralIncomes: PropTypes.func.isRequired,
  dispatchClearGeneralIncomes: PropTypes.func.isRequired,
};

GeneralIncomes.defaultProps = {
  incomes: null,
  loading: false,
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ingresos',
      href: '',
    },
  ],
  incomes: SalesUnit.GeneralIncome.getIn(['all', 'content']),
  loading: SalesUnit.GeneralIncome.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetGeneralIncomes: getGeneralIncomes,
  dispatchClearGeneralIncomes: clearGeneralIncomes,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralIncomes);
