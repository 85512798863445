import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { MECHANIC_PATH } from '../../../../config/paths';
import {
  clearMechanic,
  getMechanic,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import MechanicHierarchyInformationResource from './resource/MechanicHierarchyInformationResource';
import CustomerBasicInformationResource from '../../user/customer/resource/CustomerBasicInformationResource';
import { EMPLOYEE_WITHOUT_BOSS } from '../../../../config/messages';
import MechanicToolbar from './MechanicToolbar';

export const Mechanic = ({
  breadcrumbs,
  dispatchGetMechanic,
  dispatchClearMechanic,
  match: {
    params: { id: mechanicId },
  },
  loading,
  mechanic,
}) => {
  useLayoutEffect(() => {
    dispatchGetMechanic({ mechanicId });

    return () => dispatchClearMechanic();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (mechanic.isEmpty())
    content = <NoDataResource returnPage={MECHANIC_PATH} />;
  else {
    const mechanicJson = mechanic.toJS();

    const bossResource = mechanicJson.boss ? (
      <CustomerBasicInformationResource {...mechanicJson.boss.customer} />
    ) : (
      <i className="text-muted">{EMPLOYEE_WITHOUT_BOSS}</i>
    );

    content = (
      <>
        <Row>
          <Col md={6} className="mb-2">
            <h3>Datos del Personal</h3>
            <CustomerBasicInformationResource {...mechanicJson.customer} />
          </Col>
          <Col md={6} className="mb-2">
            <h3>Datos del Organigrama</h3>
            <MechanicHierarchyInformationResource {...mechanicJson} />
          </Col>
        </Row>
        <h3>Datos del Jefe</h3>
        {bossResource}
      </>
    );

    toolbar = <MechanicToolbar mechanicId={mechanicJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Detalle del Personal"
      content={content}
    />
  );
};

Mechanic.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMechanic: PropTypes.func.isRequired,
  dispatchGetMechanic: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  mechanic: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Mechanic.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMechanic: clearMechanic,
  dispatchGetMechanic: getMechanic,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Personal',
      href: MECHANIC_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  mechanic: MechanicalMaintenanceUnit.Mechanic.getIn(['current', 'content']),
  loading: !MechanicalMaintenanceUnit.Mechanic.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mechanic);
