import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import AddCargoToItineraryByParcelButton from './AddCargoToItineraryByParcelButton';

const AddCargoToItineraryToolbar = ({ onClickAddItemToItinerary }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <Button color="success" type="submit">
        Subir
      </Button>{' '}
      <AddCargoToItineraryByParcelButton
        onClickAddItemToItinerary={onClickAddItemToItinerary}
      />{' '}
    </div>
  </ButtonToolbar>
);

AddCargoToItineraryToolbar.propTypes = {
  onClickAddItemToItinerary: PropTypes.func.isRequired,
};

export default AddCargoToItineraryToolbar;
