import Immutable from 'immutable';
import {
  HOME_PATH,
  BAGGAGE_UNIT_PATH,
  BAGGAGE_PATH,
  BAGGAGE_ITEM_MOVEMENT_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'baggage',
      title: 'Equipajes y Excesos',
      path: BAGGAGE_PATH,
      icon: 'suitcase',
    },
    {
      key: 'baggage-item-movement',
      title: 'Movimiento de Equipajes',
      path: BAGGAGE_ITEM_MOVEMENT_PATH,
      icon: 'truck',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Equipajes y Excesos',
      tag: 'span',
      href: BAGGAGE_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
