import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ChangeStatusPurchaseOrderButton from './ChangeStatusPurchaseOrderButton';
import EditPurchaseOrderButton from './EditPurchaseOrderButton';
import { PURCHASE_ORDER_STATUS } from '../../../../config/constants';

const PurchaseOrderToolBar = ({ purchaseOrderId, status, comments }) => {
  const purchaseOrderStatus = status || PURCHASE_ORDER_STATUS.CREATED.value;
  
  if (purchaseOrderStatus !== PURCHASE_ORDER_STATUS.CREATED.value) return null;

  return (
    <ButtonToolbar className="pull-right">
      <div>
        <ChangeStatusPurchaseOrderButton
          purchaseOrderId={purchaseOrderId}
          action={PURCHASE_ORDER_STATUS.APPROVED.value}
          comments={comments}
        />{' '}
        <ChangeStatusPurchaseOrderButton
          purchaseOrderId={purchaseOrderId}
          action={PURCHASE_ORDER_STATUS.REJECTED.value}
          comments={comments}
        />{' '}
        <EditPurchaseOrderButton purchaseOrderId={purchaseOrderId} />
      </div>
    </ButtonToolbar>
  );
};

PurchaseOrderToolBar.propTypes = {
  purchaseOrderId: PropTypes.number.isRequired,
  status: PropTypes.string,
  comments: PropTypes.string,
};

PurchaseOrderToolBar.defaultProps = {
  status: PURCHASE_ORDER_STATUS.CREATED.value,
  comments: null,
};

export default PurchaseOrderToolBar;
