// types should always be imperative verbs

export const POST_OCCUPANCY_REPORT = 'POST_OCCUPANCY_REPORT';
export const GET_OCCUPANCY_REPORTS = 'GET_OCCUPANCY_REPORTS';
export const GET_OCCUPANCY_REPORT = 'GET_OCCUPANCY_REPORT';
export const DOWNLOAD_OCCUPANCY_REPORT = 'DOWNLOAD_OCCUPANCY_REPORT';
export const CLEAR_OCCUPANCY_REPORTS = 'CLEAR_OCCUPANCY_REPORTS';
export const CLEAR_OCCUPANCY_REPORT = 'CLEAR_OCCUPANCY_REPORT';
export const FLAG_GETTING_OCCUPANCY_REPORT = 'FLAG_GETTING_OCCUPANCY_REPORT';
export const FLAG_GETTING_OCCUPANCY_REPORTS = 'FLAG_GETTING_OCCUPANCY_REPORTS';
export const CLEAR_ERROR_POSTING_OCCUPANCY_REPORT = 'CLEAR_ERROR_POSTING_OCCUPANCY_REPORT';
export const SHOW_ERROR_POSTING_OCCUPANCY_REPORT = 'SHOW_ERROR_POSTING_OCCUPANCY_REPORT';
export const SHOW_ERROR_GETTING_OCCUPANCY_REPORT = 'SHOW_ERROR_GETTING_OCCUPANCY_REPORT';
export const SHOW_ERROR_GETTING_OCCUPANCY_REPORTS = 'SHOW_ERROR_GETTING_OCCUPANCY_REPORTS';
export const CLEAR_ERROR_GETTING_OCCUPANCY_REPORT = 'CLEAR_ERROR_GETTING_OCCUPANCY_REPORT';
export const CLEAR_ERROR_GETTING_OCCUPANCY_REPORTS = 'CLEAR_ERROR_GETTING_OCCUPANCY_REPORTS';
export const FLAG_POSTING_OCCUPANCY_REPORT = 'FLAG_POSTING_OCCUPANCY_REPORT';
