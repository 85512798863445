import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_EXTRAORDINARY_MOVEMENT_CONTROLS,
  GET_EXTRAORDINARY_MOVEMENT_CONTROLS,
  CLEAR_EXTRAORDINARY_MOVEMENT_CONTROLS,
  FLAG_EXTRAORDINARY_MOVEMENT_CONTROL_ACTIVITY,
  GET_EXTRAORDINARY_MOVEMENT_CONTROL,
  CLEAR_EXTRAORDINARY_MOVEMENT_CONTROL,
} from '../types';
import { EXTRAORDINARY_MOVEMENT_CONTROL_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_PUT_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { EXTRAORDINARY_MOVEMENT_CONTROL_PATH } from '../../config/paths';

const flagGettingExtraordinaryMovementControls = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXTRAORDINARY_MOVEMENT_CONTROLS,
    payload: flag,
  });

const getExtraordinaryMovementControlsAsync = async (tableFilters) => {
  const query = tableFilters;

  const url = `${EXTRAORDINARY_MOVEMENT_CONTROL_ENDPOINT}?${QueryString.stringify(
    query,
  )}`;

  const response = await fetch(url, DEFAULT_GET_CONFIG);
  await isErrorResponse(response);
  const extraordinaryMovementControls = await response.json();

  return extraordinaryMovementControls;
};

const getExtraordinaryMovementControls =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingExtraordinaryMovementControls(true));

      const extraordinaryMovementControls =
        await getExtraordinaryMovementControlsAsync(tableFilters);

      dispatch({
        type: GET_EXTRAORDINARY_MOVEMENT_CONTROLS,
        payload: extraordinaryMovementControls,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingExtraordinaryMovementControls(false));
    }
  };

const clearExtraordinaryMovementControls = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXTRAORDINARY_MOVEMENT_CONTROLS,
  });

const flagExtraordinaryMovementControlActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXTRAORDINARY_MOVEMENT_CONTROL_ACTIVITY,
    payload: flag,
  });

const getExtraordinaryMovementControl =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagExtraordinaryMovementControlActivity(true));
      const url = `${EXTRAORDINARY_MOVEMENT_CONTROL_ENDPOINT}/${id}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const extraordinaryMovementControl = await response.json();
      dispatch({
        type: GET_EXTRAORDINARY_MOVEMENT_CONTROL,
        payload: extraordinaryMovementControl,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExtraordinaryMovementControlActivity(false));
    }
  };

const clearExtraordinaryMovementControl = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXTRAORDINARY_MOVEMENT_CONTROL,
  });

const putExtraordinaryMovementControl =
  async (
    id,
    {
      registeredBusId,
      comments,
      status,
      initialSourceLocationName,
      realSourceLocationName,
      initialDestinationLocationName,
      realDestinationLocationName,
      initialKilometers,
      realKilometers,
      expectedFuelConsumption,
      authorizedFuelConsumption,
      expectedAdblueConsumption,
      realAdblueConsumption,
      initialTollAmount,
      realTollAmount,
      initialDriverCommission,
      realDriverCommission,
      initialCabinCrewCommission,
      realCabinCrewCommission,
      initialOtherExpenses,
      realOtherExpenses,
      initialArrivalTime,
      initialDepartureTime,
      itineraryId,
      realArrivalTime,
      realDepartureTime,
      userId,
      createDate,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagExtraordinaryMovementControlActivity(true));
      const payload = {
        id,
        registeredBusId: registeredBusId.value,
        comments,
        status: status.value,
        initialSourceLocationName,
        realSourceLocationName,
        initialDestinationLocationName,
        realDestinationLocationName,
        initialKilometers,
        realKilometers,
        expectedFuelConsumption,
        authorizedFuelConsumption,
        expectedAdblueConsumption,
        realAdblueConsumption,
        initialTollAmount,
        realTollAmount,
        initialDriverCommission,
        realDriverCommission,
        initialCabinCrewCommission,
        realCabinCrewCommission,
        initialOtherExpenses,
        realOtherExpenses,
        initialArrivalTime,
        initialDepartureTime,
        itineraryId,
        realArrivalTime,
        realDepartureTime,
        userId,
        createDate,
      };
      const url = `${EXTRAORDINARY_MOVEMENT_CONTROL_ENDPOINT}/${id}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const extraordinaryMovementControl = await response.json();
      dispatch(
        push(
          `${EXTRAORDINARY_MOVEMENT_CONTROL_PATH}/${extraordinaryMovementControl.id}`,
        ),
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExtraordinaryMovementControlActivity(false));
    }
  };

export {
  clearExtraordinaryMovementControls,
  getExtraordinaryMovementControls,
  getExtraordinaryMovementControl,
  clearExtraordinaryMovementControl,
  putExtraordinaryMovementControl,
  getExtraordinaryMovementControlsAsync,
};
