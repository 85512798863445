import {
  FLAG_GETTING_ENDPOINT_CONFIGURATION,
  SHOW_ERROR_GETTING_ENDPOINT_CONFIGURATION,
  CLEAR_ERROR_GETTING_ENDPOINT_CONFIGURATION,
  GET_ENDPOINT_CONFIGURATION,
  CLEAR_ENDPOINT_CONFIGURATION,
} from '../types/index';
import {
  BASE_PUBLIC_URL,
  ENDPOINT_CONFIGURATION_ENDPOINT,
} from '../../config/endpoints';
import { handleResponseError } from '../../utils/error-handlers';

const flagGettingEndpointConfiguration = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_ENDPOINT_CONFIGURATION,
});

const clearErrorGettingEndpointConfiguration = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_ENDPOINT_CONFIGURATION,
});

const clearEndpointConfiguration = () => dispatch => dispatch({
  type: CLEAR_ENDPOINT_CONFIGURATION,
});

const showErrorGettingEndpointConfiguration = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_ENDPOINT_CONFIGURATION,
  payload: error,
});

const getEndpointConfiguration = async () => async (dispatch, getState) => {
  dispatch(clearErrorGettingEndpointConfiguration());
  const state = getState();
  // only get endpoint configuration if it's not already present
  if (state.endpoint.get('reportsServer')) {
    return false;
  }
  // start async process
  dispatch(flagGettingEndpointConfiguration(true));
  const url = BASE_PUBLIC_URL + ENDPOINT_CONFIGURATION_ENDPOINT;
  try {
    const response = await fetch(url);
    // check for response errors
    const serverError = await handleResponseError(response);
    if (serverError) {
      return dispatch(showErrorGettingEndpointConfiguration(serverError));
    }
    const endpoints = response.json();
    return dispatch({
      type: GET_ENDPOINT_CONFIGURATION,
      payload: endpoints,
    });
  } catch (err) {
    dispatch(flagGettingEndpointConfiguration(false));
    console.error(err);
    const errMsg = 'Error en el proceso de obtención de la dirección del servidor de reportes.';
    return dispatch(showErrorGettingEndpointConfiguration(errMsg));
  }
};

const getReportsServerEndpoint = async (dispatch, getState) => {
  let state = getState();
  if (!state.endpoint.get('reportsServer')) {
    await dispatch(getEndpointConfiguration());
    state = getState();
  }
  return state.endpoint.get('reportsServer');
};

export {
  getEndpointConfiguration,
  clearEndpointConfiguration,
  getReportsServerEndpoint,
};
