import PropTypes from 'prop-types';

const CountryPropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

const RegionPropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  country: PropTypes.shape(CountryPropTypes).isRequired,
  countryId: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
};

const CityPropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  region: PropTypes.shape(RegionPropTypes).isRequired,
  regionId: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
  districts: PropTypes.arrayOf(PropTypes.any).isRequired,
  geographicZoneId: PropTypes.number.isRequired,
};

const AddressPropTypes = {
  id: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  address2: PropTypes.string,
  district: PropTypes.string.isRequired,
  city: PropTypes.shape(CityPropTypes).isRequired,
  cityId: PropTypes.number.isRequired,
  postalCode: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

const IdentificationTypePropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  identificationInCountry: PropTypes.string.isRequired,
};

const CustomerPropTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  addressId: PropTypes.number.isRequired,
  address: PropTypes.shape(AddressPropTypes).isRequired,
  idDocumentNumber: PropTypes.string.isRequired,
  idCountryOfOrigin: PropTypes.string.isRequired,
  gender: PropTypes.string,
  dob: PropTypes.number,
  identificationTypeId: PropTypes.number.isRequired,
  identificationType: PropTypes.shape(IdentificationTypePropTypes).isRequired,
  mobilePhone: PropTypes.string,
  homePhone: PropTypes.string,
  workPhone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  discountCode: PropTypes.string,
};

const CompanyPropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  businessId: PropTypes.number,
  business: PropTypes.any,
  companyType: PropTypes.string.isRequired,
};

const CargoCorporateCreditInformationPropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  company: PropTypes.shape(CompanyPropTypes).isRequired,
  customerList: PropTypes.arrayOf(PropTypes.shape(CustomerPropTypes))
    .isRequired,
  contractType: PropTypes.string.isRequired,
  initialBalance: PropTypes.number.isRequired,
  actualBalance: PropTypes.number.isRequired,
  cutoffDay: PropTypes.number,
  slack: PropTypes.number.isRequired,
  active: PropTypes.bool,
  createDate: PropTypes.number.isRequired,
  lastUpdate: PropTypes.number.isRequired,
  nextCutoffDate: PropTypes.number,
  commission: PropTypes.number,
};

export default CargoCorporateCreditInformationPropTypes;
