import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { validateIntegerNumber } from '../../../../utils/validators';

const TicketCustomerSearchForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Order Id">
        <Field
          name="customerOrderId"
          component={TextInput}
          type="text"
          placeholder="Order Id"
          validate={[validateIntegerNumber]}
        />
      </FormItem>
    </FormGroup>
    <Row className="mt-3">
      <Col className="flex row-reverse">
        <Button type="submit" color="secondary" outline>
          <i className="fa fa-search" /> Buscar
        </Button>
      </Col>
    </Row>
  </Form>
);

TicketCustomerSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ItinerarySearchForm',
})(TicketCustomerSearchForm);
