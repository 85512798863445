import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomers, clearCustomers } from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_QUERY } from '../../../../config/queries';
import { customersGenerator } from '../../../../utils/app/json-generator-from-reducer';

class CustomerSelect extends Component {
  componentWillUnmount() {
    this.props.dispatchClearCustomer();
  }

  handleCustomerChange = (inputValue) => {
    const { dispatchGetCustomers } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetCustomers({ ...DEFAULT_QUERY, query: inputValue });
    }
  };

  render() {
    const {
      options,
      loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
      autoFocus,
      placeholder,
      isMulti,
    } = this.props;

    const props = {
      options,
      onInputChange: this.handleCustomerChange,
      isLoading: loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
      autoFocus,
      placeholder,
      isMulti,
    };

    return <Select {...props} />;
  }
}

const mapStateToProps = ({ UserUnit }, { options }) => {
  const customers = UserUnit.Customer.getIn(['all', 'content', 'content']);

  return {
    options: customers.length === 0 ? options : customersGenerator(customers),
    loading: UserUnit.Customer.getIn(['all', 'loading']),
  };
};

CustomerSelect.propTypes = {
  options: optionsPropTypes,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetCustomers: PropTypes.func.isRequired,
  dispatchClearCustomer: PropTypes.func.isRequired,
  input: inputPropTypes,
  meta: metaPropTypes,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isMulti: PropTypes.bool,
};

CustomerSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  placeholder: 'Ingrese Nombre o DNI',
  noOptionsMessage: undefined,
  isDisabled: false,
  isClearable: false,
  options: [],
  autoFocus: false,
  isMulti: false,
};
const mapDispatchToProps = {
  dispatchGetCustomers: getCustomers,
  dispatchClearCustomer: clearCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelect);
