import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ButtonToolbar } from 'reactstrap';
import { DRIVER_BUS_ASSIGNMENT_PATH } from '../../../../config/paths';
import {
  getDriverBusAssignment,
  clearDriverBusAssignment,
} from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { Table } from '../../../common/Table';
import EditDriverBusAssignmentButton from './EditDriverBusAssignmentButton';
import Alert from '../../../common/informative/Alert';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

class DriverBusAssignment extends Component {
  componentDidMount() {
    const {
      dispatchGetDriverBusAssignmentByBus,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDriverBusAssignmentByBus({ id });
  }

  componentWillUnmount() {
    const { dispatchClearDriverBusAssignment } = this.props;
    dispatchClearDriverBusAssignment();
  }

  render() {
    const {
      breadcrumbs,
      driverBusAssignment,
      loading,
      match: {
        params: { id },
      },
    } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (driverBusAssignment.isEmpty()) {
      content = <NoDataResource returnPage={DRIVER_BUS_ASSIGNMENT_PATH} />;
    } else {
      const driverList = driverBusAssignment.get('driverAssignmentDateList');
      const items = (
        <div>
          <h3>Lista de Conductores</h3>
          <Table
            columns={[
              {
                Header: 'Conductor',
                accessor: 'driver.driverName',
                className: 'text-center',
              },
              {
                Header: 'Tipo de Conductor',
                accessor: 'driver.driverType.name',
                className: 'text-center',
              },
            ]}
            data={driverList}
            defaultPageSize={driverList.length}
            showPagination={false}
          />
        </div>
      );

      content = (
        <Alert message="El bus seleccionado no tiene conductores asignados" />
      );

      if (driverBusAssignment.get('registeredBus')) {
        toolbar = (
          <ButtonToolbar className="pull-right">
            <div>
              <EditDriverBusAssignmentButton
                driverBusAssignmentId={parseInt(id, 10)}
              />
            </div>
          </ButtonToolbar>
        );

        content = (
          <Fragment>
            <ResourceProperty label="Bus:">
              {driverBusAssignment.get('registeredBus').companyBusId}
            </ResourceProperty>
            {items}
          </Fragment>
        );
      }
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Asignación de Conductor"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

DriverBusAssignment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDriverBusAssignmentByBus: PropTypes.func.isRequired,
  dispatchClearDriverBusAssignment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  driverBusAssignment: PropTypes.instanceOf(Immutable.Map).isRequired,
};

DriverBusAssignment.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignaciones de Conductor',
      href: DRIVER_BUS_ASSIGNMENT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  driverBusAssignment: TrafficUnit.DriverBusAssignment.getIn([
    'current',
    'content',
  ]),
  loading: !TrafficUnit.DriverBusAssignment.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDriverBusAssignmentByBus: getDriverBusAssignment,
  dispatchClearDriverBusAssignment: clearDriverBusAssignment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverBusAssignment);
