import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { AGENCY_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import AgencyForm from './AgencyForm';
import Loader from '../../../common/Loader';
import { clearAgency, getAgency, putAgency } from '../../../../actions';
import { CONTRACT_TYPE } from '../../../../config/constants';
import { DATE_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import { tzNormalizeDate } from '../../../../utils/date';

export const EditAgency = ({
  match: {
    params: { id: agencyId },
  },
  dispatchGetAgency,
  breadcrumbs,
  agency,
  loading,
  dispatchPutAgency,
  dispatchClearAgency,
}) => {
  useLayoutEffect(() => {
    dispatchGetAgency({ agencyId });

    return () => dispatchClearAgency();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    newFormValues.agencyGroupId = agency.get('agencyGroupId');
    newFormValues.agencySunatCode = agency.get('agencySunatCode');
    newFormValues.createDate = agency.get('createDate');
    newFormValues.costCenterId = agency.get('costCenterId');

    newFormValues.locationId = formValues.locationId.value;

    if (formValues.userList)
      newFormValues.userList = formValues.userList.map((user) => ({
        id: user.value,
      }));

    if (formValues.workstationList)
      newFormValues.workstationList = formValues.workstationList.map(
        (workstation) => ({
          id: workstation.value,
          name: workstation.label,
          serialNumber: workstation.serialNumber,
          printStationList: workstation.printStationList,
        }),
      );

    newFormValues.companyId = formValues.companyId
      ? formValues.companyId.value
      : null;

    newFormValues.contractType = formValues.contractType
      ? formValues.contractType.value
      : CONTRACT_TYPE.DEFAULT.value;

    if (
      formValues.contractType &&
      formValues.contractType.value === CONTRACT_TYPE.AE_AGENCY.value
    ) {
      newFormValues.commission = formValues.commission;
      newFormValues.taxExempt = formValues.taxExempt || false;
    }

    newFormValues.actualBalance =
      agency.get('actualBalance') === 0 &&
      agency.get('initialBalance') === 0 &&
      formValues.initialBalance !== 0
        ? formValues.initialBalance
        : agency.get('actualBalance');

    dispatchPutAgency(agencyId, newFormValues);
  };

  const generateInitialValues = (agencyJson) => ({
    name: agencyJson.name,
    description: agencyJson.description,
    locationId: {
      value: agencyJson.location.id,
      label: agencyJson.location.name,
    },
    userList: agencyJson.userList.map((user) => ({
      value: user.id,
      label: user.customer.fullName,
    })),
    workstationList: agencyJson.workstationList.map((workstation) => ({
      value: workstation.id,
      label: workstation.name,
      serialNumber: workstation.serialNumber,
      printStationList: workstation.printStationList,
    })),
    companyId: agencyJson.company
      ? {
          value: agencyJson.company.id,
          label: agencyJson.company.name,
        }
      : null,
    contractType: agencyJson.contractType
      ? {
          value: agencyJson.contractType,
          label: CONTRACT_TYPE[agencyJson.contractType].label,
        }
      : null,
    initialBalance: agencyJson.initialBalance,
    actualBalance: agencyJson.actualBalance,
    cutoffDay: agencyJson.cutoffDay,
    nextCutoffDate: agencyJson.nextCutoffDate
      ? tzNormalizeDate({
          date: agencyJson.nextCutoffDate,
          format: DATE_FORMAT,
        })
      : null,
    slack: agencyJson.slack,
    active: agencyJson.active,
    commission: agencyJson.commission,
    taxExempt: agencyJson.taxExempt,
  });

  let content = null;

  if (loading) content = <Loader />;
  else if (agency.isEmpty())
    content = <NoDataResource returnPage={AGENCY_PATH} />;
  else
    content = (
      <AgencyForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(agency.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Agencia"
      subtitle="Edita esta agencia"
      content={content}
    />
  );
};

const mapStateToProps = (
  { HumanResourcesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Agencias',
      href: AGENCY_PATH,
    },
    {
      text: 'Ver',
      href: `${AGENCY_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  agency: HumanResourcesUnit.Agency.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.Agency.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetAgency: getAgency,
  dispatchClearAgency: clearAgency,
  dispatchPutAgency: putAgency,
};

EditAgency.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutAgency: PropTypes.func.isRequired,
  dispatchGetAgency: PropTypes.func.isRequired,
  dispatchClearAgency: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  agency: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditAgency.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAgency);
