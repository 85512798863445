import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { BAGGAGE_ITEMS_MOVEMENT_ENDPOINT } from '../../../../../config/endpoints';
import { POST } from '../../../../../config/permissions';

const BaggageItemMovementButton = ({ itineraryId }) => (
  <Link
    className="btn btn-light border"
    to={`${ITINERARY_FOR_COUNTER_PATH}/${itineraryId}/baggage-item-movement`}
  >
    <i className="fa fa-truck" /> Movimiento de equipajes
  </Link>
);

BaggageItemMovementButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default withEndpointAuthorization({
  url: BAGGAGE_ITEMS_MOVEMENT_ENDPOINT,
  permissionType: POST,
})(BaggageItemMovementButton);
