import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { FUEL_PROFILE_PATH } from '../../../../config/paths';
import { getFuelProfile, clearFuelProfile } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import FuelProfileToolbar from './FuelProfileToolbar';
import IsActive from '../../../common/cells/IsActive';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';

class FuelProfile extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    dispatchGetFuelProfile: PropTypes.func.isRequired,
    dispatchClearFuelProfile: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    match: matchPropTypes.isRequired,
    fuelProfile: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  state = {
    fuelProfileState: false,
  };

  componentDidMount() {
    this.onMount();
  }

  componentWillUnmount() {
    const { dispatchClearFuelProfile } = this.props;
    dispatchClearFuelProfile();
  }

  onMount = () => {
    const {
      dispatchGetFuelProfile,
      fuelProfile,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetFuelProfile({ id });
    this.setState({ fuelProfileState: fuelProfile.get('isActive') });
  };

  switchState = (value) => {
    this.setState({ fuelProfileState: value });
  };

  render() {
    const { breadcrumbs, fuelProfile, loading } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (fuelProfile.isEmpty()) {
      content = <NoDataResource returnPage={FUEL_PROFILE_PATH} />;
    } else {
      const isActiveComponent = (
        <IsActive value={this.state.fuelProfileState} />
      );

      content = (
        <Fragment>
          <ResourceProperty label="Estado:">
            {isActiveComponent}
          </ResourceProperty>
          <ResourceProperty label="Nombre:">
            {fuelProfile.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {fuelProfile.get('description')}
          </ResourceProperty>
          <ResourceProperty label="Circuito:">
            {fuelProfile.get('circuit').id} - {fuelProfile.get('circuit').name}
          </ResourceProperty>
          <h3>Rellenos</h3>
          {fuelProfile.get('refuellingStopList').map((refuellingStop) => (
            <div className="pb-3" key={refuellingStop.id}>
              <h5>{refuellingStop.fuelStation.location.name}</h5>
              {refuellingStop.refuellingStopBusFuelGroupList.map((group) => (
                <ResourceProperty
                  key={group.id}
                  label={`${group.busFuelGroup.name} :`}
                >
                  {group.numberOfGallon} galones
                </ResourceProperty>
              ))}
            </div>
          ))}
          <InternalResource
            id={fuelProfile.get('id')}
            createDate={fuelProfile.get('createDate')}
            lastUpdate={fuelProfile.get('lastUpdate')}
          />
        </Fragment>
      );

      toolbar = (
        <FuelProfileToolbar
          fuelProfileId={fuelProfile.get('id')}
          onChangeState={this.switchState}
          switchIsActive={fuelProfile.get('isActive')}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Relleno Combustible por Circuito"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos Combustible por Circuito',
      href: FUEL_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  fuelProfile: TrafficUnit.FuelProfile.getIn(['current', 'content']),
  loading: !TrafficUnit.FuelProfile.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetFuelProfile: getFuelProfile,
  dispatchClearFuelProfile: clearFuelProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelProfile);
