import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { ORGANIZATION_CHART_PATH } from '../../../../config/paths';
import OrganizationChartDeleteButton from './OrganizationChartDeleteButton';

const OrganizationChartToolbar = ({ organizationChartId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${ORGANIZATION_CHART_PATH}/${organizationChartId}/edit`}
      />{' '}
      <OrganizationChartDeleteButton
        organizationChartId={organizationChartId}
      />
    </div>
  </ButtonToolbar>
);

OrganizationChartToolbar.propTypes = {
  organizationChartId: PropTypes.number.isRequired,
};

export default OrganizationChartToolbar;
