import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import InSystemNotificationEditButton from './InSystemNotificationEditButton';
import InSystemNotificationDeleteButton from './InSystemNotificationDeleteButton';

const InSystemNotificationToolbar = ({ inSystemNotificationId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <InSystemNotificationEditButton
        inSystemNotificationId={inSystemNotificationId}
      />{' '}
      <InSystemNotificationDeleteButton
        inSystemNotificationId={inSystemNotificationId}
      />
    </div>
  </ButtonToolbar>
);

InSystemNotificationToolbar.propTypes = {
  inSystemNotificationId: PropTypes.number.isRequired,
};

export default InSystemNotificationToolbar;
