export const FLAG_GETTING_ORGANIZATION_CHARTS =
  'FLAG_GETTING_ORGANIZATION_CHARTS';
export const GET_ORGANIZATION_CHARTS = 'GET_ORGANIZATION_CHARTS';
export const CLEAR_ORGANIZATION_CHARTS = 'CLEAR_ORGANIZATION_CHARTS';
export const FLAG_ORGANIZATION_CHART_ACTIVITY =
  'FLAG_ORGANIZATION_CHART_ACTIVITY';
export const GET_ORGANIZATION_CHART = 'GET_ORGANIZATION_CHART';
export const CLEAR_ORGANIZATION_CHART = 'CLEAR_ORGANIZATION_CHART';

export const GET_MANAGEMENT_CONTENT = 'GET_MANAGEMENT_CONTENT';
export const CLEAR_MANAGEMENT_CONTENT = 'CLEAR_MANAGEMENT_CONTENT';
export const GET_DEPARTMENT_CONTENT = 'GET_DEPARTMENT_CONTENT';
export const CLEAR_DEPARTMENT_CONTENT = 'CLEAR_DEPARTMENT_CONTENT';
export const GET_AREA_CONTENT = 'GET_AREA_CONTENT';
export const CLEAR_AREA_CONTENT = 'CLEAR_AREA_CONTENT';
export const GET_SECTION_CONTENT = 'GET_SECTION_CONTENT';
export const CLEAR_SECTION_CONTENT = 'CLEAR_SECTION_CONTENT';
export const GET_SPECIALITY_CONTENT = 'GET_SPECIALITY_CONTENT';
export const CLEAR_SPECIALITY_CONTENT = 'CLEAR_SPECIALITY_CONTENT';
