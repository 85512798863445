import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Loader from '../../../../common/Loader';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  ITINERARY_SCHEDULE_PATH,
  OLD_FRONT_CIRCUIT_PATH,
  OLD_FRONT_ROUTE_PATH,
  OLD_FRONT_SEAT_MAP_PATH,
  PRICING_PROFILE_PATH,
} from '../../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import IsActive from '../../../../common/cells/IsActive';
import {
  ITINERARY_REPEAT_TYPE,
  ITINERARY_TYPE,
} from '../../../../../config/constants';
import { FULL_DATE_FORMAT } from '../../../../../config/locale';
import TimetableTemplate from '../TimetableTemplate';
import ItineraryScheduleToolbar from './ItineraryScheduleToolbar';
import {
  clearItinerarySchedule,
  getItinerarySchedule,
} from '../../../../../actions/itinerary/ItinerarySchedule';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Content from '../../../../layout/Content';
import { ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE } from '../../../../../config/messages';
import Alert from '../../../../common/informative/Alert';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import BadgeList from '../../../../common/BadgeList';
import { tzNormalizeDate } from '../../../../../utils/date';
import Checkbox from '../../../../common/forms/Checkbox';

export class ItinerarySchedule extends Component {
  componentDidMount() {
    const {
      dispatchGetItinerarySchedule,
      match: {
        params: { id: itineraryScheduleId },
      },
    } = this.props;

    dispatchGetItinerarySchedule({ itineraryScheduleId });
  }

  componentWillUnmount() {
    const { dispatchClearItinerarySchedule } = this.props;
    dispatchClearItinerarySchedule();
  }

  render() {
    const { breadcrumbs, itinerarySchedule, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (itinerarySchedule.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_SCHEDULE_PATH} />;
    } else {
      const {
        itinerary,
        isMaciva,
        itineraryCode,
        itineraryScheduleVersion,
        name,
        description,
        itinerarySchedulePricingProfiles,
        company,
        repeatEvery,
        endDate,
        startDate,
        serviceType,
        repeatType,
        itineraryScheduleSegmentList,
        id,
      } = itinerarySchedule.toJS();

      let activeComponent = '-';
      let seatMapComponent = '-';
      let driverCommissionComponent = '-';
      let cabinCrewCommissionComponent = '-';

      if (itinerary) {
        activeComponent = <IsActive value={itinerary.active} />;

        seatMapComponent = itinerary.seatMap ? (
          <LinkToModuleResource
            text={`${itinerary.seatMap.id} - ${itinerary.seatMap.name}`}
            href={`${OLD_FRONT_SEAT_MAP_PATH}/${itinerary.seatMap.id}`}
            isExternalPath
          />
        ) : (
          '-'
        );

        driverCommissionComponent = itinerary.driverCommission
          ? `${itinerary.driverCommission.id} - ${itinerary.driverCommission.name}`
          : '-';

        cabinCrewCommissionComponent = itinerary.cabinCrewCommission
          ? itinerary.cabinCrewCommission.name
          : '-';
      }

      const itineraryResource = (
        <Fragment>
          <h3>Itinerario</h3>
          <ResourceProperty label="Código de Itinerario:">
            {itineraryCode}
          </ResourceProperty>
          <ResourceProperty label="Versión de Plantilla de Itinerario:">
            {itineraryScheduleVersion}
          </ResourceProperty>
          <ResourceProperty label="Nombre:">{name}</ResourceProperty>
          <ResourceProperty label="Descripción:">
            {description}
          </ResourceProperty>
          <ResourceProperty label="Ruta:">
            {itinerary ? (
              <LinkToModuleResource
                text={`${itinerary.route.id} - ${itinerary.route.name}`}
                href={`${OLD_FRONT_ROUTE_PATH}/${itinerary.route.id}`}
                isExternalPath
              />
            ) : (
              '-'
            )}
          </ResourceProperty>
          <ResourceProperty label="Circuito:">
            {itinerary ? (
              <LinkToModuleResource
                text={`${itinerary.circuit.id} - ${itinerary.circuit.name}`}
                href={`${OLD_FRONT_CIRCUIT_PATH}/${itinerary.circuit.id}`}
                isExternalPath
              />
            ) : (
              '-'
            )}
          </ResourceProperty>
          <ResourceProperty label="Tipo de Servicio:">
            {serviceType ? serviceType.name : '-'}
          </ResourceProperty>
          <ResourceProperty label="Maciva:">
            <Checkbox checked={isMaciva} />
          </ResourceProperty>
          <ResourceProperty label="Estado:">{activeComponent}</ResourceProperty>
          <ResourceProperty label="Mapa de Asientos:">
            {seatMapComponent}
          </ResourceProperty>
          <ResourceProperty label="Perfil de Precio:">
            <BadgeList
              textArray={itinerarySchedulePricingProfiles.map(
                ({ pricingProfile }) => ({
                  text: `${pricingProfile.id} - ${pricingProfile.name}`,
                  href: `${PRICING_PROFILE_PATH}/${pricingProfile.id}`,
                }),
              )}
            />
          </ResourceProperty>
          <ResourceProperty label="Comisión del Conductor:">
            {driverCommissionComponent}
          </ResourceProperty>
          <ResourceProperty label="Comisión del Servicio a Bordo:">
            {cabinCrewCommissionComponent}
          </ResourceProperty>
          <ResourceProperty label="Compañia:">
            {company ? company.name : '-'}
          </ResourceProperty>
          <ResourceProperty label="Tipo:">
            {itinerary
              ? ITINERARY_TYPE[itinerary.type.toLowerCase()].label
              : null}
          </ResourceProperty>
          <ResourceProperty label="Ingresos Totales:">
            {itinerary ? itinerary.totalIncome : null}
          </ResourceProperty>
        </Fragment>
      );

      const {
        label: repeatTypeLabel,
        unit: repeatUnit,
        value: repeatTypeValue,
      } = ITINERARY_REPEAT_TYPE[repeatType];

      let frecuencyResource = null;

      if (repeatTypeValue.value === ITINERARY_REPEAT_TYPE.NO_REPEAT.value) {
        frecuencyResource = (
          <Fragment>
            <h3>Frecuencia</h3>
            <ResourceProperty label="Tipo de Repetición:">
              {repeatTypeLabel}
            </ResourceProperty>
            <ResourceProperty label="Fecha:">
              {tzNormalizeDate({ date: startDate, format: FULL_DATE_FORMAT })}
            </ResourceProperty>
          </Fragment>
        );
      } else {
        frecuencyResource = (
          <Fragment>
            <h3>Frecuencia</h3>
            <ResourceProperty label="Tipo de Repetición:">
              {repeatTypeLabel}
            </ResourceProperty>
            <ResourceProperty label="Fecha de Inicio:">
              {tzNormalizeDate({ date: startDate, format: FULL_DATE_FORMAT })}
            </ResourceProperty>
            <ResourceProperty label="Fecha de Fin:">
              {tzNormalizeDate({ date: endDate, format: FULL_DATE_FORMAT })}
            </ResourceProperty>
            <ResourceProperty label="Repetir cada:">
              {repeatEvery} {repeatUnit}
            </ResourceProperty>
          </Fragment>
        );
      }

      let timetableTemplate = (
        <Alert
          type="warning"
          message={ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE}
        />
      );

      if (itineraryScheduleSegmentList.length) {
        timetableTemplate = (
          <TimetableTemplate
            itineraryScheduleSegmentList={itineraryScheduleSegmentList}
            estimatedDepartureTime={startDate}
            showFullDate={false}
          />
        );
      }

      const timetableResource = (
        <Fragment>
          <h3>Horarios</h3>
          <div className="mb-3">{timetableTemplate}</div>
        </Fragment>
      );

      content = (
        <Fragment>
          {itineraryResource}
          {frecuencyResource}
          {timetableResource}
        </Fragment>
      );

      toolbar = <ItineraryScheduleToolbar itineraryId={id} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Plantilla de Itinerario"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    { text: 'Plantillas de Itinerario', href: ITINERARY_SCHEDULE_PATH },
    { text: 'Ver', href: '' },
  ],
  itinerarySchedule: ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'content',
  ]),
  loading: !ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItinerarySchedule: getItinerarySchedule,
  dispatchClearItinerarySchedule: clearItinerarySchedule,
};

ItinerarySchedule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  itinerarySchedule: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetItinerarySchedule: PropTypes.func.isRequired,
  dispatchClearItinerarySchedule: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItinerarySchedule);
