import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LIQUIDATION_SESSION_PATH } from '../../../../config/paths';

const LiquidationSessionEditButton = ({ liquidationSessionId }) => (
  <Link
    className="btn btn-primary"
    to={`${LIQUIDATION_SESSION_PATH}/${liquidationSessionId}/edit`}
  >
    Editar
  </Link>
);

LiquidationSessionEditButton.propTypes = {
  liquidationSessionId: PropTypes.number.isRequired,
};

export default LiquidationSessionEditButton;
