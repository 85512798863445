import Immutable from 'immutable';
import {
  FLAG_GETTING_ACTIVE_SALES_SESSION,
  GET_ACTIVE_SALES_SESSION,
  SHOW_ERROR_GETTING_ACTIVE_SALES_SESSION,
  CLEAR_ERROR_GETTING_ACTIVE_SALES_SESSION,
  CLEAR_ACTIVE_SALES_SESSION,
  GET_SALES_SESSIONS,
  CLEAR_SALES_SESSIONS,
  CLEAR_SALES_SESSION,
  GET_SALES_SESSION,
  FLAG_SALES_SESSION_ACTIVITY,
  FLAG_GETTING_SALES_SESSIONS,
  GET_OLD_SALES_SESSION,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  active: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    oldSalesSession: Immutable.Map(),
    activity: Immutable.List(),
  }),
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_ACTIVE_SALES_SESSION:
      return state.setIn(['active', 'loading'], action.payload);
    case SHOW_ERROR_GETTING_ACTIVE_SALES_SESSION:
      return state.setIn(['active', 'error'], action.payload);
    case CLEAR_ERROR_GETTING_ACTIVE_SALES_SESSION:
      return state.deleteIn(['active', 'error']);
    case GET_ACTIVE_SALES_SESSION:
      return state.setIn(['active', 'content'], Immutable.Map(action.payload));
    case CLEAR_ACTIVE_SALES_SESSION:
      return state.setIn(['active', 'content'], Immutable.Map());

    case FLAG_GETTING_SALES_SESSIONS:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_SALES_SESSIONS:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_SALES_SESSIONS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );

    case FLAG_SALES_SESSION_ACTIVITY:
      // raising flag
      if (action.payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(action.payload),
        );
      }

      // lowering flag
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );

    case GET_SALES_SESSION:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case CLEAR_SALES_SESSION:
      return state.setIn(
        ['current', 'content'],
        INITIAL_STATE.getIn(['current', 'content']),
      );

    case GET_OLD_SALES_SESSION:
      return state.setIn(
        ['current', 'oldSalesSession'],
        Immutable.Map(action.payload),
      );

    default:
      return state;
  }
};
