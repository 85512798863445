import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PRINT_STATION_PATH } from '../../../../config/paths';
import { getPrintStation, clearPrintStation } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { Table } from '../../../common/Table';
import PrintStationToolbar from './PrintStationToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import { padStart } from '../../../../utils/string';

class PrintStation extends Component {
  componentDidMount() {
    const {
      dispatchGetPrintStation,
      match: {
        params: { id: printStationId },
      },
    } = this.props;

    dispatchGetPrintStation({ printStationId });
  }

  componentWillUnmount() {
    const { dispatchClearPrintStation } = this.props;
    dispatchClearPrintStation();
  }

  render() {
    const { breadcrumbs, printStation, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (printStation.isEmpty()) {
      content = <NoDataResource returnPage={PRINT_STATION_PATH} />;
    } else {
      const basicInformationResource = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="Número de Serie:">
            {printStation.get('serialNumber')}
          </ResourceProperty>
          <ResourceProperty label="Nombre:">
            {printStation.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {printStation.get('description')}
          </ResourceProperty>
        </Fragment>
      );

      const printTemplateData = printStation.get('printTemplateList');

      const userResource = (
        <div className="mb-3">
          <h3>Plantillas de Impresión</h3>
          <Table
            columns={[
              {
                Header: 'Plantilla de Impresión',
                accessor: 'printTemplateType',
                className: 'text-center',
              },
              {
                Header: 'Serie',
                accessor: 'documentSeries',
                className: 'text-center',
              },
              {
                Header: 'Correlativo Siguiente',
                accessor: 'documentCode',
                className: 'text-center',
                Cell: (row) => padStart(row.value, '7'),
              },
            ]}
            data={printTemplateData}
            defaultPageSize={printTemplateData.length}
            showPagination={false}
          />
        </div>
      );

      const internalResource = (
        <InternalResource
          id={printStation.get('id')}
          createDate={printStation.get('createDate')}
          lastUpdate={printStation.get('lastUpdate')}
        />
      );

      content = (
        <Fragment>
          {basicInformationResource}
          {userResource}
          {internalResource}
        </Fragment>
      );

      toolbar = <PrintStationToolbar printStationId={printStation.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Impresora"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

PrintStation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetPrintStation: PropTypes.func.isRequired,
  dispatchClearPrintStation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  printStation: PropTypes.instanceOf(Immutable.Map).isRequired,
};

PrintStation.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Impresoras',
      href: PRINT_STATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  printStation: HumanResourcesUnit.PrintStation.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.PrintStation.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetPrintStation: getPrintStation,
  dispatchClearPrintStation: clearPrintStation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintStation);
