import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { enumToSelectOptions } from '../../../../utils/enum';
import { EXPENSE_TYPE_LABEL } from '../../../../config/constants';
import SelectFilter from '../../filters/SelectFilter';

const ExpenseTypeLabelSelect = ({
  noOptionsMessage,
  input,
  meta,
  isDisabled,
  useForFilter,
  onChangeFilter,
  isMulti,
  ...rest
}) =>
  useForFilter ? (
    <SelectFilter
      options={enumToSelectOptions(EXPENSE_TYPE_LABEL)}
      isMulti={isMulti}
      onChangeFilter={onChangeFilter}
    />
  ) : (
    <Select
      options={enumToSelectOptions(EXPENSE_TYPE_LABEL)}
      noOptionsMessage={noOptionsMessage}
      input={input}
      meta={meta}
      isDisabled={isDisabled}
      isMulti={isMulti}
      {...rest}
    />
  );
ExpenseTypeLabelSelect.propTypes = {
  noOptionsMessage: PropTypes.func,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
  useForFilter: PropTypes.bool,
  onChangeFilter: PropTypes.func,
  isMulti: PropTypes.bool,
};

ExpenseTypeLabelSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  noOptionsMessage: undefined,
  isDisabled: false,
  useForFilter: false,
  onChangeFilter: () => {},
  isMulti: false,
};

export default ExpenseTypeLabelSelect;
