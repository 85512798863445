import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditExtraordinaryMovementControlButton from './EditExtraordinaryMovementControlButton';

const ExtraordinaryMovementControlToolbar = ({
  extraordinaryMovementControlId,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditExtraordinaryMovementControlButton
        extraordinaryMovementControlId={extraordinaryMovementControlId}
      />
    </div>
  </ButtonToolbar>
);

ExtraordinaryMovementControlToolbar.propTypes = {
  extraordinaryMovementControlId: PropTypes.number.isRequired,
};

export default ExtraordinaryMovementControlToolbar;
