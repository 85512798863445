const validate = (values) => {
  const errors = {};

  const assignEmployeeErrors = [];

  values.activityBusExecutionList.forEach((activityBusExecution, index) => {
    const employeeError = {};

    activityBusExecution.activityTaskList.forEach((activityTask) => {
      const existEmployee = activityBusExecution.employeeId.every(
        (employee) =>
          activityTask.employeeId &&
          employee.value !== activityTask.employeeId.value,
      );
      if (existEmployee) {
        employeeError.employeeId =
          'Hay un empleado seleccionado en la tarea pero no en la actividad';
        assignEmployeeErrors[index] = employeeError;
      }
    });

    if (assignEmployeeErrors.length)
      errors.activityBusExecutionList = assignEmployeeErrors;
  });

  return errors;
};

export default validate;
