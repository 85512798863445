import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import TaskFields from './TaskFields';
import {
  deleteActivityTaskExecution,
  postActivityBusExecutionEmployee,
  postActivityTaskExecution,
} from '../../../../../actions';
import { ACTIVITY_TASK_EXECUTION_STATUS } from '../../../../../config/constants';

const validateIfExistActivityEmployeeSelected = (
  activityEmployeeOptions,
  employeeId,
) => activityEmployeeOptions.some((option) => option.value === employeeId);

const RenderTaskList = ({
  fields,
  activityIndex,
  maintenanceServiceOrder,
  dispatchPostActivityTaskExecution,
  dispatchDeleteActivityTaskExecution,
  dispatchPostActivityBusExecutionEmployee,
  dispatchChange,
}) => {
  const { activityBusExecutionList } = maintenanceServiceOrder.toJS();

  const onChangeMechanic = async (
    inputActionMeta,
    index,
    activityEmployeeOptions,
    selectedPreviousEmployeeValue,
  ) => {
    const {
      id: activityBusExecutionId,
      activityBus: {
        activity: { activityTaskList },
      },
    } = activityBusExecutionList[activityIndex];

    const employeeOption =
      inputActionMeta.action === 'select-option'
        ? inputActionMeta.option
        : inputActionMeta.removedValue;

    if (inputActionMeta.action === 'select-option') {
      if (selectedPreviousEmployeeValue) {
        await dispatchDeleteActivityTaskExecution({
          activityBusExecutionId,
          employeeId: selectedPreviousEmployeeValue,
        });
      }

      const activityTaskExecutionRegistered =
        await dispatchPostActivityTaskExecution({
          activityBusExecutionId,
          employeeId: employeeOption.value,
          activityTaskId: activityTaskList[index].id,
          status: ACTIVITY_TASK_EXECUTION_STATUS.ASSIGNED.value,
        });
      if (activityTaskExecutionRegistered) {
        const employeeNotExist = !validateIfExistActivityEmployeeSelected(
          activityEmployeeOptions,
          employeeOption.value,
        );
        if (employeeNotExist) {
          const activityBusExecutionEmployeeRegistered =
            await dispatchPostActivityBusExecutionEmployee({
              activityBusExecutionId,
              employeeId: employeeOption.value,
            });
          if (activityBusExecutionEmployeeRegistered) {
            const newActivityEmployeeOptions = [
              ...activityEmployeeOptions,
              employeeOption,
            ];
            dispatchChange(
              'AssignEmployeesToMaintenanceServiceOrderForm',
              `activityBusExecutionList[${activityIndex}].employeeId`,
              newActivityEmployeeOptions,
            );
            dispatchChange(
              'AssignEmployeesToMaintenanceServiceOrderForm',
              `activityBusExecutionList[${activityIndex}].activityTaskList[${index}].employeeId`,
              inputActionMeta.option,
            );
          }
        }
      }
    }
    if (inputActionMeta.action === 'clear') {
      // inputActionMeta.action is equals to 'remove-value' when
      // `${task}.employeeId` of TaskFields is multi
      const activityBusExecutionDeleted =
        await dispatchDeleteActivityTaskExecution({
          activityBusExecutionId,
          employeeId: employeeOption.value,
        });

      if (activityBusExecutionDeleted) {
        dispatchChange(
          'AssignEmployeesToMaintenanceServiceOrderForm',
          `activityBusExecutionList[${activityIndex}].activityTaskList[${index}].employeeId`,
          null,
        );
      }
    }
  };

  return fields.map((task, index) => (
    <TaskFields
      task={task}
      key={task}
      index={index}
      activityIndex={activityIndex}
      fields={fields}
      onChange={onChangeMechanic}
    />
  ));
};

const mapStateToProps = (state) => ({
  maintenanceServiceOrder:
    state.MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
});

const mapDispatchToProps = {
  dispatchPostActivityTaskExecution: postActivityTaskExecution,
  dispatchDeleteActivityTaskExecution: deleteActivityTaskExecution,
  dispatchChange: change,
  dispatchPostActivityBusExecutionEmployee: postActivityBusExecutionEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderTaskList);
