import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Form, FormGroup, Button, Row, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import CircuitSelect from '../../../common/forms/select/CircuitSelect';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  ITINERARY_GROUP_STATUS,
  MAX_PAGE_SIZE,
} from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';
import {
  clearItineraries,
  getItinerariesNotAddedToItineraryGroup,
} from '../../../../actions';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import TextInput from '../../../common/forms/input/TextInput';
import Loader from '../../../common/Loader';

export class ItineraryGroupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gettingItineraries: false,
      itineraryOptions: null,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    this.props.dispatchClearItineraries();
  }

  onMount = () => {
    const {
      initialValues: { circuitGroupItineraryList },
    } = this.props;

    if (circuitGroupItineraryList) {
      this.setState({ itineraryOptions: circuitGroupItineraryList });
    }
  };

  onDidUpdate = () => {
    if (this.state.gettingItineraries) {
      const { loadingItineraries, itineraries } = this.props;

      if (!loadingItineraries && itineraries.get('totalElements') > 0) {
        const itineraryOptions = itineraries
          .get('content')
          .map(({ id, routeName, departuretime, serviceType }) => ({
            value: id,
            label: `${id} - ${routeName} ${tzNormalizeDate({
              date: departuretime,
              format: DATE_TIME_FORMAT,
            })} Servicio ${serviceType}`,
          }));

        this.setState({ gettingItineraries: false, itineraryOptions });
      }
    }
  };

  onChangeBus = ({ value }) => {
    if (value) {
      const {
        dispatchGetItinerariesNotAddedToItineraryGroup,
        dispatchClearItineraries,
      } = this.props;

      this.setState({ gettingItineraries: true, itineraryOptions: null });

      const query = {
        size: MAX_PAGE_SIZE,
      };

      dispatchClearItineraries();
      dispatchGetItinerariesNotAddedToItineraryGroup({
        registeredBusId: value,
        query,
      });
    }
  };

  render() {
    const { loading, handleSubmit, loadingItineraries } = this.props;

    const { itineraryOptions } = this.state;

    if (loading) {
      return <Loader />;
    }

    const circuitGroupItineraryListPlaceholder = itineraryOptions
      ? 'Seleccione los itinerarios'
      : 'Seleccione un Bus';

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Bus" required>
            <Field
              name="registeredBusId"
              component={RegisteredBusSelect}
              isClearable={false}
              onChange={this.onChangeBus}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Itinerario" required>
            <Field
              name="circuitGroupItineraryList"
              component={Select}
              isMulti
              placeholder={circuitGroupItineraryListPlaceholder}
              options={itineraryOptions}
              isLoading={loadingItineraries}
              isDisabled={!itineraryOptions}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Circuito" required>
            <Field
              name="circuitId"
              component={CircuitSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Estado" required>
            <Field
              name="status"
              component={Select}
              options={enumToSelectOptions(ITINERARY_GROUP_STATUS)}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentarios">
            <Field name="comments" component={TextInput} type="textarea" />
          </FormItem>
        </FormGroup>
        <Row className="mt-5">
          <Col className="flex row-reverse">
            <Button type="submit" color="primary">
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

ItineraryGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetItinerariesNotAddedToItineraryGroup: PropTypes.func.isRequired,
  dispatchClearItineraries: PropTypes.func.isRequired,
  loadingItineraries: PropTypes.bool,
  itineraries: PropTypes.instanceOf(Immutable.Map),
  initialValues: PropTypes.shape({
    circuitGroupItineraryList: PropTypes.arrayOf(
      PropTypes.shape({
        itineraryId: PropTypes.number,
      }),
    ),
  }),
};

ItineraryGroupForm.defaultProps = {
  initialValues: null,
  loadingItineraries: false,
  itineraries: null,
};

const mapStateToProps = ({ RouteUnit, ItineraryUnit }) => ({
  loading: !RouteUnit.ItineraryGroup.getIn(['current', 'activity']).isEmpty(),
  loadingItineraries: ItineraryUnit.Itinerary.getIn(['all', 'loading']),
  itineraries: ItineraryUnit.Itinerary.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetItinerariesNotAddedToItineraryGroup:
    getItinerariesNotAddedToItineraryGroup,
  dispatchClearItineraries: clearItineraries,
};

const formComponent = reduxForm({
  form: 'ItineraryGroupForm',
})(ItineraryGroupForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
