import { x, y, createPdfDocument, addText, subtitleSize } from '../pdf';
import { tzNormalizeDate } from '../date';
import { LOCAL_FULL_TIME_FORMAT } from '../../config/locale';

const rectangleHeight = 15;
const initialX = x;
const initialY = y;

const printBus = (seatingChart, doc) => {
  const myX = 90;
  let myY = 20;

  addText(doc, 'Bus', myX, myY);
  addText(doc, seatingChart.bus.companyBusId.toString(), myX + 15, myY);

  addText(doc, 'Placa', myX, (myY += 5));
  addText(doc, seatingChart.bus.licensePlate.toString(), myX + 15, myY);

  addText(doc, 'Marca', myX, (myY += 5));
  addText(doc, seatingChart.bus.busChassis.make.toString(), myX + 15, myY);

  addText(doc, 'Modelo', myX, (myY += 5));
  addText(doc, seatingChart.bus.busChassis.model.toString(), myX + 15, myY);
};

const printItinerary = (seatingChart, doc) => {
  const myX = 140;
  let myY = 20;

  addText(doc, 'Origen', myX, myY);
  addText(doc, seatingChart.origin, myX + 15, myY);

  addText(doc, 'Destino', myX, (myY += 5));
  addText(doc, seatingChart.destination, myX + 15, myY);

  addText(doc, 'Fecha', myX, (myY += 5));
  addText(doc, seatingChart.tripDate, myX + 15, myY);

  addText(doc, 'Hora', myX, (myY += 5));
  addText(doc, seatingChart.tripHour, myX + 15, myY);
};

const printCrew = (seatingChart, doc) => {
  const myX = 5;
  let myY = 15;

  seatingChart.driverAssignmentList.forEach((element) => {
    addText(doc, 'Piloto', myX, (myY += 5));
    addText(
      doc,
      element.driver.customer.fullName.substring(0, 40),
      myX + 15,
      myY,
    );
  });

  seatingChart.cabinCrewAssignmentList.forEach((element) => {
    addText(doc, 'SAB', myX, (myY += 5));
    addText(
      doc,
      element.cabinCrew.customer.fullName.substring(0, 40),
      myX + 15,
      myY,
    );
  });

  addText(doc, 'Embarque', myX, (myY += 10));
  addText(doc, seatingChart.boarding || '', myX + 30, myY);

  addText(doc, 'Desembarque', myX, (myY += 5));
  addText(doc, seatingChart.disembarking || '', myX + 30, myY);

  return myY;
};

const sortBySeatPosition = (passengerSeats) => {
  passengerSeats.sort((a, b) => {
    if (a.floorNumber < b.floorNumber) {
      return -1;
    } else if (a.floorNumber > b.floorNumber) {
      return 1;
    } else if (a.yPosition < b.yPosition) {
      return -1;
    } else if (a.yPosition > b.yPosition) {
      return 1;
    } else if (a.xPosition < b.xPosition) {
      return -1;
    }
    return 1;
  });
};

const setSeatNumberFormat = (doc) => {
  doc.setFontSize(subtitleSize + 1);
  doc.setTextColor(0);
};

const setNormalFormat = (doc) => {
  doc.setFont('Tahoma', 'normal', 'normal');
  doc.setFontSize(subtitleSize);
  doc.setTextColor(0);
};

const checkBreakLine = (index, myY) => {
  if (index % 4 === 3) {
    return myY + 15;
  }
  return myY;
};

const isNewPageNeeded = (myY, doc) => {
  if (myY + rectangleHeight >= Math.round(doc.internal.pageSize.getHeight())) {
    doc.addPage();
    return initialY;
  }
  return myY;
};

const printPassengerSeat = (passenger, xPosition, yPosition, doc) => {
  let myX = xPosition;
  const myY = yPosition;
  doc.rect(myX, myY, 50, rectangleHeight);
  myX += 1;

  setSeatNumberFormat(doc);
  addText(doc, passenger.seatNumber, myX, myY + 4);
  setNormalFormat(doc);
  if (passenger.idDocumentNumber) {
    addText(doc, passenger.fullName.substring(0, 20), myX + 5, myY + 4);
    addText(doc, `ID# ${passenger.idDocumentNumber}`, myX, myY + 9);
    addText(doc, passenger.boardingLocation.substring(0, 10), myX, myY + 14);
    addText(doc, '>', myX + 23, myY + 14);
    addText(
      doc,
      passenger.disembarkingLocation.substring(0, 10),
      myX + 26,
      myY + 14,
    );
  } else {
    addText(doc, 'LIBRE', myX + 15, myY + 9);
  }
};
const printPassengers = (seatingChart, previousY, doc) => {
  sortBySeatPosition(seatingChart.seatingChartPassengerList);
  const passengers = seatingChart.seatingChartPassengerList;
  let myY = previousY + 5;
  if (passengers && passengers.length > 0) {
    passengers.forEach((passenger, index) => {
      // calculate myX based on current passenger index
      const myX = initialX + (index % 4) * 50;
      myY = isNewPageNeeded(myY, doc);
      printPassengerSeat(passenger, myX, myY, doc);
      myY = checkBreakLine(index, myY);
    });
  }
};

const printSeatingChart = (seatingChart) => {
  const doc = createPdfDocument();

  setNormalFormat(doc);
  const horaImp = tzNormalizeDate({ format: LOCAL_FULL_TIME_FORMAT });

  const myX = 5;
  let myY = 5;
  addText(doc, 'Turismo Civa S.A.C.', myX, myY);
  addText(doc, 'CROQUIS DE ASIENTOS', myX + 70, (myY += 5));
  addText(doc, `Hora imp. ${horaImp}`, myX + 140, myY);
  const finalY = printCrew(seatingChart, doc);
  printBus(seatingChart, doc);
  printItinerary(seatingChart, doc);
  printPassengers(seatingChart, finalY, doc);
  window.open(doc.output('bloburl'), '_blank');
};

// eslint-disable-next-line import/prefer-default-export
export { printSeatingChart };
