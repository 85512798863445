import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import { DatePicker as DatePickerWidget } from 'react-widgets';
import { FormText, UncontrolledTooltip } from 'reactstrap';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../config/locale';
import { inputPropTypes } from './InputPropTypes';

const DatePicker = (props) => {
  const {
    input: { onChange, value, name },
    min,
    max,
    meta: { touched, error, warning },
    format,
    time,
    disabled,
    onSelect,
    placeholder,
    showToolTip,
    id,
  } = props;

  const inputProps = {
    onChange,
    onSelect,
    format,
    disabled,
    placeholder,
    name,
  };

  if (min) inputProps.min = new Date(min);
  if (max) inputProps.max = new Date(max);
  if (value) inputProps.value = new Date(value);

  if (time) {
    inputProps.includeTime = true;
    inputProps.placeholder = DATE_TIME_FORMAT.toLowerCase();
  }

  let toolTipComponent = null;
  if (showToolTip && id)
    toolTipComponent = (
      <UncontrolledTooltip placement="top" target={id}>
        {placeholder}
      </UncontrolledTooltip>
    );

  return (
    <div>
      <div id={id}>
        <DatePickerWidget {...inputProps} />
      </div>
      {toolTipComponent}
      <FormText color="danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </FormText>
    </div>
  );
};

DatePicker.propTypes = {
  input: inputPropTypes.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  min: PropTypes.oneOfType([PropTypes.instanceOf(Moment), PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.instanceOf(Moment), PropTypes.string]),
  format: PropTypes.string,
  time: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  showToolTip: PropTypes.bool,
  id: PropTypes.string,
};

DatePicker.defaultProps = {
  min: null,
  max: null,
  format: DATE_FORMAT,
  time: false,
  disabled: false,
  onSelect: () => { },
  placeholder: DATE_FORMAT,
  showToolTip: false,
  id: null,
};

export default DatePicker;
