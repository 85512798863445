import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import CompanyEditButton from './CompanyEditButton';
import CompanyDeleteButton from './CompanyDeleteButton';

const CompanyToolbar = ({ companyId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <CompanyEditButton companyId={companyId} />{' '}
      <CompanyDeleteButton companyId={companyId} />
    </div>
  </ButtonToolbar>
);

CompanyToolbar.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyToolbar;
