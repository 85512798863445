// types should always be imperative verbs

export const GET_ENDPOINT_CONFIGURATION = 'GET_ENDPOINT_CONFIGURATION';

// errors
export const SHOW_ERROR_AUTHENTICATING_USER = 'SHOW_ERROR_AUTHENTICATING_USER';
export const SHOW_ERROR_DEAUTHENTICATING_USER =
  'SHOW_ERROR_DEAUTHENTICATING_USER';
export const SHOW_ERROR_FETCHING_SELF = 'SHOW_ERROR_FETCHING_SELF';
export const SHOW_ERROR_GETTING_ENDPOINT_CONFIGURATION =
  'SHOW_ERROR_GETTING_ENDPOINT_CONFIGURATION';
export const CLEAR_ERROR_GETTING_ENDPOINT_CONFIGURATION =
  'CLEAR_ERROR_GETTING_ENDPOINT_CONFIGURATION';
export const CLEAR_ERROR_AUTHENTICATING_USER =
  'CLEAR_ERROR_AUTHENTICATING_USER';
export const CLEAR_ERROR_DEAUTHENTICATING_USER =
  'CLEAR_ERROR_DEAUTHENTICATING_USER';

// flags
export const FLAG_AUTHENTICATING_USER = 'FLAG_AUTHENTICATING_USER';
export const FLAG_DEAUTHENTICATING_USER = 'FLAG_DEAUTHENTICATING_USER';
export const FLAG_GETTING_ENDPOINT_CONFIGURATION =
  'FLAG_GETTING_ENDPOINT_CONFIGURATION';

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const DEAUTHENTICATE_USER = 'DEAUTHENTICATE_USER';

// fetches
export const FETCH_SELF = 'FETCH_SELF';

// resets
export const CLEAR_ENDPOINT_CONFIGURATION = 'CLEAR_ENDPOINT_CONFIGURATION';

export * from './report';
export * from './bus';
export * from './traffic';
export * from './route';
export * from './booking';
export * from './location';
export * from './accounting';
export * from './user';
export * from './sales';
export * from './itinerary';
export * from './reservation';
export * from './human-resources';
export * from './authentication';
export * from './baggage';
export * from './cargo';
export * from './contract';
export * from './security';
export * from './system';
export * from './mechanical-maintenance';
