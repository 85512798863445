import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import { isRequired, validateEndDate } from '../../../../../utils/validators';
import LocationSelect from '../../../../common/forms/select/LocationSelect';
import DatePicker from '../../../../common/forms/input/DatePicker';
import TextInput from '../../../../common/forms/input/TextInput';
import GeographicZoneSelect from '../../../../common/forms/select/GeographicZoneSelect';

const validateDate = (value, allValues) =>
  validateEndDate(value, allValues.fromDateString);

export const LiveItinerarySalesControlSearchForm = ({
  sourceLocationIsRequired,
  destinationLocationIsRequired,
  geographicZoneIsRequired,
  handleSubmit,
}) => {
  const formProps = { onSubmit: handleSubmit };
  const buttonProps = { type: 'submit' };

  return (
    <Form {...formProps}>
      <FormGroup row>
        <FormItem label="Origen" required={sourceLocationIsRequired}>
          <Field
            name="sourceLocationId"
            component={LocationSelect}
            forSales
            validate={sourceLocationIsRequired ? [isRequired] : null}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Destino" required={destinationLocationIsRequired}>
          <Field
            name="destinationLocationId"
            component={LocationSelect}
            forSales
            validate={destinationLocationIsRequired ? [isRequired] : null}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Zona Geográfica" required={geographicZoneIsRequired}>
          <Field
            name="geographicZoneId"
            component={GeographicZoneSelect}
            forSales
            validate={geographicZoneIsRequired ? [isRequired] : null}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha Inicial" required>
          <Field
            name="fromDateString"
            component={DatePicker}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha Final" required>
          <Field
            name="toDateString"
            component={DatePicker}
            validate={[isRequired, validateDate]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Hora">
          <Field
            name="departureTimeString"
            component={TextInput}
            type="time"
            placeholder="HH:mm"
          />
        </FormItem>
      </FormGroup>
      <Row>
        <Col className="flex row-reverse">
          <Button color="secondary" outline {...buttonProps}>
            <i className="fa fa-search" /> Buscar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

LiveItinerarySalesControlSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sourceLocationIsRequired: PropTypes.bool,
  destinationLocationIsRequired: PropTypes.bool,
  geographicZoneIsRequired: PropTypes.bool,
  initialValues: PropTypes.shape({
    fromDateString: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    departureDayString: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }),
};

LiveItinerarySalesControlSearchForm.defaultProps = {
  sourceLocationIsRequired: true,
  destinationLocationIsRequired: true,
  geographicZoneIsRequired: true,
  initialValues: {
    departureDayString: null,
  },
};

export default reduxForm({
  form: 'LiveItinerarySalesControlSearchForm',
})(LiveItinerarySalesControlSearchForm);
