import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import {
  clearPreventiveMaintenanceReport,
  postPreventiveMaintenanceReport,
} from '../../../../actions';
import { PREVENTIVE_MAINTENANCE_REPORT_PATH } from '../../../../config/paths';
import PreventiveMaintenanceReportSearchForm from '../preventive-maintenance-report/PreventiveMaintenanceReportSearchForm';
import {
  DATA_NOT_REGISTERED_ERROR_MESSAGE,
  EXECUTION_REGISTER_REQUIRED_FIELDS_ERROR_MESSAGE,
} from '../../../../config/messages';
import { ACTIVITY_STATUS } from '../../../../config/constants';
import { postExecutionRegistration } from '../../../../actions/mechanical-maintenance/ExecutionRegistration';
import { tzNormalizeDate } from '../../../../utils/date';
import PreventiveExecutionRegisterTable from './PreventiveExecutionRegisterTable';
import { INT_DATE_FORMAT } from '../../../../config/locale';

export const PreventiveExecutionRegister = ({
  breadcrumbs,
  dispatchPostPreventiveMaintenanceReport,
  dispatchClearPreventiveMaintenanceReport,
  dispatchPostExecutionRegistration,
  loading,
}) => {
  const [searchValues, setSearchValues] = useState({
    registeredBusIdList: [],
    homeBaseLocationId: null,
    daily: false,
    dateTo: null,
    simulationDays: 0,
    simulationKilometers: 0,
    size: 10000,
  });
  const [selectedOrganizationChart, setSelectedOrganizationChart] =
    useState(null);

  const searchActivities = (searchFormValues) => {
    dispatchClearPreventiveMaintenanceReport();
    dispatchPostPreventiveMaintenanceReport(searchFormValues);
  };

  const onSubmitToSearch = (formValues) => {
    const values = {
      registeredBusIdList:
        formValues.registeredBusIdList &&
        formValues.registeredBusIdList.length > 0
          ? formValues.registeredBusIdList.map((bus) => bus.value)
          : null,
      homeBaseLocationId: formValues.homeBaseLocationId
        ? formValues.homeBaseLocationId.value
        : null,
      daily: true,
      dateTo: tzNormalizeDate(),
      simulationDays: 0,
      simulationKilometers: 0,
      size: 10000,
    };
    setSearchValues(values);
    searchActivities(values);
    setSelectedOrganizationChart([formValues.companyAreaId.name]);
  };

  const validateValues = (formValues) => {
    let valid = true;
    formValues.forEach(
      ({
        status,
        extensionFactor,
        startDate,
        endDate,
        workingHour,
        mechanics,
      }) => {
        if (
          (status !== ACTIVITY_STATUS.PENDING.value &&
            !startDate &&
            !endDate &&
            !workingHour &&
            !mechanics) ||
          (status === ACTIVITY_STATUS.EXTENDED.value && !extensionFactor)
        )
          valid = false;
      },
    );
    return valid;
  };

  const onClickRegisterExecution = async (formValues) => {
    const valid = validateValues(formValues);
    if (!valid)
      toastr.error('Error', EXECUTION_REGISTER_REQUIRED_FIELDS_ERROR_MESSAGE);
    else {
      const filteredFormValues = formValues;

      const registerFormValues = filteredFormValues.map(
        ({
          status,
          comment,
          mechanics,
          startDate,
          endDate,
          workingHour,
          extensionFactor,
          busHomeBaseId,
          activityBusId,
        }) => {
          const employeeList = [];
          if (mechanics)
            mechanics.forEach((mechanic) => {
              employeeList.push({ employeeId: mechanic.value });
            });

          return {
            advanced: false,
            status,
            comment,
            employeeList,
            startDate: startDate ? tzNormalizeDate({ date: startDate }) : null,
            endDate: endDate
              ? tzNormalizeDate({ date: endDate })
              : searchValues.dateTo,
            workingHour: workingHour || null,
            extensionFactor,
            baseLocationId: busHomeBaseId,
            activityBusId,
          };
        },
      );

      const response = await dispatchPostExecutionRegistration(
        registerFormValues,
      );
      if (!response) toastr.error('Error', DATA_NOT_REGISTERED_ERROR_MESSAGE);
      else searchActivities(searchValues);
    }
  };

  const subtitle = (
    <span>
      Busca las actividades, selecciona el estado, los mecánicos, las fechas y
      horas de ejecución y registrarlos haciendo click en el botón{' '}
      <strong>Registrar Ejecución</strong>
    </span>
  );

  const dateTo = tzNormalizeDate({ format: INT_DATE_FORMAT });

  const content = (
    <>
      <PreventiveMaintenanceReportSearchForm
        onSubmit={onSubmitToSearch}
        initialValues={{
          dateTo,
        }}
        showBase
        registeredBusIsMulti
        showOrganizationChart
        searchButtonDisabled={loading}
        disableDateTo
      />
      <PreventiveExecutionRegisterTable
        onHandleRegisterExecution={onClickRegisterExecution}
        selectedOrganizationChart={selectedOrganizationChart}
      />
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Registro de la Ejecución Preventivo"
      subtitle={subtitle}
      content={content}
    />
  );
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Registro de la Ejecución Preventivo',
      href: PREVENTIVE_MAINTENANCE_REPORT_PATH,
    },
  ],
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

PreventiveExecutionRegister.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPreventiveMaintenanceReport: PropTypes.func.isRequired,
  dispatchClearPreventiveMaintenanceReport: PropTypes.func.isRequired,
  dispatchPostExecutionRegistration: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchPostPreventiveMaintenanceReport: postPreventiveMaintenanceReport,
  dispatchClearPreventiveMaintenanceReport: clearPreventiveMaintenanceReport,
  dispatchPostExecutionRegistration: postExecutionRegistration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreventiveExecutionRegister);
