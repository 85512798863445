import Immutable from 'immutable';
import {
  GET_SERVICE_MERGE_ID,
  CLEAR_SERVICE_MERGE_ID,
  FLAG_SERVICE_MERGE_ACTIVITY,
  FLAG_GETTING_SOURCE_ITINERARY_SEATMAP,
  GET_SOURCE_ITINERARY_SEATMAP,
  CLEAR_SOURCE_ITINERARY_SEATMAP,
  FLAG_GETTING_DESTINATION_ITINERARY_SEATMAP,
  GET_DESTINATION_ITINERARY_SEATMAP,
  CLEAR_DESTINATION_ITINERARY_SEATMAP,
  GET_SOURCE_ITINERARY_PASSENGER_LIST,
  CLEAR_SOURCE_ITINERARY_PASSENGER_LIST,
  GET_DESTINATION_ITINERARY_PASSENGER_LIST,
  CLEAR_DESTINATION_ITINERARY_PASSENGER_LIST,
} from '../../../actions/types/itinerary/ServiceMerge';

const INITIAL_STATE = Immutable.Map({
  current: Immutable.Map({
    id: null,
    activity: Immutable.List(),
  }),
  sourceItinerary: Immutable.Map({
    seatMap: Immutable.Map(),
    passengerList: Immutable.Set(),
    loading: false,
  }),
  destinationItinerary: Immutable.Map({
    seatMap: Immutable.Map(),
    passengerList: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_SERVICE_MERGE_ID:
      return state.setIn(['current', 'id'], payload);
    case CLEAR_SERVICE_MERGE_ID:
      return state.setIn(
        ['current', 'id'],
        INITIAL_STATE.getIn(['current', 'id']),
      );
    case FLAG_SERVICE_MERGE_ACTIVITY:
      // raising flag
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      // lowering flag
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case FLAG_GETTING_SOURCE_ITINERARY_SEATMAP:
      return state.setIn(['sourceItinerary', 'loading'], payload);
    case GET_SOURCE_ITINERARY_SEATMAP:
      return state.setIn(
        ['sourceItinerary', 'seatMap'],
        Immutable.Map(payload),
      );
    case CLEAR_SOURCE_ITINERARY_SEATMAP:
      return state.setIn(
        ['sourceItinerary', 'seatMap'],
        INITIAL_STATE.getIn(['sourceItinerary', 'seatMap']),
      );
    case GET_SOURCE_ITINERARY_PASSENGER_LIST:
      return state.setIn(
        ['sourceItinerary', 'passengerList'],
        Immutable.Set(payload),
      );
    case CLEAR_SOURCE_ITINERARY_PASSENGER_LIST:
      return state.setIn(
        ['sourceItinerary', 'passengerList'],
        INITIAL_STATE.getIn(['sourceItinerary', 'passengerList']),
      );
    case FLAG_GETTING_DESTINATION_ITINERARY_SEATMAP:
      return state.setIn(['destinationItinerary', 'loading'], payload);
    case GET_DESTINATION_ITINERARY_SEATMAP:
      return state.setIn(
        ['destinationItinerary', 'seatMap'],
        Immutable.Map(payload),
      );
    case CLEAR_DESTINATION_ITINERARY_SEATMAP:
      return state.setIn(
        ['destinationItinerary', 'seatMap'],
        INITIAL_STATE.getIn(['destinationItinerary', 'seatMap']),
      );
    case GET_DESTINATION_ITINERARY_PASSENGER_LIST:
      return state.setIn(
        ['destinationItinerary', 'passengerList'],
        Immutable.Set(payload),
      );
    case CLEAR_DESTINATION_ITINERARY_PASSENGER_LIST:
      return state.setIn(
        ['destinationItinerary', 'passengerList'],
        INITIAL_STATE.getIn(['destinationItinerary', 'passengerList']),
      );
    default:
      return state;
  }
};
