import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  AGENCY_PATH,
  HUMAN_RESOURCE_UNIT_PATH,
  NEW_AGENCY_PATH,
  PRINT_STATION_PATH,
  NEW_PRINT_STATION_PATH,
  WORKSTATION_PATH,
  NEW_WORKSTATION_PATH,
  AGENCY_GROUP_PATH,
  NEW_AGENCY_GROUP_PATH,
} from '../../../config/paths';
import HumanResourcesHome from './HumanResourcesHome';
import Agencies from './agency/Agencies';
import NewAgency from './agency/NewAgency';
import Agency from './agency/Agency';
import EditAgency from './agency/EditAgency';
import PrintStations from './print-station/PrintStations';
import NewPrintStation from './print-station/NewPrintStation';
import PrintStation from './print-station/PrintStation';
import EditPrintStation from './print-station/EditPrintStation';
import Workstations from './workstation/Workstations';
import NewWorkstation from './workstation/NewWorkstation';
import Workstation from './workstation/Workstation';
import EditWorkstation from './workstation/EditWorkstation';
import AgencyGroups from './agency-group/AgencyGroups';
import NewAgencyGroup from './agency-group/NewAgencyGroup';
import AgencyGroup from './agency-group/AgencyGroup';
import EditAgencyGroup from './agency-group/EditAgencyGroup';
import AddCreditLine from './agency/AddCreditLine';

const HumanResourcesUnit = () => (
  <Switch>
    <Route
      exact
      path={HUMAN_RESOURCE_UNIT_PATH}
      component={HumanResourcesHome}
    />
    <Route exact path={AGENCY_PATH} component={Agencies} />
    <Route exact path={NEW_AGENCY_PATH} component={NewAgency} />
    <Route exact path={`${AGENCY_PATH}/:id`} component={Agency} />
    <Route exact path={`${AGENCY_PATH}/:id/edit`} component={EditAgency} />
    <Route
      exact
      path={`${AGENCY_PATH}/:id/add-credit-line`}
      component={AddCreditLine}
    />
    <Route exact path={WORKSTATION_PATH} component={Workstations} />
    <Route exact path={NEW_WORKSTATION_PATH} component={NewWorkstation} />
    <Route exact path={`${WORKSTATION_PATH}/:id`} component={Workstation} />
    <Route
      exact
      path={`${WORKSTATION_PATH}/:id/edit`}
      component={EditWorkstation}
    />
    <Route exact path={PRINT_STATION_PATH} component={PrintStations} />
    <Route exact path={NEW_PRINT_STATION_PATH} component={NewPrintStation} />
    <Route exact path={`${PRINT_STATION_PATH}/:id`} component={PrintStation} />
    <Route
      exact
      path={`${PRINT_STATION_PATH}/:id/edit`}
      component={EditPrintStation}
    />
    <Route exact path={AGENCY_GROUP_PATH} component={AgencyGroups} />
    <Route exact path={NEW_AGENCY_GROUP_PATH} component={NewAgencyGroup} />
    <Route exact path={`${AGENCY_GROUP_PATH}/:id`} component={AgencyGroup} />
    <Route
      exact
      path={`${AGENCY_GROUP_PATH}/:id/edit`}
      component={EditAgencyGroup}
    />
  </Switch>
);

export default HumanResourcesUnit;
