import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNumber } from '../../../utils/number';
import { DEFAULT_QUERY } from '../../../config/queries';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearBusinesses, getBusinesses } from '../../../actions';
import SelectFilter from './SelectFilter';

const BusinessFilter = ({
  businesses,
  onChangeFilter,
  isMulti,
  loading,
  isClearable,
  placeholder,
  dispatchClearBusinesses,
  dispatchGetBusinesses,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearBusinesses();
    },
    [],
  );

  const handleInputChange = (inputValue) => {
    if (isNumber(inputValue.trim()) && inputValue.trim().length === 11)
      dispatchGetBusinesses({
        ...DEFAULT_QUERY,
        query: [`businessTaxId:${inputValue}`],
      });
  };

  const userFilterOption = (business) => business;

  return (
    <SelectFilter
      placeholder={placeholder}
      isMulti={isMulti}
      onChangeFilter={onChangeFilter}
      onInputChange={handleInputChange}
      options={businesses}
      isLoading={loading}
      filterOption={userFilterOption}
      isClearable={isClearable}
    />
  );
};

const mapStateToProps = ({ UserUnit }) => ({
  businesses: UserUnit.Business.getIn(['all', 'content', 'content']).map(
    ({
      id,
      businessTaxId,
      name,
      email,
      primaryContact,
      phone,
      fax,
      addressSummary,
      addressId,
      address,
    }) => ({
      value: id,
      label: `${businessTaxId} - ${name}`,
      businessTaxId,
      name,
      email,
      primaryContact,
      phone,
      fax,
      addressSummary,
      addressId,
      address,
    }),
  ),
  loading: UserUnit.Customer.getIn(['all', 'loading']),
});

BusinessFilter.propTypes = {
  dispatchGetBusinesses: PropTypes.func.isRequired,
  dispatchClearBusinesses: PropTypes.func.isRequired,
  businesses: optionsPropTypes,
  placeholder: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  isClearable: PropTypes.bool,
};

BusinessFilter.defaultProps = {
  businesses: [],
  isMulti: false,
  loading: false,
  isClearable: false,
  placeholder: 'Ingrese RUC',
};

const mapDispatchToprops = {
  dispatchGetBusinesses: getBusinesses,
  dispatchClearBusinesses: clearBusinesses,
};

export default connect(mapStateToProps, mapDispatchToprops)(BusinessFilter);
