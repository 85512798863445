import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CREDIT_NOTE_PATH } from '../../../../config/paths';
import { postCreditNote } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CreditNoteForm from './CreditNoteForm';
import Content from '../../../layout/Content';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

export class NewCreditNote extends Component {
  onSubmit = (formValues) =>
    this.props.dispatchPostCreditNote({
      customerId: formValues.customerId.value,
      reference: formValues.reference,
      description: formValues.description,
      amount: formValues.amount,
      isValid: formValues.isValid,
      expirationDate: tzNormalizeDate({
        date: formValues.expirationDate,
        format: TIMESTAMP_FORMAT,
      }),
    });

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Nota de Crédito"
        subtitle="Crear nueva nota de crédito"
        content={
          <CreditNoteForm
            onSubmit={this.onSubmit}
            initialValues={{
              isValid: true,
            }}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Notas de Crédito', href: CREDIT_NOTE_PATH },
    { text: 'Nuevo', href: '' },
  ],
});

const mapDispatchToProps = { dispatchPostCreditNote: postCreditNote };

NewCreditNote.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchPostCreditNote: PropTypes.func.isRequired,
};

NewCreditNote.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCreditNote);
