import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DRIVER_PATH } from '../../../../config/paths';
import { getDriverTrips, clearDriverTrips } from '../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { Table } from '../../../common/Table';
import Content from '../../../layout/Content';
import { DRIVER_TRIPS_COLUMNS } from '../../../../config/columns';
import { DEFAULT_QUERY } from '../../../../config/queries';
import ReturnButton from '../../../common/button/ReturnButton';

class DriverTrips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFormValues: { ...DEFAULT_QUERY },
    };
  }

  componentWillUnmount() {
    this.props.dispatchClearDriverTrips();
  }

  onFetchData = (searchFormValues) => {
    const {
      dispatchGetDriverTrips,
      match: {
        params: { id },
      },
    } = this.props;

    dispatchGetDriverTrips({ id }, searchFormValues);
  };

  render() {
    const {
      breadcrumbs,
      trips,
      loading,
      match: {
        params: { id: driverId },
      },
    } = this.props;

    const data = trips.get('content') || [];
    const columns = DRIVER_TRIPS_COLUMNS;
    const pages = trips.get('totalPages') || null;
    const defaultPageSize = trips.get('size') || 5;

    const documentResource = (
      <div className="mb-3">
        <Table
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          fetchData={this.onFetchData}
          params={this.state.searchFormValues}
          highlightSelectedRow
        />
      </div>
    );

    const content = (
      <Fragment>
        {documentResource}
        <ReturnButton path={`${DRIVER_PATH}/${driverId}`} />
      </Fragment>
    );

    return (
      <Content
        title="Viajes Realizados"
        subtitle="Lista de viajes realizados por conductor"
        content={content}
        breadcrumbs={breadcrumbs}
      />
    );
  }
}

DriverTrips.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDriverTrips: PropTypes.func.isRequired,
  dispatchClearDriverTrips: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  trips: PropTypes.instanceOf(Immutable.Map).isRequired,
};

DriverTrips.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Conductores',
      href: DRIVER_PATH,
    },
    {
      text: 'Ver',
      href: `${DRIVER_PATH}/${id}`,
    },
    {
      text: 'Viajes Realizados',
      href: '',
    },
  ],
  trips: TrafficUnit.Driver.getIn(['current', 'trips']),
  loading: !TrafficUnit.Driver.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDriverTrips: getDriverTrips,
  dispatchClearDriverTrips: clearDriverTrips,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverTrips);
