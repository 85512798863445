import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVoucherTypes, clearVoucherTypes } from '../../../actions/index';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';

class VoucherTypeFilter extends Component {
  componentDidMount() {
    this.props.dispatchGetVoucherTypes();
  }

  componentWillUnmount() {
    const { cleanupOnUnmount, dispatchClearVoucherTypes } = this.props;
    if (cleanupOnUnmount) dispatchClearVoucherTypes();
  }

  render() {
    const { options, onChangeFilter, loading } = this.props;
    return (
      <SelectFilter
        isMulti
        onChangeFilter={onChangeFilter}
        options={options}
        isLoading={loading}
      />
    );
  }
}

VoucherTypeFilter.propTypes = {
  dispatchGetVoucherTypes: PropTypes.func.isRequired,
  dispatchClearVoucherTypes: PropTypes.func.isRequired,
  options: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  cleanupOnUnmount: PropTypes.bool,
};

VoucherTypeFilter.defaultProps = {
  options: [],
  cleanupOnUnmount: true,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  options: AccountingUnit.VoucherType.getIn(['all', 'content', 'content']).map(
    (voucherType) => ({
      value: voucherType.id,
      label: voucherType.name,
      voucherCode: voucherType.voucherCode,
    }),
  ),
  loading: AccountingUnit.VoucherType.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetVoucherTypes: getVoucherTypes,
  dispatchClearVoucherTypes: clearVoucherTypes,
};

export default connect(mapStateToProps, mapDispatchToprops)(VoucherTypeFilter);
