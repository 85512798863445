import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ServiceMergeForm from './ServiceMergeForm';
import { postServiceMerge } from '../../../../actions';
import Content from '../../../layout/Content';
import { getSubstring } from '../../../../utils/string';

export class ServiceMerge extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostServiceMerge } = this.props;
    dispatchPostServiceMerge({
      forced: formValues.forced,
      sourceItineraryId: getSubstring(formValues.sourceItineraryId),
      destinationItineraryId: getSubstring(formValues.destinationItineraryId),
      comment: formValues.comment,
    });
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Fusión Servicios - Trasbordo"
        subtitle="Fusión servicios - trasbordo"
        content={<ServiceMergeForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

ServiceMerge.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostServiceMerge: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Fusión Servicios - Trasbordo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostServiceMerge: postServiceMerge,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMerge);
