/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const CheckBillingsStateButton = (props) => {
  const { handleChecking } = props;

  return (
    <Button color="info" className="border" onClick={handleChecking}>
      <i className="fa fa-refresh" /> Revisar Estado de Facturación
    </Button>
  );
};

CheckBillingsStateButton.propTypes = {
  handleChecking: PropTypes.func.isRequired,
};

export default CheckBillingsStateButton;
