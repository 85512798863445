import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Content from '../../../layout/Content';
import { PREVENTIVE_MAINTENANCE_REPORT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postPreventiveMaintenanceReport } from '../../../../actions';
import PreventiveMaintenanceReportSearchForm from './PreventiveMaintenanceReportSearchForm';
import printPreventiveMaintenanceReport from '../../../../utils/printers/PreventiveMaintenanceReport';
import { orderBy } from '../../../../utils/array';
import {
  LOADING_REPORT_INFO_MESSAGE,
  THERE_ARE_NOT_DATA_TO_SHOW,
} from '../../../../config/messages';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_FORMAT, INT_DATE_FORMAT } from '../../../../config/locale';
import DownloadButton from '../../../common/button/DownloadButton';

export const formatMaintenanceReportData = (content) =>
  content.map((row) => ({
    ...row,
    companyActivityId: +row.companyActivityId,
    companyBusId: +row.companyBusId,
    lastExecutionDate: row.lastExecutionDate
      ? tzNormalizeDate({
          date: row.lastExecutionDate,
          format: DATE_FORMAT,
        })
      : null,
    executionDate: row.executionDate
      ? tzNormalizeDate({
          date: row.executionDate,
          format: DATE_FORMAT,
        })
      : null,
  }));

const generateDataToPrint = (content, areaList) => {
  const sortedData = orderBy(content, ['areaName', 'companyActivityId']);

  // Getting companyBusIds not repeated
  const buses = sortedData.reduce((previousValue, currentValue) => {
    if (!previousValue.includes(currentValue.companyBusId))
      previousValue.push(currentValue.companyBusId);

    return previousValue;
  }, []);

  // Generate new area array with activities by bus
  const newAreaList = [];

  areaList.forEach((area) => {
    const newBusList = [];
    buses.forEach((bus) => {
      const activities = sortedData.filter(
        ({ companyBusId, companyAreaId }) =>
          companyBusId === bus && companyAreaId === area.value,
      );

      const busFound = newBusList.find(
        ({ companyBusId }) => companyBusId === bus,
      );
      if (!busFound && activities.length > 0)
        newBusList.push({ companyBusId: bus, activities });
    });

    // Sorting data by company bus id
    const sortedBusList = orderBy(newBusList, 'companyBusId');
    if (newBusList.length > 0) {
      newAreaList.push({
        area: area.name,
        id: area.value,
        busList: sortedBusList,
      });
    }
  });

  // Sorting data by area
  const sortedNewAreaList = orderBy(newAreaList, ['area']);

  return sortedNewAreaList;
};

const PreventiveMaintenanceReport = ({
  breadcrumbs,
  dispatchPostPreventiveMaintenanceReport,
  loading,
}) => {
  const [data, setData] = useState(null);

  const onSubmit = async (formValues) => {
    setData(null);

    const searchValues = {
      registeredBusIdList:
        formValues.registeredBusIdList &&
        formValues.registeredBusIdList.length > 0
          ? formValues.registeredBusIdList.map((bus) => bus.value)
          : null,
      homeBaseLocationId: formValues.homeBaseLocationId
        ? formValues.homeBaseLocationId.value
        : null,
      daily: true,
      dateTo: tzNormalizeDate(),
      simulationDays: 0,
      simulationKilometers: 0,
      force: formValues.force,
      size: 10000,
    };

    const response = await dispatchPostPreventiveMaintenanceReport({
      ...searchValues,
      dispatchData: false,
    });

    if (response && response.content.length === 0 && formValues.force)
      toastr.info('Información', LOADING_REPORT_INFO_MESSAGE);
    else if (response && response.content) {
      const dataFormatted = formatMaintenanceReportData(response.content);
      const dataToPrint = generateDataToPrint(
        dataFormatted,
        formValues.companyAreaId,
      );
      if (dataToPrint && dataToPrint.length > 0) {
        const dataFiltered = [];
        dataFormatted.forEach((rowFormatted) => {
          const rowFound = formValues.companyAreaId.some(
            (area) => area.value === rowFormatted.companyAreaId,
          );
          if (rowFound) {
            dataFiltered.push(rowFormatted);
          }
        });
        setData(dataFiltered);
        printPreventiveMaintenanceReport(dataToPrint, formValues.dateTo);
      } else toastr.error('Error', THERE_ARE_NOT_DATA_TO_SHOW);
    }
  };

  const now = tzNormalizeDate({ format: INT_DATE_FORMAT });

  const downloadButton = data && (
    <div className="mt-3 text-right">
      <DownloadButton data={data} csvName={`RDMP al ${now}.csv`} />
    </div>
  );
  const content = (
    <>
      <PreventiveMaintenanceReportSearchForm
        onSubmit={onSubmit}
        initialValues={{
          dateTo: now,
        }}
        showBase
        registeredBusIsMulti
        showOrganizationChart
        isForDownload
        searchButtonDisabled={loading}
        disableDateTo
        companyAreaIsMulti
      />
      {downloadButton}
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Reporte Diario de Mantenimiento Preventivo"
      subtitle="Puedes generar el reporte seleccionando varios buses y varios organigramas."
      content={content}
    />
  );
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reporte Diario de Mantenimiento Preventivo',
      href: PREVENTIVE_MAINTENANCE_REPORT_PATH,
    },
  ],
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

PreventiveMaintenanceReport.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPreventiveMaintenanceReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchPostPreventiveMaintenanceReport: postPreventiveMaintenanceReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreventiveMaintenanceReport);
