import React from 'react';
import PropTypes from 'prop-types';
import { POSTPAID_BOOKING_REJECTED } from '../../../../config/constants';

const RowExpander = ({ isExpanded, status }) => {
  if (status === POSTPAID_BOOKING_REJECTED) {
    return null;
  }

  if (isExpanded) {
    return <i className="fa fa-caret-up" />;
  }

  return <i className="fa fa-caret-down" />;
};

RowExpander.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
};

export default RowExpander;
