import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// TODO test
const LinkToModuleResource = ({ href, text, colorIcon, isExternalPath }) => {
  let link = href ? (
    <Link to={href} target="_blank" rel="noopener noreferrer">
      <i className={`fa fa-external-link ${colorIcon}`} />
    </Link>
  ) : null;

  if (href && isExternalPath)
    link = (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <i className={`fa fa-external-link ${colorIcon}`} />
      </a>
    );

  return (
    <span>
      {text} {link}
    </span>
  );
};

LinkToModuleResource.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  href: PropTypes.string,
  colorIcon: PropTypes.string,
  isExternalPath: PropTypes.bool,
};

LinkToModuleResource.defaultProps = {
  href: null,
  colorIcon: null,
  isExternalPath: false,
};

export default LinkToModuleResource;
