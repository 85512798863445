import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BUS_FUEL_GROUP_PATH } from '../../../../config/paths';
import BusFuelGroupForm from './BusFuelGroupForm';
import { postBusFuelGroup } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';

export class NewBusFuelConsumption extends Component {
  onSubmit = (formValues) => {
    const registeredBusList = [];
    formValues.registeredBus.split(',').forEach((value) => {
      const bus = { id: value };
      registeredBusList.push(bus);
    });
    const postBusFuelGroupFormValues = {
      name: formValues.name,
      description: formValues.description,
      performance: formValues.performance,
      registeredBusList,
    };
    this.props.dispatchPostBusFuelGroup(postBusFuelGroupFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Agrupación de Buses por Consumo de Combustible"
        subtitle="Crear nueva agrupación de buses por consumo de combustible"
        content={
          <BusFuelGroupForm
            onSubmit={this.onSubmit}
            onTreeSelect={this.onTreeSelect}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Bus por consumo de combustible',
      href: BUS_FUEL_GROUP_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostBusFuelGroup: postBusFuelGroup,
};

NewBusFuelConsumption.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostBusFuelGroup: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewBusFuelConsumption);
