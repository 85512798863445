import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { ACTIVITY_PATH } from '../../../../config/paths';
import ActivityDeleteButton from './ActivityDeleteButton';

const ActivityToolbar = ({ activityId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${ACTIVITY_PATH}/${activityId}/edit`} />{' '}
      <ActivityDeleteButton activityId={activityId} />
    </div>
  </ButtonToolbar>
);

ActivityToolbar.propTypes = {
  activityId: PropTypes.number.isRequired,
};

export default ActivityToolbar;
