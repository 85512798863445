import Immutable from 'immutable';
import {
  CLEAR_MANIFEST_ITEMS,
  CLEAR_MANIFEST_ITEMS_BY_PARCEL,
  DELETE_MANIFEST_ITEMS,
  FLAG_GETTING_MANIFEST_ITEMS,
  FLAG_MANIFEST_ITEMS_ACTIVITY,
  GET_MANIFEST_ITEMS,
  GET_MANIFEST_ITEMS_BY_PARCEL,
  UPDATE_MANIFEST_ITEMS,
  UPDATE_MANIFEST_ITEMS_BY_PARCEL,
} from '../../../actions/types/cargo';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.List(),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.List(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_MANIFEST_ITEMS:
      return state.setIn(['all', 'loading'], payload);
    case GET_MANIFEST_ITEMS:
      return state.setIn(['all', 'content'], Immutable.List(payload));
    case CLEAR_MANIFEST_ITEMS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case UPDATE_MANIFEST_ITEMS:
      return state.updateIn(['all', 'content'], (items) =>
        items.map((item) => {
          const updatedItem = payload.find(
            (currentUpdatedItem) =>
              currentUpdatedItem.correlation === item.correlation,
          );
          return updatedItem || item;
        }),
      );
    case DELETE_MANIFEST_ITEMS:
      return state.updateIn(['all', 'content'], (items) =>
        items.filter(
          (item) =>
            !payload.some(
              (deletedItem) => deletedItem.correlation === item.correlation,
            ),
        ),
      );
    case FLAG_MANIFEST_ITEMS_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_MANIFEST_ITEMS_BY_PARCEL:
      return state.setIn(['current', 'content'], Immutable.List(payload));
    case CLEAR_MANIFEST_ITEMS_BY_PARCEL:
      return state.setIn(
        ['current', 'content'],
        INITIAL_STATE.getIn(['current', 'content']),
      );
    case UPDATE_MANIFEST_ITEMS_BY_PARCEL:
      return state.updateIn(['current', 'content'], (items) =>
        items.map((item) => {
          const updatedItem = payload.find(
            (currentUpdatedItem) =>
              currentUpdatedItem.correlation === item.correlation,
          );
          return updatedItem || item;
        }),
      );
    default:
      return state;
  }
};
