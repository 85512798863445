import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { businessBasicInformationPropTypes } from '../proptypes/BusinessPropTypes';

const BusinessBasicInformationResource = ({ businessTaxId, name }) => (
  <>
    <ResourceProperty label="RUC:">{businessTaxId}</ResourceProperty>
    <ResourceProperty label="Razón Social:">{name}</ResourceProperty>
  </>
);

BusinessBasicInformationResource.propTypes = businessBasicInformationPropTypes;

export default BusinessBasicInformationResource;
