import React from 'react';
import PropTypes from 'prop-types';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import './dropdownTreeSelect.css';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

const TreeSelect = ({
  data,
  onInputChange,
  clearSearchOnChange,
  keepTreeOnSearch,
  keepChildrenOnSearch,
  keepOpenOnSelect,
  showPartiallySelected,
  showDropdown, // null, initial, always
  texts: { placeholder, noMatches },
}) => (
  <div>
    <DropdownTreeSelect
      data={data}
      clearSearchOnChange={clearSearchOnChange}
      keepTreeOnSearch={keepTreeOnSearch}
      keepChildrenOnSearch={keepChildrenOnSearch}
      keepOpenOnSelect={keepOpenOnSelect}
      onChange={onInputChange}
      showPartiallySelected={showPartiallySelected}
      showDropdown={showDropdown}
      texts={{
        placeholder,
        noMatches,
      }}
    />
  </div>
);

TreeSelect.propTypes = {
  onInputChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  ).isRequired,
  clearSearchOnChange: PropTypes.bool,
  keepTreeOnSearch: PropTypes.bool,
  keepChildrenOnSearch: PropTypes.bool,
  keepOpenOnSelect: PropTypes.bool,
  showPartiallySelected: PropTypes.bool,
  showDropdown: PropTypes.string,
  texts: PropTypes.shape({
    placeholder: PropTypes.string,
    noMatches: PropTypes.string,
  }),
};

TreeSelect.defaultProps = {
  onInputChange: () => {},
  clearSearchOnChange: true,
  keepTreeOnSearch: true,
  keepChildrenOnSearch: true,
  keepOpenOnSelect: true,
  showPartiallySelected: false,
  showDropdown: '',
  texts: {
    placeholder: DEFAULT_SELECT_PLACEHOLDER,
    noMatches: 'No hay resultados.',
  },
};

export default TreeSelect;
