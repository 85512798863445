import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_VOUCHER_TYPES,
  SHOW_ERROR_GETTING_VOUCHER_TYPES,
  CLEAR_ERROR_GETTING_VOUCHER_TYPES,
  GET_VOUCHER_TYPES,
  CLEAR_VOUCHER_TYPES,
} from '../types';
import { VOUCHER_TYPE_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingVoucherTypes = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_VOUCHER_TYPES,
    payload: flag,
  });

const showErrorGettingVoucherTypes = error => dispatch =>
  dispatch({
    type: SHOW_ERROR_GETTING_VOUCHER_TYPES,
    payload: error,
  });

const clearErrorGettingVoucherTypes = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR_GETTING_VOUCHER_TYPES,
  });

const getVoucherTypes = async tableFilters => async dispatch => {
  try {
    dispatch(clearErrorGettingVoucherTypes());
    dispatch(flagGettingVoucherTypes(true));
    const query = tableFilters;
    const url = `${VOUCHER_TYPE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const voucherTypes = await response.json();
    dispatch({
      type: GET_VOUCHER_TYPES,
      payload: voucherTypes,
    });
  } catch (error) {
    toastr.error('Error',error.message);
    dispatch(showErrorGettingVoucherTypes(error));
  } finally {
    dispatch(flagGettingVoucherTypes(false));
  }
};

const clearVoucherTypes = () => dispatch =>
  dispatch({
    type: CLEAR_VOUCHER_TYPES,
  });

export {
  flagGettingVoucherTypes,
  showErrorGettingVoucherTypes,
  clearErrorGettingVoucherTypes,
  getVoucherTypes,
  clearVoucherTypes,
};
