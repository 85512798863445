import React from 'react';
import {
  activityMaterialInformationDefaultProps,
  activityMaterialInformationPropTypes,
} from '../proptypes/ActivityPropTypes';
import Table from '../../../../common/Table';
import { ACTIVITY_TASK_COLUMNS } from '../../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import Alert from '../../../../common/informative/Alert';

const ActivityTaskInformationResource = ({ activityTaskList }) => {
  if (activityTaskList.length === 0)
    return (
      <Alert type="warning" message="Actividad no tiene tareas asignadas." />
    );

  return (
    <Table
      columns={ACTIVITY_TASK_COLUMNS}
      data={activityTaskList}
      filterable
      manual={false}
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
  );
};

ActivityTaskInformationResource.propTypes =
  activityMaterialInformationPropTypes;

ActivityTaskInformationResource.defaultProps =
  activityMaterialInformationDefaultProps;

export default ActivityTaskInformationResource;
