import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Button } from 'reactstrap';
import { printTicket } from '../../../../../utils/printers/Ticket';
import { printReservation } from '../../../../../utils/printers/ReservationVoucher';

class PrintTicketButton extends Component {
  printTicket = () => {
    const { ticket, salesSessionUserId } = this.props;
    if (salesSessionUserId) printReservation(ticket.toJS());
    else printTicket(ticket.toJS());
  };

  render() {
    return (
      <Button color="light" className="border" onClick={this.printTicket}>
        <i className="fa fa-print" /> Imprimir
      </Button>
    );
  }
}

PrintTicketButton.propTypes = {
  ticket: PropTypes.instanceOf(Map).isRequired,
  salesSessionUserId: PropTypes.number,
};

PrintTicketButton.defaultProps = {
  salesSessionUserId: null,
};

export default PrintTicketButton;
