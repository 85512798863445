import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { privateServiceExecutivePropTypes } from '../proptypes/PrivateServicePropTypes';

const ExecutiveInformationResource = ({
  executiveName,
  executivePosition,
  executivePhone,
  executiveEmail,
}) => (
  <Fragment>
    <h3>Datos del Ejecutivo</h3>
    <ResourceProperty label="Nombres y Apellidos:">
      {executiveName || '-'}
    </ResourceProperty>
    <ResourceProperty label="Cargo:">
      {executivePosition || '-'}
    </ResourceProperty>
    <ResourceProperty label="Celular:">
      {executivePhone || '-'}
    </ResourceProperty>
    <ResourceProperty label="Email:">{executiveEmail || '-'}</ResourceProperty>
  </Fragment>
);

ExecutiveInformationResource.propTypes = privateServiceExecutivePropTypes;

export default ExecutiveInformationResource;
