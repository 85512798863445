import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BAGGAGE_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postBaggage } from '../../../../actions';
import BaggageForm from './BaggageForm';
import Content from '../../../layout/Content';

export class NewBaggage extends Component {
  onSubmit = (formValues) => this.props.dispatchPostBaggage(formValues);

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Equipaje"
        subtitle="Crear un nuevo equipaje"
        content={<BaggageForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ BaggageUnit }) => ({
  breadcrumbs: [
    ...BaggageUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Equipajes y Excesos',
      href: BAGGAGE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostBaggage: postBaggage,
};

NewBaggage.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostBaggage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBaggage);
