import Immutable from 'immutable';
import {
  HOME_PATH,
  ITINERARY_REPORTS_PATH,
  NOT_BOARDED_TICKET_REPORTS_PATH,
  OCCUPANCY_REPORTS_PATH,
  TICKET_REPORTS_PATH,
  ACCOUNTING_REPORTS_PATH,
  DISCOUNT_CODE_REPORTS_PATH,
  CALL_CENTER_REPORTS_PATH,
  EXPENSE_REPORTS_PATH,
  PRICING_PROFILE_REPORTS_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'daily-sales',
      title: 'Ventas Diarias',
      path: '/reports/daily-sales',
      icon: 'line-chart',
      disabled: true,
    },
    {
      key: 'itineraries',
      title: 'Itinerarios',
      path: ITINERARY_REPORTS_PATH,
      icon: 'pie-chart',
      disabled: false,
    },
    {
      key: 'ticket',
      title: 'Boletos',
      path: TICKET_REPORTS_PATH,
      icon: 'sticky-note',
      disabled: false,
    },
    {
      key: 'not-boarded-ticket-report',
      title: 'Boletos No Abordados',
      path: NOT_BOARDED_TICKET_REPORTS_PATH,
      icon: 'sticky-note-o',
      disabled: false,
    },
    {
      key: 'occupancy',
      title: 'Ocupabilidad',
      path: OCCUPANCY_REPORTS_PATH,
      icon: 'male',
      disabled: false,
    },
    {
      key: 'accounting',
      title: 'Contabilidad',
      path: ACCOUNTING_REPORTS_PATH,
      icon: 'file-excel-o',
      disabled: false,
    },
    {
      key: 'discount-code',
      title: 'Códigos de Descuento',
      path: DISCOUNT_CODE_REPORTS_PATH,
      icon: 'sticky-note',
      disabled: false,
    },
    {
      key: 'call-center',
      title: 'Call Center',
      path: CALL_CENTER_REPORTS_PATH,
      icon: 'phone',
      disabled: false,
    },
    {
      key: 'expense',
      title: 'Egresos',
      path: EXPENSE_REPORTS_PATH,
      icon: 'table',
      disabled: false,
    },
    {
      key: 'pricing-profile',
      title: 'Perfiles de Precios',
      path: PRICING_PROFILE_REPORTS_PATH,
      icon: 'list-ul',
      disabled: false,
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Reportes',
      tag: 'span',
      href: '/reports',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
