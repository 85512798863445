import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteBusiness } from '../../../../actions';

export const BusinessDeleteButton = ({
  dispatchDeleteBusiness,
  businessId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const onClickDelete = () => dispatchDeleteBusiness({ businessId });
  const onClickShowModal = () => setShowModal(true);
  const onClickCancel = () => setShowModal(false);

  const confirmationModal = (
    <ConfirmationModal
      show={showModal}
      onClickConfirm={onClickDelete}
      onClickCancel={onClickCancel}
    />
  );

  const deleteButton = (
    <Button color="danger" onClick={onClickShowModal}>
      <i className="fa fa-trash" /> Eliminar
    </Button>
  );

  return (
    <>
      {deleteButton}
      {confirmationModal}
    </>
  );
};

BusinessDeleteButton.propTypes = {
  businessId: PropTypes.number.isRequired,
  dispatchDeleteBusiness: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteBusiness: deleteBusiness,
};

export default connect(null, mapDispatchToProps)(BusinessDeleteButton);
