import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Content from '../../../layout/Content';
import { ACTIVITY_PROJECTION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { getOdometerByBus } from '../../../../actions';
import PreventiveMaintenanceReportSearchForm from '../preventive-maintenance-report/PreventiveMaintenanceReportSearchForm';
import OdometerByBusSearchResult from '../odometer-by-bus/OdometerByBusSearchResult';
import { tzNormalizeDate } from '../../../../utils/date';
import {
  INT_DATE_FORMAT,
  INT_DATE_TIME_FORMAT,
} from '../../../../config/locale';
import Alert from '../../../common/informative/Alert';
import { ENTER_DATA_TO_SHOW_DATA } from '../../../../config/messages';

const OdometerByBus = ({ breadcrumbs, dispatchGetOdometerByBus, loading }) => {
  const [searchValues, setSearchValues] = useState(null);

  const onSubmit = async (formValues) => {
    const newSearchValues = {
      registeredBusId: formValues.registeredBusIdList.value,
      fromDate: tzNormalizeDate({
        date: formValues.dateFrom,
        time: 'start',
        format: `${INT_DATE_TIME_FORMAT}:00`,
      }),
      toDate: tzNormalizeDate({
        date: formValues.dateTo,
        time: 'start',
        format: `${INT_DATE_TIME_FORMAT}:00`,
      }),
    };

    setSearchValues(newSearchValues);
    dispatchGetOdometerByBus(newSearchValues);
  };

  const today = tzNormalizeDate({
    time: 'start',
    addTime: { amount: -1, unit: 'd' },
    format: INT_DATE_FORMAT,
  });
  const tomorrow = tzNormalizeDate({ time: 'start', format: INT_DATE_FORMAT });

  let table = <Alert type="info" message={ENTER_DATA_TO_SHOW_DATA} />;
  if (searchValues) table = <OdometerByBusSearchResult />;

  const content = (
    <>
      <PreventiveMaintenanceReportSearchForm
        onSubmit={onSubmit}
        initialValues={{
          dateFrom: today,
          dateTo: tomorrow,
        }}
        showDateFrom
        searchButtonDisabled={loading}
        registeredBusIsRequired
      />
      <div className="mt-3">{table}</div>
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Odómetro por Bus"
      content={content}
    />
  );
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Odómetro por Bus',
      href: ACTIVITY_PROJECTION_PATH,
    },
  ],
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

OdometerByBus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetOdometerByBus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchGetOdometerByBus: getOdometerByBus,
};

export default connect(mapStateToProps, mapDispatchToProps)(OdometerByBus);
