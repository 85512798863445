import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getUserSecurityProfiles,
  clearUserSecurityProfiles,
  getAuthorizedProfilesPerLoggedInUser,
  clearAuthorizedProfilesPerLoggedInUser,
} from '../../../../actions/index';
import Select from './Select';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  inputDefaultProps,
  inputPropTypes,
  metaDefaultProps,
  metaPropTypes,
} from '../../forms/input/InputPropTypes';

class UserSecurityProfileSelect extends Component {
  componentDidMount() {
    const {
      showAuthorizedOnly,
      dispatchGetUserSecurityProfiles,
      dispatchGetAuthorizedProfilesPerLoggedInUser,
    } = this.props;
    if (showAuthorizedOnly) {
      dispatchGetAuthorizedProfilesPerLoggedInUser({
        ...DEFAULT_QUERY_GET_ALL,
      });
    } else {
      dispatchGetUserSecurityProfiles({ ...DEFAULT_QUERY_GET_ALL });
    }
  }

  componentWillUnmount() {
    const {
      showAuthorizedOnly,
      dispatchClearUserSecurityProfiles,
      dispatchClearAuthorizedProfilesPerLoggedInUser,
    } = this.props;

    if (showAuthorizedOnly) {
      dispatchClearAuthorizedProfilesPerLoggedInUser();
    } else {
      dispatchClearUserSecurityProfiles();
    }
  }

  render() {
    const { options, isLoading, isMulti, input, meta, ...rest } = this.props;
    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        {...rest}
      />
    );
  }
}

UserSecurityProfileSelect.propTypes = {
  dispatchGetUserSecurityProfiles: PropTypes.func.isRequired,
  dispatchClearUserSecurityProfiles: PropTypes.func.isRequired,
  dispatchGetAuthorizedProfilesPerLoggedInUser: PropTypes.func.isRequired,
  dispatchClearAuthorizedProfilesPerLoggedInUser: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  showAuthorizedOnly: PropTypes.bool,
};

UserSecurityProfileSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  options: [],
  showAuthorizedOnly: false,
};

const mapStateToProps = (
  { UserUnit, SecurityUnit },
  { showAuthorizedOnly },
) => {
  let options = [];
  let loading = false;

  if (showAuthorizedOnly) {
    const authorizedProfiles = SecurityUnit.AuthorizedProfile.getIn([
      'allAuthorizedProfilesPerLoggedInUser',
      'content',
    ]);

    authorizedProfiles.forEach((profile) => {
      options.push({
        value: profile.id,
        label: profile.name,
      });
    });

    loading = SecurityUnit.AuthorizedProfile.getIn([
      'allAuthorizedProfilesPerLoggedInUser',
      'loading',
    ]);
  } else {
    options = UserUnit.UserSecurityProfile.getIn([
      'all',
      'content',
      'content',
    ]).map((profile) => ({
      value: profile.id,
      label: profile.name,
    }));

    loading = UserUnit.UserSecurityProfile.getIn(['all', 'loading']);
  }

  return {
    options,
    loading,
  };
};

const mapDispatchToprops = {
  dispatchGetUserSecurityProfiles: getUserSecurityProfiles,
  dispatchClearUserSecurityProfiles: clearUserSecurityProfiles,
  dispatchGetAuthorizedProfilesPerLoggedInUser:
    getAuthorizedProfilesPerLoggedInUser,
  dispatchClearAuthorizedProfilesPerLoggedInUser:
    clearAuthorizedProfilesPerLoggedInUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(UserSecurityProfileSelect);
