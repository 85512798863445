import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FUEL_VOUCHER_STATUS } from '../../../../config/constants';
import { patchStateFuelVoucher } from '../../../../actions';

class FuelVoucherDisableButton extends Component {
  onClickDisable = () => {
    const { fuelVoucherId, dispatchPatchStateFuelVoucher } = this.props;

    dispatchPatchStateFuelVoucher({
      fuelVoucherId,
      fuelVoucherStatus: FUEL_VOUCHER_STATUS.INACTIVE.value,
    });
  };

  render() {
    return (
      <Button color="danger" onClick={this.onClickDisable}>
        Desactivar
      </Button>
    );
  }
}

FuelVoucherDisableButton.propTypes = {
  fuelVoucherId: PropTypes.number.isRequired,
  dispatchPatchStateFuelVoucher: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPatchStateFuelVoucher: patchStateFuelVoucher,
};

export default connect(
  null,
  mapDispatchToProps,
)(FuelVoucherDisableButton);
