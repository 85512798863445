import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  getCargoCategories,
  clearCargoCategories,
} from '../../../../actions/cargo/CargoCategory';

class CargoCategorySelect extends Component {
  componentDidMount() {
    const { dispatchGetCargoCategories } = this.props;
    dispatchGetCargoCategories(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearCargoCategories, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearCargoCategories();
    }
  }

  render() {
    const {
      loading,
      cargoCategoryOptions,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={cargoCategoryOptions}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

CargoCategorySelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetCargoCategories: PropTypes.func.isRequired,
  dispatchClearCargoCategories: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  cargoCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  isClearable: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  cleanWhenUnmount: PropTypes.bool,
};

CargoCategorySelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  isClearable: false,
  onValueChange: undefined,
  value: {},
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ CargoUnit }) => ({
  cargoCategoryOptions: CargoUnit.CargoCategory.getIn([
    'all',
    'content',
    'content',
  ]).map((cargoCategory) => ({
    value: cargoCategory.id,
    label: cargoCategory.name,
  })),
  loading: CargoUnit.CargoCategory.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCargoCategories: getCargoCategories,
  dispatchClearCargoCategories: clearCargoCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CargoCategorySelect);
