import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  NEW_GEOGRAPHIC_ZONE_COMBINATION_PATH,
  GEOGRAPHIC_ZONE_COMBINATION_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postGeographicZonesCombination } from '../../../../actions/cargo';
import GeographicZonesCombinationForm from './GeographicZonesCombinationForm';

class NewGeographicZonesCombination extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      factor: formValues.factor,
      description: formValues.description,
      sourceGeographicZoneId: formValues.sourceGeographicZone.value,
      destinationGeographicZoneId: formValues.destinationGeographicZone.value,
    };
    const { dispatchPostGeographicZonesCombination } = this.props;
    dispatchPostGeographicZonesCombination(newFormValues);
  };

  render() {
    const { breadcrumbs } = this.props;
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Crear Combinación de Zonas Geográficas</h1>
            <p>Crear nueva combinación de zonas geográficas</p>
          </Col>
        </Row>
        <GeographicZonesCombinationForm onSubmit={this.onSubmit} />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Combinaciones de Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_COMBINATION_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_GEOGRAPHIC_ZONE_COMBINATION_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostGeographicZonesCombination: postGeographicZonesCombination,
};

NewGeographicZonesCombination.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostGeographicZonesCombination: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewGeographicZonesCombination);
