const downloadCsv = (
  csv,
  filename = 'report.csv',
  type = 'text/plain;charset=utf-8;',
) => {
  const blob = new Blob([csv]);
  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob, { type });
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadCsv;
