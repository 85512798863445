import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postMaintenanceServiceOrder } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaintenanceServiceOrderForm from './MaintenanceServiceOrderForm';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import { MAINTENANCE_SERVICE_ORDER_STATUS } from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';

const NewMaintenanceServiceOrder = ({
  breadcrumbs,
  dispatchPostMaintenanceServiceOrder,
}) => {
  const onSubmit = (formValues) => {
    const maintenanceWarningList =
      formValues.preventiveMaintenanceWarningList.concat(
        formValues.correctiveMaintenanceWarningList,
      );
    dispatchPostMaintenanceServiceOrder({
      maintenanceWarningList: maintenanceWarningList.map((warning) => ({
        id: warning.maintenanceWarningId.value,
        activityBusId: warning.maintenanceWarningId.activityBusId,
      })),
      registeredBusId: formValues.registeredBusId.value,
      deadline: tzNormalizeDate({ date: formValues.deadline }),
      priority: formValues.priority.value,
      description: formValues.description,
      status: MAINTENANCE_SERVICE_ORDER_STATUS.OPEN.value,
    });
  };

  const content = (
    <MaintenanceServiceOrderForm
      onSubmit={onSubmit}
      initialValues={{ status: MAINTENANCE_SERVICE_ORDER_STATUS.OPEN }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Orden de Servicio"
      subtitle="Crear nueva orden de servicio"
      content={content}
    />
  );
};

NewMaintenanceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMaintenanceServiceOrder: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostMaintenanceServiceOrder: postMaintenanceServiceOrder,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewMaintenanceServiceOrder);
