import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteOrganizationChart } from '../../../../actions/mechanical-maintenance/OrganizationChart';

const OrganizationChartDeleteButton = ({
  dispatchDeleteOrganizationChart,
  organizationChartId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteOrganizationChart({ organizationChartId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

OrganizationChartDeleteButton.propTypes = {
  dispatchDeleteOrganizationChart: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  organizationChartId: PropTypes.number.isRequired,
};

OrganizationChartDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteOrganizationChart: deleteOrganizationChart,
};

export default connect(null, mapDispatchToProps)(OrganizationChartDeleteButton);
