import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { WORKSTATION_PATH, PRINT_STATION_PATH } from '../../../../config/paths';
import { getWorkstation, clearWorkstation } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { Table } from '../../../common/Table';
import WorkstationToolbar from './WorkstationToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import { padStart } from '../../../../utils/string';

class Workstation extends Component {
  componentDidMount() {
    const {
      dispatchGetWorkstation,
      match: {
        params: { id: workstationId },
      },
    } = this.props;

    dispatchGetWorkstation({ workstationId });
  }

  componentWillUnmount() {
    const { dispatchClearWorkstation } = this.props;
    dispatchClearWorkstation();
  }

  render() {
    const { breadcrumbs, workstation, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (workstation.isEmpty()) {
      content = <NoDataResource returnPage={WORKSTATION_PATH} />;
    } else {
      const basicInformationResource = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="Número de Serie:">
            {workstation.get('serialNumber')}
          </ResourceProperty>
          <ResourceProperty label="Nombre:">
            {workstation.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {workstation.get('description')}
          </ResourceProperty>
        </Fragment>
      );

      const printStationData = [];

      workstation.get('printStationList').forEach((printStation) =>
        printStation.printTemplateList.forEach((template) => {
          printStationData.push({
            id: printStation.id,
            serialNumber: printStation.serialNumber,
            printTemplateType: template.printTemplateType,
            documentSeries: template.documentSeries,
            documentCode: template.documentCode,
          });
        }),
      );

      const userResource = (
        <div className="mb-3">
          <h3>Impresoras</h3>
          <Table
            columns={[
              {
                Header: 'Número de Serie',
                accessor: 'serialNumber',
                className: 'text-center',
                Cell: (row) => (
                  <Link to={`${PRINT_STATION_PATH}/${row.original.id}`}>
                    {row.value}
                  </Link>
                ),
              },
              {
                Header: 'Plantilla de Impresión',
                accessor: 'printTemplateType',
                className: 'text-center',
                Cell: (row) => (
                  <Link to={`${PRINT_STATION_PATH}/${row.original.id}`}>
                    {row.value}
                  </Link>
                ),
              },
              {
                Header: 'Serie',
                accessor: 'documentSeries',
                className: 'text-center',
                Cell: (row) => (
                  <Link to={`${PRINT_STATION_PATH}/${row.original.id}`}>
                    {row.value}
                  </Link>
                ),
              },
              {
                Header: 'Correlativo Siguiente',
                accessor: 'documentCode',
                className: 'text-center',
                Cell: (row) => (
                  <Link to={`${PRINT_STATION_PATH}/${row.original.id}`}>
                    {padStart(row.value, '7')}
                  </Link>
                ),
              },
            ]}
            data={printStationData}
            defaultPageSize={printStationData.length}
            showPagination={false}
          />
        </div>
      );

      const internalResource = (
        <InternalResource
          id={workstation.get('id')}
          createDate={workstation.get('createDate')}
          lastUpdate={workstation.get('lastUpdate')}
        />
      );

      content = (
        <Fragment>
          {basicInformationResource}
          {userResource}
          {internalResource}
        </Fragment>
      );

      toolbar = <WorkstationToolbar workstationId={workstation.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Caja"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

Workstation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetWorkstation: PropTypes.func.isRequired,
  dispatchClearWorkstation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  workstation: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Workstation.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Cajas',
      href: WORKSTATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  workstation: HumanResourcesUnit.Workstation.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.Workstation.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetWorkstation: getWorkstation,
  dispatchClearWorkstation: clearWorkstation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workstation);
