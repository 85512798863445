import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const UserEditButton = ({ path }) => (
  <Link className="btn btn-primary" to={path}>
    <i className="fa fa-edit" /> Editar
  </Link>
);

UserEditButton.propTypes = {
  path: PropTypes.string.isRequired,
};

export default UserEditButton;
