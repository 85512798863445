import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Table from '../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { ITINERARIES_FOR_COUNTER_COLUMNS } from '../../../../config/columns';
import { clearItineraries, searchItinerary } from '../../../../actions';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../config/paths';

export class ItinerarySearchResult extends Component {
  componentWillUnmount() {
    this.props.dispatchClearItineraries();
  }

  render() {
    const {
      loading,
      itineraries,
      getRowData,
      searchFormValues,
      dispatchSearchItinerary,
      modelPath,
      openPathInNewTab,
    } = this.props;

    const data = itineraries.get('content') || [];
    const columns = ITINERARIES_FOR_COUNTER_COLUMNS;
    const pages = itineraries.get('totalPages') || null;
    const defaultPageSize = itineraries.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <div className="mt-3">
        <Table
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          getRowData={getRowData}
          modelPath={modelPath}
          navigateToModelOnRowClick
          fetchData={dispatchSearchItinerary}
          params={searchFormValues}
          openPathInNewTab={openPathInNewTab}
          highlightSelectedRow
        />
      </div>
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: ItineraryUnit.Itinerary.getIn(['all', 'loading']),
  itineraries: ItineraryUnit.Itinerary.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchClearItineraries: clearItineraries,
  dispatchSearchItinerary: searchItinerary,
};

ItinerarySearchResult.propTypes = {
  itineraries: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  getRowData: PropTypes.func,
  dispatchClearItineraries: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    sourceLocationId: PropTypes.number,
    destinationLocationId: PropTypes.number,
    departureDayString: PropTypes.string,
    departureTimeString: PropTypes.string,
  }).isRequired,
  dispatchSearchItinerary: PropTypes.func.isRequired,
  modelPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  openPathInNewTab: PropTypes.bool,
};

ItinerarySearchResult.defaultProps = {
  loading: false,
  getRowData: null,
  modelPath: ITINERARY_FOR_COUNTER_PATH,
  openPathInNewTab: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerarySearchResult);
