import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  CARGO_MINIMAL_PRICE_PATH,
  NEW_CARGO_MINIMAL_PRICE_PATH,
} from '../../../../config/paths';
import {
  getCargoMinimalPrices,
  clearCargoMinimalPrices,
} from '../../../../actions/cargo';
import { CARGO_MINIMAL_PRICE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class CargoMinimalPrices extends Component {
  componentWillUnmount() {
    const { dispatchClearCargoMinimalPrices } = this.props;
    dispatchClearCargoMinimalPrices();
  }

  render() {
    const { prices, loading, breadcrumbs, dispatchGetCargoMinimalPrices } =
      this.props;
    const data = prices.get('content') || [];
    const pages = prices.get('totalPages') || null;
    const defaultPageSize = prices.get('size') || DEFAULT_PAGE_SIZE;

    const moduleListProps = {
      title: 'Precio Mínimo de Carga',
      breadcrumbs,
      tableStructure: {
        columns: CARGO_MINIMAL_PRICE_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: false,
        fetchData: dispatchGetCargoMinimalPrices,
        loading,
        modelPath: CARGO_MINIMAL_PRICE_PATH,
        navigateToModelOnRowClick: true,
      },
    };

    if (prices.get('numberOfElements') === 0) {
      moduleListProps.buttonPath = NEW_CARGO_MINIMAL_PRICE_PATH;
    }

    return <ModuleList {...moduleListProps} />;
  }
}

const mapDispatchToProps = {
  dispatchGetCargoMinimalPrices: getCargoMinimalPrices,
  dispatchClearCargoMinimalPrices: clearCargoMinimalPrices,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Precio Mínimo de Carga',
      href: CARGO_MINIMAL_PRICE_PATH,
    },
  ],
  prices: CargoUnit.CargoMinimalPrice.getIn(['all', 'content']),
  loading: CargoUnit.CargoMinimalPrice.getIn(['all', 'loading']),
});

CargoMinimalPrices.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  prices: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCargoMinimalPrices: PropTypes.func.isRequired,
  dispatchClearCargoMinimalPrices: PropTypes.func.isRequired,
};

CargoMinimalPrices.defaultProps = {
  prices: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoMinimalPrices);
