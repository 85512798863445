import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NEW_COUNTRY_PATH, COUNTRY_PATH } from '../../../../config/paths';
import CountryForm from './CountryForm';
import { postCountry } from '../../../../actions/location/Country';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';

const NewCountry = ({ breadcrumbs, dispatchPostCountry }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Nuevo País"
    subtitle="Crear un nuevo país"
    content={<CountryForm onSubmit={dispatchPostCountry} />}
  />
);

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Países',
      href: COUNTRY_PATH,
    },
    {
      text: 'Nuevo',
      href: NEW_COUNTRY_PATH,
    },
  ],
});

const mapDispatchToProps = { dispatchPostCountry: postCountry };

NewCountry.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCountry: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCountry);
