import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import GeographicZonesCombinationForm from './GeographicZonesCombinationForm';
import { GEOGRAPHIC_ZONE_COMBINATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getGeographicZonesCombination,
  clearGeographicZonesCombination,
  putGeographicZonesCombination,
} from '../../../../actions';
import Loader from '../../../common/Loader';

export class EditGeographicZonesCombination extends Component {
  componentDidMount() {
    const {
      dispatchGetGeographicZonesCombination,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetGeographicZonesCombination({ geographicZonesCombinationId: id });
  }

  componentWillUnmount() {
    const { dispatchClearGeographicZonesCombination } = this.props;
    dispatchClearGeographicZonesCombination();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutGeographicZonesCombination,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutGeographicZonesCombination(id, {
      sourceGeographicZoneId: formValues.sourceGeographicZone.value,
      destinationGeographicZoneId: formValues.destinationGeographicZone.value,
      factor: formValues.factor,
      description: formValues.description,
    });
  };

  render() {
    const { breadcrumbs, geographicZonesCombination, loading } = this.props;
    if (loading || geographicZonesCombination.isEmpty()) {
      return <Loader />;
    }
    const initialValues = {
      sourceGeographicZone: {
        label: geographicZonesCombination.get('sourceGeographicZone').name,
        value: geographicZonesCombination.get('sourceGeographicZone').id,
      },
      destinationGeographicZone: {
        label: geographicZonesCombination.get('destinationGeographicZone').name,
        value: geographicZonesCombination.get('destinationGeographicZone').id,
      },
      factor: geographicZonesCombination.get('factor'),
      description: geographicZonesCombination.get('description'),
    };
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Editar Combinación de Zonas Geográficas</h1>
        <p>Editar Combinación de Zonas Geográficas</p>
        <GeographicZonesCombinationForm
          onSubmit={this.onSubmit}
          initialValues={initialValues}
        />
      </Container>
    );
  }
}

EditGeographicZonesCombination.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetGeographicZonesCombination: PropTypes.func.isRequired,
  dispatchClearGeographicZonesCombination: PropTypes.func.isRequired,
  dispatchPutGeographicZonesCombination: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  geographicZonesCombination: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditGeographicZonesCombination.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Combinaciones de Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_COMBINATION_PATH,
    },
    {
      text: 'Ver',
      href: `${GEOGRAPHIC_ZONE_COMBINATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  geographicZonesCombination: CargoUnit.GeographicZonesCombination.getIn([
    'current',
    'content',
  ]),
  loading: !CargoUnit.GeographicZonesCombination.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetGeographicZonesCombination: getGeographicZonesCombination,
  dispatchClearGeographicZonesCombination: clearGeographicZonesCombination,
  dispatchPutGeographicZonesCombination: putGeographicZonesCombination,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditGeographicZonesCombination);
