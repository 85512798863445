import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { ADDRESS_PATH, NEW_ADDRESS_PATH } from '../../../../config/paths';
import { ADDRESS_COLUMNS } from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { clearAddresses, getAddresses } from '../../../../actions';

export const Addresses = ({
  breadcrumbs,
  addresses,
  loading,
  dispatchGetAddresses,
  dispatchClearAddresses,
}) => {
  useLayoutEffect(() => () => dispatchClearAddresses(), []);

  const data = addresses.get('content') || [];
  const pages = addresses.get('totalPages') || null;
  const defaultPageSize = addresses.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Direcciones"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_ADDRESS_PATH}
      tableStructure={{
        columns: ADDRESS_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetAddresses,
        modelPath: ADDRESS_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetAddresses: getAddresses,
  dispatchClearAddresses: clearAddresses,
};

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Direcciones',
      href: ADDRESS_PATH,
    },
  ],
  addresses: LocationUnit.Address.getIn(['all', 'content']),
  loading: LocationUnit.Address.getIn(['all', 'loading']),
});

Addresses.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  addresses: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetAddresses: PropTypes.func.isRequired,
  dispatchClearAddresses: PropTypes.func.isRequired,
};

Addresses.defaultProps = {
  addresses: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
