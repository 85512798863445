import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { HOME_PATH, USER_SELF_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import UserForm from './UserForm';
import Loader from '../../../common/Loader';
import { putUserSelf } from '../../../../actions';

export const EditUserSelf = ({
  dispatchPutUserSelf,
  user,
  breadcrumbs,
  loading,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      newPassword: formValues.newPassword,
      confirmPassword: formValues.confirmNewPassword,
      currentPassword: formValues.currentPassword,
    };

    dispatchPutUserSelf(user.id, newFormValues);
  };

  if (loading) return <Loader />;

  return (
    <Container fluid>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Editar Mi Usuario</h1>
      <p>Editar Mi Usuario.</p>
      <UserForm onSubmit={onSubmit} self />
    </Container>
  );
};

const mapStateToProps = ({ authentication, UserUnit }) => ({
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Mi Usuario',
      href: USER_SELF_PATH,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  loading: !UserUnit.User.getIn(['current', 'activity']).isEmpty(),
  user: authentication.getIn(['user']),
});

const mapDispatchToProps = {
  dispatchPutUserSelf: putUserSelf,
};

EditUserSelf.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutUserSelf: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

EditUserSelf.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserSelf);
