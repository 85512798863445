import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FUEL_VOUCHER_PATH } from '../../../../config/paths';

const EditFuelVoucherButton = ({ fuelVoucherId }) => (
  <Link
    className="btn btn-primary"
    to={`${FUEL_VOUCHER_PATH}/${fuelVoucherId}/edit`}
  >
    Editar
  </Link>
);

EditFuelVoucherButton.propTypes = {
  fuelVoucherId: PropTypes.number.isRequired,
};

export default EditFuelVoucherButton;
