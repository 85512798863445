import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  organizationChartBasicInformationDefaultProps,
  organizationChartBasicInformationPropTypes,
} from '../proptypes/OrganizationChartPropTypes';

const OrganizationChartBasicInformationResource = ({
  name,
  description,
  companyAreaType,
  parentCompanyArea,
}) => (
  <>
    <ResourceProperty label="Nombre:">{name}</ResourceProperty>
    <ResourceProperty label="Descripción:">
      {description || '-'}
    </ResourceProperty>
    <ResourceProperty label="Tipo:">
      {companyAreaType.name || '-'}
    </ResourceProperty>
    <ResourceProperty label="Organigrama Padre:">
      {parentCompanyArea ? parentCompanyArea.name : '-'}
    </ResourceProperty>
  </>
);

OrganizationChartBasicInformationResource.propTypes =
  organizationChartBasicInformationPropTypes;

OrganizationChartBasicInformationResource.defaultProps =
  organizationChartBasicInformationDefaultProps;

export default OrganizationChartBasicInformationResource;
