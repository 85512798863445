import { string, number, shape, arrayOf, bool } from 'prop-types';
import { ActivityBusBasicInformationPropTypes } from '../../bus-activity-assigment/prop-types/ActivityBus';
import { locationBasicInformationPropTypes } from '../../../location/location/proptypes/LocationPropTypes';
import { mechanicCustomerInformationPropTypes } from '../../mechanic/proptypes/MechanicPropTypes';

const activityBusExecutionBasicInformationPropTypes = {
  activityBus: shape(ActivityBusBasicInformationPropTypes).isRequired,
  activityBusId: number.isRequired,
  activityTaskExecutionList: arrayOf(shape({})), // TODO create PropType
  advanced: bool.isRequired,
  baseLocation: shape(locationBasicInformationPropTypes),
  baseLocationId: number,
  comment: string,
  createDate: number.isRequired,
  employeeList: arrayOf(
    shape({
      employeeId: number,
      employee: shape(mechanicCustomerInformationPropTypes),
    }),
  ),
  endDate: number,
  extensionFactor: number,
  id: number.isRequired,
  serviceOrderId: number.isRequired,
  startDate: number,
  status: string.isRequired,
  userId: number.isRequired,
  virtualOdometer: number,
  workingHour: number,
};

const activityBusExecutionBasicDefaultProps = {
  activityTaskExecutionList: null,
  baseLocation: null,
  baseLocationId: null,
  comment: null,
  employeeList: [],
  endDate: null,
  extensionFactor: null,
  startDate: null,
  virtualOdometer: null,
  workingHour: null,
};

export {
  activityBusExecutionBasicInformationPropTypes,
  activityBusExecutionBasicDefaultProps,
};
