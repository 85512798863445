import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MECHANIC_PATH } from '../../../../config/paths';
import { postMechanic } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MechanicForm from './MechanicForm';
import { DEFAULT_COMPANY_OPTION } from '../../../../config/defaults';

const NewMechanic = ({ breadcrumbs, dispatchPostMechanic }) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.customerId =
      formValues.customerId && formValues.customerId.value;
    newFormValues.companyId =
      formValues.companyId && formValues.companyId.value;
    newFormValues.managementId =
      formValues.managementId && formValues.managementId.value;
    newFormValues.departmentId =
      formValues.departmentId && formValues.departmentId.value;
    newFormValues.areaId = formValues.areaId && formValues.areaId.value;
    newFormValues.sectionId =
      formValues.sectionId && formValues.sectionId.value;
    newFormValues.specialityId =
      formValues.specialityId && formValues.specialityId.value;
    newFormValues.bossId = formValues.bossId && formValues.bossId.value;
    dispatchPostMechanic(newFormValues);
  };

  const content = (
    <MechanicForm
      onSubmit={onSubmit}
      initialValues={{ companyId: DEFAULT_COMPANY_OPTION }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Personal"
      subtitle="Crear nuevo personal"
      content={content}
    />
  );
};

NewMechanic.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMechanic: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostMechanic: postMechanic,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Personal',
      href: MECHANIC_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMechanic);
