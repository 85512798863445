import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import Event from './Event';
import InSystemNotification from './InSystemNotification';

export default combineReducers({
  UnitHome,
  Event,
  InSystemNotification,
});
