import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
} from '../../../../actions/mechanical-maintenance/MaintenanceServiceOrder';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import { ACTIVITY_STATUS } from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  clearExecutionRegistration,
  getActivityBusExecution,
  putActivityBusExecution,
} from '../../../../actions/mechanical-maintenance/ExecutionRegistration';
import ExecutionRegistrationForm from '../register-of-execution/ExecutionRegistrationForm';
import MaintenanceServiceOrderBasicInformation from './resource/MaintenanceServiceOrderBasicInformationResource';
import MaintenanceServiceOrderMaintenanceWarningBadgeListInformation from './resource/MaintenanceServiceOrderMaintenanceWarningBadgeListInformation';

const EditMaintenanceServiceOrderActivityBusExecution = ({
  breadcrumbs,
  dispatchPutActivityBusExecution,
  dispatchGetMaintenanceServiceOrder,
  dispatchClearMaintenanceServiceOrder,
  maintenanceServiceOrder,
  loading,
  match: {
    params: { id: maintenanceServiceOrderId, abeId: activityBusExecutionId },
  },
  dispatchGetActivityBusExecution,
  dispatchClearExecutionRegistration,
  loadingActivityBusExecution,
  activityBusExecution,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenanceServiceOrder({ maintenanceServiceOrderId });
    dispatchGetActivityBusExecution({ activityBusExecutionId });

    return () => {
      dispatchClearMaintenanceServiceOrder();
      dispatchClearExecutionRegistration();
    };
  }, []);

  const onSubmit = (formValues) => {
    const {
      activityBusId,
      activityTaskExecutionList,
      serviceOrderId,
      createDate,
      userId,
      virtualOdometer,
    } = activityBusExecution.toJS();

    const newActivityBusExecutionPayload = {
      status: formValues.status.value,
      startDate: formValues.startDate
        ? tzNormalizeDate({ date: formValues.startDate })
        : null,
      activityBusId,
      comment: formValues.comment,
      extensionFactor: formValues.extensionFactor,
      endDate: formValues.endDate
        ? tzNormalizeDate({ date: formValues.endDate })
        : null,
      workingHour: formValues.workingHour,
      baseLocationId: formValues.baseLocationId.value,
      employeeList: formValues.mechanics.map((mechanic) => ({
        employeeId: mechanic.value,
      })),
      activityTaskExecutionList,
      serviceOrderId,
      createDate,
      userId,
      advanced: false,
      virtualOdometer,
    };

    dispatchPutActivityBusExecution(
      activityBusExecutionId,
      newActivityBusExecutionPayload,
      true,
    );
  };

  const generateInitialValues = (activityBusExecutionJson) => {
    const {
      baseLocation,
      extensionFactor,
      status,
      workingHour,
      comment,
      startDate,
      endDate,
      activityBusId,
      employeeList,
      activityBus,
    } = activityBusExecutionJson;

    const newStartDate = startDate
      ? tzNormalizeDate({ date: startDate })
      : tzNormalizeDate();

    const newEndDate = endDate
      ? tzNormalizeDate({ date: endDate })
      : tzNormalizeDate({ addTime: { amount: 1, unit: 'h' } });

    const newWorkingHour = workingHour || 1;

    let baseLocationId;
    if (baseLocation)
      baseLocationId = {
        value: baseLocation.id,
        label: baseLocation.name,
      };
    else if (
      activityBus &&
      activityBus.registeredBus &&
      activityBus.registeredBus.homeBaseLocation
    ) {
      const {
        registeredBus: { homeBaseLocation },
      } = activityBus;
      baseLocationId = {
        value: homeBaseLocation.id,
        label: homeBaseLocation.name,
      };
    }

    return {
      status:
        status === ACTIVITY_STATUS.PENDING.value
          ? ACTIVITY_STATUS.DONE
          : ACTIVITY_STATUS[status],
      extensionFactor,
      workingHour: newWorkingHour,
      startDate: newStartDate.slice(0, 16),
      endDate: newEndDate.slice(0, 16),
      baseLocationId,
      comment,
      activityBusId,
      mechanics: employeeList.map((employee) => ({
        value: employee.employeeId,
        label: employee.employee.customer.fullName,
      })),
    };
  };

  let content;

  if (loading || loadingActivityBusExecution) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty() || activityBusExecution.isEmpty())
    content = (
      <NoDataResource
        returnPage={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/execution`}
      />
    );
  else {
    const {
      registeredBus,
      deadline,
      priority,
      description,
      status,
      maintenanceWarningList,
    } = maintenanceServiceOrder.toJS();

    const activityBusExecutionJson = activityBusExecution.toJS();

    const badgeListComponent = (
      <MaintenanceServiceOrderMaintenanceWarningBadgeListInformation
        maintenanceWarningList={maintenanceWarningList}
      />
    );

    content = (
      <>
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Datos de la Orden de Servicio</h3>
            <MaintenanceServiceOrderBasicInformation
              deadline={deadline}
              priority={priority}
              description={description}
              status={status}
              registeredBus={registeredBus}
            />
          </Col>
          <Col lg={6}>{badgeListComponent}</Col>
        </Row>
        <h3>
          Actividad:{' '}
          {activityBusExecutionJson.activityBus.activity.companyActivityId} -{' '}
          {activityBusExecutionJson.activityBus.activity.name}
        </h3>
        <ExecutionRegistrationForm
          onSubmit={onSubmit}
          initialValues={generateInitialValues(activityBusExecutionJson)}
        />
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Ejecución de Actividad"
      subtitle="Editar ejecución de actividad"
      content={content}
    />
  );
};

EditMaintenanceServiceOrderActivityBusExecution.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes,
  dispatchPutActivityBusExecution: PropTypes.func.isRequired,
  dispatchGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispatchClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetActivityBusExecution: PropTypes.func.isRequired,
  dispatchClearExecutionRegistration: PropTypes.func.isRequired,
  activityBusExecution: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingActivityBusExecution: PropTypes.bool.isRequired,
};

EditMaintenanceServiceOrderActivityBusExecution.defaultProps = {
  match: null,
};

const mapDispatchToProps = {
  dispatchPutActivityBusExecution: putActivityBusExecution,
  dispatchGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispatchClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
  dispatchGetActivityBusExecution: getActivityBusExecution,
  dispatchClearExecutionRegistration: clearExecutionRegistration,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Ejecuciones',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}/execution`,
    },
    {
      text: 'Editar Ejecución de Actividad',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  activityBusExecution: MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'content',
  ]),
  loadingActivityBusExecution:
    !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
      'current',
      'activity',
    ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceServiceOrderActivityBusExecution);
