import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_ITINERARY_GROUPS,
  GET_ITINERARY_GROUPS,
  CLEAR_ITINERARY_GROUPS,
  GET_ITINERARY_GROUP,
  CLEAR_ITINERARY_GROUP,
  FLAG_ITINERARY_GROUP_ACTIVITY,
} from '../types/route';
import {
  ITINERARY_GROUP_ENDPOINT,
  generateFuelReportPerItineraryGroupEndpoint,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_POST_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  ITINERARY_GROUP_PATH,
  FUEL_CONSUMPTION_REPORT_PER_BUS_PATH,
} from '../../config/paths';

const flagGettingItineraryGroups = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ITINERARY_GROUPS,
    payload: flag,
  });

const flagItineraryGroupActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ITINERARY_GROUP_ACTIVITY,
    payload: flag,
  });

const getItineraryGroups = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingItineraryGroups(true));
    const query = tableFilters;
    const url = `${ITINERARY_GROUP_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const circuitGroups = await response.json();
    dispatch({
      type: GET_ITINERARY_GROUPS,
      payload: circuitGroups,
    });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingItineraryGroups(false));
  }
};

const clearItineraryGroups = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARY_GROUPS,
  });

const getItineraryGroup =
  async ({ itineraryGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryGroupActivity(true));
      const url = `${ITINERARY_GROUP_ENDPOINT}/${itineraryGroupId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      // check if it is an error response
      await isErrorResponse(response);
      const circuitGroup = await response.json();
      dispatch({
        type: GET_ITINERARY_GROUP,
        payload: circuitGroup,
      });
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagItineraryGroupActivity(false));
    }
  };

const clearItineraryGroup = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARY_GROUP,
  });

const postItineraryGroup =
  async ({
    circuitId,
    comments,
    status,
    registeredBusId,
    circuitGroupItineraryList,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryGroupActivity(true));
      const payload = {
        circuitId,
        comments,
        status,
        registeredBusId,
        circuitGroupItineraryList,
      };
      const url = ITINERARY_GROUP_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(ITINERARY_GROUP_PATH));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagItineraryGroupActivity(false));
    }
  };

const putItineraryGroup =
  async (
    itineraryGroupId,
    { circuitId, comments, status, registeredBusId, circuitGroupItineraryList },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryGroupActivity(true));
      const payload = {
        id: itineraryGroupId,
        circuitId,
        comments,
        status,
        registeredBusId,
        circuitGroupItineraryList,
      };
      const url = `${ITINERARY_GROUP_ENDPOINT}/${itineraryGroupId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(ITINERARY_GROUP_PATH));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagItineraryGroupActivity(false));
    }
  };

const deleteItineraryGroup =
  async ({ itineraryGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryGroupActivity(true));
      const url = `${ITINERARY_GROUP_ENDPOINT}/${itineraryGroupId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(ITINERARY_GROUP_PATH));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagItineraryGroupActivity(false));
    }
  };

const putGenerateReport =
  async ({ itineraryGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryGroupActivity(true));

      const url = generateFuelReportPerItineraryGroupEndpoint(itineraryGroupId);

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
      });

      await isErrorResponse(response);
      await response.json();

      dispatch(push(FUEL_CONSUMPTION_REPORT_PER_BUS_PATH));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagItineraryGroupActivity(false));
    }
  };

export {
  flagGettingItineraryGroups,
  flagItineraryGroupActivity,
  getItineraryGroups,
  clearItineraryGroups,
  getItineraryGroup,
  clearItineraryGroup,
  putItineraryGroup,
  deleteItineraryGroup,
  postItineraryGroup,
  putGenerateReport,
};
