import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExpectedFuelConsumptionForm from './ExpectedFuelConsumptionForm';
import { EXPECTED_FUEL_CONSUMPTION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postExpectedFuelConsumption } from '../../../../actions';
import Content from '../../../layout/Content';

export class NewExpectedFuelConsumption extends Component {
  static propTypes = {
    dispatchPostExpectedFuelConsumption: PropTypes.func.isRequired,
    breadcrumbs: breadcrumbsPropTypes.isRequired,
  };

  handleExpectedFuelConsumptionSubmit = (formValues) => {
    const { dispatchPostExpectedFuelConsumption } = this.props;
    dispatchPostExpectedFuelConsumption(formValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Dotación de Combustible"
        subtitle="Crear nueva dotación de combustible"
        content={
          <ExpectedFuelConsumptionForm
            onSubmit={this.handleExpectedFuelConsumptionSubmit}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Dotaciones de Combustible',
      href: EXPECTED_FUEL_CONSUMPTION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostExpectedFuelConsumption: postExpectedFuelConsumption,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewExpectedFuelConsumption);
