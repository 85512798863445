import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '../../../../common/Table';
import { numberFormatter } from '../../../../../utils/number';

const LiquidationGeneralIncomeDetailResource = ({
  generalIncomeSummaryList,
  generalIncomeTypeSummaryList,
  generalIncomePaymentMethodSummaryList,
  cashPaymentMethodList,
}) => {
  if (generalIncomeSummaryList.length === 0) return null;

  const [show, setShow] = useState(false);

  const content = show ? (
    <div className="mb-3">
      <Table
        columns={[
          {
            Header: 'Serie',
            accessor: 'documentSeries',
            className: 'text-center',
          },
          {
            Header: 'Total de Ventas',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={generalIncomeSummaryList}
        defaultPageSize={generalIncomeSummaryList.length}
        showPagination={false}
      />
      <Table
        columns={[
          {
            Header: 'Método de Pago',
            accessor: 'moneyFlowConcept',
            className: 'text-center',
          },
          {
            Header: 'Total',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={generalIncomePaymentMethodSummaryList}
        defaultPageSize={generalIncomePaymentMethodSummaryList.length}
        showPagination={false}
      />
      <Table
        columns={[
          {
            Header: 'Tipo de Ingreso',
            accessor: 'moneyFlowConcept',
            className: 'text-center',
          },
          {
            Header: 'Total',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={generalIncomeTypeSummaryList}
        defaultPageSize={generalIncomeTypeSummaryList.length}
        showPagination={false}
      />
    </div>
  ) : null;

  let otherIncomes = 0;
  generalIncomePaymentMethodSummaryList.forEach((row) => {
    const foundCash = cashPaymentMethodList.find(
      (paymentMethod) => row.moneyFlowConcept === paymentMethod.name,
    );
    if (foundCash) otherIncomes += row.totalAmount;
  });

  const iconType = show ? 'minus' : 'plus';

  const icon = generalIncomeSummaryList.length > 0 && (
    <i
      className={`fa fa-${iconType}-square-o text-primary`}
      tabIndex="0"
      role="button"
      onKeyPress={() => setShow(!show)}
      onClick={() => setShow(!show)}
    />
  );

  return (
    <>
      <div className="mb-2">
        <strong>Otros Ingresos</strong>:{' '}
        {numberFormatter({ value: otherIncomes })} {icon}
      </div>
      {content}
    </>
  );
};

LiquidationGeneralIncomeDetailResource.propTypes = {
  generalIncomeSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      documentSeries: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  generalIncomeTypeSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  generalIncomePaymentMethodSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  cashPaymentMethodList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

LiquidationGeneralIncomeDetailResource.defaultProps = {
  generalIncomeSummaryList: [],
  generalIncomeTypeSummaryList: [],
  generalIncomePaymentMethodSummaryList: [],
};

export default LiquidationGeneralIncomeDetailResource;
