import Immutable from 'immutable';
import {
  DOWNLOAD_CALL_CENTER_REPORT,
  FLAG_POSTING_CALL_CENTER_REPORT,
  POST_CALL_CENTER_REPORT,
  SHOW_ERROR_POSTING_CALL_CENTER_REPORT,
  CLEAR_ERROR_POSTING_CALL_CENTER_REPORT,
  GET_CALL_CENTER_REPORTS,
  FLAG_GETTING_CALL_CENTER_REPORTS,
  CLEAR_CALL_CENTER_REPORTS,
  SHOW_ERROR_GETTING_CALL_CENTER_REPORTS,
  CLEAR_ERROR_GETTING_CALL_CENTER_REPORTS,
  GET_CALL_CENTER_REPORT,
  FLAG_GETTING_CALL_CENTER_REPORT,
  CLEAR_CALL_CENTER_REPORT,
  CLEAR_ERROR_GETTING_CALL_CENTER_REPORT,
  SHOW_ERROR_GETTING_CALL_CENTER_REPORT,
} from '../../../actions/types';
import downloadCsv from '../../../utils/download-csv';

const INITIAL_STATE = Immutable.Map({
  form: Immutable.Map({
    error: null,
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    // downloading
    case DOWNLOAD_CALL_CENTER_REPORT:
      downloadCsv(payload, 'discount-code-report.csv');
      return state;

    // posting
    case FLAG_POSTING_CALL_CENTER_REPORT:
      return state.setIn(['form', 'loading'], payload);
    case POST_CALL_CENTER_REPORT:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case SHOW_ERROR_POSTING_CALL_CENTER_REPORT:
      return state.setIn(['form', 'error'], payload);
    case CLEAR_ERROR_POSTING_CALL_CENTER_REPORT:
      return state.deleteIn(['form', 'error']);

    // getting all
    case GET_CALL_CENTER_REPORTS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case FLAG_GETTING_CALL_CENTER_REPORTS:
      return state.setIn(['all', 'loading'], payload);
    case CLEAR_CALL_CENTER_REPORTS:
      return state.deleteIn(['all', 'content']);
    case SHOW_ERROR_GETTING_CALL_CENTER_REPORTS:
      return state.setIn(['all', 'error'], payload);
    case CLEAR_ERROR_GETTING_CALL_CENTER_REPORTS:
      return state.deleteIn(['all', 'error']);

    // getting one
    case GET_CALL_CENTER_REPORT:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case FLAG_GETTING_CALL_CENTER_REPORT:
      return state.setIn(['current', 'loading'], payload);
    case CLEAR_CALL_CENTER_REPORT:
      return state.deleteIn(['current', 'content']);
    case CLEAR_ERROR_GETTING_CALL_CENTER_REPORT:
      return state.deleteIn(['current', 'error']);
    case SHOW_ERROR_GETTING_CALL_CENTER_REPORT:
      return state.setIn(['current', 'error'], payload);

    default:
      return state;
  }
};
