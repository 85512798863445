import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  generateGetItineraryQualityEndpoint,
  ITINERARY_QUALITY_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  FLAG_ITINERARY_QUALITY_ACTIVITY,
  GET_ITINERARY_QUALITY,
  CLEAR_ITINERARY_QUALITY,
} from '../types/itinerary/ItineraryQuality';
import { ITINERARY_FOR_COUNTER_PATH } from '../../config/paths';

const flagItineraryQualityActivity = flag => dispatch =>
  dispatch({
    type: FLAG_ITINERARY_QUALITY_ACTIVITY,
    payload: flag,
  });

const getItineraryQuality = async ({ itineraryId }) => async dispatch => {
  try {
    dispatch(flagItineraryQualityActivity(true));
    const url = generateGetItineraryQualityEndpoint(itineraryId);
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const itineraryQuality = await response.json();
    dispatch({
      type: GET_ITINERARY_QUALITY,
      payload: itineraryQuality,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItineraryQualityActivity(false));
  }
};

const clearItineraryQuality = () => dispatch =>
  dispatch({
    type: CLEAR_ITINERARY_QUALITY,
  });

const postItineraryQuality = async ({
  itineraryId,
  comments,
  delayReasonList,
}) => async dispatch => {
  try {
    dispatch(flagItineraryQualityActivity(true));
    const payload = {
      itineraryId,
      comments,
      delayReasonList,
    };

    const url = ITINERARY_QUALITY_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const itineraryQuality = await response.json();
    dispatch(
      push(`${ITINERARY_FOR_COUNTER_PATH}/${itineraryQuality.itineraryId}`),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItineraryQualityActivity(false));
  }
};

const putItineraryQuality = async (
  itineraryQualityId,
  { itineraryId, comments, delayReasonList },
) => async dispatch => {
  try {
    dispatch(flagItineraryQualityActivity(true));
    const payload = {
      id: itineraryQualityId,
      itineraryId,
      comments,
      delayReasonList,
    };

    const url = `${ITINERARY_QUALITY_ENDPOINT}/${itineraryQualityId}`;
    const response = await fetch(url, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const itineraryQuality = await response.json();
    dispatch(
      push(`${ITINERARY_FOR_COUNTER_PATH}/${itineraryQuality.itineraryId}`),
    );
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagItineraryQualityActivity(false));
  }
};

export {
  flagItineraryQualityActivity,
  getItineraryQuality,
  clearItineraryQuality,
  postItineraryQuality,
  putItineraryQuality,
};
