import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import {
  Button,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { inputPropTypes, metaPropTypes } from './InputPropTypes';
import Modal from '../../modal/Modal';
import ManualVouchers from '../../../units/accounting/manual-voucher/ManualVouchers';
import { numberFormatter } from '../../../../utils/number';
import { padStart } from '../../../../utils/string';

export class ManualVoucherSearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      manualVoucher: null,
    };
  }

  getRowData = (data) => {
    const {
      dispatchChange,
      form,
      onSelectRow,
      input: { name },
    } = this.props;

    const {
      id,
      documentSeries,
      documentCode,
      totalAmountPayable,
      currencyCode,
    } = data;

    const manualVoucherName = `${id} - ${documentSeries}-${padStart(
      documentCode,
      7,
    )} - ${numberFormatter({
      style: 'currency',
      value: totalAmountPayable,
      currency: currencyCode,
    })}`;

    dispatchChange(form, name, manualVoucherName);

    this.setState({ manualVoucher: manualVoucherName });

    if (onSelectRow) onSelectRow(data);

    this.closeModal();
  };

  showModal = () => {
    const { onShowModal } = this.props;
    if (onShowModal) onShowModal();
    this.setState({ showModal: true });
  };

  closeModal = () => this.setState({ showModal: false });

  clearField = () => {
    const {
      dispatchChange,
      form,
      input: { name },
      onClear,
    } = this.props;

    dispatchChange(form, name, null);

    this.setState({ manualVoucher: null });

    if (onClear) onClear();
  };

  render() {
    const {
      input: { value, name, onChange },
      placeholder,
      meta: { touched, error, warning },
      disabled,
    } = this.props;

    const buttons = (
      <InputGroupAddon addonType="append">
        <Button
          type="button"
          outline
          title={`Buscar ${placeholder}`}
          onClick={this.showModal}
          style={{ zIndex: 0 }}
        >
          <i className="fa fa-search" /> Buscar
        </Button>
        <Button
          type="button"
          title="Limpiar"
          outline
          onClick={this.clearField}
          style={{ zIndex: 0 }}
          disabled={!this.state.manualVoucher}
        >
          Eliminar
        </Button>
      </InputGroupAddon>
    );

    const modal = (
      <Modal
        show={this.state.showModal}
        title={`Búsqueda ${placeholder}`}
        body={<ManualVouchers getRowData={this.getRowData} showOnModal />}
        onClickClose={this.closeModal}
        size="xl"
      />
    );

    return (
      <div>
        {modal}
        <InputGroup>
          <Input
            disabled={disabled}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            type="text"
          />
          {buttons}
        </InputGroup>
        <FormText color="danger">
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </FormText>
      </div>
    );
  }
}

ManualVoucherSearchInput.propTypes = {
  input: inputPropTypes.isRequired,
  placeholder: PropTypes.string,
  meta: metaPropTypes.isRequired,
  disabled: PropTypes.bool,
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  onSelectRow: PropTypes.func,
  onShowModal: PropTypes.func,
  onClear: PropTypes.func,
};

ManualVoucherSearchInput.defaultProps = {
  placeholder: '',
  disabled: true,
  onSelectRow: null,
  onShowModal: null,
  onClear: null,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

export default connect(null, mapDispatchToProps)(ManualVoucherSearchInput);
