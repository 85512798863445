import { number, string } from 'prop-types';

const serviceTypeBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string,
};

const serviceTypeBasicInformationDefaultProps = {
  description: null,
};

export {
  serviceTypeBasicInformationPropTypes,
  serviceTypeBasicInformationDefaultProps,
};
