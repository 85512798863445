import React from 'react';
import { Col, Row } from 'reactstrap';
import ItinerarySearchResults from './itineraries/ItinerarySearchResults';
import ItinerarySearch from './search/ItinerarySearch';
import SeatSelection from './seats/SeatSelection';
import SelectedSeats from './seats/SelectedSeats';

const RoundTrip = () => (
  <div>
    <ItinerarySearch />
    <Row>
      <Col lg={6} md={12}>
        <ItinerarySearchResults />
      </Col>
      <Col lg={4} md={6}>
        <SeatSelection />
      </Col>
      <Col lg={2} md={6}>
        <SelectedSeats />
      </Col>
    </Row>
  </div>
);

export default RoundTrip;
