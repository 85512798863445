import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getCurrentActiveSalesSession,
  getSalesSessions,
  clearSalesSessions,
  getSalesSessionTickets,
  clearActiveSalesSession,
} from '../../../../actions/index';
import ModuleList from '../../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { SALES_SESSIONS_COLUMNS } from '../../../../config/columns';
import { SALES_SESSION_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class SalesSessions extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    salesSessions: PropTypes.instanceOf(Immutable.Map).isRequired,
    loadingSalesSessions: PropTypes.bool.isRequired,
    loadingCurrentSalesSession: PropTypes.bool.isRequired,
    dispatchClearSalesSessions: PropTypes.func.isRequired,
    dispatchGetSalesSessions: PropTypes.func.isRequired,
    dispatchClearActiveSalesSession: PropTypes.func.isRequired,
    dispatchGetCurrentActiveSalesSession: PropTypes.func.isRequired,
    activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = SALES_SESSIONS_COLUMNS;
    const { dispatchGetCurrentActiveSalesSession } = this.props;
    dispatchGetCurrentActiveSalesSession();
  }

  componentWillUnmount() {
    const { dispatchClearActiveSalesSession, dispatchClearSalesSessions } =
      this.props;
    dispatchClearSalesSessions();
    dispatchClearActiveSalesSession();
  }

  render() {
    const {
      salesSessions,
      loadingSalesSessions,
      activeSalesSession,
      loadingCurrentSalesSession,
      breadcrumbs,
      dispatchGetSalesSessions,
    } = this.props;

    if (loadingCurrentSalesSession) return <Loader />;

    const buttonText = activeSalesSession.isEmpty()
      ? 'Iniciar Sesión de Ventas'
      : 'Ver Sessión de Ventas Activa';
    const buttonPath = activeSalesSession.isEmpty()
      ? `${SALES_SESSION_PATH}/new`
      : `${SALES_SESSION_PATH}/${activeSalesSession.get('id')}`;
    const data = salesSessions.get('content') || [];
    const pages = salesSessions.get('totalPages') || null;
    const defaultPageSize = salesSessions.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Sesiones de Venta"
        breadcrumbs={breadcrumbs}
        buttonText={buttonText}
        buttonPath={buttonPath}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          modelPath: SALES_SESSION_PATH,
          filterable: true,
          fetchData: dispatchGetSalesSessions,
          loading: loadingSalesSessions,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Sesiones de Venta',
      href: '',
    },
  ],
  salesSessions: SalesUnit.SalesSession.getIn(['all', 'content']),
  loadingSalesSessions: SalesUnit.SalesSession.getIn(['all', 'loading']),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  loadingCurrentSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetSalesSessions: getSalesSessions,
  dispatchClearSalesSessions: clearSalesSessions,
  dispatchGetSalesSessionTickets: getSalesSessionTickets,
  dispatchGetCurrentActiveSalesSession: getCurrentActiveSalesSession,
  dispatchClearActiveSalesSession: clearActiveSalesSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesSessions);
