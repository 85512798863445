import Immutable from 'immutable';
import {
  CLEAR_ERROR_POSTING_ITINERARY_REPORT,
  DOWNLOAD_ITINERARY_REPORT,
  FLAG_POSTING_ITINERARY_REPORT,
  POST_ITINERARY_REPORT,
  SHOW_ERROR_POSTING_ITINERARY_REPORT,
  GET_ITINERARY_REPORTS,
  GET_ITINERARY_REPORT,
  CLEAR_ITINERARY_REPORTS,
  CLEAR_ITINERARY_REPORT,
  FLAG_GETTING_ITINERARY_REPORTS,
  FLAG_GETTING_ITINERARY_REPORT,
  CLEAR_ERROR_GETTING_ITINERARY_REPORT,
  SHOW_ERROR_GETTING_ITINERARY_REPORT,
  SHOW_ERROR_GETTING_ITINERARY_REPORTS,
  CLEAR_ERROR_GETTING_ITINERARY_REPORTS,
} from '../../../actions/types';
import downloadCsv from '../../../utils/download-csv';

const INITIAL_STATE = Immutable.Map({
  form: Immutable.Map({
    error: null,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
  loading: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_POSTING_ITINERARY_REPORT:
      return state.set('loading', action.payload);
    case DOWNLOAD_ITINERARY_REPORT:
      downloadCsv(action.payload, 'itinerary-report.csv');
      return state;
    case POST_ITINERARY_REPORT:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case SHOW_ERROR_POSTING_ITINERARY_REPORT:
      return state.setIn(['form', 'error'], action.payload);
    case CLEAR_ERROR_POSTING_ITINERARY_REPORT:
      return state.deleteIn(['form', 'error']);
    case GET_ITINERARY_REPORTS:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case GET_ITINERARY_REPORT:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case FLAG_GETTING_ITINERARY_REPORTS:
      return state.setIn(['all', 'loading'], action.payload);
    case FLAG_GETTING_ITINERARY_REPORT:
      return state.setIn(['current', 'loading'], action.payload);
    case CLEAR_ITINERARY_REPORTS:
      return state.deleteIn(['all', 'content']);
    case CLEAR_ITINERARY_REPORT:
      return state.deleteIn(['current', 'content']);
    case CLEAR_ERROR_GETTING_ITINERARY_REPORT:
      return state.deleteIn(['current', 'error']);
    case SHOW_ERROR_GETTING_ITINERARY_REPORT:
      return state.setIn(['current', 'error'], action.payload);
    case SHOW_ERROR_GETTING_ITINERARY_REPORTS:
      return state.setIn(['all', 'error'], action.payload);
    case CLEAR_ERROR_GETTING_ITINERARY_REPORTS:
      return state.deleteIn(['all', 'error']);
    default:
      return state;
  }
};
