import React from 'react';
import AddressResource from '../../../../common/resource/AddressResource';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import CustomerContactInformationResource from '../../customer/resource/CustomerContactInformationResource';
import {
  customerContactInformationDefaultProps,
  customerContactInformationPropTypes,
} from '../../customer/proptypes/CustomerPropTypes';

const UserContactInformationResource = (customer) => (
  <>
    <CustomerContactInformationResource {...customer} />
    <ResourceProperty label="Dirección:">
      <AddressResource address={customer.address} />
    </ResourceProperty>
  </>
);

UserContactInformationResource.propTypes = customerContactInformationPropTypes;

UserContactInformationResource.defaultProps =
  customerContactInformationDefaultProps;

export default UserContactInformationResource;
