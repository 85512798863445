import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '../../../../common/Table';
import CollapsibleHeader from '../../../../layout/CollapsibleHeader';
import DateCell from '../../../../common/cells/DateCell';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import { SALES_SESSION_PATH } from '../../../../../config/paths';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';

const LiquidationSalesSessionsResource = ({ salesSessions }) => {
  if (!salesSessions.length) return null;

  const salesSessionList = () => (
    <Table
      columns={[
        {
          Header: 'ID',
          accessor: 'id',
          className: 'text-center',
        },
        {
          Header: 'Usuario',
          accessor: 'user',
          className: 'text-center',
        },
        {
          Header: 'Agencia',
          accessor: 'agency',
          className: 'text-center',
        },
        {
          Header: 'Caja',
          accessor: 'workstation',
          className: 'text-center',
        },
        {
          Header: 'Inicio',
          accessor: 'startDate',
          Cell: (row) => (
            <DateCell format={DATE_TIME_FORMAT} value={row.value} />
          ),
          className: 'text-center',
        },
        {
          Header: 'Fin',
          accessor: 'endDate',
          Cell: (row) => (
            <DateCell format={DATE_TIME_FORMAT} value={row.value} />
          ),
          className: 'text-center',
        },
      ]}
      data={salesSessions}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
      navigateToModelOnRowClick
      openPathInNewTab
      manual={false}
      modelPath={SALES_SESSION_PATH}
    />
  );

  return (
    <>
      <hr />
      <CollapsibleHeader
        headerText="Sesiones de Venta"
        component={salesSessionList}
      />
    </>
  );
};

LiquidationSalesSessionsResource.propTypes = {
  salesSessions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.string,
      agency: PropTypes.string,
      workstation: PropTypes.string,
      startDate: PropTypes.number,
      endDate: PropTypes.number,
    }),
  ),
};

LiquidationSalesSessionsResource.defaultProps = {
  salesSessions: [],
};

export default LiquidationSalesSessionsResource;
