import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PRINT_STATION_PATH } from '../../../../config/paths';

const PrintStationEditButton = ({ printStationId }) => (
  <Link
    className="btn btn-primary"
    to={`${PRINT_STATION_PATH}/${printStationId}/edit`}
  >
    Editar
  </Link>
);

PrintStationEditButton.propTypes = {
  printStationId: PropTypes.number.isRequired,
};

export default PrintStationEditButton;
