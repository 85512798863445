import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  privateServicePayPerDriverDefaultProps,
  privateServicePayPerDriverPropTypes,
} from '../proptypes/PrivateServicePropTypes';
import { numberFormatter } from '../../../../../utils/number';

const PayPerDriverServiceInformationResource = ({
  driverCommission,
  driverDailyPayment,
  driverPaymentForFood,
  driverPaymentForAccommodation,
}) => (
  <Fragment>
    <h3>Pagos por Servicio al Conductor</h3>
    <ResourceProperty label="Condiciones de Trabajo por Servicio:">
      {driverCommission ? numberFormatter({ value: driverCommission }) : '-'}
    </ResourceProperty>
    <ResourceProperty label="Condiciones de Trabajo por Día de Retén:">
      {driverDailyPayment
        ? numberFormatter({ value: driverDailyPayment })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Alimentación:">
      {driverPaymentForFood
        ? numberFormatter({ value: driverPaymentForFood })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Hospedaje:">
      {driverPaymentForAccommodation
        ? numberFormatter({ value: driverPaymentForAccommodation })
        : '-'}
    </ResourceProperty>
  </Fragment>
);

PayPerDriverServiceInformationResource.propTypes =
  privateServicePayPerDriverPropTypes;

PayPerDriverServiceInformationResource.defaultProps =
  privateServicePayPerDriverDefaultProps;

export default PayPerDriverServiceInformationResource;
