import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getSalesSessionIncome,
  clearGeneralIncomes,
} from '../../../../actions';
import { SALES_SESSION_INCOME_COLUMNS } from '../../../../config/columns';
import { GENERAL_INCOME_PATH } from '../../../../config/paths';
import Table from '../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';

class IncomeTable extends Component {
  static propTypes = {
    salesSessionId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    dispatchGetSalesSessionIncome: PropTypes.func.isRequired,
    dispatchClearGeneralIncomes: PropTypes.func.isRequired,
    salesSessionIncome: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        documentSeries: PropTypes.string.isRequired,
        documentCode: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
      }),
    ),
  };

  static defaultProps = {
    salesSessionIncome: undefined,
  };

  constructor(props) {
    super(props);
    const { salesSessionId, dispatchGetSalesSessionIncome } = this.props;
    dispatchGetSalesSessionIncome({ salesSessionId });
  }

  componentWillUnmount() {
    const { dispatchClearGeneralIncomes } = this.props;
    dispatchClearGeneralIncomes();
  }

  render() {
    const { salesSessionIncome, loading } = this.props;
    return (
      <>
        <Table
          manual={false}
          columns={SALES_SESSION_INCOME_COLUMNS}
          data={salesSessionIncome}
          filterable
          defaultFilterMethod={filterMethodCaseInsensitive}
          loading={loading}
          navigateToModelOnRowClick
          modelPath={GENERAL_INCOME_PATH}
          isExternalPath
          openPathInNewTab
        />
        <br />
      </>
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => {
  const salesSessionIncome = SalesUnit.GeneralIncome.getIn([
    'all',
    'content',
    'content',
  ]);
  const loading = SalesUnit.GeneralIncome.getIn(['all', 'loading']);
  return {
    loading,
    salesSessionIncome,
  };
};

const mapDispatchToProps = {
  dispatchGetSalesSessionIncome: getSalesSessionIncome,
  dispatchClearGeneralIncomes: clearGeneralIncomes,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeTable);
