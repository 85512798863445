import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import {
  isRequired,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import OrganizationChartSelect from '../../../common/forms/select/OrganizationChartSelect';
import ActivitySelect from '../../../common/forms/select/ActivitySelect';

export const ActivityTaskForm = ({ handleSubmit, loading }) => {
  const [ordered, setOrdered] = useState(false);

  if (loading) return <Loader />;

  const onChangeOrder = (option) => setOrdered(option.target.checked);

  const orderField = ordered ? (
    <FormGroup row>
      <FormItem label="Orden" required>
        <Field
          name="order"
          component={TextInput}
          placeholder="Orden"
          validate={[isRequired, validateIntegerNumber]}
        />
      </FormItem>
    </FormGroup>
  ) : null;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Descripción" required>
          <Field
            name="description"
            component={TextInput}
            placeholder="Descripción"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Horas Trabajadas" required>
          <Field
            name="workingHours"
            component={TextInput}
            placeholder="Horas Trabajadas"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Organigrama" required>
          <Field
            name="companyAreaId"
            component={OrganizationChartSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Actividad" required>
          <Field
            name="activityId"
            component={ActivitySelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem>
          <Label>
            <Field
              name="orderDependent"
              component="input"
              type="checkbox"
              onChange={onChangeOrder}
            />{' '}
            Tarea ordenada
          </Label>
        </FormItem>
      </FormGroup>
      {orderField}

      <FormFooter />
    </Form>
  );
};

ActivityTaskForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.Material.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'ActivityTaskForm',
})(ActivityTaskForm);

export default connect(mapStateToProps)(formComponent);
