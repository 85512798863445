import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { SEGMENT_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import SegmentForm from './SegmentForm';
import { postSegment } from '../../../../actions';
import Content from '../../../layout/Content';

export class NewSegment extends Component {
  onSubmit = async (formValues) => {
    const { dispatchPostSegment, dispatchPush } = this.props;

    const newFormValues = { ...formValues };

    newFormValues.sourceLocationId = formValues.sourceLocationId.value;
    newFormValues.destinationLocationId =
      formValues.destinationLocationId.value;
    if (formValues.tollList)
      newFormValues.tollSegmentList = formValues.tollList.map(({ toll }) => ({
        tollId: toll.value,
      }));

    const response = await dispatchPostSegment(newFormValues);

    if (response) dispatchPush(SEGMENT_PATH);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Segmento"
        subtitle="Crear un nuevo segmento"
        content={<SegmentForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Segmentos',
      href: SEGMENT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostSegment: postSegment,
  dispatchPush: push,
};

NewSegment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostSegment: PropTypes.func.isRequired,
  dispatchPush: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSegment);
