import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditFuelVoucherButton from './EditFuelVoucherButton';

const FuelVoucherToolbar = ({ fuelVoucherId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditFuelVoucherButton fuelVoucherId={fuelVoucherId} />
    </div>
  </ButtonToolbar>
);

FuelVoucherToolbar.propTypes = {
  fuelVoucherId: PropTypes.number.isRequired,
};

export default FuelVoucherToolbar;
