import { shape, number } from 'prop-types';
import { paymentMethodBasicInformationPropTypes } from '../../accounting/payment-method/proptypes/PaymentMethodPropTypes';
import { voucherTypeBasicInformationPropTypes } from '../../accounting/voucher-type/proptypes/VoucherTypePropTypes';

const transactionPropTypes = {
  amount: number.isRequired,
  createDate: number.isRequired,
  voucherType: shape(voucherTypeBasicInformationPropTypes),
  paymentMethod: shape(paymentMethodBasicInformationPropTypes),
};

const transactionDefaultProps = {
  voucherType: null,
  paymentMethod: null,
};

export { transactionPropTypes, transactionDefaultProps };
