import React from 'react';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FUEL_VOUCHER_PATH } from '../../../../../config/paths';

const NewFuelVoucherButton = () => (
  <ButtonToolbar className="pull-right">
    <ButtonGroup>
      <Link
        target="_blank"
        className="btn btn-outline-secondary"
        to={FUEL_VOUCHER_PATH}
      >
        Registrar Vale de Combustible
      </Link>
    </ButtonGroup>
  </ButtonToolbar>
);

export default NewFuelVoucherButton;
