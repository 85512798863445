import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import TableToolbar from '../../../common/TableToolbar';
import {
  NEW_CALL_CENTER_REPORT_PATH,
  CALL_CENTER_REPORTS_PATH,
} from '../../../../config/paths';
import Table from '../../../common/Table';
import {
  clearCallCenterReports,
  getCallCenterReports,
  clearErrorGettingCallCenterReports,
} from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import { REPORT_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

class CallCenterReports extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes.isRequired,
    getCallCenterReports: PropTypes.func.isRequired,
    clearCallCenterReports: PropTypes.func.isRequired,
    clearErrorGettingCallCenterReports: PropTypes.func.isRequired,
    reports: PropTypes.instanceOf(Immutable.Map),
    loading: PropTypes.bool,
    error: PropTypes.string,
  };

  static defaultProps = {
    reports: null,
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.columns = REPORT_COLUMNS;
  }

  componentWillUnmount() {
    this.props.clearErrorGettingCallCenterReports();
    this.props.clearCallCenterReports();
  }

  renderContent() {
    const { reports, loading, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    const { columns } = this;

    if (reports) {
      data = reports.get('content');
      pages = reports.get('totalPages');
      defaultPageSize = reports.get('size');
    }

    return (
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={this.props.getCallCenterReports}
        modelPath={CALL_CENTER_REPORTS_PATH}
        loading={loading}
        navigateToModelOnRowClick
      />
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <TableToolbar buttonPath={NEW_CALL_CENTER_REPORT_PATH} />
        <h1>Reporte Call Center</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Call Center',
      href: CALL_CENTER_REPORTS_PATH,
    },
  ],
  reports: ReportUnit.CallCenterReport.get('all').get('content'),
  loading: ReportUnit.CallCenterReport.get('all').get('loading'),
  error: ReportUnit.CallCenterReport.get('all').get('error'),
});

const mapDispatchToProps = {
  getCallCenterReports,
  clearCallCenterReports,
  clearErrorGettingCallCenterReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallCenterReports);
