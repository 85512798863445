import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generateNewTripPath } from '../../../../../config/paths';
import Checkbox from '../../../../common/forms/Checkbox';

const ItinerarySchedulingButton = ({ itineraryId }) => (
  <Link
    className="btn btn-light border"
    to={generateNewTripPath({ id: itineraryId })}
  >
    <Checkbox checked /> Programar
  </Link>
);

ItinerarySchedulingButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default ItinerarySchedulingButton;
