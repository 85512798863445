import { validateReportFormDateRange } from '../../../../utils/validators';

const validate = values => {
  const errors = {};
  if (values.fromDate && values.toDate) {
    const dateRangeError = validateReportFormDateRange(
      values.fromDate,
      values.toDate,
    );
    if (dateRangeError) {
      errors.toDate = dateRangeError;
    }
  }
  return errors;
};

export default validate;
