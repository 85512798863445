import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  NEW_BASE_FUEL_REFILL_PATH,
  BASE_FUEL_REFILL_PATH,
} from '../../../../config/paths';
import {
  getBaseFuelRefills,
  clearBaseFuelRefills,
} from '../../../../actions/traffic';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { BASE_FUEL_REFILL_COLUMNS } from '../../../../config/columns';

export const BaseFuelRefills = ({
  breadcrumbs,
  refills,
  loading,
  dispatchGetBaseFuelRefills,
  dispatchClearBaseFuelRefills,
}) => {
  useLayoutEffect(() => () => dispatchClearBaseFuelRefills(), []);

  const data = refills.get('content') || [];
  const pages = refills.get('totalPages') || null;
  const defaultPageSize = refills.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Rellenos de Combustible en Base"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_BASE_FUEL_REFILL_PATH}
      tableStructure={{
        columns: BASE_FUEL_REFILL_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetBaseFuelRefills,
        modelPath: BASE_FUEL_REFILL_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetBaseFuelRefills: getBaseFuelRefills,
  dispatchClearBaseFuelRefills: clearBaseFuelRefills,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos de Combustible en Base',
      href: BASE_FUEL_REFILL_PATH,
    },
  ],
  refills: TrafficUnit.BaseFuelRefill.getIn(['all', 'content']),
  loading: TrafficUnit.BaseFuelRefill.getIn(['all', 'loading']),
});

BaseFuelRefills.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  refills: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetBaseFuelRefills: PropTypes.func.isRequired,
  dispatchClearBaseFuelRefills: PropTypes.func.isRequired,
};

BaseFuelRefills.defaultProps = {
  refills: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseFuelRefills);
