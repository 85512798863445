import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../layout/Card';
import ModuleHome from '../../layout/ModuleHome';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

const AccountingHome = ({ cards, breadcrumbs }) => (
  <ModuleHome
    title="Contabilidad"
    text="Éste es el módulo de Contabilidad."
    cards={cards}
    breadcrumbs={breadcrumbs}
  />
);

const mapStateToProps = ({ AccountingUnit }) => ({
  cards: AccountingUnit.UnitHome.get('cards'),
  breadcrumbs: AccountingUnit.UnitHome.get('breadcrumbs'),
});

AccountingHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default connect(mapStateToProps)(AccountingHome);
