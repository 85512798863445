import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import { PRINT_STATION_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import FormFooter from '../../../common/forms/FormFooter';

const PrintStationForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Número de Serie" required>
        <Field
          name="serialNumber"
          component={TextInput}
          placeholder="Número de Serie"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Nombre" required>
        <Field
          name="name"
          component={TextInput}
          placeholder="Nombre"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Descripción">
        <Field
          name="description"
          component={TextInput}
          type="textarea"
          placeholder="Descripción"
        />
      </FormItem>
    </FormGroup>
    <h3>Plantillas de Impresión</h3>
    <DynamicForm
      name="printTemplateList"
      columns={PRINT_STATION_FORM_COLUMNS}
    />
    <FormFooter />
  </Form>
);

PrintStationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'PrintStationForm',
})(PrintStationForm);

export default formComponent;
