import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { tzNormalizeDate } from '../../../../utils/date';
import { busFuelReportRouteRowListPropTypes } from './FuelConsumptionReportPerBusPropTypes';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../config/locale';

const BusFuelReportRouteRowListResource = ({ data }) => {
  if (!data) return null;

  const dataSorted = data.sort((a, b) => a.departureTime - b.departureTime);

  let driverCount = 0;
  dataSorted.forEach((row) => {
    if (driverCount < row.driverSet.length) driverCount = row.driverSet.length;
  });

  const driverColumns = [];
  for (let i = 0; i < driverCount; i += 1) {
    driverColumns.push(
      <th key={`header-${+i}`} className="border-black">
        Conductor {+i + 1}
      </th>,
    );
  }

  const tbody = dataSorted.map((row, index) => {
    const drivers = [];
    for (let i = 0; i < driverCount; i += 1) {
      drivers.push(
        <td className="border-right-black" key={`body-${+index}-${+i}`}>
          {row.driverSet[i] ? row.driverSet[i].customer.fullName : '-'}
        </td>,
      );
    }

    return (
      <tr key={+index}>
        <td className="border-right-black">{+index + 1}</td>
        <td className="border-right-black">{row.companyBusId}</td>
        <td className="border-right-black">
          {tzNormalizeDate({ date: row.departureTime, format: DATE_FORMAT })}
        </td>
        <td className="border-right-black">{row.sourceLocationName}</td>
        <td className="border-right-black">
          {tzNormalizeDate({ date: row.departureTime, format: TIME_FORMAT })}
        </td>
        <td className="border-right-black">{row.destinationLocationName}</td>
        <td className="border-right-black">{row.circuitName}</td>
        {drivers}
        <td className="border-right-black">{row.kilometers}</td>
        <td className="border-right-black">{row.expectedFuelConsumption}</td>
        <td className="border-right-black">{row.realFuelConsumption}</td>
        <td className="border-right-black">{row.fuelVoucherRefillGallon}</td>
        <td className="border-right-black">{row.fuelVoucherCodes}</td>
        <td className="border-right-black">{row.baseFuelRefillGallon}</td>
        <td className="border-right-black">{row.realAdblueConsumption}</td>
      </tr>
    );
  });

  return (
    <Row>
      <Col>
        <Table
          striped
          responsive
          role="grid"
          className="border-black text-center"
        >
          <thead>
            <tr>
              <th
                colSpan={15 + driverColumns.length}
                className="title border-black"
              >
                COMBUSTIBLE - REPORTE CONTROL BUS (Detalle Itinerarios)
              </th>
            </tr>
            <tr className="shadow">
              <th className="border-black">&nbsp;</th>
              <th className="border-black"># Bus</th>
              <th className="border-black">Fecha</th>
              <th className="border-black">Origen</th>
              <th className="border-black">Hora Origen</th>
              <th className="border-black">Destino</th>
              <th className="border-black">Nombre Circuito</th>
              {driverColumns}
              <th className="border-black">Km</th>
              <th className="border-black">Dotación Aut.</th>
              <th className="border-black">Consumo Calculado</th>
              <th className="border-black">Vale(s) Combustible</th>
              <th className="border-black">Cod. Vale(s) Combustible</th>
              <th className="border-black">Abastecimiento en Base (gal)</th>
              <th className="border-black">AdBlue</th>
            </tr>
          </thead>
          <tbody>{tbody}</tbody>
        </Table>
      </Col>
    </Row>
  );
};

BusFuelReportRouteRowListResource.propTypes = {
  data: busFuelReportRouteRowListPropTypes,
};

BusFuelReportRouteRowListResource.defaultProps = {
  data: null,
};

export default BusFuelReportRouteRowListResource;
