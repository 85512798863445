export const FLAG_GETTING_PAYMENT_METHODS_FOR_SECURITY_PROFILES =
  'FLAG_GETTING_PAYMENT_METHODS_FOR_SECURITY_PROFILES';
export const GET_PAYMENT_METHODS_FOR_SECURITY_PROFILES =
  'GET_PAYMENT_METHODS_FOR_SECURITY_PROFILES';
export const CLEAR_PAYMENT_METHODS_FOR_SECURITY_PROFILES =
  'CLEAR_PAYMENT_METHODS_FOR_SECURITY_PROFILES';
export const FLAG_PAYMENT_METHOD_FOR_SECURITY_PROFILE_ACTIVITY =
  'FLAG_PAYMENT_METHOD_FOR_SECURITY_PROFILE_ACTIVITY';
export const GET_PAYMENT_METHOD_FOR_SECURITY_PROFILE =
  'GET_PAYMENT_METHOD_FOR_SECURITY_PROFILE';
export const CLEAR_PAYMENT_METHOD_FOR_SECURITY_PROFILE =
  'CLEAR_PAYMENT_METHOD_FOR_SECURITY_PROFILE';
