import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import DistrictForm from './DistrictForm';
import { DISTRICT_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { getDistrict, clearDistrict, putDistrict } from '../../../../actions';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditDistrict extends Component {
  componentDidMount() {
    const {
      dispatchGetDistrict,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDistrict({ id });
  }

  componentWillUnmount() {
    const { dispatchClearDistrict } = this.props;
    dispatchClearDistrict();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutDistrict,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutDistrict(id, {
      cityId: formValues.cityId.value,
      name: formValues.name,
      ubigeo: formValues.ubigeo,
    });
  };

  render() {
    const { breadcrumbs, district, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (district.isEmpty()) {
      content = <NoDataResource returnPage={DISTRICT_PATH} />;
    } else {
      const initialValues = {
        cityId: {
          label: district.get('city').name,
          value: district.get('city').id,
        },
        name: district.get('name'),
        ubigeo: district.get('ubigeo'),
      };

      content = (
        <DistrictForm onSubmit={this.onSubmit} initialValues={initialValues} />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Distrito"
        subtitle="Editar distrito"
        content={content}
      />
    );
  }
}

EditDistrict.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDistrict: PropTypes.func.isRequired,
  dispatchClearDistrict: PropTypes.func.isRequired,
  dispatchPutDistrict: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  district: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDistrict.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { LocationUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Distritos',
      href: DISTRICT_PATH,
    },
    {
      text: 'Ver',
      href: `${DISTRICT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  district: LocationUnit.District.getIn(['current', 'content']),
  loading: !LocationUnit.District.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDistrict: getDistrict,
  dispatchClearDistrict: clearDistrict,
  dispatchPutDistrict: putDistrict,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDistrict);
