import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { AGENCY_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import AddCreditLineForm from './AddCreditLineForm';
import Loader from '../../../common/Loader';
import {
  clearAgency,
  getAgency,
  patchAgencyCreditLine,
} from '../../../../actions';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';

export const EditAgency = ({
  match: {
    params: { id: agencyId },
  },
  dispatchGetAgency,
  breadcrumbs,
  agency,
  loading,
  dispatchPatchAgencyCreditLine,
  dispatchClearAgency,
}) => {
  useLayoutEffect(() => {
    dispatchGetAgency({ agencyId });

    return () => dispatchClearAgency();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPatchAgencyCreditLine({ agencyId, amount: formValues.amount });

  const generateInitialValues = (agencyJson) => ({
    name: agencyJson.name,
    initialBalance: agencyJson.initialBalance,
    actualBalance: agencyJson.actualBalance,
  });

  let content = null;

  if (loading) content = <Loader />;
  else if (agency.isEmpty())
    content = <NoDataResource returnPage={AGENCY_PATH} />;
  else
    content = (
      <AddCreditLineForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(agency.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Incrementar Saldo"
      subtitle="Incrementar saldo"
      content={content}
    />
  );
};

const mapStateToProps = (
  { HumanResourcesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Agencias',
      href: AGENCY_PATH,
    },
    {
      text: 'Ver',
      href: `${AGENCY_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  agency: HumanResourcesUnit.Agency.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.Agency.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetAgency: getAgency,
  dispatchClearAgency: clearAgency,
  dispatchPatchAgencyCreditLine: patchAgencyCreditLine,
};

EditAgency.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPatchAgencyCreditLine: PropTypes.func.isRequired,
  dispatchGetAgency: PropTypes.func.isRequired,
  dispatchClearAgency: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  agency: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditAgency.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAgency);
