import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import printMaintenanceServiceOrder from '../../../../utils/printers/MaintenanceServiceOrder';

const MaintenanceServiceOrderPrintButton = ({ maintenanceServiceOrder }) => {
  const handlePrint = () =>
    printMaintenanceServiceOrder(maintenanceServiceOrder.toJS());

  return (
    <Button color="light" className="border" onClick={handlePrint}>
      <i className="fa fa-print" /> Imprimir
    </Button>
  );
};

MaintenanceServiceOrderPrintButton.propTypes = {
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
});

const connectedComponent = connect(mapStateToProps)(
  MaintenanceServiceOrderPrintButton,
);

export default connectedComponent;
