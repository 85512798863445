import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAddresses, clearAddresses } from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_QUERY } from '../../../../config/queries';
import { addressesGenerator } from '../../../../utils/app/json-generator-from-reducer';

class AddressSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearAddresses } = this.props;
    dispatchClearAddresses();
  }

  handleAddressesChange = (inputValue) => {
    const { dispatchGetAddresses } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetAddresses({ ...DEFAULT_QUERY, query: inputValue });
    }
  };

  render() {
    const {
      options,
      loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
    } = this.props;

    const props = {
      options,
      onInputChange: this.handleAddressesChange,
      isLoading: loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
    };

    return <Select {...props} />;
  }
}

const mapStateToProps = ({ LocationUnit }) => ({
  options: addressesGenerator(
    LocationUnit.Address.getIn(['all', 'content', 'content']),
  ),
  loading: LocationUnit.Address.getIn(['all', 'loading']),
});

AddressSelect.propTypes = {
  options: optionsPropTypes,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetAddresses: PropTypes.func.isRequired,
  dispatchClearAddresses: PropTypes.func.isRequired,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
};

AddressSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  noOptionsMessage: undefined,
  isDisabled: false,
  isClearable: false,
  options: [],
};
const mapDispatchToProps = {
  dispatchGetAddresses: getAddresses,
  dispatchClearAddresses: clearAddresses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressSelect);
