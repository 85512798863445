// get all
export const FLAG_GETTING_CUSTOMERS = 'FLAG_GETTING_CUSTOMERS';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS';
// get one
export const FLAG_CUSTOMER_ACTIVITY = 'FLAG_CUSTOMER_ACTIVITY';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
// customer emails
export const FLAG_GETTING_CUSTOMER_EMAILS = 'FLAG_GETTING_CUSTOMER_EMAILS';
export const GET_CUSTOMER_EMAILS = 'GET_CUSTOMER_EMAILS';
export const CLEAR_CUSTOMER_EMAILS = 'CLEAR_CUSTOMER_EMAILS';
// customer deliveryAddresss
export const FLAG_GETTING_CUSTOMER_DELIVERY_ADDRESSES =
  'FLAG_GETTING_CUSTOMER_DELIVERY_ADRESSES';
export const GET_CUSTOMER_DELIVERY_ADDRESSES =
  'GET_CUSTOMER_DELIVERY_ADDRESSES';
export const CLEAR_CUSTOMER_DELIVERY_ADDRESSES =
  'CLEAR_CUSTOMER_DELIVERY_ADDRESSES';
