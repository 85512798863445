import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../../Badge';

const IsPerishable = ({ value }) => {
  if (value) return <Badge color="danger" text="Perecible" />;

  return <Badge color="success" text="No Perecible" />;
};

IsPerishable.propTypes = {
  value: PropTypes.bool,
};

IsPerishable.defaultProps = {
  value: false,
};

export default IsPerishable;
