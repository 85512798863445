import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  NEW_BASE_PRICE_PER_KILOMETER_PATH,
  BASE_PRICE_PER_KILOMETER_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { BASE_PRICE_PER_KILOMETER_COLUMNS } from '../../../../config/columns';
import {
  clearBasePricesPerKilometer,
  getBasePricesPerKilometer,
} from '../../../../actions/cargo/BasePricePerKilometer';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class BasePricesPerKilometer extends Component {
  constructor(props) {
    super(props);
    this.columns = BASE_PRICE_PER_KILOMETER_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearBasePricesPerKilometer();
  }

  render() {
    const {
      basePricesPerKilometer,
      loading,
      breadcrumbs,
      dispatchGetBasePricesPerKilometer,
    } = this.props;

    const data = basePricesPerKilometer.get('content') || [];
    const pages = basePricesPerKilometer.get('totalPages') || null;
    const defaultPageSize =
      basePricesPerKilometer.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Precios Base por Kilómetro"
        buttonPath={NEW_BASE_PRICE_PER_KILOMETER_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: false,
          fetchData: dispatchGetBasePricesPerKilometer,
          modelPath: BASE_PRICE_PER_KILOMETER_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetBasePricesPerKilometer: getBasePricesPerKilometer,
  dispatchClearBasePricesPerKilometer: clearBasePricesPerKilometer,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Precios Base por Kilómetro',
      href: BASE_PRICE_PER_KILOMETER_PATH,
    },
  ],
  basePricesPerKilometer: CargoUnit.BasePricePerKilometer.getIn([
    'all',
    'content',
  ]),
  loading: CargoUnit.BasePricePerKilometer.getIn(['all', 'loading']),
});

BasePricesPerKilometer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  basePricesPerKilometer: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetBasePricesPerKilometer: PropTypes.func.isRequired,
  dispatchClearBasePricesPerKilometer: PropTypes.func.isRequired,
};

BasePricesPerKilometer.defaultProps = {
  basePricesPerKilometer: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BasePricesPerKilometer);
