import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import BadgeList from '../../../../common/BadgeList';
import {
  KILOMETER_SYMBOL,
  MATERIAL_MEASUREMENT_UNIT,
} from '../../../../../config/constants';
import { numberFormatter } from '../../../../../utils/number';

const ItineraryFuelDetail = ({
  companyBusId,
  seatMapDisplayName,
  busBodywork,
  busChassis,
  busFuelGroupId,
  circuitName,
  kilometers,
  driverList,
  AdblueInBase,
  expectedAdblueConsumption,
  expectedFuelConsumption,
  fuelOnBase,
  fuelInFuelVoucher,
  calculatedRealFuelConsumption,
  extraFuelConsumption,
  extraAdblueConsumption,
}) => (
  <div className="m-3 mb-4 text-center">
    <Row>
      <Col>
        <h5>Bus</h5>
        <div>Número: {companyBusId}</div>
        <div>Mapa de Asiento: {seatMapDisplayName}</div>
        <div>
          Carrocería: {busBodywork.make} {busBodywork.model}
        </div>
        <div>
          Chasis: {busChassis.make} {busChassis.model}
        </div>
        <div>ID de Grupo de Combustible: {busFuelGroupId || '-'}</div>
      </Col>
      <Col className="border-left">
        <h5>Conductores</h5>
        <div className="mb-3">
          <BadgeList textArray={driverList} />
        </div>
        <h5>Circuito</h5>
        <div>Nombre: {circuitName}</div>
        <div>
          Kilometraje:{' '}
          <strong className="text-info">
            {numberFormatter({
              style: 'decimal',
              value: kilometers,
              minimumFractionDigits: 2,
            })}{' '}
            {KILOMETER_SYMBOL}
          </strong>
        </div>
      </Col>
      <Col className="border-left">
        <h5>Combustible</h5>
        <div>
          Dotación:{' '}
          <strong className="text-primary">
            {numberFormatter({
              style: 'decimal',
              value: expectedFuelConsumption,
              minimumFractionDigits: 2,
            })}{' '}
            {MATERIAL_MEASUREMENT_UNIT[1]}
          </strong>
        </div>
        <div>
          Consumo Real Calculado:{' '}
          <strong className="text-success">
            {numberFormatter({
              style: 'decimal',
              value: calculatedRealFuelConsumption,
              minimumFractionDigits: 2,
            })}{' '}
            {MATERIAL_MEASUREMENT_UNIT[1]}
          </strong>
        </div>
        <div>
          Consumo Extra:{' '}
          <strong className="text-primary">
            {numberFormatter({
              style: 'decimal',
              value: extraFuelConsumption,
              minimumFractionDigits: 2,
            })}{' '}
            {MATERIAL_MEASUREMENT_UNIT[1]}
          </strong>
        </div>
        <div>
          Vales:{' '}
          {numberFormatter({
            style: 'decimal',
            value: fuelInFuelVoucher,
            minimumFractionDigits: 2,
          })}{' '}
          {MATERIAL_MEASUREMENT_UNIT[1]}
        </div>
        <div>
          Abastecimiento en Base:{' '}
          {numberFormatter({
            style: 'decimal',
            value: fuelOnBase,
            minimumFractionDigits: 2,
          })}{' '}
          {MATERIAL_MEASUREMENT_UNIT[1]}
        </div>
      </Col>
      <Col className="border-left">
        <h5>AdBlue</h5>
        <div>
          Dotación:{' '}
          {numberFormatter({
            style: 'decimal',
            value: expectedAdblueConsumption,
            minimumFractionDigits: 2,
          })}{' '}
          {MATERIAL_MEASUREMENT_UNIT[1]}
        </div>
        <div>
          Consumo Real:{' '}
          {numberFormatter({
            style: 'decimal',
            value: AdblueInBase,
            minimumFractionDigits: 2,
          })}{' '}
          {MATERIAL_MEASUREMENT_UNIT[1]}
        </div>
        <div>
          Consumo Extra:{' '}
          {numberFormatter({
            style: 'decimal',
            value: extraAdblueConsumption,
            minimumFractionDigits: 2,
          })}{' '}
          {MATERIAL_MEASUREMENT_UNIT[1]}
        </div>
      </Col>
    </Row>
  </div>
);

ItineraryFuelDetail.propTypes = {
  companyBusId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  seatMapDisplayName: PropTypes.string.isRequired,
  busBodywork: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
  }).isRequired,
  busChassis: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
  }).isRequired,
  busFuelGroupId: PropTypes.number,
  circuitName: PropTypes.PropTypes.string.isRequired,
  kilometers: PropTypes.number.isRequired,
  driverList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ).isRequired,
  expectedFuelConsumption: PropTypes.number.isRequired,
  expectedAdblueConsumption: PropTypes.number.isRequired,
  fuelOnBase: PropTypes.number.isRequired,
  AdblueInBase: PropTypes.number.isRequired,
  fuelInFuelVoucher: PropTypes.number.isRequired,
  calculatedRealFuelConsumption: PropTypes.number.isRequired,
  extraFuelConsumption: PropTypes.number.isRequired,
  extraAdblueConsumption: PropTypes.number.isRequired,
};

ItineraryFuelDetail.defaultProps = { busFuelGroupId: null };

export default ItineraryFuelDetail;
