import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getCreditNote,
  clearCreditNote,
} from '../../../../actions/sales/CreditNote';
import Loader from '../../../common/Loader';
import {
  CREDIT_NOTE_PATH,
  GENERAL_INCOME_PATH,
} from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import CreditNoteToolbar from './CreditNoteToolbar';
import CreditNoteBasicInformationResource from './resource/CreditNoteBasicInformationResource';
import CreditNoteTransactionInformationResource from './resource/CreditNoteTransactionInformationResource';

export class CreditNote extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: creditNoteId },
      },
      dispatchGetCreditNote,
    } = this.props;

    dispatchGetCreditNote({ creditNoteId });
  }

  componentWillUnmount() {
    this.props.dispatchClearCreditNote();
  }

  render() {
    const { breadcrumbs, creditNote, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading || creditNote.isEmpty()) {
      content = <Loader />;
    } else if (creditNote.isEmpty()) {
      content = <NoDataResource returnPage={GENERAL_INCOME_PATH} />;
    } else {
      const creditNoteJson = creditNote.toJS();

      content = (
        <Fragment>
          <CreditNoteBasicInformationResource {...creditNoteJson} />
          <CreditNoteTransactionInformationResource
            creditNoteTransactionList={creditNoteJson.creditNoteTransactionList}
          />
          <InternalResource {...creditNoteJson} />
        </Fragment>
      );

      toolbar = <CreditNoteToolbar creditNoteId={creditNoteJson.id} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Nota de Crédito"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Notas de Crédito', href: CREDIT_NOTE_PATH },
    { text: 'Ver', href: '' },
  ],
  creditNote: SalesUnit.CreditNote.getIn(['current', 'content']),
  loading: !SalesUnit.CreditNote.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCreditNote: getCreditNote,
  dispatchClearCreditNote: clearCreditNote,
};

CreditNote.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  creditNote: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetCreditNote: PropTypes.func.isRequired,
  dispatchClearCreditNote: PropTypes.func.isRequired,
};

CreditNote.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditNote);
