import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { getItemCategories, clearItemCategories } from '../../../../actions';

class ItemCategorySelect extends Component {
  componentDidMount() {
    const { dispatchGetItemCategories } = this.props;
    dispatchGetItemCategories(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearItemCategories, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearItemCategories();
    }
  }

  render() {
    const {
      loading,
      itemCategoryOptions,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={itemCategoryOptions}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

ItemCategorySelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetItemCategories: PropTypes.func.isRequired,
  dispatchClearItemCategories: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  itemCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  isClearable: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  cleanWhenUnmount: PropTypes.bool,
};

ItemCategorySelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  isClearable: false,
  onValueChange: undefined,
  value: {},
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ BaggageUnit }) => {
  const itemCategoryOptions = BaggageUnit.ItemCategory.getIn([
    'all',
    'content',
  ]);

  const options = [];
  itemCategoryOptions.forEach((itemCategory) => {
    options.push({
      value: itemCategory.id,
      label: itemCategory.name,
    });
  });

  return {
    itemCategoryOptions: options,
    loading: BaggageUnit.ItemCategory.getIn(['all', 'loading']),
  };
};

const mapDispatchToProps = {
  dispatchGetItemCategories: getItemCategories,
  dispatchClearItemCategories: clearItemCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCategorySelect);
