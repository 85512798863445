import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  EXTRAORDINARY_MOVEMENT_ENDPOINT,
  RESCUE_ITINERARY_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_POST_CONFIG } from '../../config/rest';
import {
  FLAG_GETTING_EXTRAORDINARY_MOVEMENTS,
  CLEAR_EXTRAORDINARY_MOVEMENTS,
  CLEAR_EXTRAORDINARY_MOVEMENT,
  FLAG_EXTRAORDINARY_MOVEMENT_ACTIVITY,
} from '../types/itinerary/ExtraordinaryMovement';
import { ITINERARY_UNIT_PATH } from '../../config/paths';

const flagGettingExtraordinaryMovements = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_EXTRAORDINARY_MOVEMENTS,
    payload: flag,
  });

const flagExtraordinaryMovementActivity = flag => dispatch =>
  dispatch({
    type: FLAG_EXTRAORDINARY_MOVEMENT_ACTIVITY,
    payload: flag,
  });

const clearExtraordinaryMovements = () => dispatch =>
  dispatch({
    type: CLEAR_EXTRAORDINARY_MOVEMENTS,
  });

const clearExtraordinaryMovement = () => dispatch =>
  dispatch({
    type: CLEAR_EXTRAORDINARY_MOVEMENT,
  });

const postExtraordinaryMovement = async ({
  busId,
  extraordinaryMovementType,
  routeId,
  sourceLocation,
  destinationLocation,
  routeDetail,
  cabinCrewCommissionAmount,
  driverCommissionAmount,
  departureTime,
  arrivalTime,
  comment,
  seatMapId,
  serviceTypeId,
  circuitId,
  tollExpenseAmount,
  otherExpenseAmount,
  distance,
  fuelGallons,
  adBlueLiters,
}) => async dispatch => {
  try {
    dispatch(flagExtraordinaryMovementActivity(true));

    const payload = {
      busId,
      extraordinaryMovementType,
      routeId,
      sourceLocation,
      destinationLocation,
      routeDetail,
      cabinCrewCommissionAmount,
      driverCommissionAmount,
      departureTime,
      arrivalTime,
      comment,
      seatMapId,
      serviceTypeId,
      circuitId,
      tollExpenseAmount,
      otherExpenseAmount,
      distance,
      fuelGallons,
      adBlueLiters,
    };

    const url = EXTRAORDINARY_MOVEMENT_ENDPOINT;

    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });

    await isErrorResponse(response);

    // TODO redirect to list of extraordinary movement
    dispatch(push(`${ITINERARY_UNIT_PATH}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagExtraordinaryMovementActivity(false));
  }
};

const postRescueItinerary = async ({
  extraordinaryMovementType,
  busId,
  sourceLocation,
  destinationLocation,
  cabinCrewCommissionAmount,
  driverCommissionAmount,
  tollExpenseAmount,
  otherExpenseAmount,
  distance,
  fuelGallons,
  adBlueLiters,
  comment,
  itineraryId,
}) => async dispatch => {
  try {
    dispatch(flagExtraordinaryMovementActivity(true));

    const payload = {
      extraordinaryMovementType,
      busId,
      sourceLocation,
      destinationLocation,
      cabinCrewCommissionAmount,
      driverCommissionAmount,
      tollExpenseAmount,
      otherExpenseAmount,
      distance,
      fuelGallons,
      adBlueLiters,
      comment,
      itineraryId,
    };

    const url = RESCUE_ITINERARY_ENDPOINT;

    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });

    await isErrorResponse(response);

    // TODO redirect to list of extraordinary movement
    dispatch(push(`${ITINERARY_UNIT_PATH}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagExtraordinaryMovementActivity(false));
  }
};

export {
  flagGettingExtraordinaryMovements,
  clearExtraordinaryMovements,
  flagExtraordinaryMovementActivity,
  clearExtraordinaryMovement,
  postExtraordinaryMovement,
  postRescueItinerary,
};
