import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LOCATION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import LocationForm from './LocationForm';
import { postLocation } from '../../../../actions';
import Content from '../../../layout/Content';
import { TIME_ZONE } from '../../../../config/locale';

export class NewLocation extends Component {
  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    // TODO change timezone param when Flit is used in the future
    newFormValues.timezone = TIME_ZONE;
    newFormValues.addressId = formValues.addressId.value;
    newFormValues.address = {
      id: formValues.addressId.value,
      address: formValues.addressId.address,
      address2: formValues.addressId.address2,
      district: formValues.addressId.district,
      cityId: formValues.addressId.cityId,
      postalCode: formValues.addressId.postalCode,
      phone: formValues.addressId.phone,
    };

    this.props.dispatchPostLocation(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Ubicación"
        subtitle="Crear una nueva ubicación"
        content={<LocationForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ LocationUnit }) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ubicaciones',
      href: LOCATION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostLocation: postLocation,
};

NewLocation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostLocation: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLocation);
