import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Loader from '../../../common/Loader';
import ItinerarySearchOmissionForm from './ItinerarySearchOmissionForm';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { ITINERARY_SEARCH_OMISSION_PATH } from '../../../../config/paths';
import {
  clearItinerarySearchOmission,
  getItinerarySearchOmission,
  putItinerarySearchOmission,
} from '../../../../actions/itinerary';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';

class EditItinerarySearchOmission extends Component {
  componentDidMount() {
    const {
      dispatchGetItinerarySearchOmission,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetItinerarySearchOmission({ id });
  }

  componentWillUnmount() {
    const { dispatchClearItinerarySearchOmission } = this.props;
    dispatchClearItinerarySearchOmission();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutItinerarySearchOmission,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutItinerarySearchOmission(id, formValues);
  };

  createInitialValues = (itinerarySearchOmission) => {
    const itinerarySearchOmissionJson = {
      sourceLocationId: {
        value: itinerarySearchOmission.get('sourceLocationId'),
        label: itinerarySearchOmission.get('sourceLocation').name,
      },
      destinationLocationId: {
        value: itinerarySearchOmission.get('destinationLocationId'),
        label: itinerarySearchOmission.get('destinationLocation').name,
      },
    };

    return itinerarySearchOmissionJson;
  };

  render() {
    const { breadcrumbs, itinerarySearchOmission, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (itinerarySearchOmission.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_SEARCH_OMISSION_PATH} />;
    } else {
      content = (
        <ItinerarySearchOmissionForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(itinerarySearchOmission)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Parada Oculta"
        subtitle="Editar parada oculta"
        content={content}
      />
    );
  }
}

EditItinerarySearchOmission.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItinerarySearchOmission: PropTypes.func.isRequired,
  dispatchClearItinerarySearchOmission: PropTypes.func.isRequired,
  dispatchPutItinerarySearchOmission: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  itinerarySearchOmission: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditItinerarySearchOmission.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Parada Oculta',
      href: ITINERARY_SEARCH_OMISSION_PATH,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  itinerarySearchOmission: ItineraryUnit.ItinerarySearchOmission.getIn([
    'current',
    'content',
  ]),
  loading: !ItineraryUnit.ItinerarySearchOmission.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItinerarySearchOmission: getItinerarySearchOmission,
  dispatchClearItinerarySearchOmission: clearItinerarySearchOmission,
  dispatchPutItinerarySearchOmission: putItinerarySearchOmission,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditItinerarySearchOmission);
