import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import CitySelect from '../../../common/forms/select/CitySelect';
import FormFooter from '../../../common/forms/FormFooter';
import DistrictSelect from '../../../common/forms/select/DistrictSelect';

export const AddressForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Dirección 1" required>
          <Field
            name="address"
            component={TextInput}
            placeholder="Dirección 1"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Dirección 2">
          <Field
            name="address2"
            component={TextInput}
            placeholder="Dirección 2"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Distrito" required>
          <Field
            name="selectedDistrict"
            component={DistrictSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Código Postal">
          <Field
            name="postalCode"
            component={TextInput}
            placeholder="Código Postal"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Teléfono">
          <Field name="phone" component={TextInput} placeholder="Teléfono" />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Ciudad" required>
          <Field
            name="city"
            component={CitySelect}
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

const mapStateToProps = ({ LocationUnit }) => ({
  loading: !LocationUnit.Address.getIn(['current', 'activity']).isEmpty(),
});

AddressForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

AddressForm.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'AddressForm',
})(AddressForm);

export default connect(mapStateToProps)(formComponent);
