import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { debounce } from '../../../utils/promise';

const SelectFilter = ({
  onChangeFilter,
  options,
  isLoading,
  isClearable,
  isSearchable,
  isMulti,
  placeholder,
  isDisabled,
  noOptionsMessage,
  onInputChange,
  filterOption,
}) => {
  const props = {
    isSearchable,
    isDisabled,
    options,
    isClearable,
    isMulti,
    isLoading,
    placeholder,
    noOptionsMessage,
    loadingMessage: () => 'Cargando...',
    menuPortalTarget: document.body,
    menuPosition: 'fixed',
    menuPlacement: 'bottom',
    styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
    onInputChange: debounce(onInputChange),
    onChange: (valueOptions) => {
      if (isMulti) {
        const values = [];
        valueOptions.forEach((option) => {
          values.push(option.value);
        });
        onChangeFilter(values);
      } else onChangeFilter(valueOptions ? valueOptions.value : null);
    },
  };

  if (filterOption) props.filterOption = filterOption;

  return (
    <div>
      <ReactSelect {...props} />
    </div>
  );
};

SelectFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
  options: optionsPropTypes,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  filterOption: PropTypes.func,
};

SelectFilter.defaultProps = {
  options: [],
  isLoading: false,
  isClearable: true,
  isSearchable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: 'Seleccione...',
  onInputChange: () => {},
  noOptionsMessage: () => 'No hay resultados.',
  filterOption: null,
};

export default SelectFilter;
