import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { CONTRACT_MOVEMENT_TYPE } from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';

const ContractMovementTypeSelect = ({
  input,
  meta,
  isGridVariant,
  onValueChange,
  onBlur,
  ...rest
}) => (
  <Select
    options={enumToSelectOptions(CONTRACT_MOVEMENT_TYPE)}
    input={input}
    meta={meta}
    isGridVariant={isGridVariant}
    onValueChange={onValueChange}
    onBlur={onBlur}
    {...rest}
  />
);

ContractMovementTypeSelect.propTypes = {
  input: inputPropTypes,
  meta: metaPropTypes,
  isGridVariant: PropTypes.bool,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
};

ContractMovementTypeSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isGridVariant: false,
  onValueChange: undefined,
  onBlur: undefined,
};

export default ContractMovementTypeSelect;
