import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import CitySelect from '../../../common/forms/select/CitySelect';
import Grid from '../../../common/grid/Grid';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';

export class GeographicZoneForm extends Component {
  state = {
    gridValues: [],
  };

  onGridValueChange = (values) => {
    this.setState({ gridValues: values });
  };

  onHandleSubmit = (formValues) => {
    const { onSubmit } = this.props;
    const { gridValues } = this.state;
    const newGeographicZone = {
      name: formValues.name,
      description: formValues.description,
    };
    if (this.validateGridValues(gridValues)) {
      newGeographicZone.cityList = gridValues;
      onSubmit(newGeographicZone);
    }
  };

  validateGridValues = (gridValues) => {
    let isValid = true;
    if (gridValues.length) {
      const isInvalidData = gridValues.some((rowValue) => {
        if (!(rowValue.city && rowValue.city.value)) {
          toastr.error('Error','Seleccione una ciudad.');
          return true;
        }
        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error','Seleccione almenos una ciudad.');
      isValid = false;
    }
    return isValid;
  };

  render() {
    const { handleSubmit, loading, initialValues } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Nombre" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Nombre"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <Grid
          columns={[
            {
              key: 'city',
              title: 'Ciudad',
              editableComponent: (onValueChange, value) => (
                <CitySelect
                  onValueChange={onValueChange}
                  value={value}
                  isGridVariant
                />
              ),
            },
          ]}
          initialValues={initialValues ? initialValues.cityList : []}
          onValueChange={this.onGridValueChange}
        />
        <FormFooter />
      </Form>
    );
  }
}

GeographicZoneForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    cityList: PropTypes.arrayOf(PropTypes.object),
  }),
};

GeographicZoneForm.defaultProps = {
  loading: false,
  initialValues: null,
};

const mapStateToProps = ({ LocationUnit }) => ({
  loading: !LocationUnit.GeographicZone.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'GeographicZoneForm',
})(GeographicZoneForm);

export default connect(mapStateToProps, null)(formComponent);
