import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';
import {
  clearSecurityProfiles,
  getSecurityProfiles,
} from '../../../../actions/security/SecurityProfile';

export const SecurityProfileSelect = ({
  dispatchClearSecurityProfiles,
  dispatchGetSecurityProfiles,
  loading,
  options,
  isGridVariant,
  value,
  isMulti,
  isClearable,
  onValueChange,
  placeholder,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearSecurityProfiles(), []);

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 3) {
      const query = [`name:${inputValue}`];

      dispatchGetSecurityProfiles({ query });
    }
  };

  return (
    <Select
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      input={{ value }}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      {...rest}
    />
  );
};

SecurityProfileSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetSecurityProfiles: PropTypes.func.isRequired,
  dispatchClearSecurityProfiles: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  forSales: PropTypes.bool,
  forCargo: PropTypes.bool,
  forHomeBase: PropTypes.bool,
};

SecurityProfileSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  forSales: false,
  forCargo: false,
  forHomeBase: false,
};

const mapStateToProps = ({ SecurityUnit }) => ({
  options: SecurityUnit.SecurityProfile.getIn([
    'all',
    'content',
    'content',
  ]).map((profile) => ({
    value: profile.id,
    label: profile.name,
  })),
  loading: SecurityUnit.SecurityProfile.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetSecurityProfiles: getSecurityProfiles,
  dispatchClearSecurityProfiles: clearSecurityProfiles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityProfileSelect);
