import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import AuthorizedProfile from './AuthorizedProfile';
import PaymentMethodForSecurityProfile from './PaymentMethodForSecurityProfile';
import SecurityProfile from './SecurityProfile';

export default combineReducers({
  UnitHome,
  AuthorizedProfile,
  PaymentMethodForSecurityProfile,
  SecurityProfile,
});
