import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BILLING_PATH, MANUAL_VOUCHER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import BillingForm from './BillingForm';
import Loader from '../../../common/Loader';
import { clearBilling, getBilling, putBilling } from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import {
  BILLING_DOCUMENT_TYPE,
  BILLING_VOUCHER_TYPE,
} from '../../../../config/constants';
import BillingFormSenderRemissionGuide from './BillingFormSenderRemissionGuide';

export const EditBilling = ({
  breadcrumbs,
  billing,
  loading,
  match: {
    params: { id: billingId },
  },
  dispatchGetBilling,
  dispatchClearBilling,
  dispatchPutBilling,
}) => {
  useLayoutEffect(() => {
    dispatchGetBilling({ billingId });

    return () => dispatchClearBilling();
  }, []);

  const onSubmit = ({
    busLicensePlate,
    busAuthorizationDocument,
    sourceCode,
    sourceAddress,
    comments,
    issueDate,
    issueTime,
    itemsList,
    remitenteFullName,
    remitenteDocumentType,
    remitenteDocumentNumber,
    driverList,
    destinationCode,
    destinationAddress,
    itineraryId,
    totalWeight,
    destinatarioFullName,
    destinatarioDocumentType,
    destinatarioDocumentNumber,
    destinatarioEmail,
    documentCode,
    documentSeries,
    itineraryDepartureDate,
    totalWeightUnitCode,
    voucherType,
    documentReferenceDocumentSeries,
    documentReferenceDocumentCode,
  }) => {
    const billingJson = billing.toJS();

    const previousJson = JSON.parse(billingJson.json);

    const newJson = JSON.stringify({
      bus: {
        licensePlate: busLicensePlate,
        authorizationDocument: busAuthorizationDocument,
      },
      source: {
        sourceCode,
        sourceAddress,
      },
      comments,
      issueDate,
      issueTime,
      itemsList,
      remitente: {
        fullName: remitenteFullName,
        documentType: remitenteDocumentType.value,
        documentNumber: remitenteDocumentNumber,
      },
      driverList: driverList.map((driver) => ({
        ...driver,
        documentType: driver.documentType.value,
      })),
      destination: {
        destinationCode,
        destinationAddress,
      },
      itineraryId,
      totalWeight,
      destinatario: {
        email: destinatarioEmail,
        fullName: destinatarioFullName,
        documentType: destinatarioDocumentType.value,
        documentNumber: destinatarioDocumentNumber,
      },
      documentCode,
      documentSeries,
      itineraryDepartureDate,
      totalWeightUnitCode,
      documentReference: {
        voucherType: voucherType.value,
        documentCode: documentReferenceDocumentCode,
        documentSeries: documentReferenceDocumentSeries,
        issuerDocumentType: previousJson.issuerDocumentType,
        issuerDocumentNumber: previousJson.issuerDocumentNumber,
      },
      carrier: billingJson.carrier,
      refDocumentCode: previousJson.refDocumentCode,
      refDocumentSeries: previousJson.refDocumentSeries,
    });

    billingJson.retry = 0;
    const newFormValues = { ...billingJson, json: newJson };

    dispatchPutBilling(billingId, newFormValues);
  };

  const onSubmitSenderRemissionGuide = ({
    busLicensePlate,
    sourceCode,
    sourceAddress,
    sourceAgencyCode,
    comments,
    issueDate,
    issueTime,
    itemsList,
    driverList,
    destinationCode,
    destinationAddress,
    destinationAgencyCode,
    itineraryId,
    totalWeight,
    destinatarioFullName,
    destinatarioDocumentType,
    destinatarioDocumentNumber,
    destinatarioEmail,
    documentCode,
    documentSeries,
    itineraryDepartureDate,
    totalWeightUnitCode,
    motiveId,
    motiveDescription,
    completeManualFields,
    refDocumentRequired,
  }) => {
    const billingJson = billing.toJS();

    const newJson = JSON.stringify({
      bus: {
        licensePlate: busLicensePlate,
      },
      source: {
        sourceCode,
        sourceAddress,
        sourceAgencyCode,
      },
      comments,
      issueDate,
      issueTime,
      itemsList,
      driverList: driverList.map((driver) => ({
        ...driver,
        documentType: driver.documentType.value,
      })),
      destination: {
        destinationCode,
        destinationAddress,
        destinationAgencyCode,
      },
      itineraryId,
      totalWeight,
      destinatario: {
        email: destinatarioEmail,
        fullName: destinatarioFullName,
        documentType: destinatarioDocumentType.value,
        documentNumber: destinatarioDocumentNumber,
      },
      documentCode,
      documentSeries,
      itineraryDepartureDate,
      totalWeightUnitCode,
      carrier: billingJson.carrier,
      motiveId,
      motiveDescription,
      completeManualFields,
      refDocumentRequired,
    });

    billingJson.retry = 0;
    const newFormValues = { ...billingJson, json: newJson };

    dispatchPutBilling(billingId, newFormValues);
  };

  const generateInitialValues = ({
    bus: { licensePlate, authorizationDocument },
    source: { sourceCode, sourceAddress },
    comments,
    issueDate,
    issueTime,
    itemsList,
    remitente: {
      fullName: remitenteFullName,
      documentType: remitenteDocumentType,
      documentNumber: remitenteDocumentNumber,
    },
    driverList,
    destination: { destinationCode, destinationAddress },
    itineraryId,
    totalWeight,
    destinatario: {
      email: destinatarioEmail,
      fullName: destinatarioFullName,
      documentType: destinatarioDocumentType,
      documentNumber: destinatarioDocumentNumber,
    },
    documentCode,
    documentSeries,
    refDocumentCode,
    refDocumentSeries,
    totalWeightUnitCode,
    itineraryDepartureDate,
    documentReference: {
      voucherType,
      documentCode: documentReferenceDocumentCode,
      documentSeries: documentReferenceDocumentSeries,
      issuerDocumentType,
      issuerDocumentNumber,
    },
  }) => ({
    busAuthorizationDocument: authorizationDocument,
    busLicensePlate: licensePlate,
    sourceCode,
    sourceAddress,
    comments,
    issueDate,
    issueTime,
    itemsList,
    remitenteFullName,
    remitenteDocumentType: remitenteDocumentType
      ? BILLING_DOCUMENT_TYPE[remitenteDocumentType]
      : null,
    remitenteDocumentNumber,
    driverList: driverList.map((driver) => ({
      ...driver,
      documentType: BILLING_DOCUMENT_TYPE[driver.documentType],
    })),
    destinationCode,
    destinationAddress,
    itineraryId,
    totalWeight,
    destinatarioEmail,
    destinatarioFullName,
    destinatarioDocumentType: destinatarioDocumentType
      ? BILLING_DOCUMENT_TYPE[destinatarioDocumentType]
      : null,
    destinatarioDocumentNumber,
    documentCode,
    documentSeries,
    refDocumentCode,
    refDocumentSeries,
    totalWeightUnitCode,
    itineraryDepartureDate,
    voucherType: voucherType ? BILLING_VOUCHER_TYPE[voucherType] : null,
    documentReferenceDocumentCode,
    documentReferenceDocumentSeries,
    issuerDocumentType,
    issuerDocumentNumber,
  });

  const generateInitialValuesForSenderRemissionGuide = ({
    bus: { licensePlate },
    source: { sourceCode, sourceAddress, sourceAgencyCode },
    comments,
    issueDate,
    issueTime,
    itemsList,
    driverList,
    destination: { destinationCode, destinationAddress, destinationAgencyCode },
    itineraryId,
    totalWeight,
    destinatario: {
      email: destinatarioEmail,
      fullName: destinatarioFullName,
      documentType: destinatarioDocumentType,
      documentNumber: destinatarioDocumentNumber,
    },
    documentCode,
    documentSeries,
    totalWeightUnitCode,
    itineraryDepartureDate,
    motiveId,
    motiveDescription,
    completeManualFields,
    refDocumentRequired,
  }) => ({
    busLicensePlate: licensePlate,
    sourceCode,
    sourceAddress,
    sourceAgencyCode,
    comments,
    issueDate,
    issueTime,
    itemsList,
    driverList: driverList.map((driver) => ({
      ...driver,
      documentType: BILLING_DOCUMENT_TYPE[driver.documentType],
    })),
    destinationCode,
    destinationAddress,
    destinationAgencyCode,
    itineraryId,
    totalWeight,
    destinatarioEmail,
    destinatarioFullName,
    destinatarioDocumentType: destinatarioDocumentType
      ? BILLING_DOCUMENT_TYPE[destinatarioDocumentType]
      : null,
    destinatarioDocumentNumber,
    documentCode,
    documentSeries,
    totalWeightUnitCode,
    itineraryDepartureDate,
    motiveId,
    motiveDescription,
    completeManualFields,
    refDocumentRequired,
  });

  let content = null;

  if (loading) {
    content = <Loader />;
  } else if (billing.isEmpty()) {
    content = <NoDataResource returnPage={MANUAL_VOUCHER_PATH} />;
  } else if (billing.get('processType') === 'GUIA_REMISION_REMITENTE') {
    content = (
      <BillingFormSenderRemissionGuide
        onSubmit={onSubmitSenderRemissionGuide}
        initialValues={generateInitialValuesForSenderRemissionGuide(
          JSON.parse(billing.get('json')),
        )}
      />
    );
  } else {
    content = (
      <BillingForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(JSON.parse(billing.get('json')))}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Comprobante Manual"
      subtitle="Edita este comprobante manual"
      content={content}
    />
  );
};

const mapStateToProps = (
  { AccountingUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Facturación',
      href: BILLING_PATH,
    },
    {
      text: 'Ver',
      href: `${BILLING_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  billing: AccountingUnit.Billing.getIn(['current', 'content']),
  loading: !AccountingUnit.Billing.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutBilling: putBilling,
  dispatchGetBilling: getBilling,
  dispatchClearBilling: clearBilling,
};

EditBilling.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutBilling: PropTypes.func.isRequired,
  dispatchGetBilling: PropTypes.func.isRequired,
  dispatchClearBilling: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  billing: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditBilling.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBilling);
