import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';

/**
 * Returns a Badge element with the label and color indicated
 * in the enumObject parameter according to enumValue
 * @method EnumStatus
 * @param {Object} enumObject
 * @param {String} enumValue
 * @return {ReactElement}
 */
const EnumStatus = ({ enumObject, enumValue }) => {
  if (!enumObject[enumValue]) return '-';

  const { color, label } = enumObject[enumValue];

  return <Badge color={color} text={label} />;
};

EnumStatus.propTypes = {
  enumObject: PropTypes.shape({}).isRequired,
  enumValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default EnumStatus;
