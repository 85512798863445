import {
  HOME_PATH,
  EXPENSE_TYPE_PATH,
  ACCOUNTING_UNIT_PATH,
} from '../../../../../config/paths';

const HOME_BREADCRUMB = [
  {
    text: 'Inicio',
    tag: 'a',
    href: HOME_PATH,
  },
];

const ACCOUNTING_UNIT_BREADCRUMBS = [
  ...HOME_BREADCRUMB,
  {
    text: 'Contabilidad',
    tag: 'span',
    href: ACCOUNTING_UNIT_PATH,
  },
];

const EXPENSE_TYPES_BREADCRUMBS = [
  ...ACCOUNTING_UNIT_BREADCRUMBS,
  {
    text: 'Tipos de Egreso',
    tag: 'span',
    href: EXPENSE_TYPE_PATH,
  },
];

const EXPENSE_TYPE_BREADCRUMBS = [
  ...EXPENSE_TYPES_BREADCRUMBS,
  {
    text: 'Ver',
    href: '',
  },
];

const EDIT_EXPENSE_TYPE_BREADCRUMBS = [
  ...EXPENSE_TYPES_BREADCRUMBS,
  {
    text: 'Editar',
    href: '',
  },
];

const NEW_EXPENSE_TYPE_BREADCRUMBS = [
  ...EXPENSE_TYPES_BREADCRUMBS,
  {
    text: 'Nuevo',
    href: '',
  },
];

export {
  EXPENSE_TYPE_BREADCRUMBS,
  ACCOUNTING_UNIT_BREADCRUMBS,
  EXPENSE_TYPES_BREADCRUMBS,
  EDIT_EXPENSE_TYPE_BREADCRUMBS,
  NEW_EXPENSE_TYPE_BREADCRUMBS
};
