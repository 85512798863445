import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getTickets, clearTickets } from '../../../../actions/index';
import { TICKET_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { TICKETS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Tickets extends Component {
  componentWillUnmount() {
    this.props.dispatchClearTickets();
  }

  render() {
    const { tickets, loading, breadcrumbs, dispatchGetTickets } = this.props;
    const data = tickets.get('content') || [];
    const pages = tickets.get('totalPages') || null;
    const defaultPageSize = tickets.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Boletos"
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: TICKETS_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetTickets,
          modelPath: TICKET_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ ReservationUnit }) => ({
  breadcrumbs: [
    ...ReservationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos',
      href: TICKET_PATH,
    },
  ],
  tickets: ReservationUnit.Ticket.getIn(['all', 'content']),
  loading: ReservationUnit.Ticket.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetTickets: getTickets,
  dispatchClearTickets: clearTickets,
};

Tickets.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  tickets: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetTickets: PropTypes.func.isRequired,
  dispatchClearTickets: PropTypes.func.isRequired,
};

Tickets.defaultProps = {
  tickets: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
