import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generateExtraordinaryMovementControlEditPath } from '../../../../config/paths';

const EditExtraordinaryMovementControlButton = ({
  extraordinaryMovementControlId,
}) => (
  <Link
    className="btn btn-primary"
    to={generateExtraordinaryMovementControlEditPath({
      id: extraordinaryMovementControlId,
    })}
  >
    Editar
  </Link>
);

EditExtraordinaryMovementControlButton.propTypes = {
  extraordinaryMovementControlId: PropTypes.number.isRequired,
};

export default EditExtraordinaryMovementControlButton;
