import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { ODOMETER_BY_BUS_COLUMNS } from '../../../../config/columns';
import { clearOdometerByBus } from '../../../../actions';
import Table from '../../../common/Table';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { THERE_ARE_NOT_DATA_TO_SHOW } from '../../../../config/messages';

export const OdometerByBusSearchResult = ({
  dispatchClearOdometerByBus,
  loading,
  itineraries,
}) => {
  useLayoutEffect(() => () => dispatchClearOdometerByBus(), []);

  const data = Array.from(itineraries.values());

  let table = <Alert message={THERE_ARE_NOT_DATA_TO_SHOW} />;
  if (loading) table = <Loader />;
  if (!loading && data.length > 0)
    table = (
      <Table
        manual={false}
        columns={ODOMETER_BY_BUS_COLUMNS}
        data={data}
        defaultPageSize={data.length}
        loading={loading}
        showPagination={false}
        tablePosition={400}
      />
    );
  return table;
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: MechanicalMaintenanceUnit.OdometerByBus.getIn(['all', 'loading']),
  itineraries: MechanicalMaintenanceUnit.OdometerByBus.getIn([
    'all',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchClearOdometerByBus: clearOdometerByBus,
};

OdometerByBusSearchResult.propTypes = {
  itineraries: PropTypes.instanceOf(Immutable.List).isRequired,
  loading: PropTypes.bool,
  dispatchClearOdometerByBus: PropTypes.func.isRequired,
};

OdometerByBusSearchResult.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'OdometerByBusSearchResult',
})(OdometerByBusSearchResult);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
