import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DRIVER_BUS_ASSIGNMENT_PATH } from '../../../../config/paths';

const EditDriverBusAssignmentButton = ({ driverBusAssignmentId }) => (
  <Link
    className="btn btn-primary"
    to={`${DRIVER_BUS_ASSIGNMENT_PATH}/${driverBusAssignmentId}/edit`}
  >
    Editar
  </Link>
);

EditDriverBusAssignmentButton.propTypes = {
  driverBusAssignmentId: PropTypes.number.isRequired,
};

export default EditDriverBusAssignmentButton;
