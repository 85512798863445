import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import GridSelect from '../../grid/GridSelect';
import FormSelect from './FormSelect';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';
import { optionsPropTypes } from './SelectPropTypes';

const Select = ({
  input,
  name,
  options,
  isLoading,
  isClearable,
  isMulti,
  onValueChange,
  onInputChange,
  placeholder,
  isDisabled,
  meta,
  autoFocus,
  noOptionsMessage,
  isGridVariant,
  filterOption,
  onBlur,
  showToolTip,
  id,
  setFieldToBeFocused,
}) => {
  // meant to be used inside redux-form Form component
  let selectComponent = (
    <FormSelect
      isDisabled={isDisabled}
      onInputChange={onInputChange}
      name={name}
      options={options}
      isClearable={isClearable}
      isMulti={isMulti}
      isLoading={isLoading}
      placeholder={placeholder}
      autoFocus={autoFocus}
      noOptionsMessage={noOptionsMessage}
      input={input}
      meta={meta}
      filterOption={filterOption}
      setFieldToBeFocused={setFieldToBeFocused}
    />
  );

  // meant to be used inside Grid component
  if (isGridVariant)
    selectComponent = (
      <GridSelect
        isLoading={isLoading}
        onInputChange={onInputChange}
        options={options}
        isMulti={isMulti}
        value={input.value}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onBlur={onBlur}
        autoFocus={autoFocus}
        setFieldToBeFocused={setFieldToBeFocused}
      />
    );

  let toolTipComponent = null;
  if (showToolTip && id) {
    toolTipComponent = (
      <UncontrolledTooltip placement="top" target={id}>
        {placeholder}
      </UncontrolledTooltip>
    );
  }

  return (
    <>
      <div id={id}>{selectComponent}</div>
      {toolTipComponent}
    </>
  );
};

Select.propTypes = {
  input: inputPropTypes,
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: optionsPropTypes,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  meta: metaPropTypes,
  autoFocus: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onValueChange: PropTypes.func,
  isGridVariant: PropTypes.bool,
  filterOption: PropTypes.func,
  onBlur: PropTypes.func,
  showToolTip: PropTypes.bool,
  id: PropTypes.string,
  setFieldToBeFocused: PropTypes.func,
};

Select.defaultProps = {
  name: '',
  options: [],
  input: inputDefaultProps,
  meta: metaDefaultProps,
  onInputChange: () => {},
  isLoading: false,
  isClearable: false,
  isMulti: false,
  isDisabled: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  autoFocus: false,
  noOptionsMessage: () => 'No hay resultados.',
  onValueChange: undefined,
  isGridVariant: false,
  filterOption: null,
  onBlur: undefined,
  showToolTip: false,
  id: null,
  setFieldToBeFocused: null,
};

export default Select;
