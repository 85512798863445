import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, ListGroup } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import Loader from '../../../../common/Loader';
import FormFooter from '../../../../common/forms/FormFooter';
import { generateEditMaintenanceServiceOrderStep2 } from '../../../../../config/paths';
import { clearMechanics, getMechanics } from '../../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../../config/queries';
import RenderActivityBusFields from './RenderActivityBusFields';
import validate from './validate';

export const AssignEmployeesToMaintenanceServiceOrderForm = ({
  handleSubmit,
  maintenanceServiceOrderId,
  loading,
  dispatchClearMechanics,
  dispatchGetMechanics,
}) => {
  useEffect(() => {
    dispatchGetMechanics({ ...DEFAULT_QUERY_GET_ALL });

    return () => dispatchClearMechanics();
  }, []);

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <h4>Asociar Empleados</h4>
      <ListGroup flush>
        <FieldArray
          name="activityBusExecutionList"
          component={RenderActivityBusFields}
        />
      </ListGroup>
      <FormFooter
        saveButtonIcon="fa fa-arrow-circle-right"
        saveButtonText="Continuar"
      >
        <Link
          className="btn btn-secondary"
          type="button"
          to={generateEditMaintenanceServiceOrderStep2(
            maintenanceServiceOrderId,
          )}
        >
          <i className="fa fa-arrow-circle-left" /> Anterior
        </Link>
      </FormFooter>
    </Form>
  );
};

AssignEmployeesToMaintenanceServiceOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  maintenanceServiceOrderId: PropTypes.number.isRequired,
  dispatchGetMechanics: PropTypes.func.isRequired,
  dispatchClearMechanics: PropTypes.func.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loadingActivityBus: !MechanicalMaintenanceUnit.BusActivityAssignment.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetMechanics: getMechanics,
  dispatchClearMechanics: clearMechanics,
};

const formComponent = reduxForm({
  form: 'AssignEmployeesToMaintenanceServiceOrderForm',
  validate,
})(AssignEmployeesToMaintenanceServiceOrderForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
