import React, { Component } from 'react';
import PropTypes from 'prop-types';

// values should be handled outside editableComponents
// handling it in local state might cause data inconsistency
class GridEditableInput extends Component {
  handleInputChange = event => {
    const { onValueChange } = this.props;
    const {
      target: { value: text },
    } = event;
    // send value to parent component
    onValueChange(text);
  };

  render() {
    const { type, value, placeholder, disabled } = this.props;
    return (
      <input
        type={type}
        className="form-control"
        value={value}
        placeholder={placeholder}
        onChange={this.handleInputChange}
        disabled={disabled}
      />
    );
  }
}

GridEditableInput.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

GridEditableInput.defaultProps = {
  type: 'text',
  value: '',
  placeholder: '',
  disabled: false,
};

export default GridEditableInput;
