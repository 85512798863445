import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ExpenseStatusChangeDropdownButton from './ExpenseStatusChangeDropdownButton';

const ExpensePerUserToolbar = ({ expenseId, expenseStatus }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <ExpenseStatusChangeDropdownButton
        expenseId={expenseId}
        expenseStatus={expenseStatus}
      />
    </div>
  </ButtonToolbar>
);

ExpensePerUserToolbar.propTypes = {
  expenseId: PropTypes.number.isRequired,
  expenseStatus: PropTypes.string.isRequired,
};

export default ExpensePerUserToolbar;
