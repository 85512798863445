import Immutable from 'immutable';
import {
  FLAG_GETTING_SERVICE_TYPE,
  FLAG_GETTING_SERVICE_TYPES,
  SHOW_ERROR_GETTING_SERVICE_TYPES,
  SHOW_ERROR_GETTING_SERVICE_TYPE,
  CLEAR_ERROR_GETTING_SERVICE_TYPES,
  CLEAR_ERROR_GETTING_SERVICE_TYPE,
  GET_SERVICE_TYPE,
  GET_SERVICE_TYPES,
  CLEAR_SERVICE_TYPES,
  CLEAR_SERVICE_TYPE,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  form: Immutable.Map({
    error: null,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    error: null,
    loading: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    error: null,
    loading: false,
  }),
  loading: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_TYPES:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case GET_SERVICE_TYPE:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case FLAG_GETTING_SERVICE_TYPES:
      return state.setIn(['all', 'loading'], action.payload);
    case FLAG_GETTING_SERVICE_TYPE:
      return state.setIn(['current', 'loading'], action.payload);
    case CLEAR_SERVICE_TYPES:
      return state.deleteIn(['all', 'content']);
    case CLEAR_SERVICE_TYPE:
      return state.deleteIn(['current', 'content']);
    case CLEAR_ERROR_GETTING_SERVICE_TYPE:
      return state.deleteIn(['current', 'error']);
    case SHOW_ERROR_GETTING_SERVICE_TYPE:
      return state.setIn(['current', 'error'], action.payload);
    case SHOW_ERROR_GETTING_SERVICE_TYPES:
      return state.setIn(['all', 'error'], action.payload);
    case CLEAR_ERROR_GETTING_SERVICE_TYPES:
      return state.deleteIn(['all', 'error']);
    default:
      return state;
  }
};
