import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ItineraryStatusEditDropdownButton from './ItineraryStatusEditDropdownButton';
import ItineraryScheduleDeleteButton from './ItineraryScheduleDeleteButton';

const ItineraryScheduleToolbar = ({ itineraryId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <ItineraryStatusEditDropdownButton itineraryId={itineraryId} />{' '}
      <ItineraryScheduleDeleteButton itineraryId={itineraryId} />
    </div>
  </ButtonToolbar>
);

ItineraryScheduleToolbar.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default ItineraryScheduleToolbar;
