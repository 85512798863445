import Immutable from 'immutable';
import {
  HOME_PATH,
  FUEL_STATION_PATH,
  AGENCY_TO_FUEL_STATION_PATH,
  ITINERARY_GROUP_PATH,
  SEGMENT_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'routes',
      title: 'Rutas',
      path: '/route-management/routes',
      icon: 'exchange',
      disabled: true,
    },
    {
      key: 'segment',
      title: 'Segmentos',
      path: SEGMENT_PATH,
      icon: 'ellipsis-h',
    },
    {
      key: 'tolls',
      title: 'Peajes',
      path: '/route-management/tolls',
      icon: 'hand-stop-o',
      disabled: true,
    },
    {
      key: 'fuel-stations',
      title: 'Grifos',
      path: FUEL_STATION_PATH,
      icon: 'fire',
      disabled: false,
    },
    {
      key: 'circuits',
      title: 'Circuitos',
      path: '',
      icon: 'rotate-left',
      disabled: true,
    },
    {
      key: 'agency-to-fuel-station',
      title: 'Asignar Agencia a Grifo',
      path: AGENCY_TO_FUEL_STATION_PATH,
      icon: 'upload',
    },
    {
      key: 'itinerary-group',
      title: 'Grupos de Itinerarios por Circuito',
      path: ITINERARY_GROUP_PATH,
      icon: 'calendar',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Rutas',
      tag: 'span',
      href: '/route',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
