import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditButton from '../../../common/button/EditButton';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { PUT } from '../../../../config/permissions';
import { generatePutMaintenanceServiceOrderEndpoint } from '../../../../config/endpoints';

const MaintenanceServiceOrderEditButton = ({ maintenanceServiceOrderId }) => (
  <EditButton
    path={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/edit`}
  />
);

MaintenanceServiceOrderEditButton.propTypes = {
  maintenanceServiceOrderId: PropTypes.number.isRequired,
};

const connectedComponent = connect()(MaintenanceServiceOrderEditButton);

export default withEndpointAuthorization({
  url: generatePutMaintenanceServiceOrderEndpoint(0),
  permissionType: PUT,
})(connectedComponent);
