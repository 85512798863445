import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { AGENCY_GROUP_PATH, AGENCY_PATH } from '../../../../config/paths';
import { getAgencyGroup, clearAgencyGroup } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import AgencyGroupToolbar from './AgencyGroupToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import BadgeList from '../../../common/BadgeList';
import Content from '../../../layout/Content';

class AgencyGroup extends Component {
  componentDidMount() {
    const {
      dispatchGetAgencyGroup,
      match: {
        params: { id: agencyGroupId },
      },
    } = this.props;

    dispatchGetAgencyGroup({ agencyGroupId });
  }

  componentWillUnmount() {
    const { dispatchClearAgencyGroup } = this.props;
    dispatchClearAgencyGroup();
  }

  render() {
    const { breadcrumbs, agencyGroup, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (agencyGroup.isEmpty()) {
      content = <NoDataResource returnPage={AGENCY_GROUP_PATH} />;
    } else {
      const agencies = agencyGroup
        .get('agencies')
        .map(({ name, id }) => ({ text: name, href: `${AGENCY_PATH}/${id}` }));

      content = (
        <Fragment>
          <ResourceProperty label="Nombre:">
            {agencyGroup.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {agencyGroup.get('description')}
          </ResourceProperty>
          <ResourceProperty label="Agencias:">
            <BadgeList textArray={agencies} />
          </ResourceProperty>
        </Fragment>
      );

      toolbar = <AgencyGroupToolbar agencyGroupId={agencyGroup.get('id')} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Grupo de Agencias"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

AgencyGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetAgencyGroup: PropTypes.func.isRequired,
  dispatchClearAgencyGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  agencyGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
};

AgencyGroup.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Agencias',
      href: AGENCY_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  agencyGroup: HumanResourcesUnit.AgencyGroup.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.AgencyGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetAgencyGroup: getAgencyGroup,
  dispatchClearAgencyGroup: clearAgencyGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyGroup);
