import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Button, ButtonToolbar, FormGroup } from 'reactstrap';
import FormItem from '../forms/FormItem';
import CustomerField from './fields/CustomerField';
import Modal from '../../common/modal/Modal';
import CustomerForm from '../../units/user/customer/CustomerForm';
import {
  clearCustomer,
  postCustomer,
  patchCustomer,
  getCustomer,
} from '../../../actions';
import { tzNormalizeDate } from '../../../utils/date';
import { optionsPropTypes } from './select/SelectPropTypes';
import { GENDERS } from '../../../config/constants';
import CustomerResource from '../resource/CustomerResource';
import Badge from '../Badge';
import { INT_DATE_FORMAT } from '../../../config/locale';
import Loader from '../Loader';
import { customerGenerator } from '../../../utils/app/json-generator-from-reducer';

const CustomerInputGroup = ({
  showDetails,
  dispatchClearCustomer,
  label,
  name,
  labelRequired,
  validate,
  disabled,
  options,
  showDiscountMessage,
  autoFocus,
  onChange,
  dispatchPostCustomer,
  dispatchPatchCustomer,
  mobilePhoneRequired,
  onClickModalClose,
  dispatchGetCustomer,
  dispatchChange,
  form,
  isClearable,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Registrar nuevo ');
  const [modalBody, setModalBody] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [showDetailsButton, setShowDetailsButton] = useState(false);
  const [hasDiscountCode, setHasDiscountCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeCustomer = (option) => {
    setHasDiscountCode(showDiscountMessage && !!option.discountCode);

    if (showDetails) {
      setShowDetailsButton(false);
      setSelectedCustomer(option.value ? option : null);
    }

    if (onChange) onChange(option);
  };

  useEffect(() => () => dispatchClearCustomer(), []);

  const onClickViewDataCustomer = async () => {
    // Get customer data when button "Ver Datos" is clicked
    if (!showDetailsButton) {
      setLoading(true);
      const returnedCustomer = await dispatchGetCustomer(
        {
          customerId: selectedCustomer.value,
        },
        false,
      );
      setLoading(false);
      setCustomer(returnedCustomer);
    }

    setShowDetailsButton(!showDetailsButton);
  };

  const handleCloseModal = () => {
    if (onClickModalClose) onClickModalClose();

    setShowModal(false);
  };

  const onSubmitCustomer = async (formValues, edit) => {
    const newFormValues = { ...formValues };

    newFormValues.gender = formValues.gender.value;
    newFormValues.identificationTypeId = formValues.identificationTypeId.value;
    newFormValues.idCountryOfOrigin = formValues.idCountryOfOrigin.value;
    newFormValues.dob = formValues.dateOfBirth
      ? tzNormalizeDate({
          date: formValues.dateOfBirth,
          format: INT_DATE_FORMAT,
        })
      : null;

    const response = edit
      ? await dispatchPatchCustomer(selectedCustomer.value, newFormValues)
      : await dispatchPostCustomer(newFormValues, false);

    if (response) {
      dispatchChange(form, name, customerGenerator(response));
      setShowDetailsButton(false);
      setCustomer(null);
      handleCloseModal();
    }
  };

  const handleShowModal = (initialValues = null) => {
    const title = initialValues ? 'Actualizar datos del ' : 'Registrar nuevo ';
    setModalTitle(title);

    const body = (
      <CustomerForm
        onSubmit={(formValues) => onSubmitCustomer(formValues, !!initialValues)}
        onModal
        mobilePhoneRequired={mobilePhoneRequired}
        initialValues={initialValues}
      />
    );
    setModalBody(body);

    setShowModal(true);
  };

  // To show data on customer form
  const onClickEditCustomer = () => {
    const initialValues = { ...customer };
    initialValues.dateOfBirth = customer.dob;

    if (customer.identificationType)
      initialValues.identificationTypeId = {
        value: customer.identificationType.id,
        label: customer.identificationType.name,
      };

    if (customer.idCountryOfOrigin)
      initialValues.idCountryOfOrigin = {
        value: customer.idCountryOfOrigin,
        label: customer.idCountryOfOrigin,
      };

    if (customer.gender)
      initialValues.gender =
        GENDERS.find(
          (customerGender) => customerGender.value === customer.gender,
        ) || null;

    handleShowModal(initialValues);
  };

  const handleNoCustomer = () => (
    <div>
      <p>No existe ningún {label} con ese nombre o número de documento.</p>
      <Button type="button" color="primary" onClick={() => handleShowModal()}>
        Nuevo {label}
      </Button>
    </div>
  );

  const modal = (
    <Modal
      show={showModal}
      title={`${modalTitle} ${label}`}
      onClickClose={handleCloseModal}
      body={modalBody}
      size="lg"
    />
  );

  let detailsButtonText = (
    <Fragment>
      <i className="fa fa-eye" /> Ver Datos
    </Fragment>
  );
  let editButton;
  let detailsResource;

  if (loading) detailsResource = <Loader />;

  if (showDetailsButton && customer) {
    detailsButtonText = (
      <Fragment>
        <i className="fa fa-eye-slash" /> Ocultar Datos
      </Fragment>
    );

    editButton = (
      <Button color="primary" outline size="sm" onClick={onClickEditCustomer}>
        <i className="fa fa-edit" /> Editar
      </Button>
    );
    detailsResource = <CustomerResource customer={customer} />;
  }

  const detailsButton = showDetails && selectedCustomer && (
    <ButtonToolbar className="mt-2">
      <div>
        <Button
          color="primary"
          outline
          size="sm"
          type="button"
          onClick={onClickViewDataCustomer}
        >
          {detailsButtonText}
        </Button>{' '}
        {editButton}
      </div>
    </ButtonToolbar>
  );

  const discountResource = showDiscountMessage && hasDiscountCode && (
    <Badge color="success" text="Este cliente tiene descuento" />
  );

  return (
    <>
      {modal}
      <FormGroup row>
        <FormItem label={label} required={labelRequired}>
          <CustomerField
            fieldName={name}
            validate={validate}
            noOptionsMessage={() => handleNoCustomer()}
            isDisabled={disabled}
            onChange={onChangeCustomer}
            options={options}
            autoFocus={autoFocus}
            isClearable={isClearable}
          />
          {discountResource}
          {detailsButton}
          {detailsResource}
        </FormItem>
      </FormGroup>
    </>
  );
};

CustomerInputGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelRequired: PropTypes.bool,
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func),
  dispatchPostCustomer: PropTypes.func.isRequired,
  dispatchClearCustomer: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  mobilePhoneRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onClickModalClose: PropTypes.func,
  dispatchPatchCustomer: PropTypes.func.isRequired,
  options: optionsPropTypes,
  showDetails: PropTypes.bool,
  showDiscountMessage: PropTypes.bool,
  autoFocus: PropTypes.bool,
  dispatchGetCustomer: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
};

CustomerInputGroup.defaultProps = {
  labelRequired: false,
  validate: [],
  disabled: false,
  mobilePhoneRequired: false,
  onChange: null,
  onClickModalClose: null,
  options: [],
  showDetails: false,
  showDiscountMessage: false,
  autoFocus: false,
  isClearable: false,
};

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchPostCustomer: postCustomer,
  dispatchClearCustomer: clearCustomer,
  dispatchPatchCustomer: patchCustomer,
  dispatchGetCustomer: getCustomer,
};

export default connect(null, mapDispatchToProps)(CustomerInputGroup);
