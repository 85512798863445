import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import FuelStationSelect from '../../../common/forms/select/FuelStationSelect';
import AgencySelect from '../../../common/forms/select/AgencySelect';
import { getFuelStation, clearFuelStation } from '../../../../actions';
import Loader from '../../../common/Loader';

export class AgencyToFuelStationForm extends Component {
  state = {
    fuelStationValues: {},
  };

  componentDidUpdate() {
    this.onUpdate();
  }

  componentWillUnmount() {
    this.props.dispatchClearFuelStation();
  }

  onUpdate = () => {
    const { fuelStation } = this.props;
    const { fuelStationId } = this.state.fuelStationValues;

    if (!fuelStation.isEmpty() && fuelStationId === undefined) {
      this.setState({
        fuelStationValues: {
          locationId: fuelStation.get('locationId'),
          businessId: fuelStation.get('businessId'),
          fuelStationId: fuelStation.get('id'),
        },
      });
    }
  };

  onHandleSubmit = (formValues) => {
    const { locationId, businessId, fuelStationId } =
      this.state.fuelStationValues;
    const newFuelStation = {
      fuelStationId,
      locationId,
      businessId,
      agencyId: formValues.agencyId.value,
    };
    this.props.onSubmit(newFuelStation);
  };

  handleFuelStationChange = (fuelStationOption) => {
    const { dispatchGetFuelStation, dispatchClearFuelStation } = this.props;
    if (fuelStationOption.value) {
      dispatchClearFuelStation();
      dispatchGetFuelStation({ id: fuelStationOption.value });
    }
  };

  render() {
    const { handleSubmit, loadingFuelStation } = this.props;

    if (loadingFuelStation) {
      return <Loader />;
    }

    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Grifo" required>
            <Field
              name="fuelStationId"
              component={FuelStationSelect}
              validate={[isRequired]}
              onChange={this.handleFuelStationChange}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Agencia" required>
            <Field
              name="agencyId"
              component={AgencySelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <div className="FormButtonGroup">
          <Button type="submit" color="primary" outline>
            Enviar
          </Button>
        </div>
      </Form>
    );
  }
}

AgencyToFuelStationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchGetFuelStation: PropTypes.func.isRequired,
  dispatchClearFuelStation: PropTypes.func.isRequired,
  fuelStation: PropTypes.instanceOf(Immutable.Map),
  loadingFuelStation: PropTypes.bool,
};

AgencyToFuelStationForm.defaultProps = {
  fuelStation: null,
  loadingFuelStation: false,
};

const mapStateToProps = ({ RouteUnit }) => ({
  loadingFuelStation: !RouteUnit.FuelStation.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  fuelStation: RouteUnit.FuelStation.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchGetFuelStation: getFuelStation,
  dispatchClearFuelStation: clearFuelStation,
};

const formComponent = reduxForm({
  form: 'AgencyToFuelStationForm',
})(AgencyToFuelStationForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
