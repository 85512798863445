export const FLAG_GETTING_MAINTENANCE_SERVICE_ORDERS =
  'FLAG_GETTING_MAINTENANCE_SERVICE_ORDERS';
export const GET_MAINTENANCE_SERVICE_ORDERS = 'GET_MAINTENANCE_SERVICE_ORDERS';
export const CLEAR_MAINTENANCE_SERVICE_ORDERS =
  'CLEAR_MAINTENANCE_SERVICE_ORDERS';
export const FLAG_MAINTENANCE_SERVICE_ORDER_ACTIVITY =
  'FLAG_MAINTENANCE_SERVICE_ORDER_ACTIVITY';
export const GET_MAINTENANCE_SERVICE_ORDER = 'GET_MAINTENANCE_SERVICE_ORDER';
export const CLEAR_MAINTENANCE_SERVICE_ORDER =
  'CLEAR_MAINTENANCE_SERVICE_ORDER';
