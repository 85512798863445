import React from 'react';
import PropTypes from 'prop-types';

// TODO test
const ResourceProperty = ({ label, boldLabel, children }) => {
  const labelComponent = boldLabel ? (
    <strong>{`${label} `}</strong>
  ) : (
    `${label} `
  );
  return (
    <div className="mb-2">
      {labelComponent}
      {children}
    </div>
  );
};

ResourceProperty.propTypes = {
  label: PropTypes.string.isRequired,
  boldLabel: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    PropTypes.element,
  ]),
};

ResourceProperty.defaultProps = {
  children: '',
};

ResourceProperty.defaultProps = {
  boldLabel: true,
};

export default ResourceProperty;
