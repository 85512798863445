import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EXPENSE_STATUS } from '../../../../config/constants';
import DropdownButton from '../../../common/button/DropdownButton';
import { enumToSelectOptions } from '../../../../utils/enum';
import { putExpenseStatus } from '../../../../actions/sales/ExpenseStatus';

class ExpenseStatusChangeDropdownButton extends Component {
  handleClick = async (value) => {
    const { expenseId, dispatchPutExpenseStatus } = this.props;

    await dispatchPutExpenseStatus({ expenseId, expenseStatus: value });
  };

  render() {
    const { expenseStatus } = this.props;
    const title = EXPENSE_STATUS[expenseStatus].label;
    const [approvedObject, rejectedObject, , solvedObject] =
      enumToSelectOptions(EXPENSE_STATUS);
    let dropdownOptions = null;
    switch (expenseStatus) {
      // Show options (APPROVED, REJECTED) when expense status is PENDING
      case EXPENSE_STATUS.PENDING.value:
        dropdownOptions = [approvedObject, rejectedObject];
        break;
      // Show option SOLVED when expense status is REJECTED
      case EXPENSE_STATUS.REJECTED.value:
        dropdownOptions = [solvedObject];
        break;
      default:
        break;
    }

    if (!dropdownOptions) return null;

    return (
      <DropdownButton
        title={title}
        options={dropdownOptions}
        handleClick={this.handleClick}
      />
    );
  }
}

ExpenseStatusChangeDropdownButton.propTypes = {
  dispatchPutExpenseStatus: PropTypes.func.isRequired,
  expenseId: PropTypes.number.isRequired,
  expenseStatus: PropTypes.string.isRequired,
};

const mapDispatchToProps = { dispatchPutExpenseStatus: putExpenseStatus };

export default connect(
  null,
  mapDispatchToProps,
)(ExpenseStatusChangeDropdownButton);
