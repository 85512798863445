import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import { BILLING_PROCESS_TYPE } from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import { enumToSelectOptions } from '../../../../utils/enum';

const BillingSearchForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Buscar por" required>
        <Field
          name="searchBy"
          component={Select}
          options={enumToSelectOptions(BILLING_PROCESS_TYPE)}
          isClearable={false}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Itinerario" required>
        <Field
          name="itineraryId"
          component={ItinerarySearchInput}
          validate={[isRequired]}
          props={{
            form: 'BillingSearchForm',
          }}
        />
      </FormItem>
    </FormGroup>
    <Row>
      <Col className="flex row-reverse">
        <Button type="submit" outline>
          <i className="fa fa-search" /> Buscar
        </Button>
      </Col>
    </Row>
  </Form>
);

BillingSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'BillingSearchForm',
})(BillingSearchForm);
