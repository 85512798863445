import React from 'react';
import { connect } from 'react-redux';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../../layout/Content';
import ItinerarySearch from '../ItinerarySearch';

const ItinerariesForCounter = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Búsqueda de Itinerarios"
    subtitle="Busca por ubicaciones, por fecha y hora"
    content={
      <ItinerarySearch
        sourceLocationIsRequired={false}
        destinationLocationIsRequired={false}
      />
    }
  />
);

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Búsqueda de Itinerarios',
      href: ITINERARY_FOR_COUNTER_PATH,
    },
  ],
});

ItinerariesForCounter.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

export default connect(mapStateToProps)(ItinerariesForCounter);
