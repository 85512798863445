import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getSeatMaps, clearSeatMaps } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import SelectFilter from '../../filters/SelectFilter';

class SeatMapSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearSeatMaps } = this.props;
    dispatchClearSeatMaps();
  }

  handleSeatMapChange = (inputValue) => {
    const { dispatchGetSeatMaps } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetSeatMaps({ query: inputValue });
    }
  };

  render() {
    const {
      options,
      isLoading,
      isMulti,
      input,
      meta,
      useForFilter,
      onChangeFilter,
      ...rest
    } = this.props;

    const component = !useForFilter ? (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        onInputChange={this.handleSeatMapChange}
        {...rest}
      />
    ) : (
      <SelectFilter
        isMulti
        onChangeFilter={onChangeFilter}
        onInputChange={this.handleSeatMapChange}
        options={options}
      />
    );

    return component;
  }
}

SeatMapSelect.propTypes = {
  dispatchGetSeatMaps: PropTypes.func.isRequired,
  dispatchClearSeatMaps: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  useForFilter: PropTypes.bool,
  onChangeFilter: PropTypes.func,
};

SeatMapSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  options: [],
  useForFilter: false,
  onChangeFilter: () => {},
};

const mapStateToProps = ({ BusUnit }) => ({
  options: BusUnit.SeatMap.getIn(['all', 'content', 'content']).map(
    (option) => ({
      value: option.id,
      label: option.name,
      floors: option.floors,
      numberOfFloors: option.numberOfFloors,
    }),
  ),
  loading: BusUnit.SeatMap.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetSeatMaps: getSeatMaps,
  dispatchClearSeatMaps: clearSeatMaps,
};

export default connect(mapStateToProps, mapDispatchToprops)(SeatMapSelect);
