import { shape, string, arrayOf, oneOfType, number } from 'prop-types';

const breadcrumbPropType = shape({
  text: string.isRequired,
  href: string.isRequired,
});

const breadcrumbsPropTypes = arrayOf(breadcrumbPropType);

const matchPropTypes = shape({
  params: shape({
    id: oneOfType([number, string]),
  }).isRequired,
});

export { breadcrumbPropType, breadcrumbsPropTypes, matchPropTypes };
