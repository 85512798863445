import Immutable from 'immutable';
import {
  FLAG_REQUIRES_TWO_STEP_AUTHORIZATION,
  FLAG_SENDING_TWO_STEP_AUTHORIZATION_REQUEST,
  SET_TWO_STEP_AUTHORIZATION_URL,
  SET_TWO_STEP_AUTHORIZATION_CALLBACK,
  SET_TWO_STEP_AUTHORIZATION_METHOD,
} from '../../../actions/types';

const INITIAL_STATE = new Immutable.Map({
  requiresTwoStepAuthentication: false,
  url: '',
  callback: null,
  sendingRequest: false,
  method: 'post',
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_REQUIRES_TWO_STEP_AUTHORIZATION:
      return state.set('requiresTwoStepAuthentication', action.payload);
    case FLAG_SENDING_TWO_STEP_AUTHORIZATION_REQUEST:
      return state.set('sendingRequest', action.payload);
    case SET_TWO_STEP_AUTHORIZATION_URL:
      return state.set('url', action.payload);
    case SET_TWO_STEP_AUTHORIZATION_CALLBACK:
      return state.set('callback', action.payload);
    case SET_TWO_STEP_AUTHORIZATION_METHOD:
      return state.set('method', action.payload);
    default:
      return state;
  }
};
