// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { handleResponseError } from '../../utils/error-handlers';
import {
  NOT_BOARDED_TICKET_REPORT_ENDPOINT,
} from '../../config/endpoints';
import {
  GET_NOT_BOARDED_TICKET_REPORTS,
  FLAG_GETTING_NOT_BOARDED_TICKET_REPORTS,
  CLEAR_ERROR_GETTING_NOT_BOARDED_TICKET_REPORTS,
  SHOW_ERROR_GETTING_NOT_BOARDED_TICKET_REPORTS,
  CLEAR_NOT_BOARDED_TICKET_REPORTS,
  CLEAR_ERROR_POSTING_NOT_BOARDED_TICKET_REPORT,
  POST_NOT_BOARDED_TICKET_REPORT,
  SHOW_ERROR_POSTING_NOT_BOARDED_TICKET_REPORT,
  FLAG_POSTING_NOT_BOARDED_TICKET_REPORT,
  DOWNLOAD_NOT_BOARDED_TICKET_REPORT,
  GET_NOT_BOARDED_TICKET_REPORT,
  SHOW_ERROR_GETTING_NOT_BOARDED_TICKET_REPORT,
  CLEAR_ERROR_GETTING_NOT_BOARDED_TICKET_REPORT,
  FLAG_GETTING_NOT_BOARDED_TICKET_REPORT,
  CLEAR_NOT_BOARDED_TICKET_REPORT,
} from '../types/index';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { getReportsServerEndpoint } from './Report';
import { NOT_BOARDED_TICKET_REPORTS_PATH } from '../../config/paths';
import { validateReportDateRange } from '../../utils/validators';

const flagGettingNotBoardedTicketReports = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_NOT_BOARDED_TICKET_REPORTS,
});

const showErrorGettingNotBoardedTicketReports = error => dispatch => dispatch({
  payload: error,
  type: SHOW_ERROR_GETTING_NOT_BOARDED_TICKET_REPORTS,
});

const clearErrorGettingNotBoardedTicketReports = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_NOT_BOARDED_TICKET_REPORTS,
});

const getNotBoardedTicketReports = async ({
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
} = {}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingNotBoardedTicketReports());
  dispatch(flagGettingNotBoardedTicketReports(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    // make request
    const url = reportsServerEndpoint + NOT_BOARDED_TICKET_REPORT_ENDPOINT;
    const query = {
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingNotBoardedTicketReports(false));
      return dispatch(showErrorGettingNotBoardedTicketReports(serverError));
    }
    const reports = await response.json();
    dispatch({
      type: GET_NOT_BOARDED_TICKET_REPORTS,
      payload: reports,
    });
    return dispatch(flagGettingNotBoardedTicketReports(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingNotBoardedTicketReports(false));
    const errMsg = 'Error obteniendo los reportes de boletos no abordados.';
    return dispatch(showErrorGettingNotBoardedTicketReports(errMsg));
  }
};

const clearNotBoardedTicketReports = () => dispatch => dispatch({
  type: CLEAR_NOT_BOARDED_TICKET_REPORTS,
});

const showErrorPostingNotBoardedTicketReport = error => dispatch => dispatch({
  type: SHOW_ERROR_POSTING_NOT_BOARDED_TICKET_REPORT,
  payload: error,
});

const clearErrorPostingNotBoardedTicketReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_POSTING_NOT_BOARDED_TICKET_REPORT,
});

const flagPostingNotBoardedTicketReport = flag => dispatch => dispatch({
  type: FLAG_POSTING_NOT_BOARDED_TICKET_REPORT,
  payload: flag,
});

const postNotBoardedTicketReport = async ({
  fromDate,
  toDate,
  force,
}) => async (dispatch, getState) => {
  dispatch(clearErrorPostingNotBoardedTicketReport());

  // validate before raising the flag and outside the
  // try/catch so that the error is caught by redux form
  validateReportDateRange(fromDate, toDate);

  // raise flag
  dispatch(flagPostingNotBoardedTicketReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    const query = {
      force,
    };
    // make request
    const url = reportsServerEndpoint + NOT_BOARDED_TICKET_REPORT_ENDPOINT;
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          fromDate,
          toDate,
        }),
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      return dispatch(showErrorPostingNotBoardedTicketReport(serverError));
    }
    const report = await response.json();
    dispatch({
      type: POST_NOT_BOARDED_TICKET_REPORT,
      payload: report,
    });
    dispatch(flagPostingNotBoardedTicketReport(false));
    // redirect to report
    return dispatch(push(`${NOT_BOARDED_TICKET_REPORTS_PATH}/${report.id}`));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagPostingNotBoardedTicketReport(false));
    const errMsg = 'Error en la creación del reporte de boletos no abordados.';
    return dispatch(showErrorPostingNotBoardedTicketReport(errMsg));
  }
};

const flagGettingNotBoardedTicketReport = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_NOT_BOARDED_TICKET_REPORT,
});

const clearErrorGettingNotBoardedTicketReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_NOT_BOARDED_TICKET_REPORT,
});

const showErrorGettingNotBoardedTicketReport = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_NOT_BOARDED_TICKET_REPORT,
  payload: error,
});

const getNotBoardedTicketReport = async ({
  id,
  reportFormat = 'json',
  download = false,
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingNotBoardedTicketReport());
  dispatch(flagGettingNotBoardedTicketReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    // make request
    const url = `${reportsServerEndpoint + NOT_BOARDED_TICKET_REPORT_ENDPOINT}/${id}`;
    const query = {
      reportFormat,
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingNotBoardedTicketReport(false));
      return dispatch(showErrorGettingNotBoardedTicketReport(serverError));
    }
    // parse json or text depending on format
    const text = reportFormat === 'csv';
    const report = text ? await response.text() : await response.json();
    if (download) {
      dispatch({
        type: DOWNLOAD_NOT_BOARDED_TICKET_REPORT,
        payload: report,
      });
    } else {
      dispatch({
        type: GET_NOT_BOARDED_TICKET_REPORT,
        payload: report,
      });
    }
    return dispatch(flagGettingNotBoardedTicketReport(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingNotBoardedTicketReport(false));
    const errMsg = `Error obteniendo el reporte de boletos no abordados #${id}.`;
    return dispatch(showErrorGettingNotBoardedTicketReport(errMsg));
  }
};

const clearNotBoardedTicketReport = () => dispatch => dispatch({
  type: CLEAR_NOT_BOARDED_TICKET_REPORT,
});

export {
  getNotBoardedTicketReports,
  clearErrorGettingNotBoardedTicketReports,
  clearNotBoardedTicketReports,
  clearErrorPostingNotBoardedTicketReport,
  postNotBoardedTicketReport,
  getNotBoardedTicketReport,
  clearNotBoardedTicketReport,
  clearErrorGettingNotBoardedTicketReport,
};
