import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText, CardTitle, Col, Row } from 'reactstrap';
import { DATE_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';
import { numberFormatter } from '../../../../utils/number';
import { padStart } from '../../../../utils/string';

const PreviewTripExpense = ({ expenseList }) => {
  const labelWidth = 5;
  const valueWidth = 7;

  const previewComponent = expenseList.map((expense, index) => {
    const document = `${expense.documentSeries}-${padStart(
      expense.documentCode,
      7,
    )}`;

    return (
      <Card body className="mb-2 bg-light" key={+index}>
        <CardTitle className="text-center">
          <strong>Salida de Caja</strong>
          <br />
          <small>{document}</small>
        </CardTitle>
        <div className="card-text">
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>Fecha:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <span>
                {expense.date
                  ? tzNormalizeDate({ date: expense.date, format: DATE_FORMAT })
                  : tzNormalizeDate({ format: DATE_FORMAT })}
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>Conductor:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <span>
                <i>Aquí el conductor que seleccione</i>
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>N° Bus:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <span>{expense.companyBusId.toString()}</span>
            </Col>
          </Row>
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>Itinerario:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <span>{expense.itinerarySourceAndDestination}</span>
            </Col>
          </Row>
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>Peaje:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <span>{numberFormatter({ value: expense.tollPayment })}</span>
            </Col>
          </Row>
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>Condición de Trabajo:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <span>
                {numberFormatter({ value: expense.commissionPayment })}
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>Otros Pagos:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <span>{numberFormatter({ value: expense.otherPayment })}</span>
            </Col>
          </Row>
          <Row>
            <Col sm={labelWidth}>
              <span>
                <strong>Total a Pagar:</strong>
              </span>
            </Col>
            <Col sm={valueWidth}>
              <Card body>
                <CardText className="text-center">
                  <strong>
                    {numberFormatter({ value: expense.totalPayment })}
                  </strong>
                </CardText>
              </Card>
            </Col>
          </Row>
        </div>
      </Card>
    );
  });

  return previewComponent;
};

PreviewTripExpense.propTypes = {
  expenseList: PropTypes.arrayOf(
    PropTypes.shape({
      documentSeries: PropTypes.string,
      documentCode: PropTypes.string,
      date: PropTypes.number,
      companyBusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      itinerarySourceAndDestination: PropTypes.string,
      tollPayment: PropTypes.number,
      commissionPayment: PropTypes.number,
      otherPayment: PropTypes.number,
      totalPayment: PropTypes.number,
    }),
  ).isRequired,
};

export default PreviewTripExpense;
