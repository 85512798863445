import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { expenseTypeSummaryListPropTypes } from './SalesSessionPropTypes';
import CollapsibleHeader from '../../../layout/CollapsibleHeader';
import ExpenseTable from './ExpenseTable';
import { numberFormatter } from '../../../../utils/number';

const ExpenseSummary = ({
  expenseTypeSummaryList,
  salesSessionId,
  totalExpense,
  numberOfExpenses,
}) => {
  if (!expenseTypeSummaryList.length) {
    return null;
  }

  return (
    <>
      <hr />
      <CollapsibleHeader
        headerText="Egresos"
        component={ExpenseTable}
        salesSessionId={salesSessionId}
      />
      <Row>
        <Col>
          <ResourceProperty label="Cantidad: " boldLabel={false}>
            {numberOfExpenses}
          </ResourceProperty>
        </Col>
      </Row>
      <Row>
        <Col>
          {expenseTypeSummaryList.map((expenseType) => (
            <ResourceProperty
              key={expenseType.moneyFlowConcept}
              label={`${expenseType.moneyFlowConcept}: `}
            >
              {numberFormatter({ value: expenseType.totalAmount })}
            </ResourceProperty>
          ))}
          <ResourceProperty label="Total de Egresos: ">
            {numberFormatter({ value: totalExpense })}
          </ResourceProperty>
        </Col>
      </Row>
    </>
  );
};

ExpenseSummary.propTypes = {
  expenseTypeSummaryList: expenseTypeSummaryListPropTypes.isRequired,
  salesSessionId: PropTypes.number.isRequired,
  totalExpense: PropTypes.number.isRequired,
  numberOfExpenses: PropTypes.number.isRequired,
};

export default ExpenseSummary;
