import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { TICKET_TAXABLE_INFORMATION_PATH } from '../../../../config/paths';
import { patchTicketTaxableInformation } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import TicketTaxableInformationForm from './TicketTaxableInformationForm';

export class EditTicketTaxableInformation extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      ticketId: formValues.ticketId.value,
      customerId: formValues.customerId.value,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      identificationTypeId: formValues.identificationTypeId.value,
      idDocumentNumber: formValues.idDocumentNumber,
      idCountryOfOrigin: formValues.idCountryOfOrigin,
      businessId: formValues.businessId ? formValues.businessId.value : null,
      businessTaxId: formValues.businessId ? formValues.businessTaxId : null,
      name: formValues.businessId ? formValues.name : null,
    };

    this.props.dispatchPatchTicketTaxableInformation(
      newFormValues.ticketId,
      newFormValues,
    );
  };

  render() {
    const { breadcrumbs } = this.props;

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Control de Ingresos - Boletos"
        subtitle="Modifica los datos de un boleto"
        content={<TicketTaxableInformationForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Control de Ingresos - Boletos',
      href: TICKET_TAXABLE_INFORMATION_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPatchTicketTaxableInformation: patchTicketTaxableInformation,
};

EditTicketTaxableInformation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPatchTicketTaxableInformation: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTicketTaxableInformation);
