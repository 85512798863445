import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { getTicket, clearTicket } from '../../../../actions/index';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import TicketVoid from './TicketVoid';
import TicketToolbar from './ticket-toolbar/TicketToolbar';
import { TICKET_PATH } from '../../../../config/paths';
import CreditNote from './CreditNote';
import { TICKET_STATUS } from '../../../../config/constants';
import Refund from './Refund';
import History from './History';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { numberFormatter } from '../../../../utils/number';
import EnumStatus from '../../../common/EnumStatus';

export class Ticket extends Component {
  static propTypes = {
    breadcrumbs: breadcrumbsPropTypes,
    dispatchGetTicket: PropTypes.func.isRequired,
    dispatchClearTicket: PropTypes.func.isRequired,
    ticket: PropTypes.instanceOf(Immutable.Map).isRequired,
    match: matchPropTypes.isRequired,
    activity: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    breadcrumbs: [],
  };

  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const { dispatchGetTicket } = this.props;
    dispatchGetTicket({ ticketId: id });
  }

  componentDidUpdate(prevProps) {
    const { id } = prevProps.match.params;
    const newId = this.props.match.params.id;
    const { dispatchGetTicket } = this.props;
    if (id !== newId) {
      dispatchGetTicket({ ticketId: id });
    }
  }

  componentWillUnmount() {
    const { dispatchClearTicket } = this.props;
    dispatchClearTicket();
  }

  render() {
    const {
      ticket,
      match: {
        params: { id: ticketId },
      },
      breadcrumbs,
    } = this.props;

    let ticketVoidComponent = null;
    let creditNoteComponent = null;
    let refundComponent = null;

    let content;
    let toolbar;
    let title = 'Boleto';

    if (this.props.activity) {
      content = <Loader />;
    } else if (ticket.isEmpty()) {
      content = <NoDataResource returnPage={TICKET_PATH} />;
    } else {
      const ticketJson = ticket.toJS();

      const {
        creditNote,
        nextTicketId,
        previousTicketId,
        ticketStatus: status,
        sourceLocation,
        destinationLocation,
        departureDate,
        seatReservation,
        nextTicketCode,
        previousTicketCode,
        customer,
        listPrice,
        ticketVoid,
        user,
        transactions,
        fullDocumentCode,
        documentAssignmentDate,
      } = ticketJson;

      const {
        voucherType: { id: voucherTypeId },
        business,
        voucherType,
        paymentMethod,
      } = transactions[0];

      const showHistory = nextTicketId || previousTicketId;

      const tripData = (
        <>
          <h3>Viaje</h3>
          <ResourceProperty label="Origen:">
            {sourceLocation.name}
          </ResourceProperty>
          <ResourceProperty label="Destino:">
            {destinationLocation.name}
          </ResourceProperty>
          <ResourceProperty label="Embarque:">
            {`${sourceLocation.name} - ${sourceLocation.address}`}
          </ResourceProperty>
          <ResourceProperty label="Desembarque:">
            {`${destinationLocation.name} - ${destinationLocation.address}`}
          </ResourceProperty>
          <ResourceProperty label="Fecha de Salida:">
            {departureDate
              ? tzNormalizeDate({
                  date: departureDate,
                  format: DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Asiento:">
            {seatReservation ? seatReservation.seatNumber : '-'}
          </ResourceProperty>
          <ResourceProperty label="Piso:">
            {seatReservation ? seatReservation.floorNumber : '-'}
          </ResourceProperty>
        </>
      );

      const passengerData = (
        <>
          <h3>Pasajero</h3>
          <ResourceProperty label="Nombre Completo:">
            {customer.fullName}
          </ResourceProperty>
          <ResourceProperty label="Documento de identificación:">
            {`${customer.identificationType.name} ${customer.idDocumentNumber}`}
          </ResourceProperty>
          <ResourceProperty label="País:">
            {`${customer.idCountryOfOrigin}`}
          </ResourceProperty>
          <ResourceProperty label="Celular:">
            {customer.mobilePhone || '-'}
          </ResourceProperty>
          <ResourceProperty label="Email:">
            {customer.email || '-'}
          </ResourceProperty>
        </>
      );

      const paymentData = (
        <>
          <h3>Pago</h3>
          <ResourceProperty label="Método de Pago:">
            {paymentMethod.name}
          </ResourceProperty>
          <ResourceProperty label="Precio de Lista:">
            {listPrice &&
              numberFormatter({
                value: listPrice,
              })}
          </ResourceProperty>
          <ResourceProperty label="Precio de Venta:">
            {numberFormatter({
              value: ticketJson.salePrice,
            })}
          </ResourceProperty>
        </>
      );

      if (creditNote)
        creditNoteComponent = <CreditNote creditNote={creditNote} />;

      const businessData = business && (
        <>
          <h3>Factura</h3>
          <ResourceProperty label="RUC:">
            {business.businessTaxId}
          </ResourceProperty>
          <ResourceProperty label="Razón Social:">
            {business.name}
          </ResourceProperty>
        </>
      );

      const userData = (
        <>
          <h3>Usuario</h3>
          <ResourceProperty label="Nombre Completo:">
            {user.customer.fullName}
          </ResourceProperty>
        </>
      );

      if (ticketVoid)
        ticketVoidComponent = <TicketVoid ticketVoid={ticketVoid} />;

      if (status === TICKET_STATUS.REFUNDED.value) refundComponent = <Refund />;

      content = (
        <>
          <Row>
            <Col lg={6} md={12}>
              <ResourceProperty label="Tipo de Comprobante:">
                {voucherType.name}
              </ResourceProperty>
              <ResourceProperty label="Fecha de Emisión:">
                {documentAssignmentDate
                  ? tzNormalizeDate({
                      date: documentAssignmentDate,
                      format: DATE_TIME_FORMAT,
                    })
                  : null}
              </ResourceProperty>
              <ResourceProperty label="Estado:">
                <EnumStatus enumObject={TICKET_STATUS} enumValue={status} />
              </ResourceProperty>
              {tripData}
              {passengerData}
              {paymentData}
              {businessData}
              {userData}
            </Col>
            <Col lg={6} md={12}>
              {showHistory && (
                <History
                  nextTicketId={nextTicketId}
                  nextTicketCode={nextTicketCode}
                  previousTicketId={previousTicketId}
                  previousTicketCode={previousTicketCode}
                />
              )}
              {ticketVoidComponent}
              {creditNoteComponent}
              {refundComponent}
            </Col>
          </Row>
        </>
      );

      toolbar = (
        <TicketToolbar
          creditNote={creditNote || null}
          ticketId={+ticketId}
          ticketStatus={status}
          voucherTypeId={voucherTypeId}
          departureDate={departureDate}
        />
      );

      title = `Boleto ${fullDocumentCode}`;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title={title}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ ReservationUnit }) => ({
  breadcrumbs: [
    ...ReservationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos',
      href: TICKET_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  ticket: ReservationUnit.Ticket.getIn(['current', 'content']),
  activity: !ReservationUnit.Ticket.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetTicket: getTicket,
  dispatchClearTicket: clearTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
