import React from 'react';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../../utils/date';
import {
  MAINTENANCE_WARNING_PRIORITY,
  MAINTENANCE_WARNING_STATUS,
  MAINTENANCE_WARNING_TYPE,
} from '../../../../../config/constants';
import EnumStatus from '../../../../common/EnumStatus';
import {
  maintenanceWarningBasicDefaultProps,
  maintenanceWarningBasicInformationPropTypes,
} from '../prop-types/MaintenanceWarningPropTypes';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../../config/paths';
import Badge from '../../../../common/Badge';
import { numberFormatter } from '../../../../../utils/number';

const MaintenanceWarningBasicInformation = ({
  issueDate,
  registeredBus,
  maintenanceFailMode,
  maintenanceWarningType,
  priority,
  comments,
  reporterCustomer,
  status,
  serviceOrderId,
  actualVirtualOdometer,
  actualRegisteredBusOdometer,
  recurrence,
}) => (
  <>
    <ResourceProperty label="Reportado por:">
      {reporterCustomer ? reporterCustomer.fullName : '-'}
    </ResourceProperty>
    <ResourceProperty label="Fecha y hora:">
      {tzNormalizeDate({ date: issueDate, format: DATE_TIME_FORMAT })}
    </ResourceProperty>
    <ResourceProperty label="Bus:">
      {registeredBus.companyBusId}
    </ResourceProperty>
    <ResourceProperty label="KM Actual:">
      {actualVirtualOdometer
        ? numberFormatter({
            style: 'decimal',
            value: actualVirtualOdometer,
            minimumFractionDigits: 0,
          })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="KM Ocurrencia:">
      {actualRegisteredBusOdometer
        ? numberFormatter({
            style: 'decimal',
            value: actualRegisteredBusOdometer,
            minimumFractionDigits: 0,
          })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Tipo de Ocurrencia:">
      {maintenanceWarningType
        ? MAINTENANCE_WARNING_TYPE[maintenanceWarningType].label
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Modo Falla:">
      {maintenanceFailMode ? maintenanceFailMode.description : '-'}
    </ResourceProperty>
    <ResourceProperty label="Prioridad:">
      {priority ? (
        <Badge
          text={MAINTENANCE_WARNING_PRIORITY[priority].label}
          color={MAINTENANCE_WARNING_PRIORITY[priority].color}
        />
      ) : (
        '-'
      )}
    </ResourceProperty>
    <ResourceProperty label="Reincidencia:">{recurrence}</ResourceProperty>
    <ResourceProperty label="Comentario:">{comments}</ResourceProperty>
    <ResourceProperty label="Estado:">
      <EnumStatus enumObject={MAINTENANCE_WARNING_STATUS} enumValue={status} />
    </ResourceProperty>
    <ResourceProperty label="Orden de Servicio:">
      {serviceOrderId ? (
        <LinkToModuleResource
          text={serviceOrderId}
          href={`${MAINTENANCE_SERVICE_ORDER_PATH}/${serviceOrderId}`}
          isExternalPath
        />
      ) : (
        '-'
      )}
    </ResourceProperty>
  </>
);

MaintenanceWarningBasicInformation.propTypes =
  maintenanceWarningBasicInformationPropTypes;

MaintenanceWarningBasicInformation.defaultProps =
  maintenanceWarningBasicDefaultProps;

export default MaintenanceWarningBasicInformation;
