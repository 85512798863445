import Immutable from 'immutable';
import {
  CLEAR_FUEL_CONSUMPTION_REPORT_PER_DRIVER,
  FLAG_FUEL_CONSUMPTION_REPORT_PER_DRIVER_ACTIVITY,
  POST_FUEL_CONSUMPTION_REPORT_PER_DRIVER,
} from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_FUEL_CONSUMPTION_REPORT_PER_DRIVER_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case POST_FUEL_CONSUMPTION_REPORT_PER_DRIVER:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_FUEL_CONSUMPTION_REPORT_PER_DRIVER:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
