import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditItineraryButton from './EditItineraryButton';
import StatusItineraryButton from './StatusItineraryButton';
import PrintDropdownButton from '../PrintDropdownButton';
import PrintFuelVoucherButton from '../PrintFuelVoucherButton';
import PrintExpenseButton from '../PrintExpenseButton';
import QualityControlButton from './QualityControlButton';
import BaggageItemMovementButton from './BaggageItemMovementButton';
import ItinerarySendGrtButton from '../../itinerary/itinerary/ItinerarySendGrtButton';
import ItinerarySendGrrButton from '../../itinerary/itinerary/ItinerarySendGrrButton';
import ViewGuidesButton from '../billings-for-itinerary/ViewGuidesButton';
import DeleteBillingsByItineraryButton from '../billings-for-itinerary/DeleteBillingsByItineraryButton';

const ItineraryForCounterToolbar = ({ itineraryId, drivers }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <BaggageItemMovementButton itineraryId={itineraryId} />{' '}
      <QualityControlButton itineraryId={itineraryId} /> <PrintDropdownButton />{' '}
      <PrintExpenseButton drivers={drivers} /> <PrintFuelVoucherButton />{' '}
      <StatusItineraryButton />{' '}
      <EditItineraryButton itineraryId={itineraryId} />{' '}
      <ItinerarySendGrtButton itineraryId={itineraryId} />{' '}
      <ItinerarySendGrrButton itineraryId={itineraryId} />{' '}
      <DeleteBillingsByItineraryButton itineraryId={itineraryId} />{' '}
      <ViewGuidesButton itineraryId={itineraryId} />
    </div>
  </ButtonToolbar>
);

ItineraryForCounterToolbar.propTypes = {
  itineraryId: PropTypes.number.isRequired,
  drivers: PropTypes.instanceOf(Immutable.Set).isRequired,
};

export default ItineraryForCounterToolbar;
