import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { printExcessBaggage } from '../../../../../src/utils/printers/ExcessBaggageTicket';
import { getPrintExcessBaggage } from '../../../../actions';

const PrintExcessBaggageTicketButton = ({
  baggage,
  dispatchGetPrintExcessBaggage,
}) => {
  const handlePrint = async () => {
    const excess = await dispatchGetPrintExcessBaggage({
      excessBaggageId: baggage.get('excessBaggage').id,
    });

    if (excess) printExcessBaggage(excess);
  };

  if (baggage.get('excessBaggage')) {
    return (
      <Button color="light" className="border" onClick={handlePrint}>
        <i className="fa fa-print" /> Exceso de Equipaje
      </Button>
    );
  }

  return null;
};

PrintExcessBaggageTicketButton.propTypes = {
  baggage: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetPrintExcessBaggage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ BaggageUnit }) => ({
  baggage: BaggageUnit.Baggage.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchGetPrintExcessBaggage: getPrintExcessBaggage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintExcessBaggageTicketButton);
