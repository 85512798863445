import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, FormGroup } from 'reactstrap';
import FormFooter from '../../../../common/forms/FormFooter';
import { isRequired, validateEmail } from '../../../../../utils/validators';
import FormItem from '../../../../common/forms/FormItem';
import TextInput from '../../../../common/forms/input/TextInput';

const SendTicketForm = ({ handleSubmit, onCancel }) => (
  <>
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Email" required>
          <Field
            name="email"
            component={TextInput}
            type="text"
            placeholder="ejemplo@gmail.com"
            validate={[isRequired, validateEmail]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter
        saveButtonColor="secondary"
        saveButtonIcon={null}
        saveButtonText="Enviar"
      >
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  </>
);

SendTicketForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'SendTicketForm',
})(SendTicketForm);
