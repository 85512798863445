import React from 'react';
import {
  maintenanceServiceOrderActivityBusExecutionListDefaultProps,
  maintenanceServiceOrderInternalInformationPropTypes,
} from '../prop-types/MaintenanceServiceOrderPropTypes';
import Alert from '../../../../common/informative/Alert';
import Table from '../../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { MAINTENANCE_SERVICE_ORDER_ACTIVITY_BUS_EXECUTION_LIST_COLUMNS } from '../../../../../config/columns';
import { SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE } from '../../../../../config/messages';
import {
  ACTIVITY_STATUS,
  ACTIVITY_TASK_EXECUTION_STATUS,
} from '../../../../../config/constants';

const MaintenanceServiceOrderActivityBusExecutionListInformation = ({
  activityBusExecutionList,
}) => {
  const data = [];
  activityBusExecutionList.forEach(
    ({
      activityBus: { activity },
      employeeList,
      activityTaskExecutionList,
      status: activityBusExecutionStatus,
    }) => {
      data.push({
        activityName: activity.name,
        taskName: null,
        employeeList: employeeList
          .map(({ employee }) => employee.customer.fullName)
          .join(', '),
        status: activityBusExecutionStatus
          ? ACTIVITY_STATUS[activityBusExecutionStatus].label
          : '-',
        newBlock: true,
      });

      activity.activityTaskList.forEach(
        ({
          description,
          id: activityTaskId,
          status: activityTaskExecutionStatus,
        }) => {
          const activityTaskExecutionFound = activityTaskExecutionList.find(
            (activityTaskExecution) =>
              activityTaskExecution.activityTaskId === activityTaskId,
          );
          const taskEmployeeList = activityTaskExecutionFound
            ? activityTaskExecutionFound.employee.customer.fullName
            : null;
          data.push({
            activityName: activity.name,
            taskName: description,
            employeeList: taskEmployeeList,
            status: activityTaskExecutionStatus
              ? ACTIVITY_TASK_EXECUTION_STATUS[activityTaskExecutionStatus]
                  .label
              : '-',
            newBlock: false,
          });
        },
      );
    },
  );

  if (activityBusExecutionList.length === 0)
    return (
      <Alert
        type="danger"
        message={SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE}
      />
    );

  return (
    <Table
      columns={MAINTENANCE_SERVICE_ORDER_ACTIVITY_BUS_EXECUTION_LIST_COLUMNS}
      data={data}
      filterable
      manual={false}
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
  );
};

MaintenanceServiceOrderActivityBusExecutionListInformation.propTypes =
  maintenanceServiceOrderInternalInformationPropTypes;

MaintenanceServiceOrderActivityBusExecutionListInformation.defaultProps =
  maintenanceServiceOrderActivityBusExecutionListDefaultProps;

export default MaintenanceServiceOrderActivityBusExecutionListInformation;
