import Immutable from 'immutable';
import {
  FLAG_GETTING_COST_CENTERS,
  GET_COST_CENTERS,
  CLEAR_COST_CENTERS,
  FLAG_COST_CENTER_ACTIVITY,
  CLEAR_COST_CENTER,
  GET_COST_CENTER,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_COST_CENTERS:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_COST_CENTERS:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_COST_CENTERS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_COST_CENTER_ACTIVITY:
      if (action.payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(action.payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_COST_CENTER:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case CLEAR_COST_CENTER:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
