import { number, string } from 'prop-types';

const paymentMethodBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string,
  transactionCode: string,
};

const paymentMethodBasicInformationDefaultProps = {
  description: null,
  transactionCode: null,
};

export {
  paymentMethodBasicInformationPropTypes,
  paymentMethodBasicInformationDefaultProps,
};
