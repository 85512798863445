import React from 'react';
import BadgeList from '../../../../common/BadgeList';
import {
  userSecurityProfileInformationDefaultProps,
  userSecurityProfileInformationPropTypes,
} from '../proptypes/UserPropTypes';

const UserSecurityProfileResource = ({ securityProfileSet }) => {
  let content = (
    <p className="text-muted">
      <i>No hay perfiles asignados</i>
    </p>
  );

  if (securityProfileSet.length) {
    const securityProfileList = securityProfileSet.map(({ name }) => ({
      text: name,
    }));

    content = (
      <div className="mb-3">
        <BadgeList textArray={securityProfileList} />
      </div>
    );
  }

  return content;
};

UserSecurityProfileResource.propTypes = userSecurityProfileInformationPropTypes;

UserSecurityProfileResource.defaultProps =
  userSecurityProfileInformationDefaultProps;

export default UserSecurityProfileResource;
