import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import BusinessSelect from '../select/BusinessSelect';
import { optionsPropTypes } from '../select/SelectPropTypes';

const BusinessField = ({
  fieldName,
  onChange,
  noOptionsMessage,
  isDisabled,
  validate,
  placeholder,
  isClearable,
  options,
}) => (
  <>
    <Field
      name={fieldName}
      component={BusinessSelect}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled}
      validate={validate}
      placeholder={placeholder}
      isClearable={isClearable}
      options={options}
    />
  </>
);

BusinessField.propTypes = {
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
  isClearable: PropTypes.bool,
  options: optionsPropTypes,
};

BusinessField.defaultProps = {
  fieldName: '',
  onChange: undefined,
  noOptionsMessage: undefined,
  isDisabled: false,
  placeholder: 'Ingrese Razón Social o RUC',
  validate: undefined,
  isClearable: false,
  options: [],
};

export default BusinessField;
