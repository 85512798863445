import autoTable from 'jspdf-autotable';
import {
  addImage,
  addText,
  createPdfDocument,
  setFont,
  subtitleSize,
  titleSize,
  x,
  y,
} from '../pdf';
import LogoCiva from '../../resources/images/logo-civa.png';

const myX = x;
let myY = y;
let pageWidth = 0;
let halfPage = 0;
let thirdPartPage = 0;

const printBaggages = (doc, baggages) => {
  const columns = [
    { title: 'N°', dataKey: 'item' },
    { title: 'N° Boleto', dataKey: 'ticket' },
    { title: 'N° Ticket Equipaje', dataKey: 'itemTicket' },
    { title: 'Embarque', dataKey: 'origin' },
    { title: 'Desembarque', dataKey: 'destination' },
    { title: 'Descripción', dataKey: 'description' },
    { title: 'Exceso Peso', dataKey: 'excessWeight' },
    { title: 'Comprobante', dataKey: 'excessTicket' },
  ];

  let rowNumber = 0;

  const data = [];

  baggages.forEach((baggage) => {
    let tickets = '';
    let sourceLocations = '';
    let destinationLocations = '';

    baggage.ticketList.forEach((ticket) => {
      tickets += `${ticket.ticketCode}\n`;
      sourceLocations += `${ticket.sourceLocation.name}\n`;
      destinationLocations += `${ticket.destinationLocation.name}\n`;
    });

    tickets = tickets.trimEnd();
    sourceLocations = sourceLocations.trimEnd();
    destinationLocations = destinationLocations.trimEnd();

    baggage.itemList.forEach((item) => {
      rowNumber += 1;

      const row = {
        item: rowNumber,
        ticket: tickets,
        itemTicket: item.itemCorrelation,
        origin: sourceLocations,
        destination: destinationLocations,
        description: item.description,
        excessWeight: baggage.excessBaggage
          ? baggage.excessBaggage.weight
          : null,
        excessTicket: baggage.excessBaggage
          ? baggage.excessBaggage.fullDocumentCode
          : null,
      };

      data.push(row);
    });
  });

  autoTable(doc, {
    columns,
    body: data,
    startY: (myY += 5),
    showHeader: 'everyPage',
    margin: 5,
    theme: 'striped',
    headStyles: { fillColor: [255, 255, 255] },
    styles: {
      overflow: 'hidden',
      cellPadding: 0.5,
      textColor: 0,
    },
  });
};

const printBaggageManifest = (manifest) => {
  const doc = createPdfDocument();

  pageWidth = doc.internal.pageSize.getWidth();
  halfPage = pageWidth / 2;
  thirdPartPage = pageWidth / 3;
  myY = 10;

  let previousLineHeight = 0;

  addImage(doc, LogoCiva, myX, 3, 35, 10);

  // Title
  doc.setFontSize(titleSize);
  setFont(doc, 'bold');
  addText(doc, 'MANIFIESTO DE EQUIPAJES', halfPage, myY, halfPage);
  setFont(doc);

  doc.setFontSize(subtitleSize);

  // 1° line
  addText(doc, `Fecha: ${manifest.tripDate}`, myX, (myY += 10));

  const origin = doc.splitTextToSize(
    `Origen: ${manifest.origin}`,
    thirdPartPage - myX / 2,
  );
  addText(doc, origin, thirdPartPage, myY);

  const destination = doc.splitTextToSize(
    `Destino: ${manifest.destination}`,
    thirdPartPage - myX / 2,
  );
  addText(doc, destination, thirdPartPage * 2, myY);

  previousLineHeight =
    destination.length > origin.length ? destination.length : origin.length;

  // 2° line
  addText(
    doc,
    `Manifiesto: ${manifest.manifestNumber}`,
    myX,
    (myY += 5 * previousLineHeight),
  );

  addText(doc, `ID Itinerario: ${manifest.itineraryId}`, thirdPartPage, myY);

  // 3° line
  addText(doc, `Hora de Salida: ${manifest.tripTime}`, myX, (myY += 10));

  addText(doc, `Servicio: ${manifest.serviceTypeName}`, halfPage / 2, myY);

  addText(doc, `# Bus: ${manifest.bus.companyBusId}`, halfPage - 10, myY);

  addText(doc, 'Conductores:', halfPage + 20, myY);
  manifest.drivers.forEach((driver, index) => {
    let driverFullName = driver.fullName;
    if (driverFullName.length > 30)
      driverFullName = `${driverFullName.substring(0, 30)}...`;
    addText(doc, driverFullName, halfPage + 45, index !== 0 ? (myY += 5) : myY);
  });

  // 4° line
  addText(doc, `Manifiesto emitido por: ${manifest.user}`, myX, (myY += 5));

  // Print baggage manifest
  printBaggages(doc, manifest.baggageList);

  window.open(doc.output('bloburl'), '_blank');
};

// eslint-disable-next-line import/prefer-default-export
export { printBaggageManifest };
