import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Form, FormGroup, Container, Label, InputGroupText } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import FuelStationSelect from '../../../common/forms/select/FuelStationSelect';
import Alert from '../../../common/informative/Alert';
import { FUEL_VOUCHER_PATH } from '../../../../config/paths';
import { getActiveSalesSession } from '../../../../actions';
import DatePicker from '../../../common/forms/input/DatePicker';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import { CURRENCY } from '../../../../config/locale';
import FormFooter from '../../../common/forms/FormFooter';

export class FuelVoucherForm extends Component {
  state = {
    enableControls: false,
  };

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const {
      dispatchGetActiveSalesSession,
      initialValues: { numberOfGallons, extraordinary },
    } = this.props;
    dispatchGetActiveSalesSession({ source: FUEL_VOUCHER_PATH });
    if (numberOfGallons && numberOfGallons !== 0) {
      this.setState({
        enableControls: !extraordinary,
      });
    }
  };

  renderFields = () => {
    const { enableControls } = this.state;
    const { numberOfGallons } = this.props.initialValues;
    const commonFields = (
      <div>
        <FormGroup row>
          <FormItem label="Cantidad de Galones:" required>
            <Field
              name="numberOfGallons"
              component={TextInput}
              type="text"
              placeholder="Cantidad de Galones"
              validate={[isRequired, validateNumber]}
              disabled={enableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Número de Bus:" required>
            <Field
              name="companyBusId"
              component={TextInput}
              type="text"
              placeholder="Número de Bus"
              validate={[isRequired, validateIntegerNumber]}
              disabled={enableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Beneficiario:" required={!enableControls}>
            <Field
              name="fuelReceiverName"
              component={TextInput}
              type="text"
              placeholder="Beneficiario"
              validate={!enableControls ? [isRequired] : null}
              disabled={enableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Itinerario:">
            <Field
              name="itineraryId"
              component={ItinerarySearchInput}
              props={{
                form: 'FuelVoucherForm',
              }}
              disabled={enableControls}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Grifo:" required>
            <Field
              name="fuelStationId"
              component={FuelStationSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentarios:">
            <Field
              name="comments"
              component={TextInput}
              type="textarea"
              placeholder="Comentarios"
            />
          </FormItem>
        </FormGroup>
      </div>
    );
    let fields = null;
    if (!numberOfGallons && numberOfGallons !== 0) {
      // Create Form
      fields = (
        <div>
          <FormGroup row>
            <FormItem label="Válido Desde:">
              <Field
                name="validFrom"
                component={DatePicker}
                placeholder="Válido Desde"
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Válido Hasta:">
              <Field
                name="validTo"
                component={DatePicker}
                placeholder="Válido Hasta"
              />
            </FormItem>
          </FormGroup>
          {commonFields}
        </div>
      );
    } else {
      // Edit Form
      fields = (
        <div>
          <FormGroup row>
            <FormItem label="Serie:">
              <Field
                name="documentSeries"
                component={TextInput}
                type="text"
                placeholder="Serie"
                disabled
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Correlativo:">
              <Field
                name="documentCode"
                component={TextInput}
                type="text"
                placeholder="Correlativo"
                disabled
                validate={[validateIntegerNumber]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Monto:">
              <Field
                name="amount"
                component={TextInput}
                type="text"
                placeholder="Monto"
                disabled
                validate={[validateIntegerNumber]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem>
              <Label>
                <Field
                  name="isSettled"
                  component="input"
                  type="checkbox"
                  disabled
                />{' '}
                Pagado
              </Label>
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem>
              <Label>
                <Field
                  name="redeemed"
                  component="input"
                  type="checkbox"
                  disabled
                />{' '}
                Abastecido
              </Label>
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem>
              <Label>
                <Field
                  name="extraordinary"
                  component="input"
                  type="checkbox"
                  disabled
                />{' '}
                Extraordinario
              </Label>
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Precio por Galón:" required={!enableControls}>
              <Field
                name="pricePerGallon"
                component={TextInput}
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Precio por Galón"
                validate={
                  !enableControls
                    ? [isRequired, validateNumber]
                    : [validateNumber]
                }
                disabled={enableControls}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Cantidad de Galones Reales:">
              <Field
                name="realNumberOfGallons"
                component={TextInput}
                type="text"
                placeholder="Cantidad de Galones Reales"
                disabled
                validate={[validateNumber]}
              />
            </FormItem>
          </FormGroup>
          {commonFields}
        </div>
      );
    }

    return fields;
  };

  render() {
    const {
      loading,
      handleSubmit,
      gettingActiveSalesSession,
      activeSalesSession,
    } = this.props;

    if (loading || gettingActiveSalesSession) {
      return <Loader />;
    }

    if (activeSalesSession.isEmpty()) {
      return (
        <Container>
          <Alert message="No hay ninguna sesión de ventas activa." />
        </Container>
      );
    }

    return (
      <Form onSubmit={handleSubmit}>
        {this.renderFields()}
        <FormFooter />
      </Form>
    );
  }
}

FuelVoucherForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  initialValues: PropTypes.shape({
    numberOfGallons: PropTypes.number,
    extraordinary: PropTypes.bool,
  }),
};

FuelVoucherForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ TrafficUnit, SalesUnit }) => ({
  loading: !TrafficUnit.FuelVoucher.getIn(['current', 'activity']).isEmpty(),
  loadingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
  gettingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetActiveSalesSession: getActiveSalesSession,
};

const formComponent = reduxForm({
  form: 'FuelVoucherForm',
})(FuelVoucherForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
