import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { Col, Container, FormGroup, Label } from 'reactstrap';
import Select from '../../../../common/forms/select/Select';
import {
  optionPropTypes,
  optionsPropTypes,
} from '../../../../common/forms/select/SelectPropTypes';

export const TaskFields = ({
  index,
  task,
  loadingMechanics,
  mechanics,
  onChange,
  taskEmployeeIdValues,
  activityEmployeeIdValues,
}) => {
  const [employee, setEmployee] = useState(taskEmployeeIdValues);

  const onChangeEmployee = (option, inputActionMeta) => {
    // Enable when `${task}.employeeId` will be multi
    // onChange(inputActionMeta, index, activityEmployeeIdValues);
    // other code lines comment

    const myInputActionMeta = { ...inputActionMeta };
    if (inputActionMeta.action === 'select-option') {
      myInputActionMeta.option = option;

      if (!employee && option.value) {
        // selected employee for first time
        onChange(myInputActionMeta, index, activityEmployeeIdValues, null);
        setEmployee(option);
      } else if (option.value !== employee.value) {
        // when previous and current employees are differents
        onChange(
          myInputActionMeta,
          index,
          activityEmployeeIdValues,
          employee.value,
        );
        setEmployee(option);
      }
    } else if (inputActionMeta.action === 'clear') {
      // when deselect employee
      const [removedOption] = inputActionMeta.removedValues;
      myInputActionMeta.removedValue = removedOption;
      onChange(
        myInputActionMeta,
        index,
        activityEmployeeIdValues,
        employee.value,
      );
      setEmployee(option);
    }
  };

  return (
    <FormGroup key={`task${+index}`} row style={{ marginBottom: 0 }}>
      <Col>
        <Container fluid>
          <FormGroup row style={{ marginBottom: 0 }}>
            <Col lg={6} className="mb-2">
              <Label>Tarea</Label>
              <Field name={`${task}.taskId`} component={Select} isDisabled />
            </Col>
            <Col lg={6}>
              <Label>Empleados</Label>
              <Field
                name={`${task}.employeeId`}
                component={Select}
                options={mechanics}
                loading={loadingMechanics}
                input={{
                  onChange: onChangeEmployee,
                  value: employee,
                }}
                isDisabled={loadingMechanics}
                isClearable
                // Enable isMulti prop when component `${task}.employeeId`
                // accepts several mechanics
                // isMulti
              />
            </Col>
          </FormGroup>
        </Container>
      </Col>
    </FormGroup>
  );
};

TaskFields.propTypes = {
  index: PropTypes.number.isRequired,
  task: PropTypes.string.isRequired,
  loadingMechanics: PropTypes.bool.isRequired,
  mechanics: optionsPropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
  taskEmployeeIdValues: optionPropTypes,
  activityEmployeeIdValues: optionsPropTypes,
};

TaskFields.defaultProps = {
  taskEmployeeIdValues: [],
  activityEmployeeIdValues: null,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit, form },
  { index, activityIndex },
) => ({
  mechanics: MechanicalMaintenanceUnit.Mechanic.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, customer }) => ({
    value: id,
    label: customer.fullName,
  })),
  loadingMechanics: MechanicalMaintenanceUnit.Mechanic.getIn([
    'all',
    'loading',
  ]),
  taskEmployeeIdValues:
    form.AssignEmployeesToMaintenanceServiceOrderForm.values
      .activityBusExecutionList[activityIndex].activityTaskList[index]
      .employeeId,
  activityEmployeeIdValues:
    form.AssignEmployeesToMaintenanceServiceOrderForm.values
      .activityBusExecutionList[activityIndex].employeeId,
});

export default connect(mapStateToProps)(TaskFields);
