import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearItineraryGroup,
  getItineraryGroup,
} from '../../../../actions/route/ItineraryGroup';
import { ITINERARY_GROUP_PATH, ITINERARY_PATH } from '../../../../config/paths';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';
import NoDataResource from '../../../common/resource/NoDataResource';
import { ITINERARY_GROUP_STATUS } from '../../../../config/constants';
import BadgeList from '../../../common/BadgeList';
import ItineraryGroupToolbar from './ItineraryGroupToolbar';
import {
  clearCircuit,
  clearItineraryPromises,
  getCircuit,
  getItinerariesByItineraryIdList,
} from '../../../../actions';
import Content from '../../../layout/Content';
import { mergeObjectsByIds } from '../../../../utils/promise';
import InternalResource from '../../../common/resource/InternalResource';

export class ItineraryGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { gettingCircuit: false, gettingItineraries: false };
  }

  componentDidMount() {
    const {
      dispatchGetItineraryGroup,
      match: {
        params: { id: itineraryGroupId },
      },
    } = this.props;
    dispatchGetItineraryGroup({ itineraryGroupId });
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    const {
      dispatchClearItineraryGroup,
      dispatchClearCircuit,
      dispatchClearItineraryPromises,
    } = this.props;
    dispatchClearItineraryGroup();
    dispatchClearCircuit();
    dispatchClearItineraryPromises();
  }

  onDidUpdate = () => {
    const {
      itineraryGroup,
      itineraryGroupLoading,
      dispatchGetCircuit,
      dispatchGetItinerariesByItineraryIdList,
    } = this.props;
    const { gettingCircuit, gettingItineraries } = this.state;
    if (!itineraryGroupLoading && !itineraryGroup.isEmpty()) {
      if (!gettingCircuit) {
        dispatchGetCircuit({ circuitId: itineraryGroup.get('circuitId') });
        this.setState({ gettingCircuit: true });
      }
      if (!gettingItineraries) {
        const itineraryIdList = itineraryGroup.get('circuitGroupItineraryList');
        dispatchGetItinerariesByItineraryIdList({ itineraryIdList });
        this.setState({ gettingItineraries: true });
      }
    }
  };

  render() {
    const {
      itineraryGroup,
      itineraryGroupLoading,
      breadcrumbs,
      match: {
        params: { id: itineraryGroupId },
      },
      circuit,
      circuitLoading,
      itineraryPromisesLoading,
      itineraryPromises,
    } = this.props;

    let content;
    let toolbar;

    if (itineraryGroupLoading || circuitLoading || itineraryPromisesLoading) {
      content = <Loader />;
    } else if (itineraryGroup.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_GROUP_PATH} />;
    } else {
      const circuitGroupData = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="Bus:">
            {itineraryGroup.get('registeredBus').companyBusId}
          </ResourceProperty>
          <ResourceProperty label="Circuito:">
            {itineraryGroup.get('circuitId')} - {circuit.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Comentario:">
            {itineraryGroup.get('comments')}
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            {ITINERARY_GROUP_STATUS[itineraryGroup.get('status')].label}
          </ResourceProperty>
        </Fragment>
      );

      const mergedItineraryList = mergeObjectsByIds(
        itineraryGroup.get('circuitGroupItineraryList'),
        'itineraryId',
        itineraryPromises,
        'id',
      );
      const itineraryList = mergedItineraryList.map(
        ({ itineraryId, data }) => ({
          text: data
            ? `${itineraryId} - ${data.route.name} - ${tzNormalizeDate({
                date: data.departureTime,
                format: DATE_TIME_FORMAT,
              })}`
            : itineraryId.toString(),
          href: `${ITINERARY_PATH}/${itineraryId}`,
        }),
      );

      const itineraryData = (
        <Fragment>
          <h3>Itinerarios</h3>
          <div className="mb-3">
            <BadgeList textArray={itineraryList} />
          </div>
        </Fragment>
      );

      const internalData = (
        <InternalResource
          id={itineraryGroup.get('id')}
          createDate={itineraryGroup.get('createDate')}
          lastUpdate={itineraryGroup.get('lastUpdate')}
        />
      );

      content = (
        <Fragment>
          {circuitGroupData}
          {itineraryData}
          {internalData}
        </Fragment>
      );

      toolbar = <ItineraryGroupToolbar itineraryGroupId={+itineraryGroupId} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Grupo de Itinerarios por Circuito"
        content={content}
      />
    );
  }
}

ItineraryGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  dispatchGetItineraryGroup: PropTypes.func.isRequired,
  dispatchClearItineraryGroup: PropTypes.func.isRequired,
  itineraryGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
  itineraryGroupLoading: PropTypes.bool.isRequired,
  dispatchGetCircuit: PropTypes.func.isRequired,
  dispatchClearCircuit: PropTypes.func.isRequired,
  circuit: PropTypes.instanceOf(Immutable.Map).isRequired,
  circuitLoading: PropTypes.bool.isRequired,
  dispatchGetItinerariesByItineraryIdList: PropTypes.func.isRequired,
  itineraryPromisesLoading: PropTypes.bool.isRequired,
  itineraryPromises: PropTypes.instanceOf(Immutable.Set).isRequired,
  dispatchClearItineraryPromises: PropTypes.func.isRequired,
};

ItineraryGroup.defaultProps = {
  breadcrumbs: [],
};

const mapStateToProps = ({ RouteUnit, ItineraryUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Itinerarios por Circuito',
      href: ITINERARY_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  itineraryGroup: RouteUnit.ItineraryGroup.getIn(['current', 'content']),
  itineraryGroupLoading: !RouteUnit.ItineraryGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  circuit: RouteUnit.Circuit.getIn(['current', 'content']),
  circuitLoading: !RouteUnit.Circuit.getIn(['current', 'activity']).isEmpty(),
  itineraryPromisesLoading: ItineraryUnit.Itinerary.getIn([
    'currentPromises',
    'loading',
  ]),
  itineraryPromises: ItineraryUnit.Itinerary.getIn([
    'currentPromises',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchGetItineraryGroup: getItineraryGroup,
  dispatchClearItineraryGroup: clearItineraryGroup,
  dispatchGetCircuit: getCircuit,
  dispatchClearCircuit: clearCircuit,
  dispatchGetItinerariesByItineraryIdList: getItinerariesByItineraryIdList,
  dispatchClearItineraryPromises: clearItineraryPromises,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryGroup);
