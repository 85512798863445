import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  MANUAL_SENDER_REMISSION_GUIDE_PATH,
  NEW_MANUAL_SENDER_REMISSION_GUIDE_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { MANUAL_GRR_COLUMNS } from '../../../../config/columns';
import {
  clearManualSenderRemissionGuides,
  getManualSenderRemissionGuides,
} from '../../../../actions/cargo/ManualSenderRemissionGuide';

export const ManualSenderRemissionGuides = (props) => {
  const {
    breadcrumbs,
    guides,
    loading,
    dispatchGetManualSenderRemissionGuides,
    dispatchClearManualSenderRemissionGuides,
  } = props;

  const [columns] = useState(MANUAL_GRR_COLUMNS);

  useEffect(() => {
    dispatchGetManualSenderRemissionGuides();

    return () => {
      dispatchClearManualSenderRemissionGuides();
    };
  }, []);

  const data = guides.get('content') || [];
  const pages = guides.get('totalPages') || null;
  const defaultPageSize = guides.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Guias Remision Remitente"
      buttonPath={NEW_MANUAL_SENDER_REMISSION_GUIDE_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetManualSenderRemissionGuides,
        loading,
        modelPath: MANUAL_SENDER_REMISSION_GUIDE_PATH,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetManualSenderRemissionGuides: getManualSenderRemissionGuides,
  dispatchClearManualSenderRemissionGuides: clearManualSenderRemissionGuides,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Guias Remision Remitente',
      href: MANUAL_SENDER_REMISSION_GUIDE_PATH,
    },
  ],
  guides: CargoUnit.ManualSenderRemissionGuide.getIn(['all', 'content']),
  loading: CargoUnit.ManualSenderRemissionGuide.getIn(['all', 'loading']),
});

ManualSenderRemissionGuides.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  guides: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetManualSenderRemissionGuides: PropTypes.func.isRequired,
  dispatchClearManualSenderRemissionGuides: PropTypes.func.isRequired,
};

ManualSenderRemissionGuides.defaultProps = {
  guides: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManualSenderRemissionGuides);
