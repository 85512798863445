import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { TRIP_STATUS_OPTIONS } from '../../../../../config/constants';
import DropdownButton from '../../../../common/button/DropdownButton';
import { patchTripStatus } from '../../../../../actions';
import { generateItineraryTripStatusEndpoint } from '../../../../../config/endpoints';
import { PATCH } from '../../../../../config/permissions';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';

class StatusItineraryButton extends Component {
  handleClick = (tripStatusId) => {
    const { itinerary, dispatchPatchTripStatus } = this.props;
    dispatchPatchTripStatus(itinerary.get('id'), tripStatusId);
  };

  render() {
    const { itinerary } = this.props;
    return (
      <DropdownButton
        title="Estado"
        options={TRIP_STATUS_OPTIONS}
        activeValue={itinerary.get('tripStatus').id}
        handleClick={this.handleClick}
      />
    );
  }
}

StatusItineraryButton.propTypes = {
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPatchTripStatus: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchPatchTripStatus: patchTripStatus,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatusItineraryButton);

export default withEndpointAuthorization({
  url: generateItineraryTripStatusEndpoint(0),
  permissionType: PATCH,
})(connectedComponent);
