import { shape, string, func, oneOfType, number, arrayOf } from 'prop-types';

const gridColumnPropTypes = shape({
  // if key not fund, dependentKey and dependentValue are expected
  // this is used whenever we want to show informational data
  // that depends in a different column
  key: string,
  dependentKey: string,
  dependentValue: string,
  title: string.isRequired,
  editableComponent: func,
  width: oneOfType([string, number]),
});

const gridColumnsPropTypes = arrayOf(gridColumnPropTypes);

export { gridColumnPropTypes, gridColumnsPropTypes };
