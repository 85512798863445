import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CUSTOMER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CustomerForm from './CustomerForm';
import { postCustomer } from '../../../../actions';
import Content from '../../../layout/Content';
import {
  DEFAULT_IDENTIFICATION_TYPE_ID_OPTION,
  DEFAULT_ID_COUNTRY_OF_ORIGIN_OPTION,
} from '../../../../config/defaults';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_FORMAT } from '../../../../config/locale';

export class NewCustomer extends Component {
  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    newFormValues.gender = formValues.gender.value;
    newFormValues.identificationTypeId = formValues.identificationTypeId.value;
    newFormValues.idCountryOfOrigin = formValues.idCountryOfOrigin.value;
    newFormValues.dob = formValues.dateOfBirth
      ? tzNormalizeDate({
          date: formValues.dateOfBirth,
          format: INT_DATE_FORMAT,
        })
      : null;
    if (formValues.addressId) {
      newFormValues.addressId = formValues.addressId.value;
      newFormValues.address = {
        id: formValues.addressId.value,
        address: formValues.addressId.address,
        address2: formValues.addressId.address2,
        district: formValues.addressId.district,
        cityId: formValues.addressId.cityId,
        postalCode: formValues.addressId.postalCode,
        phone: formValues.addressId.phone,
      };
    }

    this.props.dispatchPostCustomer(newFormValues);
  };

  render() {
    const initialValues = {
      idCountryOfOrigin: DEFAULT_ID_COUNTRY_OF_ORIGIN_OPTION,
      identificationTypeId: DEFAULT_IDENTIFICATION_TYPE_ID_OPTION,
    };

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Cliente"
        subtitle="Crear un nuevo cliente"
        content={
          <CustomerForm
            onSubmit={this.onSubmit}
            initialValues={initialValues}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Clientes',
      href: CUSTOMER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostCustomer: postCustomer,
};

NewCustomer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCustomer: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);
