import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { GEOGRAPHIC_ZONE_COMBINATION_PATH } from '../../../../config/paths';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  getGeographicZonesCombination,
  clearGeographicZonesCombination,
} from '../../../../actions/cargo';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import GeographicZonesCombinationToolbar from './GeographicZonesCombinationToolbar';
import InternalResource from '../../../common/resource/InternalResource';

class GeographicZonesCombination extends Component {
  componentDidMount() {
    const {
      dispatchGetGeographicZonesCombination,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetGeographicZonesCombination({ geographicZonesCombinationId: id });
  }

  componentWillUnmount() {
    const { dispatchClearGeographicZonesCombination } = this.props;
    dispatchClearGeographicZonesCombination();
  }

  render() {
    const { breadcrumbs, geographicZonesCombination, loading } = this.props;
    if (loading || geographicZonesCombination.isEmpty()) {
      return <Loader />;
    }
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <GeographicZonesCombinationToolbar
          geographicZonesCombinationId={geographicZonesCombination.get('id')}
        />
        <h1>Combinación de Zonas Geográficas</h1>
        <hr />
        <Fragment>
          <ResourceProperty label="Zona Origen:">
            {geographicZonesCombination.get('sourceGeographicZone').name}
          </ResourceProperty>
          <ResourceProperty label="Zona Destino:">
            {geographicZonesCombination.get('destinationGeographicZone').name}
          </ResourceProperty>
          <ResourceProperty label="Factor:">
            {geographicZonesCombination.get('factor')}
          </ResourceProperty>
          <ResourceProperty label="Descripción:">
            {geographicZonesCombination.get('description')}
          </ResourceProperty>
        </Fragment>
        <InternalResource
          id={geographicZonesCombination.get('id')}
          createDate={geographicZonesCombination.get('createDate')}
          lastUpdate={geographicZonesCombination.get('lastUpdate')}
        />
      </Container>
    );
  }
}

GeographicZonesCombination.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetGeographicZonesCombination: PropTypes.func.isRequired,
  dispatchClearGeographicZonesCombination: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  geographicZonesCombination: PropTypes.instanceOf(Immutable.Map),
};

GeographicZonesCombination.defaultProps = {
  loading: false,
  geographicZonesCombination: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Combinaciones de Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_COMBINATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  geographicZonesCombination: CargoUnit.GeographicZonesCombination.getIn([
    'current',
    'content',
  ]),
  loading: CargoUnit.GeographicZonesCombination.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetGeographicZonesCombination: getGeographicZonesCombination,
  dispatchClearGeographicZonesCombination: clearGeographicZonesCombination,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeographicZonesCombination);
