import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getTripStatuses, clearTripStatuses } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY } from '../../../../config/queries';

class TripStatusSelect extends Component {
  componentDidMount() {
    const { dispatchGetTripStatuses } = this.props;
    dispatchGetTripStatuses({ ...DEFAULT_QUERY });
  }

  componentWillUnmount() {
    const { dispatchClearTripStatuses } = this.props;
    dispatchClearTripStatuses();
  }

  render() {
    const { options, isLoading, isMulti, input, meta, isDisabled } = this.props;
    return (
      <Select
        options={options}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
        isDisabled={isDisabled}
      />
    );
  }
}

TripStatusSelect.propTypes = {
  dispatchGetTripStatuses: PropTypes.func.isRequired,
  dispatchClearTripStatuses: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

TripStatusSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  options: [],
  isDisabled: false,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  options: ItineraryUnit.TripStatus.getIn(['all', 'content', 'content']).map(
    (option) => ({
      value: option.id,
      label: option.name,
    }),
  ),
  loading: ItineraryUnit.TripStatus.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetTripStatuses: getTripStatuses,
  dispatchClearTripStatuses: clearTripStatuses,
};

export default connect(mapStateToProps, mapDispatchToprops)(TripStatusSelect);
