import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText, CardTitle, Col, Row } from 'reactstrap';
import { tzNormalizeDate } from '../../../../utils/date';
import { padStart } from '../../../../utils/string';
import { DATE_FORMAT } from '../../../../config/locale';

const PreviewFuelVoucher = ({ fuelVouchers }) => {
  const labelWidth = 5;
  const valueWidth = 7;
  const preview = fuelVouchers.map((voucher, index) => {
    const document = `${voucher.documentSeries}-${padStart(
      voucher.documentCode,
      7,
    )}`;
    return (
      <Row key={+index.toString()}>
        <Col sm={12} md={12} lg={12}>
          <Card body className="mb-2 bg-light">
            <CardTitle className="text-center">
              <strong>Vale de Combustible</strong>
              <br />
              <small>{document}</small>
            </CardTitle>
            <div className="card-text">
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Emitido:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  <span>
                    {voucher.createDate
                      ? tzNormalizeDate({
                          date: voucher.createDate,
                          format: DATE_FORMAT,
                        })
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Usuario:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  <span>{voucher.issuerFullName}</span>
                </Col>
              </Row>
              <span>
                <strong>Validez:</strong>
              </span>
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Desde:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  <span>
                    {voucher.validFrom
                      ? tzNormalizeDate({
                          date: voucher.validFrom,
                          format: DATE_FORMAT,
                        })
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Hasta:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  <span>
                    {voucher.validTo
                      ? tzNormalizeDate({
                          date: voucher.validTo,
                          format: DATE_FORMAT,
                        })
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Bus:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  <span>{voucher.companyBusId.toString()}</span>
                </Col>
              </Row>
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Placa:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  <span>{voucher.licensePlate}</span>
                </Col>
              </Row>
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Conductores:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  {voucher.driverList.map((driver, indexDriver) => (
                    <span key={`driver-${indexDriver.toString()}`}>
                      {driver}
                    </span>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col sm={labelWidth}>
                  <span>
                    <strong>Circuito:</strong>
                  </span>
                </Col>
                <Col sm={valueWidth}>
                  <span>{voucher.circuitName}</span>
                </Col>
              </Row>
              <span>
                <strong>Grifo:</strong>
              </span>
              <Row>
                <Col sm={labelWidth}>
                  <Card body>
                    <CardText className="text-center">{`${voucher.numberOfGallons.toString()} gal`}</CardText>
                  </Card>
                </Col>
                <Col sm={valueWidth}>
                  <span>{voucher.fuelStationBusinessName}</span>
                  <br />
                  <span>{voucher.fuelStationLocationName}</span>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    );
  });

  return preview;
};

PreviewFuelVoucher.propTypes = {
  fuelVouchers: PropTypes.arrayOf(
    PropTypes.shape({
      documentSeries: PropTypes.string,
      documentCode: PropTypes.string,
      createDate: PropTypes.number,
      issuerFullName: PropTypes.string,
      validFrom: PropTypes.number,
      validTo: PropTypes.number,
      companyBusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      licensePlate: PropTypes.string,
      driverList: PropTypes.arrayOf(PropTypes.string),
      circuitName: PropTypes.string,
      numberOfGallons: PropTypes.number,
      fuelStationBusinessName: PropTypes.string,
      fuelStationLocationName: PropTypes.string,
    }),
  ).isRequired,
};

export default PreviewFuelVoucher;
