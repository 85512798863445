import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Form, FormGroup, Row, Col, Button, Container } from 'reactstrap';
import { Field, change, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import DatePicker from '../../../common/forms/input/DatePicker';
import {
  getActiveSalesSession,
  getExpenseAuthorizerUser,
  clearUsers,
} from '../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import Alert from '../../../common/informative/Alert';
import Loader from '../../../common/Loader';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import { EXPENSE_PATH } from '../../../../config/paths';
import CustomerField from '../../../common/forms/fields/CustomerField';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import CostCenterSelect from '../../../common/forms/select/CostCenterSelect';
import ExpenseTypeSelect from '../../../common/forms/select/ExpenseTypeSelect';

export const ExpenseForm = ({
  dispatchGetActiveSalesSession,
  dispatchGetExpenseAuthorizerUser,
  salesSessionId,
  activeSalesSession,
  dispatchClearUsers,
  dispatchChange,
  UserOptions,
  gettingActiveSalesSession,
  loading,
  handleSubmit,
}) => {
  const [enableControls, setEnableControls] = useState(false);

  useLayoutEffect(() => {
    dispatchGetActiveSalesSession({ source: EXPENSE_PATH });
    dispatchGetExpenseAuthorizerUser(DEFAULT_QUERY_GET_ALL);

    setEnableControls(
      salesSessionId !== 0 && activeSalesSession.get('id') !== salesSessionId,
    );

    return () => dispatchClearUsers();
  }, []);

  const handleExpenseTypeChange = (expenseTypeOption) =>
    dispatchChange('ExpenseForm', 'costCenter', expenseTypeOption.costCenter);

  if (gettingActiveSalesSession) return <Loader />;

  if (loading) return <Loader />;

  if (activeSalesSession.isEmpty())
    return (
      <Container>
        <Alert message="No hay ninguna sesión de ventas activa." />
      </Container>
    );

  let sendButton = null;

  if (!enableControls) {
    sendButton = (
      <div className="FormButtonGroup">
        <Button type="submit" color="primary" outline>
          Enviar
        </Button>
      </div>
    );
  }

  const businessInputGroup = (
    <BusinessInputGroup
      label="Empresa"
      name="business"
      form="ExpenseForm"
      disabled={enableControls}
      showDetails
    />
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <h2>Información básica</h2>
        </Col>
      </Row>
      <FormGroup row>
        <FormItem label="Tipo de egreso" required>
          <Field
            name="expenseTypeId"
            component={ExpenseTypeSelect}
            props={{ isOptionDisabled: true }}
            validate={[isRequired]}
            isDisabled={enableControls}
            onChange={handleExpenseTypeChange}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Centro de Costo" required>
          <Field
            name="costCenter"
            component={CostCenterSelect}
            placeholder="Centro de Costo"
            disabled={enableControls}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipos de comprobante" required>
          <Field
            name="voucherTypeId"
            component={VoucherTypeSelect}
            validate={[isRequired]}
            isDisabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <Row>
        <Col>
          <h2>Documento</h2>
        </Col>
      </Row>
      <FormGroup row>
        <FormItem label="Serie" required>
          <Field
            name="documentSeries"
            component={TextInput}
            type="text"
            placeholder="Serie"
            validate={[isRequired]}
            disabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Correlativo" required>
          <Field
            name="documentCode"
            component={TextInput}
            type="text"
            placeholder="Correlativo"
            validate={[isRequired, validateIntegerNumber]}
            disabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Beneficiario" required>
          <CustomerField
            fieldName="payeeCustomerId"
            isDisabled={enableControls}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Autorizador" required>
          <Field
            name="authorizerUserId"
            component={Select}
            options={UserOptions}
            validate={[isRequired]}
            isDisabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha y hora de comprobante">
          <Field
            name="voucherTime"
            props={{
              time: true,
            }}
            component={DatePicker}
            disabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <Row>
        <Col>
          <h2>Item</h2>
        </Col>
      </Row>
      <FormGroup row>
        <FormItem label="Descripción" required>
          <Field
            name="description"
            component={TextInput}
            type="text"
            placeholder="Descripción"
            validate={[isRequired]}
            disabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Total" required>
          <Field
            name="amount"
            component={TextInput}
            type="text"
            placeholder="0.00"
            validate={[isRequired, validateNumber]}
            disabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <Row>
        <Col>
          <h2>Información adicional</h2>
        </Col>
      </Row>
      {businessInputGroup}
      <FormGroup row>
        <FormItem label="Bus">
          <Field
            name="registeredBusId"
            component={RegisteredBusSelect}
            isDisabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <Row>
        <Col>
          <h2>Boleto</h2>
        </Col>
      </Row>
      <FormGroup row>
        <FormItem label="Serie de documento">
          <Field
            name="code"
            component={TextInput}
            type="text"
            placeholder="Serie de documento"
            disabled={enableControls}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Código de documento">
          <Field
            name="number"
            component={TextInput}
            type="text"
            placeholder="Código de documento"
            disabled={enableControls}
            validate={[validateIntegerNumber]}
          />
        </FormItem>
      </FormGroup>
      {sendButton}
    </Form>
  );
};

const mapStateToProps = ({ UserUnit, SalesUnit }) => ({
  UserOptions: UserUnit.User.getIn(['all', 'content', 'content']).map(
    (user) => ({
      value: user.userId,
      label: user.fullName,
    }),
  ),
  gettingActiveSalesSession: SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  loading: !SalesUnit.Expense.getIn(['current', 'activity']).isEmpty(),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
});

const mapDispatchToProps = {
  dispatchGetExpenseAuthorizerUser: getExpenseAuthorizerUser,
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchClearUsers: clearUsers,
  dispatchChange: change,
};

ExpenseForm.propTypes = {
  dispatchChange: PropTypes.func.isRequired,
  salesSessionId: PropTypes.number,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetExpenseAuthorizerUser: PropTypes.func.isRequired,
  dispatchClearUsers: PropTypes.func.isRequired,
  UserOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
};

ExpenseForm.defaultProps = {
  salesSessionId: 0,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ExpenseForm',
  })(ExpenseForm),
);
