import React from 'react';
import { userActivityInformationPropTypes } from '../proptypes/UserPropTypes';
import Checkbox from '../../../../common/forms/Checkbox';

const UserActivityResource = ({ enabled, verified, blocked }) => (
  <>
    <h3>Actividad</h3>
    <strong>Estado</strong>
    <div>
      <Checkbox checked={enabled} /> Activo
    </div>
    <div>
      <Checkbox checked={verified} /> Verificado
    </div>
    <div>
      <Checkbox checked={blocked} /> Bloqueado
    </div>
  </>
);

UserActivityResource.propTypes = userActivityInformationPropTypes;

export default UserActivityResource;
