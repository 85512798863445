import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRINT_STATION_PATH } from '../../../../config/paths';
import { postPrintStation } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import PrintStationForm from './PrintStationForm';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';

export class NewPrintStation extends Component {
  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    if (formValues.printTemplateList) {
      newFormValues.printTemplateList = formValues.printTemplateList.map(
        (template) => ({
          printTemplateType: template.printTemplateType.value,
          documentCode: template.documentCode,
          documentSeries: template.documentSeries,
        }),
      );
    }

    const { dispatchPostPrintStation } = this.props;

    dispatchPostPrintStation(newFormValues);
  };

  render() {
    const { loading } = this.props;

    const content = loading ? (
      <Loader />
    ) : (
      <PrintStationForm onSubmit={this.onSubmit} />
    );

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Impresora"
        subtitle="Crear una impresora"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Impresora',
      href: PRINT_STATION_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  loading: !HumanResourcesUnit.PrintStation.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPostPrintStation: postPrintStation,
};

NewPrintStation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPrintStation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewPrintStation.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPrintStation);
