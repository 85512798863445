import React from 'react';
import { DRIVER_WITHOUT_CONTRACTS_WARNING_MESSAGE } from '../../../../../config/messages';
import { tzNormalizeDate } from '../../../../../utils/date';
import Alert from '../../../../common/informative/Alert';
import Table from '../../../../common/Table';
import { driverContractListPropTypes } from '../proptypes/DriverPropTypes';
import { DATE_FORMAT } from '../../../../../config/locale';

const DriverContractInformationResource = ({ driverContractList }) => {
  let contractList = (
    <Alert type="warning" message={DRIVER_WITHOUT_CONTRACTS_WARNING_MESSAGE} />
  );

  if (driverContractList.length)
    contractList = (
      <Table
        columns={[
          {
            Header: 'Tipo de Contrato',
            accessor: 'driverContractType.name',
            className: 'text-center',
          },
          {
            Header: 'Fecha de Expedición',
            accessor: 'effectiveDate',
            Cell: (row) =>
              tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
            className: 'text-center',
          },
          {
            Header: 'Fecha de Expiración',
            accessor: 'expirationDate',
            Cell: (row) =>
              tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
            className: 'text-center',
          },
          {
            Header: 'Razón de Terminación',
            accessor: 'reason',
            className: 'text-center',
          },
          {
            Header: 'Notas',
            accessor: 'note',
            className: 'text-center',
          },
        ]}
        data={driverContractList}
        defaultPageSize={driverContractList.length}
        showPagination={false}
      />
    );

  return (
    <div className="mb-3">
      <h3>Contratos</h3>
      {contractList}
    </div>
  );
};

DriverContractInformationResource.propTypes = driverContractListPropTypes;

export default DriverContractInformationResource;
