import { combineReducers } from 'redux';
import SalesSession from './SalesSession';
import Deposit from './Deposit';
import UnitHome from './UnitHome';
import GeneralIncome from './GeneralIncome';
import Expense from './Expense';
import PricingProfile from './PricingProfile';
import LiquidationSession from './LiquidationSession';
import DiscountToBeneficiary from './DiscountToBeneficiary';
import ExpenseStatus from './ExpenseStatus';
import CreditNote from './CreditNote';

export default combineReducers({
  SalesSession,
  Deposit,
  GeneralIncome,
  Expense,
  UnitHome,
  PricingProfile,
  LiquidationSession,
  DiscountToBeneficiary,
  ExpenseStatus,
  CreditNote,
});
