import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPricingProfiles, clearPricingProfiles } from '../../../../actions';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { isValidNumber } from '../../../../utils/validators';

class PricingProfileSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearPricingProfiles } = this.props;
    dispatchClearPricingProfiles();
  }

  handleInputChange = (inputValue) => {
    const { dispatchGetPricingProfiles } = this.props;

    const value = inputValue.trim();

    if (value.length) {
      let query = [];

      if (isValidNumber(value)) {
        query = [`id:${value}`];
      } else if (value.length >= 4) {
        query = [`name:${value}`];
      }

      dispatchGetPricingProfiles({ query });
    }
  };

  render() {
    const {
      loading,
      routeOptions,
      isGridVariant,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        onInputChange={this.handleInputChange}
        options={routeOptions}
        isMulti={isMulti}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

PricingProfileSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetPricingProfiles: PropTypes.func.isRequired,
  dispatchClearPricingProfiles: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  routeOptions: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

PricingProfileSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  routeOptions: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busque por ID o nombre de Perfil de Precio',
};

const mapStateToProps = ({ SalesUnit }) => {
  const routeOptions = SalesUnit.PricingProfile.getIn([
    'all',
    'content',
    'content',
  ]).map((route) => ({
    value: route.id,
    label: `${route.id} - ${route.name}`,
  }));

  return {
    routeOptions,
    loading: SalesUnit.PricingProfile.getIn(['all', 'loading']),
  };
};

const mapDispatchToProps = {
  dispatchGetPricingProfiles: getPricingProfiles,
  dispatchClearPricingProfiles: clearPricingProfiles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricingProfileSelect);
