import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FUEL_PROFILE_PATH } from '../../../../config/paths';

const EditFuelProfileButton = ({ fuelProfileId }) => (
  <Link
    className="btn btn-primary"
    to={`${FUEL_PROFILE_PATH}/${fuelProfileId}/edit`}
  >
    Editar
  </Link>
);

EditFuelProfileButton.propTypes = {
  fuelProfileId: PropTypes.number.isRequired,
};

export default EditFuelProfileButton;
