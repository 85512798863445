import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, isValidNumber } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import CircuitSelect from '../../../common/forms/select/CircuitSelect';
import {
  getFuelStations,
  clearFuelStations,
  getBusFuelGroups,
  clearBusFuelGroups,
  getExpectedFuelConsumptionsByCircuit,
  clearExpectedFuelConsumptions,
} from '../../../../actions';
import Grid from '../../../common/grid/Grid';
import GridEditableInput from '../../../common/grid/GridEditableInput';
import FuelStationSelect from '../../../common/forms/select/FuelStationSelect';
import Alert from '../../../common/informative/Alert';
import InformativeTable from './InformativeTable';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import FormFooter from '../../../common/forms/FormFooter';

export class FuelProfileForm extends Component {
  state = {
    showGridComponent: false,
    gridValues: [],
    busFuelGroups: [],
  };

  componentDidMount() {
    this.onMount();
  }

  componentWillUnmount() {
    const {
      dispatchClearFuelStations,
      dispatchClearBusFuelGroups,
      dispatchClearExpectedFuelConsumptions,
    } = this.props;
    dispatchClearFuelStations();
    dispatchClearBusFuelGroups();
    dispatchClearExpectedFuelConsumptions();
  }

  onMount = () => {
    const { dispatchGetFuelStations, dispatchGetBusFuelGroups, initialValues } =
      this.props;
    dispatchGetFuelStations(DEFAULT_QUERY_GET_ALL);
    dispatchGetBusFuelGroups();
    if (initialValues) {
      this.setState({ showGridComponent: true });
    }
  };

  onGridValueChange = (values) => {
    this.setState({ gridValues: values });
  };

  onHandleSubmit = (formValues) => {
    const { gridValues } = this.state;
    const newFuelProfile = {
      name: formValues.name,
      description: formValues.description,
      circuitId: formValues.circuitId.value,
    };
    if (this.validateGridValues(gridValues)) {
      newFuelProfile.refuellingStopList = gridValues.map((gridValue) => ({
        fuelStationId: gridValue.fuelStationId.value,
        refuellingStopBusFuelGroupList: this.state.busFuelGroups.map(
          (busFuelGroup) => ({
            busFuelGroupId: busFuelGroup.id,
            numberOfGallon: gridValue[`numberOfGallon${busFuelGroup.id}`],
          }),
        ),
      }));
      this.props.onSubmit(newFuelProfile);
    }
  };

  onChangeCircuit = (circuit) => {
    const {
      dispatchGetExpectedFuelConsumptionsByCircuit,
      dispatchClearExpectedFuelConsumptions,
    } = this.props;
    if (circuit.value) {
      dispatchClearExpectedFuelConsumptions();
      dispatchGetExpectedFuelConsumptionsByCircuit(circuit.value);
      this.setState({ showGridComponent: true, busFuelGroups: [] });
    } else {
      this.setState({ showGridComponent: false });
    }
  };

  validateGridValues = (gridValues) => {
    let isValid = true;
    const { busFuelGroups } = this.state;

    if (gridValues.length) {
      const isInvalidData = gridValues.some((rowValue) => {
        // validate the content of key in each row
        if (!(rowValue.fuelStationId && rowValue.fuelStationId.value)) {
          toastr.error('Error', 'Seleccione un grifo.');
          return true;
        }
        for (let index = 0; index < busFuelGroups.length; index += 1) {
          const value = rowValue[`numberOfGallon${busFuelGroups[index].id}`];
          if (value && !isValidNumber(value)) {
            toastr.error(
              'Error',
              `Ingrese un número de galones válido para el grupo ${busFuelGroups[index].name}.`,
            );
            return true;
          }
        }
        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error', 'Ingrese datos de rellenos.');
      isValid = false;
    }
    return isValid;
  };

  // Optimize code as too much data is being processed, and in some cases repeated.
  createGridColumns = (expectedFuels) => {
    let arrayBusFuelGroups = [];

    // forEach Routes
    expectedFuels.forEach((expectedFuel) => {
      // forEach Groups
      expectedFuel.busFuelGroupConsumptionList.forEach((group) => {
        arrayBusFuelGroups.push({
          id: group.busFuelGroupId,
          name: group.busFuelGroup.name,
        });
      });
    });

    // Remove duplicate
    arrayBusFuelGroups = arrayBusFuelGroups.filter(
      (currentBusFuelGroup, currentIndex, originaArray) =>
        currentIndex ===
        originaArray.findIndex(
          (row) =>
            row.id === currentBusFuelGroup.id &&
            row.name === currentBusFuelGroup.name,
        ),
    );

    if (arrayBusFuelGroups.length !== this.state.busFuelGroups.length) {
      this.setState({ busFuelGroups: arrayBusFuelGroups });
    }

    const fuelStationColumn = {
      key: 'fuelStationId',
      title: 'Grifo',
      editableComponent: (onValueChange, value) => (
        <FuelStationSelect
          initFetch={false}
          onValueChange={onValueChange}
          value={value}
          isGridVariant
        />
      ),
      width: '300',
    };

    const gridColumns = arrayBusFuelGroups.map((column) => ({
      key: `numberOfGallon${column.id}`,
      title: `Grupo ${column.name}`,
      editableComponent: (onValueChange, value) => (
        <GridEditableInput onValueChange={onValueChange} value={value} />
      ),
      width: '200',
    }));

    return [fuelStationColumn, ...gridColumns];
  };

  dataGridInitialValues = (initialValues) =>
    initialValues.refuellingStopList ? initialValues.refuellingStopList : [];

  showGridComponent = (expectedFuel, loadingExpectedFuels) => {
    if (loadingExpectedFuels) return <Loader />;

    if (expectedFuel.length > 0)
      return (
        <Grid
          columns={this.createGridColumns(expectedFuel)}
          initialValues={this.dataGridInitialValues(this.props.initialValues)}
          onValueChange={this.onGridValueChange}
        />
      );

    return <Alert message="El circuito seleccionado no tiene dotación" />;
  };

  render() {
    const { loading, handleSubmit, expectedFuels, loadingExpectedFuels } =
      this.props;

    if (loading) return <Loader />;

    let gridComponent = <Alert message="Seleccione un circuito" />;
    let informativeTableComponent = null;

    if (this.state.showGridComponent && !expectedFuels.isEmpty()) {
      // show grid component
      const expectedFuelsContent = expectedFuels.get('content');
      gridComponent = this.showGridComponent(
        expectedFuelsContent,
        loadingExpectedFuels,
      );

      // show fuel expected consumption informative component
      informativeTableComponent = (
        <InformativeTable
          expectedFuels={expectedFuelsContent}
          busFuelGroups={this.state.busFuelGroups}
        />
      );
    }

    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Nombre" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Nombre de perfil"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              placeholder="Descripción"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Circuito" required>
            <Field
              name="circuitId"
              component={CircuitSelect}
              validate={[isRequired]}
              onChange={this.onChangeCircuit}
            />
          </FormItem>
        </FormGroup>
        {informativeTableComponent}
        <Row>
          <Col>
            <h2>Rellenos</h2>
          </Col>
        </Row>
        {gridComponent}
        <FormFooter />
      </Form>
    );
  }
}

FuelProfileForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  expectedFuels: PropTypes.instanceOf(Immutable.Map),
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchGetBusFuelGroups: PropTypes.func.isRequired,
  dispatchClearBusFuelGroups: PropTypes.func.isRequired,
  dispatchGetFuelStations: PropTypes.func.isRequired,
  dispatchClearFuelStations: PropTypes.func.isRequired,
  dispatchGetExpectedFuelConsumptionsByCircuit: PropTypes.func.isRequired,
  dispatchClearExpectedFuelConsumptions: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    circuitId: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    refuellingStopList: PropTypes.arrayOf(PropTypes.object),
  }),
  loadingExpectedFuels: PropTypes.bool,
};

FuelProfileForm.defaultProps = {
  expectedFuels: null,
  initialValues: {},
  loadingExpectedFuels: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.FuelProfile.getIn(['current', 'activity']).isEmpty(),
  expectedFuels: TrafficUnit.ExpectedFuelConsumption.getIn(['all', 'content']),
  loadingExpectedFuels: TrafficUnit.ExpectedFuelConsumption.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetFuelStations: getFuelStations,
  dispatchClearFuelStations: clearFuelStations,
  dispatchGetBusFuelGroups: getBusFuelGroups,
  dispatchClearBusFuelGroups: clearBusFuelGroups,
  dispatchGetExpectedFuelConsumptionsByCircuit:
    getExpectedFuelConsumptionsByCircuit,
  dispatchClearExpectedFuelConsumptions: clearExpectedFuelConsumptions,
};

const formComponent = reduxForm({
  form: 'FuelProfileForm',
})(FuelProfileForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
