import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ITINERARY_PATH } from '../../../../../config/paths';

const ItineraryEditButton = ({ itineraryId }) => (
  <Link
    className="btn btn-primary"
    to={`${ITINERARY_PATH}/${itineraryId}/edit`}
  >
    <i className="fa fa-edit" /> Editar
  </Link>
);

ItineraryEditButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default ItineraryEditButton;
