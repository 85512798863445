import Immutable from 'immutable';
import {
  CLEAR_DISCOUNT_TO_BENEFICIARY,
  FLAG_DISCOUNT_TO_BENEFICIARY_ACTIVITY,
} from '../../../actions/types';

const INITIAL_STATE = Immutable.Map({
  current: Immutable.Map({
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CLEAR_DISCOUNT_TO_BENEFICIARY:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_DISCOUNT_TO_BENEFICIARY_ACTIVITY:
      // raising flag
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      // lowering flag
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    default:
      return state;
  }
};
