import {
  addImage,
  addQrCode,
  addText,
  createPdfDocument,
  setFont,
  y,
} from '../pdf';
import LogoCiva from '../../resources/images/logo-civa.png';

const myX = 3;
const margin = 3;
const qrWidth = 35;
const qrHeight = 35;
let myY = y;

const printCivaLogo = async (doc) => {
  addImage(doc, LogoCiva, 20, myY, 35, 10);
};

const printItemTicket = async (
  doc,
  {
    shipmentFields: { origin, destiny },
    consignedFields: {
      consignedName,
      consignedDocumentType,
      consignedDocumentNumber,
    },
    itemDescription,
    comments,
    correlationCodeItem,
    itemNumber,
    itemListSize,
    weight,
  },
) => {
  myY = y;

  await printCivaLogo(doc);
  setFont(doc, 'bold');
  doc.setFontSize(14);
  addText(doc, 'AGENCIA A AGENCIA', 15, (myY += margin * 6), 75, 'justify');
  setFont(doc);
  doc.setFontSize(8);
  addText(doc, 'Turismo Civa S.A.C', myX, (myY += margin * 2), 75, 'left');
  addText(doc, 'RUC:20102427891', myX, (myY += margin * 2), 75, 'left');
  setFont(doc, 'bold');
  doc.setFontSize(14);
  addText(doc, `DESDE:${origin}`, myX, (myY += margin * 3), 75, 'left');
  addText(doc, `HASTA:${destiny}`, myX, (myY += margin * 3), 75, 'left');
  setFont(doc);

  setFont(doc, 'bold');
  doc.setFontSize(10);
  addText(doc, 'DATOS DEL CONSIGNADO', myX, (myY += margin * 3), 75, 'left');

  doc.setFontSize(10);
  setFont(doc);

  const consignedNameText = doc.splitTextToSize(`Nombre: ${consignedName}`, 75);

  addText(doc, consignedNameText, myX, (myY += margin * 2), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += 4 * consignedNameText.length),
    40,
    'left',
  );
  addText(doc, `: ${consignedDocumentType}`, 41, myY, 55, 'left');

  addText(doc, 'Numero de Documento', myX, (myY += 4), 40, 'left');
  addText(doc, `: ${consignedDocumentNumber}`, 41, myY, 55, 'left');
  setFont(doc, 'bold');
  doc.setFontSize(12);
  addText(doc, itemDescription, myX, (myY += margin * 3), 75, 'left');
  setFont(doc);
  doc.setFontSize(10);
  addText(doc, 'Comentarios:', myX, (myY += margin * 2), 75, 'left');
  const stringComments = comments == null ? '' : comments;
  const newStringComments = doc.splitTextToSize(stringComments, 40);
  addText(doc, newStringComments, myX, (myY += margin * 1.5), 70, 'left');
  setFont(doc, 'bold');
  doc.setFontSize(16);
  addText(
    doc,
    correlationCodeItem,
    40,
    (myY += 5 * newStringComments.length),
    75,
    'center',
  );
  await addQrCode(
    doc,
    correlationCodeItem,
    22.5,
    (myY += margin * 2),
    qrWidth,
    qrHeight,
  );
  setFont(doc);
  doc.setFontSize(10);
  addText(
    doc,
    `PAQUETE ${itemNumber}/${itemListSize}`,
    10,
    (myY += qrHeight + margin),
    75,
    'left',
  );
  addText(doc, `PESO: ${weight} kg`, 50, myY, 55, 'left');
};

const formatTicket = (ticket) => {
  const formatedTicket = {
    shipmentFields: {
      origin: ticket.source,
      destiny: ticket.destination,
    },
    consignedFields: {
      consignedName: ticket.consigneeCustomer.fullName,
      consignedDocumentType: ticket.consigneeCustomer.identificationTypeName,
      consignedDocumentNumber: ticket.consigneeCustomer.idDocumentNumber,
    },
    cargoItemListSize: ticket.cargoItemPosition,
    comments: ticket.comments,
  };

  return formatedTicket;
};

export default async function printUnitTicketCargo(ticket) {
  const doc = createPdfDocument('p', 'mm', [80, 170]);

  const formatedTicket = formatTicket(ticket);

  const {
    shipmentFields: { origin, destiny },
    consignedFields: {
      consignedName,
      consignedDocumentType,
      consignedDocumentNumber,
    },
    comments,
  } = formatedTicket;

  await printItemTicket(doc, {
    shipmentFields: { origin, destiny },
    consignedFields: {
      consignedName,
      consignedDocumentType,
      consignedDocumentNumber,
    },
    itemDescription: ticket.cargoItemDetail.description,
    comments,
    correlationCodeItem: ticket.cargoItemCorrelation,
    itemNumber: ticket.cargoItemPosition,
    itemListSize: ticket.parcelCargoItemSize,
    weight: ticket.cargoItemDetail.weight,
  });

  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
}
