import Immutable from 'immutable';
import {
  HOME_PATH,
  ITINERARY_PATH,
  ITINERARY_FOR_COUNTER_PATH,
  ITINERARY_SCHEDULE_PATH,
  ITINERARY_SEARCH_OMISSION_PATH,
  SERVICE_MERGE_PATH,
  ITINERARY_FOR_SCHEDULING_PATH,
  NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH,
  KIDS_ON_ITINERARY_PATH,
  SALES_CONTROL_PATH,
  LIVE_SALES_CONTROL_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      key: 'schedules',
      title: 'Plantillas',
      path: ITINERARY_SCHEDULE_PATH,
      icon: 'repeat',
    },
    {
      key: 'itineraries',
      title: 'Itinerarios',
      path: ITINERARY_PATH,
      icon: 'calendar',
    },
    {
      key: 'itineraries-for-counter',
      title: 'Itinerarios para Counter',
      path: ITINERARY_FOR_COUNTER_PATH,
      icon: 'calendar-o',
    },
    {
      key: 'itineraries-for-scheduling',
      title: 'Itinerarios para Programar',
      path: ITINERARY_FOR_SCHEDULING_PATH,
      icon: 'calendar-check-o',
    },
    {
      key: 'service-merge',
      title: 'Fusión Servicios - Trasbordo',
      path: SERVICE_MERGE_PATH,
      icon: 'object-group',
    },
    {
      key: 'itinerary-search-omission',
      title: 'Ocultar Paradas',
      path: ITINERARY_SEARCH_OMISSION_PATH,
      icon: 'ban',
    },
    {
      key: 'itinerary-extraordinary-movement',
      title: 'Movimiento Extraordinario',
      path: NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH,
      icon: 'calendar-plus-o',
    },
    {
      key: 'kids-on-itinerary',
      title: 'Asignar Menor de Edad a Itinerario',
      path: KIDS_ON_ITINERARY_PATH,
      icon: 'vcard-o',
    },
    {
      key: 'sales-control',
      title: 'Monitoreo de Ventas',
      path: SALES_CONTROL_PATH,
      icon: 'list-ol',
    },
    {
      key: 'live-sales-control',
      title: 'Monitoreo de Ventas en Vivo',
      path: LIVE_SALES_CONTROL_PATH,
      icon: 'line-chart',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Itinerarios',
      tag: 'span',
      href: '/itinerary',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
