import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { GENDERS } from '../../../config/constants';
import { tzNormalizeDate } from '../../../utils/date';
import {
  customerBasicInformationDefaultProps,
  customerBasicInformationPropTypes,
} from '../../units/user/customer/proptypes/CustomerPropTypes';
import { DATE_FORMAT } from '../../../config/locale';

const CustomerResource = ({ customer }) => {
  if (!customer) return null;

  let genderLabel = '-';

  if (customer.gender) {
    const genderObject = GENDERS.find(
      (customerGender) => customerGender.value === customer.gender,
    );

    genderLabel = genderObject && genderObject.label;
  }

  const phoneResource = customer.mobilePhone && (
    <li className="list-inline-item">
      <a href={`tel:${customer.mobilePhone}`}>
        <i className="fa fa-phone" /> {customer.mobilePhone}
      </a>
    </li>
  );
  const emailResource = customer.email && (
    <li className="list-inline-item">
      <a href={`mailto:${customer.email}`}>
        <i className="fa fa-envelope-o" /> {customer.email}
      </a>
    </li>
  );

  return (
    <div className="mt-3">
      <Row>
        <Col>
          <div className="mb-2">
            <small>
              <strong>
                {customer.identificationType &&
                  customer.identificationType.name}
                :
              </strong>
            </small>
            <br />
            {customer.idDocumentNumber}
          </div>
          <div className="mb-2">
            <small>
              <strong>Nombre Completo:</strong>
            </small>
            <br />
            {customer.firstName} {customer.lastName}
          </div>
        </Col>
        <Col>
          <div className="mb-2">
            <small>
              <strong>Género:</strong>
            </small>
            <br />
            {genderLabel}
          </div>
          <div className="mb-2">
            <small>
              <strong>Nacionalidad:</strong>
            </small>
            <br />
            {customer.idCountryOfOrigin || '-'}
          </div>
        </Col>
        <Col>
          <div className="mb-2">
            <small>
              <strong>Datos de Contacto:</strong>
            </small>
            <br />
            <ul className="list-inline">
              {phoneResource}
              {emailResource}
            </ul>
          </div>
          <div className="mb-2">
            <small>
              <strong>Nacimiento:</strong>
            </small>
            <br />
            {customer.dob
              ? tzNormalizeDate({ date: customer.dob, format: DATE_FORMAT })
              : '-'}
          </div>
        </Col>
      </Row>
    </div>
  );
};

CustomerResource.propTypes = {
  customer: PropTypes.shape(customerBasicInformationPropTypes),
};

CustomerResource.defaultProps = {
  customer: customerBasicInformationDefaultProps,
};

export default CustomerResource;
