import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ReturnButton = ({ path }) => (
  <Link className="btn btn-secondary" to={path}>
    <i className="fa fa-arrow-circle-left" /> Regresar
  </Link>
);

ReturnButton.propTypes = {
  path: PropTypes.string.isRequired,
};

export default ReturnButton;
