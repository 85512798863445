import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  isValidBillingDate,
  isValidBillingTime,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import FormFooter from '../../../common/forms/FormFooter';
import {
  BILL_DRIVER_LIST_FORM_COLUMNS,
  BILL_ITEM_LIST_FORM_COLUMNS,
} from '../../../../config/dynamicFormFields';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import { BILLING_DOCUMENT_TYPE } from '../../../../config/constants';

export const BillingFormSenderRemissionGuide = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  const itemsDataFields = (
    <>
      <h4>Datos del Bus</h4>
      <FormGroup row>
        <FormItem label="Placa" required>
          <Field
            name="busLicensePlate"
            component={TextInput}
            placeholder="Placa"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <h4>Datos del Origen</h4>
      <FormGroup row>
        <FormItem label="Código Origen" required>
          <Field
            name="sourceCode"
            component={TextInput}
            placeholder="Código Origen"
            type="text"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Dirección Origen" required>
          <Field
            name="sourceAddress"
            component={TextInput}
            placeholder="Dirección Origen"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Código de Establecimiento Origen" required>
          <Field
            name="sourceAgencyCode"
            component={TextInput}
            placeholder="Código de Establecimiento Origen"
            type="text"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <h4>Datos del Destino</h4>
      <FormGroup row>
        <FormItem label="Código Destino" required>
          <Field
            name="destinationCode"
            component={TextInput}
            placeholder="Código Destino"
            type="text"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Dirección Destino" required>
          <Field
            name="destinationAddress"
            component={TextInput}
            placeholder="Dirección Destino"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Código de Establecimiento Destino" required>
          <Field
            name="destinationAgencyCode"
            component={TextInput}
            placeholder="Código de Establecimiento Destino"
            type="text"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <h4>Datos del Destinatario</h4>
      <FormGroup row>
        <FormItem label="Nombre Destinatario" required>
          <Field
            name="destinatarioFullName"
            component={TextInput}
            placeholder="Nombre Destinatario"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo Doc. Destinatario" required>
          <Field
            name="destinatarioDocumentType"
            component={Select}
            options={enumToSelectOptions(BILLING_DOCUMENT_TYPE)}
            placeholder="Tipo Doc. Destinatario"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Núm. Doc. Destinatario" required>
          <Field
            name="destinatarioDocumentNumber"
            component={TextInput}
            placeholder="Núm. Doc. Destinatario"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Email Destinatario">
          <Field
            name="destinatarioEmail"
            component={TextInput}
            placeholder="Email Destinatario"
            type="text"
          />
        </FormItem>
      </FormGroup>
      <h4>Lista de Items</h4>
      <DynamicForm name="itemsList" columns={BILL_ITEM_LIST_FORM_COLUMNS} />
      <h4>Lista de Conductores</h4>
      <DynamicForm name="driverList" columns={BILL_DRIVER_LIST_FORM_COLUMNS} />
      <h4>Otros Datos</h4>
      <FormGroup row>
        <FormItem label="Comentario" required>
          <Field
            name="comments"
            component={TextInput}
            placeholder="Comentario"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha Emisión" required>
          <Field
            name="issueDate"
            component={TextInput}
            placeholder="Fecha Emisión"
            type="text"
            validate={[isRequired, isValidBillingDate]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Hora Emisión" required>
          <Field
            name="issueTime"
            component={TextInput}
            placeholder="Hora Emisión"
            type="text"
            validate={[isRequired, isValidBillingTime]}
          />
        </FormItem>
      </FormGroup>

      <FormGroup row>
        <FormItem label="ID Itinerario" required>
          <Field
            name="itineraryId"
            component={TextInput}
            placeholder="ID Itinerario"
            type="text"
            validate={[isRequired, validateIntegerNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Peso Total" required>
          <Field
            name="totalWeight"
            component={TextInput}
            placeholder="Peso Total"
            type="text"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Unidad Medida" required>
          <Field
            name="totalWeightUnitCode"
            component={TextInput}
            placeholder="Unidad Medida"
            type="text"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha Salida Itinerario" required>
          <Field
            name="itineraryDepartureDate"
            component={TextInput}
            placeholder="Fecha Salida Itinerario"
            type="text"
            validate={[isRequired, isValidBillingDate]}
          />
        </FormItem>
      </FormGroup>
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      {itemsDataFields}
      <FormFooter />
    </Form>
  );
};

BillingFormSenderRemissionGuide.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: !state.AccountingUnit.Billing.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'BillingForm',
})(BillingFormSenderRemissionGuide);

export default connect(mapStateToProps)(formComponent);
