import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GENERAL_INCOME_PATH } from '../../../../config/paths';

const GeneralIncomeEditButton = ({ generalIncomeId }) => (
  <Link
    className="btn btn-primary"
    to={`${GENERAL_INCOME_PATH}/${generalIncomeId}/edit`}
  >
    Editar
  </Link>
);

GeneralIncomeEditButton.propTypes = {
  generalIncomeId: PropTypes.number.isRequired,
};

export default GeneralIncomeEditButton;
