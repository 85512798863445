import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { Table } from '../../../../common/Table';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import { tzNormalizeDate } from '../../../../../utils/date';

const LiquidationNoteResource = ({ dailyLiquidationSessionNoteList }) => {
  let table = null;

  if (dailyLiquidationSessionNoteList.length) {
    table = (
      <Fragment>
        <Card body className="alert alert-warning">
          <h4>Notas</h4>
          <Table
            columns={[
              {
                Header: 'Usuario',
                accessor: 'operatorUser.customer.fullName',
                className: 'text-center',
              },
              {
                Header: 'Fecha',
                accessor: 'lastUpdate',
                Cell: (row) =>
                  tzNormalizeDate({
                    date: row.value,
                    format: DATE_TIME_FORMAT,
                  }),
                className: 'text-center',
              },
              {
                Header: 'Nota',
                accessor: 'note',
                className: 'text-center',
              },
            ]}
            data={dailyLiquidationSessionNoteList}
            defaultPageSize={dailyLiquidationSessionNoteList.length}
            showPagination={false}
          />
        </Card>
        <br />
      </Fragment>
    );
  }

  return table;
};

LiquidationNoteResource.propTypes = {
  dailyLiquidationSessionNoteList: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
      lastUpdate: PropTypes.number,
      operatorUser: PropTypes.shape({
        customer: PropTypes.shape({
          fullName: PropTypes.string,
        }),
      }),
    }),
  ),
};

LiquidationNoteResource.defaultProps = {
  dailyLiquidationSessionNoteList: [],
};

export default LiquidationNoteResource;
