import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import Immutable from 'immutable';
import SeatSelectionHeader from '../../../modules/booking/seats/SeatSelectionHeader';
import SeatMap from '../../../modules/booking/seats/SeatMap';
import {
  clearPostpaidBookingState,
  selectPostpaidBookingSeat,
  deselectPostpaidBookingSeat,
} from '../../../../actions';

const PostpaidBookingSeatSelection = ({
  selectedItinerarySearchResults,
  dispatchSelectPostpaidBookingSeat,
  dispatchDeselectPostpaidBookingSeat,
  seatReservations,
  seatSelectionChangesInProgress,
  numPassengers,
}) => {
  // todo: handle multiple itineraries
  const itinerarySearchResult = selectedItinerarySearchResults.get(0);

  if (!itinerarySearchResult)
    return (
      <div className="border rounded p-3 mt-4">
        <h5>Selección de Asientos</h5>
        <span className="text-muted">
          <i>Debe seleccionar un itinerario</i>
        </span>
      </div>
    );

  const seatMap = itinerarySearchResult.seatMapDTO;
  const { floors } = seatMap;
  const destinationLocationId = itinerarySearchResult.destination.id;
  const sourceLocationId = itinerarySearchResult.origin.id;
  const itineraryId = itinerarySearchResult.itinerary.id;
  return (
    <div className="mt-4">
      <SeatSelectionHeader itinerarySearchResult={itinerarySearchResult} />
      <SeatMap
        destinationLocationId={destinationLocationId}
        sourceLocationId={sourceLocationId}
        itineraryId={itineraryId}
        floors={floors}
        handleSeatSelection={dispatchSelectPostpaidBookingSeat}
        handleSeatDeselection={dispatchDeselectPostpaidBookingSeat}
        seatReservations={seatReservations}
        seatSelectionChangesInProgress={seatSelectionChangesInProgress}
        numPassengers={numPassengers}
      />
    </div>
  );
};

PostpaidBookingSeatSelection.propTypes = {
  selectedItinerarySearchResults: PropTypes.instanceOf(Immutable.List)
    .isRequired,
  dispatchSelectPostpaidBookingSeat: PropTypes.func.isRequired,
  dispatchDeselectPostpaidBookingSeat: PropTypes.func.isRequired,
  seatReservations: PropTypes.instanceOf(Immutable.Set).isRequired,
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
  numPassengers: PropTypes.number,
};

PostpaidBookingSeatSelection.defaultProps = {
  numPassengers: null,
};

const mapStateToProps = (state) => ({
  source: state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'search',
    'query',
    'source',
  ]),
  destination: state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'search',
    'query',
    'destination',
  ]),
  time: state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'search',
    'query',
    'time',
  ]),
  selectedItinerarySearchResults:
    state.PostpaidBookingUnit.PostpaidBooking.getIn([
      'itineraries',
      'selectedItinerarySearchResults',
    ]),
  seatReservations: state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'seats',
    'seatReservations',
  ]),
  seatSelectionChangesInProgress:
    state.PostpaidBookingUnit.PostpaidBooking.getIn([
      'seats',
      'seatSelectionChangesInProgress',
    ]),
  numPassengers: state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'search',
    'query',
    'numPassengers',
  ]),
});

const mapDispatchToProps = {
  dispatchClearPostpaidBookingState: clearPostpaidBookingState,
  dispatchReplace: replace,
  dispatchSelectPostpaidBookingSeat: selectPostpaidBookingSeat,
  dispatchDeselectPostpaidBookingSeat: deselectPostpaidBookingSeat,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostpaidBookingSeatSelection);
