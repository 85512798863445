import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import CompanySelect from '../../../common/forms/select/CompanySelect';
import UserSelect from '../../../common/forms/select/UserSelect';
import InformationText from '../../../common/informative/InformationText';
import FormFooter from '../../../common/forms/FormFooter';

export const CompanyUserConfigurationForm = ({ loading, handleSubmit }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Usuario" required>
          <Field name="userId" component={UserSelect} validate={[isRequired]} />
          <InformationText text="Usuario que va a registrar nuevos usuarios." />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Usuario Padre" required>
          <Field
            name="parentUserId"
            component={UserSelect}
            validate={[isRequired]}
          />
          <InformationText text="Usuario Padre que será asignado automáticamente a los usuarios nuevos registrados por el usuario indicado en el primer campo." />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Empresa Tercera" required>
          <Field
            name="companyId"
            component={CompanySelect}
            validate={[isRequired]}
          />
          <InformationText text="Empresa Tercera al que pertenece el Usuario Padre." />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

CompanyUserConfigurationForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ContractUnit }) => ({
  loading: !ContractUnit.CompanyUserConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'CompanyUserConfigurationForm',
})(CompanyUserConfigurationForm);

export default connect(mapStateToProps)(formComponent);
