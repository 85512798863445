import React from 'react';
import { addressBasicInformationPropTypes } from '../proptypes/AddressPropTypes';
import ResourceProperty from '../../../../common/resource/ResourceProperty';

const AddressBasicInformationResource = ({
  address,
  postalCode,
  city,
  districtObj,
}) => (
  <>
    <ResourceProperty label="Dirección: ">{address || ''}</ResourceProperty>
    <ResourceProperty label="Distrito: ">
      {districtObj ? districtObj.name : ''}
    </ResourceProperty>
    <ResourceProperty label="Código Postal: ">
      {postalCode || ''}
    </ResourceProperty>
    <ResourceProperty label="Ciudad: ">
      {city ? city.name : ''}
    </ResourceProperty>
  </>
);

AddressBasicInformationResource.propTypes = addressBasicInformationPropTypes;

export default AddressBasicInformationResource;
