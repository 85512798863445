import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postDetraction } from '../../../../actions';
import { DETRACTION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import DetractionForm from './DetractionForm';

const NewDetraction = ({ breadcrumbs, dispatchpostDetraction }) => {
  const onSubmit = (formValues) =>
    dispatchpostDetraction({
      ...formValues,
      countryCode: formValues.countryId.value,
    });

  const content = <DetractionForm onSubmit={onSubmit} />;
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Detracción"
      subtitle="Crear una nueva Detracción."
      content={content}
    />
  );
};

const mapDispatchToProps = {
  dispatchpostDetraction: postDetraction,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Detracciones',
      href: DETRACTION_PATH,
    },
    {
      text: 'Nueva detracción',
      href: '',
    },
  ],
});

NewDetraction.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchpostDetraction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDetraction);
