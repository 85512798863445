import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DEPRECIATION_RULE_PATH } from '../../../../config/paths';

const EditDepreciationRuleButton = ({ depreciationRuleId }) => (
  <Link
    className="btn btn-primary"
    to={`${DEPRECIATION_RULE_PATH}/${depreciationRuleId}/edit`}
  >
    Editar
  </Link>
);

EditDepreciationRuleButton.propTypes = {
  depreciationRuleId: PropTypes.number.isRequired,
};

export default EditDepreciationRuleButton;
