import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import TextInput from '../../common/forms/input/TextInput';
import {
  authenticateUser,
  clearErrorAuthenticatingUser,
} from '../../../actions';
import { isRequired } from '../../../utils/validators';
import Alert from '../../common/informative/Alert';
import FormItem from '../../common/forms/FormItem';
import FormFooter from '../../common/forms/FormFooter';

// exporting unconected component for testing
// https://bit.ly/2HtSJlT
export class AuthenticateForm extends Component {
  componentWillMount() {
    this.props.clearErrorAuthenticatingUser();
  }

  render() {
    const { handleSubmit, submitting, errorMessage } = this.props;

    const icon = submitting ? 'fa fa-spinner fa-spin' : 'fa fa-sign-in';

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <FormItem label="Email" required>
            <Field
              name="email"
              component={TextInput}
              type="text"
              placeholder="Email"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Contraseña" required>
            <Field
              name="password"
              component={TextInput}
              type="password"
              placeholder="Contraseña"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <Alert message={errorMessage} />
        <FormFooter
          saveButtondisabled={submitting}
          saveButtonText="Ingresar"
          saveButtonIcon={icon}
        />
      </Form>
    );
  }
}

const mapStateToProps = ({ authentication }) => ({
  errorMessage: authentication.get('error'),
});

const mapDispatchToProps = {
  clearErrorAuthenticatingUser,
  onSubmit: authenticateUser,
};

AuthenticateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  clearErrorAuthenticatingUser: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'AuthenticateForm',
  })(AuthenticateForm),
);
