import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getSegments, clearSegments } from '../../../../actions/';
import { SEGMENT_PATH, NEW_SEGMENT_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { SEGMENT_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Segments extends Component {
  componentWillUnmount() {
    this.props.dispatchClearSegments();
  }

  onFetchData = (fetchData) => {
    const newFetchData = { ...fetchData };

    newFetchData.query = [...fetchData.query, ...['lackingData:false']];

    this.props.dispatchGetSegments({
      ...newFetchData,
    });
  };

  render() {
    const { segments, loading, breadcrumbs } = this.props;
    const data = segments.get('content') || [];
    const pages = segments.get('totalPages') || null;
    const defaultPageSize = segments.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Segmentos"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_SEGMENT_PATH}
        tableStructure={{
          columns: SEGMENT_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: this.onFetchData,
          modelPath: SEGMENT_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetSegments: getSegments,
  dispatchClearSegments: clearSegments,
};

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Segmentos',
      href: SEGMENT_PATH,
    },
  ],
  segments: RouteUnit.Segment.getIn(['all', 'content']),
  loading: RouteUnit.Segment.getIn(['all', 'loading']),
});

Segments.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  segments: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetSegments: PropTypes.func.isRequired,
  dispatchClearSegments: PropTypes.func.isRequired,
};

Segments.defaultProps = {
  segments: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Segments);
