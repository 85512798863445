import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { getPdfManualSenderRemissionGuide } from '../../../../actions/cargo/ManualSenderRemissionGuide';

const PrintBillingButton = (props) => {
  const { ticket, dispatchGetPdfManualSenderRemissionGuide } = props;

  const printBillingButon = () => {
    dispatchGetPdfManualSenderRemissionGuide(ticket);
  };

  return (
    <Button color="light" className="border" onClick={printBillingButon}>
      <i className="fa fa-print" /> Imprimir
    </Button>
  );
};

PrintBillingButton.propTypes = {
  ticket: PropTypes.string.isRequired,
  dispatchGetPdfManualSenderRemissionGuide: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetPdfManualSenderRemissionGuide: getPdfManualSenderRemissionGuide,
};

export default connect(null, mapDispatchToProps)(PrintBillingButton);
