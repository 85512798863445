import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import LocationEditButton from './LocationEditButton';
import LocationDeleteButton from './LocationDeleteButton';

const LocationToolbar = ({ locationId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <LocationEditButton locationId={locationId} />{' '}
      <LocationDeleteButton locationId={locationId} />
    </div>
  </ButtonToolbar>
);

LocationToolbar.propTypes = {
  locationId: PropTypes.number.isRequired,
};

export default LocationToolbar;
