import Immutable from 'immutable';
import {
  FLAG_GETTING_DEPRECIATION_RULES,
  GET_DEPRECIATION_RULES,
  CLEAR_DEPRECIATION_RULES,
  FLAG_DEPRECIATION_RULE_ACTIVITY,
  GET_DEPRECIATION_RULE,
  CLEAR_DEPRECIATION_RULE,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_DEPRECIATION_RULES:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_DEPRECIATION_RULES:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_DEPRECIATION_RULES:
      return state.setIn(['all', 'content'], Immutable.Map(COLLECTION_SHAPE));
    case FLAG_DEPRECIATION_RULE_ACTIVITY:
      if (action.payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(action.payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_DEPRECIATION_RULE:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case CLEAR_DEPRECIATION_RULE:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
