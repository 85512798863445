import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  Button,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  getItinerarySeatMap,
  clearItinerarySeatMap,
  clearItineraryPassengerList,
  clearServiceMergeId,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import SeatMap from '../../../modules/booking/seats/SeatMap';
import SeatSelectionHeader from '../../../modules/booking/seats/SeatSelectionHeader';
import { Table } from '../../../common/Table';
import { getPrintedMergedTickets } from '../../../../actions/itinerary';
import { printPrintedTickets } from '../../../../utils/printers/Ticket';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import { isRequired } from '../../../../utils/validators';
import { padStart } from '../../../../utils/string';

export class ServiceMergeForm extends Component {
  componentWillUnmount() {
    const {
      dispatchClearItinerarySeatMap,
      dispatchClearItineraryPassengerList,
      dispatchClearServiceMergeId,
    } = this.props;
    dispatchClearItinerarySeatMap({ type: 'source' });
    dispatchClearItinerarySeatMap({ type: '' });
    dispatchClearItineraryPassengerList({ type: 'source' });
    dispatchClearItineraryPassengerList({ type: '' });
    dispatchClearServiceMergeId();
  }

  onHandleSubmitSameSeat = (formValues) => {
    const { onSubmit } = this.props;
    onSubmit({ forced: false, ...formValues });
  };

  onHandleSubmitForcedMerge = (formValues) => {
    const { onSubmit } = this.props;
    onSubmit({ forced: true, ...formValues });
  };

  onHandlePrintingTickets = async () => {
    const { dispatchGetPrintedMergedTickets, serviceMergeId } = this.props;
    const printedTickets = await dispatchGetPrintedMergedTickets({
      serviceMergeId,
    });
    if (printedTickets) {
      printPrintedTickets(printedTickets);
    }
  };

  generatePassengerListData = (passengerList) => {
    const data = [];

    passengerList.forEach((passenger) => {
      if (passenger.ticketStatus === 'ACTIVE') {
        const row = {};
        row.seatNumber = passenger.seatNumber;
        row.ticket = `${passenger.documentSeries}-${padStart(
          passenger.documentCode.toString(),
          7,
        )}`;
        row.customerFullName = passenger.customer.fullName;
        data.push(row);
      }
    });

    return data;
  };

  renderPassengerTable = (title, data, loading) => {
    if (loading) return <Loader />;
    if (data.isEmpty()) return null;

    const passengerListData = this.generatePassengerListData(data);

    return (
      <div>
        <h4 className="text-center">{title}</h4>
        <Table
          columns={[
            {
              Header: 'Asiento',
              accessor: 'seatNumber',
              className: 'text-center',
              width: 80,
            },
            {
              Header: 'Boleto',
              accessor: 'ticket',
              className: 'text-center',
              width: 150,
            },
            {
              Header: 'Pasajero',
              accessor: 'customerFullName',
              className: 'text-center',
            },
          ]}
          data={passengerListData}
          defaultPageSize={passengerListData.length}
          showPagination={false}
        />
      </div>
    );
  };

  renderPassengerList = () => {
    const {
      passengerListSourceItinerary,
      loadingSourceItinerary,
      passengerListDestinationItinerary,
      loadingDestinationItinerary,
    } = this.props;

    return (
      <Container fluid className="mt-4">
        <Row>
          <Col md={6} sm={12} xs={12}>
            {this.renderPassengerTable(
              'Pasajeros Itinerario Inicial',
              passengerListSourceItinerary,
              loadingSourceItinerary,
            )}
          </Col>
          <Col md={6} sm={12} xs={12}>
            {this.renderPassengerTable(
              'Pasajeros Itinerario Final',
              passengerListDestinationItinerary,
              loadingDestinationItinerary,
            )}
          </Col>
        </Row>
      </Container>
    );
  };

  renderSeatMap = (title, data, loading) => {
    if (loading) return <Loader />;
    if (data.isEmpty()) return null;

    const destinationLocationId = data.get('destination').id;
    const sourceLocationId = data.get('origin').id;
    const itineraryId = data.get('itinerary').id;
    const { floors } = data.get('seatMapDTO');
    const seatReservations = Immutable.Set();
    const seatSelectionChangesInProgress = Immutable.Set();
    const numPassengers = data.get('availableSeats');

    const itinerarySearchResult = {
      seatMapDisplayName: data.get('seatMapDisplayName'),
      arrivalTime: data.get('arrivalTime'),
      departureTime: data.get('departureTime'),
      origin: data.get('origin'),
      destination: data.get('destination'),
    };

    return (
      <div>
        <Card>
          <CardBody>
            <CardTitle tag="h4" className="text-center">
              {title}
            </CardTitle>
            <CardSubtitle>
              <SeatSelectionHeader
                itinerarySearchResult={itinerarySearchResult}
              />
            </CardSubtitle>
          </CardBody>
          <CardBody>
            <SeatMap
              destinationLocationId={destinationLocationId}
              sourceLocationId={sourceLocationId}
              itineraryId={itineraryId}
              floors={floors}
              handleSeatSelection={() => {}}
              handleSeatDeselection={() => {}}
              seatReservations={seatReservations}
              seatSelectionChangesInProgress={seatSelectionChangesInProgress}
              numPassengers={numPassengers}
            />
          </CardBody>
        </Card>
      </div>
    );
  };

  renderSeatSelection = () => {
    const {
      sourceItinerary,
      loadingSourceItinerary,
      destinationItinerary,
      loadingDestinationItinerary,
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col md={6} sm={12} xs={12}>
            {this.renderSeatMap(
              'Mapa Asientos Itinerario Inicial',
              sourceItinerary,
              loadingSourceItinerary,
            )}
          </Col>
          <Col md={6} sm={12} xs={12}>
            {this.renderSeatMap(
              'Mapa Asientos Itinerario Final',
              destinationItinerary,
              loadingDestinationItinerary,
            )}
          </Col>
        </Row>
      </Container>
    );
  };

  renderPrintingTicketsButton = () => {
    const { serviceMergeId } = this.props;
    if (serviceMergeId) {
      return (
        <Button
          type="button"
          onClick={this.onHandlePrintingTickets}
          color="success"
        >
          Imprimir Tickets Migrados
        </Button>
      );
    }
    return null;
  };

  render() {
    const {
      handleSubmit,
      loadingServiceMerge,
      dispatchGetItinerarySeatMap,
      dispatchClearServiceMergeId,
    } = this.props;

    if (loadingServiceMerge) return <Loader />;

    const seatSelection = this.renderSeatSelection();
    const passengerList = this.renderPassengerList();
    const printingTicketsButton = this.renderPrintingTicketsButton();

    return (
      <Fragment>
        <Form>
          <FormGroup row>
            <FormItem label="Itinerario Origen" required>
              <Field
                name="sourceItineraryId"
                component={ItinerarySearchInput}
                placeholder="ID Itinerario Origen"
                handleSelectItinerary={({ itineraryId }) =>
                  dispatchGetItinerarySeatMap({ itineraryId, type: 'source' })
                }
                handleShowItinerarySearch={dispatchClearServiceMergeId}
                validate={[isRequired]}
                props={{
                  form: 'ServiceMergeForm',
                }}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Itinerario Destino" required>
              <Field
                name="destinationItineraryId"
                component={ItinerarySearchInput}
                placeholder="ID Itinerario Destino"
                handleSelectItinerary={({ itineraryId }) =>
                  dispatchGetItinerarySeatMap({
                    itineraryId,
                    type: 'destination',
                  })
                }
                handleShowItinerarySearch={dispatchClearServiceMergeId}
                validate={[isRequired]}
                props={{
                  form: 'ServiceMergeForm',
                }}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Comentarios ">
              <Field name="comment" component={TextInput} type="textarea" />
            </FormItem>
          </FormGroup>
          <ButtonToolbar className="flex row-reverse mb-4">
            <div>
              <Button
                type="button"
                onClick={handleSubmit(this.onHandleSubmitSameSeat)}
                color="primary"
              >
                Migrar Mismos Asientos
              </Button>{' '}
              <Button
                type="button"
                onClick={handleSubmit(this.onHandleSubmitForcedMerge)}
                color="primary"
              >
                Migrar Forzar
              </Button>{' '}
              {printingTicketsButton}
            </div>
          </ButtonToolbar>
          {seatSelection}
          {passengerList}
        </Form>
      </Fragment>
    );
  }
}

ServiceMergeForm.propTypes = {
  dispatchClearServiceMergeId: PropTypes.func.isRequired,
  dispatchGetItinerarySeatMap: PropTypes.func.isRequired,
  dispatchClearItinerarySeatMap: PropTypes.func.isRequired,
  dispatchClearItineraryPassengerList: PropTypes.func.isRequired,
  dispatchGetPrintedMergedTickets: PropTypes.func.isRequired,
  loadingServiceMerge: PropTypes.bool.isRequired,
  serviceMergeId: PropTypes.number,
  loadingSourceItinerary: PropTypes.bool.isRequired,
  sourceItinerary: PropTypes.instanceOf(Immutable.Map),
  passengerListSourceItinerary: PropTypes.instanceOf(Immutable.Set).isRequired,
  loadingDestinationItinerary: PropTypes.bool.isRequired,
  destinationItinerary: PropTypes.instanceOf(Immutable.Map),
  passengerListDestinationItinerary: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ServiceMergeForm.defaultProps = {
  sourceItinerary: null,
  destinationItinerary: null,
  serviceMergeId: null,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loadingServiceMerge: !ItineraryUnit.ServiceMerge.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  serviceMergeId: ItineraryUnit.ServiceMerge.getIn(['current', 'id']),
  loadingSourceItinerary: ItineraryUnit.ServiceMerge.getIn([
    'sourceItinerary',
    'loading',
  ]),
  sourceItinerary: ItineraryUnit.ServiceMerge.getIn([
    'sourceItinerary',
    'seatMap',
  ]),
  passengerListSourceItinerary: ItineraryUnit.ServiceMerge.getIn([
    'sourceItinerary',
    'passengerList',
  ]),
  loadingDestinationItinerary: ItineraryUnit.ServiceMerge.getIn([
    'destinationItinerary',
    'loading',
  ]),
  destinationItinerary: ItineraryUnit.ServiceMerge.getIn([
    'destinationItinerary',
    'seatMap',
  ]),
  passengerListDestinationItinerary: ItineraryUnit.ServiceMerge.getIn([
    'destinationItinerary',
    'passengerList',
  ]),
});

const mapDispatchToProps = {
  dispatchGetItinerarySeatMap: getItinerarySeatMap,
  dispatchClearItinerarySeatMap: clearItinerarySeatMap,
  dispatchClearItineraryPassengerList: clearItineraryPassengerList,
  dispatchClearServiceMergeId: clearServiceMergeId,
  dispatchGetPrintedMergedTickets: getPrintedMergedTickets,
};

export default reduxForm({
  form: 'ServiceMergeForm',
})(connect(mapStateToProps, mapDispatchToProps)(ServiceMergeForm));
