import React from 'react';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import CargoPickupForm from './CargoPickupForm';

export const CargoPickup = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Entrega de carga"
    subtitle="Entrega de carga"
    content={<CargoPickupForm />}
  />
);

CargoPickup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Entrega de carga',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(CargoPickup);
