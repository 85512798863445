import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { CARGO_PATH, NEW_CARGO_PATH } from '../../../../config/paths';
import { getParcels, clearParcels } from '../../../../actions/cargo/Cargo';
import { PARCELS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ParcelSearchForm from './ParcelSearchForm';
import Content from '../../../layout/Content';
import TableToolbar from '../../../common/TableToolbar';
import Table from '../../../common/Table';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_FORMAT } from '../../../../config/locale';
import { DEFAULT_QUERY } from '../../../../config/queries';

const Parcels = ({
  parcels,
  loading,
  breadcrumbs,
  dispatchGetParcels,
  dispatchClearParcels,
}) => {
  useLayoutEffect(() => () => dispatchClearParcels(), []);

  const [query, setQuery] = useState('');

  const data = parcels.get('content') || [];
  const pages = parcels.get('totalPages') || null;
  const defaultPageSize = parcels.get('size') || DEFAULT_PAGE_SIZE;

  const toolBar = (
    <TableToolbar
      buttonPath={NEW_CARGO_PATH}
      buttonText="Crear"
      buttonClassName="btn btn-success"
    />
  );

  const onHandleSubmit = (formValues) => {
    const newFormValues = {
      ...DEFAULT_QUERY,
      size: DEFAULT_PAGE_SIZE,
      query: `createDate$${tzNormalizeDate({
        date: formValues.fromDateString,
        format: DATE_FORMAT,
      })},${tzNormalizeDate({
        date: formValues.toDateString,
        format: DATE_FORMAT,
      })}`,
    };
    setQuery(newFormValues.query);
    dispatchGetParcels(newFormValues);
  };

  const content = (
    <>
      <ParcelSearchForm
        onSubmit={onHandleSubmit}
        initialValues={{
          fromDateString: tzNormalizeDate(),
          toDateString: tzNormalizeDate(),
        }}
      />
      <h3>Resultado de Búsqueda de Carga</h3>
      <Table
        columns={PARCELS_COLUMNS}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={dispatchGetParcels}
        modelPath={CARGO_PATH}
        loading={loading}
        filterable
        navigateToModelOnRowClick
        openPathInNewTab
        queryProps={query}
      />
    </>
  );

  return (
    <Content
      title="Carga"
      breadcrumbs={breadcrumbs}
      toolbar={toolBar}
      content={content}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetParcels: getParcels,
  dispatchClearParcels: clearParcels,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Carga',
      href: CARGO_PATH,
    },
  ],
  parcels: CargoUnit.Cargo.getIn(['all', 'content']),
  loading: CargoUnit.Cargo.getIn(['all', 'loading']),
});

Parcels.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  parcels: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetParcels: PropTypes.func.isRequired,
  dispatchClearParcels: PropTypes.func.isRequired,
};

Parcels.defaultProps = {
  parcels: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Parcels);
