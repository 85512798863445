import {
  shape,
  func,
  oneOfType,
  number,
  string,
  object,
  array,
  bool,
} from 'prop-types';

const inputPropTypes = shape({
  onChange: func,
  onBlur: func,
  value: oneOfType([number, string, object, array]),
});

const metaPropTypes = shape({
  touched: bool,
  warning: string,
  error: string,
});

const datePropTypes = oneOfType([number, string, object]);

const inputDefaultProps = {
  input: () => {},
  value: null,
};

const metaDefaultProps = {
  touched: false,
};

export {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
  datePropTypes,
};
