import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { changeStep } from '../../../actions';
import {
  POSTPAID_BOOKING_STEPS,
  POSTPAID_BOOKING_SEARCH_STEP_INDEX,
} from '../../../config/constants';

const withoutReservation = (ChildComponent) => {
  class ComposedComponent extends Component {
    static propTypes = {
      reservationCreated: PropTypes.bool.isRequired,
      dispatchChangeStep: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      const { reservationCreated, dispatchChangeStep } = this.props;
      // send user to search page.
      if (reservationCreated) {
        toastr.warning(
          'Advertencia',
          'Acabas de realizar una reserva. Se retornará a la busqueda.',
        );
        const postpaidSearchstep =
          POSTPAID_BOOKING_STEPS[POSTPAID_BOOKING_SEARCH_STEP_INDEX];
        setTimeout(() => {
          dispatchChangeStep(postpaidSearchstep);
        }, 1500);
      }
    }

    render() {
      const { reservationCreated } = this.props;
      if (reservationCreated) {
        return <div />;
      }

      return <ChildComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ PostpaidBookingUnit }) => ({
    reservationCreated:
      PostpaidBookingUnit.PostpaidBooking.get('reservationCreated'),
  });

  const mapDispatchToProps = {
    dispatchChangeStep: changeStep,
  };

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};

export default withoutReservation;
