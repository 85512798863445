import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Row, Col, FormGroup } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, isValidNumber } from '../../../../utils/validators';
import RouteSelect from '../../../common/forms/select/RouteSelect';
import Grid from '../../../common/grid/Grid';
import { BUS_GROUP_GALLONS_GRID_COLUMNS } from '../../../../config/gridColumns';
import TextInput from '../../../common/forms/input/TextInput';
import {
  getBusFuelGroups,
  clearBusFuelGroups,
  clearExpectedFuelConsumptions,
  getExpectedFuelConsumptionsByRoute,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import Alert from '../../../common/informative/Alert';
import FormFooter from '../../../common/forms/FormFooter';

class ExpectedFuelConsumptionForm extends Component {
  state = {
    showGridComponent: false,
    gridValues: [],
  };

  componentDidMount() {
    this.onMount();
  }

  componentWillUnmount() {
    const { dispatchClearBusFuelGroups } = this.props;
    dispatchClearBusFuelGroups();
  }

  onMount = () => {
    const { dispatchGetBusFuelGroups, initialValues } = this.props;
    dispatchGetBusFuelGroups(DEFAULT_QUERY_GET_ALL);
    if (initialValues) {
      this.setState({ showGridComponent: true });
    }
  };

  onGridValueChange = (values) => {
    this.setState({ gridValues: values });
  };

  onHandleSubmit = (formValues) => {
    const { gridValues } = this.state;
    const newExpectedFuelConsumption = {
      routeId: formValues.route.value,
      comment: formValues.comment,
    };
    if (this.validateGridValues(gridValues)) {
      newExpectedFuelConsumption.busFuelGroupConsumptionList = gridValues.map(
        (gridValue) => ({
          busFuelGroupId: gridValue.busFuelGroupId.value,
          numberOfGallon: parseFloat(gridValue.numberOfGallon),
          numberOfGallonAdblue: gridValue.numberOfGallonAdblue
            ? parseFloat(gridValue.numberOfGallonAdblue)
            : undefined,
        }),
      );
      this.props.onSubmit(newExpectedFuelConsumption);
    }
  };

  validateGridValues = (gridValues) => {
    let isValid = true;
    if (gridValues.length) {
      const isInvalidData = gridValues.some((rowValue) => {
        // validate the content of key in each row
        if (!(rowValue.busFuelGroupId && rowValue.busFuelGroupId.value)) {
          toastr.error('Error','Seleccione un grupo.');
          return true;
        }
        if (
          !(rowValue.numberOfGallon && isValidNumber(rowValue.numberOfGallon))
        ) {
          toastr.error('Error','Ingrese un número de galones válido.');
          return true;
        }
        if (
          rowValue.numberOfGallonAdblue &&
          !isValidNumber(rowValue.numberOfGallonAdblue)
        ) {
          toastr.error('Error','Ingrese una cantidad de ad blue válida.');
          return true;
        }
        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error','Ingrese datos de galones.');
      isValid = false;
    }
    return isValid;
  };

  handleRouteChange = (route) => {
    const {
      dispatchGetExpectedFuelConsumptionsByRoute,
      dispatchClearExpectedFuelConsumptions,
    } = this.props;
    dispatchClearExpectedFuelConsumptions();
    if (route.value) {
      dispatchGetExpectedFuelConsumptionsByRoute(route.value);
      this.setState({ showGridComponent: true });
    } else {
      this.setState({ showGridComponent: false });
    }
  };

  dataGridInitialValues = (initialValues) =>
    initialValues.busFuelGroupConsumptionList
      ? initialValues.busFuelGroupConsumptionList
      : [];

  showGridComponent = (expectedFuel) => {
    const { initialValues } = this.props;
    if (
      expectedFuel.length === 0 ||
      initialValues.busFuelGroupConsumptionList
    ) {
      return (
        <Grid
          columns={BUS_GROUP_GALLONS_GRID_COLUMNS}
          initialValues={this.dataGridInitialValues(initialValues)}
          onValueChange={this.onGridValueChange}
        />
      );
    }
    return <Alert message="La ruta seleccionada ya tiene dotación" />;
  };

  render() {
    const { showGridComponent } = this.state;
    const { loading, handleSubmit, expectedFuels, initialValues } = this.props;

    if (loading) {
      return <Loader />;
    }

    let gridComponent = <Alert message="Seleccione una ruta" />;

    const showGrid =
      showGridComponent && !expectedFuels.isEmpty()
        ? showGridComponent && !expectedFuels.isEmpty()
        : showGridComponent && initialValues.busFuelGroupConsumptionList;
    const dataGrid = expectedFuels.get('content')
      ? expectedFuels.get('content')
      : initialValues.busFuelGroupConsumptionList;

    if (showGrid) {
      gridComponent = this.showGridComponent(dataGrid);
    }

    return (
      <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Ruta" required>
            <Field
              name="route"
              component={RouteSelect}
              validate={[isRequired]}
              onChange={this.handleRouteChange}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentario:">
            <Field name="comment" component={TextInput} type="textarea" />
          </FormItem>
        </FormGroup>
        <Row>
          <Col>
            <h2>Galones</h2>
          </Col>
        </Row>
        {gridComponent}
        <FormFooter />
      </Form>
    );
  }
}

ExpectedFuelConsumptionForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetBusFuelGroups: PropTypes.func.isRequired,
  dispatchClearBusFuelGroups: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    route: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
    comment: PropTypes.string,
    busFuelGroupConsumptionList: PropTypes.arrayOf(
      PropTypes.shape({
        numberOfGallon: PropTypes.number,
        busFuelGroupId: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
      }),
    ),
  }),
  expectedFuels: PropTypes.instanceOf(Immutable.Map),
  dispatchGetExpectedFuelConsumptionsByRoute: PropTypes.func.isRequired,
  dispatchClearExpectedFuelConsumptions: PropTypes.func.isRequired,
};

ExpectedFuelConsumptionForm.defaultProps = {
  expectedFuels: null,
  initialValues: {},
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.ExpectedFuelConsumption.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  expectedFuels: TrafficUnit.ExpectedFuelConsumption.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetBusFuelGroups: getBusFuelGroups,
  dispatchClearBusFuelGroups: clearBusFuelGroups,
  dispatchGetExpectedFuelConsumptionsByRoute:
    getExpectedFuelConsumptionsByRoute,
  dispatchClearExpectedFuelConsumptions: clearExpectedFuelConsumptions,
};

const formComponent = reduxForm({
  form: 'ExpectedFuelConsumptionForm',
})(ExpectedFuelConsumptionForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
