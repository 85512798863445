import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import AuthenticateForm from './AuthenticateForm';
import { deauthenticateUser } from '../../../actions';

// exporting unconnected component for testing
// https://bit.ly/2HtSJlT
export class SignOut extends Component {
  componentDidMount() {
    this.props.deauthenticateUser();
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Volver a Ingresar</h1>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container>
          <AuthenticateForm />
        </Container>
      </div>
    );
  }
}

SignOut.propTypes = {
  deauthenticateUser: PropTypes.func.isRequired,
};

export default connect(
  null,
  { deauthenticateUser },
)(SignOut);
