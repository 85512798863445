import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { change, Field } from 'redux-form';
import { connect } from 'react-redux';
import FormItem from '../../../common/forms/FormItem';
import { TRIP_CIRCUIT_STATUS_OPTIONS } from '../../../../config/constants';
import { isRequired } from '../../../../utils/validators';
import TripStatusSelect from '../../../common/forms/select/TripStatusSelect';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import DriverSelect from '../../../common/forms/select/DriverSelect';
import CabinCrewSelect from '../../../common/forms/select/CabinCrewSelect';
import DatePicker from '../../../common/forms/input/DatePicker';
import TextInput from '../../../common/forms/input/TextInput';
import {
  clearDriverBusAssignments,
  getDriverBusAssignments,
} from '../../../../actions';
import Select from '../../../common/forms/select/Select';

class ItineraryScheduleFormFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeDrivers: false,
    };
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  onDidUpdate = () => {
    const { changeDrivers } = this.state;

    if (changeDrivers) {
      const {
        driversAssignment,
        dispatchChange,
        dispatchClearDriverBusAssignments,
        form,
      } = this.props;

      dispatchClearDriverBusAssignments();

      const drivers = [];

      driversAssignment.forEach((driver) => {
        const { driverAssignmentDateList } = driver;
        drivers.push({
          value: driverAssignmentDateList[0].driverId,
          label: driverAssignmentDateList[0].driver.driverName,
        });
      });

      dispatchChange(form, 'driverIdList', drivers);

      if (driversAssignment.length !== 0) {
        this.setState({ changeDrivers: false });
      }
    }
  };

  onRegisteredBusChange = (bus) => {
    const { dispatchGetDriverBusAssignments } = this.props;

    if (bus.value) {
      this.setState({ changeDrivers: true });
      dispatchGetDriverBusAssignments({ query: `companyBusId:${bus.label}` });
    }
  };

  render() {
    const { itineraryId, loadingDriversAssignment, disableTripFields } =
      this.props;

    return (
      <Fragment>
        <FormGroup row>
          <FormItem label="Orden dentro del Circuito" required>
            <Field
              name="tripCircuitStatus"
              component={Select}
              options={TRIP_CIRCUIT_STATUS_OPTIONS}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Estado" required>
            <Field
              name="tripStatusId"
              component={TripStatusSelect}
              validate={[isRequired]}
              isDisabled={disableTripFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Número de Bus">
            <Field
              name="companyBusId"
              component={RegisteredBusSelect}
              onChange={this.onRegisteredBusChange}
              isDisabled={disableTripFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Conductores">
            <Field
              name="driverIdList"
              component={DriverSelect}
              props={{ itineraryId }}
              isLoading={loadingDriversAssignment}
              isMulti
              isDisabled={disableTripFields}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Servicio a Bordo">
            <Field
              name="cabinCrewIdList"
              component={CabinCrewSelect}
              props={{ itineraryId }}
              isMulti
              isDisabled={disableTripFields}
              isClearable
            />
          </FormItem>
        </FormGroup>
        <h3>Estadísticas</h3>
        <FormGroup row>
          <FormItem label="Hora de Salida Estimada">
            <Field
              name="estimatedDepartureTime"
              props={{
                time: true,
              }}
              component={DatePicker}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Hora de Salida Actual">
            <Field
              name="actualDepartureTime"
              props={{
                time: true,
              }}
              component={DatePicker}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Hora de Llegada Estimada">
            <Field
              name="estimatedArrivalTime"
              props={{
                time: true,
              }}
              component={DatePicker}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Hora de Llegada Actual">
            <Field
              name="actualArrivalTime"
              props={{
                time: true,
              }}
              component={DatePicker}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Lectura de Odómetro Inicial">
            <Field
              name="initialOdometerReading"
              component={TextInput}
              type="text"
              placeholder="Lectura de Odómetro Inicial"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Lectura de Odómetro Final">
            <Field
              name="finalOdometerReading"
              component={TextInput}
              type="text"
              placeholder="Lectura de Odómetro Final"
            />
          </FormItem>
        </FormGroup>
      </Fragment>
    );
  }
}

ItineraryScheduleFormFields.propTypes = {
  itineraryId: PropTypes.number.isRequired,
  form: PropTypes.string.isRequired,
};

ItineraryScheduleFormFields.propTypes = {
  dispatchGetDriverBusAssignments: PropTypes.func.isRequired,
  dispatchClearDriverBusAssignments: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  loadingDriversAssignment: PropTypes.bool,
  driversAssignment: PropTypes.arrayOf(PropTypes.shape({})),
  disableTripFields: PropTypes.bool,
};

ItineraryScheduleFormFields.defaultProps = {
  loadingDriversAssignment: false,
  driversAssignment: [],
  disableTripFields: false,
};

const mapStateToProps = ({ ItineraryUnit, TrafficUnit }) => ({
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  loadingDriversAssignment: TrafficUnit.DriverBusAssignment.getIn([
    'all',
    'loading',
  ]),
  driversAssignment: TrafficUnit.DriverBusAssignment.getIn([
    'all',
    'content',
  ]).get('content'),
});

const mapDispatchToProps = {
  dispatchGetDriverBusAssignments: getDriverBusAssignments,
  dispatchClearDriverBusAssignments: clearDriverBusAssignments,
  dispatchChange: change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItineraryScheduleFormFields);
