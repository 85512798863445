export const GET_KIDS_ON_ITINERARY = 'GET_KIDS_ON_ITINERARY';
export const CLEAR_KIDS_ON_ITINERARY = 'CLEAR_KIDS_ON_ITINERARY';
export const FLAG_KIDS_ON_ITINERARY_ACTIVITY =
  'FLAG_KIDS_ON_ITINERARY_ACTIVITY';
export const POST_KIDS_ON_ITINERARY = 'POST_KIDS_ON_ITINERARY';
export const DELETE_KIDS_ON_ITINERARY = 'DELETE_KIDS_ON_ITINERARY';
export const POST_KIDS_ON_POSTPAID_ITINERARY =
  'POST_KIDS_ON_POSTPAID_ITINERARY';
export const DELETE_KIDS_ON_POSTPAID_ITINERARY =
  'DELETE_KIDS_ON_POSTPAID_ITINERARY';
