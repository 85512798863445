import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getMaintenanceWarningReporters,
  clearCustomers,
} from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_QUERY } from '../../../../config/queries';

class MaintenanceWarningReporterSelect extends Component {
  componentWillUnmount() {
    this.props.dispatchClearCustomer();
  }

  handleCustomerChange = (inputValue) => {
    const { dispatchGetMaintenanceWarningReporters } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetMaintenanceWarningReporters({
        ...DEFAULT_QUERY,
        query: inputValue,
      });
    }
  };

  render() {
    const {
      options,
      loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
      autoFocus,
    } = this.props;

    const props = {
      options,
      onInputChange: this.handleCustomerChange,
      isLoading: loading,
      noOptionsMessage,
      input,
      meta,
      isDisabled,
      isClearable,
      autoFocus,
    };

    return <Select {...props} />;
  }
}

const mapStateToProps = ({ UserUnit }, { options }) => {
  const customers = UserUnit.Customer.getIn(['all', 'content', 'content']);

  return {
    options:
      customers.length === 0
        ? options
        : customers.map((customer) => ({
            value: customer.id,
            label: `${customer.idDocumentNumber} (${customer.idCountryOfOrigin}) - ${customer.fullName}`,
            id: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            fullName: customer.fullName,
            idDocumentNumber: customer.idDocumentNumber,
            gender: customer.gender,
            idCountryOfOrigin: customer.idCountryOfOrigin,
            identificationType: customer.identificationType,
            email: customer.email,
            mobilePhone: customer.mobilePhone,
            dob: customer.dob,
            discountCode: customer.discountCode,
          })),
    loading: UserUnit.Customer.getIn(['all', 'loading']),
  };
};

MaintenanceWarningReporterSelect.propTypes = {
  options: optionsPropTypes,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetMaintenanceWarningReporters: PropTypes.func.isRequired,
  dispatchClearCustomer: PropTypes.func.isRequired,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

MaintenanceWarningReporterSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  noOptionsMessage: undefined,
  isDisabled: false,
  isClearable: false,
  options: [],
  autoFocus: false,
};
const mapDispatchToProps = {
  dispatchGetMaintenanceWarningReporters: getMaintenanceWarningReporters,
  dispatchClearCustomer: clearCustomers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceWarningReporterSelect);
