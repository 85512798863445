import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import AccountingReport from './AccountingReport';
import CallCenterReport from './CallCenterReport';
import DiscountCodeReport from './DiscountCodeReport';
import ExpenseReport from './ExpenseReport';
import ItineraryReport from './ItineraryReport';
import NotBoardedTicketReport from './NotBoardedTicketReport';
import OccupancyReport from './OccupancyReport';
import TicketReport from './TicketReport';
import PricingProfileReport from './PricingProfileReport';

export default combineReducers({
  UnitHome,
  AccountingReport,
  CallCenterReport,
  DiscountCodeReport,
  ExpenseReport,
  ItineraryReport,
  NotBoardedTicketReport,
  OccupancyReport,
  TicketReport,
  PricingProfileReport,
});
