import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TicketCustomerSearchForm from './TicketCustomerSearchForm';
import TicketCustomerSearchResult from './TicketCustomerSearchResult';
import { getTicketCustomer } from '../../../../actions';
import { TICKET_PATH } from '../../../../config/paths';

export class TicketCustomerSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFormValues: {
        customerOrderId: 0,
      },
      size: 10,
    };
  }

  onSubmit = formValues => {
    const { dispatchGetTicketCustomer } = this.props;

    const { size } = this.state;

    const ticketCustomerSearchFormValues = {
      customerOrderId: formValues.customerOrderId
        ? Number(formValues.customerOrderId)
        : 0,
      size,
    };
    this.setState({ searchFormValues: ticketCustomerSearchFormValues });

    dispatchGetTicketCustomer(ticketCustomerSearchFormValues);
  };

  render() {
    const { searchFormValues } = this.state;

    const { modelPath, openPathInNewTab } = this.props;

    return (
      <div>
        <TicketCustomerSearchForm onSubmit={this.onSubmit} />
        <TicketCustomerSearchResult
          searchFormValues={searchFormValues}
          modelPath={modelPath}
          openPathInNewTab={openPathInNewTab}
        />
      </div>
    );
  }
}

TicketCustomerSearch.propTypes = {
  dispatchGetTicketCustomer: PropTypes.func.isRequired,
  modelPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  openPathInNewTab: PropTypes.bool,
};

TicketCustomerSearch.defaultProps = {
  modelPath: TICKET_PATH,
  openPathInNewTab: true,
};

const mapDispatchToProps = {
  dispatchGetTicketCustomer: getTicketCustomer,
};

export default connect(
  null,
  mapDispatchToProps,
)(TicketCustomerSearch);
