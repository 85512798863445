import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteBaggage } from '../../../../actions';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class DeleteBaggageButton extends Component {
  state = {
    showModal: false,
  };

  onClickDelete = () => {
    const { baggageId, dispatchDeleteBaggage } = this.props;
    dispatchDeleteBaggage({ baggageId });
  };

  openModal = () => this.setState({ showModal: true });

  closeModal = () => this.setState({ showModal: false });

  render() {
    const warningModal = (
      <ConfirmationModal
        show={this.state.showModal}
        onClickConfirm={this.onClickDelete}
        onClickCancel={this.closeModal}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.openModal}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DeleteBaggageButton.propTypes = {
  baggageId: PropTypes.number.isRequired,
  dispatchDeleteBaggage: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteBaggage: deleteBaggage,
};

export default connect(null, mapDispatchToProps)(DeleteBaggageButton);
