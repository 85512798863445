import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Button } from 'reactstrap';
import {
  getCarrierRemissionGuidePDFByItineraryV1,
  getCarrierRemissionGuidePDFByItineraryV2,
  getItineraryJson,
} from '../../../../../actions';
import printCarrierRemissionGuide from '../../../../../utils/printers/CarrierRemissionGuide';
import printCarrierRemissionGuideOld from '../../../../../utils/printers/CarrierRemissionGuideOld';

const PrintCarrierRemissionGuideButton = ({
  itineraryId,
  dispatchGetCarrierRemissionGuidePDFByItineraryV1,
  dispatchGetCarrierRemissionGuidePDFByItineraryV2,
  dispatchGetItineraryJson,
  billings,
}) => {
  function extraFieldsNull(billingList) {
    return billingList.some((billing) => billing.extraFields === null);
  }

  const onPrintCarrierRemissionGuide = async () => {
    let carrierRemissionGuideList = null;
    const itineraryJson = await dispatchGetItineraryJson({ itineraryId });

    if (!extraFieldsNull(billings)) {
      carrierRemissionGuideList =
        await dispatchGetCarrierRemissionGuidePDFByItineraryV2(itineraryId);
    } else {
      carrierRemissionGuideList =
        await dispatchGetCarrierRemissionGuidePDFByItineraryV1(itineraryId);
    }
    if (carrierRemissionGuideList.length !== 0) {
      if (!extraFieldsNull(billings)) {
        printCarrierRemissionGuide(itineraryJson, carrierRemissionGuideList);
      } else {
        printCarrierRemissionGuideOld(itineraryJson, carrierRemissionGuideList);
      }
    } else {
      toastr.error(
        'Error',
        'El itinerario no tiene guías de remisión transportista emitidas',
      );
    }
  };

  return (
    <Button
      color="light"
      className="border"
      onClick={onPrintCarrierRemissionGuide}
    >
      <i className="fa fa-print" /> Imprimir GRT
    </Button>
  );
};

const mapStateToProps = (state) => ({
  billings: state.AccountingUnit.Billing.getIn(['all', 'content']),
});

PrintCarrierRemissionGuideButton.propTypes = {
  itineraryId: PropTypes.string.isRequired,
  dispatchGetCarrierRemissionGuidePDFByItineraryV1: PropTypes.func.isRequired,
  dispatchGetCarrierRemissionGuidePDFByItineraryV2: PropTypes.func.isRequired,
  dispatchGetItineraryJson: PropTypes.func.isRequired,
  billings: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapDispatchToProps = {
  dispatchGetCarrierRemissionGuidePDFByItineraryV1:
    getCarrierRemissionGuidePDFByItineraryV1,
  dispatchGetCarrierRemissionGuidePDFByItineraryV2:
    getCarrierRemissionGuidePDFByItineraryV2,
  dispatchGetItineraryJson: getItineraryJson,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintCarrierRemissionGuideButton);

export default connectedComponent;
