export const FLAG_GETTING_CARGO_CORPORATE_CREDITS =
  'FLAG_GETTING_CARGO_CORPORATE_CREDITS';
export const GET_CARGO_CORPORATE_CREDITS = 'GET_CARGO_CORPORATE_CREDITS';
export const CLEAR_CARGO_CORPORATE_CREDITS = 'CLEAR_CARGO_CORPORATE_CREDITS';
export const FLAG_CARGO_CORPORATE_CREDIT_ACTIVITY =
  'FLAG_CARGO_CORPORATE_CREDIT_ACTIVITY';
export const GET_CARGO_CORPORATE_CREDIT = 'GET_CARGO_CORPORATE_CREDIT';
export const CLEAR_CARGO_CORPORATE_CREDIT = 'CLEAR_CARGO_CORPORATE_CREDIT';
export const GET_CARGO_CORPORATE_INVOICES = 'GET_CARGO_CORPORATE_INVOICES';
export const CLEAR_CARGO_CORPORATE_INVOICES = 'CLEAR_CARGO_CORPORATE_INVOICES';
export const FLAG_GETTING_CARGO_CORPORATE_INVOICES =
  'FLAG_GETTING_CARGO_CORPORATE_INVOICES';
export const GET_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES =
  'GET_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES';
export const CLEAR_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES =
  'CLEAR_CARGO_CORPORATE_CREDIT_MANUAL_VOUCHER_VALUES';
