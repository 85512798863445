import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ActivityFormFields from './ActivityFormFields';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

export const ActivityForm = ({ handleSubmit, loading, initialValues }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <ActivityFormFields initialValues={initialValues} />
      <FormFooter />
    </Form>
  );
};

ActivityForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
};

ActivityForm.defaultProps = {
  initialValues: null,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.Activity.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'ActivityForm',
})(ActivityForm);

export default connect(mapStateToProps)(formComponent);
