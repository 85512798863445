import Immutable from 'immutable';
import {
  FLAG_GETTING_CHASSIS_BODYWORKS,
  GET_CHASSIS_BODYWORKS,
  CLEAR_CHASSIS_BODYWORKS,
} from '../../../actions/types/traffic/ChassisBodywork';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_CHASSIS_BODYWORKS:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_CHASSIS_BODYWORKS:
      return state.setIn(['all', 'content'], Immutable.Set(action.payload));
    case CLEAR_CHASSIS_BODYWORKS:
      return state.setIn(['all', 'content'], Immutable.Map());
    default:
      return state;
  }
};
