import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditGeographicZoneButton from './EditGeographicZoneButton';
import DeleteGeographicZoneButton from './DeleteGeographicZoneButton';

const GeographicZoneToolbar = ({ geographicZoneId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditGeographicZoneButton geographicZoneId={geographicZoneId} />{' '}
      <DeleteGeographicZoneButton geographicZoneId={geographicZoneId} />
    </div>
  </ButtonToolbar>
);

GeographicZoneToolbar.propTypes = {
  geographicZoneId: PropTypes.number.isRequired,
};

export default GeographicZoneToolbar;
