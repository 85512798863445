import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_PRIVATE_SERVICES,
  GET_PRIVATE_SERVICES,
  CLEAR_PRIVATE_SERVICES,
  FLAG_PRIVATE_SERVICE_ACTIVITY,
  GET_PRIVATE_SERVICE,
  CLEAR_PRIVATE_SERVICE,
} from '../types';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { PRIVATE_SERVICE_ENDPOINT } from '../../config/endpoints';
import { PRIVATE_SERVICE_PATH } from '../../config/paths';

const flagGettingPrivateService = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PRIVATE_SERVICES,
    payload: flag,
  });

const getPrivateServices = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingPrivateService(true));
    const query = tableFilters;
    const url = `${PRIVATE_SERVICE_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const services = await response.json();
    dispatch({
      type: GET_PRIVATE_SERVICES,
      payload: services,
    });
  } catch ({ message }) {
    toastr.error('Error',message);
  } finally {
    dispatch(flagGettingPrivateService(false));
  }
};

const clearPrivateServices = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRIVATE_SERVICES,
  });

const flagPrivateServiceActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PRIVATE_SERVICE_ACTIVITY,
    payload: flag,
  });

const getPrivateService =
  async ({ privateServiceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrivateServiceActivity(true));
      const url = `${PRIVATE_SERVICE_ENDPOINT}/${privateServiceId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const service = await response.json();
      dispatch({
        type: GET_PRIVATE_SERVICE,
        payload: service,
      });
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagPrivateServiceActivity(false));
    }
  };

const clearPrivateService = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRIVATE_SERVICE,
  });

const postPrivateService =
  async ({
    clientAddress,
    serviceConcept,
    contractNumber,
    numberOfBusses,
    includeFood,
    source,
    sourceAddress,
    destination,
    destinationAddress,
    contractItineraryList,
    driverCommission,
    driverDailyPayment,
    driverPaymentForFood,
    driverPaymentForAccommodation,
    busTollPayment,
    busGaragePayment,
    busParkingPayment,
    totalAmount,
    businessId,
    customerId,
    amountBeforeDiscount,
    amountPerBus,
    authorizedDiscount,
    discountComment,
    paymentMethodId,
    creditPayment,
    operationCode,
    creditComment,
    contractPaymentType,
    contractStatus,
    executiveName,
    executivePhone,
    executiveEmail,
    executivePosition,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrivateServiceActivity(true));
      const payload = {
        clientAddress,
        serviceConcept,
        contractNumber,
        numberOfBusses,
        includeFood,
        source,
        sourceAddress,
        destination,
        destinationAddress,
        contractItineraryList,
        driverCommission,
        driverDailyPayment,
        driverPaymentForFood,
        driverPaymentForAccommodation,
        busTollPayment,
        busGaragePayment,
        busParkingPayment,
        totalAmount,
        businessId,
        customerId,
        amountBeforeDiscount,
        amountPerBus,
        authorizedDiscount,
        discountComment,
        paymentMethodId,
        creditPayment,
        operationCode,
        creditComment,
        contractPaymentType,
        contractStatus,
        executiveName,
        executivePhone,
        executiveEmail,
        executivePosition,
      };
      const url = PRIVATE_SERVICE_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch(push(`${PRIVATE_SERVICE_PATH}/${response.id}`));
    } catch (error) {
      toastr.error('Error',error.message);
    } finally {
      dispatch(flagPrivateServiceActivity(false));
    }
  };

const putPrivateService =
  async (
    privateServiceId,
    {
      clientAddress,
      serviceConcept,
      contractNumber,
      numberOfBusses,
      includeFood,
      source,
      sourceAddress,
      destination,
      destinationAddress,
      contractItineraryList,
      driverCommission,
      driverDailyPayment,
      driverPaymentForFood,
      driverPaymentForAccommodation,
      busTollPayment,
      busGaragePayment,
      busParkingPayment,
      totalAmount,
      businessId,
      customerId,
      amountBeforeDiscount,
      amountPerBus,
      authorizedDiscount,
      discountComment,
      paymentMethodId,
      creditPayment,
      operationCode,
      creditComment,
      contractPaymentType,
      contractStatus,
      executiveName,
      executivePhone,
      executiveEmail,
      executivePosition,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagPrivateServiceActivity(true));
      const payload = {
        clientAddress,
        serviceConcept,
        contractNumber,
        numberOfBusses,
        includeFood,
        source,
        sourceAddress,
        destination,
        destinationAddress,
        contractItineraryList,
        driverCommission,
        driverDailyPayment,
        driverPaymentForFood,
        driverPaymentForAccommodation,
        busTollPayment,
        busGaragePayment,
        busParkingPayment,
        totalAmount,
        businessId,
        customerId,
        amountBeforeDiscount,
        amountPerBus,
        authorizedDiscount,
        discountComment,
        paymentMethodId,
        creditPayment,
        operationCode,
        creditComment,
        contractPaymentType,
        contractStatus,
        executiveName,
        executivePhone,
        executiveEmail,
        executivePosition,
        id: privateServiceId,
      };
      const url = `${PRIVATE_SERVICE_ENDPOINT}/${privateServiceId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch(push(`${PRIVATE_SERVICE_PATH}/${response.id}`));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagPrivateServiceActivity(false));
    }
  };

const deletePrivateService =
  async ({ privateServiceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrivateServiceActivity(true));
      const url = `${PRIVATE_SERVICE_ENDPOINT}/${privateServiceId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(PRIVATE_SERVICE_PATH));
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagPrivateServiceActivity(false));
    }
  };

const patchPrivateServiceStatus =
  async ({ privateServiceId, contractStatus }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrivateServiceActivity(true));
      const url = `${PRIVATE_SERVICE_ENDPOINT}/${privateServiceId}/change-status/${contractStatus}`;
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
      });
      await isErrorResponse(response);
      const service = await response.json();
      dispatch({
        type: GET_PRIVATE_SERVICE,
        payload: service,
      });
    } catch ({ message }) {
      toastr.error('Error',message);
    } finally {
      dispatch(flagPrivateServiceActivity(false));
    }
  };

const getPrintedTicketsByContractId =
  async ({ privateServiceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPrivateServiceActivity(true));
      const url = `${PRIVATE_SERVICE_ENDPOINT}/${privateServiceId}/print-tickets`;
      const response = await fetch(url, {
        ...DEFAULT_GET_CONFIG,
      });
      await isErrorResponse(response);
      const tickets = await response.json();
      return tickets;
    } catch ({ message }) {
      toastr.error('Error',message);
      return null;
    } finally {
      dispatch(flagPrivateServiceActivity(false));
    }
  };

export {
  getPrivateServices,
  clearPrivateServices,
  getPrivateService,
  clearPrivateService,
  postPrivateService,
  deletePrivateService,
  putPrivateService,
  patchPrivateServiceStatus,
  getPrintedTicketsByContractId,
};
