import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { tzNormalizeDate } from '../../../../utils/date';
import { getFuelConsumptionQualification } from '../../../../utils/units/traffic';
import { busFuelReportCircuitRowListPropTypes } from './FuelConsumptionReportPerBusPropTypes';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../config/locale';

const BusFuelReportCircuitRowListResource = ({ data }) => {
  if (!data) return null;

  let driverCount = 0;
  data.forEach((row) => {
    if (driverCount < row.driverSet.length) driverCount = row.driverSet.length;
  });

  const driverColumns = [];
  for (let i = 0; i < driverCount; i += 1) {
    driverColumns.push(
      <th key={`header-${+i}`} className="border-black">
        Conductor {+i + 1}
      </th>,
    );
  }

  const tbody = data.map((row, index) => {
    const drivers = [];
    for (let i = 0; i < driverCount; i += 1) {
      drivers.push(
        <td className="border-right-black" key={`body-${+index}-${+i}`}>
          {row.driverSet[i] ? row.driverSet[i].customer.fullName : '-'}
        </td>,
      );
    }

    const expectedFuelConsumption = +row.expectedFuelConsumption;
    const realFuelConsumption = +row.realFuelConsumption;

    const { qualificationText, qualificationColor } =
      getFuelConsumptionQualification({
        expectedFuelConsumption,
        realFuelConsumption,
      });

    return (
      <tr key={+index}>
        <td className="border-right-black">{+index + 1}</td>
        <td className="border-right-black">{row.companyBusId}</td>
        <td className="border-right-black">
          {tzNormalizeDate({ date: row.departureTime, format: DATE_FORMAT })}
        </td>
        <td className="border-right-black">
          {tzNormalizeDate({ date: row.departureTime, format: TIME_FORMAT })}
        </td>
        <td className="border-right-black">{row.circuitName}</td>
        {drivers}
        <td className="border-right-black">{row.fuelEfficiency}</td>
        <td className={`border-right-black ${qualificationColor}`}>
          {qualificationText}
        </td>
        <td className="border-right-black">{row.totalKilometers}</td>
        <td className="border-right-black">{row.busFuelGroupId}</td>
        <td className="border-right-black">{row.busHomeBase}</td>
        <td className="border-right-black">{row.expectedFuelConsumption}</td>
        <td className="border-right-black">{row.realFuelConsumption}</td>
        <td className="border-right-black">{row.performance}</td>
        <td className="border-right-black">{row.adblueEfficiency}</td>
        <td className="border-right-black">{row.expectedAdblueConsumption}</td>
        <td className="border-right-black">{row.realAdblueConsumption}</td>
      </tr>
    );
  });

  return (
    <Row>
      <Col>
        <Table
          striped
          responsive
          role="grid"
          className="border-black text-center"
        >
          <thead>
            <tr>
              <th
                colSpan={13 + driverColumns.length}
                className="title border-black"
              >
                COMBUSTIBLE - REPORTE CONTROL BUS(ES)
              </th>
              <th colSpan={3} className="title border-black">
                AdBlue
              </th>
            </tr>
            <tr className="shadow">
              <th className="border-black">&nbsp;</th>
              <th className="border-black"># Bus</th>
              <th className="border-black">Fecha</th>
              <th className="border-black">Hora Origen</th>
              <th className="border-black">Nombre Circuito</th>
              {driverColumns}
              <th className="border-black">Eficiencia Consumo</th>
              <th className="border-black">Calificación</th>
              <th className="border-black">Km</th>
              <th className="border-black">Grupo Bus</th>
              <th className="border-black">Base</th>
              <th className="border-black">Dotación Aut.</th>
              <th className="border-black">Consumo Real</th>
              <th className="border-black">Rendimiento km/gal</th>
              <th className="border-black">Eficiencia Consumo</th>
              <th className="border-black">Dotación Aut.</th>
              <th className="border-black">Consumo Real</th>
            </tr>
          </thead>
          <tbody>{tbody}</tbody>
        </Table>
      </Col>
    </Row>
  );
};

BusFuelReportCircuitRowListResource.propTypes = {
  data: busFuelReportCircuitRowListPropTypes,
};

BusFuelReportCircuitRowListResource.defaultProps = {
  data: null,
};

export default BusFuelReportCircuitRowListResource;
