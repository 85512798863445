import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormItem from '../../../common/forms/FormItem';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import { isRequired } from '../../../../utils/validators';
import {
  clearBaseFuelRefills,
  clearItineraryGroups,
  clearFuelVouchers,
  getBaseFuelRefillByRegisteredBusId,
  getItineraryGroups,
  patchItineraryInBaseFuelRefill,
} from '../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  BASE_FUEL_REFILL_COLUMNS,
  ITINERARY_GROUPS_COLUMNS,
  FUEL_VOUCHER_COLUMNS,
  ITINERARIES_COLUMNS,
} from '../../../../config/columns';
import Table from '../../../common/Table';
import ItineraryFuelDetail from './Resource/ItineraryFuelDetail';
import Select from '../../../common/forms/select/Select';
import Alert from '../../../common/informative/Alert';
import {
  EXTRAORDINARY_WITHOUT_REAL_VALUE_ERROR_MESSAGE,
  ITINERARIES_WITHOUT_EXPECTED_CONSUMPTION_ERROR_MESSAGE,
  NOT_FOUND_BASE_FUEL_REFILL_ERROR_MESSAGE,
  BASE_FUEL_REFILL_WITH_DUPLICATED_ITINERARIES_ERROR_MESSAGE,
} from '../../../../config/messages';
import Modal from '../../../common/modal/Modal';
import {
  clearFuelReportPreview,
  getFuelReportPreview,
} from '../../../../actions/traffic/FuelReportPreview';
import ItineraryFuelHeader from './Resource/ItineraryFuelHeader';
import NewFuelVoucherButton from './Resource/NewFuelVoucherButton';
import NewBaseFuelRefillButton from './Resource/NewBaseFuelRefillButton';
import ExtraConsumptionToolbar from './Resource/ExtraConsumptionToolbar';
import { tzNormalizeDate } from '../../../../utils/date';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import { DATE_FORMAT } from '../../../../config/locale';

export class FuelReportPreviewForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItineraryGroup: null,
      itinerarySelectOptions: [],
      selectedNewItinerary: null,
      showModal: false,
      loadingBaseFuelRefillPatch: false,
      baseFuelRefillId: null,
      selectedRegisteredBusId: null,
      selectedItinerary: null,
      itineraryInCircuit: false,
    };
  }

  componentWillUnmount() {
    const {
      dispatchClearItineraryGroups,
      dispatchClearBaseFuelRefills,
      dispatchClearFuelVouchers,
      dispatchClearFuelReportPreview,
    } = this.props;

    dispatchClearBaseFuelRefills();
    dispatchClearFuelVouchers();
    dispatchClearItineraryGroups();
    dispatchClearFuelReportPreview();
  }

  onChangeRegisteredBus = ({ value, label }) => {
    const {
      dispatchGetItineraryGroups,
      dispatchClearItineraryGroups,
      dispatchClearBaseFuelRefills,
      dispatchClearFuelVouchers,
      dispatchClearFuelReportPreview,
    } = this.props;

    dispatchClearFuelReportPreview();
    dispatchClearFuelVouchers();
    dispatchClearBaseFuelRefills();
    dispatchClearItineraryGroups();

    this.setState({
      itinerarySelectOptions: [],
      selectedItineraryGroup: null,
      baseFuelRefillId: null,
      selectedItinerary: null,
      selectedRegisteredBusId: value || null,
      itineraryInCircuit: false,
    });

    if (value) {
      dispatchGetItineraryGroups({
        ...DEFAULT_QUERY_GET_ALL,
        query: [`registeredBus.companyBusId:${label}`],
      });
    }
  };

  onSelectItinerary = (itinerary) =>
    this.setState({ selectedItinerary: itinerary });

  onSelectItineraryGroup = (itineraryGroup) => {
    const {
      dispatchGetFuelReportPreview,
      dispatchClearFuelReportPreview,
      dispatchClearBaseFuelRefills,
    } = this.props;

    this.setState({
      selectedItineraryGroup: itineraryGroup,
      selectedItinerary: null,
      baseFuelRefillId: null,
      itineraryInCircuit: false,
    });

    dispatchClearBaseFuelRefills();
    dispatchClearFuelReportPreview();
    dispatchGetFuelReportPreview({
      circuitGroupItineraryList: itineraryGroup.circuitGroupItineraryList,
      registeredBusId: itineraryGroup.registeredBusId,
    });
  };

  onSelectBaseFuelRefill = ({ itineraryId, id: baseFuelRefillId }) => {
    const itineraries = this.props.fuelReportPreview.get('itineraries');

    // Options for itinerary select on modal
    const itinerarySelectOptions = itineraries.map(
      ({ id, route: { name: routeName }, departureTime }) => ({
        value: id,
        label: `${id} - ${routeName} - ${tzNormalizeDate({
          date: departureTime,
          format: DATE_FORMAT,
        })}`,
      }),
    );

    // Validate if itineraryId of base fuel refill exists in itinerary of itinerary circuit
    const itineraryInCircuit = !!itineraries.find(
      ({ id }) => +itineraryId === +id,
    );

    this.setState({
      baseFuelRefillId,
      showModal: !itineraryInCircuit,
      itineraryInCircuit,
      itinerarySelectOptions,
    });
  };

  onChangeNewItinerary = (option) =>
    this.setState({ selectedNewItinerary: option ? option.value : null });

  onClickConfirmButton = async () => {
    this.setState({ loadingBaseFuelRefillPatch: true });

    const {
      dispatchPatchItineraryInBaseFuelRefill,
      dispatchGetBaseFuelRefillByRegisteredBusId,
      dispatchClearBaseFuelRefills,
    } = this.props;

    const { selectedNewItinerary, baseFuelRefillId, selectedRegisteredBusId } =
      this.state;

    const response = await dispatchPatchItineraryInBaseFuelRefill({
      id: baseFuelRefillId,
      itineraryId: selectedNewItinerary,
    });

    if (response) {
      dispatchClearBaseFuelRefills();
      dispatchGetBaseFuelRefillByRegisteredBusId(selectedRegisteredBusId);
      this.closeModal();
    }

    this.setState({ loadingBaseFuelRefillPatch: false });
  };

  closeModal = () =>
    this.setState({
      showModal: false,
      selectedNewItinerary: null,
      loadingBaseFuelRefillPatch: false,
    });

  handleReportSubmit = () =>
    this.props.onSubmit({
      itineraryGroupId: this.state.selectedItineraryGroup.id,
    });

  renderItineraryRowComponent = ({
    original: {
      trip: {
        assignedRegisteredBus: {
          companyBusId,
          seatMap,
          busBodywork,
          busChassis,
          busFuelGroupId,
        },
        driverAssignmentList,
      },
      circuit,
      kilometers,
      expectedFuelConsumption,
      expectedAdblueConsumption,
      fuelInFuelVoucher,
      calculatedRealFuelConsumption,
      AdblueInBase,
      fuelOnBase,
      extraFuelConsumption,
      extraAdblueConsumption,
    },
  }) => {
    const driverList = driverAssignmentList.map(
      ({ driverId, driver: { customer } }) => ({
        text: `${driverId} - ${customer.fullName}`,
      }),
    );

    const props = {
      companyBusId,
      seatMapDisplayName: seatMap.displayName,
      busBodywork,
      busChassis,
      busFuelGroupId,
      circuitName: circuit.name,
      kilometers,
      driverList,
      expectedFuelConsumption,
      expectedAdblueConsumption,
      calculatedRealFuelConsumption,
      AdblueInBase,
      fuelInFuelVoucher,
      fuelOnBase,
      extraFuelConsumption,
      extraAdblueConsumption,
    };

    return <ItineraryFuelDetail {...props} />;
  };

  renderItineraryGroupsTable = () => {
    const {
      itineraryGroups,
      loadingItineraryGroups,
      baseFuelRefills,
      loadingBaseFuelRefills,
      fuelVouchers,
      loadingFuelVouchers,
    } = this.props;

    let newLoading = loadingItineraryGroups;
    if (
      loadingBaseFuelRefills &&
      baseFuelRefills.isEmpty() &&
      loadingFuelVouchers &&
      fuelVouchers.isEmpty()
    )
      newLoading = true;

    return (
      <div className="mb-5">
        <h3>Lista de Grupos de Itinerarios por Circuito</h3>
        <p>Seleccione un grupo</p>
        <Table
          manual={false}
          columns={ITINERARY_GROUPS_COLUMNS}
          data={itineraryGroups}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          loading={newLoading}
          defaultFilterMethod={filterMethodCaseInsensitive}
          getRowData={this.onSelectItineraryGroup}
          highlightSelectedRow
          showPagination
          filterable
        />
      </div>
    );
  };

  renderItinerariesTable = () => {
    const { loadingFuelReportPreview, fuelReportPreview } = this.props;

    let newLoading = false;
    if (loadingFuelReportPreview) newLoading = true;

    let noExpectedFuelError = null;
    let noExtraordinaryRealValueError = null;

    const itineraryList = fuelReportPreview.get('itineraries');
    if (itineraryList) {
      const {
        hasNotExpectedFuelConsumptions,
        hasItinerariesWithoutExtraordinaryRealValue,
      } = fuelReportPreview.get('header');

      if (hasNotExpectedFuelConsumptions) {
        noExpectedFuelError = (
          <Alert
            message={ITINERARIES_WITHOUT_EXPECTED_CONSUMPTION_ERROR_MESSAGE}
          />
        );
      }

      if (hasItinerariesWithoutExtraordinaryRealValue) {
        noExtraordinaryRealValueError = (
          <Alert
            type="warning"
            message={EXTRAORDINARY_WITHOUT_REAL_VALUE_ERROR_MESSAGE}
          />
        );
      }
    }

    return (
      <div className="mb-5">
        <div className="mb-3">
          <ExtraConsumptionToolbar
            itinerary={this.state.selectedItinerary}
            onSubmitExtraConsumption={() =>
              this.onSelectItineraryGroup(this.state.selectedItineraryGroup)
            }
          />
          <h3>Lista de Itinerarios</h3>
          <p>Para mas detalles de un itinerario expanda la fila</p>
        </div>
        {noExpectedFuelError}
        {noExtraordinaryRealValueError}
        <ItineraryFuelHeader {...fuelReportPreview.get('header')} />
        <Table
          manual={false}
          columns={ITINERARIES_COLUMNS}
          data={itineraryList}
          defaultPageSize={5}
          loading={newLoading}
          highlightSelectedRow
          defaultFilterMethod={filterMethodCaseInsensitive}
          rowComponent={this.renderItineraryRowComponent}
          getRowData={this.onSelectItinerary}
          showPagination
        />
      </div>
    );
  };

  renderFuelVouchersTable = () => {
    const { fuelVouchers, loadingFuelVouchers } = this.props;

    let data = [];

    if (this.state.selectedItineraryGroup) data = fuelVouchers.get('content');

    return (
      <div className="mb-5">
        <div className="mb-3">
          <NewFuelVoucherButton />
          <h3>Lista de Vales de Combustible</h3>
        </div>
        <Table
          manual={false}
          columns={FUEL_VOUCHER_COLUMNS}
          data={data}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          loading={loadingFuelVouchers}
          defaultFilterMethod={filterMethodCaseInsensitive}
          filterable
          showPagination
        />
      </div>
    );
  };

  renderBaseFuelRefillsTable = () => {
    const {
      baseFuelRefills,
      loadingBaseFuelRefills,
      dispatchGetBaseFuelRefillByRegisteredBusId,
    } = this.props;

    const data = baseFuelRefills.get('content');
    const pages = baseFuelRefills.get('totalPages') || null;
    const defaultPageSize = baseFuelRefills.get('size') || DEFAULT_PAGE_SIZE;

    // Show error for duplicated itineraries in base fuel refill
    // Currently only show error for last 15 itineraries
    let duplicatedBaseFuelRefillError = null;

    let baseFuelRefillCount = 0;
    let duplicatedItinerary = 0;

    // Get selected itinerary group from this.state
    const { selectedItineraryGroup } = this.state;
    if (selectedItineraryGroup) {
      // Compare each itineraryId of itineraryGroup from this.state with each
      // itineraryId of baseFuelRefill content from reducer
      selectedItineraryGroup.circuitGroupItineraryList.forEach(
        ({ itineraryId }) => {
          // Count of base fuel refill found per each itineraryId
          let foundBaseFuelRefill = 0;
          let itinerary = 0;
          data.forEach((baseRefill) => {
            if (+baseRefill.itineraryId === +itineraryId) {
              foundBaseFuelRefill += 1;
              itinerary = itineraryId;
            }
          });

          if (foundBaseFuelRefill > 1) {
            baseFuelRefillCount = foundBaseFuelRefill;
            duplicatedItinerary = itinerary;
          }
        },
      );

      if (baseFuelRefillCount > 1)
        duplicatedBaseFuelRefillError = (
          <Alert
            message={BASE_FUEL_REFILL_WITH_DUPLICATED_ITINERARIES_ERROR_MESSAGE}
          />
        );
    }

    // Set attribute "duplicated" to data prop
    data.forEach((baseRefill, index) => {
      if (+baseRefill.itineraryId === duplicatedItinerary) {
        data[index].duplicated = true;
      }
    });

    const props = {
      columns: BASE_FUEL_REFILL_COLUMNS,
      data,
      defaultPageSize,
      pages,
      loading: loadingBaseFuelRefills,
      showPagination: true,
    };

    if (selectedItineraryGroup)
      props.fetchData = (query) =>
        dispatchGetBaseFuelRefillByRegisteredBusId(
          selectedItineraryGroup.registeredBusId,
          query,
        );

    // If there are duplicated itineraries then disable getRowData function
    if (baseFuelRefillCount === 0) {
      props.getRowData = this.onSelectBaseFuelRefill;
      props.highlightSelectedRow = true;
    }

    return (
      <div className="mb-5">
        <div className="mb-3">
          <NewBaseFuelRefillButton />
          <h3>Lista de Rellenos en Base</h3>
          <p>Selecciona un relleno para continuar</p>
        </div>
        {duplicatedBaseFuelRefillError}
        <Table {...props} />
      </div>
    );
  };

  renderConfirmationModal = () => {
    const {
      itinerarySelectOptions,
      selectedNewItinerary,
      showModal,
      loadingBaseFuelRefillPatch,
    } = this.state;

    const modalBody = (
      <Fragment>
        <Alert message={NOT_FOUND_BASE_FUEL_REFILL_ERROR_MESSAGE} />
        <Select
          name="itineraryId"
          input={{ onChange: this.onChangeNewItinerary }}
          options={itinerarySelectOptions}
        />
      </Fragment>
    );

    let confirmButtonText = 'Asociar Itinerario';
    let disableConfirmButton = !selectedNewItinerary;

    if (loadingBaseFuelRefillPatch) {
      disableConfirmButton = true;
      confirmButtonText = (
        <>
          <i className="fa fa-spinner fa-spin fa-fw" /> Asociando Itinerario
        </>
      );
    }

    return (
      <Modal
        show={showModal}
        body={modalBody}
        title="Confirmación"
        onClickConfirm={this.onClickConfirmButton}
        onClickCancel={this.closeModal}
        onClickClose={this.closeModal}
        confirmButtonText={confirmButtonText}
        cancelButtonText="Cancelar"
        size="md"
        disableConfirmButton={disableConfirmButton}
      />
    );
  };

  render() {
    const { handleSubmit, fuelReportPreview } = this.props;

    const itineraryGroupsTable = this.renderItineraryGroupsTable();
    const itinerariesTable = this.renderItinerariesTable();
    const fuelVouchersTable = this.renderFuelVouchersTable();
    const baseFuelRefillsTable = this.renderBaseFuelRefillsTable();
    const confirmationModal = this.renderConfirmationModal();

    const { itineraryInCircuit } = this.state;

    const {
      hasNotExpectedFuelConsumptions,
      hasItinerariesWithoutExtraordinaryRealValue,
    } = fuelReportPreview.get('header');

    let disabledButton = true;
    if (
      itineraryInCircuit &&
      !hasNotExpectedFuelConsumptions &&
      !hasItinerariesWithoutExtraordinaryRealValue
    )
      disabledButton = false;

    const generateReportButton = !disabledButton ? (
      <Row>
        <Col className="flex row-reverse">
          <Button type="submit" color="primary" disabled={disabledButton}>
            Generar Reporte
          </Button>
        </Col>
      </Row>
    ) : null;

    return (
      <Form onSubmit={handleSubmit(this.handleReportSubmit)}>
        <FormGroup row>
          <FormItem label="Bus">
            <Field
              name="registeredBus"
              component={RegisteredBusSelect}
              onChange={this.onChangeRegisteredBus}
              placeholder="Seleccione un bus para empezar"
              validate={isRequired}
            />
          </FormItem>
        </FormGroup>
        {itineraryGroupsTable}
        {itinerariesTable}
        {fuelVouchersTable}
        {baseFuelRefillsTable}
        {generateReportButton}
        {confirmationModal}
      </Form>
    );
  }
}

FuelReportPreviewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  itineraryGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  loadingItineraryGroups: PropTypes.bool,
  dispatchGetItineraryGroups: PropTypes.func.isRequired,
  dispatchClearItineraryGroups: PropTypes.func.isRequired,
  dispatchClearBaseFuelRefills: PropTypes.func.isRequired,
  loadingBaseFuelRefills: PropTypes.bool.isRequired,
  baseFuelRefills: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchClearFuelVouchers: PropTypes.func.isRequired,
  loadingFuelVouchers: PropTypes.bool.isRequired,
  fuelVouchers: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPatchItineraryInBaseFuelRefill: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchGetFuelReportPreview: PropTypes.func.isRequired,
  fuelReportPreview: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingFuelReportPreview: PropTypes.bool.isRequired,
  dispatchGetBaseFuelRefillByRegisteredBusId: PropTypes.func.isRequired,
  dispatchClearFuelReportPreview: PropTypes.func.isRequired,
};

FuelReportPreviewForm.defaultProps = {
  itineraryGroups: [],
  loadingItineraryGroups: false,
};

const mapStateToProps = ({ RouteUnit, TrafficUnit }) => ({
  itineraryGroups: RouteUnit.ItineraryGroup.getIn([
    'all',
    'content',
    'content',
  ]),
  loadingItineraryGroups: RouteUnit.ItineraryGroup.getIn(['all', 'loading']),
  baseFuelRefills: TrafficUnit.BaseFuelRefill.getIn(['all', 'content']),
  loadingBaseFuelRefills: TrafficUnit.BaseFuelRefill.getIn(['all', 'loading']),
  fuelVouchers: TrafficUnit.FuelVoucher.getIn(['all', 'content']),
  loadingFuelVouchers: TrafficUnit.FuelVoucher.getIn(['all', 'loading']),
  fuelReportPreview: TrafficUnit.FuelReportPreview.getIn([
    'current',
    'content',
  ]),
  loadingFuelReportPreview: !TrafficUnit.FuelReportPreview.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItineraryGroups: getItineraryGroups,
  dispatchClearItineraryGroups: clearItineraryGroups,
  dispatchGetBaseFuelRefillByRegisteredBusId:
    getBaseFuelRefillByRegisteredBusId,
  dispatchClearBaseFuelRefills: clearBaseFuelRefills,
  dispatchClearFuelVouchers: clearFuelVouchers,
  dispatchPatchItineraryInBaseFuelRefill: patchItineraryInBaseFuelRefill,
  dispatchGetFuelReportPreview: getFuelReportPreview,
  dispatchClearFuelReportPreview: clearFuelReportPreview,
};

const formComponent = reduxForm({
  form: 'FuelReportPreviewForm',
})(FuelReportPreviewForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
