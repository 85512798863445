import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import BusinessDeleteButton from './BusinessDeleteButton';
import EditButton from '../../../common/button/EditButton';
import { BUSINESS_PATH } from '../../../../config/paths';

const BusinessToolbar = ({ businessId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${BUSINESS_PATH}/${businessId}/edit`} />{' '}
      <BusinessDeleteButton businessId={businessId} />
    </div>
  </ButtonToolbar>
);

BusinessToolbar.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default BusinessToolbar;
