import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FUEL_PROFILE_PATH } from '../../../../config/paths';
import { postFuelProfile } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import FuelProfileForm from './FuelProfileForm';
import Alert from '../../../common/informative/Alert';
import { SAVE_FUEL_PROFILE_WARNING_MESSAGE } from '../../../../config/messages';
import Content from '../../../layout/Content';

export class NewFuelProfile extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostFuelProfile } = this.props;
    dispatchPostFuelProfile(formValues);
  };

  render() {
    const content = (
      <Fragment>
        <Alert type="warning" message={SAVE_FUEL_PROFILE_WARNING_MESSAGE} />
        <FuelProfileForm onSubmit={this.onSubmit} />
      </Fragment>
    );
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Relleno de Combustible por Circuito"
        subtitle="Crear un nuevo perfil de combustible"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos Combustible por Circuito',
      href: FUEL_PROFILE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostFuelProfile: postFuelProfile,
};

NewFuelProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostFuelProfile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFuelProfile);
