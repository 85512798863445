import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  clearMaintenanceWarning,
  getMaintenanceWarning,
  putMaintenanceWarning,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaintenanceWarningForm from './MaintenanceWarningForm';
import { MAINTENANCE_WARNING_PATH } from '../../../../config/paths';
import {
  FLIT_ROBOT_CUSTOMER_ID,
  MAINTENANCE_WARNING_PRIORITY,
  MAINTENANCE_WARNING_STATUS,
  MAINTENANCE_WARNING_TYPE,
} from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';

const EditMaintenanceWarning = ({
  breadcrumbs,
  dispatchPutMaintenanceWarning,
  dispacthGetMaintenanceWarning,
  dispacthClearMaintenanceWarning,
  maintenanceWarning,
  loading,
  match: {
    params: { id: maintenanceWarningId },
  },
}) => {
  useLayoutEffect(() => {
    dispacthGetMaintenanceWarning({ maintenanceWarningId });

    return () => dispacthClearMaintenanceWarning();
  }, []);

  const onSubmit = (formValues) =>
    dispatchPutMaintenanceWarning(maintenanceWarningId, {
      reporterCustomerId: formValues.reporterCustomerId.value,
      issueDate: tzNormalizeDate({ date: formValues.issueDate }),
      registeredBusId: formValues.registeredBusId.value,
      actualVirtualOdometer: formValues.actualVirtualOdometer,
      actualRegisteredBusOdometer: formValues.actualRegisteredBusOdometer,
      maintenanceWarningType:
        formValues.maintenanceWarningType &&
        formValues.maintenanceWarningType.value,
      maintenanceFailModeId:
        formValues.maintenanceFailModeId &&
        formValues.maintenanceFailModeId.value,
      categoryId:
        formValues.maintenanceFailModeId &&
        formValues.maintenanceFailModeId.categoryId,
      subCategoryId:
        formValues.maintenanceFailModeId &&
        formValues.maintenanceFailModeId.subCategoryId,
      priority: formValues.priority.value,
      recurrence: formValues.recurrence && formValues.recurrence.value,
      comments: formValues.comments,
      creationType: maintenanceWarning.get('creationType'),
      status: formValues.status && formValues.status.value,
      activityBusId: maintenanceWarning.get('activityBusId'),
      createDate: maintenanceWarning.get('createDate'),
      lastUpdate: maintenanceWarning.get('lastUpdate'),
      serviceOrderId: maintenanceWarning.get('serviceOrderId'),
      statusComment: maintenanceWarning.get('statusComment'),
      userId: maintenanceWarning.get('userId'),
    });

  const generateInitialValues = ({
    issueDate,
    registeredBusId,
    registeredBus,
    maintenanceFailModeId,
    maintenanceFailMode,
    priority,
    maintenanceWarningType,
    comments,
    reporterCustomerId,
    reporterCustomer,
    actualVirtualOdometer,
    actualRegisteredBusOdometer,
    recurrence,
    status,
  }) => ({
    issueDate: tzNormalizeDate({ date: issueDate }).slice(0, 16),
    registeredBusId: {
      value: registeredBusId,
      label: registeredBus.companyBusId,
    },
    actualVirtualOdometer,
    actualRegisteredBusOdometer,
    maintenanceFailModeId: maintenanceFailMode
      ? {
          value: maintenanceFailModeId,
          label: maintenanceFailMode.description,
          categoryId: maintenanceFailMode.categoryId,
          subCategoryId: maintenanceFailMode.subCategoryId,
          priority: maintenanceFailMode.priority,
        }
      : null,
    maintenanceWarningType: maintenanceWarningType
      ? MAINTENANCE_WARNING_TYPE[maintenanceWarningType]
      : '-',
    priority: MAINTENANCE_WARNING_PRIORITY[priority],
    comments,
    reporterCustomerId: {
      value: reporterCustomerId,
      label: reporterCustomer && reporterCustomer.fullName,
    },
    recurrence: recurrence && {
      value: recurrence,
      label: recurrence,
    },
    status: MAINTENANCE_WARNING_STATUS[status],
  });

  let content;

  if (loading) content = <Loader />;
  else if (maintenanceWarning.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_WARNING_PATH} />;
  else {
    const maintenanceWarningJson = maintenanceWarning.toJS();
    const initialValuesProps = {
      initialValues: generateInitialValues(maintenanceWarningJson),
    };
    content = (
      <MaintenanceWarningForm
        onSubmit={onSubmit}
        {...initialValuesProps}
        editionMode
        requiredField={
          maintenanceWarningJson.reporterCustomer.id !== FLIT_ROBOT_CUSTOMER_ID
        }
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Reporte de Ocurrencia"
      subtitle="Editar reporte de ocurrencia"
      content={content}
    />
  );
};

EditMaintenanceWarning.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  dispatchPutMaintenanceWarning: PropTypes.func.isRequired,
  dispacthGetMaintenanceWarning: PropTypes.func.isRequired,
  dispacthClearMaintenanceWarning: PropTypes.func.isRequired,
  maintenanceWarning: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchPutMaintenanceWarning: putMaintenanceWarning,
  dispacthGetMaintenanceWarning: getMaintenanceWarning,
  dispacthClearMaintenanceWarning: clearMaintenanceWarning,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reportes de Ocurrencia',
      href: MAINTENANCE_WARNING_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_WARNING_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  maintenanceWarning: MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceWarning);
