import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getCustomers, clearCustomers } from '../../../../actions/user/';
import { CUSTOMER_PATH, NEW_CUSTOMER_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { CUSTOMER_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Alert from '../../../common/informative/Alert';

export class Customers extends Component {
  componentWillUnmount() {
    this.props.dispatchClearCustomers();
  }

  onFetchData = (data) => {
    // Hack to search customers
    // TODO change when the backend implements filters per field.
    const lastQuery = data.query[data.query.length - 1];
    const newQuery = lastQuery
      ? lastQuery.substring(lastQuery.indexOf(':') + 1)
      : '';
    this.props.dispatchGetCustomers({
      ...data,
      query: newQuery,
    });
  };

  render() {
    const { customers, loading, breadcrumbs } = this.props;
    const data = customers.get('content') || [];
    const pages = customers.get('totalPages') || null;
    const defaultPageSize = customers.get('size') || DEFAULT_PAGE_SIZE;

    const description = (
      <Alert
        type="warning"
        message="
    Solo se filtrará los datos según el último criterio ingresado"
      />
    );

    return (
      <ModuleList
        title="Clientes"
        description={description}
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_CUSTOMER_PATH}
        tableStructure={{
          columns: CUSTOMER_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: this.onFetchData,
          modelPath: CUSTOMER_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetCustomers: getCustomers,
  dispatchClearCustomers: clearCustomers,
};

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Clientes',
      href: CUSTOMER_PATH,
    },
  ],
  customers: UserUnit.Customer.getIn(['all', 'content']),
  loading: UserUnit.Customer.getIn(['all', 'loading']),
});

Customers.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  customers: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCustomers: PropTypes.func.isRequired,
  dispatchClearCustomers: PropTypes.func.isRequired,
};

Customers.defaultProps = {
  customers: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
