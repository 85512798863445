import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
  NEW_PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { PAYMENT_METHOD_FOR_SECURITY_PROFILE_COLUMNS } from '../../../../config/columns';
import {
  clearPaymentMethodsForSecurityProfiles,
  getPaymentMethodsForSecurityProfiles,
} from '../../../../actions/security/PaymentMethodForSecurityProfile';

export const PaymentMethodsForSecurityProfiles = ({
  breadcrumbs,
  paymentMethodsForSecurityProfiles,
  loading,
  dispatchGetPaymentMethodsForSecurityProfiles,
  dispatchClearPaymentMethodsForSecurityProfiles,
}) => {
  useLayoutEffect(
    () => () => dispatchClearPaymentMethodsForSecurityProfiles(),
    [],
  );

  const data = paymentMethodsForSecurityProfiles.get('content') || [];
  const pages = paymentMethodsForSecurityProfiles.get('totalPages') || null;
  const defaultPageSize =
    paymentMethodsForSecurityProfiles.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Metodos de Pago Por Perfiles de Seguridad"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}
      tableStructure={{
        columns: PAYMENT_METHOD_FOR_SECURITY_PROFILE_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetPaymentMethodsForSecurityProfiles,
        modelPath: PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetPaymentMethodsForSecurityProfiles:
    getPaymentMethodsForSecurityProfiles,
  dispatchClearPaymentMethodsForSecurityProfiles:
    clearPaymentMethodsForSecurityProfiles,
};

const mapStateToProps = ({ SecurityUnit }) => ({
  breadcrumbs: [
    ...SecurityUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Metodos de Pago Por Perfiles de Seguridad',
      href: PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
    },
  ],
  paymentMethodsForSecurityProfiles:
    SecurityUnit.PaymentMethodForSecurityProfile.getIn(['all', 'content']),
  loading: SecurityUnit.PaymentMethodForSecurityProfile.getIn([
    'all',
    'loading',
  ]),
});

PaymentMethodsForSecurityProfiles.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  paymentMethodsForSecurityProfiles: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetPaymentMethodsForSecurityProfiles: PropTypes.func.isRequired,
  dispatchClearPaymentMethodsForSecurityProfiles: PropTypes.func.isRequired,
};

PaymentMethodsForSecurityProfiles.defaultProps = {
  paymentMethodsForSecurityProfiles: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethodsForSecurityProfiles);
