import React from 'react';
import PropTypes from 'prop-types';
import {
  POSTPAID_BOOKING_PENDING,
  POSTPAID_BOOKING_REJECTED,
} from '../../../../config/constants';
import Badge from '../../Badge';

const PostpaidBookingStatusCell = ({ value }) => {
  if (value === POSTPAID_BOOKING_PENDING)
    return <Badge color="warning" text="Pendiente" />;
  else if (value === POSTPAID_BOOKING_REJECTED)
    return <Badge color="danger" text="Rechazado" />;

  return <Badge color="success" text="Aprobado" />;
};

PostpaidBookingStatusCell.propTypes = {
  value: PropTypes.number.isRequired,
};

export default PostpaidBookingStatusCell;
