import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import {
  getCargoDistance,
  clearCargoDistance,
} from '../../../../actions/cargo';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { CARGO_DISTANCE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CargoDistanceToolbar from './CargoDistanceToolbar';

export class CargoDistance extends Component {
  componentDidMount() {
    const {
      dispatchGetCargoDistance,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetCargoDistance({ cargoDistanceId: id });
  }

  componentWillUnmount() {
    const { dispatchClearCargoDistance } = this.props;
    dispatchClearCargoDistance();
  }

  render() {
    const { cargoDistance, loading, breadcrumbs } = this.props;
    if (loading || cargoDistance.isEmpty()) {
      return <Loader />;
    }
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <CargoDistanceToolbar cargoDistanceId={cargoDistance.get('id')} />
        <h1>Kilómetros entre Ciudades</h1>
        <hr />
        <Fragment>
          <ResourceProperty label="Ciudad Origen:">
            {cargoDistance.get('sourceCity').name}
          </ResourceProperty>
          <ResourceProperty label="Ciudad Destino:">
            {cargoDistance.get('destinationCity').name}
          </ResourceProperty>
          <ResourceProperty label="Kilómetros:">
            {cargoDistance.get('kilometers')}
          </ResourceProperty>
          <ResourceProperty label="Trasbordos:">
            {cargoDistance.get('transships') || '-'}
          </ResourceProperty>
        </Fragment>
      </Container>
    );
  }
}

CargoDistance.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoDistance: PropTypes.func.isRequired,
  dispatchClearCargoDistance: PropTypes.func.isRequired,
  cargoDistance: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  loading: PropTypes.bool,
};

CargoDistance.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Kilómetros entre Ciudades',
      href: CARGO_DISTANCE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  cargoDistance: CargoUnit.CargoDistance.getIn(['current', 'content']),
  loading: CargoUnit.CargoDistance.getIn(['current', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCargoDistance: getCargoDistance,
  dispatchClearCargoDistance: clearCargoDistance,
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoDistance);
