export const GET_ITINERARY_SEARCH_OMISSIONS = 'GET_ITINERARY_SEARCH_OMISSIONS';
export const GET_ITINERARY_SEARCH_OMISSION = 'GET_ITINERARY_SEARCH_OMISSION';
export const FLAG_GETTING_ITINERARY_SEARCH_OMISSIONS =
  'FLAG_GETTING_ITINERARY_SEARCH_OMISSIONS';
export const FLAG_ITINERARY_SEARCH_OMISSION_ACTIVITY =
  'FLAG_ITINERARY_SEARCH_OMISSION_ACTIVITY';
export const CLEAR_ITINERARY_SEARCH_OMISSION =
  'CLEAR_ITINERARY_SEARCH_OMISSION';
export const CLEAR_ITINERARY_SEARCH_OMISSIONS =
  'CLEAR_ITINERARY_SEARCH_OMISSIONS';
