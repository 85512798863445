import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import { expenseBasicInformationPropTypes } from '../proptypes/ExpensePropTypes';
import { EXPENSE_STATUS } from '../../../../../config/constants';
import { padStart } from '../../../../../utils/string';
import BadgeList from '../../../../common/BadgeList';

const ExpenseBasicInformationResource = ({ expense }) => {
  const mapVoucherType = [];
  expense.transactionList.forEach(({ voucherType }) => {
    if (voucherType) mapVoucherType.push({ text: voucherType.name });
  });
  return (
    <Fragment>
      <h3>Información básica</h3>
      <ResourceProperty label="Tipo de comprobante:">
        <BadgeList textArray={mapVoucherType} />
      </ResourceProperty>
      <ResourceProperty label="Documento:">
        {`${expense.documentSeries}-${padStart(expense.documentCode, 7)}`}
      </ResourceProperty>
      <ResourceProperty label="Propietario:">
        {expense.payeeCustomer.fullName}
      </ResourceProperty>
      <ResourceProperty label="Autorizador:">
        {expense.authorizerUser.customer.fullName}
      </ResourceProperty>
      <ResourceProperty label="Fecha y hora de comprobante:">
        {tzNormalizeDate({ date: expense.voucherTime, format: DATE_TIME_FORMAT })}
      </ResourceProperty>
      <ResourceProperty label="Centro de Costo:">
        {expense.costCenter
          ? `${expense.costCenter.costCenterCode} - ${expense.costCenter.description}`
          : '-'}
      </ResourceProperty>
      <ResourceProperty label="Liquidado:">
        {expense.isSettled ? 'Si' : 'No'}
      </ResourceProperty>
      <ResourceProperty label="Estado:">
        {expense.expenseStatus
          ? EXPENSE_STATUS[expense.expenseStatus].label
          : '-'}
      </ResourceProperty>
    </Fragment>
  );
};

ExpenseBasicInformationResource.propTypes = {
  expense: expenseBasicInformationPropTypes.isRequired,
};

export default ExpenseBasicInformationResource;
