import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../../../../config/locale';
import { timeFormat, tzNormalizeDate } from '../../../../utils/date';
import { Table } from '../../../common/Table';

export class TimetableTemplate extends Component {
  generateTimetableData = () => {
    const {
      itineraryScheduleSegmentList,
      estimatedDepartureTime,
      showFullDate,
    } = this.props;

    const timetableData = [];

    const format = showFullDate ? DATE_TIME_FORMAT : TIME_FORMAT;

    itineraryScheduleSegmentList.forEach((row, index, array) => {
      const {
        segment: { duration },
        segmentAdjustment: durationAdjustment,
        startOffset,
      } = row;

      const timetable = {
        sourceLocationName: row.segment.sourceLocation.name,
        destinationLocationName: row.segment.destinationLocation.name,
        departureTime: '-',
        duration,
        durationAdjustment,
        arrivalTime: '-',
        layover: 0,
      };

      if (estimatedDepartureTime) {
        timetable.departureTime = tzNormalizeDate({
          date: estimatedDepartureTime,
          addTime: { amount: startOffset, unit: 'minutes' },
          format,
        });

        timetable.arrivalTime = tzNormalizeDate({
          date: estimatedDepartureTime,
          addTime: {
            amount: startOffset + duration + durationAdjustment,
            unit: 'minutes',
          },
          format,
        });

        if (array[index + 1]) {
          const { startOffset: nextStartOffset } = array[index + 1];

          const layover =
            nextStartOffset - durationAdjustment - duration - startOffset;

          timetable.layover = layover;
        }
      }

      timetableData.push(timetable);
    });

    return timetableData;
  };

  render() {
    const data = this.generateTimetableData();

    const timetable = (
      <Table
        columns={[
          {
            Header: 'Origen',
            accessor: 'sourceLocationName',
            className: 'text-center',
          },
          {
            Header: 'Destino',
            accessor: 'destinationLocationName',
            className: 'text-center',
          },
          {
            Header: 'Hora de Salida',
            accessor: 'departureTime',
            className: 'text-center',
          },
          {
            Header: 'Duración',
            accessor: 'duration',
            className: 'text-center',
            Cell: (row) => timeFormat(row.value),
          },
          {
            Header: 'Ajuste de Duración',
            accessor: 'durationAdjustment',
            className: 'text-center',
            Cell: (row) => timeFormat(row.value),
          },
          {
            Header: 'Hora de Llegada',
            accessor: 'arrivalTime',
            className: 'text-center',
          },
          {
            Header: 'Tiempo de Escala',
            accessor: 'layover',
            className: 'text-center',
            Cell: (row) => timeFormat(row.value),
          },
        ]}
        data={data}
        defaultPageSize={data.length}
        showPagination={false}
      />
    );

    return timetable;
  }
}

TimetableTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  itineraryScheduleSegmentList: PropTypes.arrayOf(PropTypes.object).isRequired,
  estimatedDepartureTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  showFullDate: PropTypes.bool,
};

TimetableTemplate.defaultProps = {
  showFullDate: true,
};

export default TimetableTemplate;
