import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getCargoItems, clearCargoItems } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';

class CargoItemSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearCargoItems, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearCargoItems();
    }
  }

  handleCircuitChange = (inputValue) => {
    const { dispatchGetCargoItems } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetCargoItems({ query: `correlation:${inputValue}` });
    }
  };

  render() {
    const { items, isLoading, isMulti, input, meta, isClearable } = this.props;
    return (
      <Select
        options={items}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
        isClearable={isClearable}
        onInputChange={this.handleCircuitChange}
      />
    );
  }
}

CargoItemSelect.propTypes = {
  dispatchGetCargoItems: PropTypes.func.isRequired,
  dispatchClearCargoItems: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  cleanWhenUnmount: PropTypes.bool,
};

CargoItemSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  items: [],
  isClearable: false,
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ CargoUnit }) => ({
  items: CargoUnit.CargoItem.getIn(['all', 'content', 'content']).map(
    (item) => ({
      value: item.id,
      label: item.correlation,
    }),
  ),
  loading: CargoUnit.CargoItem.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetCargoItems: getCargoItems,
  dispatchClearCargoItems: clearCargoItems,
};

export default connect(mapStateToProps, mapDispatchToprops)(CargoItemSelect);
