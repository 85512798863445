import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH } from '../../../../config/paths';
import PaymentMethodForSecurityProfileDeleteButton from './PaymentMethodForSecurityProfileDeleteButton';

const PaymentMethodForSecurityProfileToolBar = ({
  paymentMethodForSecurityProfileId,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}/${paymentMethodForSecurityProfileId}/edit`}
      />{' '}
      <PaymentMethodForSecurityProfileDeleteButton
        paymentMethodForSecurityProfileId={paymentMethodForSecurityProfileId}
      />
    </div>
  </ButtonToolbar>
);

PaymentMethodForSecurityProfileToolBar.propTypes = {
  paymentMethodForSecurityProfileId: PropTypes.number.isRequired,
};

export default PaymentMethodForSecurityProfileToolBar;
