import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, change, reduxForm } from 'redux-form';
import {
  isRequired,
  validateIntegerNumber,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  MAINTENANCE_WARNING_TYPE,
  MAINTENANCE_WARNING_PRIORITY,
  MAINTENANCE_WARNING_RECURRENCE,
  MAINTENANCE_WARNING_STATUS,
} from '../../../../config/constants';
import MaintenanceWarningReporterSelect from '../../../common/forms/select/MaintenanceWarningReporterSelect';
import MaintenanceFailModeSelect from '../../../common/forms/select/MaintenanceFailModeSelect';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

export const MaintenanceWarningForm = ({
  handleSubmit,
  loading,
  dispatchChange,
  editionMode,
  requiredField,
}) => {
  const onChangeRegisteredBus = (option) =>
    dispatchChange(
      'MaintenanceWarningForm',
      'actualVirtualOdometer',
      option.actualVirtualOdometer,
    );

  const onChangeFailMode = (option) =>
    dispatchChange(
      'MaintenanceWarningForm',
      'priority',
      MAINTENANCE_WARNING_PRIORITY[option.priority],
    );

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Reportado por" required>
          <Field
            name="reporterCustomerId"
            component={MaintenanceWarningReporterSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha y hora" required>
          <Field
            name="issueDate"
            component={TextInput}
            type="datetime-local"
            placeholder={DATE_TIME_FORMAT}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Bus" required>
          <Field
            name="registeredBusId"
            component={RegisteredBusSelect}
            onChange={onChangeRegisteredBus}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="KM Actual">
          <Field
            name="actualVirtualOdometer"
            component={TextInput}
            placeholder="KM Actual"
            disabled
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="KM Ocurrencia" required={requiredField}>
          <Field
            name="actualRegisteredBusOdometer"
            component={TextInput}
            placeholder="KM Ocurrencia"
            validate={
              requiredField
                ? [isRequired, validateIntegerNumber]
                : [validateIntegerNumber]
            }
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Ocurrencia" required={requiredField}>
          <Field
            name="maintenanceWarningType"
            component={Select}
            options={enumToSelectOptions(MAINTENANCE_WARNING_TYPE)}
            validate={requiredField ? [isRequired] : null}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Modo Falla" required={requiredField}>
          <Field
            name="maintenanceFailModeId"
            component={MaintenanceFailModeSelect}
            onChange={onChangeFailMode}
            validate={requiredField ? [isRequired] : null}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Prioridad" required={requiredField}>
          <Field
            name="priority"
            component={Select}
            options={enumToSelectOptions(MAINTENANCE_WARNING_PRIORITY)}
            validate={requiredField ? [isRequired] : null}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Reincidencia">
          <Field
            name="recurrence"
            component={Select}
            options={MAINTENANCE_WARNING_RECURRENCE}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Comentario">
          <Field
            name="comments"
            component={TextInput}
            type="textarea"
            maxLength={0}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado" required={requiredField}>
          <Field
            name="status"
            component={Select}
            options={enumToSelectOptions(MAINTENANCE_WARNING_STATUS)}
            validate={requiredField ? [isRequired] : null}
            isDisabled={!editionMode}
          />
        </FormItem>
      </FormGroup>

      <FormFooter />
    </Form>
  );
};

MaintenanceWarningForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  editionMode: PropTypes.bool,
  requiredField: PropTypes.bool,
};

MaintenanceWarningForm.defaultProps = {
  editionMode: false,
  requiredField: true,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'MaintenanceWarningForm',
})(MaintenanceWarningForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
