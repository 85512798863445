import React, { Fragment } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

const DepositVoucherResource = ({ file }) => {
  if (!file || file.isEmpty()) {
    return null;
  }

  let index = 0;

  return (
    <Fragment>
      <h3>Lista de Comprobantes Ingresados</h3>
      <p>Para poder visualizar el comprobante selecciona una opción debajo</p>
      {file.map(({ fileUrl, fileName }) => {
        index += 1;
        return (
          <p>
            <strong>{index}°</strong>{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={fileUrl}
              alt={fileName}
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-picture-o" /> {fileName}
            </a>
          </p>
        );
      })}
    </Fragment>
  );
};

DepositVoucherResource.propTypes = {
  file: PropTypes.instanceOf(Immutable.Set),
};

DepositVoucherResource.defaultProps = {
  file: null,
};

export default DepositVoucherResource;
