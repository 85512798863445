import { COLOR, MATERIAL_MEASUREMENT_UNIT } from '../../config/constants';
import { DATE_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';
import {
  createPdfDocument,
  addText,
  x,
  y,
  paragraphSize,
  textSpacing,
  titleSize,
  subtitleSize,
  setFont,
} from '../pdf';

const myX = x;
let myY = y;
const indentX = myX + 20;
const pageWidth = 90;
const pageHeigth = 110;
const halfPage = pageWidth / 2;
const quarterX = myX / 4;
const halfX = myX / 2;

const printDocumentHeader = (doc, voucher) => {
  doc.setFontSize(titleSize);
  setFont(doc, 'bold');
  addText(doc, 'VALE DE COMBUSTIBLE', halfPage, myY, pageWidth);
  doc.setFontSize(subtitleSize);
  addText(
    doc,
    `${voucher.documentSeries}-${voucher.documentCode
      .toString()
      .padStart(7, '0')}`,
    halfPage,
    (myY += textSpacing + 1),
    halfPage,
  );
};

const printDocumentBody = (doc, voucher) => {
  doc.setFontSize(paragraphSize);
  // Issued
  addText(doc, myX, (myY += textSpacing * 2), 'Emitido:');
  setFont(doc);
  addText(
    doc,
    indentX,
    myY,
    tzNormalizeDate({ date: voucher.createDate, format: DATE_FORMAT }),
  );

  // User
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Usuario:');
  setFont(doc);
  addText(doc, indentX, myY, voucher.issuerFullName);

  // Validity
  doc.setFontSize(subtitleSize);
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing + 2), 'Validez');

  doc.setFontSize(paragraphSize);
  addText(doc, myX, (myY += textSpacing), 'Desde:');
  setFont(doc);
  addText(
    doc,
    indentX,
    myY,
    tzNormalizeDate({ date: voucher.validFrom, format: DATE_FORMAT }),
  );
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Hasta:');
  setFont(doc);
  addText(
    doc,
    indentX,
    myY,
    tzNormalizeDate({ date: voucher.validTo, format: DATE_FORMAT }),
  );

  // Bus
  doc.setFontSize(subtitleSize);
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing + 2), 'Bus:');
  setFont(doc);
  addText(doc, indentX, myY, voucher.companyBusId.toString());

  // License Plate
  doc.setFontSize(paragraphSize);
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Placa:');
  setFont(doc);
  addText(doc, indentX, myY, voucher.licensePlate);

  // Drivers
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Conductores:');
  setFont(doc);
  voucher.driverList.forEach((driver, indexDriver) => {
    addText(
      doc,
      indentX,
      indexDriver !== 0 ? (myY += textSpacing) : myY,
      driver,
    );
  });

  // Circuit
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Circuito:');
  setFont(doc);
  addText(doc, indentX, myY, voucher.circuitName);

  // Fuel Station
  setFont(doc, 'bold');
  addText(doc, myX, (myY += textSpacing), 'Grifo:');
  setFont(doc);
  addText(
    doc,
    indentX,
    (myY += textSpacing + 1),
    voucher.fuelStationBusinessName,
  );
  addText(
    doc,
    indentX,
    (myY += textSpacing + 1),
    voucher.fuelStationLocationName,
  );

  // Number of Gallons
  doc.setDrawColor(COLOR.black);
  doc.setFillColor(COLOR.white);
  doc.roundedRect(myX, myY - 8, indentX - 6, 10, 2, 2, 'FD');
  doc.setFontSize(subtitleSize);
  setFont(doc, 'bold');
  addText(
    doc,
    `${voucher.numberOfGallons.toString()} ${MATERIAL_MEASUREMENT_UNIT[1]}`,
    myX + (indentX - 6) / 2,
    myY - 2,
    15,
  );
  setFont(doc);
};

const printDocumentFooter = (doc) => {
  doc.setFontSize(paragraphSize);
  addText(
    doc,
    'GRIFO',
    halfPage / 2 + quarterX,
    (myY += textSpacing * 6),
    halfPage,
  );
  addText(doc, 'ADMINISTRADOR', halfPage + halfPage / 2 - halfX, myY, halfPage);

  doc.line(myX, myY - 4, halfPage - quarterX, myY - 4);
  doc.line(halfPage + quarterX, myY - 4, pageWidth - myX, myY - 4);
};

const printFuelVoucher = async (vouchers) => {
  const doc = createPdfDocument('p', 'mm', [pageWidth, pageHeigth]);

  vouchers.forEach((voucher, index) => {
    myY = 8;

    printDocumentHeader(doc, voucher);
    printDocumentBody(doc, voucher);
    printDocumentFooter(doc);

    if (vouchers[index + 1]) doc.addPage();
  });

  doc.save('Vale-de-Combustible.pdf');
};

// eslint-disable-next-line import/prefer-default-export
export { printFuelVoucher };
