import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { deleteExpense, deleteTripExpense } from '../../../../actions';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class DeleteExpenseButton extends Component {
  state = {
    showWarningModal: false,
  };

  showDeleteModal = () => this.setState({ showWarningModal: true });

  closeDeleteModal = () => this.setState({ showWarningModal: false });

  handleDelete = () => {
    const {
      expenseId,
      isTripExpense,
      dispatchDeleteExpense,
      dispatchDeleteTripExpense,
    } = this.props;
    if (!isTripExpense) {
      dispatchDeleteExpense({ expenseId });
    } else {
      dispatchDeleteTripExpense({ expenseId });
    }
  };

  render() {
    const { showWarningModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        onClickConfirm={this.handleDelete}
        onClickCancel={this.closeDeleteModal}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.showDeleteModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DeleteExpenseButton.propTypes = {
  expenseId: PropTypes.number,
  isTripExpense: PropTypes.bool.isRequired,
  dispatchDeleteExpense: PropTypes.func.isRequired,
  dispatchDeleteTripExpense: PropTypes.func.isRequired,
};

DeleteExpenseButton.defaultProps = {
  expenseId: null,
};

const mapDispatchToProps = {
  dispatchDeleteExpense: deleteExpense,
  dispatchDeleteTripExpense: deleteTripExpense,
};

export default connect(null, mapDispatchToProps)(DeleteExpenseButton);
