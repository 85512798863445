import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { BASE_PRICE_PER_KILOMETER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import BasePricePerKilometerForm from './BasePricePerKilometerForm';
import { postBasePricePerKilometer } from '../../../../actions/cargo';
import Loader from '../../../common/Loader';

// eslint-disable-next-line react/prefer-stateless-function
class NewBasePricePerKilometer extends Component {
  render() {
    const { breadcrumbs, loading, dispatchPostBasePricePerKilometer } =
      this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Crear Precio Base por Kilómetro</h1>
            <p>Crear Precio Base por Kilómetro</p>
          </Col>
        </Row>
        <BasePricePerKilometerForm
          onSubmit={dispatchPostBasePricePerKilometer}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Precio Base por Kilometro',
      href: BASE_PRICE_PER_KILOMETER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostBasePricePerKilometer: postBasePricePerKilometer,
};

NewBasePricePerKilometer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostBasePricePerKilometer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewBasePricePerKilometer.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewBasePricePerKilometer);
