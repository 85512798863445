import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DELIVERY_GROUP_PATH } from '../../../../config/paths';

const EditDeliveryGroupButton = ({ deliveryGroupId }) => (
  <Link
    className="btn btn-primary"
    to={`${DELIVERY_GROUP_PATH}/${deliveryGroupId}/edit`}
  >
    Editar
  </Link>
);

EditDeliveryGroupButton.propTypes = {
  deliveryGroupId: PropTypes.number.isRequired,
};

export default EditDeliveryGroupButton;
