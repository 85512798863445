import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getDrivers,
  clearDrivers,
  getFreeDrivers,
} from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';

class DriverSelect extends Component {
  componentDidMount() {
    const { dispatchGetFreeDrivers, itineraryId } = this.props;

    if (itineraryId) dispatchGetFreeDrivers({ itineraryId });
  }

  componentWillUnmount() {
    const { dispatchClearDrivers } = this.props;
    dispatchClearDrivers();
  }

  handleDriverChange = (inputValue) => {
    const { dispatchGetDrivers } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetDrivers({ query: inputValue });
    }
  };

  render() {
    const { drivers, isLoading, isMulti, input, meta, itineraryId, ...rest } =
      this.props;

    const props = {
      options: drivers,
      isLoading,
      isMulti,
      input,
      meta,
      ...rest,
    };

    if (!itineraryId) {
      props.onInputChange = this.handleDriverChange;
    }

    return <Select {...props} />;
  }
}

DriverSelect.propTypes = {
  dispatchGetFreeDrivers: PropTypes.func.isRequired,
  dispatchGetDrivers: PropTypes.func.isRequired,
  dispatchClearDrivers: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  drivers: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  itineraryId: PropTypes.number,
};

DriverSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  drivers: [],
  itineraryId: null,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  drivers: TrafficUnit.Driver.getIn(['all', 'content', 'content']).map(
    (driver) => ({
      value: driver.id,
      label: driver.customer.fullName,
      driverType: driver.driverType,
    }),
  ),
  loading: TrafficUnit.Driver.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetDrivers: getDrivers,
  dispatchGetFreeDrivers: getFreeDrivers,
  dispatchClearDrivers: clearDrivers,
};

export default connect(mapStateToProps, mapDispatchToprops)(DriverSelect);
