import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Form } from 'reactstrap';
import { BUS_ACTIVITY_HISTORY_COLUMNS } from '../../../../config/columns';
import {
  clearBusActivityHistory,
  getBusActivityHistory,
} from '../../../../actions';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import BusActivityHistoryDeleteButton from './BusActivityHistoryDeleteButton';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { THERE_ARE_NOT_DATA_TO_SHOW } from '../../../../config/messages';
import DownloadButton from '../../../common/button/DownloadButton';
import {
  ACTIVITY_STATUS,
  REGISTER_OF_EXECUTION_TYPE,
} from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_TIME_FORMAT } from '../../../../config/locale';

const convertDataToCsv = (data) =>
  data.map(
    ({
      activityBus: { registeredBus, activity },
      status,
      extensionFactor,
      virtualOdometer,
      advanced,
      employeeList,
      startDate,
      endDate,
      workingHour,
    }) => ({
      companyBusId: registeredBus.companyBusId,
      companyActivityId: activity.companyActivityId,
      activityName: activity.name,
      factor: activity.factor,
      extensionFactor,
      virtualOdometer,
      status: ACTIVITY_STATUS[status].label,
      advanced: REGISTER_OF_EXECUTION_TYPE[advanced].label,
      employeeList: employeeList
        .map(
          ({
            employee: {
              customer: { fullName },
            },
          }) => fullName,
        )
        .join('-'),
      startDate: tzNormalizeDate({
        date: startDate,
        format: INT_DATE_TIME_FORMAT,
      }),
      endDate: tzNormalizeDate({ date: endDate, format: INT_DATE_TIME_FORMAT }),
      workingHour,
    }),
  );

export const BusActivityHistoryDeleteForm = ({
  dispatchClearBusActivityHistory,
  loading,
  content,
  handleSubmit,
}) => {
  useLayoutEffect(() => () => dispatchClearBusActivityHistory(), []);

  const data = content.get('content') || [];

  let table = <Alert message={THERE_ARE_NOT_DATA_TO_SHOW} />;
  if (loading) table = <Loader />;
  if (!loading && data.length > 0) {
    const downloadButton = data && (
      <div className="mb-1 text-right">
        <DownloadButton
          data={convertDataToCsv(data)}
          csvName="Historial de Actividades por Bus.csv"
        />
      </div>
    );
    table = (
      <>
        {downloadButton}
        <Form onSubmit={handleSubmit}>
          <SelectionableTable
            columns={BUS_ACTIVITY_HISTORY_COLUMNS}
            data={data}
            defaultPageSize={10}
            keyField="id"
            loading={loading}
            form="BusActivityHistoryDeleteForm"
            required
            returnOnlySelectedItems
            filterable
          />
          <BusActivityHistoryDeleteButton />
        </Form>
      </>
    );
  }

  return table;
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: MechanicalMaintenanceUnit.BusActivityHistory.getIn([
    'all',
    'loading',
  ]),
  content: MechanicalMaintenanceUnit.BusActivityHistory.getIn([
    'all',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchGetBusActivityHistory: getBusActivityHistory,
  dispatchClearBusActivityHistory: clearBusActivityHistory,
};

BusActivityHistoryDeleteForm.propTypes = {
  content: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearBusActivityHistory: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

BusActivityHistoryDeleteForm.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'BusActivityHistoryDeleteForm',
})(BusActivityHistoryDeleteForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
