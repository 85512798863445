import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  privateServicePayPerBusDefaultProps,
  privateServicePayPerBusPropTypes,
} from '../proptypes/PrivateServicePropTypes';
import { numberFormatter } from '../../../../../utils/number';

const PayPerBusServiceInformationResource = ({
  busTollPayment,
  busGaragePayment,
  busParkingPayment,
}) => (
  <Fragment>
    <h3>Pagos por Servicio por Bus</h3>
    <ResourceProperty label="Peaje:">
      {busTollPayment ? numberFormatter({ value: busTollPayment }) : '-'}
    </ResourceProperty>
    <ResourceProperty label="Cochera:">
      {busGaragePayment ? numberFormatter({ value: busGaragePayment }) : '-'}
    </ResourceProperty>
    <ResourceProperty label="Parqueo:">
      {busParkingPayment ? numberFormatter({ value: busParkingPayment }) : '-'}
    </ResourceProperty>
  </Fragment>
);

PayPerBusServiceInformationResource.propTypes =
  privateServicePayPerBusPropTypes;

PayPerBusServiceInformationResource.defaultProps =
  privateServicePayPerBusDefaultProps;

export default PayPerBusServiceInformationResource;
