import LogoCiva from '../../resources/images/logo-civa.png';
import { COLOR, CONTRACT_MOVEMENT_TYPE } from '../../config/constants';
import { tzNormalizeDate } from '../date';
import { DATE_FORMAT, LOCAL_TIME_FORMAT } from '../../config/locale';
import {
  x,
  y,
  createPdfDocument,
  addText,
  addImage,
  paragraphSize,
  tableHeaderSize,
  cellHeight,
  titleSize,
  setFont,
  isNewPageNeeded,
} from '../pdf';

const initialY = y;
let myY = initialY;
const documentWidth = 287;

const printCivaLogo = async (doc) => {
  doc.rect(x, myY, documentWidth, 14);
  addImage(doc, LogoCiva, x + 125.5, (myY += 2), 35, 10);
};

const printDocumentHeader = (doc) => {
  doc.rect(x, (myY += 12), 217, 16);
  doc.rect(x + 217, myY, 70, 16);

  doc.setFontSize(titleSize);
  setFont(doc, 'bold');
  addText(doc, 'ORDEN DE CONTRATO', 113, (myY += 10), 211);

  doc.setFontSize(paragraphSize);
  addText(doc, 'Código: CIV-CC-FO-001', x + 219, (myY -= 5));
  addText(doc, 'F. Autorización: 11/01/2023', x + 219, (myY += 4));
  addText(doc, 'Versión: 000', x + 219, (myY += 4));
};

const printContractorFields = (
  doc,
  { customer, business, contractNumber, createDate },
) => {
  doc.setFillColor(COLOR.lightGray);
  doc.rect(x, (myY += 3), 87, cellHeight, 'DF');
  doc.rect(x + 87, myY, 130, cellHeight, 'DF');
  doc.rect(x + 217, myY, 35, cellHeight, 'DF');
  doc.rect(x + 252, myY, 35, cellHeight);

  const customerTypeLabel = customer ? 'Persona Natural' : 'Persona Jurídica';
  const customerNameLabel = customer ? 'Nombres y Apellidos' : 'Razón Social';
  doc.setFontSize(tableHeaderSize);
  setFont(doc, 'bold');
  addText(doc, customerTypeLabel, x + 43, (myY += 4.5), 87);
  addText(doc, customerNameLabel, x + 150, myY, 130);
  addText(doc, 'Fecha de Orden:', x + 234, myY, 35);
  doc.setFontSize(paragraphSize);
  addText(
    doc,
    tzNormalizeDate({ date: createDate, format: DATE_FORMAT }),
    x + 269,
    myY,
    35,
  );

  doc.rect(x, (myY += 1.5), 87, cellHeight);
  doc.rect(x + 87, myY, 130, cellHeight);
  doc.setFillColor(COLOR.lightGray);
  doc.rect(x + 217, myY, 35, cellHeight, 'DF');
  doc.rect(x + 252, myY, 35, cellHeight);

  doc.setFontSize(paragraphSize);
  const customerDocumentTypeLabel = customer ? 'DNI' : 'RUC';
  const customerDocumentNumber = customer
    ? customer.idDocumentNumber
    : business.businessTaxId;
  const customerName = customer ? customer.fullName : business.name;
  addText(
    doc,
    `${customerDocumentTypeLabel}: ${customerDocumentNumber}`,
    x + 43,
    (myY += 4.5),
    87,
  );
  addText(doc, customerName.toUpperCase(), x + 150, myY, 130);
  doc.setFontSize(tableHeaderSize);
  addText(doc, 'N° de Contrato:', x + 234, myY, 35);
  doc.setFontSize(paragraphSize);
  addText(doc, contractNumber.toString(), x + 269, myY, 35);

  doc.setFillColor(COLOR.lightGray);
  doc.rect(x, (myY += 1.55), 43, cellHeight, 'DF');
  doc.rect(x + 43.1, myY, 130, cellHeight);
  doc.rect(x + 173, myY, 79, cellHeight, 'DF');
  doc.rect(x + 252, myY, 35, cellHeight);

  const customerContactName = customer
    ? customerName
    : business.primaryContact || '-';
  const customerContactPhone = customer ? customer.mobilePhone : business.phone;
  doc.setFontSize(tableHeaderSize);
  addText(doc, 'Nombre Contacto:', x + 21.5, (myY += 4.5), 35);
  doc.setFontSize(paragraphSize);
  addText(doc, customerContactName.toUpperCase(), x + 106, myY, 130);
  doc.setFontSize(tableHeaderSize);
  addText(doc, 'Teléfono de Contacto:', x + 212, myY, 79);
  doc.setFontSize(paragraphSize);
  addText(doc, customerContactPhone, x + 269, myY, 35);
};

const getPassengerCountPerServiceType = (contractItineraryList) => {
  const serviceTypeList = [];

  // Generate initial values by service type name
  contractItineraryList.forEach(({ serviceType, routeDetail }) => {
    const serviceTypeFound = serviceTypeList.find(
      (service) => service.serviceTypeName === serviceType.name,
    );

    if (!serviceTypeFound) {
      serviceTypeList.push({
        numberOfBuses: 1,
        numberOfPassenger: 0,
        serviceTypeName: serviceType.name,
        serviceTypeDescription: routeDetail || serviceType.description,
      });
    }
  });

  // Calculate others data
  const newServiceTypeList = serviceTypeList.map((serviceType) => {
    const numberOfPassenger = contractItineraryList.reduce(
      (previousValue, contractItinerary) =>
        previousValue +
        (contractItinerary.serviceType.name === serviceType.serviceTypeName
          ? contractItinerary.customerList.length
          : 0),
      0,
    );

    const numberOfBuses = contractItineraryList.reduce(
      (previousValue, contractItinerary) => {
        if (
          !previousValue.includes(contractItinerary.registeredBusId) &&
          contractItinerary.serviceType.name === serviceType.serviceTypeName
        )
          previousValue.push(contractItinerary.registeredBusId);

        return previousValue;
      },
      [],
    );

    return {
      ...serviceType,
      numberOfPassenger,
      numberOfBuses: numberOfBuses.length,
    };
  });

  return newServiceTypeList;
};

const printPassengerCountFields = (doc, contractItineraryList) => {
  const serviceTypeList = getPassengerCountPerServiceType(
    contractItineraryList,
  );

  serviceTypeList.forEach((serviceType) => {
    doc.rect(x, (myY += 1.5), 87, cellHeight);
    doc.rect(x + 87, myY, 86, cellHeight);
    doc.rect(x + 173, myY, 114, cellHeight);

    doc.setFontSize(paragraphSize);
    addText(
      doc,
      serviceType.numberOfBuses.toString(),
      x + 43,
      (myY += 4.5),
      87,
    );
    addText(doc, serviceType.numberOfPassenger.toString(), x + 129, myY, 86);
    addText(doc, serviceType.serviceTypeName.toUpperCase(), x + 230, myY, 112);
  });
};

const printServiceFields = (doc, { contractItineraryList }) => {
  doc.setFillColor(COLOR.lightGray);
  doc.rect(x, (myY += 1.5), 87, cellHeight, 'DF');
  doc.rect(x + 87, myY, 86, cellHeight, 'DF');
  doc.rect(x + 173, myY, 114, cellHeight, 'DF');

  doc.setFontSize(tableHeaderSize);
  addText(doc, 'N° DE BUSES', x + 43, (myY += 4.5), 87);
  addText(doc, 'N° DE PASAJEROS', x + 129, myY, 86);
  addText(doc, 'TIPO DE UNIDAD', x + 230, myY, 112);

  doc.setFontSize(paragraphSize);

  printPassengerCountFields(doc, contractItineraryList);
};

const printRouteGeneralFields = (
  doc,
  {
    source,
    destination,
    sourceAddress,
    destinationAddress,
    contractItineraryList,
    serviceConcept,
  },
) => {
  doc.setFillColor(COLOR.lightGray);
  doc.rect(x, (myY += 1.55), 43, cellHeight, 'DF');
  doc.rect(x + 43, myY, 44, cellHeight, 'DF');
  doc.rect(x + 87, myY, 86, cellHeight, 'DF');
  doc.rect(x + 173, myY, 114, cellHeight, 'DF');

  doc.setFontSize(tableHeaderSize);
  addText(doc, 'FECHA DE IDA', x + 21.5, (myY += 4.5), 35);
  addText(doc, 'FECHA DE RETORNO', x + 65, myY, 43);
  addText(doc, 'ORIGEN', x + 128, myY, 43);
  addText(doc, 'DESTINO', x + 229, myY, 43);

  doc.rect(x, (myY += 1.55), 43, cellHeight);
  doc.rect(x + 43, myY, 44, cellHeight);
  doc.rect(x + 87, myY, 86, cellHeight);
  doc.rect(x + 173, myY, 114, cellHeight);

  const departureDate = tzNormalizeDate({
    date: contractItineraryList[0].departureTime,
    format: DATE_FORMAT,
  });

  const arrivalDate = tzNormalizeDate({
    date: contractItineraryList[contractItineraryList.length - 1].arrivalTime,
    format: DATE_FORMAT,
  });

  doc.setFontSize(paragraphSize);
  addText(doc, departureDate, x + 21.5, (myY += 4.5), 35);
  addText(doc, arrivalDate, x + 65, myY, 43);
  addText(doc, source.toUpperCase(), x + 128, myY, 80);
  addText(doc, destination.toUpperCase(), x + 229, myY, 110);

  doc.setFillColor(COLOR.lightGray);
  doc.rect(x, (myY += 1.55), 43, cellHeight, 'DF');
  doc.rect(x + 43, myY, 44, cellHeight, 'DF');
  doc.rect(x + 87, myY, 86, cellHeight, 'DF');
  doc.rect(x + 173, myY, 114, cellHeight, 'DF');

  doc.setFontSize(tableHeaderSize);
  addText(doc, 'Hora de presentación', x + 21.5, (myY += 4.5), 43);
  addText(doc, 'Hora de presentación', x + 65, myY, 43);
  addText(doc, 'LUGAR DE EMBARQUE', x + 128, myY, 80);
  addText(doc, 'LUGAR DE DESEMBARQUE', x + 229, myY, 110);

  doc.rect(x, (myY += 1.55), 43, 20);
  doc.rect(x + 43, myY, 44, 20);
  doc.rect(x + 87, myY, 86, 20);
  doc.rect(x + 173, myY, 114, 20);

  setFont(doc);

  const departureTime = tzNormalizeDate({
    date: contractItineraryList[0].departureTime,
    format: LOCAL_TIME_FORMAT,
  });

  const arrivalTime = tzNormalizeDate({
    date: contractItineraryList[contractItineraryList.length - 1].arrivalTime,
    format: LOCAL_TIME_FORMAT,
  });

  doc.setFontSize(paragraphSize);
  addText(doc, departureTime, x + 21.5, (myY += 4.5), 35);
  addText(doc, arrivalTime, x + 65, myY, 43);
  addText(doc, sourceAddress.toUpperCase(), x + 128.5, myY, 80);
  addText(doc, destinationAddress.toUpperCase(), x + 229, myY, 110);

  doc.rect(x, (myY += 15.5), 43, 24);
  doc.rect(x + 43, myY, 244, 24);

  const serviceTypeList = getPassengerCountPerServiceType(
    contractItineraryList,
  );
  let serviceTypeDescription = '';
  serviceTypeList.forEach((serviceType) => {
    serviceTypeDescription += ` * ${serviceType.serviceTypeName.toUpperCase()}: ${
      serviceType.serviceTypeDescription
    }`;
  });

  doc.setFontSize(tableHeaderSize);
  setFont(doc, 'bold');
  addText(doc, 'Descripción del Servicio', x + 22.5, (myY += 5), 35);
  setFont(doc);
  doc.setFontSize(paragraphSize);
  addText(
    doc,
    serviceTypeDescription.trim(),
    x + 45,
    (myY -= 0.5),
    238,
    'justify',
  );

  doc.rect(x, (myY += 19.5), 43, 18);
  doc.rect(x + 43, myY, 244, 18);

  doc.setFontSize(tableHeaderSize);
  setFont(doc, 'bold');
  addText(doc, 'Observaciones', x + 22.5, (myY += 5), 35);
  setFont(doc);
  doc.setFontSize(paragraphSize);
  addText(doc, serviceConcept, x + 45, (myY -= 0.5), 238, 'justify');
};

const printItineraryListFields = (doc, { contractItineraryList }) => {
  doc.setFillColor(COLOR.darkGray);
  doc.rect(x, (myY += 13.5), 43, cellHeight, 'DF');
  doc.rect(x + 43, myY, 244, cellHeight, 'DF');

  doc.setFontSize(tableHeaderSize);
  doc.setTextColor(COLOR.white);
  setFont(doc, 'bold');
  addText(doc, 'FECHAS', x + 21, (myY += 4.5), 40);
  addText(doc, 'ITINERARIOS DEL SERVICIO', x + 164, myY, 238);

  doc.setTextColor(COLOR.black);
  contractItineraryList.forEach(
    (
      {
        departureTime,
        route,
        movementType,
        serviceType,
        sourceAddress,
        destinationAddress,
      },
      index,
    ) => {
      myY = isNewPageNeeded(doc, x, myY, cellHeight);

      doc.rect(x, (myY += 1.5), 43, cellHeight);
      doc.rect(x + 43, myY, 244, cellHeight);

      const time = tzNormalizeDate({
        date: departureTime,
        format: LOCAL_TIME_FORMAT,
      });

      let itineraryDescripcion = `${sourceAddress} - ${destinationAddress} ${time} servicio ${serviceType.name}`;

      if (movementType === CONTRACT_MOVEMENT_TYPE.REGULAR.value && route) {
        itineraryDescripcion = `${route.sourceLocation.name} - ${route.destinationLocation.name} ${time} servicio ${serviceType.name}`;
      }

      doc.setFontSize(paragraphSize);
      setFont(doc, 'bold');
      addText(
        doc,
        tzNormalizeDate({ date: departureTime, format: DATE_FORMAT }),
        x + 21,
        (myY += 4.5),
        40,
      );
      setFont(doc);
      addText(doc, `*${index + 1}. ${itineraryDescripcion}`, x + 164, myY, 240);
    },
  );
};

const printDriverRowFields = (doc, index, driver, registeredBus) => {
  myY = isNewPageNeeded(doc, x, myY, cellHeight);

  doc.setFillColor(COLOR.darkGray);
  doc.rect(x, (myY += 1.5), 43, cellHeight, 'DF');
  doc.rect(x + 43, myY, 100, cellHeight);
  doc.rect(x + 143, myY, 35, cellHeight);
  doc.rect(x + 178, myY, 40, cellHeight);
  doc.rect(x + 218, myY, 40, cellHeight);
  doc.rect(x + 258, myY, 29, cellHeight);

  const documentList =
    driver.driverDocumentList[0] && driver.driverDocumentList[0].documentNumber;

  doc.setTextColor(COLOR.white);
  doc.setFontSize(tableHeaderSize);
  setFont(doc, 'bold');
  addText(doc, `*${index + 1}.`, x + 21, (myY += 4.5), 40);
  doc.setFontSize(paragraphSize);
  doc.setTextColor(COLOR.black);
  setFont(doc);
  addText(doc, driver && driver.customer.fullName, x + 92, myY, 96);
  addText(doc, driver && driver.customer.idDocumentNumber, x + 160, myY, 31);
  addText(doc, documentList && documentList.toUpperCase(), x + 197, myY, 37);
  addText(doc, driver && driver.customer.mobilePhone, x + 237.5, myY, 37);
  addText(doc, registeredBus && registeredBus.companyBusId, x + 272.5, myY, 35);
};

const printDriverListFields = (doc, { contractItineraryList }) => {
  myY = isNewPageNeeded(doc, x, myY, cellHeight);

  doc.setFillColor(COLOR.darkGray);
  doc.rect(x, (myY += 1.5), 43, cellHeight, 'DF');
  doc.setFillColor(COLOR.lightGray);
  doc.rect(x + 43, myY, 100, cellHeight, 'DF');
  doc.rect(x + 143, myY, 35, cellHeight, 'DF');
  doc.rect(x + 178, myY, 40, cellHeight, 'DF');
  doc.rect(x + 218, myY, 40, cellHeight, 'DF');
  doc.rect(x + 258, myY, 29, cellHeight, 'DF');

  doc.setFontSize(tableHeaderSize);
  doc.setTextColor(COLOR.white);
  setFont(doc, 'bold');
  addText(doc, 'Datos Conductores', x + 21, (myY += 4.5), 40);
  doc.setTextColor(COLOR.black);
  addText(doc, 'Nombres y Apellidos', x + 92, myY, 96);
  addText(doc, 'DNI', x + 160, myY, 31);
  addText(doc, 'N° Licencia', x + 197, myY, 37);
  addText(doc, 'N° Celular', x + 237.5, myY, 37);
  addText(doc, 'N° Bus', x + 272.5, myY, 35);

  contractItineraryList.forEach(
    ({ firstDriver, secondDriver, thirdDriver, registeredBus }, index) => {
      if (firstDriver)
        printDriverRowFields(doc, index, firstDriver, registeredBus);

      if (secondDriver)
        printDriverRowFields(doc, index, secondDriver, registeredBus);

      if (thirdDriver)
        printDriverRowFields(doc, index, thirdDriver, registeredBus);
    },
  );
};

const printFooter = (doc, contract) => {
  myY = isNewPageNeeded(doc, x, myY, cellHeight);

  doc.rect(x, (myY += 1.5), documentWidth, 12);
  doc.setFontSize(paragraphSize);
  setFont(doc, 'bold');
  addText(
    doc,
    `Emitido por: ${contract.executiveName} (${contract.executivePosition}) / Celular: ${contract.executivePhone} / Email: ${contract.executiveEmail}`,
    x + 1,
    (myY += 4.5),
    documentWidth - x,
    'justify',
  );
};

const printContractServiceOrder = ({ contract }) => {
  const doc = createPdfDocument('l');

  myY = initialY;

  printCivaLogo(doc);
  printDocumentHeader(doc);
  printContractorFields(doc, contract);
  printServiceFields(doc, contract);
  printRouteGeneralFields(doc, contract);
  printItineraryListFields(doc, contract);
  printDriverListFields(doc, contract);
  printFooter(doc, contract);

  window.open(doc.output('bloburl'), '_blank');
};

// eslint-disable-next-line import/prefer-default-export
export { printContractServiceOrder };
