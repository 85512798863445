import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ContractHome from './ContractHome';
import {
  CONTRACT_UNIT_PATH,
  COMPANY_PATH,
  NEW_COMPANY_PATH,
  COMPANY_TICKET_PATH,
  COMPANY_CREDIT_NOTE_PATH,
  COMPANY_USER_CONFIGURATION_PATH,
  NEW_COMPANY_USER_CONFIGURATION_PATH,
  PRIVATE_SERVICE_PATH,
  NEW_PRIVATE_SERVICE_PATH,
} from '../../../config/paths';
import Companies from './company/Companies';
import NewCompany from './company/NewCompany';
import Company from './company/Company';
import EditCompany from './company/EditCompany';
import CompanyTickets from './company-ticket/CompanyTickets';
import CompanyTicket from './company-ticket/CompanyTicket';
import CompanyCreditNotes from './company-credit-note/CompanyCreditNotes';
import CompanyCreditNote from './company-credit-note/CompanyCreditNote';
import CompanyUserConfigurations from './company-user-configuration/CompanyUserConfigurations';
import NewCompanyUserConfiguration from './company-user-configuration/NewCompanyUserConfiguration';
import CompanyUserConfiguration from './company-user-configuration/CompanyUserConfiguration';
import EditCompanyUserConfiguration from './company-user-configuration/EditCompanyUserConfiguration';
import PrivateServices from './private-service/PrivateServices';
import NewPrivateService from './private-service/NewPrivateService';
import PrivateService from './private-service/PrivateService';
import EditPrivateService from './private-service/EditPrivateService';
import PrivateServiceRegisterPassenger from './private-service/PrivateServiceRegisterPassenger';

const ContractUnit = () => (
  <Switch>
    <Route exact path={CONTRACT_UNIT_PATH} component={ContractHome} />
    <Route exact path={COMPANY_PATH} component={Companies} />
    <Route exact path={NEW_COMPANY_PATH} component={NewCompany} />
    <Route exact path={`${COMPANY_PATH}/:id`} component={Company} />
    <Route exact path={`${COMPANY_PATH}/:id/edit`} component={EditCompany} />
    <Route exact path={COMPANY_TICKET_PATH} component={CompanyTickets} />
    <Route
      exact
      path={`${COMPANY_TICKET_PATH}/:id`}
      component={CompanyTicket}
    />
    <Route
      exact
      path={COMPANY_CREDIT_NOTE_PATH}
      component={CompanyCreditNotes}
    />
    <Route
      exact
      path={`${COMPANY_CREDIT_NOTE_PATH}/:id`}
      component={CompanyCreditNote}
    />
    <Route
      exact
      path={COMPANY_USER_CONFIGURATION_PATH}
      component={CompanyUserConfigurations}
    />
    <Route
      exact
      path={NEW_COMPANY_USER_CONFIGURATION_PATH}
      component={NewCompanyUserConfiguration}
    />
    <Route
      exact
      path={`${COMPANY_USER_CONFIGURATION_PATH}/:id`}
      component={CompanyUserConfiguration}
    />
    <Route
      exact
      path={`${COMPANY_USER_CONFIGURATION_PATH}/:id/edit`}
      component={EditCompanyUserConfiguration}
    />
    <Route exact path={PRIVATE_SERVICE_PATH} component={PrivateServices} />
    <Route
      exact
      path={NEW_PRIVATE_SERVICE_PATH}
      component={NewPrivateService}
    />
    <Route
      exact
      path={`${PRIVATE_SERVICE_PATH}/:id`}
      component={PrivateService}
    />
    <Route
      exact
      path={`${PRIVATE_SERVICE_PATH}/:id/edit`}
      component={EditPrivateService}
    />
    <Route
      exact
      path={`${PRIVATE_SERVICE_PATH}/:id/register-passenger`}
      component={PrivateServiceRegisterPassenger}
    />
  </Switch>
);

export default ContractUnit;
