import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { CARGO_DISTANCE_PATH } from '../../../../config/paths';
import { postCargoDistance } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CargoDistanceForm from './CargoDistanceForm';

export class NewCargoDistance extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostCargoDistance } = this.props;
    const newFormValues = {
      sourceCityId: formValues.sourceCity.value,
      destinationCityId: formValues.destinationCity.value,
      kilometers: formValues.kilometers,
      transships: formValues.transships || 0,
    };
    dispatchPostCargoDistance(newFormValues);
  };

  render() {
    const { breadcrumbs } = this.props;
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Nuevo Kilómetros entre Ciudades</h1>
            <p>Crear Nuevo Kilómetros entre Ciudades.</p>
          </Col>
        </Row>
        <CargoDistanceForm onSubmit={this.onSubmit} />
      </Container>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Kilómetros entre Ciudades',
      href: CARGO_DISTANCE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostCargoDistance: postCargoDistance,
};

NewCargoDistance.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCargoDistance: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCargoDistance);
