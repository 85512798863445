import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PATCH_CONFIG,
} from '../config/rest';
import { isErrorResponse } from './error-handlers';

class Fetch {
  constructor() {
    this.getConfig = DEFAULT_GET_CONFIG;
    this.postConfig = DEFAULT_POST_CONFIG;
    this.putConfig = DEFAULT_PUT_CONFIG;
    this.deleteConfig = DEFAULT_DELETE_CONFIG;
    this.patchConfig = DEFAULT_PATCH_CONFIG;
  }

  async get(url) {
    const response = await fetch(url, this.getConfig);
    // check if it is an error response
    await isErrorResponse(response);
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async post({ url, payload }) {
    const response = await fetch(url, {
      ...this.postConfig,
      body: JSON.stringify(payload),
    });
    // check if it is an error response
    await isErrorResponse(response);
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async put({ url, payload }) {
    const response = await fetch(url, {
      ...this.putConfig,
      body: JSON.stringify(payload),
    });
    // check if it is an error response
    await isErrorResponse(response);
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async delete({ url }) {
    const response = await fetch(url, {
      ...this.deleteConfig,
    });
    await isErrorResponse(response);
  }

  async patch({ url, payload }) {
    const response = await fetch(url, {
      ...this.patchConfig,
      body: JSON.stringify(payload),
    });
    // check if it is an error response
    await isErrorResponse(response);
    const jsonResponse = await response.json();
    return jsonResponse;
  }
}

export default new Fetch();
