import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroupText } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateNumber } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import { CURRENCY } from '../../../../config/locale';
import FormFooter from '../../../common/forms/FormFooter';

const AddCreditLineForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Nombre" required>
        <Field
          name="name"
          component={TextInput}
          placeholder="Nombre"
          validate={[isRequired]}
          disabled
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Saldo Inicial" required>
        <Field
          name="initialBalance"
          component={TextInput}
          type="text"
          append={<InputGroupText>{CURRENCY}</InputGroupText>}
          placeholder="Saldo Inicial"
          validate={[isRequired, validateNumber]}
          disabled
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Saldo Actual" required>
        <Field
          name="actualBalance"
          component={TextInput}
          type="text"
          append={<InputGroupText>{CURRENCY}</InputGroupText>}
          placeholder="Saldo Actual"
          validate={[isRequired, validateNumber]}
          disabled
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Saldo Adicional" required>
        <Field
          name="amount"
          component={TextInput}
          type="text"
          append={<InputGroupText>{CURRENCY}</InputGroupText>}
          placeholder="Saldo Adicional"
          validate={[isRequired, validateNumber]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

AddCreditLineForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    contractType: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    commission: PropTypes.number,
  }),
};

AddCreditLineForm.defaultProps = {
  initialValues: null,
};

const formComponent = reduxForm({
  form: 'AddCreditLineForm',
})(AddCreditLineForm);

export default formComponent;
