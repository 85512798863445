import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import SegmentEditButton from './SegmentEditButton';
import SegmentDeleteButton from './SegmentDeleteButton';

const SegmentToolbar = ({ segmentId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <SegmentEditButton segmentId={segmentId} />{' '}
      <SegmentDeleteButton segmentId={segmentId} />
    </div>
  </ButtonToolbar>
);

SegmentToolbar.propTypes = {
  segmentId: PropTypes.number.isRequired,
};

export default SegmentToolbar;
