import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../../../layout/Breadcrumbs';
import TripForm from './TripForm';
import { ITINERARY_PATH } from '../../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import {
  getItinerary,
  clearItinerary,
  postNewTrip,
} from '../../../../../actions';
import Loader from '../../../../common/Loader';
import { TIMESTAMP_FORMAT } from '../../../../../config/locale';
import { TRIP_STATUS } from '../../../../../config/constants';
import { SCHEDULED_ITINERARY_INFO_MESSAGE } from '../../../../../config/messages';
import Alert from '../../../../common/informative/Alert';
import { tzNormalizeDate } from '../../../../../utils/date';

export class EditItineraryForCounter extends Component {
  componentDidMount() {
    const {
      dispatchGetItinerary,
      match: {
        params: { id: itineraryId },
      },
    } = this.props;
    dispatchGetItinerary({ itineraryId });
  }

  componentWillUnmount() {
    const { dispatchClearItinerary } = this.props;
    dispatchClearItinerary();
  }

  onSubmit = (formValues) => {
    const {
      match: {
        params: { id: itineraryId },
      },
      dispatchPostNewTrip,
    } = this.props;

    const newFormValues = {
      itineraryId,
      assignedRegisteredBusId: formValues.companyBusId
        ? formValues.companyBusId.value
        : null,
      tripStatusId: formValues.tripStatusId.value,
      initialOdometerReading: formValues.initialOdometerReading || null,
      finalOdometerReading: formValues.finalOdometerReading || null,
      tripCircuitStatus: formValues.tripCircuitStatus.value,
      estimatedDepartureTime: formValues.estimatedDepartureTime
        ? tzNormalizeDate({
            date: formValues.estimatedDepartureTime,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      actualDepartureTime: formValues.actualDepartureTime
        ? tzNormalizeDate({
            date: formValues.actualDepartureTime,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      estimatedArrivalTime: formValues.estimatedArrivalTime
        ? tzNormalizeDate({
            date: formValues.estimatedArrivalTime,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      actualArrivalTime: formValues.actualArrivalTime
        ? tzNormalizeDate({
            date: formValues.actualArrivalTime,
            format: TIMESTAMP_FORMAT,
          })
        : null,
      driverAssignmentList: formValues.driverIdList
        ? formValues.driverIdList.map((driver) => ({
            driverId: driver.value,
          }))
        : [],
      cabinCrewAssignmentList: formValues.cabinCrewIdList
        ? formValues.cabinCrewIdList.map((cabinCrew) => ({
            cabinCrewId: cabinCrew.value,
          }))
        : [],
    };

    dispatchPostNewTrip(newFormValues);
  };

  generateInitialValues = (itinerary) => ({
    itineraryId: {
      value: itinerary.get('id'),
      label: itinerary.get('route').name,
    },
    tripStatusId: TRIP_STATUS.scheduled,
  });

  render() {
    const { breadcrumbs, itinerary, loading } = this.props;

    if (loading || itinerary.isEmpty()) {
      return <Loader />;
    }

    let content = null;

    if (itinerary.get('tripId')) {
      content = (
        <Alert type="info" message={SCHEDULED_ITINERARY_INFO_MESSAGE} />
      );
    } else {
      content = (
        <TripForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(itinerary)}
        />
      );
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Programar Itinerario</h1>
            <p>Programar un Itinerario.</p>
          </Col>
        </Row>
        {content}
      </Container>
    );
  }
}

EditItineraryForCounter.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  dispatchPostNewTrip: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditItineraryForCounter.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Itinerarios',
      href: ITINERARY_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_PATH}/${id}`,
    },
    {
      text: 'Programar Itinerario',
      href: '',
    },
  ],
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItinerary: getItinerary,
  dispatchClearItinerary: clearItinerary,
  dispatchPostNewTrip: postNewTrip,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditItineraryForCounter);
