import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { USER } from '../../../config/shapes';
import { hasViewAuthorization } from '../../../utils/auth-validator';
import Loader from '../../common/Loader';

// todo: remove eslint disable when parameter is used
// eslint-disable-next-line no-unused-vars
const Authorization = (viewAuthorizations) => (ChildComponent) => {
  class ComposedComponent extends Component {
    static redirectToSignIn(props) {
      // pathname matches the path in the url
      const { location: { pathname } = {} } = props;
      if (pathname) {
        sessionStorage.setItem('redirect', pathname);
      }
      props.dispatch(replace('/sign-in'));
    }

    static redirectToHome(props) {
      props.dispatch(replace('/'));
    }

    constructor(props) {
      super(props);
      const {
        authenticated,
        authenticating,
        user,
        location: { pathname },
      } = this.props;
      // check if authenticated or in process of authentication
      if (!authenticated && !authenticating)
        ComposedComponent.redirectToSignIn(this.props);

      if (authenticated) {
        if (
          !hasViewAuthorization({
            path: pathname,
            securityProfileSet: user.securityProfileSet,
          })
        ) {
          ComposedComponent.redirectToHome(this.props);
        }
      }
    }

    componentDidUpdate() {
      const {
        authenticated,
        authenticating,
        location: { pathname },
        user,
      } = this.props;
      // check if authenticated
      if (!authenticated && !authenticating) {
        return ComposedComponent.redirectToSignIn(this.props);
      }
      if (authenticated) {
        if (
          !hasViewAuthorization({
            path: pathname,
            securityProfileSet: user.securityProfileSet,
          })
        ) {
          ComposedComponent.redirectToHome(this.props);
        }
      }
      // todo: check if viewAuthorizations includes current view
      return true;
    }

    render() {
      const { authenticated, authenticating, user } = this.props;
      if ((!authenticated && authenticating) || !user) return <Loader />;

      return <ChildComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ authentication }) => ({
    user: authentication.get('user'),
    authenticated: authentication.get('authenticated'),
    authenticating: authentication.get('authenticating'),
  });

  ComposedComponent.propTypes = {
    user: PropTypes.shape(USER),
    authenticated: PropTypes.bool,
    authenticating: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  ComposedComponent.defaultProps = {
    user: null,
    authenticated: false,
    authenticating: false,
  };

  return connect(mapStateToProps)(ComposedComponent);
};

export default Authorization;
