import Immutable from 'immutable';
import {
  FLAG_GETTING_TICKETS,
  GET_TICKETS,
  CLEAR_TICKETS,
  GET_TICKET,
  CLEAR_TICKET,
  FLAG_TICKET_ACTIVITY,
  FLAG_CHANGING_PASSENGER,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    changingPassenger: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_TICKETS:
      return state.setIn(['all', 'loading'], payload);
    case GET_TICKETS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_TICKETS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_TICKET_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_TICKET:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_TICKET:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_CHANGING_PASSENGER:
      return state.setIn(['current', 'changingPassenger'], payload);
    default:
      return state;
  }
};
