import autoTable from 'jspdf-autotable';
import {
  addImage,
  addText,
  createPdfDocument,
  paragraphSize,
  setFont,
  textSpacing,
  titleSize,
  x,
  y,
} from '../pdf';
import LogoCiva from '../../resources/images/logo-civa.png';
import { DATE_FORMAT, TIME_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';

const myX = x;
const initialY = y;
let myY = initialY;
let pageWidth = 0;
const newTitleSize = titleSize - 2;
let page = 1;
let tableFinished = false;

const printDocumentHeader = (doc, manifest) => {
  addImage(doc, LogoCiva, myX, 3, 35, 10);

  setFont(doc, 'bold', 'arial-bold');

  doc.setFontSize(newTitleSize);
  addText(
    doc,
    'MANIFIESTO DE ENCOMIENDAS',
    pageWidth / 2,
    (myY += 5),
    pageWidth,
  );

  doc.setFontSize(paragraphSize);

  doc.rect(160, myY - 5, 45, 15);
  addText(doc, 'TURISMO CIVA S.A.C', 195, myY, 80, 'right');
  addText(doc, 'RUC:', 170, (myY += 5), 30);
  addText(doc, '20102427891', 188, myY, 30);

  doc.rect(x, (myY += 10), pageWidth - 10, 33);

  addText(doc, 'FECHA DE EMISIÓN:', 37.8, (myY += 8), 40, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: manifest.exitDate, format: DATE_FORMAT }),
    50,
    myY,
    30,
  );
  addText(doc, 'NÚMERO DE REGISTRO MTC:', 173, myY, 80, 'right');
  addText(doc, '1517538CNG', 190, myY, 30);

  addText(doc, 'USUARIO:', 23.5, (myY += textSpacing), 30, 'right');
  addText(doc, `${manifest.user.customer.fullName}`, 45, myY, 30);

  addText(doc, 'NÚMERO BUS:', 152, myY, 35, 'right');
  addText(doc, ` ${manifest.itinerary.bus.companyBusId}`, 160, myY, 30);

  addText(doc, 'ORIGEN:', 21.5, (myY += textSpacing), 30, 'right');
  addText(doc, `${manifest.sourceLocation.name}`, 45, myY, 30);

  addText(doc, 'PLACA:', 143, myY, 30, 'right');
  addText(doc, `${manifest.itinerary.bus.licensesPlate}`, 155, myY, 30);

  addText(doc, 'DESTINO:', 23, (myY += textSpacing), 30, 'right');
  addText(doc, `${manifest.destinationLocation.name}`, 45, myY, 30);

  let driverMy = myY;

  addText(doc, 'CONDUCTOR:', 152, driverMy, 30, 'right');
  manifest.driverList.forEach((driver) => {
    addText(doc, `${driver.fullName}`, 167, driverMy, 30);
    driverMy += textSpacing;
  });

  addText(doc, 'FECHA SALIDA:', 31.2, (myY += textSpacing), 30, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: manifest.exitDate, format: DATE_FORMAT }),
    45,
    myY,
    30,
  );
  addText(doc, 'HORA SALIDA:', 30, (myY += textSpacing), 30, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: manifest.exitDate, format: TIME_FORMAT }),
    35,
    myY,
    30,
  );
};

const countDestinyLocations = (data) => {
  const result = {};

  data.forEach(({ item: { destinyLocation, quantity, price } }) => {
    if (result[destinyLocation]) {
      result[destinyLocation].totalQuantity += quantity;
      result[destinyLocation].totalPrice += price;
    } else {
      result[destinyLocation] = { totalQuantity: quantity, totalPrice: price };
    }
  });

  const output = Object.entries(result).map(
    ([location, { totalQuantity, totalPrice }]) => ({
      destinyLocation: location,
      totalQuantity,
      totalPrice,
    }),
  );

  return output;
};

const sumTotalPrices = (data) =>
  data.reduce((total, { item: { price } }) => total + price, 0);

const printFooter = (doc, manifest) => {
  addText(doc, 'Subtotal', myX, (myY += 15));

  countDestinyLocations(manifest.manifestCargoItemDetails).forEach((item) => {
    addText(doc, `${item.destinyLocation}:`, myX, (myY += 5));
    addText(doc, `${item.totalQuantity}`, 40, myY);
    addText(doc, 'Importe Total:', 70, myY);
    addText(doc, `${item.totalPrice}`, 100, myY);
  });
  addText(doc, 'Total:', myX, (myY += 15));
  addText(doc, 'Importe Total:', 70, myY);
  addText(
    doc,
    `${sumTotalPrices(manifest.manifestCargoItemDetails)}`,
    100,
    myY,
  );
  addText(doc, `${manifest.manifestCargoItemDetails.length}`, 25, myY);
  addText(doc, '_____________________________', +10, myY + 20);
  addText(doc, 'Conformidad de agencia de origen', x + 6, myY + 25);
  addText(doc, '_____________________________', x + 70, myY + 20);
  addText(doc, 'Conformidad del conductor', x + 70, myY + 25);
  addText(doc, '_____________________________', x + 130, myY + 20);
  addText(doc, 'Conformidad de agencia de destino', x + 130, myY + 25);
};

const printItems = (doc, manifest) => {
  const columns = [
    { title: '#', dataKey: 'item' },
    { title: 'Correlacion', dataKey: 'correlation' },
    { title: 'Consignado', dataKey: 'consigned' },
    { title: 'Cantidad', dataKey: 'quantity' },
    { title: 'Descripcion', dataKey: 'description' },
    { title: 'Peso', dataKey: 'weight' },
    { title: 'Destino', dataKey: 'destinyLocation' },
    { title: 'Método de Pago', dataKey: 'paymentMethodName' },
  ];

  let rowNumber = 0;

  const rows = [];

  manifest.manifestCargoItemDetails.forEach((cargoItem) => {
    rowNumber += 1;

    const row = {
      item: rowNumber,
      correlation: cargoItem.item.correlation,
      consigned: cargoItem.item.consigned,
      quantity: cargoItem.item.quantity,
      description: cargoItem.item.description,
      weight: cargoItem.item.weight,
      price: cargoItem.item.price,
      destinyLocation: cargoItem.item.destinyLocation,
      paymentMethodName: cargoItem.item.paymentMethodName
        ? cargoItem.item.paymentMethodName
        : '-',
    };

    rows.push(row);
  });

  const autoTableOptions = {
    columns,
    body: rows,
    startY: myY + 5,
    showHead: 'everyPage',
    margin: { top: 65, right: 5, left: 5, bottom: 10 },
    theme: 'striped',
    headStyles: { fillColor: [255, 255, 255] },
    styles: {
      overflow: 'hidden',
      cellPadding: 0.5,
      textColor: 0,
      halign: 'center',
    },
    didDrawPage: (drawData) => {
      if (drawData.pageNumber > page) {
        page = drawData.pageNumber;
        myY = initialY;
        printDocumentHeader(doc, manifest);
      }
    },
  };

  autoTable(doc, autoTableOptions);
  tableFinished = true;
};

const printCargoItemManifest = (manifest) => {
  const doc = createPdfDocument();

  pageWidth = doc.internal.pageSize.getWidth();
  page = 1;
  myY = initialY;

  printDocumentHeader(doc, manifest);

  doc.setFontSize(newTitleSize);

  setFont(doc, 'bold', 'arial-bold');

  addText(doc, 'Listado de Encomiendas', pageWidth / 2, (myY += 12), pageWidth);

  setFont(doc);

  doc.setFontSize(paragraphSize);

  printItems(doc, manifest);

  if (tableFinished) {
    doc.addPage();
    myY = initialY;

    printDocumentHeader(doc, manifest);

    doc.setFontSize(newTitleSize);

    addText(doc, 'RESUMEN', pageWidth / 2, (myY += 15), pageWidth);

    doc.setFontSize(paragraphSize);

    printFooter(doc, manifest);
  }

  myY = 5;

  window.open(doc.output('bloburl'), '_blank');
};

export default printCargoItemManifest;
