import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deletePaymentMethodForSecurityProfile } from '../../../../actions/security/PaymentMethodForSecurityProfile';

const PaymentMethodForSecurityProfileDeleteButton = ({
  dispatchDeletePaymentMethodForSecurityProfile,
  paymentMethodForSecurityProfileId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeletePaymentMethodForSecurityProfile({
      paymentMethodForSecurityProfileId,
    });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

PaymentMethodForSecurityProfileDeleteButton.propTypes = {
  dispatchDeletePaymentMethodForSecurityProfile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  paymentMethodForSecurityProfileId: PropTypes.number.isRequired,
};

PaymentMethodForSecurityProfileDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeletePaymentMethodForSecurityProfile:
    deletePaymentMethodForSecurityProfile,
};

export default connect(
  null,
  mapDispatchToProps,
)(PaymentMethodForSecurityProfileDeleteButton);
