import React from 'react';
import PropTypes from 'prop-types';
import './NoteListResource.css';

const NoteListResource = ({ values }) => {
  let noteList = null;

  if (values.length) {
    noteList = values.map((value, index) => (
      <div className="mb-2 pb-1" key={+index}>
        <div className="mb-2">Nota {+index + 1}</div>
        <div className="inputText disabled">{value.note}</div>
      </div>
    ));
  }

  return noteList;
};

NoteListResource.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
    }),
  ),
};

NoteListResource.defaultProps = {
  values: [],
};

export default NoteListResource;
