import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_ORGANIZATION_CHARTS,
  GET_ORGANIZATION_CHARTS,
  CLEAR_ORGANIZATION_CHARTS,
  FLAG_ORGANIZATION_CHART_ACTIVITY,
  GET_ORGANIZATION_CHART,
  CLEAR_ORGANIZATION_CHART,
} from '../types/mechanical-maintenance';
import { ORGANIZATION_CHART_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { ORGANIZATION_CHART_PATH } from '../../config/paths';
import { ORGANIZATION_CHART_AREA_TYPES } from '../../config/constants';

const flagGettingOrganizationCharts = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ORGANIZATION_CHARTS,
    payload: flag,
  });

const getOrganizationCharts =
  async (tableFilters, hierarchy = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingOrganizationCharts(true));
      const query = tableFilters;
      const url = `${ORGANIZATION_CHART_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();

      if (hierarchy)
        dispatch({
          type: ORGANIZATION_CHART_AREA_TYPES[hierarchy].get,
          payload: response,
        });
      else
        dispatch({
          type: GET_ORGANIZATION_CHARTS,
          payload: response,
        });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingOrganizationCharts(false));
    }
  };

const clearOrganizationCharts =
  (hierarchy = null) =>
  (dispatch) => {
    if (hierarchy)
      dispatch({ type: ORGANIZATION_CHART_AREA_TYPES[hierarchy].clear });
    else
      dispatch({
        type: CLEAR_ORGANIZATION_CHARTS,
      });
  };

const flagOrganizationChartActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ORGANIZATION_CHART_ACTIVITY,
    payload: flag,
  });

const getOrganizationChart =
  async ({ organizationChartId }) =>
  async (dispatch) => {
    try {
      dispatch(flagOrganizationChartActivity(true));
      const url = `${ORGANIZATION_CHART_ENDPOINT}/${organizationChartId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_ORGANIZATION_CHART,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagOrganizationChartActivity(false));
    }
  };

const clearOrganizationChart = () => (dispatch) =>
  dispatch({
    type: CLEAR_ORGANIZATION_CHART,
  });

const postOrganizationChart =
  async ({
    name,
    description,
    companyAreaTypeId,
    companyId,
    parentCompanyAreaId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagOrganizationChartActivity(true));
      const payload = {
        name,
        description,
        companyAreaTypeId,
        companyId,
        parentCompanyAreaId,
      };
      const url = ORGANIZATION_CHART_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ORGANIZATION_CHART_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagOrganizationChartActivity(false));
    }
  };

const deleteOrganizationChart =
  async ({ organizationChartId }) =>
  async (dispatch) => {
    try {
      dispatch(flagOrganizationChartActivity(true));
      const url = `${ORGANIZATION_CHART_ENDPOINT}/${organizationChartId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ORGANIZATION_CHART_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagOrganizationChartActivity(false));
    }
  };

const putOrganizationChart =
  async (
    organizationChartId,
    { name, description, companyAreaTypeId, companyId, parentCompanyAreaId },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagOrganizationChartActivity(true));
      const payload = {
        id: organizationChartId,
        name,
        description,
        companyAreaTypeId,
        companyId,
        parentCompanyAreaId,
      };
      const url = `${ORGANIZATION_CHART_ENDPOINT}/${organizationChartId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(ORGANIZATION_CHART_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagOrganizationChartActivity(false));
    }
  };

export {
  flagGettingOrganizationCharts,
  getOrganizationCharts,
  clearOrganizationCharts,
  flagOrganizationChartActivity,
  getOrganizationChart,
  postOrganizationChart,
  clearOrganizationChart,
  deleteOrganizationChart,
  putOrganizationChart,
};
