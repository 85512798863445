import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import * as Sentry from '@sentry/browser';
import Moment from 'moment';
import MomentLocalizer from 'react-widgets-moment';
import ReactGa from 'react-ga';
import 'moment/locale/es';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'font-awesome/css/font-awesome.css';
import 'react-widgets/styles.css';
import 'react-table/react-table.css';
import './index.css';
import './resources/fonts/icomoon.css';
import Root from './components/Root';
import configureStore, { history } from './store/configure-store';
import registerServiceWorker from './registerServiceWorker';
import { LOCALE } from './config/locale';
import ANALYTICS_ID from './config/analytics';
import { CONFIG as SENTRY_CONFIG } from './config/sentry';
import { isLocalEnvironment } from './config/environments';

// Set up localization
Moment.locale(LOCALE);
// eslint-disable-next-line no-new
new MomentLocalizer(Moment);

ReactGa.initialize(ANALYTICS_ID);

// Only track with sentry if in dev, staging or prod
if (!isLocalEnvironment()) {
  Sentry.init(SENTRY_CONFIG);
}

// Renders app to the dom
const root = document.getElementById('root');
const renderApp = (RootComponent, store) => {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <RootComponent />
      </ConnectedRouter>
    </Provider>,
    root,
  );
};

// Render app to the dom
const store = configureStore();
renderApp(Root, store);

// Refresh a module that was changed without update the page
if (module.hot) {
  module.hot.accept('./components/Root', () => {
    // eslint-disable-next-line global-require
    const NextRoot = require('./components/Root').default;
    renderApp(NextRoot, store);
  });
}

registerServiceWorker();
