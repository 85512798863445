import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Row, Col, ButtonToolbar } from 'reactstrap';
import Loader from '../../../common/Loader';
import Table from '../../../common/Table';
import {
  INTERNAL_CARGO_ITEM_PATH,
  INTERNAL_TRANSFER_PATH,
} from '../../../../config/paths';
import {
  clearInternalParcel,
  getInternalParcel,
  postInternalParcelComment,
} from '../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { padStart } from '../../../../utils/string';
import { tzNormalizeDate } from '../../../../utils/date';
import Content from '../../../layout/Content';
import PrintCargoTicketButton from '../../reservation/ticket/ticket-toolbar/PrintCargoTicketButton';
import ParcelCommentForm from '../cargo/ParcelCommentForm';

class InternalParcel extends Component {
  componentDidMount() {
    const {
      dispatchGetInternalParcel,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetInternalParcel({ id });
  }

  componentWillUnmount() {
    const { dispatchClearInternalParcel } = this.props;
    dispatchClearInternalParcel();
  }

  postComment = (formValues) => {
    const { dispatchPostInternalParcelComment, internalParcel } = this.props;

    const newFormValues = {
      internalParcelId: internalParcel.get('id'),
      description: formValues.parcelComment,
    };

    dispatchPostInternalParcelComment(newFormValues);
  };

  render() {
    const { breadcrumbs, internalParcel, loading } = this.props;

    let content = null;
    if (loading || internalParcel.isEmpty()) {
      content = <Loader />;
    } else {
      const itemList = internalParcel.get('internalCargoItemList');
      const items = (
        <div>
          <h5>Items</h5>
          <Table
            columns={[
              {
                Header: 'Cantidad',
                accessor: 'quantity',
                className: 'text-center',
              },
              {
                Header: 'Producto Frecuente',
                accessor: 'commonProductName',
                className: 'text-center',
              },
              {
                Header: 'Descripción',
                accessor: 'description',
                className: 'text-center',
              },
              {
                Header: 'Categoría',
                accessor: 'cargoCategoryName',
                className: 'text-center',
              },
              {
                Header: 'Peso (kg)',
                accessor: 'weight',
                className: 'text-center',
              },
            ]}
            data={itemList}
            defaultPageSize={itemList.length}
            showPagination={false}
            modelPath={INTERNAL_CARGO_ITEM_PATH}
            navigateToModelOnRowClick
            openPathInNewTab
          />
        </div>
      );

      content = (
        <>
          <Row>
            <Col md={6}>
              <ResourceProperty label="Tipo de Comprobante:">
                {internalParcel.get('voucherTypeName')}
              </ResourceProperty>
              <ResourceProperty label="Número de Comprobante:">
                {internalParcel.get('documentSeries')}-
                {padStart(internalParcel.get('documentCode'), 7)}
              </ResourceProperty>
              <ResourceProperty label="Origen:">
                {internalParcel.get('sourceCityName')}
              </ResourceProperty>
              <ResourceProperty label="Destino:">
                {internalParcel.get('destinationCityName')}
              </ResourceProperty>
              <ResourceProperty label="Remitente:">
                {internalParcel.get('senderCustomerFullName')}
                {' - '}
                {
                  internalParcel.get('senderCustomer').identificationType.name
                }{' '}
                {internalParcel.get('senderCustomer').idDocumentNumber}
              </ResourceProperty>
              <ResourceProperty label="Consignado:">
                {internalParcel.get('consigneeCustomerFullName')}
                {' - '}
                {
                  internalParcel.get('consigneeCustomer').identificationType
                    .name
                }{' '}
                {internalParcel.get('consigneeCustomer').idDocumentNumber}
              </ResourceProperty>
            </Col>
            <Col md={6}>
              <h3>Información Interna</h3>
              <ResourceProperty label="Fecha de Creación:">
                {tzNormalizeDate({
                  date: internalParcel.get('createDate'),
                  format: DATE_TIME_FORMAT,
                })}
              </ResourceProperty>
              <ResourceProperty label="Última Actualización:">
                {tzNormalizeDate({
                  date: internalParcel.get('lastUpdate'),
                  format: DATE_TIME_FORMAT,
                })}
              </ResourceProperty>
              <ResourceProperty label="Creado por:">
                {internalParcel.get('createUserFullname')}
              </ResourceProperty>
              <ResourceProperty label="Actualizado por:">
                {internalParcel.get('lastUpdateUser')
                  ? internalParcel.get('lastUpdateUser').fullName
                  : '-'}
              </ResourceProperty>
            </Col>
          </Row>
          {items}
          <div>
            <h5>Comentarios</h5>
            <ParcelCommentForm
              comments={internalParcel.get('commentList')}
              onSubmit={this.postComment}
            />
          </div>
        </>
      );
    }

    const toolbar = (
      <ButtonToolbar className="pull-right">
        <div>
          <PrintCargoTicketButton ticket={internalParcel.toJS()} />{' '}
        </div>
      </ButtonToolbar>
    );

    return (
      <Content
        toolbar={toolbar}
        breadcrumbs={breadcrumbs}
        title="Translado Interno"
        content={content}
      />
    );
  }
}
InternalParcel.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetInternalParcel: PropTypes.func.isRequired,
  dispatchClearInternalParcel: PropTypes.func.isRequired,
  dispatchPostInternalParcelComment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  internalParcel: PropTypes.instanceOf(Immutable.Map).isRequired,
};

InternalParcel.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Translados Internos',
      href: INTERNAL_TRANSFER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  internalParcel: CargoUnit.InternalTransfer.getIn(['current', 'content']),
  loading: !CargoUnit.InternalTransfer.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetInternalParcel: getInternalParcel,
  dispatchClearInternalParcel: clearInternalParcel,
  dispatchPostInternalParcelComment: postInternalParcelComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalParcel);
