const hasViewAuthorization = ({ path, securityProfileSet }) =>
  securityProfileSet.some((securityProfile) =>
    securityProfile.viewAuthorizationList.some((viewAuthorization) => {
      // TODO: consider we currently have * as a view in production
      // enforce viewAuthorization to exactly match the path
      if (path === '/' || path.match(`^${viewAuthorization.view.route}$`)) {
        return true;
      }
      return false;
    }),
  );

// eslint-disable-next-line import/prefer-default-export
export { hasViewAuthorization };
