import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { getExpensesPerUser } from '../../../../actions/sales/ExpenseStatus';
import ExpenseStatusSearchForm from './ExpenseStatusSearchForm';
import ExpenseStatusSearchResult from './ExpenseStatusSearchResult';
import { EXPENSE_STATUS } from '../../../../config/constants';
import { DEFAULT_QUERY } from '../../../../config/queries';
import Content from '../../../layout/Content';

export class ExpenseStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFormValues: { expenseStatus: EXPENSE_STATUS.PENDING.value },
    };
  }

  onSubmit = (formValues) => {
    const newFormValues = {
      ...DEFAULT_QUERY,
      expenseStatus: formValues.expenseStatus.value,
    };

    this.setState({ searchFormValues: newFormValues });

    this.props.dispatchGetExpensesPerUser(newFormValues);
  };

  render() {
    const content = (
      <Fragment>
        <ExpenseStatusSearchForm
          onSubmit={this.onSubmit}
          initialValues={{ expenseStatus: EXPENSE_STATUS.PENDING }}
        />
        <ExpenseStatusSearchResult
          searchFormValues={this.state.searchFormValues}
        />
      </Fragment>
    );
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Autorización de Egresos"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Autorización de Egresos', href: '' },
  ],
});

const mapDispatchToProps = {
  dispatchGetExpensesPerUser: getExpensesPerUser,
};

ExpenseStatus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchGetExpensesPerUser: PropTypes.func.isRequired,
};

ExpenseStatus.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseStatus);
