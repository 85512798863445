import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getCompanies, clearCompanies } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';

const CompanySelect = ({
  dispatchGetCompanies,
  dispatchClearCompanies,
  companies,
  isLoading,
  isMulti,
  input,
  meta,
  isDisabled,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearCompanies(), []);

  const onChangeCompany = (inputValue) =>
    inputValue.trim().length &&
    inputValue.trim().length >= 4 &&
    dispatchGetCompanies({ query: `name:${inputValue}` });

  return (
    <Select
      options={companies}
      isLoading={isLoading}
      isMulti={isMulti}
      input={input}
      meta={meta}
      isDisabled={isDisabled}
      onInputChange={onChangeCompany}
      {...rest}
    />
  );
};

CompanySelect.propTypes = {
  dispatchGetCompanies: PropTypes.func.isRequired,
  dispatchClearCompanies: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

CompanySelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  companies: [],
  isDisabled: false,
};

const mapStateToProps = ({ ContractUnit }) => ({
  companies: ContractUnit.Company.getIn(['all', 'content', 'content']).map(
    (company) => ({
      value: company.id,
      label: company.name,
    }),
  ),
  loading: ContractUnit.Company.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetCompanies: getCompanies,
  dispatchClearCompanies: clearCompanies,
};

export default connect(mapStateToProps, mapDispatchToprops)(CompanySelect);
