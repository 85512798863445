import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { IN_SYSTEM_NOTIFICATION_PATH } from '../../../../config/paths';
import {
  getInSystemNotification,
  clearInSystemNotification,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import InSystemNotificationToolbar from './InSystemNotificationToolbar';
import Alert from '../../../common/informative/Alert';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import Checkbox from '../../../common/forms/Checkbox';

class InSystemNotification extends Component {
  componentDidMount() {
    const {
      dispatchGetInSystemNotification,
      match: {
        params: { id: inSystemNotificationId },
      },
    } = this.props;
    dispatchGetInSystemNotification({ inSystemNotificationId });
  }

  componentWillUnmount() {
    const { dispatchClearInSystemNotification } = this.props;
    dispatchClearInSystemNotification();
  }

  render() {
    const { breadcrumbs, notification, loading } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (notification.isEmpty()) {
      content = <NoDataResource returnPage={IN_SYSTEM_NOTIFICATION_PATH} />;
    } else {
      const {
        active,
        createDate,
        id,
        lastUpdate,
        message,
        notificationSeverity,
        validFrom,
        validUntil,
      } = notification.toJS();

      const basicInformationResource = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="Mensaje:">{message}</ResourceProperty>
          <ResourceProperty label="Gravedad:">
            {notificationSeverity}
          </ResourceProperty>
          <ResourceProperty label="Válido Desde:">
            {tzNormalizeDate({ date: validFrom, format: DATE_TIME_FORMAT })}
          </ResourceProperty>
          <ResourceProperty label="Válido Hasta:">
            {tzNormalizeDate({ date: validUntil, format: DATE_TIME_FORMAT })}
          </ResourceProperty>
          <div className="mb-3">
            <strong>Estado:</strong> <Checkbox checked={active} /> Activo
          </div>
        </Fragment>
      );

      const previewResource =
        notificationSeverity && message ? (
          <>
            <h3>Vista Previa</h3>
            <Alert
              type={notificationSeverity.toLowerCase()}
              message={message}
            />
          </>
        ) : null;

      const internalData = (
        <InternalResource
          id={id}
          createDate={createDate}
          lastUpdate={lastUpdate}
        />
      );

      content = (
        <Fragment>
          {basicInformationResource}
          {previewResource}
          {internalData}
        </Fragment>
      );

      toolbar = <InSystemNotificationToolbar inSystemNotificationId={id} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Notificación Interna del Sistema"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

InSystemNotification.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetInSystemNotification: PropTypes.func.isRequired,
  dispatchClearInSystemNotification: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  notification: PropTypes.instanceOf(Immutable.Map).isRequired,
};

InSystemNotification.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ SystemUnit }) => ({
  breadcrumbs: [
    ...SystemUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Notificaciones Internas del Sistema',
      href: IN_SYSTEM_NOTIFICATION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  notification: SystemUnit.InSystemNotification.getIn(['current', 'content']),
  loading: !SystemUnit.InSystemNotification.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetInSystemNotification: getInSystemNotification,
  dispatchClearInSystemNotification: clearInSystemNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InSystemNotification);
