import { string, number } from 'prop-types';

const busChassisBasicInformationPropTypes = {
  id: number.isRequired,
  make: string.isRequired,
  model: string.isRequired,
  year: number.isRequired,
  numberOfAxles: number.isRequired,
};

export default busChassisBasicInformationPropTypes;
