import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import {
  getPrivateService,
  clearPrivateService,
  putPrivateService,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import { PRIVATE_SERVICE_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';
import ContractorInformationResource from './resource/ContractInformationResource';
import ServiceInformationResource from './resource/ServiceInformationResource';
import PrivateServiceRegisterPassengerForm from './PrivateServiceRegisterPassengerForm';

class PrivateService extends Component {
  componentDidMount() {
    const {
      dispatchGetPrivateService,
      match: {
        params: { id: privateServiceId },
      },
    } = this.props;
    dispatchGetPrivateService({ privateServiceId });
  }

  componentWillUnmount() {
    this.props.dispatchClearPrivateService();
  }

  onSubmit = (formValues) => {
    const {
      privateService,
      dispatchPutPrivateService,
      match: {
        params: { id },
      },
    } = this.props;

    const contractItineraryCustomerArray = [];
    let lastTicket = true;
    formValues.contractItineraryList.forEach(
      ({ contractItineraryId, contractItineraryCustomerList }) => {
        contractItineraryCustomerList.forEach(({ seatId, customerId }) => {
          if (customerId) {
            const contractItineraryCustomerJson = {
              contractItineraryId: contractItineraryId.value,
              seatId: seatId.value,
              customerId: customerId.value,
              lastTicket,
            };
            lastTicket = false;
            contractItineraryCustomerArray.push(contractItineraryCustomerJson);
          }
        });
      },
    );

    const privateServiceJson = privateService.toJS();
    const newFormValues = { ...privateServiceJson };
    newFormValues.contractItineraryList =
      privateServiceJson.contractItineraryList.map((contractItinerary) => ({
        ...contractItinerary,
        customerList: contractItineraryCustomerArray.filter(
          (contractItineraryCustomer) =>
            contractItineraryCustomer.contractItineraryId ===
            contractItinerary.id,
        ),
      }));

    dispatchPutPrivateService(id, newFormValues);
  };

  generateInitialValues = (privateServiceJson) => {
    const contractItineraryList = privateServiceJson.contractItineraryList.map(
      (itinerary) => {
        const contractItineraryCustomerList = [];

        if (itinerary.registeredBus) {
          // forEach to generate fields to register passengers
          itinerary.registeredBus.seatMap.seats.forEach(
            ({ seatMapElement, id, seatNumber, floorNumber }) => {
              if (seatMapElement.isSeat) {
                const customerObject = itinerary.customerList.filter(
                  (customerSeat) => customerSeat.seatId === id,
                );

                const customerId = customerObject[0] &&
                  customerObject[0].customer && {
                    value: customerObject[0].customerId,
                    label: `${customerObject[0].customer.idDocumentNumber} (${customerObject[0].customer.idCountryOfOrigin}) - ${customerObject[0].customer.fullName}`,
                    id: customerObject[0].customer.id,
                    firstName: customerObject[0].customer.firstName,
                    lastName: customerObject[0].customer.lastName,
                    fullName: customerObject[0].customer.fullName,
                    idDocumentNumber:
                      customerObject[0].customer.idDocumentNumber,
                    gender: customerObject[0].customer.gender,
                    idCountryOfOrigin:
                      customerObject[0].customer.idCountryOfOrigin,
                    identificationType:
                      customerObject[0].customer.identificationType,
                    email: customerObject[0].customer.email,
                    mobilePhone: customerObject[0].customer.mobilePhone,
                    dob: customerObject[0].customer.dob,
                    discountCode: customerObject[0].customer.discountCode,
                  };

                contractItineraryCustomerList.push({
                  seatId: {
                    value: id,
                    label: `${seatNumber} (Piso ${floorNumber})`,
                    seatNumber,
                    floorNumber,
                  },
                  customerId,
                });
              }
            },
          );
        }

        const contractItineraryCustomerListSorted =
          contractItineraryCustomerList.sort((a, b) => {
            if (a.seatId.seatNumber > b.seatId.seatNumber) return 1;
            if (a.seatId.seatNumber < b.seatId.seatNumber) return -1;
            return 0;
          });

        const contractItineraryId = itinerary.registeredBus &&
          itinerary.route && {
            value: itinerary.id,
            label: `${itinerary.registeredBus.companyBusId} (${contractItineraryCustomerListSorted.length} asientos) Ruta ${itinerary.route.name}`,
          };

        return {
          contractItineraryId,
          contractItineraryCustomerList: contractItineraryCustomerListSorted,
        };
      },
    );

    return {
      contractStatus: privateServiceJson.contractStatus,
      contractItineraryList,
    };
  };

  render() {
    const { breadcrumbs, privateService, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (privateService.isEmpty()) {
      content = <NoDataResource returnPage={PRIVATE_SERVICE_PATH} />;
    } else {
      const privateServiceJson = privateService.toJS();

      const contractorInformationResource = (
        <ContractorInformationResource {...privateServiceJson} />
      );

      const serviceInformationResource = (
        <ServiceInformationResource {...privateServiceJson} />
      );

      content = (
        <>
          <Row>
            <Col>{contractorInformationResource}</Col>
            <Col>{serviceInformationResource}</Col>
          </Row>
          <PrivateServiceRegisterPassengerForm
            onSubmit={this.onSubmit}
            initialValues={this.generateInitialValues(privateServiceJson)}
          />
        </>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Registrar Pasajero"
        content={content}
      />
    );
  }
}

PrivateService.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetPrivateService: PropTypes.func.isRequired,
  dispatchClearPrivateService: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  privateService: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPutPrivateService: PropTypes.func.isRequired,
};

PrivateService.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { ContractUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Servicios Privados',
      href: PRIVATE_SERVICE_PATH,
    },
    {
      text: 'Ver',
      href: `${PRIVATE_SERVICE_PATH}/${id}`,
    },
    {
      text: 'Registrar Pasajero',
      href: '',
    },
  ],
  privateService: ContractUnit.PrivateService.getIn(['current', 'content']),
  loading: !ContractUnit.PrivateService.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutPrivateService: putPrivateService,
  dispatchGetPrivateService: getPrivateService,
  dispatchClearPrivateService: clearPrivateService,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateService);
