import { string, number, shape, arrayOf } from 'prop-types';
import { maintenanceWarningBasicInformationPropTypes } from '../../maintenance-warning/prop-types/MaintenanceWarningPropTypes';
import { activityBusExecutionBasicInformationPropTypes } from '../../register-of-execution/prop-types/ActivityBusExecutionPropTypes';
import { registeredBusBasicInformationPropTypes } from '../../../bus/registered-bus/proptypes/RegisteredBusPropTypes';

const maintenanceServiceOrderBasicInformationPropTypes = {
  deadline: number,
  priority: string.isRequired,
  comments: string,
  status: string.isRequired,
  registeredBus: shape(registeredBusBasicInformationPropTypes).isRequired,
};

const maintenanceServiceOrderBasicDefaultProps = {
  comments: null,
  maintenanceWarning: null,
  deadline: null,
};

const maintenanceServiceOrderInternalInformationPropTypes = {
  id: number.isRequired,
  createDate: number.isRequired,
  lastUpdate: number,
};

const maintenanceServiceOrderInternalDefaultProps = {
  lastUpdate: null,
};

const maintenanceServiceOrderMaintenanceWarningListPropTypes = {
  maintenanceWarningList: arrayOf(
    shape(maintenanceWarningBasicInformationPropTypes),
  ),
};

const maintenanceServiceOrderMaintenanceWarningListDefaultProps = {
  maintenanceWarningList: [],
};

const maintenanceServiceOrderActivityBusExecutionListPropTypes = {
  activityBusExecutionList: arrayOf(
    shape(activityBusExecutionBasicInformationPropTypes),
  ),
};

const maintenanceServiceOrderActivityBusExecutionListDefaultProps = {
  activityBusExecutionList: [],
};

export {
  maintenanceServiceOrderBasicInformationPropTypes,
  maintenanceServiceOrderBasicDefaultProps,
  maintenanceServiceOrderInternalInformationPropTypes,
  maintenanceServiceOrderInternalDefaultProps,
  maintenanceServiceOrderActivityBusExecutionListPropTypes,
  maintenanceServiceOrderActivityBusExecutionListDefaultProps,
  maintenanceServiceOrderMaintenanceWarningListPropTypes,
  maintenanceServiceOrderMaintenanceWarningListDefaultProps,
};
