import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DRIVER_BUS_ASSIGNMENT_PATH } from '../../../../config/paths';
import DriverBusAssignmentForm from './DriverBusAssignmentForm';
import {
  postDriverBusAssign,
  getDriverBusAssignment,
  clearDriverBusAssignment,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditDriverBusAssignment extends Component {
  componentDidMount() {
    const {
      dispatchGetDriverBusAssignment,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDriverBusAssignment({ id });
  }

  componentWillUnmount() {
    const { dispatchClearDriverBusAssignment } = this.props;
    dispatchClearDriverBusAssignment();
  }

  onSubmit = (formValues) => {
    const { dispatchPostDriverBusAssign } = this.props;
    const driverListLength = formValues.driverList.length;
    const newFormValues = {
      busId: formValues.bus.value,
      companyBusId: formValues.bus.label,
      driverId: formValues.driverList[driverListLength - 1].driver.value,
      driverTypeId:
        formValues.driverList[driverListLength - 1].driverType.value,
    };
    dispatchPostDriverBusAssign(newFormValues);
  };

  render() {
    const { breadcrumbs, driverBusAssignment, loading } = this.props;
    let content;

    if (loading) {
      content = <Loader />;
    } else if (driverBusAssignment.isEmpty()) {
      content = <NoDataResource returnPage={DRIVER_BUS_ASSIGNMENT_PATH} />;
    } else {
      const bus = {
        value: driverBusAssignment.get('registeredBus').id,
        label: driverBusAssignment.get('registeredBus').companyBusId,
      };
      content = <DriverBusAssignmentForm onSubmit={this.onSubmit} bus={bus} />;
    }
    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Asignación de Conductores"
        subtitle="Editar asignación de conductores"
        content={content}
      />
    );
  }
}

EditDriverBusAssignment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDriverBusAssign: PropTypes.func.isRequired,
  dispatchGetDriverBusAssignment: PropTypes.func.isRequired,
  dispatchClearDriverBusAssignment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  driverBusAssignment: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDriverBusAssignment.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignaciones de Conductores',
      href: DRIVER_BUS_ASSIGNMENT_PATH,
    },
    {
      text: 'Ver',
      href: `${DRIVER_BUS_ASSIGNMENT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  driverBusAssignment: TrafficUnit.DriverBusAssignment.getIn([
    'current',
    'content',
  ]),
  loading: !TrafficUnit.DriverBusAssignment.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPostDriverBusAssign: postDriverBusAssign,
  dispatchGetDriverBusAssignment: getDriverBusAssignment,
  dispatchClearDriverBusAssignment: clearDriverBusAssignment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDriverBusAssignment);
