// types should always be imperative verbs

export const CHANGE_STEP = 'CHANGE_STEP';
export const RESET_STEP = 'RESET_STEP';
export const FETCH_CITIES = 'FETCH_CITIES';
export const SEARCH_FOR_ITINERARIES = 'SEARCH_FOR_ITINERARIES';
export const SELECT_ITINERARY_SEARCH_RESULT = 'SELECT_ITINERARY_SEARCH_RESULT';
export const SELECT_SEAT = 'SELECT_SEAT';
export const DESELECT_SEAT = 'DESELECT_SEAT';
export const CLEAR_SEAT_RESERVATIONS = 'CLEAR_SEAT_RESERVATIONS';
export const POST_RESERVATION_REQUESTS = 'POST_RESERVATION_REQUESTS';
export const GET_PUBLIC_CITIES = 'GET_PUBLIC_CITIES';
export const GET_PRINTED_TICKET = 'GET_PRINTED_TICKET';
export const CLEAR_PRINTED_TICKETS = 'CLEAR_PRINTED_TICKETS';
export const CLEAR_ITINERARY_FOR_BOOKING = 'CLEAR_ITINERARY_FOR_BOOKING';
export const CLEAR_SEATS_FOR_BOOKING = 'CLEAR_SEATS_FOR_BOOKING';
export const CLEAR_SELECT_ITINERARY = 'CLEAR_SELECT_ITINERARY';

// flags
export const FLAG_FETCHING_CITIES = 'FLAG_FETCHING_CITIES';
export const FLAG_GETTING_PUBLIC_CITIES = 'FLAG_GETTING_PUBLIC_CITIES';
export const FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS =
  'FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS';
export const FLAG_POSTING_RESERVATION = 'FLAG_POSTING_RESERVATION';
export const FLAG_RESERVING = 'FLAG_RESERVING';
export const FLAG_SEARCHING_FOR_ITINERARIES = 'FLAG_SEARCHING_FOR_ITINERARIES';

// customerOrder
export const FLAG_DELETING_CUSTOMER_ORDER = 'FLAG_DELETING_CUSTOMER_ORDER';
export const DELETE_CUSTOMER_ORDER = 'DELETE_CUSTOMER_ORDER';

// payment
export const FLAG_POSTING_PAYMENT = 'FLAG_POSTING_PAYMENT';
export const POST_PAYMENT = 'POST_PAYMENT';

// food
export const GET_INTINERARY_FOOD_GROUP = 'GET_INTINERARY_FOOD_GROUP';
export const CLEAR_INTINERARY_FOOD_GROUP = 'CLEAR_INTINERARY_FOOD_GROUP';

export const FLAG_GETTING_PRINTED_TICKET = 'FLAG_GETTING_PRINTED_TICKET';

export const APPLY_CREDIT_NOTE = 'APPLY_CREDIT_NOTE';
export const CANCEL_CREDIT_NOTE = 'CANCEL_CREDIT_NOTE';

// fixed price
export const SET_FIXED_PRICE_FOR_CURRENT_BOOKING =
  'SET_FIXED_PRICE_FOR_CURRENT_BOOKING';
export const FLAG_POSTING_FIX_PRICE_REQUEST = 'FLAG_POSTING_FIX_PRICE_REQUEST';

export * from './PostpaidBooking';
