import Immutable from 'immutable';
import {
  FLAG_ACTIVITY_TASK_EXECUTION_ACTIVITY,
  CLEAR_ACTIVITY_TASK_EXECUTION,
  GET_ACTIVITY_TASK_EXECUTION,
} from '../../../actions/types/mechanical-maintenance';

const INITIAL_STATE = Immutable.Map({
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_ACTIVITY_TASK_EXECUTION_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_ACTIVITY_TASK_EXECUTION:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_ACTIVITY_TASK_EXECUTION:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    default:
      return state;
  }
};
