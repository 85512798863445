import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getPurchaseOrders,
  clearPurchaseOrders,
} from '../../../../actions/index';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { PURCHASE_ORDER_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { PURCHASE_ORDER_ENDPOINT } from '../../../../config/endpoints';
import {
  PURCHASE_ORDER_PATH,
  NEW_PURCHASE_ORDER_PATH,
} from '../../../../config/paths';

class PurchaseOrders extends Component {
  componentWillUnmount() {
    const { dispatchClearPurchaseOrders } = this.props;
    dispatchClearPurchaseOrders();
  }

  render() {
    const { purchaseOrders, loading, dispatchGetPurchaseOrders, breadcrumbs } =
      this.props;

    const data = purchaseOrders.get('content') || [];
    const pages = purchaseOrders.get('totalPages') || null;
    const defaultPageSize = purchaseOrders.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Ordenes de Compra"
        buttonPath={NEW_PURCHASE_ORDER_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: PURCHASE_ORDER_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetPurchaseOrders,
          loading,
          modelPath: PURCHASE_ORDER_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Compra',
      href: PURCHASE_ORDER_ENDPOINT,
    },
  ],
  purchaseOrders: TrafficUnit.PurchaseOrder.getIn(['all', 'content']),
  loading: TrafficUnit.PurchaseOrder.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetPurchaseOrders: getPurchaseOrders,
  dispatchClearPurchaseOrders: clearPurchaseOrders,
};

PurchaseOrders.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetPurchaseOrders: PropTypes.func.isRequired,
  dispatchClearPurchaseOrders: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  purchaseOrders: PropTypes.instanceOf(Immutable.Map),
};

PurchaseOrders.defaultProps = {
  purchaseOrders: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrders);
