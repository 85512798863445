import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import Baggage from './Baggage';
import ItemCategory from './ItemCategory';
import BaggageConfiguration from './BaggageConfiguration';

export default combineReducers({
  UnitHome,
  Baggage,
  ItemCategory,
  BaggageConfiguration,
});
