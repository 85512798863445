import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import {
  FLAG_GETTING_LIQUIDATION_DIFFERENCE_REASONS,
  GET_LIQUIDATION_DIFFERENCE_REASONS,
  CLEAR_LIQUIDATION_DIFFERENCE_REASONS,
} from '../types';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { LIQUIDATION_DIFFERENCE_REASON_ENDPONT } from '../../config/endpoints';

const flagGettingLiquidationDifferenceReasons = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_LIQUIDATION_DIFFERENCE_REASONS,
    payload: flag,
  });

const getLiquidationDifferenceReasons = async filters => async dispatch => {
  try {
    dispatch(flagGettingLiquidationDifferenceReasons(true));
    const query = {
      ...filters,
    };
    const url = `${LIQUIDATION_DIFFERENCE_REASON_ENDPONT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    // check if it is an error response
    await isErrorResponse(response);
    const liquidationDifferenceReasons = await response.json();
    dispatch({
      type: GET_LIQUIDATION_DIFFERENCE_REASONS,
      payload: liquidationDifferenceReasons,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingLiquidationDifferenceReasons(false));
  }
};

const clearLiquidationDifferenceReasons = () => dispatch =>
  dispatch({
    type: CLEAR_LIQUIDATION_DIFFERENCE_REASONS,
  });

export { getLiquidationDifferenceReasons, clearLiquidationDifferenceReasons };
