import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getEvents, clearEvents } from '../../../../actions/system/';
import { EVENT_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { EVENTS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Events extends Component {
  constructor(props) {
    super(props);
    this.columns = EVENTS_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearEvents();
  }

  render() {
    const { events, loading, dispatchGetEvents, breadcrumbs } = this.props;
    const data = events.get('content') || [];
    const pages = events.get('totalPages') || null;
    const defaultPageSize = events.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Eventos"
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: false,
          fetchData: dispatchGetEvents,
          modelPath: EVENT_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetEvents: getEvents,
  dispatchClearEvents: clearEvents,
};

const mapStateToProps = ({ SystemUnit }) => ({
  breadcrumbs: [
    ...SystemUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Eventos',
      href: EVENT_PATH,
    },
  ],
  events: SystemUnit.Event.getIn(['all', 'content']),
  loading: SystemUnit.Event.getIn(['all', 'loading']),
});

Events.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  events: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetEvents: PropTypes.func.isRequired,
  dispatchClearEvents: PropTypes.func.isRequired,
};

Events.defaultProps = {
  events: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
