import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { createElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Table from '../../../common/Table';
import {
  clearParcelsToPickup,
  getDetailFromParcel,
  getParcelsToPickup,
} from '../../../../actions/cargo/CargoPickup';
import CargoPickupDetail from './CargoPickupDetail';
import { CARGO_ITEMS_TO_PICKUP_COLUMNS } from '../../../../config/columns';

export const CargoPickupResults = ({
  searchFormValues,
  columns,
  loading,
  parcels,
  dispatchGetParcelsToPickup,
  dispatchClearParcelsToPickup,
}) => {
  useEffect(
    () => () => {
      dispatchClearParcelsToPickup();
    },
    [],
  );

  const renderItineraryManifestDetailComponent = ({
    original: { id: parcelId, internal },
  }) => {
    const component = createElement(CargoPickupDetail, {
      columns: CARGO_ITEMS_TO_PICKUP_COLUMNS,
      searchValues: { parcelId, internal },
    });

    return <div className="mt-3 mb-2 ml-5 mr-5">{component}</div>;
  };

  const data = parcels.get('content') || [];
  const pages = parcels.get('totalPages') || null;
  const defaultPageSize = parcels.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <div className="mt-3">
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        loading={loading}
        fetchData={dispatchGetParcelsToPickup}
        rowComponent={renderItineraryManifestDetailComponent}
        params={searchFormValues}
      />
    </div>
  );
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: CargoUnit.CargoPickup.getIn(['all', 'loading']),
  parcels: CargoUnit.CargoPickup.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetParcelsToPickup: getParcelsToPickup,
  dispatchClearParcelsToPickup: clearParcelsToPickup,
  dispatchGetDetailFromParcel: getDetailFromParcel,
};

CargoPickupResults.propTypes = {
  parcels: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  columns: PropTypes.instanceOf(Array).isRequired,
  searchFormValues: PropTypes.shape({
    itineraryId: PropTypes.number,
  }).isRequired,
  dispatchClearParcelsToPickup: PropTypes.func.isRequired,
  dispatchGetParcelsToPickup: PropTypes.func.isRequired,
};

CargoPickupResults.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoPickupResults);
