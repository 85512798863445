import React, { useLayoutEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import { getUniqueLocations } from '../../unloading-cargo-from-itinerary/UnloadingCargoForm';
import { MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS } from '../../../../../config/columns';
import { NO_MANIFEST_CARGO_ITEM_TO_ITINERARY } from '../../../../../config/messages';
import {
  clearManifestItems,
  putAddItemToItineraryV2,
  updateManifestItems,
  updateManifestItemsByParcelCorrelation,
} from '../../../../../actions';
import Alert from '../../../../common/informative/Alert';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import Loader from '../../../../common/Loader';
import AddCargoToItineraryModalForm from './AddCargoToItineraryModalForm';
import Modal from '../../../../common/modal/Modal';
import AddCargoToItineraryToolbar from './AddCargoToItineraryToolbar';

const AddCargoToItinerary = ({
  handleSubmit,
  items,
  loading,
  itinerary,
  itineraryCounter,
  sourceLocationId,
  dispatchClearManifestItem,
  dispatchUpdateManifestItems,
  dispatchPutAddItemToItineraryV2,
  dispatchUpdateManifestItemsByParcelCorrelation,
}) => {
  const [show, setShow] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  useLayoutEffect(
    () => () => {
      dispatchClearManifestItem();
    },
    [],
  );

  const handleAddItemToItinerary = async (cargoItemList) => {
    const added = await dispatchPutAddItemToItineraryV2({
      cargoItemList,
      itineraryId: itinerary,
      sourceLocationId,
      exitDate: itineraryCounter.get('departureTime'),
    });

    if (added && added.length) {
      const updatedItemsWithNewValues = added.map((addedItem) => {
        const updatedItem = items.find(
          (item) => item.correlation === addedItem.correlation,
        );
        return {
          ...updatedItem,
          itineraryId: itinerary,
          locationId: null,
        };
      });

      dispatchUpdateManifestItems(updatedItemsWithNewValues);
      dispatchUpdateManifestItemsByParcelCorrelation(updatedItemsWithNewValues);
    }
  };

  const handleSubmitModal = (values) => {
    const updatedItems = items
      .map((item) => {
        const destinationField =
          values[`destinationLocationId-${item.correlation}`];

        if (destinationField) {
          return {
            ...item,
            destinationLocationId: destinationField.value,
          };
        }

        return null;
      })
      .filter((item) => item !== null);

    if (!updatedItems.isEmpty()) {
      handleAddItemToItinerary(updatedItems);
    }

    setShow(false);
  };

  const convertLocationToOption = () => {
    const locationsArray = [];

    getUniqueLocations(itineraryCounter, true).forEach((location) => {
      locationsArray.push({
        value: location.id,
        label: location.name,
      });
    });

    return locationsArray;
  };

  const onClickAddItemToItinerary = ({ itemsToAddCorrelations }) => {
    if (!itemsToAddCorrelations || !itemsToAddCorrelations.length) {
      return;
    }
    const filteredItems = items.filter(
      (item) =>
        itemsToAddCorrelations.includes(item.correlation) && !item.itineraryId,
    );

    const uniqueLocations = getUniqueLocations(itineraryCounter, true);

    const matchingItems = filteredItems.filter((item) =>
      uniqueLocations.find((obj) => obj.id === item.destinationLocationId),
    );

    const nonMatchingItems = filteredItems.filter(
      (item) =>
        !uniqueLocations.find((obj) => obj.id === item.destinationLocationId),
    );

    if (!matchingItems.isEmpty()) {
      handleAddItemToItinerary(matchingItems);
    }

    if (!nonMatchingItems.isEmpty()) {
      toastr.warning(
        'Advertencia',
        'Estas encomiendas necesitan que se les asigne un destino',
      );
      setModalBody(
        <AddCargoToItineraryModalForm
          items={nonMatchingItems}
          options={convertLocationToOption()}
          onSubmit={handleSubmitModal}
        />,
      );
      setShow(true);
    }
  };

  if (items.size === 0) {
    if (loading) {
      return <Loader />;
    }
    return (
      <Alert type="warning" message={NO_MANIFEST_CARGO_ITEM_TO_ITINERARY} />
    );
  }

  const toolbar = !itineraryCounter.isEmpty() ? (
    <AddCargoToItineraryToolbar
      onClickAddItemToItinerary={onClickAddItemToItinerary}
    />
  ) : null;
  const data = items.toJS();
  const defaultPageSize =
    items.size > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : items.size;

  return (
    <>
      <Form onSubmit={handleSubmit(onClickAddItemToItinerary)}>
        <div className="mt-3">
          {toolbar}
          <div className="flex">
            <h4>Selecione las encomiendas a subir</h4>
          </div>
          <SelectionableTable
            columns={MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS}
            data={data}
            name="itemsToAddCorrelations"
            defaultPageSize={defaultPageSize}
            returnOnlySelectedItems
            keyField="correlation"
            loading={loading}
            form="AddCargoToItineraryForm"
          />
        </div>
      </Form>
      <Modal
        show={show}
        title="Cambiar destino de las encomiendas"
        body={modalBody}
        onClickClose={() => setShow(false)}
        size="lg"
      />
    </>
  );
};

AddCargoToItinerary.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  itinerary: PropTypes.number.isRequired,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
  sourceLocationId: PropTypes.number.isRequired,
  dispatchClearManifestItem: PropTypes.func.isRequired,
  dispatchUpdateManifestItems: PropTypes.func.isRequired,
  dispatchPutAddItemToItineraryV2: PropTypes.func.isRequired,
  dispatchUpdateManifestItemsByParcelCorrelation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit, authentication, ItineraryUnit }) => ({
  items: CargoUnit.ManifestCargo.getIn(['all', 'content']),
  loading: CargoUnit.ManifestCargo.getIn(['all', 'loading']),
  user: authentication.get('user') || undefined,
  itineraryCounter:
    ItineraryUnit.Itinerary.getIn(['current', 'content']) || undefined,
});

const mapDispatchToProps = {
  dispatchPutAddItemToItineraryV2: putAddItemToItineraryV2,
  dispatchUpdateManifestItems: updateManifestItems,
  dispatchUpdateManifestItemsByParcelCorrelation:
    updateManifestItemsByParcelCorrelation,
  dispatchClearManifestItem: clearManifestItems,
};

const formComponent = reduxForm({
  form: 'AddCargoToItineraryForm',
})(AddCargoToItinerary);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
