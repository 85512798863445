import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Breadcrumbs from './Breadcrumbs';
import { breadcrumbsPropTypes } from '../common/resource/proptypes/CommonPropTypes';

// TODO test
const Content = ({ breadcrumbs, toolbar, title, subtitle, content }) => {
  const header = title ? (
    <div className="border-bottom mb-3">
      {toolbar}
      <h1>{title}</h1>
      {subtitle ? <div className="mb-2">{subtitle}</div> : null}
    </div>
  ) : null;
  return (
    <Container fluid>
      {breadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
      {header}
      {content}
    </Container>
  );
};

Content.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  toolbar: PropTypes.element,
  content: PropTypes.element,
};

Content.defaultProps = {
  breadcrumbs: null,
  content: null,
  toolbar: null,
  title: null,
  subtitle: null,
};

export default Content;
