import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  getTicketActionModal,
  postPostponeTicket,
} from '../../../../../actions/index';
import {
  generateTicketPostponeEndpoint,
  TICKET_POSTPONE_MODAL_ENDPOINT,
} from '../../../../../config/endpoints';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { POST } from '../../../../../config/permissions';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import { MODAL_ACTION_CONFIRM_MESSAGE } from '../../../../../config/messages';

const PostponeTicketButton = ({
  dispatchPostPostponeTicket,
  ticketId,
  forThirdParty,
  dispatchGetTicketActionModal,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(MODAL_ACTION_CONFIRM_MESSAGE);
  const [url, setUrl] = useState(null);

  const handleShowModal = async () => {
    setLoading(true);

    const response = await dispatchGetTicketActionModal({
      ticketId,
      url: TICKET_POSTPONE_MODAL_ENDPOINT,
    });

    if (response && response.message && response.url) {
      setMessage(response.message);
      setUrl(response.url);
      setShowConfirmationModal(true);
    }

    setLoading(false);
  };

  const handleCloseModal = () => setShowConfirmationModal(false);

  const handlePostponeTicket = () =>
    dispatchPostPostponeTicket(url, ticketId, forThirdParty);

  return (
    <Fragment>
      <Button
        color="light"
        className="border"
        onClick={handleShowModal}
        disabled={loading}
      >
        <i className={`fa fa-${loading ? 'spinner fa-spin' : 'calendar'}`} />{' '}
        Postergar
      </Button>
      <ConfirmationModal
        show={showConfirmationModal}
        title="Confirmación de Postergación"
        body={message}
        onClickConfirm={handlePostponeTicket}
        onClickCancel={handleCloseModal}
        cancelButtonColor="primary"
      />
    </Fragment>
  );
};

PostponeTicketButton.propTypes = {
  dispatchPostPostponeTicket: PropTypes.func.isRequired,
  ticketId: PropTypes.number.isRequired,
  forThirdParty: PropTypes.bool,
  dispatchGetTicketActionModal: PropTypes.func.isRequired,
};

PostponeTicketButton.defaultProps = {
  forThirdParty: false,
};

const mapDispatchToProps = {
  dispatchPostPostponeTicket: postPostponeTicket,
  dispatchGetTicketActionModal: getTicketActionModal,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(PostponeTicketButton);

export default withEndpointAuthorization({
  url: generateTicketPostponeEndpoint(0),
  permissionType: POST,
})(connectedComponent);
