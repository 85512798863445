import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { INTERNAL_CARGO_ITEM_CHANGE_STATUS_DROPDOWN_OPTIONS } from '../../../../config/constants';
import DropdownButton from '../../../common/button/DropdownButton';
import { changeInternalCargoItemStatus } from '../../../../actions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { PUT } from '../../../../config/permissions';
import { generatePutChangeInternalCargoItemStatus } from '../../../../config/endpoints';

const ChangeInternalCargoItemStatusDropdownButton = ({
  cargoItemId,
  dispatchChangeInternalCargoItemStatus,
}) => {
  const handleClick = async (value) => {
    const status = value;

    dispatchChangeInternalCargoItemStatus({ cargoItemId, status });
  };

  return (
    <DropdownButton
      title="Cambiar estado"
      color="secondary"
      options={INTERNAL_CARGO_ITEM_CHANGE_STATUS_DROPDOWN_OPTIONS}
      handleClick={handleClick}
    />
  );
};

ChangeInternalCargoItemStatusDropdownButton.propTypes = {
  cargoItemId: PropTypes.number,
  dispatchChangeInternalCargoItemStatus: PropTypes.func.isRequired,
};

ChangeInternalCargoItemStatusDropdownButton.defaultProps = {
  cargoItemId: 0,
};

const mapDispatchToProps = {
  dispatchChangeInternalCargoItemStatus: changeInternalCargoItemStatus,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(ChangeInternalCargoItemStatusDropdownButton);

export default withEndpointAuthorization({
  url: generatePutChangeInternalCargoItemStatus(0, ''),
  permissionType: PUT,
})(connectedComponent);
