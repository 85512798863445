import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';

const ReportToolbar = (props) => {
  const { download, disabled } = props;
  return (
    <ButtonToolbar className="justify-content-end">
      <ButtonGroup>
        <Button
          color="primary"
          onClick={download}
          disabled={disabled}
        >
          Descargar
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

ReportToolbar.propTypes = {
  download: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ReportToolbar.defaultProps = {
  disabled: false,
};

export default ReportToolbar;
