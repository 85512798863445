import React from 'react';
import { tzNormalizeDate } from '../../../../../utils/date';
import { creditNoteTransactionInformationPropTypes } from '../proptypes/CreditNotePropTypes';
import Table from '../../../../common/Table';
import { numberFormatter } from '../../../../../utils/number';
import { DATE_FORMAT } from '../../../../../config/locale';

const CreditNoteTransactionInformationResource = ({
  creditNoteTransactionList,
}) => (
  <div className="mb-3">
    <h3>Transacciones</h3>
    <Table
      columns={[
        {
          Header: 'Fecha',
          accessor: 'transaction.createDate',
          Cell: (row) => tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
          className: 'text-center',
        },
        {
          Header: 'Monto',
          accessor: 'transaction.amount',
          Cell: (row) => numberFormatter({ value: row.value }),
          className: 'text-center',
        },
      ]}
      data={creditNoteTransactionList}
      defaultPageSize={creditNoteTransactionList.length || 0}
      showPagination={false}
    />
  </div>
);

CreditNoteTransactionInformationResource.propTypes =
  creditNoteTransactionInformationPropTypes;

export default CreditNoteTransactionInformationResource;
