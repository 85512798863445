import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  NEW_BUS_ACTIVITY_ASSIGNMENT_PATH,
  BUS_ACTIVITY_ASSIGNMENT_PATH,
} from '../../../../config/paths';
import {
  getBusActivityAssignments,
  clearBusActivityAssignments,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { BUS_ACTIVITY_ASSIGNMENT_COLUMNS } from '../../../../config/columns';

export const BusActivityAssignments = ({
  breadcrumbs,
  content,
  loading,
  dispatchGetBusActivityAssignments,
  dispatchClearBusActivityAssignments,
  dispatchPush,
}) => {
  useLayoutEffect(() => () => dispatchClearBusActivityAssignments(), []);

  const generateModelPath = ({ id }) =>
    dispatchPush(`${BUS_ACTIVITY_ASSIGNMENT_PATH}/${id}/unassign`);

  const data = content.get('content') || [];
  const pages = content.get('totalPages') || null;
  const defaultPageSize = content.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Asignaciones de Actividades a Buses"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_BUS_ACTIVITY_ASSIGNMENT_PATH}
      buttonText="Asignar"
      tableStructure={{
        columns: BUS_ACTIVITY_ASSIGNMENT_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetBusActivityAssignments,
        modelPath: generateModelPath,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

BusActivityAssignments.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  content: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetBusActivityAssignments: PropTypes.func.isRequired,
  dispatchClearBusActivityAssignments: PropTypes.func.isRequired,
  dispatchPush: PropTypes.func.isRequired,
};

BusActivityAssignments.defaultProps = {
  content: null,
  loading: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Asignaciones de Actividades a Buses',
      href: BUS_ACTIVITY_ASSIGNMENT_PATH,
    },
  ],
  content: MechanicalMaintenanceUnit.BusActivityAssignment.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.BusActivityAssignment.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetBusActivityAssignments: getBusActivityAssignments,
  dispatchClearBusActivityAssignments: clearBusActivityAssignments,
  dispatchPush: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusActivityAssignments);
