import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  FUEL_VOUCHER_PATH,
  FUEL_PROFILE_PATH,
  FUEL_STATION_PATH,
  ITINERARY_PATH,
} from '../../../../config/paths';
import {
  getFuelVoucher,
  clearFuelVoucher,
  getFuelStation,
  clearFuelStation,
  getItinerary,
  clearItinerary,
  getFuelProfile,
  clearFuelProfile,
} from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { FUEL_VOUCHER_STATUS } from '../../../../config/constants';
import FuelVoucherToolbar from './FuelVoucherToolbar';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../config/locale';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import LinkToModuleResource from '../../../common/resource/LinkToModuleResource';
import { tzNormalizeDate } from '../../../../utils/date';
import EnumStatus from '../../../common/EnumStatus';
import { numberFormatter } from '../../../../utils/number';

class FuelVoucher extends Component {
  state = {
    gotFuelStation: false,
    gotItinerary: false,
    gotFuelProfile: false,
  };

  componentDidMount() {
    const {
      dispatchGetFuelVoucher,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetFuelVoucher({ id });
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    const {
      dispatchClearFuelVoucher,
      dispatchClearFuelStation,
      dispatchClearItinerary,
      dispatchClearFuelProfile,
    } = this.props;
    dispatchClearFuelVoucher();
    dispatchClearFuelStation();
    dispatchClearItinerary();
    dispatchClearFuelProfile();
  }

  onDidUpdate = () => {
    const {
      dispatchGetFuelStation,
      fuelVoucher,
      itinerary,
      fuelStation,
      dispatchGetItinerary,
      dispatchGetFuelProfile,
    } = this.props;

    const { gotFuelStation, gotItinerary, gotFuelProfile } = this.state;

    if (!gotFuelStation && !fuelVoucher.isEmpty() && fuelStation.isEmpty()) {
      dispatchGetFuelStation({ id: fuelVoucher.get('fuelStationId') });
      this.setState({ gotFuelStation: true });
    }

    if (
      !gotItinerary &&
      fuelVoucher.get('itineraryId') &&
      itinerary.isEmpty()
    ) {
      dispatchGetItinerary({ itineraryId: fuelVoucher.get('itineraryId') });
      this.setState({ gotItinerary: true });
    }

    if (
      !gotFuelProfile &&
      fuelVoucher.get('fuelProfileId') &&
      itinerary.isEmpty()
    ) {
      dispatchGetFuelProfile({ id: fuelVoucher.get('fuelProfileId') });
      this.setState({ gotFuelProfile: true });
    }
  };

  render() {
    const {
      breadcrumbs,
      fuelVoucher,
      loading,
      fuelStation,
      itinerary,
      fuelProfile,
    } = this.props;

    let content;
    let toolbar;

    if (
      loading ||
      fuelStation.isEmpty() ||
      (fuelVoucher.get('itineraryId') && itinerary.isEmpty()) ||
      (fuelVoucher.get('fuelProfileId') && fuelProfile.isEmpty())
    ) {
      content = <Loader />;
    } else if (fuelVoucher.isEmpty()) {
      content = <NoDataResource returnPage={FUEL_VOUCHER_PATH} />;
    } else {
      const fuelStationJson = fuelStation.toJS();
      const fuelVoucherJson = fuelVoucher.toJS();
      const itineraryJson = itinerary.toJS();
      const fuelProfileJson = fuelProfile.toJS();

      const itineraryLink = fuelVoucherJson.itineraryId && (
        <LinkToModuleResource
          text={`${fuelVoucherJson.itineraryId} - ${
            itineraryJson.route.name
          } ${tzNormalizeDate({
            date: itineraryJson.departureTime,
            format: DATE_TIME_FORMAT,
          })}`}
          href={`${ITINERARY_PATH}/${fuelVoucherJson.itineraryId}`}
        />
      );

      const fuelProfileLink = fuelVoucherJson.fuelProfileId && (
        <LinkToModuleResource
          text={`${fuelProfileJson.name}`}
          href={`${FUEL_PROFILE_PATH}/${fuelVoucherJson.fuelProfileId}`}
        >
          {fuelVoucherJson.fuelProfileId}
        </LinkToModuleResource>
      );

      const fuelStationLink = fuelVoucherJson.fuelStationId && (
        <LinkToModuleResource
          text={`${fuelVoucherJson.fuelStationId} - ${fuelStationJson.location.name}`}
          href={`${FUEL_STATION_PATH}/${fuelVoucherJson.fuelStationId}`}
        />
      );

      if (
        fuelVoucherJson.fuelVoucherStatus === FUEL_VOUCHER_STATUS.CREATED.value
      ) {
        toolbar = <FuelVoucherToolbar fuelVoucherId={fuelVoucherJson.id} />;
      }

      content = (
        <Fragment>
          <ResourceProperty label="Serie:">
            {fuelVoucherJson.documentSeries}
          </ResourceProperty>
          <ResourceProperty label="Correlativo:">
            {fuelVoucherJson.documentCode}
          </ResourceProperty>
          <ResourceProperty label="Itinerario:">
            {itineraryLink || '-'}
          </ResourceProperty>
          <ResourceProperty label="Válido Desde:">
            {tzNormalizeDate({
              date: fuelVoucherJson.validFrom,
              format: DATE_FORMAT,
            })}
          </ResourceProperty>
          <ResourceProperty label="Válido Hasta:">
            {tzNormalizeDate({
              date: fuelVoucherJson.validTo,
              format: DATE_FORMAT,
            })}
          </ResourceProperty>
          <ResourceProperty label="Número de Bus:">
            {fuelVoucherJson.companyBusId}
          </ResourceProperty>
          <ResourceProperty label="Monto:">
            {fuelVoucherJson.amount
              ? numberFormatter({ value: fuelVoucherJson.amount })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Beneficiario:">
            {fuelVoucherJson.fuelReceiverName}
          </ResourceProperty>
          <ResourceProperty label="Galones:">
            {fuelVoucherJson.numberOfGallons}
          </ResourceProperty>
          <ResourceProperty label="Galones Reales:">
            {fuelVoucherJson.realNumberOfGallons}
          </ResourceProperty>
          <ResourceProperty label="Precio por Galón:">
            {fuelVoucherJson.pricePerGallon
              ? numberFormatter({ value: fuelVoucherJson.pricePerGallon })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Perfil de Combustible:">
            {fuelProfileLink || '-'}
          </ResourceProperty>
          <ResourceProperty label="Grifo:">
            {fuelStationLink || '-'}
          </ResourceProperty>
          <ResourceProperty label="Válido:">
            {fuelVoucherJson.isValid ? 'Si' : 'No'}
          </ResourceProperty>
          <ResourceProperty label="Pagado:">
            {fuelVoucherJson.isSettled ? 'Si' : 'No'}
          </ResourceProperty>
          <ResourceProperty label="Abastecido:">
            {fuelVoucherJson.redeemed ? 'Si' : 'No'}
          </ResourceProperty>
          <ResourceProperty label="Tipo:">
            {fuelVoucherJson.extraordinary ? 'Extraordinario' : 'Regular'}
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            <EnumStatus
              enumObject={FUEL_VOUCHER_STATUS}
              enumValue={fuelVoucherJson.fuelVoucherStatus}
            />
          </ResourceProperty>
          <ResourceProperty label="Comentarios:">
            {fuelVoucherJson.comments}
          </ResourceProperty>
          <InternalResource
            id={fuelVoucherJson.id}
            createDate={fuelVoucherJson.createDate}
            lastUpdate={fuelVoucherJson.lastUpdate}
          />
        </Fragment>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Vale de Combustible"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

FuelVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetFuelVoucher: PropTypes.func.isRequired,
  dispatchClearFuelVoucher: PropTypes.func.isRequired,
  dispatchGetFuelStation: PropTypes.func.isRequired,
  dispatchClearFuelStation: PropTypes.func.isRequired,
  dispatchGetItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  dispatchGetFuelProfile: PropTypes.func.isRequired,
  dispatchClearFuelProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  fuelVoucher: PropTypes.instanceOf(Immutable.Map).isRequired,
  fuelStation: PropTypes.instanceOf(Immutable.Map).isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  fuelProfile: PropTypes.instanceOf(Immutable.Map).isRequired,
};

FuelVoucher.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ TrafficUnit, RouteUnit, ItineraryUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Vales de Combustible',
      href: FUEL_VOUCHER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  fuelVoucher: TrafficUnit.FuelVoucher.getIn(['current', 'content']),
  loading: !TrafficUnit.FuelVoucher.getIn(['current', 'activity']).isEmpty(),
  fuelStation: RouteUnit.FuelStation.getIn(['current', 'content']),
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  fuelProfile: TrafficUnit.FuelProfile.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchGetFuelVoucher: getFuelVoucher,
  dispatchClearFuelVoucher: clearFuelVoucher,
  dispatchGetFuelStation: getFuelStation,
  dispatchClearFuelStation: clearFuelStation,
  dispatchGetItinerary: getItinerary,
  dispatchClearItinerary: clearItinerary,
  dispatchGetFuelProfile: getFuelProfile,
  dispatchClearFuelProfile: clearFuelProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelVoucher);
