/* eslint-disable no-param-reassign */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, InputGroupText, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import {
  isRequired,
  validateEndDate,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import DatePicker from '../../../common/forms/input/DatePicker';
import {
  MANUAL_VOUCHER_SERVICE_TYPE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  CURRENCY_CODE,
  CONTRACT_DOCUMENT_TYPE,
  SUNAT_CREDIT_NOTE_CODE,
  SUNAT_DEBIT_NOTE_CODE,
  SUNAT_TICKET_CODE,
  SUNAT_INVOICE_CODE,
} from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import Select from '../../../common/forms/select/Select';
import DynamicForm from '../../../common/forms/DynamicForm';
import { generateManualVoucherFormColumns } from '../../../../config/dynamicFormFields';
import { currencyToNumber, numberFormatter } from '../../../../utils/number';
import {
  optionPropTypes,
  optionsPropTypes,
} from '../../../common/forms/select/SelectPropTypes';
import PrivateServiceSearchButton from '../../../common/button/PrivateServiceSearchButton';
import ManualVoucherSearchInput from '../../../common/forms/input/ManualVoucherSearchInput';
import FormFooter from '../../../common/forms/FormFooter';
import { tzNormalizeDate } from '../../../../utils/date';
import { clearCargoCorporateCreditManualVoucherValues } from '../../../../actions/cargo/CargoCorporateCredit';

const selector = formValueSelector('ManualVoucherForm');

const validateFields = (values) => {
  const errors = {};

  if (!values.items || !values.items.length)
    errors.itemsError = 'Debe ingresar un item';

  return errors;
};

const ManualVoucherForm = ({
  loading,
  handleSubmit,
  dispatchChange,
  items,
  totalAmount,
  detraction,
  initialValues,
  voucherTypeOptions,
  cargoCorporateCreditValues,
  dispatchClearCargoCorporateCreditManualVoucherValues,
}) => {
  const [isPerson, setIsPerson] = useState(
    initialValues ? initialValues.isPerson : false,
  );
  const [hasCreditPayment, setHasCreditPayment] = useState(
    initialValues.paymentType.value ===
      MANUAL_VOUCHER_PAYMENT_TYPE.CREDIT.value,
  );
  const [
    flagCalculateTotalPriceAndAmount,
    setFlagCalculateTotalPriceAndAmount,
  ] = useState(false);
  const [affectedByTaxes, setAffectedByTaxes] = useState(false);
  const [dueDateValue, setDueDateValue] = useState(initialValues.dueDate);
  const [currencyCodeValue, setCurrencyCodeValue] = useState(
    initialValues.currencyCode.value,
  );
  const [voucherCodeValue, setVoucherCodeValue] = useState(
    initialValues.voucherTypeId && initialValues.voucherTypeId.voucherCode,
  );

  const calculateTotalToPay = (currentTotalAmount, detractionPercent) => {
    const newTotalAmount = currencyToNumber(currentTotalAmount);

    if (newTotalAmount >= 0) {
      const detractionAmount = newTotalAmount * (detractionPercent / 100);
      dispatchChange(
        'ManualVoucherForm',
        'detractionAmount',
        numberFormatter({
          style: 'currency',
          value: detractionAmount,
          currency: currencyCodeValue,
        }),
      );

      const totalAmountPayable = newTotalAmount - detractionAmount;
      dispatchChange(
        'ManualVoucherForm',
        'totalAmountPayable',
        numberFormatter({
          style: 'currency',
          value: totalAmountPayable,
          currency: currencyCodeValue,
        }),
      );
    }
  };

  const calculateTotalAmount = () => {
    let totalAmountWithoutTaxes = 0;

    const newItems = items.map((item) => {
      const totalPrice = (+item.quantity || 0) * (+item.unitaryPrice || 0);
      totalAmountWithoutTaxes += totalPrice;
      return {
        ...item,
        totalPrice: numberFormatter({
          style: 'currency',
          value: totalPrice,
          currency: currencyCodeValue,
        }),
      };
    });

    dispatchChange('ManualVoucherForm', 'items', newItems);
    dispatchChange(
      'ManualVoucherForm',
      'totalAmountWithoutTaxes',
      numberFormatter({
        style: 'currency',
        value: totalAmountWithoutTaxes,
        currency: currencyCodeValue,
      }),
    );

    let taxes = 0;
    let newTotalAmount = totalAmountWithoutTaxes;
    if (affectedByTaxes) {
      taxes = totalAmountWithoutTaxes * 0.18;
      newTotalAmount = +totalAmountWithoutTaxes + +taxes;
    }
    dispatchChange(
      'ManualVoucherForm',
      'taxes',
      numberFormatter({
        style: 'currency',
        value: taxes,
        currency: currencyCodeValue,
      }),
    );
    dispatchChange(
      'ManualVoucherForm',
      'totalAmount',
      numberFormatter({
        style: 'currency',
        value: newTotalAmount,
        currency: currencyCodeValue,
      }),
    );

    dispatchChange(
      'ManualVoucherForm',
      'totalAmountPayable',
      numberFormatter({
        style: 'currency',
        value: newTotalAmount,
        currency: currencyCodeValue,
      }),
    );
    calculateTotalToPay(newTotalAmount, detraction);

    setFlagCalculateTotalPriceAndAmount(false);
  };

  useLayoutEffect(
    () => () => {
      dispatchClearCargoCorporateCreditManualVoucherValues();
    },
    [],
  );

  useEffect(() => {
    if (flagCalculateTotalPriceAndAmount) {
      calculateTotalAmount();
    }
  }, [flagCalculateTotalPriceAndAmount]);

  const activateCalculateTotal = () =>
    setFlagCalculateTotalPriceAndAmount(true);

  useEffect(() => {
    if (!cargoCorporateCreditValues.isEmpty()) {
      const {
        business,
        voucherType,
        items: cargoItems,
      } = cargoCorporateCreditValues.toJS();
      const businessId = {
        value: business.id,
        label: `${business.businessTaxId} - ${business.name}`,
        businessTaxId: business.businessTaxId,
        name: business.name,
        email: business.email,
        primaryContact: business.primaryContact,
        phone: business.phone,
        fax: business.fax,
        addressSummary: business.addressSummary,
        addressId: business.addressId,
        address: business.address,
      };
      const voucherTypeId = {
        value: voucherType.id,
        label: voucherType.name,
        voucherCode: voucherType.voucherCode,
      };
      initialValues.businessId = businessId;
      initialValues.voucherTypeId = voucherTypeId;
      initialValues.serviceType = MANUAL_VOUCHER_SERVICE_TYPE.PARCEL;
      initialValues.affectedByTaxes = true;
      setAffectedByTaxes(true);
      setVoucherCodeValue(voucherType.voucherCode);
      dispatchChange('ManualVoucherForm', 'items', cargoItems);
      activateCalculateTotal();
    }
  }, [cargoCorporateCreditValues]);

  const onChangeDetraction = ({ target: { value } }) =>
    calculateTotalToPay(totalAmount, value);

  const onChangeIsPerson = ({ target: { checked } }) => {
    dispatchChange('ManualVoucherForm', 'businessId', null);
    dispatchChange('ManualVoucherForm', 'customerId', null);
    setIsPerson(checked);
  };

  const onChangeIssueDate = (e, value) => {
    setDueDateValue(value);
    if (!hasCreditPayment)
      dispatchChange('ManualVoucherForm', 'dueDate', value);
  };

  const onChangeAffectedByTaxes = ({ target: { checked } }) => {
    setAffectedByTaxes(checked);
    activateCalculateTotal();
  };

  const onChangeCurrencyCode = (option) => {
    setCurrencyCodeValue(option.value);
    activateCalculateTotal();
  };

  const onChangePaymentType = (option) => {
    if (option.value === MANUAL_VOUCHER_PAYMENT_TYPE.CASH.value)
      dispatchChange('ManualVoucherForm', 'dueDate', dueDateValue);
    dispatchChange('ManualVoucherForm', 'installments', null);

    setHasCreditPayment(
      option.value === MANUAL_VOUCHER_PAYMENT_TYPE.CREDIT.value,
    );
  };

  const onChangeVoucherType = (option) =>
    setVoucherCodeValue(option.voucherCode);

  const onClickRemoveItem = (index, fields) => {
    fields.remove(index);
    activateCalculateTotal();
  };

  const resetForm = (data = null) => {
    const today = tzNormalizeDate();

    dispatchChange('ManualVoucherForm', 'isPerson', false);
    onChangeIsPerson({ target: { checked: false } });
    dispatchChange('ManualVoucherForm', 'voucherTypeId', null);
    dispatchChange('ManualVoucherForm', 'documentSeries', null);
    dispatchChange('ManualVoucherForm', 'documentCode', null);
    dispatchChange(
      'ManualVoucherForm',
      'serviceType',
      MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE,
    );
    dispatchChange('ManualVoucherForm', 'issueDate', today);
    dispatchChange('ManualVoucherForm', 'affectedByTaxes', null);
    dispatchChange('ManualVoucherForm', 'currencyCode', CURRENCY_CODE.PEN);
    onChangeCurrencyCode(CURRENCY_CODE.PEN);
    dispatchChange('ManualVoucherForm', 'description', null);
    dispatchChange(
      'ManualVoucherForm',
      'paymentType',
      MANUAL_VOUCHER_PAYMENT_TYPE.CASH,
    );
    onChangePaymentType(MANUAL_VOUCHER_PAYMENT_TYPE.CASH);
    dispatchChange('ManualVoucherForm', 'dueDate', today);
    dispatchChange('ManualVoucherForm', 'itemsError', null);
    dispatchChange('ManualVoucherForm', 'items', []);
    dispatchChange('ManualVoucherForm', 'detraction', null);

    if (data) {
      const {
        business,
        customer,
        voucherTypeId,
        serviceType,
        affectedByTaxes: currentAffectedByTaxes,
        currencyCode,
        paymentType,
        installments,
        items: currentItems,
        detraction: currentDetraction,
      } = data;

      // Setting contractor data
      const businessId = business && {
        value: business.id,
        label: `${business.businessTaxId} - ${business.name}`,
        businessTaxId: business.businessTaxId,
        name: business.name,
        email: business.email,
        primaryContact: business.primaryContact,
        phone: business.phone,
        fax: business.fax,
        addressSummary: business.addressSummary,
        addressId: business.addressId,
        address: business.address,
      };

      const customerId = customer && {
        value: customer.id,
        label: customer.fullName,
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: customer.fullName,
        idDocumentNumber: customer.idDocumentNumber,
        gender: customer.gender,
        idCountryOfOrigin: customer.idCountryOfOrigin,
        identificationType: customer.identificationType,
        email: customer.email,
        mobilePhone: customer.mobilePhone,
        dob: customer.dob,
        discountCode: customer.discountCode,
      };

      const newIsPerson = !!customer;
      dispatchChange('ManualVoucherForm', 'isPerson', newIsPerson);
      onChangeIsPerson({ target: { checked: newIsPerson } });
      if (customerId)
        dispatchChange('ManualVoucherForm', 'customerId', customerId);
      if (businessId)
        dispatchChange('ManualVoucherForm', 'businessId', businessId);

      // Setting voucher data
      dispatchChange('ManualVoucherForm', 'voucherTypeId', voucherTypeId);
      onChangeVoucherType(voucherTypeId);
      dispatchChange(
        'ManualVoucherForm',
        'serviceType',
        MANUAL_VOUCHER_SERVICE_TYPE[serviceType],
      );

      // Setting payment data
      dispatchChange(
        'ManualVoucherForm',
        'affectedByTaxes',
        currentAffectedByTaxes,
      );
      onChangeAffectedByTaxes({ target: { checked: currentAffectedByTaxes } });
      const currencyCodeSelected = CURRENCY_CODE[currencyCode];
      dispatchChange('ManualVoucherForm', 'currencyCode', currencyCodeSelected);
      onChangeCurrencyCode(currencyCodeSelected);
      const paymentTypeSelected = MANUAL_VOUCHER_PAYMENT_TYPE[paymentType];
      dispatchChange('ManualVoucherForm', 'paymentType', paymentTypeSelected);
      onChangePaymentType(paymentTypeSelected);
      dispatchChange('ManualVoucherForm', 'installments', installments);

      // Setting items data
      dispatchChange('ManualVoucherForm', 'items', currentItems);
      dispatchChange('ManualVoucherForm', 'detraction', currentDetraction);
      if (currentDetraction)
        onChangeDetraction({ target: { value: currentDetraction } });
    }
  };

  const onSelectManualVoucher = ({
    business,
    customer,
    serviceType,
    paymentType,
    installments,
    currencyCode,
    affectedByTaxes: currentAffectedByTaxes,
    items: currentItems,
    detraction: currentDetraction,
  }) => {
    const voucherTypeId = voucherTypeOptions.find(
      ({ voucherCode }) => voucherCode === SUNAT_CREDIT_NOTE_CODE,
    );

    const currencyCodeSelected = CURRENCY_CODE[currencyCode];

    const newItems = currentItems.map(
      ({
        quantity,
        description: itemDescription,
        unitaryPrice,
        totalPrice,
      }) => ({
        quantity,
        description: itemDescription,
        unitaryPrice,
        totalPrice: numberFormatter({
          style: 'currency',
          value: totalPrice,
          currency: currencyCodeSelected.value,
        }),
      }),
    );

    resetForm({
      business,
      customer,
      voucherTypeId,
      serviceType,
      currentAffectedByTaxes,
      currencyCode,
      paymentType,
      installments,
      items: newItems,
      currentDetraction,
    });
  };

  const onSelectPrivateService = ({
    contractPaymentType,
    amountBeforeDiscount,
    source,
    destination,
    creditPayment,
    business,
    customer,
  }) => {
    dispatchChange('ManualVoucherForm', 'previousManualVoucherId', null);

    // value 03 is considered as ticket
    let voucherTypeId = voucherTypeOptions.find(
      ({ voucherCode }) => voucherCode === SUNAT_TICKET_CODE,
    );

    // value 01 is considered as invoice
    if (
      contractPaymentType === CONTRACT_DOCUMENT_TYPE.FACTURA.value ||
      contractPaymentType === CONTRACT_DOCUMENT_TYPE.BOLETA_INTERNA.value
    )
      voucherTypeId = voucherTypeOptions.find(
        ({ voucherCode }) => voucherCode === SUNAT_INVOICE_CODE,
      );

    const currencyCodeSelected = CURRENCY_CODE.PEN;

    const newItems = [
      {
        quantity: 1,
        description: `Servicio de transporte de personal de ${source} a ${destination}`,
        unitaryPrice: amountBeforeDiscount,
      },
    ];

    resetForm({
      business,
      customer,
      voucherTypeId,
      serviceType: MANUAL_VOUCHER_SERVICE_TYPE.CONTRACT.value,
      affectedByTaxes: false,
      currencyCode: currencyCodeSelected.value,
      paymentType: creditPayment
        ? MANUAL_VOUCHER_PAYMENT_TYPE.CREDIT.value
        : MANUAL_VOUCHER_PAYMENT_TYPE.CASH.value,
      installments: null,
      newItems,
      detraction: null,
    });
  };

  const validateDate = (value, allValues) =>
    validateEndDate(value, allValues.issueDate);

  if (loading) return <Loader />;

  let customerField = (
    <BusinessInputGroup
      label="Empresa"
      labelRequired
      name="businessId"
      form="ManualVoucherForm"
      validate={[isRequired]}
      showDetails
    />
  );

  if (isPerson)
    customerField = (
      <>
        <CustomerInputGroup
          label="Cliente"
          labelRequired
          name="customerId"
          form="ManualVoucherForm"
          validate={[isRequired]}
          showDetails
        />
      </>
    );

  const contractorDataFields = (
    <>
      <h3>Datos del Contratante</h3>
      <FormGroup row>
        <FormItem label="">
          <Label>
            <Field
              name="isPerson"
              component="input"
              type="checkbox"
              onChange={onChangeIsPerson}
            />{' '}
            Es persona Natural
          </Label>
        </FormItem>
      </FormGroup>
      {customerField}
    </>
  );

  const baseDocumentRequired = [
    SUNAT_CREDIT_NOTE_CODE,
    SUNAT_DEBIT_NOTE_CODE,
  ].includes(voucherCodeValue);
  const baseDocumentValidation = [
    SUNAT_CREDIT_NOTE_CODE,
    SUNAT_DEBIT_NOTE_CODE,
  ].includes(voucherCodeValue)
    ? [isRequired]
    : null;
  const baseDocumentField = (
    <FormGroup row>
      <FormItem
        label="Comprobante Manual Previo"
        required={baseDocumentRequired}
      >
        <Field
          name="previousManualVoucherId"
          component={ManualVoucherSearchInput}
          placeholder="Comprobante Manual Previo"
          onSelectRow={onSelectManualVoucher}
          onClear={resetForm}
          props={{
            form: 'ManualVoucherForm',
          }}
          validate={baseDocumentValidation}
        />
      </FormItem>
    </FormGroup>
  );

  const descriptionField = [
    SUNAT_CREDIT_NOTE_CODE,
    SUNAT_DEBIT_NOTE_CODE,
  ].includes(voucherCodeValue) && (
    <FormGroup row>
      <FormItem label="Motivo o Sustento" required>
        <Field
          name="description"
          component={TextInput}
          type="textarea"
          placeholder="Motivo o Sustento"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
  );

  const voucherDataFields = (
    <>
      <h3>Datos del Comprobante</h3>
      <FormGroup row>
        <FormItem label="Tipo de Comprobante" required>
          <Field
            name="voucherTypeId"
            component={VoucherTypeSelect}
            onChange={onChangeVoucherType}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="N° Comprobante">
          <Row>
            <Col lg={3} md={4} sm={5}>
              <Field
                name="documentSeries"
                id="documentSeries"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Serie"
                disabled
              />
            </Col>
            <Col lg={5} md={7} sm={6}>
              <Field
                name="documentCode"
                id="documentCode"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Correlativo"
                disabled
                validate={[validateIntegerNumber]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Servicio" required>
          <Field
            name="serviceType"
            component={Select}
            options={enumToSelectOptions(MANUAL_VOUCHER_SERVICE_TYPE)}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de Expedición" required>
          <Field
            name="issueDate"
            component={DatePicker}
            onChange={onChangeIssueDate}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {descriptionField}
    </>
  );

  const paymentDataFields = (
    <>
      <h3>Datos del Pago</h3>
      <FormGroup row>
        <FormItem label="">
          <Label>
            <Field
              name="affectedByTaxes"
              component="input"
              type="checkbox"
              onChange={onChangeAffectedByTaxes}
            />{' '}
            Afecta a Impuestos
          </Label>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Moneda" required>
          <Field
            name="currencyCode"
            component={Select}
            options={enumToSelectOptions(CURRENCY_CODE)}
            onChange={onChangeCurrencyCode}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Método de Pago" required>
          <Row>
            <Col lg={3} md={4} sm={12}>
              <Field
                name="paymentType"
                component={Select}
                options={enumToSelectOptions(MANUAL_VOUCHER_PAYMENT_TYPE)}
                onChange={onChangePaymentType}
                isClearable={false}
                validate={[isRequired]}
              />
            </Col>
            <Col lg={2} md={3} sm={6}>
              <Field
                name="installments"
                id="installments"
                component={TextInput}
                showToolTip
                disabled={!hasCreditPayment}
                type="text"
                placeholder="Cuotas"
                validate={[validateIntegerNumber]}
              />
            </Col>
            <Col lg={4} md={5} sm={6}>
              <Field
                name="dueDate"
                id="dueDate"
                component={DatePicker}
                disabled={!hasCreditPayment}
                placeholder="Fecha de Expiración"
                showToolTip
                validate={[isRequired, validateDate]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
    </>
  );

  const detractionFields = voucherCodeValue === SUNAT_INVOICE_CODE && (
    <>
      <FormGroup row>
        <FormItem label="Porcentaje Detracción">
          <Field
            name="detraction"
            component={TextInput}
            type="text"
            placeholder="Porcentaje Detracción"
            onChange={onChangeDetraction}
            append={<InputGroupText>%</InputGroupText>}
            validate={[validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Monto Detracción">
          <Field
            name="detractionAmount"
            component={TextInput}
            type="text"
            placeholder="Monto Detracción"
            disabled
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Total a Pagar" required>
          <Field
            name="totalAmountPayable"
            component={TextInput}
            placeholder="Total a Pagar"
            type="text"
            size="lg"
            disabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    </>
  );

  const itemsDataFields = (
    <>
      <h4>Items a Facturar</h4>
      <Field name="itemsError" component={TextInput} type="hidden" />
      <DynamicForm
        name="items"
        title="Item"
        columns={generateManualVoucherFormColumns(
          activateCalculateTotal,
          activateCalculateTotal,
        )}
        onClickRemove={onClickRemoveItem}
      />
      <FormGroup row className="align-items-center">
        <FormItem label="Total Gravada" required>
          <Field
            name="totalAmountWithoutTaxes"
            component={TextInput}
            placeholder="Total Gravada"
            type="text"
            disabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Total IGV 18%">
          <Field
            name="taxes"
            component={TextInput}
            placeholder="Total IGV 18%"
            type="text"
            disabled
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Importe Total" required>
          <Field
            name="totalAmount"
            component={TextInput}
            placeholder="Importe Total"
            type="text"
            size="lg"
            disabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {detractionFields}
    </>
  );

  return (
    <>
      <div className="text-center border-bottom pb-2 mb-3">
        <PrivateServiceSearchButton
          title="Completar con datos de Contrato"
          getRowData={onSelectPrivateService}
          size="sm"
        />
      </div>
      <Form onSubmit={handleSubmit}>
        {baseDocumentField}
        {contractorDataFields}
        <hr />
        {voucherDataFields}
        <hr />
        {paymentDataFields}
        {itemsDataFields}
        <FormFooter />
      </Form>
    </>
  );
};

ManualVoucherForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
      unitaryPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  totalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  detraction: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialValues: PropTypes.shape({
    dueDate: PropTypes.string,
    currencyCode: optionPropTypes,
    paymentType: optionPropTypes,
    isPerson: PropTypes.bool,
    voucherTypeId: optionPropTypes,
    businessId: PropTypes.instanceOf(Object),
    serviceType: PropTypes.instanceOf(Object),
    items: PropTypes.instanceOf(Array),
    affectedByTaxes: PropTypes.bool,
  }),
  voucherTypeOptions: optionsPropTypes,
  cargoCorporateCreditValues: PropTypes.instanceOf(Immutable.Map),
  dispatchClearCargoCorporateCreditManualVoucherValues:
    PropTypes.func.isRequired,
};

ManualVoucherForm.defaultProps = {
  initialValues: null,
  items: [],
  totalAmount: 0,
  detraction: 0,
  voucherTypeOptions: [],
  cargoCorporateCreditValues: Immutable.Map(),
};

const mapStateToProps = (state) => ({
  loading: !state.AccountingUnit.ManualVoucher.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  items: selector(state, 'items'),
  totalAmount: selector(state, 'totalAmount'),
  detraction: selector(state, 'detraction'),
  voucherTypeOptions: state.AccountingUnit.VoucherType.getIn([
    'all',
    'content',
    'content',
  ]).map((voucherType) => ({
    value: voucherType.id,
    label: voucherType.name,
    voucherCode: voucherType.voucherCode,
  })),
  cargoCorporateCreditValues: state.CargoUnit.CargoCorporateCredit.getIn([
    'current',
    'manualVoucherValues',
  ]),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchClearCargoCorporateCreditManualVoucherValues:
    clearCargoCorporateCreditManualVoucherValues,
};

const formComponent = reduxForm({
  form: 'ManualVoucherForm',
  validate: validateFields,
})(ManualVoucherForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
