import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, FormGroup } from 'reactstrap';
import {
  flagRequiresTwoStepAuthentication,
  sendTwoStepAuthenticationRequest,
} from '../../../../actions/index';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import Modal from '../../../common/modal/Modal';
import FormFooter from '../../../common/forms/FormFooter';

class TwoStepAuthorizationModal extends Component {
  static propTypes = {
    requiresTwoStepAuthorization: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    sendingTwoStepAuthorization: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    dispatchFlagRequiresTwoStepAuthentication: PropTypes.func.isRequired,
    dispatchSendTwoStepAuthenticationRequest: PropTypes.func.isRequired,
    callback: PropTypes.func,
    method: PropTypes.string.isRequired,
  };

  static defaultProps = {
    callback: null,
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.requiresTwoStepAuthorization &&
      this.props.requiresTwoStepAuthorization
    ) {
      // reset form
      this.props.reset();
    }
  }

  closeModal = () => {
    const { dispatchFlagRequiresTwoStepAuthentication } = this.props;
    dispatchFlagRequiresTwoStepAuthentication();
  };

  handleTwoStepAuthorizationSubmit = (formValues) => {
    const { dispatchSendTwoStepAuthenticationRequest, url, callback, method } =
      this.props;
    dispatchSendTwoStepAuthenticationRequest({
      formValues,
      url,
      callback: callback || null,
      method,
    });
  };

  render() {
    const {
      requiresTwoStepAuthorization,
      handleSubmit,
      sendingTwoStepAuthorization,
    } = this.props;

    let body = null;
    if (sendingTwoStepAuthorization)
      body = (
        <div className="mb-4">
          <Loader />
        </div>
      );
    else
      body = (
        <Form onSubmit={handleSubmit(this.handleTwoStepAuthorizationSubmit)}>
          <p>Se requiere autorización:</p>
          <FormGroup row>
            <FormItem label="Usuario" required>
              <Field
                name="username"
                component={TextInput}
                type="text"
                placeholder="usuario"
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Contraseña" required>
              <Field
                name="password"
                component={TextInput}
                type="password"
                placeholder="password"
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Comentarios">
              <Field name="comments" component={TextInput} type="textarea" />
            </FormItem>
          </FormGroup>
          <FormFooter
            saveButtonColor="secondary"
            saveButtonIcon={null}
            saveButtonText="Autorizar"
          >
            <Button color="primary" onClick={this.closeModal}>
              Cancelar
            </Button>
          </FormFooter>
        </Form>
      );

    return (
      <Modal
        show={requiresTwoStepAuthorization}
        title="Autorización"
        body={body}
        size="md"
      />
    );
  }
}

const mapStateToProps = ({ TwoStepAuthorization }) => ({
  requiresTwoStepAuthorization: TwoStepAuthorization.get(
    'requiresTwoStepAuthentication',
  ),
  url: TwoStepAuthorization.get('url'),
  sendingTwoStepAuthorization: TwoStepAuthorization.get('sendingRequest'),
  callback: TwoStepAuthorization.get('callback'),
  method: TwoStepAuthorization.get('method'),
});

const mapDispatchToProps = {
  dispatchFlagRequiresTwoStepAuthentication: flagRequiresTwoStepAuthentication,
  dispatchSendTwoStepAuthenticationRequest: sendTwoStepAuthenticationRequest,
};

const formComponent = reduxForm({
  form: 'TwoStepAuthorizationForm',
})(TwoStepAuthorizationModal);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
