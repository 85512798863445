import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import CompanyUserConfigurationEditButton from './CompanyUserConfigurationEditButton';
import CompanyUserConfigurationDeleteButton from './CompanyUserConfigurationDeleteButton';

const CompanyUserConfigurationToolbar = ({ companyUserConfigurationId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <CompanyUserConfigurationEditButton
        companyUserConfigurationId={companyUserConfigurationId}
      />{' '}
      <CompanyUserConfigurationDeleteButton
        companyUserConfigurationId={companyUserConfigurationId}
      />
    </div>
  </ButtonToolbar>
);

CompanyUserConfigurationToolbar.propTypes = {
  companyUserConfigurationId: PropTypes.number.isRequired,
};

export default CompanyUserConfigurationToolbar;
