export const FLAG_GETTING_MAINTENANCE_WARNINGS =
  'FLAG_GETTING_MAINTENANCE_WARNINGS';
export const GET_MAINTENANCE_WARNINGS = 'GET_MAINTENANCE_WARNINGS';
export const CLEAR_MAINTENANCE_WARNINGS = 'CLEAR_MAINTENANCE_WARNINGS';
export const FLAG_GETTING_MAINTENANCE_WARNINGS_IN_SELECT =
  'FLAG_GETTING_MAINTENANCE_WARNINGS_IN_SELECT';
export const GET_MAINTENANCE_WARNINGS_IN_SELECT =
  'GET_MAINTENANCE_WARNINGS_IN_SELECT';
export const CLEAR_MAINTENANCE_WARNINGS_IN_SELECT =
  'CLEAR_MAINTENANCE_WARNINGS_IN_SELECT';
export const FLAG_MAINTENANCE_WARNING_ACTIVITY =
  'FLAG_MAINTENANCE_WARNING_ACTIVITY';
export const GET_MAINTENANCE_WARNING = 'GET_MAINTENANCE_WARNING';
export const CLEAR_MAINTENANCE_WARNING = 'CLEAR_MAINTENANCE_WARNING';
