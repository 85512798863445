import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Button, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import DatePicker from '../../../common/forms/input/DatePicker';
import { isRequired } from '../../../../utils/validators';
import Alert from '../../../common/informative/Alert';

const NotBoardedTicketReportForm = (props) => {
  const { handleSubmit, submitting, error, errorMessage } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Col sm={3} xs={12}>
          <Label>
            Fecha de Inicio <span className="text-danger">*</span>
          </Label>
        </Col>
        <Col sm={9} xs={12}>
          <Field
            name="fromDate"
            component={DatePicker}
            validate={[isRequired]}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} xs={12}>
          <Label>
            Fecha de Fin <span className="text-danger">*</span>
          </Label>
        </Col>
        <Col sm={9} xs={12}>
          <Field name="toDate" component={DatePicker} validate={[isRequired]} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col xs={12}>
          <Label>
            <strong>Opciones</strong>
          </Label>
        </Col>
        <Col xs={12}>
          <Field name="force" component="input" type="checkbox" />
          &nbsp; Forzar la regeneración del reporte. Sólo aplica si ya existe un
          reporte para las fechas indicadas.
        </Col>
      </FormGroup>
      <Alert message={errorMessage} />
      <Alert message={error} />
      <div className="FormButtonGroup">
        <Button type="submit" disabled={submitting} color="primary" outline>
          {submitting ? <i className="fa fa-spinner fa-spin" /> : 'Crear'}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = ({ ReportUnit }) => ({
  errorMessage: ReportUnit.NotBoardedTicketReport.get('form').get('error'),
});

NotBoardedTicketReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.string,
};

NotBoardedTicketReportForm.defaultProps = {
  errorMessage: null,
  error: null,
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'NotBoardedTicketReportForm',
  })(NotBoardedTicketReportForm),
);
