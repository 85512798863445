import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container, ButtonToolbar } from 'reactstrap';
import { EXPENSE_PATH } from '../../../../config/paths';
import {
  getExpense,
  clearExpense,
  putExpense,
  getActiveSalesSession,
} from '../../../../actions';
import ExpenseForm from './ExpenseForm';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { TICKET_REFUND } from '../../../../config/constants';
import DeleteExpenseButton from './DeleteExpenseButton';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

export class EditExpense extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    const { dispatchGetActiveSalesSession, dispatchGetExpense } = this.props;
    dispatchGetExpense(id);
    dispatchGetActiveSalesSession({ source: EXPENSE_PATH });
  }

  componentWillUnmount() {
    this.props.dispatchClearExpense();
  }

  onSubmit = (formValues) => {
    const expenseId = parseInt(this.props.match.params.id, 10);
    const putExpenseFormValues = {
      amount: formValues.amount,
      authorizerUserId: formValues.authorizerUserId.value,
      businessId: formValues.business ? formValues.business.value : null,
      description: formValues.description,
      documentCode: formValues.documentCode,
      documentSeries: formValues.documentSeries,
      expenseType: {
        id: formValues.expenseTypeId.value,
      },
      expenseTypeId: formValues.expenseTypeId.value,
      id: expenseId,
      isSettled: formValues.isSettled || false,
      payeeCustomerId: formValues.payeeCustomerId.value,
      registeredBusId: formValues.registeredBusId
        ? formValues.registeredBusId.value
        : null,
      voucherTime: formValues.voucherTime
        ? tzNormalizeDate({
            date: formValues.voucherTime,
            format: TIMESTAMP_FORMAT,
          })
        : tzNormalizeDate({ format: TIMESTAMP_FORMAT }),
      voucherTypeId: formValues.voucherTypeId.value,
      costCenterId: formValues.costCenter ? formValues.costCenter.value : null,
    };
    let putBusinessFormValues = {
      businessTaxId: null,
      businessName: null,
      addressSummary: null,
    };
    if (formValues.businessTaxId) {
      putBusinessFormValues = {
        businessTaxId: formValues.businessTaxId,
        businessName: formValues.businessName,
        addressSummary: formValues.addressSummary,
      };
    }
    this.props.dispatchPutExpense(
      expenseId,
      putExpenseFormValues,
      putBusinessFormValues,
    );
  };

  render() {
    const { activeSalesSession, expense, loading, breadcrumbs } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (expense.isEmpty()) {
      content = <NoDataResource returnPage={EXPENSE_PATH} />;
    } else {
      const transactionList = expense.get('transactionList');
      const transaction = transactionList[0];
      const { salesSessionId } = transaction;
      const expenseType = transaction.expenseType.expenseTypeLabel;
      const transactionNumber = transactionList ? transactionList.length : 0;

      if (transactionNumber > 1 || expenseType === TICKET_REFUND) {
        return (
          <Container>
            <Alert message="No puede editar este egreso." />
          </Container>
        );
      }

      if (
        activeSalesSession.get('id') === salesSessionId &&
        expenseType !== TICKET_REFUND
      ) {
        toolbar = (
          <Fragment>
            <ButtonToolbar className="pull-right">
              <div>
                <DeleteExpenseButton
                  expenseId={expense.get('id')}
                  isTripExpense={transactionList > 1}
                />
              </div>
            </ButtonToolbar>
          </Fragment>
        );
      }

      let costCenter = null;
      if (expense.get('costCenter')) {
        costCenter = {
          value: expense.get('costCenter').id,
          label: expense.get('costCenter').description,
        };
      }

      let initialValues = {
        expenseTypeId: {
          label: transaction.expenseType.name,
          value: transaction.expenseTypeId,
        },
        payeeCustomerId: {
          label: expense.get('payeeCustomer').fullName,
          value: expense.get('payeeCustomerId'),
        },
        authorizerUserId: {
          label: expense.get('authorizerUser').customer.fullName,
          value: expense.get('authorizerUserId'),
        },
        documentSeries: expense.get('documentSeries'),
        documentCode: expense.get('documentCode').toString(),
        voucherTime: expense.get('voucherTime'),
        isSettled: expense.get('isSettled'),
        description: expense.get('description'),
        amount: transaction.amount.toString(),
        costCenter,
      };

      if (expense.get('registeredBusId')) {
        initialValues = {
          ...initialValues,
          registeredBusId: {
            label: expense.get('registeredBusId'),
            value: expense.get('registeredBusId'),
          },
        };
      }

      if (transaction.voucherType) {
        initialValues = {
          ...initialValues,
          voucherTypeId: {
            label: transaction.voucherType.name,
            value: transaction.voucherTypeId,
          },
        };
      }

      if (transaction.business) {
        initialValues = {
          ...initialValues,
          business: {
            id: transaction.businessId,
            label: transaction.business.name,
            value: transaction.businessId,
          },
        };
      }

      content = (
        <ExpenseForm
          initialValues={initialValues}
          SalesSessionId={salesSessionId}
          onSubmit={this.onSubmit}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        subtitle="Editar egreso"
        title="Editar Egreso"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { SalesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Egresos', href: EXPENSE_PATH },
    { text: 'Ver', href: `${EXPENSE_PATH}/${id}` },
    { text: 'Editar', href: '' },
  ],
  expense: SalesUnit.Expense.getIn(['current', 'content']),
  loading: !SalesUnit.Expense.getIn(['current', 'activity']).isEmpty(),
  activeSalesSession: SalesUnit.SalesSession.getIn(['active', 'content']),
});

const mapDispatchToProps = {
  dispatchGetExpense: getExpense,
  dispatchClearExpense: clearExpense,
  dispatchPutExpense: putExpense,
  dispatchGetActiveSalesSession: getActiveSalesSession,
};

EditExpense.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  expense: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetExpense: PropTypes.func.isRequired,
  dispatchClearExpense: PropTypes.func.isRequired,
  dispatchPutExpense: PropTypes.func.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditExpense.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(EditExpense);
