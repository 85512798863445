import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../layout/Card';
import ModuleHome from '../../layout/ModuleHome';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

export const ReservationHome = ({ cards, breadcrumbs }) => (
  <ModuleHome
    title="Reservas"
    text="Éste es el módulo de Reservas."
    cards={cards}
    breadcrumbs={breadcrumbs}
  />
);

const mapStateToProps = ({ ReservationUnit }) => ({
  cards: ReservationUnit.UnitHome.get('cards'),
  breadcrumbs: ReservationUnit.UnitHome.get('breadcrumbs'),
});

ReservationHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default connect(mapStateToProps)(ReservationHome);
