import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FULL_DATE_TIME_FORMAT } from '../../../../../config/locale';
import { Table } from '../../../../common/Table';
import DateCell from '../../../../common/cells/DateCell';
import { numberFormatter } from '../../../../../utils/number';

const DepositLiquidationsReource = ({ dailyLiquidationSessionSet }) => (
  <Fragment>
    <h3>Liquidaciones</h3>
    <Table
      columns={[
        {
          Header: 'Agencia',
          accessor: 'agency.name',
          className: 'text-center',
        },
        {
          Header: 'Fecha',
          accessor: 'createDate',
          Cell: (row) => (
            <DateCell value={row.value} format={FULL_DATE_TIME_FORMAT} />
          ),
          className: 'text-center',
        },
        {
          Header: 'Monto',
          accessor: 'cashOnHand',
          Cell: ({ value }) => numberFormatter({ value }),
          className: 'text-center',
        },
        {
          Header: 'Operador',
          accessor: 'operatorUser.customer.fullName',
          className: 'text-center',
        },
      ]}
      data={dailyLiquidationSessionSet}
      defaultPageSize={dailyLiquidationSessionSet.length}
      showPagination={false}
    />
    <br />
  </Fragment>
);

DepositLiquidationsReource.propTypes = {
  dailyLiquidationSessionSet: PropTypes.arrayOf(
    PropTypes.shape({
      agency: PropTypes.shape({
        name: PropTypes.string,
      }),
      createDate: PropTypes.number,
      cashToDeposit: PropTypes.number,
      operatorUser: PropTypes.shape({
        customer: PropTypes.shape({
          fullName: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
};

export default DepositLiquidationsReource;
