import { number, arrayOf, shape, bool, string } from 'prop-types';
import { segmentBasicInformationPropTypes } from '../../segment/proptypes/SegmentPropTypes';

const routeBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  isMaciva: bool.isRequired,
  isOneWay: bool,
};

const routeSegmentListPropTypes = {
  routeSegmentList: arrayOf(
    shape({
      id: number.isRequired,
      routeId: number.isRequired,
      segmentId: number.isRequired,
      segment: shape(segmentBasicInformationPropTypes),
      segmentOrder: number.isRequired,
    }),
  ),
};

const routeSegmentListDefaultProps = {
  routeSegmentList: [],
};

export {
  routeBasicInformationPropTypes,
  routeSegmentListPropTypes,
  routeSegmentListDefaultProps,
};
