import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { COMPANY_USER_CONFIGURATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CompanyUserConfigurationForm from './CompanyUserConfigurationForm';
import Loader from '../../../common/Loader';
import {
  clearCompanyUserConfiguration,
  getCompanyUserConfiguration,
  putCompanyUserConfiguration,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditCompanyUserConfiguration extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: companyUserConfigurationId },
      },
      dispatchGetCompanyUserConfiguration,
    } = this.props;
    dispatchGetCompanyUserConfiguration({ companyUserConfigurationId });
  }

  componentWillUnmount() {
    this.props.dispatchClearCompanyUserConfiguration();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutCompanyUserConfiguration,
      match: {
        params: { id },
      },
    } = this.props;

    const newFormValues = {
      userId: formValues.userId.value,
      companyId: formValues.companyId.value,
      parentUserId: formValues.parentUserId.value,
    };

    dispatchPutCompanyUserConfiguration(id, newFormValues);
  };

  createInitialValues = (companyUserConfiguration) => ({
    userId: {
      value: companyUserConfiguration.get('userId'),
      label: companyUserConfiguration.get('user').customer.fullName,
    },
    parentUserId: {
      value: companyUserConfiguration.get('parentUserId'),
      label: companyUserConfiguration.get('parentUser').customer.fullName,
    },
    companyId: {
      value: companyUserConfiguration.get('companyId'),
      label: companyUserConfiguration.get('company').name,
    },
  });

  render() {
    const { breadcrumbs, companyUserConfiguration, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (companyUserConfiguration.isEmpty()) {
      content = <NoDataResource returnPage={COMPANY_USER_CONFIGURATION_PATH} />;
    } else {
      content = (
        <CompanyUserConfigurationForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(companyUserConfiguration)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Configuración de Usuario"
        subtitle="Edita esta configuración de CompanyUserConfiguration"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { ContractUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Configuraciones de Usuario',
      href: COMPANY_USER_CONFIGURATION_PATH,
    },
    {
      text: 'Ver',
      href: `${COMPANY_USER_CONFIGURATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  companyUserConfiguration: ContractUnit.CompanyUserConfiguration.getIn([
    'current',
    'content',
  ]),
  loading: !ContractUnit.CompanyUserConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutCompanyUserConfiguration: putCompanyUserConfiguration,
  dispatchGetCompanyUserConfiguration: getCompanyUserConfiguration,
  dispatchClearCompanyUserConfiguration: clearCompanyUserConfiguration,
};

EditCompanyUserConfiguration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutCompanyUserConfiguration: PropTypes.func.isRequired,
  dispatchGetCompanyUserConfiguration: PropTypes.func.isRequired,
  dispatchClearCompanyUserConfiguration: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  companyUserConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCompanyUserConfiguration.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCompanyUserConfiguration);
