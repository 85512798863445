import QueryString from 'query-string';
import {
  FLAG_GETTING_SERVICE_TYPES,
  GET_SERVICE_TYPES,
  SHOW_ERROR_GETTING_SERVICE_TYPES,
  CLEAR_ERROR_GETTING_SERVICE_TYPES,
} from '../types';
import {
  SERVICE_TYPE_ENDPOINT,
} from '../../config/endpoints';
import { handleResponseError } from '../../utils/error-handlers';
import { DEFAULT_QUERY } from '../../config/queries';

const flagGettingServiceTypes = flag => dispatch => dispatch({
  payload: flag,
  type: FLAG_GETTING_SERVICE_TYPES,
});

const showErrorGettingServiceTypes = error => dispatch => dispatch({
  payload: error,
  type: SHOW_ERROR_GETTING_SERVICE_TYPES,
});

const clearErrorGettingServiceTypes = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_SERVICE_TYPES,
});

const getServiceTypes = async (name = null) => async (dispatch) => {
  dispatch(clearErrorGettingServiceTypes());
  dispatch(flagGettingServiceTypes(true));
  let serviceTypes = [];

  const query = {
    ...DEFAULT_QUERY,
    query: name ? `name:${name}` : '',
  };

  const url = `${SERVICE_TYPE_ENDPOINT}?${QueryString.stringify(query)}`;
  try {
    const response = await fetch(url, {
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
    });

    // handle an error response from the server
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingServiceTypes(false));
      return dispatch(showErrorGettingServiceTypes(serverError));
    }

    // the response is a response instance
    // parse the data into a usable format using `.json()`
    serviceTypes = await response.json();

    dispatch(flagGettingServiceTypes(false));
    return dispatch({
      type: GET_SERVICE_TYPES,
      payload: serviceTypes,
    });
  } catch (err) {
    dispatch(flagGettingServiceTypes(false));
    return console.error(err);
  }
};

export {
  getServiceTypes,
  clearErrorGettingServiceTypes,
};
