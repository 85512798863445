import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, ButtonToolbar, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { DEPRECIATION_RULE_PATH } from '../../../../config/paths';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  getDepreciationRule,
  clearDepreciationRule,
} from '../../../../actions/cargo';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';

import EditDepreciationRuleButton from './EditDepreciationRuleButton';
import DeleteDepreciationRuleButton from './DeleteDepreciationRuleButton';

class DepreciationRule extends Component {
  componentDidMount() {
    const {
      dispatchGetDepreciationRule,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDepreciationRule({ id });
  }

  componentWillUnmount() {
    const { dispatchClearDepreciationRule } = this.props;
    dispatchClearDepreciationRule();
  }

  render() {
    const {
      breadcrumbs,
      depreciationRule,
      loading,
      match: {
        params: { id },
      },
    } = this.props;
    const depreciationRuleId = parseInt(id, 10);

    if (loading || depreciationRule.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Fragment>
          <ButtonToolbar className="pull-right">
            <div>
              <EditDepreciationRuleButton
                depreciationRuleId={depreciationRuleId}
              />{' '}
              <DeleteDepreciationRuleButton
                depreciationRuleId={depreciationRuleId}
              />
            </div>
          </ButtonToolbar>
        </Fragment>
        <Fragment>
          <Row>
            <Col>
              <h1>Regla de Depreciación</h1>
            </Col>
          </Row>
          <hr />
        </Fragment>
        <Fragment>
          <ResourceProperty label="Tipo:">
            {depreciationRule.get('depreciationType')}
          </ResourceProperty>
          <ResourceProperty label="Categoría:">
            {depreciationRule.get('itemCategory')
              ? depreciationRule.get('itemCategory').name
              : depreciationRule.get('itemCategoryId')}
          </ResourceProperty>
          <ResourceProperty label="Periodo:">
            {depreciationRule.get('depreciationPeriodicity')}
          </ResourceProperty>
          <ResourceProperty label="Porcentaje:">
            {depreciationRule.get('depreciationPercentage')}%
          </ResourceProperty>
          <ResourceProperty label="Description:">
            {depreciationRule.get('description')}
          </ResourceProperty>
        </Fragment>
      </Container>
    );
  }
}
DepreciationRule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDepreciationRule: PropTypes.func.isRequired,
  dispatchClearDepreciationRule: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  depreciationRule: PropTypes.instanceOf(Immutable.Map),
};

DepreciationRule.defaultProps = {
  loading: false,
  depreciationRule: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reglas de Depreciación',
      href: DEPRECIATION_RULE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  depreciationRule: CargoUnit.DepreciationRule.getIn(['current', 'content']),
  loading: CargoUnit.DepreciationRule.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDepreciationRule: getDepreciationRule,
  dispatchClearDepreciationRule: clearDepreciationRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepreciationRule);
