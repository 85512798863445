export const FLAG_GETTING_ITINERARY_SALES_CONTROL =
  'FLAG_GETTING_ITINERARY_SALES_CONTROL';
export const GET_ITINERARY_SALES_CONTROL = 'GET_ITINERARY_SALES_CONTROL';
export const CLEAR_ITINERARY_SALES_CONTROL = 'CLEAR_ITINERARY_SALES_CONTROL';
export const FLAG_GETTING_ITINERARY_SALES_CONTROL_DETAIL =
  'FLAG_GETTING_ITINERARY_SALES_CONTROL_DETAIL';
export const GET_ITINERARY_SALES_CONTROL_DETAIL =
  'GET_ITINERARY_SALES_CONTROL_DETAIL';
export const CLEAR_ITINERARY_SALES_CONTROL_DETAIL =
  'CLEAR_ITINERARY_SALES_CONTROL_DETAIL';
export const FLAG_GETTING_LIVE_ITINERARY_SALES_CONTROL =
  'FLAG_GETTING_LIVE_ITINERARY_SALES_CONTROL';
export const GET_LIVE_ITINERARY_SALES_CONTROL =
  'GET_LIVE_ITINERARY_SALES_CONTROL';
export const CLEAR_LIVE_ITINERARY_SALES_CONTROL =
  'CLEAR_LIVE_ITINERARY_SALES_CONTROL';
export const UPDATE_LIVE_ITINERARY_SALES_CONTROL_PARAMS =
  'UPDATE_LIVE_ITINERARY_SALES_CONTROL_PARAMS';
export const CLEAR_LIVE_ITINERARY_SALES_CONTROL_PARAMS =
  'CLEAR_LIVE_ITINERARY_SALES_CONTROL_PARAMS';
