export const FLAG_GETTING_DRIVER_BUS_ASSIGNMENTS =
  'FLAG_GETTING_DRIVER_BUS_ASSIGNMENTS';
export const GET_DRIVER_BUS_ASSIGNMENTS = 'GET_DRIVER_BUS_ASSIGNMENTS';
export const CLEAR_DRIVER_BUS_ASSIGNMENTS = 'CLEAR_DRIVER_BUS_ASSIGNMENTS';
export const FLAG_DRIVER_BUS_ASSIGNMENT_ACTIVITY =
  'FLAG_DRIVER_BUS_ASSIGNMENT_ACTIVITY';
export const GET_DRIVER_BUS_ASSIGNMENT = 'GET_DRIVER_BUS_ASSIGNMENT';
export const CLEAR_DRIVER_BUS_ASSIGNMENT = 'CLEAR_DRIVER_BUS_ASSIGNMENT';
export const DRIVER_BUS_ASSIGNMENT_INITIAL_VALUES =
  'DRIVER_BUS_ASSIGNMENT_INITIAL_VALUES';
