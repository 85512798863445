import { string, number } from 'prop-types';

const busBodyworkBasicInformationPropTypes = {
  id: number.isRequired,
  make: string.isRequired,
  model: string.isRequired,
  year: number.isRequired,
  fuelCapacity: number.isRequired,
};

export default busBodyworkBasicInformationPropTypes;
