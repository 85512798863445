import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { numberFormatter } from '../../../../../utils/number';

const LiquidationDifferenceResource = ({
  liquidationDifferenceAmount,
  liquidationDifferenceReason,
  liquidationDifferenceReasonDescription,
  cashToDeposit,
}) => {
  if (liquidationDifferenceAmount === 0 || !liquidationDifferenceReason)
    return null;

  return (
    <Fragment>
      <hr />
      <h3>Discrepancia</h3>
      <ResourceProperty label="Monto:">
        {numberFormatter({ value: liquidationDifferenceAmount })}
      </ResourceProperty>
      <ResourceProperty label="Razón:">
        {liquidationDifferenceReason.name}
      </ResourceProperty>
      <ResourceProperty label="Descripción:">
        {liquidationDifferenceReasonDescription}
      </ResourceProperty>
      <h3>Total a depositar: {numberFormatter({ value: cashToDeposit })}</h3>
    </Fragment>
  );
};

LiquidationDifferenceResource.propTypes = {
  liquidationDifferenceAmount: PropTypes.number,
  liquidationDifferenceReason: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  liquidationDifferenceReasonDescription: PropTypes.string,
  cashToDeposit: PropTypes.number.isRequired,
};

LiquidationDifferenceResource.defaultProps = {
  liquidationDifferenceAmount: 0,
  liquidationDifferenceReason: null,
  liquidationDifferenceReasonDescription: null,
};

export default LiquidationDifferenceResource;
