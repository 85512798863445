import Immutable from 'immutable';
import {
  FLAG_GETTING_USER_LOCALES,
  GET_USER_LOCALES,
  CLEAR_USER_LOCALES,
} from '../../../actions/types/user/UserLocale';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_GETTING_USER_LOCALES:
      return state.setIn(['all', 'loading'], payload);
    case GET_USER_LOCALES:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_USER_LOCALES:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    default:
      return state;
  }
};
