import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import DynamicForm from '../../../common/forms/DynamicForm';
import { generateDriverAssignmentColumns } from '../../../../config/dynamicFormFields';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import {
  getDriverBusAssignments,
  clearDriverBusAssignments,
  setInitialValues,
  putDriverBusUnassign,
} from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import FormFooter from '../../../common/forms/FormFooter';

const selector = formValueSelector('DriverBusAssignmentForm');

export class DriverBusAssignmentForm extends Component {
  state = {
    disabledBus: false,
    showModal: false,
    modalBody: '',
    formValues: null,
    index: null,
    fields: null,
  };

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    this.setDriverBusAssignmentInitialValues();
  }

  componentWillUnmount() {
    const { dispatchSetInitialValues, dispatchClearDriverBusAssignments } =
      this.props;
    dispatchSetInitialValues({});
    dispatchClearDriverBusAssignments();
  }

  onMount = () => {
    const { dispatchSetInitialValues, dispatchGetDriverBusAssignments, bus } =
      this.props;
    this.setState({ disabledBus: !!bus.value });
    if (bus.value) {
      dispatchSetInitialValues({ bus });
      dispatchGetDriverBusAssignments({
        query: `companyBusId:${bus.label}`,
      });
    }
  };

  onHandleSubmit = (formValues) => {
    const {
      initialValues: { assignmentIds },
    } = this.props;
    if (this.validateDriverList(formValues.driverList)) {
      if (formValues.driverList.length === assignmentIds.length) {
        toastr.error('Error','Debe agregar un nuevo conductor.');
      } else {
        this.setState({
          showModal: true,
          modalBody: `¿Está seguro que quiere asignar el conductor ${
            formValues.driverList[formValues.driverList.length - 1].driver.label
          } al bus ${formValues.bus.label}?`,
          formValues,
          index: null,
          fields: null,
        });
      }
    }
  };

  onClickAdd = (fields) => {
    const {
      driverList,
      initialValues: { assignmentIds },
    } = this.props;

    if (driverList.length === 0 || this.validateDriverList(driverList)) {
      if (driverList.length !== assignmentIds.length)
        toastr.error('Error','Antes de agregar un conductor debe registrarlo.');
      else fields.push({});
    }
  };

  onClickRemove = (index, fields) => {
    if (this.props.initialValues.assignmentIds[index]) {
      const {
        driverList,
        initialValues: { bus },
      } = this.props;
      this.setState({
        showModal: true,
        modalBody: `¿Está seguro que quiere quitar el conductor ${driverList[index].driver.label} del bus ${bus.label}?`,
        index,
        fields,
        formValues: null,
      });
    } else {
      fields.remove(index);
    }
  };

  onRegisteredBusChange = (bus) => {
    const {
      dispatchGetDriverBusAssignments,
      dispatchClearDriverBusAssignments,
      dispatchSetInitialValues,
    } = this.props;
    dispatchClearDriverBusAssignments();
    dispatchSetInitialValues(
      bus.value ? { bus: { value: bus.value, label: bus.label } } : {},
    );
    if (bus.value) {
      dispatchGetDriverBusAssignments({ query: `companyBusId:${bus.label}` });
    }
  };

  onDriverChange = (index) => (value) => {
    this.props.dispatchChange(
      'DriverBusAssignmentForm',
      `driverList[${index}].driverType`,
      value.driverType
        ? { value: value.driverType.id, label: value.driverType.name }
        : '',
    );
  };

  onModalDoneClick = () => {
    const { index, fields, formValues } = this.state;
    if (fields) {
      const { initialValues, dispatchPutDriverBusUnassign } = this.props;
      if (initialValues.assignmentIds[index]) {
        dispatchPutDriverBusUnassign({
          driverBusAssignmentId: initialValues.assignmentIds[index],
          companyBusId: initialValues.bus.label,
        });
      }
      fields.remove(index);
    } else if (formValues) {
      this.props.onSubmit(formValues);
    }
    this.onModalCancelClick();
  };

  onModalCancelClick = () => {
    this.setState({
      showModal: false,
      modalBody: '',
      index: null,
      fields: null,
      formValues: null,
    });
  };

  setDriverBusAssignmentInitialValues = () => {
    const {
      assignmentsContent,
      dispatchSetInitialValues,
      initialValues: { bus },
      driverTypes,
    } = this.props;

    const driverList = [];
    const assignmentIds = [];

    assignmentsContent.forEach(({ id, driverAssignmentDateList }) => {
      const { driver, driverId, driverTypeId } = driverAssignmentDateList[0];

      let driverType = null;
      if (driverTypes)
        driverType = driverTypes.find(({ value }) => value === driverTypeId);

      assignmentIds.push(id);
      driverList.push({
        driver: { value: driverId, label: driver.driverName },
        driverType,
      });
    });

    dispatchSetInitialValues({
      bus,
      assignmentIds,
      driverList,
    });
  };

  validateDriverList = (rowValues) => {
    let isValid = true;
    if (rowValues.length) {
      const isInvalidData = rowValues.some((row) => {
        if (!(row.driver && row.driver.value)) {
          toastr.error('Error','Seleccione un conductor.');
          return true;
        }
        if (!(row.driverType && row.driverType.value)) {
          toastr.error('Error','Seleccione un tipo de conductor.');
          return true;
        }
        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error','Ingrese almenos un conductor.');
      isValid = false;
    }
    return isValid;
  };

  renderDynamicForm = () => {
    const {
      initialValues: { bus },
    } = this.props;
    if (!bus) {
      return <Alert message="Seleccione un bus." />;
    }
    return (
      <DynamicForm
        name="driverList"
        columns={generateDriverAssignmentColumns(this.onDriverChange)}
        onClickRemove={this.onClickRemove}
        onClickAdd={this.onClickAdd}
      />
    );
  };

  render() {
    const { handleSubmit, loading } = this.props;
    const { showModal, modalBody } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <Fragment>
        <ConfirmationModal
          show={showModal}
          body={modalBody}
          onClickConfirm={this.onModalDoneClick}
          onClickCancel={this.onModalCancelClick}
        />
        <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
          <FormGroup row>
            <FormItem label="Bus" required>
              <Field
                name="bus"
                component={RegisteredBusSelect}
                onChange={this.onRegisteredBusChange}
                validate={[isRequired]}
                isDisabled={this.state.disabledBus}
              />
            </FormItem>
          </FormGroup>
          <h5>Lista de Conductores</h5>
          {this.renderDynamicForm()}
          <FormFooter />
        </Form>
      </Fragment>
    );
  }
}

DriverBusAssignmentForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  driverList: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  assignmentsContent: PropTypes.arrayOf(PropTypes.object),
  bus: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  dispatchGetDriverBusAssignments: PropTypes.func.isRequired,
  dispatchClearDriverBusAssignments: PropTypes.func.isRequired,
  dispatchSetInitialValues: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    driverList: PropTypes.arrayOf(
      PropTypes.shape({
        driver: PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      }),
    ),
    bus: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    assignmentIds: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  }),
  dispatchPutDriverBusUnassign: PropTypes.func.isRequired,
  driverTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

DriverBusAssignmentForm.defaultProps = {
  loading: false,
  driverList: [],
  initialValues: { driverList: [], bus: {}, assignmentIds: [] },
  assignmentsContent: [],
  bus: {},
  driverTypes: [],
};

const mapStateToProps = (state) => ({
  loading: !state.TrafficUnit.DriverBusAssignment.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  assignmentsContent: state.TrafficUnit.DriverBusAssignment.getIn([
    'all',
    'content',
  ]).get('content'),
  driverList: selector(state, 'driverList'),
  initialValues: state.TrafficUnit.DriverBusAssignment.getIn(['initialValues']),
  driverTypes: state.TrafficUnit.DriverType.getIn([
    'all',
    'content',
    'content',
  ]).map((type) => ({
    value: type.id,
    label: type.name,
  })),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchSetInitialValues: setInitialValues,
  dispatchGetDriverBusAssignments: getDriverBusAssignments,
  dispatchClearDriverBusAssignments: clearDriverBusAssignments,
  dispatchPutDriverBusUnassign: putDriverBusUnassign,
};

const formComponent = reduxForm({
  form: 'DriverBusAssignmentForm',
  enableReinitialize: true,
})(DriverBusAssignmentForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
