import { number, shape, bool } from 'prop-types';
import { activityBasicInformationPropTypes } from '../../activity/proptypes/ActivityPropTypes';
import { registeredBusBasicInformationPropTypes } from '../../../bus/registered-bus/proptypes/RegisteredBusPropTypes';

const ActivityBusBasicInformationPropTypes = {
  active: bool.isRequired,
  activity: shape(activityBasicInformationPropTypes).isRequired,
  activityId: number.isRequired,
  createDate: number.isRequired,
  extended: bool.isRequired,
  id: number.isRequired,
  lastExecution: number,
  registeredBus: shape(registeredBusBasicInformationPropTypes).isRequired,
  registeredBusId: number.isRequired,
  userId: number.isRequired,
  virtualOdometer: number.isRequired,
};

const ActivityBusBasicDefaultProps = {
  lastExecution: null,
};

export { ActivityBusBasicInformationPropTypes, ActivityBusBasicDefaultProps };
