import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Button, ButtonToolbar, FormGroup } from 'reactstrap';
import FormItem from '../forms/FormItem';
import BusinessField from './fields/BusinessField';
import Modal from '../../common/modal/Modal';
import { clearBusiness, postBusiness, putBusiness } from '../../../actions';
import { optionsPropTypes } from './select/SelectPropTypes';
import BusinessForm from '../../units/user/business/BusinessForm';
import BusinessResource from '../resource/BusinessResource';
import { addressGenerator } from '../../../utils/app/json-generator-from-reducer';

class BusinessInputGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalTitle: null,
      modalBody: null,
      setBusiness: false,
      showDetails: props.showDetails,
      business: props.currentBusinessInForm,
      showDetailsView: false,
      businessIsSelected: !!props.currentBusinessInForm,
    };
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    this.props.dispatchClearBusiness();
  }

  onDidUpdate = () => {
    const {
      business,
      loading,
      dispatchChange,
      form,
      name,
      dispatchClearBusiness,
    } = this.props;

    const { setBusiness } = this.state;

    if (setBusiness && !loading && !business.isEmpty()) {
      const {
        id,
        businessTaxId,
        name: businessTaxName,
        email,
        primaryContact,
        phone,
        fax,
        addressSummary,
        addressId,
        address,
      } = business.toJS();

      const newBusiness = {
        value: id,
        label: `${businessTaxId} - ${businessTaxName}`,
        businessTaxId,
        name: businessTaxName,
        email,
        primaryContact,
        phone,
        fax,
        addressSummary,
        addressId,
        address,
      };

      dispatchChange(form, name, newBusiness);

      this.setState({ setBusiness: false });

      this.onChangeBusiness(newBusiness);

      dispatchClearBusiness();
    }
  };

  onChangeBusiness = (option) => {
    if (this.state.showDetails)
      this.setState({
        business: option || null,
        businessIsSelected: !!option.value,
      });
    const { onChange } = this.props;
    if (onChange) onChange(option);
  };

  onClickEditBusiness = () => {
    const { business } = this.state;
    const initialValues = { ...business };
    if (business.address)
      initialValues.addressId = addressGenerator(business.address);
    this.showModal(initialValues);
  };

  onSubmitBusiness = (formValues) => {
    const { dispatchPostBusiness, dispatchPutBusiness } = this.props;

    const { business, modalTitle } = this.state;

    const newFormValues = {
      businessTaxId: formValues.businessTaxId,
      name: formValues.name,
      primaryContact: formValues.primaryContact,
      email: formValues.email,
      phone: formValues.phone,
      fax: formValues.fax,
      addressSummary: formValues.addressSummary,
      addressId: formValues.addressId ? formValues.addressId.value : null,
    };

    this.setState({ setBusiness: true });

    if (modalTitle.includes('Actualizar'))
      dispatchPutBusiness({
        businessId: business.value,
        values: newFormValues,
        flagGetBusiness: true,
        redirect: false,
      });
    else
      dispatchPostBusiness({
        values: newFormValues,
        flagGetBusiness: true,
        redirect: false,
      });

    this.closeModal();
  };

  showModal = (initialValues = null) => {
    const modalBody = (
      <BusinessForm
        onSubmit={this.onSubmitBusiness}
        onModal
        initialValues={initialValues}
      />
    );

    const modalTitle = initialValues
      ? 'Actualizar datos de la '
      : 'Registrar nueva ';

    this.setState({ showModal: true, modalBody, modalTitle });
  };

  closeModal = () => {
    const { onClickModalClose } = this.props;

    if (onClickModalClose) onClickModalClose();

    this.setState({ showModal: false });
  };

  handleNoBusiness = () => (
    <div>
      <p>No existe ninguna {this.props.label} con esa Razón Social.</p>
      <Button type="button" color="primary" onClick={() => this.showModal()}>
        Nueva {this.props.label}
      </Button>
    </div>
  );

  render() {
    const {
      label,
      name,
      labelRequired,
      validate,
      disabled,
      options,
      isClearable,
    } = this.props;

    const {
      showModal,
      modalBody,
      showDetails,
      business,
      showDetailsView,
      modalTitle,
      businessIsSelected,
    } = this.state;

    const modal = (
      <Modal
        show={showModal}
        title={`${modalTitle} ${label}`}
        onClickClose={this.closeModal}
        body={modalBody}
        size="lg"
      />
    );

    const buttonIcon =
      showDetailsView && businessIsSelected ? (
        <i className="fa fa-eye-slash" />
      ) : (
        <i className="fa fa-eye" />
      );

    const editButton = showDetailsView && businessIsSelected && (
      <Button
        color="primary"
        outline
        size="sm"
        onClick={this.onClickEditBusiness}
      >
        <i className="fa fa-edit" /> Editar
      </Button>
    );

    const detailsButton = showDetails && businessIsSelected && business && (
      <ButtonToolbar className="mt-2">
        <div>
          <Button
            color="primary"
            outline
            size="sm"
            type="button"
            onClick={() => this.setState({ showDetailsView: !showDetailsView })}
          >
            {buttonIcon} {showDetailsView ? 'Ocultar Datos' : 'Ver Datos'}
          </Button>{' '}
          {editButton}
        </div>
      </ButtonToolbar>
    );

    const detailsResource = showDetailsView && businessIsSelected && (
      <BusinessResource business={business} />
    );

    return (
      <>
        {modal}
        <FormGroup row>
          <FormItem label={label} required={labelRequired}>
            <BusinessField
              fieldName={name}
              validate={validate}
              noOptionsMessage={() => this.handleNoBusiness()}
              isDisabled={disabled}
              onChange={this.onChangeBusiness}
              options={options}
              isClearable={isClearable}
            />
            {detailsButton}
            {detailsResource}
          </FormItem>
        </FormGroup>
      </>
    );
  }
}

BusinessInputGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelRequired: PropTypes.bool,
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func),
  dispatchPostBusiness: PropTypes.func.isRequired,
  dispatchClearBusiness: PropTypes.func.isRequired,
  business: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClickModalClose: PropTypes.func,
  dispatchPutBusiness: PropTypes.func.isRequired,
  options: optionsPropTypes,
  showDetails: PropTypes.bool,
  currentBusinessInForm: PropTypes.shape({}),
  isClearable: PropTypes.bool,
};

BusinessInputGroup.defaultProps = {
  labelRequired: false,
  validate: [],
  loading: false,
  disabled: false,
  onChange: null,
  onClickModalClose: null,
  options: [],
  showDetails: false,
  currentBusinessInForm: null,
  isClearable: false,
};

const mapStateToProps = ({ UserUnit, form }, props) => ({
  business: UserUnit.Business.getIn(['current', 'content']),
  loading: !UserUnit.Business.getIn(['current', 'activity']).isEmpty(),
  currentBusinessInForm:
    form[props.form] &&
    form[props.form].values &&
    form[props.form].values[props.name],
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchPostBusiness: postBusiness,
  dispatchClearBusiness: clearBusiness,
  dispatchPutBusiness: putBusiness,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInputGroup);
