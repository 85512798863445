import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postMaintenanceWarning } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaintenanceWarningForm from './MaintenanceWarningForm';
import { MAINTENANCE_WARNING_PATH } from '../../../../config/paths';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_WARNING_STATUS,
} from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';

const NewMaintenanceWarning = ({
  breadcrumbs,
  dispatchPostMaintenanceWarning,
}) => {
  const onSubmit = (formValues) =>
    dispatchPostMaintenanceWarning({
      reporterCustomerId: formValues.reporterCustomerId.value,
      issueDate: tzNormalizeDate({ date: formValues.issueDate }),
      registeredBusId: formValues.registeredBusId.value,
      actualVirtualOdometer: formValues.actualVirtualOdometer,
      actualRegisteredBusOdometer: formValues.actualRegisteredBusOdometer,
      maintenanceWarningType: formValues.maintenanceWarningType.value,
      maintenanceFailModeId: formValues.maintenanceFailModeId.value,
      categoryId: formValues.maintenanceFailModeId.categoryId,
      subCategoryId: formValues.maintenanceFailModeId.subCategoryId,
      priority: formValues.priority.value,
      recurrence: formValues.recurrence && formValues.recurrence.value,
      comments: formValues.comments,
      status: MAINTENANCE_WARNING_STATUS.OPEN.value,
      creationType: MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value,
    });

  const content = (
    <MaintenanceWarningForm
      onSubmit={onSubmit}
      initialValues={{
        issueDate: tzNormalizeDate().slice(0, 16),
        status: MAINTENANCE_WARNING_STATUS.OPEN,
      }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Reporte de Ocurrencia"
      subtitle="Crear reporte de ocurrencia"
      content={content}
    />
  );
};

NewMaintenanceWarning.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMaintenanceWarning: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostMaintenanceWarning: postMaintenanceWarning,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reportes de Ocurrencia',
      href: MAINTENANCE_WARNING_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewMaintenanceWarning);
