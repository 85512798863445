import { string, number, shape, bool, arrayOf } from 'prop-types';
import securityProfileBasicInformationPropTypes from '../../../security/security-profile/proptypes/SecurityProfilePropTypes';
import { customerBasicInformationPropTypes } from '../../customer/proptypes/CustomerPropTypes';

const userBasicInformationPropTypes = {
  username: string.isRequired,
  newPassword: string,
  profilePictureId: number,
  locale: string,
  customer: shape(customerBasicInformationPropTypes),
};

const userBasicInformationDefaultProps = {
  newPassword: null,
  profilePictureId: null,
  locale: null,
};

const userActivityInformationPropTypes = {
  enabled: bool.isRequired,
  verified: bool.isRequired,
  blocked: bool.isRequired,
};

const userSecurityProfileInformationPropTypes = {
  securityProfileSet: arrayOf(shape(securityProfileBasicInformationPropTypes)),
};

const userInternalInformationPropTypes = {
  id: number.isRequired,
  createDate: number,
  lastUpdate: number,
  lastLogin: number,
};

const userInternalInformationDefaultProps = {
  createDate: null,
  lastUpdate: null,
  lastLogin: null,
};

const userSecurityProfileInformationDefaultProps = {
  securityProfileSet: null,
};

export {
  userBasicInformationPropTypes,
  userBasicInformationDefaultProps,
  userSecurityProfileInformationPropTypes,
  userSecurityProfileInformationDefaultProps,
  userActivityInformationPropTypes,
  userInternalInformationPropTypes,
  userInternalInformationDefaultProps,
};
