import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getBusActivityAssignments,
  clearBusActivityAssignments,
} from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { isIntegerNumber } from '../../../../utils/validators';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

export const BusActivitySelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearBusActivityAssignments,
  dispatchGetBusActivityAssignments,
  registeredBusId,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearBusActivityAssignments(), []);

  const onInputChangeActivity = (inputValue) => {
    const inputValueLength = inputValue.trim().length;
    if (inputValueLength > 0) {
      const inputValueIsNumber =
        isIntegerNumber(inputValue.trim()) && inputValueLength;

      const query = [`registeredBusId:${registeredBusId}`];

      if (inputValueIsNumber)
        query.push(`activity.companyActivityId:${inputValue}`);
      else if (inputValueLength && inputValueLength >= 4)
        query.push(`activity.name:${inputValue}`);

      dispatchGetBusActivityAssignments({ ...DEFAULT_QUERY_GET_ALL, query });
    }
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeActivity}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

BusActivitySelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetBusActivityAssignments: PropTypes.func.isRequired,
  dispatchClearBusActivityAssignments: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  registeredBusId: PropTypes.number.isRequired,
};

BusActivitySelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por código o nombre de actividad',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.BusActivityAssignment.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, companyActivityId, activityName }) => ({
    value: id,
    label: `${companyActivityId} - ${activityName}`,
  })),
  loading: MechanicalMaintenanceUnit.BusActivityAssignment.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetBusActivityAssignments: getBusActivityAssignments,
  dispatchClearBusActivityAssignments: clearBusActivityAssignments,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusActivitySelect);
