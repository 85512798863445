import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BILLING_PATH } from '../../../../config/paths';
import { getBilling, clearBilling } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import BillingToolbar from './BillingToolbar';

export const Billing = ({
  breadcrumbs,
  billing,
  loading,
  printLoading,
  match: {
    params: { id: billingId },
  },
  dispatchGetBilling,
  dispatchClearBilling,
}) => {
  useLayoutEffect(() => {
    dispatchGetBilling({ billingId });

    return () => dispatchClearBilling();
  }, []);

  let content;
  let toolbar;

  if (loading || printLoading) content = <Loader />;
  else if (billing.isEmpty())
    content = <NoDataResource returnPage={BILLING_PATH} />;
  else {
    const {
      id,
      processType,
      documentSeries,
      refDocumentSeries,
      documentCode,
      refDocumentCode,
      itineraryId,
      json,
      response,
      retry,
      status,
      ticketId,
    } = billing.toJS();

    const responseResource = response ? <code>{response}</code> : '-';
    content = (
      <>
        <ResourceProperty label="ID:">{billingId}</ResourceProperty>
        <ResourceProperty label="Tipo de Proceso:">
          {processType}
        </ResourceProperty>
        <ResourceProperty label="Documento:">
          {documentSeries} - {documentCode}
        </ResourceProperty>
        <ResourceProperty label="Documento de Referencia:">
          {refDocumentSeries} - {refDocumentCode}
        </ResourceProperty>
        <ResourceProperty label="Itinerario:">{itineraryId}</ResourceProperty>
        <ResourceProperty label="Datos Enviados:">
          <code>{json}</code>
        </ResourceProperty>
        <ResourceProperty label="Respuesta:">
          {responseResource}
        </ResourceProperty>
        <ResourceProperty label="Intentos:">{retry}</ResourceProperty>
        <ResourceProperty label="Estado:">{status}</ResourceProperty>
      </>
    );

    toolbar = (
      <BillingToolbar billingId={id} ticket={ticketId} status={status} />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title={`Datos de Facturación #${billingId}`}
      content={content}
    />
  );
};

Billing.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBilling: PropTypes.func.isRequired,
  dispatchClearBilling: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  printLoading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  billing: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Billing.defaultProps = {
  loading: false,
  printLoading: false,
};

const mapStateToProps = ({ AccountingUnit, CargoUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Facturación',
      href: BILLING_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  billing: AccountingUnit.Billing.getIn(['current', 'content']),
  loading: !AccountingUnit.Billing.getIn(['current', 'activity']).isEmpty(),
  printLoading: !CargoUnit.ManualSenderRemissionGuide.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetBilling: getBilling,
  dispatchClearBilling: clearBilling,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
