import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import DynamicForm from '../../../common/forms/DynamicForm';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import {
  DRIVER_CONTRACT_FORM_COLUMNS,
  DRIVER_DOCUMENT_FORM_COLUMNS,
} from '../../../../config/dynamicFormFields';
import CustomerField from '../../../common/forms/fields/CustomerField';
import DriverTypeSelect from '../../../common/forms/select/DriverTypeSelect';
import CrewStatusSelect from '../../../common/forms/select/CrewStatusSelect';
import FormFooter from '../../../common/forms/FormFooter';

const DriverForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Cliente" required>
          <CustomerField fieldName="customerId" validate={[isRequired]} />
        </FormItem>
      </FormGroup>
      <h3>Documentos</h3>
      <DynamicForm
        name="driverDocumentList"
        columns={DRIVER_DOCUMENT_FORM_COLUMNS}
      />
      <h3>Estado</h3>
      <FormGroup row>
        <FormItem label="Tipo de Conductor" required>
          <Field
            name="driverTypeId"
            component={DriverTypeSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Base" required>
          <Field
            name="homeBaseId"
            component={LocationSelect}
            forHomeBase
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado" required>
          <Field
            name="crewStatusId"
            component={CrewStatusSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <h3>Contratos</h3>
      <DynamicForm
        name="driverContractList"
        columns={DRIVER_CONTRACT_FORM_COLUMNS}
      />
      <FormFooter />
    </Form>
  );
};

DriverForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.Driver.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DriverForm',
})(DriverForm);

export default connect(mapStateToProps)(formComponent);
