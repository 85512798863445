import React, { useLayoutEffect, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Form, Button, FormGroup, Col } from 'reactstrap';
import {
  clearItemToItinerary,
  getUserAgencies,
  getItineraryForCounter,
  clearItinerary,
  getManifestCargoItemsToItinerary,
  getManifestItemToUnload,
} from '../../../../actions';
import { isRequired } from '../../../../utils/validators';
import { CARGO_OPERATIONS } from '../../../../config/constants';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import { getUniqueLocations } from '../unloading-cargo-from-itinerary/UnloadingCargoForm';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import ManifestCargo from './manifest-cargo/ManifestCargo';
import AddItemToItinerary from './add-cargo-to-itinerary/AddCargoToItinerary';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import CargoOperationsButtons from './CargoOperationsButtons';
import UnloadCargoFromItinerary from './unload-cargo-from-itinerary/UnloadCargoFromItinerary';
import AgencySelect from '../../../common/forms/select/AgencySelect';
import { getManifestItemByItinerary } from '../../../../actions/cargo/ItineraryManifest';

const selector = formValueSelector('ManifestForm');

export const ManifestForm = ({
  user,
  handleSubmit,
  agencyOptions,
  loadingAgencies,
  registeredBusId,
  itineraryCounter,
  dispatchChange,
  dispatchClearItinerary,
  dispatchGetUserAgencies,
  dispatchClearItemToItinerary,
  dispatchGetItineraryForCounter,
  dispatchGetManifestItemByItinerary,
  dispatchGetManifestCargoItemsToItinerary,
  dispatchGetManifestItemToUnload,
}) => {
  const [itinerary, setItinerary] = useState(null);
  const [locationIdSelected, setLocationIdSelected] = useState(null);
  const [agenciesToShow, setAgenciesToShow] = useState([]);
  const [operation, setOperation] = useState(null);

  useLayoutEffect(
    () => () => {
      dispatchClearItemToItinerary();
      dispatchClearItinerary();
    },
    [],
  );

  useEffect(() => {
    dispatchGetUserAgencies({ userId: user.id });
  }, [user]);

  const verifyLocationWithAgencies = () => {
    const uniqueLocations = getUniqueLocations(itineraryCounter, true, true);

    let newAgenciesToShow = [];

    if (uniqueLocations.length !== 0) {
      newAgenciesToShow = agencyOptions.filter((agency) =>
        uniqueLocations.some((location) => agency.value === location.id),
      );

      if (newAgenciesToShow.length === 0) {
        toastr.error(
          'Error',
          'No puede realizar operaciones de carga en este itinerario, ninguna de sus agencias coincide con las paradas.',
        );
        dispatchChange('ManifestForm', 'sourceAgency', '');
        setAgenciesToShow(newAgenciesToShow);
        return;
      }
    }

    dispatchChange('ManifestForm', 'sourceAgency', newAgenciesToShow[0]);

    setAgenciesToShow(newAgenciesToShow);
  };

  const verifyOperationLocation = (currentLocation, unload, add) => {
    if (itineraryCounter.isEmpty()) {
      return true;
    }
    const uniqueLocations = getUniqueLocations(itineraryCounter, unload, add);

    if (uniqueLocations.length !== 0) {
      const verified = uniqueLocations.some(
        (location) => location.id === currentLocation,
      );

      if (!verified) {
        toastr.error(
          'Error',
          add
            ? 'No puede subir carga en este itinerario, su agencia es la última parada'
            : 'No puede bajar carga de este itinerario, su agencia es la primera parada',
        );
        return verified;
      }

      return verified;
    }

    return false;
  };

  useEffect(() => {
    if (!itineraryCounter.isEmpty()) {
      verifyLocationWithAgencies();
    }
  }, [itineraryCounter]);

  const onHandleSubmit = ({ sourceAgency, destinationAgency }) => {
    const sourceLocationId = sourceAgency.value;
    const destinationLocationId = destinationAgency
      ? destinationAgency.locationId
      : null;
    switch (operation) {
      case CARGO_OPERATIONS.ADD_ITEM.value:
        if (verifyOperationLocation(sourceLocationId, false, true)) {
          dispatchGetManifestCargoItemsToItinerary({
            itineraryId: itinerary,
            sourceLocationId,
            destinationLocationId,
          });
        }
        break;
      case CARGO_OPERATIONS.UNLOAD_ITEM.value:
        if (verifyOperationLocation(sourceLocationId, true, false)) {
          dispatchGetManifestItemToUnload({
            itineraryId: itinerary,
            destinationLocationId: sourceAgency.value,
            registeredBusId: registeredBusId ? registeredBusId.value : null,
          });
        }
        break;
      case CARGO_OPERATIONS.MANIFEST.value:
        dispatchGetManifestItemByItinerary({ itineraryId: itinerary });
        break;
      default:
        break;
    }

    setLocationIdSelected(sourceAgency.value);
  };

  const handleSelectItinerary = ({ itineraryId }) => {
    setItinerary(itineraryId);
    dispatchGetItineraryForCounter(itineraryId);
  };

  const handleClear = () => {
    setItinerary(null);
    setOperation(null);
    dispatchClearItinerary();
  };

  const handleShowItinerarySearch = () => {
    handleClear();
    dispatchClearItemToItinerary();
    dispatchChange('ManifestForm', 'itineraryId', '');
    dispatchChange('ManifestForm', 'itinerary', '');
  };

  const onClickAddItem = () => {
    setOperation(CARGO_OPERATIONS.ADD_ITEM.value);
  };

  const onClickUnloading = () => {
    setOperation(CARGO_OPERATIONS.UNLOAD_ITEM.value);
  };

  const onClickManifest = () => {
    setOperation(CARGO_OPERATIONS.MANIFEST.value);
  };

  const onClickHandlers = {
    [CARGO_OPERATIONS.ADD_ITEM.value]: onClickAddItem,
    [CARGO_OPERATIONS.UNLOAD_ITEM.value]: onClickUnloading,
    [CARGO_OPERATIONS.MANIFEST.value]: onClickManifest,
  };

  const onChangeSourceAgency = () => {
    setOperation(null);
  };

  useEffect(() => {
    if (registeredBusId === null || registeredBusId === '') {
      setOperation(null);
    } else {
      setAgenciesToShow(agencyOptions);
    }
  }, [registeredBusId]);

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        {registeredBusId !== null && registeredBusId !== '' ? null : (
          <FormGroup row>
            <FormItem label="Itinerario">
              <Field
                name="itineraryId"
                component={ItinerarySearchInput}
                placeholder="Itinerario"
                handleSelectItinerary={handleSelectItinerary}
                handleShowItinerarySearch={handleShowItinerarySearch}
                handleClear={handleClear}
                props={{
                  form: 'ManifestForm',
                }}
              />
            </FormItem>
          </FormGroup>
        )}
        {itinerary !== null && itinerary ? null : (
          <FormGroup row>
            <FormItem label="Número de Bus">
              <Field
                name="registeredBusId"
                component={RegisteredBusSelect}
                isClearable
              />
            </FormItem>
          </FormGroup>
        )}
        <FormGroup row>
          <FormItem label="Agencia Actual" required>
            <Field
              name="sourceAgency"
              component={Select}
              isLoading={loadingAgencies}
              options={
                registeredBusId === '' || !!itinerary
                  ? agenciesToShow
                  : agencyOptions
              }
              isDisabled={
                agenciesToShow.length <= 1 &&
                (registeredBusId === '' || !!itinerary)
              }
              onChange={onChangeSourceAgency}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <CargoOperationsButtons
          operations={CARGO_OPERATIONS}
          showNothing={!itinerary && !registeredBusId}
          toShow={
            registeredBusId !== '' && !itinerary
              ? [CARGO_OPERATIONS.UNLOAD_ITEM.value]
              : []
          }
          showAll={!!itinerary}
          onClickHandlers={onClickHandlers}
        />
        {operation === CARGO_OPERATIONS.ADD_ITEM.value ? (
          <FormGroup row>
            <FormItem label="Destino">
              <Field
                name="destinationAgency"
                component={AgencySelect}
                isClearable
                clearAllAgencies={false}
              />
            </FormItem>
          </FormGroup>
        ) : null}

        {operation === CARGO_OPERATIONS.ADD_ITEM.value ? (
          <FormGroup row>
            <Col className="flex row-reverse">
              <Button type="submit" color="primary">
                Buscar
              </Button>
            </Col>
          </FormGroup>
        ) : null}
      </Form>

      {locationIdSelected
        ? (() => {
            switch (operation) {
              case CARGO_OPERATIONS.ADD_ITEM.value:
                return (
                  <AddItemToItinerary
                    itinerary={itinerary}
                    sourceLocationId={locationIdSelected}
                  />
                );
              case CARGO_OPERATIONS.UNLOAD_ITEM.value:
                return (
                  <UnloadCargoFromItinerary
                    itineraryId={itinerary}
                    registeredBusId={
                      registeredBusId ? registeredBusId.value : null
                    }
                    destinationLocationId={locationIdSelected}
                  />
                );
              case CARGO_OPERATIONS.MANIFEST.value:
                return <ManifestCargo itineraryId={itinerary} />;
              default:
                return null;
            }
          })()
        : null}
    </>
  );
};

ManifestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
  registeredBusId: PropTypes.instanceOf(Object),
  dispatchChange: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  dispatchGetUserAgencies: PropTypes.func.isRequired,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchGetManifestItemByItinerary: PropTypes.func.isRequired,
  dispatchGetManifestCargoItemsToItinerary: PropTypes.func.isRequired,
  dispatchGetManifestItemToUnload: PropTypes.func.isRequired,
};

ManifestForm.defaultProps = {
  loadingAgencies: false,
  agencyOptions: [],
  registeredBusId: null,
};

const mapStateToProps = (state) => ({
  user: state.authentication.get('user') || undefined,
  loadingAgencies: state.HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: state.HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
  itineraryCounter:
    state.ItineraryUnit.Itinerary.getIn(['current', 'content']) || undefined,
  registeredBusId: selector(state, 'registeredBusId'),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchClearItinerary: clearItinerary,
  dispatchGetUserAgencies: getUserAgencies,
  dispatchClearItemToItinerary: clearItemToItinerary,
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchGetManifestItemByItinerary: getManifestItemByItinerary,
  dispatchGetManifestCargoItemsToItinerary: getManifestCargoItemsToItinerary,
  dispatchGetManifestItemToUnload: getManifestItemToUnload,
};

const formComponent = reduxForm({
  form: 'ManifestForm',
})(ManifestForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
