import PropTypes from 'prop-types';

const driverFuelReportHeaderPropTypes = PropTypes.shape({
  adblueEfficiency: PropTypes.number,
  driverHomeBase: PropTypes.string,
  circuitQuantity: PropTypes.number,
  excess: PropTypes.number,
  expectedAdblueConsumption: PropTypes.number,
  expectedFuelConsumption: PropTypes.number,
  fuelEfficiency: PropTypes.number,
  performance: PropTypes.number,
  qualification: PropTypes.string,
  realAdblueConsumption: PropTypes.number,
  realFuelConsumption: PropTypes.number,
  saving: PropTypes.number,
  totalKilometers: PropTypes.number,
});

const driverFuelReportRouteRowListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    baseFuelRefillGallon: PropTypes.number,
    companyBusId: PropTypes.number,
    departureTime: PropTypes.number,
    destinationLocationName: PropTypes.string,
    driver: PropTypes.shape({
      id: PropTypes.number,
    }),
    expectedFuelConsumption: PropTypes.number,
    fuelVoucherCodes: PropTypes.string,
    kilometers: PropTypes.number,
    realFuelConsumption: PropTypes.number,
    sourceLocationName: PropTypes.string,
    busFuelGroupId: PropTypes.number,
    coDriverName: PropTypes.string,
  }),
);

const driverFuelReportCircuitRowListPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    bonus: PropTypes.number,
    busFuelGroupId: PropTypes.number,
    circuitDriverEfficiency: PropTypes.number,
    circuitQuantity: PropTypes.number,
    driver: PropTypes.shape({
      id: PropTypes.number,
    }),
    expectedAdblueConsumption: PropTypes.number,
    expectedFuelConsumption: PropTypes.number,
    performance: PropTypes.number,
    qualification: PropTypes.string,
    realAdblueConsumption: PropTypes.number,
    realFuelConsumption: PropTypes.number,
    totalKilometers: PropTypes.number,
  }),
);

const fuelConsumptionReportPerDriverPropTypes = PropTypes.shape({
  driverFuelReportHeaderPropTypes,
  driverFuelReportRouteRowListPropTypes,
  driverFuelReportCircuitRowListPropTypes,
});

export {
  driverFuelReportHeaderPropTypes,
  driverFuelReportRouteRowListPropTypes,
  driverFuelReportCircuitRowListPropTypes,
  fuelConsumptionReportPerDriverPropTypes,
};
