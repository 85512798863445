import { arrayOf, number, shape, string } from 'prop-types';
import { locationBasicInformationPropTypes } from '../../../location/location/proptypes/LocationPropTypes';
import { customerBasicInformationPropTypes } from '../../../user/customer/proptypes/CustomerPropTypes';

const driverBasicInformationPropTypes = {
  id: number.isRequired,
  customerId: number.isRequired,
  customer: shape(customerBasicInformationPropTypes).isRequired,
};

const crewStatusPropTypes = {
  crewStatus: shape({
    id: number.isRequired,
    name: string.isRequired,
    description: string,
  }),
};

const driverTypePropTypes = {
  crewStatus: shape({
    id: number.isRequired,
    name: string.isRequired,
    description: string,
  }),
};

const driverStatusInformationPropTypes = {
  homeBaseId: number.isRequired,
  homeBase: shape(locationBasicInformationPropTypes).isRequired,
  crewStatusId: number.isRequired,
  ...crewStatusPropTypes,
  driverTypeId: number.isRequired,
  ...driverTypePropTypes,
};

const driverContractTypePropTypes = {
  crewStatus: shape({
    id: number.isRequired,
    name: string.isRequired,
    description: string,
  }),
};

const driverDocumentTypePropTypes = {
  crewStatus: shape({
    id: number.isRequired,
    name: string.isRequired,
    description: string,
  }),
};

const driverContractListPropTypes = {
  driverContractList: arrayOf(
    shape({
      id: number.isRequired,
      driverId: number.isRequired,
      effectiveDate: number.isRequired,
      expirationDate: number,
      driverContractTypeId: number.isRequired,
      ...driverContractTypePropTypes,
      note: string,
      reason: string,
    }),
  ),
};

const driverDocumentListPropTypes = {
  driverDocumentList: arrayOf(
    shape({
      id: number.isRequired,
      driverId: number.isRequired,
      effectiveDate: number.isRequired,
      expirationDate: number.isRequired,
      documentNumber: string.isRequired,
      driverDocumentTypeId: number.isRequired,
      ...driverDocumentTypePropTypes,
      note: string,
    }),
  ),
};

export {
  driverBasicInformationPropTypes,
  driverStatusInformationPropTypes,
  driverContractListPropTypes,
  driverDocumentListPropTypes,
};
