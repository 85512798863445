import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PRIVATE_SERVICE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import PrivateServiceForm from './PrivateServiceForm';
import Loader from '../../../common/Loader';
import {
  clearPrivateService,
  getPrivateService,
  putPrivateService,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { getSubstring } from '../../../../utils/string';
import {
  CONTRACT_DOCUMENT_TYPE,
  CONTRACT_MOVEMENT_TYPE,
  CONTRACT_STATUS,
} from '../../../../config/constants';
import { currencyToNumber, numberFormatter } from '../../../../utils/number';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const EditPrivateService = ({
  match: {
    params: { id: privateServiceId },
  },
  dispatchGetPrivateService,
  breadcrumbs,
  privateService,
  loading,
  dispatchClearPrivateService,
  dispatchPutPrivateService,
}) => {
  useLayoutEffect(() => {
    dispatchGetPrivateService({ privateServiceId });

    return () => dispatchClearPrivateService();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    if (formValues.businessId)
      newFormValues.businessId = formValues.businessId.value;

    if (formValues.customerId)
      newFormValues.customerId = formValues.customerId.value;

    newFormValues.paymentMethodId = formValues.paymentMethodId.value;
    newFormValues.contractStatus = formValues.contractStatus.value;
    newFormValues.contractPaymentType = formValues.contractPaymentType.value;

    const sortedContractItineraryList = formValues.contractItineraryList.sort(
      (a, b) => {
        if (a.departureTime > b.departureTime) return 1;
        if (a.departureTime < b.departureTime) return -1;
        return 0;
      },
    );

    newFormValues.contractItineraryList = sortedContractItineraryList.map(
      ({
        movementType,
        registeredBusId,
        routeId,
        serviceTypeId,
        authorizedStop,
        routeDetail,
        itineraryId,
        kilometers,
        sourceAddress,
        departureTime,
        destinationAddress,
        arrivalTime,
        firstDriverId,
        secondDriverId,
        thirdDriverId,
        cabinCrewQuantity,
        customerList,
        expectedFuelConsumption,
      }) => ({
        movementType: movementType.value,
        registeredBusId: registeredBusId && registeredBusId.value,
        routeId: routeId && routeId.value,
        serviceTypeId: serviceTypeId && serviceTypeId.value,
        authorizedStop,
        routeDetail,
        itineraryId: itineraryId && getSubstring(itineraryId),
        kilometers,
        sourceAddress,
        departureTime:
          departureTime &&
          tzNormalizeDate({ date: departureTime, format: TIMESTAMP_FORMAT }),
        destinationAddress,
        arrivalTime:
          arrivalTime &&
          tzNormalizeDate({ date: arrivalTime, format: TIMESTAMP_FORMAT }),
        firstDriverId: firstDriverId && firstDriverId.value,
        secondDriverId: secondDriverId && secondDriverId.value,
        thirdDriverId: thirdDriverId && thirdDriverId.value,
        cabinCrewQuantity,
        customerList,
        expectedFuelConsumption,
      }),
    );

    newFormValues.totalAmount = currencyToNumber(formValues.totalAmount);
    newFormValues.amountBeforeDiscount = currencyToNumber(
      formValues.amountBeforeDiscount,
    );

    dispatchPutPrivateService(privateServiceId, newFormValues);
  };

  const generateInitialValues = ({
    clientDocumentNumber,
    clientName,
    clientAddress,
    clientContactName,
    clientContactPhone,
    clientContactEmail,
    serviceConcept,
    contractNumber,
    numberOfBusses,
    serviceType,
    includeFood,
    source,
    sourceAddress,
    destination,
    destinationAddress,
    contractItineraryList,
    driverCommission,
    driverDailyPayment,
    driverPaymentForFood,
    driverPaymentForAccommodation,
    totalAmount,
    business,
    customer,
    amountBeforeDiscount,
    amountPerBus,
    authorizedDiscount,
    discountComment,
    creditPayment,
    operationCode,
    creditComment,
    paymentMethod,
    contractStatus,
    contractPaymentType,
    executiveName,
    executivePosition,
    executivePhone,
    executiveEmail,
    busTollPayment,
    busParkingPayment,
    busGaragePayment,
  }) => {
    const sortedContractItineraryList = contractItineraryList.sort((a, b) => {
      if (a.departureTime > b.departureTime) return 1;
      if (a.departureTime < b.departureTime) return -1;
      return 0;
    });
    return {
      businessId: business && {
        value: business.id,
        label: `${business.businessTaxId} - ${business.name}`,
        businessTaxId: business.businessTaxId,
        name: business.name,
        email: business.email,
        primaryContact: business.primaryContact,
        phone: business.phone,
        fax: business.fax,
        addressSummary: business.addressSummary,
        addressId: business.addressId,
        address: business.address,
      },
      customerId: customer && {
        value: customer.id,
        label: `${customer.idDocumentNumber} (${customer.idCountryOfOrigin}) - ${customer.fullName}`,
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: customer.fullName,
        idDocumentNumber: customer.idDocumentNumber,
        gender: customer.gender,
        idCountryOfOrigin: customer.idCountryOfOrigin,
        identificationType: customer.identificationType,
        email: customer.email,
        mobilePhone: customer.mobilePhone,
        dob: customer.dob,
        discountCode: customer.discountCode,
      },
      isPerson: !!customer,
      clientDocumentNumber,
      clientName,
      clientAddress,
      clientContactName,
      clientContactPhone,
      clientContactEmail,
      serviceConcept,
      contractNumber,
      numberOfBusses,
      serviceType,
      includeFood,
      source,
      sourceAddress,
      destination,
      destinationAddress,
      executiveName,
      executivePosition,
      executivePhone,
      executiveEmail,
      contractItineraryListElements: 'true',
      contractItineraryList: sortedContractItineraryList.map(
        (contractItinerary) => ({
          movementType: {
            value: contractItinerary.movementType,
            label: CONTRACT_MOVEMENT_TYPE[contractItinerary.movementType].label,
          },
          registeredBusId: contractItinerary.registeredBus && {
            value: contractItinerary.registeredBusId,
            label: contractItinerary.registeredBus.companyBusId,
          },
          routeId: contractItinerary.routeId
            ? {
                value: contractItinerary.routeId,
                label: contractItinerary.route.name,
              }
            : null,
          serviceTypeId: contractItinerary.serviceTypeId
            ? {
                value: contractItinerary.serviceTypeId,
                label: contractItinerary.serviceType.name, // TODO change by name when backend return that value
              }
            : null,
          authorizedStop: contractItinerary.authorizedStop,
          routeDetail: contractItinerary.routeDetail,
          itineraryId: contractItinerary.itineraryId,
          kilometers: contractItinerary.kilometers,
          sourceAddress: contractItinerary.sourceAddress,
          departureTime: contractItinerary.departureTime
            ? tzNormalizeDate({ date: contractItinerary.departureTime })
            : null,
          destinationAddress: contractItinerary.destinationAddress,
          arrivalTime: contractItinerary.arrivalTime
            ? tzNormalizeDate({ date: contractItinerary.arrivalTime })
            : null,
          firstDriverId: contractItinerary.firstDriver && {
            value: contractItinerary.firstDriverId,
            label: contractItinerary.firstDriver.customer.fullName,
          },
          secondDriverId: contractItinerary.secondDriverId
            ? {
                value: contractItinerary.secondDriverId,
                label: contractItinerary.secondDriver.customer.fullName,
              }
            : null,
          thirdDriverId: contractItinerary.thirdDriverId
            ? {
                value: contractItinerary.thirdDriverId,
                label: contractItinerary.thirdDriver.customer.fullName,
              }
            : null,
          cabinCrewQuantity: contractItinerary.cabinCrewQuantity,
          customerList: contractItinerary.customerList,
          expectedFuelConsumption: contractItinerary.expectedFuelConsumption,
        }),
      ),
      driverCommission,
      driverDailyPayment,
      driverPaymentForFood,
      driverPaymentForAccommodation,
      totalAmount: numberFormatter({ value: totalAmount }),
      amountBeforeDiscount,
      amountPerBus,
      authorizedDiscount,
      discountComment,
      paymentMethodId: paymentMethod && {
        value: paymentMethod.id,
        label: paymentMethod.name,
      },
      contractPaymentType: CONTRACT_DOCUMENT_TYPE[contractPaymentType],
      contractStatus: contractStatus
        ? CONTRACT_STATUS[contractStatus]
        : CONTRACT_STATUS.ACEPTED,
      creditPayment,
      operationCode,
      creditComment,
      busTollPayment,
      busParkingPayment,
      busGaragePayment,
    };
  };

  let content = null;

  if (loading) content = <Loader />;
  else if (privateService.isEmpty())
    content = <NoDataResource returnPage={PRIVATE_SERVICE_PATH} />;
  else {
    content = (
      <PrivateServiceForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(privateService.toJS())}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Servicio Privado"
      subtitle="Edita esta servicio privado"
      content={content}
    />
  );
};

const mapStateToProps = (
  { ContractUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Servicios Privados',
      href: PRIVATE_SERVICE_PATH,
    },
    {
      text: 'Ver',
      href: `${PRIVATE_SERVICE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  privateService: ContractUnit.PrivateService.getIn(['current', 'content']),
  loading: !ContractUnit.PrivateService.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutPrivateService: putPrivateService,
  dispatchGetPrivateService: getPrivateService,
  dispatchClearPrivateService: clearPrivateService,
};

EditPrivateService.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutPrivateService: PropTypes.func.isRequired,
  dispatchGetPrivateService: PropTypes.func.isRequired,
  dispatchClearPrivateService: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  privateService: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditPrivateService.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPrivateService);
