import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Button } from 'reactstrap';
import printVoucherCargo from '../../../../../utils/printers/CargoVoucher';

const PrintCargoVoucherButton = ({ voucher }) => {
  const printVoucher = () => {
    printVoucherCargo(typeof voucher === 'object' ? voucher : voucher.toJSON());
  };

  return (
    <Button color="light" className="border" onClick={printVoucher}>
      <i className="fa fa-print" /> Imprimir
    </Button>
  );
};

PrintCargoVoucherButton.propTypes = {
  voucher: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.object])
    .isRequired,
};

export default PrintCargoVoucherButton;
