import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BASE_FUEL_REFILL_PATH } from '../../../../config/paths';
import {
  clearBaseFuelRefill,
  getBaseFuelRefill,
  putBaseFuelRefill,
} from '../../../../actions/traffic';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import BaseFuelRefillForm from './BaseFuelRefillForm';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { registeredBusGenerator } from '../../../../utils/app/json-generator-from-reducer';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

const EditBaseFuelRefill = ({
  breadcrumbs,
  dispatchPutBaseFuelRefill,
  dispatchGetBaseFuelRefill,
  dispatchClearBaseFuelRefill,
  match: {
    params: { id: baseFuelRefillId },
  },
  loading,
  baseFuelRefill,
}) => {
  useLayoutEffect(() => {
    dispatchGetBaseFuelRefill({ baseFuelRefillId });

    return () => dispatchClearBaseFuelRefill();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.registeredBusId =
      formValues.registeredBusId && formValues.registeredBusId.value;
    newFormValues.createDate = tzNormalizeDate({
      date: formValues.createDate,
      format: TIMESTAMP_FORMAT,
    });
    dispatchPutBaseFuelRefill(baseFuelRefillId, newFormValues);
  };

  const generateInitialValues = (baseFuelRefillJson) => ({
    registeredBusId: registeredBusGenerator(baseFuelRefillJson.registeredBus),
    fuelQuantity: baseFuelRefillJson.fuelQuantity,
    adblueQuantity: baseFuelRefillJson.adblueQuantity,
    comment: baseFuelRefillJson.comment,
    createDate: tzNormalizeDate({ date: baseFuelRefillJson.createDate }),
  });

  let content;

  if (loading) content = <Loader />;
  else if (baseFuelRefill.isEmpty())
    content = <NoDataResource returnPage={BASE_FUEL_REFILL_PATH} />;
  else
    content = (
      <BaseFuelRefillForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(baseFuelRefill.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Relleno de Combustible en Base"
      subtitle="Editar relleno de combustible en base"
      content={content}
    />
  );
};

EditBaseFuelRefill.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutBaseFuelRefill: PropTypes.func.isRequired,
  dispatchClearBaseFuelRefill: PropTypes.func.isRequired,
  dispatchGetBaseFuelRefill: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  baseFuelRefill: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditBaseFuelRefill.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchPutBaseFuelRefill: putBaseFuelRefill,
  dispatchGetBaseFuelRefill: getBaseFuelRefill,
  dispatchClearBaseFuelRefill: clearBaseFuelRefill,
};

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Rellenos de Combustible en Base',
      href: BASE_FUEL_REFILL_PATH,
    },
    {
      text: 'Ver',
      href: `${BASE_FUEL_REFILL_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  baseFuelRefill: TrafficUnit.BaseFuelRefill.getIn(['current', 'content']),
  loading: !TrafficUnit.BaseFuelRefill.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBaseFuelRefill);
