import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PROFILE_AUTHORIZED_BY_PROFILE_PATH } from '../../../../config/paths';
import {
  getAuthorizedProfile,
  clearAuthorizedProfile,
  getAuthorizedProfilesByProfileId,
  clearAuthorizedProfilesByProfileId,
} from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import AuthorizedProfileByProfileToolbar from './AuthorizedProfileByProfileToolbar';
import BadgeList from '../../../common/BadgeList';
import Content from '../../../layout/Content';

class AuthorizedProfileByProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: 0,
    };
  }

  componentDidMount() {
    const {
      dispatchGetAuthorizedProfile,
      match: {
        params: { id: authorizedProfileId },
      },
    } = this.props;
    dispatchGetAuthorizedProfile({ authorizedProfileId });
  }

  componentDidUpdate() {
    this.onUpdate();
  }

  componentWillUnmount() {
    const {
      dispatchClearAuthorizedProfile,
      dispatchClearAuthorizedProfilesByProfileId,
    } = this.props;
    dispatchClearAuthorizedProfile();
    dispatchClearAuthorizedProfilesByProfileId();
  }

  onUpdate = () => {
    const { authorizedProfile, dispatchGetAuthorizedProfilesByProfileId } =
      this.props;

    const { profileId } = this.state;

    if (authorizedProfile.get('profileId') && profileId === 0) {
      dispatchGetAuthorizedProfilesByProfileId({
        profileId: authorizedProfile.get('profileId'),
      });

      this.setState({ profileId: authorizedProfile.get('profileId') });
    }
  };

  render() {
    const {
      breadcrumbs,
      authorizedProfile,
      loading,
      authorizedProfilesByProfileId,
    } = this.props;

    let content = null;
    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (authorizedProfile.isEmpty()) {
      content = (
        <NoDataResource returnPage={PROFILE_AUTHORIZED_BY_PROFILE_PATH} />
      );
    } else {
      const authorizedProfileSet = authorizedProfilesByProfileId
        .map((profile) => ({
          text: profile.authorizedSecurityProfile.name,
        }))
        .toJS();

      content = (
        <Fragment>
          <h4>Información del Registro Consultado</h4>
          <ResourceProperty label="ID:">
            {authorizedProfile.get('id')}
          </ResourceProperty>
          <ResourceProperty label="Perfil de Seguridad:">
            {authorizedProfile.get('profile').name}
          </ResourceProperty>
          <ResourceProperty label="Perfil de Seguridad Autorizado:">
            {authorizedProfile.get('authorizedSecurityProfile').name}
          </ResourceProperty>
          <hr />
          <h4>
            Perfiles de Seguridad Autorizados para el Perfil &quot;
            {authorizedProfile.get('profile').name}&quot;
          </h4>
          <BadgeList textArray={authorizedProfileSet} />
        </Fragment>
      );

      toolbar = (
        <AuthorizedProfileByProfileToolbar
          authorizedProfileId={authorizedProfile.get('id')}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Perfil Autorizado por Perfil de Seguridad"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

AuthorizedProfileByProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetAuthorizedProfile: PropTypes.func.isRequired,
  dispatchClearAuthorizedProfile: PropTypes.func.isRequired,
  dispatchGetAuthorizedProfilesByProfileId: PropTypes.func.isRequired,
  dispatchClearAuthorizedProfilesByProfileId: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  authorizedProfile: PropTypes.instanceOf(Immutable.Map).isRequired,
  authorizedProfilesByProfileId: PropTypes.instanceOf(Immutable.Set).isRequired,
};

AuthorizedProfileByProfile.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ SecurityUnit }) => ({
  breadcrumbs: [
    ...SecurityUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles Autorizados por Perfil de Seguridad',
      href: PROFILE_AUTHORIZED_BY_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  authorizedProfile: SecurityUnit.AuthorizedProfile.getIn([
    'current',
    'content',
  ]),
  loading: !SecurityUnit.AuthorizedProfile.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  authorizedProfilesByProfileId: SecurityUnit.AuthorizedProfile.getIn([
    'current',
    'authorizedProfilesByProfileId',
  ]),
});

const mapDispatchToProps = {
  dispatchGetAuthorizedProfile: getAuthorizedProfile,
  dispatchClearAuthorizedProfile: clearAuthorizedProfile,
  dispatchGetAuthorizedProfilesByProfileId: getAuthorizedProfilesByProfileId,
  dispatchClearAuthorizedProfilesByProfileId:
    clearAuthorizedProfilesByProfileId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorizedProfileByProfile);
