import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';
import { routerReducer } from 'react-router-redux';
import authentication from './authentication';
import endpoint from './endpoint';
import BusUnit from './units/bus';
import UserUnit from './units/user/';
import LocationUnit from './units/location';
import RouteUnit from './units/route';
import ItineraryUnit from './units/itinerary';
import BookingUnit from './units/booking';
import ReportUnit from './units/report';
import ReservationUnit from './units/reservation';
import SalesUnit from './units/sales';
import TrafficUnit from './units/traffic';
import MacivaManagement from './units/maciva-management/MacivaManagement';
import HumanResourcesUnit from './units/human-resources';
import SecurityUnit from './units/security';
import AccountingUnit from './units/accounting';
import SystemUnit from './units/system';
import PostpaidBookingUnit from './units/postpaid-booking';
import TwoStepAuthorization from './units/authentication/TwoStepAuthorization';
import BaggageUnit from './units/baggage';
import CargoUnit from './units/cargo';
import ContractUnit from './units/contract';
import MechanicalMaintenanceUnit from './units/mechanical-maintenance';

export default combineReducers({
  form,
  toastr,
  authentication,
  endpoint,
  BusUnit,
  UserUnit,
  LocationUnit,
  RouteUnit,
  ItineraryUnit,
  ReservationUnit,
  SalesUnit,
  TrafficUnit,
  MacivaManagement,
  HumanResourcesUnit,
  SecurityUnit,
  AccountingUnit,
  BookingUnit,
  PostpaidBookingUnit,
  BaggageUnit,
  CargoUnit,
  SystemUnit,
  ReportUnit,
  TwoStepAuthorization,
  ContractUnit,
  MechanicalMaintenanceUnit,
  routing: routerReducer,
});
