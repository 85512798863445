import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import LiquidationSessionEditButton from './LiquidationSessionEditButton';

const LiquidationSessionToolbar = ({ liquidationSessionId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <LiquidationSessionEditButton
        liquidationSessionId={liquidationSessionId}
      />
    </div>
  </ButtonToolbar>
);

LiquidationSessionToolbar.propTypes = {
  liquidationSessionId: PropTypes.number.isRequired,
};

export default LiquidationSessionToolbar;
