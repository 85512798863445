import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAgencyGroups, clearAgencyGroups } from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { optionsPropTypes } from './SelectPropTypes';

class AgencyGroupSelect extends Component {
  componentDidMount() {
    const { dispatchGetAgencyGroups } = this.props;
    dispatchGetAgencyGroups({ ...DEFAULT_QUERY_GET_ALL });
  }

  componentWillUnmount() {
    const { dispatchClearAgencyGroups } = this.props;
    dispatchClearAgencyGroups();
  }

  render() {
    const {
      agencyGroups,
      isLoading,
      isMulti,
      input,
      meta,
      isDisabled,
      ...rest
    } = this.props;
    return (
      <Select
        options={agencyGroups}
        isLoading={isLoading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        isDisabled={isDisabled}
        {...rest}
      />
    );
  }
}

AgencyGroupSelect.propTypes = {
  dispatchGetAgencyGroups: PropTypes.func.isRequired,
  dispatchClearAgencyGroups: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  agencyGroups: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

AgencyGroupSelect.defaultProps = {
  isLoading: false,
  isDisabled: false,
};

AgencyGroupSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  agencyGroups: [],
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  agencyGroups: HumanResourcesUnit.AgencyGroup.getIn([
    'all',
    'content',
    'content',
  ]).map(({ name, id }) => ({
    value: id,
    label: name,
  })),
  loading: HumanResourcesUnit.AgencyGroup.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetAgencyGroups: getAgencyGroups,
  dispatchClearAgencyGroups: clearAgencyGroups,
};

export default connect(mapStateToProps, mapDispatchToprops)(AgencyGroupSelect);
