import React from 'react';
import { ListGroup } from 'reactstrap';
import BoardColumn from './BoardColumn';
import { BoardPropTypes } from './prop-types/BoardPropTypes';

const Board = ({ columns }) => (
  <ListGroup className="d-flex overflow-auto list-group-horizontal">
    {columns.map(({ status, elements }) => (
      <BoardColumn key={status} status={status} elements={elements} />
    ))}
  </ListGroup>
);

Board.propTypes = BoardPropTypes;

export default Board;
