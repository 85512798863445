import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  NEW_MAINTENANCE_WARNING_PATH,
  MAINTENANCE_WARNING_PATH,
} from '../../../../config/paths';
import {
  getMaintenanceWarnings,
  clearMaintenanceWarnings,
} from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { MAINTENANCE_WARNING_COLUMNS } from '../../../../config/columns';

export const MaintenanceWarnings = ({
  breadcrumbs,
  warnings,
  loading,
  dispatchGetMaintenanceWarnings,
  dispatchClearMaintenanceWarnings,
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenanceWarnings(), []);

  const data = warnings.get('content') || [];
  const pages = warnings.get('totalPages') || null;
  const defaultPageSize = warnings.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Reportes de Ocurrencia"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_MAINTENANCE_WARNING_PATH}
      tableStructure={{
        columns: MAINTENANCE_WARNING_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetMaintenanceWarnings,
        modelPath: MAINTENANCE_WARNING_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetMaintenanceWarnings: getMaintenanceWarnings,
  dispatchClearMaintenanceWarnings: clearMaintenanceWarnings,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reportes de Ocurrencia',
      href: MAINTENANCE_WARNING_PATH,
    },
  ],
  warnings: MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'all',
    'loading',
  ]),
});

MaintenanceWarnings.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  warnings: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetMaintenanceWarnings: PropTypes.func.isRequired,
  dispatchClearMaintenanceWarnings: PropTypes.func.isRequired,
};

MaintenanceWarnings.defaultProps = {
  warnings: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceWarnings);
