import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Loader from '../../../common/Loader';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import BasePricePerKilometerForm from './BasePricePerKilometerForm';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { BASE_PRICE_PER_KILOMETER_PATH } from '../../../../config/paths';
import {
  clearBasePricePerKilometer,
  getBasePricePerKilometer,
  putBasePricePerKilometer,
} from '../../../../actions/cargo';

class EditBasePricePerKilometer extends Component {
  componentDidMount() {
    const {
      dispatchGetBasePricePerKilometer,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetBasePricePerKilometer({ id });
  }

  componentWillUnmount() {
    const { dispatchClearBasePricePerKilometer } = this.props;
    dispatchClearBasePricePerKilometer();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutBasePricePerKilometer,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutBasePricePerKilometer(id, formValues);
  };

  createInitialValues = (basePricePerKilometer) => ({
    fromKilometer: basePricePerKilometer.get('fromKilometer'),
    toKilometer: basePricePerKilometer.get('toKilometer'),
    price: basePricePerKilometer.get('price'),
    kilometerFactor: basePricePerKilometer.get('kilometerFactor'),
  });

  render() {
    const { breadcrumbs, basePricePerKilometer, loading } = this.props;

    if (loading || basePricePerKilometer.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Editar Precio Base por Kilómetro</h1>
          </Col>
        </Row>
        <BasePricePerKilometerForm
          onSubmit={this.onSubmit}
          initialValues={this.createInitialValues(basePricePerKilometer)}
        />
      </Container>
    );
  }
}

EditBasePricePerKilometer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBasePricePerKilometer: PropTypes.func.isRequired,
  dispatchClearBasePricePerKilometer: PropTypes.func.isRequired,
  dispatchPutBasePricePerKilometer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  basePricePerKilometer: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditBasePricePerKilometer.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Precio Base por Kilómetro',
      href: BASE_PRICE_PER_KILOMETER_PATH,
    },
    {
      text: 'Ver',
      href: `${BASE_PRICE_PER_KILOMETER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  basePricePerKilometer: CargoUnit.BasePricePerKilometer.getIn([
    'current',
    'content',
  ]),
  loading: CargoUnit.BasePricePerKilometer.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetBasePricePerKilometer: getBasePricePerKilometer,
  dispatchClearBasePricePerKilometer: clearBasePricePerKilometer,
  dispatchPutBasePricePerKilometer: putBasePricePerKilometer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditBasePricePerKilometer);
