import { string, instanceOf, shape, number } from 'prop-types';

const parcelBasicInformationPropTypes = {
  cargoItemList: instanceOf(Object).isRequired,
  cargoDelivery: shape({
    district: shape({
      name: string,
    }),
    address: string,
  }),
  detractionAmount: number,
  salePriceWithDetraction: number,
  customer: shape({
    fullName: string,
    idDocumentNumber: string,
    identificationType: shape({
      name: string,
    }),
  }),
  business: shape({
    name: string,
    businessTaxId: number,
  }),
  voucherTypeName: string.isRequired,
  documentSeries: string.isRequired,
  documentCode: number.isRequired,
  sourceCityName: string.isRequired,
  destinationCityName: string.isRequired,
  createDate: number.isRequired,
  senderCustomerFullName: string.isRequired,
  senderCustomer: shape({
    fullName: string,
    idDocumentNumber: string,
    identificationType: shape({
      name: string,
    }),
  }).isRequired,
  consigneeCustomerFullName: string.isRequired,
  consigneeCustomer: shape({
    fullName: string,
    idDocumentNumber: string,
    identificationType: shape({
      name: string,
    }),
  }).isRequired,
  paymentMethodName: string.isRequired,
  salePrice: number.isRequired,
  attachedDocument: string,
  paymentStatus: string.isRequired,
  salesSessionId: number.isRequired,
  comments: string,
  financialStatus: string.isRequired,
  user: shape({
    customer: shape({
      fullName: string,
    }),
  }),
  lastUpdateUser: shape({
    customer: shape({
      fullName: string,
    }),
  }),
};

export default parcelBasicInformationPropTypes;
