import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import DeliveryGroupForm from './DeliveryGroupForm';
import { DELIVERY_GROUP_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getDeliveryGroup,
  clearDeliveryGroup,
  putDeliveryGroup,
} from '../../../../actions';
import Loader from '../../../common/Loader';

export class EditDeliveryGroup extends Component {
  componentDidMount() {
    const {
      dispatchGetDeliveryGroup,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetDeliveryGroup({ deliveryGroupId: id });
  }

  componentWillUnmount() {
    const { dispatchClearDeliveryGroup } = this.props;
    dispatchClearDeliveryGroup();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutDeliveryGroup,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutDeliveryGroup(id, formValues);
  };

  render() {
    const { breadcrumbs, deliveryGroup, loading } = this.props;
    if (loading || deliveryGroup.isEmpty()) {
      return <Loader />;
    }
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Editar Grupo Delivery</h1>
            <p>
              Usted esta editando el Grupo Delivery{' '}
              <strong>
                {'"'}
                {deliveryGroup.get('name')}
                {'"'}
              </strong>
              .
            </p>
          </Col>
        </Row>
        <DeliveryGroupForm
          onSubmit={this.onSubmit}
          initialValues={{
            name: deliveryGroup.get('name'),
            description: deliveryGroup.get('description'),
          }}
        />
      </Container>
    );
  }
}

EditDeliveryGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDeliveryGroup: PropTypes.func.isRequired,
  dispatchClearDeliveryGroup: PropTypes.func.isRequired,
  dispatchPutDeliveryGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  deliveryGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDeliveryGroup.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos Delivery',
      href: DELIVERY_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: `${DELIVERY_GROUP_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  deliveryGroup: CargoUnit.deliveryGroup.getIn(['current', 'content']),
  loading: !CargoUnit.deliveryGroup.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDeliveryGroup: getDeliveryGroup,
  dispatchClearDeliveryGroup: clearDeliveryGroup,
  dispatchPutDeliveryGroup: putDeliveryGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDeliveryGroup);
