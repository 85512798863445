import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NEW_EXPENSE_PATH, EXPENSE_PATH } from '../../../../config/paths';
import { postExpense } from '../../../../actions';
import ExpenseForm from './ExpenseForm';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

export class NewExpense extends Component {
  onSubmit = (formValues) => {
    const postExpenseFormValues = {
      amount: formValues.amount,
      authorizerUserId: formValues.authorizerUserId.value,
      businessId: formValues.business ? formValues.business.value : null,
      description: formValues.description,
      documentCode: formValues.documentCode,
      documentSeries: formValues.documentSeries,
      createDate: formValues.expenseTypeId.createDate,
      expenseDescription: formValues.expenseTypeId.description,
      expenseCode: formValues.expenseTypeId.expenseCode,
      expenseTypeLabel: formValues.expenseTypeId.expenseTypeLabel,
      lastUpdate: formValues.expenseTypeId.lastUpdate,
      name: formValues.expenseTypeId.label,
      expenseTypeId: formValues.expenseTypeId.value,
      isSettled: formValues.isSettled || false,
      payeeCustomerId: formValues.payeeCustomerId.value,
      registeredBusId: formValues.registeredBusId
        ? formValues.registeredBusId.value
        : null,
      voucherTime: formValues.voucherTime
        ? tzNormalizeDate({
            date: formValues.voucherTime,
            format: TIMESTAMP_FORMAT,
          })
        : tzNormalizeDate({ format: TIMESTAMP_FORMAT }),
      voucherTypeId: formValues.voucherTypeId.value,
      costCenterId: formValues.costCenter ? formValues.costCenter.value : null,
    };
    let postBusinessFormValues = {
      businessTaxId: null,
      businessName: null,
      addressSummary: null,
    };
    if (formValues.businessTaxId) {
      postBusinessFormValues = {
        businessTaxId: formValues.businessTaxId,
        businessName: formValues.businessName,
        addressSummary: formValues.addressSummary,
      };
    }
    this.props.dispatchPostExpense(
      postExpenseFormValues,
      postBusinessFormValues,
    );
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Egreso"
        subtitle="Crear nuevo egreso"
        content={<ExpenseForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Egresos', href: EXPENSE_PATH },
    { text: 'Nuevo', href: NEW_EXPENSE_PATH },
  ],
});

const mapDispatchToProps = { dispatchPostExpense: postExpense };

NewExpense.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  dispatchPostExpense: PropTypes.func.isRequired,
};

NewExpense.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(NewExpense);
