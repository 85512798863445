import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import {
  KILOMETER_SYMBOL,
  MATERIAL_MEASUREMENT_UNIT,
} from '../../../../../config/constants';
import { numberFormatter } from '../../../../../utils/number';

const ItineraryFuelHeader = ({
  totalKilometers,
  totalExpectedFuelConsumption,
  totalOfCalculatedFuelConsumption,
}) => (
  <Row className="text-center">
    <Col>
      <h5>Total {KILOMETER_SYMBOL}</h5>
      <div className="text-info">
        <strong>
          {numberFormatter({
            style: 'decimal',
            value: totalKilometers,
            minimumFractionDigits: 2,
          })}{' '}
          {KILOMETER_SYMBOL}
        </strong>
      </div>
    </Col>
    <Col>
      <h5>Dotación Autorizada</h5>
      <div className="text-primary">
        <strong>
          {numberFormatter({
            style: 'decimal',
            value: totalExpectedFuelConsumption,
            minimumFractionDigits: 2,
          })}{' '}
          {MATERIAL_MEASUREMENT_UNIT[1]}
        </strong>
      </div>
    </Col>
    <Col>
      <h5>Consumo Real</h5>
      <div className="text-success">
        <strong>
          {numberFormatter({
            style: 'decimal',
            value: totalOfCalculatedFuelConsumption,
            minimumFractionDigits: 2,
          })}{' '}
          {MATERIAL_MEASUREMENT_UNIT[1]}
        </strong>
      </div>
    </Col>
  </Row>
);

ItineraryFuelHeader.propTypes = {
  totalOfCalculatedFuelConsumption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  totalExpectedFuelConsumption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  totalKilometers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ItineraryFuelHeader.defaultProps = {
  totalOfCalculatedFuelConsumption: null,
  totalExpectedFuelConsumption: null,
  totalKilometers: null,
};

export default ItineraryFuelHeader;
