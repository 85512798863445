import { number, string } from 'prop-types';

const endpointBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string,
  route: string.isRequired,
  permissionType: string.isRequired,
  lastUpdate: number,
  createDate: number,
};

const endpointBasicInformationDefaultProps = {
  description: null,
  lastUpdate: null,
  createDate: null,
};

export {
  endpointBasicInformationPropTypes,
  endpointBasicInformationDefaultProps,
};
