import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Col, Container, Form, Row } from 'reactstrap';
import Loader from '../../../common/Loader';
import NoteListResource from '../../../common/resource/NoteListResource';
import DynamicForm from '../../../common/forms/DynamicForm';
import { EXPENSE_STATUS_REMARK_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import {
  clearExpenseStatusRemark,
  getExpenseStatusRemark,
  postExpenseStatusRemark,
} from '../../../../actions/sales/ExpenseStatus';
import { EXPENSE_STATUS_PATH } from '../../../../config/paths';
import {
  breadcrumbPropType,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import FormFooter from '../../../common/forms/FormFooter';

class ExpenseStatusRemarkResource extends Component {
  componentDidMount() {
    const {
      dispatchGetExpenseStatusRemark,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetExpenseStatusRemark(id);
  }

  componentWillUnmount() {
    this.props.dispatchClearExpenseStatusRemark();
  }

  onSubmitExpenseStatusRemark = async (formValues) => {
    const {
      match: {
        params: { id },
      },
      dispatchPostExpenseStatusRemark,
    } = this.props;

    const { expenseStatusRemarkList } = formValues;

    if (expenseStatusRemarkList && expenseStatusRemarkList.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const expenseStatus of expenseStatusRemarkList) {
        // eslint-disable-next-line no-await-in-loop
        await dispatchPostExpenseStatusRemark({
          expenseId: id,
          remarks: expenseStatus.remarks,
        });
      }
    }
  };

  render() {
    const { loading, handleSubmit, remarks, breadcrumbs } = this.props;

    if (loading) {
      return <Loader />;
    }

    const notes = remarks.toJSON().map((remark) => ({
      note: remark.remarks,
    }));

    const expenseStatusAddRemarkForm = (
      <Form onSubmit={handleSubmit(this.onSubmitExpenseStatusRemark)}>
        <h3>Notas Anteriores</h3>
        <NoteListResource values={notes} />
        <h3>Notas Nuevas</h3>
        <DynamicForm
          name="expenseStatusRemarkList"
          columns={EXPENSE_STATUS_REMARK_FORM_COLUMNS}
        />
        <FormFooter />
      </Form>
    );

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col>
            <h1>Agregar nuevas notas</h1>
            <p>Aquí puede agregar una o varias notas</p>
          </Col>
        </Row>
        {expenseStatusAddRemarkForm}
      </Container>
    );
  }
}

ExpenseStatusRemarkResource.propTypes = {
  breadcrumbs: breadcrumbPropType.isRequired,
  match: matchPropTypes.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchPostExpenseStatusRemark: PropTypes.func.isRequired,
  dispatchGetExpenseStatusRemark: PropTypes.func.isRequired,
  dispatchClearExpenseStatusRemark: PropTypes.func.isRequired,
  remarks: PropTypes.instanceOf(Immutable.Set).isRequired,
};

const mapStateToProps = (
  { SalesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Autorización de Egresos', href: EXPENSE_STATUS_PATH },
    { text: 'Ver', href: `${EXPENSE_STATUS_PATH}/${id}` },
    { text: 'Agregar nuevas nostas', href: '' },
  ],
  loading: !SalesUnit.ExpenseStatus.getIn(['remark', 'activity']).isEmpty(),
  remarks: SalesUnit.ExpenseStatus.getIn(['remark', 'content']),
});

const mapDispatchToProps = {
  dispatchPostExpenseStatusRemark: postExpenseStatusRemark,
  dispatchGetExpenseStatusRemark: getExpenseStatusRemark,
  dispatchClearExpenseStatusRemark: clearExpenseStatusRemark,
};

const formComponent = reduxForm({
  form: 'ExpenseStatusRemarkResource',
})(ExpenseStatusRemarkResource);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
