import React from 'react';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { MANUAL_VOUCHER_PATH } from '../../../../config/paths';
import { padStart } from '../../../../utils/string';
import {
  manualVoucherDataDefaultProps,
  manualVoucherDataPropTypes,
} from './proptypes/ManualVoucherPropTypes';

const BaseDocument = ({ id, documentSeries, documentCode }) =>
  id && (
    <>
      <h3>Historial</h3>
      <ResourceProperty label="Documento Base:">
        <a href={`${MANUAL_VOUCHER_PATH}/${id}`}>
          {documentSeries}-{padStart(documentCode, 7)}
        </a>
      </ResourceProperty>
    </>
  );

BaseDocument.propTypes = manualVoucherDataPropTypes;

BaseDocument.defaultProps = manualVoucherDataDefaultProps;

export default BaseDocument;
