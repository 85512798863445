import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { getPrintedTicketsByContractId } from '../../../../actions';
import { printPrintedTickets } from '../../../../utils/printers/Ticket';

class PrintTicketsButton extends Component {
  onClickPrint = async () => {
    const { dispatchGetPrintedTicketsByContractId, privateServiceId } =
      this.props;

    const tickets = await dispatchGetPrintedTicketsByContractId({
      privateServiceId,
    });

    if (tickets) printPrintedTickets(tickets);
  };

  render() {
    return (
      <Button
        color="light"
        className="border"
        disabled={this.props.disabled}
        onClick={this.onClickPrint}
      >
        <i className="fa fa-ticket" /> Boletos
      </Button>
    );
  }
}

PrintTicketsButton.propTypes = {
  privateServiceId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  dispatchGetPrintedTicketsByContractId: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetPrintedTicketsByContractId: getPrintedTicketsByContractId,
};

export default connect(null, mapDispatchToProps)(PrintTicketsButton);
