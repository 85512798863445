import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const EditButton = ({ path, disabled }) =>
  disabled ? (
    <Button color="primary" disabled>
      <i className="fa fa-pencil-square-o" /> Editar
    </Button>
  ) : (
    <Link className="btn btn-primary" to={path}>
      <i className="fa fa-pencil-square-o" /> Editar
    </Link>
  );

EditButton.propTypes = {
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

EditButton.defaultProps = {
  disabled: false,
};

export default EditButton;
