import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  NEW_COMMON_PRODUCT_PATH,
  COMMON_PRODUCT_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { COMMON_PRODUCT_COLUMNS } from '../../../../config/columns';
import {
  clearCommonProducts,
  getCommonProducts,
} from '../../../../actions/cargo/CommonProduct';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class CommonProducts extends Component {
  constructor(props) {
    super(props);
    this.columns = COMMON_PRODUCT_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearCommonProducts();
  }

  render() {
    const { commonProducts, loading, breadcrumbs, dispatchGetCommonProducts } =
      this.props;

    const data = commonProducts.get('content') || [];
    const pages = commonProducts.get('totalPages') || null;
    const defaultPageSize = commonProducts.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Productos Frecuentes"
        buttonPath={NEW_COMMON_PRODUCT_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetCommonProducts,
          modelPath: COMMON_PRODUCT_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetCommonProducts: getCommonProducts,
  dispatchClearCommonProducts: clearCommonProducts,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Productos Frecuentes',
      href: COMMON_PRODUCT_PATH,
    },
  ],
  commonProducts: CargoUnit.CommonProduct.getIn(['all', 'content']),
  loading: CargoUnit.CommonProduct.getIn(['all', 'loading']),
});

CommonProducts.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  commonProducts: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCommonProducts: PropTypes.func.isRequired,
  dispatchClearCommonProducts: PropTypes.func.isRequired,
};

CommonProducts.defaultProps = {
  commonProducts: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonProducts);
