import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { isRequired } from '../../../../utils/validators';
import {
  getItineraryFoodGroup,
  deselectSeat,
  clearCustomers,
} from '../../../../actions/';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import Select from '../../../common/forms/select/Select';
import { orderBy } from '../../../../utils/array';
import FormItem from '../../../common/forms/FormItem';
import { RELATIONSHIP } from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import TextInput from '../../../common/forms/input/TextInput';
import Badge from '../../../common/Badge';
import { tzNormalizeDate } from '../../../../utils/date';

class PassengerForm extends Component {
  static hasValidSeat({ value }, allValues) {
    let duplicateCount = 0;
    allValues.passengers.forEach((passenger) => {
      const { seatReservation } = passenger;
      if (seatReservation && seatReservation.value === value) {
        duplicateCount += 1;
      }
    });

    return duplicateCount >= 2 ? 'Asiento repetido' : undefined;
  }

  constructor(props) {
    super(props);

    this.state = {
      customerDisabled: false,
      underageDisabled: true,
      showUnderageExtraFields: false,
      grownUpPassenger: null,
      underagePassenger: null,
      relationship: null,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const { dispatchGetItineraryFoodGroup, itineraryId } = this.props;

    dispatchGetItineraryFoodGroup(itineraryId);

    this.validateSelfServiceCustomer();
  };

  onChangeGrownUpPassenger = (customer) => {
    const { passenger, dispatchChange, dispatchClearCustomers } = this.props;

    dispatchClearCustomers();

    const selectedCustomer = customer && customer.firstName;

    if (!selectedCustomer) {
      dispatchChange('PassengersForm', `${passenger}.kidCustomer`, null);
      dispatchChange('PassengersForm', `${passenger}.relationship`, null);
    }

    this.setState({
      grownUpPassenger: customer && customer.fullName,
      underagePassenger: null,
      relationship: null,
      showUnderageExtraFields: false,
      underageDisabled: !selectedCustomer,
    });
  };

  onChangeUnderagePassenger = (customer) => {
    const { passenger, dispatchChange } = this.props;

    const selectedCustomer = customer && customer.firstName;

    if (!selectedCustomer)
      dispatchChange('PassengersForm', `${passenger}.relationship`, null);

    this.setState({
      showUnderageExtraFields: customer && customer.firstName,
      relationship: null,
      underagePassenger: customer && customer.fullName,
    });
  };

  onChangeRelationship = (relationship) =>
    this.setState({ relationship: relationship && relationship.label });

  onClickDeselectSeat = () => {
    const { seatReservations, index, dispatchDeselectSeat, fields } =
      this.props;

    const {
      value: seatReservationId,
      label: seatId,
      itineraryId,
    } = seatReservations[index];

    fields.remove(index);

    dispatchDeselectSeat({
      seatReservationId,
      itineraryId,
      seatId,
    });
  };

  validateSelfServiceCustomer = () => {
    const { selfServiceCustomer, dispatchChange, passenger } = this.props;

    if (selfServiceCustomer) {
      const passengerValue = {
        value: selfServiceCustomer.value,
        label: selfServiceCustomer.label,
      };

      dispatchChange(
        'PassengersForm',
        `${passenger}.passenger`,
        passengerValue,
      );

      this.setState({ customerDisabled: true });
    }
  };

  render() {
    const {
      seatReservations,
      index,
      foodOptions,
      seatSelectionChangesInProgress,
      passenger,
    } = this.props;

    const {
      customerDisabled,
      underageDisabled,
      showUnderageExtraFields,
      grownUpPassenger,
      underagePassenger,
      relationship,
    } = this.state;

    let foodInput = null;
    if (foodOptions) {
      foodInput = (
        <FormGroup row>
          <FormItem label="Alimento" required>
            <Field
              name={`${passenger}.food`}
              component={Select}
              options={foodOptions}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      );
    }

    let route = null;
    let deleteButton = null;
    if (seatReservations[index]) {
      const {
        itinerary: {
          originName,
          destinationName,
          serviceName,
          departureTime,
          arrivalTime,
        },
      } = seatReservations[index];

      // departure
      const formattedDepartureTime = tzNormalizeDate({
        date: departureTime,
        format: DATE_TIME_FORMAT,
      });

      // arrival
      const formattedArrivalTime = tzNormalizeDate({
        date: arrivalTime,
        format: DATE_TIME_FORMAT,
      });

      route = (
        <>
          <div className="mr-2">({serviceName})</div>
          <div className="text-center">
            {originName}{' '}
            <p>
              <small>{formattedDepartureTime}</small>
            </p>
          </div>
          <div className="mr-3 ml-3"> &rarr; </div>
          <div className="text-center">
            {destinationName}{' '}
            <p>
              <small>{formattedArrivalTime}</small>
            </p>
          </div>
        </>
      );

      let deselectSeatFunction = null;

      if (seatSelectionChangesInProgress.size === 0) {
        deselectSeatFunction = this.onClickDeselectSeat;
      }

      deleteButton = (
        <i
          className="fa fa-times fa-2x text-danger clickable"
          role="button"
          onClick={deselectSeatFunction}
          onKeyPress={deselectSeatFunction}
          tabIndex={0}
        />
      );
    }

    const header = (
      <div className="clearfix">
        <div className="float-left">
          <h5>
            <div className="d-flex">
              <div className="mr-3">Pasajero {index + 1}: </div> {route}
            </div>
          </h5>
        </div>
        <div className="float-right">{deleteButton}</div>
      </div>
    );

    const underageExtraFields = showUnderageExtraFields && (
      <>
        <FormGroup row>
          <FormItem label="Relación con el Menor">
            <Field
              name={`${passenger}.relationship`}
              component={Select}
              options={enumToSelectOptions(RELATIONSHIP)}
              onChange={this.onChangeRelationship}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Observaciones">
            <Field
              name={`${passenger}.comments`}
              component={TextInput}
              type="textarea"
              placeholder="Observaciones"
            />
          </FormItem>
        </FormGroup>
      </>
    );

    const relationshipText =
      RELATIONSHIP.OTHER.label !== relationship
        ? `${grownUpPassenger} es ${relationship} de ${underagePassenger}`
        : `${grownUpPassenger} tiene otro tipo de relación con ${underagePassenger}`;
    const underageDetails = underagePassenger &&
      grownUpPassenger &&
      relationship && (
        <Badge color="success" text={<small>{relationshipText}</small>} />
      );

    return (
      <div>
        <hr />
        {header}
        <CustomerInputGroup
          label="Pasajero"
          labelRequired
          name={`${passenger}.passenger`}
          form="PassengersForm"
          mobilePhoneRequired
          disabled={customerDisabled}
          onChange={this.onChangeGrownUpPassenger}
          showDetails
          showDiscountMessage
        />
        <FormGroup row>
          <FormItem label="Asiento" required>
            <Field
              name={`${passenger}.seatReservation`}
              component={Select}
              options={seatReservations}
              isDisabled
              validate={[isRequired, PassengerForm.hasValidSeat]}
            />
          </FormItem>
        </FormGroup>
        {foodInput}
        <div className="mr-3 ml-3 border rounded shadow-sm">
          <div className="pt-3 pr-3 pl-3 pb-1">
            <h5>Datos del Menor de Edad: {underageDetails}</h5>
            <CustomerInputGroup
              label="Menor de Edad"
              name={`${passenger}.kidCustomer`}
              form="PassengersForm"
              disabled={underageDisabled}
              onChange={this.onChangeUnderagePassenger}
              showDetails
              isClearable
            />
            {underageExtraFields}
          </div>
        </div>
      </div>
    );
  }
}

PassengerForm.propTypes = {
  index: PropTypes.number.isRequired,
  passenger: PropTypes.string.isRequired,
  foodOptions: Select.propTypes.options,
  seatReservations: Select.propTypes.options.isRequired,
  dispatchGetItineraryFoodGroup: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  itineraryId: PropTypes.number.isRequired,
  dispatchDeselectSeat: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object.isRequired,
  seatSelectionChangesInProgress: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
  selfServiceCustomer: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    salesSessionUserId: PropTypes.number,
  }),
  dispatchClearCustomers: PropTypes.func.isRequired,
};

PassengerForm.defaultProps = {
  selfServiceCustomer: null,
  foodOptions: null,
};

const mapStateToProps = (state, { index }) => {
  const seatReservations = state.BookingUnit.Booking.getIn([
    'seats',
    'seatReservations',
  ])
    .map((seatReservation) => ({
      value: seatReservation.id,
      label: seatReservation.seat.seatNumber,
      itinerary: seatReservation.itinerary || null,
      itineraryId: seatReservation.itineraryId || null,
      seatNumber: seatReservation.seat.seatNumber,
      floorNumber: seatReservation.seat.floorNumber,
    }))
    .toJS();

  const seatReservationsOrdered = orderBy(
    seatReservations,
    ['itinerary.departureTime', 'itineraryId', 'label'],
    ['asc'],
  );

  const foodGroup = state.BookingUnit.Booking.getIn([
    'passengers',
    'itineraryFoodGroup',
  ]);

  let foodOptions = null;
  if (foodGroup && foodGroup.foodSet) {
    foodOptions = foodGroup.foodSet.map((food) => ({
      value: food.id,
      label: food.name,
    }));
  }

  const seatSelectionChangesInProgress = state.BookingUnit.Booking.getIn([
    'seats',
    'seatSelectionChangesInProgress',
  ]);

  const { customer, salesSessionUserId } = state.authentication.get('user');

  let selfServiceCustomer;

  if (salesSessionUserId) {
    selfServiceCustomer = {
      value: customer.id,
      label: `${customer.idDocumentNumber} - ${customer.fullName}`,
    };
  }

  const { itineraryId } = seatReservations[index];

  return {
    seatReservations: seatReservationsOrdered,
    foodOptions,
    itineraryId,
    seatSelectionChangesInProgress,
    selfServiceCustomer,
  };
};

const mapDispatchToProps = {
  dispatchGetItineraryFoodGroup: getItineraryFoodGroup,
  dispatchChange: change,
  dispatchDeselectSeat: deselectSeat,
  dispatchClearCustomers: clearCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(PassengerForm);
