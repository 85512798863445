import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Form, Button } from 'reactstrap';
import FormFooter from '../../../../common/forms/FormFooter';
import { isRequired } from '../../../../../utils/validators';
import CustomerInputGroup from '../../../../common/forms/CustomerInputGroup';

const PasengerChangeForm = ({ handleSubmit, onCancel }) => (
  <>
    <Form onSubmit={handleSubmit}>
      <CustomerInputGroup
        label="Nuevo Pasajero"
        name="customer"
        labelRequired
        form="PasengerChangeForm"
        validate={[isRequired]}
      />
      <FormFooter saveButtonColor="secondary" saveButtonIcon={null}>
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  </>
);

PasengerChangeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'PasengerChangeForm',
})(PasengerChangeForm);
