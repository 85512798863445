import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CARGO_ITEM_CHANGE_STATUS_DROPDOWN_OPTIONS } from '../../../../config/constants';
import DropdownButton from '../../../common/button/DropdownButton';
import { changeCargoItemStatus } from '../../../../actions';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { PUT } from '../../../../config/permissions';
import { generatePutChangeCargoItemStatus } from '../../../../config/endpoints';

const ChangeCargoItemStatusDropdownButton = ({
  cargoItemId,
  dispatchChangeCargoItemStatus,
}) => {
  const handleClick = async (value) => {
    const status = value;

    dispatchChangeCargoItemStatus({ cargoItemId, status });
  };

  return (
    <DropdownButton
      title="Cambiar estado"
      color="secondary"
      options={CARGO_ITEM_CHANGE_STATUS_DROPDOWN_OPTIONS}
      handleClick={handleClick}
    />
  );
};

ChangeCargoItemStatusDropdownButton.propTypes = {
  cargoItemId: PropTypes.number,
  dispatchChangeCargoItemStatus: PropTypes.func.isRequired,
};

ChangeCargoItemStatusDropdownButton.defaultProps = {
  cargoItemId: 0,
};

const mapDispatchToProps = {
  dispatchChangeCargoItemStatus: changeCargoItemStatus,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(ChangeCargoItemStatusDropdownButton);

export default withEndpointAuthorization({
  url: generatePutChangeCargoItemStatus(0, ''),
  permissionType: PUT,
})(connectedComponent);
