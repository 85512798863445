import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import UserSecurityProfileSelect from '../../../common/forms/select/UserSecurityProfileSelect';
import InformationText from '../../../common/informative/InformationText';
import FormFooter from '../../../common/forms/FormFooter';

const AuthorizedProfileByProfileForm = ({
  loading,
  handleSubmit,
  initialValues,
}) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Perfil de Seguridad" required>
          <Field
            name="profileId"
            component={UserSecurityProfileSelect}
            validate={[isRequired]}
          />
          <InformationText text="Perfil de Seguridad del usuario logeado en el sistema." />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Perfil de Seguridad Autorizado" required>
          <Field
            name="authorizedSecurityProfileId"
            component={UserSecurityProfileSelect}
            isMulti={!initialValues.authorizedSecurityProfileId}
            validate={[isRequired]}
          />
          <InformationText text="Estos Perfiles de Seguridad Autorizados serán mostrados al momentos de crear un nuevo usuario siempre y cuando el Perfil de Seguridad del usuario logeado en el sistema corresponda al Perfil de Seguridad indicado en el campo de arriba." />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

AuthorizedProfileByProfileForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    authorizedSecurityProfileId: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  }),
};

AuthorizedProfileByProfileForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = ({ SecurityUnit }) => ({
  loading: !SecurityUnit.AuthorizedProfile.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const formComponent = reduxForm({
  form: 'AuthorizedProfileByProfileForm',
})(AuthorizedProfileByProfileForm);

export default connect(mapStateToProps)(formComponent);
