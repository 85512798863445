import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  postOccupancyReport,
  clearErrorPostingOccupancyReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import OccupancyReportForm from './OccupancyReportForm';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';

class NewOccupancyReport extends Component {
  componentWillMount() {}

  componentWillUnmount() {
    this.props.clearErrorPostingOccupancyReport();
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte de Ocupabilidad</h1>
            <p id="text">Crear un nuevo reporte de ocupabilidad.</p>
          </Col>
        </Row>
        <OccupancyReportForm
          onSubmit={this.props.postOccupancyReport}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            force: false,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ocupabilidad',
      href: '/reports/occupancy',
    },
    {
      text: 'Crear',
      href: '/reports/occupancy/new',
    },
  ],
});

const mapDispatchToProps = {
  postOccupancyReport,
  clearErrorPostingOccupancyReport,
};

NewOccupancyReport.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  postOccupancyReport: PropTypes.func.isRequired,
  clearErrorPostingOccupancyReport: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOccupancyReport);
