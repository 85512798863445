import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMechanics, clearMechanics } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

export const MechanicSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearMechanics,
  dispatchGetMechanics,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetMechanics({ ...DEFAULT_QUERY_GET_ALL });

    return () => dispatchClearMechanics();
  }, []);

  return (
    <Select
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

MechanicSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetMechanics: PropTypes.func.isRequired,
  dispatchClearMechanics: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

MechanicSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca así: nombre,apellido',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.Mechanic.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, customer }) => ({
    value: id,
    label: customer.fullName,
  })),
  loading: MechanicalMaintenanceUnit.Mechanic.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetMechanics: getMechanics,
  dispatchClearMechanics: clearMechanics,
};

export default connect(mapStateToProps, mapDispatchToProps)(MechanicSelect);
