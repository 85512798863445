import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, ButtonToolbar, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { CARGO_CATEGORY_PATH } from '../../../../config/paths';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import {
  getCargoCategory,
  clearCargoCategory,
} from '../../../../actions/cargo';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import EditCargoCategoryButton from './EditCargoCategoryButton';
import DeleteCargoCategoryButton from './DeleteCargoCategoryButton';

class CargoCategory extends Component {
  componentDidMount() {
    const {
      dispatchGetCargoCategory,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetCargoCategory({ id });
  }

  componentWillUnmount() {
    const { dispatchClearCargoCategory } = this.props;
    dispatchClearCargoCategory();
  }

  render() {
    const {
      breadcrumbs,
      cargoCategory,
      loading,
      match: {
        params: { id },
      },
    } = this.props;

    const cargoCategoryId = parseInt(id, 10);

    if (loading || cargoCategory.isEmpty()) {
      return <Loader />;
    }

    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Fragment>
          <ButtonToolbar className="pull-right">
            <div>
              <EditCargoCategoryButton cargoCategoryId={cargoCategoryId} />{' '}
              <DeleteCargoCategoryButton cargoCategoryId={cargoCategoryId} />
            </div>
          </ButtonToolbar>
        </Fragment>
        <Fragment>
          <Row>
            <Col>
              <h1>Categoría</h1>
            </Col>
          </Row>
          <hr />
        </Fragment>
        <Fragment>
          <ResourceProperty label="Categoría">
            {cargoCategory.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Descripción">
            {cargoCategory.get('description')}
          </ResourceProperty>
        </Fragment>
      </Container>
    );
  }
}

CargoCategory.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoCategory: PropTypes.func.isRequired,
  dispatchClearCargoCategory: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cargoCategory: PropTypes.instanceOf(Immutable.Map),
};

CargoCategory.defaultProps = {
  loading: false,
  cargoCategory: null,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Categorías',
      href: CARGO_CATEGORY_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  cargoCategory: CargoUnit.CargoCategory.getIn(['current', 'content']),
  loading: CargoUnit.CargoCategory.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCargoCategory: getCargoCategory,
  dispatchClearCargoCategory: clearCargoCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoCategory);
