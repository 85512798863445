import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getCommonProducts, clearCommonProducts } from '../../../../actions';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

class InternalCommonProductSelect extends Component {
  componentDidMount() {
    const QUERY_GET_NOT_INTERNAL_PRODUCTS = {
      ...DEFAULT_QUERY_GET_ALL,
      query: ['internalProduct:true', 'active:true'],
    };
    const { dispatchGetCommonProducts } = this.props;
    dispatchGetCommonProducts(QUERY_GET_NOT_INTERNAL_PRODUCTS);
  }

  componentWillUnmount() {
    const { dispatchClearCommonProducts, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearCommonProducts();
    }
  }

  render() {
    const {
      loading,
      commonProductOptions,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={commonProductOptions}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

InternalCommonProductSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetCommonProducts: PropTypes.func.isRequired,
  dispatchClearCommonProducts: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  commonProductOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  isClearable: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  cleanWhenUnmount: PropTypes.bool,
};

InternalCommonProductSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  isClearable: true,
  onValueChange: undefined,
  value: {},
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ CargoUnit }) => {
  const commonProductOptions = CargoUnit.CommonProduct.getIn([
    'all',
    'content',
    'content',
  ]);

  const options = [];
  commonProductOptions.forEach((commonProduct) => {
    options.push({
      value: commonProduct.id,
      label: commonProduct.productName,
    });
  });

  return {
    commonProductOptions: options,
    loading: CargoUnit.CommonProduct.getIn(['all', 'loading']),
  };
};

const mapDispatchToProps = {
  dispatchGetCommonProducts: getCommonProducts,
  dispatchClearCommonProducts: clearCommonProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternalCommonProductSelect);
