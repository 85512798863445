import Immutable from 'immutable';
import {
  FLAG_GETTING_COUNTRIES,
  GET_COUNTRIES,
  GET_COUNTRY,
  CLEAR_COUNTRIES,
  FLAG_COUNTRY_ACTIVITY,
  CLEAR_COUNTRY,
  FLAG_POSTING_COUNTRY,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  form: Immutable.Map({
    loading: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: false,
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_COUNTRIES:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_COUNTRIES:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_COUNTRIES:
      return state.setIn(['all', 'content'], Immutable.Map(COLLECTION_SHAPE));
    case FLAG_COUNTRY_ACTIVITY:
      return state.setIn(['current', 'activity'], action.payload);
    case GET_COUNTRY:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case CLEAR_COUNTRY:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_POSTING_COUNTRY:
      return state.setIn(['form', 'loading'], action.payload);
    default:
      return state;
  }
};
