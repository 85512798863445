import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'reactstrap';
import FormItem from '../forms/FormItem';
import AddressField from './fields/AddressField';
import { optionsPropTypes } from './select/SelectPropTypes';
import { OLD_FRONT_NEW_ADDRESS_PATH } from '../../../config/paths';

const openNewAddressTab = () =>
  window.open(OLD_FRONT_NEW_ADDRESS_PATH, '_blank');

export const AddressInputGroup = ({
  label,
  name,
  required,
  validate,
  disabled,
  onChange,
  options,
}) => {
  const handleNoAddress = () => (
    <div>
      <p>No existe ningún {label} con ese nombre o número de documento.</p>
      <Button type="button" color="primary" onClick={openNewAddressTab}>
        Nuevo {label}
      </Button>
    </div>
  );

  return (
    <FormGroup row>
      <FormItem label={label} required={required}>
        <AddressField
          fieldName={name}
          validate={validate}
          noOptionsMessage={handleNoAddress}
          isDisabled={disabled}
          onChange={onChange}
          options={options}
        />
      </FormItem>
    </FormGroup>
  );
};

AddressInputGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.arrayOf(PropTypes.func),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: optionsPropTypes,
};

AddressInputGroup.defaultProps = {
  required: false,
  validate: [],
  disabled: false,
  onChange: null,
  options: [],
};

export default AddressInputGroup;
