import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getExpectedFuelConsumptions,
  clearExpectedFuelConsumptions,
} from '../../../../actions/index';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  EXPECTED_FUEL_CONSUMPTION_PATH,
  NEW_EXPECTED_FUEL_CONSUMPTION_PATH,
} from '../../../../config/paths';
import { EXPECTED_FUEL_CONSUMTION_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class ExpectedFuelConsumptions extends Component {
  componentWillUnmount() {
    const { dispatchClearExpectedFuelConsumptions } = this.props;
    dispatchClearExpectedFuelConsumptions();
  }

  render() {
    const {
      expectedFuelConsumptions,
      loading,
      dispatchGetExpectedFuelConsumptions,
      breadcrumbs,
    } = this.props;

    const data = expectedFuelConsumptions.get('content') || [];
    const pages = expectedFuelConsumptions.get('totalPages') || null;
    const defaultPageSize =
      expectedFuelConsumptions.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Dotaciones de Combustible"
        buttonPath={NEW_EXPECTED_FUEL_CONSUMPTION_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: EXPECTED_FUEL_CONSUMTION_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetExpectedFuelConsumptions,
          loading,
          modelPath: EXPECTED_FUEL_CONSUMPTION_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Dotaciones de Combustible',
      href: EXPECTED_FUEL_CONSUMPTION_PATH,
    },
  ],
  expectedFuelConsumptions: TrafficUnit.ExpectedFuelConsumption.getIn([
    'all',
    'content',
  ]),
  loading: TrafficUnit.ExpectedFuelConsumption.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetExpectedFuelConsumptions: getExpectedFuelConsumptions,
  dispatchClearExpectedFuelConsumptions: clearExpectedFuelConsumptions,
};

ExpectedFuelConsumptions.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetExpectedFuelConsumptions: PropTypes.func.isRequired,
  dispatchClearExpectedFuelConsumptions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  expectedFuelConsumptions: PropTypes.instanceOf(Immutable.Map),
};

ExpectedFuelConsumptions.defaultProps = {
  expectedFuelConsumptions: undefined,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpectedFuelConsumptions);
