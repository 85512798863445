import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getCompanyCreditNotes,
  clearCompanyCreditNotes,
} from '../../../../actions/index';
import { COMPANY_CREDIT_NOTE_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { COMPANY_CREDIT_NOTE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class CompanyCreditNotes extends Component {
  componentWillUnmount() {
    this.props.dispatchClearCreditNotes();
  }

  render() {
    const { creditNotes, loading, breadcrumbs, dispatchGetCreditNotes } =
      this.props;

    const data = creditNotes.get('content') || [];
    const pages = creditNotes.get('totalPages') || null;
    const defaultPageSize = creditNotes.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Notas de Crédito"
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: COMPANY_CREDIT_NOTE_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetCreditNotes,
          modelPath: COMPANY_CREDIT_NOTE_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Notas de Crédito',
      href: COMPANY_CREDIT_NOTE_PATH,
    },
  ],
  creditNotes: ContractUnit.CompanyCreditNote.getIn(['all', 'content']),
  loading: ContractUnit.CompanyCreditNote.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCreditNotes: getCompanyCreditNotes,
  dispatchClearCreditNotes: clearCompanyCreditNotes,
};

CompanyCreditNotes.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  creditNotes: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCreditNotes: PropTypes.func.isRequired,
  dispatchClearCreditNotes: PropTypes.func.isRequired,
};

CompanyCreditNotes.defaultProps = {
  creditNotes: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreditNotes);
