import Immutable from 'immutable';
import {
  FLAG_GETTING_DEPOSITS,
  GET_DEPOSITS,
  CLEAR_DEPOSITS,
  GET_DEPOSIT,
  CLEAR_DEPOSIT,
  FLAG_DEPOSIT_ACTIVITY,
  GET_DEPOSIT_VOUCHER,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    voucher: Immutable.Set(),
  }),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLAG_GETTING_DEPOSITS:
      return state.setIn(['all', 'loading'], action.payload);
    case GET_DEPOSITS:
      return state.setIn(['all', 'content'], Immutable.Map(action.payload));
    case CLEAR_DEPOSITS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case GET_DEPOSIT:
      return state.setIn(['current', 'content'], Immutable.Map(action.payload));
    case CLEAR_DEPOSIT:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case GET_DEPOSIT_VOUCHER:
      return state.setIn(['current', 'voucher'], Immutable.Set(action.payload));
    case FLAG_DEPOSIT_ACTIVITY:
      // raising flag
      if (action.payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(action.payload),
        );
      }

      // lowering flag
      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    default:
      return state;
  }
};
