import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  clearItinerarySalesControlParams,
  clearLiveItinerarySalesControl,
  getLiveItinerarySalesControl,
  updateItinerarySalesControlParams,
} from '../../../../../actions';
import { INT_DATE_FORMAT } from '../../../../../config/locale';
import { DEFAULT_QUERY } from '../../../../../config/queries';
import { isLocalEnvironment } from '../../../../../config/environments';
import { padStart } from '../../../../../utils/string';
import { tzNormalizeDate } from '../../../../../utils/date';
import LiveItinerarySalesControlSearchForm from './LiveItinerarySalesControlSearchForm';
import LiveItinerarySalesControlSearchResult from './LiveItinerarySalesControlSearchResult';

const LiveItinerarySalesControlSearch = ({
  dispatchClearLiveItinerarySalesControl,
  dispatchGetLiveItinerarySalesControl,
  salesControlParams,
  dispatchUpdateItinerarySalesControlParams,
  dispatchClearItinerarySalesControlParams,
}) => {
  const timeoutRef = useRef(null);

  const [searchFormValues, setSearchFormValues] = useState({
    sourceLocationId: 1,
    destinationLocationId: 1,
    toDayString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
    fromDayString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
    ...DEFAULT_QUERY,
    size: 100,
  });

  const fetchData = (query) => {
    dispatchGetLiveItinerarySalesControl(query);
  };

  const refreshData = () => {
    fetchData(salesControlParams.toJS());
  };

  useLayoutEffect(
    () => () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
      dispatchClearItinerarySalesControlParams();
    },
    [],
  );

  useEffect(() => {
    if (!salesControlParams.isEmpty()) {
      timeoutRef.current = setInterval(() => {
        refreshData();
      }, 300000);
    }

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, [salesControlParams]);

  const onSubmit = (formValues) => {
    const newFormValues = {
      toDayString: tzNormalizeDate({
        date: formValues.toDateString,
        format: INT_DATE_FORMAT,
      }),
      fromDayString: tzNormalizeDate({
        date: formValues.fromDateString,
        format: INT_DATE_FORMAT,
      }),
      ...DEFAULT_QUERY,
      size: 100,
    };

    if (formValues.sourceLocationId)
      newFormValues.sourceLocationId = formValues.sourceLocationId.value;

    if (formValues.destinationLocationId)
      newFormValues.destinationLocationId =
        formValues.destinationLocationId.value;

    if (formValues.geographicZoneId)
      newFormValues.geographicZoneId = formValues.geographicZoneId.value;

    if (formValues.departureTimeString) {
      let utcTime = formValues.departureTimeString;

      if (!isLocalEnvironment()) {
        // Convert local time to utc time adding 5 hours
        const [localHour, minute] = utcTime.split(':');
        let utcHour = +localHour + 5;
        if (+localHour > 18) utcHour -= 24;
        utcHour = padStart(utcHour, 2);
        utcTime = `${utcHour}:${minute}`;
      }

      newFormValues.departureTimeHourMinuteStringUtc = utcTime;
    }

    setSearchFormValues(newFormValues);

    dispatchClearLiveItinerarySalesControl();

    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }

    fetchData(newFormValues);

    dispatchUpdateItinerarySalesControlParams(newFormValues);
  };

  const dateNow = tzNormalizeDate();

  return (
    <div>
      <LiveItinerarySalesControlSearchForm
        onSubmit={onSubmit}
        initialValues={{
          departureDayString: dateNow,
          fromDateString: tzNormalizeDate(),
          toDateString: tzNormalizeDate(),
        }}
        sourceLocationIsRequired={false}
        destinationLocationIsRequired={false}
        geographicZoneIsRequired={false}
      />
      <LiveItinerarySalesControlSearchResult
        searchFormValues={searchFormValues}
        fetchData={fetchData}
      />
    </div>
  );
};

LiveItinerarySalesControlSearch.propTypes = {
  dispatchGetLiveItinerarySalesControl: PropTypes.func.isRequired,
  dispatchClearLiveItinerarySalesControl: PropTypes.func.isRequired,
  dispatchUpdateItinerarySalesControlParams: PropTypes.func.isRequired,
  salesControlParams: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchClearItinerarySalesControlParams: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  salesControlParams: ItineraryUnit.ItinerarySalesControl.getIn([
    'params',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchGetLiveItinerarySalesControl: getLiveItinerarySalesControl,
  dispatchClearLiveItinerarySalesControl: clearLiveItinerarySalesControl,
  dispatchUpdateItinerarySalesControlParams: updateItinerarySalesControlParams,
  dispatchClearItinerarySalesControlParams: clearItinerarySalesControlParams,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveItinerarySalesControlSearch);
