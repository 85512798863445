import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import PaymentMethodForSecurityProfileForm from './PaymentMethodForSecurityProfileForm';
import { postPaymentMethodForSecurityProfile } from '../../../../actions/security/PaymentMethodForSecurityProfile';

const NewPaymentMethodForSecurityProfile = ({
  breadcrumbs,
  dispatchPostPaymentMethodForSecurityProfile,
}) => {
  const onSubmit = (formValues) =>
    dispatchPostPaymentMethodForSecurityProfile({
      securityProfileId: formValues.securityProfileId.value,
      paymentMethodId: formValues.paymentMethodId.value,
    });

  const content = <PaymentMethodForSecurityProfileForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Metodo de Pago por Perfil de Seguridad"
      subtitle="Crear nuevo metodo de pago por perfil de seguridad"
      content={content}
    />
  );
};

NewPaymentMethodForSecurityProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPaymentMethodForSecurityProfile: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostPaymentMethodForSecurityProfile:
    postPaymentMethodForSecurityProfile,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Metodos de Pago Por Perfiles de Seguridad',
      href: PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPaymentMethodForSecurityProfile);
