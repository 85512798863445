import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../layout/Card';
import ModuleHome from '../../layout/ModuleHome';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

export const RouteHome = ({ cards, breadcrumbs }) => (
  <ModuleHome
    title="Gestión de Rutas"
    text="Éste es el módulo de Gestión de Rutas."
    cards={cards}
    breadcrumbs={breadcrumbs}
  />
);

const mapStateToProps = ({ RouteUnit }) => ({
  cards: RouteUnit.UnitHome.get('cards'),
  breadcrumbs: RouteUnit.UnitHome.get('breadcrumbs'),
});

RouteHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default connect(mapStateToProps)(RouteHome);
