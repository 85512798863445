import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { COMPANY_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CompanyForm from './CompanyForm';
import { postCompany } from '../../../../actions';
import Content from '../../../layout/Content';

export class NewCompany extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      name: formValues.name,
      businessId: formValues.businessId.value,
      companyType: formValues.companyType.value,
    };

    this.props.dispatchPostCompany(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Empresa Tercera"
        subtitle="Crea una nueva empresa tercera"
        content={<CompanyForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ ContractUnit }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas Terceras',
      href: COMPANY_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostCompany: postCompany,
};

NewCompany.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCompany: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCompany);
