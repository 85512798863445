import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { ADDRESS_PATH } from '../../../../config/paths';
import EditButton from '../../../common/button/EditButton';
import AddressDeleteButton from './AddressDeleteButton';

const AddressToolbar = ({ addressId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${ADDRESS_PATH}/${addressId}/edit`} />{' '}
      <AddressDeleteButton addressId={addressId} />
    </div>
  </ButtonToolbar>
);

AddressToolbar.propTypes = {
  addressId: PropTypes.number.isRequired,
};

export default AddressToolbar;
