import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import {
  getNotBoardedTicketReport,
  clearNotBoardedTicketReport,
  clearErrorGettingNotBoardedTicketReport,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Table from '../../../common/Table';
import ReportToolbar from '../ReportToolbar';
import Loader from '../../../common/Loader';
import DateCell from '../../../common/cells/DateCell';
import Alert from '../../../common/informative/Alert';
import { NOT_BOARDED_TICKET_REPORTS_PATH } from '../../../../config/paths';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

class NotBoardedTicketReport extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        Header: 'Agencia',
        accessor: 'agency',
      },
      {
        Header: 'Serie',
        accessor: 'documentSeries',
      },
      {
        Header: 'Correlativo',
        accessor: 'documentCode',
      },
      {
        Header: 'Precio de Lista',
        accessor: 'listPrice',
      },
      {
        Header: 'Precio de Venta',
        accessor: 'salePrice',
      },
      {
        Header: 'Counter',
        accessor: 'counter',
      },
      {
        Header: 'Origen',
        accessor: 'source',
      },
      {
        Header: 'Destino',
        accessor: 'destination',
      },
      {
        Header: 'Fecha',
        accessor: 'createdDate',
        Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
      },
    ];
    this.download = this.download.bind(this);
  }

  componentWillMount() {
    // extract id from params injected by react router
    const { id } = this.props.match.params;
    this.props.getNotBoardedTicketReport({ id });
  }

  componentWillUnmount() {
    this.props.clearNotBoardedTicketReport();
    this.props.clearErrorGettingNotBoardedTicketReport();
  }

  download() {
    const { report } = this.props;
    const { id } = this.props.match.params;
    if (report) {
      return this.props.getNotBoardedTicketReport({
        id,
        reportFormat: 'csv',
        download: true,
      });
    }
    return false;
  }

  renderContent() {
    // check if report is loaded
    const { loading, report, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    // check if report is loading or empty
    if (loading || !report || report.isEmpty()) {
      return <Loader />;
    }

    // check if report is complete
    const reportStatus = report.get('reportStatus');
    if (reportStatus === 'GENERATING') {
      return <Loader />;
    }

    const notBoardedTicketReportRows = report.get('notBoardedTicketReportRows');
    let data = [];
    let pages = null;

    if (notBoardedTicketReportRows) {
      data = notBoardedTicketReportRows.content;
      pages = notBoardedTicketReportRows.totalPages;
    }
    const { columns } = this;
    return (
      <div>
        <ReportToolbar download={this.download} disabled={!data.length} />
        <Table columns={columns} data={data} pages={pages} loading={loading} />
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <h1>Reporte de Boletos No Abordados</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  breadcrumbs: [
    ...state.ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos No Abordados',
      href: NOT_BOARDED_TICKET_REPORTS_PATH,
    },
    {
      text: 'Ver',
      href: state.routing.location.pathname,
    },
  ],
  error: state.ReportUnit.NotBoardedTicketReport.get('current').get('error'),
  report: state.ReportUnit.NotBoardedTicketReport.get('current').get('content'),
  loading:
    state.ReportUnit.NotBoardedTicketReport.get('current').get('loading'),
});

const mapDispatchToProps = {
  getNotBoardedTicketReport,
  clearNotBoardedTicketReport,
  clearErrorGettingNotBoardedTicketReport,
};

NotBoardedTicketReport.propTypes = {
  getNotBoardedTicketReport: PropTypes.func.isRequired,
  clearNotBoardedTicketReport: PropTypes.func.isRequired,
  clearErrorGettingNotBoardedTicketReport: PropTypes.func.isRequired,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  report: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
  match: matchPropTypes.isRequired,
};

NotBoardedTicketReport.defaultProps = {
  report: null,
  loading: false,
  error: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotBoardedTicketReport);
