import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import {
  CONTRACT_MOVEMENT_TYPE,
  CONTRACT_STATUS,
} from '../../../../config/constants';
import DropdownButton from '../../../common/button/DropdownButton';
import { patchPrivateServiceStatus } from '../../../../actions';
import { enumToSelectOptions } from '../../../../utils/enum';

const ChangePrivateServiceContractStatusButton = ({
  contract: { contractStatus, contractItineraryList, id },
  dispatchPatchPrivateServiceStatus,
}) => {
  const handleClick = (value) => {
    const hasntPassengers = contractItineraryList.some(
      ({ customerList }) => customerList.length === 0,
    );

    const hasntExpectedFuelConsumption = contractItineraryList.some(
      ({ expectedFuelConsumption, movementType }) =>
        movementType !== CONTRACT_MOVEMENT_TYPE.REGULAR.value &&
        (expectedFuelConsumption === 0 || !expectedFuelConsumption),
    );

    let success = true;

    if (value === CONTRACT_STATUS.DONE.value && hasntPassengers) {
      success = false;
      toastr.error(
        'Error',
        'Para cambiar al estado TERMINADO debe ingresar pasajeros',
      );
    }

    if (value === CONTRACT_STATUS.DONE.value && hasntExpectedFuelConsumption) {
      success = false;
      toastr.error(
        'Error',
        'Para cambiar al estado TERMINADO debe ingresar dotación para los movimientos distintos a REGULARES',
      );
    }

    if (success)
      dispatchPatchPrivateServiceStatus({
        privateServiceId: id,
        contractStatus: value,
      });
  };

  const statusList = enumToSelectOptions(CONTRACT_STATUS);
  const contractStatusFound = statusList.find(
    (status) => status.value === CONTRACT_STATUS[contractStatus].value,
  );
  const contractStatusIndex = statusList.indexOf(contractStatusFound);
  const availableStatusList = statusList.filter(
    (status, index) => index > contractStatusIndex,
  );

  return (
    <DropdownButton
      title={contractStatusFound.label}
      options={availableStatusList}
      handleClick={handleClick}
      activeValue={contractStatus}
      color={contractStatusFound.color}
    />
  );
};

ChangePrivateServiceContractStatusButton.propTypes = {
  contract: PropTypes.shape({
    contractStatus: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    contractItineraryList: PropTypes.arrayOf(
      PropTypes.shape({
        movementType: PropTypes.string.isRequired,
        expectedFuelConsumption: PropTypes.number,
      }),
    ),
  }).isRequired,
  dispatchPatchPrivateServiceStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPatchPrivateServiceStatus: patchPrivateServiceStatus,
};

export default connect(
  null,
  mapDispatchToProps,
)(ChangePrivateServiceContractStatusButton);
