import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getMaintenanceWarnings,
  clearMaintenanceWarnings,
} from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { maintenanceWarningsGenerator } from '../../../../utils/app/json-generator-from-reducer';

export const MaintenanceWarningSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearMaintenanceWarnings,
  dispatchGetMaintenanceWarnings,
  registeredBusId,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenanceWarnings(true), []);

  const onInputChangeMaintenanceWarning = (inputValue) =>
    inputValue.trim().length &&
    dispatchGetMaintenanceWarnings(
      {
        query: [
          `id:${inputValue}`,
          `serviceOrderId:Ø`,
          `registeredBusId:${registeredBusId}`,
        ],
      },
      true,
    );

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeMaintenanceWarning}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

MaintenanceWarningSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetMaintenanceWarnings: PropTypes.func.isRequired,
  dispatchClearMaintenanceWarnings: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  registeredBusId: PropTypes.number.isRequired,
};

MaintenanceWarningSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por ID de alerta',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: maintenanceWarningsGenerator(
    MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
      'allInSelect',
      'content',
      'content',
    ]),
  ),
  loading: MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'allInSelect',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetMaintenanceWarnings: getMaintenanceWarnings,
  dispatchClearMaintenanceWarnings: clearMaintenanceWarnings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceWarningSelect);
