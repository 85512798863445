import React from 'react';
import { DRIVER_WITHOUT_DOCUMENTS_WARNING_MESSAGE } from '../../../../../config/messages';
import { tzNormalizeDate } from '../../../../../utils/date';
import Alert from '../../../../common/informative/Alert';
import Table from '../../../../common/Table';
import { driverDocumentListPropTypes } from '../proptypes/DriverPropTypes';
import { DATE_FORMAT } from '../../../../../config/locale';

const DriverDocumentInformationResource = ({ driverDocumentList }) => {
  let documentList = (
    <Alert type="warning" message={DRIVER_WITHOUT_DOCUMENTS_WARNING_MESSAGE} />
  );

  if (driverDocumentList.length)
    documentList = (
      <Table
        columns={[
          {
            Header: 'Tipo de Documento',
            accessor: 'driverDocumentType.name',
            className: 'text-center',
          },
          {
            Header: 'Número',
            accessor: 'documentNumber',
            className: 'text-center',
          },
          {
            Header: 'Fecha de Expedición',
            accessor: 'effectiveDate',
            Cell: (row) =>
              tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
            className: 'text-center',
          },
          {
            Header: 'Fecha de Expiración',
            accessor: 'expirationDate',
            Cell: (row) =>
              tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
            className: 'text-center',
          },
          {
            Header: 'Notas',
            accessor: 'note',
            className: 'text-center',
          },
        ]}
        data={driverDocumentList}
        defaultPageSize={driverDocumentList.length}
        showPagination={false}
      />
    );

  return (
    <div className="mb-3">
      <h3>Documentos</h3>
      {documentList}
    </div>
  );
};

DriverDocumentInformationResource.propTypes = driverDocumentListPropTypes;

export default DriverDocumentInformationResource;
