import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import ActivityFormFields from '../activity/ActivityFormFields';
import FormFooter from '../../../common/forms/FormFooter';
import FormItem from '../../../common/forms/FormItem';
import ActivitySelect from '../../../common/forms/select/ActivitySelect';
import { isIntegerNumber, isRequired } from '../../../../utils/validators';

const RegisterBusActivityForm = ({ handleSubmit, dispatchChange }) => {
  const [showForm, setShowForm] = useState(false);

  const onClickShowForm = (inputValue) => {
    dispatchChange('RegisterBusActivityForm', 'activityId', null);
    const field = isIntegerNumber(inputValue.trim())
      ? 'companyActivityId'
      : 'name';
    dispatchChange('RegisterBusActivityForm', field, inputValue);
    setShowForm(true);
  };

  const noOptionsMessage = ({ inputValue }) => (
    <div>
      <p>La actividad ingresada no esta registrada.</p>
      <Button
        color="primary"
        type="button"
        onClick={() => onClickShowForm(inputValue)}
      >
        <i className="fa fa-plus-circle" /> Registrar actividad
      </Button>
    </div>
  );

  const onChangeActivity = () => setShowForm(false);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Actividad" required>
          <Field
            name="activityId"
            component={ActivitySelect}
            noOptionsMessage={noOptionsMessage}
            onChange={onChangeActivity}
            validate={!showForm ? [isRequired] : []}
          />
        </FormItem>
      </FormGroup>
      {showForm && <ActivityFormFields showActivityLabel={false} />}
      <FormFooter />
    </Form>
  );
};

RegisterBusActivityForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'RegisterBusActivityForm',
})(RegisterBusActivityForm);

export default connect(null, mapDispatchToProps)(formComponent);
