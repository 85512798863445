import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getCreditNotes, clearCreditNotes } from '../../../../actions/index';
import ModuleList from '../../../layout/ModuleList';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { CREDIT_NOTE_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  CREDIT_NOTE_PATH,
  NEW_CREDIT_NOTE_PATH,
} from '../../../../config/paths';

export class CreditNotes extends Component {
  componentWillUnmount() {
    this.props.dispatchClearCreditNotes();
  }

  render() {
    const { creditNotes, loading, dispatchGetCreditNotes, breadcrumbs } =
      this.props;

    const data = creditNotes.get('content') || [];
    const pages = creditNotes.get('totalPages') || null;
    const defaultPageSize = creditNotes.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Notas de Crédito"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_CREDIT_NOTE_PATH}
        tableStructure={{
          columns: CREDIT_NOTE_COLUMNS,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetCreditNotes,
          modelPath: CREDIT_NOTE_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

CreditNotes.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  creditNotes: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCreditNotes: PropTypes.func.isRequired,
  dispatchClearCreditNotes: PropTypes.func.isRequired,
};

CreditNotes.defaultProps = {
  creditNotes: null,
  loading: false,
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Notas de Crédito',
      href: '',
    },
  ],
  creditNotes: SalesUnit.CreditNote.getIn(['all', 'content']),
  loading: SalesUnit.CreditNote.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCreditNotes: getCreditNotes,
  dispatchClearCreditNotes: clearCreditNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotes);
