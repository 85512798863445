import { arrayOf, func, bool, shape, string } from 'prop-types';

const BoardColumnElementPropTypes = {
  onClickElement: func.isRequired,
  lastElement: bool,
};

const BoardColumnPropTypes = {
  status: string.isRequired,
  elements: arrayOf(shape(BoardColumnElementPropTypes)),
};

const BoardPropTypes = {
  columns: arrayOf(shape(BoardColumnPropTypes)),
};

export { BoardColumnElementPropTypes, BoardColumnPropTypes, BoardPropTypes };
