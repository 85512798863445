import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import OrganizationChart from './OrganizationChart';
import Hierarchy from './Hierarchy';
import Mechanic from './Mechanic';
import Activity from './Activity';
import BusActivityAssignment from './BusActivityAssignment';
import PreventiveMaintenanceReport from './PreventiveMaintenanceReport';
import ExecutionRegistration from './ExecutionRegistration';
import BusActivityHistory from './BusActivityHistory';
import Material from './Material';
import OdometerByBus from './OdometerByBus';
import ActivityTask from './ActivityTask';
import MaintenanceWarning from './MaintenanceWarning';
import MaintenanceFailMode from './MaintenanceFailMode';
import MaintenanceServiceOrder from './MaintenanceServiceOrder';
import ActivityTaskExecution from './ActivityTaskExecution';
import CompanyAreaResponsable from './CompanyAreaResponsable';

export default combineReducers({
  UnitHome,
  OrganizationChart,
  Hierarchy,
  Mechanic,
  Activity,
  BusActivityAssignment,
  PreventiveMaintenanceReport,
  ExecutionRegistration,
  BusActivityHistory,
  Material,
  OdometerByBus,
  ActivityTask,
  MaintenanceWarning,
  MaintenanceFailMode,
  MaintenanceServiceOrder,
  ActivityTaskExecution,
  CompanyAreaResponsable,
});
