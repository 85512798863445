import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import CargoDistanceForm from './CargoDistanceForm';
import { CARGO_DISTANCE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getCargoDistance,
  clearCargoDistance,
  putCargoDistance,
} from '../../../../actions';
import Loader from '../../../common/Loader';

export class EditCargoDistance extends Component {
  componentDidMount() {
    const {
      dispatchGetCargoDistance,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetCargoDistance({ cargoDistanceId: id });
  }

  componentWillUnmount() {
    const { dispatchClearCargoDistance } = this.props;
    dispatchClearCargoDistance();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutCargoDistance,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutCargoDistance(id, {
      sourceCityId: formValues.sourceCity.value,
      destinationCityId: formValues.destinationCity.value,
      kilometers: formValues.kilometers,
      transships: formValues.transships || 0,
    });
  };

  render() {
    const { breadcrumbs, cargoDistance, loading } = this.props;
    if (loading || cargoDistance.isEmpty()) {
      return <Loader />;
    }
    const initialValues = {
      sourceCity: {
        label: cargoDistance.get('sourceCity').name,
        value: cargoDistance.get('sourceCity').id,
      },
      destinationCity: {
        label: cargoDistance.get('destinationCity').name,
        value: cargoDistance.get('destinationCity').id,
      },
      kilometers: cargoDistance.get('kilometers'),
      transships: cargoDistance.get('transships'),
    };
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Editar Kilómetros entre Ciudades</h1>
        <p>Editar Kilómetros entre Ciudades</p>
        <CargoDistanceForm
          onSubmit={this.onSubmit}
          initialValues={initialValues}
        />
      </Container>
    );
  }
}

EditCargoDistance.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCargoDistance: PropTypes.func.isRequired,
  dispatchClearCargoDistance: PropTypes.func.isRequired,
  dispatchPutCargoDistance: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cargoDistance: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCargoDistance.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { CargoUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Combinaciones de Zonas Geográficas',
      href: CARGO_DISTANCE_PATH,
    },
    {
      text: 'Ver',
      href: `${CARGO_DISTANCE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  cargoDistance: CargoUnit.CargoDistance.getIn(['current', 'content']),
  loading: !CargoUnit.CargoDistance.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCargoDistance: getCargoDistance,
  dispatchClearCargoDistance: clearCargoDistance,
  dispatchPutCargoDistance: putCargoDistance,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCargoDistance);
