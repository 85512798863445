import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, InputGroupText } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import DynamicForm from '../../../common/forms/DynamicForm';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import { TOLL_FORM_COLUMNS } from '../../../../config/dynamicFormFields';
import { KILOMETER_SYMBOL, MINUTE_SYMBOL } from '../../../../config/constants';
import FormFooter from '../../../common/forms/FormFooter';

const SegmentForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Origen" required>
          <Field
            name="sourceLocationId"
            component={LocationSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Destino" required>
          <Field
            name="destinationLocationId"
            component={LocationSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Distancia" required>
          <Field
            name="distance"
            component={TextInput}
            type="text"
            placeholder="Distancia"
            validate={[isRequired]}
            append={<InputGroupText>{KILOMETER_SYMBOL}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Duración">
          <Field
            name="duration"
            component={TextInput}
            type="text"
            placeholder="Duración"
            append={<InputGroupText>{MINUTE_SYMBOL}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Único Sentido">
          <Label>
            <Field name="isOneWay" component="input" type="checkbox" />
          </Label>
        </FormItem>
      </FormGroup>
      <h3>Peajes</h3>
      <DynamicForm name="tollList" columns={TOLL_FORM_COLUMNS} />
      <FormFooter />
    </Form>
  );
};

SegmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ RouteUnit }) => ({
  loading: !RouteUnit.Segment.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'SegmentForm',
})(SegmentForm);

export default connect(mapStateToProps)(formComponent);
