import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { COMPANY_USER_CONFIGURATION_PATH } from '../../../../config/paths';

const CompanyUserConfigurationEditButton = ({ companyUserConfigurationId }) => (
  <Link
    className="btn btn-primary"
    to={`${COMPANY_USER_CONFIGURATION_PATH}/${companyUserConfigurationId}/edit`}
  >
    Editar
  </Link>
);

CompanyUserConfigurationEditButton.propTypes = {
  companyUserConfigurationId: PropTypes.number.isRequired,
};

export default CompanyUserConfigurationEditButton;
