import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getUsers, clearUsers } from '../../../../actions/user/';
import { USER_PATH, NEW_USER_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { USERS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Users extends Component {
  constructor(props) {
    super(props);
    this.columns = USERS_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearUsers();
  }

  render() {
    const { users, loading, dispatchGetUsers, breadcrumbs } = this.props;
    const data = users.get('content') || [];
    const pages = users.get('totalPages') || null;
    const defaultPageSize = users.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Usuarios"
        breadcrumbs={breadcrumbs}
        buttonPath={NEW_USER_PATH}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetUsers,
          modelPath: USER_PATH,
          loading,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetUsers: getUsers,
  dispatchClearUsers: clearUsers,
};

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios',
      href: USER_PATH,
    },
  ],
  users: UserUnit.User.getIn(['all', 'content']),
  loading: UserUnit.User.getIn(['all', 'loading']),
});

Users.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  users: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetUsers: PropTypes.func.isRequired,
  dispatchClearUsers: PropTypes.func.isRequired,
};

Users.defaultProps = {
  users: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
