import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { CUSTOMER_PATH } from '../../../../config/paths';
import { getCustomer, clearCustomer } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CustomerToolbar from './CustomerToolbar';
import CustomerBasicInformationResource from './resource/CustomerBasicInformationResource';
import CustomerContactInformationResource from './resource/CustomerContactInformationResource';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import AddressResource from '../../../common/resource/AddressResource';

export class Customer extends Component {
  componentDidMount() {
    const {
      dispatchGetCustomer,
      match: {
        params: { id: customerId },
      },
    } = this.props;
    dispatchGetCustomer({ customerId });
  }

  componentWillUnmount() {
    const { dispatchClearCustomer } = this.props;
    dispatchClearCustomer();
  }

  render() {
    const { breadcrumbs, customer, loading } = this.props;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (customer.isEmpty()) {
      content = <NoDataResource returnPage={CUSTOMER_PATH} />;
    } else {
      const customeJson = customer.toJS();

      content = (
        <Fragment>
          <Row>
            <Col md={6}>
              <h3>Información Básica</h3>
              <CustomerBasicInformationResource {...customeJson} />
              <h3>Información de Contacto</h3>
              <CustomerContactInformationResource {...customeJson} />
            </Col>
            <Col md={6}>
              <h3>Dirección</h3>
              <AddressResource address={customeJson.address} />
            </Col>
          </Row>
        </Fragment>
      );

      toolbar = <CustomerToolbar customerId={customeJson.id} />;
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Cliente"
        content={content}
      />
    );
  }
}

Customer.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetCustomer: PropTypes.func.isRequired,
  dispatchClearCustomer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  customer: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Customer.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Clientes',
      href: CUSTOMER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  customer: UserUnit.Customer.getIn(['current', 'content']),
  loading: !UserUnit.Customer.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCustomer: getCustomer,
  dispatchClearCustomer: clearCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
