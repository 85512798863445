import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateMaxLength,
  validateNumber,
} from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';

const validateMaxLength10 = validateMaxLength(10);

const validateSecretCode = (confirmSecretCode, { secretCode }) => {
  if (!confirmSecretCode) return undefined;

  return confirmSecretCode !== secretCode
    ? 'Las claves secretas no coinciden'
    : undefined;
};

export const ChangeSecretCodeForm = ({
  handleSubmit,
  handleProcess,
  onCancel,
}) => (
  <Form onSubmit={handleSubmit(handleProcess)}>
    <FormGroup row>
      <FormItem label="Nueva Clave" required>
        <Field
          className="password"
          name="secretCode"
          component={TextInput}
          type="text"
          placeholder="Nueva Clave Secreta"
          validate={[isRequired, validateNumber, validateMaxLength10]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Confirmar Clave" required>
        <Field
          className="password"
          name="confirmSecretCode"
          component={TextInput}
          type="text"
          placeholder="Confirmar Clave Secreta"
          validate={[
            isRequired,
            validateNumber,
            validateMaxLength10,
            validateSecretCode,
          ]}
        />
      </FormItem>
    </FormGroup>
    <FormFooter
      saveButtonColor="warning"
      saveButtonIcon="fa fa-key"
      saveButtonText="Cambiar Clave"
    >
      <Button color="primary" onClick={onCancel}>
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

ChangeSecretCodeForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleProcess: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'ChangeSecretCodeForm',
})(ChangeSecretCodeForm);

export default connect(null, mapDispatchToProps)(formComponent);
