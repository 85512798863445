import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IN_SYSTEM_NOTIFICATION_PATH } from '../../../../config/paths';

const InSystemNotificationEditButton = ({ inSystemNotificationId }) => (
  <Link
    className="btn btn-primary"
    to={`${IN_SYSTEM_NOTIFICATION_PATH}/${inSystemNotificationId}/edit`}
  >
    Editar
  </Link>
);

InSystemNotificationEditButton.propTypes = {
  inSystemNotificationId: PropTypes.number.isRequired,
};

export default InSystemNotificationEditButton;
