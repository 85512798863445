import Immutable from 'immutable';
import {
  CHANGE_STEP,
  SEARCH_FOR_ITINERARIES,
  FLAG_SEARCHING_FOR_ITINERARIES,
  SELECT_ITINERARY_SEARCH_RESULT,
  SELECT_SEAT,
  DESELECT_SEAT,
  FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
  POST_RESERVATION_REQUESTS,
  POST_PAYMENT,
  FLAG_GETTING_PRINTED_TICKET,
  GET_PRINTED_TICKET,
  CLEAR_PRINTED_TICKETS,
  GET_INTINERARY_FOOD_GROUP,
  RESET_STEP,
  APPLY_CREDIT_NOTE,
  CANCEL_CREDIT_NOTE,
  FLAG_POSTING_PAYMENT,
  FLAG_POSTING_RESERVATION,
  SET_FIXED_PRICE_FOR_CURRENT_BOOKING,
  CLEAR_ITINERARY_FOR_BOOKING,
  CLEAR_SELECT_ITINERARY,
  CLEAR_SEATS_FOR_BOOKING,
  POST_KIDS_ON_ITINERARY,
  DELETE_KIDS_ON_ITINERARY,
} from '../../../actions/types';
import { tzNormalizeDate } from '../../../utils/date';

const SEARCH_STEP = 0;

const INITIAL_STATE = new Immutable.Map({
  currentStep: SEARCH_STEP,
  search: new Immutable.Map({
    query: new Immutable.Map({
      source: { value: 4, label: 'Lima' },
      destination: null,
      // number of passengers is just a number not an object
      numPassengers: null,
      time: tzNormalizeDate(),
    }),
  }),
  itineraries: new Immutable.Map({
    results: new Immutable.Map(),
    searchingForItineraries: false,
    selectedItinerarySearchResults: new Immutable.List(),
  }),
  seats: new Immutable.Map({
    seatSelectionChangesInProgress: new Immutable.Set(),
    seatReservations: new Immutable.Set(),
  }),
  passengers: new Immutable.Map({
    itineraryFoodGroup: null,
    postingReservation: false,
    kidsOnItinerary: new Immutable.Set(),
  }),
  payment: new Immutable.Map({
    customerOrder: new Immutable.Map(),
    creditNote: new Immutable.Map(),
    postingPayment: false,
    requiresTwoStepAuthorization: false,
  }),
  confirmation: Immutable.Map({
    reservationId: null,
    printedTickets: new Immutable.Set(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case RESET_STEP:
      return state.set(
        payload.statePropertyName,
        INITIAL_STATE.get(payload.statePropertyName),
      );
    case CHANGE_STEP:
      return state.set('currentStep', payload);
    case SEARCH_FOR_ITINERARIES:
      return (
        state
          // set the query
          .setIn(
            ['search', 'query'],
            new Immutable.Map({
              source: payload.source,
              destination: payload.destination,
              numPassengers: payload.numPassengers,
              time: payload.time,
            }),
          )
          // set the results
          .setIn(['itineraries', 'results'], new Immutable.Map(payload.results))
      );
    case FLAG_SEARCHING_FOR_ITINERARIES:
      return state.setIn(['itineraries', 'searchingForItineraries'], payload);
    case FLAG_POSTING_RESERVATION:
      return state.setIn(['passengers', 'postingReservation'], payload);
    case POST_KIDS_ON_ITINERARY:
      return state.setIn(
        ['passengers', 'kidsOnItinerary'],
        state.getIn(['passengers', 'kidsOnItinerary']).add(payload),
      );
    case DELETE_KIDS_ON_ITINERARY:
      return state.setIn(
        ['passengers', 'kidsOnItinerary'],
        state
          .getIn(['passengers', 'kidsOnItinerary'])
          .delete(
            state
              .getIn(['passengers', 'kidsOnItinerary'])
              .findKey((kidOnItinerary) => kidOnItinerary === payload),
          ),
      );
    case FLAG_POSTING_PAYMENT:
      return state.setIn(['payment', 'postingPayment'], payload);
    case SELECT_ITINERARY_SEARCH_RESULT:
      return state.setIn(
        ['itineraries', 'selectedItinerarySearchResults', payload.index],
        payload.itinerarySearchResult,
      );
    case CLEAR_ITINERARY_FOR_BOOKING:
      return state.setIn(['itineraries'], INITIAL_STATE.getIn(['itineraries']));
    case CLEAR_SELECT_ITINERARY:
      return state.setIn(
        ['itineraries', 'selectedItinerarySearchResults'],
        INITIAL_STATE.getIn(['itineraries', 'selectedItinerarySearchResults']),
      );
    case CLEAR_SEATS_FOR_BOOKING:
      return state.setIn(['seats'], INITIAL_STATE.getIn(['seats']));
    case FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS:
      // start seat selection change
      if (payload.status === 'start') {
        return state.setIn(
          ['seats', 'seatSelectionChangesInProgress'],
          state
            .getIn(['seats', 'seatSelectionChangesInProgress'])
            .add(payload.seatId),
        );
      }
      // stop seat selection change
      return state.setIn(
        ['seats', 'seatSelectionChangesInProgress'],
        state
          .getIn(['seats', 'seatSelectionChangesInProgress'])
          .remove(payload.seatId),
      );
    case SELECT_SEAT:
      return state.setIn(
        ['seats', 'seatReservations'],
        state.getIn(['seats', 'seatReservations']).add(payload),
      );
    case DESELECT_SEAT:
      return state.setIn(
        ['seats', 'seatReservations'],
        state
          .getIn(['seats', 'seatReservations'])
          .delete(
            state
              .getIn(['seats', 'seatReservations'])
              .findKey((seatReservation) => seatReservation.id === payload),
          ),
      );
    // todo: CLEAR_SEAT_RESERVATIONS
    case GET_INTINERARY_FOOD_GROUP:
      return state.setIn(['passengers', 'itineraryFoodGroup'], payload);
    case POST_RESERVATION_REQUESTS:
      return state.setIn(['payment', 'customerOrder'], Immutable.Map(payload));
    case APPLY_CREDIT_NOTE:
      return state.setIn(['payment', 'creditNote'], Immutable.Map(payload));
    case POST_PAYMENT:
      return state.setIn(['confirmation', 'reservationId'], payload);
    case CANCEL_CREDIT_NOTE:
      return state.setIn(
        ['payment', 'creditNote'],
        INITIAL_STATE.getIn(['payment', 'creditNote']),
      );
    case GET_PRINTED_TICKET:
      return state.setIn(
        ['confirmation', 'printedTickets'],
        Immutable.Set(payload),
      );

    case FLAG_GETTING_PRINTED_TICKET:
      return state.setIn(['confirmation', 'loading'], payload);

    case CLEAR_PRINTED_TICKETS:
      return state.setIn(['confirmation', 'printedTickets'], Immutable.Set());

    case SET_FIXED_PRICE_FOR_CURRENT_BOOKING: {
      const oldPassengers = [
        ...state.getIn(['payment', 'customerOrder', 'passengers']),
      ];
      const newPassengers = payload;
      oldPassengers.forEach((oldPassenger, index) => {
        newPassengers.forEach((newPassenger) => {
          if (oldPassenger.ticketId === newPassenger.ticketId) {
            oldPassengers[index].salePrice = newPassenger.fixedPrice;
          }
        });
      });
      return state.setIn(
        ['payment', 'customerOrder', 'passengers'],
        oldPassengers,
      );
    }
    default:
      return state;
  }
};
