import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired, validateEndDate } from '../../../../utils/validators';
import DatePicker from '../../../common/forms/input/DatePicker';
import DriverSelect from '../../../common/forms/select/DriverSelect';

export class FuelConsumptionReportPerDriverSearchForm extends Component {
  validateDate = (value, allValues) =>
    validateEndDate(value, allValues.fromDate);

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <FormGroup row>
          <FormItem label="Conductor">
            <Field name="driverIdSet" component={DriverSelect} isMulti />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Fecha" required>
            <Row>
              <Col>
                <Field
                  name="fromDate"
                  component={DatePicker}
                  placeholder="Fecha de Inicio"
                  validate={[isRequired]}
                />
              </Col>
              <Col>
                <Field
                  name="toDate"
                  component={DatePicker}
                  placeholder="Fecha de Fin"
                  validate={[isRequired, this.validateDate]}
                />
              </Col>
            </Row>
          </FormItem>
        </FormGroup>
        <Row>
          <Col className="flex row-reverse">
            <Button type="submit" color="secondary" outline>
              <i className="fa fa-search" /> Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

FuelConsumptionReportPerDriverSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'FuelConsumptionReportPerDriverSearchForm',
})(FuelConsumptionReportPerDriverSearchForm);
