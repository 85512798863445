// types should always be imperative verbs

export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES';
export const GET_SERVICE_TYPE = 'GET_SERVICE_TYPE';
export const FLAG_GETTING_SERVICE_TYPES = 'FLAG_GETTING_SERVICE_TYPES';
export const SHOW_ERROR_GETTING_SERVICE_TYPES = 'SHOW_ERROR_GETTING_SERVICE_TYPES';
export const CLEAR_ERROR_GETTING_SERVICE_TYPES = 'CLEAR_ERROR_GETTING_SERVICE_TYPES';

export const FLAG_GETTING_SERVICE_TYPE = 'FLAG_GETTING_SERVICE_TYPE';
export const SHOW_ERROR_GETTING_SERVICE_TYPE = 'SHOW_ERROR_GETTING_SERVICE_TYPE';
export const CLEAR_ERROR_GETTING_SERVICE_TYPE = 'CLEAR_ERROR_GETTING_SERVICE_TYPE';
export const CLEAR_SERVICE_TYPES = 'CLEAR_SERVICE_TYPES';
export const CLEAR_SERVICE_TYPE = 'CLEAR_SERVICE_TYPE';
