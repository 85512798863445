import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { PUT } from '../../../../config/permissions';
import { generatePutAssignExecutionToMaintenanceServiceOrderEndpoint } from '../../../../config/endpoints';

const MaintenanceServiceOrderAssignExecutionButton = ({
  maintenanceServiceOrderId,
}) => (
  <Link
    className="btn btn-light border"
    to={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/execution`}
  >
    <i className="fa fa-puzzle-piece" /> Ejecuciones
  </Link>
);

MaintenanceServiceOrderAssignExecutionButton.propTypes = {
  maintenanceServiceOrderId: PropTypes.number.isRequired,
};

const connectedComponent = connect()(
  MaintenanceServiceOrderAssignExecutionButton,
);

export default withEndpointAuthorization({
  url: generatePutAssignExecutionToMaintenanceServiceOrderEndpoint(0),
  permissionType: PUT,
})(connectedComponent);
