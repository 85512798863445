import Immutable from 'immutable';
import {
  FLAG_GETTING_ORGANIZATION_CHARTS,
  GET_ORGANIZATION_CHARTS,
  CLEAR_ORGANIZATION_CHARTS,
  FLAG_ORGANIZATION_CHART_ACTIVITY,
  CLEAR_ORGANIZATION_CHART,
  GET_ORGANIZATION_CHART,
  GET_MANAGEMENT_CONTENT,
  CLEAR_MANAGEMENT_CONTENT,
  GET_DEPARTMENT_CONTENT,
  CLEAR_DEPARTMENT_CONTENT,
  GET_AREA_CONTENT,
  CLEAR_AREA_CONTENT,
  GET_SECTION_CONTENT,
  CLEAR_SECTION_CONTENT,
  GET_SPECIALITY_CONTENT,
  CLEAR_SPECIALITY_CONTENT,
} from '../../../actions/types/mechanical-maintenance';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    managementContent: Immutable.Map(COLLECTION_SHAPE),
    departmentContent: Immutable.Map(COLLECTION_SHAPE),
    areaContent: Immutable.Map(COLLECTION_SHAPE),
    sectionContent: Immutable.Map(COLLECTION_SHAPE),
    specialityContent: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_ORGANIZATION_CHARTS:
      return state.setIn(['all', 'loading'], payload);
    case GET_ORGANIZATION_CHARTS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_ORGANIZATION_CHARTS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_ORGANIZATION_CHART_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_ORGANIZATION_CHART:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_ORGANIZATION_CHART:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case GET_MANAGEMENT_CONTENT:
      return state.setIn(['all', 'managementContent'], Immutable.Map(payload));
    case CLEAR_MANAGEMENT_CONTENT:
      return state.setIn(
        ['all', 'managementContent'],
        INITIAL_STATE.getIn(['all', 'managementContent']),
      );
    case GET_DEPARTMENT_CONTENT:
      return state.setIn(['all', 'departmentContent'], Immutable.Map(payload));
    case CLEAR_DEPARTMENT_CONTENT:
      return state.setIn(
        ['all', 'departmentContent'],
        INITIAL_STATE.getIn(['all', 'departmentContent']),
      );
    case GET_AREA_CONTENT:
      return state.setIn(['all', 'areaContent'], Immutable.Map(payload));
    case CLEAR_AREA_CONTENT:
      return state.setIn(
        ['all', 'areaContent'],
        INITIAL_STATE.getIn(['all', 'areaContent']),
      );
    case GET_SECTION_CONTENT:
      return state.setIn(['all', 'sectionContent'], Immutable.Map(payload));
    case CLEAR_SECTION_CONTENT:
      return state.setIn(
        ['all', 'sectionContent'],
        INITIAL_STATE.getIn(['all', 'sectionContent']),
      );
    case GET_SPECIALITY_CONTENT:
      return state.setIn(['all', 'specialityContent'], Immutable.Map(payload));
    case CLEAR_SPECIALITY_CONTENT:
      return state.setIn(
        ['all', 'specialityContent'],
        INITIAL_STATE.getIn(['all', 'specialityContent']),
      );
    default:
      return state;
  }
};
