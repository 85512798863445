import Cookies from 'js-cookie';
import { CIVA } from '../config/domains';

class Cookie {
  constructor() {
    this.options = process.env.NODE_ENV === 'production' ? CIVA : {};
  }

  set(name, value) {
    Cookies.set(name, value, this.options);
  }

  remove(name) {
    Cookies.remove(name, this.options);
  }

  // eslint-disable-next-line class-methods-use-this
  get(name) {
    return Cookies.get(name);
  }
}

export default new Cookie();
