import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_MAINTENANCE_WARNINGS,
  GET_MAINTENANCE_WARNINGS,
  CLEAR_MAINTENANCE_WARNINGS,
  FLAG_MAINTENANCE_WARNING_ACTIVITY,
  GET_MAINTENANCE_WARNING,
  CLEAR_MAINTENANCE_WARNING,
  FLAG_GETTING_MAINTENANCE_WARNINGS_IN_SELECT,
  GET_MAINTENANCE_WARNINGS_IN_SELECT,
  CLEAR_MAINTENANCE_WARNINGS_IN_SELECT,
} from '../types/mechanical-maintenance';
import { MAINTENANCE_WARNING_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
  DEFAULT_PATCH_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { MAINTENANCE_WARNING_PATH } from '../../config/paths';

const flagGettingMaintenanceWarnings =
  (flag, inSelect = false) =>
  (dispatch) =>
    dispatch({
      type: !inSelect
        ? FLAG_GETTING_MAINTENANCE_WARNINGS
        : FLAG_GETTING_MAINTENANCE_WARNINGS_IN_SELECT,
      payload: flag,
    });

const getMaintenanceWarnings =
  async (tableFilters, inSelect = false) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingMaintenanceWarnings(true, inSelect));
      const query = tableFilters;
      const url = `${MAINTENANCE_WARNING_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: !inSelect
          ? GET_MAINTENANCE_WARNINGS
          : GET_MAINTENANCE_WARNINGS_IN_SELECT,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingMaintenanceWarnings(false, inSelect));
    }
  };

const clearMaintenanceWarnings =
  (inSelect = false) =>
  (dispatch) =>
    dispatch({
      type: !inSelect
        ? CLEAR_MAINTENANCE_WARNINGS
        : CLEAR_MAINTENANCE_WARNINGS_IN_SELECT,
    });

const flagMaintenanceWarningActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MAINTENANCE_WARNING_ACTIVITY,
    payload: flag,
  });

const getMaintenanceWarning =
  async ({ maintenanceWarningId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceWarningActivity(true));
      const url = `${MAINTENANCE_WARNING_ENDPOINT}/${maintenanceWarningId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_WARNING,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceWarningActivity(false));
    }
  };

const clearMaintenanceWarning = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_WARNING,
  });

const postMaintenanceWarning =
  async ({
    reporterCustomerId,
    issueDate,
    registeredBusId,
    actualVirtualOdometer,
    actualRegisteredBusOdometer,
    maintenanceWarningType,
    maintenanceFailModeId,
    categoryId,
    subCategoryId,
    priority,
    recurrence,
    comments,
    status,
    creationType,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceWarningActivity(true));
      const payload = {
        reporterCustomerId,
        issueDate,
        registeredBusId,
        actualVirtualOdometer,
        actualRegisteredBusOdometer,
        maintenanceWarningType,
        maintenanceFailModeId,
        categoryId,
        subCategoryId,
        priority,
        recurrence,
        comments,
        status,
        creationType,
      };
      const url = MAINTENANCE_WARNING_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(MAINTENANCE_WARNING_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceWarningActivity(false));
    }
  };

const deleteMaintenanceWarning =
  async ({ maintenanceWarningId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceWarningActivity(true));
      const url = `${MAINTENANCE_WARNING_ENDPOINT}/${maintenanceWarningId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      dispatch(push(MAINTENANCE_WARNING_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceWarningActivity(false));
    }
  };

const putMaintenanceWarning =
  async (
    maintenanceWarningId,
    {
      reporterCustomerId,
      issueDate,
      registeredBusId,
      actualVirtualOdometer,
      actualRegisteredBusOdometer,
      maintenanceWarningType,
      maintenanceFailModeId,
      categoryId,
      subCategoryId,
      priority,
      recurrence,
      comments,
      status,
      creationType,
      activityBusId,
      createDate,
      lastUpdate,
      serviceOrderId,
      statusComment,
      userId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceWarningActivity(true));
      const payload = {
        id: maintenanceWarningId,
        reporterCustomerId,
        issueDate,
        registeredBusId,
        actualVirtualOdometer,
        actualRegisteredBusOdometer,
        maintenanceWarningType,
        maintenanceFailModeId,
        categoryId,
        subCategoryId,
        priority,
        recurrence,
        comments,
        status,
        creationType,
        activityBusId,
        createDate,
        lastUpdate,
        serviceOrderId,
        statusComment,
        userId,
      };
      const url = `${MAINTENANCE_WARNING_ENDPOINT}/${maintenanceWarningId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch(push(MAINTENANCE_WARNING_PATH));
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagMaintenanceWarningActivity(false));
    }
  };

const patchChangeServiceOrderIdOfMaintenanceWarning =
  async ({ maintenanceWarningId, serviceOrderId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceWarningActivity(true));
      const url = `${MAINTENANCE_WARNING_ENDPOINT}/${maintenanceWarningId}/service-order/${serviceOrderId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
      });
      await isErrorResponse(promise);
      await promise.json();
      return true;
    } catch ({ message }) {
      toastr.error('Error', message);
      return false;
    } finally {
      dispatch(flagMaintenanceWarningActivity(false));
    }
  };

export {
  flagGettingMaintenanceWarnings,
  getMaintenanceWarnings,
  clearMaintenanceWarnings,
  flagMaintenanceWarningActivity,
  getMaintenanceWarning,
  postMaintenanceWarning,
  clearMaintenanceWarning,
  deleteMaintenanceWarning,
  putMaintenanceWarning,
  patchChangeServiceOrderIdOfMaintenanceWarning,
};
