import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../../Badge';

const CargoItemMovementStatusCell = ({ value }) => {
  switch (value) {
    case 'ENTRY':
      return <Badge color="success" text="ENTRADA" />;
    case 'EXIT':
      return <Badge color="warning" text="SALIDA" />;
    case 'UNCLAIMED':
      return <Badge color="danger" text="SIN RECLAMAR" />;
    case 'DEPURATED':
      return <Badge color="info" text="DEPURADO" />;
    case 'LOST':
      return <Badge color="danger" text="PERDIDO" />;
    default:
      return <Badge color="info" text="INCAUTADO" />;
  }
};

CargoItemMovementStatusCell.propTypes = {
  value: PropTypes.string,
};

CargoItemMovementStatusCell.defaultProps = {
  value: '',
};

export default CargoItemMovementStatusCell;
