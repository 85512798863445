import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { deleteCargoDistance } from '../../../../actions';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';

class DeleteCargoDistanceButton extends Component {
  state = {
    showWarningModal: false,
  };

  showDeleteModal = () => this.setState({ showWarningModal: true });

  closeDeleteModal = () => this.setState({ showWarningModal: false });

  handleDelete = () => {
    const { cargoDistanceId, dispatchDeleteCargoDistance } = this.props;
    dispatchDeleteCargoDistance({ cargoDistanceId });
  };

  render() {
    const { showWarningModal } = this.state;

    const warningModal = (
      <ConfirmationModal
        show={showWarningModal}
        onClickConfirm={this.handleDelete}
        onClickCancel={this.closeDeleteModal}
      />
    );

    const deleteButton = (
      <Button color="danger" onClick={this.showDeleteModal}>
        Eliminar
      </Button>
    );

    return (
      <Fragment>
        {deleteButton}
        {warningModal}
      </Fragment>
    );
  }
}

DeleteCargoDistanceButton.propTypes = {
  cargoDistanceId: PropTypes.number.isRequired,
  dispatchDeleteCargoDistance: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteCargoDistance: deleteCargoDistance,
};

export default connect(null, mapDispatchToProps)(DeleteCargoDistanceButton);
