import { string, shape } from 'prop-types';
import cityBasicInformationPropTypes from '../../city/proptypes/CityPropTypes';
import districtBasicInformationPropTypes from '../../district/proptypes/DistrictPropTypes';

const addressBasicInformationPropTypes = {
  address: string,
  district: string,
  postalCode: string,
  city: shape(cityBasicInformationPropTypes),
  selectedDistrict: shape(districtBasicInformationPropTypes),
};

const addressBasicInformationDefaultProps = {
  address: null,
  district: null,
  postalCode: null,
  city: null,
  selectedDistrict: null,
};

export {
  addressBasicInformationPropTypes,
  addressBasicInformationDefaultProps,
};
