// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { handleResponseError } from '../../utils/error-handlers';
import { EXPENSE_REPORT_ENDPOINT } from '../../config/endpoints';
import {
  GET_EXPENSE_REPORTS,
  FLAG_GETTING_EXPENSE_REPORTS,
  CLEAR_ERROR_GETTING_EXPENSE_REPORTS,
  SHOW_ERROR_GETTING_EXPENSE_REPORTS,
  CLEAR_EXPENSE_REPORTS,
  CLEAR_ERROR_POSTING_EXPENSE_REPORT,
  POST_EXPENSE_REPORT,
  SHOW_ERROR_POSTING_EXPENSE_REPORT,
  FLAG_POSTING_EXPENSE_REPORT,
  DOWNLOAD_EXPENSE_REPORT,
  GET_EXPENSE_REPORT,
  SHOW_ERROR_GETTING_EXPENSE_REPORT,
  CLEAR_ERROR_GETTING_EXPENSE_REPORT,
  FLAG_GETTING_EXPENSE_REPORT,
  CLEAR_EXPENSE_REPORT,
} from '../types/index';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { getReportsServerEndpoint } from './Report';
import { EXPENSE_REPORTS_PATH } from '../../config/paths';
import { validateReportDateRange } from '../../utils/validators';

const flagGettingExpenseReports = flag => dispatch =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_EXPENSE_REPORTS,
  });

const showErrorGettingExpenseReports = error => dispatch =>
  dispatch({
    payload: error,
    type: SHOW_ERROR_GETTING_EXPENSE_REPORTS,
  });

const clearErrorGettingExpenseReports = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR_GETTING_EXPENSE_REPORTS,
  });

const getExpenseReports = async ({
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
} = {}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingExpenseReports());
  dispatch(flagGettingExpenseReports(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(
      dispatch,
      getState,
    );
    // make request
    const url = reportsServerEndpoint + EXPENSE_REPORT_ENDPOINT;
    const query = {
      page,
      size,
      sort,
    };
    const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
      method: 'GET',
      credentials: 'include',
    });
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingExpenseReports(false));
      return dispatch(showErrorGettingExpenseReports(serverError));
    }
    const reports = await response.json();
    dispatch({
      type: GET_EXPENSE_REPORTS,
      payload: reports,
    });
    return dispatch(flagGettingExpenseReports(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingExpenseReports(false));
    const errMsg = 'Error obteniendo los reportes de boletos.';
    return dispatch(showErrorGettingExpenseReports(errMsg));
  }
};

const clearExpenseReports = () => dispatch =>
  dispatch({
    type: CLEAR_EXPENSE_REPORTS,
  });

const showErrorPostingExpenseReport = error => dispatch =>
  dispatch({
    type: SHOW_ERROR_POSTING_EXPENSE_REPORT,
    payload: error,
  });

const clearErrorPostingExpenseReport = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR_POSTING_EXPENSE_REPORT,
  });

const flagPostingExpenseReport = flag => dispatch =>
  dispatch({
    type: FLAG_POSTING_EXPENSE_REPORT,
    payload: flag,
  });

const postExpenseReport = async ({ fromDate, toDate, force }) => async (
  dispatch,
  getState,
) => {
  dispatch(clearErrorPostingExpenseReport());

  // validate before raising the flag and outside the
  // try/catch so that the error is caught by redux form
  validateReportDateRange(fromDate, toDate);

  // raise flag
  dispatch(flagPostingExpenseReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(
      dispatch,
      getState,
    );
    const query = {
      force,
    };
    // make request
    const url = reportsServerEndpoint + EXPENSE_REPORT_ENDPOINT;
    const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        fromDate,
        toDate,
      }),
    });
    const serverError = await handleResponseError(response);
    if (serverError) {
      return dispatch(showErrorPostingExpenseReport(serverError));
    }
    const report = await response.json();
    dispatch({
      type: POST_EXPENSE_REPORT,
      payload: report,
    });
    dispatch(flagPostingExpenseReport(false));
    // redirect to report
    return dispatch(push(`${EXPENSE_REPORTS_PATH}/${report.id}`));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagPostingExpenseReport(false));
    const errMsg = 'Error en la creación del reporte de boletos.';
    return dispatch(showErrorPostingExpenseReport(errMsg));
  }
};

const flagGettingExpenseReport = flag => dispatch =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_EXPENSE_REPORT,
  });

const clearErrorGettingExpenseReport = () => dispatch =>
  dispatch({
    type: CLEAR_ERROR_GETTING_EXPENSE_REPORT,
  });

const showErrorGettingExpenseReport = error => dispatch =>
  dispatch({
    type: SHOW_ERROR_GETTING_EXPENSE_REPORT,
    payload: error,
  });

const getExpenseReport = async ({
  id,
  reportFormat = 'json',
  download = false,
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingExpenseReport());
  dispatch(flagGettingExpenseReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(
      dispatch,
      getState,
    );
    // make request
    const url = `${reportsServerEndpoint + EXPENSE_REPORT_ENDPOINT}/${id}`;
    const query = {
      reportFormat,
      page,
      size,
      sort,
    };
    const response = await fetch(`${url}?${QueryString.stringify(query)}`, {
      method: 'GET',
      credentials: 'include',
    });
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingExpenseReport(false));
      return dispatch(showErrorGettingExpenseReport(serverError));
    }
    // parse json or text depending on format
    const text = reportFormat === 'csv';
    const report = text ? await response.text() : await response.json();
    if (download) {
      dispatch({
        type: DOWNLOAD_EXPENSE_REPORT,
        payload: report,
      });
    } else {
      dispatch({
        type: GET_EXPENSE_REPORT,
        payload: report,
      });
    }
    return dispatch(flagGettingExpenseReport(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingExpenseReport(false));
    const errMsg = `Error obteniendo el reporte egresos #${id}.`;
    return dispatch(showErrorGettingExpenseReport(errMsg));
  }
};

const clearExpenseReport = () => dispatch =>
  dispatch({
    type: CLEAR_EXPENSE_REPORT,
  });

export {
  getExpenseReports,
  clearErrorGettingExpenseReports,
  clearExpenseReports,
  clearErrorPostingExpenseReport,
  postExpenseReport,
  getExpenseReport,
  clearExpenseReport,
  clearErrorGettingExpenseReport,
};
