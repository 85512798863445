import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ITINERARY_GROUP_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ItineraryGroupForm from './ItineraryGroupForm';
import Loader from '../../../common/Loader';
import {
  clearItineraryGroup,
  getItineraryGroup,
  getCircuit,
  clearCircuit,
  putItineraryGroup,
  getItinerariesByItineraryIdList,
  clearItineraryPromises,
} from '../../../../actions';
import { ITINERARY_GROUP_STATUS } from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';
import { mergeObjectsByIds } from '../../../../utils/promise';

export class EditItineraryGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { gettingCircuit: false, gettingItineraries: false };
  }

  componentDidMount() {
    const {
      match: {
        params: { id: itineraryGroupId },
      },
      dispatchGetItineraryGroup,
    } = this.props;
    dispatchGetItineraryGroup({ itineraryGroupId });
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    const {
      dispatchClearItineraryGroup,
      dispatchClearCircuit,
      dispatchClearItineraryPromises,
    } = this.props;
    dispatchClearItineraryGroup();
    dispatchClearCircuit();
    dispatchClearItineraryPromises();
  }

  onDidUpdate = () => {
    const {
      circuitGroup,
      circuitGroupLoading,
      dispatchGetCircuit,
      dispatchGetItinerariesByItineraryIdList,
    } = this.props;
    const { gettingCircuit, gettingItineraries } = this.state;
    if (!circuitGroupLoading && !circuitGroup.isEmpty()) {
      if (!gettingCircuit) {
        dispatchGetCircuit({ circuitId: circuitGroup.get('circuitId') });
        this.setState({ gettingCircuit: true });
      }
      if (!gettingItineraries) {
        const itineraryIdList = circuitGroup.get('circuitGroupItineraryList');
        dispatchGetItinerariesByItineraryIdList({ itineraryIdList });
        this.setState({ gettingItineraries: true });
      }
    }
  };

  onSubmit = (formValues) => {
    const newFormValues = {
      circuitGroupItineraryList: formValues.circuitGroupItineraryList.map(
        ({ value: itineraryId }) => ({
          itineraryId,
        }),
      ),
      circuitId: formValues.circuitId.value,
      comments: formValues.comments,
      registeredBusId: formValues.registeredBusId.value,
      status: formValues.status.value,
    };

    const {
      dispatchPutItineraryGroup,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutItineraryGroup(id, newFormValues);
  };

  generateInitialValues = (circuitGroup, circuit, itineraryPromises) => ({
    registeredBusId: {
      value: circuitGroup.get('registeredBusId'),
      label: circuitGroup.get('registeredBus').companyBusId,
    },
    circuitId: {
      value: circuitGroup.get('circuitId'),
      label: circuit.get('name'),
    },
    circuitGroupItineraryList: mergeObjectsByIds(
      circuitGroup.get('circuitGroupItineraryList'),
      'itineraryId',
      itineraryPromises,
      'id',
    ).map(({ itineraryId, data }) => ({
      label: data
        ? `${itineraryId} - ${data.route.name} - ${tzNormalizeDate({
            date: data.departureTime,
            format: DATE_TIME_FORMAT,
          })}`
        : itineraryId.toString(),
      value: itineraryId.toString(),
    })),
    comments: circuitGroup.get('comments'),
    status: ITINERARY_GROUP_STATUS[circuitGroup.get('status')],
  });

  render() {
    const {
      breadcrumbs,
      circuitGroup,
      circuitGroupLoading,
      circuit,
      circuitLoading,
      itineraryPromisesLoading,
      itineraryPromises,
    } = this.props;

    let content;

    if (
      circuitLoading ||
      circuitGroupLoading ||
      itineraryPromisesLoading ||
      circuitGroup.isEmpty()
    ) {
      content = <Loader />;
    } else {
      content = (
        <ItineraryGroupForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(
            circuitGroup,
            circuit,
            itineraryPromises,
          )}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Grupo de Itinerarios por Circuito"
        subtitle="Editar grupo de itinerarios por circuito"
        content={content}
      />
    );
  }
}

EditItineraryGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutItineraryGroup: PropTypes.func.isRequired,
  dispatchGetItineraryGroup: PropTypes.func.isRequired,
  dispatchClearItineraryGroup: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  circuitGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetCircuit: PropTypes.func.isRequired,
  dispatchClearCircuit: PropTypes.func.isRequired,
  circuitGroupLoading: PropTypes.bool,
  circuitLoading: PropTypes.bool,
  circuit: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetItinerariesByItineraryIdList: PropTypes.func.isRequired,
  itineraryPromisesLoading: PropTypes.bool.isRequired,
  itineraryPromises: PropTypes.instanceOf(Immutable.Set).isRequired,
  dispatchClearItineraryPromises: PropTypes.func.isRequired,
};

EditItineraryGroup.defaultProps = {
  circuitLoading: false,
  circuitGroupLoading: false,
};

const mapStateToProps = (
  { RouteUnit, ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Itinerarios por Circuito',
      href: ITINERARY_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_GROUP_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  circuitGroup: RouteUnit.ItineraryGroup.getIn(['current', 'content']),
  circuitGroupLoading: !RouteUnit.ItineraryGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  circuit: RouteUnit.Circuit.getIn(['current', 'content']),
  circuitLoading: !RouteUnit.Circuit.getIn(['current', 'activity']).isEmpty(),
  itineraryPromisesLoading: ItineraryUnit.Itinerary.getIn([
    'currentPromises',
    'loading',
  ]),
  itineraryPromises: ItineraryUnit.Itinerary.getIn([
    'currentPromises',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchPutItineraryGroup: putItineraryGroup,
  dispatchGetItineraryGroup: getItineraryGroup,
  dispatchClearItineraryGroup: clearItineraryGroup,
  dispatchGetCircuit: getCircuit,
  dispatchClearCircuit: clearCircuit,
  dispatchGetItinerariesByItineraryIdList: getItinerariesByItineraryIdList,
  dispatchClearItineraryPromises: clearItineraryPromises,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItineraryGroup);
