import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { Table } from '../../../../common/Table';
import { numberFormatter } from '../../../../../utils/number';
import Alert from '../../../../common/informative/Alert';
import { THERE_ARE_NOT_DATA_TO_SHOW } from '../../../../../config/messages';
import LiquidationGeneralIncomeDetailResource from './LiquidationGeneralIncomeDetailResource';
import {
  excessBaggageSummaryPropTypes,
  parcelIncomeSummaryPropTypes,
} from '../../salesSession/SalesSessionPropTypes';

const LiquidationIncomeResource = ({
  ticketSummaryList,
  generalIncomeSummaryList,
  generalIncomeTypeSummaryList,
  generalIncomePaymentMethodSummaryList,
  cashPaymentMethodList,
  excessBaggageSummaryList,
  totalSalesPaymentMethodSummaryList,
  parcelIncomeSummaryList,
}) => {
  const payVoucherData = ticketSummaryList.map(
    ({ documentSeries, totalAmount }) => ({
      documentSeries,
      totalAmount,
    }),
  );

  excessBaggageSummaryList.forEach((excess) => {
    payVoucherData.push({
      documentSeries: excess.documentSeries,
      totalAmount: excess.activeExcessBaggageAmount,
    });
  });

  parcelIncomeSummaryList.forEach((parcel) => {
    payVoucherData.push({
      documentSeries: parcel.documentSeries,
      totalAmount: parcel.totalAmount,
    });
  });

  let payVoucherTable = (
    <Alert type="warning" message={THERE_ARE_NOT_DATA_TO_SHOW} />
  );
  if (
    ticketSummaryList.length > 0 ||
    parcelIncomeSummaryList.length > 0 ||
    excessBaggageSummaryList.length > 0
  )
    payVoucherTable = (
      <Table
        columns={[
          {
            Header: 'Serie',
            accessor: 'documentSeries',
            className: 'text-center',
          },
          {
            Header: 'Total de Ventas',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={payVoucherData}
        defaultPageSize={payVoucherData.length}
        showPagination={false}
      />
    );

  let paymentMethodTable = (
    <Alert type="warning" message={THERE_ARE_NOT_DATA_TO_SHOW} />
  );
  if (totalSalesPaymentMethodSummaryList.length > 0)
    paymentMethodTable = (
      <Table
        columns={[
          {
            Header: 'Método de Pago',
            accessor: 'moneyFlowConcept',
            className: 'text-center',
          },
          {
            Header: 'Total',
            accessor: 'totalAmount',
            Cell: ({ value }) => numberFormatter({ value }),
            Footer: (row) =>
              `Total: ${numberFormatter({
                value: row.data.reduce(
                  (accumulated, data) => accumulated + data.totalAmount,
                  0,
                ),
              })}`,
            className: 'text-right',
          },
        ]}
        data={totalSalesPaymentMethodSummaryList}
        defaultPageSize={totalSalesPaymentMethodSummaryList.length}
        showPagination={false}
      />
    );

  let otherIncomes = 0;
  generalIncomePaymentMethodSummaryList.forEach((row) => {
    const foundCash = cashPaymentMethodList.find(
      (paymentMethod) => row.moneyFlowConcept === paymentMethod.name,
    );
    if (foundCash) otherIncomes += row.totalAmount;
  });

  let totalCash = 0;
  totalSalesPaymentMethodSummaryList.forEach((row) => {
    const foundCash = cashPaymentMethodList.find(
      (paymentMethod) => row.moneyFlowConcept === paymentMethod.name,
    );
    if (foundCash) totalCash += row.totalAmount;
  });

  const total = otherIncomes + totalCash;

  return (
    <>
      <hr />
      <h3>Ingresos</h3>
      <div className="mb-3">
        <h5>Tipos de Comprobante de Pago</h5>
        {payVoucherTable}
        {paymentMethodTable}
      </div>
      <LiquidationGeneralIncomeDetailResource
        generalIncomeSummaryList={generalIncomeSummaryList}
        generalIncomePaymentMethodSummaryList={
          generalIncomePaymentMethodSummaryList
        }
        generalIncomeTypeSummaryList={generalIncomeTypeSummaryList}
        cashPaymentMethodList={cashPaymentMethodList}
      />
      <ResourceProperty label="Efectivo total por Comprobante de Pago:">
        {numberFormatter({ value: totalCash })}
      </ResourceProperty>
      <h4 className="text-right">
        Ingresos Totales: {numberFormatter({ value: total })}
      </h4>
    </>
  );
};

LiquidationIncomeResource.propTypes = {
  ticketSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  generalIncomeSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      documentSeries: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  generalIncomeTypeSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  generalIncomePaymentMethodSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  cashPaymentMethodList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  totalSalesPaymentMethodSummaryList: PropTypes.arrayOf(
    PropTypes.shape({
      moneyFlowConcept: PropTypes.string,
      totalAmount: PropTypes.number,
    }),
  ),
  excessBaggageSummaryList: excessBaggageSummaryPropTypes,
  parcelIncomeSummaryList: parcelIncomeSummaryPropTypes,
};

LiquidationIncomeResource.defaultProps = {
  ticketSummaryList: [],
  generalIncomeSummaryList: [],
  generalIncomeTypeSummaryList: [],
  generalIncomePaymentMethodSummaryList: [],
  totalSalesPaymentMethodSummaryList: [],
  excessBaggageSummaryList: null,
  parcelIncomeSummaryList: [],
};

export default LiquidationIncomeResource;
