import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getBusinesses, clearBusinesses } from '../../../../actions/user/';
import { BUSINESS_PATH, NEW_BUSINESS_PATH } from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { BUSINESS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export const Businesses = ({
  breadcrumbs,
  businesses,
  loading,
  dispatchClearBusinesses,
  dispatchGetBusinesses,
}) => {
  useLayoutEffect(() => () => dispatchClearBusinesses(), []);

  const data = businesses.get('content') || [];
  const pages = businesses.get('totalPages') || null;
  const defaultPageSize = businesses.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Empresas"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_BUSINESS_PATH}
      tableStructure={{
        columns: BUSINESS_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetBusinesses,
        modelPath: BUSINESS_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetBusinesses: getBusinesses,
  dispatchClearBusinesses: clearBusinesses,
};

const mapStateToProps = ({ UserUnit }) => ({
  breadcrumbs: [
    ...UserUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Empresas',
      href: BUSINESS_PATH,
    },
  ],
  businesses: UserUnit.Business.getIn(['all', 'content']),
  loading: UserUnit.Business.getIn(['all', 'loading']),
});

Businesses.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  businesses: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetBusinesses: PropTypes.func.isRequired,
  dispatchClearBusinesses: PropTypes.func.isRequired,
};

Businesses.defaultProps = {
  businesses: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Businesses);
