import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import GeographicZoneForm from './GeographicZoneForm';
import { GEOGRAPHIC_ZONE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  getGeographicZone,
  clearGeographicZone,
  putGeographicZone,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditGeographicZone extends Component {
  componentDidMount() {
    const {
      dispatchGetGeographicZone,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetGeographicZone({ geographicZoneId: id });
  }

  componentWillUnmount() {
    const { dispatchClearGeographicZone } = this.props;
    dispatchClearGeographicZone();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutGeographicZone,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutGeographicZone(id, {
      name: formValues.name,
      description: formValues.description,
      cityList: formValues.cityList.map((city) => ({
        id: city.city.value,
        name: city.city.label,
      })),
    });
  };

  render() {
    const { breadcrumbs, geographicZone, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (geographicZone.isEmpty()) {
      content = <NoDataResource returnPage={GEOGRAPHIC_ZONE_PATH} />;
    } else {
      const initialValues = {
        name: geographicZone.get('name'),
        description: geographicZone.get('description'),
        cityList: geographicZone.get('cityList').map((city) => ({
          city: {
            value: city.id,
            label: `${city.name}, ${city.region.name}, ${city.region.country.name}`,
          },
        })),
      };

      content = (
        <GeographicZoneForm
          onSubmit={this.onSubmit}
          initialValues={initialValues}
        />
      );
    }
    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Zona Geográfica"
        subtitle="Editar zona geográfica"
        content={content}
      />
    );
  }
}

EditGeographicZone.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetGeographicZone: PropTypes.func.isRequired,
  dispatchClearGeographicZone: PropTypes.func.isRequired,
  dispatchPutGeographicZone: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  geographicZone: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditGeographicZone.defaultProps = {
  loading: false,
};

const mapStateToProps = (
  { LocationUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...LocationUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Zonas Geográficas',
      href: GEOGRAPHIC_ZONE_PATH,
    },
    {
      text: 'Ver',
      href: `${GEOGRAPHIC_ZONE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  geographicZone: LocationUnit.GeographicZone.getIn(['current', 'content']),
  loading: !LocationUnit.GeographicZone.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetGeographicZone: getGeographicZone,
  dispatchClearGeographicZone: clearGeographicZone,
  dispatchPutGeographicZone: putGeographicZone,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGeographicZone);
