import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AGENCY_PATH } from '../../../../config/paths';

const AgencyAddCreditLineButton = ({ agencyId }) => (
  <Link
    className="btn btn-light border"
    to={`${AGENCY_PATH}/${agencyId}/add-credit-line`}
  >
    <i className="fa fa-dollar" /> Agregar saldo
  </Link>
);

AgencyAddCreditLineButton.propTypes = {
  agencyId: PropTypes.number.isRequired,
};

export default AgencyAddCreditLineButton;
