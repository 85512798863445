import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import {
  getPrintTemplateTypes,
  clearPrintTemplateTypes,
} from '../../../../actions';

class PrintTemplateSelect extends Component {
  componentDidMount() {
    const { dispatchGetPrintTemplateTypes } = this.props;
    dispatchGetPrintTemplateTypes();
  }

  componentWillUnmount() {
    const { dispatchClearPrintTemplateTypes, cleanWhenUnmount } = this.props;
    if (cleanWhenUnmount) {
      dispatchClearPrintTemplateTypes();
    }
  }

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        {...rest}
      />
    );
  }
}

PrintTemplateSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetPrintTemplateTypes: PropTypes.func.isRequired,
  dispatchClearPrintTemplateTypes: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  isClearable: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  cleanWhenUnmount: PropTypes.bool,
};

PrintTemplateSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  isClearable: false,
  onValueChange: undefined,
  value: {},
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  options: HumanResourcesUnit.PrintTemplateType.getIn(['all', 'content'])
    .toJS()
    .map((template) => ({
      value: template,
      label: template,
    })),
  loading: HumanResourcesUnit.PrintTemplateType.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetPrintTemplateTypes: getPrintTemplateTypes,
  dispatchClearPrintTemplateTypes: clearPrintTemplateTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintTemplateSelect);
