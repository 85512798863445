// generic permissions
export const READ = 'READ';
export const WRITE = 'WRITE';

// http-based permissions
export const GET = 'READ';
export const POST = 'WRITE';
export const PATCH = 'WRITE';
export const DELETE = 'WRITE';
export const PUT = 'WRITE';
