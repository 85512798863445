import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRIVATE_SERVICE_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postPrivateService } from '../../../../actions';
import Content from '../../../layout/Content';
import PrivateServiceForm from './PrivateServiceForm';
import { getSubstring } from '../../../../utils/string';
import {
  CONTRACT_DOCUMENT_TYPE,
  CONTRACT_STATUS,
} from '../../../../config/constants';
import { currencyToNumber } from '../../../../utils/number';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const NewPrivateService = ({
  breadcrumbs,
  authenticatedUser,
  dispatchPostPrivateService,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    if (formValues.businessId)
      newFormValues.businessId = formValues.businessId.value;

    if (formValues.customerId)
      newFormValues.customerId = formValues.customerId.value;

    newFormValues.paymentMethodId = formValues.paymentMethodId.value;
    newFormValues.contractStatus = formValues.contractStatus.value;
    newFormValues.contractPaymentType = formValues.contractPaymentType.value;

    const sortedContractItineraryList = formValues.contractItineraryList.sort(
      (a, b) => {
        if (a.departureTime > b.departureTime) return 1;
        if (a.departureTime < b.departureTime) return -1;
        return 0;
      },
    );

    newFormValues.contractItineraryList = sortedContractItineraryList.map(
      ({
        movementType,
        registeredBusId,
        routeId,
        serviceTypeId,
        authorizedStop,
        routeDetail,
        itineraryId,
        kilometers,
        sourceAddress,
        departureTime,
        destinationAddress,
        arrivalTime,
        firstDriverId,
        secondDriverId,
        thirdDriverId,
        cabinCrewQuantity,
        expectedFuelConsumption,
      }) => ({
        movementType: movementType.value,
        registeredBusId: registeredBusId && registeredBusId.value,
        routeId: routeId && routeId.value,
        serviceTypeId: serviceTypeId && serviceTypeId.value,
        authorizedStop,
        routeDetail,
        itineraryId: itineraryId && getSubstring(itineraryId),
        kilometers,
        sourceAddress,
        departureTime:
          departureTime &&
          tzNormalizeDate({ date: departureTime, format: TIMESTAMP_FORMAT }),
        destinationAddress,
        arrivalTime:
          arrivalTime &&
          tzNormalizeDate({ date: arrivalTime, format: TIMESTAMP_FORMAT }),
        firstDriverId: firstDriverId && firstDriverId.value,
        secondDriverId: secondDriverId && secondDriverId.value,
        thirdDriverId: thirdDriverId && thirdDriverId.value,
        cabinCrewQuantity,
        expectedFuelConsumption,
      }),
    );

    newFormValues.totalAmount = currencyToNumber(formValues.totalAmount);
    newFormValues.amountBeforeDiscount = currencyToNumber(
      formValues.amountBeforeDiscount,
    );

    dispatchPostPrivateService(newFormValues);
  };

  const generateInititalValues = ({
    customer: { firstName, lastName, mobilePhone, email },
  }) => ({
    contractStatus: CONTRACT_STATUS.ACEPTED,
    executiveName: `${firstName} ${lastName}`,
    executivePosition: 'Ejecutivo Cuentas Clave',
    executivePhone: mobilePhone,
    executiveEmail: email,
    contractPaymentType: CONTRACT_DOCUMENT_TYPE.FACTURA,
  });

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Servicio Privado"
      subtitle="Crear un nuevo servicio privado"
      content={
        <PrivateServiceForm
          onSubmit={onSubmit}
          initialValues={generateInititalValues(authenticatedUser)}
        />
      }
    />
  );
};

const mapStateToProps = ({ ContractUnit, authentication }) => ({
  breadcrumbs: [
    ...ContractUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Servicios Privados',
      href: PRIVATE_SERVICE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  authenticatedUser: authentication.get('user'),
});

const mapDispatchToProps = {
  dispatchPostPrivateService: postPrivateService,
};

NewPrivateService.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPrivateService: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.shape({
    customer: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      mobilePhone: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPrivateService);
