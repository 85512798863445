import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../../config/locale';
import { maintenanceServiceOrderInternalInformationPropTypes } from '../prop-types/MaintenanceServiceOrderPropTypes';

const MaintenanceServiceOrderInternalInformation = ({
  id,
  createDate,
  lastUpdate,
}) => (
  <>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Fecha de creación:">
      {createDate
        ? tzNormalizeDate({
            date: createDate,
            format: FULL_DATE_TIME_FORMAT,
          })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Última actualización:">
      {lastUpdate
        ? tzNormalizeDate({
            date: lastUpdate,
            format: FULL_DATE_TIME_FORMAT,
          })
        : '-'}
    </ResourceProperty>
  </>
);

MaintenanceServiceOrderInternalInformation.propTypes =
  maintenanceServiceOrderInternalInformationPropTypes;

export default MaintenanceServiceOrderInternalInformation;
