import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import {
  clearPreventiveMaintenanceReport,
  postPreventiveMaintenanceReport,
} from '../../../../actions';
import { PREVENTIVE_MAINTENANCE_REPORT_PATH } from '../../../../config/paths';
import PreventiveMaintenanceReportSearchForm from '../preventive-maintenance-report/PreventiveMaintenanceReportSearchForm';
import {
  DATA_NOT_REGISTERED_ERROR_MESSAGE,
  EXECUTION_REGISTER_REQUIRED_FIELDS_ERROR_MESSAGE,
} from '../../../../config/messages';
import {
  ACTIVITY_STATUS,
  REGISTER_OF_EXECUTION_TYPE,
} from '../../../../config/constants';
import { postExecutionRegistration } from '../../../../actions/mechanical-maintenance/ExecutionRegistration';
import { tzNormalizeDate } from '../../../../utils/date';
import AdvancedExecutionRegisterTable from './AdvancedExecutionRegisterTable';
import { INT_DATE_FORMAT } from '../../../../config/locale';

export const AdvancedExecutionRegistration = ({
  breadcrumbs,
  dispatchPostPreventiveMaintenanceReport,
  dispatchClearPreventiveMaintenanceReport,
  dispatchPostExecutionRegistration,
  loading,
}) => {
  const [searchValues, setSearchValues] = useState({
    registeredBusIdList: [],
    homeBaseLocationId: null,
    daily: false,
    dateTo: null,
    simulationDays: 0,
    simulationKilometers: 0,
    size: 10000,
  });
  const [registerOfExecutionType, setRegisterOfExecutionType] = useState(
    REGISTER_OF_EXECUTION_TYPE.false.value,
  );

  const searchActivities = (searchFormValues) => {
    dispatchClearPreventiveMaintenanceReport();
    dispatchPostPreventiveMaintenanceReport(searchFormValues);
  };

  const onSubmitToSearch = (formValues) => {
    const values = {
      registeredBusIdList: formValues.registeredBusIdList.map(
        (bus) => bus.value,
      ),
      homeBaseLocationId:
        formValues.homeBaseLocationId && formValues.homeBaseLocationId.value,
      daily: !!formValues.daily,
      dateTo:
        formValues.dateTo && !formValues.daily
          ? tzNormalizeDate({ date: formValues.dateTo })
          : tzNormalizeDate(),
      simulationDays: formValues.simulationDays || 0,
      simulationKilometers: formValues.simulationKilometers || 0,
      size: 10000,
    };
    setSearchValues(values);
    setRegisterOfExecutionType(formValues.registerOfExecutionType.value);
    searchActivities(values);
  };

  const validateValues = (formValues) => {
    let valid = true;
    formValues.forEach(
      ({
        status,
        extensionFactor,
        startDate,
        endDate,
        workingHour,
        mechanics,
      }) => {
        if (
          (status !== ACTIVITY_STATUS.PENDING.value &&
            !startDate &&
            !endDate &&
            !workingHour &&
            !mechanics) ||
          (status === ACTIVITY_STATUS.EXTENDED.value && !extensionFactor)
        )
          valid = false;
      },
    );
    return valid;
  };

  const onClickRegisterExecution = async (formValues) => {
    const valid = validateValues(formValues);
    if (!valid)
      toastr.error('Error', EXECUTION_REGISTER_REQUIRED_FIELDS_ERROR_MESSAGE);
    else {
      const filteredFormValues = registerOfExecutionType
        ? formValues.filter((row) => row.status === ACTIVITY_STATUS.DONE.value)
        : formValues;

      const registerFormValues = filteredFormValues.map(
        ({
          status,
          comment,
          mechanics,
          startDate,
          endDate,
          workingHour,
          extensionFactor,
          busHomeBaseId,
          activityBusId,
        }) => {
          const employeeList = [];
          if (mechanics)
            mechanics.forEach((mechanic) => {
              employeeList.push({ employeeId: mechanic.value });
            });

          return {
            advanced: registerOfExecutionType,
            status: registerOfExecutionType
              ? ACTIVITY_STATUS.DONE.value
              : status,
            comment,
            employeeList,
            startDate: startDate ? tzNormalizeDate({ date: startDate }) : null,
            endDate: endDate
              ? tzNormalizeDate({ date: endDate })
              : searchValues.dateTo,
            workingHour: workingHour || null,
            extensionFactor: registerOfExecutionType ? null : extensionFactor,
            baseLocationId: busHomeBaseId,
            activityBusId,
          };
        },
      );

      const response = await dispatchPostExecutionRegistration(
        registerFormValues,
      );
      if (!response) toastr.error('Error', DATA_NOT_REGISTERED_ERROR_MESSAGE);
      else searchActivities(searchValues);
    }
  };

  const subtitle = (
    <span>
      Busca las actividades, selecciona el estado, los mecánicos, las fechas y
      horas de ejecución y registrarlos haciendo click en el botón{' '}
      <strong>Registrar Ejecución</strong>
    </span>
  );

  const dateTo = tzNormalizeDate({ format: INT_DATE_FORMAT });

  const content = (
    <>
      <PreventiveMaintenanceReportSearchForm
        onSubmit={onSubmitToSearch}
        initialValues={{
          dateTo,
          registerOfExecutionType: REGISTER_OF_EXECUTION_TYPE.true,
        }}
        searchButtonDisabled={loading}
        registeredBusIsRequired
        registeredBusIsMulti
        showRegisterOfExecutionType
        disableDateTo
      />
      <AdvancedExecutionRegisterTable
        onHandleRegisterExecution={onClickRegisterExecution}
        showAdvanced={registerOfExecutionType}
      />
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Registro de la Ejecución Adelantado"
      subtitle={subtitle}
      content={content}
    />
  );
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Registro de la Ejecución Adelantado',
      href: PREVENTIVE_MAINTENANCE_REPORT_PATH,
    },
  ],
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

AdvancedExecutionRegistration.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPreventiveMaintenanceReport: PropTypes.func.isRequired,
  dispatchClearPreventiveMaintenanceReport: PropTypes.func.isRequired,
  dispatchPostExecutionRegistration: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchPostPreventiveMaintenanceReport: postPreventiveMaintenanceReport,
  dispatchClearPreventiveMaintenanceReport: clearPreventiveMaintenanceReport,
  dispatchPostExecutionRegistration: postExecutionRegistration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedExecutionRegistration);
