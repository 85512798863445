import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { postBillingGrtByItinerary } from '../../../../../actions';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { generateGrtBillingByItineraryEndpoint } from '../../../../../config/endpoints';
import { POST } from '../../../../../config/permissions';

const ItinerarySendGrtButton = ({
  itineraryId,
  dispatchPostBillingGrtByItinerary,
}) => {
  const onSendGrt = () => dispatchPostBillingGrtByItinerary(itineraryId);

  return (
    <Button color="success" className="border" onClick={onSendGrt}>
      <i className="fa fa-send" /> Enviar GRT
    </Button>
  );
};

ItinerarySendGrtButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
  dispatchPostBillingGrtByItinerary: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostBillingGrtByItinerary: postBillingGrtByItinerary,
};

const connectedComponent = connect(
  null,
  mapDispatchToProps,
)(ItinerarySendGrtButton);

export default withEndpointAuthorization({
  url: generateGrtBillingByItineraryEndpoint(0),
  permissionType: POST,
})(connectedComponent);
