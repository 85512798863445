import React, { Fragment } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ItinerarySchedulingButton from './ItinerarySchedulingButton';
import ItineraryEditButton from './ItineraryEditButton';
import PrintDropdownButton from '../PrintDropdownButton';
import PrintExpenseButton from '../PrintExpenseButton';
import PrintFuelVoucherButton from '../PrintFuelVoucherButton';
import ItineraryDeleteButton from './ItineraryDeleteButton';
import ExtraordinaryMovementEditButton from '../../extraordinary-movement/ExtraordinaryMovementEditButton';
import ItinerarySendGrtButton from './ItinerarySendGrtButton';
import ItinerarySendGrrButton from './ItinerarySendGrrButton';

const ItineraryToolbar = ({ itineraryId, scheduled, drivers, type }) => {
  let toolbar = null;
  let rescueItineraryButton = null;

  if (!scheduled) {
    toolbar = <ItinerarySchedulingButton itineraryId={itineraryId} />;
  } else {
    rescueItineraryButton =
      type.toLowerCase() === 'regular' ? (
        <ExtraordinaryMovementEditButton itineraryId={itineraryId} />
      ) : null;

    toolbar = (
      <Fragment>
        <PrintDropdownButton /> <PrintExpenseButton drivers={drivers} />{' '}
        <PrintFuelVoucherButton />{' '}
        <ItinerarySendGrtButton itineraryId={itineraryId} />{' '}
        <ItinerarySendGrrButton itineraryId={itineraryId} />
      </Fragment>
    );
  }

  return (
    <ButtonToolbar className="pull-right">
      <div>
        {rescueItineraryButton} {toolbar}{' '}
        <ItineraryEditButton itineraryId={itineraryId} />{' '}
        {<ItineraryDeleteButton itineraryId={itineraryId} />}
      </div>
    </ButtonToolbar>
  );
};

ItineraryToolbar.propTypes = {
  itineraryId: PropTypes.number.isRequired,
  scheduled: PropTypes.bool.isRequired,
  drivers: PropTypes.instanceOf(Immutable.Set).isRequired,
  type: PropTypes.string.isRequired,
};

export default ItineraryToolbar;
