import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import BillingEditButton from './BillingEditButton';
import PrintBillingButton from './PrintBillingButton';
import { BILL_PROCESS_STATUS_PROCESSED } from '../../../../config/constants';

const BillingToolbar = ({ billingId, ticket, status }) => (
  <ButtonToolbar className="pull-right">
    <div>
      {status !== BILL_PROCESS_STATUS_PROCESSED && (
        <BillingEditButton billingId={billingId} />
      )}{' '}
      {ticket && <PrintBillingButton ticket={ticket} />}
    </div>
  </ButtonToolbar>
);

BillingToolbar.propTypes = {
  billingId: PropTypes.number.isRequired,
  ticket: PropTypes.string,
  status: PropTypes.string.isRequired,
};

BillingToolbar.defaultProps = {
  ticket: null,
};

export default BillingToolbar;
