import { validateIdDocumentNumber } from '../../../../utils/validators';

const validate = ({ idDocumentNumber, identificationTypeId }) => {
  const errors = {};

  if (identificationTypeId && idDocumentNumber) {
    errors.idDocumentNumber = validateIdDocumentNumber(
      idDocumentNumber,
      identificationTypeId,
    );
  }

  return errors;
};

export default validate;
