import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { generateGetItineraryForCounterEndpoint } from '../../../../../config/endpoints';
import { PATCH } from '../../../../../config/permissions';

const EditItineraryButton = ({ itineraryId }) => (
  <Link
    className="btn btn-primary"
    to={`${ITINERARY_FOR_COUNTER_PATH}/${itineraryId}/edit`}
  >
    <i className="fa fa-edit" /> Editar
  </Link>
);

EditItineraryButton.propTypes = {
  itineraryId: PropTypes.number.isRequired,
};

export default withEndpointAuthorization({
  url: generateGetItineraryForCounterEndpoint(),
  mapUrlParamsToProps: { itineraryId: 'itineraryId' },
  permissionType: PATCH,
})(EditItineraryButton);
