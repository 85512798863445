import { string, number, bool, shape } from 'prop-types';
import { addressBasicInformationPropTypes } from '../../address/proptypes/AddressPropTypes';

const locationBasicInformationPropTypes = {
  name: string.isRequired,
  description: string,
  locationCorrelationId: number,
  address: shape(addressBasicInformationPropTypes),
};

const locationBasicInformationDefaultProps = {
  description: null,
  locationCorrelationId: null,
  address: null,
  agencyCode: null,
};

const locationAttributeInformationPropTypes = {
  isHomeBase: bool.isRequired,
  isTerminal: bool.isRequired,
  isPointOfSale: bool.isRequired,
  isStop: bool.isRequired,
  isToll: bool.isRequired,
  isCargoPoint: bool.isRequired,
};

export {
  locationBasicInformationPropTypes,
  locationBasicInformationDefaultProps,
  locationAttributeInformationPropTypes,
};
