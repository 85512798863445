import { number, arrayOf, shape } from 'prop-types';
import { locationBasicInformationPropTypes } from '../../../location/location/proptypes/LocationPropTypes';
import tollPropTypes from '../../toll/proptypes/TollPropTypes';

const segmentBasicInformationPropTypes = {
  id: number.isRequired,
  sourceLocationId: number.isRequired,
  sourceLocation: shape(locationBasicInformationPropTypes),
  destinationLocationId: number.isRequired,
  destinationLocation: shape(locationBasicInformationPropTypes),
  distance: number.isRequired,
  duration: number.isRequired,
};

const tollSegmentListPropTypes = {
  tollSegmentList: arrayOf(
    shape({
      id: number.isRequired,
      segmentId: number.isRequired,
      tollId: number.isRequired,
      toll: shape(tollPropTypes),
    }),
  ),
};

const tollSegmentListDefaultProps = {
  tollSegmentList: [],
};

export {
  segmentBasicInformationPropTypes,
  tollSegmentListPropTypes,
  tollSegmentListDefaultProps,
};
