import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  BAGGAGE_UNIT_PATH,
  BAGGAGE_PATH,
  NEW_BAGGAGE_PATH,
  BAGGAGE_ITEM_MOVEMENT_PATH,
} from '../../../config/paths';
import BaggageHome from './BaggageHome';
import Baggages from './baggage/Baggages';
import NewBaggage from './baggage/NewBaggage';
import Baggage from './baggage/Baggage';
import EditBaggage from './baggage/EditBaggage';
import NewBaggageItemMovement from './baggage-item-movement/NewBaggageItemMovement';

const BaggageUnit = () => (
  <Switch>
    <Route exact path={BAGGAGE_UNIT_PATH} component={BaggageHome} />
    <Route exact path={BAGGAGE_PATH} component={Baggages} />
    <Route exact path={NEW_BAGGAGE_PATH} component={NewBaggage} />
    <Route exact path={`${BAGGAGE_PATH}/:id`} component={Baggage} />
    <Route exact path={`${BAGGAGE_PATH}/:id/edit`} component={EditBaggage} />
    <Route
      exact
      path={BAGGAGE_ITEM_MOVEMENT_PATH}
      component={NewBaggageItemMovement}
    />
  </Switch>
);

export default BaggageUnit;
