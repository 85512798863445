import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { POSTPAID_BOOKING_STATUS_OPTIONS } from '../../../config/constants';

const PostpaidBookingStatusFilter = ({ onChangeFilter }) => (
  <ReactSelect
    isSearchable={false}
    isClearable
    menuPortalTarget={document.body}
    styles={{ menuPortal: base => ({ ...base, zIndex: 1000 }) }}
    onChange={option => onChangeFilter(option ? option.value : null)}
    menuPosition="fixed"
    menuPlacement="bottom"
    options={POSTPAID_BOOKING_STATUS_OPTIONS}
  />
);

PostpaidBookingStatusFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default PostpaidBookingStatusFilter;
