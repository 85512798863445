import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  baseFuelRefillBasicInformationDefaultProps,
  baseFuelRefillBasicInformationPropTypes,
} from '../proptypes/BaseFuelRefillPropTypes';
import { tzNormalizeDate } from '../../../../../utils/date';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import { ITINERARY_PATH } from '../../../../../config/paths';
import { MATERIAL_MEASUREMENT_UNIT } from '../../../../../config/constants';
import { DATE_FORMAT } from '../../../../../config/locale';

const BaseFuelRefillBasicInformationResource = ({
  itineraryId,
  registeredBus,
  createDate,
  fuelQuantity,
  adblueQuantity,
  comment,
}) => (
  <>
    <ResourceProperty label="ID de Itinerario:">
      {itineraryId ? (
        <LinkToModuleResource
          text={itineraryId.toString()}
          href={`${ITINERARY_PATH}/${itineraryId}`}
        />
      ) : (
        '-'
      )}
    </ResourceProperty>
    <ResourceProperty label="Bus:">
      {registeredBus.companyBusId}
    </ResourceProperty>
    <ResourceProperty label="Fecha:">
      {tzNormalizeDate({ date: createDate, format: DATE_FORMAT })}
    </ResourceProperty>
    <ResourceProperty label="Combustible Abastecido:">
      {fuelQuantity} {MATERIAL_MEASUREMENT_UNIT[1]}
    </ResourceProperty>
    <ResourceProperty label="Adblue Abastecido:">
      {adblueQuantity} {MATERIAL_MEASUREMENT_UNIT[1]}
    </ResourceProperty>
    <ResourceProperty label="Comentario:">{comment || '-'}</ResourceProperty>
  </>
);

BaseFuelRefillBasicInformationResource.propTypes =
  baseFuelRefillBasicInformationPropTypes;

BaseFuelRefillBasicInformationResource.defaultProps =
  baseFuelRefillBasicInformationDefaultProps;

export default BaseFuelRefillBasicInformationResource;
