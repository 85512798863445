import autoTable from 'jspdf-autotable';
import { DATE_TIME_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';
import { numberFormatter } from '../number';
import {
  addQrCode,
  addText,
  createPdfDocument,
  paragraphSize,
  setFont,
  y,
} from '../pdf';
import { padStart } from '../string';
import { PARCEL_PAYMENT_STATUS } from '../../config/constants';

const myX = 3;
let myY = y;
const margin = 3;
let verticalOffset = 0;
let isFirstPrint = true;
const preFooter =
  'Autorizado mediante Resolución de Intendencia: Número 0180050002242/SUNAT Para mayor información sobre todos los términos y condiciones puede ingresar a nuestra página web: http://www.civa.com.pe';

const footer =
  'Al recibir el presente documento acepto todos los términos del contrato del servicio de transporte publicados en las agencias y página web. Documentos de embarque: el pasajero debe presentar obligatoriamente el boleto de viaje en físico o virtual y documento de identificación en físico (DNI o pasaporte o carnet de extranjería o cédula de identificación). Nota: La condición migratoria es responsabilidad del pasajero. Pasajeros menores de edad que viajen solos o en compañía de un adulto que no sean sus padres, deben presentar DNI y el acta de autorización de viaje notarial suscrita por uno de los padres para viajes nacionales, para viajes internacionales deben presentar el acta de autorización de viaje notarial suscrita por ambos padres. Cambio de Titularidad: La anticipación mínima es de 8 horas antes de la fecha y hora de viaje. Postergación: La anticipación mínima para el cambio de ruta, hora o tipo de servicio, es de 24 horas antes de la fecha y hora de viaje. La postergación o Cambio de titularidad lo puede realizar solo de manera virtual al siguiente correo electrónico: postventa@civa.com.pe (el horario de atención de respuesta es de 6:00 am - 10:00 pm). Para realizar el trámite debe enviar la siguiente información por correo: ASUNTO: "Solicito Cambio de Titularidad / Postergación" CONTENIDO DEL MENSAJE: Debe indicar Nombres y Apellidos del pasajero, adjuntar documentos legibles: Documento de identificación (DNI / pasaporte / CE / CI) y Boleto de Viaje.';

const qrWidth = 35;
const qrHeight = 35;

const printLine = (doc, factor = 1) => {
  addText(
    doc,
    '----------------------------------------------------------------------------',
    40,
    (myY += margin * factor),
    80,
  );
};

const printCompanyFields = (doc) => {
  setFont(doc, 'bold');
  doc.setFontSize(paragraphSize);
  addText(doc, 'TURISMO CIVA S.A.C.', 40, myY, 80);
  setFont(doc);
  addText(doc, 'Si va de viaje, ¡viaje en Civa!', 40, (myY += margin), 80);
  addText(doc, 'R.U.C 20102427891', 40, (myY += margin), 80);
  addText(doc, 'JR SANCHO DE RIVERA 1184', 40, (myY += margin), 80);
  addText(doc, 'URB. MONSERRATE LIMA', 40, (myY += margin), 80);
  addText(doc, 'Central Telefónica: (511) 418-1111', 40, (myY += margin), 80);
  printLine(doc);
};

const printTicketFields = (
  doc,
  { issuingAgency, createDate, userName, voucherType, ticketCode },
) => {
  const agencyName = doc.splitTextToSize(
    `Agencia de Emisión: ${issuingAgency}`,
    75,
  );
  addText(doc, agencyName, myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `Fecha/Hora Emisión: ${createDate}`,
    myX,
    (myY += margin * 2),
    75,
    'left',
  );
  addText(doc, 'Usuario:', myX, (myY += margin), 75, 'left');
  addText(doc, ':', myX + 34, myY);
  const newUserName = doc.splitTextToSize(userName, 40);
  addText(doc, newUserName, myX + 11, myY, 40, 'left');
  setFont(doc, 'bold');
  doc.setFontSize(14);
  myY += 2;

  const voucherText = doc.splitTextToSize(voucherType.toUpperCase(), 75);
  addText(doc, voucherText, 40, (myY += margin), 75);

  addText(doc, ticketCode, 40, (myY += 5.5 * voucherText.length), 75);
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printShipmentField = (
  doc,
  {
    origin,
    sourceAgencyCode,
    destiny,
    destinationAgencyCode,
    destinyDirection,
  },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL ENVIO', myX, (myY += margin * 2), 75, 'left');

  addText(doc, 'Origen', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${origin}`, 23, myY, 55, 'left');

  addText(doc, 'Cod. de Establecimiento', myX, (myY += margin), 35, 'left');
  setFont(doc, 'bold');
  addText(doc, `: ${sourceAgencyCode}`, 37, myY, 42, 'left');

  setFont(doc, 'bold');
  addText(doc, 'Destino', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${destiny}`, 23, myY, 55, 'left');

  addText(doc, 'Cod. de Establecimiento', myX, (myY += margin), 35, 'left');
  setFont(doc, 'bold');
  addText(doc, `: ${destinationAgencyCode}`, 37, myY, 42, 'left');

  addText(doc, 'Dirección', myX, (myY += margin), 30, 'left');
  setFont(doc, 'bold');
  addText(doc, `: ${destinyDirection}`, 23, myY, 55, 'left');

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printSenderField = (
  doc,
  { senderName, senderDocumentType, senderDocumentNumber },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL REMITENTE', myX, (myY += margin * 2), 75, 'left');

  const senderNameText = doc.splitTextToSize(`Nombre: ${senderName}`, 75);

  addText(doc, senderNameText, myX, (myY += margin), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += margin * senderNameText.length),
    30,
    'left',
  );
  addText(doc, `: ${senderDocumentType}`, 31, myY, 55, 'left');

  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${senderDocumentNumber}`, 36, myY, 55, 'left');

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printConsignedField = (
  doc,
  { consignedName, consignedDocumentType, consignedDocumentNumber },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL CONSIGNADO', myX, (myY += margin * 2), 75, 'left');

  const consignedNameText = doc.splitTextToSize(`Nombre: ${consignedName}`, 75);

  addText(doc, consignedNameText, myX, (myY += margin), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += margin * consignedNameText.length),
    30,
    'left',
  );
  addText(doc, `: ${consignedDocumentType}`, 31, myY, 55, 'left');

  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${consignedDocumentNumber}`, 36, myY, 55, 'left');
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};
const printCustomerField = (
  doc,
  { customerName, customerDocumentType, customerDocumentNumber },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL CLIENTE', myX, (myY += margin * 2), 75, 'left');

  const customerNameText = doc.splitTextToSize(`Nombre: ${customerName}`, 75);

  addText(doc, customerNameText, myX, (myY += margin), 80, 'left');

  addText(
    doc,
    'Tipo de Documento',
    myX,
    (myY += margin * customerNameText.length),
    30,
    'left',
  );

  addText(doc, `: ${customerDocumentType}`, 31, myY, 55, 'left');
  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${customerDocumentNumber}`, 36, myY, 55, 'left');
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};
const printBusinessFields = (doc, { businessName, businessTaxId }) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE LA EMPRESA', myX, (myY += margin * 2), 75, 'left');
  addText(doc, 'Nombre', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${businessName}`, 15, myY, 55, 'left');
  addText(doc, 'Tipo de Documento', myX, (myY += margin), 30, 'left');
  addText(doc, ': RUC', 31, myY, 55, 'left');
  addText(doc, 'Número de Documento', myX, (myY += margin), 35, 'left');
  addText(doc, `: ${businessTaxId}`, 36, myY, 55, 'left');
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const groupItemsByTicketIdentification = (cargoItemList) =>
  Object.values(
    cargoItemList.reduce((accumulator, currentItem) => {
      const ticketId = currentItem.ticketIdentificationId;
      const existingItem = accumulator[ticketId] || {
        ...currentItem,
        quantity: 0,
        totalPrice: 0,
      };

      return {
        ...accumulator,
        [ticketId]: {
          ...existingItem,
          quantity: existingItem.quantity + currentItem.quantity,
          totalPrice: existingItem.totalPrice + currentItem.salePrice,
        },
      };
    }, {}),
  );
const printList = (doc, cargoItemList) => {
  const columns = [
    { header: 'Cant.', dataKey: 'quantity' },
    { header: 'Categoría', dataKey: 'cargoCategoryName' },
    { header: 'Peso', dataKey: 'weight' },
    { header: 'P. Unitario', dataKey: 'salePrice' },
    { header: 'P. Total', dataKey: 'totalPrice' },
  ];

  const body = cargoItemList.map((item) => [
    item.quantity.toString(),
    item.cargoCategoryName,
    item.weight.toFixed(2),
    item.salePrice.toFixed(2),
    item.totalPrice.toFixed(2),
  ]);

  autoTable(doc, {
    columns,
    body,
    headStyles: {
      fillColor: '#fff',
      lineColor: '#000',
      lineWidth: { top: 0.1, bottom: 0.1 },
      halign: 'center',
    },
    startY: myY + margin * 2,
    margin: 4,
    theme: 'plain',
    styles: {
      overflow: 'linebreak',
      cellPadding: 0,
      textColor: 0,
      fontSize: 9,
      halign: 'center',
    },
  });

  // Update myY after printing table
  myY = doc.autoTable.previous.finalY;
};

const sumTotalWeight = (cargoItemList) =>
  cargoItemList.reduce((total, item) => total + item.weight, 0);

const printPaymentFields = (
  doc,
  {
    ticketSalePrice,
    ticketSalePriceString,
    ticketListPrice,
    detractionAmount,
    salePriceWithDetraction,
    discountAmount,
    salePriceWithDiscount,
    salePriceWithDiscountString,
    detractionCode,
    bankAccountNumber,
    detractionRate,
    salePriceWithDetractionString,
    paymentMethod,
    paymentStatus,
  },
) => {
  const isPaymentOnArrival = paymentMethod === 'Pago en destino';
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE PAGO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, 'IGV', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `: ${numberFormatter({ value: ticketSalePrice - ticketListPrice })}`,
    myX + 34,
    myY,
  );
  addText(doc, 'OP. GRATUITO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'OP. GRAVADA', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `: ${numberFormatter({ value: ticketListPrice })}`,
    myX + 34,
    myY,
  );
  if (discountAmount !== null) {
    addText(doc, 'IMPORTE TOTAL', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({
        value: ticketSalePrice,
      })}`,
      myX + 34,
      myY,
    );
  }
  addText(doc, 'DESCUENTO', myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `: ${numberFormatter({ value: discountAmount || 0 })}`,
    myX + 34,
    myY,
  );
  if (detractionAmount === null) {
    addText(doc, 'IMPORTE TOTAL A PAGAR', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({
        value: salePriceWithDiscount || ticketSalePrice,
      })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'Son:', myX, (myY += margin), 10, 'left');
    const priceString =
      salePriceWithDiscountString != null
        ? salePriceWithDiscountString
        : ticketSalePriceString;
    const newticketSalePriceString = doc.splitTextToSize(priceString, 60);
    addText(doc, newticketSalePriceString, myX + 6, myY, 60, 'left');
  } else {
    addText(doc, 'IMPORTE TOTAL A PAGAR', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({
        value: salePriceWithDiscount || ticketSalePrice,
      })}`,
      myX + 34,
      myY,
    );
    setFont(doc, 'bold');
    addText(doc, 'DETRACCION:', myX, (myY += margin * 2), 80, 'left');
    setFont(doc);
    addText(doc, 'Código de detracción', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: detractionCode, digits: 3 })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'Número de cuenta', myX, (myY += margin), 75, 'left');
    addText(doc, `: ${bankAccountNumber}`, myX + 34, myY);
    addText(doc, 'Porcentaje de detracción', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: detractionRate, style: 'percent' })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'Monto de detracción', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: detractionAmount })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'IMPORTE NETO A PAGAR', myX, (myY += margin), 75, 'left');
    addText(
      doc,
      `: ${numberFormatter({ value: salePriceWithDetraction })}`,
      myX + 34,
      myY,
    );
    addText(doc, 'Son:', myX, (myY += margin), 10, 'left');
    const newSalePriceWithDetractionString = doc.splitTextToSize(
      salePriceWithDetractionString,
      60,
    );
    addText(doc, newSalePriceWithDetractionString, myX + 6, myY, 60, 'left');
  }
  const paymentType = isPaymentOnArrival
    ? doc.splitTextToSize('Crédito', 40)
    : doc.splitTextToSize('Contado', 40);

  addText(
    doc,
    'Tipo de Pago',
    myX,
    (myY += margin * paymentType.length),
    75,
    'left',
  );
  addText(doc, ':', myX + 34, myY);
  paymentType.forEach((line, index) => {
    addText(doc, line, myX + 36, myY + index * 10, 40, 'left');
  });

  addText(
    doc,
    'Método de Pago',
    myX,
    (myY += margin * paymentType.length),
    75,
    'left',
  );
  addText(doc, ':', myX + 34, myY);
  const newPaymentMethod = doc.splitTextToSize(paymentMethod, 40);
  newPaymentMethod.forEach((line, index) => {
    addText(doc, line, myX + 36, myY + index * 10, 40, 'left');
  });

  if (isPaymentOnArrival) {
    addText(
      doc,
      'Estado de Pago',
      myX,
      (myY += margin * paymentType.length),
      75,
      'left',
    );
    addText(doc, ':', myX + 34, myY);
    const newPaymentStatus = doc.splitTextToSize(
      PARCEL_PAYMENT_STATUS[paymentStatus].label,
      40,
    );
    newPaymentStatus.forEach((line, index) => {
      addText(doc, line, myX + 36, myY + index * 10, 40, 'left');
    });
  }

  addText(
    doc,
    'Número Tarjeta',
    myX,
    (myY += margin * newPaymentMethod.length),
    75,
    'left',
  );
  addText(doc, `: -`, myX + 34, myY);
  addText(doc, 'Número Voucher', myX, (myY += margin), 75, 'left');
  addText(doc, `: -`, myX + 34, myY);

  setFont(doc, 'bold');
  doc.setFontSize(14);
  // TODO Commented line, can be used in the future
  // myY += newUserName.length * margin * 2 + (8 - newUserName.length * margin);
  doc.setFontSize(paragraphSize);
  addText(doc, preFooter, myX, (myY += margin * 2), 74, 'justify');
  myY += 15;
  setFont(doc);
  addText(doc, footer, myX, (myY += margin * 1), 74, 'justify');
};

const addTicket = async (
  doc,
  {
    ticketFields: {
      issuingAgency,
      createDate,
      userName,
      voucherType,
      ticketCode,
    },
    shipmentFields: {
      origin,
      sourceAgencyCode,
      destiny,
      destinationAgencyCode,
      destinyDirection,
    },
    senderFields: { senderName, senderDocumentType, senderDocumentNumber },
    consignedFields: {
      consignedName,
      consignedDocumentType,
      consignedDocumentNumber,
    },
    customerFields: {
      customerName,
      customerDocumentType,
      customerDocumentNumber,
    },
    businessFields: { businessName, businessTaxId },
    paymentFields: {
      ticketSalePrice,
      ticketSalePriceString,
      ticketListPrice,
      detractionAmount,
      salePriceWithDetraction,
      discountAmount,
      salePriceWithDiscount,
      salePriceWithDiscountString,
      detractionCode,
      bankAccountNumber,
      detractionRate,
      salePriceWithDetractionString,
      paymentMethod,
      paymentStatus,
    },
    cargoItemList,
  },
) => {
  await addQrCode(doc, ticketCode, 22.5, 0, qrWidth, qrHeight);
  myY += 32;
  printCompanyFields(doc);
  printTicketFields(doc, {
    issuingAgency,
    createDate,
    userName,
    voucherType,
    ticketCode,
  });
  printShipmentField(doc, {
    origin,
    sourceAgencyCode,
    destiny,
    destinationAgencyCode,
    destinyDirection,
  });

  printSenderField(doc, {
    senderName,
    senderDocumentType,
    senderDocumentNumber,
  });
  printConsignedField(doc, {
    consignedName,
    consignedDocumentType,
    consignedDocumentNumber,
  });
  if (customerName) {
    printCustomerField(doc, {
      customerName,
      customerDocumentType,
      customerDocumentNumber,
    });
  } else {
    printBusinessFields(doc, {
      businessName,
      businessTaxId,
    });
  }
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE LA CARGA', myX, (myY += margin * 2), 75, 'left');
  printList(doc, groupItemsByTicketIdentification(cargoItemList));

  setFont(doc, 'bold');
  addText(
    doc,
    `CANT .TOTAL: ${cargoItemList.length}`,
    myX,
    (myY += margin * 2),
    75,
    'left',
  );
  addText(
    doc,
    `PESO TOTAL: ${sumTotalWeight(cargoItemList).toFixed(2)}`,
    myX,
    (myY += margin),
    75,
    'left',
  );

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);

  printPaymentFields(doc, {
    ticketSalePrice,
    ticketSalePriceString,
    ticketListPrice,
    detractionAmount,
    salePriceWithDetraction,
    discountAmount,
    salePriceWithDiscount,
    salePriceWithDiscountString,
    detractionCode,
    bankAccountNumber,
    detractionRate,
    salePriceWithDetractionString,
    paymentMethod,
    paymentStatus,
    // TODO Commented line, can be used in the future
    // userName,
  });

  if (isFirstPrint) {
    verticalOffset = myY;
  }

  doc.addPage('p', 'mm', [80, verticalOffset + 100]);

  myY = 5;

  await addQrCode(doc, ticketCode, 22.5, 0, qrWidth, qrHeight);
  myY += 32;
  printCompanyFields(doc);
  printTicketFields(doc, {
    issuingAgency,
    createDate,
    userName,
    voucherType,
    ticketCode,
  });
  printShipmentField(doc, {
    origin,
    destiny,
    destinyDirection,
  });

  printSenderField(doc, {
    senderName,
    senderDocumentType,
    senderDocumentNumber,
  });
  printConsignedField(doc, {
    consignedName,
    consignedDocumentType,
    consignedDocumentNumber,
  });
  if (customerName) {
    printCustomerField(doc, {
      customerName,
      customerDocumentType,
      customerDocumentNumber,
    });
  } else {
    printBusinessFields(doc, {
      businessName,
      businessTaxId,
    });
  }
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE LA CARGA', myX, (myY += margin * 2), 75, 'left');
  printList(doc, groupItemsByTicketIdentification(cargoItemList));

  setFont(doc, 'bold');
  addText(
    doc,
    `CANT .TOTAL: ${cargoItemList.length}`,
    myX,
    (myY += margin * 2),
    75,
    'left',
  );
  addText(
    doc,
    `PESO TOTAL: ${sumTotalWeight(cargoItemList).toFixed(2)}`,
    myX,
    (myY += margin),
    75,
    'left',
  );

  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);

  printPaymentFields(doc, {
    ticketSalePrice,
    ticketSalePriceString,
    ticketListPrice,
    detractionAmount,
    salePriceWithDetraction,
    discountAmount,
    salePriceWithDiscount,
    salePriceWithDiscountString,
    detractionCode,
    bankAccountNumber,
    detractionRate,
    salePriceWithDetractionString,
    paymentMethod,
    paymentStatus,
  });

  myY = 5;
};

const formatTicket = (ticket) => {
  const formatedTicket = {
    ticketFields: {
      issuingAgency: `${ticket.agency.name}\n${ticket.agency.address}`,
      createDate: tzNormalizeDate({
        date: ticket.createDate,
        format: DATE_TIME_FORMAT,
      }),
      userName: ticket.user.customer.fullName,
      voucherType: ticket.voucherTypeName,
      ticketCode: `${ticket.documentSeries}-${padStart(
        ticket.documentCode,
        7,
      )}`,
    },
    shipmentFields: {
      origin: ticket.sourceCityName,
      sourceAgencyCode: ticket.sourceAgencyCode,
      destiny: ticket.destinationCityName,
      destinationAgencyCode: ticket.destinationAgencyCode,
      destinyDirection: ticket.destinationAddress,
    },
    senderFields: {
      senderName: ticket.senderCustomer.fullName,
      senderDocumentType: ticket.senderCustomer.identificationType.name,
      senderDocumentNumber: ticket.senderCustomer.idDocumentNumber,
    },
    consignedFields: {
      consignedName: ticket.consigneeCustomer.fullName,
      consignedDocumentType: ticket.consigneeCustomer.identificationType.name,
      consignedDocumentNumber: ticket.consigneeCustomer.idDocumentNumber,
    },
    customerFields: {
      customerName: ticket.customer ? ticket.customer.fullName : undefined,
      customerDocumentType: ticket.customer
        ? ticket.customer.identificationType.name
        : undefined,
      customerDocumentNumber: ticket.customer
        ? ticket.customer.idDocumentNumber
        : undefined,
    },
    businessFields: {
      businessName: ticket.business ? ticket.business.name : undefined,
      businessTaxId: ticket.business
        ? ticket.business.businessTaxId
        : undefined,
    },
    paymentFields: {
      ticketSalePrice: ticket.salePrice,
      ticketSalePriceString: ticket.salePriceString,
      ticketListPrice: ticket.listPrice,
      detractionAmount: ticket.detractionAmount,
      salePriceWithDetraction: ticket.salePriceWithDetraction,
      detractionCode: ticket.detractionCode,
      discountAmount: ticket.discountAmount,
      salePriceWithDiscountString: ticket.salePriceWithDiscountString,
      salePriceWithDiscount: ticket.salePriceWithDiscount,
      bankAccountNumber: ticket.bankAccountNumber,
      detractionRate: ticket.detractionRate,
      salePriceWithDetractionString: ticket.salePriceWithDetractionString,
      paymentMethod: ticket.paymentMethodName,
      paymentStatus: ticket.paymentStatus,
    },
    cargoItemList: ticket.cargoItemList,
  };

  return formatedTicket;
};

export default async function printVoucherCargo(ticket) {
  const doc = createPdfDocument('p', 'mm', [80, 1000]); // Create the document with an initial size
  // Call addTicket to update verticalOffset
  const formatedTicket = formatTicket(ticket);
  await addTicket(doc, formatedTicket);

  // Create the resized PDF document
  const adjustedDoc = createPdfDocument('p', 'mm', [80, verticalOffset + 100]);

  // Call addTicket again with the updated document
  await addTicket(adjustedDoc, formatedTicket);

  isFirstPrint = false;

  adjustedDoc.autoPrint();
  window.open(adjustedDoc.output('bloburl'), '_blank');
}
