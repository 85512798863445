import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import FuelConsumptionReportPerBusSearchForm from './FuelConsumptionReportPerBusSearchForm';
import { FUEL_CONSUMPTION_REPORT_PER_BUS_PATH } from '../../../../config/paths';
import {
  clearFuelConsumptionReportPerBus,
  postFuelConsumptionReportPerBus,
} from '../../../../actions';
import FuelConsumptionReportPerBusSearchResult from './FuelConsumptionReportPerBusSearchResult';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';

export class FuelConsumptionReportPerBus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBuses: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatchClearFuelConsumptionReportPerBus();
  }

  onSubmit = (formValues) => {
    this.setState({ selectedBuses: false });

    const newFormValues = {
      fromDate: tzNormalizeDate({
        date: formValues.fromDate,
        format: TIMESTAMP_FORMAT,
      }),
      toDate: tzNormalizeDate({
        date: formValues.toDate,
        format: TIMESTAMP_FORMAT,
      }),
    };

    if (formValues.registeredBusId && formValues.registeredBusId.length) {
      const registeredBusId = formValues.registeredBusId.map(
        ({ label }) => +label,
      );
      newFormValues.registeredBusId = registeredBusId;
      this.setState({ selectedBuses: true });
    }

    this.props.dispatchPostFuelConsumptionReportPerBus(newFormValues);
  };

  render() {
    const { breadcrumbs } = this.props;

    const subtitle = (
      <span>
        Busca por <strong>Bus</strong> y/o <strong>Fecha</strong>.
      </span>
    );
    const content = (
      <Fragment>
        <FuelConsumptionReportPerBusSearchForm onSubmit={this.onSubmit} />
        <FuelConsumptionReportPerBusSearchResult
          selectedBuses={this.state.selectedBuses}
        />
      </Fragment>
    );

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Reporte de Consumo de Combustible por Bus"
        subtitle={subtitle}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reporte de Consumo de Combustible por Bus',
      href: FUEL_CONSUMPTION_REPORT_PER_BUS_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostFuelConsumptionReportPerBus: postFuelConsumptionReportPerBus,
  dispatchClearFuelConsumptionReportPerBus: clearFuelConsumptionReportPerBus,
};

FuelConsumptionReportPerBus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostFuelConsumptionReportPerBus: PropTypes.func.isRequired,
  dispatchClearFuelConsumptionReportPerBus: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuelConsumptionReportPerBus);
