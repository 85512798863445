import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal as ReactModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const Modal = ({
  show,
  title,
  body,
  onClickConfirm,
  onClickCancel,
  onClickClose,
  confirmButtonText,
  cancelButtonText,
  size,
  disableConfirmButton,
  disableCancelButton,
  confirmButtonColor,
  cancelButtonColor,
}) => {
  const handleKeyDown = (event) => {
    if (event.keyCode === 27 && onClickClose) onClickClose();
  };

  // TODO remove hack when upgrade to reactstrap latest
  useEffect(() => {
    if (show) window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [show]);

  let confirmButton = null;

  if (onClickConfirm) {
    confirmButton = (
      <Button
        color={confirmButtonColor}
        disabled={disableConfirmButton}
        onClick={onClickConfirm}
      >
        {confirmButtonText}
      </Button>
    );
  }

  let cancelButton = null;

  if (onClickCancel) {
    cancelButton = (
      <Button
        color={cancelButtonColor}
        disabled={disableCancelButton}
        onClick={onClickCancel}
      >
        {cancelButtonText}
      </Button>
    );
  }

  let footer = null;

  if (onClickConfirm) {
    footer = (
      <ModalFooter>
        {cancelButton} {confirmButton}
      </ModalFooter>
    );
  }

  let buttonClose = null;

  if (onClickClose) {
    buttonClose = (
      <Button className="close" onClick={onClickClose}>
        &times;
      </Button>
    );
  }

  return (
    <ReactModal isOpen={show} size={size}>
      <ModalHeader toggle={onClickClose} close={buttonClose}>
        {title}
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      {footer}
    </ReactModal>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClickConfirm: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickClose: PropTypes.func,
  confirmButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmButtonColor: PropTypes.string,
  cancelButtonText: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  size: PropTypes.string,
  disableConfirmButton: PropTypes.bool,
  disableCancelButton: PropTypes.bool,
};

Modal.defaultProps = {
  show: false,
  body: null,
  onClickConfirm: null,
  onClickCancel: null,
  onClickClose: null,
  confirmButtonText: 'Continuar',
  confirmButtonColor: 'primary',
  cancelButtonText: 'Cancelar',
  cancelButtonColor: 'secondary',
  size: 'lg',
  disableConfirmButton: false,
  disableCancelButton: false,
};

export default Modal;
