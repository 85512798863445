import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  generateLiquidationSessionByAgencyEndpoint,
  LIQUIDATION_SESSION_DETAIL_ENDPOINT,
  LIQUIDATION_SESSION_ENDPOINT_V2,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PATCH_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_LIQUIDATION_SESSIONS,
  GET_LIQUIDATION_SESSIONS,
  CLEAR_LIQUIDATION_SESSIONS,
  GET_LIQUIDATION_SESSION,
  CLEAR_LIQUIDATION_SESSION,
  FLAG_LIQUIDATION_SESSION_ACTIVITY,
} from '../types/sales';
import { LIQUIDATION_SESSION_PATH } from '../../config/paths';

const flagGettingLiquidationSessions = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_LIQUIDATION_SESSIONS,
    payload: flag,
  });

const flagLiquidationSessionActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_LIQUIDATION_SESSION_ACTIVITY,
    payload: flag,
  });

const getLiquidationSessions = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingLiquidationSessions(true));

    const query = tableFilters;

    const url = `${LIQUIDATION_SESSION_ENDPOINT_V2}?${QueryString.stringify(
      query,
    )}`;

    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

    await isErrorResponse(response);

    const liquidationSessions = await response.json();

    dispatch({ type: GET_LIQUIDATION_SESSIONS, payload: liquidationSessions });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingLiquidationSessions(false));
  }
};

const clearLiquidationSessions = () => (dispatch) =>
  dispatch({
    type: CLEAR_LIQUIDATION_SESSIONS,
  });

const getLiquidationSession =
  async ({ liquidationSessionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagLiquidationSessionActivity(true));

      const url = `${LIQUIDATION_SESSION_DETAIL_ENDPOINT}/${liquidationSessionId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const liquidationSession = await response.json();

      dispatch({ type: GET_LIQUIDATION_SESSION, payload: liquidationSession });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagLiquidationSessionActivity(false));
    }
  };

const clearLiquidationSession = () => (dispatch) =>
  dispatch({
    type: CLEAR_LIQUIDATION_SESSION,
  });

const getLiquidationSessionByAgency =
  async ({ agencyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagLiquidationSessionActivity(true));

      const url = generateLiquidationSessionByAgencyEndpoint(agencyId);

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response);

      const liquidationSession = await response.json();

      dispatch({ type: GET_LIQUIDATION_SESSION, payload: liquidationSession });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagLiquidationSessionActivity(false));
    }
  };

const postLiquidationSession =
  async ({
    agencyId,
    invoiceNumber,
    dailyLiquidationDate,
    liquidationDifferenceReasonId,
    liquidationDifferenceReasonDescription,
    cashOnHand,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagLiquidationSessionActivity(true));

      const payload = {
        agencyId,
        invoiceNumber,
        dailyLiquidationDate,
        liquidationDifferenceReasonId,
        liquidationDifferenceReasonDescription,
        cashOnHand,
      };

      const url = LIQUIDATION_SESSION_ENDPOINT_V2;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      const liquidation = await response.json();

      dispatch(push(`${LIQUIDATION_SESSION_PATH}/${liquidation.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagLiquidationSessionActivity(false));
    }
  };

const patchLiquidationSession =
  async (liquidationSessionId, { dailyLiquidationSessionNoteList, settled }) =>
  async (dispatch) => {
    try {
      dispatch(flagLiquidationSessionActivity(true));

      const payload = {
        dailyLiquidationSessionNoteList,
        settled,
        id: liquidationSessionId,
      };

      const url = `${LIQUIDATION_SESSION_ENDPOINT_V2}/${liquidationSessionId}`;

      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      const liquidationSession = await response.json();

      dispatch(push(`${LIQUIDATION_SESSION_PATH}/${liquidationSession.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagLiquidationSessionActivity(false));
    }
  };

export {
  flagGettingLiquidationSessions,
  getLiquidationSessions,
  clearLiquidationSessions,
  getLiquidationSession,
  clearLiquidationSession,
  getLiquidationSessionByAgency,
  postLiquidationSession,
  patchLiquidationSession,
};
