export const FLAG_GETTING_PARCELS_TO_PICKUP = 'FLAG_GETTING_PARCELS_TO_PICKUP';
export const GET_PARCELS_TO_PICKUP = 'GET_PARCELS_TO_PICKUP';
export const CLEAR_PARCELS_TO_PICKUP = 'CLEAR_PARCELS_TO_PICKUP';
export const FLAG_GETTING_DETAIL_FROM_PARCEL =
  'FLAG_GETTING_DETAIL_FROM_PARCEL';
export const DELETE_PARCELS_TO_PICKUP = 'DELETE_PARCELS_TO_PICKUP';
export const GET_DETAIL_FROM_PARCEL = 'GET_DETAIL_FROM_PARCEL';
export const CLEAR_ALL_DETAILS_FROM_PARCELS_TO_PICKUP =
  'CLEAR_ALL_DETAILS_FROM_PARCELS_TO_PICKUP';
export const CLEAR_DETAIL_FROM_PARCEL = 'CLEAR_DETAIL_FROM_PARCEL';
export const ADD_SHAPE_DETAIL_FROM_PARCEL = 'ADD_SHAPE_DETAIL_FROM_PARCEL';
