import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_COST_CENTERS,
  GET_COST_CENTERS,
  CLEAR_COST_CENTERS,
  FLAG_COST_CENTER_ACTIVITY,
  GET_COST_CENTER,
  CLEAR_COST_CENTER,
} from '../types';
import { COST_CENTER_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { COST_CENTER_PATH } from '../../config/paths';

const flagGettingCostCenters = flag => dispatch =>
  dispatch({
    type: FLAG_GETTING_COST_CENTERS,
    payload: flag,
  });

const getCostCenters = async tableFilters => async dispatch => {
  try {
    dispatch(flagGettingCostCenters(true));
    const query = tableFilters;
    const url = `${COST_CENTER_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const costCenters = await response.json();
    dispatch({
      type: GET_COST_CENTERS,
      payload: costCenters,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagGettingCostCenters(false));
  }
};

const clearCostCenters = () => dispatch =>
  dispatch({
    type: CLEAR_COST_CENTERS,
  });

const flagCostCenterActivity = flag => dispatch =>
  dispatch({
    type: FLAG_COST_CENTER_ACTIVITY,
    payload: flag,
  });

const getCostCenter = async ({ id }) => async dispatch => {
  try {
    dispatch(flagCostCenterActivity(true));
    const url = `${COST_CENTER_ENDPOINT}/${id}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const costCenter = await response.json();
    dispatch({
      type: GET_COST_CENTER,
      payload: costCenter,
    });
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagCostCenterActivity(false));
  }
};

const clearCostCenter = () => dispatch =>
  dispatch({
    type: CLEAR_COST_CENTER,
  });

const postCostCenter = async ({
  description,
  costCenterCode,
}) => async dispatch => {
  try {
    dispatch(flagCostCenterActivity(true));
    const payload = { description, costCenterCode };
    const url = COST_CENTER_ENDPOINT;
    const response = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });
    await isErrorResponse(response);
    const costCenter = await response.json();
    dispatch(push(`${COST_CENTER_PATH}/${costCenter.id}`));
  } catch (error) {
    toastr.error('Error',error.message);
  } finally {
    dispatch(flagCostCenterActivity(false));
  }
};

export {
  flagGettingCostCenters,
  getCostCenters,
  clearCostCenters,
  getCostCenter,
  clearCostCenter,
  postCostCenter,
};
