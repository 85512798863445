import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { mechanicHierarchyInformationPropTypes } from '../proptypes/MechanicPropTypes';

const MechanicHierarchyInformationResource = ({
  management,
  department,
  area,
  section,
  speciality,
}) => (
  <>
    <ResourceProperty label="Gerencia:">{management.name}</ResourceProperty>
    <ResourceProperty label="Departamento:">
      {department ? department.name : '-'}
    </ResourceProperty>
    <ResourceProperty label="Área:">{area ? area.name : '-'}</ResourceProperty>
    <ResourceProperty label="Sección:">
      {section ? section.name : '-'}
    </ResourceProperty>
    <ResourceProperty label="Especialidad:">
      {speciality ? speciality.name : '-'}
    </ResourceProperty>
  </>
);

MechanicHierarchyInformationResource.propTypes =
  mechanicHierarchyInformationPropTypes;

export default MechanicHierarchyInformationResource;
