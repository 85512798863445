// todo: replace with qs
// https://www.npmjs.com/package/qs
import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { handleResponseError } from '../../utils/error-handlers';
import {
  SHOW_ERROR_POSTING_ACCOUNTING_REPORT,
  POST_ACCOUNTING_REPORT,
  FLAG_POSTING_ACCOUNTING_REPORT,
  CLEAR_ERROR_POSTING_ACCOUNTING_REPORT,
  CLEAR_ERROR_GETTING_ACCOUNTING_REPORTS,
  FLAG_GETTING_ACCOUNTING_REPORTS,
  SHOW_ERROR_GETTING_ACCOUNTING_REPORTS,
  GET_ACCOUNTING_REPORTS,
  CLEAR_ACCOUNTING_REPORTS,
  FLAG_GETTING_ACCOUNTING_REPORT,
  SHOW_ERROR_GETTING_ACCOUNTING_REPORT,
  CLEAR_ERROR_GETTING_ACCOUNTING_REPORT,
  GET_ACCOUNTING_REPORT,
  DOWNLOAD_ACCOUNTING_REPORT,
  CLEAR_ACCOUNTING_REPORT,
} from '../types';
import { getReportsServerEndpoint } from './Report';
import { ACCOUNTING_REPORT_ENDPOINT } from '../../config/endpoints';
import { ACCOUNTING_REPORTS_PATH } from '../../config/paths';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from '../../config/constants';
import { validateReportDateRange } from '../../utils/validators';

const flagGettingAccountingReports = flag => dispatch => dispatch({
  type: FLAG_GETTING_ACCOUNTING_REPORTS,
  payload: flag,
});

const showErrorGettingAccountingReports = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_ACCOUNTING_REPORTS,
  payload: error,
});

const clearErrorGettingAccountingReports = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_ACCOUNTING_REPORTS,
});

const getAccountingReports = async ({
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
} = {}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingAccountingReports());
  dispatch(flagGettingAccountingReports(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    const url = reportsServerEndpoint + ACCOUNTING_REPORT_ENDPOINT;
    const query = {
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingAccountingReports(false));
      return dispatch(showErrorGettingAccountingReports(serverError));
    }
    const reports = await response.json();
    dispatch({
      type: GET_ACCOUNTING_REPORTS,
      payload: reports,
    });
    return dispatch(flagGettingAccountingReports(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingAccountingReports(false));
    const errMsg = 'Error obteniendo los reportes de Contabilidad.';
    return dispatch(showErrorGettingAccountingReports(errMsg));
  }
};

const clearAccountingReports = () => dispatch => dispatch({
  type: CLEAR_ACCOUNTING_REPORTS,
});

const flagPostingAccountingReport = flag => dispatch => dispatch({
  type: FLAG_POSTING_ACCOUNTING_REPORT,
  payload: flag,
});

const showErrorPostingAccountingReport = error => dispatch => dispatch({
  type: SHOW_ERROR_POSTING_ACCOUNTING_REPORT,
  payload: error,
});

const clearErrorPostingAccountingReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_POSTING_ACCOUNTING_REPORT,
});

const postAccountingReport = async ({
  fromDate,
  toDate,
  force,
}) => async (dispatch, getState) => {
  dispatch(clearErrorPostingAccountingReport());

  // validate before raising the flag and outside the
  // try/catch so that the error is caught by redux form
  validateReportDateRange(fromDate, toDate);

  dispatch(flagPostingAccountingReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    const query = {
      force,
    };
    const url = reportsServerEndpoint + ACCOUNTING_REPORT_ENDPOINT;
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          fromDate,
          toDate,
        }),
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      return dispatch(showErrorPostingAccountingReport(serverError));
    }
    const report = await response.json();
    dispatch({
      type: POST_ACCOUNTING_REPORT,
      payload: report,
    });
    dispatch(flagPostingAccountingReport(false));
    return dispatch(push(`${ACCOUNTING_REPORTS_PATH}/${report.id}`));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagPostingAccountingReport(false));
    const errMsg = 'Error en la creación del reporte de contabilidad';
    return dispatch(showErrorPostingAccountingReport(errMsg));
  }
};

const flagGettingAccountingReport = flag => dispatch => dispatch({
  type: FLAG_GETTING_ACCOUNTING_REPORT,
  payload: flag,
});

const showErrorGettingAccountingReport = error => dispatch => dispatch({
  type: SHOW_ERROR_GETTING_ACCOUNTING_REPORT,
  payload: error,
});

const clearErrorGettingAccountingReport = () => dispatch => dispatch({
  type: CLEAR_ERROR_GETTING_ACCOUNTING_REPORT,
});

const getAccountingReport = async ({
  id,
  reportFormat = 'json',
  download = false,
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = DEFAULT_SORT,
}) => async (dispatch, getState) => {
  dispatch(clearErrorGettingAccountingReport());
  dispatch(flagGettingAccountingReport(true));
  try {
    const reportsServerEndpoint = await getReportsServerEndpoint(dispatch, getState);
    // make request
    const url = `${reportsServerEndpoint + ACCOUNTING_REPORT_ENDPOINT}/${id}`;
    const query = {
      reportFormat,
      page,
      size,
      sort,
    };
    const response = await fetch(
      `${url}?${QueryString.stringify(query)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const serverError = await handleResponseError(response);
    if (serverError) {
      dispatch(flagGettingAccountingReport(false));
      return dispatch(showErrorGettingAccountingReport(serverError));
    }
    // parse json or text depending on format
    const text = reportFormat === 'csv';
    const report = text ? await response.text() : await response.json();
    if (download) {
      dispatch({
        type: DOWNLOAD_ACCOUNTING_REPORT,
        payload: report,
      });
    } else {
      dispatch({
        type: GET_ACCOUNTING_REPORT,
        payload: report,
      });
    }
    return dispatch(flagGettingAccountingReport(false));
  } catch (err) {
    console.error(err);
    // lower flag
    dispatch(flagGettingAccountingReport(false));
    const errMsg = `Error obteniendo el reporte de Contabilidad #${id}.`;
    return dispatch(showErrorGettingAccountingReport(errMsg));
  }
};

const clearAccountingReport = () => dispatch => dispatch({
  type: CLEAR_ACCOUNTING_REPORT,
});


export {
  flagGettingAccountingReports,
  showErrorGettingAccountingReports,
  clearErrorGettingAccountingReports,
  getAccountingReports,
  clearAccountingReports,
  flagPostingAccountingReport,
  showErrorPostingAccountingReport,
  clearErrorPostingAccountingReport,
  postAccountingReport,
  flagGettingAccountingReport,
  showErrorGettingAccountingReport,
  clearErrorGettingAccountingReport,
  getAccountingReport,
  clearAccountingReport,
};
