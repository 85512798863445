import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { ITINERARY_SEARCH_OMISSION_PATH } from '../../../../config/paths';
import {
  getItinerarySearchOmission,
  clearItinerarySearchOmission,
} from '../../../../actions/itinerary';
import Loader from '../../../common/Loader';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import EditItinerarySearchOmissionButton from './EditItinerarySearchOmissionButton';
import DeleteItinerarySearchOmissionButton from './DeleteItinerarySearchOmissionButton';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

class ItinerarySearchOmission extends Component {
  componentDidMount() {
    const {
      dispatchGetItinerarySearchOmission,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchGetItinerarySearchOmission({ id });
  }

  componentWillUnmount() {
    const { dispatchClearItinerarySearchOmission } = this.props;
    dispatchClearItinerarySearchOmission();
  }

  render() {
    const {
      breadcrumbs,
      itinerarySearchOmission,
      loading,
      match: {
        params: { id },
      },
    } = this.props;

    const itinerarySearchOmissionId = parseInt(id, 10);

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (itinerarySearchOmission.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_SEARCH_OMISSION_PATH} />;
    } else {
      content = (
        <Fragment>
          <ResourceProperty label="Ubicación Origen Id: ">
            {itinerarySearchOmission.get('sourceLocationId')}
          </ResourceProperty>
          <ResourceProperty label="Ubicación Origen:">
            {itinerarySearchOmission.get('sourceLocationName')}
          </ResourceProperty>
          <ResourceProperty label="Ubicación Destino Id: ">
            {itinerarySearchOmission.get('destinationLocationId')}
          </ResourceProperty>
          <ResourceProperty label="Ubicación Destino:">
            {itinerarySearchOmission.get('destinationLocationName')}
          </ResourceProperty>
        </Fragment>
      );
      toolbar = (
        <ButtonToolbar className="pull-right">
          <div>
            <EditItinerarySearchOmissionButton
              itinerarySearchOmissionId={itinerarySearchOmissionId}
            />
            <DeleteItinerarySearchOmissionButton
              itinerarySearchOmissionId={itinerarySearchOmissionId}
            />
          </div>
        </ButtonToolbar>
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Parada Oculta"
        content={content}
      />
    );
  }
}

ItinerarySearchOmission.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItinerarySearchOmission: PropTypes.func.isRequired,
  dispatchClearItinerarySearchOmission: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  itinerarySearchOmission: PropTypes.instanceOf(Immutable.Map),
};

ItinerarySearchOmission.defaultProps = {
  loading: false,
  itinerarySearchOmission: null,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Parada Oculta',
      href: ITINERARY_SEARCH_OMISSION_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  itinerarySearchOmission: ItineraryUnit.ItinerarySearchOmission.getIn([
    'current',
    'content',
  ]),
  loading: !ItineraryUnit.ItinerarySearchOmission.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItinerarySearchOmission: getItinerarySearchOmission,
  dispatchClearItinerarySearchOmission: clearItinerarySearchOmission,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerarySearchOmission);
