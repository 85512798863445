export const FLAG_GETTING_EXPECTED_FUEL_CONSUMPTIONS =
  'FLAG_GETTING_EXPECTED_FUEL_CONSUMPTIONS';
export const GET_EXPECTED_FUEL_CONSUMPTIONS = 'GET_EXPECTED_FUEL_CONSUMPTIONS';
export const CLEAR_EXPECTED_FUEL_CONSUMPTIONS =
  'CLEAR_EXPECTED_FUEL_CONSUMPTIONS';
export const FLAG_EXPECTED_FUEL_CONSUMPTION_ACTIVITY =
  'FLAG_EXPECTED_FUEL_CONSUMPTION_ACTIVITY';
export const GET_EXPECTED_FUEL_CONSUMPTION = 'GET_EXPECTED_FUEL_CONSUMPTION';
export const CLEAR_EXPECTED_FUEL_CONSUMPTION =
  'CLEAR_EXPECTED_FUEL_CONSUMPTION';
export const POST_EXPECTED_FUEL_CONSUMPTION = 'POST_EXPECTED_FUEL_CONSUMPTION';
export const PUT_EXPECTED_FUEL_CONSUMPTION = 'PUT_EXPECTED_FUEL_CONSUMPTION';
