import React from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import './ParcelCommentForm.css';

export const ParcelCommentForm = ({ comments, handleSubmit }) => (
  <div>
    {comments.map((comment) => (
      <div key={comment.createDate} className="comment-box">
        <div className="header">
          <div className="avatar">{comment.userFullName.charAt(0)}</div>
          <div>
            <strong>{comment.userFullName}</strong>
            <div>
              {tzNormalizeDate({
                date: comment.createDate,
                format: DATE_TIME_FORMAT,
              })}
            </div>
          </div>
        </div>

        <p className="comment-text">{comment.description}</p>
      </div>
    ))}

    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <FormItem label="Comentario" required>
          <Field
            name="parcelComment"
            component={TextInput}
            type="textarea"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>

      <div className="mt-5">
        <FormFooter
          saveButtonText="Publicar"
          saveButtonIcon="fa fa-commenting"
        />
      </div>
    </Form>
  </div>
);

ParcelCommentForm.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      userFullName: PropTypes.string.isRequired,
      createDate: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'ParcelCommentForm',
})(ParcelCommentForm);

export default connect(null, null)(formComponent);
