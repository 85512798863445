import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteMaterial } from '../../../../actions/mechanical-maintenance/Material';

const MaterialDeleteButton = ({
  dispatchDeleteMaterial,
  materialId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () => dispatchDeleteMaterial({ materialId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

MaterialDeleteButton.propTypes = {
  dispatchDeleteMaterial: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  materialId: PropTypes.number.isRequired,
};

MaterialDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteMaterial: deleteMaterial,
};

export default connect(null, mapDispatchToProps)(MaterialDeleteButton);
