import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  postTicketReport,
  clearErrorPostingTicketReport,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import TicketReportForm from './TicketReportForm';
import {
  NEW_TICKET_REPORT_PATH,
  TICKET_REPORTS_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';

class NewTicketReport extends Component {
  componentWillUnmount() {
    const { dispatchClearErrorPostingTicketReport } = this.props;
    dispatchClearErrorPostingTicketReport();
  }

  handleSubmit = (formValues) => {
    const { dispatchPostTicketReport } = this.props;
    // send agenciesIds only if at least one agency is selected
    let agencyIds;
    if (formValues.agency && formValues.agency.length) {
      agencyIds = formValues.agency.map((agency) => agency.value);
    }
    const payload = {
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
      force: formValues.force,
      agencyIds,
    };
    dispatchPostTicketReport(payload);
  };

  render() {
    const { loading } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <Row>
          <Col>
            <h1 id="title">Nuevo Reporte de Boletos</h1>
            <p id="text">Crear un nuevo reporte de boletos.</p>
          </Col>
        </Row>
        <TicketReportForm
          onSubmit={this.handleSubmit}
          initialValues={{
            fromDate: tzNormalizeDate(),
            toDate: tzNormalizeDate(),
            force: false,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Boletos',
      href: TICKET_REPORTS_PATH,
    },
    {
      text: 'Crear',
      href: NEW_TICKET_REPORT_PATH,
    },
  ],
  loading: ReportUnit.TicketReport.getIn(['form', 'loading']),
});

const mapDispatchToProps = {
  dispatchPostTicketReport: postTicketReport,
  dispatchClearErrorPostingTicketReport: clearErrorPostingTicketReport,
};

NewTicketReport.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostTicketReport: PropTypes.func.isRequired,
  dispatchClearErrorPostingTicketReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTicketReport);
