import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import {
  getOccupancyReports,
  clearOccupancyReports,
  clearErrorGettingOccupancyReports,
} from '../../../../actions';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import Table from '../../../common/Table';
import TableToolbar from '../../../common/TableToolbar';
import {
  NEW_OCCUPANCY_REPORT_PATH,
  OCCUPANCY_REPORTS_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Alert from '../../../common/informative/Alert';
import { REPORT_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

class OccupancyReports extends Component {
  constructor(props) {
    super(props);
    this.columns = REPORT_COLUMNS;
  }

  componentWillUnmount() {
    this.props.clearOccupancyReports();
  }

  renderContent() {
    const { reports, loading, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    const { columns } = this;

    if (reports) {
      data = reports.get('content');
      pages = reports.get('totalPages');
      defaultPageSize = reports.get('size');
    }

    return (
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={this.props.getOccupancyReports}
        modelPath={OCCUPANCY_REPORTS_PATH}
        loading={loading}
        navigateToModelOnRowClick
      />
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <TableToolbar buttonPath={NEW_OCCUPANCY_REPORT_PATH} />
        <h1>Reportes de Ocupabilidad</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ocupabilidad',
      href: OCCUPANCY_REPORTS_PATH,
    },
  ],
  reports: ReportUnit.OccupancyReport.get('all').get('content'),
  loading: ReportUnit.OccupancyReport.get('all').get('loading'),
  error: ReportUnit.OccupancyReport.get('all').get('error'),
});

const mapDispatchToProps = {
  getOccupancyReports,
  clearOccupancyReports,
  clearErrorGettingOccupancyReports,
};

OccupancyReports.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  getOccupancyReports: PropTypes.func.isRequired,
  clearOccupancyReports: PropTypes.func.isRequired,
  reports: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

OccupancyReports.defaultProps = {
  reports: null,
  loading: false,
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(OccupancyReports);
