import React from 'react';
import PropTypes from 'prop-types';
import LinkToModuleResource from './resource/LinkToModuleResource';
import Badge from './Badge';

const BadgeList = ({ textArray, pill, cell }) => {
  if (!textArray) return null;
  return textArray.map(({ text, href, color }, index) => {
    let newText = text;
    if (href) newText = <LinkToModuleResource href={href} text={text} />;
    return (
      <Badge
        key={`badge${+index}`}
        color={color}
        className="mr-1"
        pill={pill}
        cell={cell}
        text={newText}
      />
    );
  });
};

BadgeList.propTypes = {
  textArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
      ]).isRequired,
      href: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  pill: PropTypes.bool,
  cell: PropTypes.bool,
};

BadgeList.defaultProps = {
  textArray: [],
  pill: false,
  cell: false,
};

export default BadgeList;
