import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';

const IsOpen = ({ value }) => {
  if (value) return <Badge color="success" text="Cerrado" />;

  return <Badge color="danger" text="Abierto" />;
};

IsOpen.propTypes = {
  value: PropTypes.bool,
};

IsOpen.defaultProps = {
  value: false,
};

export default IsOpen;
