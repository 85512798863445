import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import TableToolbar from '../../../common/TableToolbar';
import {
  NEW_EXPENSE_REPORT_PATH,
  EXPENSE_REPORTS_PATH,
} from '../../../../config/paths';
import Table from '../../../common/Table';
import {
  clearExpenseReports,
  getExpenseReports,
  clearErrorGettingExpenseReports,
} from '../../../../actions';
import Alert from '../../../common/informative/Alert';
import { REPORT_COLUMNS } from '../../../../config/columns';

class ExpenseReports extends Component {
  constructor(props) {
    super(props);
    this.columns = REPORT_COLUMNS;
  }

  componentWillUnmount() {
    this.props.clearErrorGettingExpenseReports();
    this.props.clearExpenseReports();
  }

  renderContent() {
    const { reports, loading, error } = this.props;

    // check if there is an error
    if (error) {
      return <Alert message={error} />;
    }

    let data = [];
    let pages = null;
    let defaultPageSize = DEFAULT_PAGE_SIZE;

    const { columns } = this;

    if (reports) {
      data = reports.get('content');
      pages = reports.get('totalPages');
      defaultPageSize = reports.get('size');
    }

    return (
      <Table
        columns={columns}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        fetchData={this.props.getExpenseReports}
        modelPath={EXPENSE_REPORTS_PATH}
        loading={loading}
        navigateToModelOnRowClick
      />
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
        <TableToolbar buttonPath={NEW_EXPENSE_REPORT_PATH} />
        <h1>Reporte Egresos</h1>
        <p>Egresos</p>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = ({ ReportUnit }) => ({
  breadcrumbs: [
    ...ReportUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Egreso',
      href: EXPENSE_REPORTS_PATH,
    },
  ],
  reports: ReportUnit.ExpenseReport.get('all').get('content'),
  loading: ReportUnit.ExpenseReport.get('all').get('loading'),
  error: ReportUnit.ExpenseReport.get('all').get('error'),
});

const mapDispatchToProps = {
  getExpenseReports,
  clearExpenseReports,
  clearErrorGettingExpenseReports,
};

ExpenseReports.propTypes = {
  // todo: fix once issue http://bit.ly/2EPChzJ is fixed
  // eslint-disable-next-line react/require-default-props,react/no-typos
  breadcrumbs: Breadcrumbs.propTypes.breadcrumbs,
  getExpenseReports: PropTypes.func.isRequired,
  clearExpenseReports: PropTypes.func.isRequired,
  clearErrorGettingExpenseReports: PropTypes.func.isRequired,
  reports: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

ExpenseReports.defaultProps = {
  reports: null,
  loading: false,
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReports);
