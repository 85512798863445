import { padStart } from 'lodash';
import {
  addImage,
  addText,
  createPdfDocument,
  addQrCode,
  paragraphSize,
  setFont,
  textSpacing,
  titleSize,
  x,
  y,
} from '../pdf';
import LogoCiva from '../../resources/images/logo-civa.png';
import { DATE_FORMAT, TIME_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';

const myX = x;
const initialY = y;
let myY = initialY;
const margin = 3;
const pageWidth = 210;
const newTitleSize = titleSize - 2;
const qrWidth = 35;
const qrHeight = 35;
let page = 1;

const printDocumentHeader = (doc, itinerary) => {
  addImage(doc, LogoCiva, myX, 3, 35, 10);

  setFont(doc, 'bold', 'arial-bold');

  doc.setFontSize(newTitleSize);
  addText(
    doc,
    'GUÍA DE REMISIÓN TRANSPORTISTA',
    pageWidth / 2,
    (myY += 5),
    pageWidth,
  );

  doc.setFontSize(paragraphSize);

  doc.rect(160, myY - 5, 45, 15);
  addText(doc, 'TURISMO CIVA S.A.C', 195, myY, 80, 'right');
  addText(doc, 'RUC:', 170, (myY += 5), 30);
  addText(doc, '20102427891', 188, myY, 30);

  doc.rect(x, (myY += 10), pageWidth - 10, 33);

  addText(doc, 'FECHA DE EMISIÓN:', 37.8, (myY += 8), 40, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: itinerary.departureTime, format: DATE_FORMAT }),
    50,
    myY,
    30,
  );
  addText(doc, 'NÚMERO DE REGISTRO MTC:', 173, myY, 80, 'right');
  addText(doc, '1517538CNG', 190, myY, 30);

  addText(doc, 'NÚMERO BUS:', 173, (myY += textSpacing), 35, 'right');
  addText(
    doc,
    ` ${itinerary.trip.assignedRegisteredBus.companyBusId}`,
    190,
    myY,
    30,
  );

  addText(doc, 'ORIGEN:', 21.5, myY, 30, 'right');
  addText(doc, `${itinerary.route.sourceLocation.name}`, 45, myY, 80);

  addText(doc, 'PLACA:', 172.4, (myY += textSpacing), 30, 'right');
  addText(
    doc,
    `${itinerary.trip.assignedRegisteredBus.licensePlate}`,
    190,
    myY,
    30,
  );

  addText(doc, 'DESTINO:', 23, myY, 30, 'right');
  addText(doc, `${itinerary.route.destinationLocation.name}`, 45, myY, 80);

  addText(doc, 'FECHA SALIDA:', 31.2, (myY += textSpacing), 30, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: itinerary.departureTime, format: DATE_FORMAT }),
    45,
    myY,
    30,
  );
  addText(doc, 'HORA SALIDA:', 30, (myY += textSpacing), 30, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: itinerary.departureTime, format: TIME_FORMAT }),
    35,
    myY,
    30,
  );
  addText(doc, 'PAGINA:', 180.4, myY, 30, 'right');
  addText(doc, `${page}`, 190, myY, 30);
};

const printQr = async (doc, itinerary, CarrierRemissionGuideList) => {
  const initialX = 22.5;
  let currentX = initialX;
  let currentY = myY + margin * 4;
  const qrSpacing = 10;
  const maxQrPerRow = Math.floor(
    (pageWidth - initialX) / (qrWidth + qrSpacing),
  );
  const pageHeight = 297;
  const marginBottom = 20;

  let qrCount = 0;

  const tasks = CarrierRemissionGuideList.map((item) => async () => {
    if (qrCount >= maxQrPerRow) {
      currentX = initialX;
      currentY += qrHeight + qrSpacing;
      qrCount = 0;
    }

    if (currentY + qrHeight + marginBottom > pageHeight) {
      doc.addPage();
      myY = initialY;
      page += 1;
      printDocumentHeader(doc, itinerary);
      currentX = initialX;
      currentY = myY + margin * 4;
      qrCount = 0;
    }

    await addQrCode(doc, item.pdfUrl, currentX, currentY, qrWidth, qrHeight);
    setFont(doc, 'bold');
    doc.setFontSize(12);
    addText(
      doc,
      ` ${item.refDocumentSeries}-${padStart(item.refDocumentCode, 7)}`,
      currentX + qrWidth / 2,
      currentY + qrHeight + 3,
      qrWidth,
      'center',
    );
    currentX += qrWidth + qrSpacing;
    qrCount += 1;
  });

  await tasks.reduce(
    (promiseChain, currentTask) => promiseChain.then(() => currentTask()),
    Promise.resolve(),
  );

  myY = currentY;
  page = 1;
};

const printCarrierRemissionGuideOld = async (
  itinerary,
  carrierRemissionGuideList,
) => {
  const doc = createPdfDocument();

  printDocumentHeader(doc, itinerary);

  await printQr(doc, itinerary, carrierRemissionGuideList);

  window.open(doc.output('bloburl'), '_blank');

  myY = 5;
};

export default printCarrierRemissionGuideOld;
