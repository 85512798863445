import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import DriverEditButton from './DriverEditButton';
import DriverDeleteButton from './DriverDeleteButton';

const DriverToolbar = ({ driverId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <DriverEditButton driverId={driverId} />{' '}
      <DriverDeleteButton driverId={driverId} />
    </div>
  </ButtonToolbar>
);

DriverToolbar.propTypes = {
  driverId: PropTypes.number.isRequired,
};

export default DriverToolbar;
