import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

import { SECURITY_PROFILE_ENDPOINT } from '../../config/endpoints';
import {
  CLEAR_SECURITY_PROFILES,
  FLAG_GETTING_SECURITY_PROFILES,
  GET_SECURITY_PROFILES,
} from '../types/security/SecurityProfile';

const flagGettingSecurityProfiles = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_SECURITY_PROFILES,
    payload: flag,
  });

const getSecurityProfiles = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingSecurityProfiles(true));
    const query = tableFilters;
    const url = `${SECURITY_PROFILE_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_SECURITY_PROFILES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingSecurityProfiles(false));
  }
};

const clearSecurityProfiles = () => (dispatch) =>
  dispatch({
    type: CLEAR_SECURITY_PROFILES,
  });

export { getSecurityProfiles, clearSecurityProfiles };
