import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../layout/Card';
import ModuleHome from '../../layout/ModuleHome';
import { breadcrumbsPropTypes } from '../../common/resource/proptypes/CommonPropTypes';

export const CargoHome = ({ cards, breadcrumbs }) => (
  <ModuleHome
    title="Cargo"
    text="Módulo de cargo"
    cards={cards}
    breadcrumbs={breadcrumbs}
  />
);

const mapStateToProps = ({ CargoUnit }) => ({
  cards: CargoUnit.UnitHome.get('cards'),
  breadcrumbs: CargoUnit.UnitHome.get('breadcrumbs'),
});

CargoHome.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default connect(mapStateToProps)(CargoHome);
